import axios from "axios";

import React, { useContext, useEffect, useState } from "react";

import Select from "react-select";

import AsyncSelect from "react-select/async";

//import { updateValue } from "../../TabsStructure/tabFunctions";

import { updateValue } from "../../../TabsStructure/tabFunctions";

//import { TabStore } from "../../TabsStructure/TabsStore";
import { TabStore } from "../../../TabsStructure/TabsStore";

//import { AuthStore } from "../../Auth/AuthStore";
import { AuthStore } from "../../../Auth/AuthStore";

//import useSaveChangedRows from "../../../Hooks/useSaveChangedRows";
import useSaveChangedRows from "../../../CommonControllers/Hooks/useSaveChangedRows";

import { PropaneSharp } from "@mui/icons-material";

const SaveDropDownAccounts = ({
  async,
  inputValue,
  apiUrl,
  selectionLabel,
  selectionValue,
  minInput,
  placeholder,
  displayLabel,
  className,
  apikey,
  id,
  multi,
  width,
  body,
  setDataToParent,
  defaultValue,
  zIndex = 10,
  alignment,
  onChange,
  isrequired,
}) => {
  const tabStore = useContext(TabStore);
  const auth = useContext(AuthStore);
  const bgcolor = auth?.state?.userdata?.theme[0]?.inputbgcolor
  const btncolor = auth?.state?.userdata?.theme[0]?.btncolor
  let drpWidth = width ? width : "150px";
  const [options, setOptions] = useState([]);
  const useChangedRows = useSaveChangedRows();
  const [selectedValue, setSellectedValue] = useState(null);
  const [isFocused, setIsFocused] = useState(false);
  console.log("SaveDropDownAccounts Default value", defaultValue);

  async function getDropDownList(inputValue) {
    body = {
      tenantrecno: auth.state.userdata.tenantrecno,
      domainrecno: auth.state.userdata.domainrecno,
      descn: inputValue,
      ...body,
    };

    const resp = await axios.post(apiUrl, body);

    const formattedArray = resp.data.Message.map((item) => {
      return {
        ...item,
        value: item[selectionValue],
        label: item[selectionLabel],
      };
    });
    setOptions(formattedArray);

    var selectionOptn = formattedArray.filter(
      (item) => item[apikey] == useChangedRows.getValue(apikey)
    );

    // if (selectionOptn.length > 0) {

    //   setSellectedValue(selectionOptn[0]);
    // }
    return formattedArray;
  }

    /// debouncing logic here main logic here on 18th January 2023
    const debounce = (func, delay) => {
      let timer;
    
      return function () {
        const context = this;
        const args = arguments;
    
        clearTimeout(timer);
        timer = setTimeout(() => {
          func.apply(context, args);
        }, delay);
      };
    };

  const loadOptions = async (inputValue, callback) => {
    if (inputValue.length >= minInput) {
      const customers = await getDropDownList(inputValue);
      callback(customers);
    }
  };

  // debouncing logic here start on 5th december this function helps to call the api after 3 seconds
  const debouncedLoadOptions = debounce(loadOptions, 2000);

  useEffect(() => {
    if (!async) {
      getDropDownList();
    }
  }, []);

  useEffect(() => {
    if (async) {
      let temp_value =
        tabStore?.state?.tabs[tabStore.state.tabIndex]?.changedRows[apikey];

      if (temp_value) {
        // console.log("DwopDown 1", temp_value);
        let _temp = {
          value: temp_value.code,
          label: temp_value.accountname,
        };
        setSellectedValue(_temp);
      } else {
        setSellectedValue(null);
      }
    }
  }, [defaultValue]);

  useEffect(() => {
    if (!async) {
      console.log("Default Value of DropDown is", defaultValue, selectedValue);
      console.log("Default Value of DropDown is", options);

      var selectedSupplier = tabStore?.state?.tabs[tabStore.state.tabIndex]
        ?.changedRows
        ? tabStore?.state?.tabs[tabStore.state.tabIndex]?.changedRows[apikey]
        : defaultValue;

      const selectedObject = options?.filter(
        (item) => item["code"] == selectedSupplier
      );

      console.log("Selected Object", selectedObject);
      if (selectedObject.length > 0) {
        setSellectedValue(selectedObject[0]);
        if (setDataToParent) {
          setDataToParent(selectedObject[0]);
        }
      } else {
        setSellectedValue(null);
      }
      console.log("async", async);
    }
  }, [options, defaultValue]);

  const updateMultiSelection = (e) => {
    if (e?.length > 0) {
      var updateValues = "";
      e.map((item) => {
        updateValues = updateValues + item[selectionValue] + ",";
      });
      updateValues = updateValues.substring(0, updateValues.length - 1);
      useChangedRows.updateValue(apikey, updateValues);
      setSellectedValue(e);
      if (setDataToParent) {
        setDataToParent(e);
      }
    } else {
      setSellectedValue(e);
      useChangedRows.updateValue(apikey, e[selectionValue]);
      if (setDataToParent) {
        setDataToParent(e);
      }
    }
  };

  const handleFocus = () => {
    // This function is called when the input element receives focus.
    setIsFocused(true)
  }

  const handleBlur = () => {
    // This function is called when the input element loses focus.
    setIsFocused(false)
  }

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        flexDirection: alignment,
        textAlign: "start",
        gap: "8px",
        // zIndex: zIndex,
        position: "relative"
      }}
    >
      {/* {!alignment ? (
        <p style={{ paddingRight: "15px", color: "black" }}>{displayLabel} </p>
      ) : (
        <p style={{ width: "100%" }}>{displayLabel}</p>
      )} */}
      <div style={{ width: drpWidth }}>
        {async ? (
          <AsyncSelect
            onFocus={handleFocus}
            onBlur={handleBlur}
            id={id}
            name={apikey}
            defaultValue={selectedValue}
            value={selectedValue}
            className="dropdown"
            placeholder={""}
            cacheOptions
            loadOptions={debouncedLoadOptions}
            onChange={(e, type) => {
              //updateMultiSelection(e, type);
              setSellectedValue(e);
              onChange(e, type);
            }}
            isClearable
            isMulti={multi}
            styles={{
              control: (baseStyle, state) => ({
                ...baseStyle,
                ...dropDownStyles,
                menu: (provided) => ({ ...provided, zIndex: 9999 }),
                backgroundColor:bgcolor || "#EBEDF0"
              }),
              option: (baseStyle, { isFocused, isSelected }) => ({
                ...baseStyle,
                backgroundColor: isSelected
                  ? btncolor || "#EBEDF0"
                  : isFocused
                  ? bgcolor || "#EBEDF0"
                  : 'white',
                  color:isSelected
                  ? "black" : "black"
              }),
            }}
          />
        ) : (
          <Select
            onFocus={handleFocus}
            onBlur={handleBlur}
            id={id}
            placeholder={""}
            defaultValue={selectedValue}
            onChange={(e, type) => {
              updateMultiSelection(e);
              onChange(e, type);
            }}
            value={selectedValue}
            isMulti={multi}
            options={options}
            className="basic-multi-select select-wrapper"
            classNamePrefix="dropdown select"
            name={apikey}
            styles={{
              control: (baseStyle, state) => ({
                ...baseStyle,
                ...dropDownStyles,
                menu: (provided) => ({ ...provided, zIndex: 9999 }),
                backgroundColor:bgcolor || "#EBEDF0"
              }),
              option: (baseStyle, { isFocused, isSelected }) => ({
                ...baseStyle,
                backgroundColor: isSelected
                  ? btncolor || "#EBEDF0"
                  : isFocused
                  ? bgcolor || "#EBEDF0"
                  : 'white',
                  color:isSelected
                  ? "black" : "black"
              }),
            }}
          />
        )}
      </div>
      <label
              htmlFor={id}
              className={`label ${isFocused || selectedValue ? 'focused' : 'unfocused'}`}
              style={{
               
                whiteSpace: "nowrap",
                wordWrap:"unset",
                position: 'absolute',
                top: isFocused || selectedValue ? '-19px' : '50%',
                left: '8px',
                //backgroundColor: isFocused || selectedValue ? 'white' : "transparent",
                padding: '0 4px',
                fontSize: isFocused || selectedValue ? '12px' : '16px',
                color: isFocused ? 'black' : '',
                pointerEvents: 'none',
                transform:
                  isFocused || selectedValue
                    ? 'translateY(0)'
                    : 'translateY(-50%)',
                transition:
                  'top 0.3s, transform 0.3s, font-size 0.3s, color 0.3s'
              }}
            >
              {isrequired ? (
               <span className={`label ${isFocused || selectedValue ? 'focused' : 'unfocused'}`}>
                  {isFocused || selectedValue? displayLabel : placeholder}
                  <span style={{ color: 'red' }}>*</span>
                </span>
              ) : (
                <span className={`label ${isFocused || selectedValue ? 'focused' : 'unfocused'}`}>
                  {isFocused || selectedValue? displayLabel :placeholder}
                 
                </span>
              )}
            </label>
    </div>
  );
};

export default SaveDropDownAccounts;

//CSS

const dropDownStyles = {
  // border: "1px solid #cccccc",

  borderRadius: "8px",
};

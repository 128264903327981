import React from "react";

const RateCard = () => {
  return (
    <div>
        <div style={{position:'fixed',backgroundColor:'white',width:'80%'}}><h1>Rate Card</h1></div>
        <br/> 
        <br/> 
      <h3>Procedure for maintaining Rate Cards</h3>
      <p>The activities are to be carried out at the BU ( Domain Level )</p>
      <h3>Step 1 - MS064 - Rate Card Master</h3>
      <p>
        To begin, it is necessary to create one or more Rate Cards. If the same
        rates are applicable to all customers within the domain, only one Rate
        Card Master needs to be prepared. However, if different rates are to be
        maintained for each customer, multiple Rate Card Masters must be
        created. It is important to note that at this stage, only the names of
        the Rate Cards are generated, and the actual rates will be defined and
        associated with each Rate Card in subsequent steps
      </p>
      <h3>Step2 - MS125 - Rate Card - Rates</h3>
      <p>
        The process for adding rates initially involves the following steps:
      </p>
      <p>1. Select the desired Rate Card Master.</p>
      <p>
        2. Enter the Effect Date, which signifies the starting date when the
        Rate Card will come into effect.
      </p>
      <p>3. Click the Execute Button from the toolbar.</p>
      <p>4. A list of all items will be displayed.</p>
      <p>5. Click the Save Button from the toolbar</p>
      <p>6. Click on the Edit Button corresponding to the specific item.</p>
      <p>
        7. Enter the MRP (Maximum Retail Price), Sale Rate, Purchase Rate, and
        Landed Rate for the item.
      </p>
      <p>8. For the first time, enter the rates for all the items.</p>
      <p>9. Save the rates using the Save Button located in the Toolbar.</p>
      <p>
        Note: When entering the Sale Rate and Purchase Rate, exclude the GST.
        The Sale Rate with GST and Purchase Rate with GST will be automatically
        calculated and filled in once you save the rates.
      </p>
      <p>
        In case there are changes to the rates, follow the same procedure as
        mentioned in step numbers 1 to 8. This time, you only need to enter the
        rates that have changed, rather than re-entering rates for all items.
      </p>
      <h3>Step3 - MS124 - BU Customer Rate Card</h3>
      <p>
        To establish a connection between the rate card and either all customers
        or a specific group of customers, this page is utilised. Through this
        page, businesses can easily associate the appropriate rate card with
        their customers, ensuring accurate and consistent pricing for the
        products or services offered. This functionality streamlines the process
        of managing rates across customer segments, providing greater
        flexibility and efficiency in maintaining pricing structures.
      </p>
      <h3>Step4 - MS101 - Domain Customers</h3>
      <p>
        To establish a direct association between Rate Cards and individual
        customers, this page is utilised. By utilising this page, BU can easily
        link specific Rate Cards to each customer, ensuring personalised pricing
        and tailored rate structures. This functionality allows for a more
        customised and targeted approach to managing rates for individual
        customers.
      </p>
    </div>
  );
};

export default RateCard;

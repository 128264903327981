import { Checkbox } from "antd";
import React, { useEffect, useState } from "react";
import useExecute from "../../Hooks/useExecute";

function ExecuteCheckbox({
  editable,
  returnValue,
  displayLabel,
  apikey,
  defaultValue,
}) {
  const [checked, setChecked] = useState();
  const execute = useExecute();

  // Setting Default Value that comes from the Parent Component
  useEffect(() => {
    console.log("Default Value and Store Value", defaultValue, execute.getValue(apikey))
    if (defaultValue) {
      if (execute.getValue(apikey)) {
        setChecked(execute.getValue(apikey));
      } else if (execute.getValue(apikey) == "") {
        setChecked(execute.getValue(apikey));
      } else {
        setChecked(defaultValue);
        execute.updateValue(apikey, defaultValue);
      }
    } else {
      if (execute.getValue(apikey)) {
        setChecked(execute.getValue(apikey));
      }
    }
  }, [defaultValue]);

  // On Change the value needs to be stored in the tabstore
  const onChange = () => {
    setChecked(!checked);
    if (returnValue) {
      returnValue(!checked);
    }
    execute.updateValue(apikey,!checked)
    
  };

  return (
    <div>
      {editable ? (
        <Checkbox onChange={onChange} checked={checked}>
          {displayLabel}
        </Checkbox>
      ) : (
        <Checkbox checked={checked}>{displayLabel}</Checkbox>
      )}
    </div>
  );
}

export default ExecuteCheckbox;

import { Button, Input, Modal, Radio, Space, Table, notification } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { IoAperture } from "react-icons/io5";
import ItemSelectionModal from "./ItemSelectionModal";
import useFetchItem from "./useFetchItems";
import AsyncSelect, { useAsync } from "react-select/async";
import { apiCallwithBaseUrl } from "../ApiCalls";
import { AuthStore } from "../../Auth/AuthStore";
import { datedisplay } from "../AppFunction";

function ItemSearch({
  forFlag,
  selection,
  returnValue,
  dropdown,
  supplierdata,
  defaultSelectedItem,
  itemSearchRef,
  forPage,
  trdate,
  barcodeReturnValue,
  pageDetails,
}) {
  const [searchBy, setSearchBy] = useState("itembarcode");
  const [itemsList, setItemsList] = useState([]);
  const auth = useContext(AuthStore);
  const bgcolor = auth?.state?.userdata?.theme[0]?.inputbgcolor;
  const btncolor = auth?.state?.userdata?.theme[0]?.btncolor;
  const [selectedItem, setSelectedItem] = useState(null);
  const [showSelectedName, setShowSelectedName] = useState(null);
  const [showItemSelectionModal, setShowItemSelectionModal] = useState(false);
  const [getSelectedItemBatchDetails, setSelectedItemBatchDetails] = useState(
    []
  );
  let workdate = auth?.state?.workdate;
  const options = [
    {
      label: "Code",
      value: "itemcode",
    },
    {
      label: "Name",
      value: "itemshortdescn",
    },
    {
      label: "Barcode",
      value: "itembarcode",
    },
  ];

  const useFetchItems = useFetchItem();

  // Search Items for Selection and DropDown
  // Keep the data stored in the cache so that it is later onaccessible faster.
  useEffect(() => {
    setSelectedItem(defaultSelectedItem);
  }, [defaultSelectedItem]);

  const searchItem = async (event) => {
    const data = await useFetchItems.fetchItems(
      searchBy,
      event,
      forFlag,
      true,
      supplierdata,
      trdate,
      forPage
    );

    var columns = data.data?.columnsinfo?.map((item) => {
      return { ...item, dataIndex: item.columnname, key: item.columnname };
    });

    // columns = [{dataIndex : "Select",render:(_,data) => {return <Button>Sel</Button>}}  , ...columns]

    var rows = data.data?.Message;
    setItemsList({ columns: columns, rows: rows });
    const formattedArray = rows.map((item) => {
      if (searchBy == "itemcode") {
        return {
          ...item,
          value: item["itemrecno"],
          label: item["itemcode"],
        };
      } else {
        return {
          ...item,
          value: item["itemrecno"],
          label: item["itemshortdescn"],
        };
      }
    });

    return formattedArray;
  };
  /// debouncing logic here main logic here on 5th december
  const debounce = (func, delay) => {
    let timer;

    return function () {
      const context = this;
      const args = arguments;

      clearTimeout(timer);
      timer = setTimeout(() => {
        func.apply(context, args);
      }, delay);
    };
  };
  const loadOptions = async (inputValue, callback, event) => {
    if (inputValue?.length >= 0) {
      const dropdownoptions = await searchItem(inputValue);

      callback(dropdownoptions);
    }
  };
  /// debouncing logic here start on 5th december this function helps to call the api after 3 seconds
  const debouncedLoadOptions = debounce(loadOptions, 2000);
  // Only for Item Barcode Scanning
  // Only for Item Barcode Scanning
  const getItemFromBarcode = async (event) => {
    /// date 20 april 2023 the if supplier data is notavailable it should not call api

    if (event.keyCode == 13 && supplierdata) {
      // Here we call the API for Fetching the Data from the Barcode
      try {
        let bodyForApi = {
          barcode: event.target.value,
          tenantrecno: auth.state.userdata.tenantrecno,
          domainrecno: auth.state.userdata.domainrecno,
          trdate: trdate,
          forflag: forFlag,
        };
        if (trdate && trdate.toString().includes("/")) {
          bodyForApi = { ...bodyForApi, trdate: datedisplay(trdate).dataDate };
        }

        // old bodyForApi

        if (forFlag == "P") {
          bodyForApi = { ...bodyForApi, supplierrecno: supplierdata };
        } else if (forFlag == "S") {
          bodyForApi = { ...bodyForApi, customerrecno: supplierdata };
        } else if (forFlag == "PR") {
          bodyForApi = {
            ...bodyForApi,
            supplierrecno: supplierdata,
            trdate: trdate,
          };
        } else if (forFlag == "SR") {
          bodyForApi = { ...bodyForApi, customerrecno: supplierdata };
        } else if (forFlag == "MSR") {
          bodyForApi = {
            ...bodyForApi,
            customerrecno: supplierdata,
            trdate: Number(trdate),
          };
        }
        ///stock verification
        else if (forFlag == "D") {
          bodyForApi = { ...bodyForApi };
        }
        /// here
        else if (forPage == "openingsi") {
          bodyForApi = {
            ...bodyForApi,
            customerrecno: supplierdata,
            forflag: "openingsi",
          };
        } else if (forPage == "openingpi") {
          bodyForApi = {
            ...bodyForApi,
            customerrecno: supplierdata,
            forflag: "openingpi",
          };
        } else if (forPage == "openingdc") {
          bodyForApi = {
            ...bodyForApi,
            customerrecno: supplierdata,
            forflag: "openingdc",
          };
        }
        const res = await apiCallwithBaseUrl(
          "POST",
          "getitemfromscannedbarcode",
          bodyForApi
        );
        console.log("Data checking", res);
        if (res.Success) {
          if (forPage === "PO") {
            //
            // console.log("DATa===>",forPage,res,barcodeReturnValue)
            if (forFlag == "D" && forPage == "PO") {
              returnValue(res.Message[0]);
            }
            if (barcodeReturnValue) {
              barcodeReturnValue(res.Message[0]);
            } else {
              returnValue(res.Message[0]);
            }
          } else if (forPage == "GRN") {
            //  returnValue(res.Message[0]);
            if (barcodeReturnValue) {
              barcodeReturnValue(res.Message[0]);
            } else {
              returnValue(res.Message[0]);
            }
          }
          // else if (forPage == "GRN") { /// here itemreceipt tr029
          //   //  returnValue(res.Message[0]);
          //   if (barcodeReturnValue) {
          //     barcodeReturnValue(res.Message[0]);
          //   } else {
          //     returnValue(res.Message[0]);
          //   }
          // }
          else if (forPage == "SO") {
            //  returnValue(res.Message[0]);
            if (barcodeReturnValue) {
              barcodeReturnValue(res.Message[0]);
            } else {
              returnValue(res.Message[0]);
            }
          } else if (forPage == "openingsi" && forFlag == "P") {
            if (barcodeReturnValue) {
              barcodeReturnValue(res.Message[0]);
            } else {
              returnValue(res.Message[0]);
            }
          } else if (forPage == "openingpi") {
            if (barcodeReturnValue) {
              barcodeReturnValue(res.Message[0]);
            } else {
              returnValue(res.Message[0]);
            }
          } else if (forPage == "openingdc") {
            if (barcodeReturnValue) {
              barcodeReturnValue(res.Message[0]);
            } else {
              returnValue(res.Message[0]);
            }
          } else {
            if (res.Message[0].batchdetails.length == 0) {
              if (forPage == "openingsi" && forFlag == "S") {
                barcodeReturnValue(res.Message[0]);
                event.target.value = "";
                return;
              }

              // auth.state.contextHolder.open({
              //   message: `Stock Not Available`,
              //   description: `Stock Not Available for ${res.Message[0].itemdescn}`,
              //   duration: 3,
              //   style: { background: "#efa7a7" },
              // });
              notification.error({
                message: `Stock Not Available`,
                description: `Stock Not Available for ${res.Message[0].itemdescn}`,
                duration: 3,
                style: { background: "#efa7a7" },
              });
            } else if (res.Message[0].batchdetails.length == 1) {
              // Return Only One Batch, so Directly return to Previous Page
              //  returnValue(res.Message[0]);
              if (barcodeReturnValue) {
                barcodeReturnValue(res.Message[0]);
              } else {
                returnValue(res.Message[0]);
              }

              setShowItemSelectionModal(res.Message[0].itemdescn);
            } else if (res.Message[0].batchdetails.length > 1) {
              // Open the Pop-up with the Seelcted Batch Number
              setSelectedItem(res.Message[0]);
              if (barcodeReturnValue) {
                barcodeReturnValue(res.Message[0]);
              } else {
                returnValue(res.Message[0]);
              }
              //  if (selection) {
              //    setShowItemSelectionModal(true);
              //  }
            }
          }
        } else {
          // auth.state.contextHolder.open({
          //   message: `An Error has Occured`,
          //   description: res?.Message,
          //   duration: 3,
          //   style: { background: "#efa7a7" },
          // });
          notification.error({
            message: `An Error has Occured`,
            description: res?.Message,
            duration: 3,
            style: { background: "#efa7a7" },
          });
          event.target.value = "";
        }
        event.target.value = "";
      } catch (e) {
        /// here the toast is added instead of the alert  as per the tasked assignned
        // auth.state.contextHolder.open({
        //   message: `An Error has Occured`,
        //   description: e.response.data.Message,
        //   duration: 3,
        //   style: { background: "#efa7a7" },
        // });
        notification.error({
          message: `An Error has Occured`,
          description: e?.response?.data?.Message
            ? e.response?.data?.Message
            : e.response?.data?.Error,
          duration: 3,
          style: { background: "#efa7a7" },
        });
        event.target.value = "";
      }
    }
    /// here is stock verification code
    else if (event.keyCode == 13 && forFlag == "D") {
      try {
        let bodyForApi = {
          barcode: event.target.value,
          tenantrecno: auth.state.userdata.tenantrecno,
          domainrecno: auth.state.userdata.domainrecno,
          trdate: trdate,
          forflag: forFlag,
        };

        // old bodyForApi

        if (forFlag == "P") {
          bodyForApi = { ...bodyForApi, supplierrecno: supplierdata };
        } else if (forFlag == "S") {
          bodyForApi = { ...bodyForApi, customerrecno: supplierdata };
        } else if (forFlag == "PR") {
          bodyForApi = {
            ...bodyForApi,
            supplierrecno: supplierdata,
            trdate: trdate,
          };
        } else if (forFlag == "SR") {
          bodyForApi = { ...bodyForApi, customerrecno: supplierdata };
        } else if (forFlag == "MSR") {
          bodyForApi = {
            ...bodyForApi,
            customerrecno: supplierdata,
            trdate: Number(trdate),
          };
        }
        ///stock verification
        else if (forFlag == "D") {
          bodyForApi = { ...bodyForApi };
        }
        /// here
        else if (forPage == "openingsi") {
          bodyForApi = {
            ...bodyForApi,
            customerrecno: supplierdata,
            forflag: "openingsi",
          };
        } else if (forPage == "openingpi") {
          bodyForApi = {
            ...bodyForApi,
            customerrecno: supplierdata,
            forflag: "openingpi",
          };
        } else if (forPage == "openingdc") {
          bodyForApi = {
            ...bodyForApi,
            customerrecno: supplierdata,
            forflag: "openingdc",
          };
        }
        const res = await apiCallwithBaseUrl(
          "POST",
          "getitemfromscannedbarcode",
          bodyForApi
        );

        if (res.Success) {
          console.log("Data to be added", res);
          if (forFlag == "MSR") {
            console.log("Data to be added", res);
          } else if (forPage == "PO") {
            //  returnValue(res.Message[0]);

            if (barcodeReturnValue) {
              barcodeReturnValue(res.Message[0]);
            } else {
              returnValue(res.Message[0]);
            }
          } else if (forPage == "GRN") {
            //  returnValue(res.Message[0]);
            if (barcodeReturnValue) {
              barcodeReturnValue(res.Message[0]);
            } else {
              returnValue(res.Message[0]);
            }
          } else if (forPage == "SO") {
            //  returnValue(res.Message[0]);
            if (barcodeReturnValue) {
              barcodeReturnValue(res.Message[0]);
            } else {
              returnValue(res.Message[0]);
            }
          } else if (forPage == "openingsi") {
            if (barcodeReturnValue) {
              barcodeReturnValue(res.Message[0]);
            } else {
              returnValue(res.Message[0]);
            }
          } else if (forPage == "openingpi") {
            if (barcodeReturnValue) {
              barcodeReturnValue(res.Message[0]);
            } else {
              returnValue(res.Message[0]);
            }
          } else if (forPage == "openingdc") {
            if (barcodeReturnValue) {
              barcodeReturnValue(res.Message[0]);
            } else {
              returnValue(res.Message[0]);
            }
          } else {
            if (res.Message[0].batchdetails.length == 0) {
              notification.error({
                message: `No Batch Found`,
                description: `No Batchs found for ${res.Message[0].itemdescn}`,
                duration: 3,
                style: { background: "#efa7a7" },
              });
            } else if (res.Message[0].batchdetails.length == 1) {
              // Return Only One Batch, so Directly return to Previous Page
              //  returnValue(res.Message[0]);
              if (barcodeReturnValue) {
                barcodeReturnValue(res.Message[0]);
              } else {
                returnValue(res.Message[0]);
              }

              setShowItemSelectionModal(res.Message[0].itemdescn);
            } else if (res.Message[0].batchdetails.length > 1) {
              // Open the Pop-up with the Seelcted Batch Number
              setSelectedItem(res.Message[0]);
              if (barcodeReturnValue) {
                barcodeReturnValue(res.Message[0]);
              } else {
                returnValue(res.Message[0]);
              }
              //  if (selection) {
              //    setShowItemSelectionModal(true);
              //  }
            }
          }
        } else {
          notification.error({
            message: `An Error has Occured`,
            description: res?.Message,
            duration: 3,
            style: { background: "#efa7a7" },
          });
        }
        event.target.value = "";
      } catch (e) {
        /// here the toast is added instead of the alert  as per the tasked assignned
        notification.error({
          message: `An Error has Occured`,
          description: e.response.data.Message,
          duration: 3,
          style: { background: "#efa7a7" },
        });
      }
    }
    /// here is end for stock verification
    //// Date 20 april 2023  the input field clearing the date
  };

  ////Date 31 may 2023 rajendra  is service validation
  useEffect(() => {
    if (selectedItem?.isservice == 1) {
      returnValue(selectedItem);
    }
  }, [selectedItem]);

  return (
    <div>
      {/* Item Selection Option in a Modal */}

      {dropdown ? (
        <div style={{ display: "flex", width: "100%" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              flex: 1,
              zIndex: 0,
            }}
          >
            <Space direction="vertical">
              <Radio.Group
                options={options}
                onChange={(e) => setSearchBy(e.target.value)}
                value={searchBy}
                optionType="button"
                buttonStyle="solid"
                size="large"
              />
              {/* <span style={{ color: 'red' }}>*</span> */}
              {searchBy == "itembarcode" ? (
                // amol-wms - added margintop here
                <div style={{ marginTop: -7 }}>
                  <input
                    className="inputStyles"
                    style={{ ...inputStyles, width: "250px" }}
                    placeholder="Scan Barcode"
                    onKeyDown={(e) => getItemFromBarcode(e)}
                    ref={itemSearchRef}
                  ></input>
                </div>
              ) : (
                <div>
                  <div style={{ width: "250px", zIndex: 999 }}>
                    <AsyncSelect
                      ref={itemSearchRef}
                      id="item-search-value"
                      loadOptions={debouncedLoadOptions}
                      // defaultOptions={searchItem}
                      value={selectedItem}
                      onChange={(e) => {
                        console.log(
                          "DR^&FYGUOHIPJO",
                          selection,
                          e && selection && e.wantbatchno
                        );
                        setSelectedItem(e);
                        if (
                          selection &&
                          e &&
                          e.wantbatchno &&
                          forPage !== "openingsi"
                        ) {
                          setShowItemSelectionModal(true);
                        } else {
                          returnValue(e);
                        }
                      }}
                      //   onInputChange={(e, f, g, h) => {
                      //       console.log("Event On Input Change,", e, f, g, h)
                      //   }}
                      //   onKeyDown={(e, f, g, h) => {
                      //     console.log("Event On Input Change,", e, f, g, h)
                      // }}
                      styles={{
                        control: (baseStyle, state) => ({
                          ...baseStyle,
                          ...dropDownStyles,
                          menu: (provided) => ({ ...provided, zIndex: 9999 }),
                          backgroundColor: bgcolor,
                        }),
                        option: (baseStyle, { isFocused, isSelected }) => ({
                          ...baseStyle,
                          backgroundColor: isSelected
                            ? btncolor
                            : isFocused
                            ? bgcolor
                            : "white",
                        }),
                      }}
                      // defaultValue={colourOptions[0]}
                      isClearable
                    />
                  </div>
                </div>
              )}
            </Space>
          </div>
          <div></div>
        </div>
      ) : (
        <div style={{ display: "flex", width: "100%", padding: "20px 0" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              flex: 1,
              zIndex: 0,
            }}
          >
            <Space direction="vertical">
              <Radio.Group
                options={options}
                onChange={(e) => setSearchBy(e.target.value)}
                value={searchBy}
                optionType="button"
                buttonStyle="solid"
                size="large"
              />
              <Space>
                <Input
                  id="item-search-value"
                  autoFocus
                  placeholder={`Enter ${searchBy}`}
                  onPressEnter={(e) => searchItem(e.target.value)}
                ></Input>
                <Button
                  icon={<IoAperture />}
                  onClick={() => {
                    searchItem(
                      document.getElementById("item-search-value").value
                    );
                  }}
                ></Button>
              </Space>
            </Space>
          </div>
          <div className="item-search" style={{ ...tableBox }}>
            <Table
              scroll={{ y: 150 }}
              columns={itemsList?.columns}
              dataSource={itemsList?.rows}
              pagination={false}
              size="small"
              onRow={(record, rowIndex) => {
                return {
                  onClick: (event) => {
                    setShowItemSelectionModal(true);
                    setSelectedItem(record.itemrecno);

                    returnValue(record);
                  }, // click row
                };
              }}
            />
          </div>
        </div>
      )}

      {selection &&
      selectedItem?.isservice === 0 &&
      searchBy != "itembarcode" &&
      selectedItem?.itemasbatch != 1 ? (
        <Modal
          key={showItemSelectionModal}
          onOk={() => {
            setShowItemSelectionModal(false);

            if (returnValue) {
              // Filter batch details and ensure selectedQty is a number
              let itemBatchArray =
                getSelectedItemBatchDetails?.batchdetails?.filter((batch) => {
                  const selectedQty = Number(batch.selectedQty);
                  return !isNaN(selectedQty) && selectedQty > 0; // Valid number and greater than 0
                });

              if (Array.isArray(itemBatchArray)) {
                // Convert selectedQty to number in the resulting array
                itemBatchArray = itemBatchArray.map((batch) => ({
                  ...batch,
                  selectedQty: Number(batch.selectedQty), // Ensure selectedQty is a number
                }));

                // Create a new object including the new key itembatcharray
                const updatedDetails = {
                  ...getSelectedItemBatchDetails, // Spread existing properties
                  itembatcharray: itemBatchArray,
                  datafrombatch: "frommodal", // Add the new key itembatcharray
                };

                // Log and return the updated object

                returnValue(updatedDetails);
              }
            }

            // Check for expired products
            if (
              getSelectedItemBatchDetails?.batchDetails?.[0]?.expdate < workdate
            ) {
              alert("Product is Expired");
            }
          }}
          onCancel={() => setShowItemSelectionModal(false)}
          open={showItemSelectionModal}
          width={1200}
        >
          <ItemSelectionModal
            item={selectedItem}
            forFlag={forFlag}
            selectedItemBatchDetails={getSelectedItemBatchDetails}
            setterFunction={setSelectedItemBatchDetails}
            returnValue={(e) => {
              if (selectedItem) {
                setSelectedItemBatchDetails(e);
              }
            }}
            forPage={forPage}
            pageDetails={pageDetails}
          />
        </Modal>
      ) : null}
    </div>
  );
}

export default ItemSearch;

const tableBox = {
  flex: 3,
  border: "1px solid #dddd",
  borderRadius: "5px",
};
const dropDownStyles = {
  border: "1px solid #cccccc",

  borderRadius: "8px",
};
const inputStyles = {
  padding: "10px",
  border: "1px solid rgb(120,120,120)",
  width: "80px",
  fontSize: "16px",
  marginTop: "7px",
  borderRadius: "6px",
};

import React, { useMemo } from "react";

const PrintText = ({ item,pageNumber,pageheight }) => {
  // const getFontSize = useMemo(() => {
  //   const minFontSize = 8; // Minimum font size in pixels
  //   const maxFontSize = 45; // Maximum font size in pixels
  //   const maxLength = item?.fieldlength; // Maximum string length before font size stops increasing
  //   // const length = item.label.length
  //   const length = item?.fieldvalue?.length;
  //   // const boxWidth = parseInt(item.width) ; // Width of the box
  //   const boxWidth = parseInt(item.fieldboxwidth); // Width of the box
  //   console.log("length of string", length, "border width", boxWidth);
  //   // Calculate the maximum string length that fits within the box width
  //   const maxStringLength = Math.floor(boxWidth / minFontSize);
  //   const fontSizeIncrement = (maxFontSize - minFontSize) / length;

  //   console.log(
  //     "maxStringLength",
  //     maxStringLength,
  //     "fontSizeIncrement",
  //     fontSizeIncrement
  //   );

  //   let fontSize;
  //   if (length >= maxStringLength && length >= maxLength) {
  //     fontSize = minFontSize + fontSizeIncrement;
  //     console.log("fontSize after decrement", fontSize);
  //   } else {
  //     // fontSize = item.fontsize;
  //     fontSize = item.fieldfontsize;
  //   }
  //   console.log("`${fontSize}px`", `${fontSize}px`);
  //   return `${fontSize}px`;
  // }, [item]);

  // console.log("getFontSize", getFontSize);
  return (
    <div style={{
     position:"absolute"
    }}>
           <p
              style={{
                padding:"3px",
                fontSize: `${item?.fontsize}px`,
                position: "absolute",
                border: item?.printbox === 1 ? "1px solid black" : null,
                // border: item?.fieldbox === 1 ? "1px solid black" : null,
                left: `${item?.fieldx}px`,
                // `${(pageNumber - 1)*1280 + item.fieldy}px`
                top:`${item?.fieldy}px`,
                fontFamily: item?.fontname,
                // fontWeight: item?.fontWeight,
                // fontStyle: item?.fontStyle,
                fontWeight: item?.fontbold==1?"bold":null,
                fontStyle:item?.fontitalic==1?"italic":null,
                width:`${item?.fieldxto}px`,
                // width: `${item?.fieldxto}px`,
                height:`${item?.fieldyto}px`,
                wordWrap:"break-word"
              }}
            >
              {item?.fieldvalue}
            </p>
    </div>
  );
};

export default PrintText;

// import { Button, Popconfirm, Table } from 'antd';
// import React, { useContext, useEffect, useState } from 'react';
// import SaveDropdown from '../../CommonControllers/Components/SaveComponents/SaveDropdown';
// import SaveTextInput from '../../CommonControllers/Components/SaveComponents/SaveTextInput';
// import { DomainItemListURL, getDomainItemListURL, getdomainlocationlistactive } from '../../CommonControllers/Urls';
// import ScanTable from '../../CommonControllers/Components/ScanTable/ScanTable';
// import { BiAddToQueue } from 'react-icons/bi';
// import { getfastpathapi } from '../../CommonControllers/ApiCalls';
// import { TabStore } from '../../TabsStructure/TabsStore';
// import { AuthStore } from '../../Auth/AuthStore';
// import useShortguid from '../../CommonControllers/Hooks/useShortGuid';
// import { currentTabData } from '../../CommonControllers/Variable';
// import { AiFillDelete } from 'react-icons/ai';
// import { removeByAttribute } from '../../CommonControllers/AppFunction';

// const StockExchangeNew = () => {

//   const [rowData, setRowData] = useState([]);
//   const [openItem, setOpenItem] = useState(false);
//   const [pageDetails, setPageDetails] = useState();
//   const [scandata, setScandata] = useState();
//   const shortguid = useShortguid();
//   const tabStore = useContext(TabStore);
//   const auth = useContext(AuthStore);
//   const [count, setcount] = useState(0);
//   ///batch
//   const [opnenBatchcount, setopenBatchcount] = useState(0);
//   const [itemForSelection, setItemForSelection] = useState(null);
//   const [opnenBatch, setopenBatch] = useState(false);
//   const [validator, setValidator] = useState(0);
//   const [renderer, setRenderer] = useState(0);

//   const payload = {
//     tenantrecno: auth?.state?.userdata?.tenantrecno,
//     domainrecno: auth?.state?.userdata?.domainrecno,
//     userrolerecno: auth?.state?.userdata?.userrolerecno,
//     domainuserrecno: auth.state.userdata.recno,
//     fastpath: currentTabData(tabStore)?.name,
//   };
//   useEffect(() => {
//     // Get Fast ath API
//     getfastpathapi(payload).then((res) => {
//       if (res?.Success) {
//         setPageDetails(res?.Message);
//       }
//     });
//   }, []);
//   console.log("rowdata innnnnnn",rowData,scandata)
//   const handleChange = (
//     item,
//   ) => {
//     console.log("item in handleChange", item)


//     var itemObject = {
//       ...item,
//       itemrecno: item?.item?.itemrecno,
//       itemdescn: item?.item?.itemdescn,
//       itembatchno: item?.wantbatchno ? item.itembatchno : ".",
//       fromlocation: item?.fromlocation ? item?.fromlocation : [],
//       crqty: item?.crqty ? item?.crqty : "0.00",
//       tolocation: item?.tolocation ? item?.tolocation : [],
//       drqty: item?.drqty ? item?.drqty : "0.00",
//     };
//     var currentItem = rowData.map((itm) => {
//       if (itm.shortguid == item.shortguid) {
//         return { ...itemObject };
//       } else {
//         return { ...itm };
//       }
//     });
//     // // console.log("Rajendra check",item)
//     setRowData(() => currentItem);
//     setScandata(item.item);
//     console.log("item in HandleChange rowdata", rowData, scandata)
//   };

//   const handleRemove = (item, setRowData, rowData) => {
//     var removedArray = removeByAttribute(rowData, "shortguid", item.shortguid);
//     console.log("Remove", item, setRowData, rowData, removedArray);
//     setRowData(() => [...removedArray]);
//   };
//   const columns = [
//     {
//       title: " ",
//       dataIndex: "active",
//       key: "active",
//       width: 80,
//       render: (_, text) => {
//         return (
//           <Popconfirm
//             title="Do you want to Cancel the changes?"
//             onConfirm={(e) => {
//               handleRemove(text, setRowData, rowData);
//             }}
//           >
//             <Button icon={<AiFillDelete />}></Button>
//           </Popconfirm>
//         );
//       },
//     },
//     {
//       title: 'Item Name',
//       dataIndex: 'itemName',
//       key: 'itemName',
//       render: (_, text) => {
//         return (
//           <>
// <SaveDropdown
//   // displayLabel={"Item Name"}
//   placeholder={"Search Item Name"}
//   apiurl={getDomainItemListURL}
//   selectionLabel={"itemdescn"}
//   selectionValue={"itemrecno"}
//   returnValue={(e) => {
//     console.log("Dropdown ReturnValue", e, text)
//     text.item = e;
//     handleChange(text);
//   }}
// // async
// />
//           </>

//         )
//       }
//     },
//     {
//       hidden: pageDetails?.wantbatchno ? false : true,
//       width: 180,
//       title: "Batch No",
//       dataIndex: "itembatchno",
//       key: "itembatchno",
//       render: (_, text) => {
//         if(text?.item?.itemasbatch === 0){
//         if (text?.wantbatchno) {
//           return (
//             <><> <input
//               className="tr001Input"
//               onFocus={(e) => e.target.select()}
//               style={{ textAlign: "left" }}
//               disabled={text?.item?.itemasbatch === 1}
//               defaultValue={text.itembatchno}
//               onChange={(e) => {
//                 text.itembatchno = e.target.value;
//               }}
//               onBlur={(e) => {
//                 let existingItem = rowData.filter((item) => {
//                   if (
//                     item.itemrecno == text.itemrecno &&
//                     item.itembatchno == text.itembatchno &&
//                     item.shortguid != text.shortguid
//                   ) {
//                     return item;
//                   }
//                 });
//                 if (existingItem?.length > 0) {
//                   alert("Duplicate Batch Number for Same Item");
//                   text.itembatchno = "";
//                   e.target.value = "";
//                 } else {
//                   // { Pradeep, task id-238 }
//                   handleChange(
//                     text,
//                     setRowData,
//                     // igstappl,
//                     rowData,
//                     scandata,
//                     setScandata
//                   );
//                 }
//               }}
//             ></input></><div><>{text?.wantbatchno ? (
//               <Button
//                 onClick={() => {
//                   setItemForSelection(text);
//                   setopenBatch(true)
//                   setopenBatchcount((p) => p + 1)
//                 }}
//                 style={{ fontSize: '24px' }}
//                 icon={
//                   <BiAddToQueue

//                   />
//                 }
//               ></Button>
//             ) : null}</></div></>

//           );
//         } else {
//           return <>{text?.wantbatchno ? (
//             <Button
//               icon={
//                 <BiAddToQueue
//                   onClick={() => {
//                     //   setItemForSelection(text);
//                     //   setopenBatchcount((p)=>p+1)
//                     //   setopenBatch(true)
//                   }}
//                 />
//               }
//             ></Button>
//           ) : null}</>
//         }
//       }
//       },
//     },
//     {
//       title: 'Item Scan',
//       dataIndex: 'itemScan',
//       key: 'itemScan',
//       render: (_, text, index) => {
//         console.log("Text", text, _, index)
//         return (
//           <>
//             <Button
//               disabled={text?.item?.itemasbatch === 0}
//               onClick={() => {
//                 setOpenItem(true);
//               }}
//             >
//               Item Scan
//             </Button>
//           </>

//         )
//       }
//     },
//     {
//       title: 'From Location',
//       dataIndex: 'fromlocation',
//       key: 'fromlocation',
//       render: (_, text) => {
//         return (
//           <>
//             <SaveDropdown
//               // displayLabel={"From Location"}
//               placeholder={"Search From Location"}
//               apiurl={getdomainlocationlistactive}
//               selectionLabel={"descn"}
//               selectionValue={"locationcode"}
//               disabled={text?.item?.itemasbatch === 1}
//               returnValue={(e) => {
//                 console.log("return value", e);
//                 text.fromlocation = e;
//                 handleChange(text);
//               }}
//             />
//           </>

//         )
//       }
//     },
//     {
//       title: 'From Qty',
//       dataIndex: 'crqty',
//       key: 'crqty',
//       render: (_, text) => {
//         return (
//           <>
//             <input
//               className="tr001Input"
//               type={"number"}
//               disabled={text?.item?.itemasbatch === 1}
//               onChange={(e) => {
//                 console.log("input value", e.target.value);
//                 text.crqty = e.target.value;
//                 handleChange(text);
//               }}
//             />
//           </>

//         )
//       }
//     },
//     {
//       title: 'To Location',
//       dataIndex: 'tolocation',
//       key: 'tolocation',
//       render: (_, text) => {
//         return (
//           <>
// <SaveDropdown
//   // displayLabel={"To Location"}
//   placeholder={"Search To Location"}
//   apiurl={getdomainlocationlistactive}
//   selectionLabel={"descn"}
//   selectionValue={"locationcode"}
//   disabled={text?.item?.itemasbatch === 1}
//   returnValue={(e) => {
//     console.log("return value", e);
//     text.tolocation = e;
//     handleChange(text);
//   }}
// />
//           </>

//         )
//       }
//     },
//     {
//       title: 'To Qty',
//       dataIndex: 'drqty',
//       key: 'drqty',
//       render: (_, text) => {
//         return (
//           <>
//             <input
//               className="tr001Input"
//               type={"number"}
//               disabled={text?.item?.itemasbatch === 1}
//               onChange={(e) => {
//                 console.log("input value", e.target.value);
//                 text.drqty = e.target.value;
//                 handleChange(text);
//               }}
//             />
//           </>

//         )
//       }
//     },
//   ];

//   const handleAddRow = () => {
//     const newRowKey = (rowData.length + 1).toString(); // Generate a unique key for the new row
//     const newRow = {
//       key: newRowKey,
//       itemname: '',
//       itemrecno: '',
//       itembatchno: '',
//       // serialno: '',
//       fromlocation: [],
//       // fromlocationrecno: '',
//       crqty: '',
//       tolocation: [],
//       // tolocationrecno: '',
//       drqty: '',
//       item: [],
//       shortguid: shortguid.getShortguid("STOCKTF" + count),
//       wantbatchno: true
//     };
//     setRowData([...rowData, newRow]);
//   };

//   return (
//     <div>
//       <Button onClick={() => {
//         setcount((p) => p + 1);
//         handleAddRow();
//       }}>
//         ADD
//       </Button>
//       <Table columns={columns} dataSource={rowData} />

//       {openItem && (
//         <ScanTable
//           data={scandata}
//           openItem={openItem}
//           setOpenItem={setOpenItem}
//           rowData={rowData}
//           setRowData={setRowData}
//           forFlag={"S"}
//           setValidator={setValidator}
//           renderer={renderer}
//           setRenderer={setRenderer}
//         />
//       )}
//     </div>
//   );
// };

// export default StockExchangeNew;

import React, { useContext, useState } from 'react';
import SaveDropdown from '../../CommonControllers/Components/SaveComponents/SaveDropdown';
import { getDomainItemListURL, getdomainlocationlistactive } from '../../CommonControllers/Urls';
import { Button, Checkbox, Collapse, Input, InputNumber, List, Modal, Table } from 'antd';
import { useEffect } from 'react';
import { AuthStore } from '../../Auth/AuthStore';
import useSaveChangedRows from '../../CommonControllers/Hooks/useSaveChangedRows';
import { TabStore } from '../../TabsStructure/TabsStore';
import { getDomainItemListData, getfastpathapi } from '../../CommonControllers/ApiCalls';
import { currentTabData } from '../../CommonControllers/Variable';
import ItemSearch from '../../CommonControllers/ItemController/ItemSearch';
import { useRef } from 'react';
import useShortguid from '../../CommonControllers/Hooks/useShortGuid';
import { v4 as uuidv4 } from "uuid";

const StockExchageNew = () => {
  const [item, setItem] = useState(null);
  const [selectedItem, setselectedItem] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [locationData, setLocationData] = useState([]);
  const [scannedBarcode, setScannedBarcode] = useState('');
  const [locationWiseStock1, setlocationWiseStock1] = useState(null)
  const [tableData, setTableData] = useState([]);
  const [tolocation, settoLocation] = useState(null);
  const [count, setCount] = useState(0);
  const [pageIndex, setPageIndex] = useState();
  const [pageDetails, setPageDetails] = useState();
  const tabStore = useContext(TabStore);
  const auth = useContext(AuthStore);
  const { Panel } = Collapse;
  const useChangedRows = useSaveChangedRows();
  const itemSearch = useRef(null);
  // const locationWiseStock1 = item?.locations
  const shortguid = useShortguid();

  useEffect(() => {
    var tabsArray = tabStore.state.tabs;
    for (let i = 0; i < tabsArray?.length; i++) {
      if (tabsArray[i].name == "TR035") {
        setPageIndex(i);
        break;
      }
    }
  }, []);

  const payload = {
    tenantrecno: auth?.state?.userdata?.tenantrecno,
    domainrecno: auth?.state?.userdata?.domainrecno,
    userrolerecno: auth?.state?.userdata?.userrolerecno,
    domainuserrecno: auth.state.userdata.recno,
    fastpath: currentTabData(tabStore)?.name,
  };

  useEffect(() => {
    // Get Fast ath API
    getfastpathapi(payload).then((res) => {
      if (res?.Success) {
        setPageDetails(res?.Message);
      }
    });
  }, []);

  useEffect(() => {
    // When Row Data Changes we Need to store that data to the tabsStore with Header Data
    var existingObject = useChangedRows.getValue();

    // var shortguid = existingObject?.shortguid
    //   ? existingObject?.shortguid
    //   : shortguid?.getShortguid("ST");
    console.log("new/old shortguid is",shortguid)
    console.log("existingObject", existingObject, "item", tableData, ...tableData)
    existingObject = [
      ...tableData,
      // shortguid= shortguid
    ];
    console.log("final object is ", existingObject);

    useChangedRows.updateValue(false, existingObject);

        // When Row Data Changes we Need to store that data to the tabsStore with Header Data
        var currentData = useChangedRows.getValue();
        // if (!currentData?.shortguid) {
          

        
        // }
        useChangedRows.updateValue("shortguid", shortguid?.getShortguid("ST"));
        console.log("!currentData?.shortguid",!currentData?.shortguid,currentData?.shortguid, currentData,tabStore)
  }, [tableData]);

  // useEffect(() => {
  //   // When Row Data Changes we Need to store that data to the tabsStore with Header Data
  //   var currentData = useChangedRows.getValue();
  //   if (!currentData?.shortguid) {
  //     useChangedRows.updateValue("shortguid", shortguid.getShortguid("ST"));
  //   }
  // }, []);

  useEffect(() => {
    if (tabStore.state.tabs[pageIndex]?.pageStatus == "Success") {
      console.log("tabStore.state.tabs[pageIndex]?.pageStatus====>>>>>>>>", tabStore.state.tabs[pageIndex]?.pageStatus);
      setTableData([]);
      setCount(0);
      setItem(null);
      setselectedItem(null);
      setLocationData([]);
      setScannedBarcode("");
      settoLocation(null);
      setTimeout(
        tabStore.dispatch({
          type: "UPDATE_DATA",
          fieldType: "pageStatus",
          text: null,
        }),
        1000
      );
    }
  }, [tabStore.state.tabs[pageIndex]?.pageStatus, Object.keys(currentTabData(tabStore)?.changedRows).length]);

  useEffect(() => {
    let isButtonDisabled = false;

    useChangedRows.updateValue(
      "validated",
      !isButtonDisabled ? "true" : "false"
    );
    if (tableData.length == 0) {
      useChangedRows.updateValue("validated", "false");
    }
  }, [tableData])

  useEffect(() => {
    if (selectedItem) {
      handleGetItemLocationData(selectedItem);
    }
  }, [selectedItem])

  const showModal = () => {
    setModalVisible(true);
  };

  const handleModalOk = () => {
    // Handle the action when modal is confirmed
    setModalVisible(false);
  };

  const handleModalCancel = () => {
    // Handle the action when modal is cancelled
    setModalVisible(false);
  };

  console.log("Dropdown ReturnValue item", item?.availablestock, item);

  const handleBarcodeScan = () => {
    // Logic to parse and match barcode with item code and serial number
    const itemCode = scannedBarcode.substring(0, 10); // Extract item code
    const serialNo = scannedBarcode.substring(10, 20); // Extract serial number

    // Check if scanned item code matches selected item code
    if (item.itemcode === itemCode) {
      // Find the location of the serial number in the location data
      const foundLocation = Object.keys(locationData).find(location =>
        locationData[location].some(item => item.serialno === serialNo)
      );

      if (foundLocation) {
        // Update location data to select only the scanned serial number
        const updatedLocationData = { ...locationData };
        updatedLocationData[foundLocation] = updatedLocationData[foundLocation].map(item => ({
          ...item,
          checked: item.serialno === serialNo || item.checked // Preserve checked state of previously scanned items
        }));
        setLocationData(updatedLocationData);
        setScannedBarcode("");
      } else {
        alert('Serial number not found in stock.');
        setScannedBarcode("");
      }
    } else {
      alert('Scanned item code does not match selected item code.');
      setScannedBarcode("");
    }
  };

  const handleGetItemLocationData = (item) => {
    const payload = {
      tenantrecno: auth?.state?.userdata?.tenantrecno,
      domainrecno: auth?.state?.userdata?.domainrecno,
      details: true,
      withlocation: true,
      itemrecno: item?.itemrecno
    }
    getDomainItemListData(payload)
      .then((res) => {
        console.log("ressssssssssssssss", res?.Message[0])
        setItem(res?.Message[0]);
        setlocationWiseStock1(res?.Message[0]?.locations)
      })
      .catch(err => console.error(err))
  }

  const handleCheckboxChange = (e, location, serialno) => {
    console.log("handleCheckboxChangeForBatch", locationData, locationData[location])
    const updatedLocationData = { ...locationData };
    if (serialno) {
      const index = updatedLocationData[location].findIndex(item => item.serialno === serialno);
      updatedLocationData[location][index].checked = e.target.checked;
    } else {
      // Update checked state based on individual checkbox
      updatedLocationData[location].forEach(item => {
        if (item.serialno) {
          // Only update checked state if serialno exists
          item.checked = e.target.checked;
        }
      });
    }
    setLocationData(updatedLocationData);
  };

  const handleCheckboxChangeForBatch = (e, location, batchNo) => {
    console.log("Location:", location);
    console.log("Batch Number:", batchNo, locationData[location], locationData);

    const updatedLocationData = { ...locationData };

    // Check if the location exists
    if (updatedLocationData[location]) {
      // Scenario 1: When the location checkbox is selected
      if (!batchNo) {
        const batches = updatedLocationData[location];
        Object.keys(batches).forEach(batchKey => {
          const batch = batches[batchKey];
          batch.checked = e.target.checked;
          // Check if the batch has locationData
          if (batch.locationData) {
            batch.locationData.forEach(item => {
              item.checked = e.target.checked;
            });
          }
        });
      } else {
        // Scenario 2: When a batch checkbox is selected
        // Check if the batch exists
        if (updatedLocationData[location][batchNo]) {
          const batch = updatedLocationData[location][batchNo];
          batch.checked = e.target.checked;
          // Check if the batch has locationData
          if (batch.locationData) {
            batch.locationData.forEach(item => {
              item.checked = e.target.checked;
            });
          }
        }
      }

      setLocationData(updatedLocationData);
    } else {
      console.error("Location not found:", location);
    }
  };

  const handleQuantityChange = (value, batchNo, location) => {
    // Ensure that the locationData object and the batchNo key exist before accessing properties
    if (locationData && locationData[location] && locationData[location][batchNo]) {
      // Update the quantity and checked state in the locationData state based on the input value
      const updatedLocationData = { ...locationData };
      const totalQty = updatedLocationData[location][batchNo].qty;
      updatedLocationData[location][batchNo] = {
        ...updatedLocationData[location][batchNo],
        requiredQty: value, // Update requiredQty directly with the input value
        checked: value > 0 // Update checked state based on the input value
      };
      setLocationData(updatedLocationData);
    }
  };

  const handleDropdownReturnValue = (e) => {
    console.log("handleDropdownReturnValue: ", e);
    setLocationData([]);
    // Merge the item returned from dropdown with locationWiseStock
    // const newItem = { ...e, itembatcharrayfound: locationWiseStock };
    // setItem(newItem);
    setselectedItem(e);
  };

  // // Function to group items by location and remove items with the same serial number
  // const groupItemsByLocation = () => {
  //   const groupedItems = {};
  // const uniqueSerialNumbers = new Set(); // Set to store unique serial numbers

  // // Iterate through locationWiseStock1
  // locationWiseStock1.forEach(stock => {
  //   let serialNo = stock.serialno;
  //   if (serialNo !== null) {
  //     serialNo = serialNo.trim(); // Trim serial number if it's not null
  //   }

  //   // Check if serial number is already included and not null
  //   if (serialNo !== null && !uniqueSerialNumbers.has(serialNo)) {
  //     uniqueSerialNumbers.add(serialNo); // Add serial number to set
  //     if (!groupedItems[stock.locationdescn]) {
  //       groupedItems[stock.locationdescn] = [];
  //     }
  //     groupedItems[stock.locationdescn].push({ ...stock, checked: false });
  //   }
  // });

  //   setLocationData(groupedItems);
  // };

  const groupItemsByLocation = () => {
    const groupedItems = {};
    if (item?.itemasbatch === 0 && item?.wantbatchno === 1) {
      // Group batches by location and batch number
      locationWiseStock1.forEach(stock => {
        if (!groupedItems[stock.locationdescn]) {
          groupedItems[stock.locationdescn] = {};
        }
        if (!groupedItems[stock.locationdescn][stock.itembatchno]) {
          groupedItems[stock.locationdescn][stock.itembatchno] = {
            qty: stock.qty,
            locationData: []
          };
        } else {
          groupedItems[stock.locationdescn][stock.itembatchno].qty += stock.qty;
        }
        groupedItems[stock.locationdescn][stock.itembatchno].locationData.push({ ...stock, checked: false });
      });
    } else {
      // const uniqueSerialNumbers = new Set(); // Set to store unique serial numbers

      // // Iterate through locationWiseStock1
      // locationWiseStock1.forEach(stock => {
      //   let serialNo = stock.serialno;
      //   if (serialNo !== null) {
      //     serialNo = serialNo.trim(); // Trim serial number if it's not null
      //   }

      //   // Check if serial number is already included and not null
      //   if (serialNo !== null && !uniqueSerialNumbers.has(serialNo)) {
      //     uniqueSerialNumbers.add(serialNo); // Add serial number to set
      //     if (!groupedItems[stock.locationdescn]) {
      //       groupedItems[stock.locationdescn] = [];
      //     }
      //     groupedItems[stock.locationdescn].push({ ...stock, checked: false });
      //   }
      // });
      // Iterate through locationWiseStock1
      locationWiseStock1.forEach(stock => {
        const { locationdescn, serialno } = stock;

        // Trim the serial number if it's not null
        const trimmedSerialNo = serialno ? serialno.trim() : null;

        // Check if the location already exists in groupedItems
        if (!groupedItems[locationdescn]) {
          groupedItems[locationdescn] = [];
        }

        // Check if the serial number is unique within the current location
        const isUniqueSerialNo = !groupedItems[locationdescn].some(item => item.serialno === trimmedSerialNo);

        if (trimmedSerialNo !== null && isUniqueSerialNo) {
          // Push the item with unique serial number into the location's array
          groupedItems[locationdescn].push({ ...stock, checked: false });
        }
      });

      // Filter out any items that might have been added incorrectly
      Object.keys(groupedItems).forEach(location => {
        groupedItems[location] = groupedItems[location].filter((item, index, self) => {
          return self.findIndex(t => t.serialno === item.serialno) === index;
        });
      });

    }
    setLocationData(groupedItems);
  };

  const handleEnterPress = (e) => {
    if (e.key === 'Enter') {
      handleBarcodeScan();
    }
  };

  const columns = [
    {
      title: 'Item Name',
      dataIndex: 'itemdescn',
      key: 'itemdescn',
    },
    {
      title: 'Item Batch No',
      dataIndex: 'itembatchno',
      key: 'itembatchno',
      render: (text) => text ?? '.', // Assuming itembatchno always shows '.'
    },
    {
      title: 'Serial No',
      dataIndex: 'serialno',
      key: 'serialno',
    },
    {
      title: 'From Location',
      dataIndex: 'fromlocationdescn',
      key: 'fromlocationdescn',
      render: (text) => text ?? '', // Handling null or undefined values
    },
    {
      title: 'Debit Qty',
      dataIndex: 'drqty',
      key: 'drqty',
    },
    {
      title: 'To Location',
      dataIndex: 'tolocationdescn',
      key: 'tolocationdescn',
      render: (text) => text ?? '', // Handling null or undefined values
    },
    {
      title: 'Credit Qty',
      dataIndex: 'crqty',
      key: 'crqty',
      render: (text) => text ?? "", // Assuming crqty always shows 1
    },
  ];

  const addToTable = () => {
    const newData = [];
    console.log("Item as Batch:", item?.itemasbatch, "Want Batch No:", item?.wantbatchno, item);

    if (item?.itemasbatch === 0 && item?.wantbatchno === 1) {
      Object.keys(locationData).forEach(location => {
        Object.keys(locationData[location]).forEach(batchNo => {
          const batchData = locationData[location][batchNo];

          if (batchData.checked && batchData.requiredQty > 0) {
            console.log("Adding to Table: ", batchData.locationData[0].locationrecno);
            const fromlocationrecno = batchData.locationData[0]?.locationrecno;
            const fromlocationdescn = batchData.locationData[0]?.locationdescn;

            newData.push({
              domainrecno: auth?.state?.userdata?.domainrecno,
              itemrecno: item.itemrecno,
              itemdescn: item?.itemdescn,
              itembatchno: batchNo,
              serialno: "", // No serial number for item batches
              crqty: batchData.requiredQty,
              drqty: batchData.requiredQty, // Use the requiredQty for the debit quantity
              fromlocationrecno: fromlocationrecno ? fromlocationrecno : 0,
              fromlocationdescn: fromlocationdescn,
              tolocationrecno: tolocation?.locationrecno,
              tolocationdescn: tolocation?.descn,
              shortguid: uuidv4()
              // shortguid.getShortguid("STF" + item.itemrecno),
            });
          }
        });
      });
    }
    else {
      Object.keys(locationData).forEach(location => {
        locationData[location].forEach(item => {
          if (item.checked) {
            console.log("Adding To table: ", item)
            newData.push({
              domainrecno: auth?.state?.userdata?.domainrecno,
              itemrecno: item.itemrecno,
              itemdescn: item?.itemdescn,
              itembatchno: item?.itembatchno,
              serialno: item.serialno,
              crqty: 1,
              drqty: 1, //item.qty,
              fromlocationrecno: item.locationrecno ? item.locationrecno : 0,
              fromlocationdescn: item?.locationdescn,
              tolocationrecno: tolocation?.locationrecno,
              tolocationdescn: tolocation?.descn,
              shortguid: shortguid.getShortguid("STF" + item.serialno),
              // key: uuidv4() // Generate a unique key for each table row
            });
          }
        });
      });
    }

    console.log("New Data:", newData);

    setTableData([...tableData, ...newData]);
    setCount((p) => p + 1);
    setItem(null);
    setselectedItem(null);
    setLocationData([]);
    setScannedBarcode("");
    settoLocation(null);
  };

  return (
    <div style={{ ...mainFrame }}>
      <Collapse collapsible="header" defaultActiveKey={["1"]}>
        <Panel header="Header Details" key="1">
          <div>
            <form
              style={{
                display: "flex",
                flexWrap: "wrap",
                columnGap: "40px",
                rowGap: "20px",
                marginTop: "5px"
              }}
            >
              <div style={{ zIndex: 100 }}>
                <ItemSearch
                  inputRef={itemSearch}
                  forFlag={"D"}
                  returnValue={setselectedItem}
                  // supplierdata={customerData?.customerrecno} //~% prev supplierdata?.supplierrecno
                  dropdown
                  defaultSelectedItem={selectedItem}
                  forPage={"PO"}
                />
                {selectedItem ? (
                  <p>
                    {selectedItem?.itemdescn} <br />
                    {/* Available Qty : {getSelectedItem?.availablestock} &nbsp;
                  Pending PO Qty : {getSelectedItem?.pendingpoqty} &nbsp;
                  Pending SO Qty : {getSelectedItem?.pendingsoqty}{" "} */}
                  </p>
                ) : null}
              </div>
              {/* <div style={{ zIndex: 100 }}>
                <SaveDropdown
                  displayLabel={"Item Name"}
                  placeholder={"Search Item Name"}
                  apiurl={getDomainItemListURL}
                  apikey={"item"}
                  selectionLabel={"itemdescn"}
                  selectionValue={"itemrecno"}
                  defaultSelected={selectedItem}
                  // body={{ details: true, withlocation: true }}
                  returnValue={handleDropdownReturnValue}
                  key={count}
                />
              </div> */}
              <div>
                <div style={{ position: "relative" }}>
                  <label
                    className={`label ${'focused'}`}
                    style={{
                      zIndex: 1000,
                      whiteSpace: "nowrap",
                      wordWrap: "unset",
                      position: 'absolute',
                      top: '-19px',
                      left: '12px',
                      padding: '0 4px',
                      fontSize: '12px',
                      color: 'black',
                      pointerEvents: 'none',
                      transform: 'translateY(0)',
                      transition: 'top 0.3s, transform 0.3s, font-size 0.3s, color 0.3s'
                    }}
                  >
                    Available Qty
                  </label>
                  <input
                    type="number"
                    style={{ ...inputStyles, width: "100px" }}
                    value={item?.availablestock || ""}
                    readOnly
                  />
                </div>
              </div>
              <div>
                <Button
                  disabled={!item}
                  onClick={() => {
                    showModal();
                    console.log("show modal", locationData.length === 0, item, locationData);
                    if (locationData.length === 0) {
                      groupItemsByLocation();
                    }
                  }}>
                  +
                </Button>
              </div>
              <div>
                <SaveDropdown
                  displayLabel={"To Location"}
                  placeholder={"Search To Location"}
                  apiurl={getdomainlocationlistactive}
                  selectionLabel={"descn"}
                  selectionValue={"locationcode"}
                  apikey={"tolocationrecno"}
                  defaultSelected={tolocation}
                  // disabled={text?.item?.itemasbatch === 1}
                  returnValue={(e) => {
                    console.log("return value", e);
                    settoLocation(e);
                  }}
                  key={count}
                />
              </div>
              <div>
                <Button
                  disabled={!item || !tolocation || !locationData}
                  onClick={addToTable}>
                  ADD
                </Button>
              </div>
            </form>
          </div>
        </Panel>
        <Panel header="Stock Details" key="2">
          <div>
            <Table
              dataSource={tableData}
              columns={columns}
              size="small"
              className="tr001-purchaseOrder-itemsTable"
              bordered
              pagination={false}
              scroll={{
                y: 300,
                x: 500,
              }}
            />
          </div>
        </Panel>
      </Collapse>
      <Modal
        title="Location Wise Stock"
        visible={modalVisible}
        onOk={handleModalOk}
        onCancel={handleModalCancel}
        // style={{height:1000}}
        width={400}
      >
        {locationWiseStock1 && item?.itemasbatch === 0 && item?.wantbatchno === 1 ? (
          <div style={{ overflowY: 'auto', maxHeight: '300px', paddingTop: "20px" }}>
            {/* Render item batch numbers along with their quantities and quantity input */}
            {Object.keys(locationData).map((location) => (
              <div key={location} style={{ paddingTop: "10px", }}>
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", paddingTop: "5px", border: "2px solid #dfdfdf", borderRadius: "5px 5px 0px 0px", paddingLeft: "2px" }}>
                  <h3>{location}</h3>
                  { /* <label>
                    Select All
                    <Checkbox
                      style={{ margin: "0 0 5px 15px", marginRight: "5px" }}
                      checked={Array.isArray(locationData[location]) ? locationData[location].every(batch => batch.checked) : locationData[location]?.checked}
                      onChange={(e) => {handleCheckboxChangeForBatch(e, location)}}
                    >

                    </Checkbox>
            </label> */}

                </div>
                {Object.keys(locationData[location]).map((batchNo) => {
                  const batchData = locationData[location][batchNo];
                  return (
                    <div key={batchNo} style={{ border: "2px solid #dfdfdf", borderRadius: "0px 0px 5px 5px" }}>
                      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", margin: "4px" }}>
                        <span>{`Batch No: ${batchNo} (Qty: ${batchData.qty})`}</span>
                        <InputNumber
                          min={0}
                          max={batchData.qty} // Set the maximum value to the available quantity
                          defaultValue={0}
                          value={batchData.requiredQty || 0} // Set the default value to 0
                          onChange={(value) => handleQuantityChange(value, batchNo, location)}
                        />
                        {/* <Checkbox
                          checked={batchData.checked}
                          onChange={(e) => {
                            handleCheckboxChangeForBatch(e, location, batchNo)
                          }}
                        /> */}
                      </div>
                    </div>
                  );
                })}
              </div>
            ))}
          </div>
        ) : locationWiseStock1 ? (
          <div>
            <div>
              <Input
                value={scannedBarcode}
                onChange={(e) => setScannedBarcode(e.target.value)}
                onKeyPress={handleEnterPress}
                placeholder='Scan Barcode'
              />
              {/* <Button onClick={handleBarcodeScan}>Scan</Button> */}
            </div>
            <div style={{ overflowY: 'auto', maxHeight: '300px', paddingTop: "20px", }}>
              {Object.keys(locationData).map((location) => (
                <div key={location} style={{ paddingTop: "10px", }}>
                  <h3 style={{ paddingTop: "5px", border: "2px solid #dfdfdf", borderRadius: "5px 5px 0px 0px", paddingLeft: "2px" }}>
                    <Checkbox
                      style={{ float: 'right', paddingRight: "5px" }}
                      checked={Array.isArray(locationData[location]) ? locationData[location].every(item => item.checked) : locationData[location]?.checked}
                      onChange={(e) => handleCheckboxChange(e, location)}
                    />
                    <span>{location}</span>
                  </h3>
                  {Array.isArray(locationData[location]) && (
                    <List
                      style={{ border: "2px solid #dfdfdf", borderRadius: "0px 0px 5px 5px" }}
                      dataSource={locationData[location]}
                      renderItem={item => (
                        <List.Item
                        // style={{backgroundColor : item.checked ? "#dfdfdf" : ""}}
                        >
                          <p style={{ paddingLeft: "15px" }}>{item.serialno}</p>
                          {item.serialno && ( // Only render checkbox if serialno exists
                            <Checkbox
                              style={{ float: 'right', paddingRight: "5px" }}
                              checked={item.checked}
                              onChange={(e) => handleCheckboxChange(e, location, item.serialno)}
                            />
                          )}
                        </List.Item>
                      )}
                    />
                  )}
                </div>
              ))}
            </div>
          </div>
        ) : (
          <h1>No Location Available for Selected Item</h1>
        )
        }
      </Modal>
    </div>
  );
};

export default StockExchageNew;

const mainFrame = {
  width: "99vw",
  margin: "auto",
};

const inputStyles = {
  padding: "7px",
  border: "1px solid #dfdfdf",
  width: "90px",
  fontSize: "16px",
  marginTop: "2px",
  borderRadius: "8px",
  textAlign: "right",
  height: "36px",
};

import React, { useContext, useEffect, useState } from 'react'
import { TabStore } from '../../TabsStructure/TabsStore';
import { getfastpathapi } from '../../CommonControllers/ApiCalls';
import FilterComponents from '../../CommonControllers/FiterComponent/FilterComponents';
import useExecute from '../../CommonControllers/Hooks/useExecute';
import { AuthStore } from '../../Auth/AuthStore';
import TableComponent from '../../CommonControllers/Components/TableComponent/TableComponent';

const ApiLogs = ({fastPath}) => {
    const tabStore = useContext(TabStore);
    const auth = useContext(AuthStore);
    const [filterOptions, setFilterOptions] = useState([]);
    const [ReportName, setReportName] = useState([]);
    const [open, setOpen] = useState(false);
    const [filterprinting, setFilterprinting] = useState([]);
    const [filterdropdown, setFilterdropdown] = useState("");
    const execute = useExecute();
    
  //------loading-----------------------
  const [showTable, setShowTable] = useState(true);
  const [filterDictionary, setFilterDictionary] = useState([]);

  const [pageIndex, setPageIndex] = useState();
//   console.log("fastPath",fastPath,tabStore.state.tabs[pageIndex]?.name)
  useEffect(() => {
    var tabsArray = tabStore.state.tabs;
    for (let i = 0; i < tabsArray?.length; i++) {
      if (tabsArray[i].name == fastPath) {
        setPageIndex(i);
        break;
      }
    }
  }, []);

  useEffect(() => {
    const payload = {
      tenantrecno: auth?.state?.userdata?.tenantrecno,
      domainrecno: auth?.state?.userdata?.domainrecno,
      userrolerecno: auth?.state?.userdata?.userrolerecno,
      fastpath: tabStore.state.tabs[pageIndex]?.name?tabStore.state.tabs[pageIndex]?.name:fastPath,
    };

    execute.updateValue("forreport", "Y");

    // On component mount this api is called and filterOptions are set for that fast path
  //if(pageIndex){
    getfastpathapi(payload).then((res) => {
        if (res?.Success) {
          setFilterOptions(res?.Message?.filterOptions);
          //setReportName(res?.Message?.descn);
        }
      }).catch((err)=>console.log(err));
  
  }, []);
  return (
    <div>
   <div className="searchBox">
          <FilterComponents
            filterOption={filterOptions}
            filterOptionsProps={setFilterprinting}
            setfilterdropdown={setFilterdropdown}
            filterDictionary={filterDictionary}
            setFilterDictionary={setFilterDictionary}
            fastPath={fastPath}
          />
       <TableComponent pageIndex={pageIndex}/>
        </div>
    </div>
  )
}

export default ApiLogs
import React, { useState, useEffect, useContext } from "react";
import { useRef } from "react";
import { Button, Tooltip } from "antd";
import { BsSearch } from "react-icons/bs";
import { BsArrowLeftCircle, BsArrowRightCircle } from "react-icons/bs";
import { FaSpinner } from "react-icons/fa";
import {
  AiOutlineCloseCircle,
  AiOutlineHome,
  AiOutlinePrinter,
} from "react-icons/ai";
import "./Help.css";
import AboutBcore from "./HtmlFIles/AboutBcore";
import ReactDOMServer from "react-dom/server";
import PurposeOfHelp from "./HtmlFIles/PurposeOfHelp";
import GettingStarted from "./HtmlFIles/GettingStarted";
import CommonMasters from "./HtmlFIles/CommonMasters";
import Navigation from "./HtmlFIles/Navigation";
import LinkMasters from "./HtmlFIles/LinkMasters";
import MainMasters from "./HtmlFIles/MainMasters";
import RateCard from "./HtmlFIles/RateCard";
import noData from "../../Assets/noData.png";
import SetttingsAndParameter from "./HtmlFIles/SetttingsAndParameter";
import { gethelpdoc, getfastpathlist } from "../../CommonControllers/Urls";
import { Modal } from "antd";
import { checkFastPath } from "../../TabsStructure/tabFunctions";
import { TabStore } from "../../TabsStructure/TabsStore";
import FastPathTable from "./FastPathTable";

const Help = ({ onCloseModal }) => {
  const [response, setResponse] = useState([]);
  const [entry, setEntry] = useState("");
  const [modalKey, setModalKey] = useState(0);
  const [isOpen, setIsModalOpen] = useState(false);
  const [helpdata, sethelpdata] = useState([]);
  const [fastpathdata, setfastpathdata] = useState([]);
  const [searchEntry, setSearchEntry] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [indexed, setindexed] = useState();
  const [printindex, setprintindex] = useState();
  const [tagged, settagged] = useState("");
  const [filter, setFilter] = useState("");
  const [detail1, setDetails1] = useState("");
  const componentRef = useRef(null);
  const inputRef = useRef(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [SearchResultsCount, setSearchResultsCount] = useState(0);
  const [check, setCheck] = useState(false);
  const [trigger, setTrigger] = useState(false);
  const [fst, setFst] = useState("");
  const tabs = useContext(TabStore);
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleOpen = () => {
    setIsModalOpen(true);
    setModalKey(modalKey + 1);
  };

  const fastpathLists = fastpathdata?.Message?.map((item) => item.fastpath);
  console.log("fastpathLists:", fastpathLists);

  function OnSetPage(item, i) {
    console.log("FAST PATH", item, i);
    // setFst(item?.fastpath)
    checkFastPath(item?.fastpath, tabs);
    // setFst("")
    onCloseModal();
  }

  function formatApiResponse(response) {
    const paragraphs = helpdata.split("\n").map((paragraph) => {
      return `<p>${paragraph}</p>`;
    });

    return paragraphs.join("");
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const payload = {
          tenantrecno: 3,
          fastpath: "MS100",
        };
        const response = await fetch(gethelpdoc, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(payload),
        });

        const data = await response.json();
        console.log("data:", data);
        const descnWithLineBreaks = data?.Message[0].descn.replace(
          /\n/g,
          "<br/>"
        );
        sethelpdata(descnWithLineBreaks);
      } catch (err) {
        console.error("error:", err);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const payload = {
          tenantrecno: 3,
          domainrecno: 106251,
          userrolerecno: 44,
          fastpath: "FIND",
          descn: entry,
          // fastpathdata:fastpathdata,
        };
        console.log("payload:", payload);
        const response = await fetch(getfastpathlist, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(payload),
        });

        const data = await response.json();
        console.log("getfastpathlist:", data?.Message);
        setfastpathdata(data);
        setResponse(data?.Message);
        setprintindex(16);
        setindexed(16);
      } catch (err) {
        console.error("error:", err);
      }
    };

    fetchData();
  }, [entry]);

  console.log("response::", response);

  useEffect(() => {
    setTrigger((prevState) => !prevState);
  }, []);

  const handleTrigger = () => {
    // Trigger the re-rendering effect
    setTrigger((prevState) => !prevState);
    settagged(tagged);
  };
  console.log("check:", check);
  console.log("searchQuery:", searchQuery);
  console.log("filter", filter);

  const handleGoBack = () => {
    setSearchQuery("");
    handleTrigger();
    setCurrentPage(currentPage - 1);
  };
  const handleGoFront = () => {
    setSearchQuery("");
    handleTrigger();
    setCurrentPage(currentPage + 1);
  };

  const handleGoHome = () => {
    setSearchQuery("");
    handleTrigger();
    setCurrentPage(1);
  };

  const handleFastPath = () => {
    setCurrentPage(3);
    setprintindex(16);
    setindexed(16);
  };

  const handledetailpage = (tag, index) => {
    setCurrentPage(2);
    setindexed(index);
    setprintindex(index);
    settagged(tag);
    console.log("index:", index);
    console.log("tag:", tag);
    setFilter("");
  };
  console.log("indexed:", indexed);

  const handlePrint = (printindex) => {
    handleTrigger();
    console.log("printindex:", printindex);
    const content = ReactDOMServer.renderToStaticMarkup(
      printindex === 0 ? (
        <PurposeOfHelp />
      ) : printindex === 1 ? (
        <GettingStarted />
      ) : printindex === 2 ? (
        <AboutBcore />
      ) : printindex === 3 ? (
        <Navigation />
      ) : printindex === 4 ? (
        <CommonMasters />
      ) : printindex === 5 ? (
        <LinkMasters />
      ) : printindex === 6 ? (
        <MainMasters />
      ) : printindex === 14 ? (
        <SetttingsAndParameter />
      ) : printindex === 15 ? (
        <RateCard />
      ) : printindex === 16 ? (
        <FastPathTable
          response={response}
          OnSetPage={OnSetPage}
          ref={componentRef}
        />
      ) : null
    );
    const printWindow = window.open("", "", "height=800,width=800");
    printWindow.document.write(content);
    // printWindow.document.write('</body></html>');
    // printWindow.window.close();

    window.setTimeout(() => {
      printWindow.print();
      printWindow.close();
    }, 1000);
  };

  const tags = [
    "Purpose of help ",
    "Getting started  ",
    "About BCore",
    "Navigation",
    "Common masters",
    "Link masters",
    "Main masters",
    "Sales",
    "Purchase",
    "Accounts",
    "Inventory",
    "Reports",
    "Statements",
    "House keeping",
    "Settings & parameters",
    "Rate Card",
  ];

  const CommonMasterArray = [
    "MS001",
    "MS002",
    "MS003",
    "MS004",
    "MS005",
    "MS006",
    "MS007",
    "MS008",
    "MS009",
    "MS010",
    "MS011",
    "MS012",
    "MS014",
    "MS015",
    "MS016",
    "MS017",
    "MS020",
    "MS021",
    "MS022",
    "MS024",
    "MS025",
    "MS026",
    "MS027",
    "MS028",
    "MS029",
    "MS030",
    "MS031",
    "MS032",
    "MS033",
    "MS034",
    "MS067",
    "MS068",
    "MS085",
    "MS086",
    "MS087",
    "MS088",
    "MS089",
  ];
  const LinkMastersArray = [
    "MS101",
    "MS102",
    "MS103",
    "MS104",
    "MS105",
    "MS106",
    "MS107",
    "MS108",
    "MS109",
    "MS110",
    "MS116",
    "MS119",
    "MS134",
  ];
  const MainMastersArray = [
    "MS100",
    "MS111",
    "MS112",
    "MS113",
    "MS115",
    "MS117",
    "MS120",
    "MS121",
    "MS123",
    "MS128",
    "MS130",
    "MS131",
    "MS201",
    "MS298",
    "MS299",
    "MS300",
  ];
  const RateCardArray = ["MS064", "MS125", "MS124", "MS101"];
  console.log("currentPage:", tagged, currentPage);

  const filteredTags = tags.filter((tag) =>
    tag.toLowerCase().includes(filter.toLowerCase())
  );
  console.log("filteredTags:", filteredTags);
  const validateFront = () => {
    if (currentPage > 2) {
      return false;
    } else {
      return true;
    }
  };

  const validateBack = () => {
    if (currentPage > 1) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {}, [check]);

  let timeoutId;
  function handleSearchInputChange(event) {
    event.preventDefault();

    setSearchQuery(event.target.value);

    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    timeoutId = setTimeout(() => {
      if (event.target.value) {
        const result = window.find(
          event.target.value,
          false,
          false,
          true,
          false,
          true,
          false
        );
        setCheck(result);
        if (result) {
          const selection = window.getSelection();
          const range = selection.getRangeAt(0);
          const selectedText = range.toString();
          const textNode = document.createTextNode(selectedText);
          const highlight = document.createElement("span");
          highlight.classList.add("highlight");

          range.deleteContents();
          range.insertNode(textNode);
          range.surroundContents(highlight);
        }
        const count = document.querySelectorAll(".highlight").length;
        setSearchResultsCount(count);
        if (inputRef && inputRef.current) {
          inputRef.current.focus();
        }
      } else {
        const highlights = document.querySelectorAll(".highlight");
        highlights.forEach((highlight) => {
          highlight.outerHTML = highlight.innerHTML;
        });
        setSearchResultsCount(0);
      }
    }, 3000);
  }

  const handleSearchChange = (event) => {
    setSearchEntry(event.target.value);
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      const formattedSearchEntry = searchEntry.toUpperCase();
      if (CommonMasterArray.includes(formattedSearchEntry)) {
        setSearchEntry("");
        setindexed(4);
        setCurrentPage(2);
      } else if (LinkMastersArray.includes(formattedSearchEntry)) {
        setSearchEntry("");
        setindexed(5);
        setCurrentPage(2);
      } else if (MainMastersArray.includes(formattedSearchEntry)) {
        setSearchEntry("");
        setindexed(6);
        setCurrentPage(2);
      } else if (RateCardArray.includes(formattedSearchEntry)) {
        setSearchEntry("");
        setindexed(15);
        setCurrentPage(2);
      } else {
        alert("No fastpath found");
        setSearchEntry("");
      }
    }
  };
  const toolTipColor = "#2db7f5";

  return (
    <div className="helppage">
      <div className="buttons">
        <Tooltip placement="left" title="Go Back" color={toolTipColor}>
          <Button
            onClick={handleGoBack}
            disabled={!validateBack()}
            icon={<BsArrowLeftCircle />}
          ></Button>
        </Tooltip>
        <Tooltip title="Go Front" color={toolTipColor}>
          <Button
            onClick={handleGoFront}
            disabled={!validateFront()}
            icon={<BsArrowRightCircle />}
          ></Button>
        </Tooltip>
        <Tooltip title="Help Home" color={toolTipColor}>
          <Button onClick={handleGoHome} icon={<AiOutlineHome />}></Button>
        </Tooltip>
        <Tooltip title="Print" color={toolTipColor}>
          <Button
            onClick={(e) => {
              handlePrint(printindex);
            }}
            icon={<AiOutlinePrinter />}
          ></Button>
        </Tooltip>
        <Tooltip placement="right" title="Fastpath list" color={toolTipColor}>
          <Button onClick={handleFastPath} icon={<FaSpinner />}></Button>
        </Tooltip>
      </div>
      <div
      // style={{ marginTop: "5px",border:'1px solid green'}}
      >
        {currentPage === 1 ? (
          <div className="searchbox">
            <input
              placeholder="search for.."
              id="search"
              value={filter}
              onChange={(e) => {
                setFilter(e.target.value);
              }}
            />
            <div>
              <input
                style={{ marginTop: "-3px" }}
                type="text"
                value={searchEntry}
                onChange={handleSearchChange}
                placeholder="Enter fastpath here"
                onKeyPress={handleKeyPress}
                setCurrentPage
              />
            </div>
            {/* <Button onClick={()=>setIsModalOpen(true)}>MS100</Button> */}
            <Modal
              key={modalKey}
              style={{ marginTop: "-2%", padding: "2%", top: 40 }}
              bodyStyle={{ height: 500, overflowX: "scroll" }}
              width={1300}
              title="MS100"
              open={isOpen}
              onOk={handleOk}
              onCancel={handleCancel}
              okButtonProps={{ style: { display: "none" } }}
              cancelButtonProps={{ style: { display: "none", zIndex: 9999 } }}
              header={
                <div style={{ display: "flex", alignItems: "center" }}>
                  {/* <img src={Logo} alt="Logo" style={{ width: 40, height: 40 }}/> */}
                  <h3 style={{ marginBottom: 0 }}>My Modal</h3>
                </div>
              }
            >
              <p
                style={{ fontSize: "12px" }}
                dangerouslySetInnerHTML={{ __html: helpdata }}
              />
            </Modal>
          </div>
        ) : currentPage === 2 ? (
          <div style={{ display: "flex", marginTop: "5px" }}>
            <input
              id="search"
              type="text"
              value={searchQuery}
              // onKeyDown={onKeyPress}
              onChange={handleSearchInputChange}
              placeholder="Search for the term.."
              ref={inputRef}
            />
          </div>
        ) : null}
      </div>

      {currentPage === 1 ? (
        <div
          style={
            {
              // border:'2px solid red',
              // overflow:'scroll'
            }
          }
        >
          <div>
            <div>
              {filteredTags.length === 0 ? (
                <div style={{ margin: "auto", width: "50%" }}>
                  <h1>Oops! No Records Found</h1>
                  {/* <img style={{width:'30%'}}src={noData} /> */}
                </div>
              ) : (
                <div>
                  {/* <button onClick={setIsModalOpen(true)}>MS100</button> */}
                  <ul className="gridhelp">
                    {filteredTags.map((tag, index) => (
                      <li key={tag}>
                        <a onClick={() => handledetailpage(tag, index)}>
                          {tag}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          </div>
        </div>
      ) : currentPage === 2 ? (
        <div>
          {/* <h1>{tagged}</h1> */}
          <div className="gridhelp">
            {indexed === 0 ? (
              <div className="forprint" ref={componentRef}>
                <PurposeOfHelp />
              </div>
            ) : indexed === 1 ? (
              <div className="forprint" ref={componentRef}>
                <GettingStarted />
              </div>
            ) : indexed === 2 ? (
              <div className="forprint" ref={componentRef}>
                <AboutBcore />
              </div>
            ) : indexed === 3 ? (
              <div className="forprint" ref={componentRef}>
                <Navigation />
              </div>
            ) : indexed === 4 ? (
              <div className="forprint" ref={componentRef}>
                <CommonMasters />
              </div>
            ) : indexed === 5 ? (
              <div className="forprint" ref={componentRef}>
                <LinkMasters />
              </div>
            ) : indexed === 6 ? (
              <div className="forprint" ref={componentRef}>
                <MainMasters />
              </div>
            ) : indexed === 14 ? (
              <div className="forprint" ref={componentRef}>
                <SetttingsAndParameter />
              </div>
            ) : indexed === 15 ? (
              <div className="forprint" ref={componentRef}>
                <RateCard />
              </div>
            ) : indexed === 16 ? (
              <div className="forprint" ref={componentRef}>
                <FastPathTable
                  response={response}
                  OnSetPage={OnSetPage}
                  ref={componentRef}
                />
              </div>
            ) : null}
          </div>
        </div>
      ) : currentPage === 3 ? (
        <div>
          <div
            style={{
              position: "fixed",
              backgroundColor: "white",
              marginTop: "50px",
            }}
          >
            <label style={{ fontWeight: "bold",fontSize:"16px" }}>Enter the Page name</label>
            <br />
            <input
              style={{
                height: "40px", // Increase the height to make it more visible
                padding: "8px", // Add padding to provide some breathing space
                fontSize: "16px", // Adjust the font size for better readability
                border: "2px solid #ccc", // Add a border to the input box
                borderRadius: "4px", // Round the corners of the input box
               // boxShadow: "0 1px 2px rgba(0, 0, 0, 0.2)", // Add a subtle box-shadow for beautification
                outline: "none", // Remove the default outline on focus
                width: "100%", // Make the input box expand to fill the available width
                boxSizing: "border-box", // Ensure padding and border are included in the width
                marginTop:"5px"
              }}
              type="text"
              value={entry}
              onChange={(e) => setEntry(e.target.value)}
              placeholder="Search for Page Entry..."
            />
          </div>
          <div style={{ margin: "auto", width: "50%" }}>
            <div
              style={
                {
                  // border:'1px solid red',
                }
              }
            >
              <div
                style={{
                  // border:'1px solid red',
                  display: "inline-block",
                  marginTop: "10%",
                }}
              >
                <FastPathTable
                  response={response}
                  OnSetPage={OnSetPage}
                  ref={componentRef}
                />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <h2>No results found</h2>
      )}
    </div>
  );
};

export default Help;

import React, { useState, useRef, useEffect, useContext } from "react";

import { ExcelRenderer, OutTable } from "react-excel-renderer";
import * as XLSX from "xlsx";
import "./ItemRecieptwithDC.css";
import CommonMaster from "../CommonMaster";
import TableComponent from "../../CommonControllers/Components/TableComponent/TableComponent";
import { Table } from "antd";
import { TabStore } from "../../TabsStructure/TabsStore";
import { dateFormatter } from "../../CommonControllers/AppFunction";

function ItemRecieptwithDC() {
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  const [message, setMessage] = useState("");
  const tabStore = useContext(TabStore);
const [selectedOption,setSelectedOption]=useState(null)
  const[render,setRender]=useState(0)
  const [pageIndex, setPageIndex] = useState();
  //// here to chek the length of rowData
  useEffect(() => {
    var tabsArray = tabStore.state.tabs;
    for (let i = 0; i < tabsArray?.length; i++) {
      if (tabsArray[i].name == "TR057") {
        setPageIndex(i);
        break;
      }
    }
  }, []);

  useEffect(() => {
    let obj = {
      etldata: rows,
      // linkitems: selectedOption,
      additemreceiptdc: selectedOption == "additemreceiptdc" ? true : false,
      addopeningdc: selectedOption == "addopeningdc" ? true : false,
      // openingdate: purchaseInvDate,
      // ratecardrecno:tabStore.state.tabs[pageIndex]?.changedRows?.ratecardrecno
    };
    console.log("TESTING THE DATA",obj,selectedOption)
    if (rows) {
      tabStore.dispatch({
        type: "UPDATE_TABS_ARRAY",
        changedRowsValue: obj,
        tabsFastPath: "TR057",
        keyName: "changedRows",
      });
    }

  }, [rows,selectedOption]);
  useEffect(() => {
    if (tabStore.state.tabs[pageIndex]?.pageStatus == "Success") {
     setRows([])
     setColumns([])
     setTimeout(
      tabStore.dispatch({
        type: "UPDATE_DATA",
        fieldType: "pageStatus",
        text: null,
      }),
      1000
    );
    }
    
  }, [tabStore.state.tabs[pageIndex]?.pageStatus]);

  console.log("TabStore Data is", tabStore.state);

  const convertExcelDateToYYYYMMDD = (excelDate) => {
    console.log("Excel Date", excelDate)
    const serialDate = new Date(1900, 0, excelDate - 1); // 1900 because Excel incorrectly considers 1900 as a leap year
    const year = serialDate.getFullYear().toString();
    const month = (serialDate.getMonth() + 1).toString().padStart(2, "0");
    const day = serialDate.getDate().toString().padStart(2, "0");

    if (year?.toString().length > 4) {
     
      return null
    }
    console.log("Day is", serialDate, year?.toString().length, month, day)
    return `${year}${month}${day}`;

  };

  const uploadFile = (e) => {
    console.log("E is ", e);
    const file = e;

    if (file) {
      ExcelRenderer(file, (err, resp) => {
        if (err) {
          alert("An Error Occured while reading the Excel");
        } else {
          const { cols, rows } = resp;
          console.log("cols......", cols, rows);
          let mergedData = [];
          var requiredColumns = [
            "dcnumber",
            "dcdate",
            "customerzincode",
            "customerdescn",
            "itemdescn",
            "itemcode",
            "serialno",
            "expirydate",
            "barcodeserialno",
            "qty",
          ];

          for (let i = 0; i < rows.length; i++) {
            let rowData = {};
            for (let j = 0; j < requiredColumns.length; j++) {
              if (
                i != 0 &&
                (requiredColumns[j] == "dcdate" ||
                  requiredColumns[j] == "expirydate")
              ) {
                var convertedDate = convertExcelDateToYYYYMMDD(
                  rows[i][j]
                );
                if (!convertedDate) {
                  alert("An Error Occured while Adding the Excel, Update Date in Date Format")
                  return 
                }
                rowData[requiredColumns[j]] = convertedDate
              } else {
                rowData[requiredColumns[j]] = rows[i][j];
              }

              if (rowData[requiredColumns[j]] == undefined) {
                rowData[requiredColumns[j]] = ""
              }
            }
            mergedData.push(rowData);
          }

          console.log("Merge Data is", mergedData[0]);

          const columns = Object.keys(mergedData[0]).map((key) => ({
            title: mergedData[0][key],
            dataIndex: key,
            key,
            render : (_, text) => {
              console.log("TEXT IS",text, key, text?.key)
              if (key == "dcdate" || key == "expirydate") {
                return (
                  <p>{dateFormatter(text[key])}</p>
                )
              } else {

                return (
                  <p>{text[key]}</p>
                )
              }
            }
          }));
          setColumns(columns);
          setRows(mergedData.slice(1));
        }
      });
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const files = e.dataTransfer.files;
    if (files.length > 0) {
      // Assuming you have a function called handleFileUpload to handle the file
      uploadFile(files[0]);
      showMessage("File added successfully!");
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      // Assuming you have a function called handleFileUpload to handle the file
      uploadFile(file);
      showMessage("File added successfully!");
    }
  };
  const showMessage = (message) => {
    setMessage(message);
    setTimeout(() => {
      setMessage("");
    }, 3000); // Clear message after 3 seconds
  };

  console.log("Columns Are", columns);
  useEffect(()=>{
    setRender((p)=>p+1)
      },[tabStore?.state?.tabs[pageIndex]?.changedRows?.length])

      const handleRadioChange = (event) => {
        setSelectedOption(event.target.value);
      };
  return (
    <div className="container" key={render} >
            <div style={{margin:"20px"}}>
          <input
            type="radio"
            name="batchGroup"
            value="additemreceiptdc"
            checked={selectedOption === "additemreceiptdc"}
            onChange={handleRadioChange}
          />
          <span >Add with Item Receipt & DC</span>

          <input
          style={{marginLeft:"20px"}}
            type="radio"
            name="batchGroup"
            value="addopeningdc"
            checked={selectedOption === "addopeningdc"}
            onChange={handleRadioChange}
          />
          <span  >Add with Opening DC</span>
        </div>
      <div
        className={`drop-area ${message ? "active" : ""}`}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
      >
        <p className="message">{message || "Drag & Drop files here"}</p>
        <div className="or">or</div>
        <label htmlFor="fileInput" className="file-label">
          Select File
          <input
            type="file"
            accept=".csv,.xlsx,.xls"
            id="fileInput"
            className="file-input"
            onChange={handleFileChange}
          />
        </label>
        <div className="file-info" id="fileInfo"></div>
      </div>

      <div>
        <Table dataSource={rows} columns={columns} />
      </div>
    </div>
  );
}

export default ItemRecieptwithDC;

import { render } from "@testing-library/react";
import { Button, Popconfirm } from "antd";
import { useState } from "react";
import { AiFillDelete } from "react-icons/ai";
import {
  dateFormatter,
  removeByAttribute,
} from "../../CommonControllers/AppFunction";
import CallbackDateInput from "../../CommonControllers/Components/CallbackComponents/CallbackDateInput";
import "../../CommonControllers/global.css";
import { v4 as uuidv4 } from "uuid";
import ItemsTable from "./ItemsTable";

export const prColumns = (
  igstappl,
  setRowData,
  rowData,
  scandata,
  setScandata,
  setOpenItem,
  pageDetails
) => {
  // const [disabled, setdisabled] = useState(false)
  // const [boxqty, setboxqty] = useState(0)
  console.log("rowdata=====", rowData);
  console.log("igstappl", igstappl);
  // console.log("is igstappl", rowData.item.igstrate)
  // let packingextraqty = item?.packingextraqty-item?.currentreturnpackingextraqty
  if (igstappl) {
    return [
      {
        title: " ",
        dataIndex: "active",
        key: "active",
        width: 80,
        render: (_, text) => {
          return (
            <Popconfirm
              title="Do you want to Delete?"
              onConfirm={(e) => {
                handleRemove(text, setRowData, rowData);
              }}
            >
              <Button icon={<AiFillDelete />}></Button>
            </Popconfirm>
          );
        },
      },
      {
        title: "Item Description", // nohit-425 change title Item to Item Description
        dataIndex: "itemdescn",
        key: "itemdescn",
        width: 1000,
        align: "left", // nohit-388 set Align for column
        ellipsis: true, // Add ellipsis to truncate text
      },
      {
        title: "Batch",
        dataIndex: "itembatchno",
        key: "itembatchno",
        width: 300,
        align: "left", // nohit-388 set Align for column
        render: (_, text) => {
          return <p>{text?.itembatchno}</p>;
        },
      },
      /// task-id-242 rajendra nali one exp date is remove start ////

      // {
      //   title: "Exp Date",
      //   dataIndex: "expdate",
      //   key: "expdate",
      //   width: "15%",
      //   // render: (_, text) => {
      //   //   console.log("text", text)
      //   //   return (
      //   //     <CallbackDateInput
      //   //       id={text.shortguid}

      //   //     />
      //   //   )
      //   // }

      //   render: (_, text) => {
      //     return (
      //       <p>{dateFormatter(text?.expdate)}</p>
      //     )
      //   }
      // },
      /// task-id-242 rajendra nali one exp date is remove start ////
      {
        title: "Mfg Date",
        dataIndex: "manufacturedate",
        key: "manufacturedate",
        // width: "6%",
        width: 300,
        align: "left", // nohit-388 set Align for column
        render: (_, text) => {
          return <p>{dateFormatter(text?.manufacturedate)}</p>;
        },
      },
      {
        title: "Exp Date",
        dataIndex: "expdate",
        key: "expdate",
        // width: "6%",
        width: 300,
        align: "left", // nohit-388 set Align for column
        render: (_, text) => {
          return <p>{dateFormatter(text?.expdate)}</p>;
        },
      },
      {
        hidden: !pageDetails?.wantpackingextra ? true : false,
        title: "Boxes",
        dataIndex: "packingextraqty",
        key: "packingextraqty",
        width: 150,
        align: "right",
        // render: (_, text) => {
        //   return (
        //     <input
        //       className="tr001Input"
        //       defaultValue={text?.packingextraqty}
        //       onFocus={(e) => e.target.select()}
        //       onChange={(e) => {
        //         if (e.target.value < 0) {
        //           e.target.value = null;
        //           alert("Invalid Input, please re-enter non-negative value");
        //           return false;
        //         }
        //         const regex = /^[0-9\b]+$/;
        //         if (e.target.value === "" || regex.test(e.target.value)) {
        //           text.packingextraqty = e.target.value;
        //           handleChange(text, setRowData, igstappl, rowData);
        //         }
        //       }}
        //     />
        //   );
        // },
      },
      {
        title: "Pieces",
        dataIndex: "packingqty",
        key: "packingqty",
        width: 150,
        // render: (_, text) => {
        //   return (
        //     <input
        //       className="tr001Input"
        //       defaultValue={text.packingqty}
        //       onFocus={(e) => e.target.select()}
        //       onChange={(e) => {
        //         if (e.target.value < 0) {
        //           e.target.value = null;
        //           alert("Invalid Input, please re-enter non-negative value");
        //           return false;
        //         }
        //         const regex = /^[0-9\b]+$/;
        //         if (e.target.value === "" || regex.test(e.target.value)) {
        //           text.packingqty = e.target.value;
        //           handleChange(text, setRowData, igstappl, rowData);
        //         }
        //       }}
        //     ></input>
        //   );
        // },
        align: "right",
      },
      {
        hidden: pageDetails?.wantpackingextra && pageDetails?.wantfreeqty ? false : true,
        title: "Free Boxes",
        dataIndex: "freepackingextraqty",
        key: "freepackingextraqty",
        align: "right",
      },
      {
        hidden: !pageDetails?.wantfreeqty ? true : false,
        title: "Free Pieces",
        dataIndex: "freepackingqty",
        key: "freepackingqty",
        align: "right",
      },
      {
        hidden: !pageDetails?.wantpackingextra ? true : false,
        title: "Returnable Box Qty",
        dataIndex: "returnablepackingextraqty",
        key: "returnablepackingextraqty",
        align: "right", // nohit-388 set Align for column
        width: 150,
      },
      {
        title: "Returnable Pieces Qty",
        dataIndex: "returnablepackingqty",
        key: "returnablepackingqty",
        align: "right",
        width: 150,
      },
      {
        title: "Returnable Qty",
        dataIndex: "returnableqty",
        key: "returnableqty",
        align: "right",
        width: 150,
      },
      {
        hidden: !pageDetails?.wantfreeqty ? true : false,
        title: "Returnable freeQty",
        dataIndex: "returnablefree",
        key: "returnablefree",
        align: "right",
        width: 150,
      },
      {
        title: "Amount",
        dataIndex: "amount",
        key: "amount",
        align: "right",
        // width: "5%",
      },
      {
        hidden: !pageDetails?.wantpackingextra ? true : false,
        title: "Returned Boxes",
        dataIndex: "currentreturnpackingextraqty",
        key: "currentreturnpackingextraqty",
        align: "right", // nohit-388 set Align for column
        // width: "6%",
        render: (_, text) => {
          const maxValue = Number(
            (text.returnableqty + text.returnablefree) / text.conversionfactor
          );
          console.log("Returned Boxes maxValue", maxValue);
          // * Number(text.conversionfactor)
          return (
            // <input
            //   className={`tr001Input`}
            //   // defaultValue={text.packingextraqty}
            //   onFocus={(e) => e.target.select()}
            //   // onBlur={}
            //   min={0}
            //   max={Number(maxValue)}
            //   type="text"
            //   onChange={(e) => {
            //     if (e.target.value < 0) {
            //       // document.querySelector(`.${text?.shortguid}`).value = null;
            //       e.target.value = null;
            //       // alert("Invalid Input, please re-enter non-negative value");
            //       return false;
            //     }
            //     text.currentpackingextraqty = e.target.value;
            //     handleChange(text, setRowData, igstappl, rowData);
            //   }}
            // />
            <input
              className={`tr001Input`}
              defaultValue={text?.currentreturnpackingextraqty}
              //onFocus={(e) => e.target.select()}
              type="number"
              min={0}
              max={maxValue}
              onChange={(e) => {
                const inputValue = e.target.value;
                if (inputValue > maxValue) {
                  alert(
                    `Cannot return more than available quantity  ${maxValue}`
                  );
                } else if (inputValue > text.stock) {
                  alert(
                    `Cannot return more than available quantity  ${maxValue}`
                  );
                }
                if (inputValue < 0 || inputValue > maxValue) {
                  e.target.value = null;
                  return false;
                }
                text.currentreturnpackingextraqty = Number(inputValue);
                handleChange(text, setRowData, igstappl, rowData);
              }}
            />
          );
        },
      },
      {
        title: "Returned Pieces",
        dataIndex: "currentreturnpackingqty",
        key: "currentreturnpackingqty",
        align: "right", // nohit-388 set Align for column
        // width: "6%",
        render: (_, text) => {
          const maxValue = Number(
            Number(text.returnableqty ? text.returnableqty : 0) +
              Number(text.returnablefree ? text.returnablefree : 0) -
              Number(
                text.currentreturnpackingextraqty
                  ? text.currentreturnpackingextraqty
                  : 0
              ) *
                Number(text.conversionfactor)
          );
          // Number(text.packingextraqty) * Number(text.conversionfactor) + Number(text.packingqty);
          console.log(
            "item values",
            text.returnableqty,
            text.returnpackingextraqty,
            text.conversionfactor,
            Number(
              Number(text.returnableqty ? text.returnableqty : 0) +
                Number(text.returnablefree ? text.returnablefree : 0) -
                Number(
                  text.currentreturnpackingextraqty
                    ? text.currentreturnpackingextraqty
                    : 0
                ) *
                  Number(text.conversionfactor)
            )
          );
          const maxValue1 = 0;
          console.log("Returned Pieces in pr", maxValue);
          return (
            // <input
            //   className={`tr001Input`}
            //   defaultValue={text?.currentpackingqty}
            //   // onFocus={(e) => e.target.select()}
            //   type="text"
            //   min={0}
            //   max={maxValue}
            //   onChange={(e) => {
            //     const inputValue = e.target.value;
            //     if (inputValue < 0 || inputValue > maxValue) {
            //       e.target.value = null;
            //       return false;
            //     }
            //     text.currentpackingqty = e.target.value;
            //     handleChange(text, setRowData, igstappl, rowData);
            //   }}
            // />
            <input
              className={`tr001Input`}
              defaultValue={text?.currentreturnpackingqty}
              onFocus={(e) => e.target.select()}
              type="number"
              min={0}
              max={maxValue}
              onChange={(e) => {
                const inputValue = e.target.value;
                if (inputValue > maxValue) {
                  alert(
                    `Cannot return more than available quantity  ${maxValue}`
                  );
                } else if (inputValue > text.stock) {
                  alert(
                    `Cannot return more than available quantity  ${maxValue}`
                  );
                }
                if (inputValue < 0 || inputValue > maxValue) {
                  e.target.value = null;
                  return false;
                }
                text.currentreturnpackingqty = Number(inputValue);
                handleChange(text, setRowData, igstappl, rowData);
              }}
            />
          );
        },
      },
      {
        title: "Qty",
        dataIndex: "currentreturnqty",
        key: "currentreturnqty",
        align: "right", // nohit-388 set Align for column
        // width: "6%",
      },
      {
        hidden: !pageDetails?.wantfreeqty ? true : false,
        title: "Free Qty",
        dataIndex: "returnfree",
        key: "returnfree",
        align: "right", // nohit-388 set Align for column
        // render: (_, text) => {
        //   const maxValue = Number(text.packingextraqty) * Number(text.conversionfactor) + Number(text.packingqty);
        //   const maxValue1 = 0

        //   return (
        //     <input
        //       className={`tr001Input`}
        //       defaultValue={text?.returnpackingqty}
        //       // onFocus={(e) => e.target.select()}
        //       type="text"
        //     />
        //     // <p>{text.purrate}</p>
        //   );
        // },
      },
      {
        hidden: !pageDetails?.wantpackingextra ? true : false,
        title: "Box Rate",
        dataIndex: "boxpurrate",
        key: "boxpurrate",
        // width: "8%",
        align: "right",
      },
      {
        title: "Rate",
        dataIndex: "purrate",
        key: "purrate",
        width: 150,
        // render: (_, text) => {
        //   return (
        //     <input
        //       className="tr001Input"
        //       defaultValue={text.purrate}
        //       onFocus={(e) => e.target.select()}
        //       onChange={(e) => {
        //         if (e.target.value < 0) {
        //           e.target.value = null;
        //           alert("Invalid Input, please re-enter non-negative value");
        //           return false;
        //         }
        //         const regex = /^\d*(\.\d{0,2})?$/;
        //         text.purrate = e.target.value;
        //         if (e.target.value === "" || regex.test(e.target.value)) {
        //           handleChange(text, setRowData, igstappl, rowData);
        //         }
        //       }}
        //     ></input>
        //   );
        // },
        align: "right",
      },
      {
        title: "Discount%",
        dataIndex: "returndiscountrate",
        key: "returndiscountrate",
        render: (_, text) => {
          return (
            <input
              className={`tr001Input`}
              defaultValue={text?.returndiscountrate}
              onFocus={(e) => e.target.select()}
              type="number"
              min={0}
              max={100}
              disabled={text.returndiscountamt}
              onChange={(e) => {
                // const inputValue = e.target.value;
                // if (inputValue < 0 || inputValue > maxValue) {
                //   e.target.value = null;
                //   return false;
                // }
                text.returndiscountrate = e.target.value;
                handleChange(text, setRowData, igstappl, rowData);
              }}
            />
          );
        },
      },
      {
        title: "Discount",
        dataIndex: "returndiscountamt",
        key: "returndiscountamt",
        align: "right", // nohit-388 set Align for column
        render: (_, text) => {
          return (
            <input
              className={`tr001Input`}
              defaultValue={text?.returndiscountamt}
              onFocus={(e) => e.target.select()}
              type="number"
              min={0}
              disabled={text.returndiscountrate}
              // max={maxValue}
              onChange={(e) => {
                // const inputValue = e.target.value;
                // if (inputValue < 0 || inputValue > maxValue) {
                //   e.target.value = null;
                //   return false;
                // }
                text.returndiscountamt = e.target.value;
                handleChange(text, setRowData, igstappl, rowData);
              }}
            />
          );
        },
      },
      // task-id-261 rajendra here Reason input box is added in grid start ////
      {
        title: "Reason",
        dataIndex: "narration",
        key: "narration",
        align: "left", // nohit-388 set Align for column
        render: (_, text) => {
          return (
            <input
              className={`tr001Input`}
              //defaultValue={text?.packingqty}
              onFocus={(e) => e.target.select()}
              type="text"
              min={0}
              //disabled={text.returndiscountrate}
              // max={maxValue}
              onChange={(e) => {
                // const inputValue = e.target.value;
                // if (inputValue < 0 || inputValue > maxValue) {
                //   e.target.value = null;
                //   return false;
                // }
                text.narration = e.target.value;
                handleChange(text, setRowData, igstappl, rowData);
              }}
            />
          );
        },
      },
      // task-id-261 rajendra here Reason input box is added in grid end ////
      {
        title: "Taxable Amount",
        dataIndex: "currentreturntaxableamount",
        key: "currentreturntaxableamount",
        align: "right",
        // width: "9%",
      },
      {
        title: "IGST%",
        dataIndex: "igstrate",
        key: "igstrate",
        align: "right",
        // width: "4%",
      },
      {
        title: "IGST",
        dataIndex: "currentreturnigstamt",
        key: "currentreturnigstamt",
        align: "right",
        // width: "4%",
      },
      {
        title: "Returned Amount",
        dataIndex: "currentreturnamount",
        key: "currentreturnamount",
        align: "right",
        // width: "8%",
      },
      {
        width: 150,
        title: "Scanned Items",
        dataIndex: "scannedItems",
        align: "right",
        render: (_, text) => {
          return <p>{text?.itembatcharray?.length}</p>;
        },
      },
      {
        title: "Scan Barcode",
        dataIndex: "barcode",
        align: "left", // nohit-388 set Align for column
        key: "barcode",
        width: 150,
        render: (_, text) => {
          // console.log("Text is748788", text);
          if (text.itemasbatch) {
            return (
              <Button
                onClick={() => {
                  handleChange(
                    text,
                    setRowData,
                    igstappl,
                    rowData,
                    scandata,
                    setScandata,
                    setOpenItem
                  );
                  if (
                    text?.currentreturnpackingextraqty
                      ? text?.currentreturnpackingextraqty
                      : 0 >= 0 && text?.currentreturnpackingqty > 0
                  ) {
                    setOpenItem(true);
                  }
                  // setOpenItem(true);
                }}
                // disabled={text?.packingqtyaccepted !== 0 || text?.packingextraqtyaccepted !== 0}
              >
                Item Scan
              </Button>
            );
          } else return null;
        },
      },
    ];
  } else {
    return [
      // {
      //   title: "Del",
      //   dataIndex: "active",
      //   key: "active",
      //   width: "4%",
      //   render: (_, text) => {
      //     return <Button icon={<AiFillDelete />} onClick={() => handleRemove(text, setRowData, rowData)}></Button>;
      //   },
      // },
      {
        title: " ",
        dataIndex: "active",
        key: "active",
        width: 80,
        render: (_, text) => {
          return (
            <Popconfirm
              title="Do you want to Delete?"
              onConfirm={(e) => {
                handleRemove(text, setRowData, rowData);
                console.log("HII");
              }}
            >
              <Button icon={<AiFillDelete />}></Button>
            </Popconfirm>
          );
        },
      },
      {
        title: "Item Description", // nohit-425 change title Item to Item Description
        dataIndex: "itemdescn",
        key: "itemdescn",
        width: 1000,
        align: "left", // nohit-388 set Align for column
        ellipsis: true, // Add ellipsis to truncate text
      },
      {
        title: "Batch",
        dataIndex: "itembatchno",
        key: "itembatchno",
        align: "left", // nohit-388 set Align for column
        width: 400,
        render: (_, text) => {
          return (
            // <input
            //   className={`tr001Input tr001Batch`}
            //   defaultValue={text?.batch}
            //   type="number"
            //   onFocus={(e) => e.target.select()}
            //   onChange={(e) => {
            //     if (e.target.value < 0) {
            //       // document.querySelector(`.${text?.shortguid}`).value = null;
            //       e.target.value = null;
            //       // alert("Invalid Input, please re-enter non-negative value");
            //       return false;
            //     }
            //     const regex = /^[0-9\b]+$/;
            //     if (e.target.value === "" || regex.test(e.target.value)) {
            //       text.batch = e.target.value;
            //       handleChange(text, setRowData, igstappl, rowData);
            //     }
            //   }}
            // />
            <p>{text?.itembatchno}</p>
          );
        },
      },
      {
        title: "Mfg Date",
        dataIndex: "manufacturedate",
        key: "manufacturedate",
        // width: "6%",
        width: 300,
        align: "left", // nohit-388 set Align for column
        render: (_, text) => {
          return <p>{dateFormatter(text?.manufacturedate)}</p>;
        },
      },
      {
        title: "Exp Date",
        dataIndex: "expdate",
        key: "expdate",
        // width: "6%",
        width: 300,
        align: "left", // nohit-388 set Align for column
        render: (_, text) => {
          return <p>{dateFormatter(text?.expdate)}</p>;
        },
      },

      {
        hidden: !pageDetails?.wantpackingextra ? true : false,
        title: "Boxes",
        dataIndex: "packingextraqty",
        key: "packingextraqty",
        // width: "4%",
        align: "right",
        // render: (_, text) => {
        //   return (
        //     <input
        //       className={`tr001Input tr001Box`}
        //       defaultValue={text?.packingextraqty}
        //       type="number"
        //       onFocus={(e) => e.target.select()}
        //       onChange={(e) => {
        //         if (e.target.value < 0) {
        //           // document.querySelector(`.${text?.shortguid}`).value = null;
        //           e.target.value = null;
        //           // alert("Invalid Input, please re-enter non-negative value");
        //           return false;
        //         }
        //         const regex = /^[0-9\b]+$/;
        //         if (e.target.value === "" || regex.test(e.target.value)) {
        //           text.packingextraqty = e.target.value;
        //           handleChange(text, setRowData, igstappl, rowData);
        //         }
        //       }}
        //     />
        //     // <p>{text.box}</p>
        //   );
        // },
      },
      {
        title: "Pieces",
        dataIndex: "packingqty",
        key: "packingqty",
        // width: "4%",
        // render: (_, text) => {
        //   return (
        //     <input
        //       className={`tr001Input tr001Pieces`}
        //       defaultValue={text.packingqty}
        //       onFocus={(e) => e.target.select()}
        //       type="number"
        //       onChange={(e) => {
        //         if (e.target.value < 0) {
        //           e.target.value = null;
        //           // alert("Invalid Input, please re-enter non-negative value");
        //           return false;
        //         }
        //         const regex = /^[0-9\b]+$/;
        //         if (e.target.value === "" || regex.test(e.target.value)) {
        //           text.packingqty = e.target.value;
        //           handleChange(text, setRowData, igstappl, rowData);
        //         }
        //       }}
        //     />
        //     // <p>{text.qty}</p>
        //   );
        // },
        align: "right",
      },
      {
        hidden: pageDetails?.wantpackingextra && pageDetails?.wantfreeqty ? false : true,
        title: "Free Boxes",
        dataIndex: "freepackingextraqty",
        key: "freepackingextraqty",
        align: "right",
      },
      {
        hidden: !pageDetails?.wantfreeqty ? true : false,
        title: "Free Pieces",
        dataIndex: "freepackingqty",
        key: "freepackingqty",
        align: "right",
      },
      // {
      //   title: "Rate",
      //   dataIndex: "purrate",
      //   align: "right",
      //   key: "purrate",
      //   width: "8%",
      // render: (_, text) => {
      //   return (
      //     <input
      //       className={`tr001Input`}
      //       defaultValue={text.purrate}
      //       onFocus={(e) => e.target.select()}
      //       type="number"
      //       onChange={(e) => {
      //         if (e.target.value < 0) {
      //           // document.querySelector(`.${text?.shortguid}`).value = null;
      //           e.target.value = null;
      //           // alert("Invalid Input, please re-enter non-negative value");
      //           return false;
      //         }
      //         text.purrate = e.target.value;
      //         handleChange(text, setRowData, igstappl, rowData);
      //       }}
      //     />
      //     // <p>{text.purrate}</p>
      //   );
      // },
      // },
      // {
      //   title: "Taxable Amount",
      //   dataIndex: "taxableamount",
      //   key: "taxableamount",
      //   align: "righalign: "right",t",
      //   width: "9%",
      // },
      {
        hidden: !pageDetails?.wantpackingextra ? true : false,
        title: "Returnable Box Qty",
        dataIndex: "returnablepackingextraqty",
        key: "returnablepackingextraqty",
        align: "right", // nohit-388 set Align for column
        width: 150,
      },
      {
        title: "Returnable Pieces Qty",
        dataIndex: "returnablepackingqty",
        key: "returnablepackingqty",
        align: "right",
        width: 150,
      },
      {
        title: "Returnable Qty",
        dataIndex: "returnableqty",
        key: "returnableqty",
        align: "right", // nohit-388 set Align for column
        width: 150,
      },
      {
        hidden: !pageDetails?.wantfreeqty ? true : false,
        title: "Returnable freeQty",
        dataIndex: "returnablefree",
        key: "returnablefree",
        align: "right",
        width: 150,
      },
      {
        title: "Amount",
        dataIndex: "amount",
        key: "amount",
        align: "right",
        // width: "5%",
      },
      {
        hidden: !pageDetails?.wantpackingextra ? true : false,
        title: "Returned Boxes",
        dataIndex: "currentreturnpackingextraqty",
        key: "currentreturnpackingextraqty",
        // width: "6%",
        align: "right", // nohit-388 set Align for column
        render: (_, text) => {
          const maxValue = Number(
            (text.returnableqty + text.returnablefree) / text.conversionfactor
          );
          console.log("Returned Boxes maxValue", maxValue);
          // * Number(text.conversionfactor)
          return (
            // <input
            //   className={`tr001Input`}
            //   // defaultValue={text.packingextraqty}
            //   onFocus={(e) => e.target.select()}
            //   // onBlur={}
            //   min={0}
            //   max={Number(maxValue)}
            //   type="text"
            //   onChange={(e) => {
            //     if (e.target.value < 0) {
            //       // document.querySelector(`.${text?.shortguid}`).value = null;
            //       e.target.value = null;
            //       // alert("Invalid Input, please re-enter non-negative value");
            //       return false;
            //     }
            //     text.currentpackingextraqty = e.target.value;
            //     handleChange(text, setRowData, igstappl, rowData);
            //   }}
            // />
            <input
              className={`tr001Input`}
              //defaultValue={text?.packingreturnextraqty}
              //onFocus={(e) => e.target.select()}
              defaultValue={text?.currentreturnpackingextraqty}
              type="number"
              min={0}
              max={maxValue}
              onChange={(e) => {
                const inputValue = e.target.value;
                if (inputValue > maxValue) {
                  alert(
                    `Cannot return more than available quantity  ${maxValue}`
                  );
                } else if (inputValue > text.stock) {
                  alert(
                    `Cannot return more than available quantity  ${maxValue}`
                  );
                }
                if (inputValue < 0 || inputValue > maxValue) {
                  e.target.value = null;
                  return false;
                }
                text.currentreturnpackingextraqty = Number(inputValue);
                handleChange(text, setRowData, igstappl, rowData);
              }}
            />
          );
        },
      },
      {
        title: "Returned Pieces",
        dataIndex: "currentreturnpackingqty",
        key: "currentreturnpackingqty",
        // width: "6%",
        align: "right",
        render: (_, text) => {
          const maxValue = Number(
            Number(text.returnableqty ? text.returnableqty : 0) +
              Number(text.returnablefree ? text.returnablefree : 0) -
              Number(
                text.returnpackingextraqty ? text.returnpackingextraqty : 0
              ) *
                Number(text.conversionfactor)
          );
          // Number((Number(text.returnableqty ? text.returnableqty : 0) + Number(text.returnablefree ? text.returnablefree : 0)) - (Number(text.currentreturnpackingextraqty ? text.currentreturnpackingextraqty : 0) * Number(text.conversionfactor)))
          // Number(text.packingextraqty) * Number(text.conversionfactor) + Number(text.packingqty);
          console.log(
            "item values",
            text.returnableqty,
            text.currentpackingextraqty,
            text.conversionfactor,
            text
          );
          const maxValue1 = 0;
          console.log("returned Pieces maxValue =>>", maxValue);
          return (
            // <input
            //   className={`tr001Input`}
            //   defaultValue={text?.currentpackingqty}
            //   // onFocus={(e) => e.target.select()}
            //   type="text"
            //   min={0}
            //   max={maxValue}
            //   onChange={(e) => {
            //     const inputValue = e.target.value;
            //     if (inputValue < 0 || inputValue > maxValue) {
            //       e.target.value = null;
            //       return false;
            //     }
            //     text.currentpackingqty = e.target.value;
            //     handleChange(text, setRowData, igstappl, rowData);
            //   }}
            // />
            <input
              className={`tr001Input`}
              defaultValue={text?.currentreturnpackingqty}
              onFocus={(e) => e.target.select()}
              type="number"
              min={0}
              max={maxValue}
              onChange={(e) => {
                const inputValue = e.target.value;
                if (inputValue > maxValue) {
                  alert(
                    `Cannot return more than available quantity  ${maxValue}`
                  );
                } else if (inputValue > text.stock) {
                  alert(
                    `Cannot return more than available quantity  ${maxValue}`
                  );
                }
                if (inputValue < 0 || inputValue > maxValue) {
                  e.target.value = null;
                  return false;
                }
                text.currentreturnpackingqty = Number(inputValue);
                handleChange(text, setRowData, igstappl, rowData);
              }}
            />
          );
        },
      },
      {
        title: "Qty",
        dataIndex: "currentreturnqty",
        key: "currentreturnqty",
        align: "right", // nohit-388 set Align for column
        // width: "6%",
      },
      {
        hidden: !pageDetails?.wantfreeqty ? true : false,
        title: "Free Qty",
        dataIndex: "returnfree",
        key: "returnfree",
        align: "right", // nohit-388 set Align for column
        // render: (_, text) => {
        //   const maxValue = Number(text.packingextraqty) * Number(text.conversionfactor) + Number(text.packingqty);
        //   const maxValue1 = 0

        //   return (
        //     <input
        //       className={`tr001Input`}
        //       defaultValue={text?.returnpackingqty}
        //       // onFocus={(e) => e.target.select()}
        //       type="text"
        //     />
        //     // <p>{text.purrate}</p>
        //   );
        // },
      },
      {
        hidden: !pageDetails?.wantpackingextra ? true : false,
        title: "Box Rate",
        dataIndex: "boxpurrate",
        key: "boxpurrate",
        // width: "8%",
        align: "right",
      },
      {
        title: "Rate",
        dataIndex: "purrate",
        key: "purrate",
        // width: "8%",
        // render: (_, text) => {
        //   return (
        //     <input
        //       className="tr001Input"
        //       defaultValue={text.purrate}
        //       onFocus={(e) => e.target.select()}
        //       onChange={(e) => {
        //         if (e.target.value < 0) {
        //           e.target.value = null;
        //           alert("Invalid Input, please re-enter non-negative value");
        //           return false;
        //         }
        //         const regex = /^\d*(\.\d{0,2})?$/;
        //         text.purrate = e.target.value;
        //         if (e.target.value === "" || regex.test(e.target.value)) {
        //           handleChange(text, setRowData, igstappl, rowData);
        //         }
        //       }}
        //     ></input>
        //   );
        // },
        align: "right",
      },
      {
        title: "Discount%",
        dataIndex: "returndiscountrate",
        key: "returndiscountrate",
        align: "right", // nohit-388 set Align for column
        render: (_, text) => {
          return (
            <input
              className={`tr001Input`}
              defaultValue={text?.returndiscountrate}
              onFocus={(e) => e.target.select()}
              type="number"
              min={0}
              max={100}
              disabled={text.returndiscountamt}
              onChange={(e) => {
                // const inputValue = e.target.value;
                // if (inputValue < 0 || inputValue > maxValue) {
                //   e.target.value = null;
                //   return false;
                // }
                text.returndiscountrate = e.target.value;
                handleChange(text, setRowData, igstappl, rowData);
              }}
            />
          );
        },
      },
      {
        title: "Discount",
        dataIndex: "returndiscountamt",
        key: "returndiscountamt",
        align: "right", // nohit-388 set Align for column
        render: (_, text) => {
          return (
            <input
              className={`tr001Input`}
              defaultValue={text?.returndiscountamt}
              onFocus={(e) => e.target.select()}
              type="number"
              min={0}
              max={text.returntaxableamount}
              disabled={text.returndiscountrate}
              // max={maxValue}
              onChange={(e) => {
                // const inputValue = e.target.value;
                // if (inputValue < 0 || inputValue > maxValue) {
                //   e.target.value = null;
                //   return false;
                // }
                text.returndiscountamt = e.target.value;
                handleChange(text, setRowData, igstappl, rowData);
              }}
            />
          );
        },
      },
      // task-id-261 rajendra here Reason input box is added in grid start ////
      {
        title: "Reason",
        dataIndex: "narration",
        key: "narration",
        align: "left", // nohit-388 set Align for column
        render: (_, text) => {
          return (
            <input
              className={`tr001Input`}
              //defaultValue={text?.packingqty}
              onFocus={(e) => e.target.select()}
              type="text"
              min={0}
              //disabled={text.returndiscountrate}
              // max={maxValue}
              onChange={(e) => {
                // const inputValue = e.target.value;
                // if (inputValue < 0 || inputValue > maxValue) {
                //   e.target.value = null;
                //   return false;
                // }
                text.narration = e.target.value;
                handleChange(text, setRowData, igstappl, rowData);
              }}
            />
          );
        },
      },
      // task-id-261 rajendra here Reason input box is added in grid end ////
      {
        title: "Taxable Amount",
        dataIndex: "currentreturntaxableamount",
        key: "currentreturntaxableamount",
        align: "right",
        // width: "9%",
      },
      {
        title: "CGST%",
        dataIndex: "cgstrate",
        key: "csgtrate",
        align: "right",
        // width: "4%",
      },
      {
        title: "CGST",
        dataIndex: "currentreturncgstamt",
        key: "currentreturncgstamt",
        align: "right",
        // width: "4%",
      },
      {
        title: "SGST%",
        dataIndex: "sgstrate",
        key: "sgstrate",
        align: "right",
        // width: "4%",
      },
      {
        title: "SGST",
        dataIndex: "currentreturnsgstamt",
        key: "currentreturnsgstamt",
        align: "right",
        // width: "4%",
      },
      // {
      //   title: "IGST%",
      //   dataIndex: "igstrate",
      //   key: "igstrate",
      //   align: "right",
      //   // width: "4%",
      // },
      // {
      //   title: "IGST",
      //   dataIndex: "returnigstamt",
      //   key: "returnigstamt",
      //   align: "right",
      //   // width: "4%",
      // },
      {
        title: "Returned Amount",
        dataIndex: "currentreturnamount",
        key: "currentreturnamount",
        align: "right",
        // width: "8%",
      },
      {
        width: 150,
        title: "Scanned Items",
        dataIndex: "scannedItems",
        align: "right",
        render: (_, text) => {
          return <p>{text?.itembatcharray?.length}</p>;
        },
      },
      {
        title: "Scan Barcode",
        dataIndex: "barcode",
        align: "left", // nohit-388 set Align for column
        key: "barcode",
        width: 150,
        render: (_, text) => {
          console.log(
            "Text is748788",
            text,
            text?.currentreturnpackingextraqty
              ? text?.currentreturnpackingextraqty
              : 0 >= 0 && text?.currentreturnpackingqty > 0
          );
          if (text.itemasbatch) {
            return (
              <Button
                onClick={() => {
                  handleChange(
                    text,
                    setRowData,
                    igstappl,
                    rowData,
                    scandata,
                    setScandata,
                    setOpenItem
                  );

                  if (
                    text?.currentreturnpackingextraqty
                      ? text?.currentreturnpackingextraqty
                      : 0 >= 0 && text?.currentreturnpackingqty > 0
                  ) {
                    console.log("codition true");
                    setOpenItem(true);
                  }
                  // setOpenItem(true);
                }}
                // disabled={text?.packingqtyaccepted !== 0 || text?.packingextraqtyaccepted !== 0}
              >
                Item Scan
              </Button>
            );
          } else return null;
        },
      },
    ];
  }
};

export const handleAdd = (
  item,
  setRowData,
  igstappl,
  setSelectedItem,
  shortguid,
  purchaseRate,
  purchaseQty,
  box,
  batch,
  expDate
) => {
  if (item?.packingextraqty < 0 || item?.packingqty < 0 || item?.purrate < 0) {
    alert("Cannot enter 0");
    return;
  }
  console.log("tr004 handle add  ==>", item);
  //~# Input boxes validation
  const batchValue = document.querySelector("tr004-input-batch");
  const boxValue = document.querySelector("tr004-input-box");
  const piecesValue = document.querySelector("tr004-input-pieces");
  const rateValue = document.querySelector("tr004-input-rate");

  console.log("batchValue", batchValue);
  var newShortguid = shortguid.getShortguid("POF" + item.itemrecno);
  console.log("tr001 Added Shortguid", newShortguid, box, purchaseQty, purchaseRate,item?.conversionfactor);
  var purrate = purchaseRate ? purchaseRate : 0.0;
  var cgstrate = item?.cgstrate ? item.cgstrate : 0.0;
  var sgstrate = item?.sgstrate ? item.sgstrate : 0.0;
  var igstrate = item?.igstrate ? item.igstrate : 0.0;
  var boxpurrate = Number(purrate) * Number(item?.conversionfactor);

  var returnpackingqty = item?.returnablepackingqty ? item.returnablepackingqty : 0;
  var returnpackingextraqty = item?.returnablepackingextraqty
    ? item?.returnablepackingextraqty
    : 0;
  var totalreturnQty =
    Number(returnpackingextraqty) * Number(item?.conversionfactor) +
    Number(returnpackingqty);

  var packingqty = purchaseQty ? purchaseQty : 0; //~# here qty of pieces changed to key-name packingqty, box will be freepackingqty
  var packingextraqty = box ? box : 0; //~# box value
  var totalQty =
    (item?.currentreturnpackingextraqty
      ? Number(item?.currentreturnpackingextraqty)
      : 0) *
      Number(item?.conversionfactor) +
    Number(item?.currentreturnpackingqty) +
    Number(item?.returnfree);

  var batch = batch ? batch : 0;
  var expDate = expDate ? expDate : 0;
  var taxableamount =
    (Number(packingextraqty) * Number(item?.conversionfactor) +
      Number(packingqty)) *
    Number(purrate);
  var sgstamt = !igstappl ? taxableamount * (sgstrate / 100) : 0.0;
  var cgstamt = !igstappl ? taxableamount * (cgstrate / 100) : 0.0;
  var igstamt = igstappl ? taxableamount * (igstrate / 100) : 0.0;

  var amount = igstappl
    ? taxableamount + igstamt
    : taxableamount + (cgstamt + sgstamt);

  var itemObject = {
    ...item,
    shortguid: uuidv4(),
    itemrecno: item.itemrecno,
    itemdescn: item.itemdescn,
    packingextraqty: item?.packingextraqty || 0, //~# here box is the value coming from input Boxes
    packingqty: item?.packingqty || 0, //~# here packingqty is the pieces value coming from Pieces input
    batch: batch,
    qty: Number(totalQty),
    returnqty: totalreturnQty,
    returnableqty: totalreturnQty,
    purrate: Number(purrate)?.toFixed(2),
    taxableamount: Number(Number(taxableamount)?.toFixed(2)),
    cgstrate: cgstrate,
    sgstrate: sgstrate,
    igstrate: igstrate,
    cgstamt: Number(cgstamt)?.toFixed(2),
    sgstamt: Number(sgstamt)?.toFixed(2),
    igstamt: Number(igstamt)?.toFixed(2),
    amount: Number(Number(item?.amount ? item?.amount :  0.0)?.toFixed(2)),
    purchaseshortguid: newShortguid,
    boxpurrate: Number(boxpurrate)?.toFixed(2)
  };

  if (itemObject?.packingextraqty === 0 && itemObject?.qty === 0) {
    alert("Enter boxes / pieces / Rate");
    const boxRef = document.querySelector("#tr001-input-box");
    boxRef.focus();
    return;
  }
  console.log("tr001 handleAdd final ==>", itemObject);

  setRowData((p) => [...p, itemObject]);
  setSelectedItem(null);
};

export const handleChange = (
  item,
  setRowData,
  igstappl,
  rowData,
  scandata,
  setScandata,
  setOpenItem
) => {
  // var xqty = Number(item?.currentpackingextraqty) * Number(item?.conversionfactor) + Number(item?.currentpackingqty)
  console.log("Data=====>",rowData)
  if (
    item?.currentreturnpackingextraqty == 0 &&
    item?.currentreturnpackingqty == 0
  ) {
    alert("Enter Boxes or Pieces");
  }
  if (item?.purrate == 0) {
    alert("Rate is compulsory");
  }

  // if (item?.currentreturnpackingextraqty >= 0 && item?.currentreturnpackingqty > 0) {
  //   setOpenItem(true)
  // }
  var boxpurrate = item?.boxpurrate ? item.boxpurrate : 0.0;
  var purrate = item?.purrate ? item.purrate : 0.0;
  var cgstrate = item?.cgstrate ? item.cgstrate : 0.0;
  var sgstrate = item?.sgstrate ? item.sgstrate : 0.0;
  var igstrate = item?.igstrate ? item.igstrate : 0.0;
  var returnpackingextraqty = item?.currentreturnpackingextraqty
    ? item?.currentreturnpackingextraqty
    : 0;
  var returnpackingqty = item?.currentreturnpackingqty
    ? item?.currentreturnpackingqty
    : 0;
  var packingextraqty = item?.packingextraqty ? item?.packingextraqty : 0;
  // (item?.packingextraqty - currentreturnpackingextraqty) : 0;
  var packingqty = item?.packingqty ? item?.packingqty : 0;

  // hare changes //

  var currentTotalqty = item?.returnableqty ? item?.returnableqty : 0;

  // (item?.packingqty - returnpackingqty) : 0;
  var salerate = item?.salerate ? item?.salerate : 0;
  var totalQty =
    (item?.currentreturnpackingextraqty
      ? Number(item?.currentreturnpackingextraqty)
      : 0) *
      Number(item?.conversionfactor) +
    Number(item?.currentreturnpackingqty);

  /// curently the key of the returned////
  var total =
    Number(returnpackingextraqty) * Number(item?.conversionfactor) +
    Number(returnpackingqty);

  var currentpackingextraqty = item?.currentpackingextraqty
    ? item?.currentpackingextraqty
    : 0;
  var currentpackingqty = item?.currentpackingqty ? item?.currentpackingqty : 0;
  const scanqty = Number(item.currentreturnqty);

  // var totalcurrentqty = (Number(item?.currentpackingextraqty) ? Number(item?.currentpackingextraqty) : 0) * Number(item?.conversionfactor) + (Number(item?.currentpackingqty) ? Number(item?.currentpackingqty) : 0)

  // var totalreturnQty = Number(returnpackingextraqty) * Number(item?.conversionfactor) + Number(returnpackingqty)
  // var taxableamount = totalcurrentqty * Number(purrate);
  var taxableamount =
    (Number(packingextraqty) * Number(item?.conversionfactor) +
      Number(packingqty)) *
    Number(purrate);

  var sgstamt = !igstappl ? taxableamount * (sgstrate / 100) : 0.0;
  // item?.sgstamt ? item?.sgstamt : 0.0;
  var cgstamt = !igstappl ? taxableamount * (cgstrate / 100) : 0.0;
  // item?.cgstamt ? item?.cgstamt : 0.0;

  var igstamt = igstappl ? taxableamount * (igstrate / 100) : 0.0;
  // item?.igstamt ? item?.igstamt : 0.0;

  cgstamt = cgstamt.toFixed(2);
  sgstamt = sgstamt.toFixed(2);
  igstamt = igstamt.toFixed(2);

  //  var returndiscountrate = item.returndiscountrate ? item.returndiscountrate :0.0

  //   var returnpurrate = Number(purrate)

  let percentrate = Number(
    (Number(item.returndiscountrate) / 100) * purrate
  ).toFixed(2);
  let effectiverate = purrate - percentrate;
  // console.log("nohit",item?.currentreturnpackingextraqty)
  //   var totalcurrentqty = (Number(item?.currentreturnpackingextraqty) ? Number(item?.currentreturnpackingextraqty) : 0) * Number(item?.conversionfactor) + (Number(item?.currentreturnpackingqty) ? Number(item?.currentreturnpackingqty) : 0);
  //   console.log("RRR", totalcurrentqty, item.returnableqty)
  //   if (totalcurrentqty >= item.returnableqty) {
  //     totalcurrentqty = (item.returnableqty)
  //     var currentfreeqty = (Number(item?.currentreturnpackingextraqty) ? Number(item?.currentreturnpackingextraqty) : 0) * Number(item?.conversionfactor) + (Number(item?.currentreturnpackingqty) ? Number(item?.currentreturnpackingqty) : 0) - item.returnableqty;
  //   }
  //   else if (totalcurrentqty < item.returnableqty) {
  //     totalcurrentqty = totalcurrentqty
  //   }
  //   console.log("PPP", totalcurrentqty)
  var totalreturnqty =
    (Number(item?.currentreturnpackingextraqty)
      ? Number(item?.currentreturnpackingextraqty)
      : 0) *
      Number(item?.conversionfactor ? item.conversionfactor : 1) +
    (Number(item?.currentreturnpackingqty)
      ? Number(item?.currentreturnpackingqty)
      : 0);

  if (totalreturnqty >= item.returnableqty) {
    totalreturnqty = item.returnableqty;
    var returnfreeqty =
      (Number(item?.currentreturnpackingextraqty)
        ? Number(item?.currentreturnpackingextraqty)
        : 0) *
        Number(item?.conversionfactor) +
      (Number(item?.currentreturnpackingqty)
        ? Number(item?.currentreturnpackingqty)
        : 0) -
      item.returnableqty;
  } else if (totalreturnqty < item.returnableqty) {
    totalreturnqty = totalreturnqty;
  }

  // var returntaxableamount = item.returndiscountamt ? totalcurrentqty * Number(purrate) - item.returndiscountamt : totalcurrentqty * Number(purrate);
  // var returntaxableamount = totalcurrentqty * Number(purrate);
  var returntaxableamount = 0;
  if (item.returndiscountamt && !item.returndiscountrate) {
    returntaxableamount =
      Number(totalreturnqty) * Number(purrate) -
      (Number(item?.returndiscountamt) ? Number(item?.returndiscountamt) : 0);
  } else if (!item.returndiscountamt && item.returndiscountrate) {
    returntaxableamount =
      (Number(totalreturnqty) ? Number(totalreturnqty) : 0) *
      Number(effectiverate);
  } else {
    returntaxableamount =
      (Number(totalreturnqty) ? Number(totalreturnqty) : 0) * Number(purrate);
  }
  var retutnsgstamt = !igstappl
    ? returntaxableamount * Number(sgstrate / 100)
    : 0.0;
  // item?.sgstamt ? item?.sgstamt : 0.0;
  var returncgstamt = !igstappl
    ? returntaxableamount * Number(cgstrate / 100)
    : 0.0;
  // item?.cgstamt ? item?.cgstamt : 0.0;

  var returnigstamt = igstappl
    ? returntaxableamount * Number(igstrate / 100)
    : 0.0;
  // item?.igstamt ? item?.igstamt : 0.0;

  returncgstamt = returncgstamt.toFixed(2)
    ? returncgstamt.toFixed(2)
    : item.currentreturncgstamt;
  retutnsgstamt = retutnsgstamt.toFixed(2)
    ? retutnsgstamt.toFixed(2)
    : item.currentreturnsgstamt;
  returnigstamt = returnigstamt.toFixed(2)
    ? returnigstamt.toFixed(2)
    : item.currentreturnigstamt;

  var returnamount = igstappl
    ? Number(returntaxableamount) + Number(returnigstamt)
    : Number(returntaxableamount) +
      (Number(returncgstamt) + Number(retutnsgstamt));

  //sum of currentreturnamount = amount 19/05/23
  // var amount = igstappl ? Number(taxableamount) + Number(igstamt) : Number(taxableamount) + (Number(cgstamt) + Number(sgstamt));
  var amount = igstappl
    ? Number(returntaxableamount) + Number(returnigstamt)
    : Number(returntaxableamount) +
      (Number(returncgstamt) + Number(retutnsgstamt));

  //sum of currentreturnamount = amount 19/05/23
  // var taxableamount = (Number(packingextraqty) * Number(item?.conversionfactor) + Number(packingqty)) * Number(purrate);
  var taxableamount = Number(returntaxableamount)?.toFixed(2);

  // item?.amount ? item.amount : 0.0;

  // var amountre = item.amount + taxableamount

  var itemObject = {
    ...item,
    itemrecno: item.itemrecno,
    itemdescn: item.itemdescn,
    packingextraqty: Number(packingextraqty),
    packingqty: Number(packingqty),
    returnableqty: Number(currentTotalqty),
    // returnqty: Number(totalcurrentqty),
    qty: Number(scanqty),
    purrate: Number(purrate)?.toFixed(2),
    taxableamount: Number(taxableamount)?.toFixed(2),
    returnqty: Number(totalreturnqty),
    cgstrate: cgstrate ? cgstrate : 0,
    sgstrate: sgstrate ? sgstrate : 0,
    igstrate: igstrate ? igstrate : 0,
    cgstamt: Number(cgstamt)?.toFixed(2),
    sgstamt: Number(sgstamt)?.toFixed(2),
    igstamt: Number(igstamt)?.toFixed(2),
    // amount: Number(amount)?.toFixed(2) ? Number(amount)?.toFixed(2) : 0.0,
    amount: Number(item?.amount)?.toFixed(2) ? Number(item?.amount)?.toFixed(2) : 0.0,
    // returnfree: Number(currentfreeqty),
    returnfree: returnfreeqty ? Number(returnfreeqty) : 0,
    currentreturntaxableamount: Number(returntaxableamount)?.toFixed(2),
    //rishi-246 spelling mistake currentreturnsgstamt
    currentreturnsgstamt: Number(retutnsgstamt)?.toFixed(2)
      ? Number(retutnsgstamt)?.toFixed(2)
      : 0,
    currentreturncgstamt: Number(returncgstamt)?.toFixed(2)
      ? Number(returncgstamt)?.toFixed(2)
      : 0,
    currentreturnigstamt: Number(returnigstamt)?.toFixed(2)
      ? Number(returnigstamt)?.toFixed(2)
      : 0,
    currentreturnamount: Number(returnamount)?.toFixed(2)
      ? Number(returnamount)?.toFixed(2)
      : 0.0,
    //shortguid: uuidv4(),
    caldiscount: percentrate ? percentrate : 0,
    // returndiscountamt: percentrate,
    conversionfactor: item?.conversionfactor ? item?.conversionfactor : 0,
    itembatchno: item?.wantbatchno ? item.itembatchno : ".",
    //// changes suggest by aditi ///
    // currentreturnqty:Number(item.freepackingqty) + Number(currentfreeqty),
    // currentreturnqtyNew: totalcurrentqty,
    boxpurrate: Number(boxpurrate)?.toFixed(2),
    currentreturnqty: Number(totalreturnqty) ? Number(totalreturnqty) : 0,
    currentreturnfree: Number(returnfreeqty) ? Number(returnfreeqty) : 0,
  };

  if(itemObject.itemasbatch){
    var currentItem = rowData.map((itm) => {
      
        // Compare item record numbers; ensure consistent types
        if ((itm.itemrecno) === (itemObject.itemrecno)) {
          return { ...itemObject }; // Update with itemObject if itemrecno matches
        } else {
          return { ...itm }; // Otherwise, keep the existing item
        }
     
    });
    
  setRowData(currentItem);

  setScandata(item);

  }else{
    var currentItem = rowData.map((itm) => {
      
      // Compare item record numbers; ensure consistent types
      if ((itm.itembatchno) === (itemObject.itembatchno)) {
        return { ...itemObject }; // Update with itemObject if itemrecno matches
      } else {
        return { ...itm }; // Otherwise, keep the existing item
      }
   
  });
  
setRowData(currentItem);

setScandata(item);
  }

  

};

export const handleRemove = (item, setRowData, rowData) => {
  var removedArray = removeByAttribute(rowData, "shortguid", item.shortguid);
  // var removedArray = removeByAttribute(rowData,item,setRowData);
  // console.log("Remove", item, setRowData, rowData, removedArray);

  setRowData(() => [...removedArray]);
  // setRowData(removedArray);
  // setRowData(rowData[0])
  // setRowData(rowData);
};

//~^ GST AMOUNT getting added but not showing percentage values in table : Solved - 21/01/2023
//~! USING shortguid as classname for maipulating the DOM

import React, { useContext, useEffect, useState } from "react";
import { prColumns } from "./PurchaseReturnBillingTableColumns";
import { Table, Spin } from "antd";
import { TabStore } from "../../TabsStructure/TabsStore";
import ScanTable from "../../CommonControllers/Components/ScanTable/ScanTable";
import noData from "../../Assets/noData.png";
const ItemsTable = ({
  rowData,
  setRowData,
  igstappl,
  pageDetails,
  changerow,
  trdate,
}) => {
  const [showTable, setShowTable] = useState(true);
  const tabStore = useContext(TabStore);

  console.log("setRowData in itemtables", setRowData);
  // scan table
  const [openItem, setOpenItem] = useState(false);
  const [scandata, setScandata] = useState();
  const [renderer, setRenderer] = useState(0);
  const [customerData, setCustomerData] = useState({});
  const [validator, setValidator] = useState(0);
  const [modifiedRowData, setModifiedRowData] = useState([]);

  useEffect(() => {
    setShowTable(false);

    setTimeout(() => {
      setShowTable(true);
    }, 200);

    // setModifiedRowData(modified)
  }, [
    rowData?.length,
    tabStore.state.tabs[tabStore.state.tabIndex]?.editIndex,
  ]);
  // let modified=rowData.filter((row)=>row.stockavailableforreturn!==false)
  console.log("Modified", scandata);
  return (
    <>
      <div>
        {showTable && rowData?.length > 0 ? (
          <Table
            style={{
              thead: { background: "#f5f5f5", color: "#333" },
              tfoot: { background: "#f5f5f5", color: "#333" },
            }}
            scroll={{
              // y: 305,
              x: "auto",
            }}
            size="small"
            bordered
            className="tr001-purchaseOrder-itemsTable"
            pagination={false}
            columns={prColumns(
              igstappl,
              setRowData,
              rowData,
              scandata,
              setScandata,
              setOpenItem,
              pageDetails
            )?.filter((item) => !item.hidden)}
            dataSource={rowData}
            onChange={(e) => {}}
            summary={(pageData) => {
              let quantity = 0;
              let taxableAmount = 0;
              let cgstAmount = 0;
              let sgstAmount = 0;
              let igstAmount = 0;
              let totalAmount = 0;
              let boxTotal = 0;
              let freeTotalBoxes = 0;
              let freeTotalpieces = 0;
              let batchTotal = 0;
              let rateTotal = 0;
              let currentboxTotal = 0;
              let currentpieceTotal = 0;
              let currentTotalqty = 0;
              let returnTaxableAmount = 0;
              let returncgstAmount = 0;
              let retutnsgstAmount = 0;
              let returnigstAmount = 0;
              let returnAmount = 0;
              let currentreturnpackingextraqty1 = 0;
              let currentreturnpackingqty1 = 0;
              let currentreturnqty = 0;
              let freeQty = 0;
              pageData.forEach(
                ({
                  packingqty,
                  taxableamount,
                  cgstamt,
                  sgstamt,
                  igstamt,
                  amount,
                  packingextraqty,
                  purrate,
                  batch,
                  currentpackingextraqty,
                  currentpackingqty,
                  currentqty,
                  currentreturntaxableamount,
                  currentreturnsgstamt,
                  currentreturncgstamt,
                  currentreturnigstamt,
                  currentreturnamount,
                  freepackingextraqty,
                  freepackingqty,
                  returnpackingqty,
                  returnqty,
                  returnpackingextraqty,
                  currentreturnpackingextraqty,
                  currentreturnpackingqty,
                  currentreturnqty,
                  returnfree,
                }) => {
                  quantity += Number(packingqty);
                  console.log(
                    "quantity",
                    pageData,
                    currentreturncgstamt,
                    currentreturnsgstamt
                  );
                  taxableAmount += Number(taxableamount);
                  cgstAmount += Number(cgstamt);
                  sgstAmount += Number(sgstamt);
                  igstAmount += Number(igstamt);
                  totalAmount += Number(amount);
                  boxTotal += Number(packingextraqty);
                  rateTotal += Number(purrate);
                  batchTotal += Number(batch);
                  currentboxTotal += Number(returnpackingextraqty)
                    ? Number(returnpackingextraqty)
                    : 0;
                  currentpieceTotal += Number(returnpackingqty)
                    ? Number(returnpackingqty)
                    : 0;

                  currentTotalqty += Number(currentreturnqty)
                    ? Number(currentreturnqty)
                    : 0;

                  console.log("currentTotalqty", currentreturnpackingqty);
                  returnTaxableAmount += Number(currentreturntaxableamount)
                    ? Number(currentreturntaxableamount)
                    : 0;
                  returncgstAmount += Number(currentreturncgstamt)
                    ? Number(currentreturncgstamt)
                    : 0;
                  retutnsgstAmount += Number(currentreturnsgstamt)
                    ? Number(currentreturnsgstamt)
                    : 0;
                  returnigstAmount += Number(currentreturnigstamt)
                    ? Number(currentreturnigstamt)
                    : 0;
                  returnAmount += Number(currentreturnamount)
                    ? Number(currentreturnamount)
                    : 0;
                  freeTotalBoxes += Number(freepackingextraqty)
                    ? Number(freepackingextraqty)
                    : 0;
                  freeTotalpieces += Number(freepackingqty)
                    ? Number(freepackingqty)
                    : 0;
                  currentreturnpackingextraqty1 += Number(
                    currentreturnpackingextraqty
                  )
                    ? Number(currentreturnpackingextraqty)
                    : 0;
                  currentreturnpackingqty1 += Number(currentreturnpackingqty)
                    ? Number(currentreturnpackingqty)
                    : 0;
                  freeQty += returnfree ? Number(returnfree) : 0;
                }
              );
              console.log("pageDetailssssssssss",pageDetails)
              return (
                <Table.Summary fixed>
                  {!igstappl ? (
                    <Table.Summary.Row>
                      <Table.Summary.Cell index={0}>{null}</Table.Summary.Cell>
                      {/* nohit-425 Change Position of Total Caption */}
                      <Table.Summary.Cell index={1}>
                        <p style={{ ...pStyles, textAlign: "left" }}>Total</p>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={2}>{null}</Table.Summary.Cell>
                      <Table.Summary.Cell index={1}>{null}</Table.Summary.Cell>
                      <Table.Summary.Cell index={2}>{null}</Table.Summary.Cell>

                      {pageDetails?.wantpackingextra ? (
                        <Table.Summary.Cell index={3}>
                          <p style={{ ...pStyles }}>{boxTotal}</p>
                        </Table.Summary.Cell>
                      ) : null}
                      <Table.Summary.Cell index={4}>
                        <p style={{ ...pStyles }}>{quantity}</p>
                      </Table.Summary.Cell>
                      {pageDetails?.wantpackingextra &&
                      pageDetails?.wantfreeqty ? (
                        <Table.Summary.Cell index={3}>
                          <p style={{ ...pStyles }}>{freeTotalBoxes}</p>
                        </Table.Summary.Cell>
                      ) : null}
                      {pageDetails?.wantfreeqty ? (
                        <Table.Summary.Cell index={3}>
                          <p style={{ ...pStyles }}>{freeTotalpieces}</p>
                        </Table.Summary.Cell>
                      ) : null}
                      {pageDetails?.wantpackingextra ? (
                        <Table.Summary.Cell index={3}>
                          <p style={{ ...pStyles }}>{null}</p>
                        </Table.Summary.Cell>
                      ) : null}
                      <Table.Summary.Cell index={2}>{null}</Table.Summary.Cell>
                      <Table.Summary.Cell index={2}>{null}</Table.Summary.Cell>
                      {pageDetails?.wantfreeqty ? (
                        <Table.Summary.Cell index={3}>
                          <p style={{ ...pStyles }}>{null}</p>
                        </Table.Summary.Cell>
                      ) : null}

                      
                      <Table.Summary.Cell index={7}>
                        <p style={{ ...pStyles }}>
                          {Number(totalAmount).toFixed(2)}
                        </p>
                      </Table.Summary.Cell>
                      {pageDetails?.wantpackingextra ? (
                        <Table.Summary.Cell index={8}>
                          <p style={{ ...pStyles }}>
                            {Number(currentreturnpackingextraqty1)}
                          </p>
                        </Table.Summary.Cell>
                      ) : null}
                      {/* {pageDetails?.wantpackingextra ? ( */}
                        <Table.Summary.Cell index={9}>
                          <p style={{ ...pStyles }}>
                            {Number(currentreturnpackingqty1)}
                          </p>
                        </Table.Summary.Cell>
                      {/* ) : null} */}
                      <Table.Summary.Cell index={10}>
                        <p style={{ ...pStyles }}>{Number(currentTotalqty)}</p>
                      </Table.Summary.Cell>
                      {/* <Table.Summary.Cell index={2}>{null}</Table.Summary.Cell> */}
                      {pageDetails?.wantfreeqty ? (
                        <Table.Summary.Cell index={11}>
                          <p style={{ ...pStyles }}>{freeQty}</p>
                        </Table.Summary.Cell>
                      ) : null}
                      {pageDetails?.wantpackingextra ? (
                      <Table.Summary.Cell>
                        <p style={{ ...pStyles }}></p>
                      </Table.Summary.Cell>
                      ) : null}
                      
                      <Table.Summary.Cell index={12}>
                        <p style={{ ...pStyles }}>{null}</p>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={12}>
                        <p style={{ ...pStyles }}>{null}</p>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={13}>
                        <p style={{ ...pStyles }}>{null}</p>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={14}>
                        <p style={{ ...pStyles }}>{null}</p>
                      </Table.Summary.Cell>
                      {/* taskid-261 rajendra here added infotter to make it proper alignment */}
                      {/* <Table.Summary.Cell index={18}>
                        <p style={{ ...pStyles }}>{null}</p>
                      </Table.Summary.Cell> */}
                      {/* taskid-261 rajendra here added infotter to make it proper alignment */}
                      <Table.Summary.Cell index={15}>
                        <p style={{ ...pStyles }}>
                          {Number(returnTaxableAmount).toFixed(2)}
                        </p>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={16}>
                        <p style={{ ...pStyles }}>{null}</p>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={17}>
                        <p style={{ ...pStyles }}>
                          {Number(returncgstAmount).toFixed(2)}
                        </p>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={18}>
                        <p style={{ ...pStyles }}>{null}</p>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={19}>
                        <p style={{ ...pStyles }}>
                          {Number(retutnsgstAmount).toFixed(2)}
                        </p>
                      </Table.Summary.Cell>
                      {/* <Table.Summary.Cell index={19}>
                       <p style={{ ...pStyles }}>{null}</p>
                        </Table.Summary.Cell>
                       <Table.Summary.Cell index={20}>
                         <p style={{ ...pStyles }}>{returnigstAmount}</p>
                       </Table.Summary.Cell> */}
                      <Table.Summary.Cell index={20}>
                        <p style={{ ...pStyles }}>
                          {Number(returnAmount).toFixed(2)}
                        </p>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={21}>
                        <p style={{ ...pStyles }}>{null}</p>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={18}>
                        <p style={{ ...pStyles }}>{null}</p>
                      </Table.Summary.Cell>
                      {/* <Table.Summary.Cell index={18}>
                      <p style={{ ...pStyles }}>{null}</p>
                    </Table.Summary.Cell> */}
                    </Table.Summary.Row>
                  ) : (
                    <Table.Summary.Row>
                      <Table.Summary.Cell index={0}>{null}</Table.Summary.Cell>
                      {/* nohit-425 Change Position of Total Caption */}
                      <Table.Summary.Cell index={1}>
                        <p style={{ ...pStyles, textAlign: "left" }}>Total</p>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={2}>{null}</Table.Summary.Cell>
                      <Table.Summary.Cell index={1}>{null}</Table.Summary.Cell>
                      <Table.Summary.Cell index={2}>{null}</Table.Summary.Cell>

                      {pageDetails?.wantpackingextra ? (
                        <Table.Summary.Cell index={3}>
                          <p style={{ ...pStyles }}>{boxTotal}</p>
                        </Table.Summary.Cell>
                      ) : null}
                      <Table.Summary.Cell index={4}>
                        <p style={{ ...pStyles }}>{quantity}</p>
                      </Table.Summary.Cell>
                      {pageDetails?.wantpackingextra &&
                      pageDetails?.wantfreeqty ? (
                        <Table.Summary.Cell index={3}>
                          <p style={{ ...pStyles }}>{freeTotalBoxes}</p>
                        </Table.Summary.Cell>
                      ) : null}
                      {pageDetails?.wantfreeqty ? (
                        <Table.Summary.Cell index={3}>
                          <p style={{ ...pStyles }}>{freeTotalpieces}</p>
                        </Table.Summary.Cell>
                      ) : null}
                       {pageDetails?.wantpackingextra ? (
                        <Table.Summary.Cell index={11}>
                          <p style={{ ...pStyles }}>{null}</p>
                        </Table.Summary.Cell>
                      ) : null}
                      {/* {pageDetails?.wantpackingextra ? ( */}
                        <Table.Summary.Cell index={11}>
                          <p style={{ ...pStyles }}>{null}</p>
                        </Table.Summary.Cell>
                      {/* ) : null} */}
                      <Table.Summary.Cell index={2}>{null}</Table.Summary.Cell>
                      {pageDetails?.wantfreeqty ? (
                      <Table.Summary.Cell index={2}>{null}</Table.Summary.Cell>
                    ) : null}
                      <Table.Summary.Cell index={7}>
                        <p style={{ ...pStyles }}>
                          {Number(totalAmount).toFixed(2)}
                        </p>
                        
                      </Table.Summary.Cell>
                      {pageDetails?.wantpackingextra ? (
                        <Table.Summary.Cell index={8}>
                          <p style={{ ...pStyles }}>
                            {Number(currentreturnpackingextraqty1)}
                          </p>
                        </Table.Summary.Cell>
                      ) : null}
                      {/* {pageDetails?.wantpackingextra ? ( */}
                        <Table.Summary.Cell index={9}>
                          <p style={{ ...pStyles }}>
                            {Number(currentreturnpackingqty1)}
                          </p>
                        </Table.Summary.Cell>
                      {/* ) : null} */}
                      <Table.Summary.Cell index={10}>
                        <p style={{ ...pStyles }}>{Number(currentTotalqty)}</p>
                      </Table.Summary.Cell>
                      {pageDetails?.wantfreeqty ? (
                        <Table.Summary.Cell index={11}>
                          <p style={{ ...pStyles }}>{freeQty}</p>
                        </Table.Summary.Cell>
                      ) : null}
                   {pageDetails?.wantpackingextra ? (
                      <Table.Summary.Cell>
                        <p style={{ ...pStyles }}></p>
                      </Table.Summary.Cell>
                      ) : null}
                      <Table.Summary.Cell index={13}>
                        <p style={{ ...pStyles }}>{null}</p>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={14}>
                        <p style={{ ...pStyles }}>{null}</p>
                      </Table.Summary.Cell>
                      {/* taskid-261 rajendra here added infotter to make it proper alignment */}
                      <Table.Summary.Cell index={18}>
                        <p style={{ ...pStyles }}>{null}</p>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={2}>{null}</Table.Summary.Cell>
                      {/* <Table.Summary.Cell index={2}>{null}</Table.Summary.Cell> */}
                      {/* taskid-261 rajendra here added infotter to make it proper alignment */}
                      <Table.Summary.Cell index={15}>
                        <p style={{ ...pStyles }}>
                          {Number(returnTaxableAmount).toFixed(2)}
                        </p>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={16}>
                        <p style={{ ...pStyles }}>{null}</p>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={17}>
                        <p style={{ ...pStyles }}>
                          {Number(returnigstAmount).toFixed(2)}
                        </p>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={18}>
                        <p style={{ ...pStyles }}>
                          {Number(returnAmount).toFixed(2)}
                        </p>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={21}>
                        <p style={{ ...pStyles }}>{null}</p>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={16}>
                        <p style={{ ...pStyles }}>{null}</p>
                      </Table.Summary.Cell>
                      {/* <Table.Summary.Cell index={18}>
                      <p style={{ ...pStyles }}>{null}</p>
                    </Table.Summary.Cell> */}
                    </Table.Summary.Row>
                  )}
                </Table.Summary>
              );
            }}
          />
        ) : rowData?.length > 0 ? (
          <div style={{ ...loaderStyle }}>
            <Spin size="large" />
          </div>
        ) : (
          <div className="noDataFound">
            <h1>Whoops! No Returnable Records Found</h1>
            <img src={noData} />
          </div>
        )}
      </div>
      <div>
        {openItem && (
          <ScanTable
            data={scandata}
            openItem={openItem}
            setOpenItem={setOpenItem}
            rowData={rowData}
            setRowData={setRowData}
            renderer={renderer}
            setRenderer={setRenderer}
            setValidator={setValidator}
            forFlag={"PR"}
            supplierrecno={customerData?.supplierrecno}
            // forFlag="PR"
            changerow={changerow}
            trdate={trdate}
          />
        )}
      </div>
    </>
  );
};

export default ItemsTable;

const loaderStyle = {
  height: "max-content",
  width: "max-content",
  display: "flex",
  alignContent: "center",
  margin: "50px auto",
};

const pStyles = {
  textAlign: "right",
  fontWeight: "700",
  fontSize: "16px",
  color: "black",
};

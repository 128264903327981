import React, { useContext, useState } from 'react'
import SaveNumberInput from '../../CommonControllers/Components/SaveComponents/SaveNumberInput'
import SaveTextInput from '../../CommonControllers/Components/SaveComponents/SaveTextInput'
import SaveDropDown from '../../CommonControllers/Components/SaveComponents/SaveDropdown'
import SaveCheckBox from '../../CommonControllers/Components/SaveComponents/SaveCheckbox'
import { getForAnswer, getQuestionBySurveyType, getSurveyQuestionType, getSurveyType } from '../../CommonControllers/Urls'
import { AuthStore } from '../../Auth/AuthStore'
import SurveyTypeDropDown from './SurveyTypeDropDown'
import SurveyQuestonTable from './SurveyQuestonTable'
import { TabStore } from '../../TabsStructure/TabsStore'
import { Button, Table, Tooltip } from 'antd'
import { AiOutlineFileAdd } from 'react-icons/ai'
import { FiEdit } from 'react-icons/fi'
import ExecuteDropdown from '../../CommonControllers/Components/ExecuteComponents/ExecuteDropdown'

function AddSurveyQuestions() {
  const auth = useContext(AuthStore);
  const tabs = useContext(TabStore);

  const [surveytype, setsurveytype] = useState()
  const [questiontyperecno, setquestiontyperecno] = useState()
  const [issubquestionof, setissubquestionof] = useState()


  return (
    <div style={{ width: "90vw", margin: "2%", marginTop: "1%", zIndex: 3 }}>
      <div style={{zIndex: 3}}>
      <ExecuteDropdown
        placeholder="Select Survey Type"
        displayLabel={"Survey Type"}
        apiurl={getSurveyType}
        apikey={"surveytype"}
        selectionLabel={"descn"}
        selectionValue={"recno"}
        minInput={0}
        inputValue={20}
        returnValue={setsurveytype}
        width={"300px"}
      />
      </div>
      <div style={{ display: "flex", zIndex:0 }}>
        <SurveyQuestonTable
          surveytype={surveytype}
          setsurveytype={setsurveytype}
        />
      </div>
    </div>
  )
}

export default AddSurveyQuestions
import React, { useState, useEffect } from "react";
import { Table, Typography, Button, Collapse } from "antd";
import WMSItemCard from "./WMSItemCard";
import axios from "axios";
import { getPurchaseInfoForItemURL } from "../../../../CommonControllers/Urls";
import {
  dateFormatter,
  formatCurrency,
  removeFormatCurrency,
} from "../../../../CommonControllers/AppFunction";

const { Text } = Typography;

const WMSLogTable = ({
  tableData,
  igstAppl,
  itemsColumn,
  attributeColumn,
  logData,
}) => {
  const { Panel } = Collapse;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [itemData, setItemData] = useState([]);
  const [itemDescn, setItemDescn] = useState("");
  const [formated, setFormated] = useState([]);

  // To adjust the column width
  const columnWidth = 20;
  const availableWidth = window.innerWidth;
  const maxColumns = Math.floor(availableWidth / columnWidth);

  // Now this func is used for the card of individual item, currently not in use, has hardcoded url for getting data
  const showItemCard = (rowData) => {
    setItemDescn(rowData.itemdescn);
    console.log("API called");

    setIsModalOpen((prev) => !prev);
    const payload = {};

    axios
      .post(getPurchaseInfoForItemURL, payload)
      .then((res) => {
        if (res?.data?.Message.length > 0) {
          setItemData(res?.data?.Message);
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    const formattedData = tableData.map((item) => {
      const formattedItem = {};
      for (let key in item) {
        if (
          typeof item[key] === "number" &&
          key !== "expdate" &&
          key !== "trdate"
        ) {
          formattedItem[key] = formatCurrency(item[key]);
        } else if (key === "expdate" || key === "trdate" || key === "mfgdate") {
          formattedItem[key] = dateFormatter(item[key]);
        } else if (typeof item[key] === "string") {
          if (item[key].includes(",")) {
            // If the string contains a comma, split and format each value on a new line
            const values = item[key].split(",");
            formattedItem[key] = values.map((value) => value.trim()).join("\n");
          } else {
            formattedItem[key] = item[key];
          }
        } else {
          formattedItem[key] = item[key];
        }
      }
      return formattedItem;
    });
    setFormated(formattedData);
  }, [tableData]);

  console.log("REEEEE55888", logData);

  return (
    <>
      <Collapse collapsible="header">
        {/* Attribute Details */}
        {attributeColumn?.length > 0 ? (
          <Panel header="Document Details" key="1">
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(6, 1fr)",
                gap: "20px",
              }}
            >
              {attributeColumn?.slice(0, maxColumns).map((elem, index) => (
                <div
                  key={index}
                  style={{
                    textAlign: "center",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    flexWrap: "wrap",
                  }}
                >
                  <div>
                    <strong>{elem?.title}</strong>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      gap: "8px",
                    }}
                  >
                    {elem?.type === "string" ||
                    elem?.type === "int" ||
                    elem?.type === "decimal" ? (
                      <div>
                        {logData[elem?.dataIndex]
                          ? logData[elem?.dataIndex]
                          : elem?.defaultValue}
                      </div>
                    ) : elem?.type === "date" ? (
                      <div>
                        {dateFormatter(logData[elem?.dataIndex])
                          ? dateFormatter(logData[elem?.dataIndex])
                          : elem?.defaultValue}
                      </div>
                    ) : (
                      <div>
                        {logData[elem?.dataIndex]
                          ? logData[elem?.dataIndex]
                          : elem?.defaultValue}
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </Panel>
        ) : null}

        {/* Item Details */}
        <Panel header="Item Details" key="2">
          <Table
            columns={itemsColumn}
            dataSource={formated}
            pagination={false}
            bordered
          />
        </Panel>
      </Collapse>

      {/* <div>
        <WMSItemCard
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          itemData={itemData}
          itemDescn={itemDescn}
        />
      </div> */}
    </>
  );
};
export default WMSLogTable;

import React, { useContext } from "react";
import { CloudUploadOutlined } from "@ant-design/icons";
import "../../global.css";

import { FiCloudLightning } from "react-icons/fi";
import { TabStore } from "../../../TabsStructure/TabsStore";

const ExecuteFileInput = ({ className, displayLabel, apiKey}) => {
  const [file, setFile] = React.useState("");
  const tabstore = useContext(TabStore);
  const handleFileUpload = async (e) => {
    const file = e.target.files[0];
    const base64 = await convert(file);
    console.log("file", file);
    setFile(base64);
  if(base64){
    tabstore.dispatch({
      type: "API_DATA",
      fieldType: apiKey,
      text: base64,
    });
    alert("File Uploaded")
  }
  };

  const convert = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (e) => {
        reject(e);
      };
    });
  };
 
  return (
    <div>
      <img src={file?file:null} alt="" />
      <label
        className={!className ? "fileUpload" : className}
        onChange={handleFileUpload}
      >
        <input
          type="file"
          accept=".doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document, image/* , .pdf , .doc"
        />
        <CloudUploadOutlined />
        <p>{displayLabel}</p>
      </label>
    </div>
  );
};

export default ExecuteFileInput;

// CSS

const mainFrame = {
  display: "flex",
  alignItems: "center",
  gap: "10px",
};

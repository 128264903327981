import React, { useState, useEffect } from "react";
import { Table, Typography, Button } from "antd";
import ItemCard from "./ItemCard";
import axios from "axios";
import { getPurchaseInfoForItemURL } from "../../../CommonControllers/Urls";
import { dateFormatter, formatCurrency, removeFormatCurrency } from "../../../CommonControllers/AppFunction";

const { Text } = Typography;

const LogTable = ({ tableData, igstAppl, itemsColumn }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [itemData, setItemData] = useState([]);
  const [itemDescn, setItemDescn] = useState("");
const [formated,setFormated]=useState([])

  // Now this func is used for the card of individual item, currently not in use, has hardcoded url for getting data
  const showItemCard = (rowData) => {
    setItemDescn(rowData.itemdescn);
    console.log("API called");

    setIsModalOpen((prev) => !prev);
    const payload = {};

    axios
      .post(getPurchaseInfoForItemURL, payload)
      .then((res) => {
        if (res?.data?.Message.length > 0) {
          setItemData(res?.data?.Message);
        }
      })
      .catch((err) => console.log(err));
  };



  useEffect(() => {
    const formattedData = tableData.map((item) => {
      const formattedItem = {};
      for (let key in item) {
        if (typeof item[key] === "number" && key !="expdate" && key!="trdate") {
          formattedItem[key] = formatCurrency(item[key]);
        }
        else if(key==="expdate" || key==="trdate" || key=="mfgdate"){
         
          formattedItem[key] = dateFormatter(item[key]);
        }
         else {
          formattedItem[key] = item[key];
        }
      }
      return formattedItem;
    });
    // console.log("REEEEE",formated[1])
    setFormated(formattedData);
 
  }, [tableData]);


  
  // console.log("REEEEE55888",formated[1])
  
  return (
    <>
      <Table
        columns={itemsColumn}
        dataSource={formated}
        pagination={false}
        bordered
       
      />
      <div>
        <ItemCard
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          itemData={itemData}
          itemDescn={itemDescn}
        />
      </div>
    </>
  );
};
export default LogTable;
import React, { useState, useEffect, useContext, useRef } from "react";
import useSaveChangedRows from "../../CommonControllers/Hooks/useSaveChangedRows";
import ItemSearch from "../../CommonControllers/ItemController/ItemSearch";
import { handleAdd, piColumns } from "./MaterialIssueFunc.js";
import SaveDropdown from "../../CommonControllers/Components/SaveComponents/SaveDropdown";
import {
  Button,
  Checkbox,
  Collapse,
  Input,
  Modal,
  Space,
  Table,
  Typography,
} from "antd";
import {
  getToday,
  dateFormatter,
  convertToBoxAndPieces,
} from "../../CommonControllers/AppFunction";
import SupplierSaveDropDown from "../../CommonControllers/SaveComponents/HigherLevel/SupplierSaveDropDown";
import SaveDatePicker from "../../CommonControllers/SaveComponents/SaveDatePicker";
import useShortguid from "../../CommonControllers/Hooks/useShortGuid";
import { TabStore } from "../../TabsStructure/TabsStore";
import SaveTextInput from "../../CommonControllers/Components/SaveComponents/SaveTextInput";
import { AuthStore } from "../../Auth/AuthStore";
import { IoAperture } from "react-icons/io5";
import CallbackDateInput from "../../CommonControllers/Components/CallbackComponents/CallbackDateInput";
import {
  apiCallwithBaseUrl,
  getfastpathapi,
} from "../../CommonControllers/ApiCalls";
import { Divider, List } from "antd";
import { FcAddDatabase } from "react-icons/fc";
import UserLocationList from "../../CommonControllers/SaveComponents/HigherLevel/UserLocationList";
import {
  getCustomerListWithoutDomain,
  domainLocationURL,
  getDomainCustomerSalesman,
  getDomainLocationUrl,
  getdomainlocationlistactive,
} from "../../CommonControllers/Urls";
import CustomerLocationList from "../../CommonControllers/SaveComponents/HigherLevel/CustomerLocationList";
import CallbackTextInput from "../../CommonControllers/Components/CallbackComponents/CallbackTextInput";
import ScanTable from "../../CommonControllers/Components/ScanTable/ScanTable";
import { currentTabData } from "../../CommonControllers/Variable";
import useFetchItem from "../../CommonControllers/ItemController/useFetchItems";
import { notification } from "antd";

function MaterialIssue() {
  const [getSelectedItem, setSelectedItem] = useState(null);
  const [rowData, setRowData] = useState([]);
  const [supplierdata, setSupplierdata] = useState(null);
  const [igstappl, setigstappl] = useState(false);
  const useChangedRows = useSaveChangedRows();
  const [displayMessages, setDisplayMessages] = useState(false);
  const [displayPrevPO, setDisplayPrevPO] = useState(false);
  const [previousPOList, setPreviousPOList] = useState([]);

  const shortguid = useShortguid();
  const tabStore = useContext(TabStore);
  const auth = useContext(AuthStore);
  const { Panel } = Collapse;

  const boxRef = useRef(null);
  const piecesRef = useRef(null);
  const itemSearch = useRef(null);
  const btnRef = useRef(null);

  const [box, setBox] = useState("");
  const [collapse, setCollapse] = useState(false);
  const [expDeliveryDays, setExpDeliveryDays] = useState(null);
  const [purchaseRate, setPurchaseRate] = useState(getSelectedItem?.purrate);
  const [selectedQty, setSelectedQty] = useState(0);
  const [itemInformation, setItemInformation] = useState();
  const [acctualQty, setAcctualQty] = useState(); // Qty as in pieces in
  const [freeBox, setFreeBox] = useState(0);
  const [freeQty, setFreeQty] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [discountPer, setDiscountPer] = useState(0);
  const [batchNo, setBatchNo] = useState(null);
  const [expDate, setExpDate] = useState();
  const [manuDate, setManuDate] = useState();
  const [showTable, setShowTable] = useState(true);
  const [getInfoFromPO, setInfoFromPO] = useState([]);
  const [customerData, setCustomerData] = useState({});
  const [dcDate, setDcDate] = useState();
  const [partyloc, setPartyLoc] = useState();
  const [val, setValforinput] = useState({});
  const [change, setChange] = useState(0);

  const [openItem, setOpenItem] = useState(false);
  const [scandata, setScandata] = useState();
  const [renderer, setRenderer] = useState(0);
  const [validator, setValidator] = useState(0);
  const [count, setCount] = useState(0);
  const [pageIndex, setPageIndex] = useState();
  const fetchItems = useFetchItem();
  const [pageDetails, setPageDetails] = useState();
  useEffect(() => {
    const payload = {
      tenantrecno: auth?.state?.userdata?.tenantrecno,
      domainrecno: auth?.state?.userdata?.domainrecno,
      userrolerecno: auth?.state?.userdata?.userrolerecno,
      domainuserrecno: auth.state.userdata.recno,
      fastpath: currentTabData(tabStore)?.name,
    };
    // Get Fast ath API
    getfastpathapi(payload).then((res) => {
      console.log("rr", res);
      if (res?.Success) {
        setPageDetails(res?.Message);
        console.log("Resposnse of Fast Path API", res.Message);
      }
    });
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");
    const formattedDate = `${year}${month}${day}`;

    setDcDate(formattedDate);
  }, [pageIndex]);

  useEffect(() => {
    console.log(
      `tr001 items table re-rendered ?? after deleting`,
      tabStore.state.tabs[tabStore.state.tabIndex]?.editIndex
    );
    setShowTable(false);

    setTimeout(() => {
      setShowTable(true);
    }, 100);
  }, [change]);

  useEffect(() => {
    var tabsArray = tabStore.state.tabs;
    for (let i = 0; i < tabsArray?.length; i++) {
      if (tabsArray[i].name == "TR030") {
        setPageIndex(i);
        break;
      }
    }
  }, []);

  useEffect(() => {
    if (getSelectedItem) {
      setPurchaseRate(Number(getSelectedItem?.purrate).toFixed(2));
    }
  }, [getSelectedItem]);
  useEffect(() => {
    setShowTable(false);

    setTimeout(() => {
      setShowTable(true);
    }, 200);
  }, [rowData?.length]);
  let grossAmt =
    (Number(box) * 10 + Number(selectedQty)) *
      (Number(purchaseRate) - Number(discount)) || "00";

  //~# Setting workdate
  let workdate = auth?.state?.workdate;
  workdate = JSON.stringify(workdate);
  workdate = `${workdate?.slice(-2)}/${workdate?.slice(4, 6)}/${workdate?.slice(
    0,
    4
  )}`;

  const enterPress = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      const form = e.target.form;
      const index = Array.prototype.indexOf.call(form, e.target);
      if (form.elements[index + 1]) {
        form.elements[index + 1].focus();
      }
    }
  };

  useEffect(() => {
    setAcctualQty(
      Number(box) * Number(getSelectedItem?.conversionfactor) +
        Number(selectedQty)
    );
  }, [box, selectedQty]);

  function setDatatoSupplierdata(e) {
    setSupplierdata(e);

    // Get Suppliers Purchase Orders where status == "C"
    if (e) {
      getPurchaseOrdersForSupplier(e?.supplierrecno);
    }
  }

  const getPurchaseOrdersForSupplier = async (supplierdescn) => {
    const res = await apiCallwithBaseUrl("POST", "/filterpurchaseorder/", {
      supplierrecno: supplierdescn,
      withitems: true,
      status: "P",
    });
    console.log("Resposne of Filter PO is", res);
    setPreviousPOList(res);
  };

  const addItemstoTable = () => {
    {
      /* Task-id 338 rajendra Point.1 here wantbatcno validation start*/
    }
    if (
      getSelectedItem?.wantbatchno == 1 &&
      (!batchNo || batchNo == undefined || batchNo == "")
    ) {
      alert("Batch Number Cannot be Empty");
      return;
    }
    {
      /* Task-id 338 rajendra Point.1 here wantbatcno validation end*/
    }
    if (getSelectedItem) {
      var existingItem = rowData.filter(
        (item) => item.itemrecno == getSelectedItem.itemrecno
      );

      console.log("existingItem?.length", existingItem?.length);
      if (existingItem?.length == 0) {
        // clearing all input values
        setBox("");

        setSelectedQty("");
        // setPurchaseRate("");

        setFreeBox(0);
        setFreeQty(0);
        setDiscount(0);
        setDiscountPer(0);
        setBatchNo("");
        setExpDate(null);
        setManuDate("");
        handleAdd(
          getSelectedItem,
          setRowData,
          igstappl,
          setSelectedItem,
          shortguid,
          purchaseRate,
          selectedQty,
          box,
          freeBox,
          freeQty,
          batchNo,
          expDate,
          discount,
          discountPer,
          manuDate
        );
        itemSearch?.current?.focus();
      } else {
        alert("Item Already Present");
        setSelectedItem(null);
        itemSearch?.current?.focus();
      }
    }
    itemSearch?.current?.focus();
  };

  useEffect(() => {
    if (supplierdata?.sezappl) {
      setigstappl(true);
    } else {
      setigstappl(false);
    }
  }, [supplierdata]);

  useEffect(() => {
    // When Row Data Changes we Need to store that data to the tabsStore with Header Data
    var existingObject = useChangedRows.getValue();

    // Calculate Other Numbers and Inputs and store in the tabStore
    var status = "P";
    var trdate = "workdate";
    var approvalStatus = "P";
    var remark = "Need to add a field for that";
    var narration = "Need to ad a text field for this as well";
    var amount; // Calculate the total amount after tax
    var taxableamount; // Calculate the total taxable amount after tax
    var cgstamt; //Calculate the CGSTAMT
    var sgstamt; //Calculate teh SGST AMT
    var igstamt; //Calculat the IGSTAMT

    amount = rowData.reduce((total, item) => total + Number(item.amount), 0);
    console.log("Amount is  : ", amount);
    taxableamount = rowData.reduce(
      (total, item) => total + Number(item.taxableamount),
      0
    );
    cgstamt = rowData.reduce((total, item) => total + Number(item?.cgstamt), 0);
    sgstamt = rowData.reduce((total, item) => total + Number(item?.sgstamt), 0);
    igstamt = rowData.reduce((total, item) => total + Number(item?.igstamt), 0);
    var short = existingObject?.shortguid
      ? existingObject?.shortguid
      : shortguid?.getShortguid("PO");
    existingObject = {
      ...existingObject,
      cgstamt: Number(cgstamt).toFixed(2),
      igstamt: Number(igstamt).toFixed(2),
      sgstamt: Number(sgstamt).toFixed(2),
      taxableamount: Number(taxableamount).toFixed(2),
      amount: Number(amount).toFixed(2),
      items: rowData,
      trdate: dcDate,
      podate: dcDate,
      status: "C",
      shortguid: short,
    };

    useChangedRows.updateValue(false, existingObject);
  }, [rowData]);

  useEffect(() => {
    // When Row Data Changes we Need to store that data to the tabsStore with Header Data
    var currentData = useChangedRows.getValue();
    if (!currentData?.shortguid) {
      useChangedRows.updateValue("shortguid", shortguid.getShortguid("PO"));
    }

    if (rowData?.length == 0) {
      var items = useChangedRows.getValue("items");
      if (items) {
        setRowData(items);
      }
    }
  }, []);

  useEffect(() => {

    if (useChangedRows.getValue("trdate")) {
      setDcDate(useChangedRows.getValue("trdate"));
    } else {
      useChangedRows.updateValue("trdate", auth?.state?.workdate);
      setDcDate(auth?.state?.workdate);
    }
  }, [useChangedRows.getValue("trdate")]);

  useEffect(() => {
    if (tabStore.state.tabs[pageIndex]?.pageStatus == "Success") {
      setRowData([]);
      setigstappl(false);
      setSelectedItem(null);
      setValforinput({});
      setPartyLoc("");
      setCount((p) => p + 1);
      setDcDate(auth?.state?.userdata?.workdate)
      tabStore.dispatch({
        type: "UPDATE_DATA",
        fieldType: "pageStatus",
        text: null,
      });
    }
    ///{Pradeep task-id -260}
  }, [tabStore.state.tabs[pageIndex]?.pageStatus]);

  // console.log("customerData", customerData?.customerrecno);

  // Use Effect for getSelectedItem
  // If Selected Item is not itemasbatch
  /*
  In order to add the Selected Item with Batch Details into the rows array
  1. Check if item is itemasbatch
  2. If itemasbatch : directly select the item and ask to input the quantity required
  3. If Not itemasbatch : using for loop add the selceted items to the rowsData
  4. From the ItemBatch Details and the items object select the selectedQty and selectedFreeQty form the selected Items
  */

  const addItemBatchWiseToTable = () => {
    // Get getSelectedItems

    if (getSelectedItem?.itembasbatch) {
      //Added s at the end remove later
      // Here we need to add the from the Quantity,
    } else {
      // Here we select form the selected Items
      let batchDetails = getSelectedItem?.batchdetails;
      for (let i = 0; i < batchDetails?.length; i++) {
        let itemrecno = getSelectedItem.itemrecno;
        let itemdescn = getSelectedItem.itemdescn;
        let igstrate = getSelectedItem.igstrate;
        let cgstrate = getSelectedItem.cgstrate;
        let sgstrate = getSelectedItem.sgstrate;
        let mrp = getSelectedItem.mrp;
        let conversionfactor = getSelectedItem.conversionfactor;
        let totalSelectedQuantity = batchDetails[i].selectedQty;
        let totalFreeQuantity = batchDetails[i].selectedFreeQty;
        let selectedBoxQty = convertToBoxAndPieces(
          totalSelectedQuantity,
          conversionfactor
        )[0];
        let selectedPcsQty = convertToBoxAndPieces(
          totalSelectedQuantity,
          conversionfactor
        )[1];
        let selectedFreeBoxQty = convertToBoxAndPieces(
          totalFreeQuantity,
          conversionfactor
        )[0];
        let selectedFreePcsQty = convertToBoxAndPieces(
          totalFreeQuantity,
          conversionfactor
        )[1];
        let batchNo = batchDetails[i].itembatchno;
        let itemSerialNo = batchDetails[i].serialno;
        let salerate = batchDetails[i].salerate;
        let expdate = batchDetails[i].expdate;
        let manudate = batchDetails[i].manudate;

        if (totalSelectedQuantity > 0 || totalFreeQuantity > 0) {
          handleAdd(
            getSelectedItem,
            setRowData,
            igstrate > 0 ? true : false,
            setSelectedItem,
            shortguid,
            salerate,
            selectedPcsQty,
            selectedBoxQty,
            selectedFreeBoxQty,
            selectedFreePcsQty,
            batchNo,
            expdate,
            0,
            0,
            manudate
          );
        }
      }
    }
  };

  useEffect(() => {
    addItemBatchWiseToTable();
  }, [getSelectedItem]);

  // useEffect(() => {
  //   let isButtonDisabled = false;

  //   for (let i = 0; i < rowData.length; i++) {
  //     const { packingqty, packingextraqty, freepackingqty, freepackingextraqty, manufacturedate, expdate, itembatchno,freeqty } = rowData[i];

  //     console.log("Packaging", rowData[i])
  //     if (((packingqty === "0" && packingextraqty === "0"&&freeqty=="0") || (packingqty == "" && packingextraqty == ""&&freeqty=="") || (packingqty === 0 && packingextraqty === "0"&& freeqty==0) || (packingqty === "0" && packingextraqty === 0) || (packingqty === 0 && packingextraqty === 0&& freeqty==null) )|| (rowData[i]?.wantbatchno==0 &&
  //       Number(rowData[i]?.qty?rowData[i]?.qty:0) +
  //       Number(rowData[i]?.freeqty ? rowData[i]?.freeqty : 0) !==
  //       rowData[i]?.itembatcharray?.length) ){
  //       isButtonDisabled = true;
  //       console.log("isButtonDisabled:122", isButtonDisabled);
  //       break;
  //     }
  //     else if (((packingqty === "0" && packingextraqty === "0"&&freeqty=="0") || (packingqty == "" && packingextraqty == ""&&freeqty=="") || (packingqty === 0 && packingextraqty === "0" && freeqty==0) || (packingqty === "0" && packingextraqty === 0) || (packingqty === 0 && packingextraqty === 0 && freeqty==null) || ( manufacturedate == null || expdate == null )|| (itembatchno == null || itembatchno == ""))&&rowData[i]?.wantbatchno==1
  //     ) {
  //       isButtonDisabled = true;
  //       break;
  //     }
  //     console.log("isButtonDisabled:", isButtonDisabled, packingqty, packingextraqty);
  //   }

  //   useChangedRows.updateValue("validated", isButtonDisabled ? "false" : "true");
  // }, [rowData]);

  //task id-338 rajendra save button disable

  useEffect(() => {
    let isButtonDisabled = false;

    for (let i = 0; i < rowData.length; i++) {
      const {
        packingqty,
        packingextraqty,
        freepackingqty,
        freepackingextraqty,
        manufacturedate,
        expdate,
        itembatchno,
        purrate,
        expdatecompulsory,
        itemasbatch,
        manudatecompulsory,
        wantbatchno,
        changesalerateinbill,
        isservice,
        qty,
        freeqty,
        mfgdate,
        manuDate,
        salerate,
      } = rowData[i];

      //const isManuDateOnly = !mfgdate && typeof manuDate !== "undefined" && manuDate !== 0;

      // Use the correct date based on the conditions
      const effectiveMfgdate = !mfgdate ? manuDate : mfgdate;
      console.log("DDRYTD", effectiveMfgdate, rowData[i]);
      if (
        (isservice === 1 && qty + freeqty === 0) ||
        (isservice === 0 &&
          wantbatchno &&
          (!itembatchno ||
            itembatchno == "" ||
            itembatchno == null ||
            itembatchno == undefined)) ||
        (expdatecompulsory &&
          (!expdate || expdate == "undefined" || Number(expdate) == 0)) ||
        (manudatecompulsory &&
          (!effectiveMfgdate ||
            effectiveMfgdate == "undefined" ||
            Number(effectiveMfgdate) == 0 ||
            effectiveMfgdate === "")) ||
        (packingqty === "0" && packingextraqty === "0") ||
        (packingqty === "" && packingextraqty == "") ||
        (packingqty === 0 && packingextraqty === "0") ||
        (packingqty === "0" && packingextraqty === 0) ||
        (packingqty === 0 && packingextraqty === 0) ||
        rowData[i].salerate == "undefined" ||
        rowData[i].salerate == 0
      ) {
        isButtonDisabled = true;
        console.log("Chhhhhhh==15", rowData[i]);
        break;
      } else if (
        (isservice === 0 &&
          wantbatchno == 0 &&
          itemasbatch &&
          packingqty === "0" &&
          packingextraqty === "0") ||
        (packingqty === "" && packingextraqty == "") ||
        (packingqty === 0 && packingextraqty === "0") ||
        (packingqty === "0" && packingextraqty === 0) ||
        (packingqty === 0 && packingextraqty === 0) ||
        (rowData[i]?.doctype !== "DC" &&
          rowData[i]?.itemasbatch &&
          Number(rowData[i]?.qty) +
            Number(rowData[i]?.free ? rowData[i]?.free : 0) !==
            rowData[i]?.itembatcharray?.length) ||
        rowData[i].salerate == "undefined" ||
        rowData[i].salerate == 0 ||
        rowData[i].salerate == null
      ) {
        isButtonDisabled = true;
        console.log("Chhhhhhh==1");
        break;
      } else if (
        (isservice == 0 &&
          wantbatchno &&
          (itembatchno == "" ||
            itembatchno == null ||
            itembatchno == undefined)) ||
        (packingqty === "0" && packingextraqty === "0") ||
        (packingqty == "" && packingextraqty == "") ||
        (packingqty === 0 && packingextraqty === "0") ||
        (packingqty === "0" && packingextraqty === 0) ||
        (packingqty === 0 && packingextraqty === 0) ||
        rowData[i].salerate == "undefined" ||
        rowData[i].salerate == 0
      ) {
        isButtonDisabled = true;
        console.log("Chhhhhhh==2");
        break;
      } else if (
        (isservice == 0 &&
          wantbatchno &&
          (!itembatchno ||
            itembatchno === "" ||
            itembatchno === null ||
            itembatchno === undefined)) ||
        (packingqty === "0" && packingextraqty === "0") ||
        (packingqty === "" && packingextraqty == "") ||
        (packingqty === 0 && packingextraqty === "0") ||
        (packingqty === "0" && packingextraqty === 0) ||
        (packingqty === 0 && packingextraqty === 0) ||
        rowData[i].salerate == "undefined" ||
        rowData[i].salerate == 0
      ) {
        isButtonDisabled = true;
        console.log("Chhhhhhh==3");
        break;
      }

      if (
        rowData[i]?.doctype === "DC" &&
        rowData[i]?.qty + rowData[i]?.freeqty !== rowData[i]?.dcqty
      ) {
        isButtonDisabled = true;
        console.log("Chhhhhhh==4");
      }
    }
    console.log("Chhhhhhh==5");
    useChangedRows.updateValue(
      "validated",
      !isButtonDisabled ? "true" : "false"
    );
  }, [rowData]);

  return (
    <div>
      <Modal
        open={displayMessages}
        onCancel={() => {
          setDisplayMessages(!displayMessages);
        }}
        onOk={() => {
          useChangedRows.updateValue(
            "message",
            document.getElementById("message").value
          );
          useChangedRows.updateValue(
            "narration",
            document.getElementById("narration").value
          );
          setDisplayMessages(!displayMessages);
        }}
      >
        <div style={{ display: "flex", flexDirection: "column" }}>
          <p>Message for Supl</p>
          <Input
            id={"message"}
            placeholder="Enter Message for Supl"
            defaultValue={useChangedRows.getValue("message")}
          ></Input>
          <br /> <p>Message for Self</p>
          <Input
            id={"narration"}
            placeholder="Enter Message for Self"
            defaultValue={useChangedRows.getValue("narration")}
          ></Input>
        </div>
      </Modal>

      <Modal
        open={displayPrevPO}
        onCancel={() => {
          setDisplayPrevPO(!displayPrevPO);
        }}
        onOk={() => {
          setDisplayPrevPO(!displayPrevPO);
        }}
      >
        <div>
          Select Items from Pending Purchase Orders
          <Collapse
            collapsible="header"
            defaultActiveKey={["0"]}
            style={{ height: 300, overflowX: "scroll" }}
          >
            {previousPOList?.Message?.map((item, index) => {
              return (
                <Panel
                  header={`Purchase Order : ${item?.ponumber}`}
                  key={index}
                >
                  <List
                    size="small"
                    itemLayout="horizontal"
                    bordered
                    dataSource={item?.items}
                    renderItem={(it, ind) => {
                      it.selectedQty = it?.selectedQty
                        ? it.selectedQty
                        : it?.qty;
                      return (
                        <List.Item>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              width: "100%",
                              alignItems: "center",
                            }}
                          >
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              {it?.itemdescn} <br></br>Quantity : {it?.qty}
                            </div>
                            <input
                              className="tr001Input"
                              style={{ width: 80 }}
                              type={"number"}
                              defaultValue={it?.selectedQty}
                              onChange={(e) => {
                                it.selectedQty = e.target.value;
                              }}
                            ></input>
                            <Button
                              icon={<FcAddDatabase />}
                              onClick={() => {
                                console.log("Changed Object is ", it);
                                setInfoFromPO((p) => [...p, it]);
                              }}
                            ></Button>
                          </div>
                        </List.Item>
                      );
                    }}
                  />
                </Panel>
              );
            })}
          </Collapse>
        </div>
      </Modal>
      <Collapse collapsible="header" defaultActiveKey={["1"]}>
        <Panel header="Header Details" key="1">
          <div>
            <Space
              style={{
                alignItems: "end",
                display: "flex",
                flexWrap: "wrap",
                gap: "30px",
                marginTop: "1%",
              }}
            >
              {/* Select Supplier */}

              {/* <div style={{ zIndex:10 }}>
                <SaveDropdown
                  displayLabel="Domain Locations"
                  alignment="column"
                  apiurl={getDomainLocationUrl}
                  apikey="locationrecno"
                  selectionLabel="descn"
                  selectionValue="recno"
                  body={customerData}
                />
              </div> */}
              {/*/  taksid -338 rajendra*/}
              {/* <CustomerLocationList  key={count}  />*/}

              <div style={{ zIndex: 4, position: "relative", pointerEvents: rowData.length > 0 ? "none" : "auto", }}>
                <SaveDropdown
                  displayLabel="Party"
                  placeholder="Party"
                  alignment="column"
                  apikey={"partyrecno"}
                  apiurl={getCustomerListWithoutDomain}
                  selectionLabel="descn"
                  selectionValue="recno"
                  returnValue={setValforinput}
                  isrequired
                  defaultSelected={val?.locationrecno}
                  disabled={rowData && rowData.length > 0?1:0}
                 key={count+5}
                />
                <SaveTextInput
                pageIndex={pageIndex}
                  //displayLabel="Referance Number"
                  //alignment="column"
                  apikey="partydescn"
                  defaultValue={val?.descn}
                  //id="tr003-grn-dcnumber"
                  placeholder={"Party Name"}
                  //displayLabel={"Party Name"}
                  change
                  type={"text"}
                  ///{Pradeep task-id -260}
                  key={count}
                />
              </div>
              <div>
                <SaveDropdown
                  displayLabel="Party Locn"
                  placeholder="Party Locn"
                  alignment="column"
                  apiurl={getdomainlocationlistactive}
                  apikey={"partylocationrecno"}
                  selectionLabel="descn"
                  selectionValue="recno"
                  returnValue={setPartyLoc}
                  defaultSelected={partyloc?.locationrecno}
                />
                <SaveTextInput
                  // displayLabel="Party Location"
                  //alignment="column"
                  pageIndex={pageIndex}
                  apikey="partylocationdescn"
                  defaultValue={partyloc?.descn}
                  //id="tr003-grn-dcnumber"
                  placeholder={"Party Locn"}
                  //displayLabel={"Party Locn"}
                  change
                  type={"text"}
                  ///{Pradeep task-id -260}
                  key={count+7}
                />
              </div>
              {/* <div style={{ zIndex: 10 }}>
                <SaveDropdown
                  displayLabel="Salesman"
                  alignment="column"
                  //apiurl="getdomaincustomersalesman"
                  apiurl={getDomainCustomerSalesman}
                  apikey="salesmanrecno"
                  selectionLabel="customerdescn"
                  selectionValue="customerrecno"
                  body={{ customerrecno: customerData?.customerrecno }}
                />
              </div>
              <div>
                <SaveDropdown
                  displayLabel="Surgeon"
                  alignment="column"
                  apiurl="getdomaincustomerrefby"
                  apikey="refbyrecno"
                  selectionLabel="refbydescn"
                  selectionValue="refbyrecno"
                  body={{ customerrecno: customerData?.customerrecno }}
                />
              </div> */}
              {/* Taks id-338 Rajendra the point no.3 start */}
              <div
                style={{
                  marginTop: "1%",
                  width: "106%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "30px",
                }}
              >
                {/* <Button className="btn" type="dashed" style={{ float: "left" }}>Item Issue Number : TBA</Button> */}
                <div onKeyDown={enterPress}>
                  <div style={{ position: "relative" }}>
                    <label
                      // htmlFor={id}
                      className={`label ${"focused"}`}
                      style={{
                        // zIndex: 1000,
                        whiteSpace: "nowrap",
                        wordWrap: "unset",
                        position: "absolute",
                        top: "-19px",
                        left: "12px",
                        //backgroundColor: "white",
                        padding: "0 4px",
                        fontSize: "12px",
                        color: "black",
                        pointerEvents: "none",
                        transform: "translateY(0)",
                        transition:
                          "top 0.3s, transform 0.3s, font-size 0.3s, color 0.3s",
                      }}
                    >
                      Item Issue No.
                    </label>
                    <input
                      style={{ ...poNumberStyle, marginTop: "0px" }}
                      placeholder={"To be allotted"}
                      disabled
                    />
                  </div>
                </div>

                <div
                  style={{
                    float: "left",
                    zIndex: 3,
                    position: "relative",
                    marginLeft: "2%",
                  }}
                >
                  <SaveDatePicker
                    displayLabel="Item Issue Date"
                    placeholder="Item Issue Date"
                    disabled={rowData && rowData.length > 0?1:0}
                    key={count}
                    // defaultValue={auth?.state?.userdata?.workdate}
                    defaultValue={dcDate}
                    apiKey="trdate"
                    id={"tr014-dc-dcdate"}
                    dateLessthan={auth?.state?.userdata?.workdate}
                    postAlertMsg={`DC Date cannot be greater than Today's Date`}
                    returnValue={(e) => {
                      setDcDate(e);
                      let tempCheck =
                        document.getElementById("tr014-dc-dcdate");
                      if (tempCheck.value === "") {
                        document.getElementById("tr014-dc-dcdate").focus();
                        return true;
                      }
                      return;
                    }}
                  />
                </div>
                <Button
                  className="btn"
                  style={{ float: "left", marginLeft: "1%" }}
                  type="dashed"
                  onClick={(e) => setDisplayMessages(!displayMessages)}
                >
                  Add / View Messages
                </Button>
                {/* <Button
                style={{ float: "left", marginTop: "5%", marginLeft: "1%" }}
                type="dashed"
                onClick={() => setDisplayPrevPO(!displayPrevPO)}
              >
                Select Invoice
              </Button> */}
              </div>
              {/* Taks id-338 Rajendra the point no.3 end */}
            </Space>

            {/* <Space size="large" style={{ alignItems: "end" }}>
             
            </Space><br/> */}
          </div>
        </Panel>
        {/* <Panel header="Additional Deatails" key="2" ></Panel> */}
        <Panel header="Items Details" key="2">
          <Space size="large" style={{ alignItems: "end", marginTop: "-1%" }}>
            <div style={{ position: "relative", zIndex: 2 }}>
              {/* <ItemSearch
          inputRef={itemSearch} ///task-id-338 here input ref is change
            forFlag={"D"}
            returnValue={setSelectedItem}
            supplierdata={val?.customertyperecno}
            dropdown
            defaultSelectedItem={getSelectedItem}
            forPage={"PO"}
           selection
            barcodeReturnValue={(e) => {
                    //{ start Pradeep task id -dc start }

                    // Write a function to add to existing Items and check only if it has serial number

                    if (e?.itemasbatch == 1) {
                      fetchItems
                        .addBarcodeScannedItemToTable(
                          rowData,
                          e,
                          "PIF",
                          igstappl,
                          pageDetails,
                          "P"
                        )
                        .then((res) => {
                          // setRowData(res);
                          //  nirmal-purchaseinvoice ::: Solved the issue of deleting the entire item from the grid if the old barcode is entered.
                          if (res?.length == 0) {
                            setRowData(rowData);
                            // return
                          } else {
                            setRowData(res);
                            setChange(change + 1);
                          }
                        })
                        .catch((err) => {
                          alert(err);
                        });
                    } else {
                      setSelectedItem(e);
                    }
                  }}
          /> */}

              <ItemSearch
                itemSearchRef={itemSearch} // task id-256 rajendra auto focus issue itemSearchRef-inputRef because in common components inputRef is used
                forFlag={"D"}
                returnValue={(e) => {
                  setSelectedItem(e);
                }}
                supplierdata={val?.recno}
                dropdown
                defaultSelectedItem={getSelectedItem}
                forPage={"PO"}
                selection={1}
                barcodeReturnValue={(e) => {
                  console.log("itemsearch e", e);

                  // Check the conditions for serial number and stock availability
                if(val?.customerrecno){
                  if (e?.serialnofound === false) {
                    notification.error({
                      message: `Error`,
                      duration: 1,
                      description: `Serial number not exist in system`,
                      style: { background: "#efa7a7" },
                    });
                    return;
                  } else if (
                    e?.serialnofound === true &&
                    e?.itembatcharrayfound?.length === 0
                  ) {
                    notification.error({
                      message: `Error`,
                      duration: 1,
                      description: `Stock not available`,
                      style: { background: "#efa7a7" },
                    });
                    return;
                  }

                  // Check the inward date condition for items with serial numbers
                  const bill = e?.itembatcharrayfound[0];
                  const inwardDate = bill?.inwarddate;
                  // Assuming trdate is available in the scope

                  // Perform validation only if item has a batch
                
                  if (e?.itemasbatch == 1) {
                    console.log("DC Date",inwardDate,dcDate,e)
                    // Check if inward date is less than the transaction date
                    if (inwardDate && inwardDate >Number(dcDate)) {
                      notification.error({
                        message: "Error",
                        duration: 1,
                        description:
                          " Item Issue date is less than the Inward date ",
                        style: { background: "#efa7a7" },
                      });
                      return;
                    }

                    // Proceed with adding the barcode scanned item to the table
                    fetchItems
                      .addBarcodeScannedItemToTable(
                        rowData,
                        e,
                        "PIF",
                        igstappl,
                        pageDetails,
                        "MTIS",
                        dcDate // Include transaction date in the payload
                      )
                      .then((res) => {
                        if (res?.length == 0) {
                          setRowData(rowData);
                        } else {
                          setRowData(res);
                          setChange(change + 1);
                        }
                      })
                      .catch((err) => {
                        notification.error({
                          message: "Error",
                          description: err.toString(),
                          style: { background: "#efa7a7" },
                        });
                      });
                  } else {
                    setSelectedItem(e);
                  }
                }else{
                  notification.error({
                    message: "Error",
                    duration: 1,
                    description:
                      " Party not selected ",
                    style: { background: "#efa7a7" },
                  });
                }
                }}
              />
            </div>
            <div style={{ alignItems: "end", marginTop: "-12%" }}>
              <form style={{ ...callbackTextInputStyles, alignItems: "end" }}>
                {pageDetails?.wantpackingextra ? (
                  <div onKeyDown={enterPress}>
                    <p>
                      Boxes :
                      {getSelectedItem?.conversionfactor
                        ? +getSelectedItem?.conversionfactor + " Pcs/Box"
                        : 1 + " Pcs/Box"}
                    </p>
                    <input
                      ref={boxRef}
                      ///{Pradeep task-id -260}
                      disabled={
                        getSelectedItem?.packingextrarecno > 0 ? false : true
                      }
                      style={{ ...inputStyles }}
                      value={box}
                      onChange={(e) => setBox(e.target.value)}
                      type="number"
                    />
                  </div>
                ) : null}
                <div onKeyDown={enterPress}>
                  <p>Pieces</p>
                  <input
                    ref={piecesRef}
                    ///{Pradeep task-id -260}
                    disabled={
                      getSelectedItem?.packingextrarecno == 0 ? false : true
                    }
                    style={{ ...inputStyles }}
                    value={selectedQty}
                    type="number"
                    onChange={(e) => setSelectedQty(e.target.value)}
                  />
                </div>
                {/* <div onKeyDown={enterPress}>
              <p>Free Boxes</p>
              <input
                ref={piecesRef}
                style={{ ...inputStyles }}
                value={freeBox}
                type="number"
                onChange={(e) => setFreeBox(e.target.value)}
              />
            </div>

            <div onKeyDown={enterPress}>
              <p>Free Qty</p>
              <input
                ref={piecesRef}
                style={{ ...inputStyles }}
                value={freeQty}
                type="number"
                onChange={(e) => setFreeQty(e.target.value)}
              />
            </div> */}

                {getSelectedItem?.wantbatchno ? (
                  <>
                    {" "}
                    <div onKeyDown={enterPress}>
                      <p>Batch No</p>
                      <input
                        ref={piecesRef}
                        style={{ ...inputStyles, width: "150px" }}
                        value={batchNo}
                        onChange={(e) => setBatchNo(e.target.value)}
                        onBlur={(e) => {}}
                      />
                    </div>
                    {pageDetails?.wantmanudate > 0 ? (
                      <div>
                        <p>Manufacture Date</p>
                        <CallbackDateInput
                          defaultValue={manuDate}
                          id={"dc-manufacture-date-callback"}
                          // displayLabel={"Expiry Date"}
                          dateLessthan={auth?.state?.workdate}
                          apiKey={"manudate"}
                          returnValue={(e) => {
                            setManuDate(e);
                            // document.getElementById("dc-exp-devlivery-date-callback").focus();
                          }}
                          initial
                        />
                      </div>
                    ) : null}
                    {pageDetails?.wantexpdate > 0 ? (
                      <div>
                        <p>Expiry Date</p>
                        <CallbackDateInput
                          defaultValue={expDate}
                          id={"dc-expiry-date-callback"}
                          // displayLabel={"Expiry Date"}
                          key={count}
                          apiKey={"expdate"}
                          dateGreaterthan={manuDate}
                          postAlertMsg="Expiry Date should not be lesser than Mfg. Date"
                          returnValue={(e) => {
                            if (e < manuDate) {
                              alert(
                                "Expiry Date should be greater than Mfg. Date"
                              );

                              return;
                            }
                            if (e < auth?.state?.workdate) {
                              alert(
                                "Expiry Date should be greater than WorkDate"
                              );
                              return;
                            }
                            setExpDate(e);
                          }}
                        />
                      </div>
                    ) : null}
                  </>
                ) : null}
                {/* <div onKeyDown={enterPress}>
              <p>Rate</p>
              <input
                type="number"
                style={{ ...inputStyles }}
                value={purchaseRate}
                onChange={(e) => {
                  setPurchaseRate(e.target.value);
                }}
                onBlur={(e) => {
                  if (e.target.value != 0 && e.target.value > 0) {
                    if (box == 0 && selectedQty == 0) {
                      alert("Enter non-zero values in Box / Pieces");
                      boxRef.current.focus();
                    } else {
                      btnRef.current.focus();
                    }
                  } else {
                    alert("Cannot enter 0 as rate");
                  }
                }}
              />
            </div>
            <div onKeyDown={enterPress}>
              <p>Discount %</p>
              <input
                style={{ ...inputStyles }}
                value={discountPer}
                type="number"
                onChange={(e) => {
                  setDiscountPer(e.target.value);
                  var dicsountAmount =
                    (Number(purchaseRate) * Number(e.target.value)) / 100;
                  setDiscount(dicsountAmount.toFixed(2));
                }}
              />
            </div>

            <div onKeyDown={enterPress}>
              <p>Discount</p>
              <input
                style={{ ...inputStyles }}
                value={discount}
                type="number"
                onChange={(e) => setDiscount(e.target.value)}
              />
            </div>

            <div onKeyDown={enterPress}>
              <p>Gross Amount</p>
              <div style={{ ...grossAmtStyle, width: 120 }}>
                {Number(grossAmt).toFixed(2)}
              </div>
            </div> */}

                <div>
                  <Button
                    className="btn item-search-btn"
                    //style={{ ...btnStyles }}
                    icon={<IoAperture size="1.2rem" />}
                    size="large"
                    onClick={() => {
                      addItemstoTable();
                    }}
                  >
                    Add
                  </Button>
                </div>
              </form>
            </div>
          </Space>
          <div
            style={{ zIndex: 0, position: "relative", margin: "24px auto 0" }}
          >
            {rowData.length > 0 && (
              <Table
                className="tr013-items-grid"
                scroll={{
                  y: 500,
                  x: 500,
                }}
                size="large"
                bordered
                pagination={false}
                columns={piColumns(
                  igstappl,
                  setRowData,
                  rowData,
                  setOpenItem,
                  setScandata,
                  auth?.state?.workdate,
                  pageDetails
                )?.filter((item) => !item.hidden)}
                dataSource={rowData}
                onChange={(e) => {
                  console.log("ONCHANGE HANDLE", e);
                }}
                summary={(pageData) => {
                  let qty = 0;
                  let free = 0;
                  let packingextraqty = 0;
                  let packingqty = 0;
                  let freepackingextraqty = 0;
                  let freepackingqty = 0;

                  var existingObject = useChangedRows.getValue();
                  console.log("Existing Object is", existingObject);
                  pageData.forEach((item) => {
                    qty = Number(qty) + Number(item?.qty);
                    free = Number(free) + Number(item?.free);
                    packingextraqty =
                      Number(packingextraqty) + Number(item?.packingextraqty);
                    packingqty = Number(packingqty) + Number(item?.packingqty);
                    freepackingextraqty =
                      Number(freepackingextraqty) +
                      Number(item?.freepackingextraqty);
                    freepackingqty =
                      Number(freepackingqty) + Number(item?.freepackingqty);
                  });

                  return (
                    <Table.Summary fixed>
                      {!igstappl ? (
                        <Table.Summary.Row>
                          <Table.Summary.Cell></Table.Summary.Cell>

                          {/* <Table.Summary.Cell></Table.Summary.Cell>
                    <Table.Summary.Cell></Table.Summary.Cell>
                    <Table.Summary.Cell></Table.Summary.Cell> */}
                          <Table.Summary.Cell>
                            <p style={{ fontWeight: "bold" }}>Total : </p>
                          </Table.Summary.Cell>
                          {pageDetails?.wantpackingextra ? (
                            <Table.Summary.Cell>
                              {packingextraqty}
                            </Table.Summary.Cell>
                          ) : null}
                          <Table.Summary.Cell>
                            <p style={{ fontWeight: "bold" }}>{packingqty} </p>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell>
                            <p style={{ fontWeight: "bold" }}>{qty} </p>
                          </Table.Summary.Cell>

                          {pageDetails?.wantfreeqty ? (
                            <Table.Summary.Cell>
                              {freepackingextraqty}
                            </Table.Summary.Cell>
                          ) : null}

                          {pageDetails?.wantfreeqty &&
                          pageDetails?.wantpackingextra ? (
                            <Table.Summary.Cell>
                              {freepackingqty}
                            </Table.Summary.Cell>
                          ) : null}

                          {pageDetails?.wantfreeqty ? (
                            <Table.Summary.Cell>{free}</Table.Summary.Cell>
                          ) : null}

                          {pageDetails?.wantbatchno > 0 ? (
                            <Table.Summary.Cell></Table.Summary.Cell>
                          ) : null}
                          {pageDetails?.wantmanudate > 0 ? (
                            <Table.Summary.Cell></Table.Summary.Cell>
                          ) : null}
                          {pageDetails?.wantexpdate > 0 ? (
                            <Table.Summary.Cell></Table.Summary.Cell>
                          ) : null}
                          <Table.Summary.Cell></Table.Summary.Cell>
                          <Table.Summary.Cell></Table.Summary.Cell>
                          <Table.Summary.Cell></Table.Summary.Cell>
                          <Table.Summary.Cell></Table.Summary.Cell>
                          <Table.Summary.Cell></Table.Summary.Cell>
                          {/* <Table.Summary.Cell >
                      <p style={{ fontWeight: 'bold' }}>{existingObject?.taxableamount}</p>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell></Table.Summary.Cell>
                    <Table.Summary.Cell >
                      <p style={{ fontWeight: 'bold' }}>{existingObject?.sgstamt}</p>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell></Table.Summary.Cell>
                    <Table.Summary.Cell>
                      <p style={{ fontWeight: 'bold' }}>{existingObject?.cgstamt}</p>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell >
                      <p style={{ fontWeight: 'bold' }}>{existingObject?.amount} </p>
                    </Table.Summary.Cell> */}
                        </Table.Summary.Row>
                      ) : (
                        <Table.Summary.Row>
                          <Table.Summary.Cell index={1}>
                            <p style={{ fontWeight: "bold" }}>Total : </p>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell></Table.Summary.Cell>
                          <Table.Summary.Cell></Table.Summary.Cell>
                          {pageDetails?.wantpackingextra ? (
                            <Table.Summary.Cell>
                              {packingextraqty}
                            </Table.Summary.Cell>
                          ) : null}
                          <Table.Summary.Cell>
                            <p style={{ fontWeight: "bold" }}>{packingqty}</p>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell>
                            <p style={{ fontWeight: "bold" }}>{qty}</p>
                          </Table.Summary.Cell>
                          {pageDetails?.wantfreeqty ? (
                            <Table.Summary.Cell>
                              {freepackingextraqty}
                            </Table.Summary.Cell>
                          ) : null}

                          {pageDetails?.wantfreeqty &&
                          pageDetails?.wantpackingextra ? (
                            <Table.Summary.Cell>
                              {freepackingqty}
                            </Table.Summary.Cell>
                          ) : null}

                          {pageDetails?.wantfreeqty ? (
                            <Table.Summary.Cell>{free}</Table.Summary.Cell>
                          ) : null}

                          <Table.Summary.Cell></Table.Summary.Cell>
                          <Table.Summary.Cell></Table.Summary.Cell>
                          <Table.Summary.Cell></Table.Summary.Cell>
                          <Table.Summary.Cell></Table.Summary.Cell>
                          <Table.Summary.Cell></Table.Summary.Cell>
                          <Table.Summary.Cell>
                            <p style={{ fontWeight: "bold" }}>
                              {" "}
                              {existingObject?.taxableamount}
                            </p>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell></Table.Summary.Cell>
                          <Table.Summary.Cell>
                            <p style={{ fontWeight: "bold" }}>
                              {existingObject?.igstamt}
                            </p>
                          </Table.Summary.Cell>

                          <Table.Summary.Cell>
                            <p style={{ fontWeight: "bold" }}>
                              {" "}
                              {existingObject?.amount}{" "}
                            </p>
                          </Table.Summary.Cell>
                        </Table.Summary.Row>
                      )}
                    </Table.Summary>
                  );
                }}
              />
            )}
          </div>
        </Panel>
      </Collapse>
      {/* Table for Selection  */}

      {openItem && (
        <ScanTable
          data={scandata}
          openItem={openItem}
          setOpenItem={setOpenItem}
          rowData={rowData}
          setRowData={setRowData}
          renderer={renderer}
          setRenderer={setRenderer}
          setValidator={setValidator}
          forFlag="S"
        />
      )}
    </div>
  );
}

export default MaterialIssue;

const mainFrame = {
  width: "95vw",
  margin: "auto",
};

const topRowFilters = {
  gap: "24px",
  display: "flex",
  alignItems: "center",
};

// applying padding to align input rows inline
const callbackTextInputStyles = {
  display: "flex",
  flexWrap: "wrap",
  alignItems: "center",
  gap: "10px",
  paddingTop: "10px",
};

// add to table btn styles
const btnStyles = {
  display: "flex",
  alignItems: "center",
  gap: "8px",
};

const inputStyles = {
  padding: "10px",
  border: "1px solid rgb(120,120,120)",
  width: "80px",
  fontSize: "16px",
  marginTop: "7px",
  borderRadius: "6px",
};

const grossAmtStyle = {
  textAlign: "right",
  border: "1px solid gray",
  padding: "4px 8px 4px 4px",
  fontSize: "18px",
  width: "80px",
  borderRadius: "8px",
  marginTop: "6px",
};
const poNumberStyle = {
  marginTop: "4px",
  border: "1px solid #dfdfdf",
  borderRadius: "8px",
  width: "200px",
  padding: "8px",
  fontSize: "16px",
};

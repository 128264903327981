import { Button, Popconfirm } from "antd";
import { AiFillDelete } from "react-icons/ai";
import { removeByAttribute } from "../../CommonControllers/AppFunction";
import CallbackDateInput from "../../CommonControllers/Components/CallbackComponents/CallbackDateInput";
import "../../CommonControllers/global.css";
import ItemReceipts from "./ItremReceipts";
import SaveDropdown from "../../CommonControllers/Components/SaveComponents/SaveDropdown";
import { getdomainlocationlistactive } from "../../CommonControllers/Urls";

export const grnColumns = (
  igstappl,
  setRowData,
  rowData,
  setOpenItem,
  scandata,
  setScandata,
  workdate,
  setRenderer,pageDetails
) => {

  console.log("rowData---", rowData)
  return [
    {
      title: " ",
      dataIndex: "active",
      key: "active",
      width: "70px",
      render: (_, text) => {
        return (
          <Popconfirm
            title="Do you want to Delete the changes?"
            onConfirm={(e) => {
              console.log("text", text);
              handleRemove(text, setRowData, rowData);
            }}
          >
            <Button icon={<AiFillDelete />}></Button>
          </Popconfirm>
        );
      },
    },
    {
      title: "Item Description",
      dataIndex: "itemdescn",
      key: "itemdescn",
      width: "max-content",
      align: "left", // nohit-388 set Align for column
    },

    {
      hidden: !pageDetails?.wantpackingextra ? true : false,
      title: "Boxes",
      //{start     Pradeep, task id-238  changes box  to packingextraqty key     start}
      dataIndex: "packingextraqty",
      key: "packingextraqty",
      //{{end            end }}
      width: "6%",
      align: "right",
      render: (_, text) => {
        console.log("text in ItemReceipts", text)
        return (

          <input
            className={`tr001Input tr001Box`}
            //{Pradeep task id -255}
            disabled={text.packingextrarecno > 0 ? false : true}
            defaultValue={text?.packingextraqty} //~#packingextraqty
            type="number"
            onFocus={(e) => e.target.select()}
            //{Pradeep task id -255}
            value={text.packingextraqty}
            onChange={(e) => {
              if (e.target.value < 0) {
                e.target.value = null;
                return false;
              }
              const regex = /^[0-9\b]+$/;
              // if (regex.test(e.target.value) && (Number(e.target.value) + Number(text.packingextraqty)) > 0) 

              if (e.target.value === "" || regex.test(e.target.value)) {
                text.packingextraqty = e.target.value;
                //{start Pradeep, task id-238 }
                handleChange(text, setRowData, igstappl, rowData, scandata, setScandata);

              }

              //{start Pradeep, task id-238 }
              //{Pradeep task id -255}
              else if ((e.target.value) == "0" && (text.conversionfactor == 12)) {
                console.log("hiiiop")
                text.packingextraqty = text.itembatcharray.length / text.conversionfactor || 1;
                handleChange(text, setRowData, igstappl, rowData, scandata, setScandata);
                //{END         END }

              } else {
                // console.log("1111113")
                alert("Invalid Input");
                e.target.value = "";
                text.packingextraqty = e.target.value;
                //{start Pradeep, task id-238 }
                handleChange(text, setRowData, igstappl, rowData, scandata, setScandata);
              }

            }}


            onBlur={(e) => {
              //{Pradeep task id -255}
              if ((e.target.value) == "0" && text.conversionfactor == 12) {
                if ((Number(text.qty) == 0)) {

                  let count = Number(text.packingqty) + Number((text.packingextraqty * (text.conversionfactor || 1)))

                  console.log("count", count)
                  if (count >= text.itembatcharray.length) {

                    text.packingextraqty = 0
                  } else {
                    alert(
                      "Quantity cannot be less than scanned Quantity,\n First Remove Items from Scanned List"
                    )
                    // console.log("1234",text.itembatcharray.length -count)
                    text.packingextraqty = ((text.itembatcharray.length) - (count)) / (text.conversionfactor);
                    // console.log("12345", text.freepackingextraqty)
                  }
                  handleChange(text, setRowData, igstappl, rowData, scandata, setScandata);


                }
                else {
                  e.target.value =
                    text?.itembatcharray?.length -
                    (Number(text?.packingqty));
                  console.log("e.target.value", e.target.value)

                  if (e.target.value > 0) {

                    text.packingextraqty = Number(e.target.value)
                  } else {
                    text.packingextraqty = 0
                  }


                  console.log("text.packingextraqty", text.packingextraqty)
                  handleChange(text, setRowData, igstappl, rowData, scandata, setScandata);
                }
              }

              //{END         END }

              // Validate the Entered Quantity with the new Entered Quantity
              if (
                //{start Pradeep, task id-238 start}
                //{Pradeep task id -255}
                (Number(text?.qty) <
                  text?.itembatcharray?.length) && (text.conversionfactor == 1)
                //{END         END }
              ) {
                e.target.value =
                  text?.itembatcharray?.length -
                  (Number(text?.packingqty));
                console.log("e.target.value", e.target.value)

                alert(
                  "Quantity cannot be less than scanned Quantity,\n First Remove Items from Scanned List"
                );
                text.packingextraqty = Number(e.target.value)
                console.log("text.packingextraqty", text.packingextraqty)
                handleChange(text, setRowData, igstappl, rowData, scandata, setScandata);
              }

              // Validate the Entered Quantity with the new Entered Quantity

              if (text?.wantbatchno === 1) {
                console.log("wantbatchno?.qty", text?.qty, text?.free);
                if (Number(text?.qty) + Number(text?.free) == 0) {
                  alert("Quantity cannot be less than or equal to zero.");
                }
              }
            }}
          />





        );
      },
    },
    {
      title: "Pieces",
      //{ start  Pradeep, task id-238  changes pieces to packingqty key}
      dataIndex: "packingqty",
      key: "packingqty",
      //{end    end }
      width: "6%",
      align: "right",
      render: (_, text) => {
        return (
          <input
            className={`tr001Input tr001Box`}
            //{Pradeep task id -255}
            // disabled={text.packingextrarecno == 0 ? false : true}
            key={text?.packingqty}
            defaultValue={text?.packingqty} //~# packingqty
            type="number"
            onFocus={(e) => e.target.select()}
            onChange={(e) => {

              console.log("Changed Value of the Pieces is ", e.target.value)
              if (e.target.value < 0) {
                e.target.value = null;
                return false;
              }
              const regex = /^[0-9\b]+$/;
              // if (e.target.value === "" || regex.test(e.target.value)) {   //if 0 is there else is not working 
              if (regex.test(e.target.value) && (Number(e.target.value) + Number(text.packingqty)) > 0) {
                //{   Pradeep, task id-238  changes pieces to packingqty key}
                text.packingqty = e.target.value;
                handleChange(text, setRowData, igstappl, rowData);
              }
              else {
                // e.target.value = text.packingqty
                //{  Pradeep, task id-238  changes pieces to packingqty key}
                //{ start Pradeep task id -255 start }
                {
                  alert("Invalid Input");
                  e.target.value = "";
                  text.packingqty = e.target.value;
                  // Pradeep, task id-238 }
                  handleChange(text, setRowData, igstappl, rowData, scandata, setScandata);
                }
                //{end         end }
                // text.packingqty = e.target.value;
                // alert("Box and Pieces cannot be 0")
              }
            }}

            onBlur={(e) => {
              // Validate the Entered Quantity with the new Entered Quantity
              console.log("text?.pieces", text?.packingqty)
              if (Number(text?.qty) < text?.itembatcharray?.length) {
                e.target.value = text?.itembatcharray?.length - (Number(text?.packingextraqty))
                console.log("e.target.value12", e.target.value)
                alert(
                  "Quantity cannot be less than scanned Quantity,\n First Remove Items from Scanned List"
                );
                //{ Pradeep, task id-238  changes pieces to packingqty key}
                text.packingqty = e.target.value;
                handleChange(text, setRowData, igstappl, rowData);
              }
            }}


          />
        );
      },
    },
    {
      hidden: !pageDetails?.wantdomainlocation ? true : false,
      width: 300,
      title: "Location",
      dataIndex: "location",
      align: "left",
      render: (_, text, index) => {
        if (text.itemasbatch === 1) {
          // Calculate dynamic zIndex based on the index
          let dynamicZindex = 2000 - index;
    
          return (
            <div style={{ zIndex: dynamicZindex, position: "relative" }}>
              <SaveDropdown
                apikey={"locationrecno"}
                minInput={0}
                inputValue={20}
                displayLabel={"Locn"}
                placeholder={"Locn"}
                apiurl={getdomainlocationlistactive}
                selectionLabel={"descn"}
                selectionValue={"locationrecno"}
                returnValue={(e) => {
               
                  //text.location = e;
                  text.locationrecno=e.locationrecno
                  handleChange(
                    text,
                    setRowData,
                    igstappl,
                    rowData,
                    scandata,
                    setScandata
                  );
                }}
                width={"250px"}
                alignment="column"
              />
            </div>
          );
        }
      },
    },

    {
      hidden: pageDetails?.wantbatchno > 0 ? false : true,
      title: "Batch No",
      dataIndex: "itembatchno",
      key: "itembatchno",
      width: "8%",
      align: "left",
      render: (_, text) => {
        console.log("rowData---555",text)
        return (
          <>{
            text.wantbatchno?<input
            className={`tr001Input tr001Box`}
            style={{ textAlign: "left" }}
            defaultValue={text?.itembatchno}
            type="text"
            onFocus={(e) => e.target.select()}
            //disabled={text.wantbatchno?false:true}
            onChange={(e) => {
              if (e.target.value < 0) {
                e.target.value = null;

                return false;
              }
              text.itembatchno = e.target.value;
              handleChange(text, setRowData, igstappl, rowData);
            }}
          />:<p>{text?.itembatchno}</p>
          }
          </>
        );
      },
    },
    {
      hidden: pageDetails?.wantmanudate > 0 ? false : true,
      title: "Mfg Date",
      dataIndex: "mfgdate",
      key: "mfgdate",
      width: "15%",
      align: "left", // nohit-388 set Align for column
      render: (_, text) => {
        console.log("check", text);
        return (
          <div>
            <CallbackDateInput
              apiKey={"mfgdate"}
              id={"grn-mfgdate" + text.shortguid}
              defaultValue={text?.mfgdate}
              returnValue={(e) => {
                text.mfgdate = e;
                handleChange(
                  text,
                  setRowData,
                  igstappl,
                  rowData,
                  null,
                  null,
                  setRenderer
                );
              }}
              initial
              dateLessthan={workdate}
            />
          </div>
        );
      },
    },
    {
      hidden: pageDetails?.wantexpdate > 0 ? false : true,
      title: "Expiry Date",
      dataIndex: "expirydate",
      key: "expirydate",
      width: "15%",
      align: "left", // nohit-388 set Align for column
      render: (_, text) => {
        return (
          <div style={{ position: "absolute", zIndex: 99, top: 8 }}>
            <CallbackDateInput
              id={"grn-expdate" + text.shortguid}
              apiKey={"expirydate"}
              defaultValue={text?.expdate}
              returnValue={(e) => {
                text.expdate = e;
                handleChange(
                  text,
                  setRowData,
                  igstappl,
                  rowData,
                  null,
                  null,
                  setRenderer
                );
              }}
              dateGreaterthan={text?.mfgdate}
            />
          </div>
        );
      },
    },


    // {
    //   title: "Accepted Boxes",
    //   dataIndex: "acceptedboxes",
    //   key: "box",
    //   width: "8%",
    //   align: "right",
    //   render: (_, text) => {
    //     return (
    //       <input
    //         className={`tr001Input tr001Box`}
    //         defaultValue={text?.packingextraqtyaccepted} //~# packingextraqtyqccepted
    //         type="number"
    //         onFocus={(e) => e.target.select()}
    //         onChange={(e) => {
    //           if (e.target.value < 0) {
    //             e.target.value = null;
    //             return false;
    //           }
    //           const regex = /^[0-9\b]+$/;
    //           if (e.target.value === "" || regex.test(e.target.value)) {
    //             text.packingextraqtyaccepted = e.target.value;
    //             handleChange(text, setRowData, igstappl, rowData);
    //           }
    //         }}
    //         id={`tr003-grn-`}
    //         disabled={scandata?.lenght > 0}
    //       />
    //     );
    //   },
    // },

    {
      title: " Accepted Pieces",
      dataIndex: "acceptedpieces",
      key: "acceptedpieces",
      width: "8%",
      render: (_, text) => <p>{text?.itembatcharray?.length}</p>,

      // {
      //   return (
      //     <input
      //       className={`tr001Input tr001Pieces`}
      //       defaultValue={text?.packingqtyaccepted} //~# packingqtyaccepted
      //       onFocus={(e) => e.target.select()}
      //       type="number"
      //       onChange={(e) => {
      //         if (e.target.value < 0) {
      //           e.target.value = null;
      //           return false;
      //         }
      //         const regex = /^[0-9\b]+$/;
      //         if (e.target.value === "" || regex.test(e.target.value)) {
      //           text.packingqtyaccepted = e.target.value;
      //           handleChange(text, setRowData, igstappl, rowData);
      //         }
      //       }}
      //       disabled={scandata?.lenght > 0}
      //     />
      //   );
      // },
      align: "right",
    },
    {
      title: "Scan Barcode",
      dataIndex: "barcode",
      align: "left", // nohit-388 set Align for column
      key: "barcode",
      width: "7%",
      render: (_, text) => {
        console.log("Text is", text.itemasbatch);
        if (text.itemasbatch) {
          return (
            <Button
              onClick={() => {
                setOpenItem(true);
                handleChange(
                  text,
                  setRowData,
                  igstappl,
                  rowData,
                  scandata,
                  setScandata
                );
              }}
            // disabled={text?.packingqtyaccepted !== 0 || text?.packingextraqtyaccepted !== 0}
            >
              Item Scan
            </Button>
          );
        } else return null;
      },
    },
  ];
};

export const handleAdd = (
  item,
  setRowData,
  igstappl,
  setSelectedItem,
  shortguid,
  purchaseRate,
  purchaseQty,
  box,
  batchNo,
  mfgdate,
  expdate
) => {
  if (item?.packingextraqty < 0 || item?.packingqty < 0 || item?.purrate < 0) {
    alert("Cannot enter 0");
    return;
  }


  console.log("handle add called", item, setRowData,
  igstappl,
  setSelectedItem,
  shortguid,
  purchaseRate,
  purchaseQty,
  box,
  batchNo,
  mfgdate,
  expdate)

  //~# Input boxes validation
  const boxValue = document.querySelector("tr001-input-box");
  const piecesValue = document.querySelector("tr001-input-pieces");
  const rateValue = document.querySelector("tr001-input-rate");

  console.log("tr001 handle add  ==>", item, boxValue, piecesValue, rateValue);

  var newShortguid = shortguid.getShortguid("POF" + item.itemrecno);
  console.log("tr001 Added Shortguid", newShortguid, box, purchaseQty);
  var purrate = purchaseRate ? purchaseRate : 0.0;
  var cgstrate = item?.cgstrate ? item.cgstrate : 0.0;
  var sgstrate = item?.sgstrate ? item.sgstrate : 0.0;
  var igstrate = item?.igstrate ? item.igstrate : 0.0;

  //var packingqty = Number(item?.packingqty) ? Number(item.packingqty) : 0; //~# here qty of pieces changed to key-name packingqty, box will be freepackingqty
  //var packingextraqty = Number(item?.packingextraqty) ? Number(item.packingextraqty) : 0; //~# box value
  var packingqty = purchaseQty ? purchaseQty : 0; //~# here qty of pieces changed to key-name packingqty, box will be freepackingqty
  var packingextraqty = box ? box : 0; //~# box value


  var totalQty =
    Number(packingextraqty) * Number(item?.conversionfactor) +
    Number(packingqty);
  var packingextraqtyaccepted = item?.packingextraqtyaccepted
    ? item.packingextraqtyaccepted
    : 0;
  var packingqtyaccepted = item?.packingqtyaccepted
    ? item.packingqtyaccepted
    : 0;

  let acceptedTotal =
    Number(packingextraqtyaccepted) * Number(item?.conversionfactor) +
    Number(packingqtyaccepted);

  var taxableamount =
    (Number(packingextraqty) * Number(item?.conversionfactor) +
      Number(packingqty)) *
    Number(purrate);
  var sgstamt = !igstappl ? taxableamount * (sgstrate / 100) : 0.0;
  var cgstamt = !igstappl ? taxableamount * (cgstrate / 100) : 0.0;
  var igstamt = igstappl ? taxableamount * (igstrate / 100) : 0.0;

  var amount = igstappl
    ? taxableamount + igstamt
    : taxableamount + (cgstamt + sgstamt);

  var itembatchno = batchNo ? batchNo :item.itembatchno;   // itembatchno is added 

  var itemObject = {
    ...item,
    shortguid: newShortguid,
    itemrecno: item.itemrecno,
    itemdescn: item.itemdescn,
    packingextraqty: Number(packingextraqty), //~# here box is the value coming from input Boxes
    packingqty: Number(packingqty), //~# here packingqty is the pieces value coming from Pieces input
    packingextraqtyaccepted: Number(packingextraqtyaccepted),
    packingqtyaccepted: Number(packingqtyaccepted),
    qty: totalQty,
    purrate: Number(purrate)?.toFixed(2),
    taxableamount: Number(taxableamount)?.toFixed(2),
    cgstrate: cgstrate,
    sgstrate: sgstrate,
    igstrate: igstrate,
    itembatcharray : item?.batcharray,
    cgstamt: Number(cgstamt)?.toFixed(2),
    sgstamt: Number(sgstamt)?.toFixed(2),
    igstamt: Number(igstamt)?.toFixed(2),
    amount: Number(amount)?.toFixed(2),
    mfgdate: mfgdate, //~! This key-value is for adding empty string to new item in grn table
    expdate: item.expdate?item.expdate:expdate=="."?null:expdate, //~! This key-value is for adding empty string to new item in grn table
    itembatchno: item?.wantbatchno == 1?itembatchno: "."  ,
    packingqty: purchaseQty,
    packingextraqty: box,
    qtyaccepted: acceptedTotal,
    freepackingqty: 0,
    freepackingextraqty: 0,
  };


  console.log("itemObject add called", itemObject)

  setRowData((p) => [...p, itemObject]);
  setSelectedItem(null);
};

export const handleChange = (
  item,
  setRowData,
  igstappl,
  rowData,
  scandata,
  setScandata,
  setRenderer
) => {
  var purrate = item?.purrate ? item.purrate : 0.0;
  var cgstrate = item?.cgstrate ? item.cgstrate : 0.0;
  var sgstrate = item?.sgstrate ? item.sgstrate : 0.0;
  var igstrate = item?.igstrate ? item.igstrate : 0.0;
  var packingextraqty = item?.packingextraqty ? item?.packingextraqty : 0;
  var packingqty = item?.packingqty ? item?.packingqty : 0;
  console.log("item?.conversionfactor", item?.conversionfactor)
  var totalQty =
    //{  Pradeep, task id-238  changes pieces to packingqty key and box to packingextraqty

    Number((packingextraqty) * Number(item?.conversionfactor)) +
    Number(packingqty);
  //{end     end }
  console.log("xyz", totalQty);
  var taxableamount =
    (Number(packingextraqty) * Number(item?.conversionfactor) +
      Number(packingqty)) *
    Number(purrate);
  var packingextraqtyaccepted = item?.packingextraqtyaccepted
    ? item.packingextraqtyaccepted
    : 0;
  var packingqtyaccepted = item?.packingqtyaccepted
    ? item.packingqtyaccepted
    : 0;
  var sgstamt = !igstappl ? taxableamount * (sgstrate / 100) : 0.0;
  var cgstamt = !igstappl ? taxableamount * (cgstrate / 100) : 0.0;
  var igstamt = igstappl ? taxableamount * (igstrate / 100) : 0.0;
  let acceptedTotal =
    Number(packingextraqtyaccepted) * Number(item?.conversionfactor) +
    Number(packingqtyaccepted);
  cgstamt = cgstamt.toFixed(2);
  sgstamt = sgstamt.toFixed(2);

  var amount = igstappl
    ? taxableamount + igstamt
    : taxableamount + (Number(cgstamt) + Number(sgstamt));

  let itembatchno = item?.itembatchno ? item?.itembatchno : null;

  var itemObject = {
    ...item,
    itemrecno: item.itemrecno,
    itemdescn: item.itemdescn,
    packingextraqty: Number(item?.packingextraqty),
    packingqty: Number(item?.packingqty),
    packingextraqtyaccepted: Number(item?.packingextraqtyaccepted),
    packingqtyaccepted: Number(item?.packingqtyaccepted),
    qty: Number(totalQty),
    purrate: Number(purrate)?.toFixed(2),
    taxableamount: Number(taxableamount)?.toFixed(2),
    cgstrate: cgstrate,
    sgstrate: sgstrate,
    igstrate: igstrate,
    cgstamt: Number(cgstamt)?.toFixed(2),
    sgstamt: Number(sgstamt)?.toFixed(2),
    igstamt: Number(igstamt)?.toFixed(2),
    amount: Number(amount)?.toFixed(2),
    expdate: item?.expdate,
    mfgdate: item?.mfgdate,
    qtyaccepted: acceptedTotal,
    itembatchno: rowData?.wantbatchno == 1 ?  itembatchno:"." ,
  };

  var currentItem = rowData.map((itm) => {
    if (itm.itemrecno == item.itemrecno) {
      return { ...itemObject };
    } else {
      return { ...itm };
    }
  });

  // if (setRenderer) {
  //   setRenderer((p) => p + 1);
  // }
  console.log("tr003 ln 451 ??", currentItem);
  setRowData(() => currentItem);

  setScandata(item);
};

export const handleRemove = (item, setRowData, rowData) => {
  var removedArray = removeByAttribute(rowData, "itemrecno", item.itemrecno);
  setRowData(() => [...removedArray]);
};

//~^ GST AMOUNT getting added but not showing percentage values in table : Solved - 21/01/2023
//~! USING shortguid as classname for maipulating the DOM

//scanner logic
export const handleScan = (text, setRowData, igstappl, rowData) => {
  console.log("scan", text, setRowData, rowData);
};

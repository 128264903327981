import React from "react";

const FastPathTable = ({ response, OnSetPage, componentRef }) => {
  console.log("response23", response);
  var arr = response?.filter((item) => {
    return item.fastpath != "";
  });
  console.log("arr:", arr);

  // Making the array response of descn in alphabetical order
  const sortedArray = arr
    .slice()
    .sort((a, b) => a.descn.localeCompare(b.descn));

  return (
    <div>
      <table
        style={{
          position: "sticky",
          minWidth: "400px",
          tableLayout: "fixed",
          marginLeft: "10%",
          marginTop: "20px", // Add margin to the top
          boxShadow: "0 2px 5px rgba(0, 0, 0, 0.2)", // Add a subtle box-shadow for beautification
          borderCollapse: "collapse", // Collapse borders for a cleaner look
        }}
        ref={componentRef}
      >
        <thead
          style={{
            position: "sticky",
            top: 0,
            zIndex: 1,
            backgroundColor: "white",
            padding: "10px",
          
          }}
        >
          <tr>
            <th style={{ padding: "10px" }}>Fastpath</th>
            <th style={{ padding: "10px" }}>Description</th>
          </tr>
        </thead>
        <tbody style={{top:200}}>
          {sortedArray?.map((item, index) => (
            <tr
              key={index}
              onClick={() => OnSetPage(item, index)}
              style={{ backgroundColor: "white", cursor: "pointer" }}
              onMouseOver={(event) => {
                const row = event.target.parentElement;
                row.style.backgroundColor = "skyblue";
                Array.from(row.children).forEach((child) => {
                  child.style.backgroundColor = "skyblue";
                });
              }}
              onMouseOut={(event) => {
                const row = event.target.parentElement;
                row.style.backgroundColor = "white";
                Array.from(row.children).forEach((child) => {
                  child.style.backgroundColor = "white";
                });
              }}
            >
              <td>{item.fastpath}</td>
              <td>{item.descn}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default FastPathTable;

import { Button, Collapse, Space } from "antd";
import "../../../CommonControllers/global.css";
import SaveCheckbox from "../../../CommonControllers/Components/SaveComponents/SaveCheckbox";
import { useEffect, useRef, useState } from "react";
import SaveDropdown from "../../../CommonControllers/Components/SaveComponents/SaveDropdown";
import { getDomaincustomerlist } from "../../../CommonControllers/Urls";
import SaveDatePicker from "../../../CommonControllers/SaveComponents/SaveDatePicker";
import ItemSearch from "../../../CommonControllers/ItemController/ItemSearch";
import { IoAperture } from "react-icons/io5";
import { AuthStore } from "../../../Auth/AuthStore";
import { useContext } from "react";
import { currentTabData } from "../../../CommonControllers/Variable";
import { TabStore } from "../../../TabsStructure/TabsStore";
import useShortguid from "../../../CommonControllers/Hooks/useShortGuid";
import useSaveChangedRows from "../../../CommonControllers/Hooks/useSaveChangedRows";
import GRNWMSItemsTable from "./GRNWMSItemsTable";
import { handleAdd } from "./GRNWMSTableColumn";
import { getfastpathapi } from "../../../CommonControllers/ApiCalls";
import WMSAdditionalDetails from "./../../../CommonControllers/WMS/WMSAdditionalDetails";
import WMSItemsDetails from "../../../CommonControllers/WMS/WMSItemDetails";
import SaveRadioBtn from "../../../CommonControllers/Components/SaveComponents/SaveRadioBtn";
import { enterPress } from "../../../CommonControllers/AppFunction";

const GRNWMS = () => {
  const { Panel } = Collapse;
  const itemSearch = useRef(null);
  const shortguid = useShortguid();
  const { getShortguid } = useShortguid();
  const auth = useContext(AuthStore);
  const tabStore = useContext(TabStore);
  const [rowData, setRowData] = useState([]);
  const useChangedRows = useSaveChangedRows();
  const [grnNumber, setGrnNumber] = useState();
  const [itemcount, setItemCount] = useState(0);
  const [modalcount, setModalCount] = useState(0);
  const [getSelectedItem, setSelectedItem] = useState("");

  // Create a state for date validation
  const [grnDate, setGrnDate] = useState();

  // Create a state to set customer data
  const [customerData, setCustomerData] = useState();

  // Create a state for item details inputs
  const [noOfPackages, setNoOfPackages] = useState("");
  const [itemLength, setItemLength] = useState("");
  const [itemWidth, setItemWidth] = useState("");
  const [itemHeight, setItemHeight] = useState("");
  const [quantity, setQuantity] = useState("");
  const [itemVolume, setItemVolume] = useState("");
  const [itemNetWeight, setItemNetWeight] = useState("");
  const [itemGrossWeight, setItemGrossWeight] = useState("");
  const [itemTestCertificateNo, setItemTestCertificateNo] = useState("");
  const [itemSerialNo, setItemSerialNo] = useState("");
  const [natureOfPacking, setNatureOfPacking] = useState("");
  const [itemRemarks, setItemRemarks] = useState("");
  const [inputValues, setInputValues] = useState([]);
  const [serialnoInput, setserialnoInput] = useState([]);
  const [statusOptions, setStatusOptions] = useState([]);

  // Create a state to edit Transactions
  const [transactionEdit, setTransactionEdit] = useState(false);
  // Create a state to set Batch No. "."
  const [batchNo, setBatchNo] = useState(null);

  // Create a state to manage the Additional Attribute
  const [additionalDetailsFilterOptions, setAdditionalDetailsFilterOptions] =
    useState([]);

  // Create a state to manage the Item related Attribute
  const [
    additionalItemDetailsFilterOptions,
    setAdditionalItemDetailsFilterOptions,
  ] = useState([]);
  const [itemAttribute, setItemAttribute] = useState({});

  // Modal start
  const [openTestCertificateNoModal, setOpenTestCertificateNoModal] =
    useState(false);
  const [openSerialNoModal, setOpenSerialNoModal] = useState(false);
  const [modaldata, setModaldata] = useState();

  const handleOk = () => {
    setOpenTestCertificateNoModal(false);
    setOpenSerialNoModal(false);
  };

  const handleCancel = () => {
    setOpenTestCertificateNoModal(false);
    setOpenSerialNoModal(false);
  };

  const updateValues = (itemData) => {
    console.log("UpdatedValues", itemData);
    setModaldata(itemData);
    const initialValues = Array.isArray(itemData?.testcertificateno)
      ? itemData.testcertificateno.filter((val) => val.trim() !== "")
      : typeof itemData?.testcertificateno === "string"
      ? itemData.testcertificateno.split(",").filter((val) => val.trim() !== "")
      : [];
    setInputValues(initialValues);
    const serialinitialValues = Array.isArray(itemData?.serialno)
      ? itemData.serialno.filter((val) => val.trim() !== "")
      : typeof itemData?.serialno === "string"
      ? itemData.serialno.split(",").filter((val) => val.trim() !== "")
      : [];
    setserialnoInput(serialinitialValues);
  };

  // Modal End

  const [pageIndex, setPageIndex] = useState();
  console.log("Page Index is", pageIndex);

  // Below use effect used to make a particular changes in the specific fastpath only
  useEffect(() => {
    var tabsArray = tabStore.state.tabs;
    for (let i = 0; i < tabsArray?.length; i++) {
      if (tabsArray[i].name === "WMS2") {
        setPageIndex(i);
        break;
      }
    }
  }, []);

  const [pageDetails, setPageDetails] = useState();

  const payload = {
    tenantrecno: auth?.state?.userdata?.tenantrecno,
    domainrecno: auth?.state?.userdata?.domainrecno,
    userrolerecno: auth?.state?.userdata?.userrolerecno,
    domainuserrecno: auth.state.userdata.recno,
    fastpath: currentTabData(tabStore)?.name,
  };

  const fetchFastPathData = async () => {
    const response = await getfastpathapi(payload);
    if (response?.Success) {
      setPageDetails(response?.Message);
      setAdditionalDetailsFilterOptions(response?.Message?.additionalDetails);
      setAdditionalItemDetailsFilterOptions(
        response?.Message?.additionalDetailsFooter
      );
      setStatusOptions([
        {
          label: "Save",
          value: response.Message?.defaultStatus,
        },
        {
          label: "Proceed",
          value: response.Message?.nextStatus,
        },
      ]);
    }
  };

  useEffect(() => {
    fetchFastPathData();
  }, []);

  //~# Setting workdate
  let workdate = auth?.state?.userdata?.workdate;
  workdate = JSON.stringify(workdate);
  workdate = `${workdate?.slice(-2)}/${workdate?.slice(4, 6)}/${workdate?.slice(
    0,
    4
  )}`;

  // To add Items Details
  const addItemstoTable = () => {
    if (getSelectedItem) {
      var existingItem = rowData.filter(
        (item) => item.itemrecno === getSelectedItem.itemrecno
      );

      console.log("existingItem?.length", existingItem?.length);
      if (existingItem?.length === 0) {
        setNoOfPackages("");
        setItemLength("");
        setItemWidth("");
        setItemHeight("");
        setQuantity("");
        setBatchNo("");
        setItemVolume("");
        setItemNetWeight("");
        setItemGrossWeight("");
        setItemTestCertificateNo("");
        setItemSerialNo("");
        setNatureOfPacking("");
        setItemRemarks("");

        handleAdd(
          getSelectedItem,
          setRowData,
          setSelectedItem,
          shortguid,
          noOfPackages,
          itemLength,
          itemWidth,
          itemHeight,
          quantity,
          batchNo,
          itemVolume,
          itemNetWeight,
          itemGrossWeight,
          itemTestCertificateNo,
          itemSerialNo,
          natureOfPacking,
          itemRemarks,
          itemAttribute
        );
        itemSearch.current.focus();
      } else {
        alert("Item Already Present");
        setSelectedItem(null);
        itemSearch.current.focus();
      }
    }
  };

  // Final volume display as the value of quantity changes
  let finalVolume =
    Number(quantity) * Number(getSelectedItem?.itemvolume) || "0";

  // Below use effect used to set a default value on a selection of item
  useEffect(() => {
    console.log("Get Selected Item is", getSelectedItem);
    if (getSelectedItem) {
      setItemLength(Number(getSelectedItem?.length).toFixed(2));
      setItemWidth(Number(getSelectedItem?.width).toFixed(2));
      setItemHeight(Number(getSelectedItem?.height).toFixed(2));
      setItemVolume(Number(finalVolume).toFixed(2));
      setNatureOfPacking(String(getSelectedItem?.nopacking));
    }
    setBatchNo(".");
  }, [getSelectedItem]);

  // Below use effect used to set and get value in Tabstore
  useEffect(() => {
    // When Row Data Changes we Need to store that data to the tabsStore with Header Data
    var currentData = useChangedRows.getValue();

    if (!currentData?.shortguid) {
      useChangedRows.updateValue("shortguid", shortguid.getShortguid("WMSGRN"));
    }

    if (rowData?.length === 0) {
      var items = useChangedRows.getValue("items");
      if (items) {
        setRowData(items);
      }
    }
  }, []);

  // Below use effect used to set a payload in the Tabstore
  useEffect(() => {
    // When Row Data Changes we Need to store that data to the tabsStore with Header Data
    var existingObject = useChangedRows.getValue();

    existingObject = {
      ...existingObject,
      items: rowData,
      shortguid: getShortguid("WMSGRN"),
      trdate: grnDate || auth?.state?.userdata?.workdate,
      status: "9",
    };

    useChangedRows.updateValue(false, existingObject);
  }, [rowData]);

  // Below use effect used to reset the value of fields in this fastpath
  useEffect(() => {
    if (tabStore.state.tabs[pageIndex]?.pageStatus === "Success") {
      setRowData([]);
      setSelectedItem(null);
      setGrnNumber("TBA");
      setNoOfPackages("");
      setItemLength("");
      setItemWidth("");
      setItemHeight("");
      setQuantity("");
      setBatchNo("");
      setItemVolume("");
      setItemNetWeight("");
      setItemGrossWeight("");
      setItemTestCertificateNo("");
      setItemSerialNo("");
      setNatureOfPacking("");
      setItemRemarks("");
      setGrnDate(auth?.state?.userdata?.workdate);
      setStatusOptions([
        {
          label: "Save",
          value: false,
        },
        {
          label: "Proceed",
          value: false,
        },
      ]);
      setTransactionEdit(false);

      useChangedRows.updateValue("shortguid", shortguid.getShortguid("WMSGRN"));
      setModalCount((p) => p + 1);
      setTimeout(() => {
        tabStore.dispatch({
          type: "UPDATE_DATA",
          fieldType: "pageStatus",
          text: null,
        });

        tabStore.dispatch({
          type: "UPDATE_DATA",
          fieldType: "changedRows",
          text: {},
        });
      }, 1000);
    }
  }, [
    tabStore.state.tabs[tabStore.state.tabIndex]?.pageStatus,
    Object.keys(currentTabData(tabStore)?.changedRows).length,
  ]);
  console.log("TabStore Data", rowData, getSelectedItem);

  // Below use effect used to update data from TabStore
  useEffect(() => {
    if (tabStore.state.tabs[pageIndex]?.changedRows?.dataFromEdit) {
      setRowData(tabStore.state.tabs[pageIndex]?.changedRows?.items);
      setTransactionEdit(true);
      setStatusOptions([
        {
          label: "Save",
          value: tabStore.state.tabs[pageIndex]?.changedRows?.status,
        },
        {
          label: "Proceed",
          value: tabStore.state.tabs[pageIndex]?.changedRows?.nextStatus,
        },
      ]);
    }
  }, [tabStore.state.tabs[pageIndex]?.changedRows?.dataFromEdit]);

  // Disable the save button until a customer is selected
  useEffect(() => {
    let isButtonDisabled = false;

    if (!customerData || rowData.length == 0) {
      isButtonDisabled = true;
    }
    useChangedRows.updateValue(
      "validated",
      !isButtonDisabled ? "true" : "false"
    );
    useChangedRows.updateValue("dataFromEdit", false);
  }, [customerData, rowData]);

  return (
    <div
      style={{
        ...mainFrame,
      }}
    >
      <Collapse collapsible="header" defaultActiveKey={["1"]}>
        <Panel header="Header Details" key="1">
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              gap: "24px",
            }}
          >
            {/* Customer */}
            <div
              style={{ zIndex: 2500, marginTop: "10px" }}
              onKeyDown={enterPress}
            >
              <SaveDropdown
                displayLabel="Cust"
                placeholder={"Select Cust"}
                defaultSelected={customerData}
                apikey={"supplierrecno"}
                apiurl={getDomaincustomerlist}
                selectionLabel="customerdescn"
                selectionValue="customerrecno"
                returnValue={(event) => {
                  setCustomerData(event);
                }}
                alignment="column"
                minInput={0}
                inputValue={20}
                key={modalcount}
                isrequired={1}
                // disabled={rowData?.length > 0 || transactionEdit ? true : false}
              />
            </div>

            {/* GRN No. (disabled) */}
            <div onKeyDown={enterPress} style={{ marginTop: "10px" }}>
              <div style={{ position: "relative" }}>
                <label
                  className={`label ${"focused"}`}
                  style={{
                    ...otherStyles,
                    zIndex: 1000,
                    top: "-19px",
                    left: "12px",
                  }}
                >
                  GRN No.
                </label>
                <input
                  style={{ ...poNumberStyle }}
                  placeholder={grnNumber ? grnNumber : "TBA"}
                  disabled
                />
              </div>
            </div>

            {/* GRN Date */}
            <div
              onKeyDown={enterPress}
              style={{ zIndex: 2000, marginTop: "10px" }}
            >
              <SaveDatePicker
                id={"grn-date"}
                displayLabel={"GRN Date"}
                placeholder={"Select GRN Date"}
                defaultValue={grnDate}
                apiKey={"trdate"}
                dateLessthan={auth?.state?.userdata?.workdate}
                postAlertMsg={`GRN Date cannot be greater than Today's Date`}
                returnValue={(e) => {
                  setGrnDate(e);
                }}
                key={modalcount}
              />
            </div>

            {/* Status */}
            {/* <div onKeyDown={enterPress}>
              <div style={{ position: "relative" }}>
                <label
                  className={`label ${"focused"}`}
                  style={{
                    ...otherStyles,
                    zIndex: 1000,
                    top: "-10px",
                    left: "12px",
                  }}
                >
                  Status
                </label>
                <div
                  onKeyDown={enterPress}
                  className="saveproceed"
                  style={{
                    padding: "8.7px",
                    marginTop: "4px",
                  }}
                >
                  <SaveRadioBtn
                    apikey={"status"}
                    // defaultValue={pageDetails?.defaultStatus}
                    options={statusOptions}
                  />
                </div>
              </div>
            </div> */}

            {/* Print GRN */}
            <div onKeyDown={enterPress} style={{ marginTop: "15px" }}>
              <SaveCheckbox
                apikey={"isPrint"}
                displayLabel="Print GRN"
                editable
                defaultValue={false}
                key={modalcount}
              />
            </div>
          </div>
        </Panel>

        <Panel header="Additional Details" key="2">
          <div onKeyDown={enterPress}>
            <WMSAdditionalDetails
              key={modalcount}
              filterOption={additionalDetailsFilterOptions}
              customerrecno={customerData?.customerrecno}
            />
          </div>
        </Panel>

        <Panel header="Item Details" key="3">
          <Space size="large">
            {/* Item Search Components */}
            <div
              style={{
                ...callbackTextInputStyles,
              }}
            >
              <div style={{ zIndex: 100 }} onKeyDown={enterPress}>
                <ItemSearch
                  itemSearchRef={itemSearch}
                  defaultSelectedItem={getSelectedItem}
                  supplierdata={customerData?.customerrecno}
                  returnValue={setSelectedItem}
                  dropdown
                  forFlag={"S"}
                  forPage={"GRN"}
                />
              </div>

              {/* Item details */}

              <div onKeyDown={enterPress} style={{ marginTop: "40px" }}>
                <WMSItemsDetails
                  itemFilterOption={additionalItemDetailsFilterOptions}
                  returnValue={(e) => {
                    console.log("Item related Attribute", e);
                    setItemAttribute(e);
                  }}
                  key={itemcount}
                />
              </div>

              {/* Quantity */}
              <div
                onKeyDown={enterPress}
                style={{ position: "relative", marginTop: "40px" }}
              >
                {quantity !== "" && (
                  <label
                    style={{
                      position: "absolute",
                      top: "-12px",
                      left: "12px",
                      backgroundColor: "white",
                      padding: "0 4px",
                      fontSize: "12px",
                      color: "black",
                      pointerEvents: "none",
                      transition: "top 0.3s, transform 0.3s, font-size 0.3s",
                      transform: "translateY(0)",
                    }}
                  >
                    Quantity <span style={{ color: "red" }}>*</span>
                  </label>
                )}
                <input
                  style={{
                    ...inputStyles,
                    textAlign: quantity === "" ? "left" : "right",
                  }}
                  value={quantity}
                  placeholder={quantity === "" ? "Enter Quantity" : ""}
                  type="number"
                  onFocus={() => {
                    if (quantity === "") {
                      setQuantity(" ");
                    }
                  }}
                  onBlur={() => {
                    if (quantity.trim() === "") {
                      setQuantity("");
                    }
                  }}
                  onChange={(e) => {
                    // const regex = /^[0-9\b]+$/;
                    const regex = /^\d+(\.\d+)?$/;
                    const value = e.target.value;
                    if (value === "" || regex.test(value)) {
                      setQuantity(value);
                    }
                  }}
                />
              </div>

              {/* Volume */}
              <div onKeyDown={enterPress} style={{ marginTop: "40px" }}>
                <div style={{ position: "relative" }}>
                  <label
                    className={`label ${"focused"}`}
                    style={{
                      ...otherStyles,
                      zIndex: 50,
                      top: "-12px",
                      left: "12px",
                    }}
                  >
                    Volume
                  </label>
                  <input
                    style={{ ...inputStyles, textAlign: "right" }}
                    placeholder={
                      finalVolume ? Number(finalVolume).toFixed(2) : " "
                    }
                    disabled
                  />
                </div>
              </div>

              {/* Add Button */}
              <div onKeyDown={enterPress} style={{ marginTop: "40px" }}>
                <Button
                  className="btn item-search-btn"
                  style={{ marginTop: "8px" }}
                  icon={<IoAperture size="1.2rem" />}
                  size="large"
                  onClick={() => {
                    // Validation
                    if (quantity === 0 || quantity === "") {
                      alert("Please Enter the Quantity.");
                    } else {
                      addItemstoTable();
                      setItemCount((q) => q + 1);
                    }
                  }}
                >
                  Add
                </Button>
              </div>
            </div>
          </Space>
          {/* Table for Selection  */}
          <div
            style={{
              zIndex: 0,
              position: "relative",
              margin: "24px auto 0",
            }}
          >
            {rowData.length > 0 && (
              <GRNWMSItemsTable
                rowData={rowData}
                setRowData={setRowData}
                openTestCertificateNoModal={openTestCertificateNoModal}
                setOpenTestCertificateNoModal={setOpenTestCertificateNoModal}
                openSerialNoModal={openSerialNoModal}
                setOpenSerialNoModal={setOpenSerialNoModal}
                handleOk={handleOk}
                handleCancel={handleCancel}
                updateValues={updateValues}
                modaldata={modaldata}
                setItemCount={setItemCount}
                pageDetails={pageDetails}
                inputValues={inputValues}
                setInputValues={setInputValues}
                serialnoInput={serialnoInput}
                setserialnoInput={setserialnoInput}
              />
            )}
          </div>
        </Panel>
      </Collapse>
    </div>
  );
};

export default GRNWMS;

const mainFrame = {
  width: "99vw",
  margin: "auto",
};

const poNumberStyle = {
  border: "1px solid #dfdfdf",
  borderRadius: "8px",
  width: "230px",
  padding: "8.7px",
  fontSize: "16px",
  height: "39px",
};

const callbackTextInputStyles = {
  display: "flex",
  flexWrap: "wrap",
  gap: "24px",
};

const inputStyles = {
  padding: "8.7px",
  border: "1px solid #dfdfdf",
  borderRadius: "8px",
  fontSize: "16px",
  marginTop: "10px",
  width: "200px",
  height: "37px",
};

const otherStyles = {
  whiteSpace: "nowrap",
  wordWrap: "unset",
  position: "absolute",
  // backgroundColor: "white",
  padding: "0 4px",
  fontSize: "12px",
  color: "black",
  pointerEvents: "none",
  transform: "translateY(0)",
  transition: "top 0.3s, transform 0.3s, font-size 0.3s, color 0.3s",
};

import { Button, Popconfirm } from "antd";
import { AiFillDelete } from "react-icons/ai";
import { removeByAttribute } from "../../CommonControllers/AppFunction";
import "../../CommonControllers/global.css";
import { useState } from "react";
import CallbackCheckbox from "../../CommonControllers/Components/CallbackComponents/CallbackCheckbox";

export const poColumns = (igstappl, setRowData, rowData,pageDetails) => {
  console.log("igstappl---",igstappl)
  console.log( "rowData12", rowData)

  if (igstappl) {
    return [
      {
        title: " ",
        dataIndex: "active",
        key: "active",
        width: "4%",
        render: (_, text) => {
          return (
            <Popconfirm
              title="Are you sure?"
              onConfirm={(e) => {
                handleRemove(text, setRowData, rowData);
              }}
            >
              <Button icon={<AiFillDelete />}></Button>
            </Popconfirm>
          );
        },
      },
      {
        title: "Item Description",  //{/* Pradeep task id -398  */} change title name from Item to  Item Description
        dataIndex: "itemdescn",
        key: "itemdescn",
        width: "max-content",
        align: "left", // nohit-388 set Align for column
      },
      
      {
        hidden: !pageDetails?.wantpackingextra ? true : false,
        title: "Boxes",
        dataIndex: "box",
        key: "box",
        width: "8%",
        align: "right",
        render: (_, text) => {
          console.log("text in po",text)
          return (
            <input
              className={`tr001Input tr001Box`}
              defaultValue={text?.packingextraqty}
              type="number"
              onFocus={(e) => e.target.select()}
              disabled={text.packingextrarecno>0? false:true}
              onChange={(e) => {
                if (e.target.value < 0) {
                  // document.querySelector(`.${text?.shortguid}`).value = null;
                  e.target.value = null;
                  // alert("Invalid Input, please re-enter non-negative value");
                  return false;
                }
                const regex = /^[0-9\b]+$/;
                if (regex.test(e.target.value) && (Number(e.target.value) + Number(text.packingqty)) > 0) {
                  text.packingextraqty = e.target.value;
                  handleChange(text, setRowData, igstappl, rowData);
                } else {
                  e.target.value = text.packingextraqty
                  alert("Box and Pieces cannot be 0")
                }
              }}
            />
            // <p>{text.box}</p>
          );
        },
      },
      {
        title: "Pieces",
        dataIndex: "qty",
        key: "qty",
        width: "8%",
        render: (_, text) => {
          return (
            <input
              className={`tr001Input tr001Pieces`}
              defaultValue={Number(text.packingqty)}
              onFocus={(e) => e.target.select()}
              type="number"
              //disabled={text.packingextrarecno==0? false:true}
              onChange={(e) => {
                console.log("Changed Value of the Pieces is ", e.target.value)
                if (e.target.value < 0) {
                  e.target.value = null;
                  alert("Invalid Input, please re-enter non-negative value");
                  return false;
                }
                const regex = /^[0-9\b]+$/;
                if (regex.test(e.target.value) && (Number(e.target.value) + Number(text.packingextraqty)) > 0) {
                  text.packingqty = e.target.value;
                  handleChange(text, setRowData, igstappl, rowData);
                } else {
                  e.target.value = text.packingqty
                  alert("Box and Pieces cannot be 0")
                }
              }}
            />
            // <p>{text.qty}</p>
          );
        },
        align: "right",
      },
      {
        hidden: !pageDetails?.wantpackingextra ? true : false,
        title: "Box Rate",
        dataIndex: "boxpurrate",
        key: "boxpurrate",
        width: "8%",
        align: "right",
      },
      {
        title: "Rate",
        dataIndex: "purrate",
        key: "purrate",
        width: "8%",
        // render: (_, text) => {
        //   return (
        //     <input
        //       className="tr001Input"
        //       // defaultValue={text.purrate}
        //       onFocus={(e) => e.target.select()}
        //       // editable={false}
        //       // contentEditable={false}
        //       onChange={(e) => {
        //         if (e.target.value < 0) {
        //           e.target.value = null;
        //           alert("Invalid Input, please re-enter non-negative value");
        //           return false;
        //         }
        //         const regex = /^\d*(\.\d{0,2})?$/;
        //         if (e.target.value === "" || regex.test(e.target.value)) {
        //           text.purrate = e.target.value;
        //           handleChange(text, setRowData, igstappl, rowData);
        //         }
        //       }}
              
        //       // value={purchaseRate}
        //       // value={text.purrate}
        //     ></input>
        //   );
        // },
        align: "right",
      },
      {
        title: "Taxable ",
        dataIndex: "taxableamount",
        key: "taxableamount",
        align: "right",
        width: "9%",
      },
      {
        title: "IGST%",
        dataIndex: "igstrate",
        key: "igstrate",
        align: "right",
        width: "8%",
      },
      {
        title: "IGST",
        dataIndex: "igstamt",
        key: "igstamt",
        align: "right",
        width: "8%",
      },
      {
        title: "Amount",
        dataIndex: "amount",
        key: "amount",
        align: "right",
        width: "8%",
      },
      {
        title: "Stand By",
        dataIndex: "standby",
        key: "standby",
        render : (_, text) => {
          return (
            <CallbackCheckbox 
            editable
            defaultValue={text.standby?text.standby:false}
            returnValue={(e) => {
              text.standby = e
              handleChange(text, setRowData, igstappl, rowData);
            }}
            />
          )
        }
      },
    ];
  } else {
    return [
      {
        title: " ",
        dataIndex: "active",
        key: "active",
        width: "4%",
        render: (_, text) => {
          return (
            <Popconfirm
              title="Are you sure?"
              onConfirm={(e) => {
                handleRemove(text, setRowData, rowData);
              }}
            >
              <Button icon={<AiFillDelete />}></Button>
            </Popconfirm>
          );
        },
      },
      {
        title: "Item",
        dataIndex: "itemdescn",
        key: "itemdescn",
        width: "max-content",
        align: "left", // nohit-388 set Align for column
      },
      { hidden: !pageDetails?.wantpackingextra ? true : false,
        title: "Boxes",
        dataIndex: "box",
        key: "box",
        width: "8%",
        align: "right",
        render: (_, text) => {
          return (
            <input
              className={`tr001Input tr001Box`}
              defaultValue={text?.packingextraqty}
              type="number"
              onFocus={(e) => e.target.select()}
              onChange={(e) => {
                if (e.target.value < 0) {
                  // document.querySelector(`.${text?.shortguid}`).value = null;
                  e.target.value = null;
                  // alert("Invalid Input, please re-enter non-negative value");
                  return false;
                }
                const regex = /^[0-9\b]+$/;
                if (regex.test(e.target.value) && (Number(e.target.value) + Number(text.packingqty)) > 0) {
                  text.packingextraqty = e.target.value;
                  handleChange(text, setRowData, igstappl, rowData);
                } else {
                  e.target.value = text.packingextraqty
                  alert("Box and Pieces cannot be 0")
                }
              }}
            />
            // <p>{text.box}</p>
          );
        },
      },
      {
        title: "Pieces",
        dataIndex: "qty",
        key: "qty",
        width: "8%",
        render: (_, text) => {
          return (
            <input
              className={`tr001Input tr001Pieces`}
              defaultValue={text.packingqty}
              onFocus={(e) => e.target.select()}
              type="number"
              onChange={(e) => {
                
                if (e.target.value < 0) {
                  e.target.value = null;
                  alert("Invalid Input, please re-enter non-negative value");
                  return false;
                }
                const regex = /^[0-9\b]+$/;
                console.log("Total Vlaue is", Number(e.target.value) + Number(text.packingextraqty), "E",e.target.value, Number(e.target.value), regex.test(e.target.value))
                if (regex.test(e.target.value) && (Number(e.target.value) + Number(text.packingextraqty)) > 0) {
                  text.packingqty = e.target.value;
                  handleChange(text, setRowData, igstappl, rowData);
                } else {
                  e.target.value = text.packingqty
                  alert("Box and Pieces cannot be 0 Or Field Cannot be empty.")
                }
              }}
            />
            // <p>{text.qty}</p>
          );
        },
        align: "right",
      },
      {
        hidden: !pageDetails?.wantpackingextra ? true : false,
        title: "Box Rate",
        dataIndex: "boxpurrate",
        key: "boxpurrate",
        width: "8%",
        align: "right",
      },
      {
        title: "Rate",
        dataIndex: "purrate",
        align: "right",
        key: "purrate",
        width: "8%",
        // render: (_, text) => {
        //   return (
        //     <input
        //       className={`tr001Input`}
        //       defaultValue={text.purrate}
        //       onFocus={(e) => e.target.select()}
        //       type="number"
        //       onChange={(e) => {
        //         if (e.target.value < 0) {
        //           // document.querySelector(`.${text?.shortguid}`).value = null;
        //           e.target.value = null;
        //           // alert("Invalid Input, please re-enter non-negative value");
        //           return false;
        //         }
        //         text.purrate = e.target.value;
        //         handleChange(text, setRowData, igstappl, rowData);
                
        //       }}
              
        //     />
        //     // <p>{text.purrate}</p>
        //   );
        // },
      },
      {
        title: "Taxable ",
        dataIndex: "taxableamount",
        key: "taxableamount",
        align: "right",
        width: "9%",
      },
      {
        title: "CGST%",
        dataIndex: "cgstrate",
        key: "csgtrate",
        align: "right",
        width: "6%",
      },
      {
        title: "CGST",
        dataIndex: "cgstamt",
        key: "cgstamt",
        align: "right",
        width: "6%",
      },
      {
        title: "SGST%",
        dataIndex: "sgstrate",
        key: "sgstrate",
        align: "right",
        width: "6%",
      },
      {
        title: "SGST",
        dataIndex: "sgstamt",
        key: "sgstamt",
        align: "right",
        width: "6%",
      },
      {
        title: "Amount",
        dataIndex: "amount",
        key: "amount",
        align: "right",
        width: "6%",
      },
      {
        title: "Stand By",
        dataIndex: "standby",
        key: "standby",
        render : (_, text) => {
          return (
            <CallbackCheckbox 
            editable
            defaultValue={text.standby?text.standby:false}
            returnValue={(e) => {
              text.standby = e
              handleChange(text, setRowData, igstappl, rowData);
            }}
            />
          )
        }
      },
    ];
  }
};

export const handleAdd = (item, setRowData, igstappl, setSelectedItem, shortguid, purchaseRate, purchaseQty, box) => {
  console.log("igstappl555",igstappl)
  if (item?.packingextraqty < 0 || item?.packingqty < 0 || item?.purrate < 0) {
    alert("Cannot enter 0");
    return ;
  }

  //~# Input boxes validation
  const boxValue = document.querySelector("tr001-input-box");
  const piecesValue = document.querySelector("tr001-input-pieces");
  const rateValue = document.querySelector("tr001-input-rate");

  console.log("tr001 handle add  ==>", item);

  var newShortguid = shortguid.getShortguid("POF" + item.itemrecno);
  console.log("tr001 Added Shortguid", newShortguid, box, purchaseQty);
  var purrate = purchaseRate ? purchaseRate : 0.0;
  var cgstrate = item?.cgstrate ? item.cgstrate : 0.0;
  var sgstrate = item?.sgstrate ? item.sgstrate : 0.0;
  var igstrate = item?.igstrate ? item.igstrate : 0.0;

  var packingqty = purchaseQty ? purchaseQty : 0; //~# here qty of pieces changed to key-name packingqty, box will be freepackingqty
  var packingextraqty = box ? box : 0; //~# box value
  var totalQty = Number(packingextraqty) * Number(item?.conversionfactor) + Number(packingqty);
  var boxpurrate = Number(purrate) * Number(item?.conversionfactor);
 

  var taxableamount = (Number(packingextraqty) * Number(item?.conversionfactor) + Number(packingqty)) * Number(purrate);
  var sgstamt = !igstappl ? Number(taxableamount) * (sgstrate / 100) : 0.0;
  var cgstamt = !igstappl ? Number(taxableamount) * (cgstrate / 100) : 0.0;
  var igstamt = igstappl ? Number(taxableamount) * (igstrate / 100) : 0.0;

  console.log("cgstamt11",cgstamt)  
  console.log("sgstamt",sgstamt)

  console.log("igstt",igstappl)

  var amount = igstappl ? Number(taxableamount) + igstamt : Number(taxableamount) + (cgstamt + sgstamt);

  
  var itemObject = {
    ...item,
    shortguid: newShortguid,
    itemrecno: item.itemrecno,
    itemdescn: item.itemdescn,
    packingextraqty: +box, //~# here box is the value coming from input Boxes
    packingqty: +packingqty, //~# here packingqty is the pieces value coming from Pieces input
    qty: totalQty,
    purrate: Number(purrate)?.toFixed(2),
    taxableamount: Number(taxableamount)?.toFixed(2),
    cgstrate: Number(cgstrate).toFixed(2),
    sgstrate: Number(sgstrate).toFixed(2),
    igstrate: Number(igstrate).toFixed(2),
    cgstamt: Number(cgstamt)?.toFixed(2),
    sgstamt: Number(sgstamt)?.toFixed(2),
    igstamt: Number(igstamt)?.toFixed(2),
    amount: Number(amount)?.toFixed(2),
    standby:item?.standby?item?.standby:false,
    boxpurrate: Number(boxpurrate)?.toFixed(2)
   
  };

  if (itemObject?.packingextraqty === 0 && itemObject?.qty === 0) {
    alert("Enter boxes / pieces / Rate");
    const boxRef = document.querySelector("#tr001-input-box");
    boxRef.focus();
    return ;
  }
  console.log("tr001 handleAdd final ==>", itemObject);

  setRowData((p) => [...p, itemObject]);
  setSelectedItem(null);
};

export const handleChange = (item, setRowData, igstappl, rowData) => {
  console.log("tr001 handle change", item, "conversion factor ??", rowData);

   console.log("igstappl444", igstappl)
  if (item?.packingextraqty == 0 && item?.qty == 0) {
    alert("Enter Boxes or Pieces");
  }

  if (item?.purrate == 0) {
    alert("Rate is compulsory");
  }

  var purrate = item?.purrate ? item.purrate : 0.0;
  var cgstrate = item?.cgstrate ? item.cgstrate : 0.0;
  var sgstrate = item?.sgstrate ? item.sgstrate : 0.0;
  var igstrate = item?.igstrate ? item.igstrate : 0.0;
  var packingextraqty = item?.packingextraqty ? item?.packingextraqty : 0;
  var packingqty = item?.packingqty ? item?.packingqty : 0;
  var totalQty = Number(packingextraqty) * Number(item?.conversionfactor) + Number(packingqty);
  var boxpurrate = item?.boxpurrate ? item.boxpurrate : 0.0;

  var taxableamount = (Number(packingextraqty) * Number(item?.conversionfactor) + Number(packingqty)) * Number(purrate);

  var sgstamt = !igstappl ? taxableamount * (sgstrate / 100) : 0.0;
  var cgstamt = !igstappl ? taxableamount * (cgstrate / 100) : 0.0;
  var igstamt = igstappl ? taxableamount * (igstrate / 100) : 0.0;

  var amount = igstappl ? taxableamount + igstamt : taxableamount + (cgstamt + sgstamt);
 

  var itemObject = {
    ...item,
    itemrecno: item.itemrecno,
    itemdescn: item.itemdescn,
    packingextraqty: Number(packingextraqty),
    packingqty:Number( packingqty),
    qty: Number(totalQty),
    purrate: Number(purrate)?.toFixed(2),
    taxableamount: Number(taxableamount)?.toFixed(2),
    ccgstrate: Number(cgstrate).toFixed(2),
    sgstrate: Number(sgstrate).toFixed(2),
    igstrate: Number(igstrate).toFixed(2),
    cgstamt: Number(cgstamt).toFixed(2),
    sgstamt: Number(sgstamt).toFixed(2),
    igstamt: Number(igstamt).toFixed(2),
    amount: Number(amount).toFixed(2),
    itembatchno: item?.wantbatchno?item.itembatchno:".",
    standby:item?.standby,
    boxpurrate: Number(boxpurrate)?.toFixed(2)
  };

  console.log("Handle Changed After Change Data is", itemObject)
  var currentItem = rowData.map((itm) => {
    if (itm.itemrecno == item.itemrecno) {
      return { ...itemObject };
    } else {
      return { ...itm };
    }
  });

  setRowData(currentItem);
};

export const handleRemove = (item, setRowData, rowData) => {
  console.log("Delete", item, setRowData, rowData);
  var removedArray = removeByAttribute(rowData, "itemrecno", item.itemrecno);
  console.log("Remove", item, setRowData, rowData, removedArray);
  setRowData(() => [...removedArray]);
};

//~^ GST AMOUNT getting added but not showing percentage values in table : Solved - 21/01/2023
//~! USING shortguid as classname for maipulating the DOM
// Kaushik Koparkar 6/01/2023
import { Button, Collapse, Input, Modal, Space, notification } from "antd";
import React, { useContext, useEffect, useState } from "react";
import {
  getToday,
  dateFormatter,
  addDays,
  convertToBoxAndPieces,
  enterPress,
} from "../../CommonControllers/AppFunction";

import ItemSearch from "../../CommonControllers/ItemController/ItemSearch";
import SupplierSaveDropDown from "../../CommonControllers/SaveComponents/HigherLevel/SupplierSaveDropDown";
import SaveDatePicker from "../../CommonControllers/SaveComponents/SaveDatePicker";
import { handleAdd, grnColumns } from "./GRNTableColumns";
import useSaveChangedRows from "../../CommonControllers/Hooks/useSaveChangedRows";
import useShortguid from "../../CommonControllers/Hooks/useShortGuid";
import { TabStore } from "../../TabsStructure/TabsStore";
import { AuthStore } from "../../Auth/AuthStore";
import "../../CommonControllers/global.css";
import { IoAperture } from "react-icons/io5";
import UserLocationList from "../../CommonControllers/SaveComponents/HigherLevel/UserLocationList";
import { useRef } from "react";
import GrnTable from "./GrnTable";
import SaveTextInput from "../../CommonControllers/Components/SaveComponents/SaveTextInput";
import { currentTabData } from "../../CommonControllers/Variable";
import CallbackDateInput from "../../CommonControllers/Components/CallbackComponents/CallbackDateInput";
import CallbackTextInput from "../../CommonControllers/Components/CallbackComponents/CallbackTextInput";
import SaveDropdown from "../../CommonControllers/Components/SaveComponents/SaveDropdown";
import {
  getCustomer,
  getcustomerlistwithoutdomain,
  getdomainlocationlistactive,
} from "../../CommonControllers/Urls";

import { v4 as uuidv4 } from "uuid";
import ScanTable from "../../CommonControllers/Components/ScanTable/ScanTable";
import { getfastpathapi } from "../../CommonControllers/ApiCalls";
import MultipleSelectionItem from "../GRN/MultipleSelectionItem";
import { handleRemovePrev } from "../GRN/GRNTableColumns";
import useFetchItem from "../../CommonControllers/ItemController/useFetchItems";

function ItemReceipts() {
  const [getSelectedItem, setSelectedItem] = useState(null);
  const [poDate, setPoDate] = useState();
  const [rowData, setRowData] = useState([]);
  const [supplierdata, setSupplierdata] = useState(null);
  const [igstappl, setigstappl] = useState(false);
  const useChangedRows = useSaveChangedRows();
  const [displayMessages, setDisplayMessages] = useState(false);
  const [showFilters, setShowFilters] = useState(true);
  const [purchaseRate, setPurchaseRate] = useState(" ");
  const [selectedQty, setSelectedQty] = useState(" "); // Qty as in pieces in UI, total = pieces * rate
  const [collapse, setCollapse] = useState(false);
  const [expDeliveryDays, setExpDeliveryDays] = useState(null);
  const [expDeliveryDate, setExpDeliveryDate] = useState(0);
  const { Panel } = Collapse;
  const auth = useContext(AuthStore);
  const shortguid = useShortguid();
  const tabStore = useContext(TabStore);

  const btnRef = useRef(null);
  const [batchNo, setBatchNo] = useState(null);
  const [mfgDate, setMfgDate] = useState(0);
  const [expDate, setExpDate] = useState(0);
  const [box, setBox] = useState(0);
  const [pcs, setPcs] = useState(0);

  //~# State variable for date validation
  const [grnDate, setGrnDate] = useState();
  const [supplierDcDate, setSupplierDcDate] = useState();
  const [val, setValforinput] = useState();

  const [showTable, setShowTable] = useState(true);
  const [openItem, setOpenItem] = useState(false);
  const [scandata, setScandata] = useState();
  const [renderer, setRenderer] = useState(0);
  const [validator, setValidator] = useState(0);

  const [location, setLocation] = useState("");
  const [modalcount, setModalcount] = useState(0);

  ///task id-256 rajendra
  const searchref = useRef(null);

  /// render the opage after save///
  const [count, setCount] = useState(0);
  // -------------multiple selecton-----------------
  const [previousPOList, setPreviousPOList] = useState([]);
  const [displayPrevPO, setDisplayPrevPO] = useState(false);
  const [displayPrevSO, setDisplayPrevSO] = useState(false);

  const [displayPrevDC, setDisplayPrevDC] = useState(false);

  const [returnData, setreturnData] = useState([]); //To Get the data from Selection Component of Miltiple Selection
  //const [count, setCount] = useState(0);

  //// usefetch

  const fetchItems = useFetchItem();
  const [change, setChange] = useState(0);
  const [pageIndex, setPageIndex] = useState();
  /// page details is added
  const [pageDetails, setPageDetails] = useState();
  const payld = {
    tenantrecno: auth?.state?.userdata?.tenantrecno,
    domainrecno: auth?.state?.userdata?.domainrecno,
    userrolerecno: auth?.state?.userdata?.userrolerecno,
    domainuserrecno: auth.state.userdata.recno,
    fastpath: currentTabData(tabStore)?.name,
  };
  useEffect(() => {
    var tabsArray = tabStore.state.tabs;
    for (let i = 0; i < tabsArray?.length; i++) {
      if (tabsArray[i].name == "TR029") {
        setPageIndex(i);
        break;
      }
    }
  }, []);
  //// here Date 29 jun rajendra
  useEffect(() => {
    // Get Fast ath API
    getfastpathapi(payld).then((res) => {
      if (res?.Success) {
        setPageDetails(res?.Message);
      }
    });
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");
    const formattedDate = `${year}${month}${day}`;

    setGrnDate(formattedDate);
  }, []);

  useEffect(() => {
    getSelectedItem?.purrate !== undefined
      ? setPurchaseRate(getSelectedItem?.purrate)
      : setPurchaseRate("");
  }, [getSelectedItem?.purrate]);

  useEffect(() => {
    setValforinput(val);
  }, [val, location]);

  useEffect(() => {
    setBatchNo(
      getSelectedItem?.entrybatchno ? getSelectedItem?.entrybatchno : null
    );
    setExpDate(
      getSelectedItem?.wantbatchno == 0 &&
        getSelectedItem?.expdatecompulsory == 0
        ? "29991231"
        : expDate
    );
    setMfgDate(
      getSelectedItem?.wantbatchno == 0 &&
        getSelectedItem?.manudatecompulsory == 0
        ? auth?.state?.userdata?.workdate
        : mfgDate
    );
  }, [getSelectedItem]);

  //~# Setting workdate
  let workdate = auth?.state?.workdate;
  workdate = JSON.stringify(workdate);
  workdate = `${workdate?.slice(-2)}/${workdate?.slice(4, 6)}/${workdate?.slice(
    0,
    4
  )}`;
  useEffect(() => {
    dateSetter();
  }, [poDate]);
  function dateSetter() {
    const expDays = supplierdata?.expdeliverydays;
    // let expdate = dateFormatter(addDays(auth?.state?.workdate, expDays));
    let expdate = addDays(auth?.state?.workdate, expDays);

    setExpDeliveryDate(expdate);
    return expdate;
  }

  function setDatatoSupplierdata(e) {
    setSupplierdata(e);
  }

  function handleBoxChange(event) {
    const regex = /^[0-9\b]+$/;
    if (event.target.value === "" || regex.test(event.target.value)) {
      setBox(event.target.value);
    }
  }

  function handlePiecesChange(event) {
    const regex = /^[0-9\b]+$/;
    const value = event.target.value;
    if (value === "" || regex.test(value)) {
      setSelectedQty(value);
    }
  }

  const addItemstoTable = () => {
    if (getSelectedItem) {
      var existingItem = rowData.filter(
        (item) => item.itemrecno == getSelectedItem.itemrecno
      );

      if (existingItem?.length == 0) {
        // clearing all input values
        setBox(() => "");
        setSelectedQty("");
        setPcs(() => "");
        setPurchaseRate("");
        setExpDate(() => 0);
        setMfgDate(0);
        setBatchNo(() => "");
        handleAdd(
          getSelectedItem,
          setRowData,
          igstappl,
          setSelectedItem,
          shortguid,
          purchaseRate,
          pcs,
          box,
          batchNo,
          mfgDate,
          expDate
        );
      } else {
        alert("Item Already Present");
        setSelectedItem(null);
      }
    }
    searchref.current.focus();
  };

  useEffect(() => {
    if (supplierdata?.sezappl) {
      setigstappl(true);
      dateSetter();
    } else {
      setigstappl(false);
      dateSetter();
    }
  }, [supplierdata]);

  useEffect(() => {
    // When Row Data Changes we Need to store that data to the tabsStore with Header Data
    var existingObject = useChangedRows.getValue();

    // Calculate Other Numbers and Inputs and store in the tabStore
    var status = "P";
    var trdate = "workdate";
    var approvalStatus = "P";
    var remark = "Need to add a field for that";
    var narration = "Need to ad a text field for this as well";
    var amount; // Calculate the total amount after tax
    var taxableamount; // Calculate the total taxable amount after tax
    var cgstamt; //Calculate the CGSTAMT
    var sgstamt; //Calculate teh SGST AMT
    var igstamt; //Calculat the IGSTAMT

    amount = rowData.reduce((total, item) => total + Number(item.amount), 0);
    taxableamount = rowData.reduce(
      (total, item) => total + Number(item.taxableamount),
      0
    );
    cgstamt = rowData.reduce((total, item) => total + Number(item?.cgstamt), 0);
    sgstamt = rowData.reduce((total, item) => total + Number(item?.sgstamt), 0);
    igstamt = rowData.reduce((total, item) => total + Number(item.igstamt), 0);

    existingObject = {
      ...existingObject,
      cgstamt: cgstamt,
      igstamt: igstamt,
      sgstamt: sgstamt,
      taxableamount: taxableamount,
      amount: amount,
      items: rowData,
      //trdate: auth?.state?.workdate, //~# workdate here
      shortguid: uuidv4(),

      partylocationrecno: 0,
    };

    useChangedRows.updateValue(false, existingObject);
  }, [rowData]);

  useEffect(() => {
    // When Row Data Changes we Need to store that data to the tabsStore with Header Data
    var currentData = useChangedRows.getValue();
    if (!currentData?.shortguid) {
      useChangedRows.updateValue("shortguid", shortguid.getShortguid("PO"));
    }

    if (rowData?.length == 0) {
      var items = useChangedRows.getValue("items");
      if (items) {
        setRowData(items);
      }
    }
  }, []);

  useEffect(() => {

    if (useChangedRows.getValue("trdate")) {
      setGrnDate(useChangedRows.getValue("trdate"));
    } else {
      useChangedRows.updateValue("trdate", auth?.state?.workdate);
      setGrnDate(auth?.state?.workdate);
    }
  }, [useChangedRows.getValue("trdate")]);

  useEffect(
    () => {
      if (tabStore.state.tabs[pageIndex]?.pageStatus == "Success") {
        // setRowData([]);
        // setigstappl(false);
        // setSelectedItem(null);
        // setSelectedQty(" ");
        // setPurchaseRate(" ");
        // document.getElementById("grn-date").value = "";
        // document.getElementById("tr003-grn-dcdate").value = "";

        setRowData([]);
        setigstappl(false);
        setSelectedItem(null);
        setSelectedQty(" ");
        setPurchaseRate(" ");
        setExpDeliveryDate(0);
        setValforinput({});
        setLocation("");
        setPoDate(auth.state.workdate);
        setGrnDate(auth.state.workdate)
        setDatatoSupplierdata(null);
        setSupplierdata(null);
        useChangedRows.updateValue("partydescn", "");
      }
      setCount((p) => p + 1);
    },
    [tabStore.state.tabs[pageIndex]?.pageStatus],
    Object.keys(currentTabData(tabStore)?.changedRows)?.length
  );

  //----------multiple selection item start------------------------------------------------

  const optionsforDC = [
    {
      id: 1,
      optionName: "Challan",
      apiurl: "filterdeliverychalan",
      customerrecno: val?.recno,
      doctype: "dc",
    },
  ];

  const dateFormatter = (funDate) => {
    if (funDate == 0) {
      return 0;
    }
    if (!funDate) {
      return 0;
    }
    let date = funDate?.toString() || "";
    let formattedDate =
      date.length >= 7
        ? date.slice(6, 8) + "/" + date.slice(4, 6) + "/" + date.slice(0, 4)
        : date.slice(4, 6) + "/" + date.slice(0, 4);

    return formattedDate;
  };

  return (
    <div style={{ ...mainFrame }}>
      <Modal
        key={modalcount}
        width={620}
        open={displayPrevDC}
        onCancel={() => {
          setDisplayPrevDC(!displayPrevDC);
          setCount((p) => p + 1);
        }}
        onOk={() => {
          let newArray = returnData.map((item) => {
            let pcsBox = convertToBoxAndPieces(
              Number(item?.selectedQty),
              Number(item?.conversionfactor)
            );

            if (!rowData || !item) {
              alert("Invalid data");
            } else {
              var existingItem = rowData.filter(
                (it) => it && it.itemrecno === item.itemrecno
              );

              if (existingItem.length === 0) {
                // Add item to the list
                handleAdd(
                  item,
                  setRowData,
                  item?.igstrate > 0 ? true : false,
                  setSelectedItem,
                  shortguid,
                  item?.salerate ? item.salerate : item?.rate,
                  //Made Changes to Add Box Pieces from Previous Documents
                  pcsBox[1],
                  pcsBox[0], // Made Changes to Add Pieces From Pieces Documents
                  0,
                  item.freeQuantity,
                  item?.itembatchno,
                  0,
                  item?.discountamount > 0 ? item.discountamount : 0,
                  item?.discountamt > 0 ? item.discountamt : 0,
                  0,
                  item?.amount > 0 ? item.amount : 0,
                  item?.taxableamount > 0 ? item.taxableamount : 0,
                  rowData
                );

                setreturnData([]);
              } else {
                // var totalBoxQty = existingItem.reduce((acc, el) => {
                //   console.log("Item Existing Array is ", item, el)

                //   (acc += Number(el?.packingextraqty))
                // }, 0) +  Number(pcsBox[0])
                // var totalPcsQty = existingItem.reduce((acc, el) => (acc += Number(el?.packingqty)), 0) +  Number(pcsBox[1])

                handleRemovePrev(item, setRowData, rowData);

                setTimeout(() => {
                  handleAdd(
                    item,
                    setRowData,
                    item?.igstrate > 0 ? true : false,
                    setSelectedItem,
                    shortguid,
                    item?.salerate ? item.salerate : item?.rate,
                    //Made Changes to Add Box Pieces from Previous Documents
                    pcsBox[1],
                    pcsBox[0], // Made Changes to Add Pieces From Pieces Documents
                    0,
                    item.freeQuantity,
                    item?.itembatchno,
                    0,
                    item?.discountamount > 0 ? item.discountamount : 0,
                    item?.discountamt > 0 ? item.discountamt : 0,
                    0,
                    item?.amount > 0 ? item.amount : 0,
                    item?.taxableamount > 0 ? item.taxableamount : 0
                  );

                  setreturnData([]);
                }, 200);
              }
            }
          });
          setDisplayPrevDC(!displayPrevDC);
          setModalcount((count) => count + 1);
        }}
      >
        <MultipleSelectionItem
          options={optionsforDC}
          selectedItemsArray={returnData}
          returnValue={(e) => {
            setreturnData(e);
          }}
          forFlag={"dc"} //KMK:31/03/23 Dont Change the place of this Key Value
          todate={grnDate}
        />
      </Modal>
      <Modal
        open={displayMessages}
        onCancel={() => {
          setDisplayMessages(!displayMessages);
        }}
        onOk={() => {
          useChangedRows.updateValue(
            "message",
            document.getElementById("message").value
          );
          useChangedRows.updateValue(
            "narration",
            document.getElementById("narration").value
          );
          setDisplayMessages(!displayMessages);
        }}
      >
        <div style={{ display: "flex", flexDirection: "column" }}>
          <p>Message for Supl</p>
          <Input
            id={"message"}
            placeholder="Enter Message for Supl"
            defaultValue={useChangedRows.getValue("message")}
          ></Input>
          <br /> <p>Message for Self</p>
          <Input
            id={"narration"}
            placeholder="Enter Message for Self"
            defaultValue={useChangedRows.getValue("narration")}
          ></Input>
        </div>
      </Modal>
      <Collapse collapsible="header" defaultActiveKey={["1"]}>
        <Panel header="Header Details" key="1">
          <form
            style={{
              display: "flex",
              flexWrap: "wrap",
              columnGap: "40px", // Adjust the column gap as needed
              rowGap: "15px",
              marginTop: "5px",
            }}
          >
            {/* Select Supplier */}

            <div onKeyDown={enterPress} style={{ zIndex: 1 }}>
              <SaveDropdown
                displayLabel="Locn"
                placeholder="Select Locn"
                alignment="column"
                apiurl={getdomainlocationlistactive}
                apikey={"partylocationrecno"}
                selectionLabel="descn"
                selectionValue="recno"
                returnValue={setLocation}
                defaultSelected={location}
                key={count}
              />
              <SaveTextInput
              pageIndex={pageIndex}
                apikey="partylocationdescn"
                defaultValue={location?.descn}
                placeholder={"Party Locn"}
                change
                type={"text"}
                key={count + 1}
                width={"250px"}
              />
            </div>
            <div
              onKeyDown={enterPress}
              style={{
                pointerEvents: rowData.length > 0 ? "none" : "auto",
                zIndex: 10,
                position: "relative",
                
              }}
            >
              {/* <SupplierSaveDropDown setSupplier={setDatatoSupplierdata} /> */}
              <SaveDropdown
                apikey={"partyrecno"}
                disabled={rowData && rowData.length > 0?1:0}
                minInput={0}
                inputValue={20}
                displayLabel="Party"
                placeholder="Party"
                returnValue={setValforinput}
                apiurl={getcustomerlistwithoutdomain}
                selectionLabel={"descn"}
                selectionValue={"recno"}
                setDataToParent={setSupplierdata}
                width={"250px"}
                alignment="column"
                defaultSelected={supplierdata?.locationrecno}
                //  key={count}
                isrequired={true}
                key={count + 2}
              />

              <SaveTextInput
                //displayLabel="Referance Number"
                //alignment="column"
                apikey="partydescn"
                pageIndex={pageIndex}
                id="tr003-grn-dcnumber"
                placeholder={"Party Name"}
                defaultValue={val?.descn}
                change
                key={val?.descn}
                nonEditable={true}
                width={"250px"}
              />
            </div>

            <div onKeyDown={enterPress}>
              <div style={{ position: "relative" }}>
                <label
                  // htmlFor={id}
                  className={`label ${"focused"}`}
                  style={{
                    // zIndex: 1000,
                    whiteSpace: "nowrap",
                    wordWrap: "unset",
                    position: "absolute",
                    top: "-19px",
                    left: "12px",
                    //backgroundColor: "white",
                    padding: "0 4px",
                    fontSize: "12px",
                    color: "black",
                    pointerEvents: "none",
                    transform: "translateY(0)",
                    transition:
                      "top 0.3s, transform 0.3s, font-size 0.3s, color 0.3s",
                  }}
                >
                  Item Receipt No.
                </label>
                <input
                  style={{ ...poNumberStyle, marginTop: "0px" }}
                  placeholder={"To be allotted"}
                  disabled
                />
              </div>
            </div>

            <div onKeyDown={enterPress}>
              <SaveDatePicker
                placeholder={"Receipt Date"}
                disabled={rowData && rowData.length > 0?1:0}
                // defaultValue={auth?.state?.userdata?.workdate}
                defaultValue={grnDate}
                id={"grn-date"}
                displayLabel={"Receipt Date"}
                apiKey={"trdate"}
                dateLessthan={auth?.state?.userdata?.workdate}
                postAlertMsg={`GRN Date cannot be greater than Today's Date`}
                returnValue={(e) => {
                  setGrnDate(e);
                  // document.getElementById("tr003-grn-dcdate").focus();
                  return true;
                }}
                key={count}
              />
            </div>
            {/* <div>
                <SaveTextInput
                pageIndex={pageIndex}
                  displayLabel="PO Number"
                  alignment="column"
                  apikey="porefno"
                  id="tr003-grn-po"
                />
              </div> */}
            <div onKeyDown={enterPress}>
              <SaveTextInput
                isrequired
                pageIndex={pageIndex}
                displayLabel="Reference No."
                placeholder="Reference No."
                alignment="column"
                apikey="refno"
                id="tr003-grn-dcnumber"
              />
            </div>
            <div onKeyDown={enterPress}>
              <SaveDatePicker
                displayLabel="Reference Date"
                placeholder="Reference Date"
                defaultValue=""
                apiKey="refdate"
                id={"tr003-grn-dcdate"}
                dateLessthan={grnDate}
                postAlertMsg={`Reference Date cannot be greater than Receipt Date`}
                returnValue={(e) => {
                  setSupplierDcDate(e);
                  if (e > grnDate) {
                    //alert(`Reference DC Date cannot be greater than Receipt Date`);
                    document.getElementById("tr003-grn-dcdate").value = "";
                    // let tempCheck = document.getElementById("tr003-grn-dcdate");
                    // if (tempCheck.value === "") {
                    //   // document.getElementById("tr003-grn-dcdate").focus();
                    //   return true;
                    // }
                    // return;
                  }
                }}
                key={count}
              />
            </div>

            {/* <div>
              {" "}
              <SaveTextInput
              pageIndex={pageIndex}
                displayLabel="Party Locn"
                placeholder="Party Locn"
                //alignment="column"
                apikey="partylocationdescn"
                id="tr003-grn-dcnumber"
                // placeholder={"Enter the party Location"}
                change
                key={count}
              />
            </div> */}
            <div>
              {" "}
              <SaveTextInput
              pageIndex={pageIndex}
                displayLabel="Reason"
                placeholder="Reason"
                alignment="column"
                apikey="reason"
                id="tr003-grn-dcnumber"
                // placeholder={"Write the reason"}
                //defaultValue={val?.descn}
                // type="text"
                change
                key={count}
              />
            </div>
            <div style={{}}>
              <Button
                className="btn"
                type="default"
                onClick={(e) => setDisplayMessages(!displayMessages)}
              >
                Add / View Messages
              </Button>
            </div>
            <Button
              className="btn"
              // amol-390 - added height in style and changed marginTop from 2% to 28px
              style={{}}
              // multiple dc can be selected
              // disabled={
              //   auth?.state?.userdata?.onedconeinvoice &&
              //   rowData.length > 0 &&
              //   (rowData[0].doctype == "DC" ||
              //     rowData[0].doctype == "SO" ||
              //     !rowData[0]?.doctype)
              //     ? true
              //     : false
              // }
              onClick={(e) => {
                //let customerData=true

                setModalcount((p) => p + 1);

                if (val) {
                  setDisplayPrevDC(true);
                } else {
                  alert("Select Party");
                }
              }}
            >
              Pick from DC
            </Button>
          </form>
        </Panel>

        <Panel header="Item Details" key="3">
          <form>
            {" "}
            <Space display="flex">
              <div
                style={{ position: "relative" }}
                //onKeyDown={enterPress}
                /*on key Enter functionality  removed*/
              >
                <ItemSearch
                  itemSearchRef={searchref} // task id-256 rajendra auto focus issue itemSearchRef-inputRef because in common components inputRef is used
                  forFlag={"D"}
                  returnValue={(e) => {
                    setSelectedItem(e);
                  }}
                  supplierdata={val?.recno}
                  dropdown
                  defaultSelectedItem={getSelectedItem}
                  forPage={"PO"}
                  selection={false}
                  pageDetails={pageDetails}
                  barcodeReturnValue={(e) => {
                  
                    // Check the inward date condition for items with serial numbers
                  if(val?.customerrecno){
                    const bill = e?.itembatcharrayfound[0];
                    const inwardDate = bill?.outwarddate;
                   const stock= bill?.stock;
                   // Assuming trdate is available in the scope

                    // Perform validation only if item has a batch
                    if (e?.itemasbatch == 1) {
                    
                      // Check if inward date is less than the transaction date
                      if (inwardDate && inwardDate > Number(grnDate)) {
                        notification.error({
                          message: "Error", 
                          duration: 1,
                          description:
                            "Outward date is less that inward date",
                          style: { background: "#efa7a7" },
                        });
                        return;
                      }
                      if (Number(stock)>0) {
                        notification.error({
                          message: "Error", 
                          duration: 1,
                          description:
                            "Serial No. Already Present in System .",
                          style: { background: "#efa7a7" },
                        });
                        return;
                      }

                      // Proceed with adding the barcode scanned item to the table
                      fetchItems
                        .addBarcodeScannedItemToTable(
                          rowData,
                          e,
                          "PIF",
                          igstappl,
                          pageDetails,
                          "ITRCPT",
                          grnDate // Include transaction date in the payload
                        )
                        .then((res) => {
                          if (res?.length == 0) {
                            setRowData(rowData);
                          } else {
                            setRowData(res);
                            setChange(change + 1);
                          }
                        })
                        .catch((err) => {
                          notification.error({
                            message: "Error",
                            description: err,
                          });
                        });
                    } else {
                      setSelectedItem(e);
                    }
                  }else{
                    notification.error({
                      message: "Error", 
                      duration: 1,
                      description:
                        "Party is not selected",
                      style: { background: "#efa7a7" },
                    });
                  }
                  }}
                />

                <p>
                  {getSelectedItem?.itemdescn?.substring(
                    13,
                    (getSelectedItem?.itemdescn).length + 1
                  )}
                </p>
              </div>

              {pageDetails?.wantpackingextra ? (
                <div onKeyDown={enterPress}>
                  <p>Box</p>
                  <input
                    value={box}
                    //{Pradeep task id -255}
                    disabled={
                      getSelectedItem?.packingextrarecno > 0 ? false : true
                    }
                    onChange={(e) => setBox(e.target.value)}
                    style={{ ...inputStyles }}
                    id="tr001-input-box"
                    type="number"
                  />
                </div>
              ) : null}

              <div onKeyDown={enterPress}>
                <p>Pieces</p>
                <input
                  value={pcs}
                  //{Pradeep task id -255}
                  //disabled={getSelectedItem?.packingextrarecno==0&&pageDetails?.wantpackingextra==0? false:true}
                  onChange={(e) => setPcs(e.target.value)}
                  style={{ ...inputStyles }}
                  id="tr001-input-box"
                  type="number"
                />
              </div>

              {getSelectedItem?.wantbatchno ? (
                <>
                  <div onKeyDown={enterPress}>
                    <p>Batch No</p>
                    <input
                      value={batchNo}
                      onChange={(e) => setBatchNo(e.target.value)}
                      style={{ ...inputStyles, width: "200px" }}
                      id="tr001-input-batchno"
                      onBlur={(e) => {
                        /// batch validation if itemsbatch is true or false if batch is true it should not asked for batch again
                        if (getSelectedItem?.wantbatchno) {
                          if (!e.target.value) {
                            alert("Batch Cannot Be Empty");
                          }
                          return;
                        }
                      }}
                    />
                  </div>

                  <div
                    style={{ marginTop: 10, display: "flex" }}
                    onKeyDown={enterPress}
                  >
                    {pageDetails?.wantmanudate > 0 ? (
                      <div>
                        <p>Mfg Date</p>
                        <CallbackDateInput
                          defaultValue={
                            getSelectedItem?.wantbatchno == 0 &&
                            getSelectedItem?.manudatecompulsory == 0
                              ? auth?.state?.userdata?.workdate
                              : mfgDate
                          }
                          id={"po-manufacture-date-callback"}
                          // displayLabel={"Expiry Date"}
                          dateLessthan={auth?.state?.userdata?.workdate}
                          apiKey={"manudate"}
                          returnValue={(e) => {
                            setMfgDate(e);
                          }}
                          initial
                        />
                      </div>
                    ) : null}
                    {pageDetails?.wantexpdate > 0 ? (
                      <div style={{ marginLeft: "10px" }}>
                        <p>Exp Date</p>
                        <CallbackDateInput
                          defaultValue={
                            getSelectedItem?.wantbatchno == 0 &&
                            getSelectedItem?.expdatecompulsory == 0
                              ? "99991231"
                              : expDate
                          }
                          id={"po-expiry-date-callback"}
                          // displayLabel={"Expiry Date"}
                          dateGreaterthan={mfgDate}
                          apiKey={"manudate"}
                          returnValue={(e) => {
                            setExpDate(e);
                          }}
                        />
                      </div>
                    ) : null}
                  </div>
                </>
              ) : null}

              <div onKeyDown={enterPress}>
                <Button
                  ref={btnRef}
                  style={{ ...btnStyles }}
                  icon={<IoAperture size="1.2rem" />}
                  size="large"
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      // Add Validations of Add Properties
                      if (getSelectedItem?.wantbatchno) {
                        if (mfgDate <= auth?.state?.userdata?.workdate) {
                          if (batchNo && mfgDate && expDate) {
                            addItemstoTable();
                          } else {
                            alert("Please Enter Required Fields");
                          }
                        }
                      } else if (!getSelectedItem?.wantbatchno) {
                        addItemstoTable();
                      } else {
                        alert(
                          "Date should be less then " +
                            dateFormatter(auth?.state?.userdata?.workdate)
                        );
                      }
                    }
                  }}
                  onClick={() => {
                    // Add Validations of Add Properties
                    if (getSelectedItem?.wantbatchno) {
                      if (mfgDate <= auth?.state?.userdata?.workdate) {
                        if (batchNo && mfgDate && expDate) {
                          addItemstoTable();
                        } else {
                          alert("Please Enter Required Fields");
                        }
                      }
                    } else if (!getSelectedItem?.wantbatchno) {
                      addItemstoTable();
                    } else {
                      alert(
                        "Date should be less then " +
                          dateFormatter(auth?.state?.userdata?.workdate)
                      );
                    }
                  }}
                >
                  Add
                </Button>
              </div>
            </Space>
            {/* Table for Selection  */}
            <div style={{ position: "relative", zIndex: 0, marginTop: "24px" }}>
              {rowData.length > 0 && (
                <GrnTable
                  rowData={rowData}
                  setRowData={setRowData}
                  igstappl={igstappl}
                  scandata={scandata}
                  setScandata={setScandata}
                  setOpenItem={setOpenItem}
                  useChangedRows={useChangedRows}
                  pageDetails={pageDetails}
                />
              )}
            </div>
          </form>
        </Panel>
      </Collapse>

      {openItem && (
        <ScanTable
          data={scandata}
          openItem={openItem}
          setOpenItem={setOpenItem}
          rowData={rowData}
          setRowData={setRowData}
          renderer={renderer}
          setRenderer={setRenderer}
          setValidator={setValidator}
          forFlag={"P"}
          pageDetails={pageDetails}
          pageIndex={pageIndex}
        />
      )}
    </div>
  );
}

export default ItemReceipts;

const mainFrame = {
  width: "99vw",
  margin: "auto",
};

const topRowFilters = {
  gap: "24px",
  display: "flex",
  alignItems: "center",
};

// applying padding to align input rows inline
const callbackTextInputStyles = {
  display: "flex",
  flexWrap: "wrap",
  alignItems: "center",
  gap: "36px",
  paddingTop: "38px",
};

// add to table btn styles
const btnStyles = {
  display: "flex",
  alignItems: "center",
  gap: "8px",
  marginTop: "26px",
};

const inputStyles = {
  padding: "10px",
  border: "1px solid rgb(120,120,120)",
  width: "100px",
  fontSize: "16px",
  marginTop: "18px",
  borderRadius: "5px",
};

const poNumberStyle = {
  marginTop: "4px",
  border: "1px solid #dfdfdf",
  borderRadius: "8px",
  width: "200px",
  padding: "8px",
  fontSize: "16px",
};

//~# getdomainsupplieritemlist api endpoint for Item name => pass payload as { supplierrecno : 78123 } for item name
//~# conversionfactor = boxes * conversionfactor + pieces = qty

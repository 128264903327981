import { getMenuApi } from "../CommonControllers/ApiCalls";
import {
  AppstoreOutlined,
  ContainerOutlined,
  DesktopOutlined,
  MailOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  PieChartOutlined,
} from "@ant-design/icons";

export const convertToExpectedFormat = (menuArray) => {
  var expectedArray = menuArray.map((item) => {
    item["title"] = item.headerName;
    item["key"] = item.fastpath ? item.fastpath : item.headerName;
    if (item?.childArr?.length > 0) {
      var children = convertToExpectedFormat(item.childArr);
    } else {
      var children = null;
    }
    item["children"] = children;
    return item;
  });
  return expectedArray;
};
export const convertToExpectedFormatUserRole = (menuArray) => {
  var expectedArray = menuArray.map((item) => {
    // Create a new object to avoid modifying the original item
    var newItem = {
      title: item.headerName,
      key: item.fastpath ? item.fastpath : item.headerName,
      checkbox: true,
      editButton: true,
      isview:true,
      isadd:true,
      isdelete:true
    };

    if (item.childArr && item.childArr.length > 0) {
      var children = convertToExpectedFormatUserRole(item.childArr);
      newItem.children = children;
    }

    return newItem;
  });

  return expectedArray;
};
function getItem(label, key, icon, children, type) {
  return {
    key,
    icon,
    children,
    label,
    type,
  };
}

export const convertToExpectedMenuFormat = (menuArray) => {
  // console.log("Menu Array", menuArray);
  var expectedArray = menuArray.map((item) => {
    // console.log(item.headerName, item.childArr);
    if (item?.childArr?.length > 0) {
      var retvalue = {};
      var childArray = convertToExpectedMenuFormat(item.childArr);
      retvalue = getItem(
        item.headerName,
        item.headerName,
        <PieChartOutlined />,
        childArray
      );
    } else {
      retvalue = getItem(item.headerName, item.headerName);
    }
    // console.log("Return Value", retvalue);
    return retvalue;
  });
  // console.log("Expected Array", expectedArray);
  return expectedArray;
};

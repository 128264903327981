import React, {
  useContext,
  useState,
  useEffect,
  useRef,
  useCallback,
} from "react";
import TaskBar from "../Action/TaskBar";
import MainTab from "./MainTab";
import { changeTabs, checkFastPath } from "./tabFunctions";
import { Button, Drawer, Input, Layout, Modal, Radio, Space } from "antd";
import { tabStoreinitialState, TabStore, tabStorereducer } from "./TabsStore";
import { tab } from "@testing-library/user-event/dist/tab";
import TreeView from "../Action/TreeView";
import MenuBar from "../Action/MenuBar";
import useKeyboardShortcut from "use-keyboard-shortcut";
import { getMenuApi } from "../CommonControllers/ApiCalls";
import { convertToExpectedFormat } from "../Action/menu";
import { AuthStore } from "../Auth/AuthStore";
import PrintModal from "../CommonControllers/Print/PrintModal";
import ReactPrint from "../CommonControllers/Print/ReactPrint";
import { currentTabData } from "../CommonControllers/Variable";
import { messaging } from "../firebase";
import { getToken } from "firebase/messaging";
import { isSupported } from "firebase/messaging";
import { useHotkeys } from "react-hotkeys-hook";
import PrintBarcodes from "../CommonControllers/Print/PrintBarcodes";
const { Search } = Input;

function TabStructure() {
  const tabStore = useContext(TabStore);
  const authStore = useContext(AuthStore);
  const theme = authStore?.state?.userdata?.theme[0];
  console.log("theme  from API", theme);
  const [searchBar, showSearchBar] = useState(false);
  const searchRef = useRef();
  const printRef = useRef(null);

  useEffect(() => {
    let link = document.querySelector("link[rel~='icon']");

    let title = document.querySelector("title");

    if (!link) {
      link = document.createElement("img");

      link.rel = "icon";

      document.getElementsByTagName("head")[0].appendChild(link);
    }

    var myBlob = authStore.state.userdata?.logo;

    var newTitle = authStore.state.userdata.domaindescn;

    console.log("authStore.state.userdata", authStore.state.userdata);

    title.innerHTML = newTitle;

    link.href = `data:image/jpeg;base64,${myBlob}`;
  }, []);
  useEffect(() => {
    // Firebase Notification Initialization and Notification Permission

    requestPermission();
  }, []);

  async function requestPermission() {
    const browserPermission = await isSupported();

    const permission = await Notification.requestPermission();

    if (permission == "granted" && browserPermission) {
      // generate token
      const token = await getToken(messaging, {
        vapidKey:
          "BLpQFKnRLcQ7s0hzzpl_vrTqe_g3QCedCug0Ro_aRU5u75ftQxGnX3pKVuWO-dBxSaCQTsxnuD7C3Igsz9kQk6w",
      });
    } else if (permission == "denied") {
      alert("Notification Permission Denied");
    }
  }

  const onClose = () => {
    tabStore.dispatch({ type: "Toggle_Drawer" });
  };

  useHotkeys("Control+Shift+H", () => showSearchBar(!searchBar, []));

  async function getMenu() {
    const resp = await getMenuApi(authStore.state.userdata);

    const data = convertToExpectedFormat(resp.Message);

    tabStore.dispatch({ type: "UPDATE_MENU", data: data });
  }

  useEffect(() => {
    if (searchRef && searchRef.current) {
      searchRef.current.focus();
    }
  });

  const changeTab = (e) => {
    // If Enter Key is Pressed Go to that page
    if (e.key == "Enter") {
      checkFastPath(e.target.value, tabStore);
      showSearchBar(!searchBar);
    }
  };

  useEffect(() => {
    getMenu();
  }, []);

  const defaultTheme = {
    bgcolor: "#ffffff", // Default background color
    hovercolor: "#f0f0f0", // Default hover color
    inputbgcolor: "#ffffff", // Default input background color
    focuscolor: "#ebedf0", // Default focus color
    btncolor: "#ebedf0", // Default button color
    btnfocuscolor: "#ebedf0", // Default button focus color
    footerbgcolor: "#ebedf0", // Default footer background color
    btnhovercolor: "#ebedf0", // Default button hover color
    textcolor: "#000000", // Default text color
    bordercolor: "#383838", // Default border color
    taskbarbtncolor: "white", // Default taskbar button color
    taskbardisabledbtncolor: "#ebedf0", // Default taskbar disabled button color
    collapseboxbgcolor: "#f8f9fa", // Default collapse box background color
    collapseheaderbgcolor: "#ebedf0", // Default collapse header background color
    collapseboxtextcolor: "#000000", // Default collapse box text color
    maintabbgcolor: "#ffffff", // Default main tab background color
    taskbarbgcolor: "#f8f9fa", // Default taskbar background color
    taskbartextcolor: "#000000", // Default taskbar text color
  };
  const mergedTheme = { ...defaultTheme, ...theme };

  console.log("theme", mergedTheme);

  // Access the root element
  const root = document.documentElement;

  // Update the CSS variables with theme values
  root.style.setProperty("--bg-color", mergedTheme?.bgcolor);
  root.style.setProperty("--hover-color", mergedTheme?.hovercolor);
  root.style.setProperty("--input-bg-color", mergedTheme?.inputbgcolor);
  root.style.setProperty("--input-disabled-color", mergedTheme?.inputbgcolor);
  root.style.setProperty("--focus-color", mergedTheme?.focuscolor);
  root.style.setProperty("--button-color", mergedTheme?.btncolor);
  root.style.setProperty("--btn-focus-color", mergedTheme?.btnfocuscolor);
  root.style.setProperty("--footer-bg-color", mergedTheme?.footerbgcolor);
  root.style.setProperty("--btn-hover-color", mergedTheme?.btnhovercolor);
  root.style.setProperty("--text-color", mergedTheme?.textcolor);
  root.style.setProperty("--border-color", mergedTheme?.bordercolor);
  root.style.setProperty("--taskbar-btn-color", mergedTheme?.taskbarbtncolor);
  root.style.setProperty(
    "--taskbar-disabled-btn-color",
    mergedTheme?.taskbardisabledbtncolor
  );
  root.style.setProperty(
    "--collapse-box-bg-color",
    mergedTheme?.collapseboxbgcolor
  );
  root.style.setProperty(
    "--collapse-header-bg-color",
    mergedTheme?.collapseheaderbgcolor
  );
  root.style.setProperty(
    "--collapse-box-text-color",
    mergedTheme?.collapseboxtextcolor
  );
  root.style.setProperty("--maintab-bg-color", mergedTheme?.maintabbgcolor);
  root.style.setProperty("--taskbar-bg-color", mergedTheme?.taskbarbgcolor);
  root.style.setProperty("--taskbar-text-color", mergedTheme?.taskbartextcolor);

  // Optionally, force a reflow to apply the changes immediately
  void root.offsetHeight;

  return (
    <div>
      <Modal
        open={searchBar}
        //onOk={this.handleOk}
        onCancel={() => {
          showSearchBar(!searchBar);
        }}
        footer={null}
      >
        <div style={{ padding: "0px 10px" }}>
          <Input
            autoFocus
            ref={searchRef}
            id="fast-path-search"
            size="large"
            placeholder="Enter Fast Path"
            onKeyDown={changeTab}
          />
        </div>
      </Modal>

      <TaskBar />

      <Drawer
        title="Application Menu"
        placement={"left"}
        onClose={onClose}
        open={tabStore.state.menu}
        key={"left"}
        width={"auto"}
        extra={
          <Space>
            <Button style={{ marginLeft: "10px" }} onClick={onClose}>
              Cancel
            </Button>
          </Space>
        }
        footer={<Space>Powered By Sutradhar</Space>}
      >
        <TreeView />
      </Drawer>

      {/* <MenuBar/> */}
      <MainTab />
      {/* 2 props: url-endpoint & ins */}

      {tabStore.state.printUrl ? (
        <ReactPrint
          url={tabStore.state?.printUrl}
          ins={tabStore.state?.printIns}
        />
      ) : null}

      {tabStore.state.printInsBarcode ? (
        <PrintBarcodes ins={tabStore.state?.printInsBarcode} />
      ) : null}
    </div>
  );
}

export default TabStructure;

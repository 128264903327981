import { Button, Modal, Popconfirm, Tooltip } from "antd";
import { AiFillDelete, AiOutlinePlusSquare } from "react-icons/ai";
import "../../../CommonControllers/global.css";
import { removeByAttribute } from "../../../CommonControllers/AppFunction";

export const grnColumns = (
  item,
  setRowData,
  rowData,
  openTestCertificateNoModal,
  setOpenTestCertificateNoModal,
  openSerialNoModal,
  setOpenSerialNoModal,
  handleOk,
  handleCancel,
  updateValues,
  modalData,
  setItemCount,
  pageDetails,
  inputValues,
  setInputValues,
  serialnoInput,
  setserialnoInput
) => {
  return [
    // Delete
    {
      title: " ",
      dataIndex: "active",
      key: "active",
      width: "80px",
      align: "center",
      render: (_, text) => {
        return (
          <Popconfirm
            title="Do you want to Delete the changes?"
            onConfirm={(e) => {
              handleRemove(text, setRowData, rowData);
            }}
          >
            <Button icon={<AiFillDelete />}></Button>
          </Popconfirm>
        );
      },
    },

    // Item Name
    {
      title: "Item Description",
      dataIndex: "itemdescn",
      key: "itemdescn",
      width: "300px",
      align: "left",
    },

    // No. of packages
    {
      title: "No. of packages",
      dataIndex: "packagesno",
      key: "packagesno",
      width: "120px",
      align: "center",
      render: (_, text) => {
        return (
          <input
            className="tr001Input"
            type={"number"}
            defaultValue={text.packagesno}
            onFocus={(e) => e.target.select()}
            onChange={(e) => {
              const regex = /^[0-9\b]+$/;
              if (e.target.value === "" || regex.test(e.target.value)) {
                text.packagesno = e.target.value;
                handleChange(text, setRowData, item, rowData);
              } else {
                alert("Invalid Input");
                e.target.value = "";
                text.packagesno = e.target.value;
                handleChange(text, setRowData, item, rowData);
              }
            }}
          />
        );
      },
    },

    // Length
    {
      title: "Length (cm)",
      dataIndex: "length",
      key: "length",
      width: "100px",
      align: "center",
    },

    // Width
    {
      title: "Width (cm)",
      dataIndex: "width",
      key: "width",
      width: "100px",
      align: "center",
    },

    // Height
    {
      title: "Height (cm)",
      dataIndex: "height",
      key: "height",
      width: "100px",
      align: "center",
    },

    // Quantity
    {
      title: "Quantity",
      dataIndex: "qty",
      key: "qty",
      width: "120px",
      align: "center",
      render: (_, text) => {
        return (
          <input
            className="tr001Input"
            type="number"
            defaultValue={text.qty}
            onFocus={(e) => e.target.select()}
            // onChange={(e) => {
            //   const regex = /^[0-9\b]+$/;
            //   if (e.target.value === "" || regex.test(e.target.value)) {
            //     text.qty = e.target.value;
            //     handleChange(text, item, setRowData, rowData);
            //   } else {
            //     alert("Invalid Input");
            //     e.target.value = "";
            //     text.qty = e.target.value;
            //     handleChange(text, item, setRowData, rowData);
            //   }
            // }}
            onChange={(e) => {
              if (e.target.value < 0) {
                e.target.value = null;
                alert("Please Enter Positive Value");
                return false;
              }
              // const regex = /^[0-9\b]+$/;
              const regex = /^\d+(\.\d+)?$/;
              if (regex.test(e.target.value) > 0) {
                text.qty = e.target.value;
                handleChange(text, setRowData, item, rowData);
              } else {
                e.target.value = text.qty;
                alert("Quantity is required.");
              }
            }}
          />
        );
      },
    },

    // Batch No.
    {
      hidden: pageDetails?.wantbatchno ? true : true,
      width: 100,
      title: "Batch No.",
      dataIndex: "itembatchno",
      key: "itembatchno",
      align: "center",
      render: (_, text) => {
        return (
          <input
            className="tr001Input"
            defaultValue={text.itembatchno}
            onFocus={(e) => e.target.select()}
            onChange={(e) => {
              text.itembatchno = e.target.value;
              handleChange(text, setRowData, item, rowData);
            }}
          />
        );
      },
    },

    // Volume
    {
      title: "Volume (m³)",
      dataIndex: "volume",
      key: "volume",
      width: "120px",
      align: "center",
    },

    // Net Weight
    {
      title: "Net Weight (Kg)",
      dataIndex: "netweight",
      key: "netweight",
      width: "130px",
      align: "center",
      render: (_, text) => {
        return (
          <input
            className="tr001Input"
            type="number"
            defaultValue={text.netweight}
            onFocus={(e) => e.target.select()}
            onChange={(e) => {
              const regex = /^\d+(\.\d+)?$/;
              if (e.target.value === "" || regex.test(e.target.value)) {
                text.netweight = e.target.value;
                handleChange(text, setRowData, item, rowData);
              } else {
                alert("Invalid Input");
                e.target.value = "";
                text.netweight = e.target.value;
                handleChange(text, setRowData, item, rowData);
              }
            }}
          />
        );
      },
    },

    // Gross Weight
    {
      title: "Gross Weight (Kg)",
      dataIndex: "grossweight",
      key: "grossweight",
      width: "130px",
      align: "center",
      render: (_, text) => {
        return (
          <input
            className="tr001Input"
            type="number"
            defaultValue={text.grossweight}
            onFocus={(e) => e.target.select()}
            onChange={(e) => {
              const regex = /^\d+(\.\d+)?$/;
              if (e.target.value === "" || regex.test(e.target.value)) {
                text.grossweight = e.target.value;
                handleChange(text, setRowData, item, rowData);
              } else {
                alert("Invalid Input");
                e.target.value = "";
                text.grossweight = e.target.value;
                handleChange(text, setRowData, item, rowData);
              }
            }}
          />
        );
      },
    },

    // Test Certificate No.
    {
      title: "Test Certificate No.",
      dataIndex: "testcertificateno",
      key: "testcertificateno",
      width: "120px",
      align: "center",
      render: (_, text) => {
        console.log("testcertificateno", text);
        let values = [];
        if (typeof modalData?.testcertificateno === "string") {
          values = modalData.testcertificateno.split(",").filter(Boolean);
        } else if (Array.isArray(modalData?.testcertificateno)) {
          values = modalData.testcertificateno.filter(
            (value) => value.trim() !== ""
          );

          // Update modalData.testcertificateno only if there are non-empty values
          if (values.length > 0) {
            modalData.testcertificateno = values.join(",");
          }
        }
        return (
          <div>
            <Button
              onClick={() => {
                setOpenTestCertificateNoModal(true);
                const initialValues = Array.isArray(
                  modalData?.testcertificateno
                )
                  ? modalData.testcertificateno.filter(
                      (val) => val.trim() !== ""
                    )
                  : typeof modalData?.testcertificateno === "string"
                  ? modalData.testcertificateno
                      .split(",")
                      .filter((val) => val.trim() !== "")
                  : [];
                setInputValues([...initialValues]);
                updateValues(text);
                setItemCount((q) => q + 1);
              }}
            >
              TC No.
            </Button>
            <Modal
              title={"Test Certificate No."}
              open={openTestCertificateNoModal}
              onOk={handleOk}
              onCancel={() => {
                const nonEmptyValues = inputValues.filter(
                  (value) => value.trim() !== ""
                );
                // // Update inputValues with non-empty values
                setInputValues(nonEmptyValues);

                // Update values with non-empty values
                const updatedValues = nonEmptyValues.join(",");
                values = updatedValues ? updatedValues.split(",") : [];

                // Update modalData.testcertificateno only if there are non-empty values
                if (values.length > 0) {
                  modalData.testcertificateno = values.join(",");
                }
                handleCancel();
              }}
              centered
              footer={null}
            >
              <div style={{ ...modalBox }}>
                <table>
                  <thead>
                    <tr>
                      <th style={{ textAlign: "left" }}>Item Description</th>
                      <th style={{ textAlign: "right" }}>Quantity</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td style={{ textAlign: "left" }}>
                        {modalData?.label ?? modalData?.itemdescn}
                      </td>
                      <td style={{ textAlign: "right" }}>
                        {modalData?.modalData ?? modalData?.qty}
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div style={{ marginTop: "10px" }}>
                  <Tooltip title="Add Test Certificate No." placement="right">
                    <Button
                      onClick={() =>
                        setInputValues((prevValues) => [...prevValues, ""])
                      }
                      icon={<AiOutlinePlusSquare size="1.2rem" />}
                    />
                  </Tooltip>
                </div>
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "repeat(3, 1fr)",
                    gap: "20px",
                    marginTop: "24px",
                  }}
                >
                  {inputValues?.map((value, index) => (
                    <div key={index} style={{ marginBottom: "8px" }}>
                      <input
                        className="WMSInput"
                        type="text"
                        value={value}
                        onFocus={(e) => e.target.select()}
                        onChange={(e) => {
                          // Remove empty values from newValues and update modalData.testcertificateno
                          const nonEmptyValues = inputValues.filter(
                            (val) => val.trim() !== ""
                          );
                          values = nonEmptyValues;
                          // Update the corresponding value in the inputValues array
                          const newValues = [...inputValues];
                          newValues[index] = e.target.value;
                          setInputValues(newValues);
                          modalData.testcertificateno = values.join(",");
                          handleChange(text, item, setRowData, rowData);
                        }}
                      />
                    </div>
                  ))}
                </div>
              </div>
            </Modal>
          </div>
        );
      },
    },

    // Serial No.
    {
      title: "Serial No.",
      dataIndex: "serialno",
      key: "serialno",
      width: "120px",
      align: "center",
      render: (_, text) => {
        let values = [];
        if (typeof modalData?.serialno === "string") {
          values = modalData.serialno.split(",").filter(Boolean);
        } else if (Array.isArray(modalData?.serialno)) {
          values = modalData.serialno.filter((value) => value.trim() !== "");

          // Update modalData.serialno only if there are non-empty values
          if (values.length > 0) {
            modalData.serialno = values.join(",");
          }
        }
        return (
          <div>
            <Button
              onClick={() => {
                setOpenSerialNoModal(true);
                const initialValues = Array.isArray(modalData?.serialno)
                  ? modalData.serialno.filter((val) => val.trim() !== "")
                  : typeof modalData?.serialno === "string"
                  ? modalData.serialno
                      .split(",")
                      .filter((val) => val.trim() !== "")
                  : [];
                setserialnoInput([...initialValues]);
                updateValues(text);
                setItemCount((q) => q + 1);
              }}
            >
              Serial No.
            </Button>
            <Modal
              title={"Serial No."}
              open={openSerialNoModal}
              onOk={handleOk}
              onCancel={() => {
                const nonEmptyValues = serialnoInput.filter(
                  (value) => value.trim() !== ""
                );
                // // Update inputValues with non-empty values
                setserialnoInput(nonEmptyValues);

                // Update values with non-empty values
                const updatedValues = nonEmptyValues.join(",");
                values = updatedValues ? updatedValues.split(",") : [];

                // Update modalData.testcertificateno only if there are non-empty values
                if (values.length > 0) {
                  modalData.serialno = values.join(",");
                }
                handleCancel();
              }}
              centered
              footer={null}
            >
              <div style={{ ...modalBox }}>
                <table>
                  <thead>
                    <tr>
                      <th style={{ textAlign: "left" }}>Item Description</th>
                      <th style={{ textAlign: "right" }}>Quantity</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td style={{ textAlign: "left" }}>
                        {modalData?.label ?? modalData?.itemdescn}
                      </td>
                      <td style={{ textAlign: "right" }}>
                        {modalData?.modalData ?? modalData?.qty}
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div style={{ marginTop: "10px" }}>
                  <Tooltip title="Add Serial No." placement="right">
                    <Button
                      onClick={() =>
                        setserialnoInput((prevValues) => [...prevValues, ""])
                      }
                      icon={<AiOutlinePlusSquare size="1.2rem" />}
                    />
                  </Tooltip>
                </div>
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "repeat(3, 1fr)",
                    gap: "20px",
                    marginTop: "24px",
                  }}
                >
                  {serialnoInput?.map((value, index) => (
                    <div key={index} style={{ marginBottom: "8px" }}>
                      <input
                        className="WMSInput"
                        type="text"
                        value={value}
                        onFocus={(e) => e.target.select()}
                        onChange={(e) => {
                          // Remove empty values from newValues and update modalData.testcertificateno
                          const nonEmptyValues = serialnoInput.filter(
                            (val) => val.trim() !== ""
                          );
                          values = nonEmptyValues;
                          // Update the corresponding value in the inputValues array
                          const newValues = [...serialnoInput];
                          newValues[index] = e.target.value;
                          setserialnoInput(newValues);
                          modalData.serialno = values.join(",");
                          handleChange(text, item, setRowData, rowData);
                        }}
                      />
                    </div>
                  ))}
                </div>
              </div>
            </Modal>
          </div>
        );
      },
    },

    // Nature of packing
    {
      title: "Nature of Packing",
      dataIndex: "nopacking",
      key: "nopacking",
      width: "300px",
      align: "center",
    },

    // Remarks
    {
      title: "Remarks",
      dataIndex: "remarks",
      key: "remarks",
      width: "300px",
      align: "center",
      render: (_, text) => {
        return (
          <input
            className="WMSInput"
            type="text"
            defaultValue={text.remarks}
            onFocus={(e) => e.target.select()}
            onChange={(e) => {
              text.remarks = e.target.value;
              handleChange(text, setRowData, item, rowData);
            }}
          />
        );
      },
    },
  ];
};

export const handleAdd = (
  item,
  setRowData,
  setSelectedItem,
  shortguid,
  noOfPackages,
  itemLength,
  itemWidth,
  itemHeight,
  quantity,
  batchNo,
  itemVolume,
  itemNetWeight,
  itemGrossWeight,
  itemTestCertificateNo,
  itemSerialNo,
  natureOfPacking,
  itemRemarks,
  itemAttribute
) => {
  // Shortguid for item
  var newShortguid = shortguid.getShortguid("WMSGRN" + item.itemrecno);
  console.log("handleAdd is called", itemAttribute);
  // No. of packages
  // var packagesno = noOfPackages ? noOfPackages : 0;

  // Length
  var length = itemLength ? itemLength : 0;

  // Width
  var width = itemWidth ? itemWidth : 0;

  // Height
  var height = itemHeight ? itemHeight : 0;

  // Quantity
  var qty = quantity ? quantity : 0;

  // Volume
  var volume = Number(qty) * Number(item?.itemvolume);

  // Net Weight
  // var netweight = itemNetWeight ? itemNetWeight : 0;

  // Gross Weight
  // var grossweight = itemGrossWeight ? itemGrossWeight : 0;

  // Test Certificate No.
  // var testcertificateno = itemTestCertificateNo ? itemTestCertificateNo : "";
  var testcertificateno = Array.isArray(itemAttribute?.testcertificateno)
    ? itemAttribute?.testcertificateno
        .filter((val) => val.trim() !== "")
        .map((val) => val.replace(/[^a-zA-Z0-9,]+/g, ""))
        .join(",")
        .replace(/,{2,}/g, ",") // Remove consecutive commas
    : typeof itemAttribute?.testcertificateno === "string"
    ? itemAttribute?.testcertificateno
        .replace(/[^a-zA-Z0-9,]+/g, "")
        .replace(/,{2,}/g, ",")
    : "";

  // Serial No.
  // var serialno = itemSerialNo ? itemSerialNo : 0;
  var serialno =
    Array.isArray(itemAttribute?.serialno) && itemAttribute.serialno.length > 0
      ? itemAttribute.serialno
          .map((val) => val.replace(/[^0-9]+/g, ""))
          .join(",")
      : typeof itemAttribute?.serialno === "string"
      ? itemAttribute?.serialno
          .split(",")
          .map((val) => val.replace(/\D/g, ""))
          .filter((val) => val.trim() !== "")
          .join(",")
      : "";

  // Nature of packing
  var nopacking = natureOfPacking ? natureOfPacking : "";

  // Remarks
  // var remarks = itemRemarks ? itemRemarks : "";

  var itemObject = {
    ...item,
    recno: null, // recno null when adding new item
    shortguid: newShortguid,
    itemrecno: item.itemrecno,
    itemdescn: item.itemdescn,
    packagesno: itemAttribute?.packagesno ? itemAttribute.packagesno : 0,
    length: Number(length).toFixed(2),
    width: Number(width).toFixed(2),
    height: Number(height).toFixed(2),
    qty: qty,
    itembatchno: batchNo,
    volume: Number(volume).toFixed(2),
    netweight: Number(
      itemAttribute?.netweight ? itemAttribute.netweight : 0
    ).toFixed(2),
    grossweight: Number(
      itemAttribute?.grossweight ? itemAttribute.grossweight : 0
    ).toFixed(2),
    testcertificateno: testcertificateno,
    serialno: serialno,
    nopacking: nopacking,
    remarks: itemAttribute?.remarks,
  };
  setRowData((p) => [...p, itemObject]);
  setSelectedItem(null);
};

export const handleChange = (text, setRowData, item, rowData) => {
  // No. of packages
  let packagesno = text?.packagesno ? Number(text.packagesno) : 0;

  // Length (in cm)
  let length = text?.length ? Number(text.length) : 0;

  // Width (in cm)
  let width = text?.width ? Number(text.width) : 0;

  // Height (in cm)
  let height = text?.height ? Number(text.height) : 0;

  // Quantity
  let qty = text?.qty ? Number(text.qty) : 0;

  // Volume (converted to cubic meters)
  let volume = (qty * length * width * height) / 1e6;

  // Net Weight
  let netweight = text?.netweight ? Number(text.netweight) : 0;

  // Gross Weight
  let grossweight = text?.grossweight ? Number(text.grossweight) : 0;

  // Test Certificate No.
  let testcertificateno = text?.testcertificateno ? text.testcertificateno : "";

  // Serial No.
  let serialno = text?.serialno ? text.serialno : 0;

  // Nature of packing
  let nopacking = text?.nopacking ? text.nopacking : "";

  // Remarks
  let remarks = text?.remarks ? text.remarks : "";

  var itemObject = {
    ...text,
    itemrecno: text.itemrecno,
    itemdescn: text.itemdescn,
    packagesno: packagesno,
    length: Number(length).toFixed(2),
    width: Number(width).toFixed(2),
    height: Number(height).toFixed(2),
    qty: qty,
    volume: Number(volume).toFixed(2),
    netweight: Number(netweight).toFixed(2),
    grossweight: Number(grossweight).toFixed(2),
    testcertificateno: testcertificateno,
    serialno: serialno,
    nopacking: nopacking,
    remarks: remarks,
  };

  var currentItem = rowData.map((itm) => {
    if (itm.itemrecno === text.itemrecno) {
      return { ...itemObject };
    } else {
      return { ...itm };
    }
  });
  setRowData(() => currentItem);
};

export const handleRemove = (item, setRowData, rowData) => {
  var removedArray = removeByAttribute(rowData, "itemrecno", item.itemrecno);
  setRowData(() => [...removedArray]);
};

// CSS
const modalBox = {
  width: "100%",
  height: "100%",
  padding: "4px",
};

import React, { useEffect } from 'react'

function TaskValor() {

    useEffect(() => {
        setTimeout(loginToTaskValor(),1000)
    }, [])

    const loginToTaskValor = () => {
        document.getElementById('test').contentWindow.postMessage({"loginId" : "demo", "password" : "Demo@123"}, "*")

        console.log("HILO")
    }
  return (
    <div>
          <iframe src='https://project.taskvalor.com/demosite1/' style={{width:"100vw", height:"100vh", marginTop:"20px"}} id='test' title='Task Valor'></iframe>
    </div>
  )
}

export default TaskValor
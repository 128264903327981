//~# Table for GRN - TR003
import { Button, Popconfirm } from "antd";
import { AiFillDelete } from "react-icons/ai";
import {
  addMonthsAndDays,
  dateFormatter,
  removeByAttribute,
} from "../../CommonControllers/AppFunction";
import CallbackDateInput from "../../CommonControllers/Components/CallbackComponents/CallbackDateInput";
import "../../CommonControllers/global.css";
import { BiAddToQueue } from "react-icons/bi";

export const grnColumns = (
  igstappl,
  setRowData,
  rowData,
  openItem,
  setOpenItem,
  scandata,
  setScandata,
  workdate,
  setRenderer, grnDate
  ,
 
  pageDetails,setItemForSelection,setopenBatch,setopenBatchcount
) => {
  console.log("TESTING",rowData)
  return [
    {
      title: "",
      dataIndex: "active",
      key: "active",
      width: "70px",
      render: (_, text) => {
        return (
          <Popconfirm
            title="Do you want to Delete the changes?"
            onConfirm={(e) => {
              handleRemove(text, setRowData, rowData);
            }}
          >
            <Button icon={<AiFillDelete />}></Button>
          </Popconfirm>
        );
      },
    },
    {
      title: "Item Description",
      dataIndex: "itemdescn",
      key: "itemdescn",
      width: "max-content",
    },
    {
      title: "Scan Barcode",
      dataIndex: "barcode",
      align: "right",
      key: "barcode",
      width: "8%",
      render: (_, text) => {
       
        if (text.itemasbatch) {
          return (
            <Button
              onClick={() => {
                setOpenItem(true);
                handleChange(
                  text,
                  setRowData,
                  igstappl,
                  rowData,
                  scandata,
                  setScandata
                );
              }}
            // disabled={text?.packingqtyaccepted !== 0 || text?.packingextraqtyaccepted !== 0}
            >
              Item Scan
            </Button>
          );
        } else return null;
      },
    },
    ,
    {
      hidden: !pageDetails?.wantpackingextra ? true : false,
      title: "Boxes",
      dataIndex: "box",
      key: "box",
      width: "6%",
      align: "right",
      render: (_, text) => {
        return (
          <input
            className={`tr001Input tr001Box`}
            defaultValue={text?.packingextraqty} //~#packingextraqty
            type="number"
            onFocus={(e) => e.target.select()}
            onChange={(e) => {
              if (e.target.value < 0) {
                e.target.value = null;
                return false;
              }
              const regex = /^[0-9\b]+$/;
              if (e.target.value === "" || regex.test(e.target.value)) {
                text.packingextraqty = e.target.value;
                handleChange(text, setRowData, igstappl, rowData);
              }
            }}
          />
        );
      },
    },
    {
      title: " Accepted Pieces",
      dataIndex: "acceptedpieces",
      key: "acceptedpieces",
      width: "8%",
      render: (_, text) => {
        if (text.itemasbatch) {
          return  <p>{text?.itembatcharray?.length}</p>
        } else {
          return <input
          className={`tr001Input tr001Box`}
          style={{ textAlign: "left" }}
          defaultValue={text?.packingqtyaccepted}
          type="text"
          onFocus={(e) => e.target.select()}
          onChange={(e) => {
            if (e.target.value < 0) {
              e.target.value = null;

              return false;
            }
            text.packingqtyaccepted = e.target.value;
            handleChange(text, setRowData, igstappl, rowData);
          }}
        />
        }
     },       
      align: "right",
    },
    {
      title: "Pieces",
      dataIndex: "qty",
      key: "qty",
      width: "8%",
        
      align: "right",
      render: (_, text) => {
     let x=parseInt(text.qty)+1
        //if (text?.wantbatchno) {
          return (
          <>  <input
          className={`tr001Input tr001Box`}
          style={{ textAlign: "left" }}
          defaultValue={text?.qty}
          type="text"
           key={x}
          onFocus={(e) => e.target.select()}
          onChange={(e) => {
            if (e.target.value < 0) {
              e.target.value = null;

              return false;
            }
            text.packingqty = e.target.value;
            handleChange(text, setRowData, igstappl, rowData,scandata,
                      setScandata);
          }}
        />  
          </>
          );
        // } else{
        //   return<p>{text.qty}</p>
        // }
      },
    },
    {
      hidden: pageDetails?.wantbatchno ? false : true,
      title: "Batch No",
      dataIndex: "itembatchno",
      key: "itembatchno",
      width: "8%",
      align: "left",
      render: (_, text) => {
        if (text?.wantbatchno) {
          return (
          <> <div style={{display:"flex"}}> <input
          className={`tr001Input tr001Box`}
          style={{ textAlign: "left" }}
          defaultValue={text?.itembatchno}
          type="text"
          key={text?.shortguid}
          onFocus={(e) => e.target.select()}
          onChange={(e) => {
            if (e.target.value < 0) {
              e.target.value = null;

              return false;
            }
            text.itembatchno = e.target.value;
            handleChange(text, setRowData, igstappl, rowData);
          }}
        />  <Button
        onClick={() => {
          setItemForSelection(text);
          setopenBatch(true)
          setopenBatchcount((p)=>p+1)
        }}
        style={{ fontSize: '20px',border:"none" }}
          icon={
            <BiAddToQueue
             
            />
          }
        ></Button></div>
          </>
          );
        } else {
          return <p>.</p>;
        }
      },
    },
    
    {
      hidden: pageDetails?.wantmanudate === 1 ? false : true,
      title: "Mfg Date",
      dataIndex: "manufacturedate",
      key: "manufacturedate",
      width: "15%",
      align: "right",
      render: (_, text) => {
    
        if (text?.wantbatchno) {
          return (
            <div>
              <CallbackDateInput
              width={"130px"}
                apiKey={"manufacturedate"}
                id={"grn-manufacturedate" + text.shortguid}
                key={text?.shortguid}
                defaultValue={text?.manufacturedate}
                returnValue={(e) => {
                  text.manufacturedate = e;
                  if(text?.expmonths > 0 || text?.expdays > 0){
                    let months = text?.expmonths > 0 ? Number(text?.expmonths) - 1 : 0;
                    let days = text?.expdays > 0 ? Number(text?.expdays) - 1 : 0;
                    let expDate = addMonthsAndDays(e, months, days)
                    text.expdate = expDate;
                  }
                  handleChange(
                    text,
                    setRowData,
                    igstappl,
                    rowData,
                    null,
                    null,
                    //setRenderer
                  );
                }}
                initial
                dateLessthan={grnDate}
              />
            </div>
          );
        } else {
          return <p>{dateFormatter(text?.manufacturedate)}</p>;
        }
      },
    },
    {
      hidden: pageDetails?.wantexpdate === 1 ? false : true,
      title: "Expiry Date",
      dataIndex: "expirydate",
      key: "expirydate",
      width: "15%",
      align: "right",
      render: (_, text) => {
        if (text?.wantbatchno) {
          return (
            <div >
              <CallbackDateInput
               width={"130px"}
                id={"grn-expdate" + text.shortguid}
                apiKey={"expirydate"}
                key={text?.shortguid}
                defaultValue={text?.expdate}
                returnValue={(e) => {
                  text.expdate = e;
                  handleChange(
                    text,
                    setRowData,
                    igstappl,
                    rowData,
                    null,
                    null,
                    //setRenderer
                  );
                }}
                dateGreaterthan={text?.manufacturedate}
              />
            </div>
          );
        } else {
          return <p>{dateFormatter(text?.expdate)}</p>;
        }
      },
    }
  
   
   
  ];
};

export const handleAdd = (
  item,
  setRowData,
  igstappl,
  setSelectedItem,
  shortguid,
  purchaseRate,
  purchaseQty,
  box,
  batchno,
  manufacturedate,
  expdate
) => {
  if (item?.packingextraqty < 0 || item?.packingqty < 0 || item?.purrate < 0) {
    alert("Cannot enter 0",item);
    return;
  }

  //~# Input boxes validation
  const boxValue = document.querySelector("tr001-input-box");
  const piecesValue = document.querySelector("tr001-input-pieces");
  const rateValue = document.querySelector("tr001-input-rate");

  console.log("tr001 handle add  ==>", item, boxValue, piecesValue, rateValue);

  var newShortguid = shortguid.getShortguid("POF" + item.itemrecno);
  console.log("tr001 Added Shortguid", newShortguid, box, purchaseQty);
  var purrate = purchaseRate ? purchaseRate : 0.0;
  var cgstrate = item?.cgstrate ? item.cgstrate : 0.0;
  var sgstrate = item?.sgstrate ? item.sgstrate : 0.0;
  var igstrate = item?.igstrate ? item.igstrate : 0.0;
  /// manu and expdate 
  var manufacturedate=item?.manufacturedate?item?.manufacturedate:manufacturedate
  var expdate=item?.expdate?item?.expdate:expdate
  var itembatchno=item?.itembatchno?item.itembatchno:batchno

  // var packingqty = item?.packingqty ? item.packingqty : 0; //~# here qty of pieces changed to key-name packingqty, box will be freepackingqty
  // var packingextraqty = item?.packingextraqty ? item.packingextraqty : 0; //~# box value
  var packingqty = purchaseQty ? purchaseQty : 0; //~# here qty of pieces changed to key-name packingqty, box will be freepackingqty
  var packingextraqty = box ? box : 0; //~# box value
  var totalQty =
    Number(packingextraqty) * Number(item?.conversionfactor) +
    Number(packingqty);
  var packingextraqtyaccepted = item?.packingextraqtyaccepted
    ? item.packingextraqtyaccepted
    : 0;
  var packingqtyaccepted = item?.packingqtyaccepted
    ? item.packingqtyaccepted
    : 0;

  var qtyaccepted =
    Number(packingextraqtyaccepted) * Number(item?.conversionfactor) +
    Number(packingqtyaccepted);

  let acceptedTotal =
    Number(packingextraqtyaccepted) * Number(item?.conversionfactor) +
    Number(packingqtyaccepted);

  var taxableamount =
    (Number(packingextraqty) * Number(item?.conversionfactor) +
      Number(packingqty)) *
    Number(purrate);
  var sgstamt = !igstappl ? taxableamount * (sgstrate / 100) : 0.0;
  var cgstamt = !igstappl ? taxableamount * (cgstrate / 100) : 0.0;
  var igstamt = igstappl ? taxableamount * (igstrate / 100) : 0.0;

  var amount = igstappl
    ? taxableamount + igstamt
    : taxableamount + (cgstamt + sgstamt);

  var itemObject = {
    ...item,
    shortguid: newShortguid,
    itemrecno: item.itemrecno,
    itemdescn: item.itemdescn,
    packingextraqty: Number(packingextraqty), //~# here box is the value coming from input Boxes
    packingqty: Number(packingqty), //~# here packingqty is the pieces value coming from Pieces input
    packingextraqtyaccepted: Number(packingextraqtyaccepted),
    packingqtyaccepted: Number(packingqtyaccepted),
    qty: totalQty,
    purrate: Number(purrate)?.toFixed(2),
    taxableamount: Number(taxableamount)?.toFixed(2),
    cgstrate: cgstrate,
    sgstrate: sgstrate,
    igstrate: igstrate,
    cgstamt: Number(cgstamt)?.toFixed(2),
    sgstamt: Number(sgstamt)?.toFixed(2),
    igstamt: Number(igstamt)?.toFixed(2),
    amount: Number(amount)?.toFixed(2),
    manufacturedate: manufacturedate, //~! This key-value is for adding empty string to new item in grn table
    expdate: expdate, //~! This key-value is for adding empty string to new item in grn table
    itembatchno: itembatchno,
    packingqty: purchaseQty,
    packingextraqty: box,
    qtyaccepted: acceptedTotal,
    freepackingqty: 0,
    freepackingextraqty: 0,
    qtyaccepted: qtyaccepted,
  };

  setRowData((p) => [...p, itemObject]);
  setSelectedItem(null);
};

export const handleChange = (
  item,
  setRowData,
  igstappl,
  rowData,
  scandata,
  setScandata,
  setRenderer
) => {
  var purrate = item?.purrate ? item.purrate : 0.0;
  var cgstrate = item?.cgstrate ? item.cgstrate : 0.0;
  var sgstrate = item?.sgstrate ? item.sgstrate : 0.0;
  var igstrate = item?.igstrate ? item.igstrate : 0.0;
  var packingextraqty = item?.packingextraqty ? item?.packingextraqty : 0;
  var packingqty = item?.packingqty ? item?.packingqty : 0;
  var totalQty =
    Number(packingextraqty) * Number(item?.conversionfactor) +
    Number(packingqty);

  var taxableamount =
    (Number(packingextraqty) * Number(item?.conversionfactor) +
      Number(packingqty)) *
    Number(purrate);
  var packingextraqtyaccepted = item?.packingextraqtyaccepted
    ? item.packingextraqtyaccepted
    : 0;
  var packingqtyaccepted = item?.packingqtyaccepted
    ? item.packingqtyaccepted
    : 0;

  var qtyaccepted =
    Number(packingextraqtyaccepted) * Number(item?.conversionfactor) +
    Number(packingqtyaccepted);
  var sgstamt = !igstappl ? taxableamount * (sgstrate / 100) : 0.0;
  var cgstamt = !igstappl ? taxableamount * (cgstrate / 100) : 0.0;
  var igstamt = igstappl ? taxableamount * (igstrate / 100) : 0.0;

  cgstamt = cgstamt.toFixed(2);
  sgstamt = sgstamt.toFixed(2);

  var amount = igstappl
    ? taxableamount + igstamt
    : taxableamount + (Number(cgstamt) + Number(sgstamt));

  var itemObject = {
    ...item,
    itemrecno: item.itemrecno,
    itemdescn: item.itemdescn,
    packingextraqty: Number(item?.packingextraqty),
    packingqty: Number(item?.packingqty),
    packingextraqtyaccepted: Number(item?.packingextraqtyaccepted),
    packingqtyaccepted: Number(item?.packingqtyaccepted),
    qty: Number(totalQty),
    purrate: Number(purrate)?.toFixed(2),
    taxableamount: Number(taxableamount)?.toFixed(2),
    cgstrate: cgstrate,
    sgstrate: sgstrate,
    igstrate: igstrate,
    cgstamt: Number(cgstamt)?.toFixed(2),
    sgstamt: Number(sgstamt)?.toFixed(2),
    igstamt: Number(igstamt)?.toFixed(2),
    amount: Number(amount)?.toFixed(2),
    expdate: item?.expdate,
    manufacturedate: item?.manufacturedate,
    qtyaccepted: qtyaccepted,
  };

  var currentItem = rowData.map((itm) => {
    if (itm.shortguid == item.shortguid) {
      return { ...itemObject };
    } else {
      return { ...itm };
    }
  });

  if (setRenderer) {
    setRenderer((p) => p + 1);
  }
  
 // console.log("tr003 ln 451 ??", currentItem);
 console.log("Rajendra check",item)
  setRowData(() => currentItem);

  setScandata(item);
};

export const handleRemove = (item, setRowData, rowData) => {
  var removedArray = removeByAttribute(rowData, "shortguid", item.shortguid);
  console.log("Remove", item, setRowData, rowData, removedArray);
  setRowData(() => [...removedArray]);
};

//~^ GST AMOUNT getting added but not showing percentage values in table : Solved - 21/01/2023
//~! USING shortguid as classname for maipulating the DOM

//scanner logic
export const handleScan = (text, setRowData, igstappl, rowData) => {
  console.log("scan", text, setRowData, rowData);
};

export const handleRemovePrev = (item, setRowData, rowData) => {
  var removedArray = removeByAttribute(rowData, "itemrecno", item.itemrecno);
  console.log("Remove", item, setRowData, rowData, removedArray);
  setRowData(() => [...removedArray]);
};

// CreditPoints.js
import React, { useContext, useEffect, useState } from "react";
import styles from "./CreditPoints.module.css";
import SaveTextInput from "../../../CommonControllers/Components/SaveComponents/SaveTextInput";
import { TabStore } from "../../../TabsStructure/TabsStore";

export const CreditPoints = ({ fastPath }) => {
  const tabStore = useContext(TabStore);
  const [pageIndex, setPageIndex] = useState();

  useEffect(() => {
    var tabsArray = tabStore.state.tabs;
    for (let i = 0; i < tabsArray?.length; i++) {
      if (tabsArray[i].name == fastPath) {
        setPageIndex(i);
        break;
      }
    }
  }, []);
  return (
    <>
      <div className={styles["creditscontainer"]}>
        <div style={{ display: "flex", flexDirection: "row", gap: "10px" }}>
          <div className={styles.credits}>
            <h2>Domian Points</h2>
            <p>Points: 100</p>
          </div>
          <div className={styles.credits}>
            <h2>Customer Points</h2>
            <p>Points: 100</p>
          </div>
          <div className={styles.credits}>
            <h2>Total Points</h2>
            <p>Points: 100</p>
          </div>
          <div className={styles.credits}>
            <h2>Balance Points</h2>
            <p>Points: 100</p>
          </div>
        </div>

        <div className={styles.inputsection}>
          {/* <label htmlFor="addPoints">Add Points:</label>
                    <input type="number" id="addPoints" placeholder="Enter points" value={""} />
                    <button>Add</button> */}
          <div>
            {" "}
            <SaveTextInput
            pageIndex={pageIndex}
              displayLabel="Create Points."
              placeholder="Create Points."
              alignment="column"
              // displayLabel={elem?.columndisplay}
              //placeholder={elem?.placeholder}
              apikey={"billno"}
              // defaultValue={elem?.defaultValue}
              change
              // returnValue={(e) => {
              //   console.log("FC", e);
              //   setterFunction(e, elem);
              //   storeData(e, elem?.apiKey);
              // }}
              //key={modalcount}
              isrequired={1}
            />
          </div>
          <div>
            <button className={styles.btn}>Add</button>
          </div>
        </div>
        <div className={styles["tablesection"]}>
          <table>
            <thead>
              <tr>
                <th>Date</th>
                <th>Points</th>
                <th>Created By</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>2024-02-20</td>
                <td>10</td>
                <td>10</td>
                <td>10</td>
              </tr>
              {/* Add more rows as needed */}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

import React, { useContext, useState, useEffect } from 'react'
import SaveNumberInput from '../../CommonControllers/Components/SaveComponents/SaveNumberInput'
import SaveTextInput from '../../CommonControllers/Components/SaveComponents/SaveTextInput'
import SaveDropDown from '../../CommonControllers/Components/SaveComponents/SaveDropdown'
import SaveCheckBox from '../../CommonControllers/Components/SaveComponents/SaveCheckbox'
import { getForAnswer, getQuestionBySurveyType, getSurveyQuestionType, getSurveyType } from '../../CommonControllers/Urls'
import { AuthStore } from '../../Auth/AuthStore'
import { TabStore } from '../../TabsStructure/TabsStore'
import CallbackDropdown from '../../CommonControllers/Components/CallbackComponents/CallbackDropdown'
import CallbackTextInput from '../../CommonControllers/Components/CallbackComponents/CallbackTextInput'
import CallbackCheckbox from '../../CommonControllers/Components/CallbackComponents/CallbackCheckbox'
import { Button, Input, Tooltip } from 'antd'
import { DeleteFilled } from '@ant-design/icons'
import SubQuestion from './SubQuestion'
import Select from 'react-select';

function AddSurveyQuestionModal({
    //dataobject
    setsurveytype,
    setquestiontyperecno,
    setissubquestionof,
    setsrNo,
    setquestionType,
    setquestionname,
    setquestionOpList,
    setforanswer,
    setisgroupof,
    setmandatory,
    setimages,
    setlocation,
    setautofilled,
    setisCommon,
    sethasgroup,
    setOptions,
    setHasSubOption,
    setSubOptions,
    surveytype,
    questiontyperecno,
    issubquestionof,
    srNo,
    questionType,
    questionname,
    questionOpList,
    foranswer,
    isgroupof,
    mandatory,
    images,
    location,
    autofilled,
    isCommon,
    hasgroup,
    options,
    hasSubOption,
    subOptions,
    loyaltyPoints,
    setloyaltyPoints,
    changes,
    subQuestionType,
    setsubQuestionType,
    fieldtype,
    setfieldtype,
    placeholder,
    setplaceholder
}) {

    const [questionsrno, setquestionsrno] = useState(20)
    const [subQuestionNumber, setSubQuestionNumber] = useState(20);

    const auth = useContext(AuthStore);
    const tabs = useContext(TabStore)

    console.log("tabsss ===>>>>", tabs)
    console.log("fieldtype", fieldtype)
    console.log("survey type on add survey modal", surveytype)

    const handleAddOption = () => {
        setOptions([...options, { optionlist: "", subquestions: [] }]);
        // setSubQuestionNumber((p) => p + 10);
    };

    const handleRemoveOption = (index) => {
        setOptions(options.filter((_, i) => i !== index));
    };

    console.log("Survey Type is", surveytype)

    const [showTable, setShowTable] = useState(false);
    useEffect(() => {
        setShowTable(false);

        setTimeout(() => {
            setShowTable(true);
        }, 200);
    }, [changes]);

    return (
        <>
            {showTable ?
                <div>
                    <div style={{ display: 'flex', flexDirection: 'row', marginTop: "2%" }}>
                        {/* <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', width: "50%" }}>
                            <CallbackDropdown
                                placeholder="Select Survey Type"
                                displayLabel={"Survey Type"}
                                apiurl={getSurveyType}
                                apikey={"surveytype"}
                                selectionLabel={"descn"}
                                selectionValue={"recno"}
                                defaultSelected={surveytype?.recno}
                                minInput={0}
                                inputValue={20}
                                returnValue={setsurveytype} // (e) => e
                                width={"100%"}
                            />
                        </div> */}
                        {/* <div style={{ display: 'flex', flexDirection: 'row', marginLeft: "12%", marginTop: "2%" }}> */}
                        {/* <div style={{ display: "flex",marginLeft: "5%" }}>
                    <CallbackTextInput
                        placeholder="Serial No"
                        displayLabel={"Serial No"}
                        apikey={"questionsrno"}
                        defaultValue={questionsrno}
                        width={"70%"}
                    />
                </div> */}
                        <div style={{ display: "flex", width: "50%" }}>
                            <CallbackDropdown
                                placeholder="Select Question Type"
                                displayLabel="Question Type"
                                apiurl={getSurveyQuestionType}
                                selectionLabel={"descn"}
                                selectionValue={"recno"}
                                minInput={0}
                                defaultSelected={""}
                                returnValue={setquestionType}
                                body={{ "onlyactive": 1 }}
                                inputValue={20}
                                width={"100%"}
                            />
                        </div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', marginTop: "2%" }}>
                        <div>
                            <CallbackTextInput
                                placeholder="Question"
                                displayLabel={"Question"}
                                style={{ width: "880px" }}
                                returnValue={setquestionname}
                                defaultValue={questionname}
                            />
                        </div>
                        {/* <div style={{ marginTop: "2%" }}>
                    <CallbackTextInput
                        placeholder="Optionlist e.g.opt1,opt2,opt3"
                        displayLabel={"Question Optionlist (ComaSeperated)"}
                        apikey={"optionlist"}
                        style={{ width: "700px" }}
                        returnValue={setquestionOpList}
                    />
                </div> */}
                        <div style={{ display: 'flex', justifyContent: "space-between",marginTop: "2%"}}>
                            <div style={{  display: 'flex' }}>
                                <p style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>Loyalty Points</p>
                                <div style={{ paddingLeft: "1%" }}>
                                    <Input
                                        type='number'
                                        placeholder='Enter Loyalty Points'
                                        min={0}
                                        value={loyaltyPoints}
                                        style={{ width: "80%", }}
                                        onChange={(e) => setloyaltyPoints(e.target.value)
                                        }
                                    />
                                </div>
                            </div>
                            <div style={{  display: 'flex' }}>
                                <CallbackTextInput
                                    placeholder="Enter Placeholder"
                                    displayLabel={"Placeholder"}
                                    style={{ width: "500px" }}
                                    returnValue={setplaceholder}
                                    defaultValue={placeholder}
                                />
                            </div>
                            {/* <div style={{ marginTop: "2%", display: 'flex' }}>
                                <select
                                    style={{
                                        appearance: "none",
                                        WebkitAppearance: "none",
                                        MozAppearance: "none",
                                        border: "1px solid #ccc",
                                        borderRadius: "8px",
                                        padding: "10px",
                                        fontSize: "16px",

                                        color: "#333",
                                        backgroundColor: "#fff",
                                        backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='6' viewBox='0 0 10 6'%3E%3Cpath fill='%23000' d='M1.2 0h7.5L5 5.5z'/%3E%3C/svg%3E")`,
                                        backgroundPosition: "right 8px center",
                                        backgroundRepeat: "no-repeat",
                                        height: "40px",
                                        lineHeight: "20px",
                                    }}
                                    // value={subOption.dropdownOption}
                                    placeholder="Select Type"
                                    onChange={(event) => {

                                    }}
                                >
                                    <option value="" disabled selected hidden style={{ color: "#ccc", height: "25px" }}>
                                        Select Type
                                    </option>
                                    
                                    <option value="alpha">Alpha</option>
                                    <option value="alphanumeric">Alphanumeric</option>
                                    <option value="number">Number</option>
                                    <option value="date">Date</option>
                                </select>
                            </div> */}
                            <div style={{  display: 'flex' }}>
                                {questionType?.code == "3" ? null : (
                                    <div style={{ display: 'flex' }}>
                                        <p style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>Field Type</p>
                                        <div style={{ paddingLeft: "1%", width: "100%" }}>
                                            <Select
                                                options={[
                                                    { value: 'alpha', label: 'Alpha' },
                                                    { value: 'alphanumeric', label: 'Alphanumeric' },
                                                    { value: 'number', label: 'Number' },
                                                    { value: 'date', label: 'Date' },
                                                    { value: 'boolean', label: 'Boolean' }
                                                ]}
                                                placeholder="Select Field Type"
                                                styles={{
                                                    border: "1px solid #cccccc",
                                                    borderRadius: "8px",
                                                }}
                                                onChange={(e) => setfieldtype(e.value)}
                                            />
                                        </div>
                                    </div>
                                )}

                            </div>
                        </div>
                    </div>
                    {/* <div style={{ display: 'flex', flexDirection: 'row', marginTop: "2%", width: "100%" }}>
                <div style={{ width: "33%" }}>
                    <CallbackDropdown
                        placeholder="Select Issubquestionof"
                        displayLabel={"Issubquestionof"}
                        apikey={"issubquestionof"}
                        apiurl={getQuestionBySurveyType}
                        body={{ "surveytype": surveytype?.recno, "hassubquestion": 1 }}
                        selectionLabel={"questionname"}
                        selectionValue={"recno"}
                        returnValue={setissubquestionof}
                        width={"100%"}
                    />
                </div>
                <div style={{ marginLeft: "5%", width: "33%" }}>
                    <CallbackDropdown
                        placeholder="Select For Answer"
                        displayLabel={"For Answer"}
                        apikey={"foranswer"}
                        apiurl={getForAnswer}
                        body={{ "surveytyperecno": surveytype?.recno, "questionrecno": issubquestionof?.recno }}
                        width={"100%"}
                        selectionValue={"optionlist"}
                        selectionLabel={"optionlist"}
                        returnValue={setforanswer}
                    />
                </div>
                <div style={{ marginLeft: "5%", width: "33%" }}>
                    <CallbackDropdown
                        placeholder="Select Isgroupof"
                        displayLabel={"Isgroupof"}
                        apikey={"isgroupof"}
                        apiurl={getQuestionBySurveyType}
                        body={{ "surveytype": surveytype?.recno, "hasgroup": 1 }}
                        selectionLabel={"questionname"}
                        width={"100%"}
                        selectionValue={"recno"}

                        returnValue={setisgroupof}
                    />
                </div>
            </div> */}
                    <div style={{ display: 'flex', marginTop: "2%" }}>
                        {/* <div style={{ display: 'flex', flexDirection: 'row' }}> */}
                        <div style={{ marginLeft: "10%", marginTop: "2%" }} >
                            <CallbackCheckbox
                                displayLabel={"Mandatory"}
                                editable
                                returnValue={setmandatory}
                            />
                        </div>

                        <div style={{ marginLeft: "10%", marginTop: "2%" }} >
                            <CallbackCheckbox
                                displayLabel={"Images"}
                                editable
                                returnValue={setimages}
                            />
                        </div>

                        {/* <div style={{ marginLeft: "15%", marginTop: "2%" }} >
                        <CallbackCheckbox
                            displayLabel={"isCommon"}
                            apikey={"iscommon"}
                            editable
                            returnValue={setisCommon}
                        />
                    </div> */}
                        {/* </div> */}
                        {/* <div style={{ display: 'flex', flexDirection: 'row' }}> */}
                        {/* <div style={{ marginLeft: "15%", marginTop: "2%" }} >
                        <CallbackCheckbox
                            displayLabel={"Hasgroup"}
                            apikey={"hasgroup"}
                            editable
                            returnValue={sethasgroup}
                        />
                    </div> */}

                        <div style={{ marginLeft: "10%", marginTop: "2%" }} >
                            <CallbackCheckbox
                                displayLabel={"Locn"}
                                apikey={"location"}
                                editable
                                returnValue={setlocation}
                            />
                        </div>

                        <div style={{ marginLeft: "10%", marginTop: "2%" }} >
                            <CallbackCheckbox
                                displayLabel={"Auto-filled"}
                                apikey={"autofilled"}
                                editable
                                returnValue={setautofilled}
                            />
                        </div>
                        {/* </div> */}
                    </div>
                    {/* .recno == 11236 means MCQ*/}
                    {questionType?.code == "3" ? (
                        <div className="options-container" style={{ marginTop: "1%" }}>
                            <Button id="add-option-button" onClick={handleAddOption}>
                                Add Option
                            </Button>
                            {options.map((option, i) =>
                                <SubQuestion
                                    key={i}
                                    options={options}
                                    setOptions={setOptions}
                                    index={i}
                                    handleRemoveOption={handleRemoveOption}
                                    hasSubOption={hasSubOption}
                                    setHasSubOption={setHasSubOption}
                                    subOptions={subOptions}
                                    setSubOptions={setSubOptions}
                                    questionsrno={questionsrno}
                                    subQuestionNumber={subQuestionNumber}
                                    setSubQuestionNumber={setSubQuestionNumber}
                                    subQuestionType={subQuestionType}
                                    setsubQuestionType={setsubQuestionType}
                                />
                            )}
                        </div>
                    ) : null}
                </div> : null}
        </>
    )
}

export default AddSurveyQuestionModal



import React from "react";
import Images from "./Images/Images.css";
import LinkMaster1 from "./Images/LinkMaster1.png";
import LinkMasters2 from "./Images/LinkMasters2.png";

const LinkMasters = () => {
  return (
    <div>
       <div style={{position:'fixed',backgroundColor:'white',width:'80%'}}><h1>Link Masters</h1></div>
       <br/> 
       <br/> 
      <p>
        This is basically linking the masters. Allocation of certain masters for
        specific purpose. Here the master records are already present in
        respective tables and we create a new linking information and record it.
        So within the application these references can be used.
      </p>
      <p>
        Example: linking the supplier : There will be setting of suppliers from
        whom the purchasing will be done. Here we link the suppliers to the
        domain one by one and there after while purchase order is to be
        generated then we will select only linked suppliers , this means other
        suppliers will not be entertained.
      </p>
      <p>There can be various such linking activities to be done.</p>
      <p>
        For linking suppliers to the distributors first we have to select the
        distributor using the drop down and then we can get list of all the
        suppliers we have in the system, in case of larger number we an use
        filter such as supplier type so only specific type of suppliers will be
        available to link.
      </p>
      <p>
        We get list of all the supplier , and firstly we see already linked
        suppliers and further the rest of suppliers . Here we can use edit
        button of menu and start editing the row we need to change, we can
        either link a new supplier or remove the linking of any existing. there
        can be various other parameters which entered.
      </p>
      <h3>BU Customers (MS101)</h3>
      {/* <p>Customer master is a link master to connect customer to the distributor (me) all the customers whom we are dealing needs to be connected to (me) for making sales transactions. For this we can select the customers type wise or all , then edit the desired customer and link it by setting link flag . Click save button at the row and save the row and then finally save the entire page.</p>
        <p>There are some additional fields required in this link table, credit days, credit limit, rate card here (me) will decide what credit to be given to the customer and also want rate card to be attached to the customer. All these will be used in sales activity.</p> */}
      <p>
        <p>
          We have profiles of all the entities in the Party (MS100) screen. Now,
          when we refer to a Business Unit (BU), the BU sells goods or provides
          services to specific parties. The BU needs to link these parties as
          customers using this screen. Thus, the BU can only conduct sales
          transactions with the parties linked through this screen.
          Additionally, the BU can establish specific parameters to carry out
          sales activities with each of its customers.
        </p>
        <p>
          When we link a party as a customer, an account is created for that
          party. This account is added as a sundry debtor. If we unlink the
          party as a customer, the account is not immediately deleted. However,
          it eventually becomes deactivated in the accounts master. In the
          future, if we link the party as a customer again, the previously
          created sundry debtor account becomes active once more.
        </p>
      </p>
      {/* <img src={LinkMaster1} className="images" />
      <img src={LinkMasters2} className="images" /> */}
      <h3>BU Suppliers (MS102)</h3>
      <p>
        <p>As explained earlier, we have profiles of all the entities in the Party (MS100) screen. When it
comes to suppliers, they provide goods or services to our organisation. In order to facilitate
transactions and maintain relationships, the parties need to be linked as Suppliers using this
screen. By linking suppliers through this screen, our organisation can engage in
procurement activities. Moreover, we can define specific parameters with each individual
supplier to ensure smooth procurement operations.</p>
        <p>By linking a party as a supplier, an account is generated for that party. The account is
created as a sundry creditor. If we decide to remove the link to the party as a supplier, the
account does not get deleted but instead becomes deactivated from the accounts master.
However, if we reestablish the party as a supplier in the future, the previously created sundry
creditor account becomes active once again.</p>
      </p>
      <h3>BU Salesmen (MS103)</h3>
      <p>Based on the sales volume, working area, and other business requirements, a Business Unit
(BU) selects and appoints individuals as Salesmen. In order to create profiles for these
individuals, we utilise the party screen (MS100). The parties are then linked as salesmen
through the MS103 screen. Additionally, depending on the specific business rules, certain
parameters need to be configured for the salesmen. These parameters can be set and
adjusted using the designated screen. Like customers or suppliers, account master records
are not created for the salesmen.</p>
<h3>BU Transporters (MS104)</h3>
<p>Certain Business Units (BUs) choose to engage specific transporters for the delivery or
procurement of goods. To establish profiles for these transporters, we utilise the party screen
(MS100). The parties are then linked as Transporters through the dedicated MS104 screen.
Furthermore, depending on the unique business rules and requirements, it becomes
necessary to configure specific parameters for the transporters. These parameters can be
easily set and adjusted using the designated screen. It is important to note that, similar to
customers or suppliers, account master records are not created for the transporters.</p>
<h3>BU Customer Items (MS105)</h3>
<p>The business unit (BU) has the flexibility to determine whether they want to offer all items to
all customers or selectively choose which items to sell to specific customers based on their
established business rules. In some cases the company may also decide which BU should
sell which items to which Customers. The process of linking items to customers is facilitated
through a dedicated screen. This screen allows the BU to establish associations between
items and customers according to their preferences. Additionally, the screen provides
configuration options for certain parameters, enabling the BU to customise and tailor the
selling process to align with their specific requirements.</p>
<h3>BU Supplier Items (MS106)</h3>
<p>
  <p>Typically, the company establishes rules for business units regarding the selection of
suppliers for specific items. These rules may involve specifying from which suppliers they
should procure certain or all items, or allowing them to procure items from all available
suppliers. However, business units may also have the autonomy to determine their own
criteria for procurement from the available suppliers.</p>
<p>To facilitate the process of linking items to suppliers, this dedicated screen is used. This
screen enables business units to establish associations between items and suppliers based
on their procurement requirements. Moreover, the screen offers configuration options for
certain parameters, allowing business units to customise and adjust the procurement
process to suit their specific needs.</p>
</p>
<h3>BU Customer Salesman Items (MS107)</h3>
<p>
  <p>The business unit (BU), which has assigned salesmen for sales activities, has the ability to
impose restrictions on the sale of certain items to specific customers through particular
salesmen. These constraints can be easily configured using the dedicated screen. The
process of linking items to customers through salesmen is facilitated through this screen.</p>
<p>By utilising this screen, the BU can establish associations between items, salesmen, and
customers based on their specific preferences and requirements. This allows for precise
control over which items can be sold by which salesmen to specific customers. The screen
also provides configuration options for certain parameters, empowering the BU to customise
and fine-tune the selling process to align with their specific needs and constraints.</p> </p>
<h3>BU Items (MS108)</h3>
<p>All the items and services offered by the company can be made accessible to all business
units. However, the decision to do so depends on the company's discretion and is based on
various business rules. To facilitate this process, the company utilises a dedicated screen
called BU Items (MS108). Through this screen, the company links specific items and
services to individual business units. Moreover, during the linking process, certain business
unit-specific parameters can also be configured to ensure customization and alignment with
each unit's requirements.</p>
<h3>BU Customer - Referred By (MS109)</h3>
<p>In the case of companies like Johnson & Johnson Vision, the business unit (BU) receives
orders to sell their products to hospitals through surgeons. These surgeons are affiliated with
specific hospitals. Therefore, when preparing sales invoices in such cases, it becomes
necessary to record the referral information. To obtain a list of surgeons associated with a
particular hospital, the surgeons are linked to their corresponding hospitals using this screen.</p>
<h3>BU Salesman Customer (MS110)</h3>
<p>In the scenario where a business unit (BU) employs salesmen to facilitate sales activities,
each salesman is assigned to specific customers under their care. This linkage ensures that
each salesman is responsible for promoting and serving the assigned customers, fostering a
personalised and focused approach to sales.</p>
<h3>BU Item Billing Categories (MS116)</h3>
<p>Business units have the ability to categorise items under different billing categories, which
are created using screen number MS065. In screen MS116, different billing categories can
be attached to specific items. These billing categories are specific to each business unit,
allowing them to define and categorise items according to their own requirements.</p>
<h3>BU Supplier Banks (MS116)</h3>
<p>To simplify the payment process, the business unit has the option to save bank details using
this screen. By saving the bank details, the BU can conveniently access and utilise the
information when making payments, thus streamlining the payment procedure</p>
<h3>BU Item Account Categories (MS119)</h3>
<p>
  <p>Normally, the sale of all items is accounted for under a single sale account, and the
purchase of all items is accounted for under a single purchase account. However, some
business units choose to maintain separate purchase and sales accounts based on the GST
rate, such as:</p>
<p> ● Purchase of nontaxable goods & Sales of nontaxable goods</p>
<p> ● Purchase 0f 5.00 % GST Goods & Sales 0f 5.00 % GST Goods</p>
<p> ● Purchase 0f 18.00 % GST Goods & Sales 0f 18.00 % GST Goods</p>
<p>Some BUs use only 3 accounts as GST input tax accounts & 3 accounts as GST Output tax
accounts like</p>
<p> ● Input CGST Account & Output CGST Account</p>
<p> ● Input SGST Account & Output SGST Account</p>
<p> ● Input IGST Account & Output IGST Account.</p>
<p>Some other BUs maintain separate accounts for different GST Rates. Like</p>
<p>1. Input CGST Account (5%) & Output CGST Account (5%)</p>
<p>2. Input SGST Account (5%) & Output SGST Account (5%)</p>
<p>3. Input IGST Account (5%) & Output IGST Account (5%)</p>
<p>4. Input CGST Account (18%) & Output CGST Account (18%)</p>
<p>5. Input SGST Account (18%) & Output SGST Account (18%)</p>
<p>6. Input IGST Account (18%). & Output IGST Account (18%)</p>
</p>

<p>To streamline the process of entering item details and prevent duplication of work, account
categories are created using a separate master screen, MS066. In each account category,
the relevant accounts for sale, purchase, input GST, and output GST are grouped together.
Based on specific requirements, different account categories or bunches are created. These
account categories can be easily assigned to individual items or a list of items with a simple
click of a mouse using this screen.</p>
<h3>BU Customer Consignees (MS134)</h3>
<p>A consignee is an individual, company, or entity to whom a shipment or delivery is intended.
They serve as the designated recipient of goods or products being transported or sent by a
consignor. In most trading businesses, consignees are the customers themselves. However,
in certain cases, such as in warehouse operations, the sales invoice is prepared in the
customer's name while the goods are to be delivered to a different business entity, known as
the consignee. In such situations, a separate consignee profile needs to be created. These
consignee profiles are entered using the Party screen (MS100) and subsequently linked to
the respective business unit using this screen.</p>

    </div>
  );
};

export default LinkMasters;

import React, { useContext, useState, useEffect } from "react";
import { AuthStore } from "../../Auth/AuthStore";
import { getfastpathapi } from "../../CommonControllers/ApiCalls";
import FilterComponents from "../../CommonControllers/FiterComponent/FilterComponents";
import { TabStore } from "../../TabsStructure/TabsStore";
import { Spin, Collapse, Space } from "antd";
import noData from "../../Assets/noData.png";
import useExecute from "../../CommonControllers/Hooks/useExecute";
import NewSalesInvoiceTable from "./NewSalesInvoiceTable";

const { Panel } = Collapse;

const SalesInvoiceRegister = ({ fastPath }) => {
  const tabStore = useContext(TabStore);
  const auth = useContext(AuthStore);
  const [filterOptions, setFilterOptions] = useState([]);
  const [ReportName, setReportName] = useState([]);
  const [filterprinting, setFilterprinting] = useState([]);
  const [filterdropdown, setFilterdropdown] = useState("");
  const execute = useExecute();

  const [showTable, setShowTable] = useState(true);
  const [filterDictionary, setFilterDictionary] = useState([]);

  const [pageIndex, setPageIndex] = useState();
  const [collapseOpen, setCollapseOpen] = useState(true);

  useEffect(() => {
    var tabsArray = tabStore.state.tabs;
    for (let i = 0; i < tabsArray?.length; i++) {
      if (tabsArray[i].name === fastPath) {
        setPageIndex(i);
        break;
      }
    }
  }, [fastPath, tabStore.state.tabs]);

  useEffect(() => {
    setShowTable(false);
    setTimeout(() => {
      setShowTable(true);
    }, 200);
  }, [tabStore.state.tabs[pageIndex]?.apiResponse]);

  useEffect(() => {
    const payload = {
      tenantrecno: auth?.state?.userdata?.tenantrecno,
      domainrecno: auth?.state?.userdata?.domainrecno,
      userrolerecno: auth?.state?.userdata?.userrolerecno,
      fastpath: fastPath,
    };

    execute.updateValue("forreport", "Y");

    // On component mount this api is called and filterOptions are set for that fast path
    getfastpathapi(payload)
      .then((res) => {
        if (res?.Success) {
          setFilterOptions(res?.Message?.filterOptions);
          setReportName(res?.Message?.descn);
        } else {
          // Handle unsuccessful response (optional)
          console.error("API call unsuccessful:", res);
        }
      })
      .catch((error) => {
        // Handle errors
        console.error("Error fetching data:", error);
        // Optionally, set an error state or display an error message to the user
      });
  }, [fastPath]);

  useEffect(() => {
    if (tabStore.state.tabs[pageIndex]?.executeLoading) {
      setCollapseOpen(false);
    }
  }, [
    tabStore.state.tabs[pageIndex]?.executeLoading,
    pageIndex,
    tabStore.state.tabs,
  ]);

  return (
    <div>
      <Space direction="vertical" style={{ width: "100%" }}>
        <Collapse
          activeKey={collapseOpen ? ["1"] : []}
          onChange={() => setCollapseOpen(!collapseOpen)}
        >
          <Panel header="Filter Options" key="1">
            <FilterComponents
              filterOption={filterOptions}
              filterOptionsProps={setFilterprinting}
              setfilterdropdown={setFilterdropdown}
              filterDictionary={filterDictionary}
              setFilterDictionary={setFilterDictionary}
              fastPath={fastPath}
            />
          </Panel>
        </Collapse>
        {tabStore.state.tabs[pageIndex]?.executeLoading ? (
          <div style={loaderStyle}>
            <Spin size="large" />
          </div>
        ) : (
          <div className="table-container">
            {tabStore.state.tabs[pageIndex]?.apiResponse?.Message?.length >
            0 ? (
              <div>
                <NewSalesInvoiceTable
                  filterprinting={filterprinting}
                  filterdropdownprint={filterdropdown}
                  filterDictionary={filterDictionary}
                  setFilterDictionary={setFilterDictionary}
                  fastPath={fastPath}
                />
              </div>
            ) : tabStore.state.tabs[pageIndex]?.apiResponse?.Message?.length ===
              0 ? (
              <div className="noDataFound">
                <h1>Whoops! No Records Found In Given Filter</h1>
                <img src={noData} alt="No data" />
              </div>
            ) : null}
          </div>
        )}
      </Space>
    </div>
  );
};

export default SalesInvoiceRegister;

const loaderStyle = {
  height: "max-content",
  width: "max-content",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  margin: "50px auto",
};

import { Button, Modal, Popconfirm } from "antd";
import { AiFillDelete } from "react-icons/ai";
import "../../../CommonControllers/global.css";
import { removeByAttribute } from "../../../CommonControllers/AppFunction";

export const grnColumns = (
  item,
  setRowData,
  rowData,
  pageDetails
) => {

  return [
    // Delete
    {
      title: " ",
      dataIndex: "active",
      key: "active",
      width: "80px",
      align: "center",
      render: (_, text) => {
        return (
          <Popconfirm
            title="Do you want to Delete the changes?"
            onConfirm={(e) => {
              handleRemove(text, setRowData, rowData);
            }}
          >
            <Button icon={<AiFillDelete />}></Button>
          </Popconfirm>
        );
      },
    },

    // Item Name
    {
      title: "Item Description",
      dataIndex: "itemdescn",
      key: "itemdescn",
      width: "300px",
      align: "left",
    },

    // Quantity
    {
      title: "Quantity",
      dataIndex: "qty",
      key: "qty",
      width: "100px",
      align: "center",
      render: (_, text) => {
        return (
          <input
            className="tr001Input"
            type="number"
            defaultValue={text.qty}
            onFocus={(e) => e.target.select()}
            // onChange={(e) => {
            //   const regex = /^[0-9\b]+$/;
            //   if (e.target.value === "" || regex.test(e.target.value)) {
            //     text.qty = e.target.value;
            //     handleChange(text, item, setRowData, rowData);
            //   } else {
            //     alert("Invalid Input");
            //     e.target.value = "";
            //     text.qty = e.target.value;
            //     handleChange(text, item, setRowData, rowData);
            //   }
            // }}
            onChange={(e) => {
              if (e.target.value < 0) {
                e.target.value = null;
                alert("Please Enter Positive Value");
                return false;
              }
              const regex = /^[0-9\b]+$/;

              if (regex.test(e.target.value) > 0) {
                text.qty = e.target.value;
                handleChange(text, setRowData, item, rowData);
              } else {
                e.target.value = text.qty;
                alert("Quantity is required.");
              }
            }}
          />
        );
      },
    },

    // Batch No.
    {
      hidden: pageDetails?.wantbatchno ? true : true,
      width: "100px",
      title: "Batch No.",
      dataIndex: "itembatchno",
      key: "itembatchno",
      align: "center",
      render: (_, text) => {
        return (
          <input
            className="tr001Input"
            type="number"
            defaultValue={text.itembatchno}
            onFocus={(e) => e.target.select()}
            onChange={(e) => {
              text.itembatchno = e.target.value;
              handleChange(text, setRowData, item, rowData);
            }}
          />
        );
      },
    },

    // Amount
    {
      title: "Amount",
      //   dataIndex: "",
      //   key: "",
      width: "200px",
      align: "center",
      render: (_, text) => {
        return (
          <input
            className="tr001Input"
            type="number"
            // defaultValue={text.grossweight}
            onFocus={(e) => e.target.select()}
            // onChange={(e) => {
            //   const regex = /^\d+(\.\d+)?$/;
            //   if (e.target.value === "" || regex.test(e.target.value)) {
            //     text.grossweight = e.target.value;
            //     handleChange(text, setRowData, item, rowData);
            //   } else {
            //     alert("Invalid Input");
            //     e.target.value = "";
            //     text.grossweight = e.target.value;
            //     handleChange(text, setRowData, item, rowData);
            //   }
            // }}
          />
        );
      },
    },
  ];
};

export const handleAdd = (
  item,
  setRowData,
  setSelectedItem,
  shortguid,
  quantity,
  batchNo,
  itemAmount,
  itemAttribute
) => {
  // Shortguid for item
  var newShortguid = shortguid.getShortguid("WMSRDC" + item.itemrecno);

  // Quantity
  var qty = quantity ? quantity : 0;

  // Amount
  var amount = itemAmount ? itemAmount : 0;

  var itemObject = {
    ...item,
    shortguid: newShortguid,
    itemrecno: item.itemrecno,
    itemdescn: item.itemdescn,
    qty: qty,
    itembatchno: batchNo,
    amount: amount,
  };
  setRowData((p) => [...p, itemObject]);
  setSelectedItem(null);
};

export const handleChange = (text, setRowData, item, rowData) => {
  // Quantity
  let qty = text?.qty ? text.qty : 0;

  // Remarks
  let amount = text?.amount ? text.amount : 0;

  var itemObject = {
    ...text,
    itemrecno: text.itemrecno,
    itemdescn: text.itemdescn,
    qty: qty,
    amount: amount,
  };

  var currentItem = rowData.map((itm) => {
    if (itm.itemrecno === text.itemrecno) {
      return { ...itemObject };
    } else {
      return { ...itm };
    }
  });

  console.log("Current Item", currentItem);
  setRowData(() => currentItem);
};

export const handleRemove = (item, setRowData, rowData) => {
  var removedArray = removeByAttribute(rowData, "itemrecno", item.itemrecno);
  setRowData(() => [...removedArray]);
};

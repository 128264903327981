import { Input } from "antd";
import React, { useEffect, useState } from "react";
import useExecute from "../../Hooks/useExecute";
import { removeFormatCurrency } from "../../AppFunction";

function CallbackTextInput({
  displayLabel,
  placeholder,
  change,
  className,
  style,
  type,
  required,
  defaultValue,
  alignment,
  apikey,
  id,
  returnValue,
  width,
  minlength,
  maxlength,
  minvalue,
  maxvalue,
  regex,
  title,
  isrequired,
  disabled
}) {
  // console.log("regex",type,regex)
  // added minlength, maxlength, minvalue, maxvalue as parameters
  // Steps : onChange cahnge the value of the state variable in the component
  // OnBlur : in case the component needs to be onBlue save the changed value to the tabStore on blue event, else on change event
  // For Execute we save the values to the apiVariables store in tabStore

  const execute = useExecute();
  const [textValue, setTextValue] = useState();
  const [panNumber, setPanNumber] = useState("");
  const [isValid, setIsValid] = useState(true);
  const [rlen, setLength] = useState(null);
  const [err, setError] = useState("");
  let inputWidth = width ? width : "150px";
  console.log("title======", title, type);

  const errorMessage = {
    MoreInputs: `Need more inputs`,
    PAN: "PAN Card is not valid",
    "GST No.": "GST Number is not Valid",
    Mobile: "Mobile Number is not Valid",
    "Phone No.": "Phone Number is not Valid",
    "PIN Code": "PIN Code is not Valid",
    "Contact Email": "Contact Email is not Valid",
    Email: "Email is not Valid",
  };

  const allregex = {
    PAN: /^([A-Z]){5}([0-9]){4}([A-Z]){1}?$/,
    "GST No.": /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[0-9]{1}[A-Z]{2}$/,
    Mobile: /^([0-9]){10}?$/,
    "Phone No.": /^([0-9]){10}?$/,
    "PIN Code": /^([0-9]){6}?$/,
    "Contact Email": /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
    Email: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
  };

  useEffect(() => {
    if (defaultValue) {
      setTextValue(defaultValue);
    }
  }, [defaultValue]);

  const validateInputs = (inputValue, title, x) => {
    if (inputValue.length == 15 && title == "GST No.") {
      // return xx?.test(inputValue);
      let req = inputValue?.length;
      setLength(req);
      return allregex[title].test(inputValue);
    }
    if (
      title == "GST No." &&
      x &&
      inputValue.length != 15 &&
      inputValue != ""
    ) {
      let req = inputValue?.length;
      setLength(req);
      console.log("valid", req);
      setError(errorMessage["MoreInputs"]);
      return allregex[title].test(inputValue);
    }
    if (title == "PAN" && x && inputValue.length !== 10 && inputValue != "") {
      let req = inputValue?.length;
      setLength(req);
      console.log("validvalid", inputValue);
      setError(errorMessage["MoreInputs"]);
      return allregex[title].test(inputValue);
    } else if (inputValue.length == 10 && title == "PAN") {
      let req = inputValue?.length;
      setLength(req);
      // return xx?.test(inputValue);
      return allregex[title].test(inputValue);
    }
    /// Mobile and Phone No.////
    console.log("validerdtygu", title);
    if (
      (title == "Mobile" || title == "Phone No.") &&
      inputValue.length !== 10 &&
      inputValue != ""
    ) {
      let req = inputValue?.length;
      setLength(req);

      setError(errorMessage["MoreInputs"]);
      return allregex[title].test(inputValue);
    } else if (
      inputValue.length == 10 &&
      (title == "Mobile" || title == "Phone No.")
    ) {
      let req = inputValue?.length;
      setLength(req);
      // return xx?.test(inputValue);
      return allregex[title].test(inputValue);
    }

    // PIN Code
    if (title == "PIN Code" && inputValue?.length !== 6 && inputValue != "") {
      let req = inputValue?.length;
      setLength(req);
      console.log("valid", req);
      setError(errorMessage["MoreInputs"]);
      return allregex[title].test(inputValue);
    } else if (inputValue?.length == 6 && title == "PIN Code") {
      let req = inputValue?.length;
      setLength(req);
      return allregex[title].test(inputValue);
    }
    // Contact Email and Email
    if (
      (title == "Contact Email" || title == "Email") &&
      inputValue?.length !== 100 &&
      inputValue != ""
    ) {
      let req = inputValue?.length;
      setLength(req);
      console.log("valid", req);
      setError(errorMessage["MoreInputs"]);
      return allregex[title].test(inputValue);
    } else if (
      inputValue?.length == 100 &&
      (title == "Contact Email" || title == "Email")
    ) {
      let req = inputValue?.length;
      setLength(req);
      return allregex[title].test(inputValue);
    } else {
      return true;
    }
  };

  const onChange = (e) => {
    setTextValue(e.target.value);
  };

  var className = "inputBox" + " " + className;

  function updatedValue(e) {
    handleBlur();
    const input = e.target.value;
    // setPanNumber(input);

    /// here validation for -ve  number

    if (title == "decimal") {
      console.log("regex validation", input);
      returnValue(Number(removeFormatCurrency(input)));
       // For "int" title, only allow numbers (no ".")
  // const intValue = input.replace(/[^0-9]/g, "");
  // setTextValue(intValue);
  // returnValue(intValue);
  // return intValue;
    }

    if (title == "int") {
      // For "int" title, only allow numbers (no ".")
      const intValue = input.replace(/[^0-9]/g, "");
      setTextValue(intValue);
      returnValue(intValue);
      return intValue;
      }
    /// task id-250 rajendra start here proper validation ////
    //setIsValid(validateInputs(input));
    //// Pradeep task-id 263 added validaion for Mobile and PAN
    if (
      title == "PAN" ||
      title == "GST No." ||
      title == "Mobile" ||
      title == "Phone No." ||
      title == "PIN Code" ||
      title == "Contact Email" ||
      title == "Email"
    ) {
      //setIsValid(validateInputs(input,title,false));
      setTimeout(() => {
        setIsValid(validateInputs(input, title, true));
      }, 2000);
    }
    /// task id-250 rajendra start here proper validation end ////

    //MOBILE//
    if (title == "Mobile") {
      const mobileInput = input.replace(/[^0-9]/g, "").slice(0, 10);
      console.log("RAJENDRA12345", input, mobileInput);
      if (mobileInput.length <= 10 && input != "") {
        if (validateInputs(mobileInput, title, true)) {
          returnValue(mobileInput);
          setTextValue(mobileInput);
          return mobileInput;
        }
      } else {
        console.log("Input exceeds maximum length");
      }
    }
    console.log("RAJENDRA12345", input, type, title);

    //PHONE No.//
    if (title == "Phone No.") {
      const phoneInput = input.replace(/[^0-9]/g, "").slice(0, 10);
      if (phoneInput.length <= 10 && input != "") {
        if (validateInputs(phoneInput, title, true)) {
          returnValue(phoneInput);
          setTextValue(phoneInput);
          return phoneInput;
        }
      } else {
        console.log("Input exceeds maximum length");
      }
    }

    //PINCODE//
    if (title == "PIN Code") {
      const pinCodeInput = input.replace(/[^0-9]/g, "").slice(0, 6);
      if (pinCodeInput?.length <= 6 && input != "") {
        if (validateInputs(pinCodeInput, title, true)) {
          returnValue(pinCodeInput);
          setTextValue(pinCodeInput);
          return pinCodeInput;
        }
      } else {
        console.log("Input exceeds maximum length");
      }
    }

    // Check if it's a number and non-negative using a regex
    if (type == "number" && /^-?\d+$/.test(input) && parseInt(input, 10) >= 0) {
      setTextValue(input);
      returnValue(input);
      console.log("Valid non-negative number:", input);
      return input;
    } else {
      setTextValue(input);
      returnValue(input);
      console.log("Handling non-number case or invalid input", type);

      return input;
    }

    //{end   Pradeep task id-263   end }
  }

  const mainFrame = {
    display: "flex",
    alignItems: "start",
    flexDirection: alignment,
    gap: displayLabel && "8px",
    textAlign: "start",
    width: width,
  };

  const [isFocused, setIsFocused] = useState(false);
  const handleFocus = () => {
    // This function is called when the input element receives focus.
    setIsFocused(true);
  };

  const handleBlur = () => {
    // This function is called when the input element loses focus.
    setIsFocused(false);
  };
  console.log("minlength", minlength, maxlength);
  return (
    <div style={{ ...mainFrame, width: `${inputWidth}px` }}>
      <div style={{ position: "relative" }}>
      <label
              htmlFor={id}
              className={`label ${isFocused || textValue ? 'focused' : 'unfocused'}`}
              style={{
                position: 'absolute',
                top: isFocused || textValue ? '-20px' : '50%',
                left: '8px',
                //backgroundColor: isFocused || textValue ? 'white' : "transparent",
                padding: '0 4px',
                fontSize: isFocused || textValue ? '12px' : '16px',
                color: isFocused || textValue ? 'yellow' : 'red',
                pointerEvents: 'none',
                transform:
                  isFocused || textValue
                    ? 'translateY(0)'
                    : 'translateY(-50%)',
                transition:
                  'top 0.3s, transform 0.3s, font-size 0.3s, color 0.3s'
              }}
            >
               {isrequired ? (
                <span className={`label ${isFocused || textValue ? 'focused' : 'unfocused'}`}>
                  {isFocused || textValue? displayLabel : placeholder}
                  <span style={{ color: 'red' }}>*</span>
                </span>
              ) : (
                <span className={`label ${isFocused || textValue ? 'focused' : 'unfocused'}`}>
                  {isFocused || textValue? displayLabel : placeholder}
                 
                </span>
              )}
            </label>
        <div style={{ flexGrow: 1, width: "100%" }}>
          {change ? (
            <input
              id={id}
              className={className}
              style={{ ...style, width: inputWidth, MozAppearance: "textfield" }}

              type={type}
              onBlur={handleBlur}
              onFocus={handleFocus}
              // placeholder={placeholder}
              onChange={updatedValue}
              value={textValue}
              required={required}
              minlength={minlength}
              maxlength={maxlength}
              minvalue={minvalue}
              maxvalue={maxvalue}
              onKeyDown={(e) => {
                ///  here -ve and special charaecter preventation
                if (type == "number") {
                  ["ArrowUp", "ArrowDown", "e", "E", "-"].includes(e.key) &&
                    e.preventDefault();
                }
              }}
              disabled={disabled==0?true:false}
            />
          ) : (
            <input
              className={className}
              style={{ ...style }}
              id={id}
              type={type}
              onFocus={handleFocus}
              // placeholder={placeholder}
              onBlur={updatedValue}
              onChange={onChange}
              value={textValue}
              required={required}
              minlength={minlength}
              maxlength={maxlength}
              minvalue={minvalue}
              maxvalue={maxvalue}
              onKeyDown={(e) => {
                ///  here -ve and special charaecter preventation
                if (type == "number") {
                  ["ArrowUp", "ArrowDown", "e", "E", "-"].includes(e.key) &&
                    e.preventDefault();
                }
              }}
            />
          )}

          {isValid ? (
            <div>{null}</div>
          ) : (
            <p style={{ color: "red" }}>
              {err != "" && (rlen == 10 || rlen == 15)
                ? errorMessage[title]
                : err}
            </p>
          )}
          {/* {isValid==true ? (<div>{ null }</div>): 0<panNumber.length<10 ?(<p style={{color:"red"}}>PAN Card is not valid</p>):null} */}
        </div>
      </div>
    </div>
  );
}

export default CallbackTextInput;

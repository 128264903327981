import { Grid } from "@mui/material";
import { Input, Typography, Button } from "antd";
import React, { useState, useContext, useEffect } from "react";
import ItemSearch from "../../CommonControllers/ItemController/ItemSearch";
import { TabStore } from "../../TabsStructure/TabsStore";

function ItemReceipt() {
  const tabstore = useContext(TabStore);
  // console.log("TabStore",tabstore)
  // console.log("ItemReceipt",tabstore.state.tabs[0].apiResponse)

  const [getSelectedItem, setSelectedItem] = useState(null);
  console.log("getSelectedItem", getSelectedItem);
  const [supplierdata, setSupplierdata] = useState(null);
  

  // default code
  const [defcode, setDefcode] = useState(null);
  const [itemshortdescn, setItemshortdescn] = useState(null);

  const [qty, setQty] = useState(0);
  const [box, setBox] = useState(0);
  const [batch, setBatch] = useState("");
  const [refdoc, setrefdoc] = useState("");
  const [description, setDescription] = useState("");
  const [items,setItemS] = useState("");

  const [icode, setIcode] = useState("");
  const [idescn, setIdescn] = useState("");
  const [ishortdescn, setIshortdescn] = useState("");



  useEffect(() => {
    setDefcode(getSelectedItem);
    setItemS(getSelectedItem)
  }, [getSelectedItem, defcode][(getSelectedItem, itemshortdescn,items)]);

  function setDatatoSupplierdata(e) {
    setSupplierdata(e);
  }

  const handleClear = () => {
    setQty("");
    setBox("");
    setBatch("");
    setrefdoc("");
    setDescription("");
  };
  useEffect(() => {
    if (getSelectedItem) {
      setBatch(getSelectedItem?.entrybatch);
    }
  }, [getSelectedItem]);

  const handleSave = () => {
    if (qty === "" || Number(qty) === 0) {
      alert("Please Enter Quantity ");
    } else {
      if (batch === "") {
        alert("Please Enter Batch Number ");
      } else {
        if (refdoc === "") {
          alert("Please Enter Reference Document Number ");
        } else {
          if (description === "") {
            alert("Please Enter Description ");
          }
        }
      }
    }
    handleClear();

    let val = [];

    let object = {
      drqty: 0,
      itembatchno: batch,
      refrecno: 0,
      descn: description,
      crqty: qty,
      instrument: "item Receipt",
      instrumentbillno: refdoc,
    };

    val.push(object);
    console.log("consolelog", val);

    tabstore.dispatch({
      type: "UPDATE_DATA",
      fieldType: "changedRows",
      text: val,
    });
  };

  const handlebatch = (e) => {
    setBatch(e.target.value);
  };

  const handledocs = (e) => {
    setrefdoc(e.target.value);
  };

  const handledesn = (e) => {
    setDescription(e.target.value);
  };

  // console.log("ytdft", defcode);
  return (
    <div>
      <div>
        <ItemSearch
          forFlag={"D"}
          returnValue={setSelectedItem}
          //selection={true}
          dropdown
        />
      </div>

      <div
        style={{
          marginLeft: "40px",
          display: "flex",
          flexDirection: "row",
          gap: "40px",
          marginBottom: "10px",
        }}
      >
        <Typography style={{ display: "flex", flexDirection: "column" }}>
          {" "}
          Item Code
          <Input
            style={{
              width: "150px",
              flexDirection: "column",
              marginTop: "5px",
            }}
            defaultValue={getSelectedItem?.itemcode}
            value={getSelectedItem?.itemcode}
          />
        </Typography>
        <Typography style={{ display: "flex", flexDirection: "column" }}>
          Item Description
          <Input
            style={{ width: "400px", marginTop: "5px" }}
            defaultValue={getSelectedItem?.itemdescn}
            value={getSelectedItem?.itemdescn}
          />
        </Typography>
        <Typography style={{ display: "flex", flexDirection: "column" }}>
          Item Short Description
          <Input
            style={{ width: "200px", marginTop: "5px" }}
            defaultValue={getSelectedItem?.itemshortdescn}
            value={getSelectedItem?.itemshortdescn}
          />
        </Typography>
      </div>

      <div style={{ marginLeft: "40px", marginTop: "5px" }}>
        <Grid style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "10px",
            }}
          >
            <Typography style={{ display: "flex", flexDirection: "column" }}>
              Quantity
              <Input
                value={qty}
                type="number"
                // required
                onChange={(e) => setQty(e.target.value)}
                style={{ width: "150px" }}
              />
            </Typography>
            <Typography style={{ display: "flex", flexDirection: "column" }}>
              Box Quantity
              <Input
                value={box}
                type="number"
                // required
                onChange={(e) => setBox(e.target.value)}
                style={{ width: "150px" }}
              />
            </Typography>
          </div>

          <Typography>Batch Number</Typography>
          <Input
            maxLength={45}
            type="text"
            value={batch}
            // required
            onChange={handlebatch}
            style={{ width: "150px" }}
          />

          <Typography>Reference Document Number</Typography>
          <Input
            maxLength={45}
            type="text"
            value={refdoc}
            // required
            onChange={handledocs}
            style={{ width: "250px" }}
          />

          <Typography>Description</Typography>
          <Input
            type="text"
            value={description}
            maxLength={100}
            // required
            onChange={handledesn}
            style={{ width: "500px" }}
          />
        </Grid>
      </div>

      <Button
        onClick={handleSave}
        style={{ marginLeft: "2.5%", marginTop: "10px" }}
        type="primary"
      >
        {" "}
        Proceed
      </Button>
    </div>
  );
}

export default ItemReceipt;






// Nohit Date: 14/03/2023 Create This Component for search bill modal

import React, { useContext, useEffect, useState } from 'react'


import { v4 as uuidv4 } from "uuid";
import { Button } from 'antd';
import noData from "../../Assets/noData.png";
import { AuthStore } from '../../Auth/AuthStore';
import { apiCallwithBaseUrl, getfastpathapi } from '../../CommonControllers/ApiCalls';
import { TabStore } from '../../TabsStructure/TabsStore';
import CallbackDropdown from '../../CommonControllers/Components/CallbackComponents/CallbackDropdown';
import CallbackTextInput from '../../CommonControllers/Components/CallbackComponents/CallbackTextInput';
import CallbackCheckbox from '../../CommonControllers/Components/CallbackComponents/CallbackCheckbox';
import CallbackDateInput from '../../CommonControllers/Components/CallbackComponents/CallbackDateInput';
import ExecuteFileInput from '../../CommonControllers/Components/ExecuteComponents/ExecuteFileInput';
import CallbackRadioBtn from '../../CommonControllers/Components/CallbackComponents/CallbackRadioBtn';
import LogComponent from '../Logs/LogsData/LogComponent';


function Purchasesalebillnumber({ filterOptionsProps, setfilterdropdown, filterDictionary, setFilterDictionary, fastpath, setshowFilterModal, showFilterModal, statusC, setstatusC, forFlag ,forreturn}) {
    const [filterOptions, setFilterOptions] = useState([]);
    const [apiURL, setapiURL] = useState()
    const [data, setdata] = useState()
    const [selectedFilterOptions, setSelectedFilterOptions] = useState({});
    const [dropdownValue, setdropdownValue] = useState([])
    const auth = useContext(AuthStore);
    const tabStore = useContext(TabStore);

    console.log("data on filter",forreturn)
    console.log("statusC on Searchsalebillnumber", statusC)
    useEffect(() => {
        // On component mount this api is called and filterOptions are set for that fast path
        getfastpathapi(payload).then((res) => {
            console.log("rrerererererr", res)
            if (res?.Success) {
                setFilterOptions(res?.Message?.filterOptions);
                if (filterOptionsProps)
                    filterOptionsProps(res?.Message?.filterOptions);

                setapiURL(res?.Message?.apiurl) // For Save Url
            }
        });
    }, []);

    useEffect(() => {
        console.log("Use Effect is invoked")
        setFilterOptions(filterOptionsProps);
    }, [filterOptionsProps]);

    const storeData = (e, apikey, labeldropdowns) => {
        // Create a Temporary Add Store in the TabStore
        var newData = selectedFilterOptions;
        newData[apikey] = e;
        console.log("NEw Data is", newData, e, apikey)
        setSelectedFilterOptions(newData);
        // if (setfilterdropdown) {

        //     setfilterdropdown(labeldropdowns);// smk 24-2-
        // }
    };

    //* This function sets the values obtained from the filteroptions above
    const setterFunction = (event, element) => {
        let displayValue;
        //# May need to use logical OR operator
        typeof event === "object" ? (displayValue = event?.displayLabel ?? event.label) : (displayValue = event);

        console.log("FC ln 64 value ========>", element["displayLabel"], displayValue, "filter dict", filterDictionary);

        //% Check here whether array contains the same id, if yes replace it with new object

        const targetReplacer = filterDictionary?.find((obj) => obj.id === element.displayLabel);
        if (targetReplacer) {
            //~# alert(`Replaced previous filter: ${element?.displayLabel}`);
            Object.assign(targetReplacer, { id: element?.displayLabel, descn: displayValue });
            return;
        }
        console.log("FC target found ??", targetReplacer);

        //% Setting id as displayLabel ==> id : Domain and key : value as ==> Domain : SAPL (example)
        setTimeout(() => {
            let tempArray = [...filterDictionary, { id: element?.displayLabel, descn: displayValue }];

            console.log("FC", tempArray);

            setFilterDictionary(tempArray);
        }, 100);
    };

    const enterPress = (e) => {
        if (e.keyCode === 13) {
            e.preventDefault();
            const form = e.target.form;
            const index = Array.prototype.indexOf.call(form, e.target);
            if (form.elements[index + 1]) {
                form.elements[index + 1].focus();
            }
        }
    };

    const filterBill = async () => {
        const response = await apiCallwithBaseUrl("POST", apiURL, {
            tenantrecno: auth?.state?.userdata?.tenantrecno,
            domainrecno: auth?.state?.userdata?.domainrecno,
            domainuserrecno: auth?.state?.userdata?.recno,
            fastpath: fastpath,
            ...selectedFilterOptions,
            status : fastpath === "RP102" ? "9" : "C",
            forreturn:forreturn?forreturn:false,
            userrolerecno: auth.state.userdata.userrolerecno,
            fortrn:"Y"
            /// forreturn is added  date 19/04/023 for  both PR SR by rabin
        });

        //const data = response

        setdata(response)
   console.log("filterbill response api", response)

    };

    const payload = {
        tenantrecno: auth?.state?.userdata?.tenantrecno,
        domainrecno: auth?.state?.userdata?.domainrecno,
        userrolerecno: auth?.state?.userdata?.userrolerecno,
        domainuserrecno: auth.state.userdata.recno,
        fastpath: fastpath,
        ...storeData,

    };

    console.log("payload is", payload)
    useEffect(()=>{
        console.log("RENDEERRR",data)

    },[data])

    return (
        <div>
            <div className="searchBox">
                <form className="searchBox">
                    {filterOptions?.map((elem) =>
                        elem.type === "dropdown" ? (
                            <div onKeyDown={enterPress}>
                                <CallbackDropdown
                                    displayLabel={elem?.displayLabel}
                                    alignment="column"
                                    placeholder={elem?.placeholder}
                                    apiurl={elem?.apiEndPoint}
                                    selectionLabel={elem?.selectionLabel}
                                    selectionValue={elem?.selectionValue}
                                    apikey={elem?.apiKey}
                                    multi={elem?.isMulti}
                                    records={20}
                                    minInput={0}
                                    returnValue={(e) => {
                                        if (e) {
                                            console.log("FC", e[elem?.selectionValue], elem?.apiKey, e[elem?.selectionLabel]);
                                            setterFunction(e, elem);
                                            storeData(e[elem?.selectionValue], elem?.apiKey, e[elem?.selectionLabel]);
                                             setdropdownValue(e[elem?.selectionValue], elem?.apiKey, e[elem?.selectionLabel])
                                        }
                                    }}
                                    body={{
                                        ...selectedFilterOptions
                                    }}
                                    requiredBody={elem?.payloadKey}
                                    width={elem?.width}
                                    defaultSelected={elem?.defaultValue}
                                />
                            </div>
                        ) : elem.type === "string" ? (
                            <div onKeyDown={enterPress}>
                                <CallbackTextInput
                                    alignment="column"
                                    displayLabel={elem?.displayLabel}
                                    placeholder={elem?.placeholder}
                                    apikey={elem?.apiKey}
                                    defaultValue={elem?.defaultValue}
                                    change
                                    returnValue={(e) => {
                                        console.log("Element is e", e, elem)
                                        setterFunction(e, elem);
                                        storeData(e, elem?.apiKey);
                                    }}
                                />
                            </div>
                        ) : elem.type === "boolean" ? (
                            <div onKeyDown={enterPress}>
                                <CallbackCheckbox
                                    alignment="column"
                                    displayLabel={elem?.displayLabel}
                                    placeholder={elem?.placeholder}
                                    apikey={elem?.apiKey}
                                    defaultValue={elem?.defaultValue}
                                    change
                                    editable
                                    returnValue={(e) => {
                                        setterFunction(e, elem);
                                        storeData(e, elem?.apiKey);
                                    }}
                                />
                            </div>
                        ) : elem.type === "date" ? (
                            <div onKeyDown={enterPress}>
                                <CallbackDateInput
                                    id={uuidv4()}
                                    alignment="column"
                                    defaultValue={elem?.defaultValue}
                                    displayLabel={elem?.displayLabel}
                                    placeholder={elem?.placeholder}
                                    apiKey={elem?.apiKey}
                                    change
                                    editable
                                    returnValue={(e) => {
                                        console.log("Selection Valu", elem?.selectionValue, e)
                                        setterFunction(e, elem);
                                        storeData(e, elem?.apiKey);
                                    }}
                                />
                            </div>
                        ) : elem.type === "file" ? (
                            <div onKeyDown={enterPress}>
                                <ExecuteFileInput
                                    displayLabel={elem?.displayLabel}
                                    placeholder={elem?.placeholder}
                                    apiKey={elem?.apiKey}
                                    returnValue={(e) => {
                                        setterFunction(e, elem);
                                        storeData(e, elem?.apiKey);
                                    }}
                                />
                            </div>
                        ) : elem.type === "radio" ? (
                            <div onKeyDown={enterPress}>
                                <CallbackRadioBtn
                                    apikey={"radio"}
                                    displayLabel={"Select Radio"}
                                    options={elem?.radioOptions}
                                    defaultValue={elem?.defaultValue}
                                    returnValue={(e) => {
                                        setterFunction(e, elem);
                                        storeData(e, elem?.apiKey);

                                    }}
                                />
                            </div>
                        ) : null
                    )}
                </form>
            </div>
            <div style={{ display: 'flex', justifyContent: "center", alignItems: "center" }}>
                <Button
                    type='default'
                    onClick={filterBill}
                >
                    Search
                </Button>
            </div>
            <div className="under">
                {data?.Message?.length > 0 ? (
                    <LogComponent
                        data={data?.Message}
                        columns={data?.columnsinfo}
                        itemsColumn={
                            data?.columnsinfofooter
                        }
                        setshowFilterModal={setshowFilterModal}
                        showFilterModal={showFilterModal}
                        statusC={statusC}
                        setstatusC={setstatusC}
                        forFlag={forFlag}
                    />
                ) : (
                  null
                )}
                {
               data?.Success&&data?.Message?.length ==0?  (  <div className="noDataFound">
               <h1>Oops! No Records Found</h1>
               <img src={noData} />
           </div>):null
                }
            </div>
        </div>
    );
};


export default Purchasesalebillnumber
import React, { useContext, useEffect, useState } from "react";
import { AuthStore } from "../../Auth/AuthStore";
import { TabStore } from "../../TabsStructure/TabsStore";
import DateComponent from "../Components/DateComponent";
import ExecuteDropdown from "../Components/ExecuteComponents/ExecuteDropdown";
import ExecuteTextInput from "../Components/ExecuteComponents/ExecuteTextInput";
import { getfastpathapi } from "../ApiCalls";
import ExecuteCheckbox from "../Components/ExecuteComponents/ExecuteCheckbox";
import ExecuteRadioBtn from "../Components/ExecuteComponents/ExecuteRadioBtn";
import { currentTabData } from "../Variable";
import { baseUrl } from "../Urls";
import ExecuteDateInput from "../Components/ExecuteComponents/ExecuteDateInput";
import ExecuteFileInput from "../Components/ExecuteComponents/ExecuteFileInput";
import { v4 as uuidv4 } from "uuid";
import SaveDropDown from "../SaveComponents/SaveDropDown";
import SaveTextInput from "../Components/SaveComponents/SaveTextInput";
import SaveCheckbox from "../Components/SaveComponents/SaveCheckbox";
import SaveDatePicker from "../SaveComponents/SaveDatePicker";
import SaveFileInput from "../Components/SaveComponents/SaveFileInput";
import SaveRadioBtn from "../Components/SaveComponents/SaveRadioBtn";
import { enterPress } from "../AppFunction";
import SaveDropdown from "../Components/SaveComponents/SaveDropdown";

const AdditionalDetails = ({
  filterOptionsProps,
  setfilterdropdown,
  customerrecno,
  supplierrecno,
  filterOption,
  fastPath
}) => {
  const [filterOptions, setFilterOptions] = useState([]);
  const [filterDictionary, setFilterDictionary] = useState([]);
  const [selectedFilterOptions, setSelectedFilterOptions] = useState({});
  const auth = useContext(AuthStore);
  const tabStore = useContext(TabStore);
  const [pageIndex, setPageIndex] = useState();

  useEffect(() => {
    var tabsArray = tabStore.state.tabs;
    for (let i = 0; i < tabsArray?.length; i++) {
      if (tabsArray[i].name == fastPath) {
        setPageIndex(i);
        break;
      }
    }
  }, []);


  console.log("Supplier Recno is", customerrecno);

  
  useEffect(() => {
    setFilterOptions(filterOption);
  }, [filterOption]);


  useEffect(() => {
    if (customerrecno) {
      setSelectedFilterOptions((p) => {
        return { ...p, customerrecno: customerrecno.customerrecno };
      });
    } else if (supplierrecno) {
      setSelectedFilterOptions((p) => {
        return { ...p, supplierrecno: supplierrecno.supplierrecno };
      });
    }
  }, [customerrecno, supplierrecno]);



  const storeData = (e, apikey, labeldropdowns) => {
    // Create a Temporary Add Store in the TabStore
    var newData = selectedFilterOptions;
    newData[apikey] = e;
    setSelectedFilterOptions(newData);

    // if (setfilterdropdown) {
    //   setfilterdropdown(labeldropdowns); // smk 24-2-
    // }

    console.log("SD", e, apikey, labeldropdowns);
  };

  //* This function sets the values obtained from the filteroptions above
  const setterFunction = (event, element) => {
    let displayValue;
    //# May need to use logical OR operator
    typeof event === "object"
      ? (displayValue = event?.displayLabel ?? event.label)
      : (displayValue = event);

    //% Check here whether array contains the same id, if yes replace it with new object

    const targetReplacer = filterDictionary?.find(
      (obj) => obj.id === element.displayLabel
    );
    if (targetReplacer) {
      //~# alert(`Replaced previous filter: ${element?.displayLabel}`);
      Object.assign(targetReplacer, {
        id: element?.displayLabel,
        descn: displayValue,
      });
      return;
    }

   
    //% Setting id as displayLabel ==> id : Domain and key : value as ==> Domain : SAPL (example)
    setTimeout(() => {
      let tempArray = [
        ...filterDictionary,
        { id: element?.displayLabel, descn: displayValue },
      ];

      console.log("FC", tempArray);

      setFilterDictionary(tempArray);
    }, 100);
  };

 
  
  console.log("FC target found ??",customerrecno,filterOptions);


  return (
    <div >
      {filterOptions?.map((elem) =>
        elem.type === "dropdown" ? (
          <div style={{zIndex:9}} onKeyDown={enterPress}>
            <SaveDropdown
              displayLabel={elem?.columndisplay}
              key={customerrecno}
              alignment="column"
              placeholder={elem?.placeholder}
              apiurl={elem?.apiurl}
              selectionLabel={elem?.selectionlabel}
              selectionValue={elem?.selectionvalue}
              apikey={elem?.apiKey}
              multi={elem?.isMulti}
              records={20}
              minInput={0}
              returnValue={(e) => {
                if (e) {
                 
                  setterFunction(e, elem);
                  storeData(
                    e[elem?.selectionvalue],
                    elem?.apiKey,
                    e[elem?.selectionlabel]
                  );
                }
              }}
              body={{
                ...selectedFilterOptions,
              }}
              requiredBody={elem?.payloadKey}
              //width={elem?.width}
              defaultSelected={elem?.defaultValue}
            />
          </div>
        ) : elem.type === "string" ? (
          <div onKeyDown={enterPress}>
            <SaveTextInput
              alignment="column"
              displayLabel={elem?.columndisplay}
              placeholder={elem?.placeholder}
              apikey={elem?.apiKey}
              defaultValue={elem?.defaultValue}
              change
              width={elem?.width}
              isrequired={elem.req}
              returnValue={(e) => {
                console.log("FC", e);
                setterFunction(e, elem);
                storeData(e, elem?.apiKey);
              }}
              pageIndex={pageIndex}
            />
          </div>
        ) : elem.type === "boolean" ? (
          <div onKeyDown={enterPress}>
            <SaveCheckbox
              alignment="column"
              displayLabel={elem?.columndisplay}
              placeholder={elem?.placeholder}
              apikey={elem?.apiKey}
              defaultValue={elem?.defaultValue}
              change
              editable
              returnValue={(e) => {
                setterFunction(e, elem);
                storeData(e, elem?.apiKey);
              }}
            />
          </div>
        ) : elem.type === "date" ? (
          <div onKeyDown={enterPress}>
            <SaveDatePicker
              id={uuidv4()}
              alignment="column"
              defaultValue={elem?.defaultValue}
              displayLabel={elem?.columndisplay}
              placeholder={elem?.placeholder}
              apiKey={elem?.apiKey}
              change
              editable
              returnValue={(e) => {
                console.log("FC", e);
                setterFunction(Number(e), elem);
                storeData(e, elem?.apiKey);
              }}
            />
          </div>
        ) : elem.type === "file" ? (
          <div onKeyDown={enterPress}>
            <SaveFileInput
              displayLabel={elem?.columndisplay}
              placeholder={elem?.placeholder}
              apiKey={elem?.apiKey}
              returnValue={(e) => {
                setterFunction(e, elem);
                storeData(e, elem?.apiKey);
              }}
            />
          </div>
        ) : elem.type === "radio" ? (
          <div onKeyDown={enterPress}>
            <SaveRadioBtn
              apikey={"radio"}
              displayLabel={"Select Radio"}
              options={elem?.radioOptions}
              defaultValue={elem?.defaultValue}
              returnValue={(e) => {
                setterFunction(e, elem);
                storeData(e, elem?.apiKey);
              }}
            />
          </div>
        ) : null
      )}
    </div>
  );
};

export default AdditionalDetails;

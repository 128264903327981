import React, { useState, useEffect, useContext } from "react";
import InputComponent from "../CommonControllers/Components/InputComponent";
import "../CommonControllers/global.css";
import { Typography, Button } from "antd";
import { AuthStore } from "./AuthStore";
import { confirmURL, forgotURL, loginURL } from "../CommonControllers/Urls";
import axios from "axios";
import { CheckCircleTwoTone } from "@ant-design/icons";
import johnson from '../Assets/johnson.jpeg'
import Login from "./Login";


const { Title } = Typography;

const ForgotPassword = () => {
  const auth = useContext(AuthStore);
  const [loading, setLoading] = useState(false);
  const [token, setToken] = useState("");
  const [tenantName, setTenantName] = useState("");
  const [tenantrecno, setTenantrecno] = useState("");
  const [forgot,setForgot]=useState(false)
  const[changepasswor,setChangesPasswords]=useState(false)

  const Greetings = () => <div>{token ? `Welcome ${tenantName} !!!` : { tenantName }}</div>;

  // This func handle the user loginid and password,
  const updatePassword = () => {
    
    setLoading((prev) => !prev);
    const loginId = document.querySelector("#userLoginId").value;
    const password = document.querySelector("#userPassword").value;
    const otp = document.querySelector("#otp").value;
   
    if ((loginId === "" || password === ""||otp=="")) {
      alert("Please Input Required Field");
      setLoading((prev) => !prev);
      return;
    }

    const payload = {
      loginid: loginId,
      pwd: password,
      otp:otp
    };

    payload &&
      axios
        .post(confirmURL, payload)
        .then((res) => {
          setTimeout(() => {
            if (res.data.Success) {
                alert("Passwords Updated Successfully")
                setChangesPasswords(true)
           
            } else {
              setLoading((prev) => !prev);
              alert("Invalid LoginId or Password");
              document.querySelector("#userLoginId").value = "";
              document.querySelector("#userPassword").value = "";
            }
          }, 1000);
        })
        .catch((error) => {
          console.log("RYTUGF",)
          alert(error?.response?.data?.Error)
          setLoading((prev) => !prev);
        });
  };



  return (
    <>{changepasswor==false?
     <div className="maindiv">

    <div className="imformation"> 
        
        
         <div> 
         <div >
     <div >
       {/* <img 
       
        style={{width:"100%",height:"100%",marginTop:"1px"}}
       /> */}
       {/* <img src="https://sutradhar.co.in/static/media/logo1.18cee992bb194f16b0b5.png" style={{width:"60%",height:"60%",marginTop:"30%",marginLeft:"50%"}}/> */}
     
       <img src={johnson} style={{width:"60%",height:"60%",marginTop:"10%",marginLeft:"8%"}}/>
     
     
     </div>
     {/* <div>
        <h2 style={{marginLeft:"40%" ,width:"100%",marginTop:"5%",fontSize:"20px"}} > Sutradhar Project Consultancy Pvt. Ltd.</h2>
       
     </div> */}
   </div>
         </div>
   


    </div>

     {/* <hr style={{marginTop:"3px",height:'630px'}}/> */}

    <div className="loginBox"> 
    <div>
     <Title level={3}>{!tenantName ? "User Login" : <Greetings />}</Title>
   </div>
   <div onKeyDown={(e) => {
     if (e.keyCode == 13 && e.target.value) {
       document.getElementById("userPassword").focus()
     } else if (e.keyCode == 13 && !e.target.value){
       alert("Please Enter Valid User ID")
     }
   }}>
     <p>Enter Login ID</p>
     <InputComponent
       id="userLoginId"
       required
       //displayLabel="Enter Login Id"
     />
   </div>
   
   <div onKeyDown={(e) => {
     if (e.keyCode==13 && e.target.value) {
        updatePassword(e)
     }
   }}>
  <p>Enter New Password</p>
     <InputComponent
       id="userPassword"
       type="password"
       required
       //displayLabel="Enter Password"
     />
   </div>
   <div onKeyDown={(e) => {
     if (e.keyCode==13 && e.target.value) {
        updatePassword(e)
     }
   }}>
  <p>Enter OTP</p>
     <InputComponent
       id="otp"
       type="text"
       required
       //displayLabel="Enter Password"
     />
   </div>
 
   <div>
     <Button
       type="primary"
       size="large"
       onClick={()=>{
         
        updatePassword()
         
       }}
       loading={loading}
       icon={token && <CheckCircleTwoTone twoToneColor="green" />}
     >
       {!token ? "Submit" : "Logged In"}
     </Button>
   </div>

    </div>

  
 </div>:<Login/>}
   
    </>
  );
};

export default ForgotPassword;

import React, { useEffect, useState, useContext } from "react";
import { Button, Checkbox, Input, Table } from 'antd';
import CallbackDropdown from "../../CommonControllers/Components/CallbackComponents/CallbackDropdown";
const columns = [
    {
      title: 'Item Description',
      dataIndex: 'name',
    },
    {
      title: 'Closing Stock',
      dataIndex: 'stock',
    },
    {
      title: 'MOQ',
      dataIndex: 'age',
    },
    {
      title: 'Order In Transit',
      dataIndex: 'transit',
    },
    {
      title: 'Demand Qty',
      dataIndex: 'address',
      render:(_,it)=>{
        console.log("RRRR",it) 
        return <><Input value={it.address}></Input></>
    }
    },
    {
        title: ' Select Supplier',
        dataIndex: 'address',
        render:()=>{
            return <CallbackDropdown/>
        }
      },
  ];
  const data = [];
  for (let i = 0; i < 46; i++) {
    data.push({
      key: i,
      name: `Edward King ${i}`,
      age: 32,
      address: ` ${i}`,
      stock:`${i}`,
      transit:`${i}`
    });
  }
const DemandWithPo=()=>{
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [loading, setLoading] = useState(false);
    const start = () => {
      setLoading(true);
      // ajax request after empty completing
      setTimeout(() => {
        setSelectedRowKeys([]);
        setLoading(false);
      }, 1000);
    };
    const onSelectChange = (newSelectedRowKeys) => {
      console.log('selectedRowKeys changed: ', newSelectedRowKeys);
      setSelectedRowKeys(newSelectedRowKeys);
    };
    const rowSelection = {
      selectedRowKeys,
      onChange: onSelectChange,
    
     
    };
    const hasSelected = selectedRowKeys.length > 0;
    return (<>
     <div>
      <div
        style={{
          marginBottom: 16,
        }}
      >
        <Button type="primary" onClick={start} disabled={!hasSelected} loading={loading}>
          Reload
        </Button>
        <span
          style={{
            marginLeft: 8,
          }}
        >
          {hasSelected ? `Selected ${selectedRowKeys.length} items` : ''}
        </span>
      </div>
      <Table rowSelection={rowSelection} columns={columns} dataSource={data} pagination={false}  scroll={{ y: 480 }} // Set a fixed height for the table body
      sticky />
    </div>
    </>)

}
export default DemandWithPo

import { Checkbox } from "antd";
import React, { useEffect, useState } from "react";
import useSaveChangedRows from "../../Hooks/useSaveChangedRows";

function SaveCheckbox({
  editable,
  returnValue,
  displayLabel,
  apikey,
  defaultValue,
}) {
  const [checked, setChecked] = useState();
  const execute = useSaveChangedRows();

  // Setting Default Value that comes from the Parent Component
  useEffect(() => {
  
    if (defaultValue) {
      if (execute.getValue(apikey)) {
        setChecked(execute.getValue(apikey));
      } else if (execute.getValue(apikey) == "") {
        setChecked(execute.getValue(apikey));
      } else {
        setChecked(defaultValue);
        execute.updateValue(apikey, defaultValue);
      }
    } else {
    
      if (execute.getValue(apikey)) {
       
        setChecked(execute.getValue(apikey));
      }
    }
  }, [defaultValue]);

  // On Change the value needs to be stored in the tabstore
  const onChange = (e) => {
    const isChecked = e.target.checked;
  
    
    setChecked(()=>e.target.checked);
    
    if (returnValue) {
      returnValue(isChecked);
    }
    
    execute.updateValue(apikey, isChecked);
  };
  
  return (
    <div style={{display:"flex", alignItems:"center"}} key={checked}>
      {editable ? (
        <Checkbox onChange={onChange} checked={checked=="0"?false:checked}>
          {displayLabel}
        </Checkbox>
      ) : (
        <Checkbox checked={checked=="0"?false:checked}>{displayLabel}</Checkbox>
      )}
    </div>
  );
}

export default SaveCheckbox;

import { Spin } from "antd";
import React, { useEffect, useState } from "react";
import CallbackDateInput from "../../CommonControllers/Components/CallbackComponents/CallbackDateInput";
import { addMonthsAndDays } from "../../CommonControllers/AppFunction";

const GRNDates = ({ mfgDate, grnDate, setMfgDate, expDate, setExpDate, render, getSelectedItem }) => {
  const [timer, setTimer] = useState(false);
  console.log("TR003 date rendered ==>", mfgDate, expDate);
  useEffect(() => {
    setTimer(true);

    setTimeout(() => {
      setTimer(false);
    }, 0);
  }, [render]);

  return (
    <>
      {!timer ? (
        <div style={{ ...mainFrame }}>
          <div>
            <p>Mfg Date<span style={{ color: 'red' }}>*</span></p>
            <CallbackDateInput
              defaultValue={mfgDate}
              width={"130px"}
              id={"po-manufacture-date-callback"}
              dateLessthan={grnDate}
              postAlertMsg={"Manufacturing Date should be less than GRN Date"}
              apiKey={"manudate"}
              returnValue={(e) => {
                setMfgDate(e);
                if(getSelectedItem?.expmonths > 0 || getSelectedItem?.expdays > 0){
                  let months = getSelectedItem?.expmonths > 0 ? Number(getSelectedItem?.expmonths) - 1 : 0;
                  let days = getSelectedItem?.expdays > 0 ? Number(getSelectedItem?.expdays) - 1 : 0;
                  let expDate = addMonthsAndDays(e, months, days)
                  setExpDate(expDate);
                }
              }}
              initial
            />
          </div>

          <div>
            <p>Exp Date<span style={{ color: 'red' }}>*</span></p>
            <CallbackDateInput
             width={"130px"}
              defaultValue={expDate}
              id={"po-expiry-date-callback"}
              // displayLabel={"Expiry Date"}
              apiKey={"manudate"}
              returnValue={(e) => {
                setExpDate(e);
              }}
             dateGreaterthan={mfgDate}
            />
          </div>
        </div>
      ) : (
        <div>
          <Spin size="medium" tip="loading" />
        </div>
      )}
    </>
  );
};

export default GRNDates;

const mainFrame = {
  display: "flex",
  gap: "12px",
  height: "fit-content",
};

import React, { useContext, useState, useEffect, useRef } from "react";
import { AuthStore } from "../../Auth/AuthStore";
import { getfastpathapi } from "../../CommonControllers/ApiCalls";
import DateComponent from "../../CommonControllers/Components/DateComponent";
import CustomDropDown from "../../CommonControllers/Components/DropdownSelect";
import InputComponent from "../../CommonControllers/Components/InputComponent";
import FilterComponents from "../../CommonControllers/FiterComponent/FilterComponents";
import { baseUrl } from "../../CommonControllers/Urls";
import { currentTabData } from "../../CommonControllers/Variable";
import { TabStore } from "../../TabsStructure/TabsStore";
import LogComponent from "../Logs/LogsData/LogComponent";
import SalesInvoiceTable from "./SalesInvoiceTable";

//import { useReactToPrint } from 'react-to-print';
import TableComponent from "../../CommonControllers/Components/TableComponent/TableComponent";
import Modal from "antd/es/modal/Modal";
import PrintSale from "./PrintSale";
import { Button, Spin } from "antd";
import { AiFillPrinter } from "react-icons/ai";
import noData from "../../Assets/noData.png";
import useExecute from "../../CommonControllers/Hooks/useExecute";

const MobileUserPermission = ({ fastPath }) => {
  const tabStore = useContext(TabStore);
  const auth = useContext(AuthStore);
  const [filterOptions, setFilterOptions] = useState([]);
  const [ReportName, setReportName] = useState([]);
  const [open, setOpen] = useState(false);
  const [filterprinting, setFilterprinting] = useState([]);
  const [filterdropdown, setFilterdropdown] = useState("");
  const[recno,setRecno]=useState()
  const execute = useExecute();


  //------loading-----------------------
  const [showTable, setShowTable] = useState(true);
  const [filterDictionary, setFilterDictionary] = useState([]);

  const [pageIndex, setPageIndex] = useState();
  useEffect(() => {
    var tabsArray = tabStore.state.tabs;
    for (let i = 0; i < tabsArray?.length; i++) {
      if (tabsArray[i].name == fastPath) {
        setPageIndex(i);
        break;
      }
    }
  }, []);

  useEffect(() => {
    setShowTable(false);

    setTimeout(() => {
      setShowTable(true);
    }, 200);
  }, [tabStore.state.tabs[pageIndex]?.apiResponse]);
  // ----------loading end------------------------------

  useEffect(() => {
    const payload = {
      tenantrecno: auth?.state?.userdata?.tenantrecno,
      domainrecno: auth?.state?.userdata?.domainrecno,
      userrolerecno: auth?.state?.userdata?.userrolerecno,
      fastpath: tabStore.state.tabs[pageIndex]?.name,
    };

    execute.updateValue("forreport", "Y");

    // On component mount this api is called and filterOptions are set for that fast path
    getfastpathapi(payload).then((res) => {
      if (res?.Success) {
        setFilterOptions(res?.Message?.filterOptions);
        setReportName(res?.Message?.descn);
      }
    });
  }, [pageIndex]);

  return (
    <div className="purchaseOrderLog">
      <div className="over">
        <div className="searchBox">
          <FilterComponents
            filterOption={filterOptions}
            filterOptionsProps={setFilterprinting}
            setfilterdropdown={setFilterdropdown}
            filterDictionary={filterDictionary}
            setFilterDictionary={setFilterDictionary}
            returnValue={(e)=>{
              console.log("TDGIUB",e)
              setRecno(e)
            }}
          />
        </div>
      </div>
      <div className="under">
        {tabStore.state.tabs[pageIndex]?.apiResponse?.Message?.length > 0 ? (
          // -----------------table loading style start---------------
          showTable ? (
            <div>
              <SalesInvoiceTable
                filterprinting={filterprinting}
                filterdropdownprint={filterdropdown}
                filterDictionary={filterDictionary}
                setFilterDictionary={setFilterDictionary}
                fastPath={fastPath}
                dropdownrecno={recno}
              />
            </div>
          ) : (
            <div style={{ ...loaderStyle }}>
              <Spin size="large" />
            </div>
          )
        ) : // -----------------table loading style end---------------

        // {start Pradeep,task-id-272 start }
        tabStore.state.tabs[pageIndex]?.apiResponse?.Message?.length == 0 ? (
          <div className="noDataFound">
            <h1>Whoops! No Records Found</h1>
            <img src={noData} />
          </div>
        ) : null}
      </div>
    </div>
    // {end        end}
  );
};

export default MobileUserPermission;

const loaderStyle = {
  height: "max-content",
  width: "max-content",
  display: "flex",
  alignContent: "center",
  margin: "50px auto",
};

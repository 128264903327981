//~# Majid Sayyed - 02/03/2023
//~* Sales Order : Table

import React, { useEffect, useState } from "react";
import { poColumns } from "./SalesOrderBillingTableColumns";
import { Table, Spin } from "antd";
import useSaveChangedRows from "../../CommonControllers/Hooks/useSaveChangedRows";

const ItemsTable = ({ rowData, setRowData, igstappl, pageDetails }) => {
  const [showTable, setShowTable] = useState(true);
  const useChangedRows = useSaveChangedRows(); 
  console.log(`Rajendra-----`,igstappl);
  useEffect(() => {
 
    setShowTable(false);

    setTimeout(() => {
      setShowTable(true);
    }, 100);
  }, [rowData?.length]);

  /////validation/////
  useEffect(() => {
    let isButtonDisabled = false;
    
    for (let i = 0; i < rowData.length; i++) {
      const { packingqty, packingextraqty, rate } = rowData[i];
      
      if ((packingqty === "0" && packingextraqty === "0") || (packingqty == "" && packingextraqty == "") || (rate == 0 || rate == "")||(packingqty === 0 && packingextraqty === "0")||(packingqty === "0" && packingextraqty === 0)||(packingqty === 0 && packingextraqty === 0)) {
  
        isButtonDisabled = true;
        console.log("isButtonDisabled:122", isButtonDisabled);
        break;
      }
      console.log("isButtonDisabled:",  rowData[0]);
    }
  
    useChangedRows.updateValue("validated", !isButtonDisabled ? "true" : "false");
  }, [rowData]);
  
  

  return (
    <>
      <div>
        {showTable ? (
          <Table
            scroll={{
              y: 305,
            }}
            size="small"
            bordered
            className="tr012-salesOrder-itemsTable"
            pagination={false}
            columns={poColumns(igstappl, setRowData, rowData, pageDetails)?.filter((item) => !item.hidden)}
            dataSource={rowData}
            onChange={(e) => {
              console.log("ONCHANGE HANDLE", e);
            }}
            summary={(pageData) => {
              let quantity = 0;
              let taxableAmount = 0;
              let cgstAmount = 0;
              let sgstAmount = 0;
              let igstAmount = 0;
              let totalAmount = 0;
              let boxTotal = 0;
              let rateTotal = 0;
              pageData.forEach(
                ({ packingqty, taxableamount, cgstamt, sgstamt, igstamt, amount, packingextraqty, purrate }) => {
                  quantity += Number(packingqty);
                  taxableAmount += Number(taxableamount);
                  cgstAmount += Number(cgstamt);
                  sgstAmount += Number(sgstamt);
                  igstAmount += Number(igstamt);
                  totalAmount += Number(amount);
                  boxTotal += Number(packingextraqty);
                  rateTotal += Number(purrate);
                }
              );
              return (
                <Table.Summary fixed>
                  {!igstappl ? (
                    <Table.Summary.Row>
                      <Table.Summary.Cell >
                        {null}
                      </Table.Summary.Cell>
                      <Table.Summary.Cell ><p style={{ ...pStyles, textAlign: "left" }}>Total</p></Table.Summary.Cell>
                      {
                        pageDetails?.wantpackingextra ? <Table.Summary.Cell index={2}>
                        <p style={{ ...pStyles }}>{boxTotal}</p>
                      </Table.Summary.Cell> : null
                      }
                      
                      <Table.Summary.Cell >
                        <p style={{ ...pStyles }}>{quantity}</p>
                      </Table.Summary.Cell>
                      {pageDetails?.wantpackingextra ? (
                        <Table.Summary.Cell>
                          <p style={{ ...pStyles }}>{null}</p>
                        </Table.Summary.Cell>
                      ) : null}
                      <Table.Summary.Cell >
                        <p style={{ ...pStyles }}></p>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell >
                        <p style={{ ...pStyles }}>{Number(taxableAmount).toFixed(2)}</p>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell >{null}</Table.Summary.Cell>
                      <Table.Summary.Cell >
                        <p style={{ ...pStyles }}>{Number(cgstAmount).toFixed(2)}</p>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell >{null}</Table.Summary.Cell>
                      <Table.Summary.Cell >
                        <p style={{ ...pStyles }}>{Number(sgstAmount).toFixed(2)}</p>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell >
                        <p style={{ ...pStyles }}>{Number(totalAmount).toFixed(2)}</p>
                      </Table.Summary.Cell>
                    </Table.Summary.Row>
                  ) : (
                    <Table.Summary.Row>
                      <Table.Summary.Cell>{null}</Table.Summary.Cell>
                      <Table.Summary.Cell><p style={{ ...pStyles, textAlign: "left" }}>Total</p></Table.Summary.Cell>
                      {
                        pageDetails?.wantpackingextra ? <Table.Summary.Cell index={2}>
                        <p style={{ ...pStyles }}>{boxTotal}</p>
                      </Table.Summary.Cell> : null
                      }
                      <Table.Summary.Cell>
                        <p style={{ ...pStyles }}>{quantity}</p>
                      </Table.Summary.Cell>
                      {pageDetails?.wantpackingextra ? (
                        <Table.Summary.Cell>
                          <p style={{ ...pStyles }}>{null}</p>
                        </Table.Summary.Cell>
                      ) : null}
                      <Table.Summary.Cell>
                        <p style={{ ...pStyles }}></p>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell>
                        <p style={{ ...pStyles }}>{Number(taxableAmount).toFixed(2)}</p>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell>{null}</Table.Summary.Cell>
                      <Table.Summary.Cell>
                        <p style={{ ...pStyles }}>{Number(igstAmount).toFixed(2)}</p>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell>
                        <p style={{ ...pStyles }}>{Number(totalAmount).toFixed(2)}</p>
                      </Table.Summary.Cell>
                    </Table.Summary.Row>
                  )}
                </Table.Summary>
              );
            }}
          />
        ) : (
          <div style={{ ...loaderStyle }}>
            <Spin size="large" />
          </div>
        )}
      </div>
    </>
  );
};

export default ItemsTable;

const loaderStyle = {
  height: "max-content",
  width: "max-content",
  display: "flex",
  alignContent: "center",
  margin: "50px auto",
};

const pStyles = {
  textAlign: "right",
  fontWeight: "700",
  fontSize: "16px",
  color: "black",
};

import { Button, Popconfirm } from "antd";
import { AiFillDelete } from "react-icons/ai";
import CallbackDateInput from "../../CommonControllers/Components/CallbackComponents/CallbackDateInput";
import { removeByAttribute } from "../../CommonControllers/AppFunction";


export const piColumns = (igstappl, setRowData, rowData, scandata, setScandata, setOpenItem, dcDate, pageDetails,change) => {
  //console.log("RRRRR",scandata)
  if (igstappl) {
    return [
      {
        title: " ",
        dataIndex: "active",
        key: "active",
        width: "50px",
        render: (_, text) => {
          return (
            <Popconfirm
              title="Do you want to Cancel the changes?"
              onConfirm={(e) => {
                handleRemove(text, setRowData, rowData);
              }}
            >
              <Button icon={<AiFillDelete />}></Button>
            </Popconfirm>
          );
        },
      },
    //{/* Pradeep task id -398  */} added here  Item Description
      {
        width: 200,
        title: "Item Description",
        dataIndex: "itemdescn",
        key: "itemdescn",
      },

      {
        hidden: !pageDetails?.wantpackingextra ? true : false,
        title: "Box Qty",
        width: 70,
        dataIndex: "packingextraqty",
        key: "packingextraqty",
        align: "right", // nohit-388 set Align for column
        render: (_, text) => {
          return (
            <input
              style={{ width: "50px" }}
              disabled={text.packingextrarecno > 0 ? false : true}
              defaultValue={text.packingextraqty}
              onFocus={(e) => e.target.select()}
              //{start Pradeep task-id-dc start }  added validation on box input field
              value={text.packingextraqty}
              onChange={(e) => {
                const regex = /^[0-9\b]+$/;

                // console.log("e.target.value", e.target.value)
                // console.log("conversionfactor11", e.target.value, text.conversionfactor, text.itembatcharray.length)

                //old validation on box and piece
                if (e.target.value === "" || regex.test(e.target.value)) {
                  text.packingextraqty = e.target.value;
                  //{start Pradeep, task id-238 }
                  handleChange(text, setRowData, igstappl, rowData, scandata, setScandata);

                }

                //{start Pradeep, task id-238 }
                //{Pradeep, task id-334 }
                else if ((e.target.value) == "0" && (text.conversionfactor = 12)) {
                  console.log("hiiiop")
                  text.packingextraqty = text.itembatcharray.length / text.conversionfactor || 1;
                  handleChange(text, setRowData, igstappl, rowData, scandata, setScandata);
                  //{END         END }

                } else {
                  // console.log("1111113")
                  alert("Invalid Input");
                  e.target.value = "";
                  text.packingextraqty = e.target.value;
                  //{start Pradeep, task id-238 }
                  handleChange(text, setRowData, igstappl, rowData, scandata, setScandata);
                }

                //new validation of zero qty

                // if (regex.test(e.target.value) && (Number(e.target.value) + Number(text.packingqty)) > 0) {
                //   text.packingextraqty = e.target.value;
                //   handleChange(text, setRowData, igstappl, rowData);
                // } else {
                //   e.target.value = text.packingextraqty
                //   alert("Box and Pieces cannot be 0")
                // }


              }}
              onBlur={(e) => {
                //{start Pradeep, task id-238 start}
                //{Pradeep, task id-334 changes conversion factor to 12 }
                if ((e.target.value) == "0" && text.conversionfactor == 12) {
                  if ((Number(text.free) == 0)) {

                    let count = Number(text.packingqty) + Number((text.freepackingextraqty * (text.conversionfactor || 1))) + Number(text.freepackingqty)

                    console.log("count", count)
                    if (count >= text?.itembatcharray?.length) {

                      text.packingextraqty = 0
                    } else {
                      alert(
                        "Quantity cannot be less than scanned Quantity,\n First Remove Items from Scanned List"
                      )
                      // console.log("1234",text.itembatcharray.length -count)
                      text.packingextraqty = ((text.itembatcharray.length) - (count)) / (text.conversionfactor);
                      // console.log("12345", text.freepackingextraqty)
                    }
                    handleChange(text, setRowData, igstappl, rowData, scandata, setScandata);


                  }
                  else {
                    e.target.value =
                      text?.itembatcharray?.length -
                      (Number(text?.packingqty) + Number(text?.free));
                    console.log("e.target.value", e.target.value)

                    if (e.target.value > 0) {

                      text.packingextraqty = Number(e.target.value)
                    } else {
                      text.packingextraqty = 0
                    }


                    console.log("text.packingextraqty", text.packingextraqty)
                    handleChange(text, setRowData, igstappl, rowData, scandata, setScandata);
                  }
                }

                //{END         END }

                // Validate the Entered Quantity with the new Entered Quantity
                if (
                  //{start Pradeep, task id-238 start}
                  //{Pradeep, task id-334 changes conversion factor to 1 }
                  (Number(text?.qty) + Number(text?.free) <
                    text?.itembatcharray?.length) && (text.conversionfactor == 1)
                  //{END         END }
                ) {
                  e.target.value =
                    text?.itembatcharray?.length -
                    (Number(text?.packingqty) + Number(text?.free));
                  console.log("e.target.value", e.target.value)

                  alert(
                    "Quantity cannot be less than scanned Quantity,\n First Remove Items from Scanned List"
                  );
                  text.packingextraqty = Number(e.target.value)
                  console.log("text.packingextraqty", text.packingextraqty)
                  handleChange(text, setRowData, igstappl, rowData, scandata, setScandata);
                }

                // Validate the Entered Quantity with the new Entered Quantity

                if (text?.wantbatchno === 1) {
                  console.log("wantbatchno?.qty", text?.qty, text?.free);
                  if (Number(text?.qty) + Number(text?.free) == 0) {
                    alert("Quantity cannot be less than or equal to zero.");
                  }
                }
              }}
            //{end                       end}
            ></input>
          );
        },
      },

      {
        title: "Pcs Qty",
        width: 70,
        dataIndex: "packingqty",
        key: "packingqty",
        align: "right", // nohit-388 set Align for column
        render: (_, text) => {
          return (
            <input
              style={{ width: "50px" }}
              //disabled={text?.packingextrarecno==0? false:true}
              defaultValue={text.packingqty}
              onFocus={(e) => e.target.select()}
              onChange={(e) => {
                if (e.target.value < 0) {
                  // document.querySelector(".tr001IgstBox").value = null;
                  e.target.value = null;
                  alert("Invalid Input, please re-enter non-negative value");
                  return false;
                }
                text.packingqty = e.target.value;
                handleChange(text, setRowData, igstappl, rowData);
              }}
              onBlur={(e) => {
                // Validate the Entered Quantity with the new Entered Quantity
                if (Number(text?.qty) + Number(text?.free) < text?.itembatcharray?.length) {
                  e.target.value = text?.itembatcharray?.length - (Number(text?.packingextraqty) + Number(text?.free))

                  text.packingqty = e.target.value;
                  handleChange(text, setRowData, igstappl, rowData);

                }
              }}
            ></input>
          );
        },
      },
      {
        width: 80,
        title: "Qty",
        dataIndex: "qty",
        key: "qty",
        align: "right", // nohit-388 set Align for column
      },
      {hidden:
        pageDetails?.wantfreeqty && !pageDetails?.wantpackingextra
          ? false
          : true,
        width: 120,
        title: "Free Box Qty",
        dataIndex: "freepackingextraqty",
        key: "freepackingextraqty",
        align: "right", // nohit-388 set Align for column
        render: (_, text) => {
          return (
            <input
              className="tr001Input"
              type={"number"}
              disabled={text.packingextrarecno > 0 ? false : true}
              defaultValue={text.freepackingextraqty}
              onFocus={(e) => e.target.select()}
              onChange={(e) => {
                //{start Pradeep task-id-dc start }  added validation on Free Box Qty input field
                const regex = /^[0-9\b]+$/;
                if (e.target.value === "" || regex.test(e.target.value)) {
                  text.freepackingextraqty = e.target.value;
                  // { Pradeep, task id-238 }
                  handleChange(text, setRowData, igstappl, rowData, scandata, setScandata);
                }
                //{Pradeep, task id-334 changes conversion factor to 12 }
                else if ((e.target.value) === "0" && (text.conversionfactor == 12)) {
                  console.log("hiiis")
                  text.freepackingextraqty = text.itembatcharray.length / text.conversionfactor || 1;
                  handleChange(text, setRowData, igstappl, rowData, scandata, setScandata);
                  //{END         END }

                }
                else {
                  alert("Invalid Input");
                  e.target.value = "";
                  text.freepackingextraqty = e.target.value;
                  // { Pradeep, task id-238 }
                  handleChange(text, setRowData, igstappl, rowData, scandata, setScandata);
                }
              }}
              onBlur={(e) => {

                // {start  Pradeep, task id-238  start}
                if ((e.target.value) == "0" && text.conversionfactor == 12) {
                  if ((Number(text.qty) == 0)) {


                    let count = Number(text.packingqty) + Number((text.packingextraqty * (text.conversionfactor || 1))) + Number(text.freepackingqty)

                    console.log("count", count)
                    if (count >= text.itembatcharray.length) {

                      text.freepackingextraqty = 0
                    } else {
                      alert(
                        "Quantity cannot be less than scanned Quantity,\n First Remove Items from Scanned List"
                      )
                      // console.log("1234",text.itembatcharray.length -count)
                      text.freepackingextraqty = ((text.itembatcharray.length) - (count)) / (text.conversionfactor);
                      // console.log("12345", text.freepackingextraqty)
                    }
                    handleChange(text, setRowData, igstappl, rowData, scandata, setScandata);
                  }
                  else {
                    e.target.value =
                      text?.itembatcharray?.length -
                      (Number(text?.qty) + Number(text?.freepackingqty));
                    if (e.target.value > 0) {

                      text.freepackingextraqty = Number(e.target.value)
                    } else {
                      text.freepackingextraqty = 0
                    }

                    // { Pradeep, task id-238 }
                    handleChange(text, setRowData, igstappl, rowData, scandata, setScandata);
                  }
                }
                //{ end       end }

                // Validate the Entered Quantity with the new Entered Quantity
                if (
                  Number(text?.qty) + Number(text?.free) <
                  // { Pradeep, task id-238 }
                  //{Pradeep, task id-334 changes conversion factor to 1 }
                  text?.itembatcharray?.length && (text.conversionfactor == 1)
                ) {
                  e.target.value =
                    text?.itembatcharray?.length -
                    (Number(text?.qty) + Number(text?.freepackingqty));
                  alert(
                    "Quantity cannot be less than scanned Quantity,\n First Remove Items from Scanned List"
                  );
                  text.freepackingextraqty = e.target.value;
                  // { Pradeep, task id-238 }
                  handleChange(text, setRowData, igstappl, rowData, scandata, setScandata);
                }

                if (text?.wantbatchno === 1) {
                  console.log("wantbatchno?.qty", text?.qty, text?.free);
                  if (Number(text?.qty) + Number(text?.free) == 0) {
                    alert("Quantity cannot be less than or equal to zero.");
                  }
                }
                //End                       End}
              }}
            ></input>
          );
        },
      },
      { hidden: pageDetails?.wantfreeqty ? false : true,
        width: 120,
        title: "Free Pcs Qty",
        dataIndex: "freepackingqty",
        key: "freepackingqty",
        align: "right", // nohit-388 set Align for column
        render: (_, text) => {
          return (
            <input
              className="tr001Input"
              type={"number"}
              disabled={text.packingextrarecno == 0 ? false : true}
              defaultValue={text.freepackingqty}
              onFocus={(e) => e.target.select()}
              onChange={(e) => {
                text.freepackingqty = (e.target.value);
                handleChange(text, setRowData, igstappl, rowData);
              }}
              onBlur={(e) => {
                // Validate the Entered Quantity with the new Entered Quantity
                if (Number(text?.qty) + Number(text?.free) < text?.itembatcharray?.length) {
                  e.target.value = text?.itembatcharray?.length - (Number(text?.qty) + Number(text?.freepackingextraqty))

                  text.freepackingqty = e.target.value;
                  handleChange(text, setRowData, igstappl, rowData);

                }
              }}
            ></input>
          );
        },
      },
      {
        width: 80,
        title: "Free Qty",
        dataIndex: "freeqty",
        key: "freeqty",
        align: "right", // nohit-388 set Align for column
      },
      {
        hidden: pageDetails?.wantbatchno ? false : true,
        width: 200,
        title: "Batch No",
        dataIndex: "itembatchno",
        key: "itembatchno",
        align: "left", // nohit-388 set Align for column
        render: (_, text) => {
          return (
            <input
              style={{ width: "100px" }}
              onFocus={(e) => e.target.select()}
              defaultValue={text.itembatchno}
              onChange={(e) => {
                text.itembatchno = e.target.value;
                handleChange(text, setRowData, false, rowData);
              }}

            ></input>
          );
        },
      },
      {
        hidden: pageDetails?.wantmanudate === 1 ? false : true,
        width: 150,
        title: "Mfg. Date",
        dataIndex: "manufacturedate",
        key: "manufacturedate",
        align: "left", // nohit-388 set Align for column
        render: (_, text) => {
          console.log("text", text);
          return (
            <div
              style={{
                border: "1px dashed #dddd",
                padding: "5px",
                borderRadius: "5px",
              }}
            >
              <CallbackDateInput
                defaultValue={typeof text.manufacturedate == "number" ? text.manufacturedate.toString() : text.manufacturedate}
                id={"po-manfacturedate-" + text.shortguid}
                apiKey={"tr014-mfg-date"}
                returnValue={(e) => {
                  text.manufacturedate = e;
                  handleChange(text, setRowData, false, rowData);
                }}
                initial
                dateLessthan={dcDate}
              />
            </div>
          );
        },
      },
      {
        hidden: pageDetails?.wantexpdate === 1 ? false : true,
        width: 300,
        title: "Expiry Date",
        dataIndex: "expdate",
        key: "expdate",
        align: "left", // nohit-388 set Align for column
        render: (_, text) => {
          console.log("Expiry Date", text)
          return (
            <div
              style={{
                border: "1px dashed #dddd",
                padding: "5px",
                borderRadius: "5px",
              }}
            >
              <CallbackDateInput
                defaultValue={typeof text.expdate == "number" ? text.expdate.toString() : text.expdate}
                id={"po-expdate-" + text.shortguid}
                apiKey={"expecteddeliverydate"}
                returnValue={(e) => {
                  text.expdate = e;
                  handleChange(text, setRowData, false, rowData);
                }}
              />
            </div>
          );
        },
      },
      {
        width: 80,
        title: "Pur Rate",
        dataIndex: "purrate",
        key: "purrate",
        align: "right", // nohit-388 set Align for column
        render: (_, text) => {
          return (
            <input
              style={{ width: "60px" }}
              defaultValue={text.purrate}
              onFocus={(e) => e.target.select()}
              onChange={(e) => {
                text.purrate = e.target.value;
                handleChange(text, setRowData, false, rowData);
              }}
            ></input>
          );
        },
      },
      {
        width: 80,
        title: "Discount",
        dataIndex: "discountamt",
        key: "discountamt",
        align: "right", // nohit-388 set Align for column
        render: (_, text) => {
          return (
            <input
              style={{ width: "60px" }}
              defaultValue={text.discountamt}
              onFocus={(e) => e.target.select()}
              onChange={(e) => {
                text.discountamt = e.target.value;
                handleChange(text, setRowData, false, rowData);
              }}
            ></input>
          );
        },
      },
      {
        width: 90,
        title: "Discount %",
        dataIndex: "discount",
        key: "discount",
        align: "right", // nohit-388 set Align for column
        render: (_, text) => {
          return (
            <input
              style={{ width: "60px" }}
              defaultValue={text?.discount}
              onFocus={(e) => e.target.select()}
              onChange={(e) => {
                text.discount = e.target.value;
                handleChange(text, setRowData, false, rowData);
              }}
              value={text.discount}
            ></input>
          );
        },
      },
      {
        width: 100,
        title: "Amount",
        dataIndex: "taxableamount",
        key: "taxableamount",
        align: "right", // nohit-388 set Align for column
      },
      {
        width: 80,
        title: "IGST%",
        dataIndex: "igstrate",
        key: "igstrate",
        align: "right", // nohit-388 set Align for column
      },
      {
        width: 80,
        title: "IGST",
        dataIndex: "igstamt",
        key: "igstamt",
        align: "right", // nohit-388 set Align for column 
      },
      {
        width: 80,
        title: "Amount",
        dataIndex: "amount",
        key: "amount",
        align: "right", // nohit-388 set Align for column
      },
      {
        width: 150,
        title: "Scanned Items",
        dataIndex: "scannedItems",
        align: "left", // nohit-388 set Align for column
        render: (_, data) => (
          <p>{data?.itembatcharray?.length}</p>
        )
      },
      {
        title: " Barcode",
        dataIndex: "barcode",
        align: "left", // nohit-388 set Align for column
        key: "barcode",
        width: "7%",
        render: (_, text) => {
          console.log("Text is", text);
          if (text.itemasbatch) {
            return (
              <Button
                onClick={() => {
                  setOpenItem(true);

                  setScandata(text);
                }}
              >
                Item Scan
              </Button>
            );
          } else return null;
        },
      },
    ];
  } else {
    return [
      {
        title: " ",
        dataIndex: "active",
        key: "active",
        width: "50px",
        render: (_, text) => {
          return (
            <Popconfirm
              title="Do you want to Cancel the changes?"
              onConfirm={(e) => {
                handleRemove(text, setRowData, rowData);
              }}
            >
              <Button icon={<AiFillDelete />}></Button>
            </Popconfirm>
          );
        },
      },
   //{/* Pradeep task id -398  */} added here  Item Description
      {
        width: 200,
        title: "Item Description",
        dataIndex: "itemdescn",
        key: "itemdescn",
      },
      {
        width: 50,
        title: "Scanned Items",
        dataIndex: "scannedItems",
        align: "left", // nohit-388 set Align for column
        render: (_, data) => (
          // console.log("dc data", data)
          <p>{data?.itembatcharray?.length || data?.batcharray?.length}</p>
          //  <p>{data?.batcharray ?.length}</p>
          //  <p>2</p>
        )
      },
      {
        title: "Scan Barcode",
        dataIndex: "barcode",
        align: "left", // nohit-388 set Align for column
        key: "barcode",
        width: 100,
        render: (_, text) => {
          console.log("Text is", text);
          if (text.itemasbatch) {
            return (
              <Button
                onClick={() => {
                  setOpenItem(true);

                  setScandata(text);
                }}
              >
                Item Scan
              </Button>
            );
          } else return null;
        },
      },
      {
        hidden: !pageDetails?.wantpackingextra ? true : false,
        title: "Boxes",
        width: 70,
        dataIndex: "packingextraqty",
        key: "packingextraqty",
        align: "right", // nohit-388 set Align for column
        render: (_, text) => {
          console.log("text in dc", text)
          return (
            <input
              style={{ width: "50px" }}
              defaultValue={text.packingextraqty}
              onFocus={(e) => e.target.select()}
              value={text.packingextraqty}
              disabled={text.packingextrarecno > 0 ? false : true}
              onChange={(e) => {
                if (e.target.value < 0) {
                  e.target.value = null;
                  return false;
                }
                const regex = /^[0-9\b]+$/;
                // if (regex.test(e.target.value) && (Number(e.target.value) + Number(text.packingextraqty)) > 0) 
                //{start Pradeep task-id-dc start }  added validation on Boxes input field
                if (e.target.value === "" || regex.test(e.target.value)) {
                  text.packingextraqty = e.target.value;
                  //{start Pradeep, task id-238 }
                  handleChange(text, setRowData, igstappl, rowData, scandata, setScandata);

                }

                //{start Pradeep, task id-238 }
                //{Pradeep task id -255}
                else if ((e.target.value) == "0" && (text.conversionfactor == 12)) {
                  console.log("hiiiop")
                  text.packingextraqty = text.itembatcharray.length / text.conversionfactor || 1;
                  handleChange(text, setRowData, igstappl, rowData, scandata, setScandata);
                  //{END         END }

                } else {
                  // console.log("1111113")
                  alert("Invalid Input");
                  e.target.value = "";
                  text.packingextraqty = e.target.value;
                  //{start Pradeep, task id-238 }
                  handleChange(text, setRowData, igstappl, rowData, scandata, setScandata);
                }

              }}
              onBlur={(e) => {
                //{Pradeep task id -255}
                if ((e.target.value) == "0" && text.conversionfactor == 12) {
                  if ((Number(text.qty) == 0)) {

                    let count = Number(text.packingqty) + Number((text.packingextraqty * (text.conversionfactor || 1)))

                    console.log("count", count)
                    if (count >= text.itembatcharray.length) {

                      text.packingextraqty = 0
                    } else {
                      alert(
                        "Quantity cannot be less than scanned Quantity,\n First Remove Items from Scanned List"
                      )
                      // console.log("1234",text.itembatcharray.length -count)
                      text.packingextraqty = ((text.itembatcharray.length) - (count)) / (text.conversionfactor);
                      // console.log("12345", text.freepackingextraqty)
                    }
                    handleChange(text, setRowData, igstappl, rowData, scandata, setScandata);


                  }

                  else {
                    e.target.value =
                      text?.itembatcharray?.length -
                      (Number(text?.packingqty));
                    console.log("e.target.value", e.target.value)

                    if (e.target.value > 0 && (Number(text?.packingqty) == 0)) {
                      console.log("pradeep")
                      text.packingextraqty = Number(e.target.value)
                    }
                    else if ((e.target.value > 0) && (Number(text?.packingqty) != 0)) {
                      text.packingextraqty = text?.itembatcharray?.length / (text.conversionfactor)
                      alert(
                        "Quantity cannot be less than scanned Quantity,\n First Remove Items from Scanned List"
                      )
                    }
                    else {
                      text.packingextraqty = 0
                    }


                    console.log("text.packingextraqty", text.packingextraqty)
                    handleChange(text, setRowData, igstappl, rowData, scandata, setScandata);
                  }
                }

                //{END         END }

                // Validate the Entered Quantity with the new Entered Quantity
                if (
                  //{start Pradeep, task id-238 start}
                  //{Pradeep task id -255}
                  (Number(text?.qty) <
                    text?.itembatcharray?.length) && (text.conversionfactor == 1)
                  //{END         END }
                ) {
                  e.target.value =
                    text?.itembatcharray?.length -
                    (Number(text?.packingqty));
                  console.log("e.target.value", e.target.value)

                  alert(
                    "Quantity cannot be less than scanned Quantity,\n First Remove Items from Scanned List"
                  );
                  text.packingextraqty = Number(e.target.value)
                  console.log("text.packingextraqty", text.packingextraqty)
                  handleChange(text, setRowData, igstappl, rowData, scandata, setScandata);
                }

                // Validate the Entered Quantity with the new Entered Quantity

                if (text?.wantbatchno === 1) {
                  console.log("wantbatchno?.qty", text?.qty, text?.free);
                  if (Number(text?.qty) + Number(text?.free) == 0) {
                    alert("Quantity cannot be less than or equal to zero.");
                  }
                  //End                       End} 
                }
              }}
            ></input>
          );
        },
      },

      {
        title: "Pieces",
        width: 70,
        dataIndex: "packingqty",
        key: "packingqty",
        align: "right", // nohit-388 set Align for column
        render: (_, text) => {
          return (
            <input
              style={{ width: "50px" }}
              //disabled={text?.packingextrarecno==0? false:true}
              key={change}
              defaultValue={text.packingqty}
              onFocus={(e) => e.target.select()}
              onChange={(e) => {
                // if (e.target.value < 0) {
                //   // document.querySelector(".tr001IgstBox").value = null;
                //   e.target.value = null;
                //   alert("Invalid Input, please re-enter non-negative value");
                //   return false;
                // }
                // text.packingqty = e.target.value;
                // handleChange(text, setRowData, igstappl, rowData);

                const regex = /^[0-9\b]+$/;

                if (regex.test(e.target.value) && (Number(e.target.value) + Number(text.packingextraqty)) > 0) {

                  // task id -333 rajendra start

                  if (Number(e.target.value) < text?.batcharray?.length) {

                    alert("Quantity Should not be less than scannned qty")
                    e.target.value = text?.packingqty
                    console.log("Rajendra", text?.packingqty)
                    handleChange(text, setRowData, igstappl, rowData);
                  }
                  else {
                    text.packingqty = e.target.value;
                    handleChange(text, setRowData, igstappl, rowData);
                  }
                  // task id -333 rajendra end /////
                } else {

                  e.target.value = text.packingqty
                  alert("Box and Pieces cannot be 0")
                }
              }}
              onBlur={(e) => {
                // Validate the Entered Quantity with the new Entered Quantity


                if (Number(text?.qty) < text?.itembatcharray?.length) {
                  e.target.value = text?.itembatcharray?.length - (Number(text?.packingextraqty))
                  alert(
                    "Quantity cannot be less than scanned Quantity,\n First Remove Items from Scanned List"
                  );
                  text.packingqty = e.target.value;
                  handleChange(text, setRowData, igstappl, rowData);

                }
              }}
            ></input>
          );
        },
      },
      {
        width: 80,
        title: "Qty",
        dataIndex: "qty",
        key: "qty",
        align: "right", // nohit-388 set Align for column
      },
      ///{Pradeep , task id-337}  commented this freebox and free pieces Qty
      ///{Pradeep , task id-332}  added input box for freebox Qty and Free pieces Qty 
      // {
      //   width: 120,
      //   title: "Free Box Qty",
      //   dataIndex: "freepackingextraqty",
      //   key: "freepackingextraqty",
      //   render: (_, text) => {
      //     return (
      //       <input
      //         className="tr001Input"
      //         type={"number"}
      //         defaultValue={text.freepackingextraqty}
      //         disabled={text.packingextrarecno>0? false:true}
      //         onFocus={(e) => e.target.select()}
      //         onChange={(e) => {
      //           text.freepackingextraqty = e.target.value;
      //           handleChange(text, setRowData, igstappl, rowData);
      //         }}
      //         onBlur={(e) => {
      //           // Validate the Entered Quantity with the new Entered Quantity
      //           if (Number(text?.qty) + Number(text?.free) < text?.itembatcharray?.length) {
      //             e.target.value = text?.itembatcharray?.length - (Number(text?.qty) + Number(text?.free))

      //             text.freepackingqty = e.target.value;
      //             handleChange(text, setRowData, igstappl, rowData);

      //           }
      //         }}
      //       ></input>
      //     );
      //   },
      // },
      // {
      //   width: 120,
      //   title: "Free Pcs Qty",
      //   dataIndex: "freepackingqty",
      //   key: "freepackingqty",
      //   render: (_, text) => {
      //     return (
      //       <input
      //         className="tr001Input"
      //         type={"number"}
      //         disabled={text.packingextrarecno==0? false:true}
      //         defaultValue={text.freepackingqty}
      //         onFocus={(e) => e.target.select()}
      //         onChange={(e) => {
      //           text.freepackingqty = (e.target.value);
      //           handleChange(text, setRowData, igstappl, rowData);
      //         }}
      //         onBlur={(e) => {
      //           // Validate the Entered Quantity with the new Entered Quantity
      //           if (Number(text?.qty) + Number(text?.free) < text?.itembatcharray?.length) {
      //             e.target.value = text?.itembatcharray?.length - (Number(text?.qty) + Number(text?.free))
      //             text.freepackingqty = e.target.value;
      //             handleChange(text, setRowData, igstappl, rowData);
      //           }
      //         }}
      //       ></input>
      //     );
      //   },
      // },
      // {
      //   width: 80,
      //   title: "Free Qty",
      //   dataIndex: "freeqty",
      //   key: "freeqty",
      // },
      //{  end                 end }
    
      {
        hidden: pageDetails?.wantbatchno ? false : true,
        width: 150,
        title: "Batch No",
        dataIndex: "itembatchno",
        key: "itembatchno",
        align: "left", // nohit-388 set Align for column
        render: (_, text) => {
          return (
            <input
              style={{ width: "180px" }}
              onFocus={(e) => e.target.select()}
              defaultValue={text.itembatchno}
              onChange={(e) => {
                text.itembatchno = e.target.value;
                handleChange(text, setRowData, false, rowData);
              }}
            ></input>
          );
        },
      },
      {
        hidden: pageDetails?.wantmanudate === 1 ? false : true,
        width: 150,
        title: "Mfg. Date",
        dataIndex: "manufacturedate",
        key: "manufacturedate",
        align: "left", // nohit-388 set Align for column
        render: (_, text) => {
          console.log("textRRRR", text);
          return (
            <div
              style={{
                border: "1px dashed #dddd",
                padding: "5px",
                borderRadius: "5px",
              }}
            >
              <CallbackDateInput
                defaultValue={typeof text.manufacturedate == "number" ? text.manufacturedate.toString() : text.manufacturedate}
                id={"po-manfacturedate-" + text.shortguid}
                apiKey={"tr014-mfg-date"}
                returnValue={(e) => {
                  text.manufacturedate = e;
                  handleChange(text, setRowData, false, rowData);
                }}
                initial
                dateLessthan={dcDate}
              />
            </div>
          );
        },
      },
      {
        hidden: pageDetails?.wantexpdate === 1 ? false : true,
        width: 150,
        title: "Expiry Date",
        dataIndex: "expdate",
        key: "expdate",
        align: "left", // nohit-388 set Align for column
        render: (_, text) => {
          return (
            <div
              style={{
                border: "1px dashed #dddd",
                padding: "5px",
                borderRadius: "5px",
              }}
            >
              <CallbackDateInput
                defaultValue={typeof text.expdate == "number" ? text.expdate.toString() : text.expdate}
                id={"po-expdate-" + text.shortguid}
                apiKey={"tr014-exp-date"}
                returnValue={(e) => {
                  text.expdate = e;
                  handleChange(text, setRowData, false, rowData);
                }}

              />
            </div>
          );
        },
      },
     
    ];
  }
};

export const handleAdd = (
  item,
  setRowData,
  igstappl,
  setSelectedItem,
  shortguid,
  salerate,
  purchaseQty,
  box,
  freeBox,
  freeQty,
  batchNo,
  expDate,
  discountAmt,
  discountPer,
  manuDate
) => {
  var newShortguid = shortguid.getShortguid("DCF" + item.itemrecno + batchNo);
  console.log("Added Shortguid", item);
  var purrate = salerate ? salerate : 0.0;
  var cgstrate = item?.cgstrate ? item.cgstrate : 0.0;
  var sgstrate = item?.sgstrate ? item.sgstrate : 0.0;
  var igstrate = item?.igstrate ? item.igstrate : 0.0;
  let conversionfactor = item?.conversionfactor ? item.conversionfactor : 1;
  var packingqty = purchaseQty ? purchaseQty : 0;
  var packingextraqty = box ? box : 0;
  var freepackingextraqty = freeBox ? freeBox : 0;
  var freepackingqty = freeQty ? freeQty : 0;
  var free = Number(freepackingqty) + Number(freepackingextraqty) * Number(conversionfactor);

  var qty = Number(packingqty) + Number(packingextraqty) * Number(conversionfactor);
  console.log("Quantity", qty, conversionfactor);
  var discount = discountPer ? Number(discountPer) : 0.0;
  var discountamt = discountAmt ? Number(discountAmt) : 0.0;
  var expdate = expDate ? expDate : null;
  // var manufacturedate = manufacturedate ? manufacturedate : null;
  //accepting data from item batch
  var manufacturedate = manufacturedate ? manufacturedate : (manuDate ? manuDate : null);

  var itembatchno = batchNo ? batchNo : null;

  var taxableamount = qty * (salerate - discountamt);
  var sgstamt = !igstappl ? taxableamount * (sgstrate / 100) : 0.0;
  var cgstamt = !igstappl ? taxableamount * (cgstrate / 100) : 0.0;
  var igstamt = igstappl ? taxableamount * (igstrate / 100) : 0.0;



  var amount = igstappl ? taxableamount + igstamt : taxableamount + (cgstamt + sgstamt);

  var itemObject = {
    ...item,
    shortguid: newShortguid,
    itemrecno: item.itemrecno,
    itemdescn: item.itemdescn,
    packingextraqty: packingextraqty,
    packingqty: packingqty,
    qty: qty,
    freeqty: free,
    salerate: Number(salerate).toFixed(2),
    taxableamount: Number(taxableamount).toFixed(2),
    cgstrate: Number(cgstrate).toFixed(2),
    sgstrate: Number(sgstrate).toFixed(2),
    igstrate: Number(igstrate).toFixed(2),
    cgstamt: Number(cgstamt).toFixed(2),
    sgstamt: Number(sgstamt).toFixed(2),
    igstamt: Number(igstamt).toFixed(2),
    amount: Number(amount).toFixed(2),
    discount: Number(discount).toFixed(2),
    discountamt: Number(discountamt).toFixed(2),
    expdate: expdate ? expdate : item.expdate,
    itembatchno: item?.wantbatchno ? itembatchno : ".",
    freepackingextraqty: freepackingextraqty,
    freepackingqty: freepackingqty,
    manufacturedate: manufacturedate,
  };

  setRowData((p) => [...p, itemObject]);
  setSelectedItem(null);
};

export const handleChange = (item, setRowData, igstappl, rowData, scandata, setScandata, setOpenItem) => {


  var purrate = item?.purrate ? item.purrate : 0.0;
  var cgstrate = item?.cgstrate ? item.cgstrate : 0.0;
  var sgstrate = item?.sgstrate ? item.sgstrate : 0.0;
  var igstrate = item?.igstrate ? item.igstrate : 0.0;

  let packingextraqty = item?.packingextraqty ? item?.packingextraqty : 0.0;
  let packingqty = item?.packingqty ? item.packingqty : 0.0;
  let itembatchno = item?.itembatchno ? item?.itembatchno : null;
  let expdate = item?.expdate ? item?.expdate : null;
  let conversionfactor = item?.conversionfactor ? item.conversionfactor : 1;

  let freepackingextraqty = item?.freepackingextraqty ? item?.freepackingextraqty : 0.0;
  let freepackingqty = item?.freepackingqty ? item?.freepackingqty : 0.0;
  let free = Number(freepackingqty) + Number(freepackingextraqty) * Number(conversionfactor);
  let qty = Number(packingqty) + Number(packingextraqty) * Number(conversionfactor);

  //alert(packingqty + packingextraqty + conversionfactor);

  var discount = item?.discount ? item.discount : 0.0;
  var discountamt = item?.discountamt ? item.discountamt : 0.0;
  discount = Number((discountamt / purrate) * 100).toFixed(2);
  // discountamt = Number(discount/100 * purrate)

  var taxableamount = Number(qty) * Number(Number(purrate) - Number(discountamt));

  var sgstamt = !igstappl ? taxableamount * (sgstrate / 100) : 0.0;
  var cgstamt = !igstappl ? taxableamount * (cgstrate / 100) : 0.0;
  var igstamt = igstappl ? taxableamount * (igstrate / 100) : 0.0;

  var amount = igstappl ? taxableamount + igstamt : taxableamount + (cgstamt + sgstamt);

  var itemObject = {
    ...item,
    itemrecno: item.itemrecno,
    itemdescn: item.itemdescn,
    box: +item?.box,
    purrate: +purrate,
    taxableamount: taxableamount,
    packingextraqty: packingextraqty,
    packingqty: packingqty,
    qty: qty,
    freeqty: free,
    expdate: expdate,
    itembatchno: itembatchno,
    freepackingextraqty: freepackingextraqty,
    freepackingqty: freepackingqty,
    itembatchno: item?.wantbatchno ? item.itembatchno : ".",
  };
  var currentItem = rowData.map((itm) => {
    if (itm.itemrecno == item.itemrecno) {
      return { ...itemObject };
    } else {
      return { ...itm };
    }
  });
  console.log("SeTSCAN", currentItem, item);
  setRowData(() => currentItem);

  //console.log("XYZ",item,scandata,currentItem)
};

export const handleRemove = (item, setRowData, rowData) => {
  var removedArray = removeByAttribute(rowData, "itemrecno", item.itemrecno);
  console.log("Remove", item, setRowData, rowData, removedArray);
  setRowData(() => [...removedArray]);
};

export const handleRemovePrev = (item, setRowData, rowData) => {
  var removedArray = removeByAttribute(rowData, "itemrecno", item.itemrecno);
  console.log("Remove", item, setRowData, rowData, removedArray);
  setRowData(() => [...removedArray]);
};
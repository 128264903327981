import { useState, useEffect, useContext, useRef } from "react";
import React from "react";
//import { Button, Table } from "react-bootstrap";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import { AiOutlineConsoleSql } from "react-icons/ai";
//import SaveDropDown from "../../../CommonControllers/SaveComponents/SaveDropDown";
import SaveDropDownAccounts from "./SaveDropDownAccounts";

import { Button, Radio, Space, Divider } from "antd";
import { useTransactionContext } from "./TransactionContext";
import { useTransactionDispatch } from "./TransactionContext";
import { getAccountlistURL } from "../../../CommonControllers/Urls";
import { DeleteOutlined } from "@ant-design/icons";
import PendingInsutrumentSummary from "./PendingInstrumentSummary";
import { dateFormatter } from "../../../CommonControllers/AppFunction";

import { TabStore } from "../../../TabsStructure/TabsStore";

const styles = {
  dialogPaper: {
    minHeight: "80vh",
    maxHeight: "80vh",
  },
};

function PendingInsutrumentsTransaction(props) {
  
  //console.log(props);

  const tabstore = useContext(TabStore);
  const transaction_state = useTransactionContext();
  const transaction_dispatch = useTransactionDispatch();
  //console.log("CashPendingInsutruments props",tabstore?.state?.tabs[tabstore?.state.tabIndex]?.changedRows?.VOUCHER_AMT_UPDATED);
  // console.log("transaction_state - CashPendingInsutruments");
  // console.log(JSON.stringify(transaction_state));

  const [tabledata, settabledata] = useState([]);
  const [amounttodist, setAmounttodist] = useState(0);
  const [maxamount, setmaxamount] = useState(0);
  const [open, setOpen] = useState(false);

  const [discountGLCode, setDiscountGLCode] = useState(null);
  const [discountGLName, setDiscountGLName] = useState(null);
  const [discountAmount, setDiscountAmount] = useState(null);

  useEffect(() => {
    console.log(props);
    if (props.distribute) {
      if (
        tabstore?.state?.tabs[props.pageIndex]?.changedRows?.[
          "trigger_distribute"
        ] == "YES"
      ) {
        DistributeAmount();
      }
    }
  }, [
    tabstore?.state?.tabs[props.pageIndex]?.changedRows?.[
      "trigger_distribute"
    ],
  ]);

  const handleClickOpen = (_refno) => {
    console.log("handleClickOpen refno" + _refno);
    setOpen(true);

    // check if child rows exists for pending instrument
    //
    /*
    const temp_pending_instr = transaction_state.pending_instruments.filter(
      (item) => {
        return item.refno == _refno;
      }
    );
*/

    const temp_pending_instr = tabstore?.state?.tabs[
      props.pageIndex
    ]?.changedRows?.pending_instruments?.filter((item) => {
      return item.refno == _refno;
    });

    let _inst = temp_pending_instr[0];
    //  console.log("Mandar");
    //  console.log(_inst);

    /*
    transaction_dispatch({
      type: "OPEN_GL_SETTLEMENT_POPUP",
      selected_ref_id_to_settle: _refno,
      selected_ref_instrument: _inst,
    });
*/

    tabstore.dispatch({
      type: "UPDATE_CHANGED_DATA",
      fieldType: "selected_ref_id_to_settle",
      text: _refno,
    });

    tabstore.dispatch({
      type: "UPDATE_CHANGED_DATA",
      fieldType: "selected_ref_instrument",
      text: _inst,
    });

    console.log("temp_pending_instr");
    console.log(temp_pending_instr);

    if (temp_pending_instr[0].childrows.length > 0) {
      const temp_updated_gl_list = [];

      let temp_gl_list = [];
      let temp_gl_list_blank = [];

      temp_pending_instr[0].childrows.forEach((gl_item) => {
        //console.log("gl_item handleClickOpen");
        //console.log(gl_item);
        const discount_item = {
          code: gl_item.code,
          descn: gl_item.descn,
          amount: gl_item.amount,
          gltype: "E",
        };

        temp_gl_list.push(discount_item);
      });

      /*
      transaction_dispatch({
        type: "UPDATE_GL_LIST",
        gl_list: temp_gl_list,
      });
    */
      tabstore.dispatch({
        type: "UPDATE_CHANGED_DATA",
        fieldType: "UPDATE_GL_LIST",
        text: temp_gl_list,
      });
    }
  };

  const handleClose = () => {
    transaction_dispatch({
      type: "CLOSE_GL_SETTLEMENT_POPUP",
    });

    setOpen(false);
  };

  const handleSave = () => {
    let isError = false;

    /*
    tabstore?.state?.tabs[
     props.pageIndex
    ]?.changedRows?.UPDATE_GL_LIST
*/

    //08FEB
    let temp_other_gl_amount_total = 0;

    tabstore?.state?.tabs[
      props.pageIndex
    ]?.changedRows?.UPDATE_GL_LIST.forEach((element) => {
      if (element.amount > 0) {
        temp_other_gl_amount_total =
          Number(temp_other_gl_amount_total) + Number(element.amount);
      }
    });

    const temp_item_ref_no = tabstore?.state?.tabs[
     props.pageIndex
    ]?.changedRows?.pending_instruments?.find(
      (data) =>
        data.refno ==
        tabstore?.state?.tabs[tabstore?.state.tabIndex]?.changedRows
          ?.selected_ref_id_to_settle
    );

    if (temp_item_ref_no && temp_other_gl_amount_total > 0) {
      let total_amt_adjusted =
        Number(temp_item_ref_no.on_account_amount) +
        Number(temp_other_gl_amount_total);

      if (total_amt_adjusted > temp_item_ref_no.pendingamt) {
        isError = true;
        alert("More than Pending Amt Settled for this GL.");
        return;
      }
    }

    const newData = tabstore?.state?.tabs[
     props.pageIndex
    ]?.changedRows?.pending_instruments?.map((row) => {
      if (
        row.refno ==
        tabstore?.state?.tabs[tabstore?.state.tabIndex]?.changedRows
          ?.selected_ref_id_to_settle
      ) {
        return {
          ...row,
          childrows:
            tabstore?.state?.tabs[tabstore?.state.tabIndex]?.changedRows
              ?.UPDATE_GL_LIST,
          othergl_total: temp_other_gl_amount_total,
        };
      }
      return row;
    });

    console.log("newData");
    console.log(newData);
    // check if more than pending amount is entered for this Ref ID
    if (isError == false) {
      setOpen(false);
      /*
      transaction_dispatch({
        type: "SAVE_GL_SETTLEMENT_AMOUNT_POPUP",
      });
      */

      tabstore.dispatch({
        type: "UPDATE_CHANGED_DATA",
        fieldType: "pending_instruments",
        text: newData,
      });
      props.onInstrumentsSettlementChanged("Updated");
    }
  };

  const HandleGLAmtUpdate = (code, amount) => {
    let temp_amount = amount;
    // Mandar

    if (transaction_state.voucher_amount > 0) {
      if (temp_amount > transaction_state.voucher_amount) {
        temp_amount = 0;
      }

      transaction_dispatch({
        type: "CURRENT_GL_SETTLEMENT_AMOUNT",
        code_updated: code,
        amount: temp_amount,
      });
    }
  };

  const handleAmountUpdate = async (_refno, amount,pendingamt) => {
    console.log("handleAmountUpdate" , amount,pendingamt);
    console.log("handleAmountUpdate - _refno" + _refno);
/// task id-316 rajendra received amount should not greater than balance amount start here one parameter added pendingamt
    let amt_to_update = Number(amount);
if(amt_to_update>pendingamt){
  alert("Amount update should not be graeter than Balance Amount")
  amt_to_update=pendingamt
}
/// task id-316 rajendra received amount should not greater than balance amount end
    if (amt_to_update >= 0) {
      let newData = [];
      newData = tabstore?.state?.tabs[
       props.pageIndex
      ]?.changedRows?.pending_instruments?.map((row) => {
        if (row.refno == _refno) {
          return {
            ...row,
            on_account_amount: Math.abs(amt_to_update),
          };
        }
        return row;
      });

      tabstore.dispatch({
        type: "UPDATE_CHANGED_DATA",
        fieldType: "pending_instruments",
        text: newData,
      });

      console.log("call onInstrumentsSettlementChanged");
      props.onInstrumentsSettlementChanged("Updated");
    }
  };

  /**
   *  06JAN2023
   *  Function To Distribute Amt to Pending Insurements.
   *  Currently not called . as Client does not want to distribute amt automatically.
   *  08FEB2023
   *  Move from local state to tab store state - Mandar
   */
  function DistributeAmount() {
    console.log(
      "DistributeAmount" +
        tabstore?.state?.tabs[tabstore?.state.tabIndex]?.changedRows
          ?.VOUCHER_AMT_UPDATED
    );

    let other_gl_total = 0;

    //tabstore?.state?.tabs[tabstore?.state.tabIndex]?.changedRows?.pending_instruments;
    //tabstore?.state?.tabs[tabstore?.state.tabIndex]?.changedRows?.VOUCHER_AMT_UPDATED;

    tabstore?.state?.tabs[
     props.pageIndex
    ]?.changedRows?.pending_instruments.forEach((element) => {
      other_gl_total = other_gl_total + element.othergl_total;
    });

    let RemainingAmount =
      tabstore?.state?.tabs[tabstore?.state.tabIndex]?.changedRows
        ?.VOUCHER_AMT_UPDATED - other_gl_total;

    // get already amt adjusted in other GL Accounts e.g. discount , expenses

    let temp_voucherType =
      tabstore?.state?.tabs[tabstore?.state.tabIndex]?.changedRows
        ?.voucher_type;
console.log("voucher_type==99",temp_voucherType);
    if (
      tabstore?.state?.tabs[tabstore?.state.tabIndex]?.changedRows
        ?.VOUCHER_AMT_UPDATED > 0
    ) {
      let dataArray = [];

      tabstore?.state?.tabs[
       props.pageIndex
      ]?.changedRows?.pending_instruments.forEach((element) => {
        element.on_account_amount = 0;
        if (temp_voucherType == 2) {
          if (element.cramt > 0) {
            if (RemainingAmount > 0)
              if (RemainingAmount >= element.pendingamt) {
                let item_amt_to_dist = 0;
                item_amt_to_dist = element.pendingamt - element.othergl_total;
                element.on_account_amount = item_amt_to_dist;
                RemainingAmount = RemainingAmount - element.on_account_amount;
                RemainingAmount = RemainingAmount.toFixed(2);
              } else {
                element.on_account_amount = Number(RemainingAmount);
                RemainingAmount = 0;
              }
          }
        } else if (temp_voucherType == 1) {
          if (element.dramt > 0) {
            if (RemainingAmount > 0)
              if (RemainingAmount >= element.pendingamt) {
                let item_amt_to_dist = 0;
                item_amt_to_dist = element.pendingamt - element.othergl_total;
                element.on_account_amount = item_amt_to_dist;
                RemainingAmount = RemainingAmount - element.on_account_amount;
                RemainingAmount = RemainingAmount.toFixed(2);
              } else {
                element.on_account_amount = Number(RemainingAmount);
                RemainingAmount = 0;
              }
          }
        }

        element.refno_balance =
          Number(element.pendingamt) - Number(element.on_account_amount);
        dataArray.push(element);
        console.log(dataArray);
      });

      tabstore.dispatch({
        type: "UPDATE_CHANGED_DATA",
        fieldType: "pending_instruments",
        text: dataArray,
      });

      transaction_dispatch({
        type: "TRIGGER_DISTRIBUTE_AMOUNT",
        trigger_distribute: false,
      });
    }
  }

  function RenderTableRow(childrows) {
    return (
      <>
        {childrows.childrows.map((data) => (
          <tr key={data.srno}>
            <td colspan={6} style={{ textAlign: "right" }}>
              {data.descn}
            </td>
            <td style={{ textAlign: "right", padding: "0" }}>{data.amount}</td>
            <td style={{ textAlign: "right" }}>{}</td>
          </tr>
        ))}
      </>
    );
  }

  const handleAmtUpdate = (amt) => {
    if (amt >= 0) {
      setDiscountAmount(amt);
    }
  };

  const onDiscountAccountChange = (event, type) => {
    console.log("onDiscountAccountChange", event);
    console.log("onDiscountAccountChange", type);

    if (type.action == "clear") {
      // clear page state for the account
      setDiscountGLCode(null);
    } else if (type.action == "select-option") {
      if (event != undefined) {
        if (event.code?.length > 0) {
          setDiscountGLCode(event.code);
          setDiscountGLName(event.descn);
        }
      }
    }
  };

  const AddDiscountGL = () => {
    if (discountAmount > 0 && discountGLCode.length > 0) {
      // add row to gl table
      //  console.log(discountGLCode);
      // console.log("Amt =" + discountAmount);

      let discount_item = {
        code: discountGLCode,
        descn: discountGLName,
        amount: discountAmount,
        gltype: "E",
      };

      // console.log("discount_item Add");
      // console.log(discount_item);

      // get existing state and add to same state
      //let gl_disocunt_items = transaction_state.gl_list;

      let gl_disocunt_items =
        tabstore?.state?.tabs[props?.pageIndex]?.changedRows
          ?.UPDATE_GL_LIST;

      gl_disocunt_items.push(discount_item);

      /*
      transaction_dispatch({
        type: "UPDATE_GL_LIST",
        gl_list: gl_disocunt_items,
      });
*/

      tabstore.dispatch({
        type: "UPDATE_CHANGED_DATA",
        fieldType: "UPDATE_GL_LIST",
        text: gl_disocunt_items,
      });

      // setDiscountGLCode(""); // fix for issue
      //setDiscountGLName(""); // fix for issue
      setDiscountAmount("");
    }
  };

  function handleDiscountGL(e) {
    if (e) {
      console.log("handleDiscountGL");
      console.log(e);
      setDiscountGLCode(e.code);
      setDiscountGLName(e.descn);
    }
  }
  const TotalRow = () => {
    let temp_total_pending = 0;
    let temp_total_on_account = 0;
    let temp_total_gl_amount = 0;
    let temp_voucher_amount = 0;
    let temp_balance_on_account_amt = 0;

    temp_voucher_amount =
      tabstore?.state?.tabs[props.pageIndex]?.changedRows
        ?.VOUCHER_AMT_UPDATED;

    tabstore?.state?.tabs[
     props.pageIndex
    ]?.changedRows?.pending_instruments.forEach((item) => {
      temp_total_pending = Number(temp_total_pending) + Number(item.pendingamt);
      if (item.on_account_amount > 0) {
        temp_total_on_account =
          Number(temp_total_on_account) + Number(item.on_account_amount);
      }

      const other_gl_child = item.childrows;
      other_gl_child.forEach((data) => {
        if (data.amount > 0) {
          temp_total_gl_amount =
            Number(temp_total_gl_amount) + Number(data.amount);
        }
        console.log("temp_total_gl_amount", data.amount);
        console.log("temp_total_gl_amount", temp_total_gl_amount);
      });
    });

    temp_balance_on_account_amt = Number(
      temp_voucher_amount - temp_total_on_account
    );
    temp_total_pending = temp_total_pending.toFixed(2);
    temp_total_on_account = temp_total_on_account.toFixed(2);
    temp_total_gl_amount = temp_total_gl_amount.toFixed(2);
    temp_balance_on_account_amt = temp_balance_on_account_amt.toFixed(2);

    return (
      <tr>
        <td
          colSpan={3}
          style={{ textAlign: "right", fontWeight: "bold", padding: 0 }}
        >
          {/* On Account Amount Rs. {temp_balance_on_account_amt}*/}
        </td>

        <td
          colSpan={1}
          style={{ textAlign: "right", fontWeight: "bold", padding: 0 }}
        >
          Total{" "}
        </td>
        <td style={{ textAlign: "right", fontWeight: "bold", padding: 0 }}>
          {temp_total_pending}
        </td>
        <td style={{ textAlign: "right", fontWeight: "bold", padding: 1 }}>
          {temp_total_on_account}
        </td>

        <td style={{ textAlign: "right", fontWeight: "bold", padding: 1 }}>
          {temp_total_gl_amount}
        </td>
      </tr>
    );
  };

  function removeDisocuntGL(_code) {
    console.log(_code);

    const result_gl_list = tabstore?.state?.tabs[
     props.pageIndex
    ]?.changedRows?.UPDATE_GL_LIST?.filter((item) => item.code != _code);

    tabstore.dispatch({
      type: "UPDATE_CHANGED_DATA",
      fieldType: "UPDATE_GL_LIST",
      text: result_gl_list,
    });
  }

  function getDiscountAmtSum() {
    let temp_DiscountAmtTotal = 0;

    let changedRows =
      tabstore?.state?.tabs[props.pageIndex]?.changedRows;

    changedRows?.UPDATE_GL_LIST?.forEach((element) => {
      console.log("element.amount", element.amount);

      temp_DiscountAmtTotal =
        Number(temp_DiscountAmtTotal) + Number(element.amount);
    });

    return temp_DiscountAmtTotal;
  }

  function getPaidAmount() {
    /** Sum all the amount which are paid to rerfence without Discount GL */
    let temp_paidamount = 0;
    let _selected_ref_instrument = 0;

    let changedRows =
      tabstore?.state?.tabs[props.pageIndex]?.changedRows;

    _selected_ref_instrument = changedRows?.selected_ref_instrument?.refno;
    console.log("getPaidAmount ", _selected_ref_instrument);

    changedRows?.pending_instruments?.forEach((element) => {
      if (_selected_ref_instrument == element.refno) {
        temp_paidamount =
          Number(temp_paidamount) + Number(element.on_account_amount);
      }
    });

    return temp_paidamount;
  }

  function getRemainingAmt() {
    let pendingamt = 0;
    let discountamtsum = 0;
    let RemainingAmt = 0;
    let paidamount = 0;

    pendingamt =
      tabstore?.state?.tabs[props.pageIndex]?.changedRows
        ?.selected_ref_instrument?.pendingamt;

    discountamtsum = getDiscountAmtSum();
    console.log("getRemainingAmt", discountamtsum);
    paidamount = getPaidAmount();
    RemainingAmt = (pendingamt - (paidamount + discountamtsum)).toFixed(2);
    console.log("getRemainingAmt pendingamt", pendingamt);
    console.log("getRemainingAmt paidamount", paidamount);
    console.log("getRemainingAmt RemainingAmt", RemainingAmt);

    return RemainingAmt;
  }
  /**/

  return (
    <div>
      <div>
        <table border="1" width={"100%"}>
          <tbody>
            <tr style={{ position: "sticky", top: "0", background: "#fff" }}>
              <td width="10%">Reference No.</td>
              <td width="10%">Date</td>
              <td width="5%">Credit Amount</td>
              <td width="5%">Debit Amount</td>
              <td width="5%">Balance Amount</td>
              <td width="5%">Adjusted Amount</td>
              {props.vouchertype == "RECEIPT" ? (
                <td width="5%">Pending Amount</td>
              ) : (
                <td width="5%">Pending Amount</td>
              )}

              
              <td width="5%">Action</td>
            </tr>

            {tabstore?.state?.tabs[props.pageIndex]?.changedRows?.[
              "pending_instruments"
            ]?.map((data) => (
              <>
                <tr key={data.recno}>
                  <td>{data.refno} </td>
                  <td>{dateFormatter(data.refdate)} </td>
                  <td style={{ textAlign: "right", padding: 0 }}>
                    {data.cramt}
                  </td>
                  <td style={{ textAlign: "right", padding: 0 }}>
                    {data.dramt}{" "}
                  </td>
                  <td style={{ textAlign: "right", padding: 0 }}>
                    {data.pendingamt}{" "}
                  </td>

                  <td style={{ textAlign: "right", padding: 1 }}>
                    <input
                      style={{ textAlign: "right" }}
                      type="number"
                      size={10}
                      id={data.recno}
                    disabled={tabstore?.state?.tabs[props.pageIndex]?.changedRows?.VOUCHER_AMT_UPDATED==undefined||tabstore?.state?.tabs[props.pageIndex]?.changedRows?.VOUCHER_AMT_UPDATED==""?true:false}
                      value={data?.on_account_amount}
                      onChange={(e) => {
                        console.log("On Change Fired");
                        console.log(e);
                        handleAmountUpdate(data.refno, e.target.value,data?.pendingamt);  /// task id 316  rajendra here on mre parameter passed pendingamt
                      }}
                    ></input>{" "}
                  </td>
                  <td style={{ textAlign: "right", padding: 0 }}>
                    <div style={{ textAlign: "right", padding: 0 }}>
                      {data.othergl}{" "}
                    </div>
                  </td>
                  <td align="center">
                    <input
                      type="button"
                      id={data.recno}
                      value="Select Account"
                      onClick={(e) => {
                        //console.log("A")
                        let array_temp = [];
                        tabstore.dispatch({
                          type: "UPDATE_CHANGED_DATA",
                          fieldType: "UPDATE_GL_LIST",
                          text: array_temp,
                        });
                        /*
                        transaction_dispatch({
                          type: "CLEAR_GL_LIST",
                        });
                        */
                        handleClickOpen(data.refno);
                      }}
                    ></input>
                  </td>
                </tr>
                {data.childrows.length > 0 ? (
                  <RenderTableRow childrows={data.childrows}></RenderTableRow>
                ) : (
                  <></>
                )}
              </>
            ))}
            <TotalRow />
          </tbody>
        </table>
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        PaperProps={{
          style: {
            minHeight: "90%",
            maxHeight: "90%",
            width: "70%",
            maxWidth: "90%",
            zIndex: 1000,
          },
        }}
      >
        <DialogTitle>GL List</DialogTitle>
        <DialogContent>
          {/* summary  row */}
          <div>
            <table border="1">
              <tr>
                <td>Ref No</td>
                <td>Balance Amount</td>
                {props.vouchertype == "RECEIPT" ? (
                  <td>Received Amount</td>
                ) : (
                  <td>Paid Amount</td>
                )}
                <td>Discount Amount</td>
                <td>Remaining Amount</td>
              </tr>
              <tr>
                <td>
                  {
                    tabstore?.state?.tabs[props.pageIndex]?.changedRows
                      ?.selected_ref_id_to_settle
                  }
                </td>
                <td align="center">
                  {
                    tabstore?.state?.tabs[props.pageIndex]?.changedRows
                      ?.selected_ref_instrument?.pendingamt
                  }
                </td>
                <td align="center">
                  {" "}
                  {
                    tabstore?.state?.tabs[props.pageIndex]?.changedRows
                      ?.selected_ref_instrument?.on_account_amount
                  }
                </td>

                <td align="center">{getDiscountAmtSum()}</td>
                <td align="center">{getRemainingAmt()}</td>
              </tr>
            </table>
          </div>
          {/* summary  row */}
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              margin: "3px",
              marginLeft: "25px",
            }}
          >
            <div style={{ display: "flex", marginTop: "10px" }}>
              <SaveDropDownAccounts
                displayLabel={"Dis GL"}
                alignment="column"
                id={"account1"}
                placeholder={"GL"}
                apiUrl={getAccountlistURL}
                apikey={"discountgl"}
                multi={false}
                selectionLabel={"descn"}
                selectionValue={"code"}
                records={20}
                minInput={0}
                async
                onChange={onDiscountAccountChange}
                width="30vw"
              />
            </div>
            <div
              style={{
                display: "flex",
                marginTop: "25px",
                alignSelf: "center",
              }}
            >
              <input
                style={{
                  textAlign: "right",
                  width: "100px",
                  height: "35px",
                  marginLeft: "10px",
                  marginRight: "10px",
                }}
                type="text"
                value={discountAmount}
                onChange={(e) => {
                  // HandleGLAmtUpdate(gl_data.code, e.target.value);
                  handleAmtUpdate(e.target.value);
                }}
              ></input>{" "}
              <Button type="primary" onClick={AddDiscountGL}>
                Add{" "}
              </Button>
            </div>
          </div>
          <table border="1" style={{ marginTop: "10px" }}>
            <tbody>
              <tr>
                <td>Code</td>
                <td width={"60%"}>GL Name</td>
                <td width={"10%"}>Amount</td>
                <td width={"10%"}>Delete</td>
              </tr>
              {tabstore?.state?.tabs[
               props.pageIndex
              ]?.changedRows?.UPDATE_GL_LIST?.filter(
                (item) => item.gltype == "E"
              ).map((gl_data) => (
                <tr key={gl_data.code}>
                  <td>{gl_data.code}</td>
                  <td width={"60%"}>{gl_data.descn}</td>
                  <td align="right"> {gl_data.amount}</td>
                  <td align="center">
                    <Button
                      type="primary"
                      shape="circle"
                      onClick={() => {
                        removeDisocuntGL(gl_data.code);
                      }}
                      icon={<DeleteOutlined />}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </DialogContent>
        <DialogActions>
          <Button type="primary" onClick={handleClose}>
            Cancel{" "}
          </Button>
          <Button type="primary" onClick={handleSave}>
            Save{" "}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
// Set default props
PendingInsutrumentsTransaction.defaultProps = {
  PendingInstruments: [],
  Amount: 0,
};

//export default PendingInsutrumentsTransaction;
export default React.memo(PendingInsutrumentsTransaction);

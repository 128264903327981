import { Fragment, useContext, useEffect, useState } from "react";
import { TabStore } from "../../TabsStructure/TabsStore";
import FilterComponents from "../../CommonControllers/FiterComponent/FilterComponents";
import { AuthStore } from "../../Auth/AuthStore";
import { getfastpathapi } from "../../CommonControllers/ApiCalls";
import "./style.css";
import axios from "axios";
import { getTenantItemListURL } from "../../CommonControllers/Urls";
import { UnorderedListOutlined } from "@ant-design/icons";

const MasterScreen = () => {
  const tabStore = useContext(TabStore);
  const [pageIndex, setPageIndex] = useState();
  const auth = useContext(AuthStore);
  const [fastPathOptions, setFastPathOptions] = useState();
  console.log("RDTRDYTDY", tabStore);
  const [isInfoExpanded, setInfoExpanded] = useState(false);

  const [column, setColums] = useState([]);
  const [rows, setRows] = useState([]);


  const [tableData, setTableData] = useState([]);
  const apiResponse = tabStore.state.tabs[pageIndex]?.apiResponse;

  useEffect(() => {
    setColums(apiResponse?.columnsinfo);
    setRows(apiResponse?.Message[0]);
  }, [apiResponse]);
  const handleToggleInfo = () => {
    setInfoExpanded(!isInfoExpanded);
    //getTenantItemData()

  };
  function getTenantItemData() {
    const payload = {
      tenantrecno: auth?.state?.userdata?.tenantrecno,
      domainrecno: auth?.state?.userdata?.domainrecno,
      recno:apiResponse?.Message[0].recno
    };

    axios.post(getTenantItemListURL, payload).then((res) => {
      console.log("use ms201", res?.data?.Message);
    //   setTblData(res?.data?.Message);
    //   setTblColumns(res?.data?.columnsinfo);
    });
  }
  useEffect(() => {
    var tabsArray = tabStore.state.tabs;
    for (let i = 0; i < tabsArray?.length; i++) {
      if (tabsArray[i].name == "TR994") {
        setPageIndex(i);
        break;
      }
    }
  }, []);
  useEffect(() => {
    if (pageIndex >= 0) {
      const payload = {
        tenantrecno: auth?.state?.userdata?.tenantrecno,
        domainrecno: auth?.state?.userdata?.domainrecno,
        userrolerecno: auth?.state?.userdata?.userrolerecno,
        domainuserrecno: auth.state.userdata.recno,
        fastpath: tabStore.state.tabs[pageIndex]?.name,
      };
      // On component mount this api is called and filterOptions are set for that fast path
      getfastpathapi(payload).then((res) => {
        if (res?.Success) {
          setFastPathOptions(res?.Message);
          console.log("Message", res.Message);
        }
      });
    }
  }, [pageIndex]);

  const handleCellEdit = (newValue, rowIndex, row) => {
    let obj={
        ...row,qty:newValue,mainrecno:row.recno
    }
    console.log("RDFHTFUTJU",obj);
    setTableData((p)=>[...p,obj])
  };
 
useEffect(()=>{
    tabStore.dispatch({
        type: "UPDATE_DATA",
        fieldType: "changedRows",
        text: tableData

      })
},[tableData])
console.log("TTTTabsto",tabStore)
  return (
    <Fragment>
      <FilterComponents filterOption={fastPathOptions?.filterOptions} />

      <div className="filter-section">
        {/* Your FilterComponents component */}
      </div>
      {column && rows && (
        <section>
          <div className="info-container">
            <div className="toggle-button">
              <button onClick={handleToggleInfo}><UnorderedListOutlined style={{backgroundColorL:"grey",borderRadiusL:"5px"}} /></button>
            </div>
            <div className="info-item">
              <h3>{column[1]?.columndisplay}</h3>
              <p>{rows[column[1]?.dataIndex]}</p>
            </div>
            <div className="info-item">
              <h3>{column[2]?.columndisplay}</h3>
              <p>{rows[column[2]?.dataIndex]}</p>
            </div>
            <div className="info-item">
              <h3>{column[3]?.columndisplay}</h3>
              <p>{rows[column[3]?.dataIndex]}</p>
            </div>
            <div className="info-item">
              <h3>{column[4]?.columndisplay}</h3>
              <p>{rows[column[4]?.dataIndex]}</p>
            </div>
            <div className="info-item">
              <h3>{column[5]?.columndisplay}</h3>
              <p>{rows[column[5]?.dataIndex]}</p>
            </div>
            <div className="info-item">
              <h3>{column[6]?.columndisplay}</h3>
              <p>{rows[column[6]?.dataIndex]}</p>
            </div>
          </div>
        </section>
      )}

      {isInfoExpanded && (
        <section
          className={`table-section${isInfoExpanded ? " expanded" : ""}`}
        >
          <table className="data-table">
            <thead>
              <tr>
                <th>Item Description</th>
                <th>Quantity</th>
                {/* <th>Column 3</th> */}
                {/* Add more columns as needed */}
              </tr>
            </thead>
            <tbody>
              {tabStore.state.tabs[pageIndex]?.apiResponse?.Message?.map(
                (row, index) => (
                  <>
                  <tr key={index}>
                      <td
                   
                    >
                     <p>FCM 1</p>   
                    </td>
                    <td
                    
                    >
                      <input onBlur={(e)=> handleCellEdit(e.target.value, index, row)}/>  
                    </td>
                  </tr>
                  <tr key={index}>
                      <td
                   
                    >
                     <p>FCM 500</p>   
                    </td>
                    <td
                    
                    > <input onBlur={(e)=> handleCellEdit(e.target.value, index, row)}/>  </td>
                  </tr>
                  <tr key={index}>
                      <td
                   
                    >
                     <p>MM 500</p>   
                    </td>
                    <td
                   
                    > <input onBlur={(e)=> handleCellEdit(e.target.value, index, row)}/>  </td>
                  </tr></>
                )
              )}
            </tbody>
          </table>
        </section>
      )}
    </Fragment>
  );
};
export default MasterScreen;

import React, { useContext, useEffect, useState } from 'react'
import SaveNumberInput from '../../CommonControllers/Components/SaveComponents/SaveNumberInput'
import SaveTextInput from '../../CommonControllers/Components/SaveComponents/SaveTextInput'
import SaveDropDown from '../../CommonControllers/Components/SaveComponents/SaveDropdown'
import SaveCheckBox from '../../CommonControllers/Components/SaveComponents/SaveCheckbox'
import { getForAnswer, getQuestionBySurveyType, getSurveyQuestionType, getSurveyType } from '../../CommonControllers/Urls'
import { AuthStore } from '../../Auth/AuthStore'
import { TabStore } from '../../TabsStructure/TabsStore'


function AddSingleSurveyQuestion({fastPath}) {
    const auth = useContext(AuthStore);
    const [surveytype, setsurveytype] = useState()
    const [questiontyperecno, setquestiontyperecno] = useState()
    const [issubquestionof, setissubquestionof] = useState()
    const tabStore = useContext(TabStore);

    const [pageIndex, setPageIndex] = useState();

    useEffect(() => {
      var tabsArray = tabStore.state.tabs;
      for (let i = 0; i < tabsArray?.length; i++) {
        if (tabsArray[i].name == fastPath) {
          setPageIndex(i);
          break;
        }
      }
    }, []);

    return (
        <div>
            <div>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', width: "100%" }}>
                    <SaveDropDown
                        placeholder="Select Survey Type"
                        displayLabel={"Survey Type"}
                        apiurl={getSurveyType}
                        apikey={"surveytype"}
                        selectionLabel={"descn"}
                        selectionValue={"recno"}
                        minInput={0}
                        inputValue={20}
                        returnValue={setsurveytype}
                        width={"500px"}
                    />
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', marginLeft: "20%", marginTop: "2%" }}>
                    <div style={{ display: "flex" }}>
                        <SaveNumberInput
                            placeholder="Serial No"
                            displayLabel={"Serial No"}
                            apikey={"questionsrno"}

                        />
                    </div>
                    <div style={{ display: "flex", marginLeft: "10%" }}>
                        <SaveDropDown
                            placeholder="Select Question Type"
                            displayLabel="Question Type"
                            apiurl={getSurveyQuestionType}
                            apikey={"questiontype"}
                            selectionLabel={"descn"}
                            selectionValue={"recno"}
                            minInput={0}
                            returnValue={setquestiontyperecno}

                            inputValue={20}
                            width={"100%"}
                        />
                    </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', }}>

                    <div style={{ display: 'flex', flexDirection: 'column', marginTop: "2%", justifyContent: 'space-evenly', alignItems: 'flex-start' }}>
                        <div style={{ display: 'flex', flexDirection: 'column', marginLeft: "25%", width: "100%" }}>
                            <div style={{}}>
                                <SaveTextInput
                                pageIndex={pageIndex}
                                    apikey={"questionname"}
                                    placeholder="Question"
                                    displayLabel={"Question"}
                                    style={{ width: "900px" }}
                                />
                            </div>
                            <div style={{ marginTop: "2%" }}>
                                <SaveTextInput
                                pageIndex={pageIndex}
                                    placeholder="Optionlist e.g.opt1,opt2,opt3"
                                    displayLabel={"Question Optionlist (ComaSeperated)"}
                                    apikey={"optionlist"}
                                    style={{ width: "700px" }}
                                />
                            </div>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row', marginLeft: "25%", justifyContent: 'space-around', width: "100%", marginTop: "2%" }}>

                            <SaveDropDown
                                placeholder="Select Issubquestionof"
                                displayLabel={"Issubquestionof"}
                                apikey={"issubquestionof"}
                                apiurl={getQuestionBySurveyType}
                                body={{ "surveytype": surveytype?.recno }}
                                selectionLabel={"questionname"}
                                returnValue={setissubquestionof}
                                width={"100%"}
                            />

                            <SaveDropDown
                                placeholder="Select For Answer"
                                displayLabel={"For Answer"}
                                apikey={"foranswer"}
                                apiurl={getForAnswer}
                                body={{ "surveytyperecno": surveytype?.recno, "questionrecno": issubquestionof?.recno }}
                                width={"100%"}
                                selectionLabel={"optionlist"}
                            />

                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row', marginLeft: "25%", justifyContent: 'space-around', width: "100%", marginTop: "2%" }}>
                            <SaveDropDown
                                placeholder="Select Isgroupof"
                                displayLabel={"Isgroupof"}
                                apikey={"isgroupof"}
                                apiurl={getQuestionBySurveyType}
                                body={{ "surveytype": surveytype?.recno }}
                                selectionLabel={"questionname"}
                                width={"100%"}
                            />
                        </div>
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly', marginTop: "1%", alignItems: "flex-start", width: "50%", }}>

                        <div style={{ marginLeft: "35%", marginTop: "2%" }} >
                            <SaveCheckBox
                                displayLabel={"Mandatory"}
                                apikey={"mandatory"}
                                editable
                            />
                        </div>

                        <div style={{ marginLeft: "35%", marginTop: "2%" }} >
                            <SaveCheckBox
                                displayLabel={"Images"}
                                apikey={"images"}
                                editable
                            />
                        </div>

                        <div style={{ marginLeft: "35%", marginTop: "2%" }} >
                            <SaveCheckBox
                                displayLabel={"isCommon"}
                                apikey={"iscommon"}
                                editable
                            />
                        </div>

                        <div style={{ marginLeft: "35%", marginTop: "2%" }} >
                            <SaveCheckBox
                                displayLabel={"Hasgroup"}
                                apikey={"hasgroup"}
                                editable
                            />
                        </div>

                        <div style={{ marginLeft: "35%", marginTop: "2%" }} >
                            <SaveCheckBox
                                displayLabel={"Locn"}
                                apikey={"location"}
                                editable
                            />
                        </div>

                        <div style={{ marginLeft: "35%", marginTop: "2%" }} >
                            <SaveCheckBox
                                displayLabel={"Auto-filled"}
                                apikey={"autofilled"}
                                editable
                            />
                        </div>

                    </div>

                </div>
            </div>
        </div>
    )
}

export default AddSingleSurveyQuestion
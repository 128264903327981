import React from "react";

const MainMasters = () => {
  return (
    <div>
        <div style={{position:'fixed',backgroundColor:'white',width:'80%'}}><h1>Main Masters</h1></div>
        <br/> 
        <br/> 
      <h3>Party (MS100)</h3>
      <p>
        <p>
          For BCore clients, it is essential to store profile information of all
          the Business Units (BUs) within the system. Alongside BU profiles, it
          is also necessary to maintain profiles of other entities associated
          with these BUs, such as customers, salesmen, transporters, and more.
        </p>
        <p>
          This dedicated screen serves the purpose of capturing and managing
          profiles of all these entities, regardless of whether they use BCore
          or not. It is designed to accommodate details of various individuals
          or organisations that are connected to the BUs in different
          capacities. The screen has the capability to store comprehensive
          information about these entities, ensuring a centralised and
          comprehensive database for effective management and association with
          the BUs.
        </p>
        <p>
          Adding the profiles of entities in the Party screen does not
          automatically create an account for conducting accounting
          transactions. However, in the subsequent two screens, we will explore
          how the account master automatically generates accounts for these
          parties.
        </p>
        <p>
          The tenant will also have their profile stored in the party master,
          which is the central repository for maintaining information about
          various entities within the BCore application. By storing the tenant's
          profile in the party master, all the relevant details and attributes
          associated with the tenant can be easily accessed and managed.
        </p>
      </p>
      <h3>Business Unit (BU)</h3>
      <p>
        <p>
          A Business Unit (BU) is a user entity within the BCore Application and
          plays a crucial role in the company's established ecosystem. BUs are
          integral parts of the distributor chain set up by the company. The
          technical term for BU is Domain.
        </p>
        <p>
          To create a record of a BU, a profile is first created in the Party
          screen (MS100). This profile serves as the basis for creating a new BU
          using the dedicated screen (MS301). Within this screen, all the
          relevant details pertaining to the BU are entered and stored.
        </p>
        <p>
          In the business unit list, there will be a single record for the
          tenant. This record serves the purpose of facilitating the users at
          the company level to access and utilise the BCore web application.
        </p>
      </p>

      <h3>BU Locations (MS111)</h3>
      <p>
        In certain cases, a Business Unit (BU) may have business locations
        situated in different geographical places, such as Pune, Surat,
        Bhubaneswar, and Indore. It becomes crucial for the BU to maintain
        separate accounting and inventory records for the business activities
        conducted at each of these locations. To facilitate location-wise
        operations, this screen is designed to maintain and manage these
        location details.
      </p>
      <h3>BU Licences (MS112)</h3>
      <p>
        In accordance with various government rules and regulations, different
        licences and certificates are necessary to operate a business. These
        licences and certificates include the Shop Act Certificate, GSTIN, PAN,
        FSSAI, Drug License, and more. Certain licences or certificates are
        mandatory for all types of businesses, while others depend on factors
        such as the nature of commodities dealt by the BU, business volume, or
        some other criteria. Each licence and certificate is assigned a unique
        number. Additionally, it may be necessary to store additional details
        such as commencement date, expiration date. The purpose of this screen
        is to store the pertinent information related to all licences and
        certificates associated with the business unit (BU).
      </p>
      <h3>BU Images (MS113)</h3>
      <p>
        This screen serves as a storage repository for a diverse range of
        images, each intended for specific purposes. The captured and stored
        images can be accessed and utilised by other programs within the system.
        By utilising this functionality, various programs can leverage the
        images stored here to fulfil their respective requirements.
      </p>
      <h3>BU Sub-locations (MS115)</h3>
      <p>
        <p>
          Similarly, in certain scenarios, a Business Unit (BU) may have
          sub-locations within its main locations. These sub-locations can be
          defined as specific areas or divisions within a particular
          geographical location. For instance, the client may have a structure
          of shelves within their business location. When conducting inventory
          operations, it is important for the client to have detailed
          information about each shelf.
        </p>
        <p>
          To facilitate efficient inventory management, a dedicated screen is
          utilised to capture and organise the details of these sub-locations.
          This allows the client to have precise visibility and control over
          operations related to each specific area or shelf within their main
          location. The sub-location screen enables effective monitoring and
          enhances operational efficiency within the BU's inventory management
          processes.
        </p>
      </p>
      <h3>BU POS Machines (MS117)</h3>
      <p>
        If the business unit (BU) is involved in retail operations, the billing
        process is facilitated through the utilisation of Point of Sale Machines
        (POS Machines). Each POS machine is assigned a unique identifier for
        identification purposes. Additionally, these machines possess certain
        crucial specifications. All the pertinent information regarding the POS
        machines currently in use on the premises is duly recorded and made
        available here.
      </p>
      <h3>Document Number Formats (MS120)</h3>
      <p>
        Various documents, such as sales invoices and delivery challans, are
        generated by the BU (business unit). Similarly, the BU needs to input
        additional documents, including purchase invoices and GRNs, which are
        received from external parties such as suppliers. The system is designed
        to assign unique identification numbers to these documents. These
        identification numbers begin with a customizable prefix determined by
        the BU, facilitating easy identification of the document type. Each
        document type is associated with a specific series number used as a
        prefix. This screen within the system allows for storing the prefix
        string corresponding to each document type.
      </p>
      <h3>BU Customer Locations (MS121)</h3>
      <p>
        Customers associated with the BUs may have various locations where they
        conduct their business. This screen provides a means to store and manage
        customer-specific locations. By utilising this functionality, you can
        record and organise the different locations linked to each customer.
      </p>
      
      <h3>Barcode Formula (MS123)</h3>
            <p>To facilitate inventory management and the handling of products with specific attributes such
      as batch number, serial number, date of manufacturing, date of expiration, etcIndividual
      products are labelled with barcodes. These barcodes can be read using a specialised input
      device called a barcode scanner. By using the barcode scanner, the attributes contained in
      the barcode are automatically captured and entered into the system wherever necessary.
      The barcode formula defines the specific structure in which different attributes are arranged.
      This screen is specifically designed for managing different barcode formulas.</p>
      <h3>Party Licences (MS128)</h3>
      <p>While describing the screen BU Licences (MS112), we have comprehended the fact that
    operating a business requires various licences and certificates in accordance with
    government rules and regulations. These licences and certificates include the Shop Act
    Certificate, GSTIN, PAN, FSSAI, Drug License, and more. Certain licence or certificates are
    mandatory for all types of businesses, while others depend on factors such as the nature of
    commodities dealt by the BU, business volume, or some other criteria. Each licence and certificate is assigned a unique number. Additionally, it may be necessary to store additional
    details such as commencement date, expiration date. The purpose of this screen is to store
    the pertinent information related to all licences and certificates associated with Customers &
    Suppliers</p>
    <div>
        <h3>BU Parameters (MS130)</h3>
        <table style={{ width: "80%", margin: "auto" }}>
          <thead>
            <tr>
              <th>Title </th>
              <th>Type</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Domain Record No.</td>
              <td>Domain recno of the current BU Will be populated here </td>
            </tr>
            <tr>
              <td>Sutradhar Hosting</td>
              <td>
                Whether Sutradhar will provide the hosting service (Yes/No)
              </td>
            </tr>
            <tr>
              <td>Default Department</td>
              <td>Whether there is single department available (Yes/No) </td>
            </tr>
            <tr>
              <td>Default Section</td>
              <td>Whether there is single section available (Yes/No) </td>
            </tr>
            <tr>
              <td>Multiple POS</td>
              <td>Whether there are multiple POS machines (Yes/No)</td>
            </tr>
            <tr>
              <td>Scanner</td>
              <td>Whether scanner is used (Yes/No) </td>
            </tr>
            <tr>
              <td>Camera</td>
              <td>
                Whether camera is available to capture customer’s image(Yes/No)
              </td>
            </tr>
            <tr>
              <td>CCTV</td>
              <td>Whether CCTV is available in the premises</td>
            </tr>
            <tr>
              <td>Rfsearch</td>
              <td>RF ID </td>
            </tr>
            <tr>
              <td>Offline Ability</td>
              <td>
                If there is no internet connection is available then whether to
                run the application in offline mode (Yes/No) for mobile app only{" "}
              </td>
            </tr>
            <tr>
              <td>TPOS</td>
              <td></td>
            </tr>
            <tr>
              <td>SMS</td>
              <td>Whether SMS facility is to be provided (Yes/No) </td>
            </tr>
            <tr>
              <td>Customer APP</td>
              <td>Whether the app is to be provided to customers (Yes/No)</td>
            </tr>
            <tr>
              <td>Online Order</td>
              <td>
                Whether online orders are accepted from customers (Yes/No)
              </td>
            </tr>
            <tr>
              <td>Home Delivery</td>
              <td>Facility of home delivery is there (Yes/No) </td>
            </tr>
            <tr>
              <td>Credit Points</td>
              <td>Whether Credit points to be computed (Yes/No)</td>
            </tr>
            <tr>
              <td>Handle Cash</td>
              <td>
                Whether cash handling facility is required (Denomination wise
                cash records) (Yes/No){" "}
              </td>
            </tr>
            <tr>
              <td>E-Collect</td>
              <td>Whether e-payment facility is to be available (Yes/No) </td>
            </tr>
            <tr>
              <td>Accounts</td>
              <td>Whether accounting facility is used (Yes/No)</td>
            </tr>
            <tr>
              <td>Facebook</td>
              <td>Facebook ID</td>
            </tr>
            <tr>
              <td>Instagram</td>
              <td>Instagram ID </td>
            </tr>
            <tr>
              <td>Twitter</td>
              <td>Twitter ID</td>
            </tr>
            <tr>
              <td>Linkedin</td>
              <td>Linkedin ID</td>
            </tr>
            <tr>
              <td>Telegram</td>
              <td>Telegram link </td>
            </tr>
            <tr>
              <td>WhatsAPP</td>
              <td>Whats App number</td>
            </tr>
            <tr>
              <td>Show Case</td>
              <td>
                Whether Images of items to be showcased on the screen (Yes/No){" "}
              </td>
            </tr>
            <tr>
              <td>GST Applicable</td>
              <td>Whether GST is applicable for sales (Yes/No)</td>
            </tr>
            <tr>
              <td>Charge Type</td>
              <td>Type of Hosting charges (Duration) </td>
            </tr>
            <tr>
              <td>Charge Prepaid</td>
              <td>Whether charges are prepaid (Yes/No)</td>
            </tr>
            <tr>
              <td>Paid Date</td>
              <td>Last Date of payment of charges </td>
            </tr>
            <tr>
              <td>Cloud Backup</td>
              <td>Whether cloud backup is preserved for the BU (Yes/No) </td>
            </tr>
            <tr>
              <td>Client Backup</td>
              <td>
                Whether to make available the cloud backup to the BU (Yes/No)
              </td>
            </tr>
            <tr>
              <td>List Item with Stock Zero</td>
              <td>
                Whether to include items having no stock in stock report
                (Yes/No)
              </td>
            </tr>
            <tr>
              <td>Printer Type</td>
              <td>List of available printers </td>
            </tr>
            <tr>
              <td>Stock Verification Cycle </td>
              <td>Stock verification cycle in days </td>
            </tr>
            <tr>
              <td>Points Counter ID</td>
              <td>Remove from front end </td>
            </tr>
            <tr>
              <td>Sale Rate With GST</td>
              <td>Whether GST is included in Sale Rate in print (Yes/No) </td>
            </tr>
            <tr>
              <td>Purchase Rate with GST</td>
              <td>
                Whether GST is included in Purchase Rate in print (Yes/No){" "}
              </td>
            </tr>
            <tr>
              <td>QR Code URL</td>
              <td>URL for QR code for bill print </td>
            </tr>
            <tr>
              <td>Points Security Key</td>
              <td>Remove from front end </td>
            </tr>
            <tr>
              <td>Stock Verify Allowed</td>
              <td>
                Whether the updation entry of stock verification is allowed
                (Yes/No){" "}
              </td>
            </tr>
            <tr>
              <td>Allow FSSAI Expiry</td>
              <td>Allow selling even the FSSAI licence is expired</td>
            </tr>
            <tr>
              <td>Credit Control</td>
              <td>Whether credit control to be applied (Yes/No) </td>
            </tr>
            <tr>
              <td>Sale Customer</td>
              <td>Remove from front end </td>
            </tr>
            <tr>
              <td>Customer Items</td>
              <td>Remove from front end </td>
            </tr>
            <tr>
              <td>Batch Tracing</td>
              <td>Batch tracing facility to be available(Yes/No)</td>
            </tr>
            <tr>
              <td>DC from Invoice Bill</td>
              <td>Whether to prepare DC from invoice (Yes/No) </td>
            </tr>
            <tr>
              <td>Purchase Rate in PI from PO</td>
              <td>
                Whether to pick Purchase Rate in Purchase Invoice from the
                respective POs (Yes/No){" "}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <h3>BU Customer Banks (MS131)</h3>
      <p>
        Customers typically settle their payable amounts from their designated
        bank accounts, utilising either cheques or electronic transfers. In
        order to simplify the process of filling out receipt details, a
        convenient feature is offered to store the bank account information of
        each customer. The dedicated BU Customer Banks (MS131) screen is
        specifically designed for recording and managing these bank account
        details. This functionality ensures efficient record-keeping and
        facilitates smooth transactions for both the customers and the business
        unit.
      </p>  
      <h3>Company Items (MS201)</h3>
      <p>
        All the items with which the company deals are entered using the Company
        Items screen. All the essential details such as description, short
        description, code, barcode, GST rate, HSN Code, unit of measurement,
        etc. are entered here. The services provided to different customers are
        also available in this list. Different business units can easily select
        the items and services they need based on their specific requirements.
        The process of selecting items and services from this list is carried
        out using a separate screen, which will be further explained.
      </p>
    <h3>Manufacturer Licences (MS298)</h3>
        <p>We have comprehended the fact that operating a business requires various licences and
    certificates in accordance with government rules and regulations while describing the screen
    BU Licences (MS112) and Party Licences (MS128). Similarly, licences are also necessary
    for manufacturing activities, and this screen is used to enter details about the licences of the
    manufacturers.</p>
      <h3>Manufacturer (MS299)</h3>
      <p>For certain industries like the pharmaceutical industry it is compulsory to know which
      company has manufactured the product. This screen allows for the entry and management
      of manufacturing company information.</p>
      <h3>Marketeer (MS300)</h3>
      <p>In the case of the pharmaceutical industry, products are manufactured by some companies
and marketed by other companies. To list such marketeers this screen is used.</p>
    </div>
  );
};

export default MainMasters;

import React, { useState, useEffect, useContext, useRef } from "react";
import useSaveChangedRows from "../../CommonControllers/Hooks/useSaveChangedRows";
import ItemSearch from "../../CommonControllers/ItemController/ItemSearch";
import {
  handleAdd,
  piColumns,
  handleRemove,
  handleRemovePrev,
} from "./PurchaseInvoiceFunc";
import { Button, Collapse, Input, Modal, Space, Table } from "antd";
import {
  getToday,
  dateFormatter,
  convertToBoxAndPieces,
} from "../../CommonControllers/AppFunction";
import SupplierSaveDropDown from "../../CommonControllers/SaveComponents/HigherLevel/SupplierSaveDropDown";
import SaveDatePicker from "../../CommonControllers/SaveComponents/SaveDatePicker";
import useShortguid from "../../CommonControllers/Hooks/useShortGuid";
import { TabStore } from "../../TabsStructure/TabsStore";
import SaveTextInput from "../../CommonControllers/Components/SaveComponents/SaveTextInput";
import { AuthStore } from "../../Auth/AuthStore";
import { IoAperture } from "react-icons/io5";
import CallbackDateInput from "../../CommonControllers/Components/CallbackComponents/CallbackDateInput";
import { apiCallwithBaseUrl } from "../../CommonControllers/ApiCalls";
import { List } from "antd";
import { FcAddDatabase } from "react-icons/fc";
import UserLocationList from "../../CommonControllers/SaveComponents/HigherLevel/UserLocationList";
import { currentTabData } from "../../CommonControllers/Variable";

import "../../CommonControllers/global.css";
import SearchFilterByBillNo from "../../CommonControllers/Components/SearchFilterBillNo/SearchFilterByBillNo";
import SaveCheckbox from "../../CommonControllers/Components/SaveComponents/SaveCheckbox";
import ItemsTable from "./ItemsTable";

import "../../CommonControllers/global.css";
import ScanTable from "../../CommonControllers/Components/ScanTable/ScanTable";
import { addDays } from "date-fns";
import SaveDropdown from "../../CommonControllers/Components/SaveComponents/SaveDropdown";
import { type } from "@testing-library/user-event/dist/type";
import useFetchItem from "../../CommonControllers/ItemController/useFetchItems";
import { getfastpathapi } from "../../CommonControllers/ApiCalls";
import AdditionalDetails from "../../CommonControllers/AdditionalDetails/AdditionalDetails";
import MultipleSelectionItem from "../GRN/MultipleSelectionItem";
import {
  getDomainSupplier,
  getdomainlocationlistactive,
} from "../../CommonControllers/Urls";

function PurchaseInvoiceOpening() {
  const [getSelectedItem, setSelectedItem] = useState(null);
  const [rowData, setRowData] = useState([]);
  const [supplierdata, setSupplierdata] = useState(null);
  const [igstappl, setigstappl] = useState(false);
  const useChangedRows = useSaveChangedRows();
  const [displayMessages, setDisplayMessages] = useState(false);
  const [previousPOList, setPreviousPOList] = useState([]);
  const shortguid = useShortguid();
  const tabStore = useContext(TabStore);
  const auth = useContext(AuthStore);
  const { Panel } = Collapse;
  const { getShortguid } = useShortguid();
  const fetchItems = useFetchItem();

  const [pageIndex, setPageIndex] = useState();
  console.log("Page Index is", pageIndex);

  useEffect(() => {
    var tabsArray = tabStore.state.tabs;
    for (let i = 0; i < tabsArray?.length; i++) {
      if (tabsArray[i].name == "TR998") {
        setPageIndex(i);
        break;
      }
    }
  }, []);

  const [supplierInvNo, setSupplierInvNo] = useState("");
  const [poNumber, setPoNumber] = useState("");

  const boxRef = useRef(null);
  const piecesRef = useRef(null);
  const itemSearch = useRef(null);
  const btnRef = useRef(null);
  const itemSearchdiv = useRef(null);

  const [box, setBox] = useState("");
  const [collapse, setCollapse] = useState(false);
  const [purchaseRate, setPurchaseRate] = useState(getSelectedItem?.purrate);
  const [selectedQty, setSelectedQty] = useState("");
  const [itemInformation, setItemInformation] = useState();
  const [acctualQty, setAcctualQty] = useState(); // Qty as in pieces in
  const [freeBox, setFreeBox] = useState("");
  const [freeQty, setFreeQty] = useState("");
  const [discount, setDiscount] = useState(0);
  const [discountPer, setDiscountPer] = useState(0);
  const [batchNo, setBatchNo] = useState(null);
  const [expDate, setExpDate] = useState("");
  const [manuDate, setManuDate] = useState("");
  const [showTable, setShowTable] = useState(true);
  const [getInfoFromPO, setInfoFromPO] = useState([]);

  const [change, setChange] = useState(0);

  // ---------------------Edit Transtion States------------------
  // Create useState for Show POP Of Search Bill
  const [showFilterModal, setshowFilterModal] = useState(false); //Create by Nohit
  // render
  const [countRender, setcountRender] = useState(0);
  const [piNumber, setPiNumber] = useState();
  const [location, setlocation] = useState();
  // --------------------------------------------------------------

  //~# State variables for date validation
  const [purchaseInvDate, setPurchaseInvDate] = useState(
    auth.state.userdata.openingbaldate
  );
  const [supplierInvDate, setSupplierInvDate] = useState(null);

  /// dropdown clear state//////
  const [modalcount, setModalcount] = useState(0);

  // Required For Multi Select
  const [displayPrevPO, setDisplayPrevPO] = useState(false);
  const [returnData, setreturnData] = useState([]); //To Get the data from Selection Component of Miltiple Selection
  console.log("Return Data from Prev Documents", returnData);
  const [count, setCount] = useState(0);
  // scan table validation rules
  const [validator, setValidator] = useState(0);
  const [totalQty, setTotalQty] = useState(0);
  //scan table
  const [scanitemarray, setscanitemarray] = useState([]);
  const [openItem, setOpenItem] = useState(false);
  const [scandata, setScandata] = useState([]);
  const [renderer, setRenderer] = useState(0);
  // KMK 06/04/2023 For Narration and Remarks
  const [narration, setNarration] = useState("");
  const [remarks, setRemarks] = useState("");

  // Update Data from TabStore
  console.log("When Trying to chec the Row Data", rowData);
  useEffect(() => {
    if (tabStore.state.tabs[pageIndex]?.changedRows?.dataFromEdit) {
      setRowData(tabStore.state.tabs[pageIndex]?.changedRows?.items);
    }
  }, [tabStore.state.tabs[pageIndex]?.changedRows?.dataFromEdit]);

  //this is to set supplier inv date from pi date
  console.log("manudate:", manuDate);
  function dateSetter() {
    var prdate = purchaseInvDate || auth?.state?.userdata?.workdate;

    return prdate;
  }
  console.log("rowdata above", rowData);
  const options = [
    {
      id: 1,
      optionName: "Purchase Order",
      apiurl: "filterpurchaseorder",
      supplierrecno: supplierdata?.supplierrecno,
      doctype: "PO",
    },
    {
      id: 2,
      optionName: "GRN",
      apiurl: "filtergrn",
      supplierrecno: supplierdata?.supplierrecno,
      doctype: "GRN",
    },
  ];
  console.log("expdate", expDate);
  console.log("manuDate", manuDate);
  const [pageDetails, setPageDetails] = useState();
  console.log("Page Details is", pageDetails);
  const payload = {
    tenantrecno: auth?.state?.userdata?.tenantrecno,
    domainrecno: auth?.state?.userdata?.domainrecno,
    userrolerecno: auth?.state?.userdata?.userrolerecno,
    domainuserrecno: auth.state.userdata.recno,
    fastpath: currentTabData(tabStore)?.name,
  };
  useEffect(() => {
    // Get Fast ath API
    getfastpathapi(payload).then((res) => {
      console.log("rr", res);
      if (res?.Success) {
        setPageDetails(res?.Message);
        console.log("Resposnse of Fast Path API", res.Message);
      }
    });
  }, []);
  console.log("returnData in PI", returnData);
  // KMK 06/04/2023 for automatically fetching the Narration and Remarks
  console.log("getSelectedItem in PI", getSelectedItem);
  useEffect(() => {
    if (!narration) {
      if (useChangedRows.getValue("narration")) {
        setNarration(useChangedRows.getValue("narration"));
      }
    }

    if (!remarks) {
      if (useChangedRows.getValue("remarks")) {
        setRemarks(useChangedRows.getValue("remarks"));
      }
    }
  }, [
    useChangedRows.getValue("remarks"),
    useChangedRows.getValue("narration"),
  ]);

  useEffect(() => {
    console.log("Get Selected Item is", getSelectedItem);
    if (getSelectedItem) {
      setPurchaseRate(Number(getSelectedItem?.purrate).toFixed(2));
    }
    setManuDate(
      getSelectedItem?.wantbatchno == 0 &&
        getSelectedItem?.manudatecompulsory == 0
        ? auth?.state?.userdata?.workdate
        : manuDate
    );
    setExpDate(
      getSelectedItem?.wantbatchno == 0 &&
        getSelectedItem?.expdatecompulsory == 0
        ? "29991231"
        : expDate
    );
    getSelectedItem?.wantbatchno ? setBatchNo("") : setBatchNo(".");
  }, [getSelectedItem]);

  useEffect(() => {
    setShowTable(false);

    setTimeout(() => {
      setShowTable(true);
    }, 200);
  }, [rowData?.length]);

  let grossAmt =
    (Number(box) * Number(getSelectedItem?.conversionfactor) +
      Number(selectedQty)) *
      Number(purchaseRate) || "00";

  let taxableAmt =
    (Number(box) * Number(getSelectedItem?.conversionfactor) +
      Number(selectedQty)) *
      Number(purchaseRate) -
      Number(discount) || "00";

  //~# Setting workdate
  let workdate = auth?.state?.userdata?.workdate;
  workdate = JSON.stringify(workdate);
  workdate = `${workdate?.slice(-2)}/${workdate?.slice(4, 6)}/${workdate?.slice(
    0,
    4
  )}`;

  const enterPress = (e) => {
    console.log("Enter Key is Preseed in Table");

    if (e.keyCode === 13) {
      e.preventDefault();
      const form = e.target.form;
      const index = Array.prototype.indexOf.call(form, e.target);
      if (form.elements[index + 1]) {
        form.elements[index + 1].focus();
      }
    }
  };
  useEffect(() => {
    console.log(
      "Tabs Store Data Value is",
      auth.state.userdata.openingbaldate,
      useChangedRows.getValue("trdate")
    );
    if (useChangedRows.getValue("refdate")) {
      setSupplierInvDate(useChangedRows.getValue("refdate"));
    } else {
      useChangedRows.updateValue("refdate", auth.state.userdata.openingbaldate);
      setSupplierInvDate(auth.state.userdata.openingbaldate);
    }

    if (useChangedRows.getValue("trdate")) {
      setPurchaseInvDate(useChangedRows.getValue("trdate"));
    } else {
      useChangedRows.updateValue("trdate", auth.state.userdata.openingbaldate);
      setPurchaseInvDate(auth.state.userdata.openingbaldate);
    }
  }, [useChangedRows.getValue("trdate")]);
  useEffect(() => {
    if (purchaseInvDate < supplierInvDate) {
      setSupplierInvDate(purchaseInvDate);
      useChangedRows.updateValue("refdate", purchaseInvDate);
    }
  }, [purchaseInvDate]);

  useEffect(() => {
    setAcctualQty(
      Number(box) * Number(getSelectedItem?.conversionfactor) +
        Number(selectedQty)
    );
  }, [box, selectedQty]);

  function setDatatoSupplierdata(e) {
    setSupplierdata(e);
    console.log("Supplierdata", e);
    // Get Suppliers Purchase Orders where status == "C"
    if (e) {
      getPurchaseOrdersForSupplier(e?.supplierrecno);
    }
  }

  const getPurchaseOrdersForSupplier = async (supplierdescn) => {
    const res = await apiCallwithBaseUrl("POST", "/filterpurchaseorder/", {
      supplierrecno: supplierdescn,
      withitems: true,
      status: "P",
    });
    setPreviousPOList(res);
  };

  const addItemstoTable = () => {
    // CHECKING FOR Validations
    console.log("addItemstoTable", manuDate, expDate);
    if (!purchaseRate || Number(purchaseRate) == 0) {
      alert("Please Enter Purchase Rate");
      return;
    }
    if (
      Number(box) + Number(selectedQty) + Number(freeBox) + Number(freeQty) <=
      0
    ) {
      alert("Box, Pieces, Free Box, and Boxes all cannot be zero");
      return;
    }

    console.log(" getSelectedItem?.wantbatchno", getSelectedItem?.wantbatchno);
    if (
      getSelectedItem?.wantbatchno == 1 &&
      (!batchNo || batchNo == undefined || batchNo == "")
    ) {
      alert("Batch Number Cannot be Empty");
      return;
    }

    // if (
    //   getSelectedItem?.wantbatchno ==1
    //   & manuDate==0&& expDate==0 )
    //  {
    //   alert("Mfg. Date and Expiry Date Cannot be Empty");
    //   return;
    // }

    if (
      getSelectedItem?.wantbatchno == 1 &&
      pageDetails?.wantmanudate == 1 &&
      (manuDate == 0 ||
        manuDate == undefined ||
        manuDate == "undefined" ||
        !manuDate)
    ) {
      alert("Manufacture Date Cannot be Empty");
      return;
    }
    if (
      getSelectedItem?.wantbatchno == 1 &&
      pageDetails?.wantexpdate == 1 &&
      (expDate == 0 ||
        expDate == undefined ||
        expDate == "undefined" ||
        !expDate)
    ) {
      alert("Expiry Date Cannot be Empty");
      return;
    }

    if (
      purchaseRate == 0 ||
      !purchaseRate ||
      purchaseRate == "" ||
      Number(purchaseRate) == 0
    ) {
      alert("Purchase Rate is Compulsary");
      return;
    }

    /// validation for batch if batch is true it should not asked for batch again and scan option will be available
    if (getSelectedItem?.wantbatchno) {
      if (!batchNo) {
        alert("Batch Cannot be Null");
        return;
      }
    }

    if (getSelectedItem) {
      var existingItem = rowData.filter((item) => {
        if (
          item.itemrecno == getSelectedItem.itemrecno &&
          item.itembatchno == batchNo
        ) {
          return item;
        }
      });

      if (existingItem?.length == 0) {
        // clearing all input values
        setBox("");

        setSelectedQty("");
        setPurchaseRate("");

        setFreeBox("");
        setFreeQty("");
        setDiscount("");
        setDiscountPer("");
        setBatchNo("");
        setExpDate(0);
        setManuDate(0); ///addItemstoTable   the manufacture date was no adding
        handleAdd(
          getSelectedItem,
          setRowData,
          igstappl,
          setSelectedItem,
          shortguid,
          purchaseRate,
          selectedQty,
          box,
          freeBox,
          freeQty,
          batchNo,
          expDate,
          discount,
          discountPer,
          manuDate,
          grossAmt,
          taxableAmt
        );
        itemSearch.current.focus();
      } else {
        alert("Item Already Present");
        setSelectedItem(null);
        itemSearch.current.focus();
      }
    }

    setSupplierInvNo("");
  };

  useEffect(() => {
    if (supplierdata?.igstappl) {
      setigstappl(true);
    } else {
      setigstappl(false);
    }
  }, [supplierdata]);

  useEffect(() => {
    // When Row Data Changes we Need to store that data to the tabsStore with Header Data
    var existingObject = useChangedRows.getValue();

    // Calculate Other Numbers and Inputs and store in the tabStore
    var status = "P";
    var trdate = "workdate";
    var approvalStatus = "P";
    var remark = "Need to add a field for that";
    var narration = "Need to ad a text field for this as well";
    var amount; // Calculate the total amount after tax
    var taxableamount; // Calculate the total taxable amount after tax
    var cgstamt; //Calculate the CGSTAMT
    var sgstamt; //Calculate teh SGST AMT
    var igstamt; //Calculat the IGSTAMT
    let grossAmountTotal;
    // var shortguid

    amount = rowData.reduce((total, item) => total + Number(item?.amount), 0);
    taxableamount = rowData.reduce(
      (total, item) => total + Number(item?.taxableamount),
      0
    );
    cgstamt = rowData.reduce((total, item) => total + Number(item?.cgstamt), 0);
    sgstamt = rowData.reduce((total, item) => total + Number(item?.sgstamt), 0);
    igstamt = rowData.reduce((total, item) => total + Number(item?.igstamt), 0);
    grossAmountTotal = rowData?.reduce(
      (total, item) => total + Number(item?.grossamount),
      0
    );
    let grossDIsc = rowData.reduce(
      (total, item) => total + Number(item?.discount),
      0
    );
    // shortguid =  existingObject.shortguid ? (existingObject.shortguid):( shortguid.getShortguid("PI") );
    console.log("new/old shortguid is", existingObject);

    existingObject = {
      ...existingObject,
      cgstamt: Number(cgstamt).toFixed(2),
      igstamt: Number(igstamt).toFixed(2),
      sgstamt: Number(sgstamt).toFixed(2),
      taxableamount: Number(taxableamount).toFixed(2),
      amount: Number(amount).toFixed(2),
      // shortguid: shortguid,
      items: rowData,

      // podate: purchaseInvDate,
      status: "9",
      grossAmountTotal: Number(grossAmountTotal).toFixed(2),
      discountamt: grossDIsc,
    };

    useChangedRows.updateValue(false, existingObject);
  }, [rowData]);

  //// validation for  save button
  // useEffect(() => {
  //   let validateData = "false";

  //   for (let i = 0; i < rowData?.length; i++) {
  //     console.log("RowData", rowData[i]);
  //     if (
  //       rowData[i]?.itemasbatch &&
  //       Number(rowData[i]?.qty) +
  //         Number(rowData[i]?.free ? rowData[i]?.free : 0) !=
  //         rowData[i]?.itembatcharray?.length
  //     ) {
  //       // console.log("VLAIDATIONS APPLICATION", rowData[i]?.itemasbatch,
  //       // Number(rowData[i]?.qty) +
  //       //   Number(rowData[i]?.free ? rowData[i]?.free : 0),
  //       //   rowData[i]?.itembatcharray?.length)
  //       console.log("Validations are True");
  //       validateData = "true";
  //     }
  //   }
  //   console.log("Validated IS", validateData);
  //   useChangedRows.updateValue("validated", validateData);
  // }, [rowData]);
  useEffect(() => {
    let isButtonDisabled = false;

    console.log("rowData:122", rowData);

    for (let i = 0; i < rowData.length; i++) {
      const {
        packingqty,
        packingextraqty,
        freepackingqty,
        freepackingextraqty,
        manufacturedate,
        expdate,
        itembatchno,
        purrate,
        expdatecompulsory,
        itemasbatch,
        manudatecompulsory,
        wantbatchno,
        changesalerateinbill,
      } = rowData[i];

      console.log(
        "isButtonDisabled:120",
        itembatchno,
        wantbatchno,
        expdatecompulsory,
        expdate,
        manufacturedate
      );
      console.log("rowData[i]?.itemasbatch", rowData[i]?.itemasbatch);

      // if ( (wantbatchno && (!itembatchno || itembatchno == "" || itembatchno == null || itembatchno == undefined))|| (expdatecompulsory && (!expdate || expdate == "undefined" || Number(expdate) == 0))||(manudatecompulsory && (!manufacturedate || manufacturedate == "undefined" || Number(manufacturedate) == 0)) || (packingqty === "0" && packingextraqty === "0") || (packingqty == "" && packingextraqty == "") || (packingqty === 0 && packingextraqty === "0") || (packingqty === "0" && packingextraqty === 0) || (packingqty === 0 && packingextraqty === 0) || (rowData[i]?.itemasbatch &&
      //   Number(rowData[i]?.qty) +
      //   Number(rowData[i]?.free ? rowData[i]?.free : 0) <
      //   rowData[i]?.itembatcharray?.length) || Number(purrate) == 0)

      if (
        (wantbatchno &&
          (!itembatchno ||
            itembatchno == "" ||
            itembatchno == null ||
            itembatchno == undefined)) ||
        (expdatecompulsory &&
          wantbatchno &&
          (!expdate || expdate == "undefined" || Number(expdate) == 0)) ||
        (manudatecompulsory &&
          wantbatchno &&
          (!manufacturedate ||
            manufacturedate == "undefined" ||
            Number(manufacturedate) == 0)) ||
        (rowData[i]?.itemasbatch &&
          Number(rowData[i]?.qty) +
            Number(rowData[i]?.free ? rowData[i]?.free : 0) !=
            rowData[i]?.itembatcharray?.length) ||
        Number(purrate) == 0
      ) {
        isButtonDisabled = true;

        break;
      } else if (
        (wantbatchno &&
          (!itembatchno ||
            itembatchno == "" ||
            itembatchno == null ||
            itembatchno == undefined)) ||
        // (packingqty === "0" && packingextraqty === "0") ||
        // (packingqty == "" && packingextraqty == "") ||
        // (packingqty === 0 && packingextraqty === "0") ||
        // (packingqty === "0" && packingextraqty === 0) ||
        // (packingqty === 0 && packingextraqty === 0) ||
        Number(purrate) == 0
      ) {
        isButtonDisabled = true;
        break;
      } else if (
        (wantbatchno &&
          (!itembatchno ||
            itembatchno == "" ||
            itembatchno == null ||
            itembatchno == undefined)) ||
        (!rowData[i]?.itemasbatch &&
          Number(rowData[i]?.qty) +
            Number(rowData[i]?.free ? rowData[i]?.free : 0) ==
            0)
      ) {
        isButtonDisabled = true;
        break;
      }
    }

    useChangedRows.updateValue(
      "validated",
      !isButtonDisabled ? "true" : "false"
    );
  }, [rowData]);

  useEffect(() => {
    // When Row Data Changes we Need to store that data to the tabsStore with Header Data
    var currentData = useChangedRows.getValue();
    if (!currentData?.shortguid) {
      useChangedRows.updateValue("shortguid", shortguid.getShortguid("PI"));
    }

    if (rowData?.length == 0) {
      var items = useChangedRows.getValue("items");
      if (items) {
        setRowData(items);
      }
    }
  }, []);

  useEffect(() => {
    // When Row Data Changes we Need to store that data to the tabsStore with Header Data
    var currentData = useChangedRows.getValue();
    if (!currentData?.shortguid) {
      useChangedRows.updateValue("shortguid", shortguid.getShortguid("PI"));
    }
    setSupplierdata(useChangedRows.getValue("supplierrecno"));
    setPiNumber(useChangedRows.getValue("billno"));
    setlocation(useChangedRows.getValue("locationrecno"));
    setSupplierInvDate(useChangedRows.getValue("refdate"));

    var items = useChangedRows.getValue("items");
    if (items) {
      setRowData(items);
    }
  }, [showFilterModal]);

  useEffect(() => {
    console.log(
      "Page Status is",
      tabStore.state.tabs[pageIndex]?.pageStatus,
      tabStore.state.tabs,
      pageIndex
    );
    if (tabStore.state.tabs[pageIndex]?.pageStatus == "Success") {
      console.log("Is this Page Executed", tabStore.state.tabs);
      setRowData([]);
      setigstappl(false);
      setSelectedItem(null);
      setSelectedQty(" ");
      setPurchaseRate(" ");
      setDatatoSupplierdata(null);
      setSupplierInvNo(null);
      setlocation(null);
      setPurchaseInvDate(auth.state.userdata.openingbaldate);
      setSupplierInvDate(0);
      setPiNumber("TBA");
      setFreeBox("");
      setFreeQty("");
      setBatchNo("");
      setBox("");
      // setManuDate(null)
      // setExpDate(null)
      useChangedRows.updateValue("shortguid", shortguid.getShortguid("PI"));
      // document.getElementById("po-exp-delivery-date").value = "";

      // document.getElementById("po-date").value = auth?.state?.userdata?.workdate;
      setModalcount((p) => p + 1);
      setTimeout(
        tabStore.dispatch({
          type: "UPDATE_DATA",
          fieldType: "pageStatus",
          text: null,
        }),
        1000
      );
    }
  }, [
    tabStore.state.tabs[pageIndex]?.pageStatus,
    Object.keys(currentTabData(tabStore)?.changedRows).length,
  ]);
  console.log("tabStore888", rowData, getSelectedItem);
  //console.log("tabStore888",rowData,manuDate)
  return (
    <div style={{ ...mainFrame }}>
      <Modal
        open={displayMessages}
        onCancel={() => {
          setRemarks(useChangedRows.getValue("remarks"));
          setNarration(useChangedRows.getValue("narration"));
          setDisplayMessages(!displayMessages);
        }}
        onOk={() => {
          useChangedRows.updateValue("remarks", remarks);
          useChangedRows.updateValue("narration", narration);
          setDisplayMessages(!displayMessages);
        }}
      >
        <div style={{ display: "flex", flexDirection: "column" }}>
          <p>Message from Supl</p>
          <Input
            id={"remarks"}
            placeholder="Enter Message for Supl"
            value={remarks}
            onChange={(e) => {
              setRemarks(e.target.value);
            }}
          ></Input>
          <br /> <p>Message for Self</p>
          <Input
            id={"narration"}
            placeholder="Enter Message for Self"
            value={narration}
            onChange={(e) => {
              setNarration(e.target.value);
            }}
          ></Input>
        </div>
      </Modal>
      {/* ===========modal multi select================ */}
      <Modal
        key={count}
        width={620}
        open={displayPrevPO}
        onCancel={() => {
          setDisplayPrevPO(!displayPrevPO);
          setCount((count) => count + 1);
        }}
        onOk={() => {
          console.log("Return Data while Oka Button is", returnData);
          let newArray = returnData.map((item) => {
            let pcsBox = convertToBoxAndPieces(
              Number(item?.selectedQty),
              Number(item?.conversionfactor)
            );
            console.log("item?.conversionFactor", item, pcsBox);
            console.log("PCS BOX", Number(item?.conversionfactor));
            var existingItem = [];
            if (!rowData || !item) {
              alert("Invalid data");
            } else {
              existingItem = rowData.filter(
                (it) => it?.itemrecno === item?.itemrecno
              );
              if (existingItem.length == 0) {
                console.log("Existing Item existingItem", existingItem, item);
                // Add item to the list
                handleAdd(
                  item,
                  setRowData,
                  item?.igstrate > 0 ? true : false,
                  setSelectedItem,
                  shortguid,
                  item?.purrate ? item.purrate : item?.rate,
                  pcsBox[1],
                  pcsBox[0],
                  0,
                  item.freeQuantity,
                  null,
                  0,
                  item?.discountamount > 0 ? item.discountamount : 0,
                  item?.discountamt > 0 ? item.discountamt : 0,
                  0,
                  item?.amount > 0 ? item.amount : 0,
                  item?.taxableamount > 0 ? item.taxableamount : 0,
                  auth?.state?.userdata?.workdate
                );

                // setreturnData([]);
              } else {
                console.log("Existing Item Array is", existingItem);
                // var totalBoxQty = existingItem.reduce((acc, el) => {
                //   console.log("Item Existing Array is ", item, el)

                //   (acc += Number(el?.packingextraqty))
                // }, 0) +  Number(pcsBox[0])
                // var totalPcsQty = existingItem.reduce((acc, el) => (acc += Number(el?.packingqty)), 0) +  Number(pcsBox[1])

                handleRemovePrev(item, setRowData, rowData);

                setTimeout(() => {
                  handleAdd(
                    item,
                    setRowData,
                    item?.igstrate > 0 ? true : false,
                    setSelectedItem,
                    shortguid,
                    item?.purrate ? item.purrate : item?.rate,
                    pcsBox[1],
                    pcsBox[0],
                    0,
                    item.freeQuantity,
                    null,
                    0,
                    item?.discountamount > 0 ? item.discountamount : 0,
                    item?.discountamt > 0 ? item.discountamt : 0,
                    0,
                    item?.amount > 0 ? item.amount : 0,
                    item?.taxableamount > 0 ? item.taxableamount : 0
                  );
                  // setreturnData([]);
                }, 200);
              }
            }
          });
          setreturnData([]);
          setDisplayPrevPO(!displayPrevPO);
          setCount((count) => count + 1);
        }}
      >
        <MultipleSelectionItem
          options={options}
          selectedItemsArray={returnData}
          returnValue={(e) => {
            console.log("Retiiiirjijijij", e);
            setreturnData(e);
          }}
          forFlag={"pi"}
          todate={purchaseInvDate}
        />
      </Modal>
      {/* ===========modal multi select end================ */}
      {/* =============================//*Edit Transaction*\\=================================== */}
      <Modal
        title="Search Bill"
        open={showFilterModal}
        onOk={() => {
          setshowFilterModal(false);
          setcountRender((p) => p + 1);
        }}
        onCancel={() => {
          setshowFilterModal(false);
          setcountRender((p) => p + 1);
        }}
        width={"100%"}
        style={{ top: 20 }}
        key={countRender}
      >
        <SearchFilterByBillNo
          fastpath="RP102"
          setshowFilterModal={setshowFilterModal}
          showFilterModal={showFilterModal}
        />
      </Modal>
      <Collapse collapsible="header" defaultActiveKey={["1"]}>
        {/* amol-390 - Panel name changes from "selection frame" to "Header details" */}
        <Panel header="Header Details" key="1">
          <div>
            {/* amol-temp - replace space tag with form tag and added style to form tag */}
            <form
              style={{
                display: "flex",
                flexWrap: "wrap",
                // gridTemplateColumns: "repeat(6, 1fr)",
                columnGap: "40px",
                rowGap: "15px",
                marginTop: "5px",
              }}
            >
              {/* Select Supplier */}
              <div onKeyDown={enterPress} style={{ zIndex: 10 }}>
                {/* <UserLocationList
                  setlocation={setlocation}
                  defaultSelection={location}
                  key={modalcount}
                /> */}
                <SaveDropdown
                  defaultValue={location}
                  //{Pradeep task id-398 }/// added isrequired prop

                  key={modalcount}
                  zIndex={1000}
                  // disabled={rowData?.length > 0 || transactionEdit ? true : false}
                  apikey={"locationrecno"}
                  minInput={0}
                  inputValue={20}
                  displayLabel={"Location"}
                  placeholder={"Location"}
                  apiurl={getdomainlocationlistactive}
                  selectionLabel={"descn"}
                  selectionValue={"locationrecno"} //# previous key was recno, changed to locationrecno as per network response
                  returnValue={setlocation}
                  width={"250px"}
                  alignment="column"
                />
              </div>
              <div
                onKeyDown={enterPress}
                style={{
                  pointerEvents: rowData?.length > 0 ? "none" : "auto",
                }}
              ></div>
              <div>
                <div
                  onKeyDown={enterPress}
                  style={{
                    pointerEvents: rowData?.length > 0 ? "none" : "auto",
                  }}
                >
                  {/* <SupplierSaveDropDown
                    setSupplier={setDatatoSupplierdata}
                    defaultSelection={supplierdata}
                    key={modalcount}
                  /> */}
                  <SaveDropdown
                    defaultSelected={supplierdata}
                    apikey={"supplierrecno"}
                    minInput={0}
                    inputValue={20}
                    displayLabel={"Supplier"}
                    //{Pradeep task id-398 }/// added isrequired prop
                    isrequired
                    key={modalcount}
                    apiurl={getDomainSupplier}
                    selectionLabel={"supplierdescn"}
                    selectionValue={"supplierrecno"}
                    returnValue={setDatatoSupplierdata}
                    width="250px"
                    alignment="column"
                    zIndex={1000}
                    disabled={rowData?.length > 0 ? true : false}
                  />
                </div>
              </div>

              <div onKeyDown={enterPress}>
                {/* <p>PI Number</p> */}
                {/* <SaveTextInput
                  alignment="column"
                  displayLabel={"PI No"}
                  placeholder={"PI No"}
                  apikey={"billno"}
                  // defaultValue={elem?.defaultValue}
                  change
                  // returnValue={(e) => {
                  //   console.log("FC", e);
                  //   setterFunction(e, elem);
                  //   storeData(e, elem?.apiKey);
                  // }}
                  key={modalcount}
                />  */}
                <div
                  onKeyDown={enterPress}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <SaveTextInput
                    displayLabel={"PI No."}
                    pageIndex={pageIndex}
                    alignment="column"
                    // displayLabel={elem?.columndisplay}
                    placeholder={"Enter PI No."}
                    apikey={"billno"}
                    // defaultValue={elem?.defaultValue}
                    change
                    // returnValue={(e) => {
                    //   console.log("FC", e);
                    //   setterFunction(e, elem);
                    //   storeData(e, elem?.apiKey);
                    // }}
                    key={modalcount}
                    isrequired
                  />
                </div>
              </div>
              {/* <div onKeyDown={enterPress} >
                <div style={{position:"relative"}}>
              <label
              // htmlFor={id}
              className={`label ${'focused'}`}
              style={{
                zIndex:1000,
                whiteSpace: "nowrap",
                wordWrap:"unset",
                position: 'absolute',
                top: '-19px',
                left: '12px',
                //backgroundColor: 'white',
                padding: '0 4px',
                fontSize:'12px',
                color:'black',
                pointerEvents: 'none',
                transform:
                 'translateY(0)'
                   ,
                transition:
                  'top 0.3s, transform 0.3s, font-size 0.3s, color 0.3s'
              }}
            >
             
                PI Number
             
            </label>
                <input
                  style={{ ...piInputStyles, marginTop:"0px" }}
                  placeholder={piNumber ? piNumber : ""}
                  //disabled
                />
                </div>
              </div> */}

              <div
                onKeyDown={enterPress}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  pointerEvents: rowData?.length > 0 ? "none" : "auto",
                }}
              >
                <SaveDatePicker
                  defaultValue={purchaseInvDate}
                  disabled={rowData && rowData.length > 0 ? 1 : 0}
                  id="tr002-purchase-invoice"
                  displayLabel="PI Date"
                  apiKey="trdate"
                  returnValue={(e) => {
                    setPurchaseInvDate(e);
                  }}
                  dateLessthan={auth.state.userdata.openingbaldate} //! If this is false, postAlertMsg will trigger
                  postAlertMsg={`PI Date cannot be greater than Opening Date`}
                  //key={modalcount}
                />
              </div>
              {/* nirmal-406  added in header details panel */}
              <div
                onKeyDown={enterPress}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <SaveTextInput
                  displayLabel={"Supl Inv No."}
                  pageIndex={pageIndex}
                  apikey={"refbillno"}
                  placeholder={"Enter Supl Inv No."}
                  defaultValue={supplierInvNo}
                  key={modalcount}
                  isrequired={1}
                />
              </div>
              {/* nirmal-406  added in header details panel */}
              <div
                onKeyDown={enterPress}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <SaveDatePicker
                  defaultValue={supplierInvDate}
                  id={"tr002-supplier-invoice-date"}
                  displayLabel={"Supl Inv Date"}
                  apiKey="refdate"
                  dateLessthan={auth.state.userdata.openingbaldate}
                  returnValue={(e) => {
                    setSupplierInvDate(e);
                  }}
                  // dateLessthan={purchaseInvDate ? purchaseInvDate : auth?.state?.userdata?.workdate}
                  postAlertMsg={`Supplier Invoice Date cannot be greater than PI Date`}
                  //key={modalcount}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                onKeyDown={enterPress}
              >
                <AdditionalDetails
                  filterOption={pageDetails?.additionalDetails}
                />
              </div>

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                onKeyDown={enterPress}
              >
                <button
                  className="btn"
                  // style={{ ...button }}
                  onClick={(e) => {
                    e.preventDefault();
                    setDisplayMessages(!displayMessages);
                  }}
                >
                  Add / View Messages
                </button>
              </div>

              <div
                style={{
                  ...previous,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                onKeyDown={enterPress}
              >
                <button
                  // style={{ ...button }}
                  className="btn"
                  onClick={(e) => {
                    e.preventDefault();
                    if (supplierdata) {
                      setDisplayPrevPO(!displayPrevPO);
                      setCount((count) => count + 1);
                    } else {
                      alert("Supplier not selected");
                    }
                  }}
                >
                  Previous Documents
                </button>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                onKeyDown={(e) => {
                  if (e.keyCode == 13) {
                    e.preventDefault();
                    itemSearchdiv.current.focus();
                  }
                }}
              >
                <SaveCheckbox
                  apikey={"isPrint"}
                  displayLabel="Print PI"
                  editable
                  key={modalcount}
                />
              </div>
            </form>
          </div>
        </Panel>

        <Panel header="Items Details" key="2">
          <div>
            <Space size="large" style={{ alignItems: "end" }}>
              {/* amol-390 - change width from 250px to 220px */}
              <div style={{ alignItems: "end" }}>
                <form style={{ ...callbackTextInputStyles, alignItems: "end" }}>
                  <div
                    style={{ zIndex: 2, position: "relative", width: "220px" }}
                  >
                    {/* <ItemSearch
                  itemSearchRef={itemSearch} /// task id-256 rajendra auto focus issue  itemSearchRef-inputRef because in common components inputRef is used
                  forFlag={"P"}
                  returnValue={(e) => {
                    setSelectedItem(e);
                  }}
                  supplierdata={supplierdata?.supplierrecno}
                  dropdown
                  defaultSelectedItem={getSelectedItem}
                  forPage={"PO"}
                  selection={false}
                  trdate={purchaseInvDate}
                  barcodeReturnValue={(e) => {
                    //{ start Pradeep task id -dc start }

                    // Write a function to add to existing Items and check only if it has serial number

                    if (e?.itemasbatch == 1) {
                      fetchItems
                        .addBarcodeScannedItemToTable(
                          rowData,
                          e,
                          "PIF",
                          igstappl,
                          pageDetails,"openingpi"
                        )
                        .then((res) => {
                          console.log("New ROws Data", res);
                          // setRowData(res);
                          //  nirmal-purchaseinvoice ::: Solved the issue of deleting the entire item from the grid if the old barcode is entered.
                          if (res?.length == 0) {
                            setRowData(rowData);
                            // return
                          } else {
                            setRowData(res);
                            setChange(change + 1);
                          }
                        })
                        .catch((err) => {
                          console.log("error", err);
                          alert(err);
                        });
                    } else {
                      setSelectedItem(e);
                    }
                  }}
                /> */}
                    <ItemSearch
                      itemSearchRef={itemSearch} /// task id-256 rajendra auto focus issue  itemSearchRef-inputRef because in common components inputRef is used
                      forFlag={"P"}
                      returnValue={(e) => {
                        setSelectedItem(e);
                      }}
                      supplierdata={supplierdata?.supplierrecno}
                      dropdown
                      defaultSelectedItem={getSelectedItem}
                      forPage={"openingpi"}
                      selection={false}
                      trdate={purchaseInvDate}
                      barcodeReturnValue={(e) => {
                        //{ start Pradeep task id -dc start }
                        console.log(
                          "itembatcharrayfound",
                          e.itembatcharrayfound.length
                        );
                        if (e?.serialnofound === true) {
                          // Write a function to add to existing Items and check only if it has serial number
                          if (e.itembatcharrayfound.length > 0) {
                            if (e?.itemasbatch == 1) {
                              fetchItems
                                .addBarcodeScannedItemToTable(
                                  rowData,
                                  e,
                                  "PIF",
                                  igstappl,
                                  pageDetails,
                                  "openingpi"
                                )
                                .then((res) => {
                                  console.log("New ROws Data", res);
                                  // setRowData(res);
                                  //  nirmal-purchaseinvoice ::: Solved the issue of deleting the entire item from the grid if the old barcode is entered.
                                  if (res?.length == 0) {
                                    setRowData(rowData);
                                    // return
                                  } else {
                                    setRowData(res);
                                    setChange(change + 1);
                                  }
                                })
                                .catch((err) => {
                                  console.log("error", err);
                                  alert(err);
                                });
                            } else {
                              setSelectedItem(e);
                            }
                          } else {
                            alert("Stock Not Found");
                          }
                        } else {
                          alert("Item Not Present in System");
                        }
                      }}
                    />

                    {getSelectedItem ? (
                      <p>
                        {getSelectedItem?.itemdescn} <br />
                        Available Qty : {getSelectedItem?.availablestock}
                      </p>
                    ) : null}
                  </div>
                  {/* amol-temp - Added * symbol to the mandatory field and added margin-left 5% to div style */}
                  {getSelectedItem?.packingextrarecno > 0 &&
                  pageDetails?.wantpackingextra ? (
                    <div onKeyDown={enterPress} style={{ marginLeft: "20px" }}>
                      <p>
                        Box <span style={{ color: "red" }}>*</span>
                        {getSelectedItem?.conversionfactor &&
                          +getSelectedItem?.conversionfactor}
                      </p>
                      <input
                        //  disabled={getSelectedItem?.packingextrarecno>0? false:true}
                        ref={boxRef}
                        style={{ ...inputStyles }}
                        value={box}
                        onChange={(e) => {
                          const regex = /^[0-9\b]+$/;
                          const value = e.target.value;
                          if (value === "" || regex.test(value)) {
                            setBox(value);
                          }
                        }}
                        type="number"
                      />
                    </div>
                  ) : null}
                  <div onKeyDown={enterPress} style={{ marginLeft: "20px" }}>
                    <p>
                      Pieces<span style={{ color: "red" }}>*</span>
                    </p>
                    <input
                      ref={piecesRef}
                      style={{ ...inputStyles }}
                      // disabled={getSelectedItem?.packingextrarecno==0? false:true}
                      value={selectedQty}
                      type="number"
                      onChange={(e) => {
                        const regex = /^[0-9\b]+$/;
                        const value = e.target.value;
                        if (value === "" || regex.test(value)) {
                          setSelectedQty(value);
                        }
                      }}
                    />
                  </div>

                  {pageDetails?.wantfreeqty ? (
                    <>
                      {getSelectedItem?.packingextrarecno > 0 ? (
                        <div onKeyDown={enterPress}>
                          <p>Free Boxes</p>
                          <input
                            ref={piecesRef}
                            style={{ ...inputStyles }}
                            // disabled={getSelectedItem?.packingextrarecno>0? false:true}
                            value={freeBox}
                            type="number"
                            onChange={(e) => {
                              const regex = /^[0-9\b]+$/;
                              const value = e.target.value;
                              if (value === "" || regex.test(value)) {
                                setFreeBox(value);
                              }
                            }}
                          />
                        </div>
                      ) : null}
                      <div onKeyDown={enterPress}>
                        <p>Free Pieces</p>
                        <input
                          ref={piecesRef}
                          style={{ ...inputStyles }}
                          // disabled={getSelectedItem?.packingextrarecno==0? false:true}
                          value={freeQty}
                          type="number"
                          onChange={(e) => {
                            const regex = /^[0-9\b]+$/;
                            const value = e.target.value;
                            if (value === "" || regex.test(value)) {
                              setFreeQty(value);
                            }
                          }}
                        />
                      </div>
                    </>
                  ) : null}

                  {getSelectedItem?.wantbatchno ? (
                    <>
                      {" "}
                      <div onKeyDown={enterPress}>
                        <p>
                          Batch No.<span style={{ color: "red" }}>*</span>
                        </p>
                        <input
                          ref={piecesRef}
                          style={{
                            ...inputStyles,
                            width: "160px",
                            textAlign: "left",
                          }}
                          value={batchNo}
                          onChange={(e) => setBatchNo(e.target.value)}
                          onBlur={(e) => {
                            /// batch validation if itemsbatch is true or false if batch is true it should not asked for batch again
                            if (getSelectedItem?.wantbatchno) {
                              console.log("Batch No", getSelectedItem);
                              if (!e.target.value) {
                                alert("Batch Cannot Be Empty");
                              }
                              return;
                            }
                          }}
                        />
                      </div>
                      {pageDetails?.wantmanudate > 0 ? (
                        <div onKeyDown={enterPress}>
                          <p>
                            Mfg. Date <span style={{ color: "red" }}>*</span>
                          </p>
                          <CallbackDateInput
                            defaultValue={
                              getSelectedItem?.wantbatchno == 0 &&
                              getSelectedItem?.manudatecompulsory == 0
                                ? auth?.state?.userdata?.workdate
                                : manuDate
                            }
                            id={"tr002-manufacture-date-callback"}
                            // displayLabel={"Expiry Date"}
                            apiKey={"manudate"}
                            returnValue={(e) => {
                              console.log("!manuDate", manuDate, e);
                              if (!manuDate && !e) {
                                {
                                  /*Kaushik : Added !e to check if response is coming */
                                }

                                setManuDate(auth?.state?.userdata?.workdate);
                              } else {
                                setManuDate(e);
                              }
                              console.log(
                                "Expiry Date should be greater than Mfg. Date",
                                e
                              );
                              document
                                .getElementById("tr002-exp-date-callback")
                                .focus();
                            }}
                            initial
                            dateLessthan={purchaseInvDate}
                            key={modalcount}
                          />
                        </div>
                      ) : null}
                      {pageDetails?.wantexpdate > 0 ? (
                        <div onKeyDown={enterPress}>
                          <p>
                            Expiry Date <span style={{ color: "red" }}>*</span>
                          </p>
                          <CallbackDateInput
                            defaultValue={
                              getSelectedItem?.wantbatchno == 0 &&
                              getSelectedItem?.expdatecompulsory == 0
                                ? "99991231"
                                : expDate
                            }
                            id={"tr002-exp-date-callback"}
                            // displayLabel={"Expiry Date"}
                            apiKey={"manudate"}
                            initial
                            dateGreaterthan={manuDate} //! If this is false, postAlertMsg will trigger
                            postAlertMsg="Expiry Date should not be lesser than Mfg. Date"
                            returnValue={(e) => {
                              if (e <= manuDate) {
                                document.getElementById(
                                  "po-manufacture-date-callback"
                                ).style.color = "red";
                                setExpDate(0);
                              } else {
                                setExpDate(e);
                              }
                            }}
                            key={modalcount}
                          />
                        </div>
                      ) : null}
                    </>
                  ) : null}

                  <div onKeyDown={enterPress}>
                    <p>
                      Rate <span style={{ color: "red" }}>*</span>
                    </p>
                    <input
                      type="number"
                      style={{ ...inputStyles, width: "100px" }}
                      value={purchaseRate}
                      onChange={(e) => {
                        if (getSelectedItem?.changepurrateinbill) {
                          const regex = /^\d*(\.\d{0,2})?$/;
                          const value = e.target.value;
                          if (value === "" || regex.test(value)) {
                            setPurchaseRate(value);
                          }
                        }
                      }}
                      onBlur={(e) => {
                        const regex = /^[0-9\b]+$/;
                        const value = e.target.value;
                        console.log("TR002 rate value", value);
                        if (value == "0.00" || value == "0.0") {
                          alert("Cannot enter 0.00 as rate");
                          return false;
                        }

                        if (value === "" || regex.test(value)) {
                          if (value != 0 && value > 0) {
                            if (
                              box == 0 &&
                              selectedQty == 0 &&
                              freeQty == 0 &&
                              freeBox == 0
                            ) {
                              alert("Enter non-zero values in Box / Pieces");
                              boxRef.current.focus();
                            } else {
                              btnRef.current.focus();
                            }
                          } else {
                            alert("Cannot enter 0 as rate");
                            return false;
                          }
                        }
                      }}
                    />
                  </div>
                  <div onKeyDown={enterPress}>
                    <p>Gross Amount</p>
                    <input
                      disabled
                      value={Number(grossAmt).toFixed(2)}
                      style={{ ...grossAmtStyle, width: 120 }}
                    />
                  </div>

                  <div onKeyDown={enterPress}>
                    <p>Discount %</p>
                    <input
                      style={{ ...inputStyles, width: "75px" }}
                      value={discountPer}
                      type="number"
                      id="tr002-discount-perc"
                      onChange={(e) => {
                        const regex = /^\d*(\.\d{0,2})?$/;
                        const value = e.target.value;

                        if (
                          (value === "" && value < 99.99) ||
                          (regex.test(value) && value < 99.99)
                        ) {
                          setDiscountPer(value);
                          console.log("Gross Amt IN Discount", grossAmt);
                          var dicsountAmount =
                            (Number(grossAmt) * Number(value)) / 100;
                          setDiscount(dicsountAmount.toFixed(2));
                        }
                      }}
                      onBlur={(e) => {
                        let temp = e.target.value;
                        temp != "" &&
                          setDiscountPer(Number(e.target.value)?.toFixed(2));
                        var dicsountAmount =
                          (Number(grossAmt) * Number(e.target.value)) / 100;
                        setDiscount(dicsountAmount.toFixed(2));
                      }}
                    />
                  </div>

                  <div onKeyDown={enterPress}>
                    <p>Discount</p>
                    <input
                      style={{ ...inputStyles, width: "110px" }}
                      value={discount}
                      type="number"
                      disabled={discountPer !== 0}
                      onChange={(e) => setDiscount(e.target.value)}
                    />
                  </div>
                  <div onKeyDown={enterPress}>
                    <p>Taxable Amount</p>
                    <input
                      disabled
                      value={Number(taxableAmt).toFixed(2)}
                      style={{ ...grossAmtStyle, width: 120 }}
                    />
                  </div>
                  <div onKeyDown={enterPress}>
                    <Button
                      // style={{ ...btnStyles }}
                      className="btn item-search-btn"
                      icon={<IoAperture size="1.2rem" />}
                      size="large"
                      onClick={() => {
                        addItemstoTable();
                      }}
                    >
                      Add
                    </Button>
                  </div>
                  {/* <SaveCheckbox apikey={"addgrn"} displayLabel="Add GRN" editable /> */}
                </form>
              </div>
            </Space>
          </div>
        </Panel>
      </Collapse>

      {/* Table for Selection  */}
      <div style={tableStyles}>
        {rowData?.length > 0 && (
          <ItemsTable
            rowData={rowData}
            setRowData={setRowData}
            igstappl={igstappl}
            scandata={scandata}
            setScandata={setScandata}
            setOpenItem={setOpenItem}
            purchaseInvDate={purchaseInvDate}
            useChangedRows={useChangedRows}
            change={change}
            pageDetails={pageDetails}
          />
        )}
      </div>
      {openItem && (
        <ScanTable
          data={scandata}
          openItem={openItem}
          setOpenItem={setOpenItem}
          rowData={rowData}
          setRowData={setRowData}
          renderer={renderer}
          setRenderer={setRenderer}
          setValidator={setValidator}
          supplierrecno={supplierdata.supplierrecno}
          forFlag={"P"}
          pageDetails={pageDetails}
          forPage={"openingpi"}
          pageIndex={pageIndex}
        />
      )}
    </div>
  );
}

export default PurchaseInvoiceOpening;

const itemTablePStyles = {
  textAlign: "right",
};
// amol-temp - mainFrame width change to 99vw from 95vw
const mainFrame = {
  width: "99vw",
  margin: "auto",
};

const topRowFilters = {
  gap: "24px",
  display: "flex",
  alignItems: "center",
};

const tableStyles = {
  position: "relative",
  zIndex: 0,
  marginTop: "24px",
};

// applying padding to align input rows inline
const callbackTextInputStyles = {
  display: "flex",
  flexWrap: "wrap",
  alignItems: "center",
  // amol-390 - change in CSS gap 10px to 24px
  gap: "24px",
  paddingTop: "10px",
};

// add to table btn styles
const btnStyles = {
  display: "flex",
  alignItems: "center",
  // amol-390 - change in CSS gap 8px to 24px
  gap: "24px",
  // padding:"3px",
  width: "90px",
  borderRadius: "8px",
  padding: "6px",
};

const inputStyles = {
  padding: "7px",
  border: "1px solid #dfdfdf",
  width: "90px",
  fontSize: "16px",
  marginTop: "2px",
  borderRadius: "8px",
  textAlign: "right",
  height: "36px",
};

const grossAmtStyle = {
  textAlign: "right",
  border: "1px solid #dfdfdf",
  padding: "2px",
  fontSize: "18px",
  width: "90px",
  borderRadius: "8px",
  marginTop: "2px",
  height: "36px",
  // border:"1px solid red"
};

const piInputStyles = {
  // amol-temp - margintop from 4px to 8 px
  marginTop: "8px",
  border: "1px solid #dfdfdf",
  borderRadius: "8px",
  width: "180px",
  padding: "9px",
  fontSize: "16px",
  // backgroundColor:"white"
};

const button = {
  marginTop: "7px",
  textAlign: "right",
  border: "1px solid #dfdfdf",
  height: "37px",
};

const previous = {
  // justifyContent:"center",
  // border:"1px solid red",
  textAlign: "center",
};

import React from "react";

const PrintAmount = ({ item }) => {

  // function formatCurrency(value) {
  //     let lastThree = value.substring(value.length - 3);
  //     let otherNumbers = value.substring(0, value.length - 3);
  //     if (otherNumbers != "") lastThree = "," + lastThree;
  //     const transformedInteger =
  //       otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree;
  //       console.log("transformedInteger", transformedInteger)
  //       if (transformedInteger.includes(".")) {
  //         return transformedInteger;
  //       } else {
  //         return `${transformedInteger}.00`;
  //       }
  //   }

  function formatNumberIndianStandard(transformedInteger) {
    if (item?.fieldtype === "amount") {
      const formattedNumber = parseFloat(transformedInteger).toLocaleString(
        "en-IN",
        {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }
      );

      if (formattedNumber === "NaN") {
        return {
          value: transformedInteger,
          textAlign: "left",
        };
      } else {
        let textAlign = "left";
        if (formattedNumber.includes(".")) {
          textAlign = "right";
        }
        return {
          value: formattedNumber.includes(".") ? formattedNumber : `${formattedNumber}.00`,
          textAlign: textAlign,
        };
      }
    } else {

      const formattedNumber = parseFloat(transformedInteger)

      if (isNaN(formattedNumber)) {
        return {
          value: transformedInteger,
          textAlign: "left",
        };
      } else {
        return {
          value: transformedInteger,
          textAlign: "right",
        };
      }

    }
  }

  const formattedResult = formatNumberIndianStandard(item?.fieldvalue);
  const textAlignStyle = formattedResult.textAlign === "right" ? "right" : "left";

  return (
    <div style={{
      position: "absolute"
    }}>
      <p
        style={{
          padding: "3px",
          fontSize: `${item?.fontsize}px`,
          position: "absolute",
          textAlign: textAlignStyle,
          border: item?.printbox === 1 ? "1px solid black" : null,
          left: `${item?.fieldx}px`,
          top: `${item?.fieldy}px`,
          fontFamily: item?.fontname,
          fontWeight: item?.fontbold === 1 ? "bold" : null,
          fontStyle: item?.fontitalic === 1 ? "italic" : null,
          width: `${item?.fieldxto}px`,
          height: `${item?.fieldyto}px`,
          wordWrap:"break-word"
        }}
      >
        {formattedResult.value}
      </p>
    </div>
  );
};

export default PrintAmount;

import React, { useEffect, useState, useContext } from "react";
import { grnColumns } from "./GRNTableColumns";
import { Table, Spin } from "antd";

import { TabStore } from "../../TabsStructure/TabsStore";
import { currentTabData } from "../../CommonControllers/Variable";
import { AuthStore } from "../../Auth/AuthStore";
import useSaveChangedRows from "../../CommonControllers/Hooks/useSaveChangedRows";
import ScanTable from "../../CommonControllers/Components/ScanTable/ScanTable";

const GrnTable = ({
  rowData,
  setRowData,
  igstappl,
  grnDate,
  supplierdata,
  pageDetails,
  setItemForSelection,
  setopenBatch,
  setopenBatchcount,
  pageIndex,
}) => {
  const [showTable, setShowTable] = useState(true);
  const [openItem, setOpenItem] = useState(false);
  const [scandata, setScandata] = useState();
  const [renderer, setRenderer] = useState(0);
  const useChangedRows = useSaveChangedRows();

  // scan table validation rules
  const [validator, setValidator] = useState(0);
  const [totalQty, setTotalQty] = useState(0);

  const tabs = useContext(TabStore);
  const auth = useContext(AuthStore);

  let workdate = auth?.state?.userdata?.workdate;

  useEffect(() => {
    console.log(`tr001 items table re-rendered ?? after deleting`);
    setShowTable(false);

    setTimeout(() => {
      setShowTable(true);
    }, 200);
    if (validator == totalQty && validator !== 0 && totalQty !== 0) {
      useChangedRows.updateValue("validated", "true");
    }
    //  else if(validator==0&&-){
    //   useChangedRows.updateValue("validated", "false");
    //  }
    else {
      useChangedRows.updateValue("validated", "false");
    }
  }, [rowData?.length, renderer]);
  console.log("111", scandata);

  let accPieces = rowData.reduce((acc, item) => {
    return Number(item.acceptedpieces) + acc;
  }, 0);
  let accBoxes = rowData.reduce((acc, item) => {
    return Number(item.acceptedboxes) + acc;
  }, 0);
  let pieces = rowData.reduce((acc, item) => {
    return Number(item.pieces) + acc;
  }, 0);
  let box = rowData.reduce((acc, item) => {
    return Number(item.box) + acc;
  }, 0);
  //// validation for  save button
  useEffect(() => {
    // setValidator((p)=>p+validator)

    // const total=rowData.reduce((acc,item)=> item.qty+acc,0)
    // console.log("RAjendra",validator,total)
    // setTotalQty(total)
    // if(validator==totalQty&&validator!==0 &&totalQty!==0){
    //   useChangedRows.updateValue("validated", "true");
    //  }
    //  else{
    //   useChangedRows.updateValue("validated", "false");
    //  }

    //  Check if the scanned items and total quantity is same for itemasbatch

    let validateData = "true";

    for (let i = 0; i < rowData?.length; i++) {
      console.log("RowData", rowData);
      if (
        rowData[i]?.itemasbatch &&
        Number(rowData[i]?.qty) +
          Number(rowData[i]?.free ? rowData[i]?.free : 0) !=
          rowData[i]?.itembatcharray?.length
      ) {
        validateData = "false";
      }
    }
    useChangedRows.updateValue("validated", validateData);
  }, [openItem]);
  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        {showTable ? (
          <Table
            scroll={{
              y: 305,
            }}
            size="small"
            bordered
            className="tr013-items-grid tfoot tr td"
            // className={open ? "scantable" : "not-scantable"}
            pagination={false}
            columns={grnColumns(
              igstappl,
              setRowData,
              rowData,
              openItem,
              setOpenItem,
              scandata,
              setScandata,
              workdate,
              setRenderer,
              grnDate,
              pageDetails,
              setItemForSelection,
              setopenBatch,
              setopenBatchcount
            )?.filter((item) => !item.hidden)}
            dataSource={rowData}
            onChange={(e) => {
              console.log("ONCHANGE HANDLE", e);
            }}
            summary={(pageData) => {
              console.log("tr003 page data ??", pageData);
              let boxesTotal = 0;
              let piecesTotal = 0;
              let accBoxesTotal = 0;
              let accPiecesTotal = 0;

              pageData.forEach(
                ({
                  packingextraqty,
                  packingqty,
                  packingextraqtyaccepted,
                  packingqtyaccepted,
                  itembatcharray,
                }) => {
                  console.log("packingqtyaccepted", pageData, packingqty);
                  boxesTotal += Number(packingextraqty);
                  piecesTotal += Number(packingqty);
                  accBoxesTotal += Number(packingextraqtyaccepted);
                  accPiecesTotal += packingqtyaccepted
                    ? Number(packingqtyaccepted)
                    : Number(itembatcharray?.length);
                }
              );

              return (
                <>
                  <Table.Summary fixed>
                    {
                      <Table.Summary.Row>
                        <Table.Summary.Cell></Table.Summary.Cell>
                        <Table.Summary.Cell>
                          <p style={{ ...pStyles, textAlign: "left" }}>Total</p>
                        </Table.Summary.Cell>

                        <Table.Summary.Cell></Table.Summary.Cell>
                        {pageDetails?.wantpackingextra ? (
                          <Table.Summary.Cell>
                            <p style={{ ...pStyles }}>{boxesTotal}</p>
                          </Table.Summary.Cell>
                        ) : null}

                       
                          <Table.Summary.Cell>
                            <p style={{ ...pStyles }}>
                              {accPiecesTotal ? accPiecesTotal : 0}
                            </p>
                          </Table.Summary.Cell>
                       
                        {
                          <Table.Summary.Cell>
                            <p style={{ ...pStyles }}>
                              {piecesTotal ? piecesTotal : 0}
                            </p>
                          </Table.Summary.Cell>
                        }
                       {pageDetails?.wantmanudate === 1 ? <Table.Summary.Cell>
                          <p style={{ ...pStyles }}>{null}</p>
                        </Table.Summary.Cell>  : null}

                        {pageDetails?.wantbatchno ? (
                          <Table.Summary.Cell></Table.Summary.Cell>
                        ) : null}

                        {pageDetails?.wantmanudate === 1 ? (
                          <Table.Summary.Cell></Table.Summary.Cell>
                        ) : null}
                        {/* {
                        pageDetails?.wantpackingextra ? <Table.Summary.Cell>
                        <p style={{ ...pStyles }}>{null}</p>
                      </Table.Summary.Cell> : null
                      } */}
                        {/* {  pageDetails?.wantexpdate === 1 ? <Table.Summary.Cell></Table.Summary.Cell> : null} */}
                        {/* <Table.Summary.Cell></Table.Summary.Cell> */}
                        {/* <Table.Summary.Cell></Table.Summary.Cell> */}
                      </Table.Summary.Row>
                    }
                  </Table.Summary>
                </>
              );
            }}
          />
        ) : (
          <div style={{ ...loaderStyle }}>
            <Spin size="large" />
          </div>
        )}
        {openItem && (
          <ScanTable
            data={scandata}
            openItem={openItem}
            setOpenItem={setOpenItem}
            rowData={rowData}
            setRowData={setRowData}
            renderer={renderer}
            setRenderer={setRenderer}
            setValidator={setValidator}
            forFlag={"P"}
            supplierrecno={supplierdata?.supplierrecno}
            pageIndex={pageIndex}
          />
        )}
      </div>
    </>
  );
};

export default GrnTable;

const loaderStyle = {
  height: "max-content",
  width: "max-content",
  display: "flex",
  alignContent: "center",
  margin: "50px auto",
};

const pStyles = {
  textAlign: "right",
  fontWeight: "700",
  fontSize: "16px",
  color: "black",
};

const scantable = {
  width: "40%",
};

import React, { useState, useRef, useContext, useEffect } from "react";
import PrintModal from "./PrintModal";
import { useReactToPrint } from "react-to-print";
import { AuthStore } from "../../Auth/AuthStore";
import { apiCallwithBaseUrl } from "../ApiCalls";
import { TabStore } from "../../TabsStructure/TabsStore";
import { currentTabData } from "../Variable";
import PrintingModal from "./PrintingModal";
import PrintPage from "./PrintingComponents/PrintPage";
// import jsondatawithpageno from "./csvjsonwithpageno.json";
// import csvjsonsalebill from "./csvjsonsalebill.json";
import saleinvoiceprint from "./saleinvoiceprint.json";

import _ from "lodash";

const ReactPrint = (props) => {
  const [isPrintModalOpen, setIsPrintModalOpen] = useState(false);
  const printRef = useRef(null);
  const auth = useContext(AuthStore);
  const [printData, setPrintData] = useState();
  const [NewformatprintData, setNewformatPrintData] = useState({});
  const tabStore = useContext(TabStore);

  //  console.log("jsondatawithpageno",jsondatawithpageno)
  // const pages = Array.from(new Set(saleinvoiceprint.map((item) => item.pageno)));

  const headerdata = NewformatprintData?.printarray?.filter(
    (item) => item?.headerfooter == 0
  );
  const bodyheader = NewformatprintData?.printarray?.filter(
    (item) => item?.headerfooter == 1
  );
  const rowitemsdata = NewformatprintData?.printarray?.filter(
    (item) => item?.headerfooter == 2
  );
  const footerdata = NewformatprintData?.printarray?.filter(
    (item) => item?.headerfooter == 3
  );
  const rowdatacolumnheader = NewformatprintData?.printarray?.filter(
    (item) => item?.headerfooter == 2 && item.caption === item.fieldvalue
  );


  let headerheight = headerdata?.[0]?.fieldy;
  for (let i = 0; i < headerdata?.length; i++) {
    if (headerdata?.[i]?.fieldy > headerheight) {
      headerheight = headerdata?.[i]?.fieldy;
    }
  }
  let bodyheaderheight = bodyheader?.[0]?.fieldy;
  for (let i = 0; i < bodyheader?.length; i++) {
    if (bodyheader?.[i]?.fieldy > bodyheaderheight) {
      bodyheaderheight = bodyheader?.[i]?.fieldy;
    }
  }

  let footerheightmaximum = footerdata?.[0]?.fieldy;
  for (let i = 0; i < footerdata?.length; i++) {
    if (footerdata?.[i]?.fieldy > footerheightmaximum) {
      footerheightmaximum = footerdata?.[i]?.fieldy;
    }
  }

  let footerheightminimum = footerdata?.[0]?.fieldy;
  for (let i = 0; i < footerdata?.length; i++) {
    if (footerdata?.[i]?.fieldy < footerheightminimum) {
      footerheightminimum = footerdata?.[i]?.fieldy;
    }
  }



  //let localpagenumber=1;
  let localpage = [];
  //let indexCounter = 0;
  let maxheightoflastpage = 0;
  const Pageheight = NewformatprintData?.height;
  const footerheight = footerheightmaximum - footerheightminimum;
  let ModifyPrintData = _.sortBy(NewformatprintData?.printarray, [
    "fieldy",
  ])?.map((i, index) => {
    if (i.fieldy >= Pageheight) {
      let newpageno =
        i.pageno + Math.floor((i.fieldy + headerheight + 38) / Pageheight);


      // const x = parseInt(i.fieldy / NewformatprintData?.height);
      // const nextpageno = Math.round((i.fieldy)/(NewformatprintData?.height))
      // if(((i.fieldy)-(NewformatprintData?.height)+maxfieldy+40)>(NewformatprintData?.height)){
      //   console.log("i.fieldy",(i.fieldy))
      //   indexCounter=indexCounter+1
      // }
      // console.log("indexCounter",indexCounterfieldy)
      // const modifyfieldy = ((i.fieldy)-(nextpageno>x?(newpage-1):nextpageno)*(NewformatprintData?.height)+(nextpageno>x?(newpage-1):nextpageno)*(maxfieldy+39))
      // const modifyfieldy = ((i.fieldy)-(2)*(NewformatprintData?.height)+(2)*(maxfieldy+39))
      //return {...i,pageno:((i.fieldy)-(NewformatprintData?.height)+maxfieldy+40)>(NewformatprintData?.height)?((i.pageno+ Math.round((i.fieldy)/(NewformatprintData?.height)))):newpage,
      //   return {...i,pageno:((i.fieldy)-(NewformatprintData?.height)+maxfieldy+40)>(NewformatprintData?.height)?(nextpageno>x?(newpage):newpage):(nextpageno+1),
      //   // return {...i,pageno:((i.fieldy)-(NewformatprintData?.height)+maxfieldy+40)?(i.pageno+newpage):newpage,
      //     fieldy:((i.fieldy)-(NewformatprintData?.height)+maxfieldy+40)>(NewformatprintData?.height)?modifyfieldy
      //   :((i.fieldy)-(NewformatprintData?.height)+maxfieldy+40)
      // }

      if (
        i.fieldy - (newpageno - 1) * (Pageheight - headerheight - 38) >
        Pageheight
      ) {
        newpageno = newpageno + 1;
        // return {...i,pageno:newpageno+1,fieldy:i.fieldy-((newpageno-1)*(NewformatprintData?.height-header1height-40))};
      }
      localpage.push(newpageno);
      maxheightoflastpage =
        i.fieldy - (newpageno - 1) * (Pageheight - headerheight - 38);
      return {
        ...i,
        pageno: newpageno,
        fieldy: i.fieldy - (newpageno - 1) * (Pageheight - headerheight - 38),
      };
    } else {
      return i;
    }
  });


  if (localpage?.length > 0) {
    const newlocalpage = Array.from(new Set(localpage));

    console.log("newlocalpage", newlocalpage);
    newlocalpage?.map((item) => {
      headerdata.map((i) => {
        ModifyPrintData.push({ ...i, pageno: item });
      });
    });

    newlocalpage?.map((item) => {
      rowdatacolumnheader.map((i) => {
        ModifyPrintData.push({ ...i, pageno: item, fieldy: headerheight + 26 });
      });
    });

    let maxpageno = newlocalpage[0];

    for (let i = 0; i < newlocalpage.length; i++) {
      if (newlocalpage[i] > maxpageno) {
        maxpageno = newlocalpage[i];
      }
    }

    if (Pageheight - maxheightoflastpage <= footerheight) {
      headerdata.map((i) => {
        ModifyPrintData.push({ ...i, pageno: maxpageno + 1 });
      });

      maxpageno = maxpageno + 1;
    }


    footerdata.map((i) => {
      ModifyPrintData.push({ ...i, pageno: maxpageno });
    });

    ModifyPrintData = ModifyPrintData.filter(
      (item) => !(item.headerfooter === 3 && item.pageno === 1)
    );
    
  } else {
    let maxheightoffirstpage = rowitemsdata?.[0]?.fieldy;
    for (let i = 0; i < rowitemsdata?.length; i++) {
      if (rowitemsdata?.[i]?.fieldy > maxheightoffirstpage) {
        maxheightoffirstpage = rowitemsdata?.[i]?.fieldy;
      }
    }


    if (Pageheight - maxheightoffirstpage - headerheight <= footerheight) {
      headerdata.map((i) => {
        ModifyPrintData.push({ ...i, pageno: i.pageno + 1 });
      });
      footerdata.map((i) => {
        ModifyPrintData.push({ ...i, pageno: i.pageno + 1 });
      });
      ModifyPrintData = ModifyPrintData.filter(
        (item) => !(item.headerfooter === 3 && item.pageno === 1)
      );
    }
  }
  // if (localpage === 2) {
  //   footerdata.map((i) => {
  //       ModifyPrintData.push({ ...i, pageno:localpage});
  //   });
  //   ModifyPrintData = ModifyPrintData.filter((item) => !(item.headerfooter === 3 && item.pageno === 1));
  // }
  // console.log("Printdata in reactprintcomp",ModifyPrintData)
  // rowitemsdata=rowitemsdata?.map((i)=>{
  // if(i.fieldy>NewformatprintData?.height){
  // return {...i,pageno:i.pageno+1}
  // }else{
  //   return i
  // }
  //   })
  // console.log("rowitemsdata in reactprintcomp",rowitemsdata)
  //  let filteritem= rowitemsdata.filter((i)=>i.fieldy>NewformatprintData.height)
  //  if(filteritem.length>0){
  //   return {...i,pageno:i.pageno+1}
  //  }

  //finding the page numbers from ModifyPrintData array and store it in pages array
  const pages = Array.from(
    new Set(ModifyPrintData?.map((item) => item?.pageno))
  );
 
  let printUrl = "";



  //~* Calling the api for document printer and popuating data in modal screen on click of preview button
  async function printerData() {

    if (typeof props?.ins == "string") {
      const payload = {
        domainrecno: auth?.state?.userdata?.domainrecno,
        tenantrecno: auth?.state?.userdata?.tenantrecno,
        instrumentno: props?.ins,
        billno: props?.ins,
        userrolerecno: auth?.state?.userdata?.userrolerecno,
      };
      const resp = await apiCallwithBaseUrl("POST", props.url, payload);

   
      if (resp.Success && resp.Message?.length > 0) {
       
  
        setPrintData(resp?.Message);
        setNewformatPrintData(resp?.Message[0]);
      } else {
        alert("No Document Found");
      }
    } else {
      if (props?.ins?.length > 0) {
        //         let m=""
        //         props.ins.forEach((element) => {
        // m=m+element+","
        //         })

        let newPrintData = [];
        // let convert=props?.ins.map(item => `"${item}"`).join(",")
        // let convertedData = convert.replace(/\\/g, '')

        // for (let i = 0; i < props?.ins?.length; i++) {
        let payload = {
          domainrecno: auth?.state?.userdata?.domainrecno,
          tenantrecno: auth?.state?.userdata?.tenantrecno,
          instrumentno: null,
          billno: null,
          data: props?.ins.join(","),
          userrolerecno: auth?.state?.userdata?.userrolerecno,
        };
   
        let resp = await apiCallwithBaseUrl("POST", props.url, payload);


        if (resp.Success && resp.Message?.length > 0) {
          setNewformatPrintData(resp?.Message);

          newPrintData = newPrintData?.filter((item) => {
            if (
              item?.documentDetails?.[0].value1 !=
              resp?.Message?.[0]?.documentDetails?.[0].value1
            ) {
              return item;
            }
          });
          newPrintData.push(resp?.Message[0]);
        }
        // }
        setPrintData(newPrintData);
      }
    }

    //~% /documentprinttrial/
  }

  //~# Print bill setting, using hook by react-to-print
  const printModal = useReactToPrint({
    content: () => printRef.current,
    onBeforePrint: () => setIsPrintModalOpen(false),
    removeAfterPrint: true,
    // documentTitle: printData[0]["smallHeading"],
    onAfterPrint: () => {
      // Remove the Print URL and Invoicve Number
      alert("Printing is Finished");
      tabStore.dispatch({
        type: "UPDATE_PRINT",
        printUrl: null,
        printIns: null,
      });

      tabStore.dispatch({
        type: "UPDATE_DATA",
        fieldType: "pageStatus",
        text: "",
      });
      tabStore.dispatch({
        type: "UPDATE_DATA",
        fieldType: "selectedDocuments",
        text: [],
      });
    },
    onPrintError: () => {
      auth.state.contextHolder.open({
        message: `An Error has occured`,
        description: "An Error with the Printer",
        duration: 3,
        style: { background: "#efa7a7" },
      });
      tabStore.dispatch({
        type: "UPDATE_PRINT",
        printUrl: null,
        printIns: null,
      });

      tabStore.dispatch({
        type: "UPDATE_DATA",
        fieldType: "pageStatus",
        text: "",
      });
    },
  });

  useEffect(() => {
    if (tabStore.state.tabs[tabStore.state.tabIndex]?.pageStatus == "Success") {
      setTimeout(() => {
        printerData();
      }, 500);

      // tabStore.dispatch({
      //   type: "UPDATE_DATA",
      //   fieldType: "pageStatus",
      //   text:null,
      // })
    }
  }, [tabStore.state?.printUrl, currentTabData(tabStore)]);

  useEffect(() => {

    if (printData) {
      setTimeout(() => {
        setIsPrintModalOpen(true);
      }, 1000);
    }
  }, [printData]);

  const Message = NewformatprintData?.items;

  const columnsinfo = NewformatprintData?.columnsinfo;



  const batchSize = 7;
  const batches = [];
  for (let i = 0; i < Message?.length; i += batchSize) {
    const batch = Message?.slice(i, i + batchSize);
    batches.push(batch);
  }

  const headerHeight = headerheight + bodyheaderheight;
  const availableContentHeight = 1200 - headerHeight - footerheight;

  let totalHeight = 0;
  let firstPageData = [];
  let remainingData = [];
  const transformedData = [];

  if (Message) {
    for (const row of Message) {
      if (auth?.state?.userdata?.domainrecno === 100) {
        //for seperating wms and bcore code

        if (row?.testcertificateno) {
          const testcertificatenos = row?.testcertificateno
            ?.split(",")
            ?.filter((item) => item?.trim() !== "");
          const serialnos = row?.serialno
            ?.split(",")
            ?.filter((item) => item?.trim() !== "");


          let isFirstObject = true;

          for (let i = 0; i < testcertificatenos.length; i++) {
            const testcertificateno = testcertificatenos[i];
            const serialno = serialnos ? serialnos[i] : null;

            const newRow = {
              testcertificateno,
              serialno,
              itembatchno: null,
              ...Object.keys(row).reduce((acc, key) => {
                if (
                  key !== "testcertificateno" &&
                  key !== "serialno" &&
                  isFirstObject
                ) {
                  acc[key] = row[key];
                } else if (
                  key !== "testcertificateno" &&
                  key !== "serialno" &&
                  !isFirstObject
                ) {
                  acc[key] = typeof row[key] === "number" ? null : "";
                }
                return acc;
              }, {}),
            };

            transformedData.push(newRow);
            isFirstObject = false;
          }
        } else if (!row?.testcertificateno && row?.serialno) {
          const serialnos = row?.serialno
            ?.split(",")
            ?.filter((item) => item?.trim() !== "");

     

          let isFirstObject = true;

          for (let i = 0; i < serialnos.length; i++) {
            const serialno = serialnos[i];

            const newRow = {
              serialno,
              ...Object.keys(row).reduce((acc, key) => {
                if (
                  key !== "testcertificateno" &&
                  key !== "serialno" &&
                  isFirstObject
                ) {
                  acc[key] = row[key];
                } else if (
                  key !== "testcertificateno" &&
                  key !== "serialno" &&
                  !isFirstObject
                ) {
                  acc[key] = typeof row[key] === "number" ? null : "";
                }
                return acc;
              }, {}),
            };

            transformedData.push(newRow);
            isFirstObject = false;
          }
        } else {
          const newRow = {
            ...row,
          };

          transformedData.push(newRow);
        }
      } else {
        if (row?.itembatchno) {
          const itembatchnos = row?.itembatchno
            ?.split(",")
            ?.filter((item) => item?.trim() !== "");
          const expdates = row?.expdate
            ?.split(",")
            ?.filter((item) => item?.trim() !== "");

       

          let isFirstObject = true;

          for (let i = 0; i < itembatchnos.length; i++) {
            const itembatchno = itembatchnos[i];
            const expdate = expdates ? expdates[i] : null;

            const newRow = {
              itembatchno,
              expdate,
              ...Object.keys(row).reduce((acc, key) => {
                if (
                  key !== "itembatchno" &&
                  key !== "expdate" &&
                  isFirstObject
                ) {
                  acc[key] = row[key];
                } else if (
                  key !== "itembatchno" &&
                  key !== "expdate" &&
                  !isFirstObject
                ) {
                  acc[key] = typeof row[key] === "number" ? null : "";
                }
                return acc;
              }, {}),
            };

            transformedData.push(newRow);
            isFirstObject = false;
          }
        } else if (!row?.itembatchno && row?.expdate) {
          const expdates = row?.expdate
            ?.split(",")
            ?.filter((item) => item?.trim() !== "");



          let isFirstObject = true;

          for (let i = 0; i < expdates.length; i++) {
            const expdate = expdates[i];

            const newRow = {
              expdate,
              ...Object.keys(row).reduce((acc, key) => {
                if (
                  key !== "itembatchno" &&
                  key !== "expdate" &&
                  isFirstObject
                ) {
                  acc[key] = row[key];
                } else if (
                  key !== "itembatchno" &&
                  key !== "expdate" &&
                  !isFirstObject
                ) {
                  acc[key] = typeof row[key] === "number" ? null : "";
                }
                return acc;
              }, {}),
            };

            transformedData.push(newRow);
            isFirstObject = false;
          }
        } else {
          const newRow = {
            ...row,
          };

          transformedData.push(newRow);
        }
      }
    }
  }



  if (transformedData) {
    for (const row of transformedData) {
      let itembatch = "";
      let testcerno = "";

      let rowHeight = 34;

      // if (row?.itembatchno !== "" && row?.itembatchno !== undefined ) {
      //   itembatch = row?.itembatchno
      //     ?.split(",")
      //     .filter((item) => item?.trim() !== "");
      //   console.log("itembatchno", row?.itembatchno, itembatch);

      //    if (itembatch?.length > 8 && itembatch?.length < 14) {
      //     rowHeight = 200;
      //   }

      //  else if (itembatch?.length > 5 && itembatch?.length < 8) {
      //     rowHeight = 150;
      //   }

      // }

      // if (row?.testcertificateno !== "" && row?.testcertificateno !== undefined ) {
      //   itembatch = row?.testcertificateno
      //     ?.split(",")
      //     .filter((item) => item?.trim() !== "");
      //   console.log("itembatchno", row?.itembatchno, itembatch);

      //   if (itembatch?.length > 14 ) {
      //     rowHeight = 300;
      //   }

      //    if (itembatch?.length > 8 && itembatch?.length < 14) {
      //     rowHeight = 200;
      //   }

      //  else if (itembatch?.length > 5 && itembatch?.length < 8) {
      //     rowHeight = 150;
      //   }
      //   else if(itembatch?.length > 2 && itembatch?.length < 5){
      //     rowHeight = 50;
      //   }

      // }
      // else if (row?.serialno !== "" && row?.serialno !== undefined ) {
      //   let serialno = row?.serialno
      //     ?.split(",")
      //     .filter((item) => item?.trim() !== "");
      //   console.log("itembatchno", row?.serialno, serialno);

      //    if (serialno?.length > 8 && serialno?.length < 14) {
      //     rowHeight = 200;
      //   }

      //  else if (serialno?.length > 5 && serialno?.length < 8) {
      //     rowHeight = 150;
      //   }
      //   else if(serialno?.length > 2 && serialno?.length < 5){
      //     rowHeight = 50;
      //   }

      // }

      totalHeight += rowHeight;

 

      if (totalHeight <= availableContentHeight) {
        firstPageData.push(row);
      } else {
        remainingData.push(row);
      }
    }
  }



  let topHeight = "";

  if (auth?.state?.userdata?.domainrecno === 100) {
    topHeight = headerHeight - 20;
  } else {
    topHeight = headerHeight - 50;
  }

  const firstPageComponent = (
    <PrintPage
      key="firstPage"
      printModal={printModal}
      openModal={isPrintModalOpen}
      // jsondatawithpageno={csvjsonsalebill}
      // PrintData={saleinvoiceprint}
      PrintData={ModifyPrintData}
      topHeight={topHeight}
      // pageNumber={pageNumber}
      totalPageCount={pages.length} // Pass the total page count
      rowitemsdata={firstPageData}
      pageheight={NewformatprintData?.height}
      pagewidth={NewformatprintData?.width}
      columnsinfo={columnsinfo}
      Messages={firstPageData}
      firstPage={1}
      lastPageIndex={remainingData?.length > 0 ? 0 : 2}
    />
  );

  const remainingPagesComponents = [];
  if (remainingData.length > 0) {
    const newavailableContentHeight = 1200 - headerheight - footerheight;

    let rowHeight = 34;
    let itembatch = "";

    for (const row of remainingData) {
      // if (row?.itembatchno !== "" && row?.itembatchno !== undefined ) {
      //   itembatch = row?.itembatchno
      //     ?.split(",")
      //     .filter((item) => item?.trim() !== "");
      //   console.log("itembatchno", row?.itembatchno, itembatch);
      //    if (itembatch?.length > 8 && itembatch?.length < 14) {
      //     rowHeight = 200;
      //   }
      //  else if (itembatch?.length > 5 && itembatch?.length < 8) {
      //     rowHeight = 150;
      //   }
      // }
      // if (row?.testcertificateno !== "" && row?.testcertificateno !== undefined ) {
      //   itembatch = row?.testcertificateno
      //     ?.split(",")
      //     .filter((item) => item?.trim() !== "");
      //   console.log("itembatchno", row?.itembatchno, itembatch);
      //   if (itembatch?.length > 14) {
      //     rowHeight = 300;
      //   }
      //   else if (itembatch?.length > 8 && itembatch?.length < 14) {
      //     rowHeight = 200;
      //   }
      //  else if (itembatch?.length > 5 && itembatch?.length < 8) {
      //     rowHeight = 120;
      //   }
      // }
      // else if (row?.serialno !== "" && row?.serialno !== undefined ) {
      //   let serialno = row?.serialno
      //     ?.split(",")
      //     .filter((item) => item?.trim() !== "");
      //   console.log("itembatchno", row?.serialno, serialno);
      //    if (serialno?.length > 8 && serialno?.length < 14) {
      //     rowHeight = 200;
      //   }
      //  else if (serialno?.length > 5 && serialno?.length < 8) {
      //     rowHeight = 120;
      //   }
      //   else if(serialno?.length > 2 && serialno?.length < 5){
      //     rowHeight = 50;
      //   }
      // }
    }

    const remainingPages = Math.ceil(
      (remainingData.length * rowHeight) / newavailableContentHeight
    );

    const lastPageIndex = remainingPages - 1;

    let remainingPageIndex = 0;
    for (let i = 0; i < remainingPages; i++) {
      const nextPageData = remainingData.slice(
        remainingPageIndex,
        remainingPageIndex + newavailableContentHeight / rowHeight
      );



      remainingPagesComponents.push(
        <PrintPage
          key={`remainingPage${i}`}
          printModal={printModal}
          openModal={isPrintModalOpen}
          // jsondatawithpageno={csvjsonsalebill}
          // PrintData={saleinvoiceprint}
          PrintData={ModifyPrintData}
          topHeight={headerheight + 50}
          columnsinfo={columnsinfo}
          // pageNumber={pageNumber}
          totalPageCount={pages.length} // Pass the total page count
          rowitemsdata={firstPageData}
          pageheight={NewformatprintData?.height}
          pagewidth={NewformatprintData?.width}
          Messages={nextPageData}
          firstPage={0}
          lastPageIndex={i === lastPageIndex ? 2 : 0}
        />
      );

      remainingPageIndex += newavailableContentHeight / rowHeight;
    }
  }



  const calculateHeight = (NewformatprintData) => {
    const headerdata = NewformatprintData?.printarray?.filter(
      (item) => item?.headerfooter == 0
    );
    const bodyheader = NewformatprintData?.printarray?.filter(
      (item) => item?.headerfooter == 1
    );
    const rowitemsdata = NewformatprintData?.printarray?.filter(
      (item) => item?.headerfooter == 2
    );
    const footerdata = NewformatprintData?.printarray?.filter(
      (item) => item?.headerfooter == 3
    );
    const rowdatacolumnheader = NewformatprintData?.printarray?.filter(
      (item) => item?.headerfooter == 2 && item.caption === item.fieldvalue
    );

    let headerheight = headerdata?.[0]?.fieldy;
    for (let i = 0; i < headerdata?.length; i++) {
      if (headerdata?.[i]?.fieldy > headerheight) {
        headerheight = headerdata?.[i]?.fieldy;
      }
    }
    let bodyheaderheight = bodyheader?.[0]?.fieldy;
    for (let i = 0; i < bodyheader?.length; i++) {
      if (bodyheader?.[i]?.fieldy > bodyheaderheight) {
        bodyheaderheight = bodyheader?.[i]?.fieldy;
      }
    }

    let footerheightmaximum = footerdata?.[0]?.fieldy;
    for (let i = 0; i < footerdata?.length; i++) {
      if (footerdata?.[i]?.fieldy > footerheightmaximum) {
        footerheightmaximum = footerdata?.[i]?.fieldy;
      }
    }

    let footerheightminimum = footerdata?.[0]?.fieldy;
    for (let i = 0; i < footerdata?.length; i++) {
      if (footerdata?.[i]?.fieldy < footerheightminimum) {
        footerheightminimum = footerdata?.[i]?.fieldy;
      }
    }

    let localpage = [];
    let maxheightoflastpage = 0;
    const Pageheight = NewformatprintData?.height;
    const footerheight = footerheightmaximum - footerheightminimum;
    let ModifyPrintData = _.sortBy(NewformatprintData?.printarray, [
      "fieldy",
    ])?.map((i, index) => {
      if (i.fieldy >= Pageheight) {
        let newpageno =
          i.pageno + Math.floor((i.fieldy + headerheight + 38) / Pageheight);

        console.log(
          "rowitemsdata modify==>",
          newpageno,
          i.fieldy,
          i.fieldy - (newpageno - 1) * (Pageheight - headerheight - 38)
        );

        if (
          i.fieldy - (newpageno - 1) * (Pageheight - headerheight - 38) >
          Pageheight
        ) {
          newpageno = newpageno + 1;
        }
        localpage.push(newpageno);
        maxheightoflastpage =
          i.fieldy - (newpageno - 1) * (Pageheight - headerheight - 38);
        return {
          ...i,
          pageno: newpageno,
          fieldy: i.fieldy - (newpageno - 1) * (Pageheight - headerheight - 38),
        };
      } else {
        return i;
      }
    });

    if (localpage?.length > 0) {
      const newlocalpage = Array.from(new Set(localpage));


      newlocalpage?.map((item) => {
        headerdata.map((i) => {
          ModifyPrintData.push({ ...i, pageno: item });
        });
      });

      newlocalpage?.map((item) => {
        rowdatacolumnheader.map((i) => {
          ModifyPrintData.push({
            ...i,
            pageno: item,
            fieldy: headerheight + 26,
          });
        });
      });

      let maxpageno = newlocalpage[0];

      for (let i = 0; i < newlocalpage.length; i++) {
        if (newlocalpage[i] > maxpageno) {
          maxpageno = newlocalpage[i];
        }
      }

      if (Pageheight - maxheightoflastpage <= footerheight) {
        headerdata.map((i) => {
          ModifyPrintData.push({ ...i, pageno: maxpageno + 1 });
        });

        maxpageno = maxpageno + 1;
      }

      footerdata.map((i) => {
        ModifyPrintData.push({ ...i, pageno: maxpageno });
      });

      ModifyPrintData = ModifyPrintData.filter(
        (item) => !(item.headerfooter === 3 && item.pageno === 1)
      );

    } else {
      let maxheightoffirstpage = rowitemsdata?.[0]?.fieldy;
      for (let i = 0; i < rowitemsdata?.length; i++) {
        if (rowitemsdata?.[i]?.fieldy > maxheightoffirstpage) {
          maxheightoffirstpage = rowitemsdata?.[i]?.fieldy;
        }
      }

      if (Pageheight - maxheightoffirstpage - headerheight <= footerheight) {
        headerdata.map((i) => {
          ModifyPrintData.push({ ...i, pageno: i.pageno + 1 });
        });
        footerdata.map((i) => {
          ModifyPrintData.push({ ...i, pageno: i.pageno + 1 });
        });
        ModifyPrintData = ModifyPrintData.filter(
          (item) => !(item.headerfooter === 3 && item.pageno === 1)
        );
      }
    }

    //finding the page numbers from ModifyPrintData array and store it in pages array
    const pages = Array.from(
      new Set(ModifyPrintData?.map((item) => item?.pageno))
    );

    let printUrl = "";

    const Message = NewformatprintData?.items;

    const columnsinfo = NewformatprintData?.columnsinfo;

   

    const batchSize = 7;
    const batches = [];
    for (let i = 0; i < Message?.length; i += batchSize) {
      const batch = Message?.slice(i, i + batchSize);
      batches.push(batch);
    }

    const headerHeight = headerheight + bodyheaderheight;
    const availableContentHeight = 1200 - headerHeight - footerheight;
  
    let totalHeight = 0;
    let firstPageData = [];
    let remainingData = [];
    const transformedData = [];
  
    if (Message) {
      for (const row of Message) {
        if (auth?.state?.userdata?.domainrecno === 100) {
          //for seperating wms and bcore code
  
          if (row?.testcertificateno) {
            const testcertificatenos = row?.testcertificateno
              ?.split(",")
              ?.filter((item) => item?.trim() !== "");
            const serialnos = row?.serialno
              ?.split(",")
              ?.filter((item) => item?.trim() !== "");
  
        
  
            let isFirstObject = true;
  
            for (let i = 0; i < testcertificatenos.length; i++) {
              const testcertificateno = testcertificatenos[i];
              const serialno = serialnos ? serialnos[i] : null;
  
              const newRow = {
                testcertificateno,
                serialno,
                itembatchno: null,
                ...Object.keys(row).reduce((acc, key) => {
                  if (
                    key !== "testcertificateno" &&
                    key !== "serialno" &&
                    isFirstObject
                  ) {
                    acc[key] = row[key];
                  } else if (
                    key !== "testcertificateno" &&
                    key !== "serialno" &&
                    !isFirstObject
                  ) {
                    acc[key] = typeof row[key] === "number" ? null : "";
                  }
                  return acc;
                }, {}),
              };
  
              transformedData.push(newRow);
              isFirstObject = false;
            }
          } else if (!row?.testcertificateno && row?.serialno) {
            const serialnos = row?.serialno
              ?.split(",")
              ?.filter((item) => item?.trim() !== "");
  
     
  
            let isFirstObject = true;
  
            for (let i = 0; i < serialnos.length; i++) {
              const serialno = serialnos[i];
  
              const newRow = {
                serialno,
                ...Object.keys(row).reduce((acc, key) => {
                  if (
                    key !== "testcertificateno" &&
                    key !== "serialno" &&
                    isFirstObject
                  ) {
                    acc[key] = row[key];
                  } else if (
                    key !== "testcertificateno" &&
                    key !== "serialno" &&
                    !isFirstObject
                  ) {
                    acc[key] = typeof row[key] === "number" ? null : "";
                  }
                  return acc;
                }, {}),
              };
  
              transformedData.push(newRow);
              isFirstObject = false;
            }
          } else {
            const newRow = {
              ...row,
            };
  
            transformedData.push(newRow);
          }
        } else {
          if (row?.itembatchno) {
            const itembatchnos = row?.itembatchno
              ?.split(",")
              ?.filter((item) => item?.trim() !== "");
            const expdates = row?.expdate
              ?.split(",")
              ?.filter((item) => item?.trim() !== "");
  
          
  
            let isFirstObject = true;
  
            for (let i = 0; i < itembatchnos.length; i++) {
              const itembatchno = itembatchnos[i];
              const expdate = expdates ? expdates[i] : null;
  
              const newRow = {
                itembatchno,
                expdate,
                ...Object.keys(row).reduce((acc, key) => {
                  if (
                    key !== "itembatchno" &&
                    key !== "expdate" &&
                    isFirstObject
                  ) {
                    acc[key] = row[key];
                  } else if (
                    key !== "itembatchno" &&
                    key !== "expdate" &&
                    !isFirstObject
                  ) {
                    acc[key] = typeof row[key] === "number" ? null : "";
                  }
                  return acc;
                }, {}),
              };
  
              transformedData.push(newRow);
              isFirstObject = false;
            }
          } else if (!row?.itembatchno && row?.expdate) {
            const expdates = row?.expdate
              ?.split(",")
              ?.filter((item) => item?.trim() !== "");
  
        
  
            let isFirstObject = true;
  
            for (let i = 0; i < expdates.length; i++) {
              const expdate = expdates[i];
  
              const newRow = {
                expdate,
                ...Object.keys(row).reduce((acc, key) => {
                  if (
                    key !== "itembatchno" &&
                    key !== "expdate" &&
                    isFirstObject
                  ) {
                    acc[key] = row[key];
                  } else if (
                    key !== "itembatchno" &&
                    key !== "expdate" &&
                    !isFirstObject
                  ) {
                    acc[key] = typeof row[key] === "number" ? null : "";
                  }
                  return acc;
                }, {}),
              };
  
              transformedData.push(newRow);
              isFirstObject = false;
            }
          } else {
            const newRow = {
              ...row,
            };
  
            transformedData.push(newRow);
          }
        }
      }
    }

  
    if (transformedData) {
      for (const row of transformedData) {
        let itembatch = "";
        let testcerno = "";
  
        let rowHeight = 34;
  
        // if (row?.itembatchno !== "" && row?.itembatchno !== undefined ) {
        //   itembatch = row?.itembatchno
        //     ?.split(",")
        //     .filter((item) => item?.trim() !== "");
        //   console.log("itembatchno", row?.itembatchno, itembatch);
  
        //    if (itembatch?.length > 8 && itembatch?.length < 14) {
        //     rowHeight = 200;
        //   }
  
        //  else if (itembatch?.length > 5 && itembatch?.length < 8) {
        //     rowHeight = 150;
        //   }
  
        // }
  
        // if (row?.testcertificateno !== "" && row?.testcertificateno !== undefined ) {
        //   itembatch = row?.testcertificateno
        //     ?.split(",")
        //     .filter((item) => item?.trim() !== "");
        //   console.log("itembatchno", row?.itembatchno, itembatch);
  
        //   if (itembatch?.length > 14 ) {
        //     rowHeight = 300;
        //   }
  
        //    if (itembatch?.length > 8 && itembatch?.length < 14) {
        //     rowHeight = 200;
        //   }
  
        //  else if (itembatch?.length > 5 && itembatch?.length < 8) {
        //     rowHeight = 150;
        //   }
        //   else if(itembatch?.length > 2 && itembatch?.length < 5){
        //     rowHeight = 50;
        //   }
  
        // }
        // else if (row?.serialno !== "" && row?.serialno !== undefined ) {
        //   let serialno = row?.serialno
        //     ?.split(",")
        //     .filter((item) => item?.trim() !== "");
        //   console.log("itembatchno", row?.serialno, serialno);
  
        //    if (serialno?.length > 8 && serialno?.length < 14) {
        //     rowHeight = 200;
        //   }
  
        //  else if (serialno?.length > 5 && serialno?.length < 8) {
        //     rowHeight = 150;
        //   }
        //   else if(serialno?.length > 2 && serialno?.length < 5){
        //     rowHeight = 50;
        //   }
  
        // }
  
        totalHeight += rowHeight;
  
  
        if (totalHeight <= availableContentHeight) {
          firstPageData.push(row);
        } else {
          remainingData.push(row);
        }
      }
    }
  

  
    let topHeight = "";
  
    if (auth?.state?.userdata?.domainrecno === 100) {
      topHeight = headerHeight - 20;
    } else {
      topHeight = headerHeight - 50;
    }
  
    const firstPageComponent = (
      <PrintPage
        key="firstPage"
        printModal={printModal}
        openModal={isPrintModalOpen}
        // jsondatawithpageno={csvjsonsalebill}
        // PrintData={saleinvoiceprint}
        PrintData={ModifyPrintData}
        topHeight={topHeight}
        // pageNumber={pageNumber}
        totalPageCount={pages.length} // Pass the total page count
        rowitemsdata={firstPageData}
        pageheight={NewformatprintData?.height}
        pagewidth={NewformatprintData?.width}
        columnsinfo={columnsinfo}
        Messages={firstPageData}
        firstPage={1}
        lastPageIndex={remainingData?.length > 0 ? 0 : 2}
      />
    );
  
    const remainingPagesComponents = [];
    if (remainingData.length > 0) {
      const newavailableContentHeight = 1200 - headerheight - footerheight;
  

      let rowHeight = 34;
      let itembatch = "";
  
      for (const row of remainingData) {
        // if (row?.itembatchno !== "" && row?.itembatchno !== undefined ) {
        //   itembatch = row?.itembatchno
        //     ?.split(",")
        //     .filter((item) => item?.trim() !== "");
        //   console.log("itembatchno", row?.itembatchno, itembatch);
        //    if (itembatch?.length > 8 && itembatch?.length < 14) {
        //     rowHeight = 200;
        //   }
        //  else if (itembatch?.length > 5 && itembatch?.length < 8) {
        //     rowHeight = 150;
        //   }
        // }
        // if (row?.testcertificateno !== "" && row?.testcertificateno !== undefined ) {
        //   itembatch = row?.testcertificateno
        //     ?.split(",")
        //     .filter((item) => item?.trim() !== "");
        //   console.log("itembatchno", row?.itembatchno, itembatch);
        //   if (itembatch?.length > 14) {
        //     rowHeight = 300;
        //   }
        //   else if (itembatch?.length > 8 && itembatch?.length < 14) {
        //     rowHeight = 200;
        //   }
        //  else if (itembatch?.length > 5 && itembatch?.length < 8) {
        //     rowHeight = 120;
        //   }
        // }
        // else if (row?.serialno !== "" && row?.serialno !== undefined ) {
        //   let serialno = row?.serialno
        //     ?.split(",")
        //     .filter((item) => item?.trim() !== "");
        //   console.log("itembatchno", row?.serialno, serialno);
        //    if (serialno?.length > 8 && serialno?.length < 14) {
        //     rowHeight = 200;
        //   }
        //  else if (serialno?.length > 5 && serialno?.length < 8) {
        //     rowHeight = 120;
        //   }
        //   else if(serialno?.length > 2 && serialno?.length < 5){
        //     rowHeight = 50;
        //   }
        // }
      }
  
      const remainingPages = Math.ceil(
        (remainingData.length * rowHeight) / newavailableContentHeight
      );
  
      const lastPageIndex = remainingPages - 1;
  
      let remainingPageIndex = 0;
      for (let i = 0; i < remainingPages; i++) {
        const nextPageData = remainingData.slice(
          remainingPageIndex,
          remainingPageIndex + newavailableContentHeight / rowHeight
        );
  

  
        remainingPagesComponents.push(
          <PrintPage
            key={`remainingPage${i}`}
            printModal={printModal}
            openModal={isPrintModalOpen}
            // jsondatawithpageno={csvjsonsalebill}
            // PrintData={saleinvoiceprint}
            PrintData={ModifyPrintData}
            topHeight={headerheight + 50}
            columnsinfo={columnsinfo}
            // pageNumber={pageNumber}
            totalPageCount={pages.length} // Pass the total page count
            rowitemsdata={firstPageData}
            pageheight={NewformatprintData?.height}
            pagewidth={NewformatprintData?.width}
            Messages={nextPageData}
            firstPage={0}
            lastPageIndex={i === lastPageIndex ? 2 : 0}
          />
        );
  
        remainingPageIndex += newavailableContentHeight / rowHeight;
      }
    }

    return (
      <div>
        {firstPageComponent}
        {remainingPagesComponents}
      </div>
    );
  };
console.log("NewformatprintData",NewformatprintData)
  return (
    <>
      <div>
        {isPrintModalOpen ? (
          <div ref={printRef}>
               {/* {firstPageComponent}
            {remainingPagesComponents} */}
            {(Array.isArray(NewformatprintData)?NewformatprintData:[NewformatprintData])?.map((item => calculateHeight(item)))}
            {/* {pages?.sort()?.map((pageNumber) => {
              return ( */}
            {/* {firstPageData.map((batch, index) => (
                <PrintPage
                key={index}
                  // key={pageNumber}
                  printModal={printModal}
                  openModal={isPrintModalOpen}
                  // jsondatawithpageno={csvjsonsalebill}
                  // PrintData={saleinvoiceprint}
                  PrintData={ModifyPrintData}
                  topHeight={headerHeight}
                  // pageNumber={pageNumber}
                  totalPageCount={pages.length} // Pass the total page count
                  rowitemsdata={rowitemsdata}
                  pageheight={NewformatprintData?.height}
                  pagewidth={NewformatprintData?.width}
                  Messages={firstPageData} 
                  firstPage={firstPage}
                />
                ))} */}
            {/* );
            })} */}
          </div>
        ) : null}
      </div>
    </>
  );
};

export default ReactPrint;

const btnStyle = {
  display: "flex",
  alignItems: "center",
  gap: "4px",
};

import { Table, Button, Modal, Pagination, Space } from "antd";
import { AiFillPrinter } from "react-icons/ai";
import React, { forwardRef, useContext, useRef, useState } from "react";
import { useEffect } from "react";
import { useReactToPrint } from "react-to-print";
import {
  dateFormatter,
  formatCurrency,
  formatCurrency3,
  isArray,
  removeFormatCurrency,
} from "../../CommonControllers/AppFunction";
import { TabStore } from "../../TabsStructure/TabsStore";
import "./sticky.css";
import TotalTable from "./TotalTable";
import PubSub from "pubsub-js";
import PrinterModal from "./PrinterModal";
import ReactPrint from "../../CommonControllers/Print/ReactPrint";
import { width } from "@mui/system";
//import { report } from "process";

const SalesInvoiceTable = ({ filterprinting, filterdropdownprint, filterDictionary, setFilterDictionary }) => {
  const tabstore = useContext(TabStore);
  const [checkStrictly, setCheckStrictly] = useState(false);
  const [col, setColumns] = useState([]);
  const [rows, setRow] = useState([]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [open, setOpen] = useState(false);
  const [total, setTotal] = useState([]);
  const [startPrinting, setStartPrinting] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);

  let response = tabstore?.state?.tabs[tabstore.state.tabIndex]?.apiResponse;
  let itemresponse = tabstore?.state?.tabs[tabstore.state.tabIndex]?.apiVariables.withitems;/// with item withserialno
  let titleprint = [tabstore?.state?.tabs[tabstore.state.tabIndex]?.apiVariables];
  let fastpath = tabstore?.state?.tabs[tabstore.state.tabIndex]?.name;
  let reportname = tabstore?.state?.tabs[tabstore.state.tabIndex]?.headerName;

  /// with serioan number
  let withserialno=   tabstore?.state?.tabs[tabstore.state.tabIndex]?.apiVariables?.withserialno

 

  useEffect(() => {
    PubSub.subscribe(fastpath + "_PRINT", commonFunction);
    let arr = [];
    setColumns(response?.columnsinfo?.map((item) => {
      console.log("WIdth is", item.width)
      return { ...item, width: item?.width }
    }));
 
/// here is logic for find the sub total for the give item same itemdescn
let req=calculateSubtotal(response?.Message)
console.log("REQUIREMENT", req)
let modified=calculateCategorySubtotal(req)

console.log("REtrdctfcy",modified)

let x=response?.Message.reduce((acc,it)=>acc+(Number(removeFormatCurrency(it.amount))),0)
let y=response?.Message.reduce((acc,it)=>acc+Number(it.qty),0)
console.log("RRRRR",x,y)

modified?.push({
  categorydescn: 'Total',
  subcategorydescn: "",
  itemdescn: '',
  amount: formatCurrency(x.toFixed(2)),
  qty: y.toFixed(2),
})
setRow(modified)


  }, [response, itemresponse,withserialno]);
 
  const setModal = () => {
    setOpen(true);
  };

  function handlePageChange(pageNumber) {
    // handle page change logic here
    setPage((p) => p + 1);
  }

  const commonFunction = () => {
    // handlePrintbody()
    // setOpen(true);
    setIsModalOpen((current) => !current);
    // setStartPrinting(true);
    //PubSub.unsubscribe(fastpath+"_PRINT")
  };

  useEffect(() => {
    if (startPrinting == true) {
      handlePrintbody();
    }
  }, [startPrinting]);

  const componentRef = useRef();
  let today = new Date();
  let date = today.getDate() + "-" + (today.getMonth() + 1) + "-" + today.getFullYear();

  const handlePrintbody = useReactToPrint({
    content: () => componentRef.current,
    onBeforeGetContent: () => (document.title = reportname),
    onAfterPrint: () => {
      setStartPrinting(false);
      setOpen(false);
    },
  });


  //// here is loic for the format t  he data

  
  function calculateSubtotal(data) {
    const result = [];
    let currentGroup = null;
    let currentGroupItems = [];
    let subtotalAmount = 0;
    let subtotalQty = 0;
    let crqtyTotal = 0;
    let drqtyTotal = 0;
    let balanceTotal = 0;
    let openingTotal = 0;
  
    data.forEach((item, index) => {
      if (currentGroup === null) {
        currentGroup = item?.subcategorydescn;
      }
  
      if (item?.subcategorydescn === currentGroup) {
        subtotalAmount += Number(removeFormatCurrency(item.amount));
        openingTotal += item?.opening==""?0:Number(removeFormatCurrency(item?.opening))
        crqtyTotal +=item?.crqty==""?0: Number(item?.crqty)
        drqtyTotal += item?.drqty==""?0:Number(item?.drqty)
        balanceTotal +=item?.balance==""?0: Number(removeFormatCurrency(item?.balance))
        

        subtotalQty += parseFloat(item.qty);
        currentGroupItems.push(item); // Add item to the current group
      } else {
        // Push the items of the previous group
        result.push(...currentGroupItems);
  
        // Push the subtotal for the previous group
        result.push({
          categorydescn: '',
          subcategorydescn: "Sub Total",
          crqty : crqtyTotal,
          drqty : drqtyTotal,
          balance: balanceTotal, 
          itemdescn: null,
          amount: formatCurrency(subtotalAmount.toFixed(2)),
          opening : openingTotal,
          qty: subtotalQty.toFixed(2),
        });
  
        // Reset values for the next group
        currentGroup = item.subcategorydescn;
        currentGroupItems = [item];
        subtotalAmount = Number(removeFormatCurrency(item.amount));
        subtotalQty = parseFloat(item.qty);
      }
  
      if (index === data.length - 1) {
        // Push the last group's items
        result.push(...currentGroupItems);
  
        // Push the last group's subtotal
        result.push({
          categorydescn: '',
          subcategorydescn: "Sub Total",
          itemdescn: null,
          amount: formatCurrency(subtotalAmount.toFixed(2)),
          qty: subtotalQty.toFixed(2),
          crqty : crqtyTotal,
          drqty : drqtyTotal,
          balance: balanceTotal, 
          opening : openingTotal,
        });
      }
    });
    console.log("Results", result)
    return result;
  }

  /// category 
  function calculateCategorySubtotal(data) {
    const result = [];
    let currentGroup = null;
    let currentGroupItems = [];
    let subtotalAmount = 0;
    let subtotalQty = 0;
    let crqtyTotal = 0;
    let drqtyTotal = 0;
    let balanceTotal = 0;
    let openingTotal = 0;
    
  
    data.forEach((item, index) => {
      if (currentGroup === null) {
        currentGroup = item.categorydescn;
      }
  
      if (item.categorydescn === currentGroup||item.categorydescn=="") {
        subtotalAmount +=item.categorydescn==""?0: Number(removeFormatCurrency(item.amount));
        subtotalQty += item.categorydescn==""?0:parseFloat(item.qty);
        openingTotal += item?.opening==""?0:Number(removeFormatCurrency(item?.opening))
        crqtyTotal +=item?.crqty==""?0: Number(item?.crqty)
        drqtyTotal += item?.drqty==""?0:Number(item?.drqty)
        balanceTotal +=item?.balance==""?0: Number(removeFormatCurrency(item?.balance))
        currentGroupItems.push(item); // Add item to the current group

      } else {
        // Push the items of the previous group
        result.push(...currentGroupItems);
  
        // Push the subtotal for the previous group
        result.push({
          categorydescn: 'Category Total',
          subcategorydescn: null,
          itemdescn: null,
          amount: formatCurrency(subtotalAmount.toFixed(2)),
          qty: subtotalQty.toFixed(2),
          crqty : crqtyTotal,
          drqty : drqtyTotal,
          balance: balanceTotal, 
          opening : openingTotal,
          
        });
  
        // Reset values for the next group
        currentGroup = item.categorydescn;
        currentGroupItems = [item];
        subtotalAmount = Number(removeFormatCurrency(item.amount));
        subtotalQty = parseFloat(item.qty);
      }
  
      if (index === data.length - 1) {
        // Push the last group's items
        result.push(...currentGroupItems);
  
        // Push the last group's subtotal
        result.push({
          categorydescn: 'category Total',
          subcategorydescn: null,
          itemdescn: null,
          amount: formatCurrency(subtotalAmount.toFixed(2)),
          qty: subtotalQty.toFixed(2),
          crqty : crqtyTotal,
          drqty : drqtyTotal,
          balance: balanceTotal, 
          opening : openingTotal,
        });
      }
    });
  
    return result;
  }

  return (
    <div style={{ paddingBottom: "100px", overflowX: 'auto' }} >
      {/* rajendra 11-3-2023 added overflowX and scroll {response?  <Button onClick={setModal} type="dashed">Open to Print</Button>:null} */}
    
      <Table
        columns={col}
        dataSource={rows}
        // style={{width:auto,fontSize:"10px",height:"max-content"}}
        //scroll={open?false:{ x: 400 }}
        // scroll={{ x: 'max-content' }}
        scroll={{
          scrollToFirstRowOnChange: true,
          x: col?.reduce((acc, el, i, arr) => (acc += Number(el?.width)), 0),
          y: 350,
        }}
        ref={open ? componentRef : null}
        pagination={false}
        // {open?false:{
        //   position: ["none"],
        //   pageSize: pageSize,
        //   defaultCurrent: page,
        //   current: page,
        //   onChange: handlePageChange,
        // }}
        title={() => {
          return (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                position: "sticky",
                top: "0px",
                zIndex: 1,
                offset: 0,
              }}
            >
              {open ? (
                <>
                  {filterprinting?.map((data) => {
                    console.log("check data", data, reportname);
                    return (
                      <>
                        {titleprint?.map((titleprint) => {
                          console.log("check data1", titleprint);
                          if (data.type !== "boolean") {
                            return (
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <h2>{data.displayLabel}:</h2>
                                <h3>
                                  {data.type == "date"
                                    ? dateFormatter(titleprint[data.apiKey])
                                    : data.type == "dropdown"
                                      ? filterdropdownprint
                                      : titleprint[data.apiKey]}
                                </h3>
                              </div>
                            );
                          }
                        })}
                      </>
                    );
                  })}
                </>
              ) : (
                <>
                  <Space>
                  <Button onClick={commonFunction} style={{ ...btnStyle }}>
        <AiFillPrinter style={{ fontSize: "1.08rem" }} />
        Preview
      </Button>
                    {tabstore.state.tabs[tabstore.state.tabIndex]?.apiResponse?.selected ? (
                      <Button>
                        Total Records : {tabstore.state.tabs[tabstore.state.tabIndex]?.apiResponse?.selected} /{" "}
                        {rows?.length}{" "}
                      </Button>
                    ) : (
                      <Button>Total Records : {rows?.length} </Button>
                    )}

                    <Button>Current Page : {page} </Button>
                  </Space>
                  {/* <Pagination
                    total={rows?.length}
                    showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
                    defaultCurrent={page}
                    defaultPageSize={10}
                    showSizeChanger
                    showQuickJumper
                    current={page}
                    onChange={(e, pageSize) => {
                      setPageSize(pageSize);
                      setPage(e);
                    }}
                  /> */}
                </>
              )}
            </div>
          );
        }}
        className={page == Math.ceil(rows.length / pageSize) ? "printtotalline" : open ? "printTable" : "print"}
      />

      {
        col && isArray(col) ?<PrinterModal
        columns={col}
        rows={rows}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        ref={componentRef}
        wtfIsThisData={{ data1: filterprinting, data2: filterdropdownprint }}
        filterDictionary={filterDictionary}
        setFilterDictionary={setFilterDictionary}
        setStartPrinting={setStartPrinting}
        reportname={reportname}
      /> : null
      }
      
    </div>
  );
};
export default SalesInvoiceTable;

const btnStyle = {
  display: "flex",
  alignItems: "center",
  gap: "4px",
  marginLeft: "16px",
};

import React, { useContext } from "react";
import { CloudUploadOutlined } from "@ant-design/icons";
import "../../global.css"

import { FiCloudLightning } from "react-icons/fi";
import { Modal } from "antd";
import { TabStore } from "../../../TabsStructure/TabsStore";


const CallbackFileInput = ({ className, displayLabel, apiKey, returnValue}) => {
  const [file, setFile] = React.useState("");
  const [displayFile, setDisplayFile] = React.useState(false);
  const tabstore = useContext(TabStore);
  const handleFileUpload = async (e) => {
    const file = e.target.files[0];
    const base64 = await convert(file);
    console.log("file", file,base64);
    setFile(base64);
    if (returnValue) {
      let base64WithoutPrefix = base64.replace(/^data:image\/\w+;base64,/, '');
        returnValue(base64WithoutPrefix)
    }
  };

  const convert = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (e) => {
        reject(e);
      };
    });
  };
 
  return (
    <div>
        <Modal open={displayFile}>
            <div>
            <img src={`data:image/png;base64${file}`}/>
            <object></object>
            <iframe src={`data:image/pdf;base64${file}`} width="500" height="375"></iframe>
                            <video>
                <source src="movie.mp4" type="video/mp4"/>
                <source src="movie.ogg" type="video/ogg"/>
                Your browser does not support the video tag.
                </video>
            </div>
        </Modal>
      <img src={file?file:null} alt=""  style={{ height: "50px", width: "50px", objectFit: "cover" }}/>
      <label
        className={!className ? "fileUpload" : className}
        onChange={handleFileUpload}
      >
        <input
          type="file"
          accept=".doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document, image/* , .pdf , .doc"
        />
        <CloudUploadOutlined />
        <p>{displayLabel}</p>
      </label>
    </div>
  );
};

export default CallbackFileInput;

// CSS

const mainFrame = {
  display: "flex",
  alignItems: "center",
  gap: "10px",
};

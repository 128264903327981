import { authStoreInitialState } from "../../../Auth/AuthStore";
import { getScroll } from "../../../CommonControllers/ApiCalls";
import { getAccountlist } from "../../../CommonControllers/ApiCalls";

export const getSummary = (pendinginstruments_array, voucher_amt) => {
  let credittotal = 0;
  let debittotal = 0;
  let balancetotal = 0;
  let adjusttedtotal = 0;
  let received_paidtotal = 0; // either received or paid as per the voucher type
  let on_account_amount = 0;


 // {Pradeep Task -id 241}
  // console.log("pending instruments data" ,pendinginstruments_array?.on_account_amount, "VOUCHER AMT UPDATED data",voucher_amt)

  console.log("received_paidtotal top", received_paidtotal)

  if (isNaN(voucher_amt)) {
    voucher_amt = 0;
  }


  
  pendinginstruments_array?.forEach((item,ind) => {
    credittotal = credittotal + item.cramt;
    debittotal = debittotal + item.dramt;
    balancetotal = balancetotal + item.pendingamt;
    // console.log( "received_paidtotal before",ind, item.on_account_amount,received_paidtotal)

    // received_paidtotal = Number((received_paidtotal + item.on_account_amount).toFixed(2));
    // console.log( "received_paidtotal",ind, item.on_account_amount,received_paidtotal)


    const other_gl_child = item.childrows;
    other_gl_child.forEach((data) => {
      if (data.amount > 0) {
        adjusttedtotal = Number(adjusttedtotal) + Number(data.amount);
      }
    });
  });


 received_paidtotal =  pendinginstruments_array?.reduce((acc,curr)=>{
    
    return acc+Number(curr?.on_account_amount)   

},0)

console.log("received_paidtotal above ", Number(received_paidtotal))

if(200000<=received_paidtotal<300000){
  // task id : 335 nirmal-335 showing with on account with two decimal values
  received_paidtotal= received_paidtotal?.toFixed(2)
}

console.log("received_paidtotal new", received_paidtotal)


  // on_account_amount = Number(voucher_amt) - Number(received_paidtotal);
  
  on_account_amount = (voucher_amt) - (received_paidtotal);

  console.log( "on_account_amount in helper page ", on_account_amount)
  credittotal = credittotal.toFixed(2);
  debittotal = debittotal.toFixed(2);
  balancetotal = balancetotal.toFixed(2);
  on_account_amount = on_account_amount.toFixed(2);

  let summary = {
    credittotal,
    debittotal,
    balancetotal,
    received_paidtotal,
    adjusttedtotal,
    on_account_amount,
  };
  console.log("summary in helper page",summary)
  return summary;
};

/**
 * Transfer Data received from API Call to the State Variables
 * @param {
 * } pendinginstruments_array
 *
 *
 */
export const transformPendingInsutrments = (pendinginstruments_array) => {
  const _instr = pendinginstruments_array.map((item) => ({
    accountcode: item.accountcode,
    recno: item.recno,
    refno: item.refno,
    cramt: item.cramt,
    dramt: item.dramt,
    pendingamt: item.pendingamt,
    othergl: 0,
    othergl_total: 0,
    on_account_amount: 0,
    childrows: [],
    refno_balance: 0,
    refdate: item.refdate,
  }));
  return _instr;
};

/** Clear Auto Adjusted Amount , set amount to zero
 *  20-JAN-2023
 *  @param - pending insturment array
 */
export const clearAutoAdjustements = (pendinginstruments_array) => {
  let temp_pending_array = [];

  if (pendinginstruments_array.length > 0) {
    pendinginstruments_array.forEach((item) => {
      item.on_account_amount = 0;

      temp_pending_array.push(item);
    });
  }
  return temp_pending_array;
};

export const generateAccountingEntryPayLoad_TabStore = (
  changedRows,
  domainrecno,
  tenantrecno,
  trntype,
  voucherno
) => {
  // console.log("generateAccountingEntryPayLoad");
  //console.log(state);
  //console.log(auth);

  let payload = {
    domainrecno: domainrecno,
    tenantrecno: tenantrecno,
    trdate: changedRows?.voucherdate, //state.selected_date,
    vouno: voucherno,
    items: [],
    particular: changedRows?.narration,
  };

  if (changedRows?.accounting_vouchers?.length > 0) {
    changedRows?.accounting_vouchers?.forEach((element) => {
      if (element.code != "TOTAL") {
        let amount = 0;

        if (element.CDFlag == CREDIT) {
          amount = element.Credit;
        } else {
          amount = element.Debit;
        }

        let item = {
          recno: element.recno,
          trdate: changedRows?.voucherdate,
          code: element.code,
          cdflag: element.CDFlag,
          amount: amount,
          ctflag: 2, // ALL Entries Transfer
          trntype: trntype,
          particular: changedRows?.narration,
          chqno: changedRows?.chequeno,
          chqdate: changedRows?.chequedate,
          theirrefno: changedRows?.partyreference,
          refno: changedRows?.ourreference,
          billingdetails: [],
        };

        if (item.code == changedRows?.gl_account_1?.code) {
          const billingdetails = generateBillingdetails(
            changedRows?.pending_instruments,
            changedRows?.gl_account_1?.code
          );
          item.billingdetails = billingdetails;
        }

        payload.items.push(item);
      }
    });
  }
  //console.log(payload);
  return payload;
};

export const generateAccountingEntryPayLoad = (state) => {
  // console.log("generateAccountingEntryPayLoad");
  //console.log(state);
  //console.log(auth);

  let payload = {
    domainrecno: 0,
    tenantrecno: 0,
    trdate: state.selected_date,
    items: [],
  };

  if (state.accounting_vouchers.length > 0) {
    state.accounting_vouchers.forEach((element) => {
      if (element.code != "TOTAL") {
        let amount = 0;

        if (element.CDFlag == CREDIT) {
          amount = element.Credit;
        } else {
          amount = element.Debit;
        }

        let item = {
          trdate: state.selected_date,
          code: element.code,
          cdflag: element.CDFlag,
          amount: amount,
          ctflag: 2, // ALL Entries Transfer
          trntype: 0,
          particular: state.particulars,
          billingdetails: [],
        };

        if (item.code == state.gl_account_1.code) {
          const billingdetails = generateBillingdetails(
            state.pending_instruments,
            state.gl_account_1.code
          );
          item.billingdetails = billingdetails;
        }

        payload.items.push(item);
      }
    });
  }
  //console.log(payload);
  return payload;
};

/**
 * Load Payment Voucher Data for Edit Voucher which will generate State for Page
 * @param {
 * } voucherno
 */
export const LoadPaymentVoucherForEdit = async (
  domainrecno,
  tenantrecno,
  voucherno
) => {
  let _data_scroll = null;
  let _data_instruments = null;

  let transaction_array = [];
  let gl_account_1 = null;
  let gl_account_2 = null;
  let pending_instruments = [];

  let voucher_data = {
    voucher_type: null,
    narration: "",
    ourreference: null,
    partyreference: null,
    trdate: "",
    chqno: "",
    chqdate: "",
  };

  let payload_scroll = {
    domainrecno: domainrecno,
    tenantrecno: tenantrecno,
    vouno: voucherno,
    withinstruments: true,
  };

  _data_scroll = await getScroll(payload_scroll); // get transaction table data

  if (_data_scroll.Success == true) {
    transaction_array = _data_scroll.Message;

    let debitAccountArray = null;
    let debitAccount = null;
    debitAccountArray = transaction_array.filter((obj) => obj.cdflag == 2);
    console.log("debitAccount ", debitAccountArray); // identify debit account
    if (debitAccountArray.length == 1) {
      debitAccount = debitAccountArray[0];

      voucher_data.narration = debitAccount.particular;
      voucher_data.ourreference = ""; //debitAccount.particular;
      voucher_data.partyreference = debitAccount.theirrefno;
      voucher_data.voucher_type = debitAccount.trntype;
      voucher_data.trdate = debitAccount.trdate;
      voucher_data.chqno = debitAccount?.chqno;
      voucher_data.chqdate = debitAccount?.chqdate;
      voucher_data.partyreference = debitAccount?.theirrefno;
      voucher_data.ourreference = debitAccount?.ourreference;

      gl_account_1 = new Voucher_Account(
        debitAccount.code,
        debitAccount.glcode,
        debitAccount.gldescn,
        debitAccount.code,
        debitAccount.descn,
        debitAccount.gldetailtype,
        debitAccount.recno
      );

      console.log(" debitAccount.recno", debitAccount.recno);
      console.log("gl_account_1_edit", gl_account_1);

      let payload_accountlist = {
        domainrecno: domainrecno, //508, // Changed for EDIT Testing -- Mandar
        tenantrecno: tenantrecno,
        code: gl_account_1.code,
        fortrn: 1,
        cdflag: 2,
        vounoexclude: voucherno, // voucher no to exclude instrument trn records when editing voucher
      };

      console.log("payload", payload_accountlist);

      _data_instruments = await getAccountlist(payload_accountlist);

      if (_data_instruments.Success == true) {
        pending_instruments = transformPendingInsutrments(
          _data_instruments.Message[0].pendinginstruments
        );
        console.log("pending_instruments", pending_instruments);

        // let _temp_code = debitAccount.code;
        //   console.log(" _temp_code", _temp_code);

        debitAccount?.items.forEach((item) => {
          //console.log("item", item);

          if (item.code == debitAccount.code) {
            // update on amount paid for the instrument reference

            // console.log("item code --- ", item.code);
            //objIndex = myArray.findIndex((obj => obj.id == 1));
            let objIndex = pending_instruments.findIndex(
              (instrument) => instrument.refno == item.refno
            );

            if (objIndex >= 0) {
              // instrument found
              pending_instruments[objIndex].on_account_amount = item.dramt;
            }
          } else {
            // handle others discounts  gl

            console.log("discount gl", item);

            let objIndex = pending_instruments.findIndex(
              (instrument) => instrument.refno == item.refno
            );

            if (objIndex >= 0) {
              // instrument found
              const childrows_temp = pending_instruments[objIndex].childrows;

              // find recno from accounting entries data

              let temp_array = transaction_array.filter(
                (obj) => obj.code == item.code
              );
              let temp_recno = 0;
              if (temp_array.length > 0) {
                temp_recno = temp_array[0].recno;
              }

              const discount_item = {
                code: item.code,
                descn: item.descn,
                amount: item.dramt,
                gltype: "E", // not used anywhere
                recno: temp_recno,
              };
              childrows_temp.push(discount_item);

              pending_instruments[objIndex].childrows = childrows_temp;
            }
          }
        });
      }
    }

    // find gl_account_2
    let bank_cash_account_array = null;
    let bank_cash_account = null;

    bank_cash_account_array = transaction_array.filter(
      (obj) => obj.gldetailtype == "B" || obj.gldetailtype == "A"
    );

    console.log("bank_cash_account_array", bank_cash_account_array);

    if (bank_cash_account_array.length > 0) {
      bank_cash_account = bank_cash_account_array[0];

      gl_account_2 = new Voucher_Account(
        bank_cash_account.code,
        bank_cash_account.glcode,
        bank_cash_account.gldescn,
        bank_cash_account.code,
        bank_cash_account.descn,
        bank_cash_account.gldetailtype,
        bank_cash_account.recno
      );

      voucher_data.VOUCHER_AMT_UPDATED = bank_cash_account.cramt;
    }
  }

  voucher_data.pending_instruments = pending_instruments;
  voucher_data.gl_account_1 = gl_account_1;
  voucher_data.gl_account_2 = gl_account_2;

  return voucher_data;

  /*
  
  */
};

/**
 * Load Receipt Voucher Data for Edit Voucher which will generate State for Page
 * @param {
 * } voucherno
 * in Receipt Voucher  instruments are stored with Credit Account
 * Bank a/c      dr
 * Customer a/c   cr
 *
 */

export const LoadReceiptVoucherForEdit = async (
  domainrecno,
  tenantrecno,
  voucherno
) => {
  let _data_scroll = null;
  let _data_instruments = null;

  let transaction_array = [];
  let gl_account_1 = null;
  let gl_account_2 = null;
  let pending_instruments = [];

  let voucher_data = {
    voucher_type: null,
    narration: "",
    ourreference: null,
    partyreference: null,
    trdate: "",
    chqno: "",
    chqdate: "",
  };

  let payload_scroll = {
    domainrecno: domainrecno,
    tenantrecno: tenantrecno,
    vouno: voucherno,
    withinstruments: true,
  };

  _data_scroll = await getScroll(payload_scroll); // get transaction table data

  if (_data_scroll.Success == true) {
    transaction_array = _data_scroll.Message;

    let creditAccountArray = null;
    let creditAccount = null;
    creditAccountArray = transaction_array.filter((obj) => obj.cdflag == 1); // search credit account (customer a/c in case of receipt)
    console.log("creditAccount ", creditAccountArray); // identify debit account
    if (creditAccountArray.length == 1) {
      creditAccount = creditAccountArray[0];

      voucher_data.narration = creditAccount.particular;
      voucher_data.ourreference = ""; //debitAccount.particular;
      voucher_data.partyreference = creditAccount.theirrefno;
      voucher_data.voucher_type = creditAccount.trntype;
      voucher_data.trdate = creditAccount.trdate;
      voucher_data.chqno = creditAccount?.chqno;
      voucher_data.chqdate = creditAccount?.chqdate;

      gl_account_1 = new Voucher_Account(
        creditAccount.code,
        creditAccount.glcode,
        creditAccount.gldescn,
        creditAccount.code,
        creditAccount.descn,
        creditAccount.gldetailtype,
        creditAccount.recno
      );

      console.log(" creditAccount.recno", creditAccount.recno);
      console.log("gl_account_1_edit", gl_account_1);

      let payload_accountlist = {
        domainrecno: domainrecno, //508, // Changed for EDIT Testing -- Mandar
        tenantrecno: tenantrecno,
        code: gl_account_1.code,
        fortrn: 1,
        cdflag: 1, // credit
        vounoexclude: voucherno, // voucher no to exclude instrument trn records when editing voucher
      };

      console.log("payload", payload_accountlist);

      _data_instruments = await getAccountlist(payload_accountlist);

      if (_data_instruments.Success == true) {
        pending_instruments = transformPendingInsutrments(
          _data_instruments.Message[0].pendinginstruments
        );
        console.log("pending_instruments", pending_instruments);

        // let _temp_code = debitAccount.code;
        //   console.log(" _temp_code", _temp_code);

        creditAccount?.items.forEach((item) => {
          console.log("item", item);

          if (item.code == creditAccount.code) {
            // update on amount paid for the instrument reference

            // console.log("item code --- ", item.code);
            //objIndex = myArray.findIndex((obj => obj.id == 1));
            let objIndex = pending_instruments.findIndex(
              (instrument) => instrument.refno == item.refno
            );

            if (objIndex >= 0) {
              // instrument found
              pending_instruments[objIndex].on_account_amount = item.cramt; // use credit amount for receipt
            }
          } else {
            // handle others discounts  gl

            console.log("discount gl", item);

            let objIndex = pending_instruments.findIndex(
              (instrument) => instrument.refno == item.refno
            );

            if (objIndex >= 0) {
              // instrument found
              const childrows_temp = pending_instruments[objIndex].childrows;

              // find recno from accounting entries data

              let temp_array = transaction_array.filter(
                (obj) => obj.code == item.code
              );
              let temp_recno = 0;
              if (temp_array.length > 0) {
                temp_recno = temp_array[0].recno;
              }

              const discount_item = {
                code: item.code,
                descn: item.descn,
                amount: item.cramt,
                gltype: "E", // not used anywhere
                recno: temp_recno,
              };
              childrows_temp.push(discount_item);

              pending_instruments[objIndex].childrows = childrows_temp;
            }
          }
        });
      }
    }

    // find gl_account_2
    let bank_cash_account_array = null;
    let bank_cash_account = null;

    bank_cash_account_array = transaction_array.filter(
      (obj) => obj.gldetailtype == "B" || obj.gldetailtype == "A"
    );

    console.log("bank_cash_account_array", bank_cash_account_array);

    if (bank_cash_account_array.length > 0) {
      bank_cash_account = bank_cash_account_array[0];

      gl_account_2 = new Voucher_Account(
        bank_cash_account.code,
        bank_cash_account.glcode,
        bank_cash_account.gldescn,
        bank_cash_account.code,
        bank_cash_account.descn,
        bank_cash_account.gldetailtype,
        bank_cash_account.recno
      );

      voucher_data.VOUCHER_AMT_UPDATED = bank_cash_account.dramt; // amt received in bank
    }
  }

  voucher_data.pending_instruments = pending_instruments;
  voucher_data.gl_account_1 = gl_account_1;
  voucher_data.gl_account_2 = gl_account_2;
  console.log("voucher_data", voucher_data);
  return voucher_data;

  /*
 
 */
};

/*
function generateBillingdetails(pending_instruments, account_code) {
  let billingdetails = [];

  if (bill.on_account_amount > 0 || bill?.childrows?.length > 0) {
  //if (pending_instruments?.length > 0) {
    pending_instruments?.forEach((bill) => {
      let billitem = {
        recno: bill.recno,
        refno: bill.refno,
        code: account_code,
        amount: bill.on_account_amount,
        discountdetails: [],
      };

      bill.childrows.forEach((discount) => {
        let discountitem = {
          code: discount.code,
          amount: discount.amount,
        };
        billitem.discountdetails.push(discountitem);
      });

      billingdetails.push(billitem);
    });
  }
  return billingdetails;
}
*/

// working
function generateBillingdetails(pending_instruments, account_code) {
  let billingdetails = [];
  if (pending_instruments?.length > 0) {
    pending_instruments?.forEach((bill) => {
      if (bill.on_account_amount > 0) {
        let billitem = {
          recno: bill.recno,
          refno: bill.refno,
          code: account_code,
          amount: bill.on_account_amount,
          discountdetails: [],
        };
        billingdetails.push(billitem);
      }

      if (bill?.childrows?.length > 0) {
        bill.childrows.forEach((discount) => {
          let billitem = {
            recno: bill.recno,
            refno: bill.refno,
            code: discount.code,
            amount: discount.amount,
            discountdetails: [],
          };

          billingdetails.push(billitem);
        });
      }
    });
  }
  return billingdetails;
}

// const used in Receipt and Payment screens TR021 and TR022

export const VOUCHER_TYPE_RECEIPT = 1;
export const VOUCHER_TYPE_PAYMENT = 2;
export const CREDIT = 1;
export const DEBIT = 2;

// transaction types
// commonmaster where method  = 'ACTRNTYPE';

export const VOUCHER_RECEIPT = 1;
export const VOUCHER_PAYMENT = 2;
export const VOUCHER_JV = 3;
export const VOUCHER_CREDITNOTE = 6;
export const VOUCHER_DEBITNOTE = 7;

export class Voucher_Account {
  constructor(
    code,
    glcode,
    glname,
    accountcode,
    accountname,
    gldetailtype,
    recno
  ) {
    this.code = code;
    this.glcode = glcode;
    this.glname = glname;
    this.accountcode = accountcode;
    this.accountname = accountname;
    this.gldetailtype = gldetailtype;
    this.recno = recno;
  }
}

import { Button, Popconfirm, Tag } from "antd";
import { removeByAttribute } from "../../CommonControllers/AppFunction";
import { AiFillDelete, AiFillPieChart } from "react-icons/ai";
import SaveDropdown from "../../CommonControllers/Components/SaveComponents/SaveDropdown";
import { getdomainlocationlistactive } from "../../CommonControllers/Urls";

export const tableColumns = (
  igstappl,
  setRowData,
  rowData,
  scandata,
  setScandata,
  setOpenItem,
  sidate,
  setModal,
  scanner,
  onedconeinvoice,
  setScanner,
  pageDetails,
  setOpenLinkModal,
  setItemForLink
) => {
  return [
    {
      title: "",
      dataIndex: "active",
      key: "active",
      width: 80,
      render: (_, text) => {
        return (
          <Popconfirm
            title="Do you want to Cancel the changes?"
            onConfirm={(e) => {
              handleRemove(text, setRowData, rowData);
            }}
          >
            <Button icon={<AiFillDelete />}></Button>
          </Popconfirm>
        );
      },
    },

    {
      title: "Scan Barcode",
      dataIndex: "barcode",
      align: "left", // nohit-388 set Align for column
      key: "barcode",
      width: 120,
      render: (_, text) => {
        console.log("Text is", text);
        //// task-id 345 rajendra  here if isservice is true we don't want to show scan option
        if (text.itemasbatch == 1 && text.doctype !== "DC") {
          return (
            <Button
              disabled={onedconeinvoice && text?.doctype == "DC" ? true : false}
              onClick={() => {
                setOpenItem(true);

                handleChange(
                  text,
                  setRowData,
                  igstappl,
                  rowData,
                  scandata,
                  setScandata
                );
              }}
              // disabled={text?.packingqtyaccepted !== 0 || text?.packingextraqtyaccepted !== 0}
            >
              Item Scan
            </Button>
          );
        } else if (text?.doctype == "DC" && text?.wantbatchno == 0) {
          return (
            <Button
              onClick={() => {
                setModal(true);
                setScanner(text?.batcharray); // here this code is added to showned the scanned item from DC
              }}
            >
              Show Item
            </Button>
          );
        } else {
          return null;
        }
      },
    },
    {
      title: "Item Scan",
      dataIndex: "itemdescn",
    },
    {
      title: "Qty",
      dataIndex: "qty",
      width: 80,
      render:(_,data)=>{
        return<p>{data?.wantbatchno?data.qty:data?.itembatcharray?.length}
          </p>
      }
      
    },
    {
      width: 80,
      title: "Link",
      dataIndex: "link",
      render: (_, text) => {
        return (
          <Button
            icon={<AiFillPieChart />}
            onClick={(e) => {
              setItemForLink(text);
              setOpenLinkModal(true);
            }}
          ></Button>
        );
      },
    },
    {
      hidden: !pageDetails?.wantdomainlocation ? true : false,
      width: 300,
      title: "Location",
      dataIndex: "location",
      align: "left",
      render: (_, text, index) => {
        if (text.itemasbatch === 1) {
          // Calculate dynamic zIndex based on the index
          let dynamicZindex = 2000 - index;
    
          return (
            <div style={{ zIndex: dynamicZindex, position: "relative" }}>
              <SaveDropdown
                apikey={"locationrecno"}
                minInput={0}
                inputValue={20}
                displayLabel={"Locn"}
                placeholder={"Locn"}
                apiurl={getdomainlocationlistactive}
                selectionLabel={"descn"}
                selectionValue={"locationrecno"}
                returnValue={(e) => {
               
                  //text.location = e;
                  text.locationrecno=e.locationrecno
                  handleChange(
                    text,
                    setRowData,
                    igstappl,
                    rowData,
                    scandata,
                    setScandata
                  );
                }}
                width={"250px"}
                alignment="column"
              />
            </div>
          );
        }
      },
    },
    {
      title: "Linked Item",
      dataIndex: "linkedList",
      render: (_, tags) => (
        <>
          {tags?.linkedList?.map((tag, index) => {
            const subtleLightColors = [
              "#F6C3B7", // Light Coral
              "#FFE5D9", // Peach
              "#FFE8CC", // Apricot
              "#FFEBC3", // Navajo White
              "#FFF5CC", // Unbleached Silk
              "#F4F8EF", // Tea Green
              "#E8F6EF", // Honeydew
              "#C8E7ED", // Pale Cyan
              "#D6EAF8", // Light Steel Blue
              "#E2EAF2", // Powder Blue
              "#F2E9E4", // Pink Lace
              "#F8F5E6", // Cornsilk
              "#FAF9DE", // Light Goldenrod
              "#E1F7D5", // Pastel Green
              "#F5F5F5", // White Smoke
              "#FDFDFD", // Snow
            ];

            return (
              <>
                <Tag
                  color={
                    subtleLightColors[
                      Math.floor(Math.random() * subtleLightColors.length)
                    ]
                  }
                  key={tag}
                  style={{ padding: "4px", color:"black" , border:"1px solid black", margin:"3px"}}
                >
                  {tag.descn} : {tag?.selectedQty}
                </Tag>{" "}
                {/* <br /> */}
              </>
            );
          })}
        </>
      ),
    },
    // {width: 80,
    //     title:"Linked Item Qty",
    //     dataIndex:"linkeditemqty",
    // },
    // {
    //     title:"Vhr No",
    //     dataIndex:"documentno",
    // },
    // {
    //     title : "Vhr Date",
    //     dataIndex:"documentdate",
    // },
    // {
    //     title : "Vhr Type",
    //     dataIndex:"vhrtype"
    // }
  ];
};

export const handleAdd = (
  item,
  setRowData,
  igstappl,
  setSelectedItem,
  shortguid,
  salerate,
  purchaseQty,
  box,
  freeBox,
  freeQty,
  batchNo,
  expDate,
  discountAmt,
  discountPer,
  manufacturedate,
  onedconeinvoice
) => {
  var newShortguid = shortguid.getShortguid("IEF" + item.itemrecno + batchNo);
  console.log("item---", item);

  {
    /* nohit-325 - Sales Invoice Grid Salerate NaN issue solved */
  }
  var salerate = salerate ? salerate : 0.0;
  var cgstrate = item?.cgstrate ? item.cgstrate : 0.0;
  var sgstrate = item?.sgstrate ? item.sgstrate : 0.0;
  var igstrate = item?.igstrate ? item.igstrate : 0.0;
  let conversionfactor = item?.conversionfactor ? item.conversionfactor : 1;
  var packingqty = purchaseQty ? purchaseQty : 0;
  var packingextraqty = box ? box : 0;

  let isservice = item?.isservice; //// task id -345 is service rajendra
  var freepackingextraqty = freeBox ? freeBox : 0;
  var freepackingqty = freeQty ? freeQty : 0;
  var free =
    Number(freepackingqty) +
    Number(freepackingextraqty) * Number(conversionfactor);
 

  var qty =
    Number(packingqty) + Number(packingextraqty) * Number(conversionfactor);
  
  var discount = discountPer ? Number(discountPer) : 0.0;
  var discountamt = discountAmt ? Number(discountAmt) : 0.0;
  var expdate = expDate ? expDate : null;
  var manufacturedate = manufacturedate ? manufacturedate : null;
  var itembatchno = batchNo ? batchNo : null;

  var taxableamount = qty * (salerate - discountamt);
  var sgstamt = !igstappl ? taxableamount * (sgstrate / 100) : 0.0;
  var cgstamt = !igstappl ? taxableamount * (cgstrate / 100) : 0.0;
  var igstamt = igstappl ? taxableamount * (igstrate / 100) : 0.0;

  var amount = igstappl
    ? taxableamount + igstamt
    : taxableamount + (cgstamt + sgstamt);

  var itemObject = {
    ...item,
    shortguid: newShortguid,
    itemrecno: item.itemrecno,
    itemdescn: item.itemdescn,
    packingextraqty: packingextraqty,
    packingqty: packingqty,
    qty: qty,
    free: free,
    freeqty: free,
    salerate: Number(salerate).toFixed(2),
    taxableamount: Number(taxableamount).toFixed(2),
    cgstrate: Number(cgstrate).toFixed(2),
    sgstrate: Number(sgstrate).toFixed(2),
    igstrate: Number(igstrate).toFixed(2),
    cgstamt: Number(cgstamt).toFixed(2),
    sgstamt: Number(sgstamt).toFixed(2),
    igstamt: Number(igstamt).toFixed(2),
    amount: Number(amount).toFixed(2),
    discount: Number(discount).toFixed(2),
    discountamt: Number(discountamt).toFixed(2),
    expdate: expdate ? expdate : item.expdate,
    //itembatchno: item?.wantbatchno === 0 ? "." : itembatchno,
    //// task-id-345 rajendra here isservice validation
    itembatchno:
      item?.wantbatchno === 0 && isservice == 0
        ? "."
        : isservice === 1 && item?.wantbatchno === 0
        ? "."
        : itembatchno,
    freepackingextraqty: freepackingextraqty,
    freepackingqty: freepackingqty,
    manufacturedate: manufacturedate ? manufacturedate : item?.manufacturedate,
    onedconeinvoice: onedconeinvoice,
  };
  if (item?.doctype == "DC") {
    itemObject.dcqty = qty;
    itemObject.itembatcharray = itemObject?.batcharray;
  }

  //   let x=rowData?.findIndex(num => num.itemrecno==itemObject.itemrecno);

  //   if(x!==-1){
  // rowData[x]=itemObject
  // setRowData(rowData)
  //   }
  //   else{
  //     setRowData([...rowData, itemObject]);
  //   }


  setRowData((p) => [...p, itemObject]);
  setSelectedItem(null);
};

export const handleChange = (
  item,
  setRowData,
  igstappl,
  rowData,
  scandata,
  setScandata,
  setOpenItem
) => {
  var salerate = item?.salerate ? item.salerate : 0.0;
  var cgstrate = item?.cgstrate ? item.cgstrate : 0.0;
  var sgstrate = item?.sgstrate ? item.sgstrate : 0.0;
  var igstrate = item?.igstrate ? item.igstrate : 0.0;
  let isservice = item?.isservice; //// task id -345 is service rajendra

  let packingextraqty = item?.packingextraqty ? item?.packingextraqty : 0.0;
  let packingqty = item?.packingqty ? item.packingqty : 0.0;
  let itembatchno = item?.itembatchno ? item?.itembatchno : null;
  let expdate = item?.expdate ? item?.expdate : null;
  let conversionfactor = item?.conversionfactor ? item.conversionfactor : 1;

  let freepackingextraqty = item?.freepackingextraqty
    ? item?.freepackingextraqty
    : 0.0;
  let freepackingqty = item?.freepackingqty ? item?.freepackingqty : 0.0;
  let free =
    Number(freepackingqty) +
    Number(freepackingextraqty) * Number(conversionfactor);
  let qty =
    Number(packingqty) + Number(packingextraqty) * Number(conversionfactor);
  let discountamt = item?.discountamt ? item.discountamt : 0;
  var discount = item?.discount ? item.discount : 0;

  ///===== task id-253 rajendra discount butoon unable  start=====  /////
  console.log("RAjendra==", discount, discountamt, isservice);
  if (Number(discount) >= 0 && Number(salerate) > 0) {
    discountamt = (Number(discount) * Number(salerate)) / 100;
  }
  //  if (Number(discountamt) > 0 && Number(salerate) > 0) {
  //   // Calculate discount based on discountamt and salerate
  //   discount = ((Number(discountamt) / Number(salerate)) )

  // }

  /// NAN problem in SI by rbn date 6th may 2023

  // Check if discount and salerate are valid numbers
  // if (Number(discount) > 0 && Number(salerate) > 0) {
  //   discountamt = (Number(discount) * Number(salerate)) / 100;
  // }

  // // Round the discountamt to 2 decimal places
  // discountamt = discountamt.toFixed(2);

  // discountamt = Number(discount/100 * purrate)
  console.log("RAjendra==4555", discount, discountamt);
  var taxableamount =
    Number(qty) * Number(Number(salerate) - Number(discountamt));

  var sgstamt = !igstappl ? taxableamount * (sgstrate / 100) : 0.0;
  var cgstamt = !igstappl ? taxableamount * (cgstrate / 100) : 0.0;
  var igstamt = igstappl ? taxableamount * (igstrate / 100) : 0.0;

  var amount = igstappl
    ? taxableamount + igstamt
    : taxableamount + (cgstamt + sgstamt);

  var itemObject = {
    ...item,
    itemrecno: item.itemrecno,
    itemdescn: item.itemdescn,
    box: +item?.box,
    salerate: Number(salerate).toFixed(2),
    taxableamount: Number(taxableamount).toFixed(2),
    ccgstrate: Number(cgstrate).toFixed(2),
    sgstrate: Number(sgstrate).toFixed(2),
    igstrate: Number(igstrate).toFixed(2),
    cgstamt: Number(cgstamt).toFixed(2),
    sgstamt: Number(sgstamt).toFixed(2),
    igstamt: Number(igstamt).toFixed(2),
    amount: Number(amount).toFixed(2),
    discount: Number(Number(discount).toFixed(2)), //// task id-253 rajendra converted to number
    discountamt: Number(Number(discountamt).toFixed(2)), //// task id-253 rajendra converted to number
    packingextraqty: packingextraqty,
    packingqty: packingqty,
    qty: qty,
    free: free,
    freeqty: free,
    expdate: expdate ? expdate : item.expdate,
    // itembatchno: rowData?.wantbatchno==0?".":itembatchno,
    //// task-id-345 rajendra here isservice validation
    itembatchno:
      item?.wantbatchno === 0 && isservice == 0
        ? "."
        : isservice === 1 && item?.wantbatchno === 0
        ? "."
        : itembatchno,
    freepackingextraqty: freepackingextraqty,
    freepackingqty: freepackingqty,
  };

  //{Pradeep, task id-334  }
  // if (item?.onedconeinvoice && item?.doctype == "DC" && (itemObject?.qty + itemObject?.freeqty < itemObject?.dcqty)) {
  //   alert("Total Added Quantity Should match with the DC Quantity for Item " + itemObject?.itemdescn)
  // }
  //{end                           end}

  var currentItem = rowData.map((itm) => {
    if (itm.shortguid == item.shortguid) {
      return { ...itemObject };
    } else {
      return { ...itm };
    }
  });

  setRowData(() => currentItem);
  setScandata(item);
};

export const handleRemove = (item, setRowData, rowData) => {
  var removedArray = removeByAttribute(rowData, "shortguid", item.shortguid);

  setRowData(() => [...removedArray]);
  console.log("Item Scan Items", item?.onedconeinvoice, item?.doctype);
  if (item?.onedconeinvoice && item?.doctype == "DC") {
    setRowData(() => []);
  }
};

export const handleRemovePrev = (item, setRowData, rowData) => {
  console.log("Remove", item, setRowData, rowData, removedArray);

  var removedArray = removeByAttribute(rowData, "itemrecno", item.itemrecno);
  setRowData(() => [...removedArray]);
  if (item?.onedconeinvoice && item?.doctype == "DC") {
    setRowData(() => []);
  }
};

import { DeleteFilled } from '@ant-design/icons';
import { Button, Input, Tooltip } from 'antd';
import React, { useState } from 'react';
import CallbackDropdown from '../../CommonControllers/Components/CallbackComponents/CallbackDropdown';
import { getSurveyQuestionType } from '../../CommonControllers/Urls';
import Select from 'react-select';

function EditSubQuestion({
    options,
    setOptions,
    index,
    handleRemoveOption,
    subQuestionType,
    setsubQuestionType
}) {
    const [hasSubOption, setHasSubOption] = useState(false);
    const [subOptions, setSubOptions] = useState([]);
    // const handleHasSubOptionChange = () => {
    //     setHasSubOption(!hasSubOption);

    //     const newOptions = [...options];
    //     newOptions[index].hassubquestion = !hasSubOption ? (1) : (0);
    //     setOptions(newOptions);
    // };

    // const handleOptionChange = (event) => {
    //     // const options = [...options];
    //     // options[index].option = event.target.value;
    //     // setOptions(options);
    //     const updatedOptions = [...options];
    //     updatedOptions[index].optionlist = event.target.value;
    //     updatedOptions[index].hassubquestion = hasSubOption ? (1) : (0);
    //     updatedOptions[index].subquestions = subOptions;
    //     setOptions(updatedOptions);
    // };

    // const handleAddSubOption = () => {
    //     setSubOptions([...subOptions, { question: "", marks: 0 }]);
    // };

    // const handleSubOptionChange = (event, subOptionIndex) => {
    //     const newSubOptions = [...subOptions];
    //     newSubOptions[subOptionIndex] = event.target.value;
    //     setSubOptions(newSubOptions);

    //     // options[index].subOption = event.target.value
    //     // setOptions(options.subOption)
    //     // setSubOptions(event.target.value);

    //     const newOptions = [...options];
    //     newOptions[index].subquestions = newSubOptions.map(subOption => ({
    //         questionname: subOption,
    //         loyaltypoints: 0, 
    //     }));
    //     setOptions(newOptions);
    // };

    // const handleSubQuestionMarksChange = (event, subOptionIndex) => {
    //     const newOptions = [...options];

    //     newOptions[index].subquestions[subOptionIndex].loyaltypoints = event.target.value;
    //     setOptions(newOptions);
    // };

    // const handleRemoveSubOption = (subOptionIndex) => {
    //     setSubOptions(subOptions.filter((_, i) => i !== subOptionIndex));
    // };

    const handleHasSubOptionChange = () => {
        setHasSubOption(!hasSubOption);

        const newOptions = [...options];
        newOptions[index].hassubquestion = !hasSubOption ? (1) : (0);
        setOptions(newOptions);
    };

    const handleOptionChange = (event) => {
        // const options = [...options];
        // options[index].option = event.target.value;
        // setOptions(options);
        const updatedOptions = [...options];
        updatedOptions[index].optionlist = event.target.value;
        updatedOptions[index].hassubquestion = hasSubOption ? (1) : (0);
        // updatedOptions[index].subquestions = subOptions;
        updatedOptions[index].subquestions = subOptions.map((subOption) => ({
            questionname: subOption.question,
            loyaltypoints: subOption.marks,
            fieldtype: subOption.fieldtype,
            questiontype: subOption.questiontype,
            foranswer: updatedOptions[index].optionlist,
        }));
        setOptions(updatedOptions);
    };

    console.log("suboptions", subOptions)
    const handleAddSubOption = (index) => {

        setSubOptions([...subOptions, {
            question: "", 
            marks: 0, 
            fieldtype: 'option', 
            questiontype: "", 
        }]);

        // setSubQuestionNumber((p) => p + 10);
    };

    const handleSubOptionChange = (event, subOptionIndex) => {
        // const newSubOptions = [...subOptions];
        // newSubOptions[subOptionIndex] = event.target.value;
        // setSubOptions(newSubOptions);


        const newSubOptions = [...subOptions];
        newSubOptions[subOptionIndex] = { ...newSubOptions[subOptionIndex], question: event.target.value, foranswer: options[subOptionIndex]?.optionlist };
        setSubOptions(newSubOptions);

        // const newOptions = [...options];

        // newOptions[index].subquestions = newSubOptions.map(subOption => ({
        //     questionname: subOption.question,
        //     marks: subOption.marks
        // }));

        const newOptions = [...options];
        newOptions[index].subquestions = newSubOptions.map(subOption => ({
            questionname: subOption.question,
            loyaltypoints: subOption.marks,
            fieldtype: subOption.fieldtype,
            questiontype: subOption.questiontype,
            foranswer: newOptions[index].optionlist,
        }));

        // const subQuestion = newOptions[index].subquestions[subOptionIndex];

        // newOptions[index].subquestions[subOptionIndex] = {
        //     ...subQuestion,
        //     questionname: event.target.value,
        //     marks: 0 // initialize marks to 0
        // };

        // const currentOption = newOptions[index];
        // currentOption.subquestions = newSubOptions.map((subOption) => ({
        //   questionname: subOption,
        //   marks: currentOption.marks // add the marks field
        // }));
        setOptions(newOptions);
    };

    const handleSubQuestionMarksChange = (event, subOptionIndex) => {
        // const newSubOptions = [...subOptions];
        // newSubOptions[subOptionIndex].marks = event.target.value;
        // setSubOptions(newSubOptions);
        const newSubOptions = [...subOptions];
        newSubOptions[subOptionIndex] = { ...newSubOptions[subOptionIndex], marks: event.target.value };
        setSubOptions(newSubOptions);


        const newOptions = [...options];

        // newOptions[index].subquestions[subOptionIndex].loyaltypoints = event.target.value;
        newOptions[index].subquestions = newSubOptions.map((subOption) => ({
            questionname: subOption.question,
            loyaltypoints: subOption.marks,
            fieldtype: subOption.fieldtype,
            questiontype: subOption.questiontype,
            foranswer: newOptions[index].optionlist,
        }));
        setOptions(newOptions);
    };

    const handleSubQuestionfieldtypeChange = (value, subOptionIndex) => {
        // const newSubOptions = [...subOptions];
        // newSubOptions[subOptionIndex].fieldtype = value;
        // setSubOptions(newSubOptions);

        const newSubOptions = [...subOptions];
        newSubOptions[subOptionIndex] = { ...newSubOptions[subOptionIndex], fieldtype: value };
        setSubOptions(newSubOptions);

        const newOptions = [...options];
        newOptions[index].subquestions = newSubOptions.map((subOption) => ({
            questionname: subOption.question,
            loyaltypoints: subOption.marks,
            fieldtype: subOption.fieldtype,
            questiontype: subOption.questiontype,
            foranswer: newOptions[index].optionlist,
        }));
        setOptions(newOptions);
    };

    const handleQuestionTypeChange = (subOptionIndex, questiontype) => {
        const newSubOptions = [...subOptions];
        console.log("subQuestionType?.recno", subQuestionType?.recno)
        newSubOptions[subOptionIndex] = {
            ...newSubOptions[subOptionIndex],
            questiontype: questiontype
        };
        setSubOptions(newSubOptions);
        const newOptions = [...options];
        newOptions[index].subquestions = newSubOptions.map((subOption) => ({
            questionname: subOption.question,
            loyaltypoints: subOption.marks,
            fieldtype: subOption.fieldtype,
            questiontype: subOption.questiontype,
            foranswer: newOptions[index].optionlist,
        }));
        setOptions(newOptions);
    };

    const handleRemoveSubOption = (subOptionIndex) => {
        setSubOptions(subOptions.filter((_, i) => i !== subOptionIndex));
    };

    console.log("option useState", options)
    console.log("hassubquestion", hasSubOption)
    console.log("subOptions", subOptions)
    console.log("options.optionlist", options[index]?.optionlist)
    return (
        <div className="option" style={{ marginTop: "1%", display: 'flex' }}>
            <div style={{ paddingLeft: "1%" }}>
                <Tooltip title="Delete">
                    <Button shape="circle" icon={<DeleteFilled />} onClick={() => handleRemoveOption(index)} />
                </Tooltip>
            </div>
            <div style={{ paddingLeft: "1%" }}>
                <Input
                    // type="text"
                    placeholder='Option'
                    style={{ width: '100%' }}
                    value={options.option}
                    onChange={handleOptionChange}
                />
            </div>
            <div style={{ paddingLeft: "1%", display: 'flex', width: "18%" }}>
                {/* <label style={{paddingLeft: '1%'}}> */}
                <div>
                    <input type="checkbox" onChange={handleHasSubOptionChange} />
                </div>
                <p style={{ paddingLeft: "4%" }}>Has Sub-Question</p>
                {/* </label> */}
            </div>
            {hasSubOption && (
                <div className="sub-options-container" >
                    <Button onClick={handleAddSubOption}>Add Sub-Question</Button>
                    {subOptions.map((subOption, i) => (
                        <div key={i} className="sub-option" style={{ display: 'flex', marginTop: '1%' }}>
                            {/* <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>{subQuestionNumber}</div> */}

                            <div style={{ padding: '1%', paddingLeft: "1%" }}>
                                <Input
                                    type="text"
                                    value={subOption.question}
                                    placeholder="Enter Sub Question"
                                    onChange={(event) => handleSubOptionChange(event, i)}
                                />
                            </div>
                            <div style={{ padding: '1%', paddingLeft: "1%" }}>
                                <Input
                                    type="number"
                                    value={subOption.loyaltypoints}
                                    placeholder="Loyalty Points"
                                    min={0}
                                    onChange={(event) => handleSubQuestionMarksChange(event, i)}
                                />
                            </div>
                            {/* <div style={{ padding: '1%', paddingLeft: "1%" }}>
                                <select
                                    style={{
                                        color: "#333",
                                        backgroundColor: "#fff",
                                        border: "1px solid #ccc",
                                        borderRadius: "4px",
                                        padding: "5px 10px",
                                        fontSize: "16px",
                                        // fontWeight: "bold",
                                    }}
                                    value={subOption.fieldtype}
                                    placeholder="Select Type"
                                    onChange={(event) => {
                                        // const newSubOptions = [...subOptions];
                                        // newSubOptions[i].type = event.target.value;
                                        // setSubOptions(newSubOptions);
                                        handleSubQuestionfieldtypeChange(event.target.value, i)
                                    }}
                                >
                                    <option value="option">Select Type</option>
                                    <option value="alpha">Alpha</option>
                                    <option value="alphanumeric">Alphanumeric</option>
                                    <option value="number">Number</option>
                                    <option value="date">Date</option>
                                </select>
                            </div> */}
                            <div style={{ display: 'flex', padding: '1%', paddingLeft: "1%", flexDirection: 'column', width: "30%" }}>
                                <p style={{ display: 'flex' }}>Field Type</p>
                                <div style={{ paddingLeft: "1%", width: "100%", marginTop: "1%" }}>
                                    <Select
                                        options={[
                                            { value: 'alpha', label: 'Alpha' },
                                            { value: 'alphanumeric', label: 'Alphanumeric' },
                                            { value: 'number', label: 'Number' },
                                            { value: 'date', label: 'Date' },
                                            { value: 'boolean', label: 'Boolean' }
                                        ]}
                                        placeholder="Select Field Type"
                                        styles={{
                                            border: "1px solid #cccccc",
                                            borderRadius: "10px",
                                        }}
                                        onChange={(event) => {

                                            handleSubQuestionfieldtypeChange(event.value, i)
                                        }}
                                    />
                                </div>
                            </div>
                            <div style={{ padding: '1%', paddingLeft: "1%" }}>
                                <CallbackDropdown
                                    displayLabel={"Question Type"}
                                    alignment={"column"}
                                    placeholder="Select Question Type"
                                    apiurl={getSurveyQuestionType}
                                    selectionLabel={"descn"}
                                    selectionValue={"recno"}
                                    minInput={0}
                                    defaultSelected={""}
                                    returnValue={(e) => {
                                        console.log("eeeeeeeeeeeeeeeeeeeeeeee", e.code)
                                        handleQuestionTypeChange(i, e.code)
                                    }}
                                    body={{ "onlyactive": 1 }}
                                    inputValue={20}
                                    width={"100%"}
                                />
                            </div>
                            <div style={{ paddingLeft: "1%" }}>
                                <Tooltip title="Delete Sub-Question">
                                    <Button shape="circle" icon={<DeleteFilled />} onClick={() => handleRemoveSubOption(i)} />
                                </Tooltip>
                            </div>
                        </div>
                    ))}
                </div>
            )}
        </div>
    )
}

export default EditSubQuestion
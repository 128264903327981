import { CleaningServices } from "@mui/icons-material";
import React, { createContext, useEffect, useContext, useReducer } from "react";

const _ = require("lodash");

export const TabStore = React.createContext();

var localtab = {
  display: true,
  name: "Tab1",
  apiVariables: {
    Tenant: "Testing",
    AppShortName: "Bcore",
  },
  apiUrl: "",
  apiType: "",
  mode: "view",
  changedRows: {},
  selectedDocuments : [],
  
};

export const tabStoreinitialState = {
  tabIndex: 0,
  tabs: [],
  menu: false,
  menuArray: [],
  printUrl: "",
  printIns: "",
};

export const tabStorereducer = (prevState, action) => {

  console.log("Dispatch :::::::> is data at", action)
  switch (action.type) {
    case "Toggle_Drawer":
      return {
        ...prevState,
        menu: !prevState.menu,
      };
      break;

    case "API_DATA":
      var newdata = prevState;

      var localapivariable = {};

      localapivariable[action.fieldType] = action.text;

      newdata.tabs[newdata.tabIndex].apiVariables = {
        ...newdata?.tabs[newdata.tabIndex]?.apiVariables,
        ...localapivariable,
      };

      return { ...newdata };

    case "UPDATE_DATA":

    console.log("Response of the API is", action)
      var newdata = prevState;
      newdata.tabs[newdata.tabIndex][action.fieldType] = action.text;
      console.log("NewData", newdata)
      return { ...newdata };

      case "UPDATE_DATA_WITH_TABINDEX":

      console.log("Response of the API is", action)
        var newdata = prevState;
        newdata.tabs[action.tabDataIndex][action.fieldType] = action.text;
        console.log("NewData", newdata)
        return { ...newdata };

      case "UPDATE_EDIT_MODE_CHANGED_ROWS":
        console.log("New Data in Tab Store Is Called");
        var newdata = prevState;
  
        newdata.tabs[newdata.tabIndex].editIndex = newdata.tabs[newdata.tabIndex]?.editIndex ? newdata.tabs[newdata.tabIndex].editIndex + 1 : 1;
        console.log("New Data in Tab Store", newdata);
        return { ...newdata };

    case "UPDATE_CHANGED_DATA":
      /*
      var newdata = prevState;

      console.log("Action.fieldtype", action.fieldType, action.text);

      newdata.tabs[newdata.tabIndex].changedRows[action.fieldType] =
        action.text;

      console.log("Return Value", newdata);
      return { ...newdata };
      */
      let newdata_changed = Object.assign({}, prevState);

      console.log("Action.fieldtype", newdata_changed.tabs[newdata_changed.tabIndex]);
      if (newdata_changed.tabs[newdata_changed.tabIndex].changedRows) {

        newdata_changed.tabs[newdata_changed.tabIndex].changedRows[action.fieldType] = action.text;
      } else {
        newdata_changed.tabs[newdata_changed.tabIndex].changedRows = {}
        newdata_changed.tabs[newdata_changed.tabIndex].changedRows[action.fieldType] = action.text;
      }


      console.log("Return Value", newdata_changed);
      return { ...newdata_changed };

    case "OPEN_TAB":
      return {
        ...prevState,
        tabIndex: action.index,
        menu: false,
      };

    case "UPDATE_MENU": {
      return {
        ...prevState,
        menuArray: action.data,
      };
    }

    case "REMOVE_TAB":
      var deepCopy = _.cloneDeep(prevState.tabs);
      var clonetabIndex = _.cloneDeep(prevState.tabIndex);
      if (deepCopy.length == 1) {
        return { ...prevState, tabIndex: 0, tabs: [] };
      } else if (deepCopy.length - 1 == action.index) {
        var deepCopy = _.cloneDeep(prevState.tabs);
        deepCopy.splice(action.index, 1);
        var clonetabIndex = _.cloneDeep(prevState.tabIndex);
        return {
          ...prevState,
          tabIndex: clonetabIndex == 0 ? 0 : clonetabIndex - 1,
          tabs: deepCopy,
          menu: false,
        };
      } else {
        deepCopy.splice(action.index, 1);
        return {
          ...prevState,
          tabIndex: action.index,
          tabs: deepCopy,
          menu: false,
        };
      }
      break;
    case "UPDATE_TABS_ARRAY":
      // CReate a function which following inputs
      /*
        Params
        @tabsFastPath
        @data
        @keyName

        Need to map through the TabsArray
        Search the @tabsFastPath
        if arrays tabsName == @TabsFastPath
        update the keyName with the data
        and return and save to the array again
        Here tabsArray is the prevState
      */
        console.log("TAB7777",prevState,action);
      let prevData = Object.assign({}, prevState);
      const updatedTabsArray = prevData.tabs.map((tab) => {
        if (tab.name === action.tabsFastPath) {
          console.log("Tabs is ", tab, action.keyName,action.changedRowsValue);
          tab[action.keyName] = action.changedRowsValue;
          console.log("Tabs is Changed", tab[action.keyName], tab)
          return tab;
        } else {
          return tab;
        }
      });
      console.log("CHanged Tabs Array", action.keyName, updatedTabsArray, prevState, action.changedRowsValue);
      // return updatedTabsArray;
      return prevState;

      break;
    case "NEW_TAB":
      let newCopy = _.cloneDeep(prevState.tabs);

      if (newCopy?.length  >= 5) {
       
        alert("Cannot Open More than 5 Tabs")
        return {
          ...prevState,
         
        }; 
        break;
      }
      let newTab_local = {
        display: true,
        name: "Tab1",
        apiVariables: {
          Tenant: "Testing",
          AppShortName: "Bcore",
        },
        apiUrl: "",
        apiType: "",
        mode: "view",
        changedRows: {},
        selectedDocuments : [],
      };
      let newTab = {
        ...newTab_local,
        name: action.name,
        headerName: action.headerName,
        apiUrl: action.apiUrl,
        apiType: action.apiType,
        saveApiUrl: action.menuData?.saveApiUrl,
        saveApiType: action.menuData?.saveApiType,
        callapipageload: action.menuData?.callapipageload, // smk 5-1-2023 added for auto api call
        isAdd: action.menuData?.isAdd, // smk 5-1-2023 added for auto api call
        isEdit: action.menuData?.isEdit, // smk 5-1-2023 added for auto api call
        isDelete: action.menuData?.isDelete, // smk 5-1-2023 added for auto api call
        isRead: action.menuData?.isRead, // smk 5-1-2023 added for auto api call
      };
      newCopy = [...newCopy, newTab];
      return {
        ...prevState,
        tabs: newCopy,
        tabIndex: newCopy.length - 1,
        menu: false,
      };
      break;

    case "UPDATE_PRINT": {
      return {
        ...prevState,
        printUrl: action?.printUrl,
        printIns: action?.printIns,
      };
    }

    /// here delete condition is added //
    case "UPDATE_DELETE": {
      return {
        ...prevState,
        deleteUrl: action?.deleteUrl,
        deleteIns: action?.deleteIns,
      };
    }
    case "UPDATE_PRINT_BARCODE": {
      return {
        ...prevState,
        printInsBarcode: action?.printInsBarcode,
      };
    }

    case "INITIALIZE" : {
      return tabStoreinitialState
    }

    case "DISPLAY_DASH" : {

      prevState.tabIndex = -1
      console.log("Previous State", prevState)
      return {
        ...prevState
      }
    }
    default:
      return prevState;
  }
};

import React, { useContext, useEffect, useState } from "react";
import { ExcelRenderer, OutTable } from "react-excel-renderer";
import axios from "axios";
// import { toast } from "react-toastify";

// import AppConstants from './AppConstant';
import CustomButton from "./CustomButton";
import AppConstants from "./AppConstant";
import { textAlign } from "@mui/system";
import { Card, Button, Checkbox, Select } from "antd";
import { CircularProgress, TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ValidData } from "./ValidData";
import { MultipletableValidate } from "./MultipletableValidate";
import { baseUrl } from "../../CommonControllers/Urls";
import { AuthStore } from "../../Auth/AuthStore";
import SelectComponent from "./SelectComponent";



// import { locale } from 'faker';

function ToolETL() {
  const auth = useContext(AuthStore);
  const [dataObj, setDataObj] = useState([]);
  const [listOfETL, setlistOfETL] = useState();
  const [colhead, setColhead] = useState("");

  const [columnHeader, setcolumnHeader] = useState([]);
  const [rowFooter, setrowFooter] = useState([]);
  const [rowHeader, setrowHeader] = useState([]);
  const [headerInitiation, setheaderInitiation] = useState();
  const [footerInitiation, setfooterInitiation] = useState();
  const [selectedBtn, setselectedBtn] = useState();
  const [headerJson, setheaderJson] = useState([]);
  const [footerJson, setfooterJson] = useState([]);
  const [showIndicator, setshowIndicator] = useState(false);
  const [menuVisible, setmenuVisible] = useState(false);
  const [fieldsArr, setfieldsArr] = useState([]);
  const [headerArr, setheaderArr] = useState([]);
  const [etlField, setetlField] = useState([]);
  const [formattedData, setformattedData] = useState([]);
  const [shortName, setshortName] = useState("");
  const [name, setname] = useState("");
  const [row, setrow] = useState([]);
  const [col, setcol] = useState([]);
  const [selFormat, setselFormat] = useState("");
  const [selIdentifier, setselIdentifier] = useState(null);
  const [mainrecno, setmainrecno] = useState();
  const [dropCount, setDropCount] = useState(0);
  const [data, setData] = useState([]);
  const [types, settypes] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [colHeading, setColHeading] = useState("");
  const [apidata, setapidata] = useState();
  const [errorMessage, setErrorMessage] = useState("");
  const [mapdata, setmapdata] = useState();
  const [maindata, setmaindata] = useState();
  const [rowindex, setIRowndex] = useState(0);
  const [etlcode, setetlcode] = useState();
  const [fieldsArrByFormat, setfieldsArrByFormat] = useState([]);
  const [etlFormat, setEtlFormat] = useState();
  const [selectedValue, setSelectedValue] = useState("");
  const [selectedDomain, setSelectedDomain] = useState("");
  const [rowStart, setrowStart] = useState("");
  const [showValidation, setshowValidation] = useState(false);
  const [showcopyFormat, setshowcopyFormat] = useState(false);
  const [copyFromValues, setcopyFromValues] = useState([]);
  const [copyFormatType, setcopyFormatType] = useState();
  const [copyHeaderArr, setcopyHeaderArr] = useState([]);
  const [copymap, setcopymap] = useState();
  const [selectedETLType, setselectedETLType] = useState("");
  const [refresh, setRefresh] = useState(false);
  const [fileType, setFileType] = useState("");
  const [defVal, setDefVal] = useState("");
  const [requiredFields, setRequiredFields] = useState([]);
  const [inputErrors, setInputErrors] = useState([]);
  const [formatExist, setFormatExist] = useState(0);

  console.log("formatExist", formatExist);
  console.log("copyFromValues", copyFromValues);
  console.log("rowStart", rowStart);
  console.log("footerInitiation", footerInitiation);
  console.log("menuVisible", menuVisible);
  console.log("formattedData", formattedData);
  console.log("fieldsArrByFormat", fieldsArrByFormat);
  console.log("types", types);
  console.log("copymap", copymap);

  console.log("rowHeader", rowHeader);
  useEffect(() => {
    listForETL();
    fieldsforETL();
    // save(mapdata);
  }, []);

  const setMapDataFromHeaderArr = () => {

    if (headerArr) {
      const filteredHeaders = headerArr.filter((item) => item.etltype === selectedETLType);

      console.log("filteredHeaders", filteredHeaders[0]?.fields);


      filteredHeaders[0]?.fields.forEach((field) => {
        if (field.defaultvalue !== null) {
          const data = {
            keyname: field.fieldname,
            defaultvalue: field.defaultvalue,
            cellno: ""
          };

          console.log("data", data);

          console.log("showcopyFormat", showcopyFormat);

          if (!showcopyFormat) {
            mapdata.set(field.fieldname, data);
          }
        }
      });


      console.log("copymap--", copymap);

      console.log("mapdata--", mapdata);
    }
  };


  useEffect(() => {
    setMapDataFromHeaderArr()
  }, [headerArr]);



  useEffect(() => {
    if (refresh) {
      setRefresh(false);
    }
  }, [refresh]);


  useEffect(() => {
    save();
  }, [inputValue, colHeading, defVal]);

  useEffect(() => {
    console.log("save called")
    save();
  }, [copymap]);

  useEffect(() => {
    generateMap();
  }, [types]);

  // useEffect(() => {
  //   for (var i = 1; i < rowHeader.length; i++) {
  //     if (rowHeader[i] != '') {
  //       return setIndex(i);
  //     }
  //     console.log('indexrow', index);
  //   }
  // });
  let arr = [];
  function save() {
    console.log("showcopyFormat", showcopyFormat);
    console.log("copymap called", copymap?.size);
    if (copymap?.size > 0) {
      if (copymap != undefined) {
        console.log("copymap called", copymap);
        setapidata(copymap);
      }
    }
    else {
      if (mapdata != undefined) {
        console.log("mm", mapdata);
        setapidata(mapdata);
      }
    }



  }
  console.log("Map Data ==> ", mapdata);
  console.log("fieldsarray", fieldsArr);

  // console.log(
  //   'aa',
  //   apidata?.forEach((value, key) => {
  //     console.log('sss', value);
  //     return {
  //       keyname: value.keyname,
  //       columnheading: value.columnheading,
  //       cellno: value.cellno,
  //     };
  //   })
  // );

  async function generateMap(selectedItem) {
    //  fieldsArr;
    let tempMap = new Map();

    console.log("selectedItem", selectedItem);
    fieldsArr.forEach((element) => {
      if (element.etltype == selectedItem) {
        // console.log('element', element);
        element.fields?.forEach((item) => {
          console.log("element", item);

          let regex = "";
          if (item?.type == "date") {
            regex = "ddmmyyyy";
          }

          if (item?.key === "mobile") {
            regex = "/^[6-9]d{9}$/";
          } else if (item?.key === "pan") {
            regex = "/^[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}$/";
          } else if (item?.key === "gstn") {
            regex = "/^[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}$/";
          }

          let data = {
            keyname: item.fieldname,
            columnheading: regex,
            cellno: "",
          };
          tempMap.set(data);
          console.log("firstdata", data);
        });
      }
    });

    console.log("tempMap", tempMap);
    setmapdata(tempMap);
  }

  const [inputValues, setInputValues] = useState({});
  console.log("inputValues", inputValues)

  const handleStartIndex = (e, key, type) => {

  }

  const handleEndIndex = (e, key, type) => {

  }

  const copyFromFunc = (value) => {

    console.log("value", value)
    setname(value);
    headerFromApi()
    getDataByFormat(value)
    setcopyFormatType(value)



  }

  useEffect(() => {

    console.log("formattedData", formattedData);

    const initialValues = formattedData.reduce((values, record) => {
      record.fields.forEach((field) => {
        values[field.keyname] = field.cellno || '';
      });
      return values;
    }, {});

    const initialValuesLength = Object.keys(initialValues).length;
    console.log('Array Length:', initialValuesLength);



    console.log("initialValues", initialValues);

    if (initialValues) {
      const startRow = formattedData[0]?.startrow; // Accessing the startrow property from the first record

      console.log("startRow", startRow);
      if (startRow !== undefined) {
        setrowStart(startRow);
      } else {
        setrowStart("");
      }
      setInputValues(initialValues);
      setFormatExist(initialValuesLength)

    }
    else {
      setFormatExist(0)
    }


    const globalMap = new Map();
    const copymapdata = {};
    console.log("formattedData in copy", formattedData);

    for (let i = 0; i < formattedData[0]?.fields.length; i++) {
      const { cellno, keyname, columnheading, defaultvalue } = formattedData[0].fields[i];

      copymapdata[keyname] = { cellno, keyname, columnheading, defaultvalue };
    }



    globalMap.clear(); // Optional: Clear the globalMap if needed before setting new data
    Object.entries(copymapdata).forEach(([key, value]) => {
      globalMap.set(key, value);
    });

    console.log("globalMap", globalMap);
    setcopymap(globalMap);

  }, [formattedData]);

  useEffect(() => {
    console.log("name=", name)
    if (name == '') {
      setInputValues([]);
      setFormatExist(0)
      setrowStart("");
    }
  }, [name]);


  // Update the value of the input field when rowStart changes
  // useEffect(() => {
  //   setrowStart(rowStart);
  // }, [rowStart]);

  // Handle changes to the input field
  const handleRowStartChange = (e) => {
    setrowStart(e.target.value);
  };


  const handleKeyPressIndex = (e, key, type, dataIndex) => {
    const inputValue = e.target.value;

    let regex = '';

    if (type === 'date') {
      regex = 'ddmmyyyy';
    }

    if (key === 'mobile') {
      regex = /^[6-9]\d{9}$/;
    } else if (key === 'pan') {
      regex = /^[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}$/;
    } else if (key === 'gstn') {
      regex = /^[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}$/;
    } else if (dataIndex === 'items') {
      regex = /^[A-Za-z]+$/;
    } else if (dataIndex === 'header') {
      regex = /^[A-Za-z0-9]+$/;
    }

    setInputValue(inputValue);

    setInputValues((prevInputValues) => ({
      ...prevInputValues,
      [key]: inputValue,
    }));


    let isValidInput = true;
    let errorMessage = '';

    console.log("inputValue", inputValue)


    if (inputValue != '') {     //To check the entered index is valid or not
      if (dataIndex === 'items' && !inputValue.match(regex)) {
        isValidInput = false;
        errorMessage = 'Please enter only alphabet characters (e.g. A, AB)';
      } else if (dataIndex === 'header') {

        const letters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
        const firstLetter = inputValue[0]?.toUpperCase();
        console.log("inputValue--", inputValue, letters.includes(firstLetter), !isNaN(parseInt(inputValue[1])))

        if (letters?.includes(firstLetter) && !isNaN(parseInt(inputValue[inputValue?.length - 1]))) {
          isValidInput = true;
        } else {
          isValidInput = false;
          errorMessage = 'Please enter alphanumeric characters (e.g., A1, B2)';
        }

      }
    }


    setInputErrors((prevInputErrors) => {
      const newInputErrors = { ...prevInputErrors };
      if (isValidInput) {
        delete newInputErrors[key]; // Clear the error message if the input is valid
      } else {
        newInputErrors[key] = errorMessage; // Set the error message if the input is invalid
      }
      console.log("newInputErrors", newInputErrors)
      return newInputErrors;
    });

    if (isValidInput) {
      if (showcopyFormat) {
        let existingKeyIndex = copymap.get(key);
        if (existingKeyIndex === undefined) {
          let data = {
            keyname: key,
            columnheading: "",
            cellno: inputValue
          };
          copymap.set(key, data);
        } else {
          existingKeyIndex.cellno = inputValue;
          copymap.set(key, existingKeyIndex);
        }
      } else {
        let existingKeyIndex = mapdata.get(key);
        if (existingKeyIndex === undefined) {
          let data = {
            keyname: key,
            columnheading: "",
            cellno: inputValue
          };
          mapdata.set(key, data);
        } else {
          if (inputValue !== '') {
            existingKeyIndex.cellno = inputValue;
            mapdata.set(key, existingKeyIndex);
          } else {
            // Remove the entity from mapdata if cellno is blank
            mapdata.delete(key);
          }
        }
      }
    }
  };



  const setDefaultValue = (e, key, type) => {
    const inputValue = e.target.value;

    setDefVal(inputValue)

    if (showcopyFormat) {
      let existingKeyIndex = copymap.get(key);
      if (existingKeyIndex === undefined) {
        let data = {
          keyname: key,
          defaultvalue: inputValue,
          cellno: ""
        };
        copymap.set(key, data);
      } else {
        existingKeyIndex.defaultvalue = inputValue;
        copymap.set(key, existingKeyIndex);
      }
    }
    else {

      let existingKeyIndex = mapdata.get(key);
      if (existingKeyIndex === undefined) {
        let data = {
          keyname: key,
          defaultvalue: inputValue,
          cellno: ""
        };
        mapdata.set(key, data);
      } else {
        existingKeyIndex.defaultvalue = inputValue;
        mapdata.set(key, existingKeyIndex);
      }
      console.log("mapdata--", mapdata)
    }
  };

  const handleKeyDown = (e) => {
    const { key, target } = e;
    const keyName = target.getAttribute('data-key');

    console.log("keyName", keyName)

    if (key === 'Backspace' && target.value === '') {
      setInputValues((prevInputValues) => ({
        ...prevInputValues,
        [keyName]: '',
      }));

      let existingKeyIndex = mapdata.get(keyName);
      console.log("existingKeyIndex", existingKeyIndex)
      if (existingKeyIndex !== undefined) {
        existingKeyIndex.cellno = '';
        mapdata.set(keyName, existingKeyIndex);
      }
    }
  };








  const handleKeyPressHead = (e, key) => {
    setColHeading(e.target.value);
    let existingKeyHead = mapdata.get(key);
    console.log("existingKeyHead", existingKeyHead);
    if (existingKeyHead == undefined) {
      let data = {
        keyname: key,
        // columnheading: e.target.value,
        cellno: "",
      };
      mapdata.set(key, data);
    } else {
      existingKeyHead.columnheading = e.target.value;
      mapdata.set(key, existingKeyHead);
    }
  };

  async function listForETL(value) {
    try {
      let payload = {
        etltype: listOfETL,
        tenantrecno: auth?.state?.userdata?.tenantrecno,
      };

      let { data: res } = await axios.post(
        baseUrl + "getetltypewithfieldlist/",
        // "https://scientific.sutradhar.tech/backend/api/v1/getetltypewithfieldlist/",
        payload
      );

      if (res.Success) {
        // let updatedData = res.Message[0].fields.map((i) => {
        //   return { ...i, dropData: '' };
        // });

        console.log("fields:", res?.Message);
        {
          res?.Message.map((f) => {
            console.log("fieldname", f.fields);

            return setlistOfETL(f.fields);
          });
        }
      }
    } catch (e) {
      alert(e);
      console.log("e :", e);
    }
  }
  console.log("fieldlist", selectedBtn);

  async function fieldsforETL(value) {
    try {
      let payload = {
        tenantrecno: auth?.state?.userdata?.tenantrecno,
        domainrecno: selectedDomain,
      };

      let { data: res } = await axios.post(
        baseUrl + "getetltypewithfieldlist/",
        // "https://scientific.sutradhar.tech/backend/api/v1/getetltypewithfieldlist/",
        payload
      );

      if (res.Success) {
        setfieldsArr(res?.Message);
        let tempMap = new Map();
        console.log("apiresp", res?.Message);
      }
    } catch (e) {
      alert(e);
      console.log("e :", e);
    }
  }

  async function headerFromApi(value) {
    try {
      let payload = {
        tenantrecno: auth?.state?.userdata?.tenantrecno,
      };

      let { data: res } = await axios.post(
        baseUrl + "getetltypewithfieldlist/",
        // "https://scientific.sutradhar.tech/backend/api/v1/getetltypewithfieldlist/",
        // AppConstants.APIProduction + "/getetltypewithfieldlist/",
        payload
      );

      if (res.Success) {
        console.log("res?.Message", res?.Message);
        setheaderArr(res?.Message);
      }
    } catch (e) {
      alert(e);
      console.log("e :", e);
    }
  }
  console.log("headerArr", headerArr);


  //function for fetching submitted etl format
  async function fieldsforETLFormat(etltypecode) {
    try {
      let payload = {
        tenantrecno: auth?.state?.userdata?.tenantrecno,
        domainrecno: selectedDomain,
        etltypecode: etltypecode,
      };

      let { data: res } = await axios.post(
        baseUrl + "getsubmittedformatlist/",
        // "https://scientific.sutradhar.tech/backend/api/v1/getsubmittedformatlist/",

        payload
      );

      console.log("submitetl", res);
      if (res.Success) {
        setfieldsArrByFormat(res);
        setcopyFromValues(res)
      }
    } catch (error) {
      console.log("e :", error);
    }
  }



  //Handle Change
  const handleChange = (etltypecode) => {
    console.log("f.etltypecode", etltypecode);
    setetlcode(etltypecode);

    const selectedField = fieldsArr.find((f) => f.etltypecode === etltypecode);

    if (selectedField) {
      const { tablename, etltype } = selectedField;
      console.log("tablename", tablename);
      console.log("etltype", etltype);
      setselectedETLType(etltype)
      settypes(tablename);
      generateMap(tablename);
    }

    //fetch submitted etl formats
    fieldsforETLFormat(etltypecode)
    if (etlFormat) {
      setEtlFormat('')
    }

    if (showcopyFormat) {
      copymap.clear()
      setfieldsArrByFormat([])
      setheaderArr([])
      setrowStart("")
      setcopyFormatType(null)
      setname("")
      setFileType(null)

      setInputErrors([])
      setRequiredFields([])
      setFormatExist(0)
      setshowcopyFormat(false)
    }

  };

  function getItemRecno(rows, cols) {
    setcolumnHeader(cols);
    let num = 18;
    let rowData = rows.map((i, index) => {
      if (index > num) {
        return i;
      }
    });

    var filtered = rowData.filter(function (x) {
      return x !== undefined;
    });
    // console.log('rows :', rows);

    setrowFooter(rows);
  }

  const handleDragStart = (event, index) => {
    event.currentTarget.style.backgroundColor = "yellowgreen";
  };
  const handleDragEnd = (event) => {
    event.currentTarget.style.backgroundColor = "yellowgreen";
    setDropCount((prevCount) => prevCount + 1);
  };

  const col_static = [
    { id: 1, label: "Code" },
    { id: 2, label: "Date" },
    { id: 3, label: "Time" },
    { id: 4, label: "Custdescn" },
    { id: 4, label: "Invoice No" },
    { id: 5, label: "Amount" },
    { id: 6, label: "Delivery Address" },
    { id: 7, label: "Transporter descn" },
  ];

  const row_static = [
    { id: 1, label: "Item Name" },
    { id: 2, label: "Batch No" },
    { id: 3, label: "ExpDate" },
    { id: 4, label: "MfgDate" },
    { id: 4, label: "Mfg" },
    { id: 5, label: "Qty" },
    { id: 6, label: "Free" },
    { id: 7, label: "Rate" },
    { id: 8, label: "cgstamt" },
    { id: 9, label: "sgstamt" },
    { id: 9, label: "igstamt" },
  ];

  function rowandcolumnSetter(rows, cols) {
    // setrow(rows);
    setcol(cols);
    let rowData = rows.map((r, ind) => {
      cols.map((c, index) => {
        if (r[c.key] != undefined) {
          if (index != footerInitiation) {
            let sliced_header = rows.slice(r, footerInitiation);
            setrowHeader(sliced_header);
          }
          console.log("colsss", rows);
          console.log("rowsss", cols);
          console.log("rowheadsss", setrowHeader);
        }
      });
    });
  }

  //Function to upload Excel File
  const uploadFile = (event) => {
    setshowIndicator(true);
    let fileObj = event.target.files[0];
    ExcelRenderer(fileObj, (err, resp) => {
      if (err) {
        console.log("err ::", err);
      } else {
        console.log("Read Excel", resp);
        const { cols, rows } = resp;
        console.log("cols", cols);
        setshowIndicator(true);
        rowandcolumnSetter(rows, cols);
        setshowIndicator(false);
      }
    });

    setmenuVisible(true);
  };

  function resetFile() {
    const file = document.querySelector(".file");
    file.value = "";
  }

  function onDrop(data) {
    console.log("onDrop", onDrop);
  }

  console.log("setheaderJson", headerJson);


  const refreshFunc = () => {
    setfieldsArrByFormat([])
    setselectedETLType(null)
    setformattedData([])
    setheaderArr([])
    setrowStart("")
    setcopyFormatType(null)
    setname("")
    setFileType(null)
    setshowcopyFormat(false)
    setInputErrors([])
    setRequiredFields([])
    setetlcode("")
    setFormatExist(0)
  }

  //submit format fuunction
  async function submitFormat(i) {

    let arr = [];

    apidata?.forEach((value, key) => {
      console.log("sss", value);
      arr.push({
        keyname: value?.keyname,
        columnheading: value?.columnheading,
        cellno: value?.cellno.toUpperCase(),
        defaultvalue: value?.defaultvalue
      });
    });
    console.log("www", arr);
    console.log("length", arr?.length);



    function checkFun() {
      var duplicate = "";
      var valueArr = arr.map(function (item) {
        return item.cellno;
      });
      console.log("valueArr", valueArr);

      var isDuplicate = valueArr.some(function (item, idx) {
        if (valueArr.indexOf(item) != idx) {
          duplicate = item;
        }
        console.log("valueArr", valueArr.indexOf(item) != idx);
        return valueArr.indexOf(item) != idx;
      });

      if (isDuplicate === true) {
        alert(`${duplicate}` + " value is Duplicate");
        return true;
      }
      console.log("isDuplicate", isDuplicate);
    }

    // if (checkFun()) {
    //   return;
    // }

    try {
      let payload = {
        tenantrecno: auth?.state?.userdata?.tenantrecno,
        domainrecno: selectedDomain,
        domainuserrecno: 287,
        etltypecode: etlcode,
        descn: name,
        startrow: rowStart,
        noofcolumns: arr?.length,
        data: arr,
      };
      console.log("payload", payload);

      //old api
      // let res = await axios.post(
      //   AppConstants.APIProduction + "/addetlformats/",
      //   payload
      // );
      console.log("arr", arr);
      const arrFileds = arr
        .filter(obj => obj.cellno === "" && (obj.defaultvalue === undefined || obj.defaultvalue === ""))
        .map(obj => obj.keyname);

      console.log("arrFileds", arrFileds);
      const filteredFields = headerArr
        .filter(obj => obj.etltype === selectedETLType)
        .flatMap(obj => obj.fields)
        .filter(field => field.req)
        .map(field => {
          return {
            fieldname: field.fieldname,
            columndisplay: field.columndisplay
          };
        });

      console.log("filteredFields", filteredFields);

      const missingFileds = filteredFields
        .filter(field => arrFileds.includes(field.fieldname))
        .map(field => field.columndisplay)

      console.log("missingFileds", missingFileds);

      let concatenatedString = "";
      console.log("rowStart", rowStart);


      concatenatedString = missingFileds.join(", ");

      console.log("concatenatedString", concatenatedString);

      if (!rowStart) {
        if (missingFileds.length > 0) {
          concatenatedString += ",";
        }
        concatenatedString += " Items List Starts From ";
      }

      //  const spacedMissingFields = concatenatedString.map(item => item.replace(/,/g, ', '));

      setRequiredFields(concatenatedString);

      console.log("inputErrors", Object.keys(inputErrors).length === 0);

      if (missingFileds.length == 0 && rowStart && Object.keys(inputErrors).length === 0) {
        let res = await axios.post(
          baseUrl + "addetlformats/",
          // "https://scientific.sutradhar.tech/backend/api/v1/addetlformats/",
          payload
        );

        if (res.data.Success) {
          alert("Submitted!")
          setmainrecno(res.data.Message[0].recno);
          setselFormat("");
          setselIdentifier(null);
          setmenuVisible(true);
        }
        console.log("ETL Master Res :", res.data.Message);
      }


    } catch (e) {
      alert(e);
      console.log("Error :", e);
    }
  }

  // async function bulkaddetlfooter() {
  //   try {
  //     let headerData = headerJson.map((i) => {
  //       // console.log('firstiii', headerJson);

  //       return {
  //         mainrecno: mainrecno,
  //         isheader: true,
  //         fieldname: i.field,
  //         fieldformat: null,
  //         cellno: i.rowIndex + "," + i.colIndex,
  //       };
  //     });

  //     let footerData = footerJson.map((f) => {
  //       return {
  //         mainrecno: mainrecno,
  //         isheader: false,
  //         fieldname: f.field,
  //         fieldformat: null,
  //         cellno: f.rowIndex + "," + f.colIndex,
  //       };
  //     });
  //     console.log("footerData", footerData);

  //     let payload = [...headerData, ...footerData];

  //     console.log("payload :", payload);

  //     let res = await axios.post(
  //       AppConstants.APIProduction + "/bulkaddetlfooter/",
  //       payload
  //     );

  //     console.log("res.data.Message :", res.data.Message);

  //     if (res.data.Success) {
  //       toast(res.data.Message, { type: "success" });
  //     }
  //   } catch (e) {
  //     toast(e, { type: "error" });
  //     console.log("Error :", e);
  //   }
  // }

  console.log("row :", row);
  console.log("Header Json :", headerJson);
  console.log("Footer Json :", footerJson);

  const format = [
    { label: "txt", value: "txt" },
    { label: "csv", value: "csv" },
    { label: "excel", value: "excel" },
    { label: "xml", value: "xml" },
  ];

  const identifier = [
    { label: "comma", value: "comma" },
    { label: "pipe", value: "pipe" },
  ];

  console.log("inputvalue", inputValue);
  console.log("colHeading", colHeading);

  // var x = fieldsArr.filter((item) => item.etltypecode);
  // setData(x);
  // console.log('x', x);

  // var obj = { cellno: inputValue, columnheading: colHeading };
  // if (inputValue && colHeading != '') {
  //   settabledata([...tabledata, obj]);
  // }
  // console.log('tabledata', tabledata);

  const [domainOptions, setDomainOptions] = useState({ Message: [] });
  // other state variables if needed

  // Fetch domain options from the API

  useEffect(() => {
    let body = {
      tenantrecno: auth?.state?.userdata?.tenantrecno,

    };

    let apiurl =
      "https://scientific.sutradhar.tech/backend/api/v1/getdomainlist/";

    const fetchDomainOptions = async () => {
      try {
        const response = await axios.post(baseUrl + "getdomainlist/", body);
        console.log("response==", response);
        setDomainOptions(response.data); // Assuming the API response is an array of domain options
      } catch (error) {
        console.error("Error fetching domain options:", error);
      }
    };



    fetchDomainOptions();
  }, []);

  useEffect(() => {
    setSelectedDomain(domainOptions?.Message[0]?.recno);
  }, [domainOptions]);

  console.log("domainOptions=", domainOptions);

  console.log("selectedValue", selectedValue);



  const handleRadioChange = (event) => {
    console.log("value", event.target.value)
    setSelectedValue(event.target.value);
  };

  const setDomain = (value) => {
    setSelectedDomain(value);
  };
  console.log("selectedDomain=", selectedDomain);



  const handleNextClick = () => {

    if (selectedValue == '') {
      alert("Please Select Table Type")
    } else {
      setshowValidation(true)
    }

  };

  useEffect(() => {
    fieldsETL(types);
  }, [types]);

  //fetching data
  async function fieldsETL(value) {
    console.log("value", value);
    try {
      let payload = {
        tenantrecno: auth?.state?.userdata?.tenantrecno,
        domainrecno: selectedDomain,
        etltypecode: etlcode,
      };

      let apiurl =
        "https://scientific.sutradhar.tech/backend/api/v1/getetlmasterlist/";

      let { data: res } = await axios.post(baseUrl + "getetlmasterlist/", payload);

      console.log("res", res.Message);
      if (res.Success) {
        setetlField(res?.Message);
        // settypes(value);
      }
    } catch (e) {
      alert(e);
      console.log("e :", e);
    }
  }

  async function getDataByFormat(name) {
    console.log("name--", name);
    if (name !== '') {
      try {
        let payload = {
          tenantrecno: auth?.state?.userdata?.tenantrecno,
          domainrecno: selectedDomain,
          etltypecode: etlcode,
          descn: name,
        };
        console.log("payload", payload);

        if (name) {
          let { data: res } = await axios.post(baseUrl + "getetlmasterlist/", payload);

          console.log("newres", res.Message);
          if (res.Success) {
            setformattedData(res?.Message);
            console.log("formattedData.l", formattedData?.length);
            if (formattedData?.length === 0) {
              setrowStart("")
            }

            // settypes(value);
          }
        }

      } catch (e) {
        alert(e);
        console.log("e :", e);
      }
    }
  }

  console.log("etlfield", etlField);

  const selectHeader = (value) => {
    fieldsETL(types);
    setselectedBtn(value);
  };

  const handleNameChange = (e) => {
    const newName = e.target.value;
    console.log("newName", newName);
    console.log("showcopyFormat", showcopyFormat);
    if (!showcopyFormat) {
      if (newName !== '') {

        headerFromApi();
      } else {
        setheaderArr([]);
        setrowStart("")
      }

      getDataByFormat(newName);
    }

    setname(newName);

  };

  const funcFields = (value) => {
    console.log("value", value)
    setname(value);
    headerFromApi()
    getDataByFormat(value)
    setEtlFormat(value)
  }

  const fileTypeFunc = (value) => {
    setFileType(value)
  }

  const closeError = () => {
    setRequiredFields([])
  }



  const options = [
    { value: 'option1', label: 'Option 1' },
    { value: 'option2', label: 'Option 2' },
    { value: 'option3', label: 'Option 3' },
    // Add more options as needed
  ];

  let body = {
    tenant: "testing",
    appshortname: "recovery"
  }
  let apiUrl = "https://dc.sutradhar.tech/cbsmobile/api/GetSecurityList"

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      {!showValidation &&
        <div style={{ margin: "1%" }}>
          <div style={{ width: 150 }}></div>
          {/* <div className="etltool_back">
            <CustomButton
              type="back"
              text="next"
              style={{ marginLeft: "auto" }}
              clickHandler={handleNextClick}
            />
          </div> */}
          {/* <Button type="primary" style={{width: "fit-content",marginLeft:"auto"}} onClick={handleNextClick}>
          Next
        </Button> */}
          {/* <h3 style={{ textAlign: "center" }}>Submit Format</h3> */}

          {/* Menu Selection  */}
          <div style={{ marginTop: "1%" }}>
            {/* <div style={{
              display: "flex",
              flexDirection: "row",
              flex: 0.2,
              height: 80,
              justifyContent: "center",
            }}>
              <label>
                <input
                  type="radio"
                  value="single"
                  checked={selectedValue === "single"}
                  onChange={handleRadioChange}
                />
                Single Table
              </label>
              <label>
                <input
                  type="radio"
                  value="multiple"
                  checked={selectedValue === "multiple"}
                  onChange={handleRadioChange}
                />
                Multiple Table
              </label>

            </div> */}
            <div
              style={{
                marginTop: "1%",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between", // Updated justifyContent property
              }}
            >
              <div style={{ flex: 0.2 }}>
                <label style={{ fontWeight: "bold" }}>Business Unit*</label>
                <Select
                  style={{ width: "100%", height: 40, marginTop: "4%" }}
                  placeholder="Select Domain"
                  value={domainOptions?.Message[0]?.recno}
                  onChange={setDomain}
                >
                  {domainOptions?.Message.map((item) => (
                    <option value={item?.recno} key={item?.recno}>
                      {item?.descn}
                    </option>
                  ))}
                </Select>
              </div>

              <div style={{ flex: 0.2 }}>
                <label style={{ fontWeight: "bold" }}>ETL Type*</label>
                <Select
                  style={{ width: "100%", height: 40, marginTop: "4%" }}
                  value={selectedETLType}
                  placeholder="ETL Type"
                  onChange={handleChange}
                >
                  {fieldsArr.map((f) => (
                    <option key={f.etltypecode} value={f.etltypecode}>
                      {f.etltype}
                    </option>
                  ))}
                </Select>
              </div>

              <div style={{ flex: 0.2 }}>
                <label style={{ fontWeight: "bold" }}>File Type*</label>
                <Select
                  style={{ width: "100%", height: 40, marginTop: "4%" }}
                  placeholder="File Type"
                  value={fileType}
                  onChange={fileTypeFunc}
                >
                  {format.map((f) => (
                    <option value={f?.value} key={f?.value}>
                      {f?.label}
                    </option>
                  ))}
                </Select>
              </div>

              {selFormat === "csv" && (
                <div style={{ flex: 0.15 }}>
                  <label style={{ fontWeight: "bold" }}>Select Identifier</label>
                  <Select
                    style={{ width: "90%", height: 40 }}
                    placeholder="Select For Format"
                    onChange={(event) => {
                      setselIdentifier(event.target.value);
                    }}
                  >
                    {identifier.map((id) => (
                      <option value={id.value} key={id.value}>
                        {id.label}
                      </option>
                    ))}
                  </Select>
                </div>
              )}

              <div style={{ flex: 0.2 }}>
                <label style={{ fontWeight: "bold" }}>Format Name*</label>
                <TextField
                  style={{ width: "100%", height: 40, marginTop: "4%" }}
                  placeholder="Format Name"
                  inputProps={{ style: { height: 0 } }}
                  onChange={handleNameChange}
                  value={name}
                  disabled={etlcode === ""}
                />
                {formatExist > 0 && !showcopyFormat && (
                  <p style={{ color: "red", fontSize: "13px", marginTop: "8px" }}>
                    This Format Is Already Exist!
                  </p>
                )}
              </div>



            </div>       



            {fieldsArrByFormat &&
              fieldsArrByFormat.Count > 0 && <div
                style={{
                  marginTop: "1%",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "start",
                }}
              >

                <div
                  style={{ flex: 0.2 }}
                >
                  <Button
                    style={{ marginRight: "1%" }}
                    type="primary"
                    onClick={() => setshowcopyFormat(true)}
                  >
                    Copy Format From Previous
                  </Button>
                </div>

                {showcopyFormat && <div
                  style={{ flex: "0.2 1 2%", marginLeft: "9%" }}
                >
                  <label style={{ fontWeight: "bold" }}>Available Formats</label>
                  <Select
                    style={{ width: "100%", height: 40, marginTop: "4%" }}
                    placeholder="Select For Format"
                    value={copyFormatType}
                    onChange={copyFromFunc}
                  >
                    {copyFromValues &&
                      copyFromValues.Message &&
                      copyFromValues.Message.map((item) => (
                        <option key={item.recno} value={item.descn}>
                          {item.descn}
                        </option>
                      ))}
                  </Select>
                </div>}

              </div>}

            <div
              style={{
                marginTop: "1%",
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
              }}
            >

              <div
                style={{
                  display: "flex",
                  width: "20%",
                  justifyContent: "end",
                }}
              >
              </div>
              <Button
                style={{ marginRight: "10px" }}
                type="primary"
                onClick={refreshFunc}
              >
                Refresh
              </Button>
              <Button
                style={{ marginRight: "1%" }}
                type="primary"
                onClick={submitFormat}
              >
                Create Format
              </Button>

            </div>

            {requiredFields.length > 0 && (
              <Card

                title="Error"
                extra={<a onClick={closeError}>Close</a>}
                style={{
                  width: "100%",
                  height: "175px", // Set the desired height for the Card component
                  overflow: "auto", float: "right", border: "1px solid red",
                  marginBottom: "1%",
                  marginTop: "1%"
                }}
              >

                <div >
                  <p style={{ color: "red" }}>Please Fill The Required Fileds : </p><p>{requiredFields}</p>
                  <div>
                    {Object.entries(inputErrors).map(([key, value]) => (
                      <p style={{ color: "red" }} key={key}>{key}: {value}</p>
                    ))}
                  </div>
                  {/* {!rowStart && <p style={{color:"red"}}>Please Fill The Items List Start From </p>} */}
                </div>

              </Card>
            )}
          </div>


          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-around",
              marginTop: "3%",
              flexWrap: "wrap",
              borderWidth: 0.6,
              borderStyle: "dashed",
            }}
          >
            <h3 style={{ padding: "1%", background: "lightgrey" }}>Column Details</h3>
            {/* <b style={{ padding: "1%" }}>Field Name:</b>

        <b style={{ padding: "1%" }}> RowIndex, ColumnIndex</b> */}
            <div style={{ marginTop: "1%" }}>
              <p>
                <b style={{ padding: "1%" }}> Items List Starts From*{" "}</b>
                <input
                  style={{ width: "4vw", padding: "0.5vw" }}
                  onChange={handleRowStartChange}
                  value={rowStart}
                />
              </p>
            </div>


            <div>

            </div>



            <div
              style={{
                display: "flex",
                // justifyContent: "center",
                flexDirection: "row",
                padding: "1%",
                flexWrap: "wrap",
                //backgroundColor: "lightgrey",
                //marginTop: "1%",
                width: "45%",
              }}
            >
              <table border={"1"} style={{ textAlign: "initial" }}>
                <thead >
                  {headerArr.length > 0 && (
                    <tr>
                      <th>Column Name</th>
                      <th>Index</th>
                      <th style={{ width: "40%" }}>Default Value</th>
                      {/* <td>Start Index</td>
        <td>End Index</td> */}
                    </tr>
                  )}
                </thead>
                <tbody>
                  {headerArr
                    .filter((item) => item.etltype === selectedETLType)
                    .map((row, index) =>
                      row.fields.map((f, index) => (
                        <tr key={index}>
                          <td style={{ padding: "5px" }}>
                            {f.columndisplay}
                            {f.req === 1 ? "*" : null}
                          </td>
                          <td style={{ padding: "5px" }}>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                alignContent: "center",
                                justifyContent: "center",
                              }}
                            >
                              <input
                                style={{
                                  width: "4vw",
                                  padding: "0.5vw",
                                  textTransform: "uppercase",
                                }}
                                onChange={(e) => handleKeyPressIndex(e, f.fieldname, f.type, f.dataIndex)}

                                onKeyDown={handleKeyDown}
                                value={inputValues[f.fieldname] || ''}
                                data-key={f.fieldname}
                              />
                              {inputErrors[f.fieldname] && <p style={{ color: 'red' }}>{inputErrors[f.fieldname]}</p>}
                            </div>
                          </td>
                          <td style={{ padding: "5px" }}>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                alignContent: "center",
                                justifyContent: "center",
                              }}
                            >
                              {f.req === 1 ? <input
                                style={{
                                  width: "84%",
                                  padding: "0.5vw",
                                }}
                                onChange={(e) => setDefaultValue(e, f.fieldname, f.type)}
                              /> : null}

                            </div>
                          </td>
                          {/* <td style={{ padding: "5px" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  alignContent: "center",
                  justifyContent: "center",
                }}
              >
                <input
                  style={{
                    width: "4vw",
                    padding: "0.5vw",
                    textTransform: "uppercase",
                  }}
                  onChange={(e) =>
                    handleStartIndex(e, f.fieldname, f.type)
                  }
                
                />
              </div>
            </td>
            <td style={{ padding: "5px" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  alignContent: "center",
                  justifyContent: "center",
                }}
              >
                <input
                  style={{
                    width: "4vw",
                    padding: "0.5vw",
                    textTransform: "uppercase",
                  }}
                  onChange={(e) =>
                    handleEndIndex(e, f.fieldname, f.type)
                  }
                  
                />
              </div>
            </td> */}
                        </tr>
                      ))
                    )}
                </tbody>
              </table>

            </div>



            {/* {name &&
          headerArr
            .filter((item) => item.tablename == types)
            .map((fr) => {
              console.log("frdata", fr.fields);
              return (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    flexWrap: "wrap",
                    flexDirection: "row",
                    alignItems: "center",
                    alignContent: "center",
                    padding: "1vw",
                  }}
                  types={["col_header"]}

                >
                  {fr.fields.map((f) => {
                    console.log("fdata", fr);
                    return (
                      <div
                        style={{
                          borderStyle: "dashed",
                          borderWidth: 0.6,
                          display: "flex",
                          flex: 1,
                          marginLeft: "1%",
                          justifyContent: "center",
                          elevation: "above",
                          margin: "1%",
                          flexWrap: "wrap",
                          flexDirection: "row",
                          paddingLeft: "1%",
                          paddingRight: "1%",
                          alignItems: "center",
                          alignContent: "center",
                          // backgroundColor: 'lightpink',
                          padding: "1vw",
                          // backgroundColor: headerJson.filter((h) => h.field == fr.data) ? 'grey' : 'white',
                        }}
                      >
                        <table
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            flexWrap: "wrap",
                            flexDirection: "column",
                            alignItems: "center",
                            alignContent: "center",
                          }}
                        >
                          <tr key={f.fieldname}>
                            <thead>{f.fieldname}</thead>
                            <br /><br />
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                alignContent: "center",
                                justifyContent: "center",
                              }}
                            >
                                    <input
                                style={{
                                  width: "4vw",
                                  padding: "0.5vw",
                                  textTransform: "uppercase",
                                }}
                                // onChange={  handleKeyPressIndex(e, f.fieldname)}
                                onChange={(e) =>
                                  handleKeyPressIndex(e, f.fieldname, f.type)
                                }
                                //value={etlField[0].fields.find(field => field.keyname === f.fieldname)?.cellno || ''}
                                // value={etlField.find(record => record.fields.some(field => field.keyname === f.fieldname))?.fields.find(field => field.keyname === f.fieldname)?.cellno || ''}
                                value={
                                  inputValues[f.fieldname] ||
                                  formattedData
                                    .find((record) =>
                                      record.fields.some(
                                        (field) =>
                                          field.keyname === f.fieldname
                                      )
                                    )
                                    ?.fields.find(
                                      (field) => field.keyname === f.fieldname
                                    )?.cellno ||
                                  ""
                                }
                              />
                            </div>
                          </tr>
                        </table>
                      </div>
                    );
                  })}

                </div>
              );
            })} */}
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-around",
              marginTop: "1%",
              flexWrap: "wrap",
              borderWidth: 0.6,
            }}
          >
            {/* {fieldsArr.map((fr) => {
            return (
              <Droppable
                types={['col_header']}
                onDrop={(itm) => {
                  let data = itm.col_header.split(',');
                  console.log('datafr', data);
                  setfooterJson([
                    ...footerJson,
                    {
                      fields: fr.fieldname,
                      rowIndex: data[0],
                      colIndex: data[1],
                    },
                  ]);
                }}
                style={{
                  borderStyle: 'dashed',
                  borderWidth: 0.6,
                  display: 'flex',
                  flex: 1,
                  marginLeft: '1%',
                  justifyContent: 'center',
                  elevation: 'above',
                  margin: '1%',
                  paddingLeft: '1%',
                  paddingRight: '1%',
                  backgroundColor: footerJson.filter((h) => h.field == fr.data) ? 'red' : 'white',
                }}
              >
                <div>
                  <p style={{ fontWeight: 'bold' }}>{fr.fieldname}</p>
                </div>
              </Droppable>
            );
          })} */}
          </div>


          {/* <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          flexDirection: "column",
          marginTop: "1%",
          flex: 1,
        }}
      >
        {rowHeader.map((r, index) => {
          console.log("rowHeader", r);

          return (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "row",
                // flexWrap: 'wrap',
                backgroundColor: "lightgrey",
                padding: "1%",
                width: "100vw",
              }}
            >
              {r.map((c, ind) => {
                console.log("c :", c);
                return (
                  <Draggable
                    type="col_header"
                    data={[index, ind, c]}
                    style={{ border: "1px solid black" }}
                  >
                    <table
                      draggable="true"
                      onDragOver={handleDragStart}
                      onDragEnd={handleDragEnd}
                    >
                      <th key={ind} style={{ textAlign: "center" }}>
                        {c}
                      </th>
                    </table>
                  </Draggable>
                );
              })}
            </div>
          );
        })}
      </div> */}

          {menuVisible ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "row",
                padding: "1%",
                flexWrap: "wrap",
                backgroundColor: "lightgrey",
                marginTop: "2%",
                width: "auto",
              }}
            >
              {/* {rowHeader.map((r, index) => {
            console.log("rowHeader", r);

            return (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  padding: "1%",
                  width: "100%",
                  gap: "5%",
                }}
              >
                {r.map((c, ind) => {
                  console.log("c :", c);
                  return (
                    <Draggable
                      type="col_header"
                      data={[index, ind, c]}
                      style={{
                        marginRight: "1%",
                        marginTop: "1%",
                        width: "auto",
                      }}
                    >
                      <Card
                        style={{
                          width: "auto",
                          backgroundColor: "white",
                          paddingLeft: "1vw",
                          paddingRight: "1vw",
                        }}
                        draggable="true"
                        onDragOver={handleDragStart}
                        onDragEnd={handleDragEnd}
                      >
                        <p key={ind} style={{ textAlign: "center" }}>
                          {c}
                          <br />
                         
                        </p>
                      </Card>
                     
                    </Draggable>
                  );
                })}
              </div>
            );
          })} */}

              <table border={"1"}>
                <thead>
                  {rowHeader.map((r, index) => (
                    <tr key={index}>
                      {r.map((c, ind) => (
                        <th key={ind} style={{ padding: "5px" }}>
                          {c}
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody></tbody>
              </table>
            </div>
          ) : null}
          <div style={{ padding: "2%", display: "flex", flexDirection: "column" }}>
            {headerJson &&
              headerJson.map((j) => {
                return (
                  <div style={{ marginBottom: "2%" }}>
                    <label>field : &nbsp; &nbsp; {j.field} ,</label>&nbsp; &nbsp;
                    <label>
                      location : &nbsp; &nbsp; [{j.rowIndex}, {j.colIndex}] ,
                    </label>
                    &nbsp; &nbsp;
                    <br />
                    {/* <p>
                  The component has been dropped {dropCount} {dropCount === 0 ? 'time' : 'times'}.
                </p> */}
                  </div>
                );
              })}
            {footerJson &&
              footerJson.map((f) => {
                console.log("f", f);
                return (
                  <div style={{ marginBottom: "2%" }}>
                    <label>field : &nbsp; &nbsp; {f.field} ,</label>&nbsp; &nbsp;
                    <label>
                      location : &nbsp; &nbsp; [{f.rowIndex}, {f.colIndex}]
                    </label>
                    &nbsp; &nbsp;
                  </div>
                );
              })}
          </div>
        </div>
      }

    </div>
  );
}
export default ToolETL;

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    padding: "5px",
    transform: "translate(-50%, -50%)",
    zIndex: "9999",
    width: "50%",
    height: "50%",
    borderRadius: "20px",
  },
};

import React, { useState, useEffect, useContext, useRef } from "react";
import useSaveChangedRows from "../../CommonControllers/Hooks/useSaveChangedRows";
import ItemSearch from "../../CommonControllers/ItemController/ItemSearch";
import {
  handleAdd,
  piColumns,
  handleRemove,
  handleRemovePrev,
} from "./SalesInvoiceFunc";
import { Button, Collapse, Input, Modal, Space, Table } from "antd";
import {
  getToday,
  dateFormatter,
  convertToBoxAndPieces,
} from "../../CommonControllers/AppFunction";
import useShortguid from "../../CommonControllers/Hooks/useShortGuid";
import { TabStore } from "../../TabsStructure/TabsStore";
import { AuthStore } from "../../Auth/AuthStore";
import { IoAperture } from "react-icons/io5";
import { List } from "antd";
import { FcAddDatabase } from "react-icons/fc";
import SaveDropdown from "../../CommonControllers/Components/SaveComponents/SaveDropdown";
import InputComponent from "../../CommonControllers/Components/InputComponent";
import SearchFilterByBillNo from "../../CommonControllers/Components/SearchFilterBillNo/SearchFilterByBillNo";

import SaveCheckbox from "../../CommonControllers/Components/SaveComponents/SaveCheckbox";
import { userlocationlist } from "../../CommonControllers/Urls";
import "../../CommonControllers/global.css";
import ScanTable from "../../CommonControllers/Components/ScanTable/ScanTable";
import ScannedItem from "./ScannenedItem";
import SaveDatePicker from "../../CommonControllers/SaveComponents/SaveDatePicker";
import SaveTextInput from "../../CommonControllers/Components/SaveComponents/SaveTextInput";
import ExecuteTextInput from "../../CommonControllers/Components/ExecuteComponents/ExecuteTextInput";
import useFetchItem from "../../CommonControllers/ItemController/useFetchItems";
import { setRef } from "@mui/material";
import SaveTextBox from "../../CommonControllers/NewComponents/SaveComponents/SaveTextBox";
import ExecuteTextBox from "../../CommonControllers/NewComponents/ExecuteComponents/ExecuteTextBox";
import { getfastpathapi } from "../../CommonControllers/ApiCalls";
import { currentTabData } from "../../CommonControllers/Variable";
import AdditionalDetails from "../../CommonControllers/AdditionalDetails/AdditionalDetails";
import ItemSelectionModal from "../../CommonControllers/ItemController/ItemSelectionModal";
import CallbackDateInput from "../../CommonControllers/Components/CallbackComponents/CallbackDateInput";
import MultipleSelectionItem from "../GRN/MultipleSelectionItem";

function SalesInvoiceOpening() {
  const [getSelectedItem, setSelectedItem] = useState(null);
  const [displayValue, setdisplayValue] = useState(0);
  const [invoiceAmount, setinvoiceAmount] = useState(0);
  const [typeAmt, setTypeAmt] = useState("Rounding:");
  const [rowData, setRowData] = useState([]);
  const [igstappl, setigstappl] = useState(false);
  const useChangedRows = useSaveChangedRows();
  const [displayMessages, setDisplayMessages] = useState(false);
  const [roundDiff, setRoundDiff] = useState();
  const shortguid = useShortguid();
  const tabStore = useContext(TabStore);
  const auth = useContext(AuthStore);
  const { Panel } = Collapse;
  const [change, setChange] = useState(0);

  const boxRef = useRef(null);
  const piecesRef = useRef(null);
  const itemSearch = useRef(null);
  const btnRef = useRef(null);
  const itemSearchdiv = useRef(null);
 
  const [box, setBox] = useState("");
  const [collapse, setCollapse] = useState(false);
  const [expDeliveryDays, setExpDeliveryDays] = useState(null);
  const [salerate, setSaleRate] = useState(getSelectedItem?.purrate);
  const [selectedQty, setSelectedQty] = useState(0);
  const [itemInformation, setItemInformation] = useState();
  const [acctualQty, setAcctualQty] = useState(); // Qty as in pieces in
  const [freeBox, setFreeBox] = useState(0);
  const [freeQty, setFreeQty] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [discountPer, setDiscountPer] = useState(0);
  const [batchNo, setBatchNo] = useState(null);
  const [expDate, setExpDate] = useState(0);
  const [manuDate, setManuDate] = useState(0);
  const [showTable, setShowTable] = useState(true);
  const [getInfoFromPO, setInfoFromPO] = useState([]);
  const [customerData, setCustomerData] = useState({}); //~# Setting customer data in this state
  const [drivername, setdrivername] = useState(null);
  const [vehiclenumber, setvehiclenumber] = useState(null);
  const [siDate, setSiDate] = useState(auth.state.userdata.openingbaldate)
  // -------------multiple selecton-----------------
  const [previousPOList, setPreviousPOList] = useState([]);
  const [displayPrevPO, setDisplayPrevPO] = useState(false);
  const [displayPrevSO, setDisplayPrevSO] = useState(false);

  const [displayPrevDC, setDisplayPrevDC] = useState(false);

  const [returnData, setreturnData] = useState([]); //To Get the data from Selection Component of Miltiple Selection
  const [count, setCount] = useState(0);
  const [itemForSelection, setItemForSelection] = useState(null);

  const [pageIndex, setPageIndex] = useState();
  // scan table validation rules
  const [validator, setValidator] = useState(0);
  const [totalQty, setTotalQty] = useState(0);

  ////dropdown clear//////

  const [modalcount, setModalCount] = useState(0);

  ///// scannned items/////

  const [modal, setModal] = useState(false);
  const [scanner, setScanner] = useState([]);
  // ---------------------Edit Transtion States------------------
  // Create useState for Show POP Of Search Bill
  const [showFilterModal, setshowFilterModal] = useState(false);
  // render
  const [countRender, setcountRender] = useState(0);
  const [siNumber, setSiNumber] = useState();
  const [location, setlocation] = useState();

  const fetchItems = useFetchItem();

  // scan table
  const [openItem, setOpenItem] = useState(false);
  const [scandata, setScandata] = useState();
  const [renderer, setRenderer] = useState(0);

  useEffect(() => {
    var tabsArray = tabStore.state.tabs;
    for (let i = 0; i < tabsArray?.length; i++) {
      if (tabsArray[i].name == "TR997") {
        setPageIndex(i);
        break;
      }
    }
  }, []);

  useEffect(() => {
    if (itemForSelection) {
    }
  }, [setItemForSelection]);

  const [pageDetails, setPageDetails] = useState();
  useEffect(() => {
    const payload = {
      tenantrecno: auth?.state?.userdata?.tenantrecno,
      domainrecno: auth?.state?.userdata?.domainrecno,
      userrolerecno: auth?.state?.userdata?.userrolerecno,
      domainuserrecno: auth.state.userdata.recno,
      fastpath: currentTabData(tabStore)?.name,
    };
    // Get Fast ath API
    getfastpathapi(payload).then((res) => {
      console.log("rr", res);
      if (res?.Success) {
        setPageDetails(res?.Message);
      }
    });
  }, []);

  useEffect(() => {
    setShowTable(false);

    setTimeout(() => {
      setShowTable(true);
    }, 100);
  }, [change]);

  useEffect(() => {
    console.log("returndataaaa", returnData);
  }, [returnData]);

  /// ONE DC ONE INVoice

  useEffect(() => {
    if (getSelectedItem) {
      setSaleRate(Number(getSelectedItem?.salerate).toFixed(2));
    }
  }, [getSelectedItem]);

  let grossAmt =
    (Number(box) * 10 + Number(selectedQty)) *
    (Number(salerate) - Number(discount)) || "00";

  let taxableAmt =
    (Number(box) * Number(getSelectedItem?.conversionfactor) +
      Number(selectedQty)) *
    Number(salerate) -
    Number(discount) || "00";

  //~# Setting workdate
  let workdate = auth?.state?.workdate;
  workdate = JSON.stringify(workdate);
  workdate = `${workdate?.slice(-2)}/${workdate?.slice(4, 6)}/${workdate?.slice(
    0,
    4
  )}`;

  console.log("rowData:", rowData);
  const enterPress = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      const form = e.target.form;
      const index = Array.prototype.indexOf.call(form, e.target);
      if (form.elements[index + 1]) {
        form.elements[index + 1].focus();
      }
    }
  };

  console.log("getSelectedItem in salesinvoice", getSelectedItem);
  useEffect(() => {
    // console.log("getSelectedItem in salesinvoice", getSelectedItem);
    setAcctualQty(
      Number(box) * Number(getSelectedItem?.conversionfactor) +
      Number(selectedQty)
    );
  }, [box, selectedQty]);
  //// may 31 rajendra
  useEffect(() => {
    //if (getSelectedItem?.isservice==0) {
      addItemstoTable();
   
  }, [getSelectedItem]);

  const addItemstoTable = () => {
    // if (!getSelectedItem.itembasbatch && !batchNo) {
    //   alert("Batch Cannot be Null");
    //   return;
    // }
    console.log("ftuygihojpk[l]")
    if (getSelectedItem) {
      var existingItem = rowData.filter(
        (item) => item.itemrecno == getSelectedItem.itemrecno
      );

      if (existingItem?.length == 0) {
        // clearing all input values
        setBox("");

        setSelectedQty("");
        setSaleRate("");

        setFreeBox(0);
        setFreeQty(0);
        setDiscount(0);
        setDiscountPer(0);
        setBatchNo(0);
        setExpDate(null);
        setManuDate(null);
        // alert("Set Selected Qty", selectedQty);
        handleAdd(
          getSelectedItem,
          setRowData,
          igstappl,
          setSelectedItem,
          shortguid,
          salerate,
          selectedQty,
          box,
          freeBox,
          freeQty,
          batchNo,
          expDate,
          discount,
          discountPer,
          manuDate
        );
        itemSearch?.current?.focus();
      } else {
        alert("Item Already Present");
        setSelectedItem(null);
        itemSearch?.current?.focus();
      }
    }
  };

  /*
  In order to add the Selected Item with Batch Details into the rows array
  1. Check if item is itemasbatch
  2. If itemasbatch : directly select the item and ask to input the quantity required
  3. If Not itemasbatch : using for loop add the selceted items to the rowsData
  4. From the ItemBatch Details and the items object select the selectedQty and selectedFreeQty form the selected Items
  */

  const addItemBatchWiseToTable = () => {
    // Get getSelectedItems
    console.log(
      "Get Selected Items in Sales Invoice",
      getSelectedItem,
      getSelectedItem?.itembasbatch
    );
    // if (getSelectedItem?.itembasbatch) {
    //   //Added s at the end remove later
    //   // Here we need to add the from the Quantity,
    // } else {
    // Here we select form the selected Items
    let batchDetails = getSelectedItem?.batchdetails;
    for (let i = 0; i < batchDetails?.length; i++) {
      let itemrecno = getSelectedItem.itemrecno;
      let itemdescn = getSelectedItem.itemdescn;
      let igstrate = getSelectedItem.igstrate;
      let cgstrate = getSelectedItem.cgstrate;
      let sgstrate = getSelectedItem.sgstrate;
      let mrp = getSelectedItem.mrp;
      let conversionfactor = getSelectedItem.conversionfactor;
      let totalSelectedQuantity = batchDetails[i]?.selectedQty
        ? batchDetails[i].selectedQty
        : 0;
      let totalFreeQuantity = batchDetails[i]?.selectedFreeQty
        ? batchDetails[i]?.selectedFreeQty
        : 0;

      let batchNo = batchDetails[i].itembatchno;
      let itemSerialNo = batchDetails[i].serialno;
      let salerate = batchDetails[i].salerate;
      let expdate = batchDetails[i].expdate;
      let manudate = batchDetails[i].manudate;

      if (totalSelectedQuantity > 0 || totalFreeQuantity > 0) {
        let convertedQty = convertToBoxAndPieces(
          totalSelectedQuantity,
          conversionfactor,
          getSelectedItem?.packingextrarecno
        );

        let convertedFreeQty = convertToBoxAndPieces(
          totalFreeQuantity,
          conversionfactor,
          getSelectedItem?.packingextrarecno
        );

        console.log(
          "COnverted Qty",
          convertedQty,
          getSelectedItem?.packingextrarecno,
          conversionfactor,
          totalSelectedQuantity,
          totalFreeQuantity
        );
        let selectedBoxQty = convertedQty[0];
        let selectedPcsQty = convertedQty[1];
        let selectedFreeBoxQty = convertedFreeQty[0];
        let selectedFreePcsQty = convertedFreeQty[1];

        handleAdd(
          getSelectedItem,
          setRowData,
          igstrate > 0 ? true : false,
          setSelectedItem,
          shortguid,
          salerate,
          selectedPcsQty,
          selectedBoxQty,
          selectedFreeBoxQty,
          selectedFreePcsQty,
          batchNo,
          expdate,
          0,
          0,
          manudate
        );
      }
      // }
    }
  };

  useEffect(() => {
    if (getSelectedItem && !getSelectedItem?.itemasbatch) {
      var checkIfItemExists = rowData?.filter(
        (item) => item.itemrecno == getSelectedItem.itemrecno
      );
      console.log("Get Selected Items", getSelectedItem);

      if (checkIfItemExists?.length > 0) {
        alert("Item Already Exists");
      } else {
        addItemBatchWiseToTable();
      }
    }
  }, [getSelectedItem]);

  useEffect(() => {
    // setModalCount(modalcount + 1)
    console.log("Customer Data=====", customerData);
    if (customerData?.igstappl) {
      setigstappl(true);
    } else {
      setigstappl(false);
    }
  }, [customerData]);

  console.log(
    "When Trying to chec the Row Data",
    tabStore.state.tabs[pageIndex]
  );
  useEffect(() => {
    if (tabStore.state.tabs[pageIndex]?.changedRows?.dataFromEdit) {
      setRowData(tabStore.state.tabs[pageIndex]?.changedRows?.items);
    }
  }, [tabStore.state.tabs[pageIndex]?.changedRows?.dataFromEdit]);

  useEffect(() => {
    // When Row Data Changes we Need to store that data to the tabsStore with Header Data
    var existingObject = useChangedRows.getValue();

    // Calculate Other Numbers and Inputs and store in the tabStore
    //var status = "P";
    var trdate = "workdate";
    var approvalStatus = "P";
    var remark = "Need to add a field for that";
    var narration = "Need to ad a text field for this as well";
    var amount; // Calculate the total amount after tax
    var taxableamount; // Calculate the total taxable amount after tax
    var cgstamt; //Calculate the CGSTAMT
    var sgstamt; //Calculate teh SGST AMT
    var igstamt; //Calculat the IGSTAMT
    var shortguid;

    amount = rowData.reduce((total, item) => total + Number(item.amount), 0);
    console.log("Amount is  : ", existingObject);
    taxableamount = rowData.reduce(
      (total, item) => total + Number(item.taxableamount),
      0
    );
    cgstamt = rowData.reduce((total, item) => total + Number(item?.cgstamt), 0);
    sgstamt = rowData.reduce((total, item) => total + Number(item?.sgstamt), 0);
    igstamt = rowData.reduce((total, item) => total + Number(item?.igstamt), 0);
    // shortguid = existingObject.shortguid ? (existingObject.shortguid) : (shortguid.getShortguid("SI"));
    // console.log("new/old shortguid is", shortguid)

    existingObject = {
      ...existingObject,
      cgstamt: Number(cgstamt).toFixed(2),
      igstamt: Number(igstamt).toFixed(2),
      sgstamt: Number(sgstamt).toFixed(2),
      taxableamount: Number(taxableamount).toFixed(2),
      amount: Number(amount).toFixed(2),
      shortguid: new Date(), /// shortguid was going null after second time saving

      items: rowData,
      // trdate: auth.state.userdata.openingbaldate,
      podate: getToday(),
      status: "9",
      roundamt: Number(roundDiff).toFixed(2),
    };
    const invoiceAmount = existingObject?.amount;
    const roundOff = roundOffAmount(invoiceAmount).toFixed(2);
    const roundOffDiff = roundOff - invoiceAmount;

    existingObject.roundamt = Number(roundOffDiff).toFixed(2);
    useChangedRows.updateValue(false, existingObject);
    console.log("Update", existingObject);
  }, [rowData]);
  console.log("nirmal:", rowData);
  // useEffect(() => {
  //   let validateData = "true";

  //   for (let i = 0; i < rowData?.length; i++) {
  //     console.log("RowData", rowData[i]);
  //     if (
  //       rowData[i]?.itemasbatch &&
  //       Number(rowData[i]?.qty) +
  //         Number(rowData[i]?.free ? rowData[i]?.free : 0) !=
  //         rowData[i]?.itembatcharray?.length&&rowData[i]?.doctype!="DC"  // dc to si save button validation
  //     ) {
  //       // console.log("VLAIDATIONS APPLICATION", rowData[i]?.itemasbatch,
  //       // Number(rowData[i]?.qty) +
  //       //   Number(rowData[i]?.free ? rowData[i]?.free : 0),
  //       //   rowData[i]?.itembatcharray?.length)
  //       console.log("Validations are True")
  //       validateData = "false";
  //     }
  //   }
  //   console.log("Validated IS", validateData)
  //   useChangedRows.updateValue("validated", validateData);
  // }, [rowData])

  //   useEffect(() => {
  //     let isButtonDisabled = false;

  //     for (let i = 0; i < rowData.length; i++) {
  //       const { packingqty, packingextraqty, freepackingqty, freepackingextraqty,manufacturedate,expdate ,itembatchno} = rowData[i];

  // console.log("Packaging",rowData[i]?.itemasbatch)
  //       if ((packingqty === "0" && packingextraqty === "0") || (packingqty == "" && packingextraqty == "") || (packingqty === 0 && packingextraqty === "0") || (packingqty === "0" && packingextraqty === 0) || (packingqty === 0 && packingextraqty === 0) || (rowData[i]?.itemasbatch &&
  //         Number(rowData[i]?.qty) +
  //         Number(rowData[i]?.free ? rowData[i]?.free : 0) !=
  //         rowData[i]?.itembatcharray?.length)&&rowData[i]?.doctype!="DC") {

  //         isButtonDisabled = true;
  //         console.log("isButtonDisabled:122", isButtonDisabled);
  //         break;
  //       }
  //       else if ((packingqty === "0" && packingextraqty === "0") || (packingqty == "" && packingextraqty == "") || (packingqty === 0 && packingextraqty === "0") || (packingqty === "0" && packingextraqty === 0) || (packingqty === 0 && packingextraqty === 0) || (rowData[i]?.itemasbatch&&manufacturedate==null||expdate==null||itembatchno==null||itembatchno==""

  //       )) {
  //         console.log("Custermer891",Number(rowData[i]?.qty) +
  //         Number(rowData[i]?.free ? rowData[i]?.free : 0) ,
  //         rowData[i]?.itembatcharray?.length)
  //         isButtonDisabled = true;
  //         console.log("isButtonDisabled:122", isButtonDisabled);
  //         break;
  //       }
  //       console.log("isButtonDisabled:", isButtonDisabled, packingqty, packingextraqty);
  //     }

  //     useChangedRows.updateValue("validated", !isButtonDisabled ? "true" : "false");
  //   }, [rowData]);
  /// date 1 june 2023 validation start ///
  useEffect(() => {
    console.log("Packing======", rowData);
    let isButtonDisabled = false;

    for (let i = 0; i < rowData.length; i++) {
      const {
        packingqty,
        packingextraqty,
        freepackingqty,
        freepackingextraqty,
        manufacturedate,
        expdate,
        itembatchno,
        purrate,
        expdatecompulsory,
        itemasbatch,
        manudatecompulsory,
        wantbatchno,
        changesalerateinbill,
        isservice,
        qty,
        freeqty,
      } = rowData[i];
      console.log("Packing======", rowData[i]);
      if (isservice === 1 && qty + freeqty == 0) {
        isButtonDisabled = true;

        break;
      }
      if (isservice === 1 && qty + freeqty > 0) {
        isButtonDisabled = false;
        console.log("Chhhhhhh==123", rowData[i], packingqty, packingextraqty);
        break;
      }

      if (
        (isservice === 0 &&
          wantbatchno &&
          (!itembatchno ||
            itembatchno == "" ||
            itembatchno == null ||
            itembatchno == undefined)) ||
        (expdatecompulsory &&
          (!expdate || expdate == "undefined" || Number(expdate) == 0)) ||
        (manudatecompulsory &&
          (!manufacturedate ||
            manufacturedate == "undefined" ||
            Number(manufacturedate) == 0)) ||
        (packingqty === "0" && packingextraqty === "0") ||
        (packingqty === "" && packingextraqty == "") ||
        (packingqty === 0 && packingextraqty === "0") ||
        (packingqty === "0" && packingextraqty === 0) ||
        (packingqty === 0 && packingextraqty === 0) ||
        (rowData[i]?.doctype !== "DC" &&
          rowData[i]?.itemasbatch &&
          Number(rowData[i]?.qty) +
          Number(rowData[i]?.free ? rowData[i]?.free : 0) !==
          rowData[i]?.itembatcharray?.length)
      ) {
        isButtonDisabled = true;
        console.log("Chhhhhhh==1", rowData[i]);
        break;
      } else if (
        (isservice == 0 &&
          wantbatchno &&
          (!itembatchno ||
            itembatchno == "" ||
            itembatchno == null ||
            itembatchno == undefined)) ||
        (packingqty === "0" && packingextraqty === "0") ||
        (packingqty == "" && packingextraqty == "") ||
        (packingqty === 0 && packingextraqty === "0") ||
        (packingqty === "0" && packingextraqty === 0) ||
        (packingqty === 0 && packingextraqty === 0)
      ) {
        isButtonDisabled = true;
        console.log("Chhhhhhh==2");
        break;
      } else if (
        (isservice == 0 &&
          wantbatchno &&
          (!itembatchno ||
            itembatchno === "" ||
            itembatchno === null ||
            itembatchno === undefined)) ||
        (packingqty === "0" && packingextraqty === "0") ||
        (packingqty === "" && packingextraqty === "") ||
        (packingqty === 0 && packingextraqty === "0") ||
        (packingqty === "0" && packingextraqty === 0) ||
        (packingqty === 0 && packingextraqty === 0)
      ) {
        isButtonDisabled = true;
        console.log("Chhhhhhh==3");
        break;
      }

      if (
        rowData[i]?.doctype === "DC" &&
        rowData[i]?.qty + rowData[i]?.freeqty !== rowData[i]?.dcqty
      ) {
        isButtonDisabled = true;
        console.log("Chhhhhhh==4");
      }
    }
    console.log("Chhhhhhh==5");
    useChangedRows.updateValue(
      "validated",
      !isButtonDisabled ? "true" : "false"
    );
  }, [rowData]);
  /// date 1 june 2023 validation end///
  useEffect(() => {
    // When Row Data Changes we Need to store that data to the tabsStore with Header Data
    var currentData = useChangedRows.getValue();
    if (!currentData?.shortguid) {
      useChangedRows.updateValue("shortguid", shortguid.getShortguid("PO"));
    }

    if (rowData?.length === 0) {
      var items = useChangedRows.getValue("items");
      if (items) {
        setRowData(items);
      }
    }
  }, []);

  useEffect(() => {
    // When Row Data Changes we Need to store that data to the tabsStore with Header Data
    var currentData = useChangedRows.getValue();
    if (!currentData?.shortguid) {
      useChangedRows.updateValue("shortguid", shortguid.getShortguid("SI"));
    }
    setCustomerData(useChangedRows.getValue("customerrecno"));
    setSiNumber(useChangedRows.getValue("billno"));
    setlocation(useChangedRows.getValue("locationrecno"));

    var items = useChangedRows.getValue("items");
    if (items) {
      setRowData(items);
    }
  }, [showFilterModal]);

  useEffect(() => {
    if (tabStore.state.tabs[pageIndex]?.pageStatus == "Success") {
      setRowData([]);
      setigstappl(false);
      setSelectedItem(null);
      setSiNumber("TBA");
      setvehiclenumber("");
      setdrivername("");
      tabStore.dispatch({
        type: "UPDATE_DATA",
        fieldType: "pageStatus",
        text: null,
      });
      setSiDate(auth.state.userdata.openingbaldate);
      setModalCount((p) => p + 1);
      setTimeout(
        tabStore.dispatch({
          type: "UPDATE_DATA",
          fieldType: "pageStatus",
          text: null,
        }),
        1000
      );
    }
  }, [tabStore.state.tabs[pageIndex]?.pageStatus]);

  //// rendering the table again/////////
  useEffect(() => {
    setShowTable(false);

    setTimeout(() => {
      setShowTable(true);
    }, 200);
  }, [count, rowData?.length, tabStore.state.tabs[pageIndex]?.editIndex]);
  // useEffect(() => {
  //   setShowTable(false);

  //   setTimeout(() => {
  //     setShowTable(true);
  //   }, 200);

  //   let qty=rowData?.reduce((acc,q)=>q.qty+acc,0)
  //   console.log("RRRRaj",qty)

  // }, [totalQty
  // ]);

  //----------multiple selection item start------------------------------------------------

  const optionsforDC = [
    {
      id: 1,
      optionName: "Challan",
      apiurl: "filteropeningdeliverychalan",
      customerrecno: customerData?.customerrecno,
      doctype: "DC",
    },
  ];

  const optionforSO = [
    {
      id: 2,
      optionName: "Sale Order",
      apiurl: "filtersaleorder",
      customerrecno: customerData?.customerrecno,
      doctype: "SO",
    },
  ];

  function roundOffAmount(amount) {
    const roundedAmount = Number(Math.round(amount * 100) / 100);
    if (roundedAmount < 0.5) {
      return Math.floor(roundedAmount);
    } else {
      return Math.round(roundedAmount);
    }
  }
  //  function CreditOrDebitAmt(amount){
  //   const RoundOff = existingObject?.amount
  //  }
  //-----------------------------end-----------------------------

  // start////////
  // this function is for displaying the scanned data in DC this was removed for issue was facing while clicking every scanned item was showing in every clicked in showed button

  //   useEffect(() => {

  //     for (let i = 0; i < rowData.length; i++) {
  //       let arr = [];
  //      for (let j = 0; j < rowData[i]?.batcharray?.length; j++) {
  //         arr.push(rowData[i].batcharray[j]);
  //    }

  //    setScanner(arr);
  //     }

  //   }, [rowData]);

  // console.log("Scanning", rowData, scanner);

  //// end///////
  useEffect(() => {
    console.log(
      "Tabs Store Data Value is",
      auth.state.userdata.openingbaldate,
      useChangedRows.getValue("trdate")
    );
    if (useChangedRows.getValue("refdate")) {
      // set(useChangedRows.getValue("refdate"));
    } else {
      useChangedRows.updateValue("refdate", auth.state.userdata.openingbaldate);
      // setSupplierInvDate(auth.state.userdata.openingbaldate);
    }

    if (useChangedRows.getValue("trdate")) {
      // setPurchaseInvDate(useChangedRows.getValue("trdate"));
    } else {
      useChangedRows.updateValue("trdate", auth.state.userdata.openingbaldate);
      // setPurchaseInvDate(auth.state.userdata.openingbaldate);
    }
  }, [useChangedRows.getValue("trdate")]);
console.log("TYGUIGBB",getSelectedItem)
  return (
    <div>
      <Modal
        onOk={() => {
          setItemForSelection(null);
          // Here we need to send back the Selection of Batch Details to the parent
          // if (returnValue) {
          //   returnValue={setSelectedItem}
          // }
        }}
        onCancel={() => setItemForSelection(null)}
        open={itemForSelection ? true : false}
        width={1200}
      >
        <ItemSelectionModal
          item={itemForSelection}
          forFlag={"S"}
          // selectedItemBatchDetails={getSelectedItemBatchDetails}
          // setterFunction={setSelectedItemBatchDetails}
          returnValue={setSelectedItem}
          forPage={"S"}
        />
      </Modal>

      <Modal
        open={displayMessages}
        onCancel={() => {
          setDisplayMessages(!displayMessages);
        }}
        onOk={() => {
          useChangedRows.updateValue(
            "remarks",
            document.getElementById("remarks").value
          );
          useChangedRows.updateValue(
            "narration",
            document.getElementById("narration").value
          );
          setDisplayMessages(!displayMessages);
        }}
      >
        <div style={{ display: "flex", flexDirection: "column" }}>
          <p>Message for Supl</p>
          <Input
            id={"remarks"}
            placeholder="Enter Message for Supl"
            defaultValue={useChangedRows.getValue("remarks")}
          ></Input>
          <br /> <p>Message for Self</p>
          <Input
            id={"narration"}
            placeholder="Enter Message for Self"
            defaultValue={useChangedRows.getValue("narration")}
          ></Input>
        </div>
      </Modal>

      <Modal
        open={displayPrevPO}
        onCancel={() => setDisplayPrevPO(!displayPrevPO)}
        onOk={() => setDisplayPrevPO(!displayPrevPO)}
      >
        <div>
          Select Items from Pending Sale Orders
          <Collapse
            collapsible="header"
            defaultActiveKey={["0"]}
            style={{ height: 300, overflowX: "scroll" }}
          >
            {previousPOList?.Message?.map((item, index) => {
              return (
                <Panel
                  header={`Purchase Order : ${item?.ponumber}`}
                  key={index}
                >
                  <List
                    size="small"
                    itemLayout="horizontal"
                    bordered
                    dataSource={item?.items}
                    renderItem={(it, ind) => {
                      it.selectedQty = it?.selectedQty
                        ? it.selectedQty
                        : it?.qty;
                      return (
                        <List.Item>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              width: "100%",
                              alignItems: "center",
                            }}
                          >
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              {it?.itemdescn} <br></br>Quantity : {it?.qty}
                            </div>
                            <input
                              className="tr001Input"
                              style={{ width: 80 }}
                              type={"number"}
                              defaultValue={it?.selectedQty}
                              onChange={(e) => {
                                it.selectedQty = e.target.value;
                              }}
                            ></input>
                            <Button
                              icon={<FcAddDatabase />}
                              onClick={() => {
                                console.log("Changed Object is ", it);
                                setInfoFromPO((p) => [...p, it]);
                              }}
                            ></Button>
                          </div>
                        </List.Item>
                      );
                    }}
                  />
                </Panel>
              );
            })}
          </Collapse>
        </div>
      </Modal>

      {/* =============================//*Edit Transaction*\\=================================== */}
      <Modal
        title="Search Bill"
        open={showFilterModal}
        onOk={() => {
          setshowFilterModal(false);
          setcountRender((p) => p + 1);
        }}
        onCancel={() => {
          setshowFilterModal(false);
          setcountRender((p) => p + 1);
        }}
        width={"100%"}
        style={{ top: 20 }}
        key={countRender}
      >
        <SearchFilterByBillNo
          fastpath="RP106"
          setshowFilterModal={setshowFilterModal}
          showFilterModal={showFilterModal}
        />
      </Modal>

      <div
                style={{
                  // border: "1px solid purple",
                  display: "flex",
                  gap: "24px",
                  alignItems: "center",
                  // amol-390 - commented marginTop
                  // marginTop: "28px",
                  justifyItems: "center",
                }}
              >
                {/* <div style={{ zIndex: 10 }}>   /////// {pradeep taskid -306}
                  <SaveDropdown
                    displayLabel="Salesman"
                    alignment="column"
                    apiurl="getdomaincustomersalesman"
                    apikey="salesmanrecno"
                    selectionLabel="customerdescn"
                    selectionValue="customerrecno"
                    body={{ customerrecno: customerData?.customerrecno }}
                    returnValue={() => setlocation()}
                    key={modalcount}
                  />
                </div> */}

                {/* <div>
                  <Button
                    type="dashed"
                    style={{ background: "#3A98B9" }}
                    onClick={() => setDisplayPrevPO(!displayPrevPO)}
                  >
                    Select Previous SO
                  </Button>
                </div> */}

                {/* ===========modal multi select================ */}
                <Modal
                  key={displayPrevDC} /// modal count value changes
                  width={620}
                  open={displayPrevDC}
                  onCancel={() => {
                    console.log(
                      "Set Prev DC CaA in side cancel Button",
                      displayPrevDC
                    );
                    setDisplayPrevDC(false);
                    setModalCount((p) => p + 1);
                    setreturnData([]);
                  }}
                  onOk={() => {
                    console.log("Set Prev DCCCC", returnData);

                    setreturnData(JSON.parse(JSON.stringify(returnData)));

                    let newArray = returnData?.map((item) => {
                      let pcsBox = convertToBoxAndPieces(
                        Number(item?.selectedQty),
                        Number(item?.conversionfactor)
                      );

                      console.log(
                        "item?.conversionFactor",
                        item.conversionfactor,
                        pcsBox
                      );
                      console.log("PCS BOX", item);

                      if (!rowData || !item) {
                        alert("Invalid data");
                      } else {
                        console.log("Set Prev DC444");
                        var existingItem = rowData.filter(
                          (it) => it && it.itemrecno === item.itemrecno
                        );

                        if (existingItem.length === 0) {
                          console.log("Set Prev DC333");
                          // Add item to the list

                          console.log(
                            "Purchase Invoice : ",
                            item?.packingqty,
                            " ",
                            item?.packingextraqty
                          );
                          handleAdd(
                            item,
                            setRowData,
                            item?.igstrate > 0 ? true : false,
                            setSelectedItem,
                            shortguid,
                            item?.salerate ? item.salerate : item?.rate,
                            //Made Changes to Add Box Pieces from Previous Documents
                            pcsBox[1],
                            pcsBox[0], // Made Changes to Add Pieces From Pieces Documents
                            item.freeQuantity,
                            0,
                            item?.itembatchno,
                            0,
                            item?.discountamount > 0
                              ? item.discountamount
                              : 0,
                            item?.discountamt > 0 ? item.discountamt : 0,
                            0,
                            // item?.amount > 0 ? item.amount : 0,
                            // item?.taxableamount > 0 ? item.taxableamount : 0,
                            // rowData,
                            auth?.state?.userdata?.onedconeinvoice
                          );
                          console.log("setreturnData4");
                          ///// commented this line
                          // setreturnData([]);
                          /////
                        } else {
                          console.log("Set Prev DC 222");
                          console.log("Existing Item Array is", existingItem);
                          // var totalBoxQty = existingItem.reduce((acc, el) => {
                          //   console.log("Item Existing Array is ", item, el)

                          //   (acc += Number(el?.packingextraqty))
                          // }, 0) +  Number(pcsBox[0])
                          // var totalPcsQty = existingItem.reduce((acc, el) => (acc += Number(el?.packingqty)), 0) +  Number(pcsBox[1])

                          handleRemovePrev(item, setRowData, rowData);

                          setTimeout(() => {
                            handleAdd(
                              item,
                              setRowData,
                              item?.igstrate > 0 ? true : false,
                              setSelectedItem,
                              shortguid,
                              item?.salerate ? item.salerate : item?.rate,
                              //Made Changes to Add Box Pieces from Previous Documents
                              pcsBox[1],
                              pcsBox[0], // Made Changes to Add Pieces From Pieces Documents
                              0,
                              item.freeQuantity,
                              item?.itembatchno,
                              0,
                              item?.discountamount > 0
                                ? item.discountamount
                                : 0,
                              item?.discountamt > 0 ? item.discountamt : 0,
                              0,
                              // item?.amount > 0 ? item.amount : 0,
                              // item?.taxableamount > 0 ? item.taxableamount : 0,
                              // rowData,
                              auth?.state?.userdata?.onedconeinvoice
                            );
                            console.log("setreturnData1");
                            setreturnData([]);
                          }, 200);
                        }
                      }
                    });
                    console.log("Set Prev DC");
                    setDisplayPrevDC(false);
                    setModalCount((p) => p + 1);
                  }}
                >
                  <MultipleSelectionItem
                    options={optionsforDC}
                    selectedItemsArray={returnData}
                    selectOnlyOneDoc={true}
                    returnValue={(e) => {
                      console.log("seleted DC chalan eee", e);
                      // setreturnData([...e]);
                      setreturnData(e);
                    }}
                    forFlag={"si"} //KMK:31/03/23 Dont Change the place of this Key Value
                    todate={siDate}
                  />
                </Modal>

                <Modal
                  key={modalcount}
                  width={620}
                  open={displayPrevSO}
                  onCancel={() => {
                    setDisplayPrevSO(!displayPrevSO);
                    setCount((p) => p + 1);
                  }}
                  onOk={() => {
                    let newArray = returnData.map((item) => {
                      let pcsBox = convertToBoxAndPieces(
                        Number(item?.selectedQty),
                        Number(item?.conversionfactor)
                      );
                      console.log(
                        "item?.conversionFactor >>>>",
                        item,
                        pcsBox
                      );
                      console.log("PCS BOX", item);

                      if (!rowData || !item) {
                        alert("Invalid data");
                      } else {
                        var existingItem = rowData.filter(
                          (it) => it && it.itemrecno === item.itemrecno
                        );

                        if (existingItem.length === 0) {
                          // Add item to the list
                          handleAdd(
                            item,
                            setRowData,
                            item?.igstrate > 0 ? true : false,
                            setSelectedItem,
                            shortguid,
                            item?.salerate ? item.salerate : item?.rate,
                            //Made Changes to Add Box Pieces from Previous Documents
                            pcsBox[1],
                            pcsBox[0], // Made Changes to Add Pieces From Pieces Documents
                            0,
                            item.freeQuantity,
                            item?.itembatchno,
                            0,
                            item?.discountamount > 0
                              ? item.discountamount
                              : 0,
                            item?.discountamt > 0 ? item.discountamt : 0,
                            0,
                            item?.amount > 0 ? item.amount : 0,
                            item?.taxableamount > 0 ? item.taxableamount : 0,
                            rowData
                          );
                          console.log("setreturnData2");

                          setreturnData([]);
                        } else {
                          console.log("Existing Item Array is", existingItem);
                          // var totalBoxQty = existingItem.reduce((acc, el) => {
                          //   console.log("Item Existing Array is ", item, el)

                          //   (acc += Number(el?.packingextraqty))
                          // }, 0) +  Number(pcsBox[0])
                          // var totalPcsQty = existingItem.reduce((acc, el) => (acc += Number(el?.packingqty)), 0) +  Number(pcsBox[1])

                          handleRemovePrev(item, setRowData, rowData);

                          setTimeout(() => {
                            handleAdd(
                              item,
                              setRowData,
                              item?.igstrate > 0 ? true : false,
                              setSelectedItem,
                              shortguid,
                              item?.salerate ? item.salerate : item?.rate,
                              //Made Changes to Add Box Pieces from Previous Documents
                              pcsBox[1],
                              pcsBox[0], // Made Changes to Add Pieces From Pieces Documents
                              0,
                              item.freeQuantity,
                              item?.itembatchno,
                              0,
                              item?.discountamount > 0
                                ? item.discountamount
                                : 0,
                              item?.discountamt > 0 ? item.discountamt : 0,
                              0,
                              item?.amount > 0 ? item.amount : 0,
                              item?.taxableamount > 0 ? item.taxableamount : 0
                            );
                            console.log("setreturnData3");

                            setreturnData([]);
                          }, 200);
                        }
                      }
                    });
                    setDisplayPrevSO(!displayPrevSO);
                    setModalCount((count) => count + 1);
                  }}
                >
                  <MultipleSelectionItem
                    options={optionforSO}
                    selectedItemsArray={returnData}
                    returnValue={(e) => {
                      console.log("eleted DC chalan eee", e);
                      setreturnData(e);
                    }}
                    forFlag={"si"} //KMK:31/03/23 Dont Change the place of this Key Value
                    todate={siDate}
                  />
                </Modal>
                {/* =============================//*Edit Transaction*\\=================================== */}

                {/* <Button
                  type="default"
                  onClick={() => {
                    setshowFilterModal(true);
                    setcountRender((p) => p + 1);
                  }}
                >
                  Search Billno
                </Button> */}
                {/*  <div
              style={{ flexDirection: "row", display: "flex", }}
            >
              <SaveTextInput
              pageIndex={pageIndex}
                id="tr013-drivername"
                placeholder="Driver Name"
                style={{ width: "100%" }}
                apikey="drivername"
                change={true}
                displayLabel="Driver Name"
                defaultValue={returnData[0]?.drivername}
                 returnValue={ setdrivername}
              />
              <div style={{ marginLeft: 50 }}>
                <SaveTextInput
                pageIndex={pageIndex}
                  id="tr013-vehicleno"
                  placeholder="Vehicle Number"
                  style={{ width: "100%" }}
                  apikey="vehicleno"
                  change={true}
                  value={vehiclenumber}
                  displayLabel="Vehicle Number"
                  defaultValue={returnData[0]?.vehicleno}
                   returnValue={setvehiclenumber}

                />
              </div>
            </div>*/}
              </div>

      <Collapse collapsible="header" defaultActiveKey={["1"]}>
        <Panel header="Header Details" key="1">
          <div>           
            <form
              style={{
                display: "flex",
                flexWrap: "wrap",
                columnGap: "40px", // Adjust the column gap as needed
                rowGap: "15px",
                marginTop:"5px"
              }}
            >
              {/* Select Supplier */}
              {/* //!Upper filter box red color */}
              {/* <div
                style={{ display: "flex", gap: "24px", alignItems: "center" }}
              > */}
              <div
                onKeyDown={enterPress}
                style={{
                  zIndex: 11,
                  position: "relative",
                  pointerEvents: rowData?.length > 0 && "none",
                }}
              >
                <SaveDropdown
                  defaultSelected={customerData}
                  disabled={rowData && rowData.length > 0?1:0}
                  displayLabel="Cust"
                  placeholder="Select Cust"
                  alignment="column"
                  apikey="customerrecno"
                  apiurl="getdomaincustomerlist"
                  selectionLabel="customerdescn"
                  selectionValue="customerrecno"
                  returnValue={(event) => {
                    {
                      /* // nirmal-389 adding values with bold*/
                    }
                    if (event?.hasOwnProperty("zincode")) {
                      // task rajendra id-240
                      if (event?.zincode) {
                        // task rajendra id-240
                        setCustomerData(event);
                      } else {
                        alert(
                          "Customer has no Zincode. Please Add Zincode to start billing"
                        );
                        // setModalCount(() => modalcount + 1)
                        if (customerData == 0) {
                          setCustomerData(null);
                        } else {
                          setCustomerData(0);
                        }
                      }
                    } else {
                      setCustomerData(event);
                    }
                  }}
                  key={modalcount}
                  isrequired={1}
                />
                <p>{customerData ? customerData?.remarks : ""}</p>
              </div>
              <div style={{ zIndex: 11 }} onKeyDown={enterPress}>
                <SaveDropdown
                  defaultSelected={location}
                  displayLabel="Cust Locn"
                  placeholder=" Select Cust Locn"
                  alignment="column"
                  apiurl="domaincustomerlocationlist"
                  apikey="customerlocationrecno"
                  selectionLabel="descn"
                  selectionValue="customerlocationrecno"
                  returnValue={() => setlocation()}
                  body={{ customerrecno: customerData?.customerrecno }}
                  key={modalcount}
                />
              </div>
              {/* <div onKeyDown={enterPress}>
                <SaveDropdown
                  displayLabel="Surgeon"
                  placeholder="Surgeon"
                  alignment="column"
                  apiurl="getdomaincustomerrefby"
                  apikey="refbyrecno"
                  selectionLabel="refbydescn"
                  selectionValue="refbyrecno"
                  body={{ customerrecno: customerData?.customerrecno }}
                  returnValue={() => setlocation()}
                  key={modalcount}
                />
              </div> */}
              <div onKeyDown={enterPress}>
                {/* <p>SI Number</p> */}
                <SaveTextInput
                pageIndex={pageIndex}
                  displayLabel="SI No."
                  placeholder="SI No."
                  alignment="column"
                  // displayLabel={elem?.columndisplay}
                  //placeholder={elem?.placeholder}
                  apikey={"billno"}
                  // defaultValue={elem?.defaultValue}
                  change
                  // returnValue={(e) => {
                  //   console.log("FC", e);
                  //   setterFunction(e, elem);
                  //   storeData(e, elem?.apiKey);
                  // }}
                  key={modalcount}
                  isrequired={1}
                />
              </div>
              {/* <Button type="dashed">SI Date : {dateFormatter(auth.state.userdata.workdate)}</Button> */}
              <div onKeyDown={enterPress}>
                <SaveDatePicker
                  apiKey={"trdate"}
                  displayLabel={"SI Date"}
                  placeholder={"SI Date"}
                  disabled={rowData && rowData.length > 0?1:0}
                  dateLessthan={auth.state.userdata.openingbaldate}
                  defaultValue={siDate}
                  returnValue={(e) => {
                    setSiDate(e);
                  }}
                />
              </div>
              {/* refbillno is added date 06th may 2023 by rbn 
                <SaveTextInput
                pageIndex={pageIndex}
                  displayLabel={"Customer Invoice No : "}
                  apikey={"refbillno"}
                  //defaultValue={supplierInvNo}
                  key={modalcount}
                /> */}
              
              {/* </div> */}
              <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }} onKeyDown={enterPress} >
                <AdditionalDetails filterOption={pageDetails?.additionalDetails} />
              </div>
              <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }} onKeyDown={enterPress} >

                <button
                  // style={{ ...buttonStyle }}
                  className="btn"
                  disabled={
                    auth?.state?.userdata?.onedconeinvoice &&
                      rowData.length > 0 &&
                      (rowData[0].doctype == "DC" ||
                        rowData[0].doctype == "SO" ||
                        !rowData[0]?.doctype)
                      ? true
                      : false
                  }
                  onClick={(e) => {
                    e.preventDefault()
                    if (customerData) {
                      setDisplayPrevDC(true);
                      setreturnData([]);
                    } else {
                      alert("Select Customer");
                    }
                  }}
                >
                  Pick from DC
                </button>
              </div>
              <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }} onKeyDown={enterPress} >

                <button
                  className="btn"
                  // style={{ ...buttonStyle }}
                  disabled={
                    auth?.state?.userdata?.onedconeinvoice &&
                      rowData.length > 0 &&
                      rowData[0].doctype == "DC"
                      ? true
                      : false
                  }
                  onClick={(e) => {
                    e.preventDefault()
                    if (customerData) {
                      setDisplayPrevSO(!displayPrevSO);
                    } else {
                      alert("Select Customer");
                    }
                  }}
                >
                  Pick from SO
                </button>
              </div>
              <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }} onKeyDown={enterPress} >
                {/* search billno commentedout asked by PM */}
                <button
                  className="btn"
                  onClick={(e) => { e.preventDefault(); setDisplayMessages(!displayMessages) }}
                >
                  Add / View Messages
                </button>
              </div>
              <div style={{display:"flex", alignItems:"center", justifyContent:"center",  }} onKeyDown={(e) => {
            if (e.keyCode == 13) {
              e.preventDefault()
              itemSearchdiv.current.focus()
            }
          }}>
                <SaveCheckbox
                  apikey={"isPrint"}
                  displayLabel="Print SI"
                  editable
                  key={modalcount}
                />
              </div>
              {/* //!Upper box red color bottom */}
            </form>

            {/*//task-d 345 rajendra here  this input was not need */}
            {/*<InputComponent
            //  id="tr013-vehicleno"
            id="vehicleno"
            placeholder="Vehicle Name"
            style={{ width: "30%" }}  //// Pradeep Task id -323  adjust size of input box
            apiKey="vehicleno"
            change={true}
            required
          />*/}
          </div>

          {/* </div> */}
        </Panel>
        {/* <Panel header="Additional Details" key="2"> */}
        {/* <div> */}
        {/* <Space
              style={{
                display: "flex",
                flexDirection: "column",
                ...topRowFilters,
                width: "fit-content",
              }}
            > */}
        {/* Select Supplier */}
        {/* //!Upper filter box red color */}
        {/* amol-temp - removed alignItems: "center" from below div style  */}
        {/* <div style={{ display: "flex", gap: "24px" }}> */}
        {/* amol-390 - commited below div */}
        {/* <div
                  style={{
                    zIndex: 11,
                    position: "relative",
                    pointerEvents: rowData?.length > 0 && "none",
                  }}
                ></div>
                <div style={{ zIndex: 11 }}></div> */}
        {/* amol-temp - div created for AdditionalDetails */}
        {/* <div>
              <AdditionalDetails />
            </div> */}

        {/* <div
              style={{
                // border: "1px solid purple",
                display: "flex",
                gap: "24px",
                alignItems: "center",
                justifyItems: "center",
                // amol-390 - commited margin Left and Top
                // marginTop: "10px",
                // marginLeft: "-50px",
              }}
            >
              <SaveTextInput
              pageIndex={pageIndex}
                id="tr013-drivername"
                placeholder="Driver Name"
                style={{ width: "100%" }}
                apikey="drivername"
                change={true}
                displayLabel="Driver Name"
                defaultValue={returnData[0]?.drivername}
                returnValue={(e) => setdrivername(e)}
                key={modalcount}
              />
              <SaveTextInput
              pageIndex={pageIndex}
                id="tr013-vehicleno"
                placeholder="Vehicle Number"
                style={{ width: "100%" }}
                apikey="vehicleno"
                change={true}
                displayLabel="Vehicle Number"
                defaultValue={returnData[0]?.vehicleno}
                returnValue={(e) => setvehiclenumber(e)}
                key={modalcount}
              />

              <SaveTextInput
              pageIndex={pageIndex}
                displayLabel={"Customer Invoice No : "}
                apikey={"refbillno"}
                //defaultValue={supplierInvNo}
                key={modalcount}
              /> */}

        {/* amol-temp - changed in style */}

        {/* </div> */}
        {/* </div> */}

        {/* //!Upper box red color bottom  */}
        {/* </Space>
          </div> */}
        {/* </Panel> */}
        <Panel header="Items Details " key="2">
          <Space size="large">
            <div
              style={{
                zIndex: 2,
                marginBottom: "30px",
                position: "relative",
                pointerEvents:
                  rowData?.length > 0 &&
                    auth?.state?.userdata?.onedconeinvoice &&
                    rowData[0].doctype == "DC"
                    ? "none"
                    : null,
              }}
            >
              <ItemSearch
                itemSearchRef={itemSearch}
                forFlag={"S"}
                returnValue={setSelectedItem}
                supplierdata={customerData?.customerrecno}
                dropdown
                defaultSelectedItem={getSelectedItem}
                selection
                forPage={"openingsi"}
                /// task id-269 rajendra If item has isservicetrue = 1 then don't calculate stock.
                //selection /// rajendra  may 30 two selection key was there
                barcodeReturnValue={(e) => {
                  if(e?.serialnofound === false){
                  // Write a function to add to existing Items and check only if it has serial number
                  if (e?.itemasbatch == 1) {
                    fetchItems
                      .addBarcodeScannedItemToTable(
                        rowData,
                        e,
                        "SIF",
                        igstappl,
                        pageDetails,
                        "openingsi"
                      )
                      .then((res) => {
                        console.log("New ROws Data", res);
                        if (res?.length == 0) {
                          // Rajendra the same barcode grid getting blank date 9 jun 2023
                          setRowData(rowData);
                          return;
                        } else {
                          setRowData(res);
                          setChange(change + 1);
                        } // Rajendra the same barcode grid getting blank date 9 jun 2023
                      })
                      .catch((err) => alert(err));
                  } else {
                    setSelectedItem(e);
                  }
                } else{
                  alert("Item Already Exists in System")
                }
                }}
              />
            </div>
            {getSelectedItem?.itemasbatch ? (
              <div style={{ ...callbackTextInputStyles, alignItems: "end" }}>
                {getSelectedItem?.packingextrarecno > 0 &&
                  pageDetails?.wantpackingextra ? (
                  <div onKeyDown={enterPress}>
                    <p>
                      Box <span style={{ color: "red" }}>*</span>
                      {getSelectedItem?.conversionfactor &&
                        +getSelectedItem?.conversionfactor}
                    </p>
                    <input
                      //  disabled={getSelectedItem?.packingextrarecno>0? false:true}
                      ref={boxRef}
                      style={{ ...inputStyles }}
                      value={box}
                      onChange={(e) => {
                        const regex = /^[0-9\b]+$/;
                        const value = e.target.value;
                        if (value === "" || regex.test(value)) {
                          setBox(value);
                        }
                      }}
                      type="number"
                    />
                  </div>
                ) : null}
                <div onKeyDown={enterPress}>
                  <p>
                    Pieces<span style={{ color: "red" }}>*</span>
                  </p>
                  <input
                    ref={piecesRef}
                    style={{ ...inputStyles }}
                    // disabled={getSelectedItem?.packingextrarecno==0? false:true}
                    value={selectedQty}
                    type="number"
                    onChange={(e) => {
                      const regex = /^[0-9\b]+$/;
                      const value = e.target.value;
                      if (value === "" || regex.test(value)) {
                        setSelectedQty(value);
                      }
                    }}
                  />
                </div>

                {pageDetails?.wantfreeqty ? (
                  <>
                    {getSelectedItem?.packingextrarecno > 0 ? (
                      <div onKeyDown={enterPress}>
                        <p>Free Boxes</p>
                        <input
                          ref={piecesRef}
                          style={{ ...inputStyles }}
                          // disabled={getSelectedItem?.packingextrarecno>0? false:true}
                          value={freeBox}
                          type="number"
                          onChange={(e) => {
                            const regex = /^[0-9\b]+$/;
                            const value = e.target.value;
                            if (value === "" || regex.test(value)) {
                              setFreeBox(value);
                            }
                          }}
                        />
                      </div>
                    ) : null}
                    <div onKeyDown={enterPress}>
                      <p>Free Pieces</p>
                      <input
                        ref={piecesRef}
                        style={{ ...inputStyles }}
                        // disabled={getSelectedItem?.packingextrarecno==0? false:true}
                        value={freeQty}
                        type="number"
                        onChange={(e) => {
                          const regex = /^[0-9\b]+$/;
                          const value = e.target.value;
                          if (value === "" || regex.test(value)) {
                            setFreeQty(value);
                          }
                        }}
                      />
                    </div>
                  </>
                ) : null}

                {getSelectedItem?.wantbatchno ? (
                  <>
                    {" "}
                    <div onKeyDown={enterPress}>
                      <p>
                        Batch No.<span style={{ color: "red" }}>*</span>
                      </p>
                      <input
                        ref={piecesRef}
                        style={{
                          ...inputStyles,
                          width: "160px",
                          textAlign: "left",
                        }}
                        value={batchNo}
                        onChange={(e) => setBatchNo(e.target.value)}
                        onBlur={(e) => {
                          /// batch validation if itemsbatch is true or false if batch is true it should not asked for batch again
                          if (getSelectedItem?.wantbatchno) {
                            console.log("Batch No", getSelectedItem);
                            if (!e.target.value) {
                              alert("Batch Cannot Be Empty");
                            }
                            return;
                          }
                        }}
                      />
                    </div>
                    {
                      pageDetails?.wantmanudate === 1 ? (
                        <div onKeyDown={enterPress}>
                          <p>
                            Mfg. Date <span style={{ color: "red" }}>*</span>
                          </p>
                          <CallbackDateInput
                            defaultValue={
                              getSelectedItem?.wantbatchno == 0 &&
                                getSelectedItem?.manudatecompulsory == 0
                                ? auth?.state?.userdata?.workdate
                                : manuDate
                            }
                            id={"tr002-manufacture-date-callback"}
                            // displayLabel={"Expiry Date"}
                            apiKey={"manudate"}
                            returnValue={(e) => {
                              console.log("!manuDate", manuDate, e);
                              if (!manuDate && !e) {
                                {
                                  /*Kaushik : Added !e to check if response is coming */
                                }

                                setManuDate(auth?.state?.userdata?.workdate);
                              } else {
                                setManuDate(e);
                              }
                              console.log(
                                "Expiry Date should be greater than Mfg. Date",
                                e
                              );
                              document
                                .getElementById("tr002-exp-date-callback")
                                .focus();
                            }}
                            initial
                            // dateLessthan={}
                            key={modalcount}
                          />
                        </div>
                      ) : null
                    }
                    {
                      pageDetails?.wantexpdate === 1 ? (
                        <div onKeyDown={enterPress}>
                          <p>
                            Expiry Date <span style={{ color: "red" }}>*</span>
                          </p>
                          <CallbackDateInput
                            defaultValue={
                              getSelectedItem?.wantbatchno == 0 &&
                                getSelectedItem?.expdatecompulsory == 0
                                ? "99991231"
                                : expDate
                            }
                            id={"tr002-exp-date-callback"}
                            // displayLabel={"Expiry Date"}
                            apiKey={"manudate"}
                            initial
                            dateGreaterthan={manuDate} //! If this is false, postAlertMsg will trigger
                            postAlertMsg="Expiry Date should not be lesser than Mfg. Date"
                            returnValue={(e) => {
                              if (e <= manuDate) {
                                document.getElementById(
                                  "po-manufacture-date-callback"
                                ).style.color = "red";
                                setExpDate(0);
                              } else {
                                setExpDate(e);
                              }
                            }}
                            key={modalcount}
                          />
                        </div>
                      ) : null
                    }

                  </>
                ) : null}

                <div onKeyDown={enterPress}>
                  <p>
                    Rate <span style={{ color: "red" }}>*</span>
                  </p>
                  <input
                    type="number"
                    style={{ ...inputStyles, width: "100px" }}
                    value={salerate}
                    onChange={(e) => {
                      if (getSelectedItem?.changesalerateinbill) {
                        const regex = /^\d*(\.\d{0,2})?$/;
                        const value = e.target.value;
                        if (value === "" || regex.test(value)) {
                          setSaleRate(value);
                        }
                      }
                    }}
                    onBlur={(e) => {
                      const regex = /^[0-9\b]+$/;
                      const value = e.target.value;
                      console.log("TR002 rate value", value);
                      if (value == "0.00" || value == "0.0") {
                        alert("Cannot enter 0.00 as rate");
                        return false;
                      }

                      if (value === "" || regex.test(value)) {
                        if (value != 0 && value > 0) {
                          if (
                            box == 0 &&
                            selectedQty == 0 &&
                            freeQty == 0 &&
                            freeBox == 0
                          ) {
                            alert("Enter non-zero values in Box / Pieces");
                            boxRef.current.focus();
                          } else {
                            btnRef.current.focus();
                          }
                        } else {
                          alert("Cannot enter 0 as rate");
                          return false;
                        }
                      }
                    }}
                  />
                </div>
                <div onKeyDown={enterPress}>
                  <p>Gross Amount</p>
                  <input disabled value={Number(grossAmt).toFixed(2)} style={{ ...grossAmtStyle, width: 120 }}/>
                </div>

                <div onKeyDown={enterPress}>
                  <p>Discount %</p>
                  <input
                    style={{ ...inputStyles, width: "75px" }}
                    value={discountPer}
                    type="number"
                    id="tr002-discount-perc"
                    onChange={(e) => {
                      const regex = /^\d*(\.\d{0,2})?$/;
                      const value = e.target.value;

                      if (
                        (value === "" && value < 99.99) ||
                        (regex.test(value) && value < 99.99)
                      ) {
                        setDiscountPer(value);
                        console.log("Gross Amt IN Discount", grossAmt);
                        var dicsountAmount =
                          (Number(grossAmt) * Number(value)) / 100;
                        setDiscount(dicsountAmount.toFixed(2));
                      }
                    }}
                    onBlur={(e) => {
                      let temp = e.target.value;
                      temp != "" &&
                        setDiscountPer(Number(e.target.value)?.toFixed(2));
                      var dicsountAmount =
                        (Number(grossAmt) * Number(e.target.value)) / 100;
                      setDiscount(dicsountAmount.toFixed(2));
                    }}
                  />
                </div>

                <div onKeyDown={enterPress}>
                  <p>Discount</p>
                  <input
                    style={{ ...inputStyles, width: "110px" }}
                    value={discount}
                    type="number"
                    disabled={discountPer !== 0}
                    onChange={(e) => setDiscount(e.target.value)}
                  />
                </div>
                <div onKeyDown={enterPress}>
                  <p>Taxable Amount</p>
                  <input disabled value={Number(taxableAmt).toFixed(2)} style={{ ...grossAmtStyle, width: 120 }}/>
                </div>
                <div>
                  <Button
                    style={{ ...btnStyles }}
                    icon={<IoAperture size="1.2rem" />}
                    size="large"
                    onClick={() => {
                      addItemstoTable();
                    }}
                  >
                    Add
                  </Button>
                </div>
              </div>
            ) : null}
          </Space>
          <div>
            {showTable && rowData.length > 0 ? (
              <Table
                className="tr013-items-grid"
                scroll={{
                  y: 500,
                  x: 500,
                }}
                size="large"
                bordered
                pagination={false}
                columns={piColumns(
                  igstappl,
                  setRowData,
                  rowData,
                  scandata,
                  setScandata,
                  setOpenItem,
                  auth.state.userdata.workdate,
                  setModal,
                  scanner,
                  auth?.state?.userdata?.onedconeinvoice,
                  setScanner,
                  pageDetails,
                  setItemForSelection
                )?.filter((item) => !item.hidden)}
                dataSource={rowData}
                onChange={(e) => { }}
                summary={(pageData) => {
                  let qty = 0;
                  let free = 0;
                  let packingextraqty = 0;
                  let packingqty = 0;
                  let freepackingextraqty = 0;
                  let freepackingqty = 0;

                  var existingObject = useChangedRows.getValue();
                  console.log("Existing Object is", existingObject);

                  const invoiceAmount = existingObject?.amount;
                  setinvoiceAmount(invoiceAmount);
                  const roundOff = roundOffAmount(invoiceAmount).toFixed(2);
                  const roundOffDiff = roundOff - invoiceAmount;
                  console.log("roundOffDiff", roundOffDiff);
                  let drCr;
                  if (roundOffDiff < 0) {
                    drCr = "Dr - Rounding";
                    setRoundDiff(roundOffDiff);
                    setTypeAmt(drCr);
                  } else if (roundOffDiff > 0) {
                    drCr = "Cr - Rounding";
                    setRoundDiff(roundOffDiff);
                    setTypeAmt(drCr);
                  }
                  // amol-382 - changes in condition as if there is no rounding message not display
                  else {
                    drCr = "";
                    setRoundDiff(roundOffDiff);
                    setTypeAmt(drCr);
                  }

                  const displayValue = Math.abs(roundOffDiff).toFixed(2);
                  setdisplayValue(displayValue);

                  pageData.forEach((item) => {
                    qty = Number(qty) + Number(item?.qty);
                    free = Number(free) + Number(item?.free);
                    packingextraqty =
                      Number(packingextraqty) + Number(item?.packingextraqty);
                    packingqty = Number(packingqty) + Number(item?.packingqty);
                    freepackingextraqty =
                      Number(freepackingextraqty) +
                      Number(item?.freepackingextraqty);
                    freepackingqty =
                      Number(freepackingqty) + Number(item?.freepackingqty);
                  });

                  return (
                    // amol-382 - removed fixed property and className="tr013-items-footer" property from Table.Summary
                    <Table.Summary style={{ position: "relative", zIndex: -1 }}>
                      {!igstappl ? (
                        <Table.Summary.Row>
                          <Table.Summary.Cell></Table.Summary.Cell>
                          {/* nohit-425 Change Position of Total Caption */}
                          <Table.Summary.Cell>
                            <p style={{ ...pStyles, textAlign: "left" }}>
                              Total :{" "}
                            </p>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell>{null}</Table.Summary.Cell>
                          <Table.Summary.Cell>{null}</Table.Summary.Cell>
                          {pageDetails?.wantpackingextra ? (
                            <Table.Summary.Cell>
                              <p style={{ ...pStyles }}>{packingextraqty}</p>
                            </Table.Summary.Cell>
                          ) : null}

                          <Table.Summary.Cell>
                            <p style={{ ...pStyles }}>{packingqty}</p>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell>
                            <p style={{ ...pStyles }}>{qty}</p>
                          </Table.Summary.Cell>

                          {pageDetails?.wantfreeqty ? (
                            <Table.Summary.Cell>
                              <p style={{ ...pStyles }}>{freepackingextraqty}</p>
                            </Table.Summary.Cell>
                          ) : null}

                          {pageDetails?.wantfreeqty &&
                            pageDetails?.wantpackingextra ? (
                            <Table.Summary.Cell>
                              <p style={{ ...pStyles }}>{freepackingqty}</p>
                            </Table.Summary.Cell>
                          ) : null}

                          {pageDetails?.wantfreeqty ? (
                            <Table.Summary.Cell>
                              <p style={{ ...pStyles }}>{free}</p>
                            </Table.Summary.Cell>
                          ) : null}

                          {pageDetails?.wantbatchno ? (
                            <Table.Summary.Cell></Table.Summary.Cell>
                          ) : null}

                          {pageDetails?.wantmanudate === 1 ? (
                            <Table.Summary.Cell></Table.Summary.Cell>
                          ) : null}
                          {pageDetails?.wantexpdate === 1 ? (
                            <Table.Summary.Cell></Table.Summary.Cell>
                          ) : null}
                          <Table.Summary.Cell></Table.Summary.Cell>
                          <Table.Summary.Cell></Table.Summary.Cell>
                          <Table.Summary.Cell></Table.Summary.Cell>
                          <Table.Summary.Cell></Table.Summary.Cell>
                          <Table.Summary.Cell>
                            <p style={{ ...pStyles }}>{existingObject?.taxableamount}</p>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell></Table.Summary.Cell>
                          <Table.Summary.Cell>
                            <p style={{ ...pStyles }}>{existingObject?.sgstamt}</p>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell></Table.Summary.Cell>
                          <Table.Summary.Cell>
                            <p style={{ ...pStyles }}>{existingObject?.cgstamt}</p>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell>
                            <p style={{ ...pStyles }}>{existingObject?.amount}</p>
                          </Table.Summary.Cell>
                        </Table.Summary.Row>
                      ) : (
                        <Table.Summary.Row>
                          <Table.Summary.Cell></Table.Summary.Cell>
                          {/* nohit-425 Change Position of Total Caption */}
                          <Table.Summary.Cell>
                            <p style={{ ...pStyles, textAlign: "left" }}>
                              Total :{" "}
                            </p>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell>{null}</Table.Summary.Cell>
                          <Table.Summary.Cell>{null}</Table.Summary.Cell>

                          {pageDetails?.wantpackingextra ? (
                            <Table.Summary.Cell>
                              <p style={{ ...pStyles }}>{packingextraqty}</p>
                            </Table.Summary.Cell>
                          ) : null}
                          {/* amol-temp - added p tag and applied style to p tag...
                          for packingqty and qty*/}
                          <Table.Summary.Cell>
                            <p style={{ ...pStyles }}>{packingqty}</p>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell>
                            <p style={{ ...pStyles }}>{qty}</p>
                          </Table.Summary.Cell>

                          {pageDetails?.wantfreeqty ? (
                            <Table.Summary.Cell>
                              <p style={{ ...pStyles }}>{freepackingextraqty}</p>
                            </Table.Summary.Cell>
                          ) : null}

                          {pageDetails?.wantfreeqty &&
                            pageDetails?.wantpackingextra ? (
                            <Table.Summary.Cell>
                              <p style={{ ...pStyles }}>{freepackingqty}</p>
                            </Table.Summary.Cell>
                          ) : null}

                          {pageDetails?.wantfreeqty ? (
                            <Table.Summary.Cell>
                              <p style={{ ...pStyles }}>{free}</p>
                            </Table.Summary.Cell>
                          ) : null}

                          {pageDetails?.wantbatchno ? (
                            <Table.Summary.Cell></Table.Summary.Cell>
                          ) : null}

                          {pageDetails?.wantmanudate === 1 ? (
                            <Table.Summary.Cell></Table.Summary.Cell>
                          ) : null}
                          {pageDetails?.wantexpdate === 1 ? (
                            <Table.Summary.Cell></Table.Summary.Cell>
                          ) : null}
                          {/* <Table.Summary.Cell></Table.Summary.Cell> */}
                          <Table.Summary.Cell></Table.Summary.Cell>
                          <Table.Summary.Cell></Table.Summary.Cell>
                          <Table.Summary.Cell></Table.Summary.Cell>
                          <Table.Summary.Cell></Table.Summary.Cell>
                          <Table.Summary.Cell>
                            <p style={{ ...pStyles }}>
                              {existingObject?.taxableamount}
                            </p>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell></Table.Summary.Cell>
                          <Table.Summary.Cell>
                            <p style={{ ...pStyles }}>
                              {existingObject?.igstamt}
                            </p>
                          </Table.Summary.Cell>

                          <Table.Summary.Cell>
                            <p style={{ ...pStyles }}>
                              {existingObject?.amount}
                            </p>
                          </Table.Summary.Cell>
                        </Table.Summary.Row>
                      )}
                    </Table.Summary>
                  );
                }}
              />
            ) : null}
            {rowData.length > 0 ? (
              <div style={{ width: "25%" }}>
                <div
                  style={{
                    display: "flex",
                    gap: "10px",
                    marginLeft: "20px",
                    marginTop: "20px",
                    backgroundColor: "white",
                  }}
                >
                  <table
                    style={{
                      justifyContent: "center",
                      textAlign: "center",
                      margin: "auto",
                      backgroundColor: "white",
                    }}
                  >
                    <thead style={{ textAlign: "center" }}>
                      <tr style={{ border: "1px solid white" }}>
                        <th
                          style={{
                            fontWeight: "bold",
                            textAlign: "center",
                            margin: "auto",
                          }}
                        >
                          Invoice Amount
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <div onKeyDown={enterPress}>
                            <p></p>
                            <input disabled value={invoiceAmount} style={{ ...grossAmtStyle, width: 120 }}/>
                          </div>
                        </td>
                        <td>
                          <div onKeyDown={enterPress}>
                            <p>{typeAmt}</p>
                            <input disabled value={displayValue} style={{ ...grossAmtStyle, width: 120 }}/>
                          </div>
                        </td>
                        <td>
                          <div onKeyDown={enterPress}>
                            <p></p>
                            <input
                            disabled
                              value= {roundOffAmount(invoiceAmount).toFixed(2)}
                              style={{
                                ...grossAmtStyle,
                                width: 120,
                                fontWeight: "bold",
                              }}
                            />
                             
                            
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            ) : null}
          </div>
        </Panel>
      </Collapse>
      {/* Table for Selection  */}

      {openItem && (
        <ScanTable
          data={scandata}
          openItem={openItem}
          setOpenItem={setOpenItem}
          rowData={rowData}
          setRowData={setRowData}
          renderer={renderer}
          setRenderer={setRenderer}
          setValidator={setValidator}
          forFlag={"S"}
          supplierrecno={customerData?.customerrecno}
          pageDetails={pageDetails}
        />
      )}
      {modal && (
        <ScannedItem
          modal={modal}
          setModal={setModal}
          data={scanner}
          pageDetails={pageDetails}
          forPage="openingsi"
        />
      )}
    </div>
  );
}

export default SalesInvoiceOpening;
// amol-temp - mainFrame width change to 99vw from 95vw
const mainFrame = {
  width: "99vw",
  margin: "auto",
};

const topRowFilters = {
  // amol-390 - changed in CSS - gap from 28px to 24px
  gap: "24px",
  display: "flex",
};

// applying padding to align input rows inline
const callbackTextInputStyles = {
  display: "flex",
  flexWrap: "wrap",
  alignItems: "center",
  gap: "10px",
  paddingTop: "10px",
};

// add to table btn styles
const btnStyles = {
  display: "flex",
  alignItems: "center",
  gap: "8px",
};

const inputStyles = {
  padding: "10px",
  border: "1px solid rgb(120,120,120)",
  width: "80px",
  fontSize: "16px",
  marginTop: "7px",
  borderRadius: "6px",
};

const grossAmtStyle = {
  textAlign: "right",
  border: "1px solid gray",
  padding: "4px 8px 4px 4px",
  fontSize: "18px",
  width: "80px",
  borderRadius: "8px",
  marginTop: "6px",
};
// amol-temp - change the values
const poNumberStyle = {
  marginTop: "8px",
  border: "1px solid #dfdfdf",
  borderRadius: "8px",
  width: "180px",
  padding: "9px",
  fontSize: "16px",
};

const pStyles = {
  textAlign: "right",
  fontWeight: "700",
  fontSize: "16px",
  color: "black",
};

// amol-temp - added buttonStyle
const buttonStyle = {
  marginTop: "7px",
  textAlign: "right",
  border: "1px solid #dfdfdf",
  height: "36px",
};

import React, { useState } from "react";

const CustomTree = ({ treeData, returnValue }) => {
  const [expandedNodes, setExpandedNodes] = useState([]);
  const [editableData, setEditableData] = useState(treeData);

  const toggleNodeExpansion = (nodeId) => {
    if (expandedNodes.includes(nodeId)) {
      setExpandedNodes(expandedNodes.filter((node) => node !== nodeId));
    } else {
      setExpandedNodes([...expandedNodes, nodeId]);
    }
  };

  // const handleCheckboxChange = (nodeId, checkboxType, nodes = editableData) => {
  //   const updatedData = nodes.map((node) => {
  //     if (node.recno === nodeId) {
  //       return {
  //         ...node,
  //         [checkboxType]: !node[checkboxType],
  //       };
  //     } else if (node.children && node.children.length > 0) {
  //       return {
  //         ...node,
  //         children: handleCheckboxChange(nodeId, checkboxType, node.children),
  //       };
  //     }
  //     return node;
  //   });

  //   return updatedData;
  // };


  const handleCheckboxChange = (nodeId, checkboxType, nodes = editableData) => {
    const updatedData = nodes.map((node) => {
      if (node.recno === nodeId) {
        // Toggle the checkbox state of the current node
        const updatedNode = {
          ...node,
          [checkboxType]: !node[checkboxType],
      
        };
  
        // If the current node is a parent node with children, update the children accordingly
        if (node.children && node.children.length > 0) {
          updatedNode.children = node.children.map((child) => ({
            ...child,
            [checkboxType]: updatedNode[checkboxType], // Update child checkbox state based on parent
          
          }));
        }
  
        return updatedNode;
      } else if (node.children && node.children.length > 0) {
        // Recursively update child nodes
        return {
          ...node,
          children: handleCheckboxChange(nodeId, checkboxType, node.children),
          
        };
      }
      return node;
    });
  
    return updatedData;
  };
  
  const renderRow = (node, level = 1) => (
    <>
      <tr key={node.id}>
        <td style={{ paddingLeft: `${level * 20}px`, textAlign: "left" }} onClick={() => toggleNodeExpansion(node.recno)}>
          {node.children && node.children.length > 0 && (expandedNodes.includes(node.recno) ? "▼" : "▶")}
          {node.title}
        </td>
        <td style={{ textAlign: "center", width: "100px" }}>
          <input
            type="checkbox"
            checked={node.isRead}
            onChange={() => {
              const updatedData = handleCheckboxChange(node.recno, "isRead");
              setEditableData(updatedData);
              returnValue(updatedData);
            }}
          />
        </td>
        <td style={{ textAlign: "center", width: "100px" }}>
          <input
            type="checkbox"
            checked={node.isEdit}
            onChange={() => {
              const updatedData = handleCheckboxChange(node.recno, "isEdit");
              setEditableData(updatedData);
              returnValue(updatedData);
            }}
          />
        </td>
        <td style={{ textAlign: "center", width: "100px" }}>
          <input
            type="checkbox"
            checked={node.selected}
            onChange={() => {
              const updatedData = handleCheckboxChange(node.recno, "selected");
              setEditableData(updatedData);
              returnValue(updatedData);
            }}
          />
        </td>
        <td style={{ textAlign: "center", width: "100px" }}>
          <input
            type="checkbox"
            checked={node.isDelete}
            onChange={() => {
              const updatedData = handleCheckboxChange(node.recno, "isDelete");
              setEditableData(updatedData);
              returnValue(updatedData);
            }}
          />
        </td>
      </tr>
      {expandedNodes.includes(node.recno) &&
        node.children &&
        node.children.length > 0 &&
        node.children.map((childNode) => renderRow(childNode, level + 1))}
    </>
  );

  return (
    <div>
      <table style={{ width: "90%",marginLeft:"5%" }}>
        <thead>
          <tr>
            <th style={{ width: "400px", textAlign: "left" }}>Name</th>
            <th style={{ width: "100px" }}>IsRead</th>
            <th style={{ width: "100px" }}>IsEdit</th>
            <th style={{ width: "100px" }}>Permission</th>
            <th style={{ width: "100px" }}>IsDelete</th>
          </tr>
        </thead>
        <tbody>{editableData.map((node) => renderRow(node))}</tbody>
      </table>
    </div>
  );
};

export default CustomTree;

import React, { useContext, useState, useEffect } from "react";
import { apiCallwithBaseUrl } from "../../ApiCalls";
import { AuthStore } from "../../../Auth/AuthStore";
import axios from "axios";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import useExecute from "../../Hooks/useExecute";
import useSaveChangedRows from "../../Hooks/useSaveChangedRows";
import { TabStore } from "../../../TabsStructure/TabsStore";

function SaveDropdown({
  displayLabel,
  apikey,
  apiurl,
  optionsList,
  defaultSelected,
  returnValue,
  async,
  selectionValue,
  selectionLabel,
  placeholder,
  style,
  className,
  multi,
  body,
  alignment,
  width,
  minInput,
  isrequired,
  disabled,
  id,
}) {
  let drpWidth = width ? width : "250px";
  const auth = useContext(AuthStore);
  const bgcolor = auth?.state?.userdata?.theme[0]?.inputbgcolor;
  const btncolor = auth?.state?.userdata?.theme[0]?.btncolor;
  const tabs = useContext(TabStore);
  const [options, setOptions] = useState([]);
  const [selectedValue, setSelectedValue] = useState(null);
  const [hasEffectRun, setHasEffectRun] = useState(false);


  //const execute = useExecute();
  const [apiBody, setApiBody] = useState(body);
  const save = useSaveChangedRows();
  /// here is logic for inter depended dropdown api calling date 6th dec by rbn start
  useEffect(() => {
    // Check if body is defined and its values are not undefined
    if (
      body &&
      Object.values(body).every((value) => value !== undefined) &&
      apiBody
    ) {
      const bodyValues = Object.values(body);
      const apiBodyValues = Object.values(apiBody);

      if (bodyValues.some((value, index) => value !== apiBodyValues[index])) {
        setApiBody(body);
      }
    }
  
  }, [body, apiBody]);

  // Use a separate useEffect for calling the API when apiBody changes
  useEffect(() => {
    if (body) {
     
      // Call the API function (getdropdownoptions) here
      if (Object.values(body).every((value) => value !== undefined)) {
        getdropdownoptions();
      }
    }
  }, [apiBody]);


  // Default Values
  useEffect(() => {
    if (options?.length > 0) {
      if (defaultSelected) {
        if (save.getValue(apikey)) {
          if (typeof save.getValue(apikey) == "string") {
            if (save.getValue(apikey).includes(",")) {
              let selectionArr = save.getValue(apikey).split(",");
              let selectedOptions = [];
              selectionArr.map((i) => {
                const selectedObject = options?.filter(
                  (item) => item[selectionValue] == i
                );
                selectedOptions.push(selectedObject);
              });
             
              if (returnValue) {
                returnValue(selectedOptions);
              }
              setSelectedValue(selectedOptions);
            } else {
              const selectedObject = options?.filter(
                (item) => item[selectionValue] == save.getValue(apikey)
              );
              let selectedOptions = selectedObject[0];
             
              if (returnValue) {
                returnValue(selectedOptions);
              }
              setSelectedValue(selectedOptions);
            }
          } else {
            const selectedObject = options?.filter(
              (item) => item[selectionValue] == save.getValue(apikey)
            );
            let selectedOptions = selectedObject[0];
        
            if (returnValue) {
              returnValue(selectedOptions);
            }
            setSelectedValue(selectedOptions);
          }

          // } else if (execute.getValue(apikey) == "") {
          //   setSelectedValue(execute.getValue(apikey));
        } else {
          const selectedObject = options?.filter(
            (item) => item[selectionValue] == defaultSelected
          );
        
          setSelectedValue(selectedObject);

          if (returnValue) {
            returnValue(selectedObject);
          }
          save.updateValue(apikey, defaultSelected);
        }
      } else if (defaultSelected == 0) {
        setSelectedValue(options[0]);
      } else {
        if (save.getValue(apikey)) {
          if (typeof save.getValue(apikey) == "string") {
            if (save.getValue(apikey).includes(",")) {
              let selectionArr = save.getValue(apikey).split(",");
              let selectedOptions = [];
              selectionArr.map((i) => {
                const selectedObject = options?.map((item) => {
                  if (item[selectionValue] == i) {
                    console.log("Selected Array Matched", item, i);
                    selectedOptions.push(item);
                  }
                });
              });
              console.log("Selected Value is", apikey, selectedOptions);
              if (returnValue) {
                returnValue(selectedOptions);
              }
              setSelectedValue(selectedOptions);
            } else {
              const selectedObject = options?.filter(
                (item) => item[selectionValue] == save.getValue(apikey)
              );
              let selectedOptions = selectedObject[0];
            
              if (returnValue) {
                returnValue(selectedOptions);
              }
              setSelectedValue(selectedOptions);
            }
          } else {
            const selectedObject = options?.filter(
              (item) => item[selectionValue] == save.getValue(apikey)
            );
            let selectedOptions = selectedObject[0];
        
            if (returnValue) {
              returnValue(selectedOptions);
            }
            setSelectedValue(selectedOptions);
          }
        } else {
          setSelectedValue(options[0]);
        }
      }
    }

    //default seletected value change 16/05/23
    if (selectedValue === undefined) {
      setSelectedValue(null);
    }
  }, [options, defaultSelected]);

  useEffect(() => {
    if (options?.length < 1) {
      getdropdownoptions();
    }
  }, [save.getValue(apikey)]);

  async function getdropdownoptions(inputValue) {
    body = {
      tenantrecno: auth.state.userdata.tenantrecno,
      domainrecno: auth.state.userdata.domainrecno,
      descn: inputValue,
      ...apiBody,
    };
    var resp;
    // if (apiurl.includes("http")) {
    //   resp = await axios.post(apiurl, body).then((res) => res.data).catch();
    //   resp = resp.data;
    //   console.log("resp1", resp);
    // } else {
    //   resp = await apiCallwithBaseUrl("POST", apiurl, body);
    //   console.log("resp2", resp);
    // }
    try {
      if (apiurl.includes("http")) {
        resp = await axios.post(apiurl, body);
        resp = resp?.data;
       
      } else {
        resp = await apiCallwithBaseUrl("POST", apiurl, body);
      
      }
    } catch (error) {
      // Handle error here
    
      // You can handle the error according to your application's requirements,
      // such as displaying a user-friendly error message or logging the error.
    }

    let formattedArray = resp?.Message?.map((item, i) => {
      return {
        ...item,
        value: item[selectionValue],
        label: item[selectionLabel],
      };
    });
    //  formattedArray.unshift({
    //   value:"Please select",
    //   label: "Please select",
    //  })

    // this code commented out because when one item is there it need to get selecetd automatically
    if (formattedArray?.length >= 2) {
      formattedArray = [
        { label: `Select ${displayLabel}`, value: null },
        ...formattedArray,
      ]; // KMK 05/04/23 For Null Selection
    }
    setOptions(formattedArray);
    if (formattedArray?.length === 1) {
      setSelectedValue(formattedArray[0]);
      save.updateValue(apikey, formattedArray[0][apikey]);
      if (returnValue) {
        returnValue(formattedArray[0]);
        save.updateValue(apikey, formattedArray[0][apikey]);
      }
    }
    return formattedArray;
  }

  // For Updating the Values to the TabStore and reverting to Parent
  const updateMultiSelection = (e) => {
    

    if (e?.length > 0) {
      var updateValues = "";
      e.map((item) => {
        updateValues = updateValues + item[selectionValue] + ",";
      });
      updateValues = updateValues.substring(0, updateValues.length - 1);
      save.updateValue(apikey, updateValues);
      setSelectedValue(e);
      if (returnValue) {
        returnValue(e);
      }
    } else {
      setSelectedValue(e);
      save.updateValue(apikey, e[selectionValue]);
      if (returnValue) {
        returnValue(e);
      }
    }
  };

  /// debouncing logic here main logic here on 5th december
  const debounce = (func, delay) => {
    let timer;

    return function () {
      const context = this;
      const args = arguments;

      clearTimeout(timer);
      timer = setTimeout(() => {
        func.apply(context, args);
      }, delay);
    };
  };

  const loadOptions = async (inputValue, callback) => {
    if (inputValue?.length >= minInput) {
      const dropdownoptions = await getdropdownoptions(inputValue);
      callback(dropdownoptions);
    }
  };

  /// debouncing logic here start on 5th december this function helps to call the api after 3 seconds
  const debouncedLoadOptions = debounce(loadOptions, 2000);
  // useEffect(() => {
  //   getdropdownoptions();
  // }, []);

  const [isFocused, setIsFocused] = useState(false);
  const handleFocus = () => {
    // This function is called when the input element receives focus.
    setIsFocused(true);
  };

  const handleBlur = () => {
    // This function is called when the input element loses focus.
    setIsFocused(false);
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        flexDirection: alignment,
        textAlign: "start",
        gap: "8px",
        position: "relative",
      }}
    >
      {/* {!alignment ? (
        <p>{displayLabel}</p>
      ) : (
        <p
          style={{
            width: "100%",
          }}
        >
          {isrequired ? (
            <span>
              {displayLabel}
              <span style={{ color: "red" }}>*</span>
            </span>
          ) : (
            `${displayLabel}`
          )}
          
        </p>
      )} */}

      <div style={{ width: drpWidth }}>
        {async ? (
          <AsyncSelect
            onFocus={handleFocus}
            onBlur={handleBlur}
            className="dropdown"
            isDisabled={disabled}
            placeholder={""}
            cacheOptions
            loadOptions={debouncedLoadOptions}
            onChange={(e) => {
              updateMultiSelection(e);
            }}
            value={selectedValue}
            // isClearable
            isMulti={multi}
            styles={{
              control: (baseStyle, state) => ({
                ...baseStyle,
                ...dropDownStyles,
                menu: (provided) => ({ ...provided, zIndex: 9999 }),
                backgroundColor: bgcolor || "#EBEDF0",
              }),
              option: (baseStyle, { isFocused, isSelected }) => ({
                ...baseStyle,
                backgroundColor: isSelected
                  ? btncolor || "#EBEDF0"
                  : isFocused
                  ? bgcolor || "#EBEDF0"
                  : "white",
                color: isSelected ? "black" : "black",
              }),
            }}
            isClearable
          />
        ) : (
          <Select
            onFocus={handleFocus}
            onBlur={handleBlur}
            isDisabled={disabled}
            onChange={(e) => {
              updateMultiSelection(e);
            }}
            isMulti={multi}
            value={selectedValue}
            options={options}
            defaultValue={defaultSelected}
            placeholder={""}
            className="basic-multi-select select-wrapper"
            classNamePrefix="dropdown select"
            //onChange={handleupdate}
            isClearable
            theme={(theme) => ({
              ...theme,
              borderRadius: "4px",
              colors: { ...theme.colors, text: "orangered" },
            })}
            styles={{
              control: (baseStyle, state) => ({
                ...baseStyle,
                ...dropDownStyles,
                menu: (provided) => ({ ...provided, zIndex: 9999 }),
                backgroundColor: bgcolor || "#EBEDF0",
              }),
              option: (baseStyle, { isFocused, isSelected }) => ({
                ...baseStyle,
                backgroundColor: isSelected
                  ? btncolor || "#EBEDF0"
                  : isFocused
                  ? bgcolor || "#EBEDF0"
                  : "white",
                color: isSelected ? "black" : "black",
              }),
            }}
            //// added for rendering  every changes
          />
        )}
      </div>
      <label
        htmlFor={id}
        className={`label ${
          isFocused || selectedValue ? "focused" : "unfocused"
        }`}
        style={{
          whiteSpace: "nowrap",
          wordWrap: "unset",
          position: "absolute",
          top: isFocused || selectedValue ? "-19px" : "50%",
          left: "8px",
          //backgroundColor: isFocused || selectedValue ? 'white' : "transparent",
          padding: "0 4px",
          fontSize: isFocused || selectedValue ? "12px" : "16px",
          color: isFocused ? "black" : "",
          pointerEvents: "none",
          transform:
            isFocused || selectedValue ? "translateY(0)" : "translateY(-50%)",
          transition: "top 0.3s, transform 0.3s, font-size 0.3s, color 0.3s",
        }}
      >
        {isrequired ? (
          <span
            className={`label ${
              isFocused || selectedValue ? "focused" : "unfocused"
            }`}
          >
            {isFocused || selectedValue ? displayLabel : placeholder}
            <span style={{ color: "red" }}>*</span>
          </span>
        ) : (
          <span
            className={`label ${
              isFocused || selectedValue ? "focused" : "unfocused"
            }`}
          >
            {isFocused || selectedValue ? displayLabel : placeholder}
          </span>
        )}
      </label>
    </div>
  );
}

//CSS

export default SaveDropdown;

const dropDownStyles = {
  //border: "1px solid #cccccc",

  borderRadius: "8px",
};

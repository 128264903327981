import React, { useContext, useEffect, useState, useRef } from "react";

//import DropdownSelect from "../CommonControllers/Components/DropdownSelect";
import { Modal, Typography, Button } from "antd";


import { SearchOutlined } from "@ant-design/icons";
import { Input, Space } from "antd";
import Highlighter from "react-highlight-words";


import DomainSupplierDrop from "../../CommonControllers/Components/CommonDropDowns/PartyRelated/DomainSupplierDrop";
import CellCheckBox from "../../CommonControllers/Components/TableComponent/CellCheckBox";
import EditableCommonTable from "../../CommonControllers/Components/TableComponent/EditableCommonTable";
import { TabStore } from "../../TabsStructure/TabsStore";
import { dateFormatter } from "../../CommonControllers/AppFunction";
import DomainDrop from "../../CommonControllers/Components/CommonDropDowns/DomainDrop";
import ItemCategoryMultiDrop from "../../CommonControllers/Components/CommonDropDowns/ItemRelated/ItemCategoryMultiDrop";
import TableComponent from "../../CommonControllers/Components/TableComponent/TableComponent";
import SalesmanDrop from "../../CommonControllers/Components/CommonDropDowns/SalesmanDrop";
import CustomerTypeDrop from "../../CommonControllers/Components/CommonDropDowns/CustomerTypeDrop";
import EditableTable from "../../CommonControllers/Components/Tables/EditableTable";


const { Title } = Typography;

const DomainSale = () => {
  const tabStore = useContext(TabStore);
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const covertToRequiredFormat = () => {
    const totalData = tabStore.state.tabs[tabStore.state.tabIndex].apiResponse;
    var columns = totalData?.columnsinfo?.map((item) => {
      if (item.filter){
      return {
        ...item,
        dataIndex: item.columnname,
        ...getColumnSearchProps(item.columnname),
        render: (_, tags) =>
          item.type == "boolean" ? (
            <CellCheckBox
              defaultValue={tags[item.columnname]}
              disabled
              label={item.title}
            />
          ) : item.type == "date" ? (
            <p>{dateFormatter(tags[item.columnname])}</p>
          ) : (
            <p>{tags[item.columnname]}</p>
          ),
        width: item.width,
      };
    }
    else {
      return { ...item,
        dataIndex: item.columnname,
        render: (_, tags) =>
          item.type == "boolean" ? (
            <CellCheckBox
              defaultValue={tags[item.columnname]}
              disabled
              label={item.title}
            />
          ) : item.type == "date" ? (
            <p>{dateFormatter(tags[item.columnname])}</p>
          ) : (
            <p>{tags[item.columnname]}</p>
          ),
        width: item.width,
      };
    }
    });
    var rows = totalData?.Message?.map((item) => {
      return { ...item };
    });

    setColumns(columns);
    setRows(rows);
  };

  useEffect(() => {
    
    if (
      tabStore.state.tabs[tabStore.state.tabIndex].apiResponse?.Message
        ?.length > 0
    ) {
      covertToRequiredFormat();
    } else {
      console.log("This Function is");
    }
  }, [tabStore.state.tabs[tabStore.state.tabIndex].apiResponse]);

  return (
    <div>
      <div style={{display:"flex",justifyContent:"space-evenly",alignItems:"center"}}>
      <div
       
      >
        <DomainDrop/>
      </div>
      <div
     
      >
          <SalesmanDrop />
      </div>
      <div
        
      >
        <CustomerTypeDrop/>
      </div>
      </div>
<div>

{columns.length > 0 && rows.length > 0 ? (
        <div style={{ padding: "10px" }}>
          {tabStore.state.tabs[tabStore.state.tabIndex].mode === "view" ? (
            <TableComponent data={rows} columns={columns} />
          ) : tabStore.state.tabs[tabStore.state.tabIndex].mode === "edit" ? (
            <EditableTable
              // rowData={rows}
              // columnData={columns}
              // columnLength={columns.length}
              // storeValue={"apiVariables.response.Message"}
              // isAdd={false}
              // isEdit={true}
              columnsArray={columns}
              rowsArray={rows} 
            />
          ) : null}
        </div>
      ) : null}

</div>
      {/* <EditableCommonTable rowData={rows} columnData={columns} columnLength={columns.length} storeValue={"apiVariables.response.Message"}/> */}

      {/* {tabStore.state.tabs[tabStore.state.tabIndex].mode==="view"?(<TableComponent data={rows} columns={columns}/>):<EditableCommonTable rowData={rows} columnData={columns} columnLength={columns.length} storeValue={"apiVariables.response.Message"}/>} */}
    </div>
  );
};

export default DomainSale;

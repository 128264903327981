import React, { useContext, useEffect, useState } from "react";
import { prColumns } from "./SaleReturnBillingTableColumns";
import { Table, Spin } from "antd";
import { TabStore } from "../../TabsStructure/TabsStore";
import ScanTable from "../../CommonControllers/Components/ScanTable/ScanTable";
import noData from "../../Assets/noData.png";
const ItemsTable = ({
  rowData,
  setRowData,
  igstappl,
  pageDetails,
  saleNumber,
}) => {
  const [showTable, setShowTable] = useState(true);
  const tabStore = useContext(TabStore);

  // scan table
  const [openItem, setOpenItem] = useState(false);
  const [scandata, setScandata] = useState();
  const [renderer, setRenderer] = useState(0);
  const [customerData, setCustomerData] = useState({});
  const [validator, setValidator] = useState(0);
  useEffect(() => {
    console.log(`tr001 items table re-rendered ?? after deleting`);
    setShowTable(false);

    setTimeout(() => {
      setShowTable(true);
    }, 200);
  }, [
    rowData?.length,
    tabStore.state.tabs[tabStore.state.tabIndex]?.editIndex,
  ]);

  console.log("showTable", showTable);

  console.log("ff", rowData);
  //// date 20th april 2023 by rbn
  // let modified=rowData.filter((row)=>row.stockavailableforreturn!==false)

  return (
    <>
      <div>
        {showTable && rowData?.length > 0 ? (
          <Table
            style={{
              thead: { background: "#f5f5f5", color: "#333" },
              tfoot: { background: "#f5f5f5", color: "#333" },
            }}
            scroll={{
              x: 240,
              //y:305
            }}
            size="small"
            className="tr001-purchaseOrder-itemsTable"
            bordered
            pagination={false}
            columns={prColumns(
              igstappl,
              setRowData,
              rowData,
              scandata,
              setScandata,
              setOpenItem,
              pageDetails
            )?.filter((item) => !item.hidden)}
            dataSource={rowData}
            onChange={(e) => {
              console.log("ONCHANGE HANDLE", e);
            }}
            summary={(pageData) => {
              console.log("PAGE", pageData);
              let quantity = 0;
              let taxableAmount = 0;
              let cgstAmount = 0;
              let sgstAmount = 0;
              let igstAmount = 0;
              let totalAmount = 0;
              let boxTotal = 0;
              let batchTotal = 0;
              let rateTotal = 0;
              let qty = 0;
              let returnboxTotal = 0;
              let returnpieceTotal = 0;
              let returnTotalqty = 0;
              let returnTaxableAmount = 0;
              let returncgstAmount = 0;
              let retutnsgstAmount = 0;
              let returnigstAmount = 0;
              let returnAmount = 0;

              pageData.forEach(
                ({
                  packingqty,
                  taxableamount,
                  cgstamt,
                  sgstamt,
                  igstamt,
                  amount,
                  packingextraqty,
                  purrate,
                  batch,
                  qty,
                  returnpackingextraqty,
                  returnpackingqty,
                  returnqty,
                  currentreturntaxableamount,
                  returnsgstamt,
                  returncgstamt,
                  returnigstamt,
                  returnamount,
                  currentreturnpackingqty,
                  currentreturnpackingextraqty,
                  currentreturnigstamt,
                  currentreturnamount,
                  currentreturnsgstamt,
                  currentreturncgstamt,
                  currentreturnqty,
                }) => {
                  quantity += Number(packingqty);
                  taxableAmount += Number(taxableamount);
                  cgstAmount += Number(cgstamt);
                  sgstAmount += Number(sgstamt);
                  igstAmount += Number(igstamt);
                  totalAmount += Number(amount);
                  boxTotal += Number(packingextraqty);
                  rateTotal += Number(purrate);
                  batchTotal += Number(batch);
                  qty += Number(qty);
                  returnboxTotal += Number(currentreturnpackingextraqty)
                    ? Number(currentreturnpackingextraqty)
                    : 0;
                  returnpieceTotal += Number(currentreturnpackingqty)
                    ? Number(currentreturnpackingqty)
                    : 0;
                  returnTotalqty += Number(currentreturnqty)
                    ? Number(currentreturnqty)
                    : 0;
                  returnTaxableAmount += Number(currentreturntaxableamount)
                    ? Number(currentreturntaxableamount)
                    : 0;
                  returncgstAmount += Number(currentreturncgstamt)
                    ? Number(currentreturncgstamt)
                    : 0;
                  retutnsgstAmount += Number(currentreturnsgstamt)
                    ? Number(currentreturnsgstamt)
                    : 0;
                  returnigstAmount += Number(currentreturnigstamt)
                    ? Number(currentreturnigstamt)
                    : 0;
                  returnAmount += Number(currentreturnamount)
                    ? Number(currentreturnamount)
                    : 0;
                }
              );
              return (
                <Table.Summary fixed>
                  {!igstappl ? (
                    <Table.Summary.Row>
                      <Table.Summary.Cell index={0}>{null}</Table.Summary.Cell>
                      {/* nohit-425 Change Position of Total Caption */}
                      <Table.Summary.Cell index={1}>
                        <p style={{ ...pStyles, textAlign: "left" }}>Total</p>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={2}>
                        <p style={{ ...pStyles }}>{null}</p>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={3}>
                        <p style={{ ...pStyles }}>{null}</p>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={4}>
                        <p style={{ ...pStyles }}>{null}</p>
                      </Table.Summary.Cell>
                      {pageDetails?.wantpackingextra ? (
                        <Table.Summary.Cell index={2}>
                          <p style={{ ...pStyles }}>{boxTotal}</p>
                        </Table.Summary.Cell>
                      ) : null}
                      <Table.Summary.Cell index={6}>
                        <p style={{ ...pStyles }}>{quantity}</p>
                      </Table.Summary.Cell>
                      {pageDetails?.wantpackingextra && pageDetails?.wantfreeqty ? (
                        <Table.Summary.Cell index={4}>
                          <p style={{ ...pStyles }}>{null}</p>
                        </Table.Summary.Cell>
                      ) : null}
                      {/* <Table.Summary.Cell index={2}>
                      <p style={{ ...pStyles }}>{null}</p>
                    </Table.Summary.Cell> */}
                      {pageDetails?.wantfreeqty ? (
                        <Table.Summary.Cell index={4}>
                          <p style={{ ...pStyles }}>{null}</p>
                        </Table.Summary.Cell>
                      ) : null}
                      <Table.Summary.Cell index={2}>
                        <p style={{ ...pStyles }}>{null}</p>
                      </Table.Summary.Cell>
                      {pageDetails?.wantpackingextra ? (
                        <Table.Summary.Cell index={4}>
                          <p style={{ ...pStyles }}>{null}</p>
                        </Table.Summary.Cell>
                      ) : null}
                      <Table.Summary.Cell index={2}>
                        <p style={{ ...pStyles }}>{null}</p>
                      </Table.Summary.Cell>
                      {pageDetails?.wantfreeqty ? (
                      <Table.Summary.Cell index={2}>
                        <p style={{ ...pStyles }}>{null}</p>
                      </Table.Summary.Cell>
                      ) : null}
                      <Table.Summary.Cell index={7}>
                        <p style={{ ...pStyles }}>
                          {Number(totalAmount).toFixed(2)}
                        </p>
                      </Table.Summary.Cell>
                      {pageDetails?.wantpackingextra ? (
                        <Table.Summary.Cell index={2}>
                          <p style={{ ...pStyles }}>{returnboxTotal}</p>
                        </Table.Summary.Cell>
                      ) : null}
                      <Table.Summary.Cell index={9}>
                        <p style={{ ...pStyles }}>{Number(returnpieceTotal)}</p>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={10}>
                        <p style={{ ...pStyles }}>{Number(returnTotalqty)}</p>
                      </Table.Summary.Cell>
                      {pageDetails?.wantfreeqty ? (
                        <Table.Summary.Cell index={4}>
                          <p style={{ ...pStyles }}>{null}</p>
                        </Table.Summary.Cell>
                      ) : null}
                      <Table.Summary.Cell index={12}>
                        <p style={{ ...pStyles }}>{null}</p>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={13}>
                        <p style={{ ...pStyles }}>{null}</p>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={14}>
                        <p style={{ ...pStyles }}>{null}</p>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={2}>
                        <p style={{ ...pStyles }}>{null}</p>
                      </Table.Summary.Cell>
                      {pageDetails?.wantpackingextra ? (
                        <Table.Summary.Cell>
                          <p style={{ ...pStyles }}>{null}</p>
                        </Table.Summary.Cell>
                      ) : null}
                      <Table.Summary.Cell index={15}>
                        <p style={{ ...pStyles }}>
                          {Number(returnTaxableAmount).toFixed(2)}
                        </p>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={16}>
                        <p style={{ ...pStyles }}>{null}</p>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={17}>
                        <p style={{ ...pStyles }}>
                          {Number(returncgstAmount).toFixed(2)}
                        </p>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={18}>
                        <p style={{ ...pStyles }}>{null}</p>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={19}>
                        <p style={{ ...pStyles }}>
                          {Number(retutnsgstAmount).toFixed(2)}
                        </p>
                      </Table.Summary.Cell>
                      {/* <Table.Summary.Cell index={19}>
                    <p style={{ ...pStyles }}>{null}</p>
                     </Table.Summary.Cell>
                    <Table.Summary.Cell index={20}>
                      <p style={{ ...pStyles }}>{returnigstAmount}</p>
                    </Table.Summary.Cell> */}
                      <Table.Summary.Cell index={20}>
                        <p style={{ ...pStyles }}>
                          {Number(returnAmount).toFixed(2)}
                        </p>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={2}>
                        <p style={{ ...pStyles }}>{null}</p>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={3}>
                        <p style={{ ...pStyles }}>{null}</p>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={3}>
                        <p style={{ ...pStyles }}>{null}</p>
                      </Table.Summary.Cell>
                    </Table.Summary.Row>
                  ) : (
                    <Table.Summary.Row>
                      <Table.Summary.Cell index={0}>{null}</Table.Summary.Cell>
                      {/* nohit-425 Change Position of Total Caption */}
                      <Table.Summary.Cell index={1}>
                        <p style={{ ...pStyles, textAlign: "left" }}>Total</p>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={2}>
                        <p style={{ ...pStyles }}>{null}</p>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={3}>
                        <p style={{ ...pStyles }}>{null}</p>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={4}>
                        <p style={{ ...pStyles }}>{null}</p>
                      </Table.Summary.Cell>
                      {pageDetails?.wantpackingextra ? (
                        <Table.Summary.Cell index={5}>
                          <p style={{ ...pStyles }}>{boxTotal}</p>
                        </Table.Summary.Cell>
                      ) : null}
                      <Table.Summary.Cell index={6}>
                        <p style={{ ...pStyles }}>{quantity}</p>
                      </Table.Summary.Cell>
                      {pageDetails?.wantpackingextra && pageDetails?.wantfreeqty ? (
                        <Table.Summary.Cell index={7}>
                          <p style={{ ...pStyles }}>{null}</p>
                        </Table.Summary.Cell>
                      ) : null}

                      {pageDetails?.wantfreeqty ? (
                        <Table.Summary.Cell index={7}>
                          <p style={{ ...pStyles }}>{null}</p>
                        </Table.Summary.Cell>
                      ) : null}
                      {pageDetails?.wantpackingextra ? (
                        <Table.Summary.Cell index={7}>
                          <p style={{ ...pStyles }}>{null}</p>
                        </Table.Summary.Cell>
                      ) : null}
                      <Table.Summary.Cell index={2}>
                        <p style={{ ...pStyles }}>{null}</p>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={2}>
                        <p style={{ ...pStyles }}>{null}</p>
                      </Table.Summary.Cell>
                      {pageDetails?.wantfreeqty ? (
                      <Table.Summary.Cell index={6}>
                        <p style={{ ...pStyles }}>{null}</p>
                      </Table.Summary.Cell>
                      ) : null}
                      <Table.Summary.Cell index={7}>
                        <p style={{ ...pStyles }}>
                          {Number(totalAmount).toFixed(2)}
                        </p>
                      </Table.Summary.Cell>
                      {pageDetails?.wantpackingextra ? (
                        <Table.Summary.Cell index={8}>
                          <p style={{ ...pStyles }}>{Number(returnboxTotal)}</p>
                        </Table.Summary.Cell>
                      ) : null}
                      <Table.Summary.Cell index={9}>
                        <p style={{ ...pStyles }}>{Number(returnpieceTotal)}</p>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={10}>
                        <p style={{ ...pStyles }}>{Number(returnTotalqty)}</p>
                      </Table.Summary.Cell>
                      {pageDetails?.wantfreeqty ? (
                        <Table.Summary.Cell index={11}>
                          <p style={{ ...pStyles }}>{null}</p>
                        </Table.Summary.Cell>
                      ) : null}
                      <Table.Summary.Cell index={12}>
                        <p style={{ ...pStyles }}>{null}</p>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={13}>
                        <p style={{ ...pStyles }}>{null}</p>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={14}>
                        <p style={{ ...pStyles }}>{null}</p>
                      </Table.Summary.Cell>
                      {pageDetails?.wantpackingextra ? (
                        <Table.Summary.Cell>
                          <p style={{ ...pStyles }}>{null}</p>
                        </Table.Summary.Cell>
                      ) : null}
                      <Table.Summary.Cell index={2}>
                        <p style={{ ...pStyles }}>{null}</p>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={15}>
                        <p style={{ ...pStyles }}>
                          {Number(returnTaxableAmount).toFixed(2)}
                        </p>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={16}>
                        <p style={{ ...pStyles }}>{null}</p>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={17}>
                        <p style={{ ...pStyles }}>
                          {Number(returnigstAmount).toFixed(2)}
                        </p>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={18}>
                        <p style={{ ...pStyles }}>
                          {Number(returnAmount).toFixed(2)}
                        </p>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={2}>
                        <p style={{ ...pStyles }}>{null}</p>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={3}>
                        <p style={{ ...pStyles }}>{null}</p>
                      </Table.Summary.Cell>
                    </Table.Summary.Row>
                  )}
                </Table.Summary>
              );
            }}
          />
        ) : rowData?.length > 0 ? (
          <div style={{ ...loaderStyle }}>
            <Spin size="large" />
          </div>
        ) : (
          <div className="noDataFound">
            <h1>Whoops! No Returnable Records Found</h1>
            <img src={noData} />
          </div>
        )}
      </div>
      <div>
        {openItem && (
          <ScanTable
            data={scandata}
            openItem={openItem}
            setOpenItem={setOpenItem}
            rowData={rowData}
            setRowData={setRowData}
            renderer={renderer}
            setRenderer={setRenderer}
            setValidator={setValidator}
            forFlag={"SR"}
            supplierrecno={customerData?.customerrecno}
            saleNumber={saleNumber}
          />
        )}
      </div>
    </>
  );
};

export default ItemsTable;

const loaderStyle = {
  height: "max-content",
  width: "max-content",
  display: "flex",
  alignContent: "center",
  margin: "50px auto",
};

const pStyles = {
  textAlign: "right",
  fontWeight: "700",
  fontSize: "16px",
  color: "black",
};

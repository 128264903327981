import { Button, Calendar } from "antd";
import React, { useContext, useState, useEffect } from "react";
import "../../CommonControllers/global.css";
import { AiTwotoneCalendar } from "react-icons/ai";
import dayjs from "dayjs";
import { getInputValue, updateValue } from "../../TabsStructure/tabFunctions";
import { TabStore } from "../../TabsStructure/TabsStore";
import { getToday } from "../AppFunction";
import { Margin } from "@mui/icons-material";

const dateFormatter = (funDate) => {
  if (funDate == 0) {
    return null;
  }
  if (!funDate) {
    return null;
  }
  let date = funDate?.toString() || "";
  let formattedDate =
    date.length >= 7
      ? date.slice(6, 8) + "/" + date.slice(4, 6) + "/" + date.slice(0, 4)
      : date.slice(4, 6) + "/" + date.slice(0, 4);

  return formattedDate;
};

const datedisplay = (date, initial) => {
  // Initial true meaning the day should be the first day of the month that is 01
  // Initial false meaning hte day should be last day of the month that is 30/31/28/29

  let trdate = date.toString();
  if (trdate.length == 6) {
    const dd = trdate.slice(0, 2);
    const mm = trdate.slice(2, 4);
    const yy = trdate.slice(4, 6);

    if (Number(mm) > 12) {
      return false;
    } else {
      var dispDate = dd.toString() + "/" + mm + "/" + "20" + yy;
      var dataDate = "20" + yy.toString() + mm.toString() + dd.toString();
      return { dispDate: dispDate, dataDate: dataDate };
    }
  } else if (trdate.length == 8) {
    const dd = trdate.slice(0, 2);
    const mm = trdate.slice(2, 4);
    const yy = trdate.slice(4, 8);
    if (Number(mm) > 12) {
      return false;
    } else {
      var dispDate = dd.toString() + "/" + mm + "/" + yy;
      var dataDate = yy.toString() + mm.toString() + dd.toString();
      return { dispDate: dispDate, dataDate: dataDate };
    }
  } else if (trdate.length == 10) {
    const dd = trdate.slice(0, 2);
    const mm = trdate.slice(3, 5);
    const yy = trdate.slice(6, 10);
    if (Number(mm) > 12) {
      return false;
    } else {
      var dispDate = dd.toString() + "/" + mm + "/" + yy;
      var dataDate = yy.toString() + mm.toString() + dd.toString();
      return { dispDate: dispDate, dataDate: dataDate };
    }
  } else if (trdate.length == 4) {
    const mm = trdate.slice(0, 2);
    const yy = trdate.slice(2, 4);
    if (Number(mm) > 12) {
      return false;
    } else {
      let lastDate = new Date("20" + yy, mm, 0).getDate();
      if (initial) {
        lastDate = "01";
      }

      var dispDate = lastDate.toString() + "/" + mm + "/" + "20" + yy;
      var dataDate = "20" + yy.toString() + mm.toString() + lastDate.toString();
      return { dispDate: dispDate, dataDate: dataDate };
    }
  } else {
    return { dispDate: "", dataDate: null };
  }
};

function DateComponent({
  apiKey,
  defaultValue = getToday(),
  className,
  id,
  placeholder,
  initial,
  dateLessthan,
  dateGreaterthan,
  displayLabel,
  prevDate,
  renderer,
  alignment,
}) {
  const [displayCalender, setDisplayCalender] = useState(false);
  const tabStore = useContext(TabStore);
  const value = getInputValue(tabStore, apiKey, defaultValue);
  const [calenderDate, setCalenderDate] = useState(value);
  console.log("Calender Date", calenderDate);

  const validateDate = (e) => {
    const lessThan = true;
    const dateGreater = true;
    if (dateGreaterthan) {
      if (e.dataDate < dateGreaterthan) {
        alert("Date should be greater then " + dateFormatter(dateGreaterthan));
        dateGreater = false;
      }
    }
    if (dateLessthan) {
      if (e.dataDate > dateLessthan) {
        alert("Date should be less then " + dateFormatter(dateLessthan));
        lessThan = false;
      }
    }

    return dateGreater * lessThan;
  };

  const onChange = (e) => {
    console.log("Value Chnaged", e.target.value);
    document.getElementById(id).value = e.target.value;
  };

  useEffect(() => {
    console.log(prevDate, "date comp", renderer);
  }, [renderer]);

  const mainFrame = {
    display: "flex",
    gap: "8px",
    flexDirection: alignment,
    alignItems: "center",
    textAlign: "start",
  };

  return (
    <div style={{ position: "relative", ...mainFrame }}>
      {!alignment ? (
        <p>{displayLabel}</p>
      ) : (
        <p style={{ width: "100%" }}>{displayLabel}</p>
      )}
      <div style={{ display: "flex", height: "36px", gap: "4px" }}>
        <input
          style={{
            border: "1px solid #cccccc",
            borderRadius: "8px",
            padding: "6px 10px",
            fontSize: "16px",
            width: "190px",
          }}
          id={id}
          className={className}
          type="text"
          onFocus={(e) => e.target.select()}
          defaultValue={dateFormatter(prevDate)}
          placeholder={placeholder}
          value={datedisplay(value).dispDate}
          onChange={onChange}
          onBlur={(e) => {
            let validatedDate = validateDate(datedisplay(e.target.value));

            if (validatedDate) {
              if (initial) {
                updateValue(
                  tabStore,
                  apiKey,
                  datedisplay(e.target.value, true).dataDate
                );
                e.target.value = datedisplay(e.target.value, true).dispDate;
              } else {
                updateValue(
                  tabStore,
                  apiKey,
                  datedisplay(e.target.value).dataDate
                );
                e.target.value = datedisplay(e.target.value).dispDate;
              }

              e.target.style.color = "black";
            } else {
              e.target.style.color = "red";
            }
          }}
        />
        <Button
          className={className}
          style={{
            justifyContent: "center",
            alignItems: "center",
            height: "36px",
          }}
          icon={<AiTwotoneCalendar />}
          onClick={() => setDisplayCalender(!displayCalender)}
        ></Button>
      </div>
      {displayCalender ? (
        <div
          style={{
            width: "300px",
            border: "1px solid #f0f0f0",
            borderRadius: "8px",
            position: "absolute",
            zIndex: 2,
          }}
        >
          <Calendar
            fullscreen={false}
            onSelect={(value) => setCalenderDate(value.format("YYYYMMDD"))}
          />
          <Button
            onClick={() => {
              console.log(
                "Formateed Date from Calender",
                dateFormatter(calenderDate)
              );
              setDisplayCalender(!displayCalender);
              document.getElementById(id).value = dateFormatter(calenderDate);
              document.getElementById(id).focus();
            }}
          >
            Select
          </Button>
        </div>
      ) : null}
    </div>
  );
}

export default DateComponent;

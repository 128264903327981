import React, { forwardRef, useContext, useEffect, useState } from "react";
import { Modal, Table, Button } from "antd";
import "../global.css";
import GstRows from "./GstRows";
import * as constants from "./constants";
// import QRCode from "qrcode.react"; here qr.react is not issue on 11th july due to which it commented out
import { AuthStore } from "../../Auth/AuthStore";
import { TabStore } from "../../TabsStructure/TabsStore";

const PrintingModal = forwardRef(
  (
    { openModal, orientation = "portrait", closeModal, printModal, data },
    ref
  ) => {
    //~! 430px max height for the body for single page, if row data exceeds, parent container height will be 762px
    //~^ Set data row limit
    const auth = useContext(AuthStore);
    const dataLimit = 5;
    const tabstore=useContext(TabStore)
    // const rowData = data?.itemDataRows?.splice(0, dataLimit);
    const [printingData, setPrintingData] = useState([]);

    /// printing issue in one ip
    const [pageIndex, setPageIndex] = useState();
    useEffect(() => {
      var tabsArray = tabstore.state.tabs;
      for (let i = 0; i < tabsArray?.length; i++) {
        if (tabsArray[i].name == "TR013") {
          setPageIndex(i);
          break;
        }
      }
    }, []);

    useEffect(() => {
      var newArrayforPrint = [];
      var tempNewArray = [];
      var newArray = data?.map((item) => {
        console.log("Item of Printing is", item, item?.itemDataRows, auth);

        for (let i = 0; i < item?.itemDataRows?.length; i++) {
          var newArray = item?.itemDataRows[i]?.serialno?.split("\n");
          var expArray = item?.itemDataRows[i]?.expdate?.split("\n");
          if (newArray?.length > 1) {
            console.log("Item of Printing is........", item?.itemDataRows[i]);

            for (let k = 0; k < newArray.length; k++) {
              if (newArray[k]) {
                if (k == 0) {
                  tempNewArray.push({
                    ...item?.itemDataRows[i],
                    serialno: newArray[k],
                    expdate: expArray[k],
                  });
                  console.log(
                    "Item of Printing is K ........ ",
                    newArray[k],
                    tempNewArray
                  );
                } else {
                  tempNewArray.push({
                    itemdescn: item?.itemDataRows?.itemdescn,
                    serialno: newArray[k],
                    expdate: expArray[k],
                  });
                }
              }
            }
          } else {
            tempNewArray.push({ ...item?.itemDataRows[i] });
          }
        }
        console.log("Item of Printing is New Array is ", tempNewArray);
        tempNewArray.push(item?.itemDataRows);

        if (tempNewArray?.length > 10) {
          var dividedArray = divideArray(tempNewArray, 10);
          var lastItemName = "";
          var newDividedArray = dividedArray?.map((arr) => {
            if (arr[0].itemdescn) lastItemName = arr[0].itemdescn;
            if (!arr[0].itemdescn) arr[0].itemdescn = lastItemName;
            newArrayforPrint.push({ ...item, itemDataRows: arr });
            return { ...item, itemDataRows: arr };
          });

          console.log("Print Newa Array is", newArrayforPrint);
          return newDividedArray;
        } else {
          newArrayforPrint.push(item);
          console.log("Print Newa Array is", newArrayforPrint);
          return [item];
        }
      });

      setPrintingData(newArrayforPrint);
    }, [data]);

    // const tableStyle = {
    //   height: rowData?.length >= 7 ? "auto" : "762px",
    // };
    function divideArray(array, maxSize) {
      const dividedArray = [];
      let i = 0;

      while (i < array.length) {
        dividedArray.push(array.slice(i, i + maxSize));
        i += maxSize;
      }

      return dividedArray;
    }

    useEffect(() => {
      setTimeout(() => {
        printModal();
      }, 1000);
      //closeModal(!openModal)
    }, [openModal]);

    console.log("printingData", printingData,tabstore?.state.tabs[tabstore?.state?.tabIndex]?.name);

    return (
      <div ref={ref}>
        {/* {printingData?.map((printData) => {
          console.log("Printing Data is Item wise", printData)
          return <div style={{height:100, width:100, background:"red"}}>
          
            
            {printData?.smallHeading}

          </div>
        })} */}
        {printingData.map((printData) => {
          console.log("Printing Data is Item wise", printData);
          return (
            <div style={{ width: "100vw", pageBreakAfter: "always" }}>
              {/* Self Information QR Code and Logo */}
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-evenly",
                  borderStyle: "solid",
                  borderWidth: "1px",
                  padding: "5px",
                }}
              >
                <div
                  style={{
                    borderRight: "1px solid black",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "25%",
                  }}
                >
                  {printData?.self?.logo ? (
                    <img
                      src={`data:image/jpeg;base64,${printData?.self?.logo}`} /// here 29 jun logo in printing is made to dynamic
                      // style={{
                      //   width: "70px",
                      //   height: "45px",
                      //   marginTop: "1px",
                      //   borderRadius: "3%",

                      // }}
                      style={{
                        maxWidth: "100%",
                        maxHeight: "100%",
                        width: "auto",
                        height: "auto",
                      }}
                      alt={""}
                    />
                  ) : null}
                </div>

                <thead
                  className="report-header"
                  style={{
                    borderRight: "1px solid black",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "50%",
                  }}
                >
                  <tr>
                    <td>
                      <div className="header-info">
                        <div className="selfDetailsv2">
                          <h3>{printData?.self?.title}</h3>
                          <div>
                            {printData?.self?.otherDetails?.map((el) => (
                              <p>{el.line}</p>
                            ))}
                          </div>
                        </div>

                        <center>
                          <h4>{printData?.self?.document}</h4>
                        </center>
                      </div>
                    </td>
                  </tr>
                </thead>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "25%",
                    padding: "5px",
                  }}
                >
                  {/* <img src={`data:image/jpeg;base64,${printData?.self?.ewaybillqr}`} /> */}
                  

                  {/* {printData?.self?.ewaybillqr ? (  here qr.react is not issue on 11th july due to which it commented out
                    // <QRCode value={printData?.self?.ewaybillqr} />
                  ) : null} */}
                </div>
              </div>

              {/* Document Names Division */}
              <div
                style={{
                  width: "100vw",
                  border: "1px solid black",
                  display: "flex",
                  justifyContent: "center",
                  padding: "2px",
                }}
              >
                {printData?.self?.document}
              </div>

              {/* Document Numbers Division */}
              <div
                style={{
                  width: "100vw",
                  border: "1px solid black",
                  display: "flex",
                  justifyContent: "center",
                  padding: "2px",
                }}
              >
                {/* Invoice Number and Date */}
                <p
                  style={{
                    display: "flex",
                    borderRight: "1px solid black",
                    padding: "2px",
                    justifyContent: "center",
                    alignItems: "center",
                    flex: 1,
                  }}
                >
                  {printData?.self?.document} :{" "}
                  {printData?.self["Instrument Number"]}
                </p>
                <div
                  style={{
                    display: "flex",
                    padding: "2px",
                    justifyContent: "center",
                    alignItems: "center",
                    flex: 1,
                  }}
                >
                  Date : {printData?.self["Instrument Date"]}
                </div>
              </div>

              {/* Party Details and Document Details */}
              {/* Invoice Number and Date */}
              <div
                style={{
                  display: "flex",
                  border: "1px solid black",
                  padding: "2px",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "5px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    borderRight: "1px solid black",
                    padding: "2px",
                    justifyContent: "center",
                    alignItems: "center",
                    flex: 1,
                  }}
                >
                  <table style={{ width: "100%" }}>
                    {Object.keys(printData?.partyDetails).map((key) => {
                      return (
                        <tr>
                          <td>{key}</td>
                          <td>{printData?.partyDetails[key]}</td>
                        </tr>
                      );
                    })}
                  </table>
                </div>
                <div
                  style={{
                    display: "flex",
                    padding: "2px",
                    justifyContent: "center",
                    alignItems: "center",
                    flex: 1,
                  }}
                >
                  <table>
                    {Object.keys(printData?.transportDetails).map((key) => {
                      return (
                        <tr>
                          <td>{key}</td>{" "}
                          <td>{printData?.transportDetails[key]}</td>
                        </tr>
                      );
                    })}
                  </table>
                </div>
              </div>

              <div
                className="itemsDataBoxv2"
                style={{ width: "100%", border: "1px solid black" }}
              >
                <div
                  className="printBody"
                  style={{
                    height:
                      "400px" /* Adjust the height as per your requirement */,
                    overflow: "auto",
                    pageBreakInside: "avoid",
                  }}
                >
                  <Table
                    size="small"
                    columns={printData?.itemsColumnHeadings}
                    dataSource={printData?.itemDataRows}
                    bordered
                    pagination={false}
                  />
                </div>

                <div
                  style={{
                    width: "100vw",
                    border: "1px solid black",
                    display: "flex",
                    justifyContent: "center",
                    padding: "2px",
                  }}
                >
                  {/* Invoice Number and Date */}
                  <p
                    style={{
                      display: "flex",
                      borderRight: "1px solid black",
                      padding: "2px",
                      justifyContent: "center",
                      alignItems: "center",
                      flex: 1,
                      padding: "5px",
                    }}
                  >
                    Total Box : {printData?.itemtotalsRows?.packingextraqty}
                  </p>
                  <div
                    style={{
                      display: "flex",
                      padding: "2px",
                      justifyContent: "center",
                      alignItems: "center",
                      flex: 1,
                    }}
                  >
                    Total Pieces : {printData?.itemtotalsRows?.packingqty}
                  </div>
                </div>
                {printData?.gstDataRows && (
                  <>
                    {" "}
                    <div
                      style={{
                        width: "100vw",
                        border: "1px solid black",
                        display: "flex",
                        justifyContent: "center",
                        padding: "2px",
                      }}
                    >
                      GST Summary
                    </div>
                    <div
                      className="printBody"
                      style={{
                        border:
                          "1px solid black" /* Adjust the height as per your requirement */,
                        overflow: "auto",
                        pageBreakInside: "avoid",
                      }}
                    >
                      <Table
                        size="middle"
                        columns={printData?.gstSummaryHeadings}
                        dataSource={printData?.gstDataRows}
                        bordered
                        pagination={false}
                      />
                    </div>
                  </>
                )}
              </div>

              {/* Footer Object */}
              <div>
                <div
                  style={{
                    ...amountInWords,
                    border: "1px solid black",
                    padding: "5px",
                  }}
                >
                  {printData[constants.inWords]}
                </div>

                <div
                  style={{
                    border: "1px solid black",
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    padding: "5px",
                  }}
                >
                  <div>
                    <div >
                      {auth?.state?.userdata?.tenantname == "ONEIP" ? (// as per kaushik sir suggestion we have made changes
                        <>
                          <h4 >Account Details</h4>
                          <p style={{fontSize:"14px"}}>
                            <li>Account Holder: ONE IP NETWORKS PVT LTD</li>
                          </p>
                          <p style={{fontSize:"14px"}}>
                            <li>Account Number: 50200053075453</li>
                          </p>
                          <p style={{fontSize:"14px"}}>
                            <li>IFSC: HDFC0001592</li>
                          </p>
                          <p style={{fontSize:"14px"}}>
                            <li>Branch: SECTOR 18 - NOIDA</li>
                          </p>
                          <p style={{fontSize:"14px"}}>
                            <li>Account Type: CURRENT</li>
                          </p>
                          {tabstore?.state.tabs[tabstore?.state?.tabIndex]?.name=="PR105"||tabstore?.state.tabs[tabstore?.state?.tabIndex]?.name=="PR106"?   <p style={{fontSize:"14px"}}>
                            <li>GST will get deposited only after the 100% payment is received</li>
                          </p>:null}
                       
                        </>
                      ) : (
                        <>
                          <h3>Terms and Conditions</h3>
                          <div>
                            {Object.keys(printData?.footerLeftSection).map(
                              (key) => {
                                return (
                                  <p>
                                    <li>{printData?.footerLeftSection[key]}</li>
                                  </p>
                                );
                              }
                            )}
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
        {/* ISO A4 paper size : A4 @ 72PPI = H842, W595, screen resolution*/}
        {/* Table with thead, tbody and tfoot. (thead, tfoot: fixed, tbody : dynamic) */}

        {/* </Modal> */}
      </div>
    );
  }
);

export default PrintingModal;

const header = {
  border: "1px solid black",
  borderLeft: "unset",
  borderRight: "unset",
  borderTop: "unset",
  display: "flex",
  flexDirection: "row",
};

const amountInWords = {
  borderBottom: "1px solid black",
  padding: "2px 4px 2px 2px",
  width: "100%",
  fontWeight: 700,
};

const gstSummary = {
  borderBottom: "1px solid black",
  padding: "2px 4px 2px 2px",
  width: "100%",
  textAlign: "center",
};

const documentName = {
  textAlign: "center",
  padding: "4px",
  border: "1px solid black",
  borderTop: "unset",
  borderLeft: "unset",
  borderRight: "unset",
  fontWeight: 700,
};

const pStyle = {
  padding: "0",
  margin: "0",
  lineHeight: "14px",
};

const messageData = {
  borderBottom: "1px solid black",
};

const messageBox = {
  padding: "4px",
};

//~# Changed the hardcoded alert msg to postAlertMsg pass by prop.
//~# Added a returnValue function to get the input value of date
//Modified by Vijay Joshi
//cHANGESS Done
import { Button, Popover, Calendar } from "antd";
import React, { useContext, useState, useEffect } from "react";
import "../../CommonControllers/global.css";
import { AiTwotoneCalendar } from "react-icons/ai";
import dayjs from "dayjs";
import { getInputValue, updateValue } from "../../TabsStructure/tabFunctions";
import { TabStore } from "../../TabsStructure/TabsStore";
import { getToday, isValidDate } from "../AppFunction";
import { Margin } from "@mui/icons-material";
import useSaveChangedRows from "../Hooks/useSaveChangedRows";
import { AuthStore } from "../../Auth/AuthStore";
import errorSound from "../../sounds/stop-13692.mp3";
import { FaInfoCircle } from "react-icons/fa";

//This function is for formating dates as per need
const formatDate = (inputDate) => {
  const date = new Date(inputDate);

  // Extract year, month, and day components
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-based
  const day = date.getDate().toString().padStart(2, "0");

  // Concatenate components to get the "yyyymmdd" format
  const formattedDate = `${year}${month}${day}`;

  return formattedDate;
};

const dateFormatter = (funDate) => {
  if (funDate === 0) {
    return null;
  }
  if (!funDate) {
    return null;
  }
  let date = funDate?.toString() || "";
  let formattedDate =
    date.length >= 7
      ? date.slice(6, 8) + "/" + date.slice(4, 6) + "/" + date.slice(0, 4)
      : date.slice(4, 6) + "/" + date.slice(0, 4);

  return formattedDate;
};

const datedisplay = (date, initial) => {
  let trdate = date.toString();
  let dispDate = "";
  let dataDate = null;

  if (trdate.length === 4) {
    const mm = trdate.slice(0, 2);
    const yy = trdate.slice(2, 4);

    if (Number(mm) > 12) {
      return false;
    } else {
      let lastDate = new Date("20" + yy, mm, 0).getDate();

      if (initial) {
        lastDate = "01";
      }

      dispDate = lastDate.toString() + "/" + mm + "/" + "20" + yy;
      dataDate = "20" + yy.toString() + mm.toString() + lastDate.toString();
    }
  } else if (trdate.length === 6) {
    const dd = trdate.slice(0, 2);
    const mm = trdate.slice(2, 4);
    const yy = trdate.slice(4, 6);

    if (Number(mm) > 12) {
      if (Number(dd) > 12) {
        return false;
      } else {
        let lastDate = new Date("20" + yy, mm, 0).getDate();

        if (initial) {
          lastDate = "01";
        }

        dispDate = lastDate.toString() + "/" + dd + "/" + mm + yy;
        dataDate =
          mm.toString() + yy.toString() + dd.toString() + lastDate.toString();
      }
    } else {
      dispDate = dd.toString() + "/" + mm + "/" + "20" + yy;
      dataDate = "20" + yy.toString() + mm.toString() + dd.toString();
    }
  } else if (trdate.length === 8) {
    const dd = trdate.slice(0, 2);
    const mm = trdate.slice(2, 4);
    const yy = trdate.slice(4, 8);

    if (Number(mm) > 12) {
      return false;
    } else {
      dispDate = dd.toString() + "/" + mm + "/" + yy;
      dataDate = yy.toString() + mm.toString() + dd.toString();
    }
  } else if (trdate.length === 10) {
    const dd = trdate.slice(0, 2);
    const mm = trdate.slice(3, 5);
    const yy = trdate.slice(6, 10);

    if (Number(mm) > 12) {
      return false;
    } else {
      dispDate = dd.toString() + "/" + mm + "/" + yy;
      dataDate = yy.toString() + mm.toString() + dd.toString();
    }
  }

  return { dispDate: dispDate, dataDate: dataDate };
};

//This is function used for creating date string if date is valid only
function createDateString(userEnteredDateString, initial) {
  switch (userEnteredDateString.length) {
    case 4: {
      let dateString =
        initial == false
          ? getLastDateOfMonth(
              userEnteredDateString.substring(0, 2),
              "20" + userEnteredDateString.substring(2, 4)
            ).toString() +
            "/" +
            userEnteredDateString.substring(0, 2) +
            "/20" +
            userEnteredDateString.substring(2, 4)
          : "01/" +
            userEnteredDateString.substring(0, 2) +
            "/20" +
            userEnteredDateString.substring(2, 4);
      return dateString;
    }
    case 5: {
      if (
        userEnteredDateString.includes("/") ||
        userEnteredDateString.includes("-")
      ) {
        let dateString =
          initial == false
            ? getLastDateOfMonth(
                userEnteredDateString.substring(0, 2),
                "20" + userEnteredDateString.substring(3, 5)
              ).toString() +
              "/" +
              userEnteredDateString.substring(0, 2) +
              "/20" +
              userEnteredDateString.substring(3, 5)
            : "01/" +
              userEnteredDateString.substring(0, 2) +
              "/20" +
              userEnteredDateString.substring(3, 5);
        return dateString;
      } else {
        return "0";
      }
    }
    case 6: {
      if (userEnteredDateString.substring(2, 4) <= 12) {
        let dateString =
          userEnteredDateString.substring(0, 2) +
          "/" +
          userEnteredDateString.substring(2, 4) +
          "/20" +
          userEnteredDateString.substring(4, 6);

        return dateString;
      } else if (userEnteredDateString.substring(0, 2) <= 12) {
        let dateString =
          initial == false
            ? getLastDateOfMonth(
                userEnteredDateString.substring(0, 2),
                "20" + userEnteredDateString.substring(2, 4)
              ).toString() +
              "/" +
              userEnteredDateString.substring(0, 2) +
              "/" +
              userEnteredDateString.substring(2, 6)
            : "01" +
              "/" +
              userEnteredDateString.substring(0, 2) +
              "/" +
              userEnteredDateString.substring(2, 6);

        return dateString;
      }
    }

    case 7: {
      if (
        userEnteredDateString.includes("/") ||
        userEnteredDateString.includes("-")
      ) {
        let dateString =
          "01/" +
          userEnteredDateString.substring(0, 2) +
          "/" +
          userEnteredDateString.substring(3, 7);
        return dateString;
      } else {
        let dateString =
          "01/" +
          userEnteredDateString.substring(0, 2) +
          "/" +
          userEnteredDateString.substring(2, 6);
        return dateString;
      }
    }

    case 8: {
      if (
        userEnteredDateString.includes("/") ||
        userEnteredDateString.includes("-")
      ) {
        let dateString =
          userEnteredDateString.substring(0, 2) +
          "/" +
          userEnteredDateString.substring(3, 5) +
          "/" +
          "20" +
          userEnteredDateString.substring(6, 8);
        return dateString;
      } else {
        let dateString =
          userEnteredDateString.substring(0, 2) +
          "/" +
          userEnteredDateString.substring(2, 4) +
          "/" +
          userEnteredDateString.substring(4, 8);

        return dateString;
      }
    }
    case 10: {
      let dateString = userEnteredDateString.replaceAll("-", "/");

      return dateString;
    }

    default:
      return "0";
  }
}

// here function for returnig last date of the month
function getLastDateOfMonth(month, year) {
  // Create a new Date object with the given year and month (month is zero-based)
  const date = new Date(year, month, 0);

  // Move to the next month
  date.setMonth(date.getMonth() + 1);

  // Move to the previous day of the next month (which is the last day of the given month)
  date.setDate(date.getDate() - 1);

  // Return the last date of the month
  return date.getDate() + 1;
}

function SaveDatePicker({
  width,
  apiKey,
  defaultValue = getToday(),
  className,
  id,
  placeholder,
  initial,
  dateLessthan,
  dateGreaterthan,
  displayLabel,
  prevDate,
  renderer,
  postAlertMsg,
  returnValue,
  disabled,
  isrequired,pageIndex
}) {
  const [displayCalender, setDisplayCalender] = useState(false);
  const tabStore = useContext(TabStore);
  const auth = useContext(AuthStore);
  const useChangedRows = useSaveChangedRows();
  const [value, setValue] = useState(0);
  const [dateValue, setDateValue] = useState(null);
  const [calenderDate, setCalenderDate] = useState(value);
  const [hovered, setHovered] = useState(false); //for hover popover of i
  const [invalidDate, setInvalidDate] = useState(false); //Check if date is valid or not

  const handleEscapeKey = (event) => {
    if (event.key === "Escape") {
      setDisplayCalender(false);
    }
  };

  useEffect(() => {
    // Add event listener for the 'keydown' event
    document.addEventListener("keydown", handleEscapeKey);

    // Clean up the event listener on component unmount
    return () => {
      document.removeEventListener("keydown", handleEscapeKey);
    };
  }, []);

  const hoverContent = (
    <div>
      Input can be in the format of
      <br /> DDMMYY, MMYY, DDMMYYYY, MMYYYY
    </div>
  );

  const handleHoverChange = (open) => {
    setHovered(open);
  };

  useEffect(() => {
    function isDateFormatDDMMYYYY(dateString) {
      // Assuming the date format is "dd/mm/yyyy"
      const regex = /^\d{2}\/\d{2}\/\d{4}$/;
      return regex.test(dateString);
    }

    if (useChangedRows.getValue(apiKey)) {
      const trDate = useChangedRows.getValue(apiKey,pageIndex);

      if (!isDateFormatDDMMYYYY(trDate)) {
        // If the date is not in "dd/mm/yyyy" format, format it
        const formattedDate = dateFormatter(trDate);
        setDateValue(formattedDate);
      } else {
        // Date is already in "dd/mm/yyyy" format
        setDateValue(trDate);
      }

      setInvalidDate(false);
    } else {
      if (defaultValue != null || defaultValue != undefined) {
        setDateValue(dateFormatter(defaultValue));
        useChangedRows.updateValue(apiKey, defaultValue);
      } else {
        setDateValue(dateFormatter(auth?.state?.workdate));
      }
    }
  }, [defaultValue]);

  useEffect(() => {
    if (returnValue) {
      returnValue(defaultValue);
    }
  }, []);

  const validateDate = (dateString, dateGreaterthan, dateLessthan) => {
    const regex =
      /^(0?[1-9]|[12][0-9]|3[01])[\/\-](0?[1-9]|1[0-2])[\/\-]\d{4}$/;

    if (!regex.test(dateString)) {
      return false;
    }

    const [day, month, year] = dateString.split(/[\/\-]/);
    const dateObj = new Date(`${month}/${day}/${year}`);

    if (
      dateObj.getFullYear() != year ||
      dateObj.getMonth() + 1 != month ||
      dateObj.getDate() != day
    ) {
      return false;
    }

    if (Number(month) > 12 || Number(day) > 31) {
      return false;
    } else if (
      (Number(month) == 4 ||
        Number(month) == 6 ||
        Number(month) == 9 ||
        Number(month) == 11) &&
      Number(day) > 30
    ) {
      return false;
    } else if (Number(month) == 2 && Number(day) > 29) {
      return false;
    }

    if (dateGreaterthan) {
      dateGreaterthan = dateGreaterthan.toString(); // Convert to string
      const greaterDateObj = new Date(
        `${dateGreaterthan.substring(4, 6)}/${dateGreaterthan.substring(
          6,
          8
        )}/${dateGreaterthan.substring(0, 4)}`
      );
      if (dateObj < greaterDateObj) {
        //alert("Date should be greater than " + dateFormatter(dateGreaterthan));
        return false;
      }
    }

    if (dateLessthan) {
      dateLessthan = dateLessthan.toString(); // Convert to string
      const lessDateObj = new Date(
        `${dateLessthan.substring(4, 6)}/${dateLessthan.substring(
          6,
          8
        )}/${dateLessthan.substring(0, 4)}`
      );
      if (dateObj > lessDateObj) {
        //alert("Date should be less than " + dateFormatter(dateLessthan));
        return false;
      }
    }

    return true;
  };

  const onChange = (e) => {
    const inputValue = e.target.value;
    // Use regular expression to check if the input is a number rajendra
    const numberPattern = /^[0-9/]*$/;
    let valid = numberPattern.test(inputValue);
    if (valid) {
      setDateValue(inputValue);
      // returnValue(inputValue)
    } else {
      alert("Invalid input Format");
      e.target.blur(); // Remove focus from the input field
      // e.target.focus();
    }
  };
  const [isFocused, setIsFocused] = useState(false);
  const handleFocus = () => {
    // This function is called when the input element receives focus.
    setIsFocused(true);
  };

  const handleBlur = () => {
    // This function is called when the input element loses focus.
    setIsFocused(false);
  };

  return (
    <div style={{ position: "relative", ...mainFrame }}>
      {/* <div style={{ position: 'relative' }}> */}
      <label
        htmlFor={id}
        className={`label ${isFocused || dateValue ? "focused" : "unfocused"}`}
        style={{
          whiteSpace: "nowrap",
          wordWrap: "unset",
          position: "absolute",
          top: isFocused || dateValue ? "-19px" : "50%",
          left: "8px",
          //backgroundColor: isFocused || dateValue ? 'white' : "transparent",
          padding: "0 4px",
          fontSize: isFocused || dateValue ? "12px" : "16px",
          //color: isFocused ? 'black' : '',
          pointerEvents: "none",
          transform:
            isFocused || dateValue ? "translateY(0)" : "translateY(-50%)",
          transition: "top 0.3s, transform 0.3s, font-size 0.3s, color 0.3s",
        }}
      >
        {isrequired ? (
          <span
            className={`label ${
              isFocused || dateValue ? "focused" : "unfocused"
            }`}
          >
            {isFocused || dateValue ? displayLabel : placeholder}
            <span style={{ color: "red" }}>*</span>
          </span>
        ) : (
          <span
            className={`label ${
              isFocused || dateValue ? "focused" : "unfocused"
            }`}
          >
            {isFocused || dateValue ? displayLabel : placeholder}
          </span>
        )}
      </label>
      <div
        style={{
          display: "flex",
          height: "36px",
          gap: "4px",
          flexDirection: "column",
        }}
      >
        <div style={{ display: "flex", height: "36px", gap: "4px" }}>
          <input
            style={{
              border: "1px solid #cccccc",
              borderRadius: "8px",
              padding: "6px 10px",
              fontSize: "16px",
              width: width || "130px", //smk 23-2-2023 changed from 190px
              // maxWidth:"200px",
            }}
            id={id}
            className={className}
            type="text"
            maxLength={10} /// maximum length of input
            onFocus={(e) => {
              e.target.select();
              setInvalidDate(false);
            }}
            // defaultValue={datedisplay(value)?.dispDate}
            // placeholder={placeholder}
            // value={datedisplay(dateValue)?.dispDate ? datedisplay(dateValue)?.dispDate : dateValue}
            value={dateValue}
            onChange={onChange}
            disabled={disabled}
            onBlur={(e) => {
              if (!invalidDate) {
                let validatedDate = validateDate(
                  createDateString(e.target.value, initial),
                  dateGreaterthan,
                  dateLessthan
                );
                const requiredDateString = createDateString(
                  e.target.value,
                  initial
                );

                if (validatedDate) {
                  e.target.style.color = "black";
                  setInvalidDate(false);
                  if (initial) {
                    useChangedRows.updateValue(
                      apiKey,
                      datedisplay(requiredDateString, true).dataDate
                    );
                    if (returnValue) {
                      returnValue(
                        datedisplay(requiredDateString, true).dataDate
                      );
                    }
                    e.target.value = datedisplay(
                      requiredDateString,
                      true
                    ).dispDate;

                    setDateValue(
                      datedisplay(requiredDateString, true).dispDate
                    );
                  } else {
                    console.log(
                      "CHANGED VALUE IS",
                      datedisplay(requiredDateString, false).dataDate,
                      apiKey
                    );
                    useChangedRows.updateValue(
                      apiKey,
                      datedisplay(requiredDateString, false).dataDate
                    );
                    if (returnValue) {
                      returnValue(
                        datedisplay(requiredDateString, false).dataDate
                      );
                    }
                    e.target.value = datedisplay(
                      requiredDateString,
                      false
                    ).dispDate;
                    setDateValue(
                      datedisplay(requiredDateString, false).dispDate
                    );
                  }
                } else {
                  e.target.style.color = "red";
                  new Audio(errorSound).play();
                  //setDateValue(dateFormatter(useChangedRows.getValue(apiKey)));
                  setInvalidDate(true); // Set the flag for invalid date
                  // e.target.value = ""
                  alert(postAlertMsg);
                  useChangedRows.updateValue(apiKey, "");
                  // e.target.focus();
                }
                returnValue(
                  datedisplay(createDateString(e.target.value, initial), false)
                    .dataDate
                ); // Added this for returnValue to avoid receiving null after removing the date from the input box
              } else {
                setInvalidDate(false);
              }
            }}
            // onKeyDown={(e) => {
            //   if (e.keyCode === 13) {
            //     let validatedDate = validateDate(
            //       createDateString(e.target.value, initial),
            //       dateGreaterthan,
            //       dateLessthan
            //     );

            //     if (validatedDate) {
            //       e.target.style.color = "black";
            //       setInvalidDate(false);
            //       if (initial) {
            //         useChangedRows.updateValue(
            //           apiKey,
            //           datedisplay(e.target.value, true).dataDate
            //         );
            //         if (returnValue) {
            //           returnValue(datedisplay(e.target.value, true).dataDate);
            //         }
            //         e.target.value = datedisplay(e.target.value, true).dispDate;
            //         setDateValue(datedisplay(e.target.value, true).dispDate);
            //       } else {
            //         useChangedRows.updateValue(
            //           apiKey,
            //           datedisplay(e.target.value).dataDate
            //         );
            //         if (returnValue) {
            //           returnValue(datedisplay(e.target.value, true).dataDate);
            //         }
            //         e.target.value = datedisplay(e.target.value).dispDate;
            //         setDateValue(datedisplay(e.target.value).dispDate);
            //       }
            //     } else {
            //       e.target.style.color = "red";
            //       new Audio(errorSound).play();
            //       //setDateValue(dateFormatter(useChangedRows.getValue(apiKey)));
            //       setInvalidDate(true); // Set the flag for invalid date
            //       // e.target.value = ""
            //       if (postAlertMsg) {
            //         alert(postAlertMsg);
            //       }
            //       useChangedRows.updateValue(apiKey, "");
            //     }
            //   }
            // }}
          />
          <Button
            className={className}
            style={{
              justifyContent: "center",
              alignItems: "center",
              height: "36px",
            }}
            icon={<AiTwotoneCalendar />}
            disabled={disabled}
            onClick={() => setDisplayCalender(!displayCalender)}
          ></Button>
          {/* </div> */}
          {displayCalender ? (
            <div
              style={{
                width: "300px",
                border: "1px solid #f0f0f0",
                borderRadius: "8px",
                position: "absolute",
                zIndex: 9,
              }}
            >
              <Calendar
                fullscreen={false}
                onSelect={(value) => setCalenderDate(value.format("YYYYMMDD"))}
              />
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Button
                  onClick={() => {
                    setDisplayCalender(!displayCalender);
                    // document.getElementById(id).value = dateFormatter(calenderDate);
                    document.getElementById(id).focus();
                    setDateValue(dateFormatter(calenderDate));
                    useChangedRows.updateValue(apiKey, calenderDate);
                    if (returnValue) {
                      returnValue(calenderDate);
                    }
                  }}
                >
                  Select
                </Button>
                {/* Added this Button the Cancel */}
                <Button
                  onClick={() => {
                    setDisplayCalender(!displayCalender);
                  }}
                >
                  Cancel
                </Button>
              </div>
            </div>
          ) : null}
        </div>

        {invalidDate && (
          <p style={{ marginTop: "-8px", color: "red" }}>
            Enter date in suggested format
            <Popover
              style={{
                width: 500,
              }}
              content={hoverContent}
              trigger="hover"
              open={hovered}
              onOpenChange={handleHoverChange}
            >
              <button style={{ border: "none", background: "none" }} disabled>
                <FaInfoCircle />
              </button>
            </Popover>
          </p>
        )}
      </div>
    </div>
  );
}

export default SaveDatePicker;

// CSS

const mainFrame = {
  display: "flex",
  flexDirection: "column",
  gap: "8px",
  alignItems: "center",
  flexDirection: "column",
};

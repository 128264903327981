import { Button, Modal, notification } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { AuthStore } from "../../Auth/AuthStore";
import { apiCallwithBaseUrl } from "../../CommonControllers/ApiCalls";
import CallbackCheckbox from "../../CommonControllers/Components/CallbackComponents/CallbackCheckbox";
import CallbackDropdown from "../../CommonControllers/Components/CallbackComponents/CallbackDropdown";
import CallbackRadioBtn from "../../CommonControllers/Components/CallbackComponents/CallbackRadioBtn";
import CallbackTextInput from "../../CommonControllers/Components/CallbackComponents/CallbackTextInput";
import useExecuteApi from "../../CommonControllers/Hooks/useExecuteApi";
import { TabStore } from "../../TabsStructure/TabsStore";
import CallbackDateInput from "../../CommonControllers/Components/CallbackComponents/CallbackDateInput";
import { currentTabData } from "../../CommonControllers/Variable";
import { isArray } from "../../CommonControllers/AppFunction";
import CallbackFileInput from "../../CommonControllers/Components/CallbackComponents/CallbackFileInput";

export default function DynamicModalForm({
  filterData,
  columnsArray,
  dataObject,
  handleClose,
  isEdit,
  changes,
}) {
  const tabStore = useContext(TabStore);
  const auth = useContext(AuthStore);
  const [dataObj, setDataObj] = useState({});
 const [dropdownvalue,setDropdownvalue]=useState({})
  const useExecute = useExecuteApi();

  const [showTable, setShowTable] = useState(false);
  const [modalboolean, setMoadalbool] = useState(null);
  useEffect(() => {
    setShowTable(false);

    setTimeout(() => {
      setShowTable(true);
    }, 200);
  }, [changes]);

  useEffect(() => {
    validationFun(columnsArray);
  }, []);
  useEffect(() => {
    var data = tabStore.state.tabs[tabStore.state.tabIndex]?.addObject
      ? tabStore.state.tabs[tabStore.state.tabIndex]?.addObject
      : {};
    setDataObj(data);
  }, [tabStore.state.tabs[tabStore.state.tabIndex]?.addObject]);

  // Create Function to Filter fields based on mode (Add/Edit)
  const filteredFields = columnsArray.filter((item) => {
    console.log("filteredFields",item)
    if (isEdit) {
      return item.showeditmodal;
    } else {
      return item.showaddmodal;
    }
  });

  // Create Function to recieve the returnValue from the selected Value and save it to the Changed Rows

  const storeData = (e, apikey) => {
    // Create a Temporary Add Store in the TabStore
    var existingObject = tabStore.state.tabs[tabStore.state.tabIndex]?.addObject
      ? tabStore.state.tabs[tabStore.state.tabIndex]?.addObject
      : {};
    existingObject[apikey] = e;
    console.log("TDRTYCCU",existingObject)
    tabStore.dispatch({
      type: "UPDATE_DATA",
      fieldType: "addObject",
      text: existingObject,
    });


    var newObject = dropdownvalue
    dropdownvalue[apikey] = e
     setDropdownvalue(newObject)
    //  var obje = {dropdownvalue.apikey : e}
 
  };
  const enterPress = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      const form = e.target.form;
      const index = Array.prototype.indexOf.call(form, e.target);
      if (form.elements[index + 1]) {
        form.elements[index + 1].focus();
      } else {
        alert("End of Line");
      }
    }
  };

  const onCancel = () => {
    tabStore.dispatch({
      type: "UPDATE_DATA",
      fieldType: "addObject",
      text: null,
    });
    handleClose(false);
  };
  const removeDateformat = (data, col) => {
   
    let req = col.filter((el) => el.type == "date");
    
    let arr = {};

    

   for(let i=0;i<req?.length;i++){
    if (req[i]?.req == 1||req[i]?.req == 0) {
      let dateWithSlashes = data[req[i]?.dataIndex];

     
    if(dateWithSlashes!=0){
      const dateWithoutSlashes = dateWithSlashes?.split("/").reverse().join("");
     
      const reversedDateInteger = parseInt(dateWithoutSlashes, 10);
      arr = {
        ...arr,
         [req[i]?.dataIndex]: reversedDateInteger,
       };
    }else{
      arr = {
        ...arr,
         [req[i]?.dataIndex]: dateWithSlashes,
       };
    }
      
     
    
    } 
    
   }
   
    return {...data,...arr};
  };
  const onSave = async () => {
    // Fetch required data from the tab store and state
    const currentTab = tabStore.state.tabs[tabStore.state.tabIndex];
    const apiUrl = currentTab?.saveApiUrl;
    const apiData = currentTab?.apiVariables;
    const apiType = currentTab?.saveApiType ? currentTab.saveApiType : "POST";
    const apiBody = currentTab?.addObject;
  
    // Perform validations and remove date format
    const reqBody = removeDateformat(apiBody, columnsArray);
    const requiredArray = validationFun(columnsArray);
    const isFieldEmpty = setStatusFun(requiredArray, apiBody);
  
    // Check if apiBody exists and required fields are filled
    if (apiBody && isFieldEmpty) {
      const finalApiBody = {
        tenantrecno: auth.state.userdata.tenantrecno,
        domainrecno: auth.state.userdata.domainrecno,
        domainuserrecno: auth.state.userdata.recno,
        data: [{
          ...reqBody,
          ...filterData, // Merge filterData into reqBody
        }],
        surveytyperecno: auth.state.userdata.surveytyperecno,
        fastpath: currentTabData(tabStore)?.name,
      };
  
      try {
        // Make API call with the constructed body
        const response = await apiCallwithBaseUrl(apiType.toUpperCase(), apiUrl, finalApiBody);
  
        // Handle successful response
        if (response?.Success) {
          notification.success({
            message: 'Success',
            placement: 'top',
            description: response.Message,
            duration: 3,
            style: { background: '#a7efb6' },
          });
          handleClose(false);
          setDataObj([]);
          tabStore.dispatch({ type: 'UPDATE_DATA', fieldType: 'addObject', text: null });
          tabStore.dispatch({ type: 'UPDATE_DATA', fieldType: 'apiVariables', text: apiData });
          useExecute.execute();
        } else {
          // Handle failure response
          console.log("Error check", response)
          notification.error({
            message: 'Error',
            // placement: 'top',
            description: response?.Message ? response?.Message : response.Error,
            duration: 3,
            style: { background: '#efa7a7' },
          });
        }
      } catch (error) {
        // Handle exceptions during the API call
        const errorMessage = error?.response?.data?.Error || error.message || 'An unknown error occurred';
        notification.error({
          message: 'Error',
          // placement: 'top',
          description: errorMessage,
          duration: 3,
          style: { background: '#efa7a7' },
        });
      }
    } 
  };
  

  /// validation function for form
  const validationFun = (arr) => {
    let reqarr = [];
    for (let i = 0; i < arr.length; i++) {
      let item = arr[i];
      if (item.req == 1) {
        reqarr.push(item.columnname);
      }
    }
    return reqarr;
  };

  // const setStatusFun = (requiredarray, apiBody) => {
  //   const yValues = Object.values(apiBody); // cache object values
    

  //   for (const [key, value] of Object.entries(apiBody)) {
  //     if (key == "pan" && value?.length < 10) {
  //       notification.error({
  //         message: `
  //         Error `,
  //         description: `Invalid PAN No.`,
  //         duration: 1,
  //         style: { background: "#efa7a7" },
  //       });
  //       return false;
  //     }

  //     if (key == "gstn" && value?.length < 15) {
  //       notification.error({
  //         message: `
  //         Error `,
  //         description: `Invalid GST No.`,
  //         duration: 1,
  //         style: { background: "#efa7a7" },
  //       });
  //       return false;
  //     }

  //     if (key == "phone" && value?.length < 10) {
  //       notification.error({
  //         message: `
  //         Error `,
  //         description: `Invalid Phone No.`,
  //         duration: 1,
  //         style: { background: "#efa7a7" },
  //       });
  //       return false;
  //     }

  //     if (key == "pincode" && value?.length < 6) {
  //       notification.error({
  //         message: `
  //         Error `,
  //         description: `Invalid PIN Code`,
  //         duration: 1,
  //         style: { background: "#efa7a7" },
  //       });
  //       return false;
  //     }
  //   }

  //   for (let i = 0; i < requiredarray?.length; i++) {
  //     const key = requiredarray[i];
  //     const value = apiBody[key];
  //     console.log("RDYTCUJ",requiredarray,apiBody)
  //     if (key == "mobile" && value?.length < 10) {
  //       // alert("Invalid mobile number");
  //       notification.error({
  //         message: `
  //         Error `,
  //         description: `Invalid Mobile No.`,
  //         duration: 1,
  //         style: { background: "#efa7a7" },
  //       });
  //       return false;
  //     }

  //     if (value === null || value === "" || value?.length == 0||value==undefined) {
  //       // alert("Please filled required field");
  //       notification.error({
  //         message: `
  //         Error `,
  //         description: `Please filled required field`,
  //         duration: 1,
  //         style: { background: "#efa7a7" },
  //       });

  //       return false;
  //     }
  //   }

  //   return true;
  // };
  //// function to write the default value in key value pair
 
  const setStatusFun = (requiredArray, apiBody) => {
    const showErrorNotification = (message) => {
      notification.error({
        message: 'Error',
        description: message,
        duration: 1,
        style: { background: '#efa7a7' },
      });
    };
  
    if (!requiredArray || !apiBody) {
      showErrorNotification('Invalid input data.');
      return false;
    }
  
    for (let i = 0; i < requiredArray?.length; i++) {
      const key = requiredArray[i];
      const value = apiBody[key];
  
      if (key === "mobile" && (!value || value.length < 10)) {
        showErrorNotification('Invalid Mobile No.');
        return false;
      }
  
      if (key === "gstn" && (!value || value.length < 15)) {
        showErrorNotification('Invalid GST No.');
        return false;
      }
  
      if (key === "pan" && (!value || value.length < 10)) {
        showErrorNotification('Invalid PAN No.');
        return false;
      }
  
      if (key === "pincode" && (!value || value.length < 6)) {
        showErrorNotification('Invalid PIN Code');
        return false;
      }
  
      if (!value || value.length === 0) {
        showErrorNotification(`Please fill the required field for key: ${key}`);
        return false;
      }
    }
  
    // Additional conditions for specific keys
    // for (const [key, value] of Object.entries(apiBody)) {
    //   if (value === null || value === undefined) {
    //     showErrorNotification(`Invalid value for key: ${key}`);
    //     return false;
    //   }
    //   // Add more conditions for other specific keys if needed
    // }
  
    return true;
  };
  
  
  useEffect(() => {
    // if(tabStore.state.tabs[tabStore.state.tabIndex]?.name=="MS900"){
    let x = columnsArray.filter((it) => it.type == "boolean"||it.columnname=="tablerecno"||it.type == "date");
   
    for (let i = 0; i < x?.length; i++) {
    
  if(x[i].type=="date"){
  
    //storeData(removeDateformatforDynamicmodal(x[i]?.defaultValue),x[i].apiKey)
  }else{
    storeData(x[i].defaultValue, x[i].apiKey);
  }
     
    }
    // }
  }, []);
  

  return (
    <div style={{ ...mainFrame }}>
      <div
        style={{
          width: "auto",
          // height: '150px',
          overflowY: "auto", // Enable vertical scrolling

          minHeight: "100px",
          maxHeight: "500px",
          padding: "10px",
          borderTop: "1px solid grey",
        }}
      >
        {showTable ? (
          <form style={{ ...modalGrid }}>
            {filteredFields.map((item, index) => {
              var stylings = item?.span ? item.span : "3";
              stylings = "span " + stylings;
              //console.log("item",item)
              return ((item.showeditmodal) 
                 ? (
                <div
                  // style={{
                  //   gridColumn: stylings,
                  // }}
                  style={{
                    display: "grid",
                    gridTemplateColumns: "repeat(3)",
                    margin: "10px"
                  }}
                  onKeyDown={enterPress}
                >
                  {item?.type == "int" ? (
                    <CallbackTextInput
                      // placeholder={"Enter " + item?.title}
                      placeholder={item?.placeholder}
                      change
                      alignment="column"
                      type={"number"}
                      isrequired={item?.req}
                      displayLabel={item?.title}
                      minlength={item?.minimumlength}
                      maxlength={item?.columnlength}
                      minvalue={item?.minimumvalue}
                      maxvalue={item?.maximumvalue}
                      returnValue={(e) => {
                        storeData(e, item?.apiKey);
                      }}
                      defaultValue={
                        dataObj.hasOwnProperty(item?.apiKey)
                          ? dataObj[item?.apiKey]
                          : ""
                      }
                      title={item.type}
                      width={item?.width}
                      disabled={item.isedit}
                    />
                  ) : item.type == "image" ? (
                    <>
                      <CallbackFileInput
                        id={item?.apiKey + index}
                        alignment="column"
                        defaultValue={
                          dataObj.hasOwnProperty(item?.apiKey)
                            ? dataObj[item?.apiKey]
                            : ""
                        }
                        isrequired={item?.req}
                        displayLabel={item?.title}
                        placeholder={item?.placeholder}
                        width={`${item?.width}px`}
                        apiKey={item?.apiKey}
                        returnValue={(e) => {
                       
                          // setterFunction(e, item);
                          storeData(e, item?.apiKey);
                        }}
                      />
                    </>
                  ) : item.type == "date" ? (
                    <>
                      <CallbackDateInput
                        id={item?.apiKey + index}
                        alignment="column"
                        defaultValue={
                          dataObj.hasOwnProperty(item?.apiKey)
                            ? dataObj[item?.apiKey]
                            : ""
                        }
                        isrequired={item?.req}
                        displayLabel={item?.title}
                        placeholder={item?.placeholder}
                        width={`${item?.width}px`}
                        apiKey={item?.apiKey}
                        returnValue={(e) => {
                          
                          // setterFunction(e, item);
                          storeData(e, item?.apiKey);
                        }}
                      />
                    </>
                  ) : item.type == "string" &&
                    item.title != "PAN" &&
                    item.title != "GST No." &&
                    item.title != "Mobile" ? (
                    <CallbackTextInput
                      // placeholder={"Enter " + item?.title}
                      placeholder={item?.placeholder}
                      change
                      alignment="column"
                      type={"text"}
                      isrequired={item?.req}
                      displayLabel={item?.title}
                      minlength={item?.minimumlength}
                      maxlength={item?.columnlength}
                      minvalue={item?.minimumvalue}
                      maxvalue={item?.maximumvalue}
                      returnValue={(e) => {
                        storeData(e, item?.apiKey);
                      }}
                      defaultValue={
                        dataObj.hasOwnProperty(item?.apiKey)
                          ? dataObj[item?.apiKey]
                          : ""
                      }
                      regex={item.regex}
                      title={item.title}
                      disabled={item.isedit}

                      width={item?.width}
                    />
                  ) : item.title == "PAN" ? (
                    <CallbackTextInput
                      // placeholder={"Enter " + item?.title}
                      placeholder={item?.placeholder}
                      change
                      alignment="column"
                      // type={item.dataIndex == "mobile" ? "number" : "text"}
                      type={"text"}
                      required={item?.req}
                      displayLabel={
                        item?.req === 1 ? item?.title + "*" : item?.title
                      }
                      minlength={item?.minimumlength}
                      maxlength={item?.columnlength}
                      minvalue={item?.minimumvalue}
                      maxvalue={item?.maximumvalue}
                      returnValue={(e) => {
                        storeData(e, item?.apiKey);
                      }}
                      defaultValue={
                        dataObj.hasOwnProperty(item?.apiKey)
                          ? dataObj[item?.apiKey]
                          : ""
                      }
                      regex={item.regex}
                      title={item.title}
                      disabled={item.isedit}
                      width={item?.width}
                    />
                  ) : item.title == "GST No." ? (
                    <CallbackTextInput
                      // placeholder={"Enter " + item?.title}
                      placeholder={item?.placeholder}
                      change
                      alignment="column"
                      // type={item.dataIndex == "mobile" ? "number" : "text"}
                      type={"text"}
                      isrequired={item?.req}
                      displayLabel={item?.title}
                      minlength={item?.minimumlength}
                      maxlength={item?.columnlength}
                      minvalue={item?.minimumvalue}
                      maxvalue={item?.maximumvalue}
                      returnValue={(e) => {
                        storeData(e, item?.apiKey);
                      }}
                      defaultValue={
                        dataObj.hasOwnProperty(item?.apiKey)
                          ? dataObj[item?.apiKey]
                          : ""
                      }
                      regex={item.regex}
                      title={item.title}
                      disabled={item.isedit}
                      width={item?.width}
                    />
                  ) : item.title == "Mobile" ? (
                    <CallbackTextInput
                      // placeholder={"Enter " + item?.title}
                      placeholder={item?.placeholder}
                      change
                      alignment="column"
                      // type={item.dataIndex == "mobile" ? "number" : "text"}
                      type={"number"}
                      isrequired={item?.req}
                      displayLabel={item?.title}
                      minlength={item?.minimumlength}
                      maxlength={item?.columnlength}
                      minvalue={item?.minimumvalue}
                      maxvalue={item?.maximumvalue}
                      returnValue={(e) => {
                        storeData(e, item?.apiKey);
                      }}
                      defaultValue={
                        dataObj.hasOwnProperty(item?.apiKey)
                          ? dataObj[item?.apiKey]
                          : ""
                      }
                      regex={item.regex}
                      title={item.title}
                      disabled={item.isedit}
                      width={item?.width}
                    />
                  ) : item.type == "boolean" ? (
                    <CallbackCheckbox
                      editable
                      alignment="column"
                      returnValue={(e) => {
                       
                        storeData(e, item?.apiKey);
                      }}
                      isrequired={item?.req}
                      displayLabel={item?.title}
                      defaultValue={
                        dataObj.hasOwnProperty(item?.apiKey)
                          ? dataObj[item?.apiKey]
                          : item?.defaultValue
                      } /// date 29 may
                      // defaultValue={item?.defaultValue ? true :false}
                    />
                  ) : item.type == "decimal" ? (
                    <CallbackTextInput
                      // placeholder={"Enter " + item?.title}
                      placeholder={item?.placeholder}
                      change
                      alignment="column"
                      // type={item.dataIndex == "mobile" ? "number" : "text"}
                      //type={"number"}//comma formattted value not displaying issue solved
                      isrequired={item?.req}
                      displayLabel={item?.title}
                      minlength={item?.minimumlength}
                      maxlength={item?.columnlength}
                      minvalue={item?.minimumvalue}
                      maxvalue={item?.maximumvalue}
                      returnValue={(e) => {
                        storeData(e, item?.apiKey);
                      }}
                      defaultValue={
                        dataObj.hasOwnProperty(item?.apiKey)
                          ? dataObj[item?.apiKey]
                          : ""
                      }
                      regex={item.regex}
                      title={item.type}
                      disabled={item.isedit}
                      width={item?.width}
                    /> /// date 29 may
                  ) : // defaultValue={item?.defaultValue ? true :false}

                  item.type == "radio" ? (
                    <CallbackRadioBtn />
                  ) : item.type == "dropdown" ? (
                    <CallbackDropdown
                      // placeholder={"Select " + item?.title}
                      apikey={item?.apiKey}
                      apiurl={item?.apiurl}
                      disabled={item?.isedit}
                      alignment="column"
                      isrequired={item?.req}
                      displayLabel={item?.title}
                      placeholder={item?.placeholder}
                      selectionValue={item?.selectionvalue}
                      selectionLabel={item?.selectionlabel}
                      body={item?.payload ? { [item.payload]: dropdownvalue[item?.payload] } : {}}

                      returnValue={(e) => {
                        
                        if (isArray(e)) {
                          var data = "";
                          e.forEach((element) => {
                            data = data + " , " + element[item?.selectionvalue];
                          });
                          data = data.slice(2);
                          storeData(data, item?.apiKey);
                        } else {
                          storeData(e[item?.selectionvalue], item?.apiKey);
                        }
                        console.log("Return Value",dropdownvalue[item?.payload] );
                      }}
                      defaultSelected={
                        dataObj.hasOwnProperty(item?.dataIndex)
                          ? dataObj[item?.dataIndex]
                          : null
                      }
                      multi={item?.isMulti}
                      width={`${item?.width}px`}
                    />
                  
                  ) : null}
                </div>
              ) : item.showaddmodal ? (
                <div
                  // style={{
                  //   gridColumn: stylings,
                  // }}
                  style={{
                    display: "grid",
                    gridTemplateColumns: "repeat(3)",
                    margin: "10px"
                  }}
                  onKeyDown={enterPress}
                >
                  {item?.type == "int" ? (
                    <CallbackTextInput
                      // placeholder={"Enter " + item?.title}
                      placeholder={item?.placeholder}
                      change
                      alignment="column"
                      type={"number"}
                      isrequired={item?.req}
                      displayLabel={item?.title}
                      minlength={item?.minimumlength}
                      maxlength={item?.columnlength}
                      minvalue={item?.minimumvalue}
                      maxvalue={item?.maximumvalue}
                      returnValue={(e) => {
                        storeData(e, item?.apiKey);
                      }}
                      defaultValue={
                        dataObj.hasOwnProperty(item?.apiKey)
                          ? dataObj[item?.apiKey]
                          : ""
                      }
                      title={item.type}
                      width={item?.width}
                      disabled={item.isadd}
                    />
                  ) : item.type == "image" ? (
                    <>
                      <CallbackFileInput
                        id={item?.apiKey + index}
                        alignment="column"
                        defaultValue={
                          dataObj.hasOwnProperty(item?.apiKey)
                            ? dataObj[item?.apiKey]
                            : ""
                        }
                        isrequired={item?.req}
                        displayLabel={item?.title}
                        placeholder={item?.placeholder}
                        width={`${item?.width}px`}
                        apiKey={item?.apiKey}
                        returnValue={(e) => {
                       
                          // setterFunction(e, item);
                          storeData(e, item?.apiKey);
                        }}
                        disabled={item.isadd}
                      />
                    </>
                  ) : item.type == "date" ? (
                    <>
                      <CallbackDateInput
                        id={item?.apiKey + index}
                        alignment="column"
                        defaultValue={
                          dataObj.hasOwnProperty(item?.apiKey)
                            ? dataObj[item?.apiKey]
                            : ""
                        }
                        isrequired={item?.req}
                        displayLabel={item?.title}
                        placeholder={item?.placeholder}
                        width={`${item?.width}px`}
                        apiKey={item?.apiKey}
                        returnValue={(e) => {
                          
                          // setterFunction(e, item);
                          storeData(e, item?.apiKey);
                        }}
                        disabled={item.isadd}
                      />
                    </>
                  ) : item.type == "string" &&
                    item.title != "PAN" &&
                    item.title != "GST No." &&
                    item.title != "Mobile" ? (
                    <CallbackTextInput
                      // placeholder={"Enter " + item?.title}
                      placeholder={item?.placeholder}
                      change
                      alignment="column"
                      type={"text"}
                      isrequired={item?.req}
                      displayLabel={item?.title}
                      minlength={item?.minimumlength}
                      maxlength={item?.columnlength}
                      minvalue={item?.minimumvalue}
                      maxvalue={item?.maximumvalue}
                      returnValue={(e) => {
                        storeData(e, item?.apiKey);
                      }}
                      defaultValue={
                        dataObj.hasOwnProperty(item?.apiKey)
                          ? dataObj[item?.apiKey]
                          : ""
                      }
                      regex={item.regex}
                      title={item.title}
                      disabled={item.isadd}

                      width={item?.width}
                      
                    />
                  ) : item.title == "PAN" ? (
                    <CallbackTextInput
                      // placeholder={"Enter " + item?.title}
                      placeholder={item?.placeholder}
                      change
                      alignment="column"
                      // type={item.dataIndex == "mobile" ? "number" : "text"}
                      type={"text"}
                      required={item?.req}
                      displayLabel={
                        item?.req === 1 ? item?.title + "*" : item?.title
                      }
                      minlength={item?.minimumlength}
                      maxlength={item?.columnlength}
                      minvalue={item?.minimumvalue}
                      maxvalue={item?.maximumvalue}
                      returnValue={(e) => {
                        storeData(e, item?.apiKey);
                      }}
                      defaultValue={
                        dataObj.hasOwnProperty(item?.apiKey)
                          ? dataObj[item?.apiKey]
                          : ""
                      }
                      regex={item.regex}
                      title={item.title}
                      disabled={item.isadd}
                      width={item?.width}
                    />
                  ) : item.title == "GST No." ? (
                    <CallbackTextInput
                      // placeholder={"Enter " + item?.title}
                      placeholder={item?.placeholder}
                      change
                      alignment="column"
                      // type={item.dataIndex == "mobile" ? "number" : "text"}
                      type={"text"}
                      isrequired={item?.req}
                      displayLabel={item?.title}
                      minlength={item?.minimumlength}
                      maxlength={item?.columnlength}
                      minvalue={item?.minimumvalue}
                      maxvalue={item?.maximumvalue}
                      returnValue={(e) => {
                        storeData(e, item?.apiKey);
                      }}
                      defaultValue={
                        dataObj.hasOwnProperty(item?.apiKey)
                          ? dataObj[item?.apiKey]
                          : ""
                      }
                      regex={item.regex}
                      title={item.title}
                      disabled={item.isadd}
                      width={item?.width}
                    />
                  ) : item.title == "Mobile" ? (
                    <CallbackTextInput
                    placeholder={item?.placeholder}
                    // placeholder={"Enter " + item?.title}
                      change
                      alignment="column"
                      // type={item.dataIndex == "mobile" ? "number" : "text"}
                      type={"number"}
                      isrequired={item?.req}
                      displayLabel={item?.title}
                      minlength={item?.minimumlength}
                      maxlength={item?.columnlength}
                      minvalue={item?.minimumvalue}
                      maxvalue={item?.maximumvalue}
                      returnValue={(e) => {
                        storeData(e, item?.apiKey);
                      }}
                      defaultValue={
                        dataObj.hasOwnProperty(item?.apiKey)
                          ? dataObj[item?.apiKey]
                          : ""
                      }
                      regex={item.regex}
                      title={item.title}
                      disabled={item.isadd}
                      width={item?.width}
                    />
                  ) : item.type == "boolean" ? (
                    <CallbackCheckbox
                      editable
                      alignment="column"
                      returnValue={(e) => {
                       
                        storeData(e, item?.apiKey);
                      }}
                      isrequired={item?.req}
                      displayLabel={item?.title}
                      defaultValue={
                        dataObj.hasOwnProperty(item?.apiKey)
                          ? dataObj[item?.apiKey]
                          : item?.defaultValue
                      } /// date 29 may
                      // defaultValue={item?.defaultValue ? true :false}
                      disabled={item.isadd}

                    />
                  ) : item.type == "decimal" ? (
                    <CallbackTextInput
                      // placeholder={"Enter " + item?.title}
                      placeholder={item?.placeholder}
                      change
                      alignment="column"
                      // type={item.dataIndex == "mobile" ? "number" : "text"}
                      type={"number"}
                      isrequired={item?.req}
                      displayLabel={item?.title}
                      minlength={item?.minimumlength}
                      maxlength={item?.columnlength}
                      minvalue={item?.minimumvalue}
                      maxvalue={item?.maximumvalue}
                      returnValue={(e) => {
                        storeData(e, item?.apiKey);
                      }}
                      defaultValue={
                        dataObj.hasOwnProperty(item?.apiKey)
                          ? dataObj[item?.apiKey]
                          : ""
                      }
                      regex={item.regex}
                      title={item.type}
                      disabled={item.isadd}
                      width={item?.width}
                    /> /// date 29 may
                  ) : // defaultValue={item?.defaultValue ? true :false}

                  item.type == "radio" ? (
                    <CallbackRadioBtn />
                  ) : item.type == "dropdown" ? (
                    <CallbackDropdown
                      // placeholder={"Select " + item?.title}
                      apiurl={item?.apiurl}
                      disabled={item?.isadd}
                      alignment="column"
                      isrequired={item?.req}
                      displayLabel={item?.title}
                      placeholder={item?.placeholder}
                      selectionValue={item?.selectionvalue}
                      selectionLabel={item?.selectionlabel}
                      body={item?.payload ? { [item.payload]: dropdownvalue[item?.payload] } : {}}

                      returnValue={(e) => {
                        
                        if (isArray(e)) {
                          var data = "";
                          e.forEach((element) => {
                            data = data + " , " + element[item?.selectionvalue];
                          });
                          data = data.slice(2);
                          storeData(data, item?.apiKey);
                        } else {
                          storeData(e[item?.selectionvalue], item?.apiKey);
                        }
                        console.log("Return Value",dropdownvalue[item?.payload] );
                      }}
                      defaultSelected={
                        dataObj.hasOwnProperty(item?.dataIndex)
                          ? dataObj[item?.dataIndex]
                          : null
                      }
                      multi={item?.isMulti}
                      width={`${item?.width}px`}
                    />
                  ) : null}
                </div>
              ):null)
            })}
          </form>
        ) : null}
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-evenly",
          //marginTop: "-30px",
          position: "-webkit-sticky",
        }}
      >
        <Button
          style={{ width: "150px" }}
          type="primary"
          danger
          onClick={() => {
            onCancel();
          }}
        >
          Cancel
        </Button>
        <Button
        className="save-button"
          style={{ width: "150px" }}
          type="primary"
          onClick={() => {
            onSave();
          }}
        >
          Save
        </Button>
        {/* <Button
          style={{ width: "150px" }}
          type="primary"
          onClick={() => {
            //onSave();
          }}
        >
          Reset
        </Button> */}
      </div>
    </div>
  );
}

// CSS
const mainFrame = {
  display: "flex",
  gap: "16px",
  flexDirection: "column",
  margin: "24px 0",
};

const modalGrid = {
  display: "grid",
  gridTemplateColumns: "repeat(3, 1fr)",
  gap: "24px",
  marginTop:"10px"
};

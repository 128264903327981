import { Button, Popconfirm } from "antd";
import { AiFillDelete } from "react-icons/ai";
import { removeByAttribute } from "../../CommonControllers/AppFunction";
import CallbackDateInput from "../../CommonControllers/Components/CallbackComponents/CallbackDateInput";
import SaveDatePicker from "../../CommonControllers/SaveComponents/SaveDatePicker";

export const piColumns = (
  igstappl,
  setRowData,
  rowData,
  setOpenItem,
  setScandata,
  workdate,
  pageDetails
) => {
  if (igstappl) {
    return [
      {
        title: "",
        dataIndex: "active",
        key: "active",
        width: "50px",
        render: (_, text) => {
          return (
            <Popconfirm
              title="Do you want to Cancel the changes?"
              onConfirm={(e) => {
                handleRemove(text, setRowData, rowData);
              }}
            >
              <Button icon={<AiFillDelete />}></Button>
            </Popconfirm>
          );
        },
      },
      {
        width: 120,
        title: "Item Description",
        dataIndex: "itemdescn",
        key: "itemdescn",
      },
      {
        title: "PO Qty",
        dataIndex: "poqty",
        key: "poqty",
        width: 50,
        align: "right", // nohit-388 set Align for column
      },
      {
        hidden: !pageDetails?.wantpackingextra ? true : false,
        title: "Box Qty",
        width: 70,
        dataIndex: "packingextraqty",
        key: "packingextraqty",
        align: "right", // nohit-388 set Align for column
        render: (_, text) => {
          return (
            <input
              type={"number"}
              className="tr001Input"
              style={{ width: "50px" }}
              //{Pradeep, task id -272}
              ///{Pradeep task-id -260}
              disabled={text.packingextrarecno > 0 ? false : true}
              defaultValue={text.packingextraqty}
              //{start Pradeep, task id-238  }
              value={text.packingextraqty}
              // onFocus={(e) => e.target.select()}
              onChange={(e) => {
                const regex = /^[0-9\b]+$/;

                // console.log("e.target.value", e.target.value)
                // console.log("conversionfactor11", e.target.value, text.conversionfactor, text.itembatcharray.length)

                //old validation on box and piece
                if (e.target.value === "" || regex.test(e.target.value)) {
                  text.packingextraqty = e.target.value;
                  //{start Pradeep, task id-238 }
                  handleChange(
                    text,
                    setRowData,
                    igstappl,
                    rowData,
                    setScandata
                  );
                }

                //{start Pradeep, task id-238 }
                else if (e.target.value == "0" && text.conversionfactor == 12) {
                  console.log("hiiiop");
                  text.packingextraqty =
                    text.itembatcharray.length / text.conversionfactor || 1;
                  handleChange(
                    text,
                    setRowData,
                    igstappl,
                    rowData,
                    setScandata
                  );
                  //{END         END }
                } else {
                  // console.log("1111113")
                  alert("Invalid Input");
                  e.target.value = "";
                  text.packingextraqty = e.target.value;
                  //{start Pradeep, task id-238 }
                  handleChange(
                    text,
                    setRowData,
                    igstappl,
                    rowData,
                    setScandata
                  );
                }
              }}
              onBlur={(e) => {
                //{start Pradeep, task id-238 start}

                if (e.target.value == "0" && text.conversionfactor == 12) {
                  if (Number(text.free) == 0) {
                    let count =
                      Number(text.packingqty) +
                      Number(
                        text.freepackingextraqty * (text.conversionfactor || 1)
                      ) +
                      Number(text.freepackingqty);

                    console.log("count", count);
                    if (count >= text.itembatcharray.length) {
                      text.packingextraqty = 0;
                    } else {
                      alert(
                        "Quantity cannot be less than scanned Quantity,\n First Remove Items from Scanned List"
                      );
                      // console.log("1234",text.itembatcharray.length -count)
                      text.packingextraqty =
                        (text.itembatcharray.length - count) /
                        text.conversionfactor;
                      // console.log("12345", text.freepackingextraqty)
                    }
                    handleChange(
                      text,
                      setRowData,
                      igstappl,
                      rowData,
                      setScandata
                    );
                  } else {
                    e.target.value =
                      text?.itembatcharray?.length -
                      (Number(text?.packingqty) + Number(text?.free));
                    console.log("e.target.value", e.target.value);

                    if (e.target.value > 0) {
                      text.packingextraqty = Number(e.target.value);
                    } else {
                      text.packingextraqty = 0;
                    }

                    console.log("text.packingextraqty", text.packingextraqty);
                    handleChange(
                      text,
                      setRowData,
                      igstappl,
                      rowData,
                      setScandata
                    );
                  }
                }

                //{END         END }

                // Validate the Entered Quantity with the new Entered Quantity
                if (
                  //{start Pradeep, task id-238 start}

                  Number(text?.qty) + Number(text?.free) <
                    text?.itembatcharray?.length &&
                  text.conversionfactor == 1
                  //{END         END }
                ) {
                  e.target.value =
                    text?.itembatcharray?.length -
                    (Number(text?.packingqty) + Number(text?.free));
                  console.log("e.target.value", e.target.value);

                  alert(
                    "Quantity cannot be less than scanned Quantity,\n First Remove Items from Scanned List"
                  );
                  text.packingextraqty = Number(e.target.value);
                  console.log("text.packingextraqty", text.packingextraqty);
                  handleChange(
                    text,
                    setRowData,
                    igstappl,
                    rowData,
                    setScandata
                  );
                }

                // Validate the Entered Quantity with the new Entered Quantity

                if (text?.wantbatchno === 1) {
                  console.log("wantbatchno?.qty", text?.qty, text?.free);
                  if (Number(text?.qty) + Number(text?.free) == 0) {
                    alert("Quantity cannot be less than or equal to zero.");
                  }
                }
              }}
            />
          );
        },
      },
    

      {
        title: "Pcs Qty",
        width: 70,
        dataIndex: "packingqty",
        key: "packingqty",
        align: "right", // nohit-388 set Align for column
        render: (_, text) => {
          return (
            <input
              className="tr001Input"
              type={"number"}
              //{Pradeep, task id -272}
              disabled={text.packingextrarecno == 0 ? false : true}
              style={{ width: "50px" }}
              defaultValue={text.packingqty}
              onFocus={(e) => e.target.select()}
              onChange={(e) => {
                const regex = /^[0-9\b]+$/;

                console.log("text--", text);

                if (e.target.value === "" || regex.test(e.target.value)) {
                  text.packingqty = e.target.value;
                  //{Pradeep, task id-238 }
                  handleChange(
                    text,
                    setRowData,
                    igstappl,
                    rowData,
                    setScandata
                  );
                } else {
                  alert("Invalid Input");
                  e.target.value = "";
                  text.packingqty = e.target.value;
                  // Pradeep, task id-238 }
                  handleChange(
                    text,
                    setRowData,
                    igstappl,
                    rowData,
                    setScandata
                  );
                }

                // if (regex.test(e.target.value) && (Number(e.target.value) + Number(text.packingextraqty)) > 0) {
                //   text.packingqty = e.target.value;
                //   handleChange(text, setRowData, igstappl, rowData);
                // } else {
                //   e.target.value = text.packingqty
                //   alert("Box and Pieces cannot be 0")
                // }
              }}
              onBlur={(e) => {
                console.log(
                  "text?.qty",
                  text?.qty,
                  text?.free,
                  text?.itembatcharray?.length
                );
                // Validate the Entered Quantity with the new Entered Quantity
                if (
                  Number(text?.qty) + Number(text?.free) <
                  text?.itembatcharray?.length
                ) {
                  e.target.value =
                    text?.itembatcharray?.length -
                    (Number(text?.packingextraqty) + Number(text?.free));

                  console.log("e.target.value", e.target.value);
                  alert(
                    "Quantity cannot be less than scanned Quantity,\n First Remove Items from Scanned List"
                  );
                  console.log("text.packingqty pieces", text.packingqty);
                  text.packingqty = Number(e.target.value);
                  // { Pradeep, task id-238 }
                  handleChange(
                    text,
                    setRowData,
                    igstappl,
                    rowData,
                    setScandata
                  );
                  // console.log("E",handleChange(text, setRowData, igstappl, rowData))
                }

                if (text?.wantbatchno === 1) {
                  console.log("wantbatchno?.qty", text?.qty, text?.free);
                  if (Number(text?.qty) + Number(text?.free) == 0) {
                    alert("Quantity cannot be less zero");
                  }
                }
              }}
            ></input>
          );
        },
      },
      {
        width: 80,
        title: "Qty",
        dataIndex: "qty",
        key: "qty",
        align: "right", // nohit-388 set Align for column
      },
      {
        hidden:
          pageDetails?.wantfreeqty && !pageDetails?.wantpackingextra
            ? false
            : true,
        width: 70,
        title: "Free Box Qty",
        dataIndex: "freepackingextraqty",
        key: "freepackingextraqty",
        align: "right", // nohit-388 set Align for column
        render: (_, text) => {
          return (
            <input
              type={"number"}
              //{Pradeep, task id -272}
              disabled={text.packingextrarecno > 0 ? false : true}
              className="tr001Input"
              defaultValue={text.freepackingextraqty}
              value={text.freepackingextraqty}
              onFocus={(e) => e.target.select()}
              onChange={(e) => {
                ///{ start Pradeep task-id -260 start }
                const regex = /^[0-9\b]+$/;
                if (e.target.value === "" || regex.test(e.target.value)) {
                  text.freepackingextraqty = e.target.value;
                  // { Pradeep, task id-238 }
                  handleChange(
                    text,
                    setRowData,
                    igstappl,
                    rowData,
                    setScandata
                  );
                } else if (
                  e.target.value === "0" &&
                  text.conversionfactor == 12
                ) {
                  console.log("hiiis");
                  text.freepackingextraqty =
                    text.itembatcharray.length / text.conversionfactor || 1;
                  handleChange(
                    text,
                    setRowData,
                    igstappl,
                    rowData,
                    setScandata
                  );
                  //{END         END }
                } else {
                  alert("Invalid Input");
                  e.target.value = "";
                  text.freepackingextraqty = e.target.value;
                  // { Pradeep, task id-238 }
                  handleChange(
                    text,
                    setRowData,
                    igstappl,
                    rowData,
                    setScandata
                  );
                }
              }}
              onBlur={(e) => {
                // {start  Pradeep, task id-238  start}
                if (e.target.value == "0" && text.conversionfactor == 12) {
                  if (Number(text.qty) == 0) {
                    let count =
                      Number(text.packingqty) +
                      Number(
                        text.packingextraqty * (text.conversionfactor || 1)
                      ) +
                      Number(text.freepackingqty);

                    console.log("count", count);
                    if (count >= text.itembatcharray.length) {
                      text.freepackingextraqty = 0;
                    } else {
                      alert(
                        "Quantity cannot be less than scanned Quantity,\n First Remove Items from Scanned List"
                      );
                      // console.log("1234",text.itembatcharray.length -count)
                      text.freepackingextraqty =
                        (text.itembatcharray.length - count) /
                        text.conversionfactor;
                      // console.log("12345", text.freepackingextraqty)
                    }
                    handleChange(
                      text,
                      setRowData,
                      igstappl,
                      rowData,
                      setScandata
                    );
                  } else {
                    e.target.value =
                      text?.itembatcharray?.length -
                      (Number(text?.qty) + Number(text?.freepackingqty));
                    if (e.target.value > 0) {
                      text.freepackingextraqty = Number(e.target.value);
                    } else {
                      text.freepackingextraqty = 0;
                    }

                    // { Pradeep, task id-238 }
                    handleChange(
                      text,
                      setRowData,
                      igstappl,
                      rowData,
                      setScandata
                    );
                  }
                }
                //{ end       end }

                // Validate the Entered Quantity with the new Entered Quantity
                if (
                  Number(text?.qty) + Number(text?.free) <
                    // { Pradeep, task id-238 }
                    text?.itembatcharray?.length &&
                  text.conversionfactor == 1
                ) {
                  e.target.value =
                    text?.itembatcharray?.length -
                    (Number(text?.qty) + Number(text?.freepackingqty));
                  alert(
                    "Quantity cannot be less than scanned Quantity,\n First Remove Items from Scanned List"
                  );
                  text.freepackingextraqty = e.target.value;
                  // { Pradeep, task id-238 }
                  handleChange(
                    text,
                    setRowData,
                    igstappl,
                    rowData,
                    setScandata
                  );
                }

                if (text?.wantbatchno === 1) {
                  console.log("wantbatchno?.qty", text?.qty, text?.free);
                  if (Number(text?.qty) + Number(text?.free) == 0) {
                    alert("Quantity cannot be less than or equal to zero.");
                  }
                }
              }}
            ></input>
          );
        },
      },
      {
        hidden: pageDetails?.wantfreeqty ? false : true,
        width: 70,
        title: "Free Pcs Qty",
        dataIndex: "freepackingqty",
        key: "freepackingqty",
        align: "right", // nohit-388 set Align for column
        render: (_, text) => {
          return (
            <input
              className="tr001Input"
              //{Pradeep, task id -272}
              disabled={text.packingextrarecno == 0 ? false : true}
              type={"number"}
              defaultValue={text.freepackingqty}
              onFocus={(e) => e.target.select()}
              onChange={(e) => {
                const regex = /^[0-9\b]+$/;
                if (e.target.value === "" || regex.test(e.target.value)) {
                  text.freepackingqty = e.target.value;
                  // { Pradeep, task id-238 }
                  handleChange(
                    text,
                    setRowData,
                    igstappl,
                    rowData,
                    setScandata
                  );
                } else {
                  alert("Invalid Input");
                  e.target.value = "";
                  text.freepackingqty = e.target.value;
                  // { Pradeep, task id-238 }
                  handleChange(
                    text,
                    setRowData,
                    igstappl,
                    rowData,
                    setScandata
                  );
                }
              }}
              onBlur={(e) => {
                // Validate the Entered Quantity with the new Entered Quantity
                if (
                  Number(text?.qty) + Number(text?.free) <
                  text?.itembatcharray?.length
                ) {
                  e.target.value =
                    text?.itembatcharray?.length -
                    (Number(text?.qty) + Number(text?.freepackingextraqty));
                  alert(
                    "Quantity cannot be less than scanned Quantity,\n First Remove Items from Scanned List"
                  );
                  text.freepackingqty = e.target.value;
                  // { Pradeep, task id-238 }
                  handleChange(
                    text,
                    setRowData,
                    igstappl,
                    rowData,
                    setScandata
                  );
                }
                if (text?.wantbatchno === 1) {
                  console.log("wantbatchno?.qty", text?.qty, text?.free);
                  if (Number(text?.qty) + Number(text?.free) == 0) {
                    alert("Quantity cannot be less than or equal to zero.");
                  }
                }
              }}
            ></input>
          );
        },
      },
      {
        hidden: pageDetails?.wantfreeqty ? false : true,
        width: 80,
        title: "Free Qty",
        dataIndex: "free",
        key: "free",
        align: "right", // nohit-388 set Align for column
      },
      {
        title: "Sale Rate",
        width: 70,
        dataIndex: "salerate",
        key: "salerate",
        align: "right", // nohit-388 set Align for column
        render: (_, text) => {
          return (
            <input
              className="tr001Input"
              type={"number"}
              //{Pradeep, task id -272}
              disabled={text.packingextrarecno == 0 ? false : true}
              defaultValue={text.salerate}
              onFocus={(e) => e.target.select()}
              onChange={(e) => {
                const regex = /^[0-9\b]+$/;

                console.log("text--", text);

                if (e.target.value === "" || regex.test(e.target.value)) {
                  text.packingqty = e.target.value;
                  //{Pradeep, task id-238 }
                  handleChange(
                    text,
                    setRowData,
                    igstappl,
                    rowData,
                    setScandata
                  );
                } else {
                  alert("Invalid Input");
                  e.target.value = "";
                  text.salerate = e.target.value;
                  // Pradeep, task id-238 }
                  handleChange(
                    text,
                    setRowData,
                    igstappl,
                    rowData,
                    setScandata
                  );
                }

                // if (regex.test(e.target.value) && (Number(e.target.value) + Number(text.packingextraqty)) > 0) {
                //   text.packingqty = e.target.value;
                //   handleChange(text, setRowData, igstappl, rowData);
                // } else {
                //   e.target.value = text.packingqty
                //   alert("Box and Pieces cannot be 0")
                // }
              }}
              onBlur={(e) => {
                console.log(
                  "text?.qty",
                  text?.qty,
                  text?.free,
                  text?.itembatcharray?.length
                );
                // Validate the Entered Quantity with the new Entered Quantity
                if (
                  Number(text?.qty) + Number(text?.free) <
                  text?.itembatcharray?.length
                ) {
                  e.target.value =
                    text?.itembatcharray?.length -
                    (Number(text?.packingextraqty) + Number(text?.free));

                  console.log("e.target.value", e.target.value);
                  alert(
                    "Quantity cannot be less than scanned Quantity,\n First Remove Items from Scanned List"
                  );
                  console.log("text.packingqty pieces", text.packingqty);
                  text.packingqty = Number(e.target.value);
                  // { Pradeep, task id-238 }
                  handleChange(
                    text,
                    setRowData,
                    igstappl,
                    rowData,
                    setScandata
                  );
                  // console.log("E",handleChange(text, setRowData, igstappl, rowData))
                }

                if (text?.wantbatchno === 1) {
                  console.log("wantbatchno?.qty", text?.qty, text?.free);
                  if (Number(text?.qty) + Number(text?.free) == 0) {
                    alert("Quantity cannot be less zero");
                  }
                }
              }}
            ></input>
          );
        },
      },

      {
        hidden: pageDetails?.wantbatchno > 0 ? false: true,
        width: 200,
        title: "Batch No.",
        dataIndex: "itembatchno",
        key: "itembatchno",
        align: "left", // nohit-388 set Align for column
        render: (_, text) => {
          return (
            <input
            className="tr001Input"
              onFocus={(e) => e.target.select()}
              defaultValue={text.itembatchno}
              onChange={(e) => {
                text.itembatchno = e.target.value;
                handleChange(text, setRowData, false, rowData);
              }}
            ></input>
          );
        },
      },
      {
        hidden: pageDetails?.wantexpdate > 0 ? false: true,
        width: 300,
        title: "Exp. Date",
        dataIndex: "expdate",
        key: "expdate",
        align: "left", // nohit-388 set Align for column
        render: (_, text) => {
          return (
            <div
              style={{
                border: "1px dashed #dddd",
                padding: "5px",
                borderRadius: "5px",
              }}
            >
              <CallbackDateInput
                defaultValue={text.expdate}
                id={"po-expdate-" + text.shortguid}
                apiKey={"expecteddeliverydate"}
                returnValue={(e) => {
                  text.expdate = e;
                  handleChange(text, setRowData, false, rowData);
                }}
              />
            </div>
          );
        },
      },
      {
        width: 80,
        title: "Pur Rate",
        dataIndex: "purrate",
        key: "purrate",
        align: "right", // nohit-388 set Align for column
        render: (_, text) => {
          return (
            <input
              style={{ width: "60px" }}
              defaultValue={text.purrate}
              onFocus={(e) => e.target.select()}
              onChange={(e) => {
                text.purrate = e.target.value;
                handleChange(text, setRowData, false, rowData);
              }}
            ></input>
          );
        },
      },
      {
        width: 80,
        title: "Discount",
        dataIndex: "discountamt",
        key: "discountamt",
        align: "right", // nohit-388 set Align for column
        render: (_, text) => {
          return (
            <input
              style={{ width: "60px" }}
              defaultValue={text.discountamt}
              onFocus={(e) => e.target.select()}
              onChange={(e) => {
                text.discountamt = e.target.value;
                handleChange(text, setRowData, false, rowData);
              }}
            ></input>
          );
        },
      },
      {
        width: 90,
        title: "Discount %",
        dataIndex: "discount",
        key: "discount",
        align: "right", // nohit-388 set Align for column
        render: (_, text) => {
          return (
            <input
              style={{ width: "60px" }}
              defaultValue={text?.discount}
              onFocus={(e) => e.target.select()}
              onChange={(e) => {
                text.discount = e.target.value;
                handleChange(text, setRowData, false, rowData);
              }}
              value={text.discount}
            ></input>
          );
        },
      },
      {
        width: 100,
        title: "Taxable Amount",
        dataIndex: "taxableamount",
        key: "taxableamount",
        align: "right", // nohit-388 set Align for column
      },
      {
        width: 80,
        title: "IGST%",
        dataIndex: "igstrate",
        key: "igstrate",
        align: "right", // nohit-388 set Align for column
      },
      {
        width: 80,
        title: "IGST",
        dataIndex: "igstamt",
        key: "igstamt",
        align: "right", // nohit-388 set Align for column
      },
      {
        width: 80,
        title: "Amount",
        dataIndex: "amount",
        key: "amount",
        align: "right", // nohit-388 set Align for column
      },
    ];
  } else {
    return [
      {
        title: "",
        dataIndex: "active",
        key: "active",
        width: "50px",
        render: (_, text) => {
          return (
            <Popconfirm
              title="Do you want to Cancel the changes?"
              onConfirm={(e) => {
                handleRemove(text, setRowData, rowData);
              }}
            >
              <Button icon={<AiFillDelete />}></Button>
            </Popconfirm>
          );
        },
      },
      {
        width: 120,
        title: "Item Description",
        dataIndex: "itemdescn",
        key: "itemdescn",
      },
      // {
      //   title: "PO Qty",
      //   dataIndex: "poqty",
      //   key: "poqty",
      //   width: 70,
      // },
      {
        hidden: !pageDetails?.wantpackingextra ? true : false,
        title: "Boxes",
        width: 60,
        dataIndex: "packingextraqty",
        key: "packingextraqty",
        align: "right", // nohit-388 set Align for column
        render: (_, text) => {
          console.log("textboxes in materialfnc", text);
          return (
            <input
              className={`tr001Input tr001Box`}
              //{Pradeep task id -255}
              disabled={text.packingextrarecno > 0 ? false : true}
              defaultValue={text?.packingextraqty} //~#packingextraqty
              type="number"
              onFocus={(e) => e.target.select()}
              //{Pradeep task id -255}
              value={text.packingextraqty}
              onChange={(e) => {
                if (e.target.value < 0) {
                  e.target.value = null;
                  return false;
                }
                const regex = /^[0-9\b]+$/;
                // if (regex.test(e.target.value) && (Number(e.target.value) + Number(text.packingextraqty)) > 0)

                if (e.target.value === "" || regex.test(e.target.value)) {
                  text.packingextraqty = e.target.value;
                  //{start Pradeep, task id-238 }
                  console.log("text.packingextraqty", text.packingextraqty);
                  handleChange(
                    text,
                    setRowData,
                    igstappl,
                    rowData,
                    setScandata
                  );
                }
                ///{Pradeep task-id -260}
                //{start Pradeep, task id-238 }
                //{Pradeep task id -255}
                else if (e.target.value == "0" && text.conversionfactor == 12) {
                  console.log("hiiiop");
                  text.packingextraqty =
                    text.itembatcharray.length / text.conversionfactor || 1;
                  handleChange(
                    text,
                    setRowData,
                    igstappl,
                    rowData,
                    setScandata
                  );
                  //{END         END }
                } else {
                  // console.log("1111113")
                  alert("Invalid Input");
                  e.target.value = "";
                  text.packingextraqty = e.target.value;
                  //{start Pradeep, task id-238 }
                  handleChange(
                    text,
                    setRowData,
                    igstappl,
                    rowData,
                    setScandata
                  );
                }
              }}
              onBlur={(e) => {
                //{Pradeep task id -255}
                ///{ Pradeep task-id -260  }
                if (e.target.value == "0" && text.conversionfactor == 12) {
                  if (Number(text.qty) == 0) {
                    let count =
                      Number(text.packingqty) +
                      Number(
                        text.packingextraqty * (text.conversionfactor || 1)
                      );

                    console.log("count", count);
                    if (count >= text.itembatcharray.length) {
                      text.packingextraqty = 0;
                    } else {
                      alert(
                        "Quantity cannot be less than scanned Quantity,\n First Remove Items from Scanned List"
                      );
                      // console.log("1234",text.itembatcharray.length -count)
                      text.packingextraqty =
                        (text.itembatcharray.length - count) /
                        text.conversionfactor;
                      // console.log("12345", text.freepackingextraqty)
                    }
                    handleChange(
                      text,
                      setRowData,
                      igstappl,
                      rowData,
                      setScandata
                    );
                  } else {
                    e.target.value =
                      text?.itembatcharray?.length - Number(text?.packingqty);
                    console.log("e.target.value", e.target.value);

                    if (e.target.value > 0) {
                      text.packingextraqty = Number(e.target.value);
                    } else {
                      text.packingextraqty = 0;
                    }

                    console.log("text.packingextraqty", text.packingextraqty);
                    handleChange(
                      text,
                      setRowData,
                      igstappl,
                      rowData,
                      setScandata
                    );
                  }
                }

                //{END         END }

                // Validate the Entered Quantity with the new Entered Quantity
                if (
                  //{start Pradeep, task id-238 start}
                  //{Pradeep task id -255}
                  ///{ Pradeep task-id -260  }
                  Number(text?.qty) < text?.itembatcharray?.length &&
                  text.conversionfactor == 1
                  //{END         END }
                ) {
                  e.target.value =
                    text?.itembatcharray?.length - Number(text?.packingqty);
                  console.log("e.target.value", e.target.value);

                  alert(
                    "Quantity cannot be less than scanned Quantity,\n First Remove Items from Scanned List"
                  );
                  text.packingextraqty = Number(e.target.value);
                  console.log("text.packingextraqty", text.packingextraqty);
                  handleChange(
                    text,
                    setRowData,
                    igstappl,
                    rowData,
                    setScandata
                  );
                }

                // Validate the Entered Quantity with the new Entered Quantity

                if (text?.wantbatchno === 1) {
                  console.log("wantbatchno?.qty", text?.qty, text?.free);
                  if (Number(text?.qty) + Number(text?.free) == 0) {
                    alert("Quantity cannot be less than or equal to zero.");
                  }
                }
              }}
            />
          );
        },
      },

      {
        title: "Pieces",
        width: 60,
        dataIndex: "packingqty",
        key: "packingqty",
        align: "right", // nohit-388 set Align for column
        render: (_, text) => {
          console.log("textpieces in materialfnc", text);
          return (
            <input
              className={`tr001Input tr001Box`}
              //{Pradeep task id -255}
              disabled={text.packingextrarecno == 0 ? false : true}
              defaultValue={text?.packingqty} //~# packingqty
              key={text?.packingqty}
              type="number"
              onFocus={(e) => e.target.select()}
              onChange={(e) => {
                console.log("Changed Value of the Pieces is ", e.target.value);
                if (e.target.value < 0) {
                  e.target.value = null;
                  return false;
                }
                const regex = /^[0-9\b]+$/;
                // if (e.target.value === "" || regex.test(e.target.value)) {   //if 0 is there else is not working
                if (
                  regex.test(e.target.value) &&
                  Number(e.target.value) + Number(text.packingqty) > 0
                ) {
                  //{   Pradeep, task id-238  changes pieces to packingqty key}
                  text.packingqty = e.target.value;
                  handleChange(text, setRowData, igstappl, rowData);
                } else {
                  // e.target.value = text.packingqty
                  //{  Pradeep, task id-238  changes pieces to packingqty key}
                  //{ start Pradeep task id -255 start }
                  {
                    // alert("Invalid Input");
                    e.target.value = "";
                    text.packingqty = e.target.value;
                    // Pradeep, task id-238 }
                    handleChange(
                      text,
                      setRowData,
                      igstappl,
                      rowData,
                      setScandata
                    );
                  }
                  //{end         end }
                  // text.packingqty = e.target.value;
                  // alert("Box and Pieces cannot be 0")
                }
              }}
              onBlur={(e) => {
                // Validate the Entered Quantity with the new Entered Quantity
                console.log("text?.pieces", text?.packingqty);
                if (Number(text?.qty) < text?.itembatcharray?.length) {
                  e.target.value =
                    text?.itembatcharray?.length -
                    Number(text?.packingextraqty);
                  console.log("e.target.value12", e.target.value);
                  alert(
                    "Quantity cannot be less than scanned Quantity,\n First Remove Items from Scanned List"
                  );
                  //{ Pradeep, task id-238  changes pieces to packingqty key}
                  text.packingqty = e.target.value;
                  handleChange(text, setRowData, igstappl, rowData);
                }
              }}
            />
          );
        },
      },
      {
        width: 60,
        title: "Qty",
        dataIndex: "qty",
        key: "qty",
        align: "right", // nohit-388 set Align for column
      },
      {
        hidden:
          pageDetails?.wantfreeqty && !pageDetails?.wantpackingextra
            ? false
            : true,
        width: 70,
        title: "Free Box Qty",
        dataIndex: "freepackingextraqty",
        key: "freepackingextraqty",
        render: (_, text) => {
          return (
            <input
              style={{ width: "50px" }}
              defaultValue={text.freepackingqty}
              onFocus={(e) => e.target.select()}
              onChange={(e) => {
                text.freepackingqty = e.target.value;
                // handleChange(text, setRowData, false, rowData);
              }}
            ></input>
          );
        },
      },
      {
        hidden: pageDetails?.wantfreeqty ? false : true,
        width: 70,
        title: "Free Pcs Qty",
        dataIndex: "freepackingqty",
        key: "freepackingqty",
        render: (_, text) => {
          return (
            <input
              style={{ width: "50px" }}
              defaultValue={text.freepackingextraqty}
              onFocus={(e) => e.target.select()}
              onChange={(e) => {
                text.freepackingextraqty = e.target.value;
                // handleChange(text, setRowData, false, rowData);
              }}
            ></input>
          );
        },
      },
      {
        hidden: pageDetails?.wantfreeqty ? false : true,
        width: 80,
        title: "Free Qty",
        dataIndex: "free",
        key: "free",
      },
      {
        title: "Sale Rate",
        width: 70,
        dataIndex: "sgstrate",
        key: "sgstrate",
        align: "right", // nohit-388 set Align for column
        render: (_, text) => {
          return (
            <input
              className="tr001Input"
              type={"number"}
              //{Pradeep, task id -272}
              disabled={text.packingextrarecno == 0 ? false : true}
              defaultValue={text.salerate}
              onFocus={(e) => e.target.select()}
              onChange={(e) => {
                const regex = /^[0-9\b]+$/;

                console.log("text--", text);

                if (e.target.value === "" || regex.test(e.target.value)) {
                  text.salerate = e.target.value;
                  //{Pradeep, task id-238 }
                  handleChange(
                    text,
                    setRowData,
                    igstappl,
                    rowData,
                    setScandata
                  );
                } else {
                  alert("Invalid Input");
                  e.target.value = "";
                  text.salerate = e.target.value;
                  // Pradeep, task id-238 }
                  handleChange(
                    text,
                    setRowData,
                    igstappl,
                    rowData,
                    setScandata
                  );
                }

                // if (regex.test(e.target.value) && (Number(e.target.value) + Number(text.packingextraqty)) > 0) {
                //   text.packingqty = e.target.value;
                //   handleChange(text, setRowData, igstappl, rowData);
                // } else {
                //   e.target.value = text.packingqty
                //   alert("Box and Pieces cannot be 0")
                // }
              }}
              onBlur={(e) => {
                console.log(
                  "text?.qty",
                  text?.qty,
                  text?.free,
                  text?.itembatcharray?.length
                );
                // Validate the Entered Quantity with the new Entered Quantity
                if (
                  Number(text?.qty) + Number(text?.free) <
                  text?.itembatcharray?.length
                ) {
                  e.target.value =
                    text?.itembatcharray?.length -
                    (Number(text?.packingextraqty) + Number(text?.free));

                  console.log("e.target.value", e.target.value);
                  alert(
                    "Quantity cannot be less than scanned Quantity,\n First Remove Items from Scanned List"
                  );
                  console.log("text.packingqty pieces", text.packingqty);
                  text.packingqty = Number(e.target.value);
                  // { Pradeep, task id-238 }
                  handleChange(
                    text,
                    setRowData,
                    igstappl,
                    rowData,
                    setScandata
                  );
                  // console.log("E",handleChange(text, setRowData, igstappl, rowData))
                }

                if (text?.wantbatchno === 1) {
                  console.log("wantbatchno?.qty", text?.qty, text?.free);
                  if (Number(text?.qty) + Number(text?.free) == 0) {
                    alert("Quantity cannot be less zero");
                  }
                }
              }}
            ></input>
          );
        },
      },
      {
        hidden: pageDetails?.wantbatchno > 0 ? false: true,
        width: 100,
        title: "Batch No.",
        dataIndex: "itembatchno",
        key: "itembatchno",
        align: "left", // nohit-388 set Align for column
        render: (_, text) => {
          return (
            <input
            className="tr001Input"
              onFocus={(e) => e.target.select()}
              defaultValue={text.itembatchno}
              onChange={(e) => {
                text.itembatchno = e.target.value;
                handleChange(text, setRowData, false, rowData);
              }}
            ></input>
          );
        },
      },
      {
        hidden: pageDetails?.wantmanudate > 0 ? false: true,
        width: 200,
        title: "Mfg. Date",
        dataIndex: "mfgdate",
        key: "mfgdate",
        align: "left", // nohit-388 set Align for column
        render: (_, text) => {
          
          return (
            <CallbackDateInput
                defaultValue={text.manuDate}
                id={"po-expdate-" + text.shortguid}
                apiKey={"expectedmanudate"}
                dateLessthan={text?.expdate}
                returnValue={(e) => {
                  console.log("text998898", text,"rtch",e,"trdfyug");
                  text.mfgdate = e;
                  handleChange(text, setRowData, false, rowData);
                }}
              />
            // <div
            //   style={{
            //     border: "1px dashed #dddd",
            //     padding: "3px",
            //     borderRadius: "5px",
            //   }}
            // >
              
            // </div>
          );
        },
      },
      {
        hidden: pageDetails?.wantexpdate > 0 ? false: true,
        width: 200,
        title: "Exp. Date",
        dataIndex: "expdate",
        key: "expdate",
        align: "left", // nohit-388 set Align for column
        render: (_, text) => {
          return (
            <CallbackDateInput
                defaultValue={text.expdate}
                id={"po-expdate-" + text.shortguid}
                apiKey={"expecteddeliverydate"}
                dateGreaterthan={text?.manuDate}
                returnValue={(e) => {
                  text.expdate = e;
                  handleChange(text, setRowData, false, rowData);
                }}
              />
            // <div
            //   style={{
            //     border: "1px dashed #dddd",
            //     padding: "3px",
            //     borderRadius: "5px",
            //   }}
            // >
              
            // </div>
          );
        },
      },
      {
        title: " Accepted Pieces",
        dataIndex: "acceptedpieces",
        key: "acceptedpieces",
        width: "8%",
        render: (_, text) => <p>{text?.itembatcharray?.length}</p>,
        align: "right",
      },
      {
        width: 80,
        title: "Scan Barcode",
        dataIndex: "barcode",
        align: "left", // nohit-388 set Align for column
        key: "barcode",
        render: (_, text) => {
          console.log("Text is", text);
          if (text.itemasbatch) {
            return (
              <Button
                onClick={() => {
                  setOpenItem(true);

                  setScandata(text);
                }}
              >
                Item Scan
              </Button>
            );
          } else return null;
        },
      },
      //   {
      //     width: 80,
      //     title: "Pur Rate",
      //     dataIndex: "purrate",
      //     key: "purrate",
      //     render: (_, text) => {
      //       return (
      //         <input
      //           style={{ width: "60px" }}
      //           defaultValue={text.purrate}
      //           onFocus={(e) => e.target.select()}
      //           onChange={(e) => {
      //             text.purrate = e.target.value;
      //             handleChange(text, setRowData, false, rowData);
      //           }}
      //         ></input>
      //       );
      //     },
      //   },
      //   {
      //     width: 80,
      //     title: "Discount",
      //     dataIndex: "discountamt",
      //     key: "discountamt",
      //     render: (_, text) => {
      //       return (
      //         <input
      //           style={{ width: "60px" }}
      //           defaultValue={text.discountamt}
      //           onFocus={(e) => e.target.select()}
      //           onChange={(e) => {
      //             text.discountamt = e.target.value;
      //             handleChange(text, setRowData, false, rowData);
      //           }}
      //         ></input>
      //       );
      //     },
      //   },
      //   {
      //     width: 90,
      //     title: "Discount %",
      //     dataIndex: "discount",
      //     key: "discount",
      //     render: (_, text) => {
      //       return (
      //         <input
      //           style={{ width: "60px" }}
      //           defaultValue={text?.discount}
      //           onFocus={(e) => e.target.select()}
      //           onChange={(e) => {
      //             text.discount = e.target.value;
      //             handleChange(text, setRowData, false, rowData);
      //           }}
      //           value={text.discount}
      //         ></input>
      //       );
      //     },
      //   },
      //   {
      //     width: 100,
      //     title: "Taxable Amount",
      //     dataIndex: "taxableamount",
      //     key: "taxableamount",
      //   },
      //   {
      //     width: 80,
      //     title: "CGST%",
      //     dataIndex: "cgstrate",
      //     key: "cgstrate",
      //   },
      //   {
      //     width: 80,
      //     title: "CGST",
      //     dataIndex: "cgstamt",
      //     key: "cgst",
      //   },
      //   {
      //     width: 80,
      //     title: "SGST%",
      //     dataIndex: "sgstrate",
      //     key: "sgstrate",
      //   },
      //   {
      //     width: 80,
      //     title: "SGST",
      //     dataIndex: "sgstamt",
      //     key: "sgst",
      //   },
      //   {
      //     width: 80,
      //     title: "Amount",
      //     dataIndex: "amount",
      //     key: "amount",
      //   },
    ];
  }
};

export const handleAdd = (
  item,
  setRowData,
  igstappl,
  setSelectedItem,
  shortguid,
  purchaseRate,
  purchaseQty,
  box,
  freeBox,
  freeQty,
  batchNo,
  expDate,
  discountAmt,
  discountPer,
  manuDate
) => {
  var newShortguid = shortguid.getShortguid("POF" + item.itemrecno + batchNo);
  console.log("Added Shortguid", item, purchaseQty, box);
  var purrate = purchaseRate ? purchaseRate : 0.0;
  var cgstrate = item?.cgstrate ? item.cgstrate : 0.0;
  var sgstrate = item?.sgstrate ? item.sgstrate : 0.0;
  var igstrate = item?.igstrate ? item.igstrate : 0.0;
  var packingqty = purchaseQty ? purchaseQty : 0;
  var packingextraqty = box ? box : 0;
  var freepackingextraqty = freeBox ? freeBox : 0;
  var freepackingqty = freeQty ? freeQty : 0;
  var free =
    Number(freepackingqty) +
    Number(freepackingextraqty) * Number(item?.conversionfactor);

  var qty =
    Number(packingqty) +
    Number(packingextraqty) * Number(item?.conversionfactor);
  var discount = discountPer ? Number(discountPer) : 0.0;
  var discountamt = discountAmt ? Number(discountAmt) : 0.0;
  var expdate = expDate ? expDate : null;
  var itembatchno = batchNo ? batchNo : null;

  var taxableamount = qty * (purrate - discountamt);
  var sgstamt = !igstappl ? taxableamount * (sgstrate / 100) : 0.0;
  var cgstamt = !igstappl ? taxableamount * (cgstrate / 100) : 0.0;
  var igstamt = igstappl ? taxableamount * (igstrate / 100) : 0.0;

  var amount = igstappl
    ? taxableamount + igstamt
    : taxableamount + (cgstamt + sgstamt);

  var itemObject = {
    ...item,
    shortguid: newShortguid,
    itemrecno: item.itemrecno,
    itemdescn: item.itemdescn,
    packingextraqty: packingextraqty,
    packingqty: packingqty,
    qty: qty,
    free: free,
    purrate: Number(purrate).toFixed(2),
    taxableamount: Number(taxableamount).toFixed(2),
    cgstrate: cgstrate.toFixed(2),
    sgstrate: sgstrate.toFixed(2),
    igstrate: igstrate.toFixed(2),
    cgstamt: cgstamt.toFixed(2),
    sgstamt: sgstamt.toFixed(2),
    igstamt: igstamt.toFixed(2),
    amount: amount.toFixed(2),
    discount: Number(discount).toFixed(2),
    discountamt: discountamt.toFixed(2),
    expdate: expdate,
    manuDate: manuDate,
    itembatchno: itembatchno,
    freepackingextraqty: freepackingextraqty,
    freepackingqty: freepackingqty,
  };

  setRowData((p) => [...p, itemObject]);
  setSelectedItem(null);
};

export const handleChange = (item, setRowData, igstappl, rowData) => {
  console.log("Handle CHange");
  var purrate = item?.purrate ? item.purrate : 0.0;
  var cgstrate = item?.cgstrate ? item.cgstrate : 0.0;
  var sgstrate = item?.sgstrate ? item.sgstrate : 0.0;
  var igstrate = item?.igstrate ? item.igstrate : 0.0;

  let packingextraqty = item?.packingextraqty ? item?.packingextraqty : 0.0;
  let packingqty = item?.packingqty ? item.packingqty : 0.0;
  let itembatchno = item?.itembatchno ? item?.itembatchno : null;
  let expdate = item?.expdate ? item?.expdate : null;
  let conversionfactor = item?.conversionfactor ? item.conversionfactor : 1;

  let freepackingextraqty = item?.freepackingextraqty
    ? item?.freepackingextraqty
    : 0.0;
  let freepackingqty = item?.freepackingqty ? item?.freepackingqty : 0.0;
  let free =
    Number(freepackingqty) +
    Number(freepackingextraqty) * Number(conversionfactor);
  let qty =
    Number(packingqty) + Number(packingextraqty) * Number(conversionfactor);
  console.log("qty", qty);
  //{ Pradeep, task id-238  )  }
  // alert(packingqty + packingextraqty + conversionfactor);

  var discount = item?.discount ? item.discount : 0.0;
  var discountamt = item?.discountamt ? item.discountamt : 0.0;
  discount = Number((discountamt / purrate) * 100).toFixed(2);
  // discountamt = Number(discount/100 * purrate)

  var taxableamount =
    Number(qty) * Number(Number(purrate) - Number(discountamt));

  var sgstamt = !igstappl ? taxableamount * (sgstrate / 100) : 0.0;
  var cgstamt = !igstappl ? taxableamount * (cgstrate / 100) : 0.0;
  var igstamt = igstappl ? taxableamount * (igstrate / 100) : 0.0;

  var amount = igstappl
    ? taxableamount + igstamt
    : taxableamount + (cgstamt + sgstamt);

  var itemObject = {
    ...item,
    itemrecno: item.itemrecno,
    itemdescn: item.itemdescn,
    box: +item?.box,
    purrate: +purrate,
    taxableamount: taxableamount,
    ///{start Pradeep task-id -260 start }
    cgstrate: cgstrate,
    sgstrate: sgstrate,
    igstrate: igstrate,
    cgstamt: cgstamt?.toFixed(2),
    sgstamt: sgstamt?.toFixed(2),
    igstamt: igstamt?.toFixed(2),
    amount: amount?.toFixed(2),
    discount: discount,
    discountamt: discountamt,
    //{  end         end }
    packingextraqty: packingextraqty,
    packingqty: packingqty,
    qty: qty,
    free: free,
    expdate: expdate,
    itembatchno: itembatchno,
    freepackingextraqty: freepackingextraqty,
    freepackingqty: freepackingqty,
    shortguid: new Date(),
    itembatchno: item?.wantbatchno ? item.itembatchno : ".",
  };
  var currentItem = rowData.map((itm) => {
    if (itm.shortguid == item.shortguid) {
      return { ...itemObject };
    } else {
      return { ...itm };
    }
  });

  setRowData(() => currentItem);
};

export const handleRemove = (item, setRowData, rowData) => {
  var removedArray = removeByAttribute(rowData, "shortguid", item.shortguid);
  console.log("Remove", item, setRowData, rowData, removedArray);
  setRowData(() => [...removedArray]);
};

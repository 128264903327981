import { TransactionProvider } from "./RcTransactionContext";

import TransactionScreen from "./RcTransactionScreen";

function TransactionUI(props) {
  return (
    <TransactionProvider>
      <TransactionScreen vouchertype={props.vouchertype} mode="ADD" />
    </TransactionProvider>
  );
}

export { TransactionUI as RcTransactionUI };

import { Button, Checkbox, Collapse, Input, Modal, Radio, Space } from "antd";
import React, { useCallback, useContext, useEffect, useState } from "react";
import {
  getToday,
  dateFormatter,
  addDays,
  datedisplay,
  sortByTrDate,
  getUniqueElements,
  deSelectPreviuos,
} from "../../CommonControllers/AppFunction";

import { handleAdd, grnColumns, handleRemove } from "./GRNTableColumns";
import useSaveChangedRows from "../../CommonControllers/Hooks/useSaveChangedRows";
import useShortguid from "../../CommonControllers/Hooks/useShortGuid";
import { TabStore } from "../../TabsStructure/TabsStore";
import { AuthStore } from "../../Auth/AuthStore";
import "../../CommonControllers/global.css";
import { useRef } from "react";
import { currentTabData } from "../../CommonControllers/Variable";

import { List } from "antd";
import { FcAddDatabase } from "react-icons/fc";
import { FcDeleteDatabase } from "react-icons/fc";
import { apiCallwithBaseUrl } from "../../CommonControllers/ApiCalls";
import { itCH, tr } from "date-fns/locale";
import CallbackCheckbox from "../../CommonControllers/Components/CallbackComponents/CallbackCheckbox";
import SaveCheckbox from "../../CommonControllers/Components/SaveComponents/SaveCheckbox";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import noData from "../../Assets/noData.png";
function MultipleSelectionItem({
  options,
  returnValue,
  selectedItemsArray,
  count,
  selectOnlyOneDoc,
  setCount,
  forFlag,
  dcReturn,
  todate,pageDetails,isopening
}) {
  const useChangedRows = useSaveChangedRows();

  const [selectedDocument, setSelectedDocument] = useState(false);

  const { Panel } = Collapse;
  const auth = useContext(AuthStore);
  const shortguid = useShortguid();
  const tabStore = useContext(TabStore);
  const [mainArray, setMainArray] = useState([]);
  // -------------multiple selecton-----------------
  const [previousTransactionList, setPreviousTransactionList] = useState([]);
  const [filteredPreviousTransactionList, setFilteredPreviousTransactionList] =
    useState([]);
  const [filterValue, setFilterValue] = useState("");
  const [selectedItemsArray1, setSelectedItemsArray1] = useState([]);
  ///-----selectall----toggle----///
  const [selectall, setSelectAll] = useState(false);
  const [filterbillno, setFilterBillno] = useState("");
  const [buttonDisableDataStore, setStoreDisabledata] = useState([]);
  const [wantpagination, setWantPagination] = useState(true);
  const [serachquery, setSearchQuery] = useState("");

  function filterPreviousDocuementArray(event) {
    if (event?.keyCode == 13) {
      setWantPagination(false);
      setFilterBillno(serachquery); // Update the filterBillno state

      if (serachquery === "") {
        setWantPagination(true);
        // If input is empty, reset the filtered list to show all transactions
        setFilteredPreviousTransactionList(previousTransactionList);
      } else {
        // If there's a value, make an API call to search
        // This will be handled in useEffect based on the updated filterbillno
      }
    }
  }

  const [selectedOption, setSelectedOption] = useState(options[0]?.optionName);
  const [selectedDocType, setselectedDocType] = useState(options[0]?.doctype);

  const [showList, setShowList] = useState(true);
  const [returnArray, setreturnArray] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedporecno, setselectedporecno] = useState([]);
  const [showTable, setShowTable] = useState(false);

  //// select all functionality

  const [checkbox, setcheckbox] = useState(false);
  const [loading, setLoading] = useState(true);

  const [pageno, setPageNo] = useState(1);

  //////loading///
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 24,
      }}
      spin
    />
  );

  useEffect(() => {
    setShowTable(false);

    setTimeout(() => {
      setShowTable(true);
    }, 100);
  }, [
    selectedRows?.length,
    selectedItemsArray?.length,
    tabStore.state.tabs[tabStore.state.tabIndex]?.editIndex,
  ]);

  useEffect(() => {
    if (selectedItemsArray) {
      setMainArray(selectedItemsArray);
    }
    setSelectedDocument(false);
  }, [selectedItemsArray]);

  useEffect(() => {
    setTimeout(() => {
      if (setCount) {
        setCount((p) => p + 1);
      }
    }, 200);
  }, [count]);

  const handleRowClick = (id) => {
    if (selectedRows.includes(id)) {
      // Remove the id from the array if it already exists
      //setSelectedRows(selectedRows.filter((rowId) => rowId !== id));
    } else {
      // Add the id to the array if it doesn't exist
      setSelectedRows([...selectedRows, id]);
    }
  };
  function debounce(func, wait) {
    let timeout;
    return function (...args) {
      clearTimeout(timeout);
      timeout = setTimeout(() => func.apply(this, args), wait);
    };
  }

  //------------------end------------------------------------
  // const debouncedGetPreviousTransactionsList = useCallback(
  //       debounce(
  //           getPreviousTransactionsList,
  //        300),
  //       [] // Dependencies for the debounced function
  //   );

  useEffect(() => {
    setLoading(true);
    const selectedObject = options?.find(
      (item) => item.optionName === selectedOption
    );
    if (selectedObject) {
      getPreviousTransactionsList(selectedObject);
      setselectedDocType(selectedObject.doctype);
    }
    return () => {
      setselectedDocType(null);
    };
  }, [selectedOption, pageno, filterbillno]);

  // =========================function for return value=======================
  {
    /* Function to check the Array if the Item Exists
Data Structure : Main Item Array : 
 { Itemrecno : int 

Itemdescn : string 

SelectedQty : int 

RefDocuments : Array 

FreeQty : optional }

refDocuments Array : {
  Itemrecno : int 

Refdocumentno : string 

RefDocRecno : int 

Quantity 

freeQuantity 
}
Conditions to Be Checked : If Item Exists in the array
      Check if the Item from Same Document Exists
      If Exists : Change the internal array of RefDocuments : Update the Array's Quantity and Free Quantity
                  Reduce the Parent Items Quantity and FreeQuantiy by previous quantity and add the new Quantity
      IF Dosent Exist : Add a new Object to the refdocuments Array with the DocumentNumber ...
    If the total Quantity of the item is 0, remove from the Array
    return the array
*/
  }

  const handleChange = (
    selectionItem,
    selectedDocument,
    item,
    selectedQtys,
    isMulti
  ) => {
    const itemIndex = mainArray?.findIndex(
      // (item) => item.itemrecno === selectionItem.itemrecno
      ///{ Pradeep task id -348}   /// for adding same items in mainArray from different PO number based on recno of item
      (item) => item.itemrecno === selectionItem.itemrecno
    );



    if (
      Number(selectionItem.selectedQty) > 0 &&
      options[0].doctype !== "PENDING"
    ) {
      if (itemIndex === -1) {
        // Item doesn't exist in the main array
        const newRefDocument = {
          refdocrecno: selectedDocument,
          selectedQty: Number(selectionItem.selectedQty),
          freeQuantity: Number(selectionItem.freeQuantity) || 0,

          // changes for taxable amout

          doctype: selectedDocType,
          refshortguid: item.shortguid,
          refrecno: selectionItem.recno,
        };

        var y = mainArray;

        y.push({
          ...selectionItem,
          itemrecno: selectionItem.itemrecno,
          selectedQty: Number(selectionItem.selectedQty),
          freeQuantity: Number(selectionItem.freeQuantity) || 0,

          itembatchno: item?.wantbatchno == 0 ? "." : selectionItem.itembatchno,
          // changes for taxable amout

          doctype: selectedDocType,
          refshortguid: item.shortguid,
          refrecno: selectionItem.recno,
          expdate: selectionItem.expdate,

          refDocuments: [newRefDocument],
          drivername: item.drivername,
          vehicleno: item.vehicleno,
        });

        setMainArray([...y]);
      } else {
        // Item exists in the main array

        const itemIndexBatchArray = mainArray?.findIndex(
          // (item) => item.itemrecno === selectionItem.itemrecno
          ///{ Pradeep task id -348}   /// for adding same items in mainArray from different PO number based on recno of item
          (item) =>
            item.itemrecno === selectionItem.itemrecno &&
            item.itembatchno == selectionItem.itembatchno
        );

        if (itemIndexBatchArray === -1) {
          const newRefDocument = {
            refdocrecno: selectedDocument,
            selectedQty: Number(selectionItem.selectedQty),
            freeQuantity: Number(selectionItem.freeQuantity) || 0,

            // changes for taxable amout

            doctype: selectedDocType,
            refshortguid: item.shortguid,
            refrecno: selectionItem.recno,
          };

          var y = mainArray;

          y.push({
            ...selectionItem,
            itemrecno: selectionItem.itemrecno,
            selectedQty: Number(selectionItem.selectedQty),
            freeQuantity: Number(selectionItem.freeQuantity) || 0,

            itembatchno:
              item?.wantbatchno == 0 ? "." : selectionItem.itembatchno,
            // changes for taxable amout

            doctype: selectedDocType,
            refshortguid: item.shortguid,
            refrecno: selectionItem.recno,
            expdate: selectionItem.expdate,

            refDocuments: [newRefDocument],
            drivername: item.drivername,
            vehicleno: item.vehicleno,
          });

          setMainArray([...y]);
        } else {
          const refIndex = mainArray[
            itemIndexBatchArray
          ].refDocuments?.findIndex(
            (ref) => ref.refdocrecno == selectedDocument
          );

          if (refIndex === -1) {
            // Reference document doesn't exist in the item's refDocuments array

            const newRefDocument = {
              refdocrecno: selectedDocument,
              selectedQty: Number(selectionItem.selectedQty),
              freeQuantity: Number(selectionItem.freeQuantity) || 0,

              // changes for taxable amout
              doctype: selectedDocType,
              refshortguid: item.shortguid,
              refrecno: selectionItem.recno,
            };
            mainArray[itemIndexBatchArray].refDocuments.push(newRefDocument);
            mainArray[itemIndexBatchArray].selectedQty += Number(
              selectionItem.selectedQty
            );
            mainArray[itemIndexBatchArray].qty = Number(
              selectionItem.selectedQty
            ); //packingqty

            mainArray[itemIndexBatchArray].freeQuantity +=
              Number(selectionItem.freeQuantity) || 0;

            // changes for taxable amout
            mainArray[itemIndexBatchArray].doctype = selectedDocType;
            mainArray[itemIndexBatchArray].refshortguid = item.shortguid;
            mainArray[itemIndexBatchArray].refrecno = selectionItem.recno;
            mainArray[itemIndexBatchArray].itemasbatch =
              selectionItem.itemasbatch;
            mainArray[itemIndexBatchArray].itembatchno =
              selectionItem?.wantbatchno == 0 ? "." : selectionItem.itembatchno;
            /////////
            mainArray[itemIndexBatchArray].drivername = item.drivername;
            mainArray[itemIndexBatchArray].vehicleno = item.vehicleno;
            ////logic for concat the batcharray and itembacharray
            let newItemBatchArray =
              mainArray[itemIndexBatchArray].itembatcharray;

            // Assuming newItemBatchArray is the array of objects you want to concatenate
            let arrayToConcatenate = [
              ...(newItemBatchArray ?? []),
              ...(selectionItem?.itembatcharray ?? []),
            ];

            mainArray[itemIndexBatchArray].itembatcharray = arrayToConcatenate;
            //// batcharray
            let newItemBatch = mainArray[itemIndexBatchArray].batcharray;

            // Assuming newItemBatchArray is the array of objects you want to concatenate
            let arrayToCon = [
              ...(newItemBatch ?? []),
              ...(selectionItem?.batcharray ?? []),
            ];

            mainArray[itemIndexBatchArray].batcharray = arrayToCon;
            ////
          } else {
            // Reference document exists in the item's refDocuments array

            mainArray[itemIndexBatchArray].selectedQty -= Number(
              mainArray[itemIndexBatchArray].refDocuments[refIndex].selectedQty
            );
            mainArray[itemIndexBatchArray].freeQuantity -=
              Number(
                mainArray[itemIndexBatchArray].refDocuments[refIndex]
                  .freeQuantity
              ) || 0;
            mainArray[itemIndexBatchArray].refDocuments[refIndex].selectedQty =
              Number(selectionItem.selectedQty);
            mainArray[itemIndexBatchArray].refDocuments[refIndex].freeQuantity =
              Number(selectionItem.freeQuantity) || 0;
            mainArray[itemIndexBatchArray].selectedQty += Number(
              selectionItem.selectedQty
            );
            mainArray[itemIndexBatchArray].qty = Number(
              selectionItem.selectedQty
            );
            mainArray[itemIndexBatchArray].freeQuantity +=
              Number(selectionItem.freeQuantity) || 0;

            // changes for taxable amout
            mainArray[itemIndexBatchArray].doctype = selectedDocType;
            mainArray[itemIndexBatchArray].refshortguid = item.shortguid;
            mainArray[itemIndexBatchArray].refrecno = selectionItem.recno;
            mainArray[itemIndexBatchArray].itemasbatch =
              selectionItem?.itemasbatch;
            mainArray[itemIndexBatchArray].itembatchno =
              selectionItem?.wantbatchno ? "." : selectionItem.itembatchno;

            /////////////////////
            // mainArray[itemIndexBatchArray].drivername = "prda";;
            // mainArray[itemIndexBatchArray].vehicleno  ="12345";;
            mainArray[itemIndexBatchArray].drivername = item.drivername;
            mainArray[itemIndexBatchArray].vehicleno = item.vehicleno;
            ////////
          }
        }
      }
      setSelectedItemsArray1(mainArray);

      setSelectedRows((prev) => [...prev, selectionItem?.recno]);

      if (!isMulti) {
        returnValue(mainArray);
      } else {
        return mainArray;
      }
      //color of row will change into green
      // handleRowClick(selectionItem?.recno);
    } else if (options[0].doctype == "PENDING") {
      if (itemIndex === -1) {
        // Item doesn't exist in the main array
        const newRefDocument = {
          refdocrecno: selectedDocument,
          selectedQty: Number(selectionItem.selectedQty),
          freeQuantity: Number(selectionItem.freeQuantity) || 0,

          // changes for taxable amout

          doctype: selectedDocType,
          refshortguid: item.shortguid,
          refrecno: selectionItem.recno,
        };

        var y = mainArray;

        y.push({
          ...selectionItem,
          itemrecno: selectionItem.itemrecno,
          selectedQty: Number(selectionItem.selectedQty),
          freeQuantity: Number(selectionItem.freeQuantity) || 0,

          itembatchno: item?.wantbatchno == 0 ? "." : selectionItem.itembatchno,
          // changes for taxable amout

          doctype: selectedDocType,
          refshortguid: item.shortguid,
          refrecno: selectionItem.recno,
          expdate: selectionItem.expdate,

          refDocuments: [newRefDocument],
          drivername: item.drivername,
          vehicleno: item.vehicleno,
        });

        setMainArray([...y]);
      } else {
        // Item exists in the main array

        const itemIndexBatchArray = mainArray?.findIndex(
          // (item) => item.itemrecno === selectionItem.itemrecno
          ///{ Pradeep task id -348}   /// for adding same items in mainArray from different PO number based on recno of item
          (item) =>
            item.itemrecno === selectionItem.itemrecno &&
            item.itembatchno == selectionItem.itembatchno
        );

        if (itemIndexBatchArray === -1) {
          const newRefDocument = {
            refdocrecno: selectedDocument,
            selectedQty: Number(selectionItem.selectedQty),
            freeQuantity: Number(selectionItem.freeQuantity) || 0,

            // changes for taxable amout

            doctype: selectedDocType,
            refshortguid: item.shortguid,
            refrecno: selectionItem.recno,
          };

          var y = mainArray;

          y.push({
            ...selectionItem,
            itemrecno: selectionItem.itemrecno,
            selectedQty: Number(selectionItem.selectedQty),
            freeQuantity: Number(selectionItem.freeQuantity) || 0,

            itembatchno:
              item?.wantbatchno == 0 ? "." : selectionItem.itembatchno,
            // changes for taxable amout

            doctype: selectedDocType,
            refshortguid: item.shortguid,
            refrecno: selectionItem.recno,
            expdate: selectionItem.expdate,

            refDocuments: [newRefDocument],
            drivername: item.drivername,
            vehicleno: item.vehicleno,
          });

          setMainArray([...y]);
        } else {
          const refIndex = mainArray[
            itemIndexBatchArray
          ].refDocuments?.findIndex(
            (ref) => ref.refdocrecno == selectedDocument
          );

          if (refIndex === -1) {
            // Reference document doesn't exist in the item's refDocuments array

            const newRefDocument = {
              refdocrecno: selectedDocument,
              selectedQty: Number(selectionItem.selectedQty),
              freeQuantity: Number(selectionItem.freeQuantity) || 0,

              // changes for taxable amout
              doctype: selectedDocType,
              refshortguid: item.shortguid,
              refrecno: selectionItem.recno,
            };
            mainArray[itemIndexBatchArray].refDocuments.push(newRefDocument);
            mainArray[itemIndexBatchArray].selectedQty += Number(
              selectionItem.selectedQty
            );
            mainArray[itemIndexBatchArray].qty = Number(
              selectionItem.selectedQty
            ); //packingqty

            mainArray[itemIndexBatchArray].freeQuantity +=
              Number(selectionItem.freeQuantity) || 0;

            // changes for taxable amout
            mainArray[itemIndexBatchArray].doctype = selectedDocType;
            mainArray[itemIndexBatchArray].refshortguid = item.shortguid;
            mainArray[itemIndexBatchArray].refrecno = selectionItem.recno;
            mainArray[itemIndexBatchArray].itemasbatch =
              selectionItem.itemasbatch;
            mainArray[itemIndexBatchArray].itembatchno =
              selectionItem?.wantbatchno == 0 ? "." : selectionItem.itembatchno;
            /////////
            mainArray[itemIndexBatchArray].drivername = item.drivername;
            mainArray[itemIndexBatchArray].vehicleno = item.vehicleno;
            ////logic for concat the batcharray and itembacharray
            let newItemBatchArray =
              mainArray[itemIndexBatchArray].itembatcharray;

            // Assuming newItemBatchArray is the array of objects you want to concatenate
            let arrayToConcatenate = [
              ...(newItemBatchArray ?? []),
              ...(selectionItem?.itembatcharray ?? []),
            ];

            mainArray[itemIndexBatchArray].itembatcharray = arrayToConcatenate;
            //// batcharray
            let newItemBatch = mainArray[itemIndexBatchArray].batcharray;

            // Assuming newItemBatchArray is the array of objects you want to concatenate
            let arrayToCon = [
              ...(newItemBatch ?? []),
              ...(selectionItem?.batcharray ?? []),
            ];

            mainArray[itemIndexBatchArray].batcharray = arrayToCon;
            ////
          } else {
            // Reference document exists in the item's refDocuments array

            mainArray[itemIndexBatchArray].selectedQty -= Number(
              mainArray[itemIndexBatchArray].refDocuments[refIndex].selectedQty
            );
            mainArray[itemIndexBatchArray].freeQuantity -=
              Number(
                mainArray[itemIndexBatchArray].refDocuments[refIndex]
                  .freeQuantity
              ) || 0;
            mainArray[itemIndexBatchArray].refDocuments[refIndex].selectedQty =
              Number(selectionItem.selectedQty);
            mainArray[itemIndexBatchArray].refDocuments[refIndex].freeQuantity =
              Number(selectionItem.freeQuantity) || 0;
            mainArray[itemIndexBatchArray].selectedQty += Number(
              selectionItem.selectedQty
            );
            mainArray[itemIndexBatchArray].qty = Number(
              selectionItem.selectedQty
            );
            mainArray[itemIndexBatchArray].freeQuantity +=
              Number(selectionItem.freeQuantity) || 0;

            // changes for taxable amout
            mainArray[itemIndexBatchArray].doctype = selectedDocType;
            mainArray[itemIndexBatchArray].refshortguid = item.shortguid;
            mainArray[itemIndexBatchArray].refrecno = selectionItem.recno;
            mainArray[itemIndexBatchArray].itemasbatch =
              selectionItem?.itemasbatch;
            mainArray[itemIndexBatchArray].itembatchno =
              selectionItem?.wantbatchno ? "." : selectionItem.itembatchno;

            /////////////////////
            // mainArray[itemIndexBatchArray].drivername = "prda";;
            // mainArray[itemIndexBatchArray].vehicleno  ="12345";;
            mainArray[itemIndexBatchArray].drivername = item.drivername;
            mainArray[itemIndexBatchArray].vehicleno = item.vehicleno;
            ////////
          }
        }
      }
      setSelectedItemsArray1(mainArray);

      setSelectedRows((prev) => [...prev, selectionItem?.recno]);

      if (!isMulti) {
        returnValue(mainArray);
      } else {
        return mainArray;
      }
      //color of row will change into green
      // handleRowClick(selectionItem?.recno);
    }
    //}
  };
  // =========================================================================

  /////////

  ///{ start Pradeep task id -266 start }   ///add multiselection PO functionality using common checkbox
  const handleaddallitems = (e, selectionItem) => {
    // selectAll()

    //alert("Hellooo")
    let yy = selectionItem;

    const newRefDocument = {
      refdocrecno: selectedDocument,
      selectedQty: Number(selectionItem.selectedQty),
      freeQuantity: Number(selectionItem.freeQuantity) || 0,

      // changes for taxable amout
      doctype: selectedDocType,
      refshortguid: selectionItem.shortguid,
      refrecno: selectionItem.recno,
    };

    if (e?.target?.checked) {
      for (let i = 0; i < selectionItem?.items?.length; i++) {
        //selectionItem.items[i]["refDocuments"]=[...newRefDocument]
        yy.push(selectionItem?.items[i]);
        selectedRows.push(selectionItem?.items[i]?.recno);
      }
      /////{Pradeep task-id -348  Remove Duplicate elements from array based on recno }
      var x = [];
      var localrecno = [];
      yy.map((item) => {
        if (!localrecno.includes(item.recno)) {
          x.push(item);
          localrecno.push(item.recno);
        }
      });

      // setMainArray(x);
      // setselectedporecno(x);
      // returnValue(x);
      setMainArray(yy);
      setselectedporecno(yy);
      returnValue(yy);
      handleRowClick(selectedRows);
    }

    ///{end              end}
    else {
      // setSelectedRows([])

      // mainArray.filter((it)=>it?.recno=="11")
      // returnValue([])
      var y = mainArray;

      for (let i = 0; i < selectionItem?.items?.length; i++) {
        // mainArray.push(selectionItem?.items[i])
        // selectedporecno.push(selectionItem?.items[i])

        y = y.filter((item) => item.recno !== selectionItem.items[i].recno);
        setMainArray(y);
        setselectedporecno(y);

        const index = selectedRows.indexOf(selectionItem.items[i].recno);
        if (index > -1) {
          // only splice array when item is found=
          //{Pradeep task id-383}
          selectedRows.splice(
            index,
            selectionItem?.items?.length == 1 ? 2 : selectionItem?.items?.length
          ); // 2nd parameter in splice method remove elements from array
        }
      }
      returnValue(y);
    }

    // setcheckbox(e?.target?.checked)
    setcheckbox(!checkbox);
  };
  ///{end   Pradeep task id -266   end }

  // Updated Function for HandleSelectAll

  const selectAll = (e, selectionItem) => {
    // Get the Current Selected Item
    // Map through the items add items to the existing array
    // Add to existing Items, if this document is not already present.
    if (e.target.checked) {
      var returnedItems = [];
      var rows = selectionItem.items?.map((item) => {
        item.selectedQty = item.remainingqty;
        returnedItems = handleChange(
          item,
          selectionItem?.ponumber
            ? selectionItem.ponumber
            : selectionItem?.orderno
            ? selectionItem.orderno
            : selectionItem?.billno,
          selectionItem,
          0,
          true
        );
      });

      returnValue(returnedItems);
    } else {
      var newMainArray = [...mainArray];
      var newDatList = [...selectedRows];

      var rows = selectionItem.items?.map((item) => {
        newMainArray = newMainArray.filter((row) => row.recno !== item.recno);
        newDatList = newDatList.filter((row) => row !== item.recno);
      });

      setSelectedRows(newDatList);
      setMainArray(newMainArray);

      if (returnValue) {
        returnValue(newMainArray);
      }
    }
  };

  async function getPreviousTransactionsList(selection) {
    /// here pending documents logics start

    var payload = {};
    if (options[0].doctype == "PENDING") {
      payload = {
        supplierrecno: selection?.supplierrecno,
        customerrecno: selection?.customerrecno,
        tenantrecno: auth.state.userdata.tenantrecno,
        domainrecno: auth.state.userdata.domainrecno,
        userrolerecno: auth.state.userdata.userrolerecno,
        withitems: true,
        forflag: forFlag,
        dcreturn: dcReturn,
        userrolerecno: auth.state.userdata.userrolerecno,
        fortrn: "Y",
        trnedit: 1,
        todate: todate,
        wantpagination: wantpagination,
        pageno: pageno,
        noofrec: 10,
        billno: filterbillno,
      };
    } else if (forFlag == "pi") {
      payload = {
        supplierrecno: selection?.supplierrecno,
        tenantrecno: auth?.state?.userdata?.tenantrecno,
        domainrecno: auth?.state?.userdata?.domainrecno,
        domainuserrecno: auth?.state?.userdata?.recno,
        fastpath: "TR004",
        // ...selectedFilterOptions,
        status: "9",
        //forreturn:forreturn?forreturn:false,
        userrolerecno: auth.state.userdata.userrolerecno,
        forflag: forFlag,
        fortrn: "Y",
        todate: todate,
        wantpagination: wantpagination,
        pageno: pageno,
        noofrec: 10,
        billno: filterbillno,
        /// forreturn is added  date 19/04/023 for  both PR SR by rabin
      };
    } else if (forFlag == "si") {
    
      payload = {
        supplierrecno: selection?.supplierrecno,
        customerrecno: selection?.customerrecno,
        tenantrecno: auth.state.userdata.tenantrecno,
        domainrecno: auth.state.userdata.domainrecno,
        userrolerecno: auth.state.userdata.userrolerecno,
        withitems: true,
        forflag:
          selectedOption === "Sale Invoice" &&
          currentTabData(tabStore)?.name === "TR014"
            ? "dcfromsi"
            : forFlag,
        dcreturn: dcReturn,
        userrolerecno: auth.state.userdata.userrolerecno,
        fortrn: "Y",
        todate: todate,
        wantpagination: wantpagination,
        pageno: pageno,
        noofrec: 10,
        billno: filterbillno,
        isopening:isopening?true:false
      };
    }

    /// here pending documents logics end
    else {
      payload = {
        supplierrecno: selection?.supplierrecno,
        customerrecno: selection?.customerrecno,
        tenantrecno: auth.state.userdata.tenantrecno,
        domainrecno: auth.state.userdata.domainrecno,
        userrolerecno: auth.state.userdata.userrolerecno,
        withitems: true,
        forflag:
          selectedOption === "Sale Invoice" &&
          currentTabData(tabStore)?.name === "TR014"
            ? "dcfromsi"
            : forFlag,
        dcreturn: dcReturn,
        userrolerecno: auth.state.userdata.userrolerecno,
        fortrn: "Y",
        todate: todate,
        wantpagination: wantpagination,
        pageno: pageno,
        noofrec: 10,
        billno: filterbillno,
      };
    }
    if (
      selection?.supplierrecno
        ? selection.supplierrecno
        : selection?.customerrecno
    ) {
      var res = await apiCallwithBaseUrl("POST", selection?.apiurl, payload); /// here pending documents logics here paload is take as per conditions

      /// logic for modify the item batch array and batch rray instroment number added  start date 23 nov 23
      setStoreDisabledata(res.Message);
      const updatedResponseMessage = res.Message?.map((document) => {
        const updatedItems = document?.items.map((items) => {
          const updatedItemBachArr = items?.itembatcharray?.map((batch) => {
            return { ...batch, docno: document.instrumentno };
          });
          return {
            ...items,
            itembatcharray: updatedItemBachArr,
            batcharray: updatedItemBachArr,
          };
        });
        return { ...document, items: updatedItems };
      });
      const updatedResponseMessamodify = updatedResponseMessage?.map(
        (document) => {
          const updatedItems1 = document?.items.map((items) => {
            const updatedItemBachArr1 = items?.batcharray?.map((batch) => {
              return { ...batch, docno: document.instrumentno };
            });
            return { ...items, batcharray: updatedItemBachArr1 };
          });
          return { ...document, items: updatedItems1 };
        }
      );
      res.Message = updatedResponseMessamodify;
      // res.Message = updatedResponseMessage;
      //// here is the end of logic start date 23 nov 23
      console.log("Datat===>", updatedResponseMessamodify, res.Message);

      if (res.Success) {
        // Here we will remove items which are 0 and Documents with 0

        /// here pending documents logics
        if (options[0].doctype == "PENDING") {
          setLoading(false);
          var newTempArray = [];
          for (let i = 0; i < res.Message.length; i++) {
            let newItemTempArray = [];
            for (let k = 0; k < res.Message[i]?.items.length; k++) {
              if (Number(res.Message[i]?.items[k].selectedQty) >= 0) {
                res.Message[i].items[k].remainingqty =
                  res.Message[i]?.items[k].selectedQty;
                newItemTempArray.push(res.Message[i]?.items[k]);
              }
            }

            if (newItemTempArray.length > 0) {
              newTempArray.push({
                ...res.Message[i],
                items: newItemTempArray,
              });
            }
          }
          /// here pending documents logics
        }
        /// here Pick from pi start
        else if (forFlag == "pi") {
          setLoading(false);
          var newTempArray = [];
          for (let i = 0; i < res.Message.length; i++) {
            let newItemTempArray = [];
            for (let k = 0; k < res.Message[i]?.items.length; k++) {
              if (Number(res.Message[i]?.items[k].selectedQty) > 0) {
                res.Message[i].items[k].remainingqty =
                  res.Message[i]?.items[k].selectedQty;
                newItemTempArray.push(res.Message[i]?.items[k]);
              }
            }

            if (newItemTempArray.length > 0) {
              newTempArray.push({
                ...res.Message[i],
                items: newItemTempArray,
              });
            }
          }
        }
        /// here Pick from pi end
        else {
          setLoading(false);
          var newTempArray = [];
          for (let i = 0; i < res.Message.length; i++) {
            let newItemTempArray = [];
            for (let k = 0; k < res.Message[i]?.items.length; k++) {
              if (Number(res.Message[i]?.items[k].selectedQty) > 0) {
                res.Message[i].items[k].remainingqty =
                  res.Message[i]?.items[k].selectedQty;
                newItemTempArray.push(res.Message[i]?.items[k]);
              }
            }

            if (newItemTempArray.length > 0) {
              newTempArray.push({
                ...res.Message[i],
                items: newItemTempArray,
              });
            }
          }
        }

        newTempArray = sortByTrDate(newTempArray, "recno", false);

        setPreviousTransactionList(newTempArray);
        setFilteredPreviousTransactionList(newTempArray);
        /////// added this
        // returnValue(newTempArray)
        ///////
      } else {
        alert(res.Message);
      }
    }
  }

  //----------------------------------------------------------

  /// DESELECT FUNCTIONALITY//// date 19 april 2023
  // const deselectFun = (item) => {
  //   console.log("Item is", selectedItemsArray,item)
  //   let fiterdata = selectedItemsArray?.filter(
  //     (it) => it?.recno !== item.recno
  //   );

  //   let newFilterdata = selectedItemsArray?.map((it) => {

  //     if (item.itemrecno == it.itemrecno && item.itembatchno == it.itembatchno) {
  //       if (Number(it.selectedQty) >=Number(item.selectedQty)) {

  //         it.qty = it.qty - item.qty
  //         it.selectedQty = it?.selectedQty - item?.selectedQty
  //         it.cgstamt = it?.cgstamt - item?.cgstamt
  //         it.sgstamt = it?.sgstamt - item?.sgstamt
  //         it.igstamt = it?.igstamt - item?.igstamt
  //         it.taxableamt = it?.taxableamt - item?.taxableamt
  //         it.amount = it?.amount - item?.amount
  //         it.discountamt = it?.discountamt - item?.discountamt
  //         let itembatcharray = getUniqueElements(it.itembatcharray, item.itembatcharray, "serialno")
  //         it.itembatcharray = itembatcharray
  //         it.batcharray = itembatcharray

  //         console.log("Updated item is", it)
  //         return it
  //       } else {
  //         return null
  //       }
  //     } else {
  //       return it
  //     }
  //   })

  //   console.log("New Filter Data is",newFilterdata,newFilterdata)

  //   setMainArray(newFilterdata?newFilterdata:[]);
  //   returnValue(newFilterdata?newFilterdata:[]);
  //   setSelectedRows(selectedRows.filter((row) => row !== item.recno));
  // };
  const deselectFun = (item) => {
    console.log("Item is", selectedItemsArray, item);
    let fiterdata = selectedItemsArray?.filter(
      (it) => it?.recno !== item.recno
    );

    let newFilterdata = selectedItemsArray?.map((it) => {
      if (
        item.itemrecno == it.itemrecno &&
        item.itembatchno == it.itembatchno
      ) {
        if (Number(it.selectedQty) > Number(item.selectedQty)) {
          it.qty = it.qty - item.qty;
          it.selectedQty = it?.selectedQty - item?.selectedQty;
          it.cgstamt = it?.cgstamt - item?.cgstamt;
          it.sgstamt = it?.sgstamt - item?.sgstamt;
          it.igstamt = it?.igstamt - item?.igstamt;
          it.taxableamt = it?.taxableamt - item?.taxableamt;
          it.amount = it?.amount - item?.amount;
          it.discountamt = it?.discountamt - item?.discountamt;
          let itembatcharray = getUniqueElements(
            it.itembatcharray,
            item.itembatcharray,
            "serialno"
          );
          it.itembatcharray = itembatcharray;
          it.batcharray = itembatcharray;

          console.log("Updated item is", it);
          return it;
        } else {
          return null; // Don't include the item in the array
        }
      } else {
        return it;
      }
    });

    // Remove null entries from the array
    newFilterdata = newFilterdata.filter((it) => it && it.recno !== item.recno);

    console.log("New Filter Data is", newFilterdata, newFilterdata);

    setMainArray(newFilterdata ? newFilterdata : []);
    returnValue(newFilterdata ? newFilterdata : []);
    setSelectedRows(selectedRows.filter((row) => row !== item.recno));
  };

  return (
    //----------multiple selection item start------------------------------------------------
    <div>
      <div style={{ display: "flex" }}>
        {/* Apply Styling to the DIV */}
        <Radio.Group
          onChange={(e) => setSelectedOption(e.target.value)}
          value={selectedOption}
        >
          {options.map((item) => {
            return <Radio value={item.optionName}>{item.optionName}</Radio>;
          })}
        </Radio.Group>

        <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
          <Button
            onClick={() => setPageNo((pageno) => pageno - 1)}
            disabled={pageno <= 1}
            style={{
              padding: "8px 12px",
              borderRadius: "5px",
              border: "none",
              backgroundColor: pageno <= 1 ? "#d3d3d3" : "#4CAF50",
              color: "white",
              cursor: pageno <= 1 ? "not-allowed" : "pointer",
              fontSize: "16px",
            }}
          >
            &laquo; {/* Double Left Arrow */}
          </Button>

          <span style={{ fontSize: "16px", fontWeight: "bold" }}>{pageno}</span>

          <Button
            onClick={() => setPageNo((pageno) => pageno + 1)}
            disabled={buttonDisableDataStore?.length === 0}
            style={{
              padding: "8px 12px",
              borderRadius: "5px",
              border: "none",
              backgroundColor:
                filteredPreviousTransactionList.length === 0
                  ? "#d3d3d3"
                  : "#4CAF50",
              color: "white",
              cursor:
                filteredPreviousTransactionList.length === 0
                  ? "not-allowed"
                  : "pointer",
              fontSize: "16px",
            }}
          >
            &raquo; {/* Double Right Arrow */}
          </Button>
        </div>
      </div>
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "20vh",
          }}
        >
          <Spin
            indicator={
              <LoadingOutlined
                style={{ fontSize: 40, color: "blueviolet" }}
                spin
              />
            }
          />
        </div>
      ) : (
        <>
          {" "}
          {previousTransactionList?.length > 0 && !selectOnlyOneDoc ? (
            <div>
              Select Items from Pending {`${selectedOption}`}
              <div style={{ padding: "5px" }}>
                <input
                  placeholder="Document"
                  className="searchBox"
                  value={serachquery}
                  onChange={(e) => {
                    console.log("Ketan===>",e)
                    setSearchQuery(e.target.value);
                    if (e.target.value === "") {
                      setFilterBillno(""); // Reset the filterBillno
                      setWantPagination(true);
                      // Make the API call to fetch all data when the input is cleared
                      filterPreviousDocuementArray();
                    }
                  }}
                  onKeyDown={filterPreviousDocuementArray}
                />
              </div>
              <Collapse
                collapsible="header"
                defaultActiveKey={["0"]}
                style={{ height: 300, overflowX: "scroll" }}
              >
                {filteredPreviousTransactionList?.map((item, index) => {
                  const quantityAvailable = item?.items?.reduce(
                    (acc, el) => (acc += Number(el?.selectedQty)),
                    0
                  );

                  //used selectedQtys variable beacuse its changing the value in reming also
                  var selectedQtys = quantityAvailable;

                  var date = dateFormatter(item.trdate);

                  if (quantityAvailable > 0) {
                    return (
                      <Panel
                        header={`${selectedOption} : ${
                          item?.ponumber
                            ? item.ponumber
                            : item?.orderno
                            ? item.orderno
                            : item?.billno
                        } | Date :${dateFormatter(item.trdate)} | Amount :${
                          item?.amount ? item.amount : item?.grossamount
                        } `}
                        key={index}
                      >
                        <div style={{ display: "flex", marginLeft: 430 }}>
                          {/* <Button
                        icon={<FcAddDatabase />}
                        onClick={() => {
                          handleaddallitems(
                            item
                           
                          );
                        }}
                      ></Button> */}

                          {/* { start Pradeep task id -266 start  */}
                          <input
                            id={index}
                            type="checkbox"
                            onChange={(e) => {
                              selectAll(e, item);
                            }}
                            checked={
                              selectall
                                ? false
                                : selectedporecno.find((it) => {
                                    if (it.recno == item?.recno) {
                                      return true;
                                    } else {
                                      return false;
                                    }
                                  })
                            }
                          />
                          {/* <input id={index} type="checkbox" 
                      onChange={(e) => { 
                        handleaddallitems( e,item) }} checked={checkbox} /> */}

                          <label> Select all </label>
                          {/* end             end */}
                        </div>
                        <List
                          size="small"
                          itemLayout="horizontal"
                          bordered
                          dataSource={item?.items}
                          renderItem={(it, ind) => {
                            it.remainingqty = it?.remainingqty
                              ? it.remainingqty
                              : it?.qty;
                            //   <div style={{marginLeft:500}}>
                            //   <Button
                            //    icon={<FcAddDatabase />}
                            //    onClick={() => {
                            //      handleaddallitems(
                            //        it,
                            //        item?.ponumber
                            //          ? item.ponumber
                            //          : item?.orderno
                            //          ? item.orderno
                            //          : item?.billno,
                            //        item

                            //      );
                            //    }}
                            //  ></Button>
                            //  </div>

                            return (
                              <List.Item
                                key={it?.recno}
                                style={{
                                  background: selectedRows?.includes(it?.recno)
                                    ? "#0080007a"
                                    : "white",
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    width: "100%",
                                    alignItems: "center",
                                  }}
                                >
                                  <div
                                    style={{
                                      flex: 3,
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    {it?.itemdescn} <br></br>Quantity :{" "}
                                    {it?.qty} &nbsp; Remaining Qty :{" "}
                                    {it?.remainingqty}
                                  </div>
                                  <input
                                    className="tr001Input"
                                    style={{ width: 80, flex: 1 }}
                                    type={"number"}
                                    defaultValue={it?.remainingqty}
                                    //qty should be save on entering the value
                                    // onBlur={(e) => {

                                    //   if (Number(it.selectedQty) <= Number(it?.remainingqty)) {
                                    //     handleChange(
                                    //       it,
                                    //       item?.ponumber
                                    //         ? item.ponumber
                                    //         : item?.orderno
                                    //         ? item.orderno
                                    //         : item?.billno,
                                    //       item

                                    //     );
                                    //   } else {
                                    //     e.target.value = it?.remainingqty// added for initialize the previous quantity
                                    //     it.selectedQty = 0;
                                    //     alert("Invalid Value")
                                    //   }
                                    // }}
                                   onBlur={(e) => {
                                      it.selectedQty = e.target.value;
                                      if (
                                        Number.isInteger(Number(it.selectedQty))
                                      ) {
                                        // Check if the input value is an integer
                                        if (
                                          Number(it.selectedQty) >
                                          Number(it?.remainingqty)
                                        ) {
                                          alert(
                                            `Selected Quantity Should be less than Or Equal to Remaining Quantity`
                                          );
                                          e.target.value = it?.remainingqty; // Set input value to remaining quantity
                                          it.selectedQty = it?.remainingqty;
                                        } else if (
                                          Number(it.selectedQty) <= 0
                                        ) {
                                          e.target.value = it?.remainingqty; // Set input value to remaining quantity
                                          it.selectedQty = it?.remainingqty;
                                        } else {
                                          it.selectedQty = Math.floor(
                                            e.target.value
                                          );
                                        }
                                      } else {
                                        // If input value is a decimal, reset it to remaining quantity
                                        e.target.value = it?.remainingqty;
                                        it.selectedQty = it?.remainingqty;
                                        handleChange(
                                          it,
                                          item?.ponumber
                                            ? item.ponumber
                                            : item?.orderno
                                            ? item.orderno
                                            : item?.billno,
                                          item
                                        );
                                      }
                                      // if (Number(it.selectedQty) <= Number(it?.remainingqty)) {

                                      handleChange(
                                        it,
                                        item?.ponumber
                                          ? item.ponumber
                                          : item?.orderno
                                          ? item.orderno
                                          : item?.billno,
                                        item
                                      );
                                      //       }
                                    }}
                                  ></input>
                                  <div
                                    style={{
                                      flex: 1,
                                      display: "flex",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <Button
                                      icon={<FcAddDatabase />}
                                      disabled={
                                        selectedRows?.includes(it?.recno)
                                          ? true
                                          : false
                                      }
                                      onClick={() => {
                                        handleChange(
                                          it,
                                          item?.ponumber
                                            ? item.ponumber
                                            : item?.orderno
                                            ? item.orderno
                                            : item?.billno,
                                          item
                                        );
                                      }}
                                    ></Button>
                                    <Button
                                      icon={<FcDeleteDatabase />}
                                      onClick={() => {
                                        /// deselect fuctionality 19 april 2023
                                        console.log("RDYKDFYT", it);
                                        setSelectAll((p) => !p);
                                        deselectFun(it);
                                      }}
                                    ></Button>
                                  </div>
                                </div>
                              </List.Item>
                            );
                          }}
                        />
                      </Panel>
                    );
                  }
                })}
              </Collapse>
            </div>
          ) : filteredPreviousTransactionList?.length > 0 &&
            selectOnlyOneDoc ? (
            <div style={{ height: "350px", overflow: "scroll" }}>
              <List
                size="small"
                bordered
                dataSource={filteredPreviousTransactionList}
                renderItem={(item) => (
                  <List.Item>
                    <div style={{ display: "flex", width: "100%" }}>
                      <p style={{ flex: 4 }}>
                        {item?.ponumber
                          ? item.ponumber
                          : item?.orderno
                          ? item.orderno
                          : item?.billno}{" "}
                        &nbsp; | &nbsp; Date :{dateFormatter(item.trdate)}{" "}
                        &nbsp; | &nbsp; Amount :
                        {item?.amount ? item.amount : item?.grossamount}
                      </p>
                      <div style={{ flex: 1 }}>
                        <Checkbox
                          disabled={
                            !selectedDocument
                              ? false
                              : item?.recno == selectedDocument
                              ? false
                              : true
                          }
                          // disabled={false}
                          defaultChecked={
                            selectedDocument && item?.recno == selectedDocument
                              ? true
                              : false
                          }
                          onChange={(e) => {
                            // Add to The Table and Dont Let it Add
                            if (e.target.checked == true && !selectedDocument) {
                              setSelectedDocument(item?.recno);
                              item?.items?.map((it) => {
                                handleChange(
                                  it,
                                  item?.ponumber
                                    ? item.ponumber
                                    : item?.orderno
                                    ? item.orderno
                                    : item?.billno,
                                  item
                                );
                              });
                            } else if (e.target.checked == false) {
                              setSelectedDocument(false);
                              returnValue([]);
                            }
                          }}
                        />
                      </div>
                    </div>
                  </List.Item>
                )}
              />
            </div>
          ) : (
            <div>
              {" "}
              <div className="noDataFound">
                <h1>Whoops! No Records Found</h1>
                <img src={noData} />
              </div>
            </div>
          )}
        </>
      )}
    </div>
    //----------multiple selection item end------------------------------------------------
  );
}

export default MultipleSelectionItem;

const mainFrame = {
  width: "99vw",
  margin: "auto",
};

const topRowFilters = {
  gap: "24px",
  display: "flex",
  alignItems: "center",
};

// applying padding to align input rows inline
const callbackTextInputStyles = {
  display: "flex",
  flexWrap: "wrap",
  alignItems: "center",
  gap: "36px",
  paddingTop: "38px",
};

// add to table btn styles
const btnStyles = {
  display: "flex",
  alignItems: "center",
  gap: "8px",
  marginTop: "26px",
};

const inputStyles = {
  padding: "10px",
  border: "1px solid rgb(120,120,120)",
  width: "100px",
  fontSize: "16px",
  marginTop: "7px",
  borderRadius: "6px",
};

const grossAmtStyle = {
  textAlign: "right",
  border: "1px solid gray",
  padding: "4px 8px 4px 4px",
  fontSize: "18px",
  width: "120px",
  borderRadius: "8px",
  marginTop: "6px",
};

const poNumberStyle = {
  marginTop: "4px",
  border: "1px solid #dfdfdf",
  borderRadius: "8px",
  width: "200px",
  padding: "8px",
  fontSize: "16px",
};

const selected = {
  background: "#00800021",
};

//~# getdomainsupplieritemlist api endpoint for Item name => pass payload as { supplierrecno : 78123 } for item name
//~# conversionfactor = boxes * conversionfactor + pieces = qty

import React, { useContext, useEffect, useState } from "react";
import { TabStore } from "./TabsStore";
import Viewscreen from "./ViewScreen";
import { Alert, Button, Modal, Space, Tooltip } from "antd";
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { RxCross2 } from "react-icons/rx";
import Dashboard from "../Screens/Dashboard";
import { AuthStore } from "../Auth/AuthStore";

function MainTab() {
  const tabStore = useContext(TabStore);
  const [closingTabIndex, setClosingTabIndex] = useState(null);
  const [closeModal, setCloseModal] = useState(false);
  const auth = useContext(AuthStore);
  const checkTab = (index) => {
    if (
      tabStore.state.tabs[tabStore.state.tabIndex]?.changedRows?.length > 0 ||
      Object.keys(tabStore.state.tabs[tabStore.state.tabIndex]?.changedRows)
        .length > 0
    ) {
      setClosingTabIndex(index);
      setCloseModal(true);
    } else {
      tabStore.dispatch({ type: "REMOVE_TAB", index: index });
    }
  };

  return (
    <div style={{}}>
      <Modal
        open={closeModal}
        onCancel={() => {
          setClosingTabIndex(false);
          setCloseModal(false);
        }}
        onOk={() => {
          tabStore.dispatch({ type: "REMOVE_TAB", index: closingTabIndex });
          setClosingTabIndex(null);
          setCloseModal(false);
        }}
      >
        <Alert
          message="Warning!!"
          description="Data not saved. Close anyway ?"
          type="error"
          style={{ margin: "28px 0" }}
        />
      </Modal>

      <div style={{ width: "auto" }}>
        <div
          style={{
            position: "absolute",
            height: "85vh",
            overflow: "scroll",
            zIndex: tabStore.state?.tabIndex == -1 ? 1000 : 0,
            width: "100vw",
          }}
        >
          <Dashboard />
        </div>
        {tabStore.state.tabs.length > 0 ? (
          <div
            style={{
              height: "85vh",
              overflowY: "auto",
              marginBottom: "20px",
              position: "relative",
              overflowX: "hidden",
            }}
          >
            {tabStore.state.tabs?.map((item, index) => (
              <div
                key={index} // Added key prop for better performance and to avoid warnings
                className="maintab"
                style={{
                  position: "absolute",
                  zIndex: tabStore.state.tabIndex == index ? 1000 : 0,
                  height: "85vh",
                  width: "100vw",
                  overflowX: "hidden",
                }}
              >
                <Viewscreen name={item?.name} />
              </div>
            ))}
          </div>
        ) : null}
      </div>
      {/* //~! DO NOT TOUCH THIS FILE OR CSS, ELSE YOU"LL BE FIRED */}
      <div style={{ ...tabFrame }}>
        <div style={{ display: "flex" }}>
          {tabStore.state.tabs.map((tab, index) => {
            var bgColor =
              index == tabStore.state.tabIndex
                ? auth?.state?.userdata?.theme[0]?.btncolor || "#aaabad"
                : auth?.state?.userdata?.theme[0]?.collapseheaderbgcolor;

            // "#2196F3"  //blue  "#DDDDDD"
            return (
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: bgColor,
                  // marginRight: "5px",
                  marginLeft: "4px",
                  // borderTopLeftRadius: 10,
                  // borderTopRightRadius: 10,
                  height: "30px",
                  zIndex: 0,
                  // color:'2196F3'
                }}
                onClick={(e) => {}}
              >
                <div
                  style={{
                    flex: 9.5,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    borderTopLeftRadius: 10,
                    borderTopRightRadius: 10,
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    tabStore.dispatch({ type: "OPEN_TAB", index: index });
                  }}
                >
                  {tab.name + "-" + tab.headerName}
                </div>
                <div
                  style={{
                    // background: "red",
                    flex: 0.5,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <button
                    // color=" white "

                    style={{
                      border: "none",
                    }}
                    onClick={() => {
                      checkTab(index);
                    }}
                  >
                    <RxCross2 style={{ marginTop: "3px" }} />
                  </button>
                </div>
                {/* <button style={{ width: "5%" }}
                  onClick={() => {
                    tabStore.dispatch({ type: "REMOVE_TAB", index: index });
                  }}
                >
                  close
                </button> */}
              </div>
            );
          })}
          {/* 
commented out for time being

      <Tooltip title="Display Dashboard" color="#2db7f5">
          <div style={{
                  display: "flex",
                  width:"30px",
                  position:"relative",
                  left:0,
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "#87CEEB",
                  // marginRight: "5px",
                  marginLeft: "4px",
                  // borderTopLeftRadius: 10,
                  // borderTopRightRadius: 10,
                  height: "30px",
                  zIndex: 0,
                  // color:'2196F3'
                }}
                
                
                onClick={() => {
                  tabStore.dispatch({type:"DISPLAY_DASH"})
                }}
                >

          </div>
          </Tooltip>*/}
        </div>
      </div>
    </div>
  );
}

export default MainTab;

const tabFrame = {
  bottom: 0,
  position: "fixed",
  width: "100%",
  zIndex: 99,
};

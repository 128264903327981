import React, { useContext, useEffect, useRef, useState } from "react";
import SaveDropdown from "../../../CommonControllers/Components/SaveComponents/SaveDropdown";
import { Collapse, notification, Space } from "antd";
import ItemSearch from "../../../CommonControllers/ItemController/ItemSearch";
import { getfastpathapi } from "../../../CommonControllers/ApiCalls";
import { AuthStore } from "../../../Auth/AuthStore";
import { TabStore } from "../../../TabsStructure/TabsStore";
import { currentTabData } from "../../../CommonControllers/Variable";
import MultipleItemTable from "./MultipleItemTable";
import useSaveChangedRows from "../../../CommonControllers/Hooks/useSaveChangedRows";
import { enterPress } from "../../../CommonControllers/AppFunction";
import SaveDatePicker from "../../../CommonControllers/SaveComponents/SaveDatePicker";
import { getDomainSupplier } from "../../../CommonControllers/Urls";

function MultiplePurchaseReturn() {
  const [getSelectedItem, setSelectedItem] = useState(null);
  const [rowData, setRowData] = useState([]);
  const [customerData, setCustomerData] = useState({});
  const [igstappl, setigstappl] = useState(false);
  const [barcodeValue, setbarcodeValue] = useState();
  const [pageDetails, setPageDetails] = useState();

  const auth = useContext(AuthStore);
  const tabStore = useContext(TabStore);
  // Date
  const [purchaseInvDate, setPurchaseInvDate] = useState(
    auth?.state?.userdata?.workdate
  );

  const itemSearch = useRef(null);
  const { Panel } = Collapse;
  const useChangedRows = useSaveChangedRows();
  const changerow = tabStore?.state?.tabs[tabStore.state.tabIndex]?.changedRows;

  useEffect(() => {
    if (customerData?.igstappl > 0) {
      setigstappl(true);
    } else {
      setigstappl(false);
    }
  }, [customerData]);

  // useEffect(() => {

  // }, [])

  const payld = {
    tenantrecno: auth?.state?.userdata?.tenantrecno,
    domainrecno: auth?.state?.userdata?.domainrecno,
    userrolerecno: auth?.state?.userdata?.userrolerecno,
    domainuserrecno: auth.state.userdata.recno,
    userrolerecno: auth.state.userdata.userrolerecno,
    fastpath: currentTabData(tabStore)?.name,
  };

  useEffect(() => {
    // Get Fast path API
    getfastpathapi(payld).then((res) => {
      if (res?.Success) {
        setPageDetails(res?.Message);
      }
    });
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");
    const formattedDate = `${year}${month}${day}`;

    setPurchaseInvDate(formattedDate);
  }, []);

  useEffect(() => {
    const combinedRows = combineRowsBySavePayload(rowData);

    const existingObject = {
      items: combinedRows,
      // billdate: auth?.state?.workdate,
      billdate: purchaseInvDate ? purchaseInvDate : auth?.state?.workdate,
      trdate: purchaseInvDate,
    };
    useChangedRows.updateValue(false, existingObject);
  }, [rowData]);

  function combineRowsBySavePayload(rows) {
    const result = [];
    console.log("combineRowsBySavePayload", rows);
    rows.forEach((row) => {
      const {
        purchasebillno,
        dcbillno,
        billdate,
        dcbilldate,
        itemrecno,
        itembatchno,
      } = row;
      const existingRow = result.find(
        (existing) =>
          existing.purchasebillno === purchasebillno &&
          existing.dcbillno === dcbillno &&
          existing.billdate === billdate &&
          existing.dcbilldate === dcbilldate &&
          existing.itemrecno === itemrecno &&
          existing.itembatchno === itembatchno
      );

      if (existingRow) {
        // Parse the amounts as floats and round to 2 decimal places
        const amount = parseFloat(row.amount);
        const igstamt = parseFloat(row.igstamt);
        const cgstamt = parseFloat(row.cgstamt);
        const sgstamt = parseFloat(row.sgstamt);

        existingRow.qty += Number(row.qty);
        existingRow.taxableamount += Number(row.taxableamount);
        existingRow.igstamt = (
          parseFloat(existingRow.igstamt) + igstamt
        ).toFixed(2);
        existingRow.amount = (parseFloat(existingRow.amount) + amount).toFixed(
          2
        );
        existingRow.cgstamt = (
          parseFloat(existingRow.cgstamt) + cgstamt
        ).toFixed(2);
        existingRow.sgstamt = (
          parseFloat(existingRow.sgstamt) + sgstamt
        ).toFixed(2);
        existingRow.itembatcharray.push({
          serialno: row.serialno,
          manudate: row.manudate,
          expdate: row.expdate,
        });
      } else {
        result.push({
          ...row,
          itembatcharray: [
            {
              serialno: row.serialno,
              manudate: row.manudate,
              expdate: row.expdate,
            },
          ],
        });
      }
    });

    return result;
  }

  useEffect(() => {
    if (useChangedRows.getValue("trdate")) {
      setPurchaseInvDate(useChangedRows.getValue("trdate"));
    } else {
      useChangedRows.updateValue("trdate", auth?.state?.workdate);
      setPurchaseInvDate(auth?.state?.workdate);
    }
  }, [useChangedRows.getValue("trdate")]);

  useEffect(() => {
    if (tabStore.state.tabs[tabStore.state.tabIndex]?.pageStatus == "Success") {
      setRowData([]);
      setigstappl(false);
      setCustomerData("");
      setPurchaseInvDate(auth?.state?.userdata?.workdate);
      tabStore.dispatch({
        type: "UPDATE_DATA",
        fieldType: "pageStatus",
        text: null,
      });
    }
  }, [tabStore.state.tabs[tabStore.state.tabIndex]?.pageStatus]);

  // useEffect(() => {
  //   if (barcodeValue?.itembatcharrayfound[0]?.billno) {
  //     handleBarcodeScan(barcodeValue)
  //   } else {
  //     alert("Item Not Found for this Customer")
  //   }
  // }, [barcodeValue])

  function createItemObject(barcodeValue, igstappl) {
    const data = barcodeValue;

    const {
      itemdescn,
      itemrecno,
      itembatcharrayfound,
      entryserialno,
      entryexpdate,
      qty,
      purrate,
      taxableamount,
      cgstsmt,
      cgstrate,
      sgstsmt,
      sgstrate,
      igstamt,
      igstrate,
      amount,
      entrybarcode,
      discountamt,
      itemasbatch,
    } = data;

    const itemObject = {
      itemdescn,
      itemrecno,
      itembatchno: itembatcharrayfound[0].itembatchno,
      serialno: itembatcharrayfound[0].serialno,
      manudate: itembatcharrayfound[0].manudate,
      expdate: itembatcharrayfound[0].expdate,
      qty: 1,
      purrate,
      taxableamount: Number(purrate),
      discountamt: 0,
      supplierrecno: customerData?.supplierrecno,
      itemasbatch: itemasbatch,
    };

    if (itembatcharrayfound[0].billno) {
      itemObject.purchasebillno = itembatcharrayfound[0].billno;
      itemObject.billno = itembatcharrayfound[0].billno;
      itemObject.billdate = itembatcharrayfound[0].billdate;
    } else if (itembatcharrayfound[0].dcbillno) {
      itemObject.purchasebillno = itembatcharrayfound[0].billno
        ? itembatcharrayfound[0].billno
        : null;
      itemObject.billdate = itembatcharrayfound[0].billdate
        ? itembatcharrayfound[0].billdate
        : null;
      itemObject.dcbillno = itembatcharrayfound[0].dcbillno;
      itemObject.dcbilldate = itembatcharrayfound[0].dcbilldate;
    }

    if (igstappl) {
      itemObject.igstamt = purrate * (igstrate / 100);
      itemObject.igstrate = igstrate;
      itemObject.cgstamt = 0.0;
      itemObject.sgstamt = 0.0;
      itemObject.amount = purrate + itemObject.igstamt;
    } else {
      itemObject.cgstamt = purrate * (cgstrate / 100);
      itemObject.cgstrate = cgstrate;
      itemObject.sgstamt = purrate * (sgstrate / 100);
      itemObject.sgstrate = sgstrate;
      itemObject.igstamt = 0.0;
      itemObject.amount = purrate + itemObject.cgstamt + itemObject.sgstamt;
    }

    itemObject.amount = itemObject.amount.toFixed(2);
    itemObject.cgstamt = itemObject.cgstamt.toFixed(2);
    itemObject.sgstamt = itemObject.sgstamt.toFixed(2);
    itemObject.igstamt = itemObject.igstamt.toFixed(2);

    return itemObject;
  }

  // Function to handle barcode scanning
  function handleBarcodeScan(barcodeValue) {
    const newItem = createItemObject(barcodeValue, igstappl);
    // Check if the item with the same serial number is already present
    const isItemAlreadyPresent = rowData.some(
      (item) => item.serialno === newItem.serialno
    );

    if (isItemAlreadyPresent) {
      alert("Item already present");
    } else {
      // Update the rowData state with the new item
      setRowData([...rowData, newItem]);
    }
  }

  return (
    <div>
      <Collapse collapsible="header" defaultActiveKey={["1"]}>
        <Panel header="Header Details" key="1">
          <div>
            <Space
              style={{
                display: "flex",
                flexDirection: "column",
                ...topRowFilters,
                width: "fit-content",
              }}
            >
              <div
                style={{ display: "flex", gap: "24px", alignItems: "center" }}
              >
                <div
                  style={{
                    zIndex: 11,
                    position: "relative",
                    pointerEvents:
                      rowData && rowData.length > 0 ? "none" : "auto",
                    opacity: rowData && rowData.length > 0 ? 0.5 : 1,
                    marginTop: "10px",
                  }}
                >
                  <SaveDropdown
                    defaultSelected={customerData}
                    displayLabel="Supl"
                    placeholder="Supl"
                    alignment="column"
                    apikey="supplierrecno"
                    apiurl={getDomainSupplier}
                    selectionLabel={"supplierdescn"}
                    selectionValue={"supplierrecno"}
                    disabled={rowData && rowData.length > 0 ? 1 : 0}
                    returnValue={(event) => {
                      setCustomerData(event);
                    }}
                    // key={modalcount}
                    isrequired={1}
                  />
                </div>
                <div
                  onKeyDown={enterPress}
                  style={{
                    zIndex: 11,
                    position: "relative",
                    pointerEvents:
                      rowData && rowData.length > 0 ? "none" : "auto",
                    opacity: rowData && rowData.length > 0 ? 0.5 : 1,
                    marginTop: "10px",
                  }}
                >
                  <SaveDatePicker
                    // disabled={fastPath=="TR013"?true:false}
                    defaultValue={purchaseInvDate}
                    disabled={rowData && rowData.length > 0 ? 1 : 0}
                    placeholder={"PI Date"}
                    id="tr013-sale-invoice"
                    displayLabel="PR Date"
                    apiKey="trdate"
                    returnValue={(e) => {
                      setPurchaseInvDate(e);
                    }}
                    dateLessthan={auth?.state?.userdata?.workdate} //! If this is false, postAlertMsg will trigger
                    postAlertMsg={`SR Date cannot be greater than Today's Date or less than Opening date`}
                    dateGreaterthan={auth?.state?.userdata?.openingbaldate} // New Date Validation
                  />
                </div>
                <div>
                  <ItemSearch
                    itemSearchRef={itemSearch}
                    forFlag={"PR"}
                    returnValue={setSelectedItem}
                    supplierdata={customerData?.supplierrecno}
                    dropdown
                    selection
                    trdate={purchaseInvDate}
                    barcodeReturnValue={(e) => {
                      setbarcodeValue(e);

                      const bill = e?.itembatcharrayfound[0];
                      const sidate = bill?.billdate;
                      const dcdate = bill?.inwarddate;
                      const supplyrecno = bill?.billsupplierrecno;
                      //billsupplierrecno const purchaseInvDate = new Date().toISOString().slice(0, 10).replace(/-/g, ''); // Assuming purchaseInvDate is in YYYYMMDD format

                      if (
                        (bill?.billno || bill?.dcbillno) &&
                        Number(customerData?.supplierrecno) ==
                          Number(supplyrecno)
                      ) {
                        if (
                          (sidate &&
                            Number(sidate) > Number(purchaseInvDate)) ||
                          (dcdate && Number(dcdate) > Number(purchaseInvDate))
                        ) {
                          // alert("Error: SR Date is less than DC date or Bill Date");
                          notification.error({
                            message: `Error:`,
                            description: `PR Date is less than PI date or Latest Inward Date`,
                            duration: 3,
                            style: { background: "#efa7a7" },
                          });
                        } else {
                          handleBarcodeScan(e);
                        }
                      } else {
                        // alert("Item Not Found for this Customer");
                        notification.error({
                          message: `Notification`,
                          description: `Item Not Found for Selected Supplier`,
                          duration: 3,
                          style: { background: "#efa7a7" },
                        });
                      }
                      // getBillFromBarcode(e);
                    }}
                  />
                </div>
              </div>
            </Space>
          </div>
          <div style={{ margin: "24px auto 0" }}>
            {rowData?.length > 0 && (
              <>
                <MultipleItemTable
                  // rowData={rowData.filter((item) => item.returnableqty !== 0)}
                  rowData={rowData}
                  setRowData={setRowData}
                  // igstappl={igstappl} // setDatapayload={setDatapayload}  igstappl and igstrate validation
                  igstappl={
                    typeof igstappl === "number" ||
                    (typeof igstappl === "string" && parseFloat(igstappl) > 0)
                      ? true
                      : typeof igstappl === "boolean"
                      ? igstappl
                      : false
                  } /// here the igstapp getting string value its converted to Number
                  pageDetails={pageDetails}
                  changerow={changerow}
                />
              </>
            )}
          </div>
        </Panel>
      </Collapse>
    </div>
  );
}

export default MultiplePurchaseReturn;

const topRowFilters = {
  gap: "24px",
  display: "flex",
};

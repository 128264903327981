import axios from "axios";
import {
  getMasterUrl,
  getMenuUrl,
  getPartyNameURL,
  baseUrl,
  loginURL,
  filtercommonmaster,
  getfastpathurl,
  addCustomersURL,
  getcustomerlistURL,
  getDomain,
  addDomainsURL,
  getGllistURL,
  getScrollURL,
  getAccountlistURL,
  addTransactionsURL,
  getLanguageListURL,
  getSurveyType,
  getSurveyorListURL,
  getCustomerTypeListURL,
  getCustomeListForSurveyURL,
  getBank,
  getQuestionBySurveyType,
  bulkupdatesurveyquestion,
  getPDCList,
  filterTenantItemListURL,
  getDomainItemListURL,
  getDomainItemColumnsURL,
  getFilterSalebill,
  getpurchasebillURL,
  getItemFromScannedBarcodeURL,
  getSurveyQuestionsListUrl,
  getSurveyQuestionListUrl,
  getlistCustomber,
  filterpurchasereturnlog,
  filterpurchasebilllogs,
  getGRNDetailsUrl,
  getWMSGRNDetailsUrl,
  getWMSGDNDetailsUrl,
  filtersalebilllogs,
  getLocationItemList,
  getFilterSalebillOPening,
  getFilterPurchasebillOPening,
  deletetransactions,
} from "./Urls";
import { domainrecno, tenantrecno } from "./Variable";
const postAPI = async (url, body) => {
  // body = {...body,
  //     tenantrecno : tenantrecno
  //     }

  const resp = await axios.post(url, body);
  return resp.data;
};

export const apiCall = async (type, url, body) => {
  if (type == "POST") {
    return await postAPI(url, body);
  }
};

export const apiCallwithBaseUrl = async (type, url, body) => {
  if (type == "POST") {
   if(url[url?.length-1]==="/"){
    url = baseUrl + url  
    return await postAPI(url, body);
   }
   else{
    url = baseUrl + url + "/";
    return await postAPI(url, body);
   }
  }
};

// const patchAPI = async (url, body) => {
//   try {
//     body = {};
//     const response = await axios.patch(url, body);
//     if (response.data.Success) {
//       return response.data.Message;
//     } else {
//       //throw response.data.Error;
//     }
//   } catch (e) {
//     //alert(e);
//   }
// };

const patchAPI = async (url, body) => {
  try {
    const response = await axios.patch(url, body);

    if (response.data.Success) {
      return response.data.Message;
    } else {
     // throw new Error("Request was not successful.");
    }
  } catch (error) {
    console.error("Error occurred during PATCH request:", error);
   // throw error;
  }
};


export const loginUser = (payload) => postAPI(loginURL, payload);

export const getMenuApi = async (body) => await postAPI(getMenuUrl, body);
export const getMasterApi = async (body) => await postAPI(getMasterUrl, body); ///master url rajendra

///// Purchase Order API calls /////
export const getPartyname = (payload) => postAPI(getPartyNameURL, payload);
export const getPurchaseInfoForItemURL = (payload) => postAPI(getPurchaseInfoForItemURL, payload);

export const PATCHcommonmaster = (payload) => patchAPI(filtercommonmaster, payload);
export const POSTfiltercommonmaster = (payload) => postAPI(filtercommonmaster, payload);

// Party MS100
export const addCustomers = (payload) => postAPI(addCustomersURL, payload);
export const getCustomerList = (payload) => postAPI(getcustomerlistURL, payload);

export const getfastpathapi = (payload) => postAPI(getfastpathurl, payload);

// Domain Master
export const addDomains = (payload) => postAPI(addDomainsURL, payload);
export const getDomainList = (payload) => postAPI(getDomain, payload);
export const getLanguageList = (payload) => postAPI(getLanguageListURL, payload);

// 
// export const getSurveyQuestionsList = (payload) => postAPI(getSurveyQuestionsListUrl, payload)
export const addSurveyQuestion = (payload) => postAPI(bulkupdatesurveyquestion, payload)
export const getSurveyQuestionsList =(payload) => postAPI(getSurveyQuestionListUrl,payload)
          
// accounting API
export const getGllist = (payload) => postAPI(getGllistURL, payload);
export const getScroll = (payload) => postAPI(getScrollURL, payload);
export const getAccountlist = (payload) => postAPI(getAccountlistURL, payload);

export const addTransactions = (payload) => postAPI(addTransactionsURL, payload);

// SV004 : API func
export const getSurveyTypeListData = (payload) => postAPI(getSurveyType, payload);

export const getSurveyorListData = (payload) => postAPI(getSurveyorListURL, payload);

export const getCustomerTypeListData = (payload) => postAPI(getCustomerTypeListURL, payload);

export const getCustomerListForSurveyData = (payload) => postAPI(getCustomeListForSurveyURL, payload);

export const getBankBranchApi = (payload) => postAPI(getBank, payload);

export const getPDCListApi = (payload) => postAPI(getPDCList, payload);

// TR
export const getFilterTenantItemListData = (payload) => postAPI(filterTenantItemListURL, payload);

export const getDomainItemListData = (payload) => postAPI(getDomainItemListURL, payload);

export const getDomainItemListColumns = (payload) => postAPI(getDomainItemColumnsURL, payload);
//tr015 sale return
export const getSalebill=(payload)=>postAPI(getFilterSalebill,payload)
export const getSalebillOPening=(payload)=>postAPI(getFilterSalebillOPening,payload)


export const getpurchasebill = (payload) => postAPI(getpurchasebillURL, payload);

export const getItemFromScannedBarcode = (payload) => postAPI(getItemFromScannedBarcodeURL, payload);

export const getSubLocationDetails = (payload) => postAPI(getItemFromScannedBarcodeURL, payload);

/////pr///getBillreturn
export const getPurchasebillOpening=(payload)=>postAPI(getFilterPurchasebillOPening, payload)
export const getBillreturn=(payload)=>postAPI(filterpurchasereturnlog,payload)
export const getPibill=(payload)=>postAPI(filterpurchasebilllogs,payload)
export const filtergrn = (payload) => postAPI(getGRNDetailsUrl, payload);
export const getLocationItemListApi = (payload) => postAPI(getLocationItemList, payload)


export const  getSibill = (payload) => postAPI(filtersalebilllogs,payload)

export const deleteFunction=(payload)=>postAPI(deletetransactions,payload)



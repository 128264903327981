import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import { updateValue } from "../../TabsStructure/tabFunctions";
import { TabStore } from "../../TabsStructure/TabsStore";
import { AuthStore } from "../../Auth/AuthStore";

const CustomDropDown = ({
  async,
  inputValue,
  apiUrl,
  selectionLabel,
  selectionValue,
  minInput,
  placeholder,
  displayLabel,
  className,
  apikey,
  id,
  multi,
  width,
  body,
  alignment,
}) => {
  const tabStore = useContext(TabStore);
  const auth = useContext(AuthStore);
  let drpWidth = width ? width : "150px";
  let val = tabStore.state.tabs[tabStore.state.tabIndex].apiVariables[apikey];
  const [options, setOptions] = useState([]);

  async function getcustomers(inputValue) {
    body = {
      tenantrecno: auth.state.userdata.tenantrecno,
      domainrecno: auth.state.userdata.domainrecno,
      domainuserrecno: auth.state.userdata.recno,
      descn: inputValue,
      ...body,
    };
    const resp = await axios.post(apiUrl, body);

    const formattedArray = resp.data.Message.map((item) => {
      return {
        ...item,
        value: item[selectionValue],
        label: item[selectionLabel],
      };
    });
    setOptions(formattedArray);

    return formattedArray;
  }

  const loadOptions = async (inputValue, callback) => {
    if (inputValue.length >= minInput) {
      const customers = await getcustomers(inputValue);
      callback(customers);
    }
  };

  useEffect(() => {
    if (!async) {
      getcustomers();
    }
  }, []);

  const updateMultiSelection = (e) => {
    if (e?.length > 0) {
      var updateValues = "";
      e.map((item) => {
        updateValues = updateValues + item[selectionValue] + ",";
      });
      updateValues = updateValues.substring(0, updateValues.length - 1);
      updateValue(tabStore, apikey, updateValues);
    } else {
      updateValue(tabStore, apikey, e[selectionValue]);
    }
  };

  // css according to props:

  const mainFrame = {
    display: "flex",
    alignItems: "center",
    flexDirection: alignment,
    textAlign: "start",
    gap: "8px",
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        flexDirection: alignment,
        textAlign: "start",
        gap: "8px",
    
      }}
    >
      {!alignment ? (
        <p>{displayLabel}</p>
      ) : (
        <p
          style={{
            width: "100%",
          }}
        >
          {displayLabel}
        </p>
      )}

      <div style={{ width: drpWidth, zIndex: 9999 }}>
        {async ? (
          <AsyncSelect
            id={id}
            placeholder={placeholder}
            cacheOptions
            loadOptions={loadOptions}
            onChange={(e) => {
              updateMultiSelection(e);
            }}
            // isClearable
            isMulti={multi}
            styles={{
              control: (baseStyle, state) => ({
                ...baseStyle,
                ...dropDownStyles,
                menu: (provided) => ({ ...provided, zIndex: 9999 }),
              }),
            }}
          />
        ) : (
          <Select
            id={id}
            onChange={(e) => {
              updateMultiSelection(e);
            }}
            name="colors"
            isMulti={multi}
            options={options}
            placeholder={placeholder}
            className="basic-multi-select"
            classNamePrefix="select"
            //onChange={handleupdate}
            isClearable
            theme={(theme) => ({
              ...theme,
              borderRadius: "4px",
              backgroundColor: "ghostwhite",
              colors: { ...theme.colors, text: "orangered" },
            })}
            styles={{
              control: (baseStyle, state) => ({
                ...baseStyle,
                ...dropDownStyles,
                menu: (provided) => ({ ...provided, zIndex: 9999 }),
              }),
            }}
          />
        )}
      </div>
    </div>
  );
};

export default CustomDropDown;

//CSS

const dropDownStyles = {
  border: "1px solid #cccccc",
  // borderRadius: "8px",
};

import { Button, Modal } from "antd";
import React, { useContext, useEffect, useState } from "react";
import useExecuteApi from "../../Hooks/useExecuteApi";
import CallbackCheckbox from "../CallbackComponents/CallbackCheckbox";
import CallbackDateInput from "../CallbackComponents/CallbackDateInput";
import CallbackDropdown from "../CallbackComponents/CallbackDropdown";
import CallbackRadioBtn from "../CallbackComponents/CallbackRadioBtn";
import CallbackTextInput from "../CallbackComponents/CallbackTextInput";
import { v4 as uuidv4 } from "uuid";
import { TabStore } from "../../../TabsStructure/TabsStore";

export default function InlineEdit({ columnsArray, handleCancel, returnValue, setCount }) {
  const [dataObj, setDataObj] = useState({});
  const executeApi = useExecuteApi();
  const tabStore = useContext(TabStore);

  console.log("columnsArray",columnsArray)

  // Create Function to recieve the returnValue from the selected Value and save it to the Changed Rows

  const onSave = () => {
    for (const item of columnsArray) {
    
        if (item.req === 1 && (!dataObj[item.dataIndex] || dataObj[item.dataIndex] === "")) {
          alert(`Please fill in the required field: ${item.title}`);
          return;
        }
      
    }
  
    // All required fields are filled, continue with saving
    returnValue(dataObj);
    setDataObj({});
    tabStore.dispatch({
      type: "UPDATE_DATA",
      fieldType: "addObject",
      text: null,
    });
    setCount((p) => p + 1);
  };
  const enterPress = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      const form = e.target.form;
      const index = Array.prototype.indexOf.call(form, e.target);
      if (form.elements[index + 1]) {
        form.elements[index + 1].focus();
      } else {
        alert("End of Line");
      }
    }
  };

  // smk 15-2-2023 needs the function
  const validateInput = () => {
    return true;
  };

  const storeData = (e, dataIndex) => {
   
    var newObject = dataObj;
    newObject["new" + dataIndex] = e;
    newObject[dataIndex] = e;
   
    setDataObj(newObject);
  };
  ///validation
  // validationFun takes the array filterout all the required column returned only filtered array of dataIndexs name

  useEffect(() => {
    let requiredarray = validationFun(columnsArray);
    setStatusFun(requiredarray, dataObj);
  }, [dataObj]);
  
  const validationFun = (arr) => {
    let reqarr = [];
    for (let i = 0; i < arr.length; i++) {
      let item = arr[i];
      if (item.req == 1) {
        if (item.dataIndex) {
          reqarr.push(item.dataIndex);
        }
      }
    }

    return reqarr;
  };

  const setStatusFun = (requiredarray, apiBody) => {
    // cache object values
    if (Object.keys(apiBody).length == 0) {
      return false;
    } else {
      requiredarray = requiredarray.filter((item) => apiBody[item] != undefined);
      const yValues = Object.values(apiBody);

      for (let i = 0; i < requiredarray.length; i++) {
        const dataIndex = requiredarray[i];

        var value;
        if (dataIndex) {
          value = apiBody?.hasOwnProperty("new" + dataIndex) ? apiBody["new" + dataIndex] : apiBody[dataIndex];
        }

        //   if (dataIndex && apiBody.hasOwnProperty(dataIndex)) { value = apiBody[dataIndex];} else
        //   let value;

        // if (apiBody.hasOwnProperty("new" + dataIndex)) {
        //     value = apiBody["new" + dataIndex];
        //   }

        if (value === null || value === "" || value.length === 0 || value == 0 || value == false) {
          alert(dataIndex + " is Compulsary");
          return false;
        }
      }

      return true;
    }
  };
 
  return (
    <div style={{ ...mainFrame }}>
      <form style={{ ...modalGrid }}>
        {columnsArray.map((item, index) => {
          var stylings = item?.span ? item.span : "3";
          stylings = "span " + stylings;
          return item.isadd == false ? null : (
            <div
              style={
                {
                  //   gridColumn: stylings,
                }
              }
              onKeyDown={enterPress}
            >
              {item?.type == "int" ? (
                <CallbackTextInput
                  // placeholder={item?.title}
                  placeholder={item?.placeholder}
                  alignment={"column"}
                  change
                  type={"number"}
                  isrequired={item?.req}
                  displayLabel={item?.title}
                  minvalue={item?.minimumvalue}
                  maxvalue={item?.maximumvalue}
                  returnValue={(e) => {
                    storeData(e, item?.dataIndex);
                  }}
                  defaultValue={dataObj.hasOwnProperty(item?.dataIndex) ? dataObj[item?.dataIndex] : ""}
                />
              ) : item?.type == "date" ? (
                <CallbackDateInput
                  // placeholder={item?.title}
                  placeholder={item?.placeholder}
                  alignment={"column"}
                  isrequired={item?.req}
                  displayLabel={item?.title}
                  change
                  id={uuidv4()}
                  type={"int"}
                  // required
                  defaultValue={
                    dataObj.hasOwnProperty(item?.key)
                      ? dataObj[item?.key]
                      : ""
                  }
                  returnValue={(e) => {
                    storeData(e, item?.dataIndex);
                  }}
                />
              ) :item.type == "string"&& item.title != "PAN"&& item.title!= "GST No."
              && item.title!="Mobile" ? (
               <CallbackTextInput
                //  placeholder={item?.title}
                placeholder={item?.placeholder}
                alignment={"column"}
                 //rishi-250 if we type something after clearing it again we cant type anything
                 change
                 type={"text"}
                 isrequired={item?.req}
                 displayLabel={item?.title}
                 minlength={item?.minimumlength}
                 maxlength={item?.columnlength}
                 minvalue={item?.minimumvalue}
                 maxvalue={item?.maximumvalue}
                 returnValue={(e) => {
                   storeData(e, item?.dataIndex);
                 }}
                 defaultValue={dataObj.hasOwnProperty(item?.dataIndex) ? dataObj[item?.dataIndex] : ""}
                 regex={item.regex}
                 title={item.title}
               />
             ) : item.title == "PAN" ?  (
              <CallbackTextInput
                // placeholder={item?.title}
                placeholder={item?.placeholder}
                change
                // type={item.dataIndex == "mobile" ? "number" : "text"}
                type={"text"}
                isrequired={item?.req}
                displayLabel={item?.title}
                minlength={item?.minimumlength}
                maxlength={item?.columnlength}
                minvalue={item?.minimumvalue}
                maxvalue={item?.maximumvalue}
                returnValue={(e) => {
                  storeData(e, item?.dataIndex);
                }}
                defaultValue={dataObj.hasOwnProperty(item?.dataIndex) ? dataObj[item?.dataIndex] : ""}
                regex={item.regex}
                title={item.title}
              />
            ): item.title == "GST No." ?  (
              <CallbackTextInput
                // placeholder={item?.title}
                placeholder={item?.placeholder}
                change
                // type={item.dataIndex == "mobile" ? "number" : "text"}
                type={"text"}
                isrequired={item?.req}
                displayLabel={item?.title}
                minlength={item?.minimumlength}
                maxlength={item?.columnlength}
                minvalue={item?.minimumvalue}
                maxvalue={item?.maximumvalue}
                returnValue={(e) => {
                  storeData(e, item?.dataIndex);
                }}
                defaultValue={dataObj.hasOwnProperty(item?.dataIndex) ? dataObj[item?.dataIndex] : ""}
                regex={item.regex}
                title={item.title}
              />
            ) : item.title == "Mobile" ?  (
              <CallbackTextInput
                // placeholder={item?.title}
                placeholder={item?.placeholder}
                change
                // type={item.dataIndex == "mobile" ? "number" : "text"}
                type={"text"}
                isrequired={item?.req}
                displayLabel={item?.title}
                minlength={item?.minimumlength}
                maxlength={item?.columnlength}
                minvalue={item?.minimumvalue}
                maxvalue={item?.maximumvalue}
                returnValue={(e) => {
                  storeData(e, item?.dataIndex);
                }}
                defaultValue={dataObj.hasOwnProperty(item?.dataIndex) ? dataObj[item?.dataIndex] : ""}
                regex={item.regex}
                title={item.title}
              />
            ): item?.type == "boolean" ? (
                <CallbackCheckbox
                  editable
                  returnValue={(e) => {
                    storeData(e, item?.dataIndex);
                  }}
                  isrequired={item?.req}
                  displayLabel={item?.title}
                  defaultValue={dataObj.hasOwnProperty(item?.dataIndex) ? dataObj[item?.dataIndex] : ""}
                />
              ) :item.type == "decimal" ? (
                <CallbackTextInput
                // placeholder={item?.title}
                placeholder={item?.placeholder}
                change
                alignment={"column"}
                // type={item.dataIndex == "mobile" ? "number" : "text"}
                type={"number"}
                isrequired={item?.req}
                displayLabel={item?.title}
                minlength={item?.minimumlength}
                maxlength={item?.columnlength}
                minvalue={item?.minimumvalue}
                maxvalue={item?.maximumvalue}
                returnValue={(e) => {
                  storeData(e, item?.key);
                }}
                defaultValue={
                  dataObj.hasOwnProperty(item?.key)
                    ? dataObj[item?.key]
                    : ""
                }
                regex={item.regex}
                title={item.title}
              /> /// date 29 may
                  // defaultValue={item?.defaultValue ? true :false}
                
              ) :  item?.type == "radio" ? (
                <CallbackRadioBtn />
              ) : item?.type == "dropdown" ? (
                <CallbackDropdown
                  apiurl={item?.apiurl}
                  isrequired={item?.req}
                  displayLabel={item?.title}
                  alignment={"column"}
                  selectionValue={item?.selectionvalue}
                  selectionLabel={item?.selectionlabel}
                  returnValue={(e) => {
                    storeData(e[item?.selectionvalue], item?.dataIndex);
                  }}
                  defaultSelected={dataObj.hasOwnProperty(item?.dataIndex) ? dataObj[item?.dataIndex] : null}
                />
              ) : null}
            </div>
          );
        })}
        {/* <div style={{ gridColumn: "span 1" }}></div> */}

        <Button
          style={{ width: "150px", marginLeft: "22%", display: "none" }}
          type="primary"
          danger
          onClick={() => {
            // setAddRow(false);
            //handleClose(false)
          }}
        >
          Cancel
        </Button>

        <Button
          style={{ width: "80px", position: "absolute", right: "87px", bottom: "-56px" }}
          type="primary"
          onClick={() => {

            console.log("inline edit called")
            let requiredarray = validationFun(columnsArray);
            console.log("requiredarray",requiredarray,dataObj)
            let isfieldEmpty = setStatusFun(requiredarray, dataObj);
            console.log("isfieldEmpty",isfieldEmpty)

            //onSave();
            if (isfieldEmpty) {
              onSave();

              handleCancel(false);
            }
          }}
        >
          Save
        </Button>
      </form>
    </div>
  );
}

// CSS
const mainFrame = {
  display: "flex",
  gap: "16px",
  flexDirection: "column",
  margin: "24px 0",
  justifyContent: "flex-end",
  position: "relative",
};

const checkBoxstyle = {
  display: "flex",
  alignItems: "center",
  gap: "6px",
  fontSize: "16px",
};

const pStyles = {
  margin: "0 0 8px 0",
  padding: 0,
};

const modalGrid = {
  display: "grid",
  gridTemplateColumns: "repeat(3, 1fr)",
  gap: "24px",
};

import { Table, Button, Modal, Pagination, Space, Checkbox, Input } from "antd";
import { AiFillPrinter } from "react-icons/ai";
import React, { forwardRef, useContext, useRef, useState } from "react";
import { useEffect } from "react";
import { useReactToPrint } from "react-to-print";
import {
  dateFormatter,
  formatCurrency,
  formatCurrency3,
  isArray,
  removeFormatCurrency,
} from "../../CommonControllers/AppFunction";
import { TabStore } from "../../TabsStructure/TabsStore";
import "./sticky.css";
import TotalTable from "./TotalTable";
import PubSub from "pubsub-js";
import PrinterModal from "./PrinterModal";
import ReactPrint from "../../CommonControllers/Print/ReactPrint";
import { FilterFilled, SearchOutlined } from "@ant-design/icons";
import CallbackCheckbox from "../../CommonControllers/Components/CallbackComponents/CallbackCheckbox";
import Highlighter from "react-highlight-words";
import useSaveChangedRows from "../../CommonControllers/Hooks/useSaveChangedRows";


const SalesInvoiceTable = ({ filterprinting, filterdropdownprint, filterDictionary, setFilterDictionary ,fastPath,dropdownrecno}) => {
  const tabstore = useContext(TabStore);
  const [checkStrictly, setCheckStrictly] = useState(false);
  const [col, setColumns] = useState([]);
  const [rows, setRow] = useState([]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [open, setOpen] = useState(false);
  const [total, setTotal] = useState([]);
  const [startPrinting, setStartPrinting] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [firstRender, setFirstRender] = useState(true);
/// logic for search 
const useChangedRows = useSaveChangedRows();

  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);
 
  const [searchParameters, setSearchParameters] = useState(false);

  const [pageIndex, setPageIndex] = useState();

  let response = tabstore?.state?.tabs[pageIndex]?.apiResponse;

  let titleprint = [tabstore?.state?.tabs[pageIndex]?.apiVariables];
  let fastpath = tabstore?.state?.tabs[pageIndex]?.name;
  let reportname = tabstore?.state?.tabs[pageIndex]?.headerName;

  /// with serioan number

  const apiVariablesww = tabstore?.state?.tabs[pageIndex]?.apiVariables;

 
  useEffect(() => {
 
    let arr = [];
    setColumns(response?.columnsinfo);
    
    for (let i = 0; i < response?.Message?.length; i++) {
      let responserow = response?.Message[i];

      // arr.push(responserow);
      for (let j = 0; j < response?.Message[i]?.data?.length; j++) {
        let itemobj = responserow.data[j];
        
        arr.push(itemobj);
        
      }
     
    }

console.log("tgyiojpko",arr)
    setRow(arr);
  }, [response]);

  const setModal = () => {
    setOpen(true);
  };

  function handlePageChange(pageNumber) {
    // handle page change logic here
    setPage((p) => p + 1);
  }

  const commonFunction = () => {
    // handlePrintbody()
    // setOpen(true);
    setIsModalOpen((current) => !current);
    // setStartPrinting(true);
    //PubSub.unsubscribe(fastpath+"_PRINT")
  };

  useEffect(() => {
    if (startPrinting == true) {
      handlePrintbody();
    }
  }, [startPrinting]);

  const componentRef = useRef();
  let today = new Date();
  let date = today.getDate() + "-" + (today.getMonth() + 1) + "-" + today.getFullYear();

  const handlePrintbody = useReactToPrint({
    content: () => componentRef.current,
    onBeforeGetContent: () => (document.title = reportname),
    onAfterPrint: () => {
      setStartPrinting(false);
      setOpen(false);
    },
  });


  useEffect(() => {
    const apiVariablesww = tabstore?.state?.tabs[tabstore.state.tabIndex]?.apiVariables;
    const apiResponse = tabstore?.state?.tabs[tabstore.state.tabIndex]?.apiResponse;

    // This block will only be executed on the first render
    console.log("yscxysavcusygc",filterDictionary)
   
    if (firstRender) {
      setFirstRender(false);
      return;
    }

    // This block will be executed when any of the dependencies change, but not on the first render
    // if (filterDictionary && apiResponse?.Success == true||apiResponse?.Count==0) {
    //   setRow([]);
    //   setColumns([]);
    // } 

  }, [ filterDictionary,apiVariablesww]);


  //// logic for search 
  
 
  useEffect(() => {
    var tabsArray = tabstore.state.tabs;
    for (let i = 0; i < tabsArray?.length; i++) {
      if (tabsArray[i].name == fastPath) {
        setPageIndex(i);
        break;
      }
    }
  }, []);
  useEffect(() => {
    if (tabstore.state.tabs[pageIndex]?.pageNo) {
      setPage(tabstore.state.tabs[pageIndex].pageNo);
    }
    setSearchedColumn(
      tabstore.state.tabs[pageIndex]?.searchedColumn || ""
    );
    setSearchParameters(tabstore.state.tabs[pageIndex]?.searchParameters)
      
  }, []);

  // useEffect(() => {
  //   var searchParams = searchParameters
  //   if (searchParams && rows.length > 0) {
  //       console.log("Search Parameters is ....", searchParams)
  //       handleSearch(searchParams.selectedKeys, searchParams.confirm, searchParams.dataIndex)
        
  //     }
  // }, [col])
 

  // useEffect(() => {
  //   // Need to Store the search Text to the server
  //   tabstore.dispatch({
  //     type: "UPDATE_DATA",
  //     fieldType: "searchParameters",
  //     text: searchParameters,
  //   });
  // }, [searchParameters]);
  

  // useEffect(() => {

  //   console.log("Page Data is ", page)
  //   tabstore.dispatch({
  //     fieldType: "UPDATE_DATA",
  //     text: page,
  //   });
  // }, [page]);

  // const handleSearch = (selectedKeys, confirm, dataIndex) => {
  //   confirm();
  //   setSearchText(selectedKeys[0]);
  //   setSearchedColumn(dataIndex);
   
  // };
  // const handleReset = (clearFilters, confirm) => {
  //   clearFilters();
  //   setSearchText("");
  //   confirm();
  // };
  // const getColumnSearchProps = (dataIndex) => ({
  //   filterDropdown: ({
  //     setSelectedKeys,
  //     selectedKeys,
  //     confirm,
  //     clearFilters,
  //     close,
  //   }) => (
  //     <div
  //       style={{
  //         padding: 8,
  //       }}
  //       onKeyDown={(e) => e.stopPropagation()}
  //     >
  //       <Input
  //         ref={searchInput}
  //         placeholder={`Search ${dataIndex}`}
  //         value={selectedKeys[0]}
  //         onChange={(e) => {
  //           setSelectedKeys(e.target.value ? [e.target.value] : []);
  //           setSearchParameters({
  //             selectedKeys : e.target.value ? [e.target.value] : [],
  //             confirm: confirm,
  //             dataIndex: dataIndex,
  //           });
  //         }}
  //         onPressEnter={() => {
  //           handleSearch(selectedKeys, confirm, dataIndex);
  //         }}
  //         style={{
  //           marginBottom: 8,
  //           display: "block",
  //         }}
  //       />
  //       <Space>
  //         <Button
  //           type="primary"
  //           onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
  //           icon={<SearchOutlined />}
  //           size="small"
  //           style={{
  //             width: 90,
  //           }}
  //         >
  //           Search
  //         </Button>
  //         <Button
  //           onClick={() => clearFilters && handleReset(clearFilters, confirm)}
  //           size="small"
  //           style={{
  //             width: 90,
  //           }}
  //         >
  //           Reset
  //         </Button>
  //         <Button
  //           type="link"
  //           size="small"
  //           onClick={() => {
  //             confirm({
  //               closeDropdown: false,
  //             });
  //             setSearchText(selectedKeys[0]);
  //             setSearchedColumn(dataIndex);
  //           }}
  //         >
  //           Filter
  //         </Button>
  //         <Button
  //           type="link"
  //           size="small"
  //           onClick={() => {
  //             close();
  //           }}
  //         >
  //           close
  //         </Button>
  //       </Space>
  //     </div>
  //   ),
  
  //   filterIcon: (filtered) => (
  //     <div>
  //       {filtered ? (
  //         <FilterFilled
  //           style={{
  //             backgroundColor: filtered ? "#2196F23" : undefined,
  //           }}
  //         />
  //       ) : null}
  //       <SearchOutlined
  //         style={{
  //           color: filtered ? "#1890ff" : undefined,
  //         }}
  //       />
  //     </div>
  //   ),
  //   onFilter: (value, record) =>
  //     record[dataIndex]?.toString().toLowerCase().includes(value.toLowerCase()),
  //   onFilterDropdownOpenChange: (visible) => {
  //     if (visible) {
  //       setTimeout(() => searchInput.current?.select(), 100);
  //     }
  //   },
  //   render: (text) =>
  //     searchedColumn === dataIndex ? (
  //       <Highlighter
  //         highlightStyle={{
  //           backgroundColor: "#ffc069",
  //           padding: 0,
  //         }}
  //         searchWords={[searchText]}
  //         autoEscape
  //         textToHighlight={text ? text.toString() : ""}
  //       />
  //     ) : (
  //       text
  //     ),
  // });

  const covertToRequiredFormat = () => {
    const totalData = {...tabstore.state.tabs[pageIndex]?.apiResponse};

    console.log("Total Data is Columns", totalData)
    // var columns = totalData?.columnsinfo?.map((item) => {

    // //   if (item.filter) {
    // //     return {
    // //       ...item,
    // //       width: Number(item?.width) > 0 ? item.width : 100,
    // //       dataIndex: item.columnname,
    // //       key: item.columnname,
    // //       // ...getColumnSearchProps(item.columnname),
    // //       render: (_, tags) =>
    // //         item.type == "boolean" ? (
    // //           <CallbackCheckbox
    // //             defaultValue={tags[item.columnname]}
    // //             //disabled
    // //             editable={1}
    // //             label={[item.Title]}
    // //             returnValue={(e)=>{
    // //               console.log("IS Filteringertyu1",e);
    // //             }}
    // //           />
    // //         ) : item.type == "date" ? (
    // //           <p>{dateFormatter(tags[item.columnname])}</p>
    // //         ) : item.type == "dropdown" ? (
    // //           <p>{tags[item.columndisplay]}</p>
    // //         ) : (
    // //           <p>{tags[item.columnname]}</p>
    // //         ),
    // //     };
    // //   } else {
    // //     return {
    // //       ...item,
    // //       width: Number(item?.width) > 0 ? item.width : 100,
    // //       dataIndex: item.columnname,
    // //       key: item.columnname,
    // //       render: (_, tags) => {
    // //         return item.type == "boolean" ? (
    // //           <CallbackCheckbox checked={tags[item.columnname]}
    // //         editable={1}
    // //           returnValue={(e)=>{
    // //             console.log("IS Filteringertyu2",e);
    // //           }}
    // //             />
    // //         ) : item.type == "date" ? (
    // //           <p>{dateFormatter(tags[item.columnname])}</p>
    // //         ) : item.type == "dropdown" ? (
    // //           <p>{tags[item.columndisplay]}</p>
    // //         ) : (
    // //           <p>{tags[item.columnname]}</p>
    // //         );
    // //       },
    // //     };
    // //   }
    // // });
    var newColumns = [];
    for (let i = 0; i < totalData.columnsinfo?.length; i++) {
      if (totalData.columnsinfo[i]?.isview) {
     
        if (totalData.columnsinfo[i]?.filter) {
          var newData = {
            ...totalData.columnsinfo[i],
            width: Number(totalData.columnsinfo[i]?.width)
              ? totalData.columnsinfo[i]?.width
              : 100,
            dataIndex: totalData.columnsinfo[i].columnname,
            key: totalData.columnsinfo[i].columnname,
            // ...getColumnSearchProps(totalData.columnsinfo[i].columnname),
            render: (_, tags) =>
              totalData.columnsinfo[i].type == "boolean" ? (
                <CallbackCheckbox
                  defaultValue={tags[totalData.columnsinfo[i].columnname]}
                  // disabled
                
                  editable
                  apikey={totalData.columnsinfo[i].apiKey}
                  label={[totalData.columnsinfo[i].Title]}
                  returnValue={(e)=>{
                  
                    tags.showScreen=e
                    console.log("RTYTY5555666",tags,e)
                    console.log("EDGHUJLKM",tags,newColumns)
                    modifyThedataforPayload(totalData,tags,e)
                  }}
                />
              ) : totalData.columnsinfo[i].type == "date" ? (
                <p>
                  {dateFormatter(tags[totalData.columnsinfo[i].columnname])}
                </p>
              ) : totalData.columnsinfo[i].type == "dropdown" ? (
                <p>{tags[totalData.columnsinfo[i].columndisplay]}</p>
              ) : (
                <p>{tags[totalData.columnsinfo[i].columnname]}</p>
              ),
          };

          newColumns.push(newData);
        } else {
          newData = {
            ...totalData.columnsinfo[i],
            width: Number(totalData.columnsinfo[i]?.width)
              ? totalData.columnsinfo[i]?.width
              : 100,
            dataIndex: totalData.columnsinfo[i].columnname,
            key: totalData.columnsinfo[i].columnname,
            render: (_, tags) => {
              
              return totalData.columnsinfo[i].type == "boolean" ? (
                <CallbackCheckbox
                defaultValue={tags[totalData.columnsinfo[i].columnname]}
                  // disabled
                  editable={1}
                  apikey={totalData.columnsinfo[i].apiKey}
                  returnValue={(e)=>{
                    tags.showScreen=e
                    console.log("RTYTY5555777",tags,e)
                    console.log("EDGHUJLKM",tags,newColumns)
                    modifyThedataforPayload(totalData,tags,e)
                  }}
                />
              ) : totalData.columnsinfo[i].type == "date" ? (
                <p>
                  {dateFormatter(tags[totalData.columnsinfo[i].columnname])}
                </p>
              ) : totalData.columnsinfo[i].type == "dropdown" ? (
                <p>{tags[totalData.columnsinfo[i].columndisplay]}</p>
              ) : (
                <p>{tags[totalData.columnsinfo[i].columnname]}</p>
              );
            },
          };
          newColumns.push(newData);
        }
      }
    }

    // var rows = totalData?.Message?.map((item) => {
    //   return { ...item };
    // });
   
    setColumns(newColumns);
  //setRow(totalData?.Message.data);
  };

  
  useEffect(() => {
    covertToRequiredFormat();
  }, [tabstore.state.tabs[pageIndex]?.apiResponse]);

  const modifyThedataforPayload=(data,tags,e)=>{
 
    let modify = data.Message[0].data.map((it) => {
      if (it?.role === tags?.role) {
          return {
              ...it,
              showScreen: e 
          };
      } else {
          return it;
      }
  });
  let totalRole=""
  for(let i=modify.length-1;i>=0;i--){
    if(modify[i].showScreen==true){
      totalRole+="1"
    }
    else{
      totalRole+="0"
    }
  }
  console.log("totalRole",totalRole,modify)
 // let reverstr=totalRole?.split('').reverse().join('')
  let payload={
    recno:dropdownrecno.recno,totalrolevalue:totalRole
  }
  useChangedRows.updateValue(false, payload);

  }
  console.log("tabstoretabstore",tabstore,dropdownrecno.recno)
  return (
    <div style={{ paddingBottom: "100px", overflowX: 'auto',width:"70%",alignItems:"center",alignContent:"center",marginLeft:"15%" }} >
      {/* rajendra 11-3-2023 added overflowX and scroll {response?  <Button onClick={setModal} type="dashed">Open to Print</Button>:null} */}
      {/* <Button onClick={commonFunction} style={{ ...btnStyle }}>
        <AiFillPrinter style={{ fontSize: "1.08rem" }} />
        Preview
      </Button> */}
      <Table
        columns={col}
        dataSource={rows}
        // style={{width:auto,fontSize:"10px",height:"max-content"}}
        //scroll={open?false:{ x: 400 }}
        // scroll={{ x: 'max-content' }}
        scroll={{
          scrollToFirstRowOnChange: true,
          x: col?.reduce((acc, el, i, arr) => (acc += Number(el?.width)), 0),
          y: 350,
        }}
        ref={open ? componentRef : null}
        pagination={false}
        // {open?false:{
        //   position: ["none"],
        //   pageSize: pageSize,
        //   defaultCurrent: page,
        //   current: page,
        //   onChange: handlePageChange,
        // }}
        title={() => {
          return (
            <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              position: "sticky",
              top: "0px",
              zIndex: 1,
              offset: 0,
              background: "white", // Optional: Add background color for the sticky header
            }}
            >
              {open ? (
                <>
                  {filterprinting?.map((data) => {
                    console.log("check data", data, reportname);
                    return (
                      <>
                        {titleprint?.map((titleprint) => {
                          console.log("check data1", titleprint);
                          if (data.type !== "boolean") {
                            return (
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <h2>{data.displayLabel}:</h2>
                                <h3>
                                  {data.type == "date"
                                    ? dateFormatter(titleprint[data.apiKey])
                                    : data.type == "dropdown"
                                      ? filterdropdownprint
                                      : titleprint[data.apiKey]}
                                </h3>
                              </div>
                            );
                          }
                        })}
                      </>
                    );
                  })}
                </>
              ) : (
                <>
              
                  {/* <Pagination
                    total={rows?.length}
                    showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
                    defaultCurrent={page}
                    defaultPageSize={10}
                    showSizeChanger
                    showQuickJumper
                    current={page}
                    onChange={(e, pageSize) => {
                      setPageSize(pageSize);
                      setPage(e);
                    }}
                  /> */}
                </>
              )}
            </div>
          );
        }}
        className={page == Math.ceil(rows?.length / pageSize) ? "printtotalline" : open ? "printTable" : "print"}
      />
      {
         col && isArray(col) ? <PrinterModal
         columns={col}
         rows={rows}
         isModalOpen={isModalOpen}
         setIsModalOpen={setIsModalOpen}
         ref={componentRef}
         wtfIsThisData={{ data1: filterprinting, data2: filterdropdownprint }}
         filterDictionary={filterDictionary}
         setFilterDictionary={setFilterDictionary}
         setStartPrinting={setStartPrinting}
         reportname={reportname}
       /> : null
      }
     
    </div>
  );
};
export default SalesInvoiceTable;

const btnStyle = {
  display: "flex",
  alignItems: "center",
  gap: "4px",
  marginLeft: "16px",
};

import React, { useContext, useState, useEffect, useCallback } from "react";
import { apiCallwithBaseUrl } from "../../ApiCalls";
import { AuthStore } from "../../../Auth/AuthStore";
import axios from "axios";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import useExecute from "../../Hooks/useExecute";
import { isArray } from "../../AppFunction";
import _isEqual from 'lodash/isEqual';
const ExecuteDropdown=React.memo(({
  displayLabel,
  apikey,
  apiurl,
  optionsList,
  defaultSelected,
  returnValue,
  async,
  selectionValue,
  selectionLabel,
  placeholder,
  style,
  className,
  multi,
  body,
  alignment,
  width,
  minInput,
  requiredBody,
  bodyKey,
  isrequired,
  id
})=> {
  let drpWidth = width ? width : "150px";
  const auth = useContext(AuthStore);
  const bgcolor = auth?.state?.userdata?.theme[0]?.inputbgcolor
  const btncolor = auth?.state?.userdata?.theme[0]?.btncolor
  const [options, setOptions] = useState([]);
  const [selectedValue, setSelectedValue] = useState(null);
  const execute = useExecute();
  const [apiBody, setApiBody] = useState({});
 

  if (!selectedValue || (Array.isArray(selectedValue) && selectedValue.length === 0)) {
    drpWidth = "250px";
  }
  console.log("Data===>--",apikey,defaultSelected)
  useEffect(() => {
    let apibody = {};
    if (requiredBody?.length > 0) {
      for (let i = 0; i < requiredBody?.length; i++) {
        if (body.hasOwnProperty(requiredBody[i])) {
          apibody[requiredBody[i]] = body[requiredBody[i]];
        }
      }
    }
  
   
  
    // Check if the deep equality of apibody and apiBody has changed
    if (!_isEqual(apibody, apiBody)) {
      setApiBody(apibody);
    }
  }, [body, apiBody]);
  
  useEffect(() => {
    if (Object.keys(apiBody).length > 0) {
      getdropdownoptions();
    }
  }, [apiBody]);

  // Default Values
  useEffect(() => {
    if (options?.length > 0) {
      if (defaultSelected) {
        if (execute.getValue(apikey)) {
          if (typeof execute.getValue(apikey) == "string") {
            if (execute.getValue(apikey).includes(",")) {
              let selectionArr = execute.getValue(apikey).split(",");
              let selectedOptions = [];
              selectionArr.map((i) => {
                const selectedObject = options?.filter((item) => item[selectionValue] == i);
                selectedOptions.push(selectedObject);
              });
              setSelectedValue(selectedOptions);
              returnValue(selectedOptions);
            } else {
              const selectedObject = options?.filter((item) => item[selectionValue] == execute.getValue(apikey));
              if (selectedObject?.length > 0) {
                let selectedOptions = selectedObject[0];
                setSelectedValue(selectedOptions);
                returnValue(selectedOptions);
              } else {
                setSelectedValue(null);
              }
            }
          } else {
            const selectedObject = options?.filter((item) => item[selectionValue] == execute.getValue(apikey));
            if (selectedObject?.length > 0) {
              let selectedOptions = selectedObject[0];
              setSelectedValue(selectedOptions);
              returnValue(selectedOptions);
            } else {
              setSelectedValue(null);
            }
          }

          // } else if (execute.getValue(apikey) == "") {
          //   setSelectedValue(execute.getValue(apikey));
        } else {
          const selectedObject = options?.filter((item) => item[selectionValue] == defaultSelected);
          setSelectedValue(selectedObject);
          execute.updateValue(apikey, defaultSelected);
         
          returnValue(selectedObject);
        }
      } else {
        if (execute.getValue(apikey)) {
          if (typeof execute.getValue(apikey) == "string") {
            if (execute.getValue(apikey).includes(",")) {
              let selectionArr = execute.getValue(apikey).split(",");
              let selectedOptions = [];
              selectionArr.map((i) => {
                const selectedObject = options?.map((item) => {
                  if (item[selectionValue] == i) {
                    selectedOptions.push(item);
                  }
                });
              });

              setSelectedValue(selectedOptions);
             
              returnValue(selectedOptions);
            } else {
              const selectedObject = options?.filter(
                (item) => item[selectionValue] == execute.getValue(apikey)
              );
              if (selectedObject?.length > 0) {
                let selectedOptions = selectedObject[0];
                setSelectedValue(selectedOptions);
             
                returnValue(selectedOptions);
              } else {
                setSelectedValue(null);
              }
            }
          } else {
            const selectedObject = options?.filter((item) => item[selectionValue] == execute.getValue(apikey));
            if (selectedObject?.length > 0) {
              let selectedOptions = selectedObject[0];
              setSelectedValue(selectedOptions);
             
              returnValue(selectedOptions);
            } else {
              setSelectedValue(null);
            }
          }
        }
      }
    }
  }, [options, defaultSelected]);

  const getdropdownoptions = useCallback(async (inputValue) => {
    // your code here
    const payload = {
      tenantrecno: auth.state.userdata.tenantrecno,
      domainrecno: auth.state.userdata.domainrecno,
      domainuserrecno: auth.state.userdata.recno,
      descn: inputValue,
      ...apiBody,
    };
    if (bodyKey) {
      payload[bodyKey] = inputValue
    }

  
    var resp;
    if (apiurl.includes("http")) {
      resp = await axios.post(apiurl, payload);
      resp = resp.data;
    } else {
      resp = await apiCallwithBaseUrl("POST", apiurl, payload);
    }

    const formattedArray = resp.Message.map((item) => {
      return {
        ...item,
        value: item[selectionValue],
        label: item[selectionLabel],
      };
    });

    setOptions(formattedArray);
    if (formattedArray.length === 1) {
      setSelectedValue(formattedArray[0]);
      execute.updateValue(apikey, formattedArray[0][apikey]);
      if (returnValue) {
        returnValue(formattedArray[0]);
        execute.updateValue(apikey, formattedArray[0][apikey]);
      }
    }

    return formattedArray;
  }, [auth, apiurl, apiBody, selectionValue, selectionLabel]);

  // async function getdropdownoptions(inputValue) {
  //   body = {
  //     tenantrecno: auth.state.userdata.tenantrecno,
  //     domainrecno: auth.state.userdata.domainrecno,
  //     domainuserrecno: auth.state.userdata.recno,
  //     descn: inputValue,
  //     ...apiBody,
  //   };

  //   console.log("BODY OF DROPDOWN", body, apiBody);
  //   var resp;
  //   if (apiurl.includes("http")) {
  //     resp = await axios.post(apiurl, body);
  //     resp = resp.data;
  //   } else {
  //     resp = await apiCallwithBaseUrl("POST", apiurl, body);
  //   }

  //   const formattedArray = resp.Message.map((item) => {
  //     return {
  //       ...item,
  //       value: item[selectionValue],
  //       label: item[selectionLabel],
  //     };
  //   });
  //   setOptions(formattedArray);

  //   return formattedArray;
  // }

  // For Updating the Values to the TabStore and reverting to Parent
  const updateMultiSelection = (e) => {
    
    if (isArray(e) &&  e?.length > 0) {
      var updateValues = "";
      e.map((item) => {
        updateValues = updateValues + item[selectionValue] + ",";
      });
      updateValues = updateValues.substring(0, updateValues.length - 1);
      execute.updateValue(apikey, updateValues);
      setSelectedValue(e);
      if (returnValue) {
        returnValue(e);
      }
    } else {
      setSelectedValue(e);
      if (e) {
        if (e.hasOwnProperty(selectionValue)) {
          execute.updateValue(apikey, e[selectionValue]);
        } else {
          execute.updateValue(apikey, null);
        }
      } else {
        execute.updateValue(apikey, null);
      }
      if (returnValue) {
        returnValue(e);
      }
    }
  };

  const loadOptions = async (inputValue, callback) => {
    if (inputValue.length >= minInput) {
      const dropdownoptions = await getdropdownoptions(inputValue);
      callback(dropdownoptions);
    }
  };

  useEffect(() => {
    getdropdownoptions();
  }, []);


  const [isFocused, setIsFocused] = useState(false);
  const handleFocus = () => {
    // This function is called when the input element receives focus.
    setIsFocused(true)
  }

  const handleBlur = () => {
    // This function is called when the input element loses focus.
    setIsFocused(false)
  }

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        flexDirection: alignment,
        textAlign: "start",
        gap: "8px",
        position: "relative"
      }}
    >
    

      <div style={{ width: drpWidth, position: "relative" }}>
        {async ? (
          <AsyncSelect
            placeholder={""}
            cacheOptions
            loadOptions={loadOptions}
            onChange={(e) => {
              updateMultiSelection(e);
            }}
            value={selectedValue}
            // isClearable
            isMulti={multi}
            styles={{
              control: (baseStyle, state) => ({
                ...baseStyle,
                ...dropDownStyles,
                menu: (provided) => ({ ...provided, zIndex: 9999 }),
                backgroundColor:bgcolor || "#EBEDF0"
              }),
              option: (baseStyle, { isFocused, isSelected }) => ({
                ...baseStyle,
                backgroundColor: isSelected
                  ? btncolor || "#EBEDF0"
                  : isFocused
                  ? bgcolor || "#EBEDF0"
                  : 'white',
                  color:isSelected
                  ? "black" : "black"
              }),
            }}
          />
        ) : (
          <Select
          onFocus={handleFocus}
            onBlur={handleBlur}
            onChange={(e) => {
              updateMultiSelection(e);
            }}
            isMulti={multi}
            value={selectedValue}
            options={options}
            placeholder={""}
            className="basic-multi-select"
            classNamePrefix="select"
            //onChange={handleupdate}
            isClearable
            theme={(theme) => ({
              ...theme,
              borderRadius: "4px",
              backgroundColor: "ghostwhite",
              colors: { ...theme.colors, text: "orangered" },
            })}
            styles={{
              control: (baseStyle, state) => ({
                ...baseStyle,
                ...dropDownStyles,
                menu: (provided) => ({ ...provided, zIndex: 9999 }),
                backgroundColor:bgcolor || "#EBEDF0"
              }),
              option: (baseStyle, { isFocused, isSelected }) => ({
                ...baseStyle,
                backgroundColor: isSelected
                  ? btncolor || "#EBEDF0"
                  : isFocused
                  ? bgcolor || "#EBEDF0"
                  : 'white',
                  color:isSelected
                  ? "black" : "black"
              }),
            }}
          />
        )}
      </div>
      <label
              htmlFor={id}
              className={`label ${isFocused || selectedValue ? 'focused' : 'unfocused'}`}
              style={{
               
                whiteSpace: "nowrap",
                wordWrap:"unset",
                position: 'absolute',
                top: isFocused || selectedValue ? '-19px' : '50%',
                left: '8px',
                //backgroundColor: isFocused || selectedValue ? 'white' : "transparent",
                padding: '0 4px',
                fontSize: isFocused || selectedValue ? '12px' : '16px',
                color: isFocused ? 'black' : '',
                pointerEvents: 'none',
                transform:
                  isFocused || selectedValue
                    ? 'translateY(0)'
                    : 'translateY(-50%)',
                transition:
                  'top 0.3s, transform 0.3s, font-size 0.3s, color 0.3s'
              }}
            >
              {isrequired ? (
               <span className={`label ${isFocused || selectedValue ? 'focused' : 'unfocused'}`}>
                  {isFocused || selectedValue? displayLabel : placeholder}
                  <span style={{ color: 'red' }}>*</span>
                </span>
              ) : (
                <span className={`label ${isFocused || selectedValue ? 'focused' : 'unfocused'}`}>
                  {isFocused || selectedValue? displayLabel :placeholder}
                 
                </span>
              )}
            </label>
    </div>
  );
})

//CSS

export default ExecuteDropdown;

const dropDownStyles = {
  border: "1px solid #cccccc",
  // borderRadius: "8px",
  height:'36px'
};

import { notification } from "antd";
import axios from "axios";
import { useState, useEffect, useContext } from "react";
import { AuthStore } from "../../Auth/AuthStore";
import { TabStore } from "../../TabsStructure/TabsStore";
import { baseUrl } from "../Urls";
import { currentTabData } from "../Variable";
import useExecuteApi from "./useExecuteApi";
import { datedisplay, isArray } from "../AppFunction";

export default function useSaveApi() {
  const auth = useContext(AuthStore);
  const tabStore = useContext(TabStore);
  const executeApi = useExecuteApi();

  var changedRows = tabStore?.state?.tabs[tabStore.state.tabIndex]?.changedRows;

  async function execute(pageIndex) {
    var tabIndex = 0;

    tabStore.dispatch({
      type: "UPDATE_DATA_WITH_TABINDEX",
      fieldType: "saveLoading",
      text: true,
      tabDataIndex: pageIndex ? pageIndex : tabStore.state.tabIndex,
    });

    if (!pageIndex) {
      tabIndex = tabStore.state.tabIndex;
    } else {
      tabIndex = pageIndex;
    }
    var body = tabStore?.state?.tabs[tabIndex]?.changedRows;

    // check if chnaghed rows has validations error then show those
    if (body?.hasOwnProperty("validations")) {
      //  let errors_string = body?.validations.join("<br>");
      const _description = (
        <>
          {body?.validations?.map(function (item) {
            return item + "</br>";
          })}
        </>
      );
      if (body?.validations?.length > 0) {
        auth.state.contextHolder.open({
          message: `An Error has occured`,
          description: (
            <div style={{ display: "flex", flexDirection: "column" }}>
              {body?.validations.map((item) => (
                <p>{item}</p>
              ))}
            </div>
          ),
          duration: 5,
        });
        return;
      }
    }


    if (Object?.keys(body)?.length == 0) {
      tabStore.dispatch({
        type: "UPDATE_DATA_WITH_TABINDEX",
        fieldType: "saveLoading",
        text: false,
        tabDataIndex: pageIndex ? pageIndex : tabStore.state.tabIndex,
      });
      return;
    }
    var newBody = body;
    if (isArray(body)) {
      var newBody = body?.map((item) => {
        for (const property in item) {
          // Added Last includes ("/") because when date was comming in correct format it was getting converted to undefined

          if (
            property?.includes("date") &&
            item[property] &&
            typeof item[property] == "string" &&
            item[property]?.includes("/")
          ) {
            //// task id-369 rajendra MS108 the save button is not executing && item[property]?.includes("/") this one is removed

            item[property] = datedisplay(item[property]).dataDate;
          }
        }

        return item;
      });
    } else {
      for (const property in body) {
        // Added Last includes ("/") because when date was comming in correct format it was getting converted to undefined

        if (
          property?.includes("date") &&
          body[property] &&
          typeof body[property] == "string" &&
          body[property]?.includes("/")
        ) {
          //// task id-369 rajendra MS108 the save button is not executing && body[property]?.includes("/") this one is removed

          body[property] = datedisplay(body[property]).dataDate;
        }
      }
    }
    body = {
      data: newBody,
      tenantrecno: auth.state.userdata.tenantrecno,
      domainrecno: auth.state.userdata.domainrecno,
      domainuserrecno: auth.state.userdata.recno,
      userrolerecno: auth.state.userdata.recno,
      fastpath: tabStore.state.tabs[tabIndex].name,
    };
    // This change are added for TR035 because shortguid needed in header
    if (tabStore.state.tabs[tabIndex].name === "TR035") {
      body.shortguid = tabStore.state.tabs[tabIndex].changedRows.shortguid
    }

    var url =
      baseUrl + tabStore.state.tabs[tabIndex]?.saveApiUrl + "/";
    var type = tabStore?.state?.tabs[tabIndex]?.saveApiType;

    let executeurl = tabStore?.state?.tabs[tabIndex]?.apiUrl;

    var resp = null;
    try {
      if (type == "PATCH") {
        resp = await axios.patch(url, body);
      } else if (type == "GET") {
        resp = await axios.get(url, body);
      } else {
        tabStore.dispatch({
          type: "UPDATE_DATA_WITH_TABINDEX",
          fieldType: "saveLoading",
          text: true,
          tabDataIndex: pageIndex ? pageIndex : tabStore.state.tabIndex,
        });
        resp = await axios.post(url, body);
      }

      // If the Function is of Execute Type
      // Update the TabStore to store the data as API-Response
   
      if (resp?.data?.Success) {
     
        if (resp?.data?.ErrData?.length > 0) {
          var errorMessage = "";

          resp?.data?.ErrData?.map((item) => {
            errorMessage = errorMessage + item + "\n";
          });

          // auth.state.contextHolder.open({
          //   message: `An Error has occured`,
          //   description: errorMessage,
          //   duration: 3,
          //   style: { background: "#efa7a7" },
          // });
          notification.error({
            message: `An Error has occured`,
            description: errorMessage,
            duration: 3,
            style: { background: "#efa7a7" },
          });
        } else {
          // auth.state.contextHolder.open({
          //   message: `An Error has occured`,
          //   description: errorMessage,
          //   duration: 3,
          //   style: { background: "#efa7a7" },
          // });

          // auth.state.contextHolder.open({
          //   message: `Success`,
          //   placement:"top",
          //   description: resp.data?.Message,
          //   duration: 3,
          //   style: { background: "#a7efb6" },
          // });
          notification.success({
            message: `Success`,
            placement: "top",
            description: resp?.data?.Message,
            duration: 3,
            style: { background: "#a7efb6" },
            //style: { background: "#a7efa7" },
          });
        }

        //~# Checking tabstore for isPrint flag, if true run func
        if (tabStore?.state?.tabs[tabIndex]?.changedRows?.isPrint) {
          // print
          tabStore.dispatch({
            type: "UPDATE_PRINT",
            printUrl: resp?.data?.printurl,
            printIns: resp?.data?.printinstrumentno,
            tabDataIndex: tabIndex,
          });
        }

        if (tabStore?.state?.tabs[tabIndex]?.changedRows?.isPrintBarcode) {
          // print
          tabStore.dispatch({
            type: "UPDATE_PRINT_BARCODE",
            printInsBarcode: resp?.data?.forbarcode,
            tabDataIndex: tabIndex,
          });
        }

        tabStore.dispatch({
          type: "UPDATE_DATA_WITH_TABINDEX",
          fieldType: "changedRows",
          text: {}, // reset to Initial Stage use Blank instead of null - Mandar
          tabDataIndex: tabIndex,
        });

        tabStore.dispatch({
          type: "UPDATE_DATA_WITH_TABINDEX",
          fieldType: "pageStatus",
          text: "Success",
          tabDataIndex: tabIndex,
        });

        // func on every save =>

        if (executeurl != null && currentTabData(tabStore).name !== "WMS5") {
          // &&(currentTabData(tabStore).name!=="WMS5") because the api calling again wms5

          executeApi.execute(tabIndex);
        }
      } else {
       
        /// here is error mssg when succses when errData is multiple line

        if (resp?.data?.ErrData?.length > 0 && !resp?.data.Success) {
          var errorMessage = "";

          resp?.data?.ErrData?.map((item) => {
            errorMessage = errorMessage + item + "\n";
          });
          notification.error({
            message: `An Error has occured`,
            description: errorMessage,
            duration: 3,
            style: { background: "#efa7a7" },
          });
          return;
        }
        // auth.state.contextHolder.open({
        //   message: `An Error has occured`,
        //   description: resp.data?.Message,
        //   duration: 3,
        //   style: { background: "#efa7a7" },
        // });
        notification.error({
          message: `An Error has occured`,
          description: resp?.data?.Message,
          duration: 3,
          style: { background: "#efa7a7" },
        });
        tabStore.dispatch({
          type: "UPDATE_DATA_WITH_TABINDEX",
          fieldType: "pageStatus",
          text: resp?.data?.Message,
          tabDataIndex: tabIndex,
        });

        if (executeurl != null && currentTabData(tabStore).name !== "WMS5") {
          // &&(currentTabData(tabStore).name!=="WMS5") because the api calling again wms5
          executeApi.execute(tabIndex);
        }
      }
    } catch (error) {
    
      if (currentTabData(tabStore).name ==="TR016") {
        if (error?.response?.data?.Error?.ErrorDetails?.length > 0) {
          var errorMessage = "";

          error?.response?.data?.Error?.ErrorDetails.map((item) => {
            errorMessage = errorMessage + item.ErrorMessage + "\n";
          });
          notification.error({
            message: `An Error has occured`,
            description: errorMessage,
            duration: 3,
            style: { background: "#efa7a7" },
          });
          return;
        }
      }
      // auth.state.contextHolder.info({
      //   message: `An Error has Occured`,
      //   description: String(error.response.data.Error),
      //   duration: 3,
      //   style: { background: "#efa7a7" },
      // });
      else if (error?.response?.data?.Error === "undefined") {
        notification.success({
          message: `Notification`,
          description: "Process is running in background",
          duration: 3,
          style: { background: "#a7efb6" },
        });
      } else {
        notification.error({
          message: `An Error has Occured`,
          description: String(error?.response?.data?.Error),
          duration: 3,
          style: { background: "#efa7a7" },
        });
      }
    } finally {
      tabStore.dispatch({
        type: "UPDATE_DATA_WITH_TABINDEX",
        fieldType: "saveLoading",
        text: false,
        tabDataIndex: pageIndex ? pageIndex : tabStore.state.tabIndex,
      });
    }
  }

  return { execute, changedRows };
}

import { Spin, Table } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { grnColumns } from "./GDNWMSTableColumn";
import { TabStore } from "../../../TabsStructure/TabsStore";

const GDNWMSItemsTable = ({
  item,
  setRowData,
  rowData,
  openTestCertificateNoModal,
  setOpenTestCertificateNoModal,
  openSerialNoModal,
  setOpenSerialNoModal,
  handleOk,
  handleCancel,
  updateValues,
  modaldata,
  setItemCount,
  pageDetails,
  inputValues,
  setInputValues,
  serialnoInput,
  setserialnoInput,
}) => {
  const [showTable, setShowTable] = useState(true);
  const tabStore = useContext(TabStore);

  useEffect(() => {
    console.log(`tr001 items table re-rendered ?? after deleting`);
    setShowTable(false);

    setTimeout(() => {
      setShowTable(true);
    }, 200);
  }, [
    rowData?.length,
    tabStore.state.tabs[tabStore.state.tabIndex]?.editIndex,
  ]);

  return (
    <>
      <div>
        {showTable ? (
          <Table
            scroll={{
              y: 500,
            }}
            size="large"
            className="tr001-purchaseOrder-itemsTable"
            bordered
            pagination={false}
            columns={grnColumns(
              item,
              setRowData,
              rowData,
              openTestCertificateNoModal,
              setOpenTestCertificateNoModal,
              openSerialNoModal,
              setOpenSerialNoModal,
              handleOk,
              handleCancel,
              updateValues,
              modaldata,
              setItemCount,
              pageDetails,
              inputValues,
              setInputValues,
              serialnoInput,
              setserialnoInput
            )?.filter((item) => !item.hidden)}
            dataSource={rowData}
            summary={(pageData) => {
              let noOfPackages = 0;
              let itemLength = 0;
              let itemWidth = 0;
              let itemHeight = 0;
              let quantity = 0;
              let itemVolume = 0;
              let itemNetWeight = 0;
              let itemGrossWeight = 0;
              let itemTestCertificateNo = "";
              let itemSerialNo = 0;
              let natureOfPacking = "";
              let itemRemarks = "";

              pageData.forEach((item) => {
                noOfPackages =
                  Number(noOfPackages) +
                  Number(item?.packagesno ? item?.packagesno : 0);
                itemLength = Number(itemLength) + Number(item?.length);
                itemWidth = Number(itemWidth) + Number(item?.width);
                itemHeight = Number(itemHeight) + Number(item?.height);
                quantity = Number(quantity) + Number(item?.qty);
                itemVolume = Number(itemVolume) + Number(item?.volume);
                itemNetWeight =
                  Number(itemNetWeight) +
                  Number(item?.netweight ? item?.netweight : 0);
                itemGrossWeight =
                  Number(itemGrossWeight) +
                  Number(item?.grossweight ? item?.grossweight : 0);
                itemTestCertificateNo =
                  String(itemTestCertificateNo) +
                  String(item?.testcertificateno);
                itemSerialNo = Number(itemSerialNo) + Number(item?.serialno);
                natureOfPacking =
                  String(natureOfPacking) + String(item?.nopacking);
                itemRemarks = String(itemRemarks) + String(item?.remarks);
              });

              return (
                <Table.Summary>
                  {
                    <Table.Summary.Row>
                      <Table.Summary.Cell></Table.Summary.Cell>
                      <Table.Summary.Cell>
                        <p style={{ ...pStyles, textAlign: "left" }}>Total :</p>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell>
                        <p style={{ ...pStyles }}>{noOfPackages}</p>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell></Table.Summary.Cell>
                      <Table.Summary.Cell></Table.Summary.Cell>
                      <Table.Summary.Cell></Table.Summary.Cell>
                      <Table.Summary.Cell>
                        <p style={{ ...pStyles }}>{quantity}</p>
                      </Table.Summary.Cell>
                      {pageDetails?.wantbatchno ? (
                        <Table.Summary.Cell></Table.Summary.Cell>
                      ) : null}
                      <Table.Summary.Cell>
                        <p style={{ ...pStyles, textAlign: "center" }}>
                          {Number(itemVolume).toFixed(2)}
                        </p>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell>
                        <p style={{ ...pStyles }}>
                          {Number(itemNetWeight).toFixed(2)}
                        </p>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell>
                        <p style={{ ...pStyles }}>
                          {Number(itemGrossWeight).toFixed(2)}
                        </p>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell></Table.Summary.Cell>
                      <Table.Summary.Cell></Table.Summary.Cell>
                      <Table.Summary.Cell></Table.Summary.Cell>
                      <Table.Summary.Cell></Table.Summary.Cell>
                    </Table.Summary.Row>
                  }
                </Table.Summary>
              );
            }}
          />
        ) : (
          <div style={{ ...loaderStyle }}>
            <Spin size="large" />
          </div>
        )}
      </div>
    </>
  );
};

export default GDNWMSItemsTable;

const loaderStyle = {
  height: "max-content",
  width: "max-content",
  display: "flex",
  alignContent: "center",
  margin: "50px auto",
};

const pStyles = {
  textAlign: "right",
  fontWeight: "700",
  fontSize: "16px",
  color: "black",
};

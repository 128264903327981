import React, { useContext, useState, useCallback } from "react";
import { useEffect } from "react";
// import CustomerinSurveyDrop from "../../../CommonControllers/Components/CommonDropDowns/survey/CustomerinSurveyDrop";
// import SurveyTypeDrop from "../../../CommonControllers/Components/CommonDropDowns/survey/SurveyTypeDrop";
import { TabStore } from "../../../TabsStructure/TabsStore";
import TableComponent from "../../../CommonControllers/Components/TableComponent/TableComponent";
import { Button, Modal, Table, Space, Pagination, Input } from "antd";
import { AiFillEdit } from "react-icons/ai";
import { BiShow } from "react-icons/bi"
import EditableTable from "../../../CommonControllers/Components/Tables/EditableTable";
import { getbulkaddsurveyanswerfooter, getsurveyanswerfooterlist } from "../../../CommonControllers/Urls";
import EditableCommonTable from "../../../CommonControllers/Components/TableComponent/EditableCommonTable";
import axios from "axios";
import { AuthStore } from "../../../Auth/AuthStore";
import _, { set } from "lodash";
import CallbackCheckbox from "../../../CommonControllers/Components/CallbackComponents/CallbackCheckbox";
import CartIcon from '../../../Assets/noData.png'
import FilterComponents from "../../../CommonControllers/FiterComponent/FilterComponents";
import { notification } from "antd";
import { v4 as uuidv4 } from "uuid";
import useShortguid from "../../../CommonControllers/Hooks/useShortGuid";
import ImageGallery from "react-image-lightbox";
import "react-image-lightbox/style.css";
import { useRef } from "react";
import Highlighter from "react-highlight-words";
import { FilterFilled, SearchOutlined } from "@ant-design/icons";

const SurveyView = () => {
  const [col, setCol] = useState([]);
  const [row, setRow] = useState([]);
  /// ,modata data 
  const [modalrow, setModalatarow] = useState([])
  const [modalcol, setModalDatacol] = useState([])
  console.log("modalcol state", modalcol)
  const [recno, setBody] = useState(0)
  const tabStore = useContext(TabStore);
  const auth = useContext(AuthStore)
  const [data, setData] = useState(null)
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [count, setcount] = useState(0)
  const [isEditModal, setisEditModal] = useState(false)
  const [index, setindex] = useState(0)
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState(0);
  const shortguid = useShortguid();
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);
  const [searchedColumnDisplay, setSearchedColumnDisplay] = useState(null);
  const [searchParameters, setSearchParameters] = useState(false);

  console.log("shortguid", shortguid.getShortguid("SV"))


  const totalData = tabStore.state.tabs[tabStore.state.tabIndex].apiResponse;
  let api = getsurveyanswerfooterlist

  const handleSearch = (
    selectedKeys,
    confirm,
    dataIndex,
    setSearchText,
    setSearchedColumn
  ) => {
    confirm();
  };
  const handleReset = (
    clearFilters,
    setSearchText,
    setSearchedColumn,
    setSearchedColumnDisplay,
    confirm
  ) => {
    clearFilters();
    setSearchText("");
    setSearchedColumn("");
    setSearchedColumnDisplay(null);
    confirm();
  };
  const getColumnSearchProps = (
    dataIndex,
    setSearchText,
    searchInput,
    setSearchedColumn,
    searchedColumn,
    searchText,
    setSearchedColumnDisplay,
    columnname
  ) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => {
            handleSearch(selectedKeys, confirm, dataIndex);
  
            setSearchText(() => selectedKeys[0]);
            setSearchedColumn(() => dataIndex);
            setSearchedColumnDisplay(() => columnname);
          }}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => {
              handleSearch(selectedKeys, confirm, dataIndex);
              setSearchText(() => selectedKeys[0]);
              setSearchedColumn(() => dataIndex);
              setSearchedColumnDisplay(() => columnname);
            }}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() =>
              clearFilters &&
              handleReset(
                clearFilters,
                setSearchText,
                setSearchedColumn,
                setSearchedColumnDisplay,
                confirm
              )
            }
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(() => selectedKeys[0]);
              setSearchedColumn(() => dataIndex);
              setSearchedColumnDisplay(() => columnname);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <div>
        {filtered ? (
          <FilterFilled
            style={{
              backgroundColor: filtered ? "#2196F23" : undefined,
            }}
          />
        ) : null}
        <SearchOutlined
          style={{
            color: filtered ? "#1890ff" : undefined,
          }}
        />
      </div>
    ),
    onFilter: (value, record) =>
      record[dataIndex]?.toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });
  
  
  useEffect(() => {
    if (totalData) {
      let columns = totalData?.columnsinfo
      console.log("totalData?.columnsinfo", totalData?.columnsinfo)
      if (columns !== undefined) {
        columns = columns.map((item) => {
          if (item.columnname == "approved") {
            return {
              title: "Approved",
              render: (_, record) => (
                <CallbackCheckbox
                  defaultValue={record.approved == 0 ? (false) : (true)}
                />
              ),
              // ...item
              // ...getColumnSearchProps(
              //   item.columnname, 
              //   setSearchText, 
              //   searchInput, 
              //   setSearchedColumn,                 
              //   searchedColumn,
              //   searchText,
              //   setSearchedColumnDisplay,
              //   item?.columndisplay
              //   ),
            }
          } else if (item.columnname == "active") {
            return {
              title: "Active",
              render: (_, record) => (
                <CallbackCheckbox
                  defaultValue={record.active == 0 ? (false) : (true)}
                />
              ),
              // ...item
              // ...getColumnSearchProps(
              //   item.columnname, 
              //   setSearchText, 
              //   searchInput, 
              //   setSearchedColumn,                 
              //   searchedColumn,
              //   searchText,
              //   setSearchedColumnDisplay,
              //   item?.columndisplay
              //   ),
            }
          }else if (item.columnname == "trtime") {
            return {
              title: "trtime",
              render: (_, record) => (
                <p>{convertTime(record.trtime)}</p>
              ),
              // ...item
              // ...getColumnSearchProps(
              //   item.columnname, 
              //   setSearchText, 
              //   searchInput, 
              //   setSearchedColumn,                 
              //   searchedColumn,
              //   searchText,
              //   setSearchedColumnDisplay,
              //   item?.columndisplay
              //   ),
            }
          }
           else if(item.filter){
            return (
              { ...item,   
                ...getColumnSearchProps(
                item.columnname, 
                setSearchText, 
                searchInput, 
                setSearchedColumn,                 
                searchedColumn,
                searchText,
                setSearchedColumnDisplay,
                item?.columndisplay
                ), }
            )
          } else {
            return(
              {...item}
            )
          }
        }
        )
        columns = [
          {
            title: "Operation",
            key: "key",
            dataIndex: "key",
            render: (i, record) => (
              <Button onClick={() => showModal(record)} color="white">
                <BiShow color='white' />
              </Button>
            ),
          },
          ...columns
        ]
      }
      console.log("columns", columns)
      setCol(columns);
      setRow(totalData?.Message);
      //setCol(coldata)
    }
  }, [tabStore, isModalOpen, count]);

  // useEffect(() => {
  //   tabStore.dispatch({
  //     type: "UPDATE_DATA",
  //     fieldType: "changedRows",
  //     text: modalrow,
  // });
  // }, [])
  function convertTime(inputTime) {
   let req=inputTime.toString()
    const hours = Number(req[0] + req[1]);
    const minutes = Number(req[2] + req[3]);
    const seconds = Number(req[4] + req[5]);

    let convertedHours = hours;
    if (hours >= 12) {
        if (hours > 12) {
            convertedHours = hours - 12;
        }
        
    } 
    else{
      convertedHours=hours
    }

    const convertedTime = `${convertedHours}:${minutes}:${seconds}`;
    return convertedTime+`${hours>12?" PM":" AM"}`;
}

  useEffect(() => {
    let x = tabStore?.state?.tabs[0]?.changedRows;
    setData(x);
  }, [tabStore?.state?.tabs[0].changedRows]);

  const getModaltabledata = async (x) => {
    const body = {
      mainrecno: x,
      tenantrecno: auth?.state?.userdata?.tenantrecno,
    };

    const resp = await axios.post(api, body);


    if (resp.data.columnsinfo !== undefined) {
      let columns = resp?.data?.columnsinfo.map((ques) => {
        console.log("ques.type", ques.type)
        if (ques.type == "image") {
          console.log("modalcol", ques.type);
          return {
            title: "Answer",
            // dataIndex: ques?.type,
            render: (i, record) => {
              console.log("record?.answerimage", record?.answerimage)
              return (
                <div>
                  {record?.answerimage != "" ? (
                    <div>
                      <img style={{ width: 200, height: 200 }} src={`data:image/jpeg;base64,${record?.answerimage}`} />
                    </div>

                  ) : null
                  }

                </div >
              )
            }

            //   <img
            //   source={{ uri: `data:image/png;base64,${record?.answerimage}` }}
            //   alt="answerimage"
            //   style={{ maxWidth: "100%" }}
            // />
            // console.log("record.answerimage", record?.answerimage);
            // const imageData = atob(record.answerimage);
            // const imageUrl = URL.createObjectURL(new Blob([imageData], { type: "image/jpeg" }));
            // console.log("imageUrl", imageUrl);
            // return (
            //   <div>
            //     <img
            //       source={{ uri: `data:image/png;base64,${record?.answerimage}` }}
            //       alt="answerimage"
            //       style={{ maxWidth: "100%" }}
            //     />
            //   </div>
            // );
            ,
            ...ques
          };
        } else if (ques.columnname == "questionrecno") {
          return {
            title: "Question",
            dataIndex: "questionrecno",
            render: (i, record) => {
              return (
                <div>{record.questionname + "(" + record.questionrecno + ")"}</div>
              )
            }
          }
        } else {
          console.log("else condition true")
          return {
            // title: ques.columndisplay,
            // dataIndex: ques.columnname,
            // render: (_, data) => (
            //   <p><b>Hello</b></p>
            // ),
            ...ques
          };
        }
      });
      console.log("Columns are", columns)
      columns = [
        {
          title: "Operation",
          key: "key",
          dataIndex: "key",
          render: (tabIndex, record, index) => {
            console.log("edit modal", tabIndex, record, index)
            return (
              <div>
                {record.hassubquestions != 1 ? (
                  <Button onClick={() => {
                    setisEditModal(true)
                    setindex(index)

                  }} color="white">
                    <AiFillEdit color='white' />
                  </Button>
                ) : null}
              </div>

            )

          },
        },

        ...columns
      ]
      setModalDatacol(columns)
    }

    // setModalatarow(resp?.data?.Message.map((i) => {
    //   return { ...i, questionrecno: i.questionname + "(" + i.questionrecno + ")" }
    // }));

    setModalatarow(resp?.data?.Message)
  };

  const showModal = (record) => {
    console.log("modal open", record);
    //setModalData(record)
    getModaltabledata(record.recno);
    setIsModalOpen(true);
    setBody(record.recno)
    auth.surveytyperecno = record?.surveytyperecno
  };

  const handleOk = async () => {
    // console.log("rtatrds", recno)

    // const body = {
    //   data: data,
    //   tenantrecno: auth.state.userdata.tenantrecno,
    //   mainrecno: recno,
    //   domainrecno: auth.state.userdata.domainrecno,
    //   domainuserrecno: auth.state.userdata.recno,
    //   userrolerecno: auth.state.userdata.recno,
    // };
    const updatedModalRow = modalrow.map((item) => ({
      ...item,
      tenantrecno: auth.state.userdata.tenantrecno,
      domainrecno: auth.state.userdata.domainrecno,
      domainuserrecno: auth.state.userdata.recno,
      userrolerecno: auth.state.userdata.recno,
      shortguid: uuidv4()
    }));
    updatedModalRow.pop();
    try {
      const response = await axios.post(getbulkaddsurveyanswerfooter, updatedModalRow);
      console.log("response Atharv", response)
      if (response?.data?.Success) {
        notification.success({
          message: "Survey answers updated successfully!",
        });
        setIsModalOpen(false);
        setcount((p) => p + 1);
      } else {
        notification.error({
          message: "Failed to update survey answers.",
        });
      }
      // setIsModalOpen(false);
      setcount((p) => p + 1);
    } catch (error) {
      notification.error({
        message: "Failed to update survey answers. Please try again later.",
      });
      // callOk(body)
      // setIsModalOpen(false);
      // setcount((p) => p + 1)
    };
  }

  const callOk = async (x) => {
    let resp = await axios.post(getbulkaddsurveyanswerfooter, x);
  }

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleEditCancel = () => {
    setisEditModal(false);
  };
  //console.log("BODY",body)

  // const coldata = [
  //   {
  //     title: "Operation",
  //     key: "key",
  //     dataIndex: "key",
  //     render: (i, record) => (
  //       <Button onClick={() => showModal(record)} color="white">
  //         <AiFillEdit color='white' />
  //       </Button>
  //     ),
  //   },
  //   ...col,
  // ];

  const handleCheckBoxChange = (option) => {
    if (modalrow[index]?.answer?.split(",").includes(option)) {
      const updatedOptions = modalrow[index]?.answer?.split(",").filter((item) => item !== option);
      console.log("updatedOptions", updatedOptions.join(','))
      setModalatarow(p => {
        p[index].answer = updatedOptions.join(',');
        return [...p];
      });
      //   setCheckedOptions(updatedOptions);
    } else {
      const updatedOptions = [...modalrow[index]?.answer?.split(","), option];
      console.log("updatedOptions", updatedOptions.join(','))
      setModalatarow(p => {
        p[index].answer = updatedOptions.join(',');
        return [...p];
      });
      //   setCheckedOptions(updatedOptions);
    }
  };

  const renderAnswerComponent = (modalrow) => {

    if (modalrow[index]?.questiontype === 5) {
      return (
        <div>
          {modalrow[index].optionlist.split(",").map(item => {
            return (
              <div className="checkbox">

                <label>
                  <input
                    type="checkbox"
                    checked={modalrow[index]?.answer.split(",").includes(item) ? true : false}
                    onChange={() => handleCheckBoxChange(item)}
                  /> &nbsp;
                  {item}
                </label>
              </div>
            );
          })}
        </div>
      );
    }

    if (modalrow[index]?.questiontype === 4 || modalrow[index]?.questiontype === 3) {
      return (


        <div>
          {modalrow[index].optionlist.split(", ").map(item => {
            return (
              // <div style={{ flexDirection: 'row' }}>
              //   <RadioButton
              //     value="first"
              //     status={
              //       modalrow[index]?.answer === item.value ? 'checked' : 'unchecked'
              //     }
              // onPress={() => {
              //   setanswer(p => {
              //     p[index].answer = item.label;
              //     return [...p];
              //   });
              // }}
              //   />
              //   <Text style={{ marginTop: 8 }}> {item.value}</Text>
              // </div>

              <div className="radio">

                <label>
                  <input
                    type="radio"
                    checked={modalrow[index]?.answer === item}
                    onChange={() => {
                      setModalatarow(p => {
                        p[index].answer = item;
                        return [...p];
                      });
                    }}
                  /> &nbsp;
                  {item}
                </label>
              </div>
            );
          })}
        </div>

      );
    }

    if (modalrow[index]?.questiontype === 2) {
      return (
        <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>

          <Input type="text" value={modalrow[index]?.answer}
            onChange={(e) => {
              setModalatarow(p => {
                p[index].answer = e.target.value;
                return [...p];
              });
            }} />

          <img
            style={{ width: 450, height: 450, marginTop: "6%" }}
            src={`data:image/jpeg;base64,${modalrow[index]?.answerimage}`}
            onClick={() => openImageViewer(index)}
          />
          {isViewerOpen && (
            <ImageGallery
              mainSrc={`data:image/jpeg;base64,${modalrow[currentImage]?.answerimage}`}
              onCloseRequest={closeImageViewer}
            // src={`data:image/jpeg;base64,${modalrow[index]?.answerimage}`}
            // currentIndex={index}
            // disableScroll={false}
            // closeOnClickOutside={true}
            // onClose={closeImageViewer}
            />
          )}
        </div>
      );
    }

    if (modalrow[index]?.questiontype === 1) {
      return (
        <div>
          <Input
            type="text"
            value={modalrow[index]?.answer}
            onChange={(e) => {
              setModalatarow(p => {
                p[index].answer = e.target.value;
                return [...p];
              });
            }} />
        </div>
      );
    }

    return (
      <div>
        No answer available
      </div>
    );
  };

  const openImageViewer = useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-evenly",
          alignItems: "center",
        }}
      >
        <FilterComponents />

      </div>
      <Table
        columns={col}
        dataSource={row}
        pagination={{
          position: ["none"],
          pageSize: pageSize,
          defaultCurrent: page,
          current: page,
        }}
        title={() => {
          return (
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Space>
                {tabStore.state.tabs[tabStore.state.tabIndex]?.apiResponse
                  ?.selected ? (
                  <Button>
                    Total Records :{" "}
                    {
                      tabStore.state.tabs[tabStore.state.tabIndex]?.apiResponse
                        ?.selected
                    }{" "}
                    / {row?.length}{" "}
                  </Button>
                ) : (
                  <Button>Total Records : {row?.length} </Button>
                )}

                <Button>Current Page : {page} </Button>
              </Space>
              <Pagination
                total={row?.length}
                showTotal={(total, range) =>
                  `${range[0]}-${range[1]} of ${total} items`
                }
                defaultCurrent={page}
                defaultPageSize={10}
                showSizeChanger
                showQuickJumper
                current={page}
                onChange={(e, pageSize) => {
                  setPageSize(pageSize);
                  setPage(e);
                }}
                size="small"

              />
            </div>
          );
        }} />
      {/* <SurveyEditableTable columnsArray={col}
          rowsArray={row}  /> */}

      <Modal
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        width={1100}
        style={{ height: "600px" }}
      >
        {/* <EditableCommonTable rowData={modalrow} height={200}
          columnData={modalcol}
          columnLength={modalcol?.length}
          storeValue={"apiVariables.response.Message"}
          isAdd={true} 
          isEdit={true} /> */}

        {/* <EditableTable columnsArray={modalcol} rowsArray={modalrow} /> */}
        <div style={{ overflowY: "scroll", height: "700px" }}>
          <Table
            pagination={false}
            columns={modalcol}
            dataSource={modalrow}
          />
        </div>
      </Modal>

      <Modal
        open={isEditModal}
        onOk={handleEditCancel}
        onCancel={handleEditCancel}
        width={500}
        style={{ height: "600px" }}
        title={"Edit Answer"}
      >
        <div style={{ overflowY: "scroll", height: "600px" }}>
          <div>
            <p><b>Question Name:</b></p>
            <p><b>{modalrow[index]?.questionname}</b></p>


          </div>
          <div>
            {/* <p><b>Answer:</b></p> */}

            {renderAnswerComponent(modalrow)}
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default SurveyView;

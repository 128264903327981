import { Button, Modal, Table, Tooltip, Input, Space, Pagination } from 'antd';
import React, { useContext, useEffect, useState, useRef } from 'react'
import { AiOutlineEdit, AiOutlineFileAdd } from 'react-icons/ai';
import { FiEdit } from 'react-icons/fi';
import { AuthStore } from '../../Auth/AuthStore';
import { addSurveyQuestion, getSurveyQuestionsList } from '../../CommonControllers/ApiCalls';
import CallbackCheckbox from '../../CommonControllers/Components/CallbackComponents/CallbackCheckbox';
import CallbackTextInput from '../../CommonControllers/Components/CallbackComponents/CallbackTextInput';
import { currentTabData } from '../../CommonControllers/Variable';
import { TabStore } from '../../TabsStructure/TabsStore';
import AddSurveyQuestionModal from './AddSurveyQuestionModal';
import EditSurveyQuestionModal from './EditSurveyQuestionModal';
import { notification } from 'antd';
import Highlighter from "react-highlight-words";
import { FilterFilled, SearchOutlined } from "@ant-design/icons";


function SurveyQuestonTable({
  surveytype, setsurveytype
}) {
  const tabs = useContext(TabStore);
  const auth = useContext(AuthStore);

  // const [surveytype, setsurveytype] = useState()
  const [questiontyperecno, setquestiontyperecno] = useState()
  const [issubquestionof, setissubquestionof] = useState()
  const [srNo, setsrNo] = useState()
  const [questionType, setquestionType] = useState()
  const [questionname, setquestionname] = useState("")
  const [questionOpList, setquestionOpList] = useState("")
  const [foranswer, setforanswer] = useState()
  const [isgroupof, setisgroupof] = useState()
  const [mandatory, setmandatory] = useState()
  const [images, setimages] = useState()
  const [location, setlocation] = useState()
  const [autofilled, setautofilled] = useState()
  const [isCommon, setisCommon] = useState()
  const [hasgroup, sethasgroup] = useState()
  const [loyaltyPoints, setloyaltyPoints] = useState(0)
  const [fieldtype, setfieldtype] = useState("")
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [type, settype] = useState("")
  const searchInput = useRef(null);
  const [searchedColumnDisplay, setSearchedColumnDisplay] = useState(null);
  const [searchParameters, setSearchParameters] = useState(false);
  const [tableData, settableData] = useState()
  const [rendered, setrendered] = useState(false)
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [count, setcount] = useState(0)
  const [active, setactive] = useState()
  const [placeholder, setplaceholder] = useState("")
  console.log("survey questionType", questionType)
  console.log("survey mandory", mandatory)
  console.log("survey surveytype", surveytype)
  console.log("survey foranswer", foranswer)
  console.log("survey isgroupof", isgroupof)
  console.log("survey questionOpList", questionOpList)
  console.log("survey questionname", questionname)
  console.log("survey loyaltyPoints", loyaltyPoints)

  const [options, setOptions] = useState([
    { optionlist: "", hassubquestion: "", subquestions: [] },
  ]);
  const [hasSubOption, setHasSubOption] = useState(false);
  const [subOptions, setSubOptions] = useState([]);
  const [subQuestionType, setsubQuestionType] = useState()
  console.log("hasSubOption useState", hasSubOption)

  const [renderer, setRenderer] = useState(0);
  const [rendererloading, setRendererloading] = useState(false);

  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [rowRecord, setRowRecord] = useState({});
  const [getSurveyQuestionData, setgetSurveyQuestionData] = useState([])
  const [singleQuestionData, setsingleQuestionData] = useState({})
  const [changesHaveBeenMade, setChangesHaveBeenMade] = useState(1);

  const tenantrecno = auth?.state?.userdata?.tenantrecno;
  const domainrecno = auth?.state?.userdata?.domainrecno;

  useEffect(() => {
    console.log("first")
    getSurveyQuestionsList();
    if (surveytype) {
      surveyQuestionsList();
      console.log("api call", tableData)
    }
    setRendererloading(!rendererloading)
  }, [isEditModalOpen, isAddModalOpen, rendered, count, surveytype])

  console.log("tabledata", tableData)
  const initializevariables = () => {
    // setsurveytype('')
    setquestiontyperecno('')
    setissubquestionof(false)
    setsrNo('')
    setquestionType('')
    setquestionname('')
    setquestionOpList("")
    setforanswer('')
    setisgroupof('')
    setmandatory(false)
    setimages(false)
    setlocation(false)
    setautofilled(false)
    setisCommon(false)
    sethasgroup(false)
    setactive(false)
    setloyaltyPoints('')
    setfieldtype('')
    setplaceholder('')
  }
  const handleCancel = () => {
    setIsEditModalOpen(false);
    setIsAddModalOpen(false);
    setOptions([]);
    initializevariables()

    console.log("Common handle cancel clicked");
  };

  let columns = tabs?.state?.tabs[
    tabs?.state?.tabIndex
  ]?.apiResponse?.columnsinfo
  // .filter((el) => el.filter === true);
  //console.log("Columns", columns)

  // const data = tabs?.state?.tabs[tabs?.state?.tabIndex]?.apiResponse?.Message;

  const data = tableData?.length > 0 ? tableData : tabs?.state?.tabs[tabs?.state?.tabIndex]?.apiResponse?.Message;

  console.log("dddddddddddddddddddddddddddddddata", data, columns)

  const handleSearch = (
    selectedKeys,
    confirm,
    dataIndex,
    setSearchText,
    setSearchedColumn
  ) => {
    confirm();
  };
  const handleReset = (
    clearFilters,
    setSearchText,
    setSearchedColumn,
    setSearchedColumnDisplay,
    confirm
  ) => {
    clearFilters();
    setSearchText("");
    setSearchedColumn("");
    setSearchedColumnDisplay(null);
    confirm();
  };
  const getColumnSearchProps = (
    dataIndex,
    setSearchText,
    searchInput,
    setSearchedColumn,
    searchedColumn,
    searchText,
    setSearchedColumnDisplay,
    columnname
  ) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => {
            handleSearch(selectedKeys, confirm, dataIndex);
  
            setSearchText(() => selectedKeys[0]);
            setSearchedColumn(() => dataIndex);
            setSearchedColumnDisplay(() => columnname);
          }}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => {
              handleSearch(selectedKeys, confirm, dataIndex);
              setSearchText(() => selectedKeys[0]);
              setSearchedColumn(() => dataIndex);
              setSearchedColumnDisplay(() => columnname);
            }}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() =>
              clearFilters &&
              handleReset(
                clearFilters,
                setSearchText,
                setSearchedColumn,
                setSearchedColumnDisplay,
                confirm
              )
            }
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(() => selectedKeys[0]);
              setSearchedColumn(() => dataIndex);
              setSearchedColumnDisplay(() => columnname);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <div>
        {filtered ? (
          <FilterFilled
            style={{
              backgroundColor: filtered ? "#2196F23" : undefined,
            }}
          />
        ) : null}
        <SearchOutlined
          style={{
            color: filtered ? "#1890ff" : undefined,
          }}
        />
      </div>
    ),
    onFilter: (value, record) =>
      record[dataIndex]?.toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });
  
  //console.log("currentTabData(tabs)?.changedRows?.questionsrno", currentTabData(tabs)?.changedRows?.questionsrno)
  if (columns !== undefined) {
    columns = columns.map((item) => {
      // console.log("item", item)
      if (item.columnname == "mandatory") {
        return {

          title: "Mandatory",
          render: (_, record) => (

            <CallbackCheckbox
              defaultValue={record.mandatory == 0 ? (false) : (true)}
            />
          ),
          // ...getColumnSearchProps(item.columnname),
        }
      } else if (item.columnname == "images") {
        return {

          title: "Images",
          render: (_, record) => (

            <CallbackCheckbox
              defaultValue={record.images == 0 ? (false) : (true)}
            />
          ),
          // ...getColumnSearchProps(item.columnname),
        }
      } else if (item.columnname == "hassubquestions") {
        return {

          title: "Hassubquestions",
          render: (_, record) => (

            <CallbackCheckbox
              defaultValue={record.hassubquestions == 0 ? (false) : (true)}
            />
          ),
          // ...getColumnSearchProps(item.columnname),
        }
      } else if (item.columnname == "hasgroup") {
        return {

          title: "Hasgroup",
          render: (_, record) => (

            <CallbackCheckbox
              defaultValue={record.hasgroup == 0 ? (false) : (true)}
            />
          ),
          // ...getColumnSearchProps(item.columnname),
        }
      } else if (item.columnname == "location") {
        return {

          title: "Location",
          render: (_, record) => (

            <CallbackCheckbox
              defaultValue={record.location == 0 ? (false) : (true)}
            />
          ),
          // ...getColumnSearchProps(item.columnname),
        }
      } else if (item.columnname == "autofilled") {
        return {

          title: "Autofilled",
          render: (_, record) => (

            <CallbackCheckbox
              defaultValue={record.autofilled == 0 ? (false) : (true)}
            />
          ),
          // ...getColumnSearchProps(item.columnname),
        }
      } else if (item.columnname == "multipleanswers") {
        return {

          title: "Multiple Answers",
          render: (_, record) => (

            <CallbackCheckbox
              defaultValue={record.multipleanswers == 0 ? (false) : (true)}
            />
          ),
          // ...getColumnSearchProps(item.columnname),
        }
      } else if (item.columnname == "active") {
        return {

          title: "Active",
          render: (_, record) => (

            <CallbackCheckbox
              defaultValue={record.active == 0 ? (false) : (true)}
            />
          ),
          // ...getColumnSearchProps(item.columnname),
        }
      }
      else if (item.columnname == "issubquestionof") {
        return {
          title: "Is Sub Question Of",
          render: (_, record) => (
            // <CallbackTextInput 
            // defaultValue={record.active == 0 ? "": ""}
            // />
            <p>{record.issubquestionof == 0 ? "" : record.issubquestionof}</p>
          ),
          // ...getColumnSearchProps(item.columnname),
        }
      } else if (item.columnname == "isgroupof") {
        return {
          title: "Is Group Of",
          render: (_, record) => (
            // <CallbackTextInput 
            // defaultValue={record.active == 0 ? "": ""}
            // />
            <p>{record.isgroupof == 0 ? null : record.isgroupof}</p>
          ),
          // ...getColumnSearchProps(item.columnname),
        }
      }
      else if(item.filter) {
        return (
          { 
            ...item, 
            ...getColumnSearchProps(
            item.columnname, 
            setSearchText, 
            searchInput, 
            setSearchedColumn,                 
            searchedColumn,
            searchText,
            setSearchedColumnDisplay,
            item?.columndisplay
            ), 
          } 
        )
      } else {
        return (
          {...item}
        )
      }
    }

    )
    columns = [
      {
        title: "Action",
        render: (_, record) => (
          <Tooltip title="Edit Row Details" placement="bottom">
            <Button
              onClick={() => {
                setChangesHaveBeenMade((p) => Number(p) + 1)
                showEditModal(record)
              }}
              style={{ paddingTop: "6.4px" }}
            >
              <AiOutlineEdit size="1.08rem" color="white" />
            </Button>
          </Tooltip>
        ),
      },

      ...columns,
    ];
  }

  const surveyQuestionsList = async () => {
    const response = await getSurveyQuestionsList({
      tenantrecno: tenantrecno,
      domainrecno: domainrecno,
      domainuserrecno: auth?.state?.userdata?.recno,
      fastpath: "SV002",
      surveytype: surveytype?.recno
    });

    const data = response?.Message
    settableData(data);
  };



  // useEffect(() => {
  //   tabs.dispatch({ type: "API_DATA", text: true, fieldType: "forlist" });
  //   // Need to call api here getcustomerlist ??
  //   addSurveyQuestion({
  //     tenantrecno: tenantrecno,
  //     domainrecno: domainrecno,

  //   }).then(
  //     (response) => response?.Success && setgetSurveyQuestionData(response?.Message)
  //   );
  //   console.log("survey table rendered", data);
  // }, [
  //   tabs?.state?.tabs[tabs?.state?.tabIndex]?.apiVariables?.response?.data,
  //   renderer,
  // ]);

  const showEditModal = (record) => {
    setChangesHaveBeenMade((p) => Number(p) + 1)
    setRowRecord(record);
    console.log("record", record)
    const singleQuestionData = data?.find(
      (elem) => elem?.recno === record?.recno,
    );
    setsingleQuestionData(singleQuestionData);
    setIsEditModalOpen(true);

    setRenderer((prev) => prev + 1);
  };



  const showAddModal = () => {
    setIsAddModalOpen(true);
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // const [addSurveyQuestionLoading, setAddSurveyQuestionLoading] = useState(false);
  // const [addSurveyQuestionError, setAddSurveyQuestionError] = useState(null);
  // const [addSurveyQuestionData, setAddSurveyQuestionData] = useState(null);

  // useEffect(() => {
  //   if (addSurveyQuestionData) {
  //     notification.success({
  //       message: 'Success',
  //       description: 'Question Added Successfully',
  //     });

  //     setOptions([]);
  //     setquestionname('');
  //     setquestionType({});
  //     setlocation(false);
  //     setmandatory(false);
  //     setimages(false);
  //     setautofilled(false);
  //   }
  // }, [addSurveyQuestionData]);

  // useEffect(() => {
  //   if (addSurveyQuestionError) {
  //     notification.error({
  //       message: 'Error',
  //       description: 'Failed to add question',
  //     });
  //   }
  // }, [addSurveyQuestionError]);

  // const handleAddOk = () => {
  //   setAddSurveyQuestionLoading(true);

  //   addSurveyQuestion({
  //     tenantrecno: tenantrecno,
  //     domainrecno: domainrecno,
  //     surveytype: surveytype?.recno,
  //     questionname: questionname,
  //     questiontype: questionType?.recno,
  //     location: location ? (1) : (0),
  //     mandatory: mandatory ? (1) : (0),
  //     images: images ? (1) : (0),
  //     autofilled: autofilled ? (1) : (0),
  //     data: options        
  //   })
  //     .then((response) => setAddSurveyQuestionData(response))
  //     .catch((error) => setAddSurveyQuestionError(error))
  //     .finally(() => setAddSurveyQuestionLoading(false), setIsAddModalOpen(false));

  // };

  //   const handleAddOk = () => {
  //     setIsAddModalOpen(false);
  //     console.log("add modal input data", currentTabData(tabs)?.apiVariables);
  //     console.log("currentTabData(tabs)?.changedrows", currentTabData(tabs)?.changedRows)
  //     console.log("currentTabData(tabs)?.apiVariables", currentTabData(tabs)?.apiVariables)
  //     const payload = [
  //       {
  //         options
  //       },
  //     ];
  //     if (payload[0].questionname !== "") {
  //       addSurveyQuestion({
  //         tenantrecno: tenantrecno,
  //         domainrecno: domainrecno,
  //         surveytype: surveytype?.recno,
  //         questionname: questionname,
  //         questiontype: questionType?.recno,
  //         location: location ? (1) : (0),
  //         mandatory: mandatory ? (1) : (0),
  //         images: images ? (1) : (0),
  //         autofilled: autofilled ? (1) : (0),
  //         data: options        
  //       })
  //       .then((response) => {
  //         if (response.success) {
  //           notification.success({
  //             message: "Question Added Successfully",
  //             description: "The question has been added successfully",
  //           });
  //           // Clear all fields
  //           setquestionname("");
  //           setOptions([]);
  //           // etc.
  //         } else {
  //           notification.error({
  //             message: "Error Adding Question",
  //             description: "There was an error adding the question, please fill empty fields.",
  //           });
  //         }
  //       })
  //       .then(() => setIsAddModalOpen(false));
  //   } else {
  //     console.log("payload sv002", "mobile ip is empty");
  //   }
  // };

  /////////////?><><><<<><>////////////////////////////////////
  // const handleAddOk = () => {
  //   const payload = {
  //     tenantrecno: tenantrecno,
  //     domainrecno: domainrecno,
  //     surveytype: surveytype?.recno,
  //     questionname: questionname,
  //     questiontype: questionType?.recno,
  //     location: location ? (1) : (0),
  //     mandatory: mandatory ? (1) : (0),
  //     images: images ? (1) : (0),
  //     autofilled: autofilled ? (1) : (0),
  //     data: options
  //   };

  //   if (questionType?.recno === 11236) {
  //     payload.data = options;
  //   } else {
  //     payload.data = [];
  //     payload.hassubquestions = 0;
  //   }

  //   if (payload.questionname !== "") {
  //     addSurveyQuestion(payload)
  //       .then((response) => {
  //         if (response.success) {
  //           setquestionname("");
  //           setquestionType("");
  //           setlocation(false);
  //           setmandatory(false);
  //           setimages(false);
  //           setautofilled(false);
  //           setOptions([]);
  //           notification.success({
  //             message: "Question Added Successfully"
  //           });
  //           setIsAddModalOpen(false);
  //         } else {
  //           notification.error({
  //             message: "Failed to add question"
  //           });
  //         }
  //       });
  //   };
  // }

  // const handleAddOk = () => {
  //   const payload = {
  //     tenantrecno: tenantrecno,
  //     domainrecno: domainrecno,
  //     surveytype: surveytype?.recno,
  //     questionname: questionname,
  //     questiontype: questionType?.recno,
  //     location: location ? (1) : (0),
  //     mandatory: mandatory ? (1) : (0),
  //     images: images ? (1) : (0),
  //     autofilled: autofilled ? (1) : (0),
  //     data: options
  //   };

  //   if (questionType?.recno === 11236) {
  //     payload.data = options;
  //   } else {
  //     payload.data = [];
  //     payload.hassubquestions = 0;
  //   }

  //   if (payload.questionname !== "") {
  //     addSurveyQuestion(payload)
  //       .then((response) => {
  //         console.log("Response:", response); 
  //         if (response.success && response.data.questionname === questionname) {
  //           setquestionname("");
  //           setquestionType("");
  //           setlocation(false);
  //           setmandatory(false);
  //           setimages(false);
  //           setautofilled(false);
  //           setOptions([]);
  //           notification.success({
  //             message: "Question Added Successfully"
  //           });
  //           setIsAddModalOpen(false);
  //         } else {
  //           notification.error({
  //             message: "Failed to add question"
  //           });
  //         }
  //       })
  //       .catch((error) => {
  //         console.log("Error:", error); // Add this to check for any errors
  //         notification.error({
  //           message: "Failed to add question"
  //         });
  //       });
  //   }
  // };

  const handleAddOk = () => {
    const payload = {
      tenantrecno: tenantrecno,
      domainrecno: domainrecno,
      surveytype: surveytype?.recno,
      questionname: questionname,
      questiontype: questionType?.code,
      loyaltypoints: loyaltyPoints,
      fieldtype: fieldtype,
      placeholder: placeholder,
      location: location ? (1) : (0),
      mandatory: mandatory ? (1) : (0),
      images: images ? (1) : (0),
      autofilled: autofilled ? (1) : (0),
      data: options
    };

    if (questionType?.code === "3") {/* questionType?.recno === 11236*/
      payload.data = options;
      payload.fieldtype = null;
    } else {
      payload.data = [];
      payload.hassubquestions = 0;
    }

    if (payload.questionname !== "") {
      addSurveyQuestion(payload)
        .then((response) => {
          console.log(response.Success);
          if (response.Success) {
            initializevariables();
            setOptions([]);
            notification.success({
              message: "Question Added Successfully"
            });
            setrendered(true);
            setIsAddModalOpen(false);
            setcount((prev) => prev + 1)
          } else {
            notification.error({
              message: "Failed to add question"
            });
          }
        });
    }
  };


  const handleEditOk = () => {
    console.log("Survey handleEditOk called");
    const payload = {
      recno: rowRecord?.recno,
      tenantrecno: tenantrecno,
      domainrecno: domainrecno,
      questionsrno: srNo,
      surveytype: surveytype?.recno,
      questionname: questionname,
      questiontype: questionType,
      loyaltypoints: loyaltyPoints,
      fieldtype: fieldtype,
      placeholder: placeholder,
      location: location ? (1) : (0),
      mandatory: mandatory ? (1) : (0),
      images: images ? (1) : (0),
      autofilled: autofilled ? (1) : (0),
      active: active ? (1) : (0),
      data: options
    };

    if (questionType?.code === "3") {
      payload.data = options;
      payload.fieldtype = null;
    } else {
      payload.data = [];
      payload.hassubquestions = 0;
    }

    if (payload.questionname !== "") {
      console.log("Surveyhandle ok called", payload)
      addSurveyQuestion(payload)
        .then((response) => {
          initializevariables();

          if (response.Success) {
            initializevariables();
            setOptions([]);
            notification.success({
              message: "Question Updated Successfully"
            });
            setrendered(true);
            setIsEditModalOpen(false);
            setcount((prev) => prev + 1)
          } else {
            notification.error({
              message: "Failed to update question"
            });
          }
        });
    }
  }

  // const handleAddOk = () => {
  //   try {
  //     const payload = {
  //       tenantrecno: tenantrecno,
  //       domainrecno: domainrecno,
  //       surveytype: surveytype?.recno,
  //       questionname: questionname,
  //       questiontype: questionType?.recno,
  //       location: location ? (1) : (0),
  //       mandatory: mandatory ? (1) : (0),
  //       images: images ? (1) : (0),
  //       autofilled: autofilled ? (1) : (0),
  //     };
  //     if (questionType?.recno === 11236) {
  //       payload.data = options;
  //     } else {
  //       payload.data = [];
  //       payload.hassubquestion = 0;
  //     }
  //     if (payload.questionname !== "") {
  //       addSurveyQuestion(payload)
  //         .then((response) => {
  //           if (response.success) {
  //             notification.success({ message: "Question Added Successfully" });
  //             setIsAddModalOpen(false);
  //             // clear all fields here
  //           } else {
  //             notification.error({ message: "Failed to add question" });
  //           }
  //         });
  //     } else {
  //       console.log("payload sv002", "mobile ip is empty");
  //     }
  //   } catch (error) {
  //     notification.error({ message: "Failed to add question" });
  //   }
  // };


  // const handleEditOk = () => {
  //   // setIsEditModalOpen(false);
  //   // console.log("ms100 edit row", rowRecord, rowRecord?.recno);
  //   console.log(" in ok ")
  //   const payload = [
  //     {
  //       recno: rowRecord?.recno,
  //       // surveytyperecno:
  //       //   currentTabData(tabs)?.changedRows?.surveytype,
  //       // questionname:
  //       //   currentTabData(tabs)?.changedRows?.questionname,
  //       // foranswer:
  //       //   currentTabData(tabs)?.changedRows?.foranswer,
  //       // questiontype:
  //       //   currentTabData(tabs)?.changedRows?.questiontype,
  //       // optionlist:
  //       //   currentTabData(tabs)?.changedRows?.optionlist,
  //       // hasgroup:
  //       //   currentTabData(tabs)?.changedRows?.hasgroup,
  //       // location:
  //       //   currentTabData(tabs)?.changedRows?.location,
  //       // mandatory:
  //       //   currentTabData(tabs)?.changedRows?.mandatory,
  //       // images:
  //       //   currentTabData(tabs)?.changedRows?.images,
  //       // autofilled:
  //       //   currentTabData(tabs)?.changedRows?.autofilled,
  //       // isCommon:
  //       //   currentTabData(tabs)?.changedRows?.iscommon,
  //       // issubquestionof:
  //       //   currentTabData(tabs)?.changedRows?.issubquestionof,
  //       // isgroupof:
  //       //   currentTabData(tabs)?.changedRows?.isgroupof,
  //       // questionsrno:
  //       //   currentTabData(tabs)?.changedRows?.questionsrno,
  //     },
  //   ];

  //   if (payload[0].descn === "") {
  //     alert("Please Enter Descn");
  //     return;
  //   }

  //   if (payload[0].mobile !== "") {
  //     addSurveyQuestion({
  //       tenantrecno: tenantrecno, data: {
  //         recno: rowRecord?.recno,
  //         surveytype: surveytype?.recno,
  //         questionname: questionname,
  //         foranswer: foranswer?.optionlist,
  //         questiontype: questionType?.recno,
  //         optionlist: questionOpList,
  //         hasgroup: hasgroup ? (1) : (0),
  //         location: location ? (1) : (0),
  //         mandatory: mandatory ? (1) : (0),
  //         images: images ? (1) : (0),
  //         autofilled: autofilled ? (1) : (0),
  //         isCommon: isCommon ? (1) : (0),
  //         issubquestionof: issubquestionof?.recno,
  //         isgroupof: isgroupof?.recno,
  //         questionsrno: srNo,
  //       }
  //     })
  //       .then(() => setRenderer((prev) => prev + 1))
  //       .then((response) => console.log("payload sv002 edit", response))
  //       .then(() => setIsEditModalOpen(false))
  //       .then(alert("Question Edited Successfully"));
  //   } else {
  //     console.log("Mobile is empty");
  //   }
  // };
  return (
    <div>
      <Tooltip title="Add row details" placement="right">
        <Button
          // disabled={data === undefined}
          onClick={() => {
            setChangesHaveBeenMade((p) => Number(p) + 1)
            showAddModal()
          }}

        >
          <AiOutlineFileAdd size="1.08rem" color="white" />
        </Button>
      </Tooltip>

      {/* <Table
        columns={columns}
        dataSource={data}
        size={"middle"}
        scroll={{
          x: 1200,
        }}
        bordered
        pagination={{ position: ["topRight"], pageSize: 7 }}
      /> */}
      <form style={{ height: "1000px", position: "relative" }}>
        <Table
          columns={columns}
          dataSource={data}
          pagination={{
            position: ["none"],
            pageSize: pageSize,
            defaultCurrent: page,
            current: page,
          }}
          title={() => {
            return (
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Space>
                  {tabs.state.tabs[tabs.state.tabIndex]?.apiResponse
                    ?.selected ? (
                    <Button>setquestionType
                      Total Records :{" "}
                      {
                        tabs.state.tabs[tabs.state.tabIndex]?.apiResponse
                          ?.selected
                      }{" "}
                      / {data?.length}{" "}
                    </Button>
                  ) : (
                    <Button>Total Records : {data?.length} </Button>
                  )}

                  <Button>Current Page : {page} </Button>
                </Space>
                <Pagination
                  total={data?.length}
                  showTotal={(total, range) =>
                    `${range[0]}-${range[1]} of ${total} items`
                  }
                  defaultCurrent={page}
                  defaultPageSize={10}
                  showSizeChanger
                  showQuickJumper
                  current={page}
                  onChange={(e, pageSize) => {
                    setPageSize(pageSize);
                    setPage(e);
                  }}
                />
              </div>
            );
          }}
          size={"small"}
          bordered
          rowClassName="editable-row"
          // sticky={true}
          // tableLayout="auto"
          scroll={{
            scrollToFirstRowOnChange: true,
            x: columns?.reduce((acc, el, i, arr) => (acc += el?.width), 0),
            y: `500px`,
          }}
          style={{ position: "fixed" }} // used to avoid overwrite of filtercomponent dropdown
        ></Table>
      </form>
      <Modal
        title="Edit details"
        open={isEditModalOpen}
        onOk={handleEditOk}
        onCancel={handleCancel}
        width={1200}
        style={{ top: 20 }}
      >
        <EditSurveyQuestionModal
          changes={changesHaveBeenMade}
          data={singleQuestionData}
          renderer={renderer}
          setsurveytype={setsurveytype}
          setquestiontyperecno={setquestiontyperecno}
          setissubquestionof={setissubquestionof}
          setsrNo={setsrNo}
          setquestionType={setquestionType}
          setquestionname={setquestionname}
          setquestionOpList={setquestionOpList}
          setforanswer={setforanswer}
          setisgroupof={setisgroupof}
          setmandatory={setmandatory}
          setimages={setimages}
          setlocation={setlocation}
          setautofilled={setautofilled}
          setisCommon={setisCommon}
          sethasgroup={sethasgroup}
          setOptions={setOptions}
          setHasSubOption={setHasSubOption}
          setSubOptions={setSubOptions}
          surveytype={surveytype}
          questiontyperecno={questiontyperecno}
          issubquestionof={issubquestionof}
          srNo={srNo}
          questionType={questionType}
          questionname={questionname}
          questionOpList={questionOpList}
          foranswer={foranswer}
          isgroupof={isgroupof}
          mandatory={mandatory}
          images={images}
          location={location}
          autofilled={autofilled}
          isCommon={isCommon}
          hasgroup={hasgroup}
          options={options}
          hasSubOption={hasSubOption}
          subOptions={subOptions}
          loyaltyPoints={loyaltyPoints}
          setloyaltyPoints={setloyaltyPoints}
          fieldtype={fieldtype}
          setfieldtype={setfieldtype}
          active={active}
          setactive={setactive}
          placeholder={placeholder}
          setplaceholder={setplaceholder}
        />
      </Modal>

      <Modal
        title="Add Details"
        open={isAddModalOpen}
        onOk={handleAddOk}
        onCancel={handleCancel}
        width={1200}
        style={{ top: 20 }}
      >
        <AddSurveyQuestionModal
          changes={changesHaveBeenMade}
          setsurveytype={setsurveytype}
          setquestiontyperecno={setquestiontyperecno}
          setissubquestionof={setissubquestionof}
          setsrNo={setsrNo}
          setquestionType={setquestionType}
          setquestionname={setquestionname}
          setquestionOpList={setquestionOpList}
          setforanswer={setforanswer}
          setisgroupof={setisgroupof}
          setmandatory={setmandatory}
          setimages={setimages}
          setlocation={setlocation}
          setautofilled={setautofilled}
          setisCommon={setisCommon}
          sethasgroup={sethasgroup}
          setOptions={setOptions}
          setHasSubOption={setHasSubOption}
          setSubOptions={setSubOptions}
          surveytype={surveytype}
          questiontyperecno={questiontyperecno}
          issubquestionof={issubquestionof}
          srNo={srNo}
          questionType={questionType}
          questionname={questionname}
          questionOpList={questionOpList}
          foranswer={foranswer}
          isgroupof={isgroupof}
          mandatory={mandatory}
          images={images}
          location={location}
          autofilled={autofilled}
          isCommon={isCommon}
          hasgroup={hasgroup}
          options={options}
          hasSubOption={hasSubOption}
          subOptions={subOptions}
          loyaltyPoints={loyaltyPoints}
          setloyaltyPoints={setloyaltyPoints}
          fieldtype={fieldtype}
          setfieldtype={setfieldtype}
          subQuestionType={subQuestionType}
          setsubQuestionType={setsubQuestionType}
          placeholder={placeholder}
          setplaceholder={setplaceholder}
        />
      </Modal>
    </div>
  )
}

export default SurveyQuestonTable
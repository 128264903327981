import { DownOutlined } from "@ant-design/icons";
import { Button, Space, Tree } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { getMenuApi } from "../CommonControllers/ApiCalls";
import {  checkFastPath } from "../TabsStructure/tabFunctions";
import { TabStore } from "../TabsStructure/TabsStore";
import { convertToExpectedFormat } from "./menu";

function TreeView() {
  // const [menuData, setMenuData] = useState();
  const tabStore = useContext(TabStore);
  const { DirectoryTree } = Tree;

  // useEffect(() => {
  //   getMenu();
  // }, []);

  // async function getMenu() {
  //   const resp = await getMenuApi({ domainrecno: "3" });

  //   setMenuData(convertToExpectedFormat(resp));

  //   tabStore.dispatch({ type: "API_DATA", menuArray: resp });
  // }

  const onSelect = (selectedKeys, info) => {
    console.log("selected?././.asdasdasdf", selectedKeys, info.node.fastpath);
    if (info.node.fastpath) {
      checkFastPath(info.node.fastpath, tabStore);
    }
  };
  const onExpand = (keys, info) => {
    console.log("Trigger Expand", keys, info);
  };

  // console.log("Tree Data", menuData);

  return (
    <div>
      <DirectoryTree
        showLine
        multiple
        // switcherIcon={<DownOutlined />}
        onSelect={onSelect}
        treeData={tabStore.state.menuArray}
        onExpand={onExpand}
        width={500}
      ></DirectoryTree>
    </div>
  );
}

export default TreeView;

import { Button, Popconfirm } from "antd";
import { AiFillDelete } from "react-icons/ai";
import { removeByAttribute } from "../../../CommonControllers/AppFunction";
import CallbackDateInput from "../../../CommonControllers/Components/CallbackComponents/CallbackDateInput";
import { BiSelectMultiple } from "react-icons/bi";

export const piColumns = (
  igstappl,
  setRowData,
  rowData,
  scandata,
  setScandata,
  setOpenItem,
  sidate,
  setModal,
  scanner,
  onedconeinvoice,
  setScanner,
  pageDetails,
  setItemForSelection,
  setopenBatch
) => {
  if (igstappl) {
    return [
      {
        title: " ",
        dataIndex: "active",
        key: "active",
        width: "80px",
        align: "center",
        render: (_, text) => {
          return (
            <Popconfirm
              title="Do you want to Cancel the changes?"
              onConfirm={(e) => {
                handleRemove(text, setRowData, rowData);
              }}
            >
              <Button icon={<AiFillDelete />}></Button>
            </Popconfirm>
          );
        },
      },
      {
        width: 300,
        title: "Item Description", // nohit-425 change title Item to Item Description
        dataIndex: "item",
        key: "item",
        align: "left", // nohit-388 set Align for column
      },

      {
        width: 80,
        title: "Quantity",
        dataIndex: "qty",
        key: "qty",
        align: "right", // nohit-388 set Align for column
        render: (_, text) => {
          console.log("Sale rate of the Item is ", text);
          return (
            //{Pradeep task id -314 }
            text.newone == 1 ? (
              <input
                className="tr001Input"
                disabled={
                  onedconeinvoice && text?.doctype == "DC" ? true : false
                }
                defaultValue={text.qty}
                onFocus={(e) => e.target.select()}
                onChange={(e) => {
                  text.qty = e.target.value;
                  handleChange(text, setRowData, igstappl, rowData);
                }}
              ></input>
            ) : (
              <p>{text.qty}</p>
            )
          );
        },
      },

      {
        hidden: pageDetails?.wantbatchno ? false : true,
        width: 200,
        title: "Batch No.",
        dataIndex: "itembatchno",
        key: "itembatchno",
        render: (_, text) => {
          console.log("Text is888", text);
          return (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <p
                //className="tr001Input"
                style={{ width: "70%" }}
                // rajendra-338 calendar disabled when isservice=true
                /// let isservice=item?.isservice //// task id -345 is service rajendra
                // disabled={
                //   onedconeinvoice &&
                //   (text?.doctype == "DC" || text?.isservice == 1)
                //     ? true
                //     : false
                // }
                // onFocus={(e) => e.target.select()}
                // defaultValue={text?.isservice == 1 ? null : text.itembatchno}
                // onChange={(e) => {
                //   console.log("TR013 igst =======>", text);
                //   text.itembatchno = e.target.value;
                //   handleChange(text, setRowData, igstappl, rowData);
                // }}
              >
                {text?.itembatchno}
              </p>
              <div>
                {text?.wantbatchno ? (
                  <Button
                    icon={
                      <BiSelectMultiple
                        onClick={() => {
                          setItemForSelection(text);
                          setopenBatch(true);
                        }}
                      />
                    }
                  ></Button>
                ) : null}
              </div>
            </div>
          );
        },
      },
      // {
      //   hidden: pageDetails?.wantmanudate === 1 ? false : true,
      //   width: 300,
      //   title: "Manufacture Date",
      //   dataIndex: "manufacturedate",
      //   key: "manufacturedate",
      //   align: "left", // nohit-388 set Align for column
      //   render: (_, text) => {
      //     return (
      //       // <CallbackDateInput
      //       //   defaultValue={typeof text.manfacturedate=="number"?text.manfacturedate.toString():text.manfacturedate}
      //       //   id={"po-manufacturedate-" + text.shortguid}
      //       //   apiKey={"manufacturedate"}
      //       //   initial
      //       //   returnValue={(e) => {
      //       //     text.manufacturedate = e;
      //       //     handleChange(text, setRowData, igstappl, rowData);
      //       //   }}
      //       //   dateLessthan={sidate}
      //       //   postAlertMsg={""}

      //       // />
      //       <CallbackDateInput
      //         defaultValue={text.manufacturedate}
      //         id={"si-manufacturedate-" + text.shortguid}
      //         apiKey={"manufacturedate"}
      //         initial
      //         // rajendra-338 calendar disabled when isservice=true
      //         disabled={
      //           onedconeinvoice &&
      //           (text?.doctype == "DC" || text?.isservice == 1)
      //             ? true
      //             : false
      //         }
      //         returnValue={(e) => {
      //           text.manufacturedate = e;
      //           handleChange(text, setRowData, igstappl, rowData);
      //         }}
      //         dateLessthan={sidate}
      //         postAlertMsg={"Manufacture date must be less than SI date"}
      //       />
      //     );
      //   },
      // },
      // {
      //   hidden: pageDetails?.wantexpdate === 1 ? false : true,
      //   width: 300,
      //   title: "Expiry Date",
      //   dataIndex: "expdate",
      //   key: "expdate",
      //   align: "left", // nohit-388 set Align for column
      //   render: (_, text) => {
      //     console.log("SIDATE", text);
      //     return (
      //       <CallbackDateInput
      //         defaultValue={
      //           typeof text.expdate == "number"
      //             ? text.expdate.toString()
      //             : text.expdate
      //         }
      //         // rajendra-338 calendar disabled when isservice=true
      //         disabled={
      //           onedconeinvoice &&
      //           (text?.doctype == "DC" || text?.isservice == 1)
      //             ? true
      //             : false
      //         }
      //         id={"po-expdate-" + text.shortguid}
      //         apiKey={"expecteddeliverydate"}
      //         returnValue={(e) => {
      //           text.expdate = e;
      //           handleChange(text, setRowData, igstappl, rowData);
      //         }}
      //         dateGreaterthan={text.manufacturedate}
      //         // dateLessthan={text.manufacturedate}
      //       />
      //     );
      //   },
      // },
      {
        width: 120,
        title: "Rate",
        dataIndex: "rate",
        key: "rate",
        align: "right", // nohit-388 set Align for column
        render: (_, text) => {
          console.log("Sale rate of the Item is ", text);
          return (
            //{Pradeep task id -314 }
            <input
              className="tr001Input"
              disabled={onedconeinvoice && text?.doctype == "DC" ? true : false}
              defaultValue={text.rate}
              onFocus={(e) => e.target.select()}
              onChange={(e) => {
                text.rate = e.target.value;
                handleChange(text, setRowData, igstappl, rowData);
              }}
            ></input>
          );
        },
      },

      {
        width: 110,
        title: "Discount%",
        dataIndex: "discount",
        key: "discount",
        align: "right", // nohit-388 set Align for column
        render: (_, text) => {
          console.log("text check:", text);
          return (
            <input
              className="tr001Input"
              /// task-id-253 rajendra discount amount input is unable
              // disabled={text.discountamt!=0||text.discountamt!="0.00"?true:false}

              value={
                text.discount != "0.00" || text.discount != 0
                  ? text.discount
                  : null
              }
              defaultValue={text?.discount}
              onFocus={(e) => e.target.select()}
              onChange={(e) => {
                const discountValue = e.target.value;
                // Ensure that the value is a number before updating
                if (!isNaN(discountValue)) {
                  // Ensure the value is between 0 and 100
                  const clampedValue = Math.min(
                    100,
                    Math.max(0, discountValue)
                  );
                  text.discount = clampedValue;
                  handleChange(text, setRowData, igstappl, rowData);
                }
              }}
              type="number"
              max={100} /// task-id-253 rajendra discount amount input is unable
            ></input>
          );
        },
      },
      {
        width: 120,
        title: "Discount",
        dataIndex: "discountamt",
        key: "discountamt",
        align: "right", // nohit-388 set Align for column
        render: (_, text) => {
          return <p>{text?.discountamt}</p>; /// task id-253 the input box is remove here according to the percentage input discount change
        },
      },
      {
        width: 120,
        title: "Taxable Amount",
        dataIndex: "taxableamount",
        key: "taxableamount",
        align: "right", // nohit-388 set Align for column
      },
      {
        width: 120,
        title: "IGST%",
        dataIndex: "igstrate",
        key: "igstrate",
        align: "right", // nohit-388 set Align for column
      },
      {
        width: 120,
        title: "IGST",
        dataIndex: "igstamt",
        key: "igstamt",
        align: "right", // nohit-388 set Align for column
      },
      {
        width: 150,
        title: "Amount",
        dataIndex: "amount",
        key: "amount",
        align: "right", // nohit-388 set Align for column
      },
    ];
  } else {
    return [
      {
        title: " ",
        dataIndex: "active",
        key: "active",
        width: 80,
        render: (_, text) => {
          return (
            <Popconfirm
              title="Do you want to Cancel the changes?"
              onConfirm={(e) => {
                handleRemove(text, setRowData, rowData);
              }}
            >
              <Button icon={<AiFillDelete />}></Button>
            </Popconfirm>
          );
        },
      },
      {
        width: 300,
        title: "Item Description", // nohit-425 change title Item to Item Description
        dataIndex: "item",
        key: "item ",
        align: "left", // nohit-388 set Align for column
      },

      {
        width: 80,
        title: "Quantity",
        dataIndex: "qty",
        key: "qty",
        align: "right", // nohit-388 set Align for column
        render: (_, text) => {
          console.log("Sale rate of the Item is ", text);
          return (
            //{Pradeep task id -314 }
            text.newone == 1 ? (
              <input
                className="tr001Input"
                disabled={
                  onedconeinvoice && text?.doctype == "DC" ? true : false
                }
                defaultValue={text.qty}
                onFocus={(e) => e.target.select()}
                onChange={(e) => {
                  text.qty = e.target.value;
                  handleChange(text, setRowData, igstappl, rowData);
                }}
              ></input>
            ) : (
              <p>{text.qty}</p>
            )
          );
        },
      },

      {
        hidden: pageDetails?.wantbatchno ? false : true,
        width: 200,
        title: "Batch No.",
        dataIndex: "itembatchno",
        key: "itembatchno",
        align: "left", // nohit-388 set Align for column
        render: (_, text) => {
          return (
            <>
              {" "}
              {/* <input
              //className="tr001Input"
              // rajendra-338 calendar disabled when isservice=true
              ///let isservice=item?.isservice //// task id -345 is service rajendra
              disabled={
                onedconeinvoice &&
                  (text?.doctype == "DC" || text?.isservice == 1)
                  ? true
                  : false
              }
              onFocus={(e) => e.target.select()}
              defaultValue={text?.isservice == 1 ? null : text.itembatchno}
              onChange={(e) => {
                text.itembatchno = e.target.value;
                handleChange(text, setRowData, igstappl, rowData);
              }}
            ></input>{" "} */}
              <p>{text?.itembatchno}</p>
              <div>
                {text?.wantbatchno ? (
                  <Button
                    icon={
                      <BiSelectMultiple
                        onClick={() => {
                          setItemForSelection(text);
                          setopenBatch(true);
                        }}
                      />
                    }
                  ></Button>
                ) : null}
              </div>
            </>
          );
        },
      },
      // {
      //   hidden: pageDetails?.wantmanudate ? false : true,
      //   width: 300,
      //   title: "Manufacture Date",
      //   dataIndex: "manufacturedate",
      //   key: "manufacturedate",
      //   align: "left", // nohit-388 set Align for column
      //   render: (_, text) => {
      //     return (
      //       <CallbackDateInput
      //         defaultValue={
      //           typeof text.manfacturedate == "number"
      //             ? text.manfacturedate.toString()
      //             : text.manfacturedate
      //         }
      //         // rajendra-338 calendar disabled when isservice=true
      //         disabled={
      //           onedconeinvoice &&
      //           (text?.doctype == "DC" || text?.isservice == 1)
      //             ? true
      //             : false
      //         }
      //         id={"po-manufacturedate-" + text.shortguid}
      //         apiKey={"manufacturedate"}
      //         initial
      //         returnValue={(e) => {
      //           text.manufacturedate = e;
      //           handleChange(text, setRowData, igstappl, rowData);
      //         }}
      //         dateLessthan={sidate}
      //       />
      //     );
      //   },
      // },
      // {
      //   hidden: pageDetails?.wantexpdate ? false : true,
      //   width: 300,
      //   title: "Expiry Date",
      //   dataIndex: "expdate",
      //   key: "expdate",
      //   align: "left", // nohit-388 set Align for column
      //   render: (_, text) => {
      //     console.log("Text is6848696", text);
      //     return (
      //       <CallbackDateInput
      //         defaultValue={
      //           typeof text.expdate == "number"
      //             ? text.expdate.toString()
      //             : text.expdate
      //         }
      //         // rajendra-338 calendar disabled when isservice=true
      //         disabled={
      //           onedconeinvoice &&
      //           (text?.doctype == "DC" || text?.isservice == 1)
      //             ? true
      //             : false
      //         }
      //         id={"po-expdate-" + text.shortguid}
      //         apiKey={"expecteddeliverydate"}
      //         returnValue={(e) => {
      //           text.expdate = e;
      //           handleChange(text, setRowData, igstappl, rowData);
      //         }}
      //         dateGreaterthan={text.manufacturedate}
      //       />
      //     );
      //   },
      // },
      {
        width: 120,
        title: "Rate",
        dataIndex: "rate",
        key: "rate",
        align: "right", // nohit-388 set Align for column
        render: (_, text) => {
          console.log("TUFUYI", text);
          return (
            //{Pradeep task id -314 }
            <input
              className="tr001Input"
              defaultValue={text.rate}
              disabled={onedconeinvoice && text?.doctype == "DC" ? true : false}
              onFocus={(e) => e.target.select()}
              onChange={(e) => {
                text.rate = e.target.value;
                handleChange(text, setRowData, igstappl, rowData);
              }}
            ></input>
          );
        },
      },
      {
        width: 110,
        title: "Discount%",
        dataIndex: "discount",
        key: "discount",
        align: "right", // nohit-388 set Align for column
        render: (_, text) => {
          console.log("Text=====", text);

          return (
            <input
              className="tr001Input"
              /// task-id-253 rajendra discount amount input is unable
              // disabled={text.discountamt!=0||text.discountamt!="0.00"?true:false}

              value={
                text.discount != "0.00" || text.discount != 0
                  ? text.discount
                  : null
              }
              defaultValue={text?.discount}
              onFocus={(e) => e.target.select()}
              onChange={(e) => {
                const discountValue = e.target.value;
                // Ensure that the value is a number before updating
                if (!isNaN(discountValue)) {
                  // Ensure the value is between 0 and 100
                  const clampedValue = Math.min(
                    100,
                    Math.max(0, discountValue)
                  );
                  text.discount = clampedValue;
                  handleChange(text, setRowData, igstappl, rowData);
                }
              }}
              type="number"
              max={100} /// task-id-253 rajendra discount amount input is unable
            ></input>
          );
        },
      },
      {
        width: 120,
        title: "Discount",
        dataIndex: "discountamt",
        key: "discountamt",
        align: "right", // nohit-388 set Align for column
        render: (_, text) => {
          return <p>{text?.discountamt}</p>; /// task id-253 the input box is remove here according to the percentage input discount change
        },
      },
      {
        width: 120,
        title: "Taxable Amount",
        dataIndex: "taxableamount",
        key: "taxableamount",
        align: "right", // nohit-388 set Align for column
      },
      {
        width: 120,
        title: "CGST%",
        dataIndex: "cgstrate",
        key: "cgstrate",
        align: "right", // nohit-388 set Align for column
      },
      {
        width: 120,
        title: "CGST",
        dataIndex: "cgstamt",
        key: "cgst",
        align: "right", // nohit-388 set Align for column
      },
      {
        width: 120,
        title: "SGST%",
        dataIndex: "sgstrate",
        key: "sgstrate",
        align: "right", // nohit-388 set Align for column
      },
      {
        width: 120,
        title: "SGST",
        dataIndex: "sgstamt",
        key: "sgst",
        align: "right", // nohit-388 set Align for column
      },
      {
        width: 150,
        title: "Amount",
        dataIndex: "amount",
        key: "amount",
        align: "right", // nohit-388 set Align for column
      },
    ];
  }
};

export const handleAdd = (
  item,
  setRowData,
  igstappl,
  setSelectedItem,
  shortguid,
  salerate,
  purchaseQty,
  box,
  freeBox,
  freeQty,
  batchNo,
  expDate,
  discountAmt,
  discountPer,
  manufacturedate,
  onedconeinvoice
) => {
  // var newShortguid = shortguid.getShortguid("SIF" + item.itemrecno + batchNo);
  console.log("RDYTFG");

  {
    /* nohit-325 - Sales Invoice Grid Salerate NaN issue solved */
  }
  var salerate = salerate ? salerate : 0.0;
  var cgstrate = item?.cgstrate ? item.cgstrate : 0.0;
  var sgstrate = item?.sgstrate ? item.sgstrate : 0.0;
  var igstrate = item?.igstrate ? item.igstrate : 0.0;
  let conversionfactor = item?.conversionfactor ? item.conversionfactor : 1;
  var packingqty = purchaseQty ? purchaseQty : 0;
  var packingextraqty = box ? box : 0;

  let isservice = item?.isservice; //// task id -345 is service rajendra
  var freepackingextraqty = freeBox ? freeBox : 0;
  var freepackingqty = freeQty ? freeQty : 0;
  var free =
    Number(freepackingqty) +
    Number(freepackingextraqty) * Number(conversionfactor);

  var qty =
    Number(packingqty) + Number(packingextraqty) * Number(conversionfactor);

  var discount = discountPer ? Number(discountPer) : 0.0;
  var discountamt = discountAmt ? Number(discountAmt) : 0.0;
  var expdate = expDate ? expDate : null;
  var manufacturedate = manufacturedate ? manufacturedate : null;
  var itembatchno = batchNo ? batchNo : null;

  var taxableamount = qty * (salerate - discountamt);
  var sgstamt = !igstappl ? taxableamount * (sgstrate / 100) : 0.0;
  var cgstamt = !igstappl ? taxableamount * (cgstrate / 100) : 0.0;
  var igstamt = igstappl ? taxableamount * (igstrate / 100) : 0.0;

  var amount = igstappl
    ? taxableamount + igstamt
    : taxableamount + (cgstamt + sgstamt);

  var itemObject = {
    ...item,
    shortguid: 3,
    itemrecno: item.itemrecno,
    itemdescn: item.itemdescn,
    packingextraqty: packingextraqty,
    packingqty: packingqty,
    qty: Number(qty),
    free: Number(free),
    freeqty: Number(free),
    salerate: Number(salerate).toFixed(2),
    taxableamount: Number(taxableamount).toFixed(2),
    cgstrate: Number(cgstrate).toFixed(2),
    sgstrate: Number(sgstrate).toFixed(2),
    igstrate: Number(igstrate).toFixed(2),
    cgstamt: Number(cgstamt).toFixed(2),
    sgstamt: Number(sgstamt).toFixed(2),
    igstamt: Number(igstamt).toFixed(2),
    amount: Number(amount).toFixed(2),
    discount: Number(discount).toFixed(2),
    discountamt: Number(discountamt).toFixed(2),
    expdate: expdate ? expdate : item.expdate,
    //itembatchno: item?.wantbatchno === 0 ? "." : itembatchno,
    //// task-id-345 rajendra here isservice validation
    itembatchno:
      item?.wantbatchno === 0 && isservice == 0
        ? "."
        : isservice === 1 && item?.wantbatchno === 0
        ? "."
        : itembatchno,
    freepackingextraqty: Number(freepackingextraqty),
    freepackingqty: Number(freepackingqty),
    manufacturedate: manufacturedate ? manufacturedate : item?.manufacturedate,
    onedconeinvoice: onedconeinvoice,
  };
  if (item?.doctype == "DC") {
    itemObject.dcqty = Number(qty);
    itemObject.itembatcharray = itemObject?.batcharray;
  }

  //   let x=rowData?.findIndex(num => num.itemrecno==itemObject.itemrecno);

  //   if(x!==-1){
  // rowData[x]=itemObject
  // setRowData(rowData)
  //   }
  //   else{
  //     setRowData([...rowData, itemObject]);
  //   }

  setRowData((p) => [...p, itemObject]);
  setSelectedItem(null);
};

export const handleChange = (
  item,
  setRowData,
  igstappl,
  rowData,
  scandata,
  setScandata,
  setOpenItem
) => {
  var salerate = item?.rate ? item.rate : 0.0;
  var cgstrate = item?.cgstrate ? item.cgstrate : 0.0;
  var sgstrate = item?.sgstrate ? item.sgstrate : 0.0;
  var igstrate = item?.igstrate ? item.igstrate : 0.0;
  let isservice = item?.isservice; //// task id -345 is service rajendra

  let packingextraqty = item?.packingextraqty ? item?.packingextraqty : 0.0;
  let packingqty = item?.packingqty ? item.packingqty : 0.0;
  let itembatchno = item?.itembatchno ? item?.itembatchno : null;
  let expdate = item?.expdate ? item?.expdate : null;
  let conversionfactor = item?.conversionfactor ? item.conversionfactor : 1;

  let freepackingextraqty = item?.freepackingextraqty
    ? item?.freepackingextraqty
    : 0.0;
  let freepackingqty = item?.freepackingqty ? item?.freepackingqty : 0.0;
  let free =
    Number(freepackingqty) +
    Number(freepackingextraqty) * Number(conversionfactor);
  // let qty =Number(packingqty) + Number(packingextraqty) * Number(conversionfactor);
  let qty = item.qty;
  let discountamt = item?.discountamt ? item.discountamt : 0;
  var discount = item?.discount ? item.discount : 0;

  var grossamount = Number(qty) * Number(salerate);
  if (Number(discount) >= 0 && Number(salerate) > 0) {
    discountamt = (Number(discount) * Number(grossamount)) / 100;
  }
  var taxableamount = Number(grossamount) - Number(discountamt);
  // var taxableamount =
  //   Number(qty) * Number(Number(salerate) - Number(discountamt));

  var sgstamt = !igstappl ? taxableamount * (sgstrate / 100) : 0.0;
  var cgstamt = !igstappl ? taxableamount * (cgstrate / 100) : 0.0;
  var igstamt = igstappl ? taxableamount * (igstrate / 100) : 0.0;

  var amount = igstappl
    ? taxableamount + igstamt
    : taxableamount + (cgstamt + sgstamt);

  var itemObject = {
    ...item,
    itemrecno: item.itemrecno,
    itemdescn: item.itemdescn,
    box: +item?.box,
    rate: Number(salerate).toFixed(2),
    salerate: Number(salerate).toFixed(2),
    grossamount: Number(grossamount).toFixed(2),
    taxableamount: Number(taxableamount).toFixed(2),
    ccgstrate: Number(cgstrate).toFixed(2),
    sgstrate: Number(sgstrate).toFixed(2),
    igstrate: Number(igstrate).toFixed(2),
    cgstamt: Number(cgstamt).toFixed(2),
    sgstamt: Number(sgstamt).toFixed(2),
    igstamt: Number(igstamt).toFixed(2),
    amount: Number(amount).toFixed(2),
    discount: Number(discount), //// task id-253 rajendra converted to number
    discountamt: Number(Number(discountamt).toFixed(2)), //// task id-253 rajendra converted to number
    packingextraqty: Number(packingextraqty),
    packingqty: Number(qty),
    qty: Number(qty),
    free: Number(free),
    freeqty: Number(free),
    expdate: expdate ? expdate : item.expdate,
    shortguid: new Date(),
    //availablestockforsale:Number(item.availablestockforsale)+Number(item?.returnableqty),/// here availableforsale
    // itembatchno: rowData?.wantbatchno==0?".":itembatchno,
    //// task-id-345 rajendra here isservice validation
    itembatchno:
      item?.wantbatchno === 0 && isservice == 0
        ? "."
        : isservice === 1 && item?.wantbatchno === 0
        ? "."
        : itembatchno,
    freepackingextraqty: Number(freepackingextraqty),
    freepackingqty: Number(freepackingqty),
  };

  //{Pradeep, task id-334  }
  // if (item?.onedconeinvoice && item?.doctype == "DC" && (itemObject?.qty + itemObject?.freeqty < itemObject?.dcqty)) {
  //   alert("Total Added Quantity Should match with the DC Quantity for Item " + itemObject?.itemdescn)
  // }
  //{end                           end}

  var currentItem = rowData.map((itm) => {
    if (itm.item == item.item) {
      return { ...itemObject };
    } else {
      return { ...itm };
    }
  });
  console.log("ERTUI", currentItem);
  setRowData(() => currentItem);
  setScandata(item);
};

export const handleRemove = (item, setRowData, rowData) => {
  var removedArray = removeByAttribute(rowData, "itemrecno", item.itemrecno);

  setRowData(() => [...removedArray]);

  if (item?.onedconeinvoice && item?.doctype == "DC") {
    setRowData(() => []);
  }
};

export const handleRemovePrev = (item, setRowData, rowData) => {
  var removedArray = removeByAttribute(rowData, "itemrecno", item.itemrecno);

  setRowData(() => [...removedArray]);
  if (item?.onedconeinvoice && item?.doctype == "DC") {
    setRowData(() => []);
  }
};

import React, { useContext, useEffect, useState, useRef } from "react";
import EditableTable from "../../CommonControllers/Components/Tables/EditableTable";
import "../../CommonControllers/global.css";
import useExecuteApi from "../../CommonControllers/Hooks/useExecuteApi";
import { TabStore } from "../../TabsStructure/TabsStore";
import TableComponent from "../../CommonControllers/Components/TableComponent/TableComponent";
import FilterComponents from "../../CommonControllers/FiterComponent/FilterComponents";
import { AuthStore } from "../../Auth/AuthStore";
import { currentTabData } from "../../CommonControllers/Variable";
import { getfastpathapi } from "../../CommonControllers/ApiCalls";
import { Spin } from "antd";
import { RiFootprintFill } from "react-icons/ri";

const CommonMasterwithDynamicModal = ({ fastPath }) => {
  const tabStore = useContext(TabStore);
  const auth = useContext(AuthStore);
  const execute = useExecuteApi();
  const [fastPathOptions, setFastPathOptions] = useState();
  const [filterData, setFilterData] = useState(null);
  // Define a function to handle the returned data
  const handleFilterData = (data) => {
    // Do something with the returned data, e.g., update state
    setFilterData(data);
  };
  const [pageIndex, setPageIndex] = useState();
  useEffect(() => {
    var tabsArray = tabStore.state.tabs;
    for (let i = 0; i < tabsArray?.length; i++) {
      if (tabsArray[i].name == fastPath) {
        setPageIndex(i);
        break;
      }
    }
  }, []);
 
  // -----------------table loading style start---------------
  const [showTable, setShowTable] = useState(true);
  useEffect(() => {
    setShowTable(false);

    setTimeout(() => {
      setShowTable(true);
    }, 200);
  }, [tabStore.state.tabs[pageIndex]?.apiResponse]);

  // -----------------table loading style end---------------

  async function getCommonMasterList() {
    execute.execute();
  }
  useEffect(() => {
    if (!tabStore.state.tabs[pageIndex]?.apiResponse) {
      if (
        (tabStore.state.tabs[pageIndex]?.apiResponse?.length <= 0 ||
          tabStore.state.tabs[pageIndex]?.apiResponse?.length == undefined) &&
        tabStore.state.tabs[pageIndex]?.callapipageload == true
        // smk 5-1-2023 want to call api at page load added dont want for tenantitem ms201
      ) {
        getCommonMasterList();
      }
    }
  }, []);

  useEffect(() => {
    if (pageIndex >= 0) {
      const payload = {
        tenantrecno: auth?.state?.userdata?.tenantrecno,
        domainrecno: auth?.state?.userdata?.domainrecno,
        userrolerecno: auth?.state?.userdata?.userrolerecno,
        domainuserrecno: auth.state.userdata.recno,
        fastpath: tabStore.state.tabs[pageIndex]?.name,
      };
      // On component mount this api is called and filterOptions are set for that fast path
      getfastpathapi(payload).then((res) => {
        if (res?.Success) {
          setFastPathOptions(res?.Message);
        }
      });
    }
  }, [pageIndex]);
  return (
    <div style={{ padding: "10px" }}>
      {fastPathOptions?.filterOptions.length === 0 && 
          tabStore.state.tabs[pageIndex]?.apiResponse == undefined ? 
        <h1 style={{ display: "flex", justifyContent: "center", alignItems: "center"}}>
          Please Click On Execute(<RiFootprintFill/>)&nbsp; Button To Get Data
        </h1>
       : 
        ( 
      <FilterComponents filterOption={fastPathOptions?.filterOptions} returnValue={handleFilterData} />
       )}
      {
        tabStore.state.tabs[pageIndex]?.executeLoading === true ? (
          <div style={{ ...loaderStyle }}>
            <Spin size="large" />
          </div>
        ) : (
          <div>
            {tabStore.state.tabs[pageIndex]?.apiResponse?.columnsinfo?.length > 0 ? (
              tabStore.state.tabs[pageIndex].mode == "edit" &&
                tabStore.state.tabs[pageIndex].name != "SV002" ? (
                <>
                  <EditableTable
                    filter={filterData}
                    pageIndex={pageIndex}
                    columnsArray={
                      tabStore.state.tabs[pageIndex]?.apiResponse?.columnsinfo
                    }
                    rowsArray={tabStore.state.tabs[pageIndex]?.apiResponse?.Message}
                    modal
                    selection={fastPathOptions?.selectall}
                    isAdd={fastPathOptions?.isadd}
                    isSurvey={false}
                    isEdit={fastPathOptions?.isedit}
                  />
                </>
              ) : tabStore.state.tabs[pageIndex].mode == "edit" &&
                tabStore.state.tabs[pageIndex].name == "SV002" ? (
                <>
                  <EditableTable
                    filter={filterData}
                    pageIndex={pageIndex}
                    columnsArray={
                      tabStore.state.tabs[pageIndex]?.apiResponse?.columnsinfo
                    }
                    rowsArray={tabStore.state.tabs[pageIndex]?.apiResponse?.Message}
                    modal
                    selection={fastPathOptions?.selectall}
                    isAdd={fastPathOptions?.isadd}
                    isSurvey={true}
                  />
                </>
              ) : // -----------------table loading style start---------------
                showTable ? (
                  <>
                    <TableComponent pageIndex={pageIndex} />
                  </>
                ) : (
                  <div style={{ ...loaderStyle }}>
                    <Spin size="large" />
                  </div>
                )
            ) : // -----------------table loading style end---------------
              null}
          </div>
        )
      }



    </div>
  );
};

export default CommonMasterwithDynamicModal;

const loaderStyle = {
  height: "max-content",
  width: "max-content",
  display: "flex",
  alignContent: "center",
  margin: "50px auto",
};

import React, { useEffect, useState } from "react";
import { apiCall } from "../../ApiCalls";
import CallbackCheckbox from "../CallbackComponents/CallbackCheckbox";
import CallbackDropdown from "../CallbackComponents/CallbackDropdown";
import CallbackNumberInput from "../CallbackComponents/CallbackNumberInput";
import CallbackRadioBtn from "../CallbackComponents/CallbackRadioBtn";
import CallbackTextInput from "../CallbackComponents/CallbackTextInput";
import SaveCheckbox from "../SaveComponents/SaveCheckbox";
import SaveDropdown from "../SaveComponents/SaveDropdown";
import SaveRadioBtn from "../SaveComponents/SaveRadioBtn";
import EditableTable from "../Tables/EditableTable";
import ExecuteCheckbox from "./ExecuteCheckbox";
import ExecuteDropdown from "./ExecuteDropdown";
import ExecuteRadioBtn from "./ExecuteRadioBtn";
import ExecuteTextInput from "./ExecuteTextInput";
import { getCity, getDistrict, getDomainSupplierAll } from "../../Urls";
import ReactPrint from "../../Print/ReactPrint";

function ExecuteComponentTesting() {
  const radioOptions = [
    { label: "Kaushik", value: "kaushik" },
    { label: "Kaushik1", value: "kaushik1" },
  ];
  const [selectedOptionfromChild1, setselectedOptionfromChild1] = useState();
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  console.log("Selection Options from CHild 1", selectedOptionfromChild1);
  useEffect(() => {
    getData();
  }, []);

  async function getData() {
    const resp = await apiCall("POST", getDistrict, { tenantrecno: 3, domainrecno: 508 });
    console.log("Get Domain Supplier Api Response", resp);
    setColumns(resp.columnsinfo);
    setRows(resp.Message);
  }
  return (
    <div>
      <ReactPrint url="getpurchaseorderforprint" ins="PO2203003"/>
    </div>
  );
}

export default ExecuteComponentTesting;

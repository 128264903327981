import { Button, Typography, Tooltip, Spin } from "antd";
import React, { useContext, useState, useEffect } from "react";
import "../../../../CommonControllers/global.css";
import { currentTabData } from "../../../Variable";
import WMSLogComponent from "./../WMSLogsData/WMSLogComponent";
// import CustomDropDown from "../../../CommonControllers/Components/DropdownSelect";
// import DateComponent from "../../../CommonControllers/Components/DateComponent";
import noData from "../../../../Assets/noData.png";
// import InputComponent from "../../../CommonControllers/Components/InputComponent";
import { TabStore } from "../../../../TabsStructure/TabsStore";
import { AuthStore } from "../../../../Auth/AuthStore";
import { getfastpathapi } from "../../../ApiCalls";
// import { baseUrl } from "../../../CommonControllers/Urls";
import FilterComponents from "../../../FiterComponent/FilterComponents";

const { Title } = Typography;

const WMSLogsMainScreen = ({ dontEdit, fastPath }) => {
  const tabStore = useContext(TabStore);
  const auth = useContext(AuthStore);
  const [filterOptions, setFilterOptions] = useState([]);
  //------loading-----------------------
  const [showTable, setShowTable] = useState(true);

  const [pageIndex, setPageIndex] = useState();

  useEffect(() => {
    var tabsArray = tabStore.state.tabs;
    for (let i = 0; i < tabsArray?.length; i++) {
      if (tabsArray[i].name === fastPath) {
        setPageIndex(i);
        break;
      }
    }
  }, []);

  useEffect(() => {
    setShowTable(false);

    setTimeout(() => {
      setShowTable(true);
    }, 200);
  }, [tabStore.state.tabs[pageIndex]?.apiResponse]);
  // ----------loading end------------------------------

  // console.log("Purchase Logs Data", tabStore.state.tabs[pageIndex]?.apiResponse?.columnsinfofooter);

  useEffect(() => {
    const payload = {
      tenantrecno: auth?.state?.userdata?.tenantrecno,
      domainrecno: auth?.state?.userdata?.domainrecno,
      userrolerecno: auth?.state?.userdata?.userrolerecno,
      domainuserrecno: auth?.state?.userdata?.recno,
      fastpath: tabStore.state.tabs[pageIndex]?.name,
      userrolerecno: auth.state.userdata.userrolerecno,
    };
    // On component mount this api is called and filterOptions are set for that fast path
    getfastpathapi(payload).then((res) => {
      if (res?.Success) {
        console.log("on PO component mount", res);
        setFilterOptions(res?.Message?.filterOptions);
        tabStore.dispatch({
          type: "UPDATE_DATA",
          fieldType: "printUrl",
          text: res?.Message?.printUrl,
        });
        tabStore.dispatch({
          type: "UPDATE_DATA",
          fieldType: "deleteUrl",
          text: res?.Message?.deleteapiurl,
        });
      }
    });
  }, [pageIndex]);

  console.log("on PO component mount outside", tabStore);
  console.log("");
  return (
    <div className="purchaseOrderLog" style={{ padding: "5px" }}>
      <div className="over">
        <div className="searchBox">
          <FilterComponents filterOption={filterOptions} />
        </div>
      </div>
      {tabStore.state.tabs[pageIndex]?.executeLoading === true ? (
        <div style={{ ...loaderStyle }}>
          <Spin size="large" />
        </div>
      ) : (
        <div className="under">
          {/* <Button>Total Records : {tabStore.state.tabs[pageIndex]?.apiResponse?.Message?.length} </Button>  */}

          {tabStore.state.tabs[pageIndex]?.apiResponse?.Message?.length > 0 ? (
            // -----------------table loading style start---------------
            showTable ? (
              <div>
                <WMSLogComponent
                  dontEdit={dontEdit}
                  data={tabStore.state.tabs[pageIndex]?.apiResponse?.Message}
                  columns={
                    tabStore.state.tabs[pageIndex]?.apiResponse?.columnsinfo
                  }
                  attributeColumn={
                    tabStore.state.tabs[pageIndex]?.apiResponse
                      ?.attribscolumnsinfo
                  }
                  itemsColumn={
                    tabStore.state.tabs[pageIndex]?.apiResponse
                      ?.columnsinfofooter
                  }
                />
              </div>
            ) : (
              <div style={{ ...loaderStyle }}>
                <Spin size="large" />
              </div>
            )
          ) : // {start Pradeep,task-id-272 start }
          tabStore.state.tabs[pageIndex]?.apiResponse?.Message?.length === 0 ? (
            <div className="noDataFound">
              <h1>Whoops! No Records Found</h1>
              <img src={noData} />
            </div>
          ) : null}
        </div>
      )}
    </div>
    // {end       end   }
  );
};

export default WMSLogsMainScreen;

// CSS

const dropdownStyles = {
  border: "1px solid #cccccc",
  borderRadius: "8px",
  width: "15vw",
};

const loaderStyle = {
  height: "max-content",
  width: "max-content",
  display: "flex",
  alignContent: "center",
  margin: "50px auto",
};

import React, { useContext, useEffect, useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import { TabStore } from "../../TabsStructure/TabsStore";
import { AuthStore } from "../../Auth/AuthStore";
import { Modal } from "antd";
import JsBarcode from "jsbarcode";
import './barcode.css'

const PrintBarcodes = (props) => {
  const [isPrintModalOpen, setIsPrintModalOpen] = useState(true);
  const printRef = useRef(null);
  const auth = useContext(AuthStore);
  const barcodeContainerRef = useRef(null);

  const tabStore = useContext(TabStore);

  const printModal = useReactToPrint({
    content: () => printRef.current,
    onBeforePrint: () => setIsPrintModalOpen(false),
    removeAfterPrint: true,
    // documentTitle: printData[0]["smallHeading"],
    onAfterPrint: () => {
      // Remove the Print URL and Invoicve Number
      tabStore.dispatch({
        type: "UPDATE_PRINT_BARCODE",
        printInsBarcode: null,
      });

      tabStore.dispatch({
        type: "UPDATE_DATA",
        fieldType: "pageStatus",
        text: "",
      });
      tabStore.dispatch({
        type: "UPDATE_DATA",
        fieldType: "selectedDocuments",
        text: [],
      });
    },
    onPrintError: () => {
      auth.state.contextHolder.open({
        message: `An Error has occured`,
        description: "An Error with the Printer",
        duration: 3,
        style: { background: "#efa7a7" },
      });
      tabStore.dispatch({
        type: "UPDATE_PRINT_BARCODE",
        printInsBarcode: null,
      });

      tabStore.dispatch({
        type: "UPDATE_DATA",
        fieldType: "pageStatus",
        text: "",
      });
    },
  });

  useEffect(() => {
    if (barcodeContainerRef.current) {
      barcodeContainerRef.current.innerHTML = ""; // Clear previous content

      props?.ins?.footer.forEach((inputString) => {
        const barcodeDiv = document.createElement("div");
        const svg = document.createElementNS(
          "http://www.w3.org/2000/svg",
          "svg"
        );
        const paragraph = document.createElement("paragraph");

        JsBarcode(svg, inputString.footerrecno, {
          format: "CODE128", // You can choose the barcode format here
          displayValue: true, // Display the value below the barcode
        });

        barcodeDiv.appendChild(svg);
        paragraph.textContent =
          paragraph.textContent + " " + inputString.footerguid;
        barcodeDiv.appendChild(paragraph);
        barcodeDiv.className = "barcode-item";
        barcodeContainerRef.current.appendChild(barcodeDiv);
      });
    }
  }, [props?.ins?.footer]);

  useEffect(() => {
    console.log("props?.ins?.footer", props?.ins?.footer);
    if (props?.ins?.footer) {
      setTimeout(() => {
        printModal();
        setIsPrintModalOpen(true);
      }, 1000);
    }
  }, [props?.ins?.footer, tabStore.state?.printInsBarcode]);

  return (
    <div>
      {isPrintModalOpen ? (
        <div ref={printRef} open={isPrintModalOpen} className="barcode-container">
          <div ref={barcodeContainerRef} className="barcode-container"/>
        </div>
      ) : null}
    </div>
  );
};

export default PrintBarcodes;

import { Button, Popconfirm } from "antd";
import { AiFillDelete } from "react-icons/ai";
import {
  dateFormatter,
  removeByAttribute,
} from "../../../CommonControllers/AppFunction";
import { v4 as uuidv4 } from "uuid";
import SaveDropdown from "../../../CommonControllers/Components/SaveComponents/SaveDropdown";
import { getdomainlocationlistactive } from "../../../CommonControllers/Urls";

export const srColumns = (igstappl, setRowData, rowData, pageDetails) => {
  if (igstappl) {
    return [
      {
        title: " ",
        dataIndex: "active",
        key: "active",
        width: 80,
        render: (_, text) => {
          return (
            <Popconfirm
              title="Do you want to Delete?"
              onConfirm={(e) => {
                handleRemove(text, setRowData, rowData);
              }}
            >
              <Button icon={<AiFillDelete />}></Button>
            </Popconfirm>
          );
        },
      },
      {
        hidden: pageDetails?.wantdomainlocation ? false : true,
        width: 300,
        title: "Location",
        dataIndex: "location",
        align: "left",
        render: (_, text, index) => {
          console.log("checking1", text);
          if (text.itemasbatch === 1) {
            // Calculate dynamic zIndex based on the index
            let dynamicZindex = 80000 - index;

            return (
              <div
                style={{
                  zIndex: dynamicZindex,
                  position: "relative",
                  height: "auto",
                }}
              >
                <SaveDropdown
                  apikey={"locationrecno"}
                  minInput={0}
                  inputValue={20}
                  displayLabel={"Locn"}
                  placeholder={"Locn"}
                  apiurl={getdomainlocationlistactive}
                  selectionLabel={"descn"}
                  selectionValue={"locationrecno"}
                  returnValue={(e) => {
                    //text.location = e;
                    text.locationrecno = e.locationrecno;
                    handleChange(
                      text,
                      setRowData,
                      igstappl,
                      rowData
                      // scandata,
                      // setScandata
                    );
                  }}
                  width={"250px"}
                  alignment="column"
                />
              </div>
            );
          }
        },
      },
      {
        title: "Item Description",
        dataIndex: "itemdescn",
        key: "itemdescn",
        width: "max-content",
        align: "left",
        ellipsis: true,
      },
      {
        title: "Batch",
        dataIndex: "itembatchno",
        key: "itembatchno",
        width: "8%",
        align: "left",
        render: (_, text) => {
          return <p>{text?.itembatchno}</p>;
        },
      },
      {
        title: "Serial No",
        dataIndex: "serialno",
        key: "serialno",
        width: "8%",
        align: "left",
        render: (_, text) => {
          return <p>{text?.serialno}</p>;
        },
      },
      {
        title: "Mfg Date",
        dataIndex: "manudate",
        key: "manudate",
        // width: "6%",
        align: "left",
        render: (_, text) => {
          return <p>{dateFormatter(text?.manudate)}</p>;
        },
      },
      {
        title: "Exp Date",
        dataIndex: "expdate",
        key: "expdate",
        // width: "6%",
        align: "left",
        render: (_, text) => {
          return <p>{dateFormatter(text?.expdate)}</p>;
        },
      },
      {
        title: "Qty",
        dataIndex: "qty",
        key: "qty",
        align: "right",
        // width: "6%",
      },
      {
        title: "Rate",
        dataIndex: "salerate",
        key: "salerate",
        width: "8%",
        align: "right",
      },
      {
        title: "Discount%",
        dataIndex: "discountrate",
        key: "discountrate",
        render: (_, text) => {
          return (
            <input
              className={`tr001Input`}
              //defaultValue={text?.packingqty}
              onFocus={(e) => e.target.select()}
              type="number"
              min={0}
              // max={maxValue}
              disabled={text.discountamt}
              onChange={(e) => {
                // const inputValue = e.target.value;
                // if (inputValue < 0 || inputValue > maxValue) {
                //   e.target.value = null;
                //   return false;
                // }
                text.discountrate = e.target.value;
                handleChange(text, setRowData, igstappl, rowData);
              }}
            />
          );
        },
      },
      {
        title: "Discount",
        dataIndex: "discountamt",
        key: "discountrate",
        align: "right",
        render: (_, text) => {
          return (
            <input
              className={`tr001Input`}
              //defaultValue={text?.packingqty}
              onFocus={(e) => e.target.select()}
              type="number"
              min={0}
              disabled={text.discountrate}
              // max={maxValue}
              onChange={(e) => {
                // const inputValue = e.target.value;
                // if (inputValue < 0 || inputValue > maxValue) {
                //   e.target.value = null;
                //   return false;
                // }
                text.discountamt = e.target.value;
                handleChange(text, setRowData, igstappl, rowData);
              }}
            />
          );
        },
      },
      // {
      //   title: "Reason",
      //   dataIndex: "narration",
      //   key: "narration",
      //   align: "left",
      //   render: (_, text) => {
      //     return (
      //       <input
      //         className={`tr001Input`}
      //         //defaultValue={text?.packingqty}
      //         onFocus={(e) => e.target.select()}
      //         type="text"
      //         min={0}
      //         //disabled={text.returndiscountrate}
      //         // max={maxValue}
      //         onChange={(e) => {
      //           // const inputValue = e.target.value;
      //           // if (inputValue < 0 || inputValue > maxValue) {
      //           //   e.target.value = null;
      //           //   return false;
      //           // }
      //           text.narration = e.target.value;
      //           handleChange(text, setRowData, igstappl, rowData);
      //         }}
      //       />
      //     )
      //   }
      // },
      {
        title: "Taxable Amount",
        dataIndex: "taxableamount",
        key: "taxableamount",
        align: "right",
        // width: "9%",
      },
      {
        title: "IGST%",
        dataIndex: "igstrate",
        key: "igstrate",
        align: "right",
        // width: "4%",
      },
      {
        title: "IGST",
        dataIndex: "igstamt",
        key: "igstamt",
        align: "right",
        // width: "4%",
      },
      {
        title: "Amount",
        dataIndex: "amount",
        key: "amount",
        align: "right",
        // width: "5%",
      },
      {
        title: "Sale Bill No",
        dataIndex: "salebillno",
        key: "salebillno",
        align: "right",
        width: "9%",
        render: (_, text) => {
          return <p>{text?.salebillno ? text?.salebillno : "-"}</p>;
        },
      },
      {
        title: "Sale Bill Date",
        dataIndex: "billdate",
        key: "billdate",
        align: "right",
        width: "9%",
        render: (_, text) => {
          return <p>{text?.billdate ? dateFormatter(text?.billdate) : "-"}</p>;
        },
      },
      {
        title: "DC Bill No",
        dataIndex: "dcbillno",
        key: "dcbillno",
        align: "right",
        width: "9%",
        render: (_, text) => {
          return <p>{text?.dcbillno ? text?.dcbillno : "-"}</p>;
        },
      },
      {
        title: "DC Bill Date",
        dataIndex: "dcbilldate",
        key: "dcbilldate",
        align: "right",
        width: "9%",
        render: (_, text) => {
          return (
            <p>{text?.dcbilldate ? dateFormatter(text?.dcbilldate) : "-"}</p>
          );
        },
      },
    ];
  } else {
    return [
      {
        title: " ",
        dataIndex: "active",
        key: "active",
        width: "4%",
        render: (_, text) => {
          return (
            <Popconfirm
              title="Do you want to Delete?"
              onConfirm={(e) => {
                handleRemove(text, setRowData, rowData);
                console.log("HII");
              }}
            >
              <Button icon={<AiFillDelete />}></Button>
            </Popconfirm>
          );
        },
      },
      {
        hidden: pageDetails?.wantdomainlocation ? false : true,
        width: 300,
        title: "Location",
        dataIndex: "location",
        align: "left",
        render: (_, text, index) => {
          console.log("checking", text);
          if (text.itemasbatch === 1) {
            // Calculate dynamic zIndex based on the index
            let dynamicZindex = 2000 - index;

            return (
              <div style={{ zIndex: dynamicZindex, position: "relative" }}>
                <SaveDropdown
                  apikey={"locationrecno"}
                  minInput={0}
                  inputValue={20}
                  displayLabel={"Locn"}
                  placeholder={"Locn"}
                  apiurl={getdomainlocationlistactive}
                  selectionLabel={"descn"}
                  selectionValue={"locationrecno"}
                  returnValue={(e) => {
                    //text.location = e;
                    text.locationrecno = e.locationrecno;
                    handleChange(
                      text,
                      setRowData,
                      igstappl,
                      rowData
                      // scandata,
                      // setScandata
                    );
                  }}
                  width={"250px"}
                  alignment="column"
                />
              </div>
            );
          }
        },
      },
      {
        title: "Item Description",
        dataIndex: "itemdescn",
        key: "itemdescn",
        align: "left",
        width: "20%",
        ellipsis: true,
      },
      {
        title: "Batch",
        dataIndex: "itembatchno",
        key: "itembatchno",
        align: "left",
        width: "5%",
        render: (_, text) => {
          return <p>{text?.itembatchno}</p>;
        },
      },
      {
        title: "Serial No",
        dataIndex: "serialno",
        key: "serialno",
        width: "8%",
        align: "left",
        render: (_, text) => {
          return <p>{text?.serialno}</p>;
        },
      },
      {
        title: "Mfg Date",
        dataIndex: "manudate",
        key: "manudate",
        // width: "6%",
        align: "left",
        render: (_, text) => {
          return <p>{dateFormatter(text?.manudate)}</p>;
        },
      },
      {
        title: "Exp Date",
        dataIndex: "expdate",
        key: "expdate",
        // width: "6%",
        align: "left",
        render: (_, text) => {
          return <p>{dateFormatter(text?.expdate)}</p>;
        },
      },

      {
        title: "Qty",
        dataIndex: "qty",
        key: "qty",
        align: "right",
        // width: "6%",
      },
      {
        title: "Rate",
        dataIndex: "salerate",
        key: "salerate",
        // width: "8%",
        // render: (_, text) => {
        //   return (
        //     <input
        //       className="tr001Input"
        //       defaultValue={text.purrate}
        //       onFocus={(e) => e.target.select()}
        //       onChange={(e) => {
        //         if (e.target.value < 0) {
        //           e.target.value = null;
        //           alert("Invalid Input, please re-enter non-negative value");
        //           return false;
        //         }
        //         const regex = /^\d*(\.\d{0,2})?$/;
        //         text.purrate = e.target.value;
        //         if (e.target.value === "" || regex.test(e.target.value)) {
        //           handleChange(text, setRowData, igstappl, rowData);
        //         }
        //       }}
        //     ></input>
        //   );
        // },
        align: "right",
      },
      {
        title: "Discount%",
        dataIndex: "discountrate",
        key: "discountrate",
        align: "right",
        render: (_, text) => {
          return (
            <input
              className={`tr001Input`}
              //defaultValue={text?.packingqty}
              onFocus={(e) => e.target.select()}
              type="number"
              min={0}
              // max={maxValue}
              disabled={text.discountamt}
              onChange={(e) => {
                // const inputValue = e.target.value;
                // if (inputValue < 0 || inputValue > maxValue) {
                //   e.target.value = null;
                //   return false;
                // }
                text.discountrate = e.target.value;
                handleChange(text, setRowData, igstappl, rowData);
              }}
            />
          );
        },
      },
      {
        title: "Discount",
        dataIndex: "discountamt",
        key: "discountamt",
        align: "right",
        render: (_, text) => {
          return (
            <input
              className={`tr001Input`}
              defaultValue={text?.caldiscount}
              onFocus={(e) => e.target.select()}
              type="number"
              min={0}
              maxValue={text.taxableamount}
              disabled={text.discountrate}
              // max={maxValue}
              onChange={(e) => {
                // const inputValue = e.target.value;
                // if (inputValue < 0 || inputValue > maxValue) {
                //   e.target.value = null;
                //   return false;
                // }
                text.discountamt = e.target.value;
                handleChange(text, setRowData, igstappl, rowData);
              }}
            />
          );
        },
      },
      // {
      //   title: "Reason",
      //   dataIndex: "narration",
      //   key: "narration",
      //   align: "left",
      //   render: (_, text) => {
      //     return (
      //       <input
      //         className={`tr001Input`}
      //         //defaultValue={text?.packingqty}
      //         onFocus={(e) => e.target.select()}
      //         type="text"
      //         min={0}
      //         //disabled={text.returndiscountrate}
      //         // max={maxValue}
      //         onChange={(e) => {
      //           // const inputValue = e.target.value;
      //           // if (inputValue < 0 || inputValue > maxValue) {
      //           //   e.target.value = null;
      //           //   return false;
      //           // }
      //           text.narration = e.target.value;
      //           handleChange(text, setRowData, igstappl, rowData);
      //         }}
      //       />
      //     )
      //   }
      // },
      {
        title: "Taxable Amount",
        dataIndex: "taxableamount",
        key: "taxableamount",
        align: "right",
        // width: "9%",
      },
      {
        title: "CGST%",
        dataIndex: "cgstrate",
        key: "csgtrate",
        align: "right",
        // width: "4%",
      },
      {
        title: "CGST",
        dataIndex: "cgstamt",
        key: "cgstamt",
        align: "right",
        // width: "4%",
      },
      {
        title: "SGST%",
        dataIndex: "sgstrate",
        key: "sgstrate",
        align: "right",
        // width: "4%",
      },
      {
        title: "SGST",
        dataIndex: "sgstamt",
        key: "sgstamt",
        align: "right",
        // width: "4%",
      },
      {
        title: "Amount",
        dataIndex: "amount",
        key: "amount",
        align: "right",
        // width: "9%",
      },
      {
        title: "Sale Bill No",
        dataIndex: "salebillno",
        key: "salebillno",
        align: "right",
        width: "9%",
        render: (_, text) => {
          return <p>{text?.salebillno ? text?.salebillno : "-"}</p>;
        },
      },
      {
        title: "Sale Bill Date",
        dataIndex: "billdate",
        key: "billdate",
        align: "right",
        width: "9%",
        render: (_, text) => {
          return <p>{text?.billdate ? dateFormatter(text?.billdate) : "-"}</p>;
        },
      },
      {
        title: "DC Bill No",
        dataIndex: "dcbillno",
        key: "dcbillno",
        align: "right",
        width: "9%",
        render: (_, text) => {
          return <p>{text?.dcbillno ? text?.dcbillno : "-"}</p>;
        },
      },
      {
        title: "DC Bill Date",
        dataIndex: "dcbilldate",
        key: "dcbilldate",
        align: "right",
        width: "9%",
        render: (_, text) => {
          return (
            <p>{text?.dcbilldate ? dateFormatter(text?.dcbilldate) : "-"}</p>
          );
        },
      },
    ];
  }
};

export const handleChange = (item, setRowData, igstappl, rowData) => {
  var cgstrate = item?.cgstrate ? item.cgstrate : 0;
  var sgstrate = item?.sgstrate ? item.sgstrate : 0;
  var igstrate = item?.igstrate ? item.igstrate : 0;
  let salerate = item?.salerate ? item?.salerate : 0;
  var qty = item.qty ? Number(item.qty) : 1;

  var taxableamount = Number(qty) * Number(salerate);

  var sgstamt = !igstappl ? taxableamount * (sgstrate / 100) : 0.0;
  var cgstamt = !igstappl ? taxableamount * (cgstrate / 100) : 0.0;
  var igstamt = igstappl ? taxableamount * (igstrate / 100) : 0.0;
  cgstamt = cgstamt.toFixed(2);
  sgstamt = sgstamt.toFixed(2);
  igstamt = igstamt.toFixed(2);

  // var amount = igstappl ? Number(taxableamount) + Number(igstamt) : Number(taxableamount) + (Number(cgstamt) + Number(sgstamt));

  let percentrate = (Number(item.discountrate) / 100) * salerate;
  let effectiverate = salerate - percentrate;
  var totalreturnqty = 1;
  var returntaxableamount = 0;
  if (item.discountamt && !item.discountrate) {
    returntaxableamount =
      Number(totalreturnqty) * Number(salerate) -
      (Number(item?.discountamt) ? Number(item?.discountamt) : 0);
  } else if (!item.discountamt && item.discountrate) {
    returntaxableamount =
      (Number(totalreturnqty) ? Number(totalreturnqty) : 0) *
      Number(effectiverate);
  } else {
    returntaxableamount =
      (Number(totalreturnqty) ? Number(totalreturnqty) : 0) * Number(salerate);
  }
  var sgstamt = !igstappl ? returntaxableamount * (sgstrate / 100) : 0.0;
  // item?.sgstamt ? item?.sgstamt : 0.0;
  var cgstamt = !igstappl ? returntaxableamount * (cgstrate / 100) : 0.0;
  // item?.cgstamt ? item?.cgstamt : 0.0;

  var igstamt = igstappl ? returntaxableamount * (igstrate / 100) : 0.0;
  // item?.igstamt ? item?.igstamt : 0.0;

  cgstamt = cgstamt.toFixed(2);
  sgstamt = sgstamt.toFixed(2);
  igstamt = igstamt.toFixed(2);

  var amount = igstappl
    ? Number(returntaxableamount) + Number(igstamt)
    : returntaxableamount + (Number(cgstamt) + Number(sgstamt));
  var itemObject = {
    ...item,
    itemrecno: item.itemrecno,
    itemdescn: item.itemdescn,
    qty: item.qty ? Number(item.qty) : 0,
    salerate: salerate ? salerate : 0,
    taxableamount: Number(Number(returntaxableamount)?.toFixed(2)),
    sgstamt: Number(sgstamt)?.toFixed(2),
    cgstamt: Number(cgstamt)?.toFixed(2),
    igstamt: Number(igstamt)?.toFixed(2),
    amount: Number(Number(amount)?.toFixed(2)),
    itembatchno: item?.wantbatchno ? item.itembatchno : ".",
  };

  let arr = [];
  for (let i = 0; i < rowData.length; i++) {
    let itm = rowData[i];
    if (itm.serialno === itemObject.serialno) {
      arr.push(itemObject);
    } else {
      itm.shortguid = uuidv4();

      arr.push(itm);
    }
  }

  setRowData(arr);
};

export const handleRemove = (item, setRowData, rowData) => {
  // Filter out the item to be removed based on its shortguid
  const updatedRowData = rowData.filter(
    (rowDataItem) => rowDataItem.serialno !== item.serialno
  );

  // Update the state with the new row data that excludes the removed item
  setRowData(updatedRowData);
};

import React, { useState, useContext, useEffect } from "react";
import SaveDatePicker from "../../../CommonControllers/SaveComponents/SaveDatePicker";
import "./contra.css";
import SaveDropDown from "../../../CommonControllers/SaveComponents/SaveDropDown";
import SaveTextInput from "../../../CommonControllers/Components/SaveComponents/SaveTextInput";
import { TabStore } from "../../../TabsStructure/TabsStore";
import { currentTabData } from "../../../CommonControllers/Variable";
import { getAccountlistURL } from "../../../CommonControllers/Urls";
import { AuthStore } from "../../../Auth/AuthStore";

const ContraEntry = ({fastPath}) => {
  const tabStore = useContext(TabStore);
  const auth = useContext(AuthStore);
  const TabData = currentTabData(tabStore)?.changedRows;
  const [pageIndex, setPageIndex] = useState();

  useEffect(() => {
    var tabsArray = tabStore.state.tabs;
    for (let i = 0; i < tabsArray?.length; i++) {
      if (tabsArray[i].name == fastPath) {
        setPageIndex(i);
        break;
      }
    }
  }, []);

  const [deposit, setDeposit] = useState({});
  const [withdraw, setWithdraw] = useState({});
  const [render, setRender] = useState(0);

  const gllistbody = {
    gldetailtype: "'B','A'",
  };

  //#   ctflag: deposit 2, withdraw: 1
  const generateApiData = () => {
    const data = [
      {
        ...deposit,
        trdate: TabData?.trdate ?? auth?.state?.userdata?.workdate,
        ctflag: 2,
        amount: TabData?.amount,
        particular: TabData?.narration,
        recno: 0,
        refno: TabData?.referenceno,
        cdflag: 1,
      },
      {
        ...withdraw,
        trdate: TabData?.trdate ?? auth?.state?.userdata?.workdate,
        ctflag: 2,
        amount: TabData?.amount,
        particular: TabData?.narration,
        recno: 0,
        refno: TabData?.referenceno,
        cdflag: 2,
      },
    ];

    const generateApiPayload = {
      domainrecno: auth?.state?.userdata?.domainrecno,
      tenantrecno: auth?.state?.userdata?.tenantrecno,
      items: data,
      trdate: auth?.state?.userdata?.workdate ?? TabData?.trdate,
    };

    console.log("TR024 api payload ===>", generateApiPayload);
    tabStore.dispatch({
      type: "UPDATE_CHANGED_DATA",
      fieldType: "data",
      text: generateApiPayload,
    });
  };

  const tabStoreDispatcher = (validations) => {
    tabStore.dispatch({
      type: "UPDATE_CHANGED_DATA",
      fieldType: "validations",
      text: validations,
    });
    tabStore.dispatch({
      type: "UPDATE_CHANGED_DATA",
      fieldType: "tenantrecno",
      text: auth?.state?.userdata?.tenantrecno,
    });
    tabStore.dispatch({
      type: "UPDATE_CHANGED_DATA",
      fieldType: "domainrecno",
      text: auth?.state?.userdata?.domainrecno,
    });
  };

  useEffect(() => {
    let validations = [];
    if (deposit?.code && withdraw?.code) {
      if (deposit?.code == withdraw?.code) {
        alert(`Deposit and withdraw cannot be same`);
        validations.push(`Deposit and withdraw cannot be same`);
        console.log("TR024 rendered", deposit, withdraw, render);
        tabStoreDispatcher(validations);
      } else {
        tabStoreDispatcher(validations);
      }
    }
    generateApiData();
  }, [render]);

  return (
    <>
      <div className="contraWrapper">
        <div className="topFilters">
          <SaveDatePicker
            id="tr024-voucher-date"
            displayLabel="Voucher Date"
            apiKey="trdate"
            defaultValue={auth?.state?.userdata?.workdate}
            returnValue={() => setRender((curr) => curr + 1)}
          />
          <SaveTextInput
            id="tr024-reference-no"
            displayLabel="Reference No."
            apikey="referenceno"
            placeholder="Enter Reference No."
            returnValue={() => setRender((curr) => curr + 1)}
            type="text"
            pageIndex={pageIndex}
          />
          <div>
            <SaveTextInput
              id="tr024-amount"
              displayLabel="Amt"
              apikey="amount"
              placeholder="Enter amt"
              type="number"
              returnValue={() => setRender((curr) => curr + 1)}
              pageIndex={pageIndex}
            />
          </div>
        </div>
        <br />
        <div className="dropdownBox">
          <div>
            <SaveDropDown
              id="tr024-deposit-to"
              displayLabel="Deposited to"
              alignment="column"
              width="100%"
              placeholder="Deposit details"
              async
              selectionLabel="descn"
              selectionValue="code"
              apiUrl={getAccountlistURL}
              apikey="depositedto"
              multi={false}
              minInput={0}
              body={gllistbody}
              setDataToParent={(e) => {
                setDeposit(e);
                setRender((curr) => curr + 1);
              }}
              defaultValue=""
            />
          </div>
          <div>
            <SaveDropDown
              id="tr024-withdrawn-from"
              displayLabel="Withdrawn from"
              alignment="Column"
              width="100%"
              placeholder="Withdraw details"
              async
              selectionLabel="descn"
              selectionValue="code"
              apiUrl={getAccountlistURL}
              apikey="withdrawnfrom"
              multi={false}
              minInput={0}
              body={gllistbody}
              setDataToParent={(e) => {
                setWithdraw(e);
                setRender((curr) => curr + 1);
              }}
              defaultValue=""
            />
          </div>
        </div>
        <br />
        <div>
          <SaveTextInput
            id="tr024-narration"
            apikey="narration"
            displayLabel="Narration"
            placeholder="Enter narration"
            returnValue={() => setRender((curr) => curr + 1)}
            pageIndex={pageIndex}
          />
        </div>
      </div>
    </>
  );
};

export default ContraEntry;

import { Button, Collapse, Space } from "antd";
import SaveCheckbox from "../../../CommonControllers/Components/SaveComponents/SaveCheckbox";
import { useEffect, useRef, useState } from "react";
import SaveDropdown from "../../../CommonControllers/Components/SaveComponents/SaveDropdown";
import { getDomaincustomerlist } from "../../../CommonControllers/Urls";
import SaveDatePicker from "../../../CommonControllers/SaveComponents/SaveDatePicker";
import ItemSearch from "../../../CommonControllers/ItemController/ItemSearch";
import { IoAperture } from "react-icons/io5";
import { AuthStore } from "../../../Auth/AuthStore";
import { useContext } from "react";
import { currentTabData } from "../../../CommonControllers/Variable";
import { TabStore } from "../../../TabsStructure/TabsStore";
import useShortguid from "../../../CommonControllers/Hooks/useShortGuid";
import useSaveChangedRows from "../../../CommonControllers/Hooks/useSaveChangedRows";
import { getfastpathapi } from "../../../CommonControllers/ApiCalls";
import WMSAdditionalDetails from "../../../CommonControllers/WMS/WMSAdditionalDetails";
import WMSItemsDetails from "../../../CommonControllers/WMS/WMSItemDetails";
import { handleAdd } from "./ReturnableDCTableColumn";
import ReturnableDCItemsTable from "./ReturnableDCItemsTable";
import SaveTextInput from "../../../CommonControllers/Components/SaveComponents/SaveTextInput";
import SaveRadioBtn from "../../../CommonControllers/Components/SaveComponents/SaveRadioBtn";
import { enterPress } from "../../../CommonControllers/AppFunction";

const ReturnableDC = () => {
  const { Panel } = Collapse;
  const itemSearch = useRef(null);
  const shortguid = useShortguid();
  const { getShortguid } = useShortguid();
  const auth = useContext(AuthStore);
  const tabStore = useContext(TabStore);
  const [rowData, setRowData] = useState([]);
  const useChangedRows = useSaveChangedRows();
  const [challanNumber, setChallanNumber] = useState();
  const [itemcount, setItemCount] = useState(0);
  const [modalcount, setModalCount] = useState(0);
  const [getSelectedItem, setSelectedItem] = useState("");

  // Create a state for date validation
  const [challanDate, setChallanDate] = useState();

  // Create a state to set customer data
  const [customerData, setCustomerData] = useState();

  // // Create a state for item details inputs
  const [quantity, setQuantity] = useState("");
  const [itemAmount, setItemAmount] = useState("");
  const [amount, setAmount] = useState(" ");
  // Create a state to set Batch No. "."
  const [batchNo, setBatchNo] = useState(null);
  // Create a state for Status
  const [statusOptions, setStatusOptions] = useState([]);

  // Create a state to edit Transactions
  const [transactionEdit, setTransactionEdit] = useState(false);

  // Create a state to manage the Additional Attribute
  const [additionalDetailsFilterOptions, setAdditionalDetailsFilterOptions] =
    useState([]);

  // Create a state to manage the Item related Attribute
  const [
    additionalItemDetailsFilterOptions,
    setAdditionalItemDetailsFilterOptions,
  ] = useState([]);
  const [itemAttribute, setItemAttribute] = useState({});

  const [pageIndex, setPageIndex] = useState();
  console.log("Page Index is", pageIndex);

  // Below use effect used to make a particular changes in the specific fastpath only
  useEffect(() => {
    var tabsArray = tabStore.state.tabs;
    for (let i = 0; i < tabsArray?.length; i++) {
      if (tabsArray[i].name === "WMS4") {
        setPageIndex(i);
        break;
      }
    }
  }, []);

  const [pageDetails, setPageDetails] = useState();

  const payload = {
    tenantrecno: auth?.state?.userdata?.tenantrecno,
    domainrecno: auth?.state?.userdata?.domainrecno,
    userrolerecno: auth?.state?.userdata?.userrolerecno,
    domainuserrecno: auth.state.userdata.recno,
    fastpath: currentTabData(tabStore)?.name,
  };

  const fetchFastPathData = async () => {
    const response = await getfastpathapi(payload);
    if (response?.Success) {
      setPageDetails(response?.Message);
      setAdditionalDetailsFilterOptions(response?.Message?.additionalDetails);
      setAdditionalItemDetailsFilterOptions(
        response?.Message?.additionalDetailsFooter
      );
      setStatusOptions([
        {
          label: "Save",
          value: response.Message?.defaultStatus,
        },
        {
          label: "Proceed",
          value: response.Message?.nextStatus,
        },
      ]);
    }
  };

  useEffect(() => {
    fetchFastPathData();
  }, []);

  //~# Setting workdate
  let workdate = auth?.state?.userdata?.workdate;
  workdate = JSON.stringify(workdate);
  workdate = `${workdate?.slice(-2)}/${workdate?.slice(4, 6)}/${workdate?.slice(
    0,
    4
  )}`;
  console.log("workdate.", workdate);

  // To add Items Details
  const addItemstoTable = () => {
    if (getSelectedItem) {
      var existingItem = rowData.filter(
        (item) => item.itemrecno === getSelectedItem.itemrecno
      );

      console.log("existingItem?.length", existingItem?.length);
      if (existingItem?.length === 0) {
        setQuantity("");
        setBatchNo("");
        setItemAmount("");

        handleAdd(
          getSelectedItem,
          setRowData,
          setSelectedItem,
          shortguid,
          quantity,
          batchNo,
          itemAmount,
          itemAttribute
        );
        itemSearch.current.focus();
      } else {
        alert("Item Already Present");
        setSelectedItem(null);
        itemSearch.current.focus();
      }
    }
  };

  // Below use effect used to set a default value on a selection of item
  useEffect(() => {
    console.log("Get Selected Item is", getSelectedItem);

    setBatchNo(".");
  }, [getSelectedItem]);

  // Below use effect used to set and get value in Tabstore
  useEffect(() => {
    // When Row Data Changes we Need to store that data to the tabsStore with Header Data
    var currentData = useChangedRows.getValue();

    if (!currentData?.shortguid) {
      useChangedRows.updateValue("shortguid", shortguid.getShortguid("WMSRDC"));
    }

    if (rowData?.length === 0) {
      var items = useChangedRows.getValue("items");
      if (items) {
        setRowData(items);
      }
    }
  }, []);

  // Below use effect used to set a payload in the Tabstore
  useEffect(() => {
    // When Row Data Changes we Need to store that data to the tabsStore with Header Data
    var existingObject = useChangedRows.getValue();

    existingObject = {
      ...existingObject,
      items: rowData,
      shortguid: getShortguid("WMSRDC"),
      trdate: challanDate || auth?.state?.userdata?.workdate,
      status: "C",
    };

    useChangedRows.updateValue(false, existingObject);
  }, [rowData]);

  // Below use effect used to reset the value of fields in this fastpath
  useEffect(() => {
    if (tabStore.state.tabs[pageIndex]?.pageStatus === "Success") {
      setRowData([]);
      setSelectedItem(null);
      setChallanNumber("TBA");
      setQuantity("");
      setBatchNo("");
      setAmount("");
      setChallanDate(auth?.state?.userdata?.workdate);
      setStatusOptions([
        {
          label: "Save",
          value: false,
        },
        {
          label: "Proceed",
          value: false,
        },
      ]);
      setTransactionEdit(false);

      useChangedRows.updateValue("shortguid", shortguid.getShortguid("WMSRDC"));
      setModalCount((p) => p + 1);
      setTimeout(() => {
        tabStore.dispatch({
          type: "UPDATE_DATA",
          fieldType: "pageStatus",
          text: null,
        });

        tabStore.dispatch({
          type: "UPDATE_DATA",
          fieldType: "changedRows",
          text: {},
        });
      }, 1000);
    }
  }, [
    tabStore.state.tabs[tabStore.state.tabIndex]?.pageStatus,
    Object.keys(currentTabData(tabStore)?.changedRows).length,
  ]);
  console.log("TabStore Data", rowData, getSelectedItem);

  // Below use effect used to update data from TabStore
  useEffect(() => {
    if (tabStore.state.tabs[pageIndex]?.changedRows?.dataFromEdit) {
      setRowData(tabStore.state.tabs[pageIndex]?.changedRows?.items);
      setTransactionEdit(true);
      setStatusOptions([
        {
          label: "Save",
          value: tabStore.state.tabs[pageIndex]?.changedRows?.status,
        },
        {
          label: "Proceed",
          value: tabStore.state.tabs[pageIndex]?.changedRows?.nextStatus,
        },
      ]);
    }
  }, [tabStore.state.tabs[pageIndex]?.changedRows?.dataFromEdit]);

  // Disable the save button until a customer is selected
  useEffect(() => {
    let isButtonDisabled = false;

    if (!customerData || rowData.length == 0) {
      isButtonDisabled = true;
    }
    useChangedRows.updateValue(
      "validated",
      !isButtonDisabled ? "true" : "false"
    );
    useChangedRows.updateValue("dataFromEdit", false);
  }, [customerData, rowData]);

  return (
    <div
      style={{
        ...mainFrame,
      }}
    >
      <Collapse collapsible="header" defaultActiveKey={["1"]}>
        <Panel header="Header Details" key="1">
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              gap: "24px",
              marginTop: "1%",
            }}
          >
            {/* Material Received From */}
            <div style={{ zIndex: 1000 }} onKeyDown={enterPress}>
              <SaveDropdown
                displayLabel="Material Received From"
                placeholder={"Select Material Received From"}
                defaultSelected={customerData}
                apikey={"customerrecno"}
                apiurl={getDomaincustomerlist}
                selectionLabel="customerdescn"
                selectionValue="customerrecno"
                returnValue={(event) => {
                  console.log("Customer Data is in Retrun Value", customerData);
                  console.log("event---", event);
                  setCustomerData(event);
                }}
                alignment="column"
                minInput={0}
                inputValue={20}
                key={modalcount}
                isrequired={1}
              />
            </div>

            {/* To */}
            <div>
              <SaveTextInput
              pageIndex={pageIndex}
                displayLabel={"Material Delivered To"}
                placeholder={"Enter Material Delivered To"}
                // apikey={""}
                // defaultValue={}
                key={modalcount}
                width={250}
                isrequired={1}
              />
            </div>

            {/* Challan No. (disabled) */}
            <div onKeyDown={enterPress}>
              <div style={{ position: "relative" }}>
                <label
                  className={`label ${"focused"}`}
                  style={{
                    ...otherStyles,
                    zIndex: 1000,
                    top: "-19px",
                    left: "12px",
                  }}
                >
                  Challan No.
                </label>
                <input
                  style={{ ...poNumberStyle }}
                  placeholder={challanNumber ? challanNumber : "TBA"}
                  disabled
                />
              </div>
            </div>

            {/* Challan Date */}
            <div onKeyDown={enterPress}>
              <SaveDatePicker
                id={"gdn-date"}
                displayLabel={"Challan Date"}
                placeholder={"Select Challan Date"}
                defaultValue={challanDate}
                apiKey={"trdate"}
                dateLessthan={auth?.state?.userdata?.workdate}
                postAlertMsg={`Challan Date cannot be greater than Today's Date`}
                returnValue={(e) => {
                  setChallanDate(e);
                }}
                key={modalcount}
              />
            </div>

            {/* Status */}
            {/* <div onKeyDown={enterPress}>
              <div style={{ position: "relative" }}>
                <label
                  className={`label ${"focused"}`}
                  style={{
                    zIndex: 1000,
                    whiteSpace: "nowrap",
                    wordWrap: "unset",
                    position: "absolute",
                    top: "-12px",
                    left: "12px",
                    backgroundColor: "white",
                    padding: "0 4px",
                    fontSize: "12px",
                    color: "black",
                    pointerEvents: "none",
                    transform: "translateY(0)",
                    transition:
                      "top 0.3s, transform 0.3s, font-size 0.3s, color 0.3s",
                  }}
                >
                  Status
                </label>
                <div
                  onKeyDown={enterPress}
                  className="saveproceed"
                  style={{
                    padding: "8.7px",
                    marginTop: "4px",
                  }}
                >
                  <SaveRadioBtn
                    apikey={"status"}
                    // defaultValue={pageDetails?.defaultStatus}
                    options={statusOptions}
                  />
                </div>
              </div>
            </div> */}

            {/* Print Returnable DC */}
            <div onKeyDown={enterPress} style={{ marginTop: "6px" }}>
              <SaveCheckbox
                apikey={"isPrint"}
                displayLabel="Print Returnable DC"
                editable
                defaultValue={false}
                key={modalcount}
              />
            </div>
          </div>
        </Panel>

        <Panel header="Additional Details" key="2">
          <div onKeyDown={enterPress}>
            <WMSAdditionalDetails
              key={modalcount}
              filterOption={additionalDetailsFilterOptions}
              customerrecno={customerData?.customerrecno}
            />
          </div>
        </Panel>

        <Panel header="Item Details" key="3">
          <Space size="large">
            {/* Item Search Components */}
            <div
              style={{
                ...callbackTextInputStyles,
              }}
            >
              <div onKeyDown={enterPress}>
                <ItemSearch
                  itemSearchRef={itemSearch}
                  forFlag={"S"}
                  returnValue={setSelectedItem}
                  supplierdata={customerData?.customerrecno}
                  dropdown
                  defaultSelectedItem={getSelectedItem}
                  forPage={"GDN"}
                />
              </div>

              {/* Item details */}
              <div>
                <div
                  onKeyDown={enterPress}
                  style={{
                    margin: "10px",
                  }}
                >
                  <WMSItemsDetails
                    itemFilterOption={additionalItemDetailsFilterOptions}
                    returnValue={(e) => {
                      console.log("Item related Attribute", e);
                      setItemAttribute(e);
                    }}
                    key={itemcount}
                  />
                </div>

                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "24px",
                    marginLeft: "8px",
                    marginTop: "20px",
                  }}
                >
                  {/* Quantity */}
                  <div onKeyDown={enterPress} style={{ position: "relative" }}>
                    {quantity !== "" && (
                      <label
                        style={{
                          position: "absolute",
                          top: "-12px",
                          left: "12px",
                          backgroundColor: "white",
                          padding: "0 4px",
                          fontSize: "12px",
                          color: "black",
                          pointerEvents: "none",
                          transition:
                            "top 0.3s, transform 0.3s, font-size 0.3s",
                          transform: "translateY(0)",
                        }}
                      >
                        Quantity <span style={{ color: "red" }}>*</span>
                      </label>
                    )}
                    <input
                      style={{
                        ...inputStyles,
                        textAlign: quantity === "" ? "left" : "right",
                      }}
                      value={quantity}
                      placeholder={quantity === "" ? "Enter Quantity" : ""}
                      type="number"
                      onFocus={() => {
                        if (quantity === "") {
                          setQuantity(" ");
                        }
                      }}
                      onBlur={() => {
                        if (quantity.trim() === "") {
                          setQuantity("");
                        }
                      }}
                      onChange={(e) => {
                        const regex = /^[0-9\b]+$/;
                        const value = e.target.value;
                        if (value === "" || regex.test(value)) {
                          setQuantity(value);
                        }
                      }}
                    />
                  </div>

                  {/* Amount */}
                  <div onKeyDown={enterPress} style={{ position: "relative" }}>
                    {amount !== "" && (
                      <label
                        style={{
                          position: "absolute",
                          top: "-12px",
                          left: "12px",
                          backgroundColor: "white",
                          padding: "0 4px",
                          fontSize: "12px",
                          color: "black",
                          pointerEvents: "none",
                          transition:
                            "top 0.3s, transform 0.3s, font-size 0.3s",
                          transform: "translateY(0)",
                        }}
                      >
                        Amount
                      </label>
                    )}
                    <input
                      style={{
                        ...inputStyles,
                        textAlign: amount === "" ? "left" : "right",
                      }}
                      value={amount}
                      placeholder={amount === "" ? "Enter Amount" : ""}
                      type="number"
                      onFocus={() => {
                        if (amount === "") {
                          setAmount(" ");
                        }
                      }}
                      onBlur={() => {
                        if (amount.trim() === "") {
                          setAmount("");
                        }
                      }}
                      onChange={(e) => {
                        const regex = /^\d+(\.\d+)?$/;
                        const value = e.target.value;
                        if (value === "" || regex.test(value)) {
                          setAmount(value);
                        }
                      }}
                    />
                  </div>
                  {/* <div onKeyDown={enterPress}>
                    <p>Amount</p>
                    <input
                      style={{ ...inputStyles, width: "120px" }}
                      value={amount}
                      placeholder="Enter Amt"
                      type="number"
                      onChange={(e) => {
                        const regex = /^\d+(\.\d+)?$/;
                        const value = e.target.value;
                        if (value === "" || regex.test(value)) {
                          setAmount(value);
                        }
                      }}
                    />
                  </div> */}

                  {/* Add Button */}
                  <div onKeyDown={enterPress}>
                    <Button
                      className="btn item-search-btn"
                      style={{ marginTop: "2%" }}
                      icon={<IoAperture size="1.2rem" />}
                      size="large"
                      onClick={() => {
                        // Validation
                        if (quantity === 0 || quantity === "") {
                          alert("Please Enter the Quantity.");
                        } else {
                          addItemstoTable();
                          setItemCount((q) => q + 1);
                        }
                      }}
                    >
                      Add
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </Space>
          {/* Table for Selection  */}
          <div
            style={{
              zIndex: 0,
              position: "relative",
              margin: "24px auto 0",
            }}
          >
            {rowData.length > 0 && (
              <ReturnableDCItemsTable
                rowData={rowData}
                setRowData={setRowData}
                pageDetails={pageDetails}
              />
            )}
          </div>
        </Panel>
      </Collapse>
    </div>
  );
};

export default ReturnableDC;

const mainFrame = {
  width: "99vw",
  margin: "auto",
};

const poNumberStyle = {
  border: "1px solid #dfdfdf",
  borderRadius: "8px",
  width: "230px",
  padding: "8.7px",
  fontSize: "16px",
};

const callbackTextInputStyles = {
  display: "flex",
  flexWrap: "wrap",
  gap: "24px",
};

const inputStyles = {
  padding: "7px",
  border: "1px solid #dfdfdf",
  width: "200px",
  fontSize: "16px",
  marginTop: "5px",
  borderRadius: "8px",
  textAlign: "right",
  height: "36px",
};

const btnStyles = {
  display: "flex",
  alignItems: "center",
  gap: "15px",
  width: "100px",
  borderRadius: "8px",
  padding: "6px",
  marginTop: "22px",
};

const otherStyles = {
  whiteSpace: "nowrap",
  wordWrap: "unset",
  position: "absolute",
  // backgroundColor: "white",
  padding: "0 4px",
  fontSize: "12px",
  color: "black",
  pointerEvents: "none",
  transform: "translateY(0)",
  transition: "top 0.3s, transform 0.3s, font-size 0.3s, color 0.3s",
};

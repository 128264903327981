import React, { useContext, useEffect, useState } from 'react'
import SaveNumberInput from '../../CommonControllers/Components/SaveComponents/SaveNumberInput'
import SaveTextInput from '../../CommonControllers/Components/SaveComponents/SaveTextInput'
import SaveDropDown from '../../CommonControllers/Components/SaveComponents/SaveDropdown'
import SaveCheckBox from '../../CommonControllers/Components/SaveComponents/SaveCheckbox'
import { getForAnswer, getQuestionBySurveyType, getSurveyQuestionType, getSurveyType } from '../../CommonControllers/Urls'
import { AuthStore } from '../../Auth/AuthStore'
import axios from 'axios'
import CallbackDropdown from '../../CommonControllers/Components/CallbackComponents/CallbackDropdown'
import CallbackTextInput from '../../CommonControllers/Components/CallbackComponents/CallbackTextInput'
import CallbackCheckbox from '../../CommonControllers/Components/CallbackComponents/CallbackCheckbox'
import EditSubQuestion from './EditSubQuestion'
import { Button, Input } from 'antd'
import Select from 'react-select';

function EditSurveyQuestionModal({
  data,
  setsurveytype,
  setquestiontyperecno,
  setissubquestionof,
  setsrNo,
  setquestionType,
  setquestionname,
  setquestionOpList,
  setforanswer,
  setisgroupof,
  setmandatory,
  setimages,
  setlocation,
  setautofilled,
  setisCommon,
  sethasgroup,
  setOptions,
  setHasSubOption,
  setSubOptions,
  surveytype,
  questiontyperecno,
  issubquestionof,
  srNo,
  questionType,
  questionname,
  questionOpList,
  foranswer,
  isgroupof,
  mandatory,
  images,
  location,
  autofilled,
  isCommon,
  hasgroup,
  options,
  hasSubOption,
  subOptions,
  loyaltyPoints,
  setloyaltyPoints,
  changes,
  subQuestionType,
  setsubQuestionType,
  fieldtype,
  setfieldtype,
  active,
  setactive,
  placeholder,
  setplaceholder
}) {
  console.log("data on Edit Modal", data)
  const auth = useContext(AuthStore);
  //   const [questiontyperecno, setquestiontyperecno] = useState()
  //   const [issubquestionof, setissubquestionof] = useState(data?.issubquestionof)
  //   const [surveyTypeOptions, setsurveyTypeOptions] = useState([])
  //   const [srNo, setsrNo] = useState(data?.srNo)
  //   const [questionType, setquestionType] = useState(data?.qutionsetype)
  //   const [questionname, setquestionname] = useState(data?.questionname)
  //   const [questionOpList, setquestionOpList] = useState(data?.questionOpList)
  //   const [foranswer, setforanswer] = useState(data?.foranswer)
  //   const [isgroupof, setisgroupof] = useState(data?.isgroupof)
  //   const [mandatory, setmandatory] = useState(data?.mandatory)
  //   const [images, setimages] = useState(data?.images)
  //   const [location, setlocation] = useState(data?.location)
  //   const [autofilled, setautofilled] = useState(data?.autofilled)
  //   const [isCommon, setisCommon] = useState(data?.isCommon)
  // const [hasgroup, sethasgroup] = useState(data?.hasgroup)

  const [editData, seteditData] = useState()
  const [showTable, setShowTable] = useState(false);

  const [fieldTypeArr, setfieldTypeArr] = useState([
    { value: 'alpha', label: 'Alpha' },
    { value: 'alphanumeric', label: 'Alphanumeric' },
    { value: 'number', label: 'Number' },
    { value: 'date', label: 'Date' },
    { value: 'boolean', label: 'Boolean' }


  ])

  const [selectedFieldType, setSelectedFieldType] = useState();
  console.log("Selected Field TYpe is", selectedFieldType)
  useEffect(() => {
    if (fieldtype) {

      setSelectedFieldType(fieldtype)
    }
  }, [fieldtype])
  useEffect(() => {
    setShowTable(false);
    setquestionType(data?.questiontype)
    setquestionname(data?.questionname)
    setloyaltyPoints(data?.loyaltypoints)
    setsrNo(data?.questionsrno)
    setfieldtype(data?.fieldtype || null);
    setTimeout(() => {
      setShowTable(true);
    }, 200);
  }, [changes]);
  console.log("editData", editData)
  console.log("Default Selected Value for DropDown", data?.surveytyperecno)
  console.log("editData?.issubquestionof", editData?.issubquestionof)
  console.log("editData?.foranswer", editData?.foranswer)
  console.log("editData?.isgroupof", editData?.isgroupof)
  console.log("editData?.fieldtype", editData?.fieldtype, "nohit", fieldtype)

  console.log("questiiiiiiiiiiiiiiiiiion type for grid", editData?.questiontype, questionType)
  console.log("EditModal loyaltyPoints", loyaltyPoints)

  const surveyTypeListOptions = async () => {
    const payload = {
      tenantrecno: auth?.state?.userdata?.tenantrecno,
      domainrecno: auth?.state?.userdata?.domainrecno,
    };
    const response = await axios.post(getSurveyType, payload);

    const data = await response?.data?.Message;

    const filtered = [];
    for (let i = 0; i < data?.length; i++) {
      filtered.push({ label: data[i]?.descn, value: data[i]?.recno });
    }

    // setsurveyTypeOptions(filtered);
  };

  useEffect(() => {
    if (data) {

      // surveyTypeListOptions();
      seteditData(data)
    }
    // setfieldtype(data?.fieldtype)
  }, [data])

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // const [options, setOptions] = useState([
  //   { option: "", subOptions: [] },
  // ]);

  const handleAddOption = () => {
    setOptions([...options, { option: "", subOptions: [] }]);
  };

  const handleRemoveOption = (index) => {
    setOptions(options.filter((_, i) => i !== index));
  };

  return (

    <div>
      {showTable ?
        <div>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', width: "100%" }}>

            {/* </div> */}
            {/* <div style={{ display: 'flex', flexDirection: 'row', marginLeft: "12%", marginTop: "2%" }}> */}
            {
              editData?.hassubquestions == 0 ? (
                <div style={{ display: "flex" }}>
                  <CallbackTextInput
                    placeholder="Serial No"
                    displayLabel={"Serial No"}
                    apikey={"questionsrno"}
                    defaultValue={editData?.questionsrno}
                    returnValue={setsrNo}
                    width={"70%"}
                  />
                </div>
              ) : null
            }

            {/* <div style={{ display: "flex" }}>
          <CallbackDropdown
            placeholder="Select Survey Type"
            displayLabel={"Survey Type"}
            apiurl={getSurveyType}
            apikey={"surveytype"}
            selectionLabel={"descn"}
            selectionValue={"recno"}
            minInput={0}
            inputValue={20}
            returnValue={setsurveytype}
            width={"100%"}
            defaultSelected={editData?.surveytyperecno}
            style={{ display: "flex" }}

          />
        </div> */}
            <div style={{ display: "flex", marginLeft: "15%" }}>
              <CallbackDropdown
                placeholder="Select Question Type"
                displayLabel={"Question Type"}
                apiurl={getSurveyQuestionType}
                apikey={"questiontype"}
                selectionLabel={"descn"}
                selectionValue={"code"}
                minInput={0}
                inputValue={20}
                // returnValue={setquestionType}
                returnValue={(e) => setquestionType(e?.code)}
                defaultSelected={editData?.questiontype}
                // body={{ "onlyactive": 1 }}
                width={"100%"}
                style={{ display: 'flex' }}
              />
            </div>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', marginTop: "2%" }}>
            <div>
              <CallbackTextInput
                apikey={"questionname"}
                placeholder="Question"
                displayLabel={"Question"}
                style={{ display: 'flex' }}
                returnValue={setquestionname}
                defaultValue={editData?.questionname}
                change={1}
              />
            </div>
            {/* <div style={{ marginTop: "2%" }}>
          <CallbackTextInput
            placeholder="Optionlist e.g.opt1,opt2,opt3"
            displayLabel={"Question Optionlist (ComaSeperated)"}
            apikey={"optionlist"}
            style={{ display: 'flex' }}
            returnValue={setquestionOpList}
            defaultValue={editData?.optionlist}
          />
        </div> */}
            <div style={{ display: 'flex', justifyContent: "space-between",marginTop: "2%" }}>
              <div style={{ marginTop: "2%", display: 'flex' }}>
                <p style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>Loyalty Points</p>
                <div style={{ paddingLeft: "1%" }}>
                  <Input
                    type='number'
                    placeholder='Enter Loyalty Points'
                    min={0}
                    style={{ width: "80%", }}
                    onChange={(e) => setloyaltyPoints(e.target.value)}
                    // defaultValue={loyaltyPoints}
                    value={loyaltyPoints}
                  />
                </div>
              </div>
              <div style={{ marginTop: "2%", display: 'flex' }}>
                <CallbackTextInput
                  placeholder="Enter Placeholder"
                  displayLabel={"Placeholder"}
                  style={{ width: "500px" }}
                  returnValue={setplaceholder}
                  defaultValue={editData?.placeholder}
                />
              </div>
              <div style={{ marginTop: "2%", display: 'flex' }}>
                {editData?.questiontype == "3" || questionType == "3" ? null : (
                  <div style={{ display: 'flex' }}>
                    <p style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>Field Type</p>
                    <div style={{ paddingLeft: "1%", width: "100%" }}>
                      <Select

                        placeholder="Select Field Type"
                        // selected={selectedFieldType}
                        defaultValue={fieldTypeArr.filter(item => item.value == selectedFieldType)[0]}
                        // styles={{
                        //   border: "1px solid #cccccc",
                        //   borderRadius: "8px",
                        // }}
                        onChange={(e) => {
                          console.log("Selected value:", e.value);
                          setfieldtype(e.value)
                          setSelectedFieldType(e.value)
                        }}
                        options={fieldTypeArr}
                      />
                    </div>
                  </div>
                )}

              </div>
            </div>
          </div>
          {/* <div style={{ display: 'flex', flexDirection: 'row', marginTop: "2%" }}>
        <div>
          <CallbackDropdown
            placeholder="Select Issubquestionof"
            displayLabel={"Issubquestionof"}
            apikey={"issubquestionof"}
            apiurl={getQuestionBySurveyType}
            body={{ "surveytype": editData?.surveytyperecno, "hassubquestion": 1 }}
            selectionLabel={"questionname"}
            selectionValue={"recno"}
            returnValue={setissubquestionof}
            width={"100%"}
            defaultSelected={editData?.issubquestionof}
          />
        </div>
        <div style={{ marginLeft: "5%" }}>
          <CallbackDropdown
            placeholder="Select For Answer"
            displayLabel={"For Answer"}
            apikey={"foranswer"}
            apiurl={getForAnswer}
            body={{ "surveytyperecno": editData?.surveytyperecno, "questionrecno": editData?.issubquestionof }}
            width={"100%"}
            selectionLabel={"optionlist"}
            selectionValue={"optionlist"}
            returnValue={setforanswer}
            defaultSelected={editData?.foranswer}
          />
        </div>
        <div style={{ marginLeft: "5%" }}>
          <CallbackDropdown
            placeholder="Select Isgroupof"
            displayLabel={"Isgroupof"}
            apikey={"isgroupof"}
            apiurl={getQuestionBySurveyType}
            body={{ "surveytype": editData?.surveytyperecno, "hasgroup": 1 }}
            selectionLabel={"questionname"}
            selectionValue={"isgroupof"}
            width={"100%"}
            returnValue={setisgroupof}
            defaultSelected={editData?.isgroupof}
          />
        </div>
      </div> */}
          <div style={{ display: 'flex', marginTop: "2%" }}>
            {/* <div style={{ display: 'flex', flexDirection: 'row' }}> */}
            <div style={{ marginLeft: "10%", marginTop: "2%" }} >
              <CallbackCheckbox
                displayLabel={"Mandatory"}
                apikey={"mandatory"}
                editable
                returnValue={setmandatory}
                defaultValue={editData?.mandatory}
              />
            </div>

            <div style={{ marginLeft: "10%", marginTop: "2%" }} >
              <CallbackCheckbox
                displayLabel={"Images"}
                apikey={"images"}
                editable
                returnValue={setimages}
                defaultValue={editData?.images}
              />
            </div>

            {/* <div style={{ marginLeft: "15%", marginTop: "2%" }} >
            <CallbackCheckbox
              displayLabel={"isCommon"}
              apikey={"iscommon"}
              editable
              returnValue={setisCommon}
              defaultValue={editData?.isCommon}
            />
          </div> */}
            {/* </div> */}
            {/* <div style={{ display: 'flex', flexDirection: 'row' }}> */}
            {/* <div style={{ marginLeft: "15%", marginTop: "2%" }} >
            <SaveCheckBox
              displayLabel={"Hasgroup"}
              apikey={"hasgroup"}
              editable
              returnValue={sethasgroup}
              defaultValue={editData?.hasgroup}
            />
          </div> */}

            <div style={{ marginLeft: "10%", marginTop: "2%" }} >
              <CallbackCheckbox
                displayLabel={"Locn"}
                apikey={"location"}
                editable
                returnValue={setlocation}
                defaultValue={editData?.location}
              />
            </div>

            <div style={{ marginLeft: "10%", marginTop: "2%" }} >
              <CallbackCheckbox
                displayLabel={"Auto-filled"}
                apikey={"autofilled"}
                editable
                returnValue={setautofilled}
                defaultValue={editData?.autofilled}
              />
            </div>

            <div style={{ marginLeft: "10%", marginTop: "2%" }} >
              <CallbackCheckbox
                displayLabel={"Active"}
                apikey={"active"}
                editable
                returnValue={setactive}
                defaultValue={editData?.active}
              />
            </div>
            {/* </div> */}
          </div>
          {editData?.questiontype == "3" || questionType == "3" ? (
            <div style={{ marginTop: "1%" }}>
              <Button id="add-option-button" onClick={handleAddOption}>
                Add Option
              </Button>
              {options.map((option, i) => (
                <EditSubQuestion
                  key={i}
                  options={options}
                  setOptions={setOptions}
                  index={i}
                  handleRemoveOption={handleRemoveOption}
                  hasSubOption={hasSubOption}
                  setHasSubOption={setHasSubOption}
                  subOptions={subOptions}
                  setSubOptions={setSubOptions}
                  subQuestionType={subQuestionType}
                  setsubQuestionType={setsubQuestionType}
                />
              ))}
            </div>
          ) : null}
        </div>
        : null}
    </div>

  )
}

export default EditSurveyQuestionModal
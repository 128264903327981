import React from "react";

const AboutBcore = () => {
  return (
    <div 
    >
       <div style={{position:'fixed',backgroundColor:'white',width:'80%'}}> <h1>About Bcore</h1></div>
       <br/> 
       <br/> 
      <h2>Introduction</h2>
      <p>
        B-Core is an application stack designed for micro, small, and medium
        business organisations as well as self-employed professionals. Its
        primary objective is to provide an easy-to-use, handy way of managing
        business operations and obtaining informed business outcomes. B-Core is
        specifically tailored to meet the needs of different businesses,
        providing business-specific facilities that match their operations. This
        is achieved by designing various models that categorise the work and
        provide an intuitive user interface accordingly. It has a wide range of
        features, including procurement, counter sales, returns, accounts,
        taxation, stock management, services, complaint management, time
        management, costing, revenue estimation, customer management, analytics,
        and basic reporting.
      </p>
      <h2>Procurement </h2>
      <p>
        The procurement module covers the total procurement cycle from order to
        receipt of goods, including accounting and stock management.{" "}
      </p>
      <h2>Counter Sales</h2>
      <p>
        The counter sales module provides an intuitive interface for managing
        all counter sales operations, including MRP, discounting, billing,
        credit management, dispatch, and floor stock management.{" "}
      </p>
      <h2>Returns </h2>
      <p>
        The returns module tracks sales and purchase returns, reverses
        transactions, and accounts for GST effects and stock adjustments.
      </p>
      <h2>Accounts</h2>
      <p>
        The accounts module provides basic financial accounting, including cash
        receipts, cash payments, bank transactions, GST processing, other
        taxation processes, books of accounts, and accounts receivable/payable.{" "}
      </p>
      <h2>Taxation</h2>
      <p>
        The taxation module specifically handles GST management, invoicing,
        credit/debit GST, GST reporting, and GST upload onto portals.
      </p>
      <h2>Stock Management</h2>
      <p>
        The stock management module handles location-wise stock management,
        inward/ outward stock tracking, stock verification, and valuation.{" "}
      </p>
      <h2>Services</h2>
      <p>
        The services module manages service call scheduling, call attendance,
        call completion, and billing/collection.
      </p>
      <h2>Complaint</h2>
      <p>
        The complaint module covers receiving, logging, allocation,
        scheduling/assignment, attending, completing, and billing for
        complaints.
      </p>
      <h2>Time Management</h2>
      <p>
        The time management module tracks time for any activity, including start
        and finish times, idle time estimation, transit time, and time patterns.{" "}
      </p>
      <h2>Costing </h2>
      <p>
        The costing module provides activity-based costing, cost estimation,
        overheads, categorising costs, and resource costs.{" "}
      </p>
      <h2>Revenue</h2>
      <p>
        The revenue module estimates revenue for each activity, earning per
        bill, per service, per trip, cost head-wise expenses, and averages of
        costs.
      </p>
      <h2>Customer</h2>
      <p>
        The customers module manages customer data, including customer-wise
        bills, item sales, services rendered, complaints, TAT on sale/service
        delivery, time consumed, and cost head-wise expenses.{" "}
      </p>
      <h2>Analytics</h2>
      <p>
        The analytics module provides profitable activities, item and service
        analysis, cost analysis, time patterns, item movement analysis, stock
        expiry, and alerts/notifications.{" "}
      </p>
      <h2>Basic Reporting </h2>
      <p>
        The basic reporting module includes stock reports, consumption reports,
        expenses reports, P&L, cashflow, and all tax, accounts, and customers
        related reports.
      </p>
      <h2>Scheduler</h2>
      <p>
        B-Core’s scheduler module manages work schedules, workflow schedules,
        reminders, and periodic payment schedules.
      </p>
      <h2>Group/Cluster</h2>
      <p>
        Additionally, B-Core’s group/cluster module caters to managing
        multiple-level businesses of a group, such as dealer-retailer networks,
        MFG-dealer networks, transport fleets, group analysis, collective
        demand, supply management, order management, and accounting. B-Core uses
        micro-service API to cater to the needs of frontend UI and reporting
        tools using JSON formats. The UI and frontend are developed in ReactJS
        and React Native for mobile. The modules are developed in loose-coupled
        design, which can be integrated with any other module. B-Core also
        utilises third-party open-source modules as needed. With B-Core, micro,
        small, and medium business organisations, as well as self-employed
        professionals, can now manage their operations more efficiently with a
        single, unified, and easy-to-use software suite.
      </p>
    </div>
  );
};

export default AboutBcore;

// Kaushik Koparkar 6/01/2023
import { Button, Collapse, Input, Modal, Space } from "antd";
import React, { useContext, useEffect, useState } from "react";
import {
  getToday,
  dateFormatter,
  addDays,
  convertToBoxAndPieces,
  removeByAttribute,
  enterPress,
} from "../../CommonControllers/AppFunction";

import ItemSearch from "../../CommonControllers/ItemController/ItemSearch";
import SupplierSaveDropDown from "../../CommonControllers/SaveComponents/HigherLevel/SupplierSaveDropDown";
import SaveDatePicker from "../../CommonControllers/SaveComponents/SaveDatePicker";
import {
  handleAdd,
  grnColumns,
  handleRemove,
  handleRemovePrev,
} from "./GRNTableColumns";
import useSaveChangedRows from "../../CommonControllers/Hooks/useSaveChangedRows";
import useShortguid from "../../CommonControllers/Hooks/useShortGuid";
import { TabStore } from "../../TabsStructure/TabsStore";
import { AuthStore } from "../../Auth/AuthStore";
import "../../CommonControllers/global.css";
import { IoAperture } from "react-icons/io5";
import UserLocationList from "../../CommonControllers/SaveComponents/HigherLevel/UserLocationList";
import { useRef } from "react";
import GrnTable from "./GrnTable";
import SaveTextInput from "../../CommonControllers/Components/SaveComponents/SaveTextInput";
import { currentTabData } from "../../CommonControllers/Variable";

import CallbackTextInput from "../../CommonControllers/Components/CallbackComponents/CallbackTextInput";
import SearchFilterByBillNo from "../../CommonControllers/Components/SearchFilterBillNo/SearchFilterByBillNo";

import { List } from "antd";
import { FcAddDatabase } from "react-icons/fc";
import {
  apiCallwithBaseUrl,
  getfastpathapi,
} from "../../CommonControllers/ApiCalls";
import MultipleSelectionItem from "./MultipleSelectionItem";
import SaveCheckbox from "../../CommonControllers/Components/SaveComponents/SaveCheckbox";
import { ContactSupport } from "@mui/icons-material";
import GRNDates from "./GRNDates";
import AdditionalDetails from "../../CommonControllers/AdditionalDetails/AdditionalDetails";
import useFetchItem from "../../CommonControllers/ItemController/useFetchItems";
import SaveRadioBtn from "../../CommonControllers/Components/SaveComponents/SaveRadioBtn";
import {
  getDomainSupplier,
  getdomainlocationlistactive,
} from "../../CommonControllers/Urls";

import SaveDropDown from "../../CommonControllers/SaveComponents/SaveDropDown";
import SaveDropdown from "../../CommonControllers/Components/SaveComponents/SaveDropdown";
function GrnPurchaseOrder({fastPath}) {
  const [getSelectedItem, setSelectedItem] = useState(null);
  const [rowData, setRowData] = useState([]);
  const [supplierdata, setSupplierdata] = useState(null);
  const [igstappl, setigstappl] = useState(false);
  const useChangedRows = useSaveChangedRows();
  const [displayMessages, setDisplayMessages] = useState(false);
  const [showFilters, setShowFilters] = useState(true);
  const [purchaseRate, setPurchaseRate] = useState(" ");
  const [selectedQty, setSelectedQty] = useState(" "); // Qty as in pieces in UI, total = pieces * rate
  const [collapse, setCollapse] = useState(false);
  const [expDeliveryDays, setExpveryDays] = useState(null);
  const [expDeliveryDate, setExpDeliveryDate] = useState(0);

  // ---------------------Edit Transtion States------------------
  // Create useState for Show POP Of Search Bill
  const [showFilterModal, setshowFilterModal] = useState(false);
  // render
  const [countRender, setCountRender] = useState(0);
  const [grnNumber, setGRNNumber] = useState();
  const [location, setlocation] = useState();
  // --------------------------------------------------------------

  const { Panel } = Collapse;
  const auth = useContext(AuthStore);
  const shortguid = useShortguid();
  const tabStore = useContext(TabStore);

  const btnRef = useRef(null);

  const [batchNo, setBatchNo] = useState(null);
  const [mfgDate, setMfgDate] = useState(0);
  const [expDate, setExpDate] = useState(0);
  const [box, setBox] = useState(0);
  const [pcs, setPcs] = useState(0);

  //~# State variable for date validation
  const [grnDate, setGrnDate] = useState(auth?.state?.userdata?.workdate);
  const [supplierDcDate, setSupplierDcDate] = useState();

  // -------------multiple selecton-----------------
  const [previousPOList, setPreviousPOList] = useState([]);
  const [displayPrevPO, setDisplayPrevPO] = useState(false);
  const [returnData, setreturnData] = useState([]); //To Get the data from Selection Component of Miltiple Selection
  const [count, setCount] = useState(0);
  const [render, setRender] = useState(0);
  const [checkExistingItem, setcheckExistingItem] = useState();

  ////dropdown clear//////

  const [modalcount, setModalCount] = useState(0);
  const [pageIndex, setPageIndex] = useState();

  ///batch
  const [opnenBatchcount, setopenBatchcount] = useState(0);
  const [itemForSelection, setItemForSelection] = useState(null);
  const [opnenBatch1, setopenBatch1] = useState(false);
  const [pageDetails, setPageDetails] = useState();
  const [statusOptions, setStatusOptions] = useState([]);
  const [status, setstatus] = useState(true);
  //////
  const fetchItems = useFetchItem();
  //// suppier disable addedd here date 2 dec
  const [transactionEdit, setTransactionEdit] = useState(false);

  const itemsearch=useRef()

  useEffect(() => {
    var tabsArray = tabStore.state.tabs;
    for (let i = 0; i < tabsArray?.length; i++) {
      if (tabsArray[i].name == "TR003") {
        setPageIndex(i);
        break;
      }
    }
  }, []);

  const payload = {
    tenantrecno: auth?.state?.userdata?.tenantrecno,
    domainrecno: auth?.state?.userdata?.domainrecno,
    userrolerecno: auth?.state?.userdata?.userrolerecno,
    domainuserrecno: auth.state.userdata.recno,
    fastpath: currentTabData(tabStore)?.name,
  };
  useEffect(() => {
    // Get Fast ath API
    getfastpathapi(payload).then((res) => {
      if (res?.Success) {
        setPageDetails(res?.Message);
        setStatusOptions([
          {
            label: "Save",
            value: res.Message?.defaultStatus,
          },
          {
            label: "Proceed",
            value: res.Message?.nextStatus,
          },
        ]);
      }
    });
  }, []);
  useEffect(() => {
    if (getSelectedItem) {
      setPurchaseRate(Number(getSelectedItem?.purrate).toFixed(2));
    }
    setMfgDate(
      getSelectedItem?.wantbatchno == 0 &&
        getSelectedItem?.manudatecompulsory == 0
        ? auth?.state?.userdata?.workdate
        : mfgDate
    );
    setExpDate(
      getSelectedItem?.wantbatchno == 0 ||
      pageDetails?.wantexpdate == 0
        ? "29991231"
        : expDate
    );

    getSelectedItem?.wantbatchno ? setBatchNo("") : setBatchNo(".");
  }, [getSelectedItem]);

  useEffect(() => {
    getSelectedItem?.purrate !== undefined
      ? setPurchaseRate(getSelectedItem?.purrate)
      : setPurchaseRate("");
  }, [getSelectedItem?.purrate]);

  // Update Data from TabStore

  useEffect(() => {
    if (tabStore.state.tabs[pageIndex]?.changedRows?.dataFromEdit) {
      setRowData(tabStore.state.tabs[pageIndex]?.changedRows?.items);
      setTransactionEdit(true);
      setStatusOptions([
        {
          label: "Save",
          value: tabStore.state.tabs[pageIndex]?.changedRows?.status,
        },
        {
          label: "Proceed",
          value: tabStore.state.tabs[pageIndex]?.changedRows?.nextStatus,
        },
      ]);
    }
  }, [tabStore.state.tabs[pageIndex]?.changedRows?.dataFromEdit]);

  // useEffect(() => {
  //   console.log("Batch Details Are", getSelectedItem?.entryexpdate);
  //   setBatchNo(
  //     getSelectedItem?.entrybatchno ? getSelectedItem?.entrybatchno : 0
  //   );
  //   setExpDate(
  //     getSelectedItem?.entryexpdate ? getSelectedItem?.entryexpdate : 0
  //   );
  //   setMfgDate(
  //     getSelectedItem?.entrymanudate ? getSelectedItem?.entrymanudate : 0
  //   );
  // }, [getSelectedItem]);

  //~# Setting workdate
  let workdate = auth?.state?.workdate;
  workdate = JSON.stringify(workdate);
  workdate = `${workdate?.slice(-2)}/${workdate?.slice(4, 6)}/${workdate?.slice(
    0,
    4
  )}`;

  function dateSetter() {
    const expDays = supplierdata?.expdeliverydays;
    // let expdate = dateFormatter(addDays(auth?.state?.workdate, expDays));
    let expdate = addDays(auth?.state?.workdate, expDays);

    setExpDeliveryDate(expdate);
    return expdate;
  }

  function setDatatoSupplierdata(e) {
    setSupplierdata(e);
  }

  function handleBoxChange(event) {
    const regex = /^[0-9\b]+$/;
    if (event.target.value === "" || regex.test(event.target.value)) {
      setBox(event.target.value);
    }
  }

  function handlePiecesChange(event) {
    const regex = /^[0-9\b]+$/;
    const value = event.target.value;
    if (value === "" || regex.test(value)) {
      setSelectedQty(value);
    }
  }

  const addItemstoTable = () => {
    let x=workdate.split("/").reverse().join("")

if(getSelectedItem?.wantbatchno == 1 &&
  pageDetails?.wantexpdate == 1&&(Number(x)>Number(expDate))){
      alert("Product is Expired")

}
    if (!getSelectedItem.itemasbatch) {
      if (!batchNo) {
        alert("Batch Cannot be Null");
        return;
      }
    }
    if (
      getSelectedItem?.wantbatchno == 1 &&
      pageDetails?.wantmanudate == 1 &&
      (mfgDate == 0 ||
        mfgDate == undefined ||
        mfgDate == "undefined" ||
        !mfgDate)
    ) {
      alert("Manufacture Date Cannot be Empty");
      return;
    }
    if (
      getSelectedItem?.wantbatchno == 1 &&
      pageDetails?.wantexpdate == 1 &&
      (expDate == 0 ||
        expDate == undefined ||
        expDate == "undefined" ||
        !expDate)
    ) {
      alert("Expiry Date Cannot be Empty");
      return;
    }
    setRender((curr) => curr + 1);
    if (getSelectedItem) {
      var existingItem = rowData.filter(
        (item) => item.itemrecno == getSelectedItem.itemrecno
      );

      if (existingItem?.length == 0) {
        // clearing all input values
        setBox(() => "");
        setSelectedQty("");
        setPcs(() => "");
        setPurchaseRate("");
        setExpDate(() => 0);
        setMfgDate(0);
        setBatchNo(() => "");
        handleAdd(
          getSelectedItem,
          setRowData,
          igstappl,
          setSelectedItem,
          shortguid,
          purchaseRate,
          pcs,
          box,
          batchNo,
          mfgDate,
          expDate
        );
      } else {
        alert("Item Already Present");
        setSelectedItem(null);
      }
    }
    setMfgDate(0);
    setExpDate(0);
    itemsearch.current.focus()
  };

  useEffect(() => {
    if (supplierdata?.sezappl) {
      setigstappl(true);
      dateSetter();
    } else {
      setigstappl(false);
      dateSetter();
    }
  }, [supplierdata]);

  useEffect(() => {
    // When Row Data Changes we Need to store that data to the tabsStore with Header Data
    var existingObject = useChangedRows.getValue();
    // Calculate Other Numbers and Inputs and store in the tabStore
    // var status = "P";
    var trdate = "workdate";
    var approvalStatus = "P";
    var remark = "Need to add a field for that";
    var narration = "Need to ad a text field for this as well";
    var amount; // Calculate the total amount after tax
    var taxableamount; // Calculate the total taxable amount after tax
    var cgstamt; //Calculate the CGSTAMT
    var sgstamt; //Calculate teh SGST AMT
    var igstamt; //Calculat the IGSTAMT
    // var shortguid

    amount = rowData.reduce((total, item) => total + Number(item.amount), 0);
    taxableamount = rowData.reduce(
      (total, item) => total + Number(item.taxableamount),
      0
    );
    cgstamt = rowData.reduce((total, item) => total + Number(item?.cgstamt), 0);
    sgstamt = rowData.reduce((total, item) => total + Number(item?.sgstamt), 0);
    igstamt = rowData.reduce((total, item) => total + Number(item.igstamt), 0);
    var shortguid = existingObject?.shortguid
      ? existingObject?.shortguid
      : shortguid?.getShortguid("GRN-TR003");
    // console.log("new/old shortguid is",shortguid)

    existingObject = {
      ...existingObject,
      cgstamt: cgstamt,
      igstamt: igstamt,
      sgstamt: sgstamt,
      taxableamount: taxableamount,
      amount: amount,
      items: rowData,
      trdate: auth?.state?.workdate, //~# workdate here
      shortguid: shortguid,
      status: status ? statusOptions[1]?.value : statusOptions[0]?.value,
      //status: "C", ///asked by PM
    };

    useChangedRows.updateValue(false, existingObject);
  }, [rowData, status]);

  useEffect(() => {
    // When Row Data Changes we Need to store that data to the tabsStore with Header Data
    var currentData = useChangedRows.getValue();
    if (!currentData?.shortguid) {
      useChangedRows.updateValue("shortguid", shortguid.getShortguid("PO"));
    }

    if (rowData?.length == 0) {
      var items = useChangedRows.getValue("items");
      if (items) {
        setRowData(items);
      }
    }
  }, []);

  useEffect(() => {
    // When Row Data Changes we Need to store that data to the tabsStore with Header Data
    var currentData = useChangedRows.getValue();
    if (!currentData?.shortguid) {
      useChangedRows.updateValue("shortguid", shortguid.getShortguid("GR"));
    }
    setSupplierdata(useChangedRows.getValue("supplierrecno"));
    setGRNNumber(useChangedRows.getValue("billno"));
    setlocation(useChangedRows.getValue("locationrecno"));

    var items = useChangedRows.getValue("items");
    if (items) {
      setRowData(items);
      setTransactionEdit(true);
    }
  }, [showFilterModal]);

  useEffect(() => {

    if (useChangedRows.getValue("trdate")) {
      setGrnDate(useChangedRows.getValue("trdate"));
    } else {
      useChangedRows.updateValue("trdate", auth?.state?.workdate);
      setGrnDate(auth?.state?.workdate);
    }
  }, [useChangedRows.getValue("trdate")]);

  useEffect(
    () => {
      if (tabStore.state.tabs[pageIndex]?.pageStatus == "Success") {
        setRowData([]);
        setigstappl(false);
        setSelectedItem(null);
        setSelectedQty(" ");
        setPurchaseRate(" ");
        setDatatoSupplierdata(null);
        setGRNNumber("To Be Allotted");
        setGrnDate(auth?.state?.userdata?.workdate)
        setStatusOptions([
          {
            label: "Save",
            value: pageDetails?.defaultStatus,
          },
          {
            label: "Proceed",
            value: pageDetails?.nextStatus,
          },
        ]);
        setstatus(true);
        setTransactionEdit(false);
        document.getElementById("grn-date").value = "";
        document.getElementById("tr003-grn-dcdate").value = "";
        tabStore.dispatch({
          type: "UPDATE_DATA",
          fieldType: "pageStatus",
          text: null,
        });
        useChangedRows.updateValue("shortguid", shortguid.getShortguid("GRN"));
        setModalCount((p) => p + 1);
        setTimeout(
          tabStore.dispatch({
            type: "UPDATE_DATA",
            fieldType: "pageStatus",
            text: null,
          }),
          1000
        );
      }
    },
    [tabStore.state.tabs[pageIndex]?.pageStatus],
    Object.keys(currentTabData(tabStore)?.changedRows)?.length
  );

  // ----------------------multiple selection---------------------

  function setDatatoSupplierdata(e) {
    console.log();
    setSupplierdata(e);

    // Get Suppliers Purchase Orders where status == "C"
    if (e) {
      getPurchaseOrdersForSupplier(e?.supplierrecno);
    }
  }

  const getPurchaseOrdersForSupplier = async (supplierdescn) => {
    try {
      const res = await apiCallwithBaseUrl("POST", "/filterpurchaseorder/", {
        supplierrecno: supplierdescn,
        withitems: true,
        status: "P",
      });
      setPreviousPOList(res);
    } catch (error) {
      console.error("Error fetching purchase orders:", error);
      // Optionally, you can set an error state or show a notification to the user
      setPreviousPOList([]);
      // If you have a state or function to show error messages, use it here
      // showErrorNotification("Failed to fetch purchase orders. Please try again.");
    }
  };
  
  //---------------multiple selection item start------------------
  const optionName = ["Purchase Order", "Purchase Invoice"];

  const options = [
    {
      id: 1,
      optionName: "Purchase Order",
      apiurl: "filterpurchaseorder",
      supplierrecno: supplierdata?.supplierrecno,
      doctype: "PO",
    },
    {
      id: 2,
      optionName: "Purchase Invoice",
      apiurl: "filterpurchasebill",
      supplierrecno: supplierdata?.supplierrecno,
      doctype: "PI",
    },
  ];
  //-----------------------------end-----------------------------

  useEffect(() => {
    if (opnenBatchcount > 0) {
      let req = {
        ...itemForSelection,
        packingqty: 0,
        itembatchno: "",
        manufacturedate: 0,
        expdate: 0,
        qty: 0,
        packingqtyaccepted: 0,
        shortguid: generateUniqueId(), // Add a unique identifier to the object
      };
      // Find the index of the clicked item in rowData
      const currentIndex = rowData.findIndex(
        (item) => item === itemForSelection
      );
      // Calculate the index where you want to insert the new req object
      const insertionIndex = currentIndex + 1;
      // Create a new copy of the rowData array
      let newRowData = [...rowData];
      // Insert the req object at the specified index using splice
      newRowData.splice(insertionIndex, 0, req);
      // Update your state or variable with the modified newRowData

      setRowData(newRowData);
    }
  }, [opnenBatchcount]);
  //// opnenBatch1  this condition was ommited for useeffect

  // Function to generate a unique identifier
  function generateUniqueId() {
    return Date.now().toString(36) + Math.random().toString(36).substr(2);
  }
  //----------------start------------///
  //// validation for  save button
  // useEffect(() => {
  //   let validateData = "false";

  //   for (let i = 0; i < rowData?.length; i++) {
  //     console.log("RowData", rowData[i]);
  //     if (
  //       rowData[i]?.itemasbatch &&
  //       Number(rowData[i]?.qty) +
  //         Number(rowData[i]?.free ? rowData[i]?.free : 0) !=
  //         rowData[i]?.itembatcharray?.length
  //     ) {
  //       // console.log("VLAIDATIONS APPLICATION", rowData[i]?.itemasbatch,
  //       // Number(rowData[i]?.qty) +
  //       //   Number(rowData[i]?.free ? rowData[i]?.free : 0),
  //       //   rowData[i]?.itembatcharray?.length)
  //       console.log("Validations are True");
  //       validateData = "true";
  //     }
  //   }
  //   console.log("Validated IS", validateData);
  //   useChangedRows.updateValue("validated", validateData);
  // }, [rowData]);
  useEffect(() => {
    let isButtonDisabled = false;

    for (let i = 0; i < rowData.length; i++) {
      const {
        packingqty,
        packingextraqty,
        freepackingqty,
        freepackingextraqty,
        manufacturedate,
        expdate,
        itembatchno,
        purrate,
        expdatecompulsory,
        itemasbatch,
        manudatecompulsory,
        wantbatchno,
        changesalerateinbill,
      } = rowData[i];

      // if ( (wantbatchno && (!itembatchno || itembatchno == "" || itembatchno == null || itembatchno == undefined))|| (expdatecompulsory && (!expdate || expdate == "undefined" || Number(expdate) == 0))||(manudatecompulsory && (!manufacturedate || manufacturedate == "undefined" || Number(manufacturedate) == 0)) || (packingqty === "0" && packingextraqty === "0") || (packingqty == "" && packingextraqty == "") || (packingqty === 0 && packingextraqty === "0") || (packingqty === "0" && packingextraqty === 0) || (packingqty === 0 && packingextraqty === 0) || (rowData[i]?.itemasbatch &&
      //   Number(rowData[i]?.qty) +
      //   Number(rowData[i]?.free ? rowData[i]?.free : 0) <
      //   rowData[i]?.itembatcharray?.length) || Number(purrate) == 0)

      if (
        (wantbatchno &&
          (!itembatchno ||
            itembatchno == "" ||
            itembatchno == null ||
            itembatchno == undefined)) ||
        (pageDetails?.wantexpdate &&
          wantbatchno &&
          (!rowData[i]?.expdate || rowData[i]?.expdate == "undefined" || Number(rowData[i]?.expdate) == 0)) ||
        (pageDetails?.wantmanudate &&
          wantbatchno &&
          (!rowData[i]?.manufacturedate ||
            rowData[i]?.manufacturedate == "undefined" ||
            Number(rowData[i]?.manufacturedate) == 0)) ||
        (rowData[i]?.itemasbatch &&
          Number(rowData[i]?.qty) +
            Number(rowData[i]?.free ? rowData[i]?.free : 0) !=
            rowData[i]?.itembatcharray?.length)
        //     ||
        // Number(purrate) == 0
      ) {
        isButtonDisabled = true;
        console.log("RYFUKFVU1", rowData, isButtonDisabled);
        break;
      } else if (
        wantbatchno &&
        (!itembatchno ||
          itembatchno == "" ||
          itembatchno == null ||
          itembatchno == undefined)
        // ||
        // (packingqty === "0" && packingextraqty === "0") ||
        // (packingqty == "" && packingextraqty == "") ||
        // (packingqty === 0 && packingextraqty === "0") ||
        // (packingqty === "0" && packingextraqty === 0) ||
        // (packingqty === 0 && packingextraqty === 0) ||
        // Number(purrate) == 0
      ) {
        isButtonDisabled = true;
        console.log("RYFUKFVU2", rowData, isButtonDisabled);
        break;
      } else if (
        (wantbatchno &&
          (!itembatchno ||
            itembatchno == "" ||
            itembatchno == null ||
            itembatchno == undefined)) ||
        (!rowData[i]?.itemasbatch &&
          Number(rowData[i]?.qty) +
            Number(rowData[i]?.free ? rowData[i]?.free : 0) ==
            0)
      ) {
        console.log("RYFUKFVU3", rowData, isButtonDisabled);
        isButtonDisabled = true;
        break;
      } else if (!supplierdata) {
        isButtonDisabled = true;
        break;
      }
    }
    console.log("RYFUKFVU4", rowData, isButtonDisabled);
    useChangedRows.updateValue(
      "validated",
      !isButtonDisabled ? "true" : "false"
    );
  }, [rowData]);

  /////------validation------////

  console.log("returnDatareturnDatan",rowData);

  return (
    <div style={{ ...mainFrame }}>
      <Modal
        open={displayMessages}
        onCancel={() => {
          setDisplayMessages(!displayMessages);
        }}
        onOk={() => {
          useChangedRows.updateValue(
            "message",
            document.getElementById("message").value
          );
          useChangedRows.updateValue(
            "narration",
            document.getElementById("narration").value
          );
          setDisplayMessages(!displayMessages);
        }}
      >
        <div style={{ display: "flex", flexDirection: "column" }}>
          <p>Message for Supl</p>
          <Input
            id={"message"}
            placeholder="Enter Message for Supl"
            defaultValue={useChangedRows.getValue("message")}
          ></Input>
          <br /> <p>Message for Self</p>
          <Input
            id={"narration"}
            placeholder="Enter Message for Self"
            defaultValue={useChangedRows.getValue("narration")}
          ></Input>
        </div>
      </Modal>

      {/* ================ modal multi select================ */}
      <Modal
        key={count}
        open={displayPrevPO}
        width={620}
        onCancel={() => {
          setDisplayPrevPO(!displayPrevPO);
          setCount((count) => count + 1);
        }}
        onOk={() => {
          let newArray = returnData.map((item) => {
            let pcsBox = convertToBoxAndPieces(
              Number(item?.selectedQty),
              Number(item?.conversionfactor)
            );

            if (!rowData || !item) {
              alert("Invalid data");
            } else {
              var existingItem = rowData.filter(
                (it) => it && it.itemrecno === item.itemrecno
              );

              if (existingItem.length === 0) {
                // Add item to the list
                console.log("returnDatareturnDatan",item)
                handleAdd(
                  item,
                  setRowData,
                  item.igstrate > 0 ? true : false,
                  setSelectedItem,
                  shortguid,
                  item.purchaseRate,
                  pcsBox[1],
                  pcsBox[0],
                  null,
                  0,
                  0
                );
                setreturnData([]);
              } else {
                handleRemovePrev(item, setRowData, rowData);

                setTimeout(() => {
                  handleAdd(
                    item,
                    setRowData,
                    item.igstrate > 0 ? true : false,
                    setSelectedItem,
                    shortguid,
                    item.purchaseRate,
                    pcsBox[1],
                    pcsBox[0],
                    null,
                    0,
                    0
                  );
                  setreturnData([]);
                }, 200);
              }
            }
          });

          setDisplayPrevPO(!displayPrevPO);
          setCount((count) => count + 1);
        }}
      >
        <MultipleSelectionItem
          options={options}
          forFlag={"grn"}
          selectedItemsArray={returnData}
          returnValue={(e) => {
          
            setreturnData(e);
          }}
        />
      </Modal>
      {/* ===========modal multi select end================ */}

      {/* ============================= //*Edit Transaction *\\ =================================== */}
      <Modal
        title="Search Bill"
        open={showFilterModal}
        onOk={() => {
          setshowFilterModal(false);
          setCountRender((p) => p + 1);
        }}
        onCancel={() => {
          setshowFilterModal(false);
          setCountRender((p) => p + 1);
        }}
        width={"100%"}
        style={{ top: 20 }}
        key={countRender}
      >
        <SearchFilterByBillNo
          fastpath="RP103"
          setshowFilterModal={setshowFilterModal}
          showFilterModal={showFilterModal}
        />
      </Modal>
      <Collapse collapsible="header" defaultActiveKey={["1"]}>
        {/* nirmal-406  panel header title changed */}
        <Panel header="Header Details" key="1">
          <form
            style={{
              display: "flex",
              flexWrap: "wrap",
              columnGap: "40px", // Adjust the column gap as needed
              rowGap: "15px",
              marginTop:"5px"
            }}
          >
            {/* Select Supplier */}
            <div
              onKeyDown={enterPress}
              style={{ zIndex: 10, marginTop: "10px" }}
            >
              {/* <SaveDropdown
                defaultValue={location}
                apikey={"locationrecno"}
                minInput={0}
                inputValue={20}
                displayLabel={ "Location"}
                zIndex={1000}
                //className={style.drop}
                key={modalcount}
                apiurl={getdomainlocationlistactive} // getdomainlocationlistactive is added from 2nd Dec
                selectionLabel={"descn"}
                selectionValue={"locationrecno"} //# previous key was recno, changed to locationrecno as per network response
                returnValue={setlocation}
                width={"250px"}
                alignment="column"

              /> */}
              <SaveDropdown
                defaultValue={location}
                //{Pradeep task id-398 }/// added isrequired prop
                placeholder={"Select Locn"}
                key={modalcount}
                zIndex={1000}
                // disabled={rowData?.length > 0 || transactionEdit ? true : false}
                apikey={"locationrecno"}
                minInput={0}
                inputValue={20}
                displayLabel={"Locn"}
                apiurl={getdomainlocationlistactive}
                selectionLabel={"descn"}
                selectionValue={"locationrecno"} //# previous key was recno, changed to locationrecno as per network response
                returnValue={setlocation}
                width={"250px"}
                alignment="column"
              />
            </div>
            <div
              onKeyDown={enterPress}
              style={{ pointerEvents: rowData.length > 0 ? "none" : "auto",zIndex:10,marginTop: "10px" }}
            >
              <SaveDropdown
                defaultSelected={supplierdata}
                apikey={"supplierrecno"}
                disabled={rowData && rowData.length > 0?1:0}
                minInput={0}
                key={modalcount}
                inputValue={20}
                displayLabel={"Supl"}
                //{Pradeep task id-398 }/// added isrequired prop
                isrequired
                placeholder={"Select Supl"}
                // className={style.drop}
                apiurl={getDomainSupplier}
                selectionLabel={"supplierdescn"}
                selectionValue={"supplierrecno"}
                returnValue={setDatatoSupplierdata}
                width="250px"
                alignment="column"
                zIndex={1000}
              
              />
            </div>

            {/* <div onKeyDown={enterPress} style={{
                
                position: "relative",
                marginTop: "-20px",
              }}>
              <p>GRN Number</p>
              <input
                style={{ ...poNumberStyle }}
                placeholder={grnNumber ? grnNumber : "TBA"}
                disabled
                key={modalcount}
              />
            </div> */}
            <div onKeyDown={enterPress} style={{ marginTop: "10px" }}>
              <div style={{ position: "relative" }}>
                <label
                  // htmlFor={id}
                  className={`label ${"focused"}`}
                  style={{
                    // zIndex: 1000,
                    whiteSpace: "nowrap",
                    wordWrap: "unset",
                    position: "absolute",
                    top: "-19px",
                    left: "12px",
                    //backgroundColor: "white",
                    padding: "0 4px",
                    fontSize: "12px",
                    color: "black",
                    pointerEvents: "none",
                    transform: "translateY(0)",
                    transition:
                      "top 0.3s, transform 0.3s, font-size 0.3s, color 0.3s",
                  }}
                >
                  GRN No.
                </label>
                <input
                  style={{ ...poNumberStyle }}
                  placeholder={grnNumber ? grnNumber : "To Be Allotted"}
                  disabled
                  key={modalcount}
                />
              </div>
            </div>
            <div onKeyDown={enterPress} style={{ marginTop: "10px" }}>
              <SaveDatePicker
                defaultValue={grnDate}
                id={"grn-date"}
                displayLabel={"GRN Date"}
                placeholder={"Select GRN Date"}
                disabled={rowData && rowData.length > 0?1:0}
                apiKey={"trdate"}
                // disabled={fastPath=="TR003A"?true:false}
                dateLessthan={auth?.state?.userdata?.workdate} //! If this is false, postAlertMsg will trigger
                postAlertMsg={`GRN Date cannot be greater than Today's Date or less than Opening date`}
                dateGreaterthan={auth?.state?.userdata?.openingbaldate} // New date Valdation
                returnValue={(e) => {
                  setGrnDate(e);

                  //##### the automically focus in dc date  is solve when we remove the focus
                  // document.getElementById("tr003-grn-dcdate").focus();
                  // return true;
                }}
                key={modalcount}
              />
            </div>
            <div onKeyDown={enterPress} style={{ marginTop: "10px" }}>
              <SaveTextInput
              pageIndex={pageIndex}
                displayLabel="PO No."
                alignment="column"
                apikey="porefno"
                id="tr003-grn-po"
                placeholder={"Select PO No."}
              />
            </div>
            <div onKeyDown={enterPress} style={{ marginTop: "10px" }}>
              <SaveTextInput
              pageIndex={pageIndex}
                displayLabel="Supl DC No."
                alignment="column"
                apikey="dcrefno"
                id="tr003-grn-dcnumber"
                key={modalcount}
                placeholder={"Select Supl DC No."}
              />
            </div>

            <div
              onKeyDown={enterPress}
              style={{ zIndex: 1, marginTop: "10px" }}
            >
              <SaveDatePicker
                displayLabel="Supl DC Date"
                //defaultValue=""
                placeholder={"Supl DC Date"}
                apiKey="refdate"
                id={"tr003-grn-dcdate"}
                dateLessthan={grnDate}
                postAlertMsg={`Supplier DC Date cannot be greater than GRN Date`}
                returnValue={(e) => {
                  setSupplierDcDate(e);
                }}
                key={modalcount}
              />
            </div>

            {/* Status */}
            <div style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }} 
            onKeyDown={enterPress}>
              <div style={{ position: "relative" }}>
                {/* <label
                  className={`label ${"focused"}`}
                  style={{
                    // zIndex: 1000,
                    whiteSpace: "nowrap",
                    wordWrap: "unset",
                    position: "absolute",
                    top: "-10px",
                    left: "12px",
                    //backgroundColor: "white",
                    padding: "0 4px",
                    fontSize: "12px",
                    color: "black",
                    pointerEvents: "none",
                    transform: "translateY(0)",
                    transition:
                      "top 0.3s, transform 0.3s, font-size 0.3s, color 0.3s",
                  }}
                >
                  Status
                </label>
                <div
                  onKeyDown={enterPress}
                  className="saveproceed"
                  style={{
                    padding: "8.7px",
                    marginTop: "4px",
                  }}
                >
                  <SaveRadioBtn
                    apikey={"status"}
                    key={modalcount}
                    // defaultValue={pageDetails?.defaultStatus}
                    options={statusOptions}
                  />
                </div> */}
                <SaveCheckbox 
                  displayLabel={"Proceed"}
                  defaultValue={status}
                  editable
                  apikey={"status"}
                  returnValue={(e) => setstatus(e) }                    
                />
              </div>
            </div>

            <div onKeyDown={enterPress} style={{ marginTop: "8px" }}>
              <Button
                className="btn"
                //style={{ ...button }}
                type="default"
                onClick={(e) => setDisplayMessages(!displayMessages)}
              >
                Add / View Messages
              </Button>
            </div>
            {/* =============================multiple selection=============================================== */}
            <div onKeyDown={enterPress} style={{ marginTop: "8px" }}>
              <Button
                className="btn"
                //style={{ ...button }}
                onClick={() => {
                  if (supplierdata) {
                    setDisplayPrevPO(!displayPrevPO);
                    setCount((count) => count + 1);
                  } else {
                    alert("Supplier Not Selected");
                  }
                }}
              >
                Previous Documents
              </Button>
            </div>

            {/* =============================//*Edit Transaction*\\=================================== */}
            <div onKeyDown={enterPress} style={{ marginTop: "8px" }}>
              <Button
                className="btn"
                //style={{ ...button }}
                type="default"
                onClick={() => {
                  setshowFilterModal(true);
                  setCountRender((p) => p + 1);
                }}
              >
                Search Billno
              </Button>
            </div>
            <div onKeyDown={enterPress} style={{ marginTop: "10px" }}>
              <AdditionalDetails />
            </div>
            <div
              style={{
                textAlign: "right",
                position: "relative",
                marginTop: "15px",
              }}
              onKeyDown={enterPress}
            >
              <SaveCheckbox
                apikey={"isPrint"}
                displayLabel="Print GRN"
                editable
                defaultValue={false}
                key={modalcount} /// clear after save butoon date 27jun 2023
              />
            </div>
            <div
              style={{
                textAlign: "right",
                position: "relative",
                marginTop: "15px",
              }}
              onKeyDown={enterPress}
            >
              <SaveCheckbox
                apikey={"isPrintBarcode"}
                displayLabel="Print Barcode"
                editable
                defaultValue={false}
                key={modalcount} /// clear after save butoon date 27jun 2023
              />
            </div>
          </form>
        </Panel>
        {/* amol-temp - removed alignItems: "end" from sapace tag style 
        and added style to button tag present in Additional details panel */}

        <Panel header="Item Details" key="3">
          <Space
            style={{
              // alignItems:'end',    //nirmal-389 added css of showing horizonal all the input fields evenly
              display: "flex",
            }}
          >
            <form>
              <div style={{ display: "flex" }}>
                <div
                  style={{ zIndex: 2, position: "relative" }}
                  // onKeyDown={enterPress}  enter functionality remove
                >
                  <ItemSearch
                    forFlag={"P"}
                    returnValue={setSelectedItem}
                    supplierdata={supplierdata?.supplierrecno}
                    itemSearchRef={itemsearch}
                    //selection
                    dropdown
                    forPage={"GRN"}
                    defaultSelectedItem={getSelectedItem}
                    barcodeReturnValue={(e) => {
                      //{ start Pradeep task id -dc start }
                      console.log("New ROws Data", e);
                      // Write a function to add to existing Items and check only if it has serial number

                      if (e?.itemasbatch == 1) {
                        fetchItems
                          .addBarcodeScannedItemToTable(
                            rowData,
                            e,
                            "PIF",
                            igstappl,
                            pageDetails
                          )
                          .then((res) => {
                            // setRowData(res);
                            //  nirmal-purchaseinvoice ::: Solved the issue of deleting the entire item from the grid if the old barcode is entered.
                            if (res?.length == 0) {
                              setRowData(rowData);
                              // return
                            } else {
                              setRowData(res);
                              //setChange(change + 1);
                            }
                          })
                          .catch((err) => {
                            console.log("error", err);
                            alert(err);
                          });
                      } else {
                        setSelectedItem(e);
                      }
                    }}
                  />
                  <p>{getSelectedItem?.itemdescn}</p>
                </div>
                {getSelectedItem?.packingextrarecno > 0 &&
                pageDetails?.wantpackingextra ? (
                  <div
                    onKeyDown={enterPress}
                    style={{ marginTop: "30px", marginLeft: "10px" }}
                  >
                    <p>Box</p>
                    <input
                      value={box}
                      onChange={(e) => setBox(e.target.value)}
                      style={{ ...inputStyles }}
                      id="tr001-input-box"
                      type="number"
                    />
                  </div>
                ) : null}

                <div
                  onKeyDown={enterPress}
                  style={{ marginTop: "18px", marginLeft: "10px" }}
                >
                  <p>
                    Pcs<span style={{ color: "red" }}>*</span>
                  </p>
                  <input
                    value={pcs}
                    onChange={(e) => setPcs(e.target.value)}
                    style={{ ...inputStyles }}
                    id="tr001-input-box"
                    type="number"
                  />
                </div>
                {getSelectedItem?.wantbatchno ? (
                  <>
                    <div
                      onKeyDown={enterPress}
                      style={{ marginTop: "18px", marginLeft: "10px" }}
                    >
                      <p>Batch No.</p>
                      <input
                        value={batchNo}
                        onChange={(e) => setBatchNo(e.target.value)}
                        style={{ ...inputStyles, width: "200px" }}
                        id="tr001-input-batchno"
                      />
                    </div>
                   
                  </>
                ) : null}
                <div  onKeyDown={enterPress}> {pageDetails?.wantexpdate &&
                    pageDetails?.wantmanudate? (
                      <div style={{ marginTop: "25px", marginLeft: "10px" }}>
                        {" "}
                        <GRNDates
                          grnDate={grnDate}
                          mfgDate={mfgDate}
                          setMfgDate={setMfgDate}
                          expDate={expDate}
                          setExpDate={setExpDate}
                          render={render}
                          getSelectedItem={getSelectedItem}
                        />
                      </div>
                    ) : null}</div>

                <div
                  onKeyDown={enterPress}
                  style={{ marginTop: "43px", marginLeft: "15px" }}
                >
                  <Button
                    ref={btnRef}
                    className="btn item-search-btn"
                    // style={{ ...btnStyles }}
                    icon={<IoAperture size="1.2rem" />}
                    size="large"
                    onClick={() => {
                      if (!getSelectedItem.itemasbatch) {
                        addItemstoTable();
                      } else if (getSelectedItem.itemasbatch) {
                        if (batchNo && mfgDate && expDate) {
                          addItemstoTable();
                        } else {
                          alert("Please Enter Required Fields");
                        }
                      }

                      //setSupplierInvNo("");
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        if (!getSelectedItem.itemasbatch) {
                          addItemstoTable();

                        } else if (getSelectedItem.itemasbatch) {
                          if (batchNo && mfgDate && expDate) {
                            addItemstoTable();
                          } else {
                            alert("Please Enter Required Fields");
                          }
                        }
                      }
                      //setSupplierInvNo("");
                    }}
                  >
                    Add
                  </Button>
                  {/* <SaveCheckbox apikey={"isPrint"} displayLabel="Print PO" editable /> */}
                </div>
              </div>
            </form>
          </Space>
          {/* Table for Selection  */}
          <div
            style={{ zIndex: 0, position: "relative", margin: "24px auto 0" }}
          >
            {rowData.length > 0 && (
              <GrnTable
                rowData={rowData}
                setRowData={setRowData}
                igstappl={igstappl}
                grnDate={grnDate}
                supplierdata={supplierdata}
                pageDetails={pageDetails}
                setItemForSelection={setItemForSelection}
                setopenBatch={setopenBatch1}
                setopenBatchcount={setopenBatchcount}
                pageIndex={pageIndex}
              />
            )}
          </div>
        </Panel>
      </Collapse>
    </div>
  );
}

export default GrnPurchaseOrder;

const mainFrame = {
  width: "99vw",
  margin: "auto",
};

const topRowFilters = {
  gap: "24px",
  display: "flex",
  alignItems: "center",
};

// applying padding to align input rows inline
const callbackTextInputStyles = {
  display: "flex",
  flexWrap: "wrap",
  alignItems: "center",
  gap: "36px",
  paddingTop: "38px",
};

// add to table btn styles
const btnStyles = {
  display: "flex",
  alignItems: "center",
  gap: "8px",
  marginTop: "26px",
};

const inputStyles = {
  padding: "10px",
  border: "1px solid rgb(120,120,120)",
  width: "100px",
  fontSize: "16px",
  marginTop: "6px",
  borderRadius: "6px",
  height:"38px"
};

const grossAmtStyle = {
  textAlign: "right",
  border: "1px solid gray",
  padding: "4px 8px 4px 4px",
  fontSize: "18px",
  width: "120px",
  borderRadius: "8px",
  marginTop: "6px",
};

// amol-temp - value changes
const poNumberStyle = {
  // marginTop: "8px",
  border: "1px solid #dfdfdf",
  borderRadius: "8px",
  width: "180px",
  padding: "9px",
  fontSize: "16px",
  height: "39px",
};
// amol-temp - added button
const button = {
  marginTop: "28px",
  textAlign: "right",
  border: "1px solid #dfdfdf",
  height: "36px",
};

//~# getdomainsupplieritemlist api endpoint for Item name => pass payload as { supplierrecno : 78123 } for item name
//~# conversionfactor = boxes * conversionfactor + pieces = qty

import { TransactionProvider } from "./TransactionContext";

import TransactionScreen from "./TransactionScreen";

function TransactionUI(props) {
  return (
    <TransactionProvider>
      <TransactionScreen
        vouchertype={props.vouchertype}
        mode="ADD"
        voucherno="2200005"
        fastPath={props.fastPath}
      />
    </TransactionProvider>
  );
}



export default TransactionUI;

import { Spin } from "antd";
import React, { useContext, useEffect, useState, useRef } from "react";
import { AuthStore } from "../Auth/AuthStore";
import { getfastpathapi } from "../CommonControllers/ApiCalls";
import EditableCommonTable from "../CommonControllers/Components/TableComponent/EditableCommonTable";
import TableComponent from "../CommonControllers/Components/TableComponent/TableComponent";
import EditableTable from "../CommonControllers/Components/Tables/EditableTable";
import FilterComponents from "../CommonControllers/FiterComponent/FilterComponents";
import "../CommonControllers/global.css";
import useExecuteApi from "../CommonControllers/Hooks/useExecuteApi";
import { currentTabData } from "../CommonControllers/Variable";
import { TabStore } from "../TabsStructure/TabsStore";

const CommonMaster = ({ fastPath }) => {
  const tabStore = useContext(TabStore);
  const auth = useContext(AuthStore);
  const execute = useExecuteApi();
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);
  const [fastPathOptions, setFastPathOptions] = useState();

  const [pageIndex, setPageIndex] = useState();
  useEffect(() => {
    var tabsArray = tabStore.state.tabs;
    for (let i = 0; i < tabsArray?.length; i++) {
      if (tabsArray[i].name == fastPath) {
        setPageIndex(i);
        break;
      }
    }
  }, []);

  // -----------------table loading style start---------------
  const [showTable, setShowTable] = useState(true);
  useEffect(() => {
    setShowTable(false);

    setTimeout(() => {
      setShowTable(true);
    }, 200);
  }, [tabStore.state.tabs[pageIndex]?.apiResponse]);

  // -----------------table loading style end---------------

  async function getCommonMasterList() {
    execute.execute(pageIndex);
  }

  useEffect(() => {
    if (!tabStore.state.tabs[pageIndex]?.apiResponse) {
      if (
        (tabStore.state.tabs[pageIndex]?.apiResponse?.length <= 0 ||
          tabStore.state.tabs[pageIndex]?.apiResponse?.length == undefined) &&
        tabStore.state.tabs[pageIndex]?.callapipageload == true
        // smk 5-1-2023 want to call api at page load added dont want for tenantitem ms201
      ) {
        getCommonMasterList();
      }
    }
  }, [pageIndex]);

  useEffect(() => {
    if (pageIndex >= 0) {
      const payload = {
        tenantrecno: auth?.state?.userdata?.tenantrecno,
        domainrecno: auth?.state?.userdata?.domainrecno,
        userrolerecno: auth?.state?.userdata?.userrolerecno,
        domainuserrecno: auth.state.userdata.recno,
        fastpath: tabStore.state.tabs[pageIndex]?.name,
      };
      // On component mount this api is called and filterOptions are set for that fast path
      getfastpathapi(payload).then((res) => {
        if (res?.Success) {
          setFastPathOptions(res?.Message);
          console.log("Message", res.Message);
        }
      });
    }
  }, [pageIndex]);
  return (
    <div style={{ padding: "5px" }}>
      <FilterComponents filterOption={fastPathOptions?.filterOptions} />
      {tabStore.state.tabs[pageIndex]?.executeLoading === true ||
      tabStore.state.tabs[pageIndex]?.saveLoading === true ? (
        <div style={{ ...loaderStyle }}>
          <Spin size="large" />
        </div>
      ) : (
        <div>
          {tabStore.state.tabs[pageIndex]?.apiResponse?.columnsinfo?.length >
          0 ? (
            tabStore.state.tabs[pageIndex].mode == "edit" && showTable ? (
              <>
                <EditableTable
                  pageIndex={pageIndex}
                  columnsArray={
                    tabStore.state.tabs[pageIndex]?.apiResponse?.columnsinfo
                  }
                  rowsArray={
                    tabStore.state.tabs[pageIndex]?.apiResponse?.Message
                  }
                  selection={fastPathOptions?.selectall}
                  // selection={["changesalerateinbill", "changepurrateinbill", "selected", "wantbatchno"]}
                  isAdd={fastPathOptions?.isadd}
                  isEdit={fastPathOptions?.isedit}
                />
              </>
            ) : // -----------------table loading style start---------------
            showTable ? (
              <>
                <TableComponent pageIndex={pageIndex} />
              </>
            ) : (
              <div style={{ ...loaderStyle }}>
                <Spin size="large" />
              </div>
            )
          ) : // -----------------table loading style end---------------
          null}
        </div>
      )}
    </div>
  );
};

export default CommonMaster;
const loaderStyle = {
  height: "max-content",
  width: "max-content",
  display: "flex",
  alignContent: "center",
  margin: "50px auto",
};

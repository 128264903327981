import errorSound from "../sounds/stop-13692.mp3";
import successSound from "../sounds/achive-sound-132273.mp3";
import axios from "axios";
import { notification } from "antd";
export const getToday = () => {
  return (
    new Date().getFullYear().toString() +
    ("0" + (new Date().getMonth() + 1).toString()).slice(-2) +
    ("0" + new Date().getDate().toString()).slice(-2)
  );
};

export const getCurrentTime = () => {
  var currentDate = new Date();

  // Get current time in hours, minutes, and seconds
  var hours = currentDate.getHours();
  var minutes = currentDate.getMinutes();
  var seconds = currentDate.getSeconds();

  // Format the time to have leading zeros if less than 10
  hours = hours < 10 ? "0" + hours : hours;
  minutes = minutes < 10 ? "0" + minutes : minutes;
  seconds = seconds < 10 ? "0" + seconds : seconds;
  return hours.toString() + minutes.toString() + seconds.toString();
};

export const updateAddArray = (existingArray, newRow, primaryKey) => {
  var arrayIndex = -1;
  if (existingArray.length > 0) {
    var newArray = existingArray.map((item, index) => {
      if (item[primaryKey] == newRow[primaryKey]) {
        arrayIndex = index;
      }
    });
    if (arrayIndex > -1) {
      var removedArray = existingArray.splice(arrayIndex, 1);
    }
  }

  existingArray = [...existingArray, newRow];
  return existingArray;
};

export const dateFormatter = (funDate) => {
  if (/[a-zA-Z]/.test(funDate)) {
    return funDate;
  } else {
    if (funDate == 0) {
      return null;
    }
    if (!funDate) {
      return null;
    }
    let date = funDate?.toString() || "";
    let formattedDate = "";

    // smk 16-2-2023 added here so that two times its not added
    if (date.includes("/")) {
      formattedDate = date;
    } else {
      formattedDate =
        date.length >= 7
          ? date.slice(6, 8) + "/" + date.slice(4, 6) + "/" + date.slice(0, 4)
          : // : date.slice(4, 6) + "/" + date.slice(0, 4);
          // nirmal-purchaseinvoice  removed slash from the date if the expiry date.length is less than 7
          date.length === 4
          ? (() => {
              const year = "20" + date.slice(2);
              const month = date.slice(0, 2);
              const daysInMonth = new Date(
                Number(year),
                Number(month),
                0
              ).getDate();
              return `${daysInMonth}/${month}/${year}`;
            })()
          : `${date.slice(0, 2)}/${date.slice(2, 4)}/${date.slice(4)}`;
    }
    return formattedDate;
  }
};

export const removeByAttribute = (arr, attr, value) => {
  var i = arr.length;
  while (i--) {
    if (
      arr[i] &&
      arr[i].hasOwnProperty(attr) &&
      arr.length > 0 &&
      arr[i][attr] === value
    ) {
      arr.splice(i, 1);
    }
  }
  return arr;
};

export const datedisplay = (date, initial) => {
  // Initial true meaning the day should be the first day of the month that is 01
  // Initial false meaning hte day should be last day of the month that is 30/31/28/29
  let trdate = date.toString();
  if (trdate.length == 6) {
    const dd = trdate.slice(0, 2);
    const mm = trdate.slice(2, 4);
    const yy = trdate.slice(4, 6);

    if (Number(mm) > 12) {
      return false;
    } else {
      var dispDate = dd.toString() + "/" + mm + "/" + "20" + yy;
      var dataDate = "20" + yy.toString() + mm.toString() + dd.toString();
      return { dispDate: dispDate, dataDate: dataDate };
    }
  } else if (trdate.length == 8) {
    const dd = trdate.slice(0, 2);
    const mm = trdate.slice(2, 4);
    const yy = trdate.slice(4, 8);
    if (Number(mm) > 12) {
      return false;
    } else {
      var dispDate = dd.toString() + "/" + mm + "/" + yy;
      var dataDate = yy.toString() + mm.toString() + dd.toString();
      return { dispDate: dispDate, dataDate: dataDate };
    }
  } else if (trdate.length == 10) {
    const dd = trdate.slice(0, 2);
    const mm = trdate.slice(3, 5);
    const yy = trdate.slice(6, 10);
    if (Number(mm) > 12) {
      return false;
    } else {
      var dispDate = dd.toString() + "/" + mm + "/" + yy;
      var dataDate = yy.toString() + mm.toString() + dd.toString();
      return { dispDate: dispDate, dataDate: dataDate };
    }
  } else if (trdate.length == 4) {
    const mm = trdate.slice(0, 2);
    const yy = trdate.slice(2, 4);
    if (Number(mm) > 12) {
      return false;
    } else {
      let lastDate = new Date("20" + yy, mm, 0).getDate();
      if (initial) {
        lastDate = "01";
      }

      var dispDate = lastDate.toString() + "/" + mm + "/" + "20" + yy;
      var dataDate = "20" + yy.toString() + mm.toString() + lastDate.toString();
      return { dispDate: dispDate, dataDate: dataDate };
    }
  } else {
    return { dispDate: "", dataDate: null };
  }
};

// Check if the variable is an Array
export const isArray = (a) => {
  return !!a && a.constructor === Array;
};

export function addDays(dateString, daysToAdd) {
  var daysAdd = daysToAdd ? daysToAdd : 0;
  if (dateString.toString()?.length == 8) {
    dateString = dateString.toString();
    var year = dateString.substr(0, 4);
    var month = dateString.substr(4, 2) - 1;
    var day = dateString.substr(6, 2);

    var date = new Date(year, month, day);
    date.setDate(date.getDate() + daysAdd);

    var newYear = date.getFullYear();
    var newMonth = (date.getMonth() + 1).toString().padStart(2, "0");
    var newDay = date.getDate().toString().padStart(2, "0");

    return newYear + newMonth + newDay;
  } else {
    return 0;
  }
}

export function reduceDays(dateString, daysToSubtract) {
  if (dateString.toString()?.length == 8) {
    dateString = dateString.toString();
    var year = dateString.substr(0, 4);
    var month = dateString.substr(4, 2) - 1;
    var day = dateString.substr(6, 2);

    var date = new Date(year, month, day);
    date.setDate(date.getDate() - daysToSubtract);

    var newYear = date.getFullYear();
    var newMonth = (date.getMonth() + 1).toString().padStart(2, "0");
    var newDay = date.getDate().toString().padStart(2, "0");
    return newYear + newMonth + newDay;
  } else {
    return 0;
  }
}

export function addMonths(dateString, months) {
  if (dateString.toString()?.length == 8) {
    dateString = dateString.toString();
    var year = dateString.substr(0, 4);
    var month = dateString.substr(4, 2) - 1;
    var day = dateString.substr(6, 2);

    var date = new Date(year, month, day);
    date.setMonth(date.getDate() + months);

    var newYear = date.getFullYear();
    var newMonth = (date.getMonth() + 1).toString().padStart(2, "0");
    var newDay = date.getDate().toString().padStart(2, "0");
    return newYear + newMonth + newDay;
  } else {
    return 0;
  }
}
export function reduceMonths(dateString, months) {
  if (dateString.toString()?.length == 8) {
    dateString = dateString.toString();
    var year = dateString.substr(0, 4);
    var month = dateString.substr(4, 2) - 1;
    var day = dateString.substr(6, 2);

    var date = new Date(year, month, day);
    date.setMonth(date.getDate() - months);

    var newYear = date.getFullYear();
    var newMonth = (date.getMonth() + 1).toString().padStart(2, "0");
    var newDay = date.getDate().toString().padStart(2, "0");
    return newYear + newMonth + newDay;
  } else {
    return 0;
  }
}

export function removeFormatCurrency(amountstr) {
  if (amountstr != null) return amountstr.toString().replace(/,/g, "");
  else return null;
}
export function formatCurrency(amount) {
  const formatter = new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
    minimumFractionDigits: 2,
  });
  if (amount == 0) {
    return null;
  } else {
    return formatter.format(amount).replace("₹", "");
    // return formatter.format(amount).replace(',', '');
  }
}
export function formatCurrency3(amount) {
  const formatter = new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
    minimumFractionDigits: 3,
  });
  return formatter.format(amount).replace("₹", "");
}

export function convertAmountToWords(amount) {
  // Define arrays with word representations of numbers and units
  const numberWords = [
    "",
    "one",
    "two",
    "three",
    "four",
    "five",
    "six",
    "seven",
    "eight",
    "nine",
    "ten",
    "eleven",
    "twelve",
    "thirteen",
    "fourteen",
    "fifteen",
    "sixteen",
    "seventeen",
    "eighteen",
    "nineteen",
  ];
  const tensWords = [
    "",
    "",
    "twenty",
    "thirty",
    "forty",
    "fifty",
    "sixty",
    "seventy",
    "eighty",
    "ninety",
  ];
  const unitWords = ["", "thousand", "lakh", "crore"];

  let words = "";

  // Check for negative amount and convert it to positive
  if (amount < 0) {
    words += "minus ";
    amount = Math.abs(amount);
  }

  // Split the amount into groups of 2 digits (Crore, Lakh, Thousand, Hundred)
  const groups = [];
  while (amount > 0) {
    groups.push(amount % 100);
    amount = Math.floor(amount / 100);
  }

  // Convert each group of 2 digits to words and append unit words
  for (let i = groups.length - 1; i >= 0; i--) {
    let groupWords = "";

    if (groups[i] > 19) {
      groupWords += tensWords[Math.floor(groups[i] / 10)] + " ";
      groups[i] %= 10;
    }

    if (groups[i] > 0) {
      groupWords += numberWords[groups[i]] + " ";
    }

    groupWords = groupWords.trim();

    if (groupWords !== "") {
      groupWords += " " + unitWords[i];
    }

    words += groupWords + " ";
  }

  // Add 'rupees' at the end and return the final words
  words += "rupees";
  return words;
}

export function getDaysBetweenDates(date1, date2) {
  // Parse the date strings into Date objects
  const year1 = date1.substring(0, 4);
  const month1 = date1.substring(4, 6) - 1;
  const day1 = date1.substring(6, 8);
  const dateObject1 = new Date(year1, month1, day1);

  const year2 = date2.substring(0, 4);
  const month2 = date2.substring(4, 6) - 1;
  const day2 = date2.substring(6, 8);
  const dateObject2 = new Date(year2, month2, day2);

  // Calculate the difference between the dates in milliseconds
  const differenceInMs = dateObject2 - dateObject1;

  // Convert the difference to days and return
  const daysDifference = Math.floor(differenceInMs / (1000 * 60 * 60 * 24));
  return daysDifference;
}

export function amountToWorlds(amount) {
  const words = [
    { value: 10000000, text: "कोटी" },
    { value: 100000, text: "लाख" },
    { value: 1000, text: "हजार" },
    { value: 100, text: "शंभर" },
    { value: 1, text: "" },
  ];

  const marathiNumbers = ["०", "१", "२", "३", "४", "५", "६", "७", "८", "९"];

  let result = "";
  let remainder = amount.toFixed(2) * 100;

  for (let i = 0; i < words.length; i++) {
    const quotient = Math.floor(remainder / (words[i].value * 100));
    if (quotient !== 0) {
      result += marathiNumbers[quotient] + " " + words[i].text + " ";
    }
    remainder %= words[i].value * 100;
  }

  if (result === "") {
    result = marathiNumbers[0] + " ";
  }

  const paise = Math.floor(remainder);
  result +=
    marathiNumbers[paise / 10] + " " + marathiNumbers[paise % 10] + " पैसे";

  return result;
}

// export const enterPress = (e) => {
//   if (e.keyCode === 13) {
//     e.preventDefault();
//     const form = e.target.form;
//     const index = Array.prototype.indexOf.call(form, e.target);

//     if (form.elements[index + 1]) {
//       form.elements[index + 1].focus();
//     }
//   }
// };
export const enterPress = (e) => {
  /// when the button is disable also we have make to move the next elements

  if (e.keyCode === 13) {
    e.preventDefault();
    const form = e.target.form;

    const index = Array.prototype.indexOf.call(form, e.target);

    // Find the next focusable element that is not disabled
    let nextIndex = index + 1;
    while (form.elements[nextIndex] && form.elements[nextIndex].disabled) {
      nextIndex++;
    }

    // Focus on the next focusable and non-disabled element
    if (form.elements[nextIndex]) {
      form.elements[nextIndex].focus();
    }
  }
};

export function convertToBoxAndPieces(
  totalQuantity,
  conversionFactor,
  packingextrarecno
) {
  if (conversionFactor == 0) {
    return [0, totalQuantity];
  }

  var piecesPerBox = conversionFactor;
  var boxes = Math.floor(totalQuantity / piecesPerBox);
  var pieces = totalQuantity % piecesPerBox;
  //{Pradeep task id -337}
  //return [boxes, pieces];
  //{Pradeep task id -dc }
  ///packingextrarecno===0 logic date 14 june2023 rajendra
  if (packingextrarecno > 0) {
    //While adding product, if packingextrarecno >0 then add qty in pieces input field of grid
    return [boxes, pieces];
  } else {
    // While adding product, if packingextrarecno = 0 then add qty in pieces input field of grid
    return [0, totalQuantity];
  }
  //{end               end }
}

export function moveItemToTop(items, id, filterID) {
  // Find the index of the item with the specified id
  let index = items.findIndex((item) => item[filterID] === id);

  // If the item is already at the top of the array, do nothing
  if (index === 0) {
    return false;
  }

  // Remove the item from its current position in the array
  let item = items.splice(index, 1)[0];

  // Insert the item at the beginning of the array
  items.unshift(item);

  return items;
}

export function sortByTrDate(arr, key, sortType) {
  if (sortType) {
    arr.sort(function (a, b) {
      var dateA = new Date(a[key]);
      var dateB = new Date(b[key]);
      return dateA - dateB;
    });
  } else {
    arr.sort(function (a, b) {
      var dateA = new Date(b[key]);
      var dateB = new Date(a[key]);
      return dateA - dateB;
    });
    return arr;
  }
  return arr;
}

export function isValidDate(dateString) {
  // Check if the input is exactly 8 digits
  if (dateString.length !== 8) {
    return false;
  }

  // Extract year, month, and day components from the input string
  var year = parseInt(dateString.slice(0, 4));
  var month = parseInt(dateString.slice(4, 6));
  var day = parseInt(dateString.slice(6, 8));

  // Create a new Date object with the extracted components
  var date = new Date(year, month - 1, day);

  // Check if the extracted components match the input values
  // and the resulting Date object is a valid date
  return (
    date.getFullYear() === year &&
    date.getMonth() === month - 1 &&
    date.getDate() === day &&
    !isNaN(date)
  );
}

export function generateErrorSound() {
  new Audio(errorSound).play();
}

export function generateSucessSound() {
  new Audio(successSound).play();
}
export function getUniqueElements(array1, array2, property) {
  const uniqueElements = [];

  // Combine both arrays if they are defined, otherwise use empty arrays
  const combinedArray = [...(array1 || []), ...(array2 || [])];
  // Use a Set to keep track of unique values
  const uniqueSet = new Set();

  // Iterate through the combined array
  for (const element of combinedArray) {
    const value = element[property];

    // Check if the value is not in the set (i.e., unique)
    if (!uniqueSet.has(value)) {
      uniqueSet.add(value);
      uniqueElements.push(element);
    }
  }

  return uniqueElements;
}

export const deSelectPreviuos = (array1, array2, property) => {
  // Check if property is defined and not empty
  if (!property) {
    console.error("Property is required for comparison.");
    return array1; // Return array1 as is if property is not provided
  }

  // Create a set of unique values from array2 based on the specified property
  const uniqueValues = new Set(array2.map((item) => item[property]));

  // Filter out elements from array1 where the property value is present in uniqueValues
  const filteredArray = array1.filter(
    (item) => !uniqueValues.has(item[property])
  );

  return filteredArray;
};
// excel download function

export const downloadExcel = async (urlbase, payload) => {
  try {
    const response = await axios.post(
      urlbase,
      payload,
      { responseType: "blob" } // Ensure response is treated as binary data
    );
    let filename = `${payload.trdate?payload.trdate:payload.fromdate}_${payload.domainrecno}_${payload.fastpath}`;
    console.log("FYGVUHBIJN", payload);
    const url = window.URL.createObjectURL(new Blob([response.data]));

    const a = document.createElement("a");
    a.href = url;
    a.download = `${filename}.xlsx`; // Set the filename to myexportedfile.xlsx
    a.click();

    window.URL.revokeObjectURL(url);
    notification.success({
      message: `Success`,
      placement: "top",
      description: "Report generated Successfully",
      duration: 3,
      style: { background: "#a7efb6" },
      //style: { background: "#a7efa7" },
    });
  } catch (error) {
    console.error("Error downloading Excel:", error);
    // Handle error here
  }
};


export const CheckResigister=(fastPath)=>{
  let arr=['RP001A',"RP048" ,'RP002A', 'RP003A', 'RP004A', 'RP011A', 'RP012A', 'RP013A', 'RP014A',  'RP039A']
  if(arr.includes(fastPath))return true;
}

export const addMonthsAndDays = (dateStr, months = 0, days = 0) => {
  // Parse the date string (YYYYMMDD)
  const year = parseInt(dateStr.substring(0, 4), 10);
  const month = parseInt(dateStr.substring(4, 6), 10) - 1; // Month is 0-indexed
  const day = parseInt(dateStr.substring(6, 8), 10);

  // Create a Date object from the date
  const date = new Date(year, month, day);

  // Check if the date is valid
  if (!isNaN(date.getTime())) {
    // Add the specified number of months
    if (months) {
      date.setMonth(date.getMonth() + months);
    }

    // Add the specified number of days
    if (days) {
      date.setDate(date.getDate() + days);
    }

    // Format the new date as YYYYMMDD
    const newYear = date.getFullYear();
    const newMonth = String(date.getMonth() + 1).padStart(2, '0'); // Month is 0-indexed
    const newDay = String(date.getDate()).padStart(2, '0');

    // Return the formatted new date
    return `${newYear}${newMonth}${newDay}`;
  } else {
    console.error("Invalid date provided.");
    return ''; // Return an empty string for invalid dates
  }
};
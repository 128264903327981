import React, { useContext, useEffect, useState } from "react";
import { Button, Input, Modal } from "antd";
import { TabStore } from "../../TabsStructure/TabsStore";
import { AuthStore } from "../../Auth/AuthStore";
import { dateFormatter } from "../../CommonControllers/AppFunction";
import { RxCross2 } from "react-icons/rx";
import { Checkbox } from "antd";

const ScannedItem = ({
  modal,
  setModal,
  data,
  openItem,
  setOpenItem,
  rowData,
  setRowData,
  renderer,
  setRenderer,
  setValidator,
  supplierrecno,
  forFlag,
}) => {
  //~^ These values are calculated for the UI display only, no func can manipulate these values
console.log("Rowdata===>1",rowData)
  const [scanData, setScanData] = useState(data);
  const [serial, setSerialno] = useState(null);

  const [barcode, setBarcode] = useState([]);
  const [barcodeData, setBarcodeData] = useState([]);

  const [acceptedBoxes, setAcceptedBoxes] = useState(
    scanData?.packingextraqtyaccepted ?? 0
  );
  const [acceptedPieces, setAcceptedPieces] = useState(
    scanData?.packingqtyaccepted ?? 0
  );
  const [count, setCount] = useState(0);
  const tabs = useContext(TabStore);
  const auth = useContext(AuthStore);
  const [rowDatamodify, setModifyrow] = useState(rowData);
  const[selectall,setSelectedItem]=useState(true)
/// here the logic for togg;e the select all 
const [toggle,setToggle]=useState(true)

useEffect(()=>{
 setTimeout(()=>{
  const allSelected = scanData.every(item => item.selected==true);

// If allSelected is true, then return true; otherwise, return false
setSelectedItem(allSelected)
 },1000)
},[scanData])
  useEffect(() => {
    setScanData(data);
    let itmbatcharr = [];
    // Flatten the itembatcharray from each row into itmbatcharr
    for (let i = 0; i < rowData.length; i++) {
      let itemBatches = rowData[i]?.itembatcharray;

      itmbatcharr = itmbatcharr?.concat(itemBatches);
    }
    const updatedRowData = rowData?.map((ele) => {
      if (
        ele?.itembatcharray &&ele?.batcharray?.length>0&&
        ele?.batcharray[0]?.serialno === scanData[0]?.serialno
      ) {
        // Update the selection property for every item in itembatcharray
        ele.itembatcharray = ele?.itembatcharray?.map((item) => ({
          ...item,
          selected: true,
        }));
      }
      return ele;
    });

    const itmbatchSerialnos = new Set(itmbatcharr.map((item) => item.serialno));

    const updatedScanData = scanData.map((item) => {
      if (itmbatchSerialnos.has(item?.serialno)) {
        return { ...item, selected: true };
      }

      return item;
    });

    // Update the state with the new scanData
    setScanData(updatedScanData);
    setRowData(updatedRowData);
  }, [data]);

  let carryOver = Math.floor(scanData?.packingqty / scanData?.conversionfactor);

  let boxes = Number(scanData?.packingextraqty) + Number(carryOver);

  // let pieces =
  //   scanData?.packingqty >= scanData?.conversionfactor
  //     ? Math.floor(scanData?.packingqty % scanData?.conversionfactor)
  //     : scanData?.packingqty;

  let pieces = scanData?.free ? scanData?.qty + scanData?.free : scanData?.qty;
  if (forFlag == "PR" || forFlag == "SR") {
    pieces = scanData?.returnfree
      ? scanData?.returnqty + scanData?.returnfree
      : scanData?.returnqty;
  }

  const handleBarcodeDelete = (code) => {
    // Create a deep copy of the rowData to avoid directly mutating the state
    const updatedRowData = rowData.map((ele) => {
      if (ele.itembatcharray) {
        // Check if the current element's itembatcharray contains the item with the matching serialno
        const updatedItemBatchArray = ele?.itembatcharray.filter(
          (el) => el.serialno !== code.serialno
        );

        // Assign the updated itembatcharray back to the element
        ele.itembatcharray = updatedItemBatchArray;
      }
      return ele;
    });

    // Extract and set scanData for the specific item
    const filteredData = scanData?.filter(
      (barcode) => barcode.serialno !== code.serialno /// task id -367 rajendra delete problem
    );

    // If there's updatedScanData, set scanData to its batcharray, otherwise set it to an empty array
    setScanData(filteredData);

    // Update the state with the updated rowData
    setModifyrow(updatedRowData);
  };

  // const handleOk = () => {
  //   setModal(false);
    
  //   const filteredData = rowData.map((item) => {
  //     if (item.itembatcharray) {
       
  //       if (item.itembatcharray[0].serialno == scanData[0].serialno) {
  //         let filter = item?.itembatcharray.filter((it) => it.selected == true);
  //         console.log("RRRRR",filter)
  //         item.itembatcharray = filter;
  //       }
  //     }
  //     return item;
  //   });
  //   console.log("rowDatamodify", filteredData);
  //   setRowData(filteredData);
  //   setModal(!modal);
  // };
  const handleOk = () => {
    setModal(false);
  
    const scanSerialNos = scanData.map((scanItem) => scanItem.serialno);
  
    const filteredData = rowData.map((item, i) => {
      if (item?.itembatcharray) {
        const filter = item?.itembatcharray?.filter((batchItem) => {
          return scanSerialNos.some((serialNo) =>
            batchItem.serialno.includes(serialNo)&&batchItem?.selected
          );
        });
  if(filter.length>0){
    item.itembatcharray = filter;
  }
  else{
    item.itembatcharray=item.itembatcharray
  }
        
      }
      return item;
    });
  
   
    setRowData(filteredData);
    setModal(!modal);
  };
  
  const selectAll = (e) => {
    // Logic for updating scanData
    setSelectedItem(e.target.checked)
    const modifiedScanData = scanData.map((it) => ({
      ...it,
      selected: e.target.checked,
    }));

    setScanData(modifiedScanData);

    const updatedRowData = rowData.map((ele) => {
      if (
        ele?.itembatcharray &&
        ele?.batcharray[0]?.serialno === scanData[0]?.serialno
      ) {
        // Update the selection property for every item in itembatcharray
        ele.itembatcharray = ele?.itembatcharray?.map((item) => ({
          ...item,
          selected: e.target.checked,
        }));
      }
      return ele;
    });

    
    setModifyrow(updatedRowData);
  };

  const selectItem = (e, code) => {
    const updatedRowData = rowData.map((ele) => {
      if (ele?.itembatcharray) {
        const updatedItemBatchArray = ele?.itembatcharray?.map((el) => {
          if (el?.serialno === code?.serialno) {
            // If the serialno exists, update the item
            return {
              ...el,
              selected: el.selected ? !el.selected : true,
            };
          }
    
          return el;
        });
    
        let index = ele.itembatcharray.findIndex((el) => el.serialno === code.serialno);
        let batchind=ele.batcharray.findIndex((el)=> el.serialno === code.serialno)
   
        if (index === -1 && e.target.checked&&batchind!=-1) {
          // If the serialno doesn't exist and the checkbox is checked, insert the item
      
          ele.itembatcharray = [...ele.itembatcharray, { ...code,selected:e.target.checked }];
        } else {
          // If the serialno exists, update the item in the array
          ele.itembatcharray = updatedItemBatchArray;
        }
      }
    
      return ele;
    });
    
    // Rest of your code...
    

    let updated = scanData.map((el) => {
      if (el.serialno == code.serialno) {
        return { ...el, selected: e.target.checked };
      } else {
        return el;
      }
    });

    setScanData(updated);
    
    setModifyrow(updatedRowData);
  };

  return (
    <div>
      <Modal
        open={modal}
        maskClosable={false}
        onCancel={() => setModal(!modal)}
        onOk={() => handleOk()}
        width={786}
        // key={modal}
        style={{ top: 0 }}
        title={scanData?.label ?? scanData?.itemdescn}
        closable={false}
      >
        <div className="itemsMainBox">
          <div>
            <label>Select All</label>
            <input
            key={selectall}
            checked={selectall}
              type="checkbox"
              style={{ padding: "10px" }}
              onChange={(e) => selectAll(e)}
            ></input>
          </div>
          <div className="barcodeTableWrapper">
            {data?.length >= 0 && (
              <table className="barcodeTable">
                <thead>
                  <tr>
                    <th>Barcode</th>
                    <th>Serial No</th>
                    <th>DC No</th>
                    <th>Mfg Date</th>
                    <th>Exp Date</th>
                    <th>Delete</th>
                    
                  </tr>
                </thead>
                <tbody>
                  {scanData?.map((_barcode, i) => {
                    return (
                      <tr
                        style={{
                          backgroundColor: _barcode.deleted ? "pink" : "white",
                        }}
                      >
                        <td>{_barcode?.itembarcode}</td>
                        <td>{_barcode?.serialno}</td>
                        <td>{_barcode?.docno}</td>
                        <td>{dateFormatter(_barcode?.manudate)}</td>
                        <td>{dateFormatter(_barcode?.expdate)}</td>
                        <td>
                          <>
                            {" "}
                            {/* <Button
                              size="medium"
                              danger
                              type="primary"
                              shape="circle"
                              onClick={() => handleBarcodeDelete(_barcode)}
                            >
                              <RxCross2 style={{ margin: "4px 0 0 1px" }} />
                            </Button> */}
                            <input
                              type="checkbox"
                              style={{}}
                              checked={_barcode?.selected}
                              onChange={(e) => selectItem(e, _barcode)}
                            />
                          </>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </Modal>
    </div>
  );
};
export default ScannedItem;
const barcodeIp = {
  // width: "46%",
  margin: "18px 0",
  display: "flex",
  gap: "24px",
};

const accAssest = {
  display: "flex",
  alignItems: "center",
  boxSizing: "border-box",
  gap: "12px",
};

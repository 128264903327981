import React from 'react';
import './CustomButton.css';

function CustomButton({ text, clickHandler, type }) {
  return (
    <button onClick={() => (clickHandler ? clickHandler() : null)} type={type} className={`${type}Btn`}>
      {text}
    </button>
  );
}

export default CustomButton;

import { Radio } from "antd";
import React, { useEffect, useState } from "react";
import useExecute from "../../Hooks/useExecute";

function ExecuteRadioBtn({
  options,
  defaultValue,
  apikey,
  displayLabel,
  returnValue,
}) {
  const [value, setValue] = useState();
  const execute = useExecute();

//   Set Default / Selected Value
useEffect(() => {
    if (defaultValue) {
      if (execute.getValue(apikey)) {
        setValue(execute.getValue(apikey));
      } else if (execute.getValue(apikey) == "") {
        setValue(execute.getValue(apikey));
      } else {
        setValue(defaultValue);
        execute.updateValue(apikey, defaultValue);
      }
    } else {
      if (execute.getValue(apikey)) {
        setValue(execute.getValue(apikey));
      }
    }
  }, [defaultValue]);

  const onChange = ( {target: { value }} ) => {
    setValue(value)
    if (returnValue) {
        returnValue(value)
    }
    execute.updateValue(apikey, value)
  }

  return (
    <div>
        <label>{displayLabel}</label>
      <Radio.Group
        // options={options}
        onChange={onChange}
        value={value}
        optionType="button"
        buttonStyle="solid"

      >
        {
          options.map((item) => {
            return (
              <Radio.Button
              style={{ zIndex : 0}}
              value={item.value}>
                {item.label}
              </Radio.Button>
            )
          })
        }
      </Radio.Group>
    </div>
  );
}

export default ExecuteRadioBtn;

import React, { useContext } from "react";
import { getInputValue, updateValue } from "../../TabsStructure/tabFunctions";
import { TabStore } from "../../TabsStructure/TabsStore";
import { Divider } from "antd";

function InputComponent({
  id,
  className,
  type,
  placeholder,
  apiKey,
  change,
  defaultValue,
  style,
  required,
  displayLabel,
  alignment,
}) {
  const tabStore = useContext(TabStore);

  const value = getInputValue(tabStore, apiKey, defaultValue);

  const onChange = (e) => {
    document.getElementById(id).value = e.target.value;
  };

  var className = "inputBox" + " " + className;

  function updatedValue(e) {
    console.log("E", e.target.value);

    console.log("tabStore in input",tabStore)
    const inputValue = e.target.value;
    updateValue(tabStore, apiKey, inputValue);
  }

  const mainFrame = {
    display: "flex",
    alignItems: "center",
    flexDirection: alignment,
    gap: alignment && "8px",
    textAlign: "start",
  };

  return (
    <>
      <div style={{ ...mainFrame }}>
        {!alignment ? (
          <p>{displayLabel}</p>
        ) : (
          <p style={{ width: "100%" }}>{displayLabel}</p>
        )}
        <div style={{ flexGrow: 1 }}>
          {change ? (
            <input
              id={id}
              className={className}
              style={{ ...style }}
              type={type}
              placeholder={placeholder}
              onChange={updatedValue}
              value={value}
              required={required}
              defaultValue={defaultValue}
            />
          ) : (
            <input
              className={className}
              style={style}
              id={id}
              type={type}
              placeholder={placeholder}
              onBlur={updatedValue}
              onChange={onChange}
              value={value}
              required={required}
              defaultValue={defaultValue}
            />
          )}
        </div>
      </div>
    </>
  );
}

export default InputComponent;

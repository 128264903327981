import React, { useContext, useEffect, useReducer } from "react";
import { AuthStore } from "./AuthStore";
import Login from "./Login";
import {
  tabStoreinitialState,
  TabStore,
  tabStorereducer,
} from "../TabsStructure/TabsStore";

import TabStructure from "../TabsStructure/TabStructure";
import { notification } from "antd";
function Auth() {
  const [api, contextHolder] = notification.useNotification();

  const auth = useContext(AuthStore);
  const [tabStoreState, dispatchTabStoreState] = useReducer(
    tabStorereducer,
    tabStoreinitialState
  );



  useEffect(() => {
    auth.dispatch({ type: "CONTEXT_HOLDER", contextHolder: api });
  }, []);
  const base = `data:image/png;base64,${auth?.state?.userdata?.background}`

  return (
    <div>
      {contextHolder}
      {auth.state.isLoggedIn === false ? (
        <Login />
      ) : (
        <TabStore.Provider
          value={{ state: tabStoreState, dispatch: dispatchTabStoreState }}
        >

          <div style={{
            backgroundImage: `url(${base})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            height: '100vh',
            
          }}
          >
            <TabStructure />
          </div>




        </TabStore.Provider>
      )}
    </div>
  );
}

export default Auth;

import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { baseUrl } from '../../CommonControllers/Urls';
import { TabStore } from '../../TabsStructure/TabsStore';

const ExcelDropdown = ({ returnValue }) => {
  const [files, setFiles] = useState([]);
  const [selectedFile, setSelectedFile] = useState('');
  const tabs = useContext(TabStore);
  
  const fetchData = () => {
    axios.post(`${baseUrl}listofexcelfiles/`)
      .then(response => {
        console.log("Files download", response.data.Message);
        setFiles(response.data.Message);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  };

  useEffect(() => {
    // Initial fetch
    fetchData();
  }, []); // Empty dependency array means this effect runs once after the initial render

  useEffect(() => {
    // Fetch when excelUpdate is true
    if (tabs.state.tabs[tabs.state.tabIndex]?.excelUpdate) {
      fetchData();
      tabs.dispatch({
        type: "UPDATE_DATA",
        fieldType: "excelUpdate",
        text: false,
      });
    }
  }, [tabs.state.tabs[tabs.state.tabIndex]?.excelUpdate, tabs, tabs.state.tabIndex]); // Dependencies to ensure effect runs correctly

  const handleChange = (event) => {
    setSelectedFile(event.target.value);
    returnValue(event.target.value);
  };

  return (
    <div style={{ marginBottom: '20px', fontFamily: 'Arial, sans-serif' }}>
      <label htmlFor="fileDropdown" style={{ marginRight: '10px', fontSize: '18px', color: '#333' }}>Select File:</label>
      <select
        id="fileDropdown"
        value={selectedFile}
        onChange={handleChange}
        style={{
          padding: '8px',
          borderRadius: '5px',
          border: '1px solid #ccc',
          backgroundColor: '#f9f9f9',
          boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
          cursor: 'pointer',
          outline: 'none',
          minWidth: '200px',
          fontSize: '14px',
          color: '#555'
        }}
      >
        <option value="" style={{ color: '#999' }}>Select...</option>
        {files?.map((file, index) => (
          <option key={index} value={file} style={{ color: '#333' }}>{file}</option>
        ))}
      </select>
    </div>
  );
};

export default ExcelDropdown;

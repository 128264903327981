import React from "react";
import SaveDropDown from "../SaveDropDown";
import style from "../../Components/CommonDropDowns/commondropdown.module.css";
import { getDomainSupplier } from "../../Urls";
import SaveDropdown from "../../Components/SaveComponents/SaveDropdown";

function SupplierSaveDropDown({ setSupplier, width, defaultSelection, disabled }) {
  console.log("Return Value in SaveDripDown Parent", disabled);
  return (
    <div style={{ zIndex: 9999, position: "relative" }}>
      <SaveDropdown
        defaultValue={defaultSelection}
        apikey={"supplierrecno"}
        minInput={0}
        inputValue={20}
        displayLabel={"Supl"}
        placeholder={"Select Supl"}
      //{Pradeep task id-398 }/// added isrequired prop
        isrequired
        className={style.drop}
        apiUrl={getDomainSupplier}
        selectionLabel={"supplierdescn"}
        selectionValue={"supplierrecno"}
        setDataToParent={setSupplier}
        width="250px"
        alignment="column"
        zIndex={1000}
        disabled={disabled}
      />
    </div>
  );
}

export default SupplierSaveDropDown;

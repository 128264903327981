import React, { useMemo } from "react";

const PrintDate = ({ item, pageNumber }) => {
  const dateFormatter = (item) => {
    let date = item?.fieldvalue?.toString();
    console.log("date",date)
    let formattedDate =
      date?.length > 6
        ? date.slice(6, 8) + "/" + date.slice(4, 6) + "/" + date.slice(0, 4)
        : date.slice(4, 6) + "/" + date.slice(2, 4) + "/" + date.slice(0, 2);

    return formattedDate;
  };


  return (
    <div style={{
      position:"absolute"
     }}>
            <p
               style={{
                 fontSize: `${item?.fontsize}px`,
                 position: "absolute",
                 border: item?.printbox === 1 ? "1px solid black" : null,
                 left: `${item?.fieldx}px`,
                 top:`${item?.fieldy}px`,
                 fontFamily: item?.fontname,
                 // fontWeight: item?.fontWeight,
                 // fontStyle: item?.fontStyle,
                 fontWeight: item?.fontbold==1?"bold":null,
                 fontStyle:item?.fontitalic==1?"italic":null,
                 width:`${item?.fieldxto}px`,
                 height:`${item?.fieldyto}px`,
               }}
             >
               {item?.fieldvalue ? dateFormatter(item) : ""}
             </p>
     </div>
  );
};

export default PrintDate;

// import { Button } from "@material-ui/core";
// import { useContext } from "react";
// import { AuthContext } from "./Auth/Auth";
// const { getJsDateFromExcel } = require("excel-date-to-js");




const environments = {
  // bcore: {
  //   env: "bcore",
  //   bcoreUrl: "https://dev.sutradhar.tech/bcore/api/v1",
  //   url: "https://dev.sutradhar.tech/BcoreAdminPanelBack",
  //   sutraposUrl: "https://dev.sutradhar.tech/bcore/api/v1",
  //   approvals: true,
  //   localWorkBench: true,
  //   workBench: true
  // },
  sutradharDev: {
    env: "sutradharDev",
    url: "https://dev.sutradhar.tech/SutraposAdminPanelBack",
    sutraposUrl: "https://pos.chitalegroup.co.in/chitaleposreports/api/v1",
    approvals: true,
    localWorkBench: true,
    workBench: true
  },
  chitaleProd: {
    env: "chitaleProd",
    url: "https://pos.chitalegroup.co.in/posadminback",
    sutraposUrl: "https://pos.chitalegroup.co.in/chitaleposreports/api/v1",
    approvals: true,
    localWorkBench: true,
    workBench: true
  },
  stuwell: {
    env: "stuwell",
    url: "https://dev.sutradhar.tech/StuwellAdminPanelBack",
    sutraposUrl: "",
    approvals: false,
    localWorkBench: false,
    workBench: true
  },
  dev: {
    env: "dev",
    url: "http://10.10.0.20:2015",
    sutraposUrl: "https://pos.chitalegroup.co.in/chitaleposreports/api/v1",
    approvals: true,
    localWorkBench: true,
    workBench: true
  },

  // survey: {
  //   env: "dev",
  //   url: "http://103.151.97.157/surveyapi/api/v1/",
  //   approvals: true,
  //   localWorkBench: true,
  //   workBench: true
  // },

  // New Bcore

  bcore: {
    env: "bcore",
    bcoreUrl: "https://scientific.sutradhar.tech/backend/api/v1",
    url: "https://dev.sutradhar.tech/BcoreAdminPanelBack",
    sutraposUrl: "https://dev.sutradhar.tech/bcore/api/v1",
    approvals: true,
    localWorkBench: true,
    workBench: true
  },
  
  survey: {
    env: "dev",
    url: "https://scientific.sutradhar.tech/survey/api/v1",
    approvals: true,
    localWorkBench: true,
    workBench: true
  },

};

const currentEnvironment = environments.bcore;
const AppConstants = {

  APIProduction: environments.bcore.bcoreUrl,
  APISurvey: environments.survey.url,
  currentEnvironment: currentEnvironment,
  APIUrl: currentEnvironment.url,
  APIUrl1: currentEnvironment.url,
  APISutrapos: currentEnvironment.sutraposUrl,
  bcoreapi: currentEnvironment.bcore,
  Project: "bhoopal",
  primarycolor: "#3b4252",
  fileToBase64: (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    }),
  dateShowConverter: (dbDate) =>
    dbDate.toString().substr(6) +
    "/" +
    dbDate.toString().substr(4, 2) +
    "/" +
    dbDate.toString().substr(0, 4),

  dateShow: (dbDate) =>
    dbDate.toString().substr(6) +
    "-" +
    dbDate.toString().substr(4, 2) +
    "-" +
    dbDate.toString().substr(0, 4),


  dateShowConv: (dbDate) =>
    dbDate.toString().substr(6) +
    "/" +
    dbDate.toString().substr(4, 2) +
    "/" +
    dbDate.toString().substr(0, 4),



  timeShowConverter: (dbTime) => {
    dbTime = ("0" + dbTime.toString()).substr(-6);
    return (
      dbTime.substr(0, 2) +
      ":" +
      dbTime.substr(2, 2) +
      ":" +
      dbTime.substr(4, 2)
    );
  },
  dateFormatter: (funDate) => {
    let date = funDate?.toString() || "";
    let formattedDate =
      date.length >= 7
        ? date.slice(6, 8) + "/" + date.slice(4, 6) + "/" + date.slice(0, 4)
        : date.slice(4, 6) + "/" + date.slice(0, 4);

    return formattedDate;
  },
  convertDate: (date, format) => {
    // let localDate;
    var dateForDisplay;
    var dateForAPI;


    if (format === "YYYY-MM-DD") {
      console.log("Format", format)
      date = date?.split("-")
      console.log("Date", date)
      dateForDisplay = date[2] + "/" + date[1] + "/" + date[0]
      console.log("dateForDisplay", dateForDisplay)
      dateForAPI = date[0] + date[1] + date[2]
    }

    return ({ dateForDisplay: dateForDisplay, dateForAPI: dateForAPI })
  },


  datedisplay: (date) => {
    let trdate = date.toString();
    if (trdate.length == 8) {
      const dd = trdate.slice(0, 2);
      const mm = trdate.slice(2, 4);
      const yy = trdate.slice(4, 8);
      if (Number(mm) > 12) {
        return false
      } else {
        var dispDate = dd.toString() + '/' + mm + '/' + yy
        var dataDate = yy.toString() + mm.toString() + dd.toString()
        return { dispDate: dispDate, dataDate: dataDate }
      }
    }
    else {
      return trdate
    }

  },



  // For Formats Like
  // if input 05 consider as month and datedisp=01/05/2022
  // if input 0522 consider as month and year and datedisp=01/05/2022
  // if input 040522 consider as date and month and year datedisp=04/05/2022

  datedispNewFormat: (date) => {
    let trdate = date?.toString();


    if (trdate?.length >= 8) {
      // dbDate.toString().substr(6) +
      //   "/" +
      //   dbDate.toString().substr(4, 2) +
      //   "/" +
      //   dbDate.toString().substr(0, 4),
      const dd = trdate.slice(6)
      const mm = trdate.slice(4, 6);
      const yy = trdate.slice(0, 4);
      if (Number(mm) > 12) {
        return false
      } else {
        var dispDate = dd.toString() + '/' + mm + '/' + yy
        var dataDate = yy.toString() + mm.toString() + dd.toString()
        return { dispDate: dispDate, dataDate: dataDate }
      }
    }


    else if (trdate?.length == 8) {
      const dd = trdate.slice(0, 2);
      const mm = trdate.slice(2, 4);
      const yy = trdate.slice(4, 8)
      if (Number(mm) > 12) {
        return false
      } else {
        var dispDate = dd.toString() + '/' + mm + '/' + yy
        var dataDate = yy.toString() + mm.toString() + dd.toString()
        return { dispDate: dispDate, dataDate: dataDate }
      }
    }
    else if (trdate?.length == 7) {
      const dd = trdate.slice(0, 1);
      const mm = trdate.slice(1, 3);
      const yy = trdate.slice(3, 7)
      if (Number(mm) > 12) {
        return false
      } else {
        var dispDate = 0 + '' + dd.toString() + '/' + mm + '/' + yy
        var dataDate = yy.toString() + mm.toString() + dd.toString()
        return { dispDate: dispDate, dataDate: dataDate }
      }
    }


    else if (trdate?.length >= 5) {
      const dd = trdate.slice(0, 2);
      const mm = trdate.slice(2, 4);
      const yy = trdate.slice(4)
      if (Number(mm) > 12) {
        return false
      } else {
        var dispDate = dd.toString() + '/' + mm + '/' + '20' + yy
        var dataDate = '20' + yy.toString() + mm.toString() + dd.toString()
        return { dispDate: dispDate, dataDate: dataDate }
      }
    }

    else if (trdate?.length >= 4) {
      const d = new Date();
      const dd = '01'
      const mm = trdate.slice(0, 2);
      const yy = trdate.slice(2)
      console.log("first==", mm, "ff", yy)
      if (Number(mm) > 12) {
        return false
      } else {
        var dispDate = dd.toString() + '/' + mm + '/' + '20' + yy
        var dataDate = '20' + yy.toString() + mm.toString() + dd.toString()
        return { dispDate: dispDate, dataDate: dataDate }
      }
    }


    else if (trdate?.length >= 2) {
      const d = new Date();
      const dd = '01'
      const mm = trdate.slice(0);
      const yy = d.getFullYear()
      if (Number(mm) > 12) {
        return false
      } else {
        var dispDate = dd.toString() + '/' + mm + '/' + yy
        var dataDate = yy.toString() + mm.toString() + dd.toString()
        return { dispDate: dispDate, dataDate: dataDate }
      }
    }

    else {
      return trdate
    }



  },






  timeFormatter: (time) => {

    let localTime = time.toString() || "";
    console.log("localTime", localTime, "time", time, typeof (time))
    let formattedTime =
      localTime.slice(0, 2) + ":" + localTime.slice(2, 4) + ":" + localTime.slice(4, 6);
    return formattedTime;
  },
  dateStrToObj: (dateStr) => {
    dateStr = dateStr.toString();
    return new Date(
      dateStr.slice(0, 4) +
      "-" +
      dateStr.slice(4, 6) +
      "-" +
      dateStr.slice(6, 8)
    );
  },
  today: () =>
    new Date().getFullYear().toString() +
    ("0" + (new Date().getMonth() + 1).toString()).slice(-2) +
    ("0" + new Date().getDate().toString()).slice(-2),
  dateSendConvert: (date) => {
    let dateVar = date?.toString();
    let sendApiDate = dateVar.slice(6,) + dateVar.slice(3, 5) + dateVar.slice(0, 2)
    return sendApiDate
  },

  // dateSendConvertnew: (date) => {
  //   let dateVar = date?.toString();
  //   let sendApiDate = dateVar.slice(5,) + dateVar.slice(2, 4) + dateVar.slice(0, 1)
  //   return sendApiDate
  // },


  // DATE WITH FORMAT 17042022
  datewithoutdashSendConvert: (date) => {
    let dateVar = date?.toString();
    let sendApiDate = dateVar.slice(4) + dateVar.slice(2, 4) + dateVar.slice(0, 2)
    return sendApiDate
  },


  // date.getFullYear().toString() +
  // ("0" + (date.getMonth() + 1).toString()).slice(-2) +
  // ("0" + date.getDate().toString()).slice(-2),
  datetime: (today) =>
    ("0" + today.getDate()).slice(-2) +
    ("0" + (today.getMonth() + 1).toString()).slice(-2) +
    today.getFullYear() +
    ("0" + today.getHours()).slice(-2) +
    ("0" + today.getMinutes()).slice(-2) +
    ("0" + today.getSeconds()).slice(-2),
  now: () => {
    let now = new Date();
    return (
      ("0" + now.getHours()).slice(-2) +
      ("0" + now.getMinutes()).slice(-2) +
      ("0" + now.getSeconds()).slice(-2)
    );
  },
  timeSendConvert: (now) =>
    ("0" + now.getHours()).slice(-2) +
    ("0" + now.getMinutes()).slice(-2) +
    ("0" + now.getSeconds()).slice(-2),

  objDateToStr: (prop) => {
    console.log("obj", prop, typeof prop, new Date(prop));
    let propDateStr = prop.toString();
    let dateObj = new Date(propDateStr);
    // console.log("dateObj", dateObj);
    let strDate =
      dateObj.getFullYear() +
      (dateObj.getMonth() + 1 > 9
        ? dateObj.getMonth() + 1
        : "0" + (dateObj.getMonth() + 1)) +
      (dateObj.getDate() > 9 ? dateObj.getDate() : "0" + dateObj.getDate());
    // console.log("strDate", strDate);
    return strDate;
  },

  formatDataToSend: (data, properties) => {
    return new Promise(async (resolve, reject) => {
      try {
        for (let key in data) {
          if (properties[key].type == "mediumblob") {
            if (data[key]) {
              data[key] = await AppConstants.fileToBase64(data[key]);
              data[key] = data[key].slice(22);
            } else data[key] = null;
          } else if (properties[key].type == "date") {
            data[key] = Number(AppConstants.dateSendConvert(data[key]));
          } else if (
            properties[key].type == "int" ||
            properties[key].type == "Int"
          ) {
            if (data[key] != "") data[key] = Number(data[key]);
            else data[key] = null;
          }
        }
        resolve(data);
      } catch (e) {
        reject(e);
      }
    });
  },
  defaultColDef: {
    editable: true,
    minWidth: 100,
    sortable: true,
    resizable: true,
    filter: true,
  },

  userRolePermission: (auth, str) => {
    const userRoleArr = auth?.state?.userRoleInfo;
    let userRoleInfo = userRoleArr?.filter(i => i.functiondescn == str)[0];
    return userRoleInfo;
  }


};
export default AppConstants;


import React, { useContext, useState, useCallback } from "react";
import { useEffect } from "react";
// import CustomerinSurveyDrop from "../../../CommonControllers/Components/CommonDropDowns/survey/CustomerinSurveyDrop";
// import SurveyTypeDrop from "../../../CommonControllers/Components/CommonDropDowns/survey/SurveyTypeDrop";
import { TabStore } from "../../../TabsStructure/TabsStore";
import TableComponent from "../../../CommonControllers/Components/TableComponent/TableComponent";
import { Button, Modal, Table } from "antd";
import { AiFillEdit, AiOutlineEdit } from "react-icons/ai";
import { BiShow } from "react-icons/bi"
import EditableTable from "../../../CommonControllers/Components/Tables/EditableTable";
import { addprintformatfooters, getbulkaddsurveyanswerfooter, getprintformatfooterlist, getsurveyanswerfooterlist } from "../../../CommonControllers/Urls";
import EditableCommonTable from "../../../CommonControllers/Components/TableComponent/EditableCommonTable";
import axios from "axios";
import { AuthStore } from "../../../Auth/AuthStore";
import _, { set } from "lodash";
import CallbackCheckbox from "../../../CommonControllers/Components/CallbackComponents/CallbackCheckbox";
import CartIcon from '../../../Assets/noData.png'
import FilterComponents from "../../../CommonControllers/FiterComponent/FilterComponents";
import { notification } from "antd";
import { v4 as uuidv4 } from "uuid";
import useShortguid from "../../../CommonControllers/Hooks/useShortGuid";
import useSaveApi from "../../../CommonControllers/Hooks/useSaveApi";

const PrintingFormat = () => {
  const [col, setCol] = useState([]);
  const [row, setRow] = useState([]);
  /// ,modata data 
  const [modalrow, setModalatarow] = useState([])
  const [modalcol, setModalDatacol] = useState([])
  console.log("modalcol state", modalcol)
  const [recno, setBody] = useState(0)
  const tabStore = useContext(TabStore);
  const auth = useContext(AuthStore)
  const [data, setData] = useState(null)
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [count, setcount] = useState(0)
  const [isEditModal, setisEditModal] = useState(false)
  const [index, setindex] = useState(0)

  const [inlineedit,setInlineEdit]=useState(false)
  const shortguid = useShortguid();
  

  const totalData = tabStore.state.tabs[tabStore.state.tabIndex].apiResponse;
 
  let api = getprintformatfooterlist
  useEffect(() => {
    if (totalData) {
      let columns = totalData?.columnsinfo
      console.log("totalData?.columnsinfo", totalData?.columnsinfo)
      if (columns !== undefined) {
        columns = columns.map((item) => {
          if (item.columnname == "approved") {
            return {
              title: "Approved",
              render: (_, record) => (
                <CallbackCheckbox
                  defaultValue={record.approved == 0 ? (false) : (true)}
                />
              ),
              // ...item
            }
          } else if (item.columnname == "active") {
            return {
              title: "Active",
              render: (_, record) => (
                <CallbackCheckbox
                  defaultValue={record.active == 0 ? (false) : (true)}
                />
              ),
              // ...item
            }
          } else {
            return (
              { ...item }
            )
          }
        }
        )
        columns = [
          {
            title: "Operation",
            key: "key",
            dataIndex: "key",
            render: (i, record) => (
              <Button onClick={() => showModal(record)} color="white">
                <BiShow color='white' />
              </Button>
            ),
          },
          {
            title: "Edit",
            key: "edit",
            dataIndex: "edit",
            render: (i, record) => (
              <Button onClick={() => setInlineEdit(true)} color="white">
                <AiOutlineEdit color='white' />
              </Button>
            ),
          },
          ...columns
        ]
      }
      console.log("columns987908-",tabStore)
      setCol(columns);
      setRow(totalData?.Message);
      //setCol(coldata)
    }
  }, [tabStore.state.tabs[tabStore.state.tabIndex]?.pageStatus=="Success"]);
  console.log("rtdyTDW1223",tabStore)
  useEffect(()=>{
    setInlineEdit(false)
    tabStore.dispatch({
      type: "UPDATE_DATA",
      fieldType: "pageStatus",
      text: "",
    });
    console.log("rtdyTDW",tabStore)
  },[tabStore.state.tabs[tabStore.state.tabIndex]?.pageStatus=="Success"])

 


  useEffect(() => {
    let x = tabStore?.state?.tabs[0]?.changedRows;
    setData(x);
  }, [tabStore?.state?.tabs[0].changedRows]);

  const getModaltabledata = async (x) => {
    const body = {
      mainrecno: x,
      tenantrecno : auth?.state?.userdata?.tenantrecno,
    };

    const resp = await axios.post(api, body);


    if (resp.data.columnsinfo !== undefined) {
      let columns = resp?.data?.columnsinfo.map((ques) => {
        console.log("ques.type", ques.type)
        if (ques.type == "image") {
          console.log("modalcol", ques.type);
          return {
            title: "Answer",
            // dataIndex: ques?.type,
            render: (i, record) => {
              console.log("record?.answerimage", record?.answerimage)
              return (
                <div>
                  {record?.answerimage != "" ? (
                    <div>
                      <img style={{ width: 200, height: 200 }} src={`data:image/jpeg;base64,${record?.answerimage}`} />
                    </div>

                  ) : null
                  }

                </div >
              )
            }

            //   <img
            //   source={{ uri: `data:image/png;base64,${record?.answerimage}` }}
            //   alt="answerimage"
            //   style={{ maxWidth: "100%" }}
            // />
            // console.log("record.answerimage", record?.answerimage);
            // const imageData = atob(record.answerimage);
            // const imageUrl = URL.createObjectURL(new Blob([imageData], { type: "image/jpeg" }));
            // console.log("imageUrl", imageUrl);
            // return (
            //   <div>
            //     <img
            //       source={{ uri: `data:image/png;base64,${record?.answerimage}` }}
            //       alt="answerimage"
            //       style={{ maxWidth: "100%" }}
            //     />
            //   </div>
            // );
            ,
            ...ques
          };
        } else if (ques.columnname == "questionrecno") {
          return {
            title: "Question",
            dataIndex: "questionrecno",
            render: (i, record) => {
              return (
                <div>{record.questionname + "(" + record.questionrecno + ")"}</div>
              )
            }
          }
        } else {
          console.log("else condition true")
          return {
            // title: ques.columndisplay,
            // dataIndex: ques.columnname,
            // render: (_, data) => (
            //   <p><b>Hello</b></p>
            // ),
            ...ques
          };
        }
      });
      console.log("Columns are", columns)
      columns = [
        {
          title: "Operation",
          key: "key",
          dataIndex: "key",
          render: (tabIndex, record, index) => {
            console.log("edit modal", tabIndex, record, index)
            return (
              <div>
                {record.hassubquestions != 1 ? (
                  <Button onClick={() => {
                    setisEditModal(true)
                    setindex(index)

                  }} color="white">
                    <AiFillEdit color='white' />
                  </Button>
                ) : null}
              </div>

            )

          },
        },

        ...columns
      ]
      setModalDatacol(columns)
    }

    // setModalatarow(resp?.data?.Message.map((i) => {
    //   return { ...i, questionrecno: i.questionname + "(" + i.questionrecno + ")" }
    // }));

    setModalatarow(resp?.data?.Message)
  };

  const showModal = (record) => {
    console.log("modal open", record);
    //setModalData(record)
    getModaltabledata(record.recno);
    setIsModalOpen(true);
    setBody(record.recno)
    auth.surveytyperecno = record?.surveytyperecno
  };

  const handleOk = async () => {
   
    const updatedModalRow = tabStore?.state?.tabs[tabStore.state.tabIndex]?.changedRows.map((item) => ({
      ...item,
      tenantrecno: auth.state.userdata.tenantrecno,
      domainrecno: auth.state.userdata.domainrecno,
      domainuserrecno: auth.state.userdata.recno,
      userrolerecno: auth.state.userdata.recno,
      shortguid: uuidv4()
    }));
    
    try {
      const response = await axios.post(addprintformatfooters, updatedModalRow);
      console.log("response Atharv", response)
      if (response?.data?.Success) {
        notification.success({
          message: " updated successfully!",
          style: { background: "#a7efb6" },
        });
        setIsModalOpen(false);
        setcount((p) => p + 1);
      } else {
        notification.error({
          message: "Failed to update .",
        });
        setIsModalOpen(false);
      }
      // setIsModalOpen(false);
      setcount((p) => p + 1);
    } catch (error) {
      notification.error({
        message: "Failed to update . Please try again later.",
      });
      // callOk(body)
      // setIsModalOpen(false);
      // setcount((p) => p + 1)
    };
  }

  const callOk = async (x) => {
    let resp = await axios.post(getbulkaddsurveyanswerfooter, x);
  }

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleEditCancel = () => {
    setisEditModal(false);
  };
  //console.log("BODY",body)

  // const coldata = [
  //   {
  //     title: "Operation",
  //     key: "key",
  //     dataIndex: "key",
  //     render: (i, record) => (
  //       <Button onClick={() => showModal(record)} color="white">
  //         <AiFillEdit color='white' />
  //       </Button>
  //     ),
  //   },
  //   ...col,
  // ];

  const handleCheckBoxChange = (option) => {
    if (modalrow[index]?.answer?.split(",").includes(option)) {
      const updatedOptions = modalrow[index]?.answer?.split(",").filter((item) => item !== option);
      console.log("updatedOptions", updatedOptions.join(','))
      setModalatarow(p => {
        p[index].answer = updatedOptions.join(',');
        return [...p];
      });
      //   setCheckedOptions(updatedOptions);
    } else {
      const updatedOptions = [...modalrow[index]?.answer?.split(","), option];
      console.log("updatedOptions", updatedOptions.join(','))
      setModalatarow(p => {
        p[index].answer = updatedOptions.join(',');
        return [...p];
      });
      //   setCheckedOptions(updatedOptions);
    }
  };

  // const renderAnswerComponent = (modalrow) => {

  //   if (modalrow[index]?.questiontype === 5) {
  //     return (
  //       <div>
  //         {modalrow[index].optionlist.split(",").map(item => {
  //           return (
  //             <div className="checkbox">

  //               <label>
  //                 <input
  //                   type="checkbox"
  //                   checked={modalrow[index]?.answer.split(",").includes(item) ? true : false}
  //                   onChange={() => handleCheckBoxChange(item)}
  //                 /> &nbsp;
  //                 {item}
  //               </label>
  //             </div>
  //           );
  //         })}
  //       </div>
  //     );
  //   }

  //   if (modalrow[index]?.questiontype === 4 || modalrow[index]?.questiontype === 3) {
  //     return (


  //       <div>
  //         {modalrow[index].optionlist.split(", ").map(item => {
  //           return (
  //             // <div style={{ flexDirection: 'row' }}>
  //             //   <RadioButton
  //             //     value="first"
  //             //     status={
  //             //       modalrow[index]?.answer === item.value ? 'checked' : 'unchecked'
  //             //     }
  //             // onPress={() => {
  //             //   setanswer(p => {
  //             //     p[index].answer = item.label;
  //             //     return [...p];
  //             //   });
  //             // }}
  //             //   />
  //             //   <Text style={{ marginTop: 8 }}> {item.value}</Text>
  //             // </div>

  //             <div className="radio">

  //               <label>
  //                 <input
  //                   type="radio"
  //                   checked={modalrow[index]?.answer === item}
  //                   onChange={() => {
  //                     setModalatarow(p => {
  //                       p[index].answer = item;
  //                       return [...p];
  //                     });
  //                   }}
  //                 /> &nbsp;
  //                 {item}
  //               </label>
  //             </div>
  //           );
  //         })}
  //       </div>

  //     );
  //   }

  //   if (modalrow[index]?.questiontype === 2) {
  //     return (
  //       <div>
  //         {/* <img style={{ width: 200, height: 200 }} src={`data:image/jpeg;base64,${modalrow[index]?.answerimage}`} /> */}

  //         <input type="text" value={modalrow[index]?.answer}
  //           onChange={(e) => {
  //             setModalatarow(p => {
  //               p[index].answer = e.target.value;
  //               return [...p];
  //             });
  //           }} />
  //       </div>
  //     );
  //   }

  //   if (modalrow[index]?.questiontype === 1) {
  //     return (
  //       <div>
  //         <input
  //           type="text"
  //           value={modalrow[index]?.answer}
  //           onChange={(e) => {
  //             setModalatarow(p => {
  //               p[index].answer = e.target.value;
  //               return [...p];
  //             });
  //           }} />
  //       </div>
  //     );
  //   }

  //   return (
  //     <div>
  //       No answer available
  //     </div>
  //   );
  // };

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-evenly",
          alignItems: "center",
        }}
      >
        <FilterComponents />

      </div>
      {inlineedit? <EditableTable columnsArray={col} rowsArray={row} isEdit={true}/>: <Table columns={col} dataSource={row} /> }
      {/* <EditableTable columnsArray={col} rowsArray={row}/> */}
      {/* <SurveyEditableTable columnsArray={col}
          rowsArray={row}  /> */}

      <Modal
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        width={1100}
        style={{ height: "200px" }}
      >
        {/* <EditableCommonTable rowData={modalrow} height={200}
          columnData={modalcol}
          columnLength={modalcol?.length}
          storeValue={"apiVariables.response.Message"}
          isAdd={true} 
          isEdit={true} /> */}

        {/* <EditableTable columnsArray={modalcol} rowsArray={modalrow} /> */}
        <div style={{ overflowY: "scroll", height: "400px" }}>
          {/* <EditableTable pagination={false} columns={modalcol} dataSource={modalrow} /> */}
          <EditableTable columnsArray={modalcol} rowsArray={modalrow} />
        </div>
      </Modal>

   
    </div>
  );
};

export default PrintingFormat;

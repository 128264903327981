import { Input } from "antd";
import React, { useEffect, useState } from "react";
import useExecute from "../../Hooks/useExecute";

function CallbackNumberInput({
  displayLabel,
  placeholder,
  change,
  className,
  style,
  type,
  required,
  defaultValue,
  apikey,
  id,
  returnValue,
}) {
  // Steps : onChange cahnge the value of the state variable in the component
  // OnBlur : in case the component needs to be onBlue save the changed value to the tabStore on blue event, else on change event
  // For Execute we save the values to the apiVariables store in tabStore

  const execute = useExecute();
  const [textValue, setTextValue] = useState(null);

  // useEffect(() => {
  //   if (defaultValue) {
  //     if (execute.getValue(apikey)) {
  //       setTextValue(execute.getValue(apikey));
  //     } else if (execute.getValue(apikey) == "") {
  //       setTextValue(execute.getValue(apikey));
  //     } else {
  //       setTextValue(defaultValue);
  //       execute.updateValue(apikey, defaultValue);
  //     }
  //   } else {
  //     if (execute.getValue(apikey)) {
  //       setTextValue(execute.getValue(apikey));
  //     }
  //   }
  // }, [defaultValue]);

  // useEffect(() => {
  //   if (execute.getValue(apikey)) {
  //     if (!textValue) {
  //       setTextValue(execute.getValue(apikey));
  //     }
  //   }
  // }, [execute.getValue(apikey)]);

  const onChange = (e) => {
    setTextValue(e.target.value);
  };

  var className = "inputBox" + " " + className;

  function updatedValue(e) {
    setTextValue(e.target.value);
    // Send the Data to the tabStore
    //execute.updateValue(apikey, e.target.value);
    return textValue;
  }

  const mainFrame = {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    gap: "row" && "8px",
    textAlign: "start",
  };
  return (
    <div style={{ ...mainFrame }}>
      <label>{displayLabel}</label>
      <div style={{ flexGrow: 1 }}>
        {change ? (
          <input
            id={id}
            className={className}
            style={{ ...style }}
            type={"number"}
            placeholder={placeholder}
            onChange={updatedValue}
            value={textValue}
            required={required}
          />
        ) : (
          <input
            className={className}
            style={style}
            id={id}
            type={"number"}
            placeholder={placeholder}
            onBlur={updatedValue}
            onChange={onChange}
            value={textValue}
            required={required}
          />
        )}
      </div>
    </div>
  );
}

export default CallbackNumberInput;

// import React from 'react'
// import { DateInput, NumberInput, TextInput, } from 'react-component-library-sutradhar'


// function ChequeBounceEntry() {
//     return (
//         <div>
//             <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
//                 <label>
//                     <b>Entry Date</b>
//                 </label>
//                 <DateInput
//                     label=''
//                     border='outlined'
//                     fontSize='14px'
//                     font='Arial'
//                     width='200px'
//                     placeholder='Enter Date'
//                 />
//             </div>
//             <div style={{ display: 'flex', }}>
//                 <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
//                     <label>
//                         <b>SD Account</b>
//                     </label>
//                     <NumberInput
//                         type='decimalMasking'
//                         // label='SD Account'
//                         border='outlined'
//                         fontSize='14px'
//                         font='Arial'
//                         width='200px'
//                         placeholder='Enter Acc No'
//                     />
//                 </div>

//                 <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
//                     <label>
//                         <b>Name</b>
//                     </label>
//                     <TextInput
//                         label=''
//                         placeholder='Enter Name'
//                         border='outlined'
//                         width='200px'
//                         fontSize='14px'
//                     // fontColor={fontColor}
//                     />
//                 </div>
//             </div>
//             <div style={{ display: 'flex', }}>
//                 <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
//                     <label>
//                         <b>Cheque No from</b>
//                     </label>
//                     <NumberInput
//                         type='decimalMasking'
//                         // label='SD Account'
//                         border='outlined'
//                         fontSize='14px'
//                         font='Arial'
//                         width='200px'
//                         placeholder='Enter Cheque No'
//                     />
//                 </div>

//                 <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
//                     <label>
//                         <b>Cheque Date</b>
//                     </label>
//                     <DateInput
//                         label=''
//                         border='outlined'
//                         fontSize='14px'
//                         font='Arial'
//                         width='200px'
//                         placeholder='Enter Date'
//                     />
//                 </div>

//                 <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
//                     <label>
//                         <b>Amount</b>
//                     </label>
//                     <NumberInput
//                         type='decimalMasking'
//                         // label='SD Account'
//                         border='outlined'
//                         fontSize='14px'
//                         font='Arial'
//                         width='200px'
//                         placeholder='Enter No of Cheques'
//                     />
//                 </div>
//             </div>
//             <div style={{ display: 'flex', }}>
//                 <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
//                     <label>
//                         <b>Bank Name</b>
//                     </label>
//                     <TextInput
//                         label=''
//                         placeholder='Enter Bank Name'
//                         border='outlined'
//                         width='200px'
//                         fontSize='14px'
//                     // maxChars={}
//                     />
//                 </div>

//                 <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
//                     <label>
//                         <b>IFSC</b>
//                     </label>
//                     <TextInput
//                         label=''
//                         placeholder='Enter IFSC Code'
//                         border='outlined'
//                         width='200px'
//                         fontSize='14px'
//                     />
//                 </div>
//             </div>

//             <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
//                 <label>
//                     <b>Bounce Reason</b>
//                 </label>
//                 <TextInput
//                     label=''
//                     placeholder='Enter Reason'
//                     border='outlined'
//                     width='200px'
//                     fontSize='14px'
//                     maxChars={100}
//                 />
//             </div>

//         </div>
//     )
// }

// export default ChequeBounceEntry

import React, { useContext, useEffect } from 'react'
import { AuthStore } from '../../../Auth/AuthStore';
import { getPDCListApi } from '../../../CommonControllers/ApiCalls';
import TableComponent from '../../../CommonControllers/Components/TableComponent/TableComponent';
import EditableTable from '../../../CommonControllers/Components/Tables/EditableTable';
import useExecuteApi from '../../../CommonControllers/Hooks/useExecuteApi';
import { currentTabData } from '../../../CommonControllers/Variable';
import { TabStore } from '../../../TabsStructure/TabsStore';


function ChequeBounceEntry() {
    const tabStore = useContext(TabStore);
    const auth = useContext(AuthStore)
    const execute = useExecuteApi();

console.log("auth",auth)

    const chequeBncData = currentTabData(tabStore)?.apiResponse?.Message;
    const chequeBncColumns = currentTabData(tabStore)?.apiResponse?.columnsinfo;

    useEffect(() => {

        const payload = {
            tenantrecno: auth?.state?.userdata?.tenantrecno,
            domainrecno: auth?.state?.userdata?.domainrecno,
            userrolerecno: auth?.state?.userdata?.userrolerecno,
            domainuserrecno: auth.state.userdata.recno,
            fastpath: currentTabData(tabStore)?.name,
            details: "pdcentry"
        };
        var columns;
        var Message;
        var newResp;

        getPDCListApi(payload).then((res) => {
            columns = res?.columnsinfo
            Message = res?.Message
            newResp = { ...newResp, columnsinfo: columns, Message: Message }
            tabStore.dispatch({
                type: "UPDATE_DATA",
                fieldType: "apiResponse",
                text: newResp,
            });
        })

    }, []);

    return (
        <div style={{ padding: "10px" }}>

            <EditableTable columnsArray={chequeBncColumns} rowsArray={chequeBncData} />

        </div>
    )
}

export default ChequeBounceEntry
import { useState, useEffect, useContext } from "react";
import { AuthStore } from "../../Auth/AuthStore";
import { TabStore } from "../../TabsStructure/TabsStore";

const useExecute = () => {
    const auth = useContext(AuthStore)
    const tabStore = useContext(TabStore)
    const getValue = (key) => {
        if (key) {
            if (tabStore.state.tabs[tabStore.state.tabIndex].apiVariables) {

                return tabStore.state.tabs[tabStore.state.tabIndex].apiVariables[key]
            } else {
                return null
            }
        } else {
            return tabStore.state.tabs[tabStore.state.tabIndex].apiVariables
        }
    }

    const updateValue = (key, value) => {
        // Need to take the key and store the value with that  key in the tab Store
        //  If the Tabs in Multi-level need to update the store using map function
        
        if (key) {

            tabStore.dispatch({type:"API_DATA",fieldType: key, text:value})
        } else {
            tabStore.dispatch({type:"UPDATE_DATA",fieldType: "apiVariables", text:value})
        }
    }

    return {updateValue, getValue}
};

export default useExecute;
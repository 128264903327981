import { initializeApp } from "firebase/app";
import { getMessaging, onMessage, isSupported } from "firebase/messaging";


const firebaseConfig = {
  apiKey: "AIzaSyA6eCtTCXSZD1O3Olf0btdeyQW6iUqKCqY",
  authDomain: "bcore-582ba.firebaseapp.com",
  projectId: "bcore-582ba",
  storageBucket: "bcore-582ba.appspot.com",
  messagingSenderId: "888192431810",
  appId: "1:888192431810:web:335828f440712d89f9ed5b",
  measurementId: "G-5TXP3FNYGH",
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const messaging = getMessaging(app);


  console.log("Messaging", getMessaging())
    onMessage(messaging, (payload) => {
      console.log("Message received. ", payload.notification?.body);
      alert("Not" + payload.notification?.body);
      // ...
    });





// import React, { useContext, useEffect, useState } from 'react'
// import { AuthStore } from '../../../Auth/AuthStore';
// import { getfastpathapi, getPDCListApi } from '../../../CommonControllers/ApiCalls';
// import ExecuteDropdown from '../../../CommonControllers/Components/ExecuteComponents/ExecuteDropdown';
// import EditableTable from '../../../CommonControllers/Components/Tables/EditableTable';
// import { currentTabData } from '../../../CommonControllers/Variable';
// import { TabStore } from '../../../TabsStructure/TabsStore';

// function DepositePDCEntry() {
//     const tabStore = useContext(TabStore);
//     const auth = useContext(AuthStore)
//     const [filterOptions, setFilterOptions] = useState([]);

//     const dpdcData = currentTabData(tabStore)?.apiResponse?.Message;
//     const dpdcColumns = currentTabData(tabStore)?.apiResponse?.columnsinfo;

//     const payload = {
//         tenantrecno: auth?.state?.userdata?.tenantrecno,
//         domainrecno: auth?.state?.userdata?.domainrecno,
//         userrolerecno: auth?.state?.userdata?.userrolerecno,
//         fastpath: currentTabData(tabStore)?.name,
//     };

//     useEffect(() => {
//            getfastpathapi(payload).then((res) => {
//             if (res?.Success) {
//                 console.log("on PO component mount", res?.Message?.filterOptions);
//                 setFilterOptions(res?.Message?.filterOptions);
//             }
//         });
//     }, []);

//     useEffect(() => {

//         const payload = {
//             tenantrecno: auth?.state?.userdata?.tenantrecno,
//             domainrecno: auth?.state?.userdata?.domainrecno,
//             gltype: "b"
//         };
//         var columns;
//         var Message;
//         var newResp;

//         getPDCListApi(payload).then((res) => {
//             columns = res?.columnsinfo
//             Message = res?.Message
//             newResp = { ...newResp, columnsinfo: columns, Message: Message }
//             tabStore.dispatch({
//                 type: "UPDATE_DATA",
//                 fieldType: "apiResponse",
//                 text: newResp,
//             });
//         })

//     }, []);
//     return (
//         <div style={{ padding: "10px" }}>

//             <ExecuteDropdown />

//             <EditableTable columnsArray={dpdcColumns} rowsArray={dpdcData} />

//         </div>
//     )
// }

// export default DepositePDCEntry

import React, { useContext, useEffect, useState } from 'react'
import { AuthStore } from '../../../Auth/AuthStore';
import { getfastpathapi } from '../../../CommonControllers/ApiCalls';
import { dateFormatter } from '../../../CommonControllers/AppFunction';
import TableComponent from '../../../CommonControllers/Components/TableComponent/TableComponent';
import EditableTable from '../../../CommonControllers/Components/Tables/EditableTable';
import FilterComponents from '../../../CommonControllers/FiterComponent/FilterComponents';
import useExecuteApi from '../../../CommonControllers/Hooks/useExecuteApi';
import { currentTabData } from '../../../CommonControllers/Variable';
import { TabStore } from '../../../TabsStructure/TabsStore';

// TRo53
function DepositePDCEntry() {
    const tabStore = useContext(TabStore);
    const auth = useContext(AuthStore)
    const execute = useExecuteApi();
    const [rows, setRows] = useState([]);
    // const [columns, setColumns] = useState([]);
    const [fastPathOptions, setFastPathOptions] = useState();

    async function getCommonMasterList() {
        execute.execute();
    }

    // let columns = tabStore.state.tabs[tabStore.state.tabIndex]?.apiResponse?.columnsinfo

    // console.log("columns", columns)

    // if (columns !== undefined) {
    //     columns = columns.map((item) => {
    //         if (item.columnname == "entrydate") {
    //             return {
    //                 ...item,
    //                 title: "Work Date",
    //                 dataIndex: "entrydate",
    //                 render: (_, record) => (
                        
    //                     <p>{auth.state.userdata.workdate.defaultValue}</p>
    //                 ),
    //             }
    //         } else {
    //             return (
    //                 item
    //             )
    //         }
    //     })
    //     columns = [
    //         ... columns
    //     ]
    // }


    useEffect(() => {
        if (!tabStore.state.tabs[tabStore.state.tabIndex]?.apiResponse) {
            if (
                (tabStore.state.tabs[tabStore.state.tabIndex]?.apiResponse?.length <= 0 ||
                    tabStore.state.tabs[tabStore.state.tabIndex]?.apiResponse?.length == undefined) &&
                tabStore.state.tabs[tabStore.state.tabIndex]?.callapipageload == true
                // smk 5-1-2023 want to call api at page load added dont want for tenantitem ms201
            ) {
                getCommonMasterList();
            }
        }
    }, []);

    useEffect(() => {
        const payload = {
          tenantrecno: auth?.state?.userdata?.tenantrecno,
          domainrecno: auth?.state?.userdata?.domainrecno,
          userrolerecno: auth?.state?.userdata?.userrolerecno,
          domainuserrecno: auth.state.userdata.recno,
          fastpath: currentTabData(tabStore)?.name,
        };
        // On component mount this api is called and filterOptions are set for that fast path
        getfastpathapi(payload).then((res) => {
          if (res?.Success) {
            setFastPathOptions(res?.Message);
            console.log("Message", res.Message)
          }
        });
      }, []);

    return (
        <div style={{ padding: "10px" }}>
            <FilterComponents id="Cheque Deposite Entry" />
            {tabStore.state.tabs[tabStore.state.tabIndex]?.apiResponse?.columnsinfo
                ?.length > 0 ? (
                tabStore.state.tabs[tabStore.state.tabIndex].mode == "edit" ? (
                    <>
                        <EditableTable columnsArray={tabStore.state.tabs[tabStore.state.tabIndex]?.apiResponse?.columnsinfo} rowsArray={tabStore.state.tabs[tabStore.state.tabIndex]?.apiResponse?.Message} selection={fastPathOptions?.selectall} isAdd={fastPathOptions?.isadd}/>


                    </>
                ) : (
                    <TableComponent />
                )
            ) : null}
        </div>
    )
}

export default DepositePDCEntry

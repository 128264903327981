import { Fragment, useContext, useEffect, useState } from "react";
import { TabStore } from "../../TabsStructure/TabsStore";
import UserDropDown from "./UserPermissionDrop";
import { Spin, Tree, notification } from "antd";
import { convertToExpectedFormat } from "../../Action/menu";
import CustomTree from "./CustomTree";

const UsserRolePermission = () => {
  const tabstore = useContext(TabStore);
  const [menu, setRowsmenu] = useState([]);
  const [api, contextHolder] = notification.useNotification();
  const [checkedKeys, setCheckedKeys] = useState([]);
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [autoExpandParent, setAutoExpandParent] = useState(true);
  const [expandedKeys, setExpandedKeys] = useState([]);
  const [payload, setPayload] = useState({});
  /// here is function for conversion of data to required format

  const [updatedData,setUpdatedData]=useState([])
/// logic for table dispaly data start
 const convertToExpectedFormat = (menuArray) => {
    var expectedArray = menuArray.map((item) => {
      item["title"] = item.headerName;
      item["key"] = item.fastpath ? item.fastpath : item.headerName;
      item.userrolerecno =
      tabstore.state.tabs[tabstore.state.tabIndex]?.apiVariables?.userrolerecno
      if (item?.childArr?.length > 0) {
        var children = convertToExpectedFormat(item.childArr);
      } else {
        var children = null;
      }
      item["children"] = children;
      return item;
    });
    return expectedArray;
  };

  /// logic for table dispaly data end 
  const covertToRequiredFormat = () => {
    // Wait for the data to be loaded
    const totalData = tabstore.state.tabs[tabstore.state.tabIndex].apiResponse;

    if (totalData) {
      const columns = totalData?.columnsinfo?.map((item) => ({
        ...item,
        dataIndex: item.columnname,
      }));

      let rows = [];
      if (totalData.Message.length > 0) {
        console.log("total", totalData);
        rows = totalData.Message.map((item) => ({ ...item }));
        const children = convertToExpectedFormat(rows);

        // Convert to checked array based on 'selected' property
        //let flattenData=getAllItemsPerChildren(children)
        //    if(children){
        //     let checkkey=getCheckKeyRecursive(children)
        //     if(checkedKeys){
        //         setCheckedKeys(checkkey)
        //     }
        //    }

        setRowsmenu(children);
      }
    }
  };
  // const getCheckKeyRecursive = (data) => {
  //   const result = [];

  //   const processItem = (item, i) => {
  //     // Check if the current item should be included
  //     console.log("^%&GIOJK:", item.childArr, i);
  //     if (item.childArr == undefined && item.selected) {
  //       result.push(item.key);
  //     }
  //     if (item.selected === 1) {
  //       // If it has children, process them
  //       if (item.childArr && item.childArr.length > 0) {
  //         item.childArr.forEach(processItem);
  //       }

  //       // Push the key to the result array
  //       result.push(item.key);
  //     } else {
  //       // If the current item is not selected, check its children
  //       if (item.childArr && item.childArr.length > 0) {
  //         item.childArr.forEach(processItem);
  //       }
  //     }
  //     //result.push(item.key)
  //   };

  //   // Iterate through the initial array
  //   data.forEach((it) => {
  //     if (it.childArr == undefined && it.selected) {
  //       result.push(it.key||it.headerName);
  //     } else if (it.childArr && it.childArr.length > 0) {
  //       processItem(it);
  //     }
  //   });

  //   return result;
  // };

  /// here the default check data

  const getCheckKeyRecursive = (data) => {
    const result = [];
  
    const processItem = (item) => {
      // Check if the current item should be included
      if (item.childArr === undefined && item.selected) {
        result.push(item.key);
      }
  
      if (item.selected === 1) {
        // If it has children, process them
        if (item.childArr && item.childArr.length > 0) {
          let allChildrenSelected = true;
  
          item.childArr.forEach((child) => {
            // Process child items
            processItem(child);
  
            // Check if any child is not selected
            if (child.selected !== 1) {
              allChildrenSelected = false;
            }
          });
  
          // Include the parent key only if all children are selected
          if (allChildrenSelected) {
            result.push(item.key);
          }
        }
      } else {
        // If the current item is not selected, use a different data structure
        result.push({
          key: item.key,
          selected: item.selected,
          childArr: item.childArr,
        });
      }
    };
  
    // Iterate through the initial array
    data.forEach((it) => {
      if (it.childArr === undefined && it.selected) {
        result.push(it.key || it.headerName);
      } else if (it.childArr && it.childArr.length > 0) {
        processItem(it);
      }
    });
  
    return result;
  };
  
  const getParent=(array)=>{
  console.log("RDYTHFFU",array)
let result=[]
array.forEach((it)=>{
if(it.selected==1){
  result.push(it.headerName)
}
})
return result
}

  useEffect(() => {
    const totalData = tabstore.state.tabs[tabstore.state.tabIndex].apiResponse;
    if (menu.length > 0) {
      let checkkey = getCheckKeyRecursive(totalData?.Message);
      let parentcheck=getParent(totalData?.Message)
      console.log("RYTFFYU",parentcheck)
      if (checkedKeys) {
        setCheckedKeys(checkkey);
      }
    }
  }, [menu]);
  /// here useeffect
  useEffect(() => {
    if (
      tabstore.state.tabs[tabstore.state.tabIndex].apiResponse?.Message
        ?.length > 0
    ) {
      console.log("covertToRequiredFormat");
      covertToRequiredFormat();
    }
  }, [tabstore.state.tabs[tabstore.state.tabIndex].apiResponse]);

  //// here is onckeck function
  const onCheck = (checkedKeys, info) => {
    // steps
    //checkeds keys give all checked values and info has halfchecked need to add in  checkedKeys
    /// iterrate over the array and toggle the value

    let modifiedCheck = checkedKeys.concat(info.halfCheckedKeys);
    let alllflattenData = getAllItemsPerChildren(menu);

    const userrolerecno =
      tabstore.state.tabs[tabstore.state.tabIndex]?.apiVariables?.userrolerecno;

    const updatedData = alllflattenData.map((item) => {
      // Check if the item's key is in modifiedCheck
      const isSelected = modifiedCheck.includes(item.key);

      // Update the selected property in the item
      return {
        ...item,
        selected: isSelected ? 1 : 0,
        userrolerecno: userrolerecno,
      };
    });

    console.log("updatedData", updatedData);

    tabstore.dispatch({
      type: "UPDATE_DATA",
      fieldType: "changedRows",
      text: updatedData,
    });

    setCheckedKeys(checkedKeys);
  };

  /// onselect functionality
  const onSelect = (selectedKeysValue, info) => {
    setSelectedKeys(selectedKeysValue);
  };

  const onExpand = (expandedKeysValue) => {
    setExpandedKeys(expandedKeysValue);
    setAutoExpandParent(false);
  };

  //// flatten the tree data to array of data
  const getAllItemsPerChildren = (array) => {
    var result = [];
    array.forEach(function (a) {
      result.push(a);
      if (Array.isArray(a.children)) {
        result = result.concat(getAllItemsPerChildren(a.children));
      }
    });
    return result;
  };
/// logic for table dispaly data
  useEffect(()=>{

  
  const data=getAllItemsPerChildren(updatedData)
  tabstore.dispatch({
    type: "UPDATE_DATA",
    fieldType: "changedRows",
    text: data,
  });

  },[updatedData])

  console.log("RRRRRRRRRRR", updatedData,menu);
  return (
    <Fragment>
      <div style={{ marginLeft: "40%" }}>
        <UserDropDown />
        {contextHolder}
        {tabstore.state.tabs[tabstore.state.tabDataIndex]?.executeLoading ===
          true ||
        tabstore.state.tabs[tabstore.state.tabDataIndex]?.saveLoading ===
          true ? (
          <div style={{ ...loaderStyle }}>
            <Spin size="large" />
          </div>
        ) : (
          // <div>
          //   <Tree
          //     // className={style.tree}
          //     checkable
          //     onExpand={onExpand}
          //     expandedKeys={expandedKeys}
          //     autoExpandParent={autoExpandParent}
          //     onCheck={onCheck}
          //     checkedKeys={checkedKeys}
          //     onSelect={onSelect}
          //     selectedKeys={selectedKeys}
          //     treeData={menu}
          //     key={menu}
          //   />
           
          // </div>
          null
        )}
        
      </div>
     <div style={{marginTop:"2%"}}> <CustomTree treeData={menu} key={menu} returnValue={setUpdatedData}/></div>
    </Fragment>
  );
};
export default UsserRolePermission;
const loaderStyle = {
  height: "max-content",
  width: "max-content",
  display: "flex",
  alignContent: "center",
  margin: "50px auto",
};

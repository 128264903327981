import {
  CaretRightFilled,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
} from "@ant-design/icons";
import {
  Button,
  Input,
  Space,
  Tooltip,
  Typography,
  Divider,
  Modal,
} from "antd";
import Help from "../Screens/Help/Help";
import Logo from "../Screens/Help/Logo.jpeg";
import {
  AiOutlineEdit,
  AiOutlineDownload,
  AiFillSave,
  AiOutlineDelete,
  AiOutlineReload,
  AiOutlineCopy,
  AiOutlineArrowLeft,
  AiOutlinePrinter,
  AiOutlineCaretDown,
  AiOutlineUser,
  AiOutlineQuestion,
  AiOutlineLogout,
  AiOutlineCheckCircle,
} from "react-icons/ai";
import { RiFootprintFill } from "react-icons/ri";
import React, { useContext, useEffect, useState } from "react";
import { TabStore } from "../TabsStructure/TabsStore";
import {
  checkFastPath,
  executeAPI,
  saveUpdatedTable,
  updateValue,
} from "../TabsStructure/tabFunctions";
import { AuthStore } from "../Auth/AuthStore";
import Time from "../CommonControllers/Components/Time";
import useExecuteApi from "../CommonControllers/Hooks/useExecuteApi";
import useSaveApi from "../CommonControllers/Hooks/useSaveApi";
import { borderRadius, fontSize, textAlign } from "@mui/system";
import PubSub from "pubsub-js";
import useExecute from "../CommonControllers/Hooks/useExecute";
import ExecuteComponentTesting from "../CommonControllers/Components/ExecuteComponents/ExecuteComponentTesting";
import useSaveChangedRows from "../CommonControllers/Hooks/useSaveChangedRows";
import ReactPrint from "../CommonControllers/Print/ReactPrint";
import { Excel } from "antd-table-saveas-excel";
import { currentTabData } from "../CommonControllers/Variable";
import {
  dateFormatter,
  formatCurrency,
  formatCurrency3,
  removeFormatCurrency,
} from "../CommonControllers/AppFunction";
import useDeleteApi from "../CommonControllers/Hooks/useDeleteApi";
import { baseUrl } from "../CommonControllers/Urls";
import axios from "axios";
import { getfastpathapi } from "../CommonControllers/ApiCalls";

function TaskBar() {
  const [isOpen, setIsModalOpen] = useState(false);
  const [count, setcount] = useState(0);
  const [modalKey, setModalKey] = useState(0);
  const auth = useContext(AuthStore);
  const [fst, setFst] = useState("");
  const [changedrowscount, setChangedrowscount] = useState("");
  const executeAPIhook = useExecuteApi();
  const saveApi = useSaveApi();
  const [isprint, setPrint] = useState(null);
  const ChangedRows = useSaveChangedRows();
  const date = auth?.state?.workdate?.toString();
const deleteApi = useDeleteApi();

  const todaysDate = new Date().toJSON()?.slice(0, 10)?.replace(/-/g, "/");

  // setting all the usestates and other info required for excel

  const [col, setColumns] = useState([]);
  const [rows, setRow] = useState([]);

  const tabstore = useContext(TabStore);
  let response = tabstore?.state?.tabs[tabstore.state.tabIndex]?.apiResponse;
  let itemresponse =
    tabstore?.state?.tabs[tabstore.state.tabIndex]?.apiVariables?.withitems;
  let fastpath = tabstore?.state?.tabs[tabstore.state.tabIndex]?.name;
  let withserialno =
    tabstore?.state?.tabs[tabstore.state.tabIndex]?.apiVariables?.withserialno;

  const toolTipColor = auth?.state?.userdata?.theme[0]?.btncolor;
  const style = {
    fontSize: "20px",
  };
  const { Text, Link } = Typography;

  const tabs = useContext(TabStore);
  const toggleCollapsed = () => {
    tabs.dispatch({ type: "Toggle_Drawer" });
  };
  let execute = useExecute();
  // useEffect(() => {
  //   setFst(tabs?.state?.tabs[tabs.state.tabIndex]?.name);
  // }, [tabs?.state?.tabs[tabs.state.tabIndex]?.name])

  function onKeyPress(e) {
    if (e.keyCode == 13) {
      if (e.target.value != "") {
        let { x } = checkFastPath(fst, tabs);

        if (x === false) {
          alert(`${fst} is currenty not active in system`);
        } else {
        }
        // setFst(e.target.value); Commented By Kaushik for initial issue of removing the
        // {Pradeep task id -320 removed redo from taskbar for MS115}
        //  if((fst?.toUpperCase()!="MS115")){
        setFst(""); // Uncommeted by Kaushik
        //     }
        ///{end      end }
      }
    }
  }

  async function handleEdit() {
    if (tabs.state.tabs[tabs.state.tabIndex]?.mode == "edit") {
      updateValue(tabs, "mode", "view", "UPDATE_DATA");
    } else {
      updateValue(tabs, "mode", "edit", "UPDATE_DATA");
    }
  }
  async function handleSave() {
    // Here What do we do?
    saveApi();
  }
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleOpen = () => {
    setIsModalOpen(true);
    setModalKey(modalKey + 1);
  };
  useEffect(() => {
    setIsModalOpen(false);
  }, []);
  /// Redo button Disable in ms115  start
  useEffect(() => {
    if (
      tabs?.state?.tabs[tabs?.state?.tabIndex]?.name == "MS115" ||
      tabs?.state?.tabs[tabs?.state?.tabIndex]?.name == "TR014"
    ) {
      //{Pradeep task id -337}
      setFst(tabs?.state?.tabs[tabs?.state?.tabIndex]?.name);
    } else {
      setFst("");
    }
  }, [tabs?.state?.tabIndex]);
  /// Redo button Disable in ms115  end
  function buttonDisabled(fst, buttonName) {
    const currentTab = tabs.state.tabs[tabs.state.tabIndex];
    const tabName = currentTab?.name || "";
    const fstUpper = fst?.toUpperCase() || "";
  
    // Check if Save button should be disabled
    if (
      currentTab?.changedRows?.validated === "false" &&
      buttonName === "Save"
    ) {
      return true;
    }
  
    // Special condition for RP117 to ensure Redo button is not disabled
    if (fstUpper === "RP117" && buttonName === "Redo") {
      return false;
    }
  
    // Disable specific buttons for TR063 except Delete
    if (
      tabName === "TR063" &&
      ["Edit", "Copy", "Download"].includes(buttonName)
    ) {
      return true;
    }
  
    // Disable buttons for RP except RP117
    if (
      fstUpper.slice(0, 2) === "RP" &&
      fstUpper !== "RP117" &&
      ["Edit", "Save", "Delete", "Copy"].includes(buttonName)
    ) {
      return true;
    }
  
    // Disable Redo for MS115
    if (fstUpper === "MS115" && buttonName === "Redo") {
      return true;
    }
  
    // Disable buttons when fst is empty and no tabs are open, except Help and Logout
    if (
      fst === "" &&
      tabs?.state?.tabs?.length === 0 &&
      buttonName !== "Help" &&
      buttonName !== "Logout"
    ) {
      return true;
    }
  
    // Disable Search button when fst is empty
    if (fst === "" && buttonName === "Search") {
      return true;
    }
  
    // Disable specific buttons for TR tabs except TR005 and TR063
    if (
      tabName.includes("TR") &&
      !["TR005", "TR063"].includes(tabName) &&
      ["Edit", "Copy", "Delete", "Download"].includes(buttonName)
    ) {
      return true;
    }
  
    // Disable specific buttons for PR tabs
    if (
      tabName.includes("PR") &&
      !["Print", "Execute", "Logout", "Help", "Download"].includes(buttonName)
    ) {
      return true;
    }
  
    // Disable specific buttons for RP tabs with exceptions
    if (
      tabName.includes("RP") &&
      ![
        "Print",
        "Execute",
        "Logout",
        "Help",
        "Download",
        "Delete",
        "Redo",
      ].includes(buttonName)
    ) {
      return true;
    }
  
    // Disable Save button for specific MS tabs
    if (
      ["MS027", "MS030", "MS032", "MS033"].some((msTab) =>
        tabName.includes(msTab)
      ) &&
      buttonName === "Save"
    ) {
      return true;
    }
  
    // Default to not disabled
    return false;
  }
  

  ////////////////////////////////////////////////////////////////
  /// function for reset the filter components
  const reseFun = () => {
    let x = tabs.state.tabs[tabs.state.tabIndex];
  
    if (currentTabData(tabs)?.name == "RP117") {
      // const payld = {
      //   tenantrecno: auth?.state?.userdata?.tenantrecno,
      //   domainrecno: auth?.state?.userdata?.domainrecno,
      //   userrolerecno: auth?.state?.userdata?.userrolerecno,
      //   domainuserrecno: auth.state.userdata.recno,
      //   fastpath: currentTabData(tabs)?.name,
      // };
      // getfastpathapi(payld).then((res) => {
      //   if (res?.Success) {
      //     //setPageDetails(res?.Message);
      //   }
      // });
      tabs.dispatch({
        type: "UPDATE_DATA",
        fieldType: "excelUpdate",
        text: true,
      });
    }
  
    tabs.dispatch({ type: "UPDATE_DATA", fieldType: "apiVariables", text: {} });
    tabs.dispatch({ type: "UPDATE_DATA", fieldType: "refresh", text: true });
    tabs.dispatch({ type: "UPDATE_DATA", fieldType: "changedRows", text: {} });
    tabs.dispatch({
      type: "UPDATE_DATA",
      fieldType: "pageStatus",
      text: "Success",
    });
  
    // Set refresh to false after 5 seconds (5000 milliseconds)
    setTimeout(() => {
      tabs.dispatch({ type: "UPDATE_DATA", fieldType: "refresh", text: false });
    }, 5000);
  };

  // useeffect required for excel

  useEffect(() => {
    let arr = [];
    setColumns(
      response?.columnsinfo?.map((item) => {
        return { ...item, width: item?.width };
      })
    );

    let reqarr = [];
    let totalcolumn = -1;
    if (Array.isArray(response?.Message)) {
      for (let i = 0; i < response?.Message?.length; i++) {
        let responserow = response?.Message[i];

        for (let i = 0; i < response?.columnsinfo?.length; i++) {
          // to display the column in required format e.g. date, decimal
          let responsecolumntype = response?.columnsinfo[i]["type"];
          let responsecolumnname = response?.columnsinfo[i]["dataIndex"];
          if (
            responsecolumntype == "date" &&
            responserow[responsecolumnname] != ""
          )
            responserow[responsecolumnname] = dateFormatter(
              responserow[responsecolumnname]
            );
          else if (
            responsecolumntype == "decimal" &&
            responserow[responsecolumnname] != ""
          )
            responserow[responsecolumnname] = responserow[responsecolumnname]
              ? formatCurrency(
                  Number(removeFormatCurrency(responserow[responsecolumnname]))
                )
              : "";
          else if (
            responsecolumntype == "decimal3" &&
            responserow[responsecolumnname] != ""
          )
            responserow[responsecolumnname] = responserow[responsecolumnname]
              ? formatCurrency3(
                  Number(removeFormatCurrency(responserow[responsecolumnname]))
                )
              : "";
        }
        arr.push(responserow);

        // to display items in the message

        if (itemresponse) {
          for (let j = 0; j < response?.Message[i]?.items?.length; j++) {
            let itemobj = responserow.items[j];
            for (let i = 0; i < response?.columnsinfo?.length; i++) {
              let itemcolumntype = response?.columnsinfo[i]["type"];
              let itemcolumnname = response?.columnsinfo[i]["dataIndex"];
              if (itemcolumntype == "date" && itemobj[itemcolumnname] != "")
                itemobj[itemcolumnname] = dateFormatter(
                  itemobj[itemcolumnname]
                );
              else if (itemcolumntype == "decimal")
                itemobj[itemcolumnname] = itemobj[itemcolumnname]
                  ? formatCurrency(
                      Number(removeFormatCurrency(itemobj[itemcolumnname]))
                    )
                  : "";
              else if (itemcolumntype == "decimal3")
                itemobj[itemcolumnname] = itemobj[itemcolumnname]
                  ? formatCurrency3(
                      Number(removeFormatCurrency(itemobj[itemcolumnname]))
                    )
                  : "";
            }
            arr.push(itemobj);
            if (withserialno && itemobj?.batcharray) {
              for (let k = 0; k < itemobj?.batcharray?.length; k++) {
                let itembatcharray = itemobj?.batcharray[k];
                for (let m in itembatcharray) {
                  if (m == "expdate" || m == "manudate") {
                    itembatcharray[m] = dateFormatter(itembatcharray[m]);
                  } else {
                    itembatcharray[m] = itembatcharray[m];
                  }
                }
                arr.push(itembatcharray);
              }
            }
          }
        }
      }
    }

    // create total of the columns
    reqarr = [];
    totalcolumn = -1;
    for (let i = 0; i < response?.columnsinfo?.length; i++) {
      let colfortotal = response?.columnsinfo[i]?.fortotal;
      if (colfortotal) {
        let amt = 0;
        for (let j = 0; j < response?.Message?.length; j++) {
          amt = response?.Message?.reduce((x, y) => {
            return (
              Number(
                removeFormatCurrency(y[response?.columnsinfo[i]?.dataIndex])
              ) + x
            );
          }, 0);
        }
        var columnname = response?.columnsinfo[i][`dataIndex`];
        if (columnname) {
          var totalobj = {
            [columnname]: formatCurrency(amt),
          };
        }
      } else if (totalcolumn == -1) {
        totalcolumn = i;
      }
      reqarr.push(totalobj);
    }

    if (totalcolumn > -1) {
      var columnname = response.columnsinfo[totalcolumn][`dataIndex`];
      if (columnname) {
        var totalobj = {
          [columnname]: "Total",
        };
      }
      reqarr.push(totalobj);
    }
    // create total of the columns

    const mergedObject = reqarr.reduce((acc, obj) => ({ ...acc, ...obj }), {});

    arr.push(mergedObject);
    setRow(arr);
  }, [response, itemresponse, withserialno]);

  //new function of excel report

  const handleClickExcel = () => {
    const excel = new Excel();

    excel
      .addSheet("test")
      .addColumns(col)
      .addDataSource(rows, {
        str2Percent: true,
      })
      .saveAs(currentTabData(tabs)?.name + "-" + auth.state.workdate + ".xlsx");
  };

  ///// print button ////////
  const PrintButton = (x) => {
    //let x=tabs.state.tabs[tabs.state.tabIndex]?.changedRows?.isPrint
    if (currentTabData(tabs)?.selectedDocuments?.length > 0) {
      tabs.dispatch({
        type: "UPDATE_DATA",
        fieldType: "pageStatus",
        text: "Success",
      });
      tabs.dispatch({
        type: "UPDATE_PRINT",
        printUrl: currentTabData(tabs)?.printUrl,
        printIns: currentTabData(tabs)?.selectedDocuments,
      });
    }
  };

  /// here is the function for the delete ///
  const DeleteFun = (x) => {
    // Display a confirmation message before proceeding with the delete action
    const userConfirmed = window.confirm(
      "Are you sure you want to delete the selected item(s)? This action cannot be undone."
    );
  
    if (userConfirmed) {
      // If the user confirmed, proceed with the delete action
      // if (currentTabData(tabs)?.selectedDocuments?.length > 0) {
      //   tabs.dispatch({
      //     type: "UPDATE_DATA",
      //     fieldType: "pageStatus",
      //     text: "Success",
      //   });
      //   tabs.dispatch({
      //     type: "UPDATE_DELETE",
      //     deleteUrl: currentTabData(tabs)?.deleteUrl,
      //     deleteIns: currentTabData(tabs)?.selectedDocuments,
      //   });
      // }
      deleteApi.execute();
    } else {
      // If the user canceled, you can handle it here if needed
      console.log("Deletion canceled by the user.");
    }
  };
  
  //// logout functionality date 3 april 2023
  const logOut = () => {
    tabs.dispatch({ type: "INITIALIZE" });
    auth.dispatch({
      type: "LOGOUT",
      isLoggedIn: false,
      sessionValid: false,
      activityValid: false,
    });
  };

  //   const downloadExcel = async (url) => {
  //     try {
  //         const payload = {
  //             tenantrecno: 3,
  //             domainrecno: 1,
  //             domainuserrecno: 341,
  //             userrolerecno: 1,
  //             fastpath: "RP048",
  //             Tenant: "Testing",
  //             AppShortName: "Bcore",
  //             trdate: 20240412,
  //             forreport: "Y"
  //         };

  //         const response = await axios.post(
  //           url,
  //             payload,
  //             { responseType: 'blob' } // Ensure response is treated as binary data
  //         );

  //         const url = window.URL.createObjectURL(new Blob([response.data]));

  //         const a = document.createElement('a');
  //         a.href = url;
  //         a.download = 'myexportedfile.xlsx'; // Set the filename to myexportedfile.xlsx
  //         a.click();

  //         window.URL.revokeObjectURL(url);
  //     } catch (error) {
  //         console.error("Error downloading Excel:", error);
  //         // Handle error here
  //     }
  // }

  return (
    <div
      className="taskbar"
      //~! If everything works fine, dont touch this CSS else you'll be FIRED
      style={{ ...mainFrameStyles }}
    >
      <div style={{ ...navbar }}>
        <div>
          <Button
            className="taskbar_btn"
            onClick={toggleCollapsed}
            style={{ position: "relative", top: "-2px" }}
            icon={
              tabs.state.menu ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />
            }
          ></Button>

          <Input
            id="fastpath"
            placeholder="Fast Path"
            style={{ width: "150px", position: "relative", top: "-4.5px" }}
            value={fst}
            onChange={(e) => setFst(e.target.value)}
            onKeyDown={onKeyPress}
          />
          <Tooltip title="Search" color={toolTipColor}>
            <Button
              className="taskbar_btn"
              icon={<CaretRightFilled style={{ marginTop: "4px" }} />}
              style={{
                padding: "0px 30px 0px 12px",
                color: "grey",
                position: "relative",
                top: "-1px",
              }}
              onClick={(e) => {
                checkFastPath(document.getElementById("fastpath").value, tabs);
              }}
              disabled={buttonDisabled(fst, "Search")}
            />
          </Tooltip>
          <Tooltip title="Edit" color={toolTipColor}>
            <Button
              className="taskbar_btn"
              icon={<AiOutlineEdit style={{ marginTop: "4px" }} />}
              style={{ padding: "0px 28px 0px 12px", color: "grey" }}
              onClick={handleEdit}
              disabled={
                buttonDisabled(fst, "Edit") || tabs?.state?.tabs?.length == 0
              }
            />
          </Tooltip>
          {/* task-id-254 rajendra  start task bar having two download button one is remove */}
          {/* <Tooltip title="Download" color={toolTipColor}>
            <Button
              icon={<AiOutlineDownload style={{ marginTop: "4px" }} />}
              style={{ padding: "0px 28px 0px 12px", color: "grey" }}
              disabled={
                buttonDisabled(fst, "Download") ||
                tabs?.state?.tabs?.length == 0
              }
            />
          </Tooltip> 
              {/* task-id-254 rajendra  end */}
          <Tooltip title="Save" color={toolTipColor}>
            <Button
              className="taskbar_btn"
              icon={<AiFillSave style={{ marginTop: "4px" }} />}
              style={{ padding: "0px 28px 0px 12px", color: "grey" }}
              onClick={() => saveApi.execute(tabs.state.tabIndex)}
              disabled={
                buttonDisabled(fst, "Save") || tabs?.state?.tabs?.length == 0
              }
            />
          </Tooltip>
          <Tooltip title="Execute" color={toolTipColor}>
            <Button
              className="taskbar_btn"
              icon={<RiFootprintFill style={{ marginTop: "4px" }} />}
              style={{ padding: "0px 28px 0px 12px", color: "grey" }}
              onClick={() => {
                executeAPIhook.execute(tabs.state.tabIndex);
                // if(currentTabData(tabs).name=="RP048"){
                //   downloadExcel(baseUrl+currentTabData(tabs).apiUrl)
                // }
              }}
              disabled={
                buttonDisabled(fst, "Execute") || tabs?.state?.tabs?.length == 0
              }
            ></Button>
          </Tooltip>
          <Tooltip title="Delete" color={toolTipColor}>
            <Button
              className="taskbar_btn"
              icon={<AiOutlineDelete style={{ marginTop: "4px" }} />}
              style={{ padding: "0px 28px 0px 12px", color: "grey" }}
              // onClick={()=>DeleteFun(tabs?.state?.tabs[tabs?.state?.tabIndex].name)}
              onClick={() => {
               
                DeleteFun()

                //executeAPIhook.execute(); wrong execution code
              }}
              disabled={
                buttonDisabled(fst, "Delete") || tabs?.state?.tabs?.length == 0
              }
            />
          </Tooltip>
          <Tooltip title="Redo" color={toolTipColor}>
            <Button
              className="taskbar_btn"
              icon={<AiOutlineReload style={{ marginTop: "4px" }} />}
              style={{ padding: "0px 28px 0px 12px", color: "grey" }}
              // {Pradeep task id -320 removed redo from taskbar for MS115}
              disabled={
                buttonDisabled(fst, "Redo") || tabs?.state?.tabs?.length == 0
              }
              onClick={reseFun}
            />
          </Tooltip>
          <Tooltip title="Copy" color={toolTipColor}>
            <Button
              className="taskbar_btn"
              icon={<AiOutlineCopy style={{ marginTop: "4px" }} />}
              style={{ padding: "0px 28px 0px 12px", color: "grey" }}
              disabled={
                buttonDisabled(fst, "Copy") || tabs?.state?.tabs?.length == 0
              }
            />
          </Tooltip>
          <Tooltip title="Back" color={toolTipColor}>
            <Button
              className="taskbar_btn"
              icon={<AiOutlineArrowLeft style={{ marginTop: "4px" }} />}
              style={{ padding: "0px 28px 0px 12px", color: "grey" }}
              disabled={
                buttonDisabled(fst, "Back") || tabs?.state?.tabs?.length == 0
              }
            />
          </Tooltip>
          <Tooltip title="Download" color={toolTipColor}>
            <Button
              className="taskbar_btn"
              icon={<AiOutlineDownload style={{ marginTop: "4px" }} />}
              style={{ padding: "0px 28px 0px 12px", color: "grey" }}
              // onClick={() => PubSub.publishSync(fst + "_PRINT", "")}
              disabled={
                buttonDisabled(fst, "Download") ||
                tabs?.state?.tabs?.length == 0
              }
              // onClick={()=>PrintButton(tabs?.state?.tabs[tabs?.state?.tabIndex].name)}
              onClick={() => {
                handleClickExcel();
              }}
            />
          </Tooltip>
          <Tooltip title="Print" color={toolTipColor}>
            <Button
              className="taskbar_btn"
              icon={<AiOutlinePrinter style={{ marginTop: "4px" }} />}
              style={{ padding: "0px 28px 0px 12px", color: "grey" }}
              // onClick={() => PubSub.publishSync(fst + "_PRINT", "")}
              disabled={
                buttonDisabled(fst, "Print") || tabs?.state?.tabs?.length == 0
              }
              onClick={() =>
                PrintButton(tabs?.state?.tabs[tabs?.state?.tabIndex].name)
              }
            />
          </Tooltip>
          {/* <Tooltip title="User" color={toolTipColor}>
            <Button
              icon={
                <>
                  <AiOutlineUser style={{marginTop:"4px"}}  /> <AiOutlineCaretDown />
                </>
              }
              style={{ padding: "0px 38px 0px 12px", color: "grey" }}
            />
          </Tooltip> */}
          <Tooltip title="Logout" color={toolTipColor}>
            <Button
              className="taskbar_btn"
              icon={<AiOutlineLogout style={{ marginTop: "4px" }} />}
              style={{ padding: "0px 28px 0px 12px", color: "grey" }}
              disabled={buttonDisabled(fst, "Logout")}
              onClick={logOut}
            />
          </Tooltip>
          <Tooltip title="Help" color={toolTipColor}>
            <Button
              className="taskbar_btn"
              onClick={handleOpen}
              icon={<AiOutlineQuestion style={{ marginTop: "4px" }} />}
              style={{ padding: "0px 28px 0px 12px", color: "grey" }}
              disabled={buttonDisabled(fst, "Help")}
            />
            <Modal
              key={modalKey}
              style={{ marginTop: "-2%", padding: "2%", top: 40 }}
              width={1300}
              title="Bcore Help"
              open={isOpen}
              onOk={handleOk}
              onCancel={handleCancel}
              okButtonProps={{ style: { display: "none" } }}
              visible={true}
              cancelButtonProps={{ style: { display: "none", zIndex: 9999 } }}
              header={
                <div style={{ display: "flex", alignItems: "center" }}>
                  <img
                    src={Logo}
                    alt="Logo"
                    style={{ width: 40, height: 40 }}
                  />
                  <h3 style={{ marginBottom: 0 }}>My Modal</h3>
                </div>
              }
            >
              <Help onCloseModal={handleCancel} />
            </Modal>
          </Tooltip>
          <Tooltip title="Check Validations" color={toolTipColor}>
            <Button
              className="taskbar_btn"
              icon={<AiOutlineCheckCircle style={{ marginTop: "4px" }} />}
              style={{ padding: "0px 28px 0px 12px", color: "grey" }}
              disabled={buttonDisabled(fst, "Logout")}
              onClick={(e) => {
                alert("Coming Soon!");
              }}
            />
          </Tooltip>

          <div style={{ ...headerName }} className="taskbar">
            <div>
              {" "}
              <h2 className="taskbar_header" style={{ padding: "3px" }}>
                {tabs.state.tabs[tabs.state.tabIndex]?.headerName}
              </h2>
            </div>

            <div style={{ ...taskbarFrame }}>
              <Space.Compact></Space.Compact>

              {/* <h3>{auth?.state?.userdata?.tenantname}</h3> */}

              <Space.Compact size={[0, 1]} wrap>
                <Button
                  className="taskbar_btn"
                  style={{
                    padding: "0px 12px 0px 3.5px",
                    border: "0.8px solid ghostwhite",
                    outline: "none",
                  }}
                >
                  {auth?.state?.userdata?.descn}
                </Button>
                <Button
                  className="taskbar_btn"
                  style={{
                    padding: "0px 12px 0px 3.5px",
                    border: "0.8px solid ghostwhite",
                    outline: "none",
                  }}
                >
                  {auth?.state?.userdata?.role[0]}
                </Button>

                <Button
                  className="taskbar_btn"
                  style={{
                    padding: "0px 12px 0px 3.5px",
                    border: "0.8px solid ghostwhite",
                    outline: "none",
                  }}
                >
                  4.0.43
                </Button>
                <Button
                  className="taskbar_btn"
                  style={{
                    padding: "0px 12px 0px 3.5px",
                    border: "0.8px solid ghostwhite",
                    outline: "none",
                  }}
                >
                  {`${date?.slice(-2)}/${date?.slice(4, 6)}/${date?.slice(
                    0,
                    4
                  )}`}
                </Button>
                <Button
                  className="taskbar_btn"
                  style={{
                    padding: "0px 12px 0px 3.5px",
                    border: "0.8px solid ghostwhite",
                    outline: "none",
                  }}
                >
                  {`${todaysDate?.slice(-2)}/${todaysDate?.slice(
                    5,
                    7
                  )}/${todaysDate?.slice(0, 4)}`}
                </Button>
                <Button
                  className="taskbar_btn"
                  style={{
                    padding: "0px 12px 0px 3.5px",
                    border: "0.8px solid ghostwhite",
                    outline: "none",
                  }}
                >
                  <Time />
                </Button>
                <Button
                  className="taskbar_btn"
                  style={{
                    padding: "0px 12px 0px 3.5px",
                    border: "0.8px solid ghostwhite",
                    outline: "none",
                  }}
                >
                  {/* <Time /> */}
                  {auth?.state?.userdata?.domaindescn}
                </Button>
              </Space.Compact>
            </div>
          </div>
        </div>
        <div>
          <div style={{ ...images }}>
            <div>
              <img
                src={`data:image/png;base64,${auth?.state?.userdata?.logo}`}
                style={{
                  width: "70px",
                  height: "45px",
                  marginTop: "1px",
                  borderRadius: "3%",
                }}
              />
            </div>
            <div>
              <h2 style={{ ...head }}>{auth?.state?.userdata?.tenantname}</h2>{" "}
            </div>
          </div>
          <div style={{ width: "100%" }}>
            {" "}
            <h5> Powered by Sutradhar</h5>{" "}
          </div>
        </div>
      </div>
      <div>
        {/* <h6>
          {tabs.state.tabs[tabs.state.tabIndex]?.pageStatus
            ? tabs.state.tabs[tabs.state.tabIndex]?.pageStatus
            : "Ready.."}
        </h6> */}
      </div>
    </div>
  );
}

export default TaskBar;

// CSS

const headerName = {
  display: "flex",
  fontSize: "12px",
  justifyContent: "space-between",
  // gap:"50px",
  alignItems: "center",
  //backgroundColor: "ghostwhite",
  padding: "4px",
};

const taskbarFrame = {
  display: "flex",
  // justifyContent: "space-between",
  // alignItems: "center",
  //backgroundColor: "ghostwhite",
  // marginRight:"90px"

  // marginRight:"30%"
};

const images = {
  display: "flex",
  alignItems: "center",
  gap: "5px",
};

const head = {
  // marginRight: "79%",
  width: "100%",

  // backgroundColor: "ghostwhite",
};
const navbar = {
  display: "flex",
  justifyContent: "space-between",
  marginRight: "13px",
};

const mainFrameStyles = {
  position: "sticky",
  top: 0,
  zIndex: 99,
  //backgroundColor: "ghostwhite",
  padding: "1px",
};

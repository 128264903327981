import React, { useState } from "react";

const Time = () => {
  const [currTime, setCurrTime] = useState("");

  const calculateTime = () => {
    const fullTime = (n) => (n < 10 ? "0" + n : n);
    let time = new Date();
    let hour = time.getHours();
    let minute = time.getMinutes();
    let seconds = time.getSeconds();
    let isAmPm = hour >= 12 ? "PM" : "AM";
    hour = hour % 12;
    hour = hour ? hour : 12;
    hour = fullTime(hour);
    minute = fullTime(minute);
    seconds = fullTime(seconds);

    setCurrTime(`${hour}:${minute}:${seconds} ${isAmPm}`);
  };

  setInterval(() => {
    calculateTime();
  }, 1000);

  return (
    <>
      <div>{currTime}</div>
    </>
  );
};

export default Time;

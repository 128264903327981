import React, { useEffect, useState } from "react";

const PrintImage = ({ item,pageNumber}) => {
  console.log("item?.height",item?.fieldboxheight)

  return (
    <div>
      {(item?.fieldyto && item?.fieldxto)!=0?(
        <div
          style={{
            // position: "relative", // Make sure the container has a position other than the default 'static'
            width: `${item?.fieldxto}px`,
            height: `${item?.fieldyto}px`,
            position:"absolute"
          }}
        >
          <img
            style={{
              height: "100%", // Set the height of the image to 100% of the container's height
              width: "100%", // Set the width of the image to 100% of the container's width
              objectFit: "cover", // Make the image cover the container while maintaining its aspect ratio
              position: "absolute",
              // border: item?.printbox === 1 ? "1px solid black" : null,
              left: `${item?.fieldx}px`,
              // top: `${(pageNumber - 1)*1280 + item.fieldy}px`,
              top: `${ item?.fieldy}px`,
            }}
            src={"data:image/png;base64,"+item?.fieldvalue}
          />
        </div>
      ) : (
        <div
        style={{
        position:"absolute"
        }}>
          <img
            style={{
              objectFit: "cover", // Make the image cover the container while maintaining its aspect ratio
              position: "absolute",
              // border: item?.printbox === 1 ? "1px solid black" : null,
              left: `${item?.fieldx}px`,
              // top: `${(pageNumber - 1)*1280 + item.fieldy}px`,
              top: `${ item?.fieldy}px`,
            }}
            src={"data:image/png;base64,"+item?.fieldvalue}
          />
        </div>
      )}
    </div>
  );
};
export default PrintImage;

import { createContext, useContext, useReducer } from "react";
import { Voucher_Account } from "./RcTransactionHelper";

const TransactionContext = createContext(null);

const TransactionDispatchContext = createContext(null);

export function TransactionProvider({ children }) {
  const [state, dispatch] = useReducer(TransactionReducer, initialState);

  //console.log("CashTransactionProvider");

  return (
    <TransactionContext.Provider value={state}>
      <TransactionDispatchContext.Provider value={dispatch}>
        {children}
      </TransactionDispatchContext.Provider>
    </TransactionContext.Provider>
  );
}

export function useTransactionContext() {
  return useContext(TransactionContext);
}

export function useTransactionDispatch() {
  return useContext(TransactionDispatchContext);
}

function TransactionReducer(state, action) {
  // console.log("CashTranReducer" + action.UserSelectedDate);
  // console.log("CashTranReducer" + JSON.stringify(state));
  //console.log("REDUCER CALLED -- ACTION - " + action);

  switch (action.type) {
    case "DATE_UPDATED": {
      return { ...state, selected_date: Number(action.selected_date) };
    }
    case "DATE_UPDATED_FORMATTED": {
      return {
        ...state,
        selected_date_formatted: action.selected_date_formatted,
      };
    }
    case "SET_TRANSACTION_TYPE": {
      return { ...state, selected_trantype: action.selected_trantype };
    }

    case "SET_GL_ACCOUNT_1_SELECTED": {
      return {
        ...state,
        gl_account_1: action.gl_account_1,
      };
    }

    case "SET_GL_ACCOUNT_2_SELECTED": {
      return {
        ...state,
        gl_account_2: action.gl_account_2,
      };
    }

    case "GL_UPDATED": {
      return {
        ...state,
        selected_gl: action.selected_gl,
        selected_gl_descn: action.selected_gl_descn,
      };
    }
    case "ACCOUNT_UPDATED": {
      return {
        ...state,
        selected_account: action.selected_account,
        selected_account_descn: action.selected_account_descn,
      };
    }
    case "VOUCHER_AMT_UPDATED": {
      return { ...state, voucher_amount: action.voucher_amount };
    }
    case "UPDATE_PARTICULARS": {
      return { ...state, particulars: action.particulars };
    }
    case "UPDATE_PENDING_INSTRUMENTS": {
      return { ...state, pending_instruments: action.pending_instruments };
    }

    case "UPDATE_GL_LIST": {
      return { ...state, gl_list: action.gl_list };
    }

    case "CLEAR_GL_LIST": {
      const temp_gl_list = [];
      return { ...state, gl_list: temp_gl_list };
    }

    case "OPEN_GL_SETTLEMENT_POPUP_EDIT": {
      console.log("OPEN_GL_SETTLEMENT_POPUP_EDIT");

      return {
        ...state,
        selected_ref_id_to_settle: action.selected_ref_id_to_settle,
        gl_list: action.updated_gl_list,
      };
    }

    case "OPEN_GL_SETTLEMENT_POPUP": {
      console.log("OPEN_GL_SETTLEMENT_POPUP");
      return {
        ...state,
        selected_ref_id_to_settle: action.selected_ref_id_to_settle,
        selected_ref_instrument: action.selected_ref_instrument,
      };
    }

    case "CLOSE_GL_SETTLEMENT_POPUP": {
      const GL_Updated_List_Clear = state.gl_list.map((row) => {
        return {
          ...row,
          amount: 0,
          selected_ref_instrument: null,
        };
      });

      return {
        ...state,
        selected_ref_id_to_settle: 0,
        gl_list: GL_Updated_List_Clear,
      };
    }

    case "UPDATE_ONACCOUNT_AMOUNT": {
      const newData = state.pending_instruments.map((row) => {
        if (row.refno == action.refno_updated) {
          return {
            ...row,
            on_account_amount: action.on_account_amount,
          };
        }
        return row;
      });
      return { ...state, pending_instruments: newData };
    }

    case "CURRENT_GL_SETTLEMENT_AMOUNT": {
      const newData = state.gl_list.map((row) => {
        if (row.code == action.code_updated) {
          return {
            ...row,
            amount: Number(action.amount),
          };
        }
        return row;
      });
      return { ...state, gl_list: newData };
    }

    case "SAVE_GL_SETTLEMENT_AMOUNT_POPUP": {
      const GL_Updated_List = state.gl_list.filter((row) => {
        return row.amount > 0;
      });

      console.log("SAVE_GL_SETTLEMENT_AMOUNT_POPUP");
      console.log(GL_Updated_List);

      console.log("state.selected_ref_id_to_settle ");
      console.log(state.selected_ref_id_to_settle);

      let temp_other_gl_amt = 0;
      GL_Updated_List.forEach((element) => {
        if (element.amount > 0) {
          temp_other_gl_amt =
            Number(temp_other_gl_amt) + Number(element.amount);
        }
      });

      const newData = state.pending_instruments.map((row) => {
        if (row.refno == state.selected_ref_id_to_settle) {
          return {
            ...row,
            childrows: GL_Updated_List,
            othergl_total: temp_other_gl_amt,
          };
        }
        return row;
      });

      const GL_Updated_List_Clear = state.gl_list.map((row) => {
        return {
          ...row,
          amount: 0,
        };
      });

      return {
        ...state,
        pending_instruments: newData,
        selected_ref_id_to_settle: 0,
        gl_list: GL_Updated_List_Clear,
        selected_ref_instrument: null,
      };
    }

    case "UPDATE_PARTICULARS": {
      return { ...state, particulars: action.particulars };
    }
    case "CHEQUENO_UPDATED": {
      return { ...state, cheque_no: action.cheque_no };
    }
    case "BANKNAME_UPDATED": {
      return { ...state, bank_name: action.bank_name };
    }
    case "UPDATE_VOUCHER_REFNO": {
      return { ...state, voucher_reference_no: action.voucher_reference_no };
    }
    case "UPDATE_VOUCHER_TYPE": {
      // cash / Bank / JV

      // change 16JAN2023 , only RECEIPT / PAYMENT
      return { ...state, voucher_type: action.voucher_type };
    }
    case "SET_MODE": {
      // EDIT / ADD
      return { ...state, selected_mode: action.selected_mode };
    }
    case "SET_VOUCHERNO_FOR_EDIT": {
      // EDIT / ADD
      return { ...state, selected_voucherno: action.selected_voucherno };
    }
    case "SAVE_ENTRY": {
      let array_temp = action.accounting_vouchers;

      // array_temp.sort((a, b) => a.CDFlag - b.CDFlag);

      return { ...state, accounting_vouchers: array_temp };
    }
    case "TRIGGER_DISTRIBUTE_AMOUNT": {
      // EDIT / ADD
      return { ...state, trigger_distribute: action.trigger_distribute };
    }
    default: {
      throw Error("Unknown action: " + action.type);
    }
  }
}

const initialState = {
  selected_mode: "ADD", //   ADD / EDIT
  selected_voucherno: "", // set this in case of Edit Only
  selected_date: null,
  selected_date_formatted: "",

  voucher_type: "1", //Cash /Bank /JV

  gl_account_1: Voucher_Account,
  gl_account_2: Voucher_Account,

  //selected_gl_1: 0,
  //selected_gl_descn_1: "",

  //selected_account_1: 0,
  //selected_account_descn_1: "",

  //selected_gl_2: 0,
  //selected_gl_descn_2: "",

  //selected_account_2: 0,
  //selected_account_descn_2: "",
  cheque_date: "",
  cheque_no: "",
  bank_name: "",
  voucher_amount: 0,
  voucher_reference_no: 0,
  particulars: "",
  pending_instruments: [],
  gl_list: [],
  selected_ref_id_to_settle: 0,
  selected_ref_instrument: null,
  total_paid_amt: 0,
  total_adjusted_amt: 0,
  total_on_account: 0,
  trigger_distribute: false,
  accounting_vouchers: [],
};

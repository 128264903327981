// Nohit Date: 14/03/2023 Create This Component for search bill modal

import React, { useContext, useEffect, useState } from 'react'
import { TabStore } from '../../../TabsStructure/TabsStore';
import { getfastpathapi, apiCallwithBaseUrl } from '../../ApiCalls';
import SaveDatePicker from '../../SaveComponents/SaveDatePicker'
import { currentTabData } from '../../Variable';
import CallbackDropdown from '../CallbackComponents/CallbackDropdown'
import CallbackTextInput from '../CallbackComponents/CallbackTextInput'
import { v4 as uuidv4 } from "uuid";
import { AuthStore } from '../../../Auth/AuthStore';
import ExecuteDropdown from '../ExecuteComponents/ExecuteDropdown';
import CallbackCheckbox from '../CallbackComponents/CallbackCheckbox';
import CallbackDateInput from '../CallbackComponents/CallbackDateInput';
import CallbackRadioBtn from '../CallbackComponents/CallbackRadioBtn';
import ExecuteFileInput from '../ExecuteComponents/ExecuteFileInput';
import { Button } from 'antd';
import noData from "../../../Assets/noData.png";
import LogComponent from '../../../Screens/Logs/LogsData/LogComponent';
import { isArray } from 'lodash';

function SearchFilterByBillNo({ filterOptionsProps, setfilterdropdown, filterDictionary, setFilterDictionary, fastpath, setshowFilterModal, showFilterModal,pageDetails }) {
    const [filterOptions, setFilterOptions] = useState([]);
    const [apiURL, setapiURL] = useState()
    const [data, setdata] = useState()
    const [selectedFilterOptions, setSelectedFilterOptions] = useState({});
    const [dropdownValue, setdropdownValue] = useState()
    const auth = useContext(AuthStore);
    const tabStore = useContext(TabStore);

   
    useEffect(() => {
        // On component mount this api is called and filterOptions are set for that fast path
        getfastpathapi(payload).then((res) => {
            if (res?.Success) {
                setFilterOptions(res?.Message?.filterOptions);
                if (filterOptionsProps)
                    filterOptionsProps(res?.Message?.filterOptions);

                setapiURL(res?.Message?.apiurl) // For Save Url
            }
        });
    }, []);

    useEffect(() => {
        setFilterOptions(filterOptionsProps);
    }, [filterOptionsProps]);

    // const storeData = (e, apikey, labeldropdowns) => {
    //     // Create a Temporary Add Store in the TabStore
    //     var newData = selectedFilterOptions;
    //     newData[apikey] = e;
    //     console.log("NEw Data is", newData, e, apikey)
    //     setSelectedFilterOptions(newData);
    //     // if (setfilterdropdown) {

    //     //     setfilterdropdown(labeldropdowns);// smk 24-2-
    //     // }
    // };

    const storeData = (e, apikey, labeldropdowns) => {
        // Create a Temporary Add Store in the TabStore
        if (!e && !labeldropdowns) return;
    
        var newData = selectedFilterOptions;
        newData[apikey] = e;
        setSelectedFilterOptions(newData);
    
        // date validation started
        const fromdate = selectedFilterOptions?.fromdate?selectedFilterOptions?.fromdate : getCurrentDateFormatted();
        const todate = selectedFilterOptions?.todate;
    
        const containsFromToDates = filterOptions?.some(option => option.apiKey === "fromdate") && filterOptions?.some(option => option.apiKey === "todate");

    
       
    
        if (containsFromToDates && Number(todate) < Number(fromdate)) {
            let elements = document.getElementsByClassName("dateinput");
           
            for (let i = 0; i < elements.length; i++) {
                elements[i].style.color = "red";
            }
       // alert("To date cannot be less than from date");
        
            return false
           
         
        } else {
        
    
            let elements = document.getElementsByClassName("dateinput");
            for (let i = 0; i < elements.length; i++) {
                elements[i].style.color = "black";
            }
            
        }
        // date validation end
    
        // if (returnValue) {
        //     returnValue(newData);
        // }
    
       if (setfilterdropdown) {

            setfilterdropdown(labeldropdowns);// smk 24-2-
        }
    };
    
    
  /// logic for getting todays date
  function getCurrentDateFormatted() {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    return `${year}${month}${day}`;
}
    //* This function sets the values obtained from the filteroptions above
    const setterFunction = (event, element) => {
        let displayValue;
        //# May need to use logical OR operator
        typeof event === "object" ? (displayValue = event?.displayLabel ?? event.label) : (displayValue = event);

        console.log("FC ln 64 value ========>", element["displayLabel"], displayValue, "filter dict", filterDictionary);

        //% Check here whether array contains the same id, if yes replace it with new object

        const targetReplacer = filterDictionary?.find((obj) => obj.id === element.displayLabel);
        if (targetReplacer) {
            //~# alert(`Replaced previous filter: ${element?.displayLabel}`);
            Object.assign(targetReplacer, { id: element?.displayLabel, descn: displayValue });
            return;
        }
        console.log("FC target found ??", targetReplacer);

        //% Setting id as displayLabel ==> id : Domain and key : value as ==> Domain : SAPL (example)
        setTimeout(() => {
            var newFilterDictionary = filterDictionary ? filterDictionary : {};
            let tempArray = [];
        
            if (typeof newFilterDictionary === 'object' && newFilterDictionary !== null && !Array.isArray(newFilterDictionary)) {
                tempArray = Object.entries(newFilterDictionary).map(([id, descn]) => ({ id, descn }));
            }
        
            tempArray.push({ id: element?.displayLabel, descn: displayValue });
        
            console.log("FC", tempArray);
        if(setFilterDictionary){
            setFilterDictionary(tempArray);
        }
           
        }, 100);
        
    };

    const enterPress = (e) => {
        if (e.keyCode === 13) {
            e?.preventDefault();
            const form = e.target.form;
            const index = Array.prototype.indexOf.call(form, e.target);
            if (form.elements[index + 1]) {
                form.elements[index + 1].focus();
            }
        }
    };

    const filterBill = async () => {
        console.log("auth",auth,selectedFilterOptions,storeData)
        const response = await apiCallwithBaseUrl("POST", apiURL, {
            tenantrecno: auth?.state?.userdata?.tenantrecno,
            domainrecno: auth?.state?.userdata?.domainrecno,
            domainuserrecno: auth?.state?.userdata?.recno,
            fastpath: fastpath,
            userrolerecno:auth?.state?.userdata?.userrolerecno,
            ...selectedFilterOptions,
           // status : "P",
            withoutstatus:"C", /// task-id 358 rajendra key name withoutstatus
            forFlag : "edit",
            norecords : 10,fortrn:"Y",
        });

        //const data = response
    
        setdata(response)   


    };

    const payload = {
        tenantrecno: auth?.state?.userdata?.tenantrecno,
        domainrecno: auth?.state?.userdata?.domainrecno,
        userrolerecno: auth?.state?.userdata?.userrolerecno,
        domainuserrecno: auth.state.userdata.recno,
        userrolerecno: auth.state.userdata.userrolerecno,
        fastpath: fastpath,
        ...storeData,
    };

    console.log("payload is", payload)
    // useEffect(()=>{
    //     console.log("RENDEERRR",data)

    // },[data])

    return (
        <div>
            <div className="searchBox">
                <form className="searchBox">
                    {filterOptions?.map((elem) =>

                        elem.type === "dropdown" ? (
                            <div onKeyDown={enterPress}>
                                <CallbackDropdown
                                    displayLabel={elem?.displayLabel}
                                    alignment="column"
                                    placeholder={elem?.placeholder}
                                    apiurl={elem?.apiEndPoint}
                                    selectionLabel={elem?.selectionLabel}
                                    selectionValue={elem?.selectionValue}
                                    apikey={elem?.apiKey}
                                    // multi={elem?.isMulti}
                                    records={20}
                                    minInput={0}
                                    returnValue={(e) => {
                                    //     if (!elem?.isMulti && e) {
                                    //         setterFunction(e, elem);
                                    //         storeData(e[elem?.selectionValue], elem?.apiKey, e[elem?.selectionLabel]);
                                    //         setdropdownValue(e[elem?.selectionValue], elem?.apiKey, e[elem?.selectionLabel])
                                    //     } else if (elem?.isMulti && e) {
                                    //         var selectedItems = ""
                                    //         var selectedItemDisplay = ""
                                    //    if(isArray(e)){
                                    //     e?.map((item) => {
                                    //         selectedItems = selectedItems + item[elem?.selectionValue]?.toString() + ","
                                    //         selectedItemDisplay= selectedItems + item[elem?.selectionLabel]?.toString() + ","
                                    //     })
                                    //    }
                                    //         setterFunction(e, elem);
                                    //         storeData(selectedItems, elem?.apiKey, e[elem?.selectionLabel]);
                                    //         setdropdownValue(selectedItems, elem?.apiKey, e[elem?.selectionLabel])
                                    //     }
                                    if (e) {
                                        console.log("FC", e[elem?.selectionValue], elem?.apiKey, e[elem?.selectionLabel]);
                                        setterFunction(e, elem);
                                        storeData(e[elem?.selectionValue], elem?.apiKey, e[elem?.selectionLabel]);
                                         setdropdownValue(e[elem?.selectionValue], elem?.apiKey, e[elem?.selectionLabel])
                                    }
                                    }}
                                    // body={{
                                    //     ...selectedFilterOptions
                                    // }}
                                    requiredBody={elem?.payloadKey}
                                    width={elem?.width}
                                    defaultSelected={elem?.defaultValue}
                                />
                            </div>
                        ) : elem.type === "string" ? (
                            <div onKeyDown={enterPress}>
                                <CallbackTextInput
                                    alignment="column"
                                    displayLabel={elem?.displayLabel}
                                    placeholder={elem?.placeholder}
                                    apikey={elem?.apiKey}
                                    defaultValue={elem?.defaultValue}
                                    change
                                    returnValue={(e) => {
                                        console.log("Element is e", e, elem)
                                        setterFunction(e, elem);
                                        storeData(e, elem?.apiKey);
                                    }}
                                />
                            </div>
                        ) : elem.type === "boolean" ? (
                            <div onKeyDown={enterPress}>
                                <CallbackCheckbox
                                    alignment="column"
                                    displayLabel={elem?.displayLabel}
                                    placeholder={elem?.placeholder}
                                    apikey={elem?.apiKey}
                                    defaultValue={elem?.defaultValue}
                                    change
                                    editable
                                    returnValue={(e) => {
                                        setterFunction(e, elem);
                                        storeData(e, elem?.apiKey);
                                    }}
                                />
                            </div>
                        ) : elem.type === "date" ? (
                            <div onKeyDown={enterPress}>
                                <CallbackDateInput
                                    id={uuidv4()}
                                    alignment="column"
                                    defaultValue={elem?.defaultValue}
                                    displayLabel={elem?.displayLabel}
                                    placeholder={elem?.placeholder}
                                    apiKey={elem?.apiKey}
                                    change
                                    className={"dateinput"}
                                    editable
                                    returnValue={(e) => {
                                        console.log("Selection Valu", elem?.selectionValue, e)
                                        setterFunction(e, elem);
                                        storeData(e, elem?.apiKey);
                                    }}
                                />
                            </div>
                        ) : elem.type === "file" ? (
                            <div onKeyDown={enterPress}>
                                <ExecuteFileInput
                                    displayLabel={elem?.displayLabel}
                                    placeholder={elem?.placeholder}
                                    apiKey={elem?.apiKey}
                                    returnValue={(e) => {
                                        setterFunction(e, elem);
                                        storeData(e, elem?.apiKey);
                                    }}
                                />
                            </div>
                        ) : elem.type === "radio" ? (
                            <div onKeyDown={enterPress}>
                                <CallbackRadioBtn
                                    apikey={"radio"}
                                    displayLabel={"Select Radio"}
                                    options={elem?.radioOptions}
                                    defaultValue={elem?.defaultValue}
                                    returnValue={(e) => {
                                        setterFunction(e, elem);
                                        storeData(e, elem?.apiKey);

                                    }}
                                />
                            </div>
                        ) : null
                    )}
                </form>
            </div>
            <div style={{ display: 'flex', justifyContent: "center", alignItems: "center" }}>
                <Button
                    className="btn"
                    type='default'
                    onClick={filterBill}
                >
                    Search
                </Button>
            </div>
            <div className="under">
                {data?.Message?.length > 0 ? (
                    <LogComponent
                        data={data?.Message}
                        columns={data?.columnsinfo}
                        itemsColumn={
                            data?.columnsinfofooter
                        }
                        setshowFilterModal={setshowFilterModal}
                        showFilterModal={showFilterModal}
                        dontEdit={false}
                        pageDetails={pageDetails}
                    />
             ///{ Start   Pradeep task id-dc  image should come when no record found when we search record and remove it from initially    start }
                ) :data?.Message?.length ==0 ? (    
                    <div className="noDataFound">
                        <h1>Oops! No Records Found</h1>
                        <img src={noData} />
                    </div>
                ):null} 
                {/*end                        end */}
            </div>
        </div>
    );
};


export default SearchFilterByBillNo
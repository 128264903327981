import { Button, Checkbox, Input, Popconfirm, Space, Table } from "antd";
import {
  dateFormatter,
  isArray,
  removeFormatCurrency,
  updateAddArray,
} from "../../AppFunction";
import CallbackCheckbox from "../CallbackComponents/CallbackCheckbox";
import CallbackDropdown from "../CallbackComponents/CallbackDropdown";
import CallbackTextInput from "../CallbackComponents/CallbackTextInput";
import {
  AiFillCheckCircle,
  AiFillCloseCircle,
  AiOutlineEdit,
  AiOutlineFileAdd,
} from "react-icons/ai";
import { v4 as uuidv4 } from "uuid";
import { EyeOutlined, FilterFilled, SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import CallbackDateInput from "../CallbackComponents/CallbackDateInput";

///validation
export const validationFun = (arr) => {
  let reqarr = [];
  for (let i = 0; i < arr.length; i++) {
    let item = arr[i];
    if (item.req == 1) {
      if (item.key) {
        reqarr.push(item.key);
      }
    }
  }

  return reqarr;
};

export const setStatusFun = (requiredarray, apiBody) => {
  const yValues = Object?.values(apiBody); // cache object values
  for (let i = 0; i < requiredarray?.length; i++) {
    /// task-id -340 rajendra  start
    const key = requiredarray[i];

    var value;
    if (key) {
      value = apiBody.hasOwnProperty("new" + key)
        ? apiBody["new" + key]
        : apiBody[key];
    }

    console.log("KEY IS", apiBody);
    if (value === null || value === "" || value?.length === 0 || value == 0) {
      /// task-id -340 rajendra  start
      alert(key + " is Compulsary");
      return false;
    }
  }

  return true;
};
// Convert Selected Rows to be Editable
export const convertRows = (rows, editKey, setRows, index) => {
  var newRows = rows.map((item) => {
    if (item[index] == editKey) {
      item.isEditing = true;
    }
    // else {
    //   item.isEditing = false
    // }
    return item;
  });
  setRows(() => newRows);
};

// Function to Save the Rows after Editing
export const saveRows = (
  rows,
  editKey,
  editedRow,
  setRows,
  tabStore,
  setPage
) => {
  // Change color background as changed rows
 
  var changedRows = rows.filter((item) => item[editKey] == editedRow[editKey]);
  changedRows = changedRows[0];
 
 
  var availableChanges = 0;
  for (const key in changedRows) {
    if (changedRows.hasOwnProperty(key)) {
      if (editedRow.hasOwnProperty("new" + key)) {
        if (availableChanges == 0) {
          availableChanges = 1;
        }
        changedRows[key] = editedRow["new" + key];
        console.log("CHANGED ROWS ARE IN", editedRow);
        delete editedRow["new" + key];
      }
      if (changedRows.hasOwnProperty("new" + key)) {
        delete changedRows["new" + key]
      }
    }
  }
  if (availableChanges == 1) {
    // Store data to the Api Variables and call chagedRows with chanedColor
    //  Store the to TabStore
    changedRows.changed = true;
    changedRows.isEditing = false;

    var newRows = [...rows];
    setRows(() => newRows);

    try {
      var apiResponse =
        tabStore.state.tabs[tabStore.state.tabIndex]?.apiResponse;
      apiResponse.Message = newRows;
      console.log(
        "Response of the API in the IF Function is in editable table func",
        apiResponse
      );
      tabStore.dispatch({
        type: "UPDATE_DATA",
        fieldType: "apiResponse",
        text: apiResponse,
      });
    } catch (e) {
      console.log("Error is ", e);
    }

    // Need to Save to the Changed Rows
    var existingTabs = tabStore.state.tabs[tabStore.state.tabIndex]?.changedRows
      ? tabStore.state.tabs[tabStore.state.tabIndex]?.changedRows
      : [];

    var existingTabsArr = isArray(existingTabs);
    if (!existingTabsArr) {
      existingTabs = [];
    }

    var newRows = updateAddArray(existingTabs, changedRows, editKey);
    console.log("DYGUHJLK:L", newRows);
    newRows = newRows?.map((item) => {
      if (item?.hasOwnProperty("height")) {
        item.height = Number(removeFormatCurrency(item.height));
      }
      /// condition added here so that it will converted
      if (
        item?.hasOwnProperty("maxcreditamt") ||
        item?.hasOwnProperty("amount")
      ) {
        item.maxcreditamt = Number(removeFormatCurrency(item.maxcreditamt));
      }
      if (item?.hasOwnProperty("width")) {
        item.width = Number(removeFormatCurrency(item.width))
          ? Number(removeFormatCurrency(item.width))
          : 100;
      }
      return item;
    });
    console.log("NEW ROWS ARE", newRows);
    tabStore.dispatch({
      type: "UPDATE_DATA",
      fieldType: "changedRows",
      text: newRows,
    });
  } else {
    var newRows = rows.map((item) => {
      if (item[editKey] == editedRow[editKey]) {
        item.isEditing = false;
      }
      return item;
    });

    
    setRows(() => newRows);
  }
};

export const removeRow = (rows, editKey, setRows, index) => {
  for (let i = 0; i < rows.length; i++) {
    if (rows[i][index] == editKey) {
      rows.splice(i, 1);
    }
  }

  setRows(() => [...rows]);
};

export const cancelChanges = (rows, editKey, setRows, index) => {
  // change the rows from editingtrue to false
  var removeIndex = -1;
  var newRows = rows.map((item, ind) => {
    if (item[index] == editKey) {
      item.isEditing = false;
    }
    if (!item?.isNew) {
      return item;
    } else if (item.isNew && item.hasOwnProperty(index)) {
      return item;
    } else {
      removeIndex = ind;
      return false;
    }
  });

  if (removeIndex > -1) {
    newRows.splice(removeIndex, 1);
  }
  setRows(() => newRows);
};

const enterPress = (e) => {
  if (e.keyCode === 13) {
    e.preventDefault();
    const form = e.target.form;
    const index = Array.prototype.indexOf.call(form, e.target);
    if (form.elements[index + 1]) {
      form.elements[index + 1].focus();
    } else {
      alert("End of Line");
    }
  }
};
// Create Columns Initially
export const convertColumns = (
  columnsArray,
  setColumns,
  rows,
  setRows,
  tabStore,
  useChangedRows,
  modal,
  setOpenModal,
  searchText,
  setSearchText,
  searchedColumn,
  setSearchedColumn,
  searchInput,
  setEditOpenType,
  setPage,
  setSearchedColumnDisplay,
  setChanges,
  setOpenImage,
  openImage,
  handleFileUpload,
  isEdit,
  setBillformat
) => {
 
  if (columnsArray) {
    var editKey;
    let newNewColumns = [];
    for (let i = 0; i < columnsArray.length; i++) {
      //  if( (( columnsArray[i]?.showeditmodal) || ( columnsArray[i]?.showaddmodal))|| columnsArray[i]?.isview)
    if(columnsArray[i]?.isview){
      if (columnsArray[i]?.isview) { 
        //columnsArray[i]?.isview   initial condition here
        if (columnsArray[i]?.isview) {
  
          columnsArray[i]["dataIndex"] = columnsArray[i]?.columnname;
          columnsArray[i]["key"] = columnsArray[i]?.columnname;
          if (columnsArray[i]?.primarykey == true) {
            editKey = columnsArray[i].columnname;
          }

          columnsArray[i].render = (_, data) => {
            if (columnsArray[i].type == "date") {
              console.log("DATA DATE IS DATA TDATE", data);
            }
            if (!data?.isEditing) {
              if (columnsArray[i]?.type == "int") {
                return <p>{data[columnsArray[i].dataIndex] ? data[columnsArray[i].dataIndex] : 0 }</p>;
              } else if (columnsArray[i]?.type == "decimal") {
                return <p>{data[columnsArray[i].dataIndex] ? data[columnsArray[i].dataIndex] : 0 }</p>;
              } else if (columnsArray[i].type == "date") {
                return <p>{dateFormatter(data[columnsArray[i].dataIndex])}</p>;
              } else if (columnsArray[i].type == "dropdown") {
                return <p>{data[columnsArray[i].columndisplay]}</p>;
              } else if (columnsArray[i].type == "image") {
                // Changes By Nohit Merge By Kaushik. 04/04/2023
                // return <img src={`data:image/jpeg;base64,${data.answerimage}`} />;
                return (
                  <Button
                    onClick={() => {
                      handleFileUpload(data[columnsArray[i].dataIndex]);
                      setOpenImage(!openImage);
                    }}
                  >
                    <EyeOutlined />
                  </Button>
                );
              } else if (columnsArray[i].type == "boolean") {
                return (
                  <div onKeyDown={enterPress}>
                    {/* <CallbackCheckbox
                    
                    defaultValue={data[columnsArray[i].dataIndex]}
                  /> */}
                    <Checkbox
                      checked={data[columnsArray[i].dataIndex]}
                      disabled
                    />
                  </div>
                );
              } else if (columnsArray[i]?.type == "string") {
                return <p>{data[columnsArray[i].dataIndex]}</p>;
              } else {
                return <p>{data[columnsArray[i].dataIndex]}</p>;
              }
            } else if (data?.isEditing) {
              if (data?.isNew) {
                if (columnsArray[i]?.isadd) {
                  if (columnsArray[i]?.type == "int") {
                    if (columnsArray[i]?.isedit == false) {
                      return <p>{data[columnsArray[i].dataIndex] ? data[columnsArray[i].dataIndex] : 0 }</p>;
                    } else {
                      return (
                        <div onKeyDown={enterPress}>
                          <CallbackTextInput
                            placeholder={columnsArray[i]?.placeholder}
                            change
                            type={"number"} // Changed the type int to number because it was accepting letters along with numbers
                            // required
                            defaultValue={data[columnsArray[i].dataIndex]}
                            returnValue={(e) => {
                              data["new" + columnsArray[i].dataIndex] = e;
                            }}
                            title={columnsArray[i]?.type}
                          />
                        </div>
                      );
                    }
                  } else if (columnsArray[i]?.type == "decimal") {
                    if (columnsArray[i]?.isedit == false) {
                      return <p>{data[columnsArray[i].dataIndex] ? data[columnsArray[i].dataIndex] : 0 }</p>;
                    } else {
                      return (
                        <div onKeyDown={enterPress}>
                          <CallbackTextInput
                            placeholder={columnsArray[i]?.placeholder}
                            change
                            type={"number"}
                            // required
                            defaultValue={data[columnsArray[i].dataIndex]}
                            returnValue={(e) => {
                              data["new" + columnsArray[i].dataIndex] = Number(
                                removeFormatCurrency(e)
                              );
                            }}
                            title={columnsArray[i]?.type}
                          />
                        </div>
                      );
                    }
                  } else if (columnsArray[i]?.type == "boolean") {
                    return (
                      <div onKeyDown={enterPress}>
                        <CallbackCheckbox
                          editable={columnsArray[i]?.isedit}
                          returnValue={(e) => {
                            data["new" + columnsArray[i].dataIndex] = e;
                          }}
                          defaultValue={data[columnsArray[i].dataIndex]}
                        />
                      </div>
                    );
                  } else if (columnsArray[i]?.type == "date") {
                    if (columnsArray[i]?.isedit == false) {
                      return (
                        <p>{dateFormatter(data[columnsArray[i].dataIndex])}</p>
                      );
                    } else {
                      return (
                        <div onKeyDown={enterPress}>
                          <CallbackDateInput
                            placeholder={columnsArray[i]?.placeholder}
                            change
                            id={uuidv4()}
                            type={"int"}
                            // required
                            defaultValue={data[columnsArray[i].dataIndex]}
                            returnValue={(e) => {
                              data["new" + columnsArray[i].dataIndex] = e;
                            }}
                          />
                        </div>
                      );
                    }
                  } else if (columnsArray[i]?.type == "string") {
                    if (columnsArray[i]?.isedit == false) {
                      return <p>{data[columnsArray[i].dataIndex]}</p>;
                    } else {
                      return (
                        <div onKeyDown={enterPress}>
                          <CallbackTextInput
                            placeholder={columnsArray[i]?.placeholder}
                            change
                            type={"text"}
                            // required
                            defaultValue={data[columnsArray[i].dataIndex]}
                            returnValue={(e) => {
                              data["new" + columnsArray[i].dataIndex] = e;
                            }}
                          />
                        </div>
                      );
                    }
                  } else if (columnsArray[i]?.type == "dropdown") {
                    return (
                      <div
                        onKeyDown={enterPress}
                        style={{
                          display: "flex",
                          position: "relative",
                          zIndex: 10,
                        }}
                      >
                        <CallbackDropdown
                          apiurl={columnsArray[i]?.apiurl}
                          defaultSelected={data[columnsArray[i].dataIndex]}
                          width={columnsArray[i]?.width}
                          returnValue={(e) => {
                            if (e?.length > 0) {
                              var newString = "";
                              var newDisplayScreen = "";
                              var newArray = e.map((item) => {
                                newString =
                                  newString +
                                  " ," +
                                  item[columnsArray[i]?.selectionvalue];
                                newDisplayScreen =
                                  newDisplayScreen +
                                  " ," +
                                  item[columnsArray[i]?.selectionlabel];
                              });
                              newString = newString.slice(1, 2);
                              newDisplayScreen = newDisplayScreen.slice(1, 2);
                              data["new" + columnsArray[i].dataIndex] =
                                newString;
                              data["new" + columnsArray[i].columndisplay] =
                                newDisplayScreen;
                              if (!data[columnsArray[i].columndisplay]) {
                                data[columnsArray[i].columndisplay] =
                                  newDisplayScreen;
                              }
                            } else {
                              data["new" + columnsArray[i].dataIndex] =
                                e[columnsArray[i]?.selectionvalue];
                              data["new" + columnsArray[i].columndisplay] =
                                e[columnsArray[i]?.selectionlabel];
                              if (!data[columnsArray[i].columndisplay]) {
                                data[columnsArray[i].columndisplay] =
                                  e[columnsArray[i]?.selectionlabel];
                              }
                            }
                          }}
                          multi={columnsArray[i]?.isMulti}
                          selectionValue={columnsArray[i]?.selectionvalue}
                          selectionLabel={columnsArray[i]?.selectionlabel}
                          body={columnsArray[i]?.payload}
                        />
                      </div>
                    );
                  }
                } else {
                  if (columnsArray[i].type == "dropdown") {
                    return <p>{data[columnsArray[i].columndisplay]}</p>;
                  } else if (columnsArray[i].type == "boolean") {
                    return (
                      <div onKeyDown={enterPress}>
                        <Checkbox
                          checked={data[columnsArray[i].dataIndex]}
                          disabled
                        />
                      </div>
                    );
                  }
                  return <p>{data[columnsArray[i].dataIndex]}</p>;
                }
              } else {
                if (columnsArray[i]?.isedit) {
                  if (columnsArray[i]?.type == "int") {
                    if (columnsArray[i]?.isedit == false) {
                      return <p>{data[columnsArray[i].dataIndex]}</p>;
                    } else {
                      return (
                        <div onKeyDown={enterPress}>
                          <CallbackTextInput
                            placeholder={columnsArray[i]?.placeholder}
                            change
                            type={"number"} // Changed the type because it was accepting letters along with numbers
                            // required
                            defaultValue={data[columnsArray[i].dataIndex]}
                            returnValue={(e) => {
                              data["new" + columnsArray[i].dataIndex] = e;
                              console.log('Changed Event of the Retrurn Value is', columnsArray[i].dataIndex, data, i)
                            }}
                            title={columnsArray[i]?.type}
                          />
                        </div>
                      );
                    }
                  } else if (columnsArray[i]?.type == "decimal") {
                    if (columnsArray[i]?.isedit == false) {
                      return <p>{data[columnsArray[i].dataIndex]}</p>;
                    } else {
                      return (
                        <div onKeyDown={enterPress}>
                          <CallbackTextInput
                            placeholder={columnsArray[i]?.placeholder}
                            change
                            type={"number"}
                            // required
                            title={columnsArray[i]?.type}
                            defaultValue={data[columnsArray[i].dataIndex]}
                            returnValue={(e) => {
                              console.log('Changed Event of the Retrurn Value is', e)
                              data["new" + columnsArray[i].dataIndex] = Number(
                                removeFormatCurrency(e)
                              );
                            }}
                          />
                        </div>
                      );
                    }
                  } else if (columnsArray[i]?.type == "date") {
                    if (columnsArray[i]?.isedit == false) {
                      return (
                        <p>{dateFormatter(data[columnsArray[i].dataIndex])}</p>
                      );
                    } else {
                      return (
                        <div onKeyDown={enterPress}>
                          <CallbackDateInput
                            placeholder={columnsArray[i]?.placeholder}
                            change
                            type={"int"}
                            // required
                            id={uuidv4()}
                            defaultValue={data[columnsArray[i].dataIndex]}
                            returnValue={(e) => {
                              data["new" + columnsArray[i].dataIndex] = e;
                              console.log("DATE IS CHANGED", data);
                            }}
                          />
                        </div>
                      );
                    }
                  } else if (columnsArray[i]?.type == "dropdown") {
                    return (
                      <div
                        onKeyDown={enterPress}
                        style={{
                          display: "flex",
                          position: "relative",
                          zIndex: 10,
                        }}
                      >
                        <CallbackDropdown
                          width={columnsArray[i]?.width}
                          apiurl={columnsArray[i]?.apiurl}
                          defaultSelected={data[columnsArray[i].dataIndex]}
                          returnValue={(e) => {
                            if (e?.length > 0) {
                              var newString = "";
                              var newDisplayScreen = "";
                              var newArray = e.map((item) => {
                                newString =
                                  newString +
                                  " ," +
                                  item[columnsArray[i]?.selectionvalue];
                                newDisplayScreen =
                                  newDisplayScreen +
                                  " ," +
                                  item[columnsArray[i]?.selectionlabel];
                              });
                              newString = newString.slice(1, 2);
                              newDisplayScreen = newDisplayScreen.slice(1, 2);
                              data["new" + columnsArray[i].dataIndex] =
                                newString;
                              data["new" + columnsArray[i].columndisplay] =
                                newDisplayScreen;
                              if (!data[columnsArray[i].columndisplay]) {
                                data[columnsArray[i].columndisplay] =
                                  newDisplayScreen;
                              }
                            } else {
                              data["new" + columnsArray[i].dataIndex] =
                                e[columnsArray[i]?.selectionvalue];
                              data["new" + columnsArray[i].columndisplay] =
                                e[columnsArray[i]?.selectionlabel];
                              if (!data[columnsArray[i].columndisplay]) {
                                data[columnsArray[i].columndisplay] =
                                  e[columnsArray[i]?.selectionlabel];
                              }
                            }
                          }}
                          multi={columnsArray[i]?.isMulti}
                          selectionValue={columnsArray[i]?.selectionvalue}
                          selectionLabel={columnsArray[i]?.selectionlabel}
                          body={columnsArray[i]?.payload}
                        />
                      </div>
                    );
                  } else if (columnsArray[i]?.type == "boolean") {
                    return (
                      <div onKeyDown={enterPress}>
                        <CallbackCheckbox
                          editable={columnsArray[i]?.isedit}
                          returnValue={(e) => {
                            data["new" + columnsArray[i].dataIndex] = e;
                            console.log("Check Box Data is 888", e, data,columnsArray[i].dataIndex,);
                          }}
                          defaultValue={data[columnsArray[i].dataIndex]}
                        />
                      </div>
                    ); /// image
                  } else if (
                    columnsArray[i]?.type == "image" &&
                    columnsArray[i].columnname == "imagelocation"
                  ) {
                    return (
                      <div onKeyDown={enterPress}>
                        {/* <CallbackCheckbox
                        editable={columnsArray[i]?.isedit}
                        returnValue={(e) => {
                          data["new" + columnsArray[i].dataIndex] = e;
                          console.log("Check Box Data is ", e, data)
                        }}
                        defaultValue={data[columnsArray[i].dataIndex]}
                      /> */}
                        <Button
                          onClick={() => {
                            handleFileUpload(data[columnsArray[i].dataIndex]);
                            setOpenImage(!openImage);
                          }}
                        >
                          <EyeOutlined />
                        </Button>
                      </div>
                    );
                  } // image
                  else if (columnsArray[i]?.type == "string") {
                    if (columnsArray[i]?.isedit == false) {
                      return <p>{data[columnsArray[i].dataIndex]}</p>;
                    } else {
                      return (
                        <div onKeyDown={enterPress}>
                          <CallbackTextInput
                            placeholder={columnsArray[i]?.placeholder}
                            change
                            type={"text"}
                            // required
                            defaultValue={data[columnsArray[i].dataIndex]}
                            returnValue={(e) => {
                              data["new" + columnsArray[i].dataIndex] = e;
                            }}
                          />
                        </div>
                      );
                    }
                  }
                } else {
                  if (columnsArray[i]?.type == "int") {
                    return <p>{data[columnsArray[i].dataIndex]}</p>;
                  } else if (columnsArray[i]?.type == "decimal") {
                    return <p>{data[columnsArray[i].dataIndex]}</p>;
                  } else if (columnsArray[i].type == "date") {
                    return (
                      <p>{dateFormatter(data[columnsArray[i].dataIndex])}</p>
                    );
                  } else if (columnsArray[i].type == "dropdown") {
                    return <p>{data[columnsArray[i].columndisplay]}</p>;
                  } else if (columnsArray[i].type == "boolean") {
                    return (
                      <div onKeyDown={enterPress}>
                        <Checkbox
                          checked={data[columnsArray[i].dataIndex]}
                          disabled
                        />
                      </div>
                    );
                  } else if (columnsArray[i]?.type == "string") {
                    return <p>{data[columnsArray[i].dataIndex]}</p>;
                  } else {
                    return <p>{data[columnsArray[i].dataIndex]}</p>;
                  }
                }
              }
            }
          };

          newNewColumns.push(columnsArray[i]);
        }
      } else if (columnsArray[i]?.showaddmodal) {

        //columnsArray[i]?.isview   initial condition here
        // if (columnsArray[i]?.showaddmodal) {
          console.log("RTDDCYTDYU2")
          columnsArray[i]["dataIndex"] = columnsArray[i]?.columnname;
          columnsArray[i]["key"] = columnsArray[i]?.columnname;
          if (columnsArray[i]?.primarykey == true) {
            editKey = columnsArray[i].columnname;
          }

          columnsArray[i].render = (_, data) => {
            if (columnsArray[i].type == "date") {
              console.log("DATA DATE IS DATA TDATE", data);
            }
            if (!data?.isEditing) {
              if (columnsArray[i]?.type == "int") {
                return <p>{data[columnsArray[i].dataIndex]}</p>;
              } else if (columnsArray[i]?.type == "decimal") {
                return <p>{data[columnsArray[i].dataIndex]}</p>;
              } else if (columnsArray[i].type == "date") {
                return <p>{dateFormatter(data[columnsArray[i].dataIndex])}</p>;
              } else if (columnsArray[i].type == "dropdown") {
                return <p>{data[columnsArray[i].columndisplay]}</p>;
              } else if (columnsArray[i].type == "image") {
                // Changes By Nohit Merge By Kaushik. 04/04/2023
                // return <img src={`data:image/jpeg;base64,${data.answerimage}`} />;
                return (
                  <Button
                    onClick={() => {
                      handleFileUpload(data[columnsArray[i].dataIndex]);
                      setOpenImage(!openImage);
                    }}
                  >
                    <EyeOutlined />
                  </Button>
                );
              } else if (columnsArray[i].type == "boolean") {
                return (
                  <div onKeyDown={enterPress}>
                    {/* <CallbackCheckbox
                      
                      defaultValue={data[columnsArray[i].dataIndex]}
                    /> */}
                    <Checkbox
                      checked={data[columnsArray[i].dataIndex]}
                      disabled
                    />
                  </div>
                );
              } else if (columnsArray[i]?.type == "string") {
                return <p>{data[columnsArray[i].dataIndex]}</p>;
              } else {
                return <p>{data[columnsArray[i].dataIndex]}</p>;
              }
            } else if (data?.isEditing) {
              if (data?.isNew) {
                if (columnsArray[i]?.isadd) {
                  if (columnsArray[i]?.type == "int") {
                    if (columnsArray[i]?.isedit == false) {
                      return <p>{data[columnsArray[i].dataIndex]}</p>;
                    } else {
                      return (
                        <div onKeyDown={enterPress}>
                          <CallbackTextInput
                            placeholder={columnsArray[i]?.placeholder}
                            change
                            type={"number"} // Changed the type int to number because it was accepting letters along with numbers
                            // required
                            defaultValue={data[columnsArray[i].dataIndex]}
                            returnValue={(e) => {
                              data["new" + columnsArray[i].dataIndex] = e;
                            }}
                            title={columnsArray[i]?.type}
                          />
                        </div>
                      );
                    }
                  } else if (columnsArray[i]?.type == "decimal") {
                    if (columnsArray[i]?.isedit == false) {
                      return <p>{data[columnsArray[i].dataIndex]}</p>;
                    } else {
                      return (
                        <div onKeyDown={enterPress}>
                          <CallbackTextInput
                            placeholder={columnsArray[i]?.placeholder}
                            change
                            type={"number"}
                            // required
                            defaultValue={data[columnsArray[i].dataIndex]}
                            returnValue={(e) => {
                              data["new" + columnsArray[i].dataIndex] = Number(
                                removeFormatCurrency(e)
                              );
                            }}
                          />
                        </div>
                      );
                    }
                  } else if (columnsArray[i]?.type == "boolean") {
                    return (
                      <div onKeyDown={enterPress}>
                        <CallbackCheckbox
                          editable={columnsArray[i]?.isedit}
                          returnValue={(e) => {
                            data["new" + columnsArray[i].dataIndex] = e;
                          }}
                          defaultValue={data[columnsArray[i].dataIndex]}
                        />
                      </div>
                    );
                  } else if (columnsArray[i]?.type == "date") {
                    if (columnsArray[i]?.isedit == false) {
                      return (
                        <p>{dateFormatter(data[columnsArray[i].dataIndex])}</p>
                      );
                    } else {
                      return (
                        <div onKeyDown={enterPress}>
                          <CallbackDateInput
                            placeholder={columnsArray[i]?.placeholder}
                            change
                            id={uuidv4()}
                            type={"int"}
                            // required
                            defaultValue={data[columnsArray[i].dataIndex]}
                            returnValue={(e) => {
                              data["new" + columnsArray[i].dataIndex] = e;
                            }}
                          />
                        </div>
                      );
                    }
                  } else if (columnsArray[i]?.type == "string") {
                    if (columnsArray[i]?.isedit == false) {
                      return <p>{data[columnsArray[i].dataIndex]}</p>;
                    } else {
                      return (
                        <div onKeyDown={enterPress}>
                          <CallbackTextInput
                            placeholder={columnsArray[i]?.placeholder}
                            change
                            type={"text"}
                            // required
                            defaultValue={data[columnsArray[i].dataIndex]}
                            returnValue={(e) => {
                              data["new" + columnsArray[i].dataIndex] = e;
                            }}
                          />
                        </div>
                      );
                    }
                  } else if (columnsArray[i]?.type == "dropdown") {
                    return (
                      <div
                        onKeyDown={enterPress}
                        style={{
                          display: "flex",
                          position: "relative",
                          zIndex: 10,
                        }}
                      >
                        <CallbackDropdown
                          apiurl={columnsArray[i]?.apiurl}
                          defaultSelected={data[columnsArray[i].dataIndex]}
                          width={columnsArray[i]?.width}
                          returnValue={(e) => {
                            if (e?.length > 0) {
                              var newString = "";
                              var newDisplayScreen = "";
                              var newArray = e.map((item) => {
                                newString =
                                  newString +
                                  " ," +
                                  item[columnsArray[i]?.selectionvalue];
                                newDisplayScreen =
                                  newDisplayScreen +
                                  " ," +
                                  item[columnsArray[i]?.selectionlabel];
                              });
                              newString = newString.slice(1, 2);
                              newDisplayScreen = newDisplayScreen.slice(1, 2);
                              data["new" + columnsArray[i].dataIndex] =
                                newString;
                              data["new" + columnsArray[i].columndisplay] =
                                newDisplayScreen;
                              if (!data[columnsArray[i].columndisplay]) {
                                data[columnsArray[i].columndisplay] =
                                  newDisplayScreen;
                              }
                            } else {
                              data["new" + columnsArray[i].dataIndex] =
                                e[columnsArray[i]?.selectionvalue];
                              data["new" + columnsArray[i].columndisplay] =
                                e[columnsArray[i]?.selectionlabel];
                              if (!data[columnsArray[i].columndisplay]) {
                                data[columnsArray[i].columndisplay] =
                                  e[columnsArray[i]?.selectionlabel];
                              }
                            }
                          }}
                          multi={columnsArray[i]?.isMulti}
                          selectionValue={columnsArray[i]?.selectionvalue}
                          selectionLabel={columnsArray[i]?.selectionlabel}
                          body={columnsArray[i]?.payload}
                        />
                      </div>
                    );
                  }
                } else {
                  if (columnsArray[i].type == "dropdown") {
                    return <p>{data[columnsArray[i].columndisplay]}</p>;
                  } else if (columnsArray[i].type == "boolean") {
                    return (
                      <div onKeyDown={enterPress}>
                        <Checkbox
                          checked={data[columnsArray[i].dataIndex]}
                          disabled
                        />
                      </div>
                    );
                  }
                  return <p>{data[columnsArray[i].dataIndex]}</p>;
                }
              } else {
                if (columnsArray[i]?.isedit) {
                  if (columnsArray[i]?.type == "int") {
                    if (columnsArray[i]?.isedit == false) {
                      return <p>{data[columnsArray[i].dataIndex]}</p>;
                    } else {
                      return (
                        <div onKeyDown={enterPress}>
                          <CallbackTextInput
                            placeholder={columnsArray[i]?.placeholder}
                            change
                            type={"number"} // Changed the type because it was accepting letters along with numbers
                            // required
                            defaultValue={data[columnsArray[i].dataIndex]}
                            returnValue={(e) => {
                              data["new" + columnsArray[i].dataIndex] = e;
                            }}
                          />
                        </div>
                      );
                    }
                  } else if (columnsArray[i]?.type == "decimal") {
                    if (columnsArray[i]?.isedit == false) {
                      return <p>{data[columnsArray[i].dataIndex]}</p>;
                    } else {
                      return (
                        <div onKeyDown={enterPress}>
                          <CallbackTextInput
                            placeholder={columnsArray[i]?.placeholder}
                            change
                            type={"number"}
                            // required
                            title={columnsArray[i]?.type}
                            defaultValue={data[columnsArray[i].dataIndex]}
                            returnValue={(e) => {
                              data["new" + columnsArray[i].dataIndex] = Number(
                                removeFormatCurrency(e)
                              );
                            }}
                          />
                        </div>
                      );
                    }
                  } else if (columnsArray[i]?.type == "date") {
                    if (columnsArray[i]?.isedit == false) {
                      return (
                        <p>{dateFormatter(data[columnsArray[i].dataIndex])}</p>
                      );
                    } else {
                      return (
                        <div onKeyDown={enterPress}>
                          <CallbackDateInput
                            placeholder={columnsArray[i]?.placeholder}
                            change
                            type={"int"}
                            // required
                            id={uuidv4()}
                            defaultValue={data[columnsArray[i].dataIndex]}
                            returnValue={(e) => {
                              data["new" + columnsArray[i].dataIndex] = e;
                              console.log("DATE IS CHANGED", data);
                            }}
                          />
                        </div>
                      );
                    }
                  } else if (columnsArray[i]?.type == "dropdown") {
                    return (
                      <div
                        onKeyDown={enterPress}
                        style={{
                          display: "flex",
                          position: "relative",
                          zIndex: 10,
                        }}
                      >
                        <CallbackDropdown
                          width={columnsArray[i]?.width}
                          apiurl={columnsArray[i]?.apiurl}
                          defaultSelected={data[columnsArray[i].dataIndex]}
                          returnValue={(e) => {
                            if (e?.length > 0) {
                              var newString = "";
                              var newDisplayScreen = "";
                              var newArray = e.map((item) => {
                                newString =
                                  newString +
                                  " ," +
                                  item[columnsArray[i]?.selectionvalue];
                                newDisplayScreen =
                                  newDisplayScreen +
                                  " ," +
                                  item[columnsArray[i]?.selectionlabel];
                              });
                              newString = newString.slice(1, 2);
                              newDisplayScreen = newDisplayScreen.slice(1, 2);
                              data["new" + columnsArray[i].dataIndex] =
                                newString;
                              data["new" + columnsArray[i].columndisplay] =
                                newDisplayScreen;
                              if (!data[columnsArray[i].columndisplay]) {
                                data[columnsArray[i].columndisplay] =
                                  newDisplayScreen;
                              }
                            } else {
                              data["new" + columnsArray[i].dataIndex] =
                                e[columnsArray[i]?.selectionvalue];
                              data["new" + columnsArray[i].columndisplay] =
                                e[columnsArray[i]?.selectionlabel];
                              if (!data[columnsArray[i].columndisplay]) {
                                data[columnsArray[i].columndisplay] =
                                  e[columnsArray[i]?.selectionlabel];
                              }
                            }
                          }}
                          multi={columnsArray[i]?.isMulti}
                          selectionValue={columnsArray[i]?.selectionvalue}
                          selectionLabel={columnsArray[i]?.selectionlabel}
                          body={columnsArray[i]?.payload}
                        />
                      </div>
                    );
                  } else if (columnsArray[i]?.type == "boolean") {
                    return (
                      <div onKeyDown={enterPress}>
                        <CallbackCheckbox
                          editable={columnsArray[i]?.isedit}
                          returnValue={(e) => {
                            data["new" + columnsArray[i].dataIndex] = e;
                            console.log("Check Box Data is 999 ", e, data);
                          }}
                          defaultValue={data[columnsArray[i].dataIndex]}
                        />
                      </div>
                    ); /// image
                  } else if (
                    columnsArray[i]?.type == "image" &&
                    columnsArray[i].columnname == "imagelocation"
                  ) {
                    return (
                      <div onKeyDown={enterPress}>
                        {/* <CallbackCheckbox
                          editable={columnsArray[i]?.isedit}
                          returnValue={(e) => {
                            data["new" + columnsArray[i].dataIndex] = e;
                            console.log("Check Box Data is ", e, data)
                          }}
                          defaultValue={data[columnsArray[i].dataIndex]}
                        /> */}
                        <Button
                          onClick={() => {
                            handleFileUpload(data[columnsArray[i].dataIndex]);
                            setOpenImage(!openImage);
                          }}
                        >
                          <EyeOutlined />
                        </Button>
                      </div>
                    );
                  } // image
                  else if (columnsArray[i]?.type == "string") {
                    if (columnsArray[i]?.isedit == false) {
                      return <p>{data[columnsArray[i].dataIndex]}</p>;
                    } else {
                      return (
                        <div onKeyDown={enterPress}>
                          <CallbackTextInput
                            placeholder={columnsArray[i]?.placeholder}
                            change
                            type={"text"}
                            // required
                            defaultValue={data[columnsArray[i].dataIndex]}
                            returnValue={(e) => {
                              data["new" + columnsArray[i].dataIndex] = e;
                            }}
                          />
                        </div>
                      );
                    }
                  }
                } else {
                  if (columnsArray[i]?.type == "int") {
                    return <p>{data[columnsArray[i].dataIndex]}</p>;
                  } else if (columnsArray[i]?.type == "decimal") {
                    return <p>{data[columnsArray[i].dataIndex]}</p>;
                  } else if (columnsArray[i].type == "date") {
                    return (
                      <p>{dateFormatter(data[columnsArray[i].dataIndex])}</p>
                    );
                  } else if (columnsArray[i].type == "dropdown") {
                    return <p>{data[columnsArray[i].columndisplay]}</p>;
                  } else if (columnsArray[i].type == "boolean") {
                    return (
                      <div onKeyDown={enterPress}>
                        <Checkbox
                          checked={data[columnsArray[i].dataIndex]}
                          disabled
                        />
                      </div>
                    );
                  } else if (columnsArray[i]?.type == "string") {
                    return <p>{data[columnsArray[i].dataIndex]}</p>;
                  } else {
                    return <p>{data[columnsArray[i].dataIndex]}</p>;
                  }
                }
              }
            }
          };

          newNewColumns.push(columnsArray[i]);
        // }
      } else if (columnsArray[i]?.showeditmodal) {
        //columnsArray[i]?.isview   initial condition here
        // if (columnsArray[i]?.showeditmodal ) {
          console.log("RTDDCYTDYU3")
          columnsArray[i]["dataIndex"] = columnsArray[i]?.columnname;
          columnsArray[i]["key"] = columnsArray[i]?.columnname;
          if (columnsArray[i]?.primarykey == true) {
            editKey = columnsArray[i].columnname;
          }

          columnsArray[i].render = (_, data) => {
            if (columnsArray[i].type == "date") {
              console.log("DATA DATE IS DATA TDATE", data);
            }
            if (!data?.isEditing) {
              if (columnsArray[i]?.type == "int") {
                return <p>{data[columnsArray[i].dataIndex]}</p>;
              } else if (columnsArray[i]?.type == "decimal") {
                return <p>{data[columnsArray[i].dataIndex]}</p>;
              } else if (columnsArray[i].type == "date") {
                return <p>{dateFormatter(data[columnsArray[i].dataIndex])}</p>;
              } else if (columnsArray[i].type == "dropdown") {
                return <p>{data[columnsArray[i].columndisplay]}</p>;
              } else if (columnsArray[i].type == "image") {
                // Changes By Nohit Merge By Kaushik. 04/04/2023
                // return <img src={`data:image/jpeg;base64,${data.answerimage}`} />;
                return (
                  <Button
                    onClick={() => {
                      handleFileUpload(data[columnsArray[i].dataIndex]);
                      setOpenImage(!openImage);
                    }}
                  >
                    <EyeOutlined />
                  </Button>
                );
              } else if (columnsArray[i].type == "boolean") {
                return (
                  <div onKeyDown={enterPress}>
                    {/* <CallbackCheckbox
                        
                        defaultValue={data[columnsArray[i].dataIndex]}
                      /> */}
                    <Checkbox
                      checked={data[columnsArray[i].dataIndex]}
                      disabled
                    />
                  </div>
                );
              } else if (columnsArray[i]?.type == "string") {
                return <p>{data[columnsArray[i].dataIndex]}</p>;
              } else {
                return <p>{data[columnsArray[i].dataIndex]}</p>;
              }
            } else if (data?.isEditing) {
              if (data?.isNew) {
                if (columnsArray[i]?.isadd) {
                  if (columnsArray[i]?.type == "int") {
                    if (columnsArray[i]?.isedit == false) {
                      return <p>{data[columnsArray[i].dataIndex]}</p>;
                    } else {
                      return (
                        <div onKeyDown={enterPress}>
                          <CallbackTextInput
                            placeholder={columnsArray[i]?.placeholder}
                            change
                            type={"number"} // Changed the type int to number because it was accepting letters along with numbers
                            // required
                            defaultValue={data[columnsArray[i].dataIndex]}
                            returnValue={(e) => {
                              data["new" + columnsArray[i].dataIndex] = e;
                            }}
                          />
                        </div>
                      );
                    }
                  } else if (columnsArray[i]?.type == "decimal") {
                    if (columnsArray[i]?.isedit == false) {
                      return <p>{data[columnsArray[i].dataIndex]}</p>;
                    } else {
                      return (
                        <div onKeyDown={enterPress}>
                          <CallbackTextInput
                            placeholder={columnsArray[i]?.placeholder}
                            change
                            type={"number"}
                            // required
                            defaultValue={data[columnsArray[i].dataIndex]}
                            returnValue={(e) => {
                              data["new" + columnsArray[i].dataIndex] = Number(
                                removeFormatCurrency(e)
                              );
                            }}
                          />
                        </div>
                      );
                    }
                  } else if (columnsArray[i]?.type == "boolean") {
                    return (
                      <div onKeyDown={enterPress}>
                        <CallbackCheckbox
                          editable={columnsArray[i]?.isedit}
                          returnValue={(e) => {
                            data["new" + columnsArray[i].dataIndex] = e;
                          }}
                          defaultValue={data[columnsArray[i].dataIndex]}
                        />
                      </div>
                    );
                  } else if (columnsArray[i]?.type == "date") {
                    if (columnsArray[i]?.isedit == false) {
                      return (
                        <p>{dateFormatter(data[columnsArray[i].dataIndex])}</p>
                      );
                    } else {
                      return (
                        <div onKeyDown={enterPress}>
                          <CallbackDateInput
                            placeholder={columnsArray[i]?.placeholder}
                            change
                            id={uuidv4()}
                            type={"int"}
                            // required
                            defaultValue={data[columnsArray[i].dataIndex]}
                            returnValue={(e) => {
                              data["new" + columnsArray[i].dataIndex] = e;
                            }}
                          />
                        </div>
                      );
                    }
                  } else if (columnsArray[i]?.type == "string") {
                    if (columnsArray[i]?.isedit == false) {
                      return <p>{data[columnsArray[i].dataIndex]}</p>;
                    } else {
                      return (
                        <div onKeyDown={enterPress}>
                          <CallbackTextInput
                            placeholder={columnsArray[i]?.placeholder}
                            change
                            type={"text"}
                            // required
                            defaultValue={data[columnsArray[i].dataIndex]}
                            returnValue={(e) => {
                              data["new" + columnsArray[i].dataIndex] = e;
                            }}
                          />
                        </div>
                      );
                    }
                  } else if (columnsArray[i]?.type == "dropdown") {
                    return (
                      <div
                        onKeyDown={enterPress}
                        style={{
                          display: "flex",
                          position: "relative",
                          zIndex: 10,
                        }}
                      >
                        <CallbackDropdown
                          apiurl={columnsArray[i]?.apiurl}
                          defaultSelected={data[columnsArray[i].dataIndex]}
                          width={columnsArray[i]?.width}
                          disabled={columnsArray[i]?.isedit}
                          returnValue={(e) => {
                            if (e?.length > 0) {
                              var newString = "";
                              var newDisplayScreen = "";
                              var newArray = e.map((item) => {
                                newString =
                                  newString +
                                  " ," +
                                  item[columnsArray[i]?.selectionvalue];
                                newDisplayScreen =
                                  newDisplayScreen +
                                  " ," +
                                  item[columnsArray[i]?.selectionlabel];
                              });
                              newString = newString.slice(1, 2);
                              newDisplayScreen = newDisplayScreen.slice(1, 2);
                              data["new" + columnsArray[i].dataIndex] =
                                newString;
                              data["new" + columnsArray[i].columndisplay] =
                                newDisplayScreen;
                              if (!data[columnsArray[i].columndisplay]) {
                                data[columnsArray[i].columndisplay] =
                                  newDisplayScreen;
                              }
                            } else {
                              data["new" + columnsArray[i].dataIndex] =
                                e[columnsArray[i]?.selectionvalue];
                              data["new" + columnsArray[i].columndisplay] =
                                e[columnsArray[i]?.selectionlabel];
                              if (!data[columnsArray[i].columndisplay]) {
                                data[columnsArray[i].columndisplay] =
                                  e[columnsArray[i]?.selectionlabel];
                              }
                            }
                          }}
                          multi={columnsArray[i]?.isMulti}
                          selectionValue={columnsArray[i]?.selectionvalue}
                          selectionLabel={columnsArray[i]?.selectionlabel}
                          body={columnsArray[i]?.payload}
                        />
                      </div>
                    );
                  }
                } else {
                  if (columnsArray[i].type == "dropdown") {
                    return <p>{data[columnsArray[i].columndisplay]}</p>;
                  } else if (columnsArray[i].type == "boolean") {
                    return (
                      <div onKeyDown={enterPress}>
                        <Checkbox
                          checked={data[columnsArray[i].dataIndex]}
                          disabled
                        />
                      </div>
                    );
                  }
                  return <p>{data[columnsArray[i].dataIndex]}</p>;
                }
              } else {
                if (columnsArray[i]?.isedit) {
                  if (columnsArray[i]?.type == "int") {
                    if (columnsArray[i]?.isedit == false) {
                      return <p>{data[columnsArray[i].dataIndex]}</p>;
                    } else {
                      return (
                        <div onKeyDown={enterPress}>
                          <CallbackTextInput
                            placeholder={columnsArray[i]?.placeholder}
                            change
                            type={"number"} // Changed the type because it was accepting letters along with numbers
                            // required
                            defaultValue={data[columnsArray[i].dataIndex]}
                            returnValue={(e) => {
                              data["new" + columnsArray[i].dataIndex] = e;
                            }}
                          />
                        </div>
                      );
                    }
                  } else if (columnsArray[i]?.type == "decimal") {
                    if (columnsArray[i]?.isedit == false) {
                      return <p>{data[columnsArray[i].dataIndex]}</p>;
                    } else {
                      return (
                        <div onKeyDown={enterPress}>
                          <CallbackTextInput
                            placeholder={columnsArray[i]?.placeholder}
                            change
                            type={"number"}
                            // required
                            title={columnsArray[i]?.type}
                            defaultValue={data[columnsArray[i].dataIndex]}
                            returnValue={(e) => {
                              data["new" + columnsArray[i].dataIndex] = Number(
                                removeFormatCurrency(e)
                              );
                            }}
                          />
                        </div>
                      );
                    }
                  } else if (columnsArray[i]?.type == "date") {
                    if (columnsArray[i]?.isedit == false) {
                      return (
                        <p>{dateFormatter(data[columnsArray[i].dataIndex])}</p>
                      );
                    } else {
                      return (
                        <div onKeyDown={enterPress}>
                          <CallbackDateInput
                            placeholder={columnsArray[i]?.placeholder}
                            change
                            type={"int"}
                            // required
                            id={uuidv4()}
                            defaultValue={data[columnsArray[i].dataIndex]}
                            returnValue={(e) => {
                              data["new" + columnsArray[i].dataIndex] = e;
                              console.log("DATE IS CHANGED", data);
                            }}
                          />
                        </div>
                      );
                    }
                  } else if (columnsArray[i]?.type == "dropdown") {
                    return (
                      <div
                        onKeyDown={enterPress}
                        style={{
                          display: "flex",
                          position: "relative",
                          zIndex: 10,
                        }}
                      >
                        <CallbackDropdown
                          width={columnsArray[i]?.width}
                          apiurl={columnsArray[i]?.apiurl}
                          defaultSelected={data[columnsArray[i].dataIndex]}
                          returnValue={(e) => {
                            if (e?.length > 0) {
                              var newString = "";
                              var newDisplayScreen = "";
                              var newArray = e.map((item) => {
                                newString =
                                  newString +
                                  " ," +
                                  item[columnsArray[i]?.selectionvalue];
                                newDisplayScreen =
                                  newDisplayScreen +
                                  " ," +
                                  item[columnsArray[i]?.selectionlabel];
                              });
                              newString = newString.slice(1, 2);
                              newDisplayScreen = newDisplayScreen.slice(1, 2);
                              data["new" + columnsArray[i].dataIndex] =
                                newString;
                              data["new" + columnsArray[i].columndisplay] =
                                newDisplayScreen;
                              if (!data[columnsArray[i].columndisplay]) {
                                data[columnsArray[i].columndisplay] =
                                  newDisplayScreen;
                              }
                            } else {
                              data["new" + columnsArray[i].dataIndex] =
                                e[columnsArray[i]?.selectionvalue];
                              data["new" + columnsArray[i].columndisplay] =
                                e[columnsArray[i]?.selectionlabel];
                              if (!data[columnsArray[i].columndisplay]) {
                                data[columnsArray[i].columndisplay] =
                                  e[columnsArray[i]?.selectionlabel];
                              }
                            }
                          }}
                          multi={columnsArray[i]?.isMulti}
                          selectionValue={columnsArray[i]?.selectionvalue}
                          selectionLabel={columnsArray[i]?.selectionlabel}
                          body={columnsArray[i]?.payload}
                        />
                      </div>
                    );
                  } else if (columnsArray[i]?.type == "boolean") {
                    return (
                      <div onKeyDown={enterPress}>
                        <CallbackCheckbox
                          editable={columnsArray[i]?.isedit}
                          returnValue={(e) => {
                            data["new" + columnsArray[i].dataIndex] = e;
                            console.log("Check Box Data is2333 ",data,columnsArray[i].dataIndex);
                          }}
                          defaultValue={data[columnsArray[i].dataIndex]}
                        />
                      </div>
                    ); /// image
                  } else if (
                    columnsArray[i]?.type == "image" &&
                    columnsArray[i].columnname == "imagelocation"
                  ) {
                    return (
                      <div onKeyDown={enterPress}>
                        {/* <CallbackCheckbox
                            editable={columnsArray[i]?.isedit}
                            returnValue={(e) => {
                              data["new" + columnsArray[i].dataIndex] = e;
                              console.log("Check Box Data is ", e, data)
                            }}
                            defaultValue={data[columnsArray[i].dataIndex]}
                          /> */}
                        <Button
                          onClick={() => {
                            handleFileUpload(data[columnsArray[i].dataIndex]);
                            setOpenImage(!openImage);
                          }}
                        >
                          <EyeOutlined />
                        </Button>
                      </div>
                    );
                  } // image
                  else if (columnsArray[i]?.type == "string") {
                    if (columnsArray[i]?.isedit == false) {
                      return <p>{data[columnsArray[i].dataIndex]}</p>;
                    } else {
                      return (
                        <div onKeyDown={enterPress}>
                          <CallbackTextInput
                            placeholder={columnsArray[i]?.placeholder}
                            change
                            type={"text"}
                            // required
                            defaultValue={data[columnsArray[i].dataIndex]}
                            returnValue={(e) => {
                              data["new" + columnsArray[i].dataIndex] = e;
                            }}
                          />
                        </div>
                      );
                    }
                  }
                } else {
                  if (columnsArray[i]?.type == "int") {
                    return <p>{data[columnsArray[i].dataIndex]}</p>;
                  } else if (columnsArray[i]?.type == "decimal") {
                    return <p>{data[columnsArray[i].dataIndex]}</p>;
                  } else if (columnsArray[i].type == "date") {
                    return (
                      <p>{dateFormatter(data[columnsArray[i].dataIndex])}</p>
                    );
                  } else if (columnsArray[i].type == "dropdown") {
                    return <p>{data[columnsArray[i].columndisplay]}</p>;
                  } else if (columnsArray[i].type == "boolean") {
                    return (
                      <div onKeyDown={enterPress}>
                        <Checkbox
                          checked={data[columnsArray[i].dataIndex]}
                          disabled
                        />
                      </div>
                    );
                  } else if (columnsArray[i]?.type == "string") {
                    return <p>{data[columnsArray[i].dataIndex]}</p>;
                  } else {
                    return <p>{data[columnsArray[i].dataIndex]}</p>;
                  }
                }
              }
            }
          };

          newNewColumns.push(columnsArray[i]);
        // }
      }
    }
    }
    console.log("columnsArraycolumnsArrayrrr", newNewColumns);
    var newColumnsNew = newNewColumns.map((item) => {
      if (item.filter) {
        return {
          ...getColumnSearchProps(
            item?.columnname,
            item?.title,
            setSearchText,
            searchInput,
            setSearchedColumn,
            searchedColumn,
            searchText,
            setSearchedColumnDisplay,
            item?.columndisplay
          ),
          ...item,
          width : item.width == 'fit-content' ? 'fit-content' : Number(item?.width) > 0 ? Number(item.width) : 100
        };
      } else {
        item.width = item.width == 'fit-content' ? 'fit-content' : Number(item?.width) > 0 ? Number(item.width) : 100
        return item;
      }
    });
    setColumns(newColumnsNew);
    console.log("billformatbillformat",newColumnsNew,rows)
    setBillformat(()=>newColumnsNew.filter((it)=>it.dataIndex=="billformat"))
    var newColums = [];

    if (isEdit > 0) {
      newColums.push({
        title: "Edit",
        dataIndex: "update",
        key: "update",
        width: "100px",
        align: "center",
        render: (_, data) => {
          if (data?.isEditing) {
            return (
              <Space>
                <Button
                  onClick={() => {
                    console.log("Please select=2", data);
                    let requiredarray = validationFun(columnsArray);
                    let isfieldEmpty = setStatusFun(requiredarray, data);

                    if (isfieldEmpty) {
                      saveRows(rows, "uuid", data, setRows, tabStore, setPage);
                    }
                  }}
                  style={{ padding: "5px" }}
                  icon={<AiFillCheckCircle size="1.08rem" color="white" />}
                ></Button>
                <Popconfirm
                  title="Do you want to Cancel the changes?"
                  onConfirm={(e) => {
                    cancelChanges(rows, data["uuid"], setRows, "uuid");
                  }}
                >
                  <Button
                    style={{ padding: "5px" }}
                    icon={<AiFillCloseCircle size="1.08rem" color="white" />}
                  ></Button>
                </Popconfirm>
              </Space>
            );
          } else {
            return (
              <div>
                <Button
                  style={{ backgroundColor: data?.changed ? "#D55534" : "" }}
                  onClick={(e) => {
                    if (modal) {
                      tabStore.dispatch({
                        type: "UPDATE_DATA",
                        fieldType: "addObject",
                        text: data,
                      });

                      // setOpenModal(true);
                      setEditOpenType(true);
                      setChanges((p) => p + 1);
                    } else {
                      console.log("Please select=21");
                      convertRows(rows, data["uuid"], setRows, "uuid");
                      /// task-id -340 rajendra  start inline edit issue
                      setEditOpenType(false);
                      setOpenModal(false);
                    }
                  }}
                >
                  <AiOutlineEdit size="1.08rem" color="white" />
                </Button>
                {data?.isNew ? (
                  <Popconfirm
                    title="Do you want to Remove the changes?"
                    onConfirm={(e) => {
                      removeRow(rows, data["uuid"], setRows, "uuid");
                    }}
                  >
                    <Button
                      style={{ padding: "5px" }}
                      onClick={() => {}}
                      icon={<AiFillCloseCircle size="1.08rem" color="white" />}
                    ></Button>
                  </Popconfirm>
                ) : null}
              </div>
            );
          }
        },
      });
    }

    setColumns((p) => [...newColums, ...p]);
  }
};

// Creating Rows Initially
export const createRows = (columns, rowsArray, setRows) => {
  // Rows are set to the state variable
  var newRows = rowsArray.map((item) => {
    return { ...item, uuid: uuidv4() };
  });
  setRows(newRows);
};

export const addNewRows = (rows, setRows, columns) => {
  var objNew = { isNew: true, isEditing: true, uuid: uuidv4() };
  var i;
  for (i = 0; i < columns.length; i++) {
    objNew[columns[i].dataIndex] = columns[i]?.defaultValue
      ? columns[i]?.defaultValue
      : null;
  }

  rows = [objNew, ...rows];
  setRows(() => rows);
};

const handleSearch = (
  selectedKeys,
  confirm,
  dataIndex,
  setSearchText,
  setSearchedColumn
) => {
  confirm();
};
const handleReset = (
  clearFilters,
  setSearchText,
  setSearchedColumn,
  setSearchedColumnDisplay,
  confirm
) => {
  clearFilters();
  setSearchText("");
  setSearchText("");
  setSearchedColumn("");
  setSearchedColumnDisplay(null);
  confirm();
};
const getColumnSearchProps = (
  dataIndex,
  title,
  setSearchText,
  searchInput,
  setSearchedColumn,
  searchedColumn,
  searchText,
  setSearchedColumnDisplay,
  columnname
) => ({
  filterDropdown: ({
    setSelectedKeys,
    selectedKeys,
    confirm,
    clearFilters,
    close,
  }) => (
    <div
      style={{
        padding: 8,
      }}
      onKeyDown={(e) => e.stopPropagation()}
    >
      <Input
        ref={searchInput}
        placeholder={`Search ${title}`}
        value={selectedKeys[0]}
        onChange={(e) =>
          setSelectedKeys(e.target.value ? [e.target.value] : [])
        }
        onPressEnter={() => {
          handleSearch(selectedKeys, confirm, dataIndex);

          setSearchText(() => selectedKeys[0]);
          setSearchedColumn(() => dataIndex);
          setSearchedColumnDisplay(() => columnname);
        }}
        style={{
          marginBottom: 8,
          display: "block",
        }}
      />
      <Space>
        <Button
          type="primary"
          onClick={() => {
            handleSearch(selectedKeys, confirm, dataIndex,title);
            setSearchText(() => selectedKeys[0]);
            setSearchedColumn(() => dataIndex);
            setSearchedColumnDisplay(() => columnname);
          }}
          icon={<SearchOutlined />}
          size="small"
          style={{
            width: 90,
          }}
        >
          Search
        </Button>
        <Button
          onClick={() =>
            clearFilters &&
            handleReset(
              clearFilters,
              setSearchedColumn,
              setSearchedColumnDisplay,
              confirm
            )
          }
          size="small"
          style={{
            width: 90,
          }}
        >
          Reset
        </Button>
        <Button
          type="link"
          size="small"
          onClick={() => {
            confirm({
              closeDropdown: false,
            });
            setSearchText(() => selectedKeys[0]);
            setSearchedColumn(() => dataIndex);
            setSearchedColumnDisplay(() => columnname);
          }}
        >
          Filter
        </Button>
        <Button
          type="link"
          size="small"
          onClick={() => {
            close();
          }}
        >
          close
        </Button>
      </Space>
    </div>
  ),
  filterIcon: (filtered) => (
    <div>
      {filtered ? (
        <FilterFilled
          style={{
            backgroundColor: filtered ? "#2196F23" : undefined,
          }}
        />
      ) : null}
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    </div>
  ),
  onFilter: (value, record) =>
    record[dataIndex]?.toString().toLowerCase().includes(value.toLowerCase()),
  onFilterDropdownOpenChange: (visible) => {
    if (visible) {
      setTimeout(() => searchInput.current?.select(), 100);
    }
  },
  render: (text) =>
    searchedColumn === dataIndex ? (
      <Highlighter
        highlightStyle={{
          backgroundColor: "#ffc069",
          padding: 0,
        }}
        searchWords={[searchText]}
        autoEscape
        textToHighlight={text ? text.toString() : ""}
      />
    ) : (
      text
    ),
});

import "./App.css";
import TabStructure from "./TabsStructure/TabStructure";
import React, { useReducer, useState, useContext } from "react";
import {
  tabStoreinitialState,
  TabStore,
  tabStorereducer,
} from "./TabsStructure/TabsStore";
import { notification } from "antd";
import "./Themes/theme.css"



import Login from "./Auth/Login";
import  {AuthStore,
  authStoreInitialState,
  authStoreReducer,
} from "./Auth/AuthStore";
import Auth from "./Auth/Auth";

function App() {

  const [tabStoreState, dispatchTabStoreState] = useReducer(
    tabStorereducer,
    tabStoreinitialState
  );
  const [authStoreState, dispatchAuthStore] = useReducer(
    authStoreReducer,
    authStoreInitialState
  );

  
  
  return (
    <>
      <AuthStore.Provider
        value={{ state: authStoreState, dispatch: dispatchAuthStore }}
      >
        <Auth/>
      </AuthStore.Provider>
    </>
  );
}

export default App;

import React, { useContext, useState } from "react";
import { EditOutlined, UnorderedListOutlined } from "@ant-design/icons";
import { Button, Space, Typography } from "antd";
import "../../../CommonControllers/global.css";
import LogTable from "./LogTable";
import {
  formatCurrency,
  dateFormatter,
  formatCurrency3,
} from "../../../CommonControllers/AppFunction";
import PurchaseOrder from "../../PurchaseOrder/PurchaseOrder";
import { checkFastPath } from "../../../TabsStructure/tabFunctions";
import { TabStore } from "../../../TabsStructure/TabsStore";
import useSaveChangedRows from "../../../CommonControllers/Hooks/useSaveChangedRows";
import CheckBoxFun from "../../../CommonControllers/Components/CustomCheckBox";
import CellCheckBox from "../../../CommonControllers/Components/TableComponent/CellCheckBox";
import CallbackCheckbox from "../../../CommonControllers/Components/CallbackComponents/CallbackCheckbox";
import { currentTabData } from "../../../CommonControllers/Variable";
import SaveCheckbox from "../../../CommonControllers/Components/SaveComponents/SaveCheckbox";

// import { PurchaseOrderLogColumns } from "./LogColumns";

const { Title } = Typography;

const Log = ({
  logData,
  showAllItems,
  columns,
  itemsColumn,
  setshowFilterModal,
  showFilterModal,
  statusC,
  setstatusC,
  forFlag,
  dontEdit,
  pageDetails,
}) => {
  const [showTable, setShowTable] = React.useState(false);
  const tabs = useContext(TabStore);
  const Changedrow = useSaveChangedRows();
  const [confirm, setConfirm] = useState([]);
  const [ewaybillno, setEwaybillno] = useState([]);

  // Create Function For Get Fast Path
  function knownFastPath() {
    if (forFlag == "PR") {
      return "TR004";
    }
    if (forFlag == "SR") {
      return "TR015";
    }
    if (forFlag == "PO") {
      return "TR001";
    }

    if (logData?.ponumber) {
      return "TR001";
    }
    //// the billno is remove the replace with doctype
    else if (logData?.doctype === "GR") {
      return "TR003";
    } else if (logData?.doctype === "PR") {
      return "TR004";
    } else if (logData?.doctype === "SO") {
      return "TR012";
    } else if (logData?.doctype === "DC") {
      return "TR014";
    } else if (logData?.doctype === "SI") {
      return "TR013";
    } else if (logData?.doctype === "PI") {
      return "TR002";
    } else {
      console.log("Function Not Working");
    }
  }

  function updateSelectedDocuments(e) {
    if (currentTabData(tabs)?.name === "TR035" && e === true) {
      let obj = {
        ...logData,
        status: "C",
      };
      setConfirm((prevConfirm) => [...prevConfirm, obj]);
    } else if (currentTabData(tabs)?.name === "TR016" && e) {
      var newSelectedArray = currentTabData(tabs)?.selectedDocuments?.filter(
        (item) => item?.instrumentno !== logData?.instrumentno
      );

      const newItem = {
        salebillno: logData?.instrumentno,
        // Add other properties as needed
      };

      if (e) {
        newSelectedArray.push(newItem);

        // Use the callback provided by setEwaybillno to ensure working with the updated state
        setEwaybillno((r) => {
          return [...r, newItem];
        });
      }

      // Here we will add them to the List of Selected Items

      tabs.dispatch({
        type: "UPDATE_DATA",
        fieldType: "changedRows",
        text: newSelectedArray,
      });
      tabs.dispatch({
        type: "UPDATE_DATA",
        fieldType: "selectedDocuments",
        text: newSelectedArray,
      });
    } else {
      var newSelectedArray = currentTabData(tabs)?.selectedDocuments?.filter(
        (item) => item?.instrumentno !== logData?.instrumentno
      );

      if (e === true) {
        newSelectedArray.push(logData?.instrumentno);
      }

      // Here we will add them to the List of Selected Item
      console.log("newSelectedArray", newSelectedArray);
      if (currentTabData(tabs)?.name === "DL001") {
        // fieldType: "deleteVoucherPayload",
        let modified = newSelectedArray.map((x) => {
          return {
            billno: x,
          };
        });
        console.log("Data===", modified);
        tabs.dispatch({
          type: "UPDATE_DATA",
          fieldType: "deleteVoucherPayload",
          text: modified,
        });
      } else {
        tabs.dispatch({
          type: "UPDATE_DATA",
          fieldType: "selectedDocuments",
          text: newSelectedArray,
        });
      }
    }
  }

  return (
    <div className="logMainFrame" style={{ padding: "5px", width: "100%" }}>
      <div
        className={
          logData?.status === "C" || logData?.status === "9"
            ? "logBox completed"
            : logData?.status === "P" || logData?.status === "0"
            ? "logBox pending"
            : logData?.status === "O"
            ? "logBox ordered"
            : (logData?.trnedit === 1 && logData?.status === 1) ||
              2 ||
              3 ||
              4 ||
              5
            ? "logBox pending"
            : logData?.trnedit === 0
            ? "logBox completed"
            : "logBox"
        }
      >
        <Space size={14}>
          {currentTabData(tabs)?.name.includes("PR") ||
          currentTabData(tabs)?.name.includes("TR016") ||
          currentTabData(tabs)?.name.includes("DL001") ||
          currentTabData(tabs)?.name.includes("RP") ? ( ///taskid-352 rajendra Eway Fastpath is added for checkbox
            <CallbackCheckbox
              editable
              returnValue={(e) => {
                if (logData?.instrumentno) {
                  updateSelectedDocuments(e);
                }
              }}
            />
          ) : currentTabData(tabs)?.name.includes("TR035") ? (
            <SaveCheckbox
              editable
              returnValue={(e) => {
                if (logData?.instrumentno) {
                  updateSelectedDocuments(e);
                }
              }}
            />
          ) : null}

          <Button
            type="primary"
            icon={<UnorderedListOutlined />}
            size="large"
            onClick={() => setShowTable((prev) => !prev)}
            disabled={showAllItems}
          >
            {`  ${logData?.items?.length}`}
          </Button>
        </Space>
        {/* <div className="supplierBox" style={{ border : "1px solid teal" }} >
          <Title level={5} color="#252525">
            Supplier
          </Title>
          <p>{logData?.suppliername}</p>
        </div> */}
        {/* <PurchaseOrderLogColumns logData={logData} /> */}

        <div style={{ ...columnsBox }}>
          {columns?.length > 0 &&
            columns?.map((elem, i) => (
              <div className="partyBox">
                <Title level={5} color="#252525">
                  {elem.title}
                </Title>
                {elem?.type === "date" ? (
                  // <p>{`${logData[elem?.columnname]
                  //   .toString()
                  //   ?.slice(-2)}/${logData[elem?.columnname]
                  //   .toString()
                  //   ?.slice(4, 6)}/${logData[elem?.columnname]
                  //   .toString()
                  //   ?.slice(0, 4)}`}</p>
                  <p>{dateFormatter(logData[elem?.columnname])}</p>
                ) : elem?.type === "decimal" ? (
                  // <p>{formatCurrency(logData[elem?.columnname])}</p>
                  //// Changes here
                  <p>{logData[elem?.columnname]}</p>
                ) : //////////

                elem?.type === "decimal3" ? (
                  <p>{formatCurrency3(logData[elem?.columnname])}</p>
                ) : (
                  <p>
                    {logData[elem?.columnname]}
                    {/*smk 22-2-2023 removed ?? logData?.selfdetails["descn"]*/}
                  </p>
                )}
              </div>
            ))}
        </div>
        {/* Add Ternary Operator For show Edit Button when logData Status Is Pending */}
        {logData?.trnedit > 0 ? ( /// Task-id 358 logData?.status === "O "is added
          // {logData?.trnedit > 0 ? (
          <Space size={14}>
            {!dontEdit ? (
              <Button
                type="primary"
                icon={<EditOutlined />}
                size="large"
                onClick={() => {
                  if (showFilterModal) {
                    logData.dataFromEdit = true;

                    tabs.dispatch({
                      type: "UPDATE_TABS_ARRAY",
                      changedRowsValue: {},
                      tabsFastPath: knownFastPath(),
                      // tabsFastPath: "TR004",
                      keyName: "changedRows",
                    });
                    tabs.dispatch({
                      type: "UPDATE_TABS_ARRAY",
                      changedRowsValue: "",
                      tabsFastPath: knownFastPath(),
                      keyName: "pageStatus",
                    });

                    tabs.dispatch({
                      type: "UPDATE_TABS_ARRAY",
                      changedRowsValue: logData,
                      tabsFastPath: knownFastPath(),
                      keyName: "changedRows",
                    });
                    tabs.dispatch({ type: "UPDATE_EDIT_MODE_CHANGED_ROWS" });
                    Changedrow.updateValue("billno", logData?.billno);
                    Changedrow.updateValue("pibillno", logData?.refbillno);
                    Changedrow.updateValue(
                      "supplierrecno",
                      logData?.supplierrecno
                    );
                    Changedrow.updateValue("status", logData?.status);
                    Changedrow.updateValue(
                      "locationrecno",
                      logData?.locationrecno
                    );

                    Changedrow.updateValue(
                      "customerrecno",
                      logData?.customerrecno
                    );
                    Changedrow.updateValue(
                      "customerdescn",
                      logData?.customerdescn
                    );
                    Changedrow.updateValue(
                      "suppliername",
                      logData?.supplierdescn
                    );
                    Changedrow.updateValue(
                      "locationdescn",
                      logData?.locationdescn
                    );

                    Changedrow.updateValue("dataFromEdit", true);

                    //setRowData([logData])
                    setshowFilterModal(!showFilterModal);
                    // setstatusC(!statusC);
                  } else {
                    // Function used to navigate to the Page
                    checkFastPath(knownFastPath(), tabs);
                    logData.dataFromEdit = true;

                    Changedrow.updateValue("status", logData?.status);
                    tabs.dispatch({
                      type: "UPDATE_TABS_ARRAY",
                      changedRowsValue: {},
                      tabsFastPath: knownFastPath(),
                      // tabsFastPath: "TR004",
                      keyName: "changedRows",
                    });
                    tabs.dispatch({
                      type: "UPDATE_TABS_ARRAY",
                      changedRowsValue: "",
                      tabsFastPath: knownFastPath(),
                      keyName: "pageStatus",
                    });

                    tabs.dispatch({
                      type: "UPDATE_TABS_ARRAY",
                      changedRowsValue: logData,
                      tabsFastPath: knownFastPath(),
                      keyName: "changedRows",
                    });
                    tabs.dispatch({ type: "UPDATE_EDIT_MODE_CHANGED_ROWS" });

                    // logData.dataFromEdit = true
                    // console.log("Log Data.........>>>>>>>>", logData);
                    // // Also need to add the data to the changedRows for the FastPath
                    // tabs.dispatch({
                    //   type: "UPDATE_TABS_ARRAY",
                    //   changedRowsValue: "",
                    //   tabsFastPath: knownFastPath(),
                    //   keyName: "pageStatus",
                    // });

                    // tabs.dispatch({ type: "UPDATE_EDIT_MODE_CHANGED_ROWS" });

                    // tabs.dispatch({
                    //   type: "UPDATE_TABS_ARRAY",
                    //   changedRowsValue: logData,
                    //   tabsFastPath: knownFastPath(),
                    //   keyName: "changedRows",
                    // });
                    // for Close Modal Of Search Bill
                    // setRowData([logData])
                  }
                }}
              >
                {`  ${logData?.items?.length}`}
              </Button>
            ) : null}
          </Space>
        ) : null}
      </div>
      <div
        className={
          !showAllItems && !showTable ? "logTableHidden" : "logTableVisible"
        }
      >
        <LogTable
          tableData={logData?.items}
          igstAppl={logData?.igstappl}
          itemsColumn={itemsColumn}
        />
      </div>
    </div>
  );
};

export default Log;

// CSS
const columnsBox = {
  padding: "2px",
  display: "flex",
  gap: "16px",
};

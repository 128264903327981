import React, { useContext, useEffect, useState, useRef } from "react";
import SaveDropDown from "../../../CommonControllers/SaveComponents/SaveDropDown";
import SaveDatePicker from "../../../CommonControllers/NewComponents/SaveComponents/NewSaveDatePicker";
import { Button, Table } from "antd";
import { TabStore } from "../../../TabsStructure/TabsStore";
import { AuthStore } from "../../../Auth/AuthStore";
import ItemsTable from "./ItemsTable";
import useSaveChangedRows from "../../../CommonControllers/Hooks/useSaveChangedRows";
import FilterComponents from "../../../CommonControllers/FiterComponent/FilterComponents";
import useExecute from "../../../CommonControllers/Hooks/useExecute";
import { getfastpathapi } from "../../../CommonControllers/ApiCalls";
import ExecuteDropdown from "../../../CommonControllers/Components/ExecuteComponents/ExecuteDropdown";
import SaveDropdown from "../../../CommonControllers/Components/SaveComponents/SaveDropdown";
import { handleAdd } from "./SalesInvoiceFunc";
import useShortguid from "../../../CommonControllers/Hooks/useShortGuid";
import ExecuteDateInput from "../../../CommonControllers/Components/ExecuteComponents/ExecuteDateInput";
import { v4 as uuidv4 } from "uuid";
import { IoAperture } from "react-icons/io5";
import SaveCheckbox from "../../../CommonControllers/Components/SaveComponents/SaveCheckbox";
import noData from "../../../Assets/noData.png";
import { enterPress } from "../../../CommonControllers/AppFunction";
import SaveRadioBtn from "../../../CommonControllers/Components/SaveComponents/SaveRadioBtn";

const WmsBilling = ({ fastPath }) => {
  const useChangedRows = useSaveChangedRows();
  const tabStore = useContext(TabStore);
  const auth = useContext(AuthStore);
  const [rowData, setRowData] = useState([]);
  const [pageIndex, setPageIndex] = useState();
  const [showTable, setShowTable] = useState(true);
  const [filterDictionary, setFilterDictionary] = useState([]);
  const [filterOptions, setFilterOptions] = useState([]);
  const execute = useExecute();
  const shortguid = useShortguid();
  const [customer, setCustomer] = useState(0);

  const [customerData, setCustomerData] = useState();
  const [igstapp, setIgst] = useState();

  const [dropDrownItem, setDropdownItem] = useState([]);
  const [fromdate, setFromdate] = useState(null);
  const [todate, setTodate] = useState(null);
  const [reset, setReset] = useState(0);

  const [statusOptions, setStatusOptions] = useState([]);

  // Create a state to edit Transactions
  const [transactionEdit, setTransactionEdit] = useState(false);

  useEffect(() => {
    var tabsArray = tabStore.state.tabs;
    for (let i = 0; i < tabsArray?.length; i++) {
      if (tabsArray[i].name == "WMS5") {
        setPageIndex(i);
        break;
      }
    }
  }, []);
  console.log("RTYGUIHO", customerData);
  useEffect(() => {
    setFromdate("");
    if (customerData) {
      setFromdate(
        customerData?.newbilldate ? customerData?.newbilldate : fromdate
      );
    }
    setIgst(customerData?.igstappl);
  }, [customerData]);
  useEffect(() => {
    setShowTable(false);

    let datamodified = handleAddtotable(
      Array.isArray(tabStore.state.tabs[pageIndex]?.apiResponse?.Message)
        ? tabStore.state.tabs[pageIndex]?.apiResponse?.Message
        : [],
      igstapp
    );
    console.log("datamodified", datamodified);
    if (datamodified) {
      setRowData(datamodified);
    }
    tabStore.dispatch({
      type: "UPDATE_DATA",
      fieldType: "pageStatus",
      text: null,
    });
    setTimeout(() => {
      setShowTable(true);
    }, 200);
  }, [tabStore.state.tabs[pageIndex]?.apiResponse]);
  useEffect(() => {
    const currentPage = tabStore.state.tabs[pageIndex];

    if (
      currentPage &&
      Object.keys(currentPage.apiVariables || {}).length === 0 &&
      Object.keys(currentPage.changedRows || {}).length === 0
    ) {
      setReset((prevState) => prevState + 1);
    }
  }, [
    tabStore.state.tabs[pageIndex]?.apiVariables,
    tabStore.state.tabs[pageIndex]?.changedRows,
    pageIndex,
  ]);

  // ----------loading end------------------------------

  const handleAddtotable = (data, igstappl) => {
    let mod = data?.map((item) => {
      console.log("data", item);
      var salerate = item.rate ? item.rate : 0.0;
      var cgstrate = item?.cgstrate ? item.cgstrate : 0.0;
      var sgstrate = item?.sgstrate ? item.sgstrate : 0.0;
      var igstrate = item?.igstrate ? item.igstrate : 0.0;
      let conversionfactor = item?.conversionfactor ? item.conversionfactor : 1;
      var packingqty = item.packingqty ? item.packingqty : 0;
      var packingextraqty = item.box ? item.box : 0;

      let isservice = item?.isservice; //// task id -345 is service rajendra
      var freepackingextraqty = item.free ? item.free : 0;
      var freepackingqty = item.free ? item.free : 0;
      var free =
        Number(freepackingqty) +
        Number(freepackingextraqty) * Number(conversionfactor);

      var qty =
        Number(Number(item.qty).toFixed(2)) +
        Number(packingqty) +
        Number(packingextraqty) * Number(conversionfactor);

      // var discount = discountPer ? Number(discountPer) : 0.0;
      var discountamt = item.discount ? Number(item.discount) : 0.0;
      // var expdate = expDate ? expDate : null;
      var manufacturedate = manufacturedate ? manufacturedate : null;
      //var itembatchno = batchNo ? batchNo : null;

      var taxableamount = qty * (salerate - discountamt);
      var sgstamt = !igstappl ? taxableamount * Number(sgstrate / 100) : 0.0;
      var cgstamt = !igstappl ? taxableamount * Number(cgstrate / 100) : 0.0;
      var igstamt = igstappl ? taxableamount * Number(igstrate / 100) : 0.0;

      var amount = igstappl
        ? taxableamount + igstamt
        : taxableamount + (cgstamt + sgstamt);

      var itemObject = {
        ...item,
        shortguid: shortguid?.getShortguid("WMSBILL" + item.itemrecno),
        itemrecno: item.itemrecno,
        itemdescn: item.itemdescn,
        packingextraqty: packingextraqty,
        packingqty: packingqty,
        qty: Number(qty),
        packingqty: Number(qty),
        free: Number(free),
        freeqty: Number(free),
        salerate: Number(salerate).toFixed(2),
        taxableamount: Number(taxableamount).toFixed(2),
        cgstrate: !igstappl ? Number(cgstrate).toFixed(2) : 0,
        sgstrate: !igstappl ? Number(sgstrate).toFixed(2) : 0,
        igstrate: igstappl ? Number(igstrate).toFixed(2) : 0,
        cgstamt: !igstappl ? Number(cgstamt).toFixed(2) : 0,
        sgstamt: !igstappl ? Number(sgstamt).toFixed(2) : 0,
        igstamt: igstappl ? Number(igstamt).toFixed(2) : 0,
        amount: Number(amount).toFixed(2),
        discount: Number(item.discount).toFixed(2),
        discountamt: Number(discountamt).toFixed(2),
        expdate: item.expdate,
        itembatchno: item?.itembatchno ? item.itembatchno : ".",
        //// task-id-345 rajendra here isservice validation
        // itembatchno:
        //   item?.wantbatchno === 0 && isservice == 0
        //     ? "."
        //     : isservice === 1 && item?.wantbatchno === 0
        //     ? "."
        //     : item.itembatchno,
        freepackingextraqty: Number(freepackingextraqty),
        freepackingqty: Number(freepackingqty),
        manufacturedate: manufacturedate
          ? manufacturedate
          : item?.manufacturedate,
        // onedconeinvoice: onedconeinvoice,
      };
      if (item?.doctype == "DC") {
        itemObject.dcqty = Number(qty);
        itemObject.itembatcharray = itemObject?.batcharray;
      }
      return itemObject;
    });
    return mod;
  };

  useEffect(() => {
    const payload = {
      tenantrecno: auth?.state?.userdata?.tenantrecno,
      domainrecno: auth?.state?.userdata?.domainrecno,
      userrolerecno: auth?.state?.userdata?.userrolerecno,
      fastpath: tabStore.state.tabs[pageIndex]?.name,
    };

    execute.updateValue("forreport", "Y");

    // On component mount this api is called and filterOptions are set for that fast path
    getfastpathapi(payload).then((res) => {
      if (res?.Success) {
        setFilterOptions(res?.Message?.filterOptions);
        setStatusOptions([
          {
            label: "Save",
            value: res.Message?.defaultStatus,
          },
          {
            label: "Proceed",
            value: res.Message?.nextStatus,
          },
        ]);
        // setReportName(res?.Message?.descn);
      }
    });
  }, [pageIndex]);
  useEffect(() => {
    // When Row Data Changes we Need to store that data to the tabsStore with Header Data
    var existingObject = useChangedRows.getValue();
    var amount; // Calculate the total amount after tax
    var taxableamount; // Calculate the total taxable amount after tax
    var cgstamt; //Calculate the CGSTAMT
    var sgstamt; //Calculate teh SGST AMT
    var igstamt; //Calculat the IGSTAMT
    var shortguid;

    amount = rowData?.reduce((total, item) => total + Number(item.amount), 0);

    taxableamount = rowData?.reduce(
      (total, item) =>
        total + Number(item.taxableamount ? item.taxableamount : 0),
      0
    );
    cgstamt = rowData?.reduce(
      (total, item) => total + Number(item?.cgstamt ? item?.cgstamt : 0),
      0
    );
    sgstamt = rowData?.reduce(
      (total, item) => total + Number(item?.sgstamt ? item?.sgstamt : 0),
      0
    );
    igstamt = rowData?.reduce(
      (total, item) => total + Number(item?.igstamt ? item?.igstamt : 0),
      0
    );

    // rowDatamodified=convertToNormalString(rowDatamodified);

    existingObject = {
      ...existingObject,
      cgstamt: Number(cgstamt).toFixed(2),
      igstamt: Number(igstamt).toFixed(2),
      sgstamt: Number(sgstamt).toFixed(2),
      taxableamount: Number(taxableamount).toFixed(2),
      amount: Number(amount).toFixed(2),
      shortguid: shortguid?.getShortguid("WMSBILL")
        ? shortguid?.getShortguid("WMSBILL")
        : new Date(), /// shortguid was going null after second time saving

      items: rowData,
      customerrecno: dropDrownItem,
      status: "9",
      customerrecno: customer,
      tobilldate: tabStore.state.tabs[pageIndex]?.apiVariables?.todate,
      frombilldate: tabStore.state.tabs[pageIndex]?.apiVariables?.fromdate,
    };
    console.log("RTGHGFUGVU", rowData);
    useChangedRows.updateValue(false, existingObject);
    //AddInitialLoad()
  }, [rowData]);
  useEffect(() => {
    if (
      tabStore.state.tabs[pageIndex]?.pageStatus == "Success" &&
      rowData.length > 0
    ) {
      console.log("DTUFU&FVU",rowData)
      setStatusOptions([
        {
          label: "Save",
          value: false,
        },
        {
          label: "Proceed",
          value: false,
        },
      ]);
      setReset((p)=>p+1)
      // clearing logic
      setRowData([]);
      // setCustomer(null);
      // useChangedRows.updateValue("refbillno", null);
      setCustomer("")
      setFromdate(0);
      setTodate(getOneDayBeforeToday());
      // setigstappl(false);
      // setSelectedItem(null);
      // setSiNumber("TBA");
      // setvehiclenumber("");
      // setdrivername("");
      tabStore.dispatch({type:"UPDATE_DATA",fieldType: "apiVariables", text:{}})
      tabStore.dispatch({
        type: "UPDATE_DATA",
        fieldType: "pageStatus",
        text: null,
      });
      tabStore.dispatch({
        type: "UPDATE_DATA",
        fieldType: "apiResponse",
        text: null,
      });

      // setModalCount((p) => p + 1);
      setTimeout(
        tabStore.dispatch({
          type: "UPDATE_DATA",
          fieldType: "pageStatus",
          text: null,
        }),
        1000
      );
      const payload = {
        tenantrecno: auth?.state?.userdata?.tenantrecno,
        domainrecno: auth?.state?.userdata?.domainrecno,
        userrolerecno: auth?.state?.userdata?.userrolerecno,
        fastpath: tabStore.state.tabs[pageIndex]?.name,
      };
      setTimeout(
        getfastpathapi(payload).then((res) => {
          if (res?.Success) {
            setFilterOptions(res?.Message?.filterOptions);
            setStatusOptions([
              {
                label: "Save",
                value: res.Message?.defaultStatus,
              },
              {
                label: "Proceed",
                value: res.Message?.nextStatus,
              },
            ]);
            // setReportName(res?.Message?.descn);
          }
        }),
        2000
      );
    }
    // let payload = {};
    // FetchLoyality(payload);
  }, [tabStore.state.tabs[pageIndex]?.pageStatus]);

  // Below use effect used to update data from TabStore
  useEffect(() => {
    if (tabStore.state.tabs[pageIndex]?.changedRows?.dataFromEdit) {
      setRowData(tabStore.state.tabs[pageIndex]?.changedRows?.items);
      setTransactionEdit(true);
      setStatusOptions([
        {
          label: "Save",
          value: tabStore.state.tabs[pageIndex]?.changedRows?.status,
        },
        {
          label: "Proceed",
          value: tabStore.state.tabs[pageIndex]?.changedRows?.nextStatus,
        },
      ]);
    }
  }, [tabStore.state.tabs[pageIndex]?.changedRows?.dataFromEdit]);

  const AddRows = () => {
    let obj = {
      ...dropDrownItem,
      item: dropDrownItem?.itemdescn,
      qty: dropDrownItem.qty ? dropDrownItem.qty : "",
      rate: dropDrownItem.salerate,
      salerate: dropDrownItem.salerate,
      amount: dropDrownItem.amount
        ? dropDrownItem.amount
        : dropDrownItem.amount,
      newone: 1,
      cgstrate: dropDrownItem.cgstrate && !igstapp ? dropDrownItem.cgstrate : 0,
      sgstrate: dropDrownItem.sgstrate && !igstapp ? dropDrownItem.sgstrate : 0,
      igstrate: dropDrownItem.igstrate && igstapp ? dropDrownItem.igstrate : 0,
      itemrecno: dropDrownItem.itemrecno,
      shortguid: shortguid?.getShortguid("WMSBILL" + dropDrownItem?.itemrecno),
    };
    console.log("dropDrownItem", dropDrownItem, obj);
    let x = rowData?.filter((it) => it.itemrecno == obj.itemrecno);
    if (!obj.item) {
      alert("Can't add blank item!");
      return;
    }
    if (x?.length == 0 && obj.item) {
      setRowData((p) => [...p, obj]);
    } else {
      alert("Item already exist!!!");
    }
  };

  const AddInitialLoad = () => {
    for (let i = 0; i < rowData?.length; i++) {
      //console.log("ytfyfuyfuy",tabStore.state.tabs[pageIndex]?.apiResponse,rowData)

      console.log("ytfyfuyfuy", rowData[i]);
      // handleAdd(
      //   rowData[i],
      //   setRowData,

      // );
    }
  };
  /// date 1 june 2023 validation start ///
  useEffect(() => {
    let isButtonDisabled = false;

    for (let i = 0; i < rowData.length; i++) {
      const {
        packingqty,
        packingextraqty,
        freepackingqty,
        freepackingextraqty,
        manufacturedate,
        expdate,
        itembatchno,
        purrate,
        expdatecompulsory,
        itemasbatch,
        manudatecompulsory,
        wantbatchno,
        changesalerateinbill,
        isservice,
        qty,
        freeqty,
      } = rowData[i];

      if (qty == 0) {
        isButtonDisabled = true;

        break;
      }
      // if (isservice === 1 && qty + freeqty > 0) {
      //   isButtonDisabled = false;

      //   break;
      // }
    }

    useChangedRows.updateValue(
      "validated",
      !isButtonDisabled ? "true" : "false"
    );
    if (rowData.length == 0) {
      useChangedRows.updateValue("validated", "false");
    }
  }, [rowData]);

  useEffect(() => {
    setTodate(getOneDayBeforeToday());
  }, []);
  function getOneDayBeforeToday() {
    // Get the current date
    let currentDate = new Date();

    // Subtract one day
    currentDate.setDate(currentDate.getDate() - 1);

    // Get individual components of the date
    let day = currentDate.getDate();
    let month = currentDate.getMonth() + 1; // Months are zero-based, so add 1
    let year = currentDate.getFullYear();

    // Format the result as "dd-mm-yyyy"
    let formattedDate = `${year}${addLeadingZero(month)}${addLeadingZero(day)}`;

    return formattedDate;
  }
  function addLeadingZero(number) {
    return number < 10 ? `0${number}` : number;
  }

  console.log("customerData", tabStore);
  return (
    <>
      <div key={reset}>
        {/* <FilterComponents
          filterOption={filterOptions}
          returnValue={(e) => {
            setCustomer(e?.customerrecno ? e?.customerrecno : 0);
          }}
        /> */}
        <div
          onKeyDown={enterPress}
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            gap: "24px",
            paddingTop: "20px",
            margin: "30px",
          }}
        >
          <div onKeyDown={enterPress} style={{ marginTop: "10px",zIndex:500 }}>
            <ExecuteDropdown
            key={reset}
              displayLabel="Party"
              placeholder="Select Party"
              apikey="customerrecno"
              apiurl="getdomaincustomerlist"
              selectionLabel="customerdescn"
              selectionValue="customerrecno"
              returnValue={(e) => {
                setCustomer(e?.customerrecno ? e?.customerrecno : 0);
                setCustomerData(e);
              }}
              width="300px"
              alignment="column"
              minInput={0}
              inputValue={20}
              //body={{ details: true, customerrecno: customer }}
              isrequired={1}
              defaultSelected={customer}
            />
          </div>

          <div onKeyDown={enterPress} style={{ marginTop: "10px",zIndex:400 }}>
            <ExecuteDateInput
              width={"150px"}
              id={uuidv4()}
              displayLabel={"From Date"}
              placeholder={"Select From Date"}
              defaultValue={
                fromdate?fromdate : ""
              }
              apiKey={"fromdate"}
              dateLessthan={todate}
              // dateGreaterthan={customerData?.newbilldate}
              returnValue={(e) => {
                console.log("rdtygui21", e);
                // setterFunction(Number(e), elem);
                // storeData(e, elem?.apiKey);
                setFromdate(e);
              }}
              // key={customerData}
              //key={reset}
              isrequired={1}
              alignment="column"
              change
              editable
            />
          </div>

          <div onKeyDown={enterPress} style={{ marginTop: "10px" ,zIndex:300}}>
            <ExecuteDateInput
              width={"140px"}
              id={uuidv4()}
              displayLabel={"To Date"}
              placeholder={"Select To Date"}
              defaultValue={Number(todate)}
              apiKey={"todate"}
              // dateLessthan={todate}
              returnValue={(e) => {
                console.log("rdtygui2", e);
                setTodate(e);
                // setterFunction(Number(e), elem);
                // storeData(e, elem?.apiKey);
              }}
              // key={fastPath}
              alignment="column"
              isrequired={1}
              change
              editable
              key={reset}
            />
          </div>

          {/* Status */}
          {/* <div onKeyDown={enterPress}>
            <div style={{ position: "relative" }}>
              <label
                className={`label ${"focused"}`}
                style={{
                  ...otherStyles,
                  zIndex: 1000,
                  top: "-10px",
                  left: "12px",
                }}
              >
                Status
              </label>
              <div
                onKeyDown={enterPress}
                className="saveproceed"
                style={{
                  padding: "8.7px",
                  marginTop: "4px",
                }}
              >
                <SaveRadioBtn
                  apikey={"status"}
                  // defaultValue={pageDetails?.defaultStatus}
                  options={statusOptions}
                />
              </div>
            </div>
          </div> */}

          <div
            onKeyDown={enterPress}
            style={{ display: "flex",zIndex:200, marginTop: "10px" }}
          >
            {rowData?.length > 0 && (
              <SaveDropdown
                displayLabel="Services"
                placeholder="Select Services"
                apikey="itemrecno"
                apiurl="getdomaincustomeritemlistforwms"
                selectionLabel="itemdescn"
                selectionValue="recno"
                returnValue={(e) => {
                  setDropdownItem(e);
                }}
                alignment="column"
                width="300px"
                minInput={0}
                inputValue={20}
                body={{ details: true, customerrecno: customer }}
              />
            )}

            {rowData?.length > 0 && (
              <Button
                className="btn item-search-btn"
                style={{marginLeft: "24px"}}
                icon={<IoAperture size="1.2rem" />}
                size="middle"
                onClick={AddRows}
              >
                Add
              </Button>
            )}
          </div>

          {/* Print Bill */}
          <div onKeyDown={enterPress} style={{ marginTop: "20px" }}>
            <SaveCheckbox
              apikey={"isPrint"}
              displayLabel="Print Bill"
              editable
              defaultValue={false}
              // key={modalcount}
            />
          </div>
        </div>
        {rowData?.length > 0 ? (
          <ItemsTable
            rowData={rowData}
            setRowData={setRowData}
            igstappl={igstapp}
          />
        ) : rowData?.length == 0 &&
          tabStore.state.tabs[pageIndex]?.apiResponse ? (
          <div className="noDataFound">
            <h1>Oops! No Records Found</h1>
            <img src={noData} />
          </div>
        ) : null}
      </div>
    </>
  );
};
export default WmsBilling;

// CSS
const otherStyles = {
  whiteSpace: "nowrap",
  wordWrap: "unset",
  position: "absolute",
  //backgroundColor: "white",
  padding: "0 4px",
  fontSize: "12px",
  color: "black",
  pointerEvents: "none",
  transform: "translateY(0)",
  transition: "top 0.3s, transform 0.3s, font-size 0.3s, color 0.3s",
};

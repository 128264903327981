import React, {useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import CallbackDropdown from "../Components/CallbackComponents/CallbackDropdown";
import CallbackCheckbox from "../Components/CallbackComponents/CallbackCheckbox";
import CallbackTextInput from "../Components/CallbackComponents/CallbackTextInput";
import CallbackDateInput from "../Components/CallbackComponents/CallbackDateInput";
import CallbackRadioBtn from "../Components/CallbackComponents/CallbackRadioBtn";
import CallbackFileInput from "../Components/CallbackComponents/CallbackFileInput";

const WMSItemsDetails = ({
  returnValue,
  customerrecno,
  supplierrecno,
  itemFilterOption,
}) => {
  const [itemFilterOptions, setItemFilterOptions] = useState([]);
  const [itemFilterDictionary, setItemFilterDictionary] = useState([]);
  const [selectedItemFilterOptions, setSelectedItemFilterOptions] = useState(
    {}
  );

  useEffect(() => {
    setItemFilterOptions(itemFilterOption);
  }, [itemFilterOption]);

  useEffect(() => {
    if (customerrecno) {
      setSelectedItemFilterOptions((p) => {
        return { ...p, customerrecno: customerrecno.customerrecno };
      });
    } else if (supplierrecno) {
      setSelectedItemFilterOptions((p) => {
        return { ...p, supplierrecno: supplierrecno.supplierrecno };
      });
    }
  }, [customerrecno, supplierrecno]);

  //* This function sets the values obtained from the filteroptions above
  const setterFunction = (event, element) => {
    let displayValue;
    //# May need to use logical OR operator
    typeof event === "object"
      ? (displayValue = event?.displayLabel ?? event.label)
      : (displayValue = event);

    console.log(
      "FC ln 64 value ========>",
      element["displayLabel"],
      displayValue,
      "item filter dict",
      itemFilterDictionary
    );

    //% Check here whether array contains the same id, if yes replace it with new object

    const targetReplacer = itemFilterDictionary?.find(
      (obj) => obj.id === element.displayLabel
    );
    if (targetReplacer) {
      //~# alert(`Replaced previous filter: ${element?.displayLabel}`);
      Object.assign(targetReplacer, {
        id: element?.displayLabel,
        descn: displayValue,
      });
      return;
    }

    console.log("FC target found ??", targetReplacer);

    //% Setting id as displayLabel ==> id : Domain and key : value as ==> Domain : SAPL (example)
    setTimeout(() => {
      let tempArray = [
        ...itemFilterDictionary,
        { id: element?.displayLabel, descn: displayValue },
      ];

      console.log("FC", tempArray);

      setItemFilterDictionary(tempArray);
    }, 100);

    returnValue(selectedItemFilterOptions);
  };

  const storeData = (e, apikey, labeldropdowns) => {
    // Create a Temporary Add Store in the TabStore
    console.log("FC events ===>", newData, e, apikey);
    var newData = selectedItemFilterOptions;
    newData[apikey] = e;
    setSelectedItemFilterOptions(newData);

    // if (setItemFilterdropdown) {
    //   setItemFilterdropdown(labeldropdowns); // smk 24-2-
    // }

    console.log("SD", e, apikey, labeldropdowns);
  };

  // Switch to the next field as we click the Enter button
  const enterPress = (e) => {
    // when the button is disable also we have make to move the next elements
    if (e.keyCode === 13) {
      e.preventDefault();
      const form = e.target.form;
      const index = Array.prototype.indexOf.call(form, e.target);

      // Find the next focusable element that is not disabled
      let nextIndex = index + 1;
      while (form.elements[nextIndex] && form.elements[nextIndex].disabled) {
        nextIndex++;
      }

      // Focus on the next focusable and non-disabled element
      if (form.elements[nextIndex]) {
        form.elements[nextIndex].focus();
      }
    }
  };
  console.log("itemFilterOptions", itemFilterOptions);
  return (
    <form className="itemsDetails">
      {itemFilterOptions?.map((elem) =>
        elem.type === "dropdown" ? (
          <div onKeyDown={enterPress}>
            <CallbackDropdown
              displayLabel={elem?.title}
              alignment="column"
              placeholder={elem?.placeholder}
              apiUrl={elem?.apiurl}
              selectionLabel={elem?.selectionLabel}
              selectionValue={elem?.selectionValue}
              apikey={elem?.apiKey}
              multi={elem?.isMulti}
              records={20}
              minInput={0}
              returnValue={(e) => {
                if (e) {
                  console.log(
                    "FC:",
                    e[elem?.selectionValue],
                    elem?.apiKey,
                    e[elem?.selectionLabel]
                  );
                  setterFunction(e, elem);
                  storeData(
                    e[elem?.selectionValue],
                    elem?.apiKey,
                    e[elem?.selectionLabel]
                  );
                }
              }}
              body={{
                ...selectedItemFilterOptions,
              }}
              requiredBody={elem?.payloadKey}
              width={elem?.width}
              defaultSelected={elem?.defaultValue}
              isrequired={elem?.req}
            />
          </div>
        ) : elem.type === "boolean" ? (
          <div onKeyDown={enterPress}>
            <CallbackCheckbox
              alignment="column"
              displayLabel={elem?.title}
              placeholder={elem?.placeholder}
              apikey={elem?.apiKey}
              defaultValue={elem?.defaultValue}
              change
              editable
              returnValue={(e) => {
                setterFunction(e, elem);
                storeData(e, elem?.apiKey);
              }}
            />
          </div>
        ) : elem.type === "int" ? (
          <div onKeyDown={enterPress}>
            <CallbackTextInput
              alignment="column"
              type={"number"}
              displayLabel={elem?.title}
              placeholder={elem?.placeholder}
              apikey={elem?.apiKey}
              defaultValue={elem?.defaultValue}
              change
              returnValue={(e) => {
                setterFunction(e, elem);
                storeData(e, elem?.apiKey);
              }}
              isrequired={elem?.req}
              width={elem?.width}
              style={{ textAlign: "right" }}
            />
          </div>
        ) : elem.type === "decimal" ? (
          <div onKeyDown={enterPress}>
            <CallbackTextInput
              alignment="column"
              type={"number"}
              displayLabel={elem?.title}
              placeholder={elem?.placeholder}
              apikey={elem?.apiKey}
              defaultValue={elem?.defaultValue}
              change
              returnValue={(e) => {
                console.log("FC", e);
                setterFunction(e, elem);
                storeData(e, elem?.apiKey);
              }}
              isrequired={elem?.req}
              width={elem?.width}
              style={{ textAlign: "right" }}
            />
          </div>
        ) : elem.type === "string" ? (
          <div onKeyDown={enterPress}>
            <CallbackTextInput
              alignment="column"
              displayLabel={elem?.title}
              placeholder={elem?.placeholder}
              apikey={elem?.apiKey}
              defaultValue={elem?.defaultValue}
              change
              returnValue={(e) => {
                console.log("FC", e);
                setterFunction(e, elem);
                storeData(e, elem?.apiKey);
              }}
              isrequired={elem?.req}
              width={elem?.width}
              maxlength={elem?.columnlength}
            />
          </div>
        ) : elem.type === "date" ? (
          <div onKeyDown={enterPress} style={{zIndex:1000}}>
            <CallbackDateInput
              id={uuidv4()}
              alignment="column"
              defaultValue={elem?.defaultValue ? elem.defaultValue : ""}
              displayLabel={elem?.title}
              placeholder={elem?.placeholder}
              apiKey={elem?.apiKey}
              change
              editable
              returnValue={(e) => {
                console.log("FC", e);
                setterFunction(Number(e), elem);
                storeData(e, elem?.apiKey);
              }}
              width={`${elem?.width}px`}
            />
          </div>
        ) : elem.type === "file" ? (
          <div onKeyDown={enterPress}>
            <CallbackFileInput
              displayLabel={elem?.title}
              placeholder={elem?.placeholder}
              apiKey={elem?.apiKey}
              returnValue={(e) => {
                setterFunction(e, elem);
                storeData(e, elem?.apiKey);
              }}
            />
          </div>
        ) : elem.type === "radio" ? (
          <div onKeyDown={enterPress}>
            <CallbackRadioBtn
              apikey={"radio"}
              displayLabel={"Select Radio"}
              options={elem?.radioOptions}
              defaultValue={elem?.defaultValue}
              returnValue={(e) => {
                setterFunction(e, elem);
                storeData(e, elem?.apiKey);
              }}
            />
          </div>
        ) : null
      )}
    </form>
  );
};

export default WMSItemsDetails;

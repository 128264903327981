// Nohit Patel 20/02/2023
import { Button, Collapse, Input, Modal, Space, Table, Typography } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { getToday, dateFormatter } from "../../CommonControllers/AppFunction";
import BillingTable from "../../CommonControllers/Components/BillingTableComponents/BillingTable";
import ItemSearch from "../../CommonControllers/ItemController/ItemSearch";
import SupplierSaveDropDown from "../../CommonControllers/SaveComponents/HigherLevel/SupplierSaveDropDown";
import SaveDatePicker from "../../CommonControllers/SaveComponents/SaveDatePicker";
import { handleAdd, prColumns } from "./SaleReturnBillingTableColumns";
import useSaveChangedRows from "../../CommonControllers/Hooks/useSaveChangedRows";
import useShortguid from "../../CommonControllers/Hooks/useShortGuid";
import { TabStore } from "../../TabsStructure/TabsStore";
import { AuthStore } from "../../Auth/AuthStore";
import SaveDropDown from "../../CommonControllers/SaveComponents/SaveDropDown";
import { v4 as uuidv4 } from "uuid";
import {
  domainLocationURL,
  getCustomer,
  getcustomerlistURL,
  getDomaincustomerlist,
} from "../../CommonControllers/Urls";
import "../../CommonControllers/global.css";
import CallbackTextInput from "../../CommonControllers/Components/CallbackComponents/CallbackTextInput";
import { IoAperture } from "react-icons/io5";
import UserLocationList from "../../CommonControllers/SaveComponents/HigherLevel/UserLocationList";
import ItemsTable from "./ItemsTable";
import { useRef } from "react";
import CallbackDateInput from "../../CommonControllers/Components/CallbackComponents/CallbackDateInput";
import SaveDropdown from "../../CommonControllers/Components/SaveComponents/SaveDropdown";
import SaveTextInput from "../../CommonControllers/Components/SaveComponents/SaveTextInput";

import { getSalebill, getfastpathapi } from "../../CommonControllers/ApiCalls";
import SearchFilterByBillNo from "../../CommonControllers/Components/SearchFilterBillNo/SearchFilterByBillNo";
import { borderRadius } from "@mui/system";
import Searchsalebillnumber from "./Searchsalebillnumber";
import { CleaningServices } from "@mui/icons-material";
import { currentTabData } from "../../CommonControllers/Variable";
import useFetchItem from "../../CommonControllers/ItemController/useFetchItems";
import SearchItemsTable from "./SearchItemsTable"
import { handleAdd2 } from "./SearchSaleReturnBillingTableColumns";
//import async from "react-select/dist/declarations/src/async";
function StockExchange() {
  const [getSelectedItem, setSelectedItem] = useState(null);
  const [rowData, setRowData] = useState([]);
  const [customerdata, setCustomerdata] = useState(null);
  const [igstappl, setigstappl] = useState(false);
  const useChangedRows = useSaveChangedRows();
  const [displayMessages, setDisplayMessages] = useState(false);
  const [showFilters, setShowFilters] = useState(true);
  const [saleRate, setSaleRate] = useState(" "); // smk 7-3-2023 purchaserate -salerate
  const [selectedQty, setSelectedQty] = useState(" "); // Qty as in pieces in UI, total = pieces * rate
  const [box, setBox] = useState("");
  const [batch, setbatch] = useState(" ");
  const [expDate, setexpDate] = useState(null);
  const [collapse, setCollapse] = useState(false);
  const [expDeliveryDays, setExpDeliveryDays] = useState(null);
  const [datapayload, setDatapayload] = useState([]);
  // saleshortuid
  const [saleshortguid, setsaleShortguid] = useState("");
  const [pageIndex, setPageIndex] = useState();
 

 

  // ---------------------Edit Transtion States------------------
  // Create useState for Show POP Of Search Bill
  const [showFilterModal, setshowFilterModal] = useState(false);
  const [customerdata1, setCustomerdata1] = useState(null);
  const [location1, setlocation1] = useState();
  // render
  const [count, setCount] = useState(0);
  const [srNumber, setSrNumber] = useState();
  const [location, setlocation] = useState();
  // --------------------------------------------------------------

  ///  new model state for search invoice
  const [model, setModel] = useState(false);
  const [counter, Setcounter] = useState(0);
  const [statusC, setstatusC] = useState(false);
  console.log("statusC on sale return", customerdata1);

  const { Panel } = Collapse;
  const auth = useContext(AuthStore);
  const shortguid = useShortguid();
  const tabStore = useContext(TabStore);

  const changerow = tabStore?.state?.tabs[tabStore.state.tabIndex]?.changedRows;

  const [pi, setPino] = useState(null);

  const [countRender, setcountRender] = useState(0);

  const [stock,setStock] = useState([]);
  const [item, setItem] = useState()

  console.log("pppp=====", stock);
  useEffect(()=>{
let array=getSelectedItem?.batchdetails?.filter((item)=>item?.selectedQty>0)
addItemstoTable()
//console.log("Rajendra=====", array)
setItem(array)
  },[getSelectedItem])

  /// page details is added 
  const [pageDetails, setPageDetails]= useState();
  const payld = {
    tenantrecno: auth?.state?.userdata?.tenantrecno,
    domainrecno: auth?.state?.userdata?.domainrecno,
    userrolerecno: auth?.state?.userdata?.userrolerecno,
    domainuserrecno: auth.state.userdata.recno,
    fastpath: currentTabData(tabStore)?.name,
  };

  //// here Date 29 jun rajendra 
  useEffect(() => {
    // Get Fast ath API
    getfastpathapi(payld).then((res) => {
      console.log("rr", res);
      if (res?.Success) {
        setPageDetails(res?.Message);
        console.log("Resposnse of Fast Path API", res.Message)
        
      }
    });
  }, [])

  useEffect(() => {
    var tabsArray = tabStore.state.tabs;
    for (let i = 0; i < tabsArray?.length; i++) {
      if (tabsArray[i].name == "TR002") {
        setPageIndex(i);
        break;
      }
    }
  }, []);

  useEffect(() => {
    getSelectedItem?.salerate !== undefined
      ? setSaleRate(getSelectedItem?.salerate)
      : setSaleRate("");
  }, [getSelectedItem?.salerate]);

  useEffect(() => {
    let _temp = changerow?.billno;
    console.log("kkkk", _temp);
    if (_temp?.length > 0) {
      handleAdditem();
    }
  }, [counter, model]);
  //~# Setting workdate
  let workdateyymmdd = auth?.state?.workdate;
  let workdate = auth?.state?.workdate;
  workdate = JSON.stringify(workdate);
  workdate = `${workdate?.slice(-2)}/${workdate?.slice(4, 6)}/${workdate?.slice(
    0,
    4
  )}`;

  function setDatatoCustomerdata(e) {
    setCustomerdata1(e);
    setlocation1(e);
  }
  useEffect(() => {
    //customerdata1 have igstappl field not customerdata

    console.log("Customer Data in Sale REturn", customerdata1);
    if (customerdata1?.igstappl) {
      setigstappl(true);
      // dateSetter();
    } else {
      setigstappl(false);
      //dateSetter();
    }
  }, [customerdata1]);

  useEffect(() => {
    // When Row Data Changes we Need to store that data to the tabsStore with Header Data
    var existingObject = useChangedRows.getValue();

    console.log("RRRR", existingObject);

    // Calculate Other Numbers and Inputs and store in the tabStore
    var status = "P";
    var approvalStatus = "P";
    var remark = "Need to add a field for that";
    var narration = "Need to ad a text field for this as well";
    var amount; // Calculate the total amount after tax
    var taxableamount; // Calculate the total taxable amount after tax
    var cgstamt; //Calculate the CGSTAMT
    var sgstamt; //Calculate teh SGST AMT
    var igstamt; //Calculat the IGSTAMT
    var returnamount; //Calculate the Return amount
    // var shortguid;

    //console.log("ROW DATA", rowData);
    var reqpayload = [];
    for (let i = 0; i < rowData.length; i++) {
      //console.log("ROW DATA", rowData);
      let item = rowData[i];

      if (item["returnqty"] !== undefined) {
        //console.log("CHH",item["returnqty"])
        reqpayload.push(item);
      }
    }

    amount = reqpayload.reduce((total, item) => total + Number(item.amount), 0);
    taxableamount = datapayload.reduce(
      (total, item) => total + Number(item.taxableamount),
      0
    );
    cgstamt = reqpayload.reduce(
      (total, item) => total + Number(item?.cgstamt),
      0
    );
    sgstamt = reqpayload.reduce(
      (total, item) => total + Number(item?.sgstamt),
      0
    );
    igstamt = reqpayload.reduce(
      (total, item) => total + Number(item?.igstamt),
      0
    );
    // shortguid =  existingObject.shortguid ? (existingObject.shortguid):( shortguid.getShortguid("SR") );
    // console.log("new/old shortguid is",shortguid)
    //// return amount calculation
    returnamount = reqpayload.reduce(
      (total, item) =>
        total + Number(item?.returnamount ? item?.returnamount : 0),
      0
    );
    let returncgstamt = reqpayload.reduce(
      (total, item) =>
        total + Number(item?.returncgstamt ? item?.returncgstamt : 0),
      0
    );
    let returnigstamt = reqpayload.reduce(
      (total, item) =>
        total + Number(item?.returnigstamt ? item?.returnigstamt : 0),
      0
    );

    let returntaxableamount = reqpayload.reduce(
      (total, item) =>
        total +
        Number(item?.returntaxableamount ? item?.returntaxableamount : 0),
      0
    );
    let returnsgstamt = reqpayload.reduce(
      (total, item) =>
        total + Number(item?.returnsgstamt ? item?.returnsgstamt : 0),
      0
    );

    existingObject = {
      ...existingObject,
      cgstamt: cgstamt ? Number(Number(cgstamt).toFixed(2)) : 0,
      igstamt: igstamt ? Number(Number(igstamt).toFixed(2)) : 0,
      sgstamt: sgstamt ? Number(Number(sgstamt).toFixed(2)) : 0,
      returnamount: returnamount ? returnamount : 0,
      taxableamount: taxableamount ? taxableamount : 0,
      // shortguid: shortguid,
      amount: amount ? Number(Number(amount).toFixed(2)) : 0,
      trdate: auth.state.userdata.workdate,
      items: rowData,
      status: "C",
      saleshortguid: saleshortguid,
      // return amt
      returncgstamt: returncgstamt
        ? Number(Number(returncgstamt).toFixed(2))
        : 0,
      returnigstamt: Number(returnigstamt.toFixed(2)),
      returntaxableamount: returntaxableamount
        ? Number(Number(returntaxableamount).toFixed(2))
        : 0,
      returnsgstamt: returnsgstamt
        ? Number(Number(returnsgstamt).toFixed(2))
        : 0,
    };

    console.log("fdss", existingObject);
    useChangedRows.updateValue(false, existingObject);
  }, [rowData]);
  useEffect(() => {
    console.log(
      "Page Status is",
      tabStore.state.tabs[pageIndex]?.pageStatus,
      tabStore.state.tabs,
      pageIndex
    );
    if (tabStore.state.tabs[pageIndex]?.pageStatus == "Success") {
      console.log("Is this Page Executed", tabStore.state.tabs);
      setRowData([]);
      setSelectedItem(null);
      setSelectedQty(" ");
      setlocation(null);

    
      
      useChangedRows.updateValue("shortguid", shortguid.getShortguid("IE"));
     
      setTimeout(
        tabStore.dispatch({
          type: "UPDATE_DATA",
          fieldType: "pageStatus",
          text: null,
        }),
        1000
      );
    }
  }, [
    tabStore.state.tabs[pageIndex]?.pageStatus,
    Object.keys(currentTabData(tabStore)?.changedRows).length,
  ]);
  useEffect(() => {
    // When Row Data Changes we Need to store that data to the tabsStore with Header Data
    var currentData = useChangedRows.getValue();
    if (!currentData?.shortguid) {
      useChangedRows.updateValue("shortguid", shortguid.getShortguid("PR"));
    }

    if (rowData?.length == 0) {
      var items = useChangedRows.getValue("items");
      console.log("item", items);
      if (items) {
        setRowData(items);
      }
    }
  }, []);

  useEffect(() => {
    // When Row Data Changes we Need to store that data to the tabsStore with Header Data
    var currentData = useChangedRows.getValue();
    if (!currentData?.shortguid) {
      useChangedRows.updateValue("shortguid", shortguid.getShortguid("PR"));
    }
    setCustomerdata(useChangedRows.getValue("customerrecno"));
    setSrNumber(useChangedRows.getValue("billno"));
    setlocation(useChangedRows.getValue("locationrecno"));
    setlocation1(useChangedRows.getValue("locationrecno"));

   

    if (rowData?.length == 0) {
      var items = useChangedRows.getValue("items");
      if (items) {
        setRowData(items);
      }
    }
  }, [showFilterModal]);

  useEffect(() => {
    // When Row Data Changes we Need to store that data to the tabsStore with Header Data
    var currentData = useChangedRows.getValue();
    if (!currentData?.shortguid) {
      useChangedRows.updateValue("shortguid", shortguid.getShortguid("PR"));
    }
    setCustomerdata1(useChangedRows.getValue("customerrecno"));
    setSrNumber(useChangedRows.getValue("billno"));
    setlocation1(useChangedRows.getValue("locationrecno"));

    console.log("loccation ff", useChangedRows.getValue("locationrecno"));

    if (rowData?.length == 0) {
      var items = useChangedRows.getValue("items");
      if (items) {
        setRowData(items);
      }
    }
  }, [model]);

  /// the pi date  validation
  const setPi = (e) => {
    console.log("setPI");
    setPino(e);
  };

  useEffect(() => {
    setPi();
  }, [pi]);

  useEffect(() => {
    if (tabStore.state.tabs[pageIndex]?.pageStatus == "Success") {
      setRowData([]);
      setigstappl(false);
      setSelectedItem(null);
      setSelectedQty(" ");
      setSaleRate(" ");
      setSrNumber("TBA");
    }
  }, [
    tabStore.state.tabs[pageIndex]?.pageStatus,
    Object.keys(currentTabData(tabStore)?.changedRows)?.length,
  ]);

  /// function for api call

  const payload = {
    ...changerow,
    tenantrecno: auth?.state?.userdata?.tenantrecno,
    domainrecno: auth?.state?.userdata?.domainrecno,
  };

  async function handleAdditem() {
    if (pi !== null && pi !== "" && pi !== 0) {
      try {
        let res = await getSalebill(payload);

        if (res.Success && res?.Message[0]?.items.length > 0) {
          setsaleShortguid(res?.Message[0]?.shortguid);
          setRowData(res?.Message[0]?.items);

          setigstappl(res?.Message[0]?.items[0]?.igstrate);
        } else {
          auth.state.contextHolder.info({
            message: `An Error has Occured`,
            description: "Bill not present",
            duration: 3,
            style: { background: "#efa7a7" },
          });
        }
      } catch (err) {
        auth.state.contextHolder.info({
          message: `An Error has Occured`,
          description: String(err),
          duration: 3,
          style: { background: "#efa7a7" },
        });
      }
    }
  }
  /////// validation  for save //////
  ////// validation for save button changes /////
  useEffect(() => {
    let isButtonDisabled = false;

    for (let i = 0; i < rowData.length; i++) {
      const {
        packingqty,
        packingextraqty,
        freepackingqty,
        freepackingextraqty,
        manufacturedate,
        expdate,
        itembatchno,
        purrate,
        expdatecompulsory,
        itemasbatch,
        manudatecompulsory,
        wantbatchno,
        changesalerateinbill,
        currentreturnqty,
        currentreturnpackingqty,
      } = rowData[i];
      console.log("ROWDATA", rowData[i]);

      if (
        (wantbatchno == 0 && currentreturnpackingqty == 0) ||
        (rowData[i]?.itemasbatch &&
          Number(rowData[i]?.currentreturnqty) +
            Number(rowData[i]?.returnfree ? rowData[i]?.returnfree : 0) !==
            rowData[i]?.itembatcharray?.length)
      ) {
        isButtonDisabled = true;
        console.log("isButtonDisabled:122", isButtonDisabled);
        break;
      }
      // else if ((wantbatchno && (!itembatchno || itembatchno == "" || itembatchno == null || itembatchno == undefined))||  (packingqty === "0" && packingextraqty === "0") || (packingqty == "" && packingextraqty == "") || (packingqty === 0 && packingextraqty === "0") || (packingqty === "0" && packingextraqty === 0) || (packingqty === 0 && packingextraqty === 0)|| Number(purrate) == 0) {
      //   console.log("Custermer891",Number(rowData[i]?.qty) +
      //   Number(rowData[i]?.free ? rowData[i]?.free : 0) ,
      //   rowData[i]?.itembatcharray?.length)
      //   isButtonDisabled = true;
      //   console.log("isButtonDisabled:122", isButtonDisabled);
      //   break;
      // }
      console.log(
        "isButtonDisabled:",
        isButtonDisabled,
        packingqty,
        packingextraqty
      );
    }

    useChangedRows.updateValue(
      "validated",
      !isButtonDisabled ? "true" : "false"
    );
  }, [rowData]);
  const addItemstoTable = () => {
    // if (!getSelectedItem.itembasbatch && !batchNo) {
    //   alert("Batch Cannot be Null");
    //   return;
    // }
    if (getSelectedItem) {
      var existingItem = rowData.filter(
        (item) => item.itemrecno == getSelectedItem.itemrecno
      );

      if (existingItem?.length == 0) {
        // handleAdd(
        //   getSelectedItem,
        //   setRowData,
        //   igstappl,
        //   setSelectedItem,
        //   shortguid,
        //   salerate,
        //   selectedQty,
        //   box,
        //   freeBox,
        //   freeQty,
        //   batchNo,
        //   expDate,
        //   discount,
        //   discountPer,
        //   manuDate
        // );
        let array=getSelectedItem?.batchdetails?.filter((item)=>item?.selectedQty>0)
        for(let i=0;i<array.length;i++){
          //console.log("tr001 Added Shortguid",array[i])
          handleAdd2(
            getSelectedItem,
            setStock,
            igstappl,
            setSelectedItem,
            shortguid,
            0,
            array[i],
            box,
            0,
            0,
            0,
            expDate,
            1,
            1,
            0
          );
        }
       
        //itemSearch?.current?.focus();
      } else {
        alert("Item Already Present");
        setSelectedItem(null);
        //itemSearch?.current?.focus();
      }
    }
  };


  console.log("update datarray", stock);
  return (
    <div style={{ ...mainFrame }}>
      <Modal
        open={displayMessages}
        onCancel={() => {
          setDisplayMessages(!displayMessages);
        }}
        onOk={() => {
          useChangedRows.updateValue(
            "message",
            document.getElementById("message").value
          );
          useChangedRows.updateValue(
            "narration",
            document.getElementById("narration").value
          );
          setDisplayMessages(!displayMessages);
        }}
      >
        <div style={{ display: "flex", flexDirection: "column" }}>
          <p>Message from Cust</p>
          <Input
            id={"message"}
            placeholder="Enter Message for Cust"
            defaultValue={useChangedRows.getValue("message")}
          ></Input>
          <br /> <p>Message for Self</p>
          <Input
            id={"narration"}
            placeholder="Enter Message for Self"
            defaultValue={useChangedRows.getValue("narration")}
          ></Input>
        </div>
      </Modal>

      {/* =============================//*Edit Transaction*\\=================================== */}

      {/* ============here======= */}
      {/* commentou asper asked by client date 20 april 2023 rbn */}
     
      {/* =============here======== */}
      {/*            model new  search invoice number   */}
      <Modal
        title="Search Invoice"
        open={model}
        onOk={() => {
          setModel(false);
          Setcounter((p) => p + 1);
        }}
        onCancel={() => {
          setModel(false);
          Setcounter((p) => p + 1);
        }}
        width={"100%"}
        style={{ top: 20 }}
        key={counter}
      >
        <Searchsalebillnumber
          fastpath="RP106"
          setshowFilterModal={setModel}
          showFilterModal={model}
          statusC={statusC}
          setstatusC={setstatusC}
          forreturn={true} /// this key is added so that it was required for payload
        />
      </Modal>

      <Collapse collapsible="header" defaultActiveKey={["1"]}>
        <Panel header="Selection Frame" key="1">
          <div>
            <div style={{ ...topRowFilters }}>
              {/* =============================//*Edit Transaction*\\=================================== */}

              <div>
                <Button
                  className="btn"
                  type="default"
                  onClick={() => {
                    setModel(true);

                    Setcounter((p) => p + 1);

                    setstatusC(true); ///////////////////////// Added for deleting data from Tabstore for tab TR015

                    tabStore.dispatch({
                      type: "UPDATE_TABS_ARRAY",

                      changedRowsValue: {},

                      tabsFastPath: "TR015",

                      keyName: "changedRows",
                    }); //////////////////////////////////
                  }}
                >
                  Search Sales Invoice
                </Button>
              </div>

              {/* Select Customer */}
              <div style={{ pointerEvents: "none" }}>
                {" "}
                <UserLocationList
                  setSupplier={setDatatoCustomerdata}
                  setlocation={setlocation1}
                  defaultSelection={location1}
                  apikey={"locationrecno"}
                />
              </div>
              <div
                style={{
                  zIndex: 4,
                  position: "relative",
                  pointerEvents: "none",
                }}
              >
                <SaveDropdown
                  defaultSelected={customerdata1}
                  returnValue={setDatatoCustomerdata}
                  apiurl={getDomaincustomerlist}
                  displayLabel={"Cust"}
                  apikey={"customerrecno"}
                  selectionLabel={"customerdescn"}
                  selectionValue={"customerrecno"}
                  alignment="column"
                />
              </div>

              <div>
                <p>SR Number</p>
                <input
                  style={{ ...poNumberStyle }}
                  placeholder={srNumber ? srNumber : "TBA"}
                  disabled
                  //defaultValue={changerow.billno}

                  //date 26 april 2023
                  defaultValue={"TBA"}
                />
              </div>
          
             
              <div>
              
                <SaveDatePicker
                  displayLabel={"SR Date"}
                  // dateLessthan={workdate}

                  apiKey={"trdate"}
                  defaultValue={auth?.state?.userdata?.workdate}
                  id={"sr-date"}
                  dateLessthan={workdateyymmdd}
                  postAlertMsg={"SR date cannot be greater than Today's Date"}
                  returnValue={(e) => {
                    return true;
                  }}
                />
              </div>




              <Button
                style={{ ...buttons }}
                type="default"
                onClick={(e) => setDisplayMessages(!displayMessages)}
              >
                Add / View Messages
              </Button>
            </div>
           
            {/* <div>
                  <p>Batch No</p>
                  <input
                    style={{ ...inputStyles,textAlign:"left" }}
                    value={batch}
                    id="tr004-input-batch"
                    onChange={handleBatchChange}
                    type="text"
                  />
                </div>
                <div>
                  <CallbackDateInput
                    id={"exp-date"}
                    displayLabel={"Expiry Date"}
                    apiKey={"expirydate"}
                    returnValue={setexpDate}
                   
                  
                  />
                </div>
                <div>
                  <p>Boxes</p>
                  <input
                    ref={boxRef}
                    style={{ ...inputStyles }}
                    value={box}
                    id="tr004-input-box"
                    onChange={handleBoxChange}
                    type="number"
                    onKeyDown={(event) => {
                      const { key } = event;
                      if (key === "Enter") {
                        event.target.value.length > 0 &&
                          piecesRef.current.focus();
                      }
                    }}
                  />
                </div>
                <div>
                  <p>Pieces</p>
                  <input
                    ref={piecesRef}
                    style={{ ...inputStyles }}
                    value={selectedQty}
                    id="tr004-input-pieces"
                    type="number"
                    onChange={handlePiecesChange}
                    onKeyDown={(event) => {
                      const { key } = event;
                      if (key === "Enter") {
                        event.target.value.length > 0 &&
                          rateRef.current.focus();
                      }
                    }}
                  />
                </div>
                <div>
                  <p>Rate</p>
                  <input
                    ref={rateRef}
                    type="number"
                    style={{ ...inputStyles }}
                    value={purchaseRate}
                    id="tr004-input-rate"
                    onChange={handleRateChange}
                    onKeyDown={(event) => {
                      const { key } = event;
                      if (key === "Enter") {
                        if (
                          event.target.value != 0 &&
                          event.target.value.length > 0
                        ) {
                          if (box == 0 && selectedQty == 0) {
                            alert("Enter non-zero values in Box / Pieces");
                            boxRef.current.focus();
                          } else {
                            btnRef.current.focus();
                          }
                        } else {
                          alert("Cannot enter 0 as rate");
                        }
                      }
                    }}
                  />
                </div>
                <div>
                  <p>Gross Amount</p>
                  <div style={{ ...grossAmtStyle }}>
                    {Number(grossAmt).toFixed(2)}
                  </div>
                </div> */}
           
      <div style={{ zIndex: 1, position: "relative", margin: "24px auto 0" }}>
        {rowData.length > 0 && (
          <ItemsTable
            rowData={rowData.filter((item) => item?.returnableqty !== 0)}
            setRowData={setRowData}
            // setDatapayload={setDatapayload}
            igstappl={igstappl > 0 ? true : false}
            datapayload={datapayload}
            // pageDetails={pageDetails}
            saleNumber={srNumber}
          />
        )}
      </div>
          </div>
        </Panel>
        <Panel>
     <div style={{zIndex:100}}>
     <ItemSearch
     // itemSearchRef={itemSearch}
      forFlag={"S"}
      returnValue={setSelectedItem}
      supplierdata={customerdata1?.customerrecno}
      dropdown
      defaultSelectedItem={getSelectedItem}
      selection
      /// task id-269 rajendra If item has isservicetrue = 1 then don't calculate stock.
      //selection /// rajendra  may 30 two selection key was there
      barcodeReturnValue={(e) => {
        // Write a function to add to existing Items and check only if it has serial number
        if (e?.itemasbatch == 1) {
          useFetchItem
            .addBarcodeScannedItemToTable(rowData, e, "SIF", igstappl, pageDetails)
            .then((res) => {
              console.log("New ROws Data", res);
              if (res?.length == 0) {
                // Rajendra the same barcode grid getting blank date 9 jun 2023
                setRowData(rowData);
                return;
              } else {
                setRowData(res);
               // setChange(change + 1);
              } // Rajendra the same barcode grid getting blank date 9 jun 2023
            })
            .catch((err) => alert(err));
        } else {
          setSelectedItem(e);
        }
      }}
    /></div>
   <div >
   <SearchItemsTable
   rowData={stock}
   setRowData={setStock}
   igstappl={igstappl}
   pageDetails={pageDetails}
   />
   </div>
        </Panel>
      </Collapse>

      
   
    </div>
  );
}

export default StockExchange;

const mainFrame = {
  width: "95vw",
  margin: "auto",
};

const topRowFilters = {
  gap: "24px",
  display: "flex",
  alignItems: "center",
};

// applying padding to align input rows inline
const callbackTextInputStyles = {
  display: "flex",
  flexWrap: "wrap",
  alignItems: "center",
  gap: "36px",
  paddingTop: "38px",
};

// add to table btn styles
const btnStyles = {
  display: "flex",
  alignItems: "center",
  gap: "8px",
  marginTop: "26px",
};

const inputStyles = {
  padding: "10px",
  border: "1px solid rgb(120,120,120)",
  width: "100px",
  fontSize: "16px",
  marginTop: "7px",
  borderRadius: "6px",
};

const grossAmtStyle = {
  textAlign: "right",
  border: "1px solid gray",
  padding: "4px 8px 4px 4px",
  fontSize: "18px",
  width: "120px",
  borderRadius: "8px",
  marginTop: "6px",
};

const poNumberStyle = {
  marginTop: "4px",
  border: "1px solid gray",
  borderRadius: "8px",
  width: "200px",
  padding: "8px",
  fontSize: "16px",
};

const buttons = {
  width: "200px",
  height: "40px",
  borderRadius: "8px",
  marginTop: "23px",
};

//~# getdomainsupplieritemlist api endpoint for Item name => pass payload as { supplierrecno : 78123 } for item name
//~# conversionfactor = boxes * conversionfactor + pieces =


import React, { useState, useEffect, useContext } from "react";
import { Popover, Modal, Popconfirm, message } from "antd";
import { FaInfoCircle } from "react-icons/fa";
import "./AddBox.css";

import "./RectangleBox.css";

import { Rnd } from "react-rnd";

import { Link } from "react-router-dom";

import axios from "axios";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Button,
  FormControlLabel,
  Checkbox,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";

import SaveDropdown from "../../CommonControllers/Components/SaveComponents/SaveDropdown";
import { AuthStore } from "../../Auth/AuthStore";
import useSaveChangedRows from "../../CommonControllers/Hooks/useSaveChangedRows";
import { addprintformatall, copyprintfromats, getprintformatfooterlist, removeprintformatfooter } from "../../CommonControllers/Urls";

function AddBox() {
  const [clicked, setClicked] = useState(false); //for clicked popover of i
  const [hovered, setHovered] = useState(false); //for hover popover of i

  const [isModalOpen, setIsModalOpen] = useState(false);

  const hide = () => {
    setClicked(false);
    setHovered(false);
  };

  //For opening info popover on hover
  const handleHoverChange = (open) => {
    setHovered(open);
    setClicked(false);
  };

  //For opening info popover on click
  const handleClickChange = (open) => {
    setHovered(false);
    setClicked(open);
  };

  //Content for Popover of info button-onhover
  // const hoverContent = (
  //   <div>
  //     Input can be in the format of
  //     <br /> DDMMYY, MMYY, DDMMYYYY, MMYYYY
  //   </div>
  // );

  useSaveChangedRows();

  const auth = useContext(AuthStore);
  const newBoxFlag = "newlyAdded";

  const [boxes, setBoxes] = useState([]);
  const [copyBoxes, setCopyBoxes] = useState([]);
  console.log("copyBoxes", copyBoxes);

  useEffect(() => {
    console.log("first");
  }, [boxes]);

  const [opendialogdeletebox, setopendialogdeletebox] = useState(false);

  const [opendialogdetails, setopendialogdetails] = useState(false);
  const [caption, setcaption] = useState("");
  const [fontsize, setfontsize] = useState("");
  const [headerfooter, setheaderfooter] = useState("");
  const [fieldname, setfieldname] = useState("");
  const [fontname, setfontname] = useState("Arial");
  const [printbox, setprintbox] = useState(false);
  const [fontitalic, setfontitalic] = useState(false);
  const [fontbold, setfontbold] = useState(false);
  const [captionbefore, setcaptionbefore] = useState(false);
  const [pageno, setpageno] = useState(1);
  const [fieldtype, setfieldtype] = useState("");
  const [x1, setx1] = useState("");
  const [boxWidth, setboxWidth] = useState("");
  const [x2, setx2] = useState("");
  const [y1, sety1] = useState("");
  const [y2, sety2] = useState("");
  const [boxid, setboxid] = useState("");
  const [rndKey, setRndKey] = useState(Date.now());
  const [sequence, setsequence] = useState("");

  const [id, setIdd] = useState("");

  const [selectedBox, setSelectedBox] = useState("");

  const [code, setcode] = useState("");
  const [codeCopyFrom, setcodeCopyFrom] = useState("");
  const [doctype, setDocType] = useState("");
  const [doctypeCopyFrom, setdoctypeCopyFrom] = useState("");
  const [doctypeCopyTo, setdoctypeCopyTo] = useState("");

  useEffect(() => {
    if (code != "") {
      getboxdetails();
    }
  }, [code]);

  async function getboxdetails() {
    const payload = {
      tenantrecno: auth?.state?.userdata?.tenantrecno,
      domainrecno: auth?.state?.userdata?.domainrecno,
      code: Number(code),
    };
 
    const url =getprintformatfooterlist;
    const resp = await axios.post(url, payload);
  
    const mappedBoxes = resp?.data?.Message?.map((item) => ({
      id: item.boxid,
      caption: item.caption,
      fontname: item.fontname,
      fontsize: item.fontsize,
      fieldtype: item.fieldtype,
      pageno: item.pageno,
      printbox: item.printbox === 1 ? true : false,
      fontitalic: item.fontitalic === 1 ? true : false,
      fontbold: item.fontbold === 1 ? true : false,
      captionbefore: item.captionbefore === 1 ? true : false,
      headerfooter: item.headerfooter,
      fieldname: item.fieldname,
      sequence: item?.sequence,
      position: {
        x1: item.fieldx,

        y1: item.fieldy,

        width: item.fieldxto,

        height: item.fieldyto,

        x2: item.fieldx + item.fieldxto,

        y2: item.fieldy + item.fieldyto,
      },
    }));

    console.log("mappedBoxes", mappedBoxes);
    setBoxes([...mappedBoxes]);
  }

  // async function getfieldtablist(){
  //   const payload = {
  //     tenantrecno:3,
  //     doctype:Number(code),
  //     // headerfooter:Number(headerfooter),
  //     headerfooter:Number(1)

  //   }
  //   console.log("payload2",payload)
  // const  url="https://scientific.sutradhar.tech/backend/api/v1/getfieldtablistforprintformatsetting/"
  // const resp = await axios.post(url, payload)
  // console.log("response from getfieldtablist api",resp?.data?.Message,"code",code)
  // }

  async function Addboxdetails() {
    //   const modifyboxes = boxes.map((item) =>{
    //     if(item?.position?.x1<0||item?.position?.y1<0){
    //     return {...item,x1:0,y1:0}
    //     }
    //     else if(item?.position?.x1>0 && item?.position?.y1>0){
    //     return {...item}
    //     }
    //   });
    // console.log("modifyboxes",modifyboxes)
    const newlyAddedBoxes = boxes.filter((item) => item[newBoxFlag]);
    const existingBoxes = boxes.filter((item) => !item.newlyAdded);
    // const newlyAddedBoxes = boxes.filter((item) => {
    //   return item?.newlyAdded === true;
    // })

 

    let boxdata = [];

    newlyAddedBoxes?.map((item) => {
      boxdata.push({
        mainrecno: 0,
        code: Number(code),
        headerfooter: Number(item.headerfooter),
        pageno: Number(item?.pageno),
        fontname: item?.fontname,
        fontsize: Number(item?.fontsize),
        fontitalic: item?.fontitalic == true ? 1 : 0,
        fontbold: item?.fontbold == true ? 1 : 0,
        fieldx: Number(item?.position?.x1?.toFixed(2)),
        fieldy: Number(item?.position?.y1?.toFixed(2)),
        fieldxto: Number(item?.position.width?.toFixed(2)),
        fieldyto: Number(item?.position.height?.toFixed(2)),
        printline: 0,
        printbox: item?.printbox == true ? 1 : 0,
        fieldname: item.fieldname,
        fieldtype: item?.fieldtype,
        caption: item?.caption,
        captionbefore: item?.captionbefore == true ? 1 : 0,
        boxid: Number(item.id),
        sequence: Number(item?.sequence),
        width: Number(item?.position.width?.toFixed(2)),
      });
    });
    existingBoxes?.map((item) => {
      boxdata.push({
        mainrecno: 0,
        code: Number(code),
        // edit:item.edit==true?true:false,
        pageno: Number(item?.pageno),
        headerfooter: Number(item.headerfooter),
        fontname: item?.fontname,
        fontsize: Number(item?.fontsize),
        fontitalic: item?.fontitalic == true ? 1 : 0,
        fontbold: item?.fontbold == true ? 1 : 0,
        fieldx: Number(item?.position?.x1?.toFixed(2)),
        fieldy: Number(item?.position?.y1?.toFixed(2)),
        fieldxto: Number(item?.position.width?.toFixed(2)),
        fieldyto: Number(item?.position.height?.toFixed(2)),
        printline: 0,
        printbox: item?.printbox == true ? 1 : 0,
        fieldname: item.fieldname,
        fieldtype: item?.fieldtype,
        caption: item?.caption,
        captionbefore: item?.captionbefore == true ? 1 : 0,
        boxid: Number(item.id),
        sequence: Number(item?.sequence),
        width: Number(item?.position.width?.toFixed(2)),
      });
    });

    var rowheight = "";

    // if (doctype === "5" || doctype === "6") {
    //   rowheight = 35;
    // } else {
    //   rowheight = 20;
    // }

    const payload = {
      tenantrecno: auth?.state?.userdata?.tenantrecno,
      domainrecno: auth?.state?.userdata?.domainrecno,
      height: 1200,
      width: 1100,
      rowheight: 20,
      data: boxdata,
    };
    console.log("payload data", payload);
    const url =addprintformatall;
    const resp = await axios.post(url, payload);
    console.log("response for addprintformatsall", resp);
    if (resp?.data) {
      alert(resp?.data?.Message);
      // getboxdetails()
    }
  }

  async function handleDelete() {
    if (id == "") {
      alert("Please Enter ID of Box");
      return;
    }
    setopendialogdeletebox(false);
    const payload = {
      tenantrecno: auth?.state?.userdata?.tenantrecno,
      domainrecno: auth?.state?.userdata?.domainrecno,
      boxid: Number(id),
      code: Number(code),
    };

    const url =removeprintformatfooter;
    const resp = await axios.post(url, payload);
    console.log("response from delete api", resp);

    if (resp?.data?.Success == true) {
      alert(resp?.data?.Message);
      setBoxes([]);
      getboxdetails();
    }
  }

  const handleBoxDrag = (index) => (e, d) => {
    console.log("ondragging", d, index);

    // destructuring data object d to get updated x and y coordinates
    const { x, y } = d;

    console.log("x111", x, "y111", y);

    const updatedBoxes = [...boxes];
    console.log("boxes in handleBoxDrag ", updatedBoxes);
    // console.log( "...updatedBoxes[index].position", ...updatedBoxes[index].position)
    console.log(
      "x2 in handleBoxDrag",
      updatedBoxes[index].position.x2,
      "x1",
      updatedBoxes[index].position.x1
    );
    updatedBoxes[index].position = {
      ...updatedBoxes[index].position,

      x1: Number(x.toFixed(2)),

      y1: Number(y.toFixed(2)),

      x2: Number(
        (
          x +
          (updatedBoxes[index].position.x2 - updatedBoxes[index].position.x1)
        ).toFixed(2)
      ),

      y2: Number(
        (
          y +
          (updatedBoxes[index].position.y2 - updatedBoxes[index].position.y1)
        ).toFixed(2)
      ),

      width: Number(
        (
          updatedBoxes[index].position.x2 - updatedBoxes[index].position.x1
        ).toFixed(2)
      ),

      height: Number(
        (
          updatedBoxes[index].position.y2 - updatedBoxes[index].position.y1
        ).toFixed(2)
      ),
    };

    console.log("updatedBoxes in handleBoxDrag", updatedBoxes);

    setBoxes(updatedBoxes);
  };

  const handleBoxResize = (index) => (d, direction, ref, delta, position) => {
    console.log("handleBoxResizecall", index);
    const { width, height } = ref.style;
    console.log("position", position, "heightonresize", height);

    const boxdetails = [...boxes];
    const Addboxdetails = boxdetails[index];

    Addboxdetails.position.x2 = Number(
      (position.x + parseFloat(width)).toFixed(2)
    );
    Addboxdetails.position.y2 = Number(
      (position.y + parseFloat(height)).toFixed(2)
    );
    Addboxdetails.position.x1 = Number(position.x.toFixed(2));
    Addboxdetails.position.y1 = Number(position.y.toFixed(2));
    Addboxdetails.position.width = parseFloat(width);
    Addboxdetails.position.height = parseFloat(height);

    console.log("Addboxdetails", Addboxdetails, "boxdetails", boxdetails);
    console.log(
      "updatedBoxes1 in handleBoxResize",
      boxdetails,
      "Width:",
      parseFloat(width),
      "Height:",
      parseFloat(height)
    );

    setBoxes(boxdetails);
  };

  const [newItem, setnewItem] = useState("");

  useEffect(() => {}, [newItem]);

  const handleAddBox = () => {
    // Creating a new Box to be added with default values
    console.log("boxes in handleAddBox", boxes);
    const idArray = boxes.map((obj) => Number(obj.id));
    console.log("idArray", idArray);
    let maxid = idArray[0];
    for (let i = 0; i < idArray.length; i++) {
      if (idArray[i] > maxid) {
        maxid = idArray[i];
      }
    }
    console.log("maxid", maxid);
    const newBox = {
      id: boxes.length > 0 ? maxid + 1 : 1,
      position: { x1: 0, y1: 0, x2: 100, y2: 100, width: 100, height: 100 },
      [newBoxFlag]: true,
    };

    setBoxes((prevBoxes) => [...prevBoxes, newBox]);
  };

  const handleSave = () => {
    console.log("handleSave call");
    console.log("fontsize1", fontsize);
    console.log("selectedBox dd", selectedBox);
    let updatedBoxes = [...boxes];
    console.log("new obj added", updatedBoxes[selectedBox]);

    console.log(
      "x2 in handleBoxDrag",
      updatedBoxes[selectedBox].position.x2,
      "x1",
      updatedBoxes[selectedBox].position.x1
    );

    let selectedBoxdetails = updatedBoxes[selectedBox];
    console.log(
      "selectedBox1 in handleSave",
      selectedBoxdetails,
      "updatedBoxes1",
      updatedBoxes,
      "selectedBox",
      selectedBox
    );
    console.log("fontsize2", fontsize);
    selectedBoxdetails.caption = caption == undefined ? "" : caption;
    selectedBoxdetails.sequence = sequence;
    selectedBoxdetails.fontsize = fontsize;
    selectedBoxdetails.fontname = fontname;
    selectedBoxdetails.printbox = printbox;
    selectedBoxdetails.pageno = pageno;
    selectedBoxdetails.fontitalic = fontitalic;
    selectedBoxdetails.fontbold = fontbold;
    selectedBoxdetails.fieldtype = fieldtype;
    selectedBoxdetails.headerfooter = headerfooter;
    selectedBoxdetails.captionbefore = captionbefore;
    selectedBoxdetails.fieldname = fieldname == undefined ? "" : fieldname;
    // selectedBox1.position.height = parseFloat(boxheight);
    console.log("updatedBoxes2 in handleSave", updatedBoxes);
    console.log(
      "selectedBoxdetails?.headerfooter",
      selectedBoxdetails?.headerfooter,
      selectedBoxdetails?.fieldtype
    );
    if (
      selectedBoxdetails?.fontsize == undefined ||
      selectedBoxdetails?.fontsize == ""
    ) {
      alert("Please Enter Fontsize");
      return;
    }
    if (
      selectedBoxdetails?.fontname == undefined ||
      selectedBoxdetails?.fontname == ""
    ) {
      alert("Please Enter Fontname");
      return;
    }
    if (
      selectedBoxdetails?.fieldtype == undefined ||
      selectedBoxdetails?.fieldtype == ""
    ) {
      alert("Please Select Fieldtype");
      return;
    }
    if (
      selectedBoxdetails?.pageno == undefined ||
      selectedBoxdetails?.pageno == ""
    ) {
      alert("Please Enter Pageno");
      return;
    }
    if (Number(selectedBoxdetails?.pageno) === 0) {
      alert("Page number cannot be Zero");
      return;
    }
    if (selectedBoxdetails?.headerfooter == undefined) {
      alert("Please Select Headerfooter");
      return;
    }

    if (selectedBoxdetails?.headerfooter === 2) {
      if (selectedBoxdetails?.sequence == undefined) {
        alert("Please Add column no");
        return;
      }
    }

    updatedBoxes[selectedBox].position = {
      ...updatedBoxes[selectedBox].position,

      x1: Number(x1),
      y1: Number(y1),
      x2: Number(x2),
      y2: Number(y2),

      // width: Number(x2) - Number(x1),

      height: Number(y2) - Number(y1),
    };

    const newKey = Date.now();
    setRndKey(newKey);

    console.log("updatedBoxes", updatedBoxes);
    setBoxes(updatedBoxes);
    console.log("handleSave12", boxes);
    setopendialogdetails(false);

    // setRndKey(Date.now());

    // Addboxdetails()

    // setBoxes([]);
    //   getboxdetails();

    // setBoxes((prevBoxes) => {
    //   let updatedBoxes = [...prevBoxes];
    //   let selectedBoxdetails = updatedBoxes[selectedBox];
    //  console.log("selectedBox1 in handleSave",selectedBoxdetails,"updatedBoxes1",updatedBoxes,"selectedBox",selectedBox)
    //  console.log("fontsize2",fontsize)
    //  selectedBoxdetails.caption = caption;
    //  selectedBoxdetails.fontsize = fontsize;
    //  selectedBoxdetails.fontname = fontname;
    //  selectedBoxdetails.printbox = printbox;
    //  selectedBoxdetails.pageno = pageno;
    //  selectedBoxdetails.fontitalic = fontitalic;
    //  selectedBoxdetails.fontbold = fontbold;
    //  selectedBoxdetails.fieldtype = fieldtype;
    //  selectedBoxdetails.headerfooter = headerfooter;
    //   // selectedBox1.position.height = parseFloat(boxheight);
    //   console.log("updatedBoxes2 in handleSave",updatedBoxes)
    //   if(selectedBoxdetails.fontsize==undefined){
    //     alert("please enter fontsize")
    //     return
    //    }
    //  if(fieldtype==""){
    //   alert("please enter fieldtype")
    //   return
    //  }
    //  if(pageno==""){
    //   alert("please enter pageno")
    //   return
    //  }
    //  if(headerfooter=""){
    //   alert("please enter headerfooter")
    //   return
    //  }
    //   return updatedBoxes;
    // });
  };
  // const handleSave = () => {
  //   setBoxes((p) => {
  //     console.log("previous value",p)
  //     p[selectedBox].caption = caption;
  //     p[selectedBox].fontsize = fontsize;
  //     p[selectedBox].fontname = fontname
  //     p[selectedBox].printbox = printbox;
  //     p[selectedBox].pageno = pageno;
  //     p[selectedBox].fontitalic = fontitalic;
  //     p[selectedBox].fontbold = fontbold;
  //     p[selectedBox].fieldtype = fieldtype;
  //     return p;
  //   });
  //   setopendialogdetails(false);
  // };
  console.log("Boxes", boxes);
  /// for delete boxes
  // const handleDeleteBox = () => {
  //   console.log("handleDeleteBox call")
  //   console.log("id of deletebox",id)
  //   const newBoxes = [...boxes];
  //   const Newarray = newBoxes.filter((item) => item.id!==Number(id));
  //   console.log("boxes after delete",Newarray)
  //   setBoxes(Newarray);
  //   setopendialogdeletebox(false)
  // };

  const handleFieldSelect = (e) => {
    setcaption(e?.title);
    setfieldname(e.descn);
    setboxWidth(e?.width);
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setdoctypeCopyFrom("");
    setdoctypeCopyTo("");
    setIsModalOpen(false);
  };

  const handleCopyFrom = async () => {
    try {
      let payload = {
        copyformatfrom: doctypeCopyFrom,
        copyformatto: doctypeCopyTo,
        tenantrecno: auth?.state?.userdata?.tenantrecno,
        domainrecno: auth?.state?.userdata?.domainrecno,
      };

      console.log("payload", payload);

      let apiurl = copyprintfromats;

      let res = await axios.post(apiurl, payload);

      console.log("handleCopyFrom",res);

      if (res.Success) {
        console.log("first");
      }
    } catch (e) {
      console.log("error");
    }
  };

  const confirm = (e) => {
    handleCopyFrom()
    message.success('Format Copied Successfully');
    handleCancel()

  };
  const cancel = (e) => {
    console.log(e);
    message.error('Cancel');
  };

  return (
    <div className="App">
      <div style={{ marginLeft: "1050px" }}>
        <SaveDropdown
          displayLabel={"Print Format"}
          placeholder={"Select Document Type"}
          apiurl={"getprintforlistactive/"}
          selectionLabel={"descn"}
          // selectionValue={"descn"}
          returnValue={(e) => {
            console.log("documentcode", e);
            if (e) {
              setBoxes([]);
              setcode(e.code);
              setDocType(e?.packingbox);
            }
          }}
        />
      </div>
      {boxes.map((item, index) => {
        // Rnd - a draggable and resizable component provided by react-rnd library
        console.log("newitem details", item);
        // console.log("x2 cordinates",((item.position.x1)+(item.position.width)),"y2",((item.position.y1)+(item.position.height))),
        return (
          <Rnd
            // style={{marginLeft:item.position.x1,marginTop:item.position.y1}}
            //  key={index.toString()}
            key={`${item?.id}-${rndKey}`}
            default={{
              x: item.position.x1,

              y: item.position.y1,

              width: item.position.width,

              height: item.position.height,

              x2: item.position.x1 + item.position.width,

              y2: item.position.y1 + item.position.height,
            }}
            // called when user stops dragging the box
            onDragStop={handleBoxDrag(index)}
            // called when user stops resizing the box
            onResize={handleBoxResize(index)}
          >
            <div
              style={{ position: "relative" }}
              className="rectangle-box"
              onDoubleClick={() => {
                console.log("position of boxes", boxes[index], "item", item);
                console.log("fieldname", boxes[index]?.fieldname);
                setx1(boxes[index]?.position?.x1);
                setx2(boxes[index]?.position?.x2);
                sety1(boxes[index]?.position?.y1);
                sety2(boxes[index]?.position?.y2);
                setboxid(item?.id);
                setfontitalic(item.fontitalic);
                setfontsize(item.fontsize);
                setheaderfooter(item.headerfooter);
                setfieldname(boxes[index]?.fieldname);
                setprintbox(item.printbox);
                setfontbold(item.fontbold);
                setcaptionbefore(item.captionbefore);
                setfieldtype(item.fieldtype);
                setpageno(item.pageno);
                // item?.newlyAdded?setcaption(caption):setcaption(item.caption)
                setcaption(item.caption);
                setsequence(item.sequence);
                setfontname(item.fontname);
                setSelectedBox(index);
                setopendialogdetails(true);
              }}
            >
              <p>{item?.caption}</p>
              <div>ID:{item?.id}</div>
              <div>x1: {item.position.x1}</div>
              <div>y1: {item.position.y1}</div>
              <div>x2: {item.position.x2}</div>
              <div>y2: {item.position.y2}</div>

          
              {/* <Popover
                content={
                  <div>
                    <a onClick={hide}>
                      <div>x1:{item.position.x1}</div>
                      <div>y1:{item.position.y1}</div>
                      <div>x2:{item.position.x2}</div>
                      <div>y2:{item.position.y2}</div>
                    </a>
                  </div>
                }
                trigger="click"
                open={clicked}
                onOpenChange={handleClickChange}
              >
                <button
                  style={{
                    border: "none",
                    background: "none",
                    position: "absolute",
                    top: 0,
                    right: 0,
                  }}
                >
                  <FaInfoCircle />
                </button>
              </Popover> */}
              {/* </Popover> */}
            </div>
          </Rnd>
        );
      })}

      <div>
     
        <Modal
          title="Copy print Format"
          open={isModalOpen}
          footer={false}
          onOk={handleCancel}
          onCancel={handleCancel}
        >
          <div style={{}}>
            <label>Copy From</label>
            <SaveDropdown
            
              defaultSelected={doctypeCopyFrom}
              apiurl={"getexistingformatlist/"}
              selectionLabel={"descn"}
              selectionValue={"code"}
              returnValue={(e) => {
                console.log("documentcode", e);
                if (e) {
                  setdoctypeCopyFrom(e?.code);
                }
              }}
            />
          </div>
          <div style={{marginTop:"10px"}}>
          <label>Copy To</label>
            <SaveDropdown
             
              apiurl={"getprintforlistactive/"}
              selectionLabel={"descn"}
              // selectionValue={"descn"}
              returnValue={(e) => {
                console.log("documentcode", e);
                if (e) {
                  setdoctypeCopyTo(e?.code);
                }
              }}
            />
          </div>

          <Popconfirm
            title="Copy Print Format"
            description="Are you sure to copy this format?"
            onConfirm={confirm}
            onCancel={cancel}
            okText="Yes"
            cancelText="No"
          >
            <Button variant="contained" style={{marginTop:"10px"}}>Copy</Button>
          </Popconfirm>
        </Modal>
      </div>

      <div style={{ marginLeft: "1050px", marginTop: "20px" }}>
        <button onClick={handleAddBox}>Add Box</button>
        <button
          onClick={() => {
            setopendialogdeletebox(true);
          }}
        >
          Delete Box
        </button>
        <button onClick={Addboxdetails}>Save Record</button>
      </div>
      <Button type="primary" variant="contained" style={{marginLeft: "1050px",marginTop:"10px"}} onClick={showModal}>
          Copy Print Format
        </Button>

      <Dialog
        open={opendialogdeletebox}
        onClose={() => {
          setopendialogdeletebox(false);
        }}
      >
        <DialogTitle>Enter ID of Box </DialogTitle>
        <DialogContent>
          <div style={{ marginTop: "10px" }}>
            <TextField
              label="ID"
              // value={id}
              onChange={(e) => {
                setIdd(e.target.value);
              }}
            />
          </div>
        </DialogContent>

        <Button variant="contained" color="primary" onClick={handleDelete}>
          {/* <Button variant="contained" color="primary" onClick={handleDeleteBox}> */}
          Delete
        </Button>
        <div style={{ marginTop: "12px" }}></div>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            setopendialogdeletebox(false);
          }}
        >
          Cancel
        </Button>
      </Dialog>

      <Dialog
        open={opendialogdetails}
        onClose={() => {
          setopendialogdetails(false);
        }}
        // maxWidth="10px"
        // fullWidth={true}
        // style={{ maxWidth: "3000px", width: "1000px" }}
      >
        <DialogTitle>Enter Caption and its properties ID : {boxid}</DialogTitle>

        <DialogContent>
          <div style={{ zIndex: 99 }}>
            <FormControl>
              <label style={{}}>Select Fieldname</label>
            </FormControl>

            <SaveDropdown
              style={{ zIndex: 99 }}
              displayLabel={""}
              // defaultSelected={fieldname}
              placeholder={"Select Fieldname"}
              apiurl={"getfieldtablistforprintformatsetting/"}
              body={{ doctype: doctype }}
              width={"210px"}
              selectionLabel={"descn"}
              returnValue={(e) => {
                console.log("options for headerfooter", e);
                if (e) {
                  setfieldname(e.descn);
                  setcaption(e.title);
                }
              }}
            />
          </div>
          <div style={{ marginTop: "12px",zIndex: 1 }}>
            <TextField
              size="small"
              sx={{ padding: "0px", fontsize: "4px", zIndex: 0 }}
              autoFocus
              label="x1"
              value={x1}
              onChange={(e) => {
                setx1(e.target.value);
              }}
              margin="2px"
            />
          </div>
          <div style={{ marginTop: "12px",zIndex: 2 }}>
            <TextField
              size="small"
              sx={{ padding: "0px", fontsize: "4px", zIndex: 0 }}
              autoFocus
              label="y1"
              value={y1}
              onChange={(e) => {
                sety1(e.target.value);
              }}
              margin="2px"
            />
          </div>
          <div style={{ marginTop: "12px" }}>
            <TextField
              size="small"
              sx={{ padding: "0px", fontsize: "4px", zIndex: 0 }}
              autoFocus
              label="x2"
              value={x2}
              onChange={(e) => {
                setx2(e.target.value);
              }}
              margin="2px"
            />
          </div>
          <div style={{ marginTop: "12px" }}>
            <TextField
              size="small"
              sx={{ padding: "0px", fontsize: "4px", zIndex: 0 }}
              autoFocus
              label="y2"
              value={y2}
              onChange={(e) => {
                sety2(e.target.value);
              }}
              margin="2px"
            />
          </div>
          <div style={{ marginTop: "12px" }}>
            <TextField
              size="small"
              sx={{ padding: "0px", fontsize: "4px", zIndex: 0 }}
              label="Caption"
              value={caption}
              onChange={(e) => {
                setcaption(e.target.value);
              }}
              margin="2px"
            />
          </div>
          <div style={{ marginTop: "12px" }}>
            <TextField
              size="small"
              sx={{ padding: "0px", fontsize: "4px", zIndex: 0 }}
              autoFocus
              label="Fontsize"
              value={fontsize}
              onChange={(e) => {
                setfontsize(e.target.value);
              }}
              margin="2px"
            />
          </div>
          <div style={{ marginTop: "12px" }}>
            <TextField
              size="small"
              sx={{ padding: "0px", fontsize: "4px" }}
              autoFocus
              label="Fontname"
              value={fontname}
              onChange={(e) => {
                setfontname(e.target.value);
              }}
              margin="2px"
            />
          </div>
          {/* <div style={{marginTop:"12px"}}>
        <TextField
           
            label="Fieldtype"
            value={fieldtype}
            onChange={(e)=>{setfieldtype(e.target.value)}}
            margin="5px"
          />
            </div> */}
          <div style={{ marginTop: "12px" }}>
            <FormControl>
              <InputLabel>Select Fieldtype</InputLabel>
              <Select
                size="small"
                sx={{ padding: "0px", fontsize: "4px" }}
                value={fieldtype}
                label="Select Fieldtype"
                onChange={(e) => {
                  console.log("eeee", e);
                  setfieldtype(e.target.value);
                }}
                style={{ width: "210px" }}
              >
                <MenuItem>Select Fieldtype</MenuItem>
                <MenuItem value="string">string</MenuItem>
                <MenuItem value="text">text</MenuItem>
                <MenuItem value="image">image</MenuItem>
                <MenuItem value="date">date</MenuItem>
                <MenuItem value="amount">amount</MenuItem>
                <MenuItem value="number">number</MenuItem>
                <MenuItem value="qrcode">qrcode</MenuItem>
                <MenuItem value="decimal">decimal</MenuItem>
                <MenuItem value="currentdatetime">currentdatetime</MenuItem>
              </Select>
            </FormControl>
          </div>

          <div style={{ marginTop: "12px" }}>
            <TextField
              size="small"
              sx={{ padding: "0px", fontsize: "4px" }}
              label="Pageno"
              value={pageno}
              onChange={(e) => {
                setpageno(e.target.value);
              }}
              margin="5px"
            />
          </div>
          <div style={{ marginTop: "12px" }}>
            <FormControl>
              <InputLabel>Select Headerfooter</InputLabel>
              <Select
                size="small"
                sx={{ padding: "0px", fontsize: "4px" }}
                value={headerfooter}
                label="Select Headerfooter"
                onChange={(e) => {
                  console.log("eeee", e);
                  setheaderfooter(e.target.value);
                }}
                style={{ width: "210px" }}
              >
                <MenuItem>Select Headerfooter</MenuItem>
                <MenuItem value={0}>0</MenuItem>
                <MenuItem value={1}>1</MenuItem>
                <MenuItem value={2}>2</MenuItem>
                <MenuItem value={3}>3</MenuItem>
              </Select>
            </FormControl>
          </div>

          {headerfooter === 2 && (
            <div style={{ marginTop: "12px" }}>
              <TextField
                size="small"
                sx={{ padding: "0px", fontsize: "4px" }}
                label="Table Column No"
                value={sequence}
                onChange={(e) => {
                  setsequence(e.target.value);
                }}
                margin="2px"
              />
            </div>
          )}

          <FormControlLabel
            control={
              <Checkbox
                checked={printbox}
                onChange={(e) => {
                  setprintbox(e.target.checked);
                }}
              />
            }
            label="Printbox"
            margin="5px"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={fontbold}
                onChange={(e) => {
                  setfontbold(e.target.checked);
                }}
              />
            }
            label="Fontbold"
            margin="5px"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={fontitalic}
                onChange={(e) => {
                  setfontitalic(e.target.checked);
                }}
              />
            }
            label="Fontitalic"
            margin="5px"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={captionbefore}
                onChange={(e) => {
                  setcaptionbefore(e.target.checked);
                }}
              />
            }
            label="captionbefore"
            margin="5px"
          />
        </DialogContent>

        <Button variant="contained" color="primary" onClick={handleSave}>
          Save
        </Button>
        <div style={{ marginTop: "12px" }}></div>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            setopendialogdetails(false);
          }}
        >
          Cancel
        </Button>
      </Dialog>
    </div>
  );
}

export default AddBox;

import { Spin, Table } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { TabStore } from "../../TabsStructure/TabsStore";
import { piColumns } from "./GdnFunction";

const GdnItemsTable = ({
  rowData,
  setRowData,
  igstappl,
  // scandata,
  // setScandata,
  // setOpenItem,
  // purchaseInvDate,
  useChangedRows,
}) => {
  const [showTable, setShowTable] = useState(true);
  const tabStore = useContext(TabStore);

  useEffect(() => {
    console.log(`tr001 items table re-rendered ?? after deleting`, tabStore);
    setShowTable(false);

    setTimeout(() => {
      setShowTable(true);
    }, 100);
  }, [
    rowData?.length,
    tabStore.state.tabs[tabStore.state.tabIndex]?.editIndex,
  ]);

  return (
    <>
      <div>
        {showTable ? (
          <Table
            scroll={{
              y: 500,
              x: 500,
            }}
            size="large"
            className="tr001-purchaseOrder-itemsTable"
            bordered
            pagination={false}
            columns={piColumns(
              igstappl,
              setRowData,
              rowData,
              // scandata,
              // setScandata,
              // setOpenItem,
              // purchaseInvDate
            )}
            dataSource={rowData}
            summary={(pageData) => {
              let packingextraqty = 0;
              let packingqty = 0;
              let freepackingextraqty = 0.0;
              let qty = 0;
              let freepackingqty = 0.0;
              let free = 0.0;
              let purrate = 0.0;
              let cgstrate = "";
              let sgstrate = 0;
              let igstrate = "";
              let amount = "";

              var existingObject = useChangedRows.getValue();
              console.log("Existing Object is", existingObject);
              pageData.forEach((item) => {
                packingextraqty =
                  Number(packingextraqty) + Number(item?.packingextraqty);
                packingqty = Number(packingqty) + Number(item?.packingqty);
                freepackingextraqty =
                  Number(freepackingextraqty) +
                  Number(item?.freepackingextraqty);
                qty = Number(qty) + Number(item?.qty);
                freepackingqty =
                  Number(freepackingqty) + Number(item?.freepackingqty);
                free = Number(free) + Number(item?.free);
                purrate = Number(purrate) + Number(item?.purrate);
                cgstrate = String(cgstrate) + String(item?.cgstrate);
                sgstrate = Number(sgstrate) + Number(item?.sgstrate);
                igstrate = String(igstrate) + String(item?.igstrate);
                amount = String(amount) + String(item?.amount);
              });

              return (
                <Table.Summary>
                  {!igstappl ? (
                    <Table.Summary.Row>
                      <Table.Summary.Cell index={1}>{null}</Table.Summary.Cell>
                      <Table.Summary.Cell>Total : </Table.Summary.Cell>
                      <Table.Summary.Cell>
                        <p style={{ ...itemTablePStyles }}>{packingextraqty}</p>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell>
                        <p style={{ ...itemTablePStyles }}></p>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell>
                        <p style={{ ...itemTablePStyles }}>
                          {Number(freepackingextraqty).toFixed(2)}
                        </p>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell>
                        <p style={{ ...itemTablePStyles }}>{qty}</p>
                      </Table.Summary.Cell>

                      <Table.Summary.Cell>
                        <p style={{ ...itemTablePStyles }}>{Number(freepackingqty).toFixed(2)}</p>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell>
                        <p style={{ ...itemTablePStyles }}>{Number(free).toFixed(2)}</p>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell>
                        <p style={{ ...itemTablePStyles }}>{Number(purrate).toFixed(2)}</p>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell></Table.Summary.Cell>
                      <Table.Summary.Cell></Table.Summary.Cell>
                      <Table.Summary.Cell></Table.Summary.Cell>
                      <Table.Summary.Cell></Table.Summary.Cell>
                    </Table.Summary.Row>
                  ) : (
                    <Table.Summary.Row>
                      <Table.Summary.Cell index={1}>{null}</Table.Summary.Cell>
                      <Table.Summary.Cell>Total : </Table.Summary.Cell>
                      <Table.Summary.Cell>
                        <p style={itemTablePStyles}>{packingextraqty}</p>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell>
                        <p style={itemTablePStyles}>{packingqty}</p>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell>
                        <p style={itemTablePStyles}>{freepackingextraqty}</p>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell>
                        <p style={itemTablePStyles}>{qty}</p>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell>
                        <p style={itemTablePStyles}>{freepackingqty}</p>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell>
                        <p style={itemTablePStyles}>{free}</p>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell>
                        <p style={itemTablePStyles}>{purrate}</p>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell>
                        <p style={itemTablePStyles}>{cgstrate}</p>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell>
                        <p style={itemTablePStyles}>{sgstrate}</p>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell>
                        <p style={itemTablePStyles}>{igstrate}</p>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell>
                        <p style={itemTablePStyles}>{amount}</p>
                      </Table.Summary.Cell>
                    </Table.Summary.Row>
                  )}
                </Table.Summary>
              );
            }}
          />
        ) : (
          <div style={{ ...loaderStyle }}>
            <Spin size="large" />
          </div>
        )}
      </div>
    </>
  );
};

export default GdnItemsTable;

const loaderStyle = {
  height: "max-content",
  width: "max-content",
  display: "flex",
  alignContent: "center",
  margin: "50px auto",
};

const itemTablePStyles = {
  textAlign: "right",
};

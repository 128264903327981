import { useState, useEffect, useContext, useRef } from "react";
import { useTransactionContext } from "./RcTransactionContext";
import { useTransactionDispatch } from "./RcTransactionContext";

import { TabStore } from "../../../TabsStructure/TabsStore";

function PendingInsutrumentSummary(props) {
  const transaction_state = useTransactionContext();
  const transaction_dispatch = useTransactionDispatch();
  const [tempGLTotal, setTempGLTotal] = useState(0);

  const tabstore = useContext(TabStore);

  useEffect(() => {
    let _total = 0;
    console.log("PendingInsutrumentSummary");

    transaction_state?.gl_list.forEach((element) => {
      _total = Number(_total) + Number(element.amount);
    });
    setTempGLTotal(_total);
  });

  return (
    <div>
      <table border="1">
        <tr>
          <td>Ref No</td>
          <td>Balance Amount</td>
          <td>Paid Amount</td>
          <td>Discount Amount</td>
          <td>Remaining Amount</td>
        </tr>
        <tr>
          <td>
            {
              tabstore?.state?.tabs[tabstore?.state.tabIndex]?.changedRows
                ?.selected_ref_id_to_settle
            }
          </td>
          <td align="center">
            {
              tabstore?.state?.tabs[tabstore?.state.tabIndex]?.changedRows
                ?.selected_ref_instrument?.pendingamt
            }
          </td>
          <td align="center">
            {" "}
            {
              tabstore?.state?.tabs[tabstore?.state.tabIndex]?.changedRows
                ?.selected_ref_instrument?.on_account_amount
            }
          </td>

          <td align="center">
            {
              tabstore?.state?.tabs[tabstore?.state.tabIndex]?.changedRows
                ?.selected_ref_instrument?.othergl_total
            }
          </td>
          <td align="center">
            {tabstore?.state?.tabs[tabstore?.state.tabIndex]?.changedRows
              ?.selected_ref_instrument?.pendingamt -
              (Number(
                tabstore?.state?.tabs[tabstore?.state.tabIndex]?.changedRows
                  ?.selected_ref_instrument?.on_account_amount
              ) +
                Number(tempGLTotal))}
          </td>
        </tr>
      </table>
    </div>
  );
}

export default PendingInsutrumentSummary;

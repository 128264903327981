import { Button, Popconfirm } from "antd";
import { AiFillDelete } from "react-icons/ai";
import { removeByAttribute } from "../../CommonControllers/AppFunction";

export const piColumns = (igstappl, setRowData, rowData) => {
  console.log("rowData1", rowData);
  if (igstappl) {
    return [
      // Delete
      {
        title: " ",
        dataIndex: "active",
        key: "active",
        width: 80,
        render: (_, text) => {
          return (
            <Popconfirm
              title="Do you want to Cancel the changes?"
              onConfirm={(e) => {
                handleRemove(text, setRowData, rowData);
              }}
            >
              <Button icon={<AiFillDelete />}></Button>
            </Popconfirm>
          );
        },
      },

      // Item info
      {
        width: 350,
        title: "Item Description",
        dataIndex: "itemdescn",
        key: "itemdescn",
      },

      // No. of packages
      {
        title: "No. of packages",
        width: 100,
        dataIndex: "packingextraqty",
        key: "packingextraqty",
        render: (_, text) => {
          return (
            <input
              className="tr001Input"
              type={"number"}
              defaultValue={text.packingextraqty}
              onFocus={(e) => e.target.select()}
              onChange={(e) => {
                const regex = /^[0-9\b]+$/;
                if (e.target.value === "" || regex.test(e.target.value)) {
                  text.packingextraqty = e.target.value;
                  handleChange(text, setRowData, igstappl, rowData);
                } else {
                  alert("Invalid Input");
                  e.target.value = "";
                  text.packingextraqty = e.target.value;
                  handleChange(text, setRowData, igstappl, rowData);
                }
              }}
            ></input>
          );
        },
      },

      // Dimensions
      {
        title: "Dimensions",
        width: 115,
        dataIndex: "packingqty",
        key: "packingqty",
        render: (_, text) => {
          return (
            <input
              className="tr001Input"
              type={"number"}
              defaultValue={text.packingqty}
              onFocus={(e) => e.target.select()}
              // {amol-373 change in onChange event}
              onChange={(e) => {
                const regex = /^[0-9\b]+$/;
                if (e.target.value === "" || regex.test(e.target.value)) {
                  text.packingqty = e.target.value;
                  handleChange(text, setRowData, igstappl, rowData);
                } else {
                  alert("Invalid Input");
                  e.target.value = "";
                  text.packingqty = e.target.value;
                  handleChange(text, setRowData, igstappl, rowData);
                }
              }}
            ></input>
          );
        },
      },

      // Area
      {
        title: "Area",
        width: 100,
        dataIndex: "freepackingextraqty",
        key: "freepackingextraqty",
        render: (_, text) => {
          return (
            <input
              className="tr001Input"
              type={"number"}
              defaultValue={text.freepackingextraqty}
              onFocus={(e) => e.target.select()}
              // {amol-373 change in onChange event}
              onChange={(e) => {
                // {amol-373 regex for decimal}
                const regex = /^\d+(\.\d+)?$/;
                if (e.target.value === "" || regex.test(e.target.value)) {
                  text.freepackingextraqty = e.target.value;
                  handleChange(text, setRowData, igstappl, rowData);
                } else {
                  alert("Invalid Input");
                  e.target.value = "";
                  text.freepackingextraqty = e.target.value;
                  handleChange(text, setRowData, igstappl, rowData);
                }
              }}
              // onChange={(e) => {
              //   text.freepackingextraqty = e.target.value;
              //   handleChange(text, setRowData, igstappl, rowData);
              // }}
            ></input>
          );
        },
      },

      // Quantity
      {
        title: "Quantity",
        width: 100,
        dataIndex: "qty",
        key: "qty",
        render: (_, text) => {
          return (
            <input
              className="tr001Input"
              type={"number"}
              defaultValue={text.qty}
              onFocus={(e) => e.target.select()}
              // {amol-373 change in onChange event}
              onChange={(e) => {
                const regex = /^[0-9\b]+$/;
                if (e.target.value === "" || regex.test(e.target.value)) {
                  text.qty = e.target.value;
                  handleChange(text, setRowData, igstappl, rowData);
                } else {
                  alert("Invalid Input");
                  e.target.value = "";
                  text.qty = e.target.value;
                  handleChange(text, setRowData, igstappl, rowData);
                }
              }}
              // onChange={(e) => {
              //   text.qty = e.target.value;
              //   handleChange(text, setRowData, igstappl, rowData);
              // }}
            ></input>
          );
        },
      },

      // Volume
      {
        title: "Volume",
        width: 100,
        dataIndex: "freepackingqty",
        key: "freepackingqty",
        render: (_, text) => {
          return (
            <input
              className="tr001Input"
              type={"number"}
              defaultValue={text.freepackingqty}
              onFocus={(e) => e.target.select()}
              // {amol-373 change in onChange event}
              onChange={(e) => {
                // {amol-373 regex for decimal}
                const regex = /^\d+(\.\d+)?$/;
                if (e.target.value === "" || regex.test(e.target.value)) {
                  text.freepackingqty = e.target.value;
                  handleChange(text, setRowData, igstappl, rowData);
                } else {
                  alert("Invalid Input");
                  e.target.value = "";
                  text.freepackingqty = e.target.value;
                  handleChange(text, setRowData, igstappl, rowData);
                }
              }}
              // onChange={(e) => {
              //   text.freepackingqty = e.target.value;
              //   handleChange(text, setRowData, igstappl, rowData);
              // }}
            ></input>
          );
        },
      },

      //  Net Weight
      {
        title: "Net Weight",
        width: 100,
        dataIndex: "free",
        key: "free",
        render: (_, text) => {
          return (
            <input
              className="tr001Input"
              type={"number"}
              defaultValue={text.free}
              onFocus={(e) => e.target.select()}
              // {amol-373 change in onChange event}
              onChange={(e) => {
                // {amol-373 regex for decimal}
                const regex = /^\d+(\.\d+)?$/;
                if (e.target.value === "" || regex.test(e.target.value)) {
                  text.free = e.target.value;
                  handleChange(text, setRowData, igstappl, rowData);
                } else {
                  alert("Invalid Input");
                  e.target.value = "";
                  text.free = e.target.value;
                  handleChange(text, setRowData, igstappl, rowData);
                }
              }}
              // onChange={(e) => {
              //   text.free = e.target.value;
              //   handleChange(text, setRowData, igstappl, rowData);
              // }}
            ></input>
          );
        },
      },

      // Gross Weight
      {
        title: "Gross Weight",
        width: 100,
        dataIndex: "purrate",
        key: "purrate",
        render: (_, text) => {
          return (
            <input
              className="tr001Input"
              type={"number"}
              defaultValue={text.purrate}
              onFocus={(e) => e.target.select()}
              // {amol-373 change in onChange event}
              onChange={(e) => {
                // {amol-373 regex for decimal}
                const regex = /^\d+(\.\d+)?$/;
                if (e.target.value === "" || regex.test(e.target.value)) {
                  text.purrate = e.target.value;
                  handleChange(text, setRowData, igstappl, rowData);
                } else {
                  alert("Invalid Input");
                  e.target.value = "";
                  text.purrate = e.target.value;
                  handleChange(text, setRowData, igstappl, rowData);
                }
              }}
              // onChange={(e) => {
              //   text.purrate = e.target.value;
              //   handleChange(text, setRowData, igstappl, rowData);
              // }}
            ></input>
          );
        },
      },

      // Test Certificate No.
      {
        title: "Test Certificate No.",
        width: 105,
        dataIndex: "cgstrate",
        key: "cgstrate",
        render: (_, text) => {
          return (
            <input
              className="tr001Input"
              // {amol-373 changed type to text}
              type={"text"}
              defaultValue={text.cgstrate}
              onFocus={(e) => e.target.select()}
              onChange={(e) => {
                text.cgstrate = e.target.value;
                handleChange(text, setRowData, igstappl, rowData);
              }}
            ></input>
          );
        },
      },

      // Serial No.
      {
        title: "Serial No.",
        width: 100,
        dataIndex: "sgstrate",
        key: "sgstrate",
        render: (_, text) => {
          return (
            <input
              className="tr001Input"
              type={"number"}
              defaultValue={text.sgstrate}
              onFocus={(e) => e.target.select()}
              // {amol-373 change in onChange event}
              onChange={(e) => {
                // {amol-373 regex for decimal}
                const regex = /^\d+(\.\d+)?$/;
                if (e.target.value === "" || regex.test(e.target.value)) {
                  text.sgstrate = e.target.value;
                  handleChange(text, setRowData, igstappl, rowData);
                } else {
                  alert("Invalid Input");
                  e.target.value = "";
                  text.sgstrate = e.target.value;
                  handleChange(text, setRowData, igstappl, rowData);
                }
              }}
              // onChange={(e) => {
              //   text.sgstrate = e.target.value;
              //   handleChange(text, setRowData, igstappl, rowData);
              // }}
            ></input>
          );
        },
      },

      // Nature of packing
      {
        title: "Nature of packing",
        width: 200,
        dataIndex: "igstrate",
        key: "igstrate",
        render: (_, text) => {
          return (
            <input
              className="tr001Input"
              // {amol-373 changed type to text}
              type={"text"}
              defaultValue={text.igstrate}
              onFocus={(e) => e.target.select()}
              onChange={(e) => {
                text.igstrate = e.target.value;
                handleChange(text, setRowData, igstappl, rowData);
              }}
            ></input>
          );
        },
      },

      // Remarks
      {
        title: "Remarks",
        width: 200,
        dataIndex: "amount",
        key: "amount",
        render: (_, text) => {
          return (
            <input
              className="tr001Input"
              // {amol-373 changed type to text}
              type={"text"}
              defaultValue={text.amount}
              onFocus={(e) => e.target.select()}
              onChange={(e) => {
                text.amount = e.target.value;
                handleChange(text, setRowData, igstappl, rowData);
              }}
            ></input>
          );
        },
      },
    ];
  } else {
    return [
      // Delete
      {
        title: " ",
        dataIndex: "active",
        key: "active",
        width: 80,
        render: (_, text) => {
          return (
            <Popconfirm
              title="Do you want to Cancel the changes?"
              onConfirm={(e) => {
                handleRemove(text, setRowData, rowData);
              }}
            >
              <Button icon={<AiFillDelete />}></Button>
            </Popconfirm>
          );
        },
      },

      // Item info
      {
        width: 350,
        title: "Item Description",
        dataIndex: "itemdescn",
        key: "itemdescn",
      },

      // No. of packages
      {
        title: "No. of packages",
        width: 100,
        dataIndex: "packingextraqty",
        key: "packingextraqty",
        render: (_, text) => {
          return (
            <input
              className="tr001Input"
              type={"number"}
              defaultValue={text.packingextraqty}
              onFocus={(e) => e.target.select()}
              onChange={(e) => {
                const regex = /^[0-9\b]+$/;
                if (e.target.value === "" || regex.test(e.target.value)) {
                  text.packingextraqty = e.target.value;
                  handleChange(text, setRowData, igstappl, rowData);
                } else {
                  alert("Invalid Input");
                  e.target.value = "";
                  text.packingextraqty = e.target.value;
                  handleChange(text, setRowData, igstappl, rowData);
                }
              }}
            ></input>
          );
        },
      },

      // Dimensions
      {
        title: "Dimensions",
        width: 115,
        dataIndex: "packingqty",
        key: "packingqty",
        render: (_, text) => {
          return (
            <input
              className="tr001Input"
              type={"number"}
              defaultValue={text.packingqty}
              onFocus={(e) => e.target.select()}
              // {amol-373 change in onChange event}
              onChange={(e) => {
                const regex = /^[0-9\b]+$/;
                if (e.target.value === "" || regex.test(e.target.value)) {
                  text.packingqty = e.target.value;
                  handleChange(text, setRowData, igstappl, rowData);
                } else {
                  alert("Invalid Input");
                  e.target.value = "";
                  text.packingqty = e.target.value;
                  handleChange(text, setRowData, igstappl, rowData);
                }
              }}
            ></input>
          );
        },
      },

      // Area
      {
        title: "Area",
        width: 100,
        dataIndex: "freepackingextraqty",
        key: "freepackingextraqty",
        render: (_, text) => {
          return (
            <input
              className="tr001Input"
              type={"number"}
              defaultValue={text.freepackingextraqty}
              onFocus={(e) => e.target.select()}
              // {amol-373 change in onChange event}
              onChange={(e) => {
                // {amol-373 regex for decimal}
                const regex = /^\d+(\.\d+)?$/;
                if (e.target.value === "" || regex.test(e.target.value)) {
                  text.freepackingextraqty = e.target.value;
                  handleChange(text, setRowData, igstappl, rowData);
                } else {
                  alert("Invalid Input");
                  e.target.value = "";
                  text.freepackingextraqty = e.target.value;
                  handleChange(text, setRowData, igstappl, rowData);
                }
              }}
              // onChange={(e) => {
              //   text.freepackingextraqty = e.target.value;
              //   handleChange(text, setRowData, igstappl, rowData);
              // }}
            ></input>
          );
        },
      },

      // Quantity
      {
        title: "Quantity",
        width: 100,
        dataIndex: "qty",
        key: "qty",
        render: (_, text) => {
          return (
            <input
              className="tr001Input"
              type={"number"}
              defaultValue={text.qty}
              onFocus={(e) => e.target.select()}
              // {amol-373 change in onChange event}
              onChange={(e) => {
                const regex = /^[0-9\b]+$/;
                if (e.target.value === "" || regex.test(e.target.value)) {
                  text.qty = e.target.value;
                  handleChange(text, setRowData, igstappl, rowData);
                } else {
                  alert("Invalid Input");
                  e.target.value = "";
                  text.qty = e.target.value;
                  handleChange(text, setRowData, igstappl, rowData);
                }
              }}
              // onChange={(e) => {
              //   text.qty = e.target.value;
              //   handleChange(text, setRowData, igstappl, rowData);
              // }}
            ></input>
          );
        },
      },

      // Volume
      {
        title: "Volume",
        width: 100,
        dataIndex: "freepackingqty",
        key: "freepackingqty",
        render: (_, text) => {
          return (
            <input
              className="tr001Input"
              type={"number"}
              defaultValue={text.freepackingqty}
              onFocus={(e) => e.target.select()}
              // {amol-373 change in onChange event}
              onChange={(e) => {
                // {amol-373 regex for decimal}
                const regex = /^\d+(\.\d+)?$/;
                if (e.target.value === "" || regex.test(e.target.value)) {
                  text.freepackingqty = e.target.value;
                  handleChange(text, setRowData, igstappl, rowData);
                } else {
                  alert("Invalid Input");
                  e.target.value = "";
                  text.freepackingqty = e.target.value;
                  handleChange(text, setRowData, igstappl, rowData);
                }
              }}
              // onChange={(e) => {
              //   text.freepackingqty = e.target.value;
              //   handleChange(text, setRowData, igstappl, rowData);
              // }}
            ></input>
          );
        },
      },

      //  Net Weight
      {
        title: "Net Weight",
        width: 100,
        dataIndex: "free",
        key: "free",
        render: (_, text) => {
          return (
            <input
              className="tr001Input"
              type={"number"}
              defaultValue={text.free}
              onFocus={(e) => e.target.select()}
              // {amol-373 change in onChange event}
              onChange={(e) => {
                // {amol-373 regex for decimal}
                const regex = /^\d+(\.\d+)?$/;
                if (e.target.value === "" || regex.test(e.target.value)) {
                  text.free = e.target.value;
                  handleChange(text, setRowData, igstappl, rowData);
                } else {
                  alert("Invalid Input");
                  e.target.value = "";
                  text.free = e.target.value;
                  handleChange(text, setRowData, igstappl, rowData);
                }
              }}
              // onChange={(e) => {
              //   text.free = e.target.value;
              //   handleChange(text, setRowData, igstappl, rowData);
              // }}
            ></input>
          );
        },
      },

      // Gross Weight
      {
        title: "Gross Weight",
        width: 100,
        dataIndex: "purrate",
        key: "purrate",
        render: (_, text) => {
          return (
            <input
              className="tr001Input"
              type={"number"}
              defaultValue={text.purrate}
              onFocus={(e) => e.target.select()}
              // {amol-373 change in onChange event}
              onChange={(e) => {
                // {amol-373 regex for decimal}
                const regex = /^\d+(\.\d+)?$/;
                if (e.target.value === "" || regex.test(e.target.value)) {
                  text.purrate = e.target.value;
                  handleChange(text, setRowData, igstappl, rowData);
                } else {
                  alert("Invalid Input");
                  e.target.value = "";
                  text.purrate = e.target.value;
                  handleChange(text, setRowData, igstappl, rowData);
                }
              }}
              // onChange={(e) => {
              //   text.purrate = e.target.value;
              //   handleChange(text, setRowData, igstappl, rowData);
              // }}
            ></input>
          );
        },
      },

      // Test Certificate No.
      {
        title: "Test Certificate No.",
        width: 105,
        dataIndex: "cgstrate",
        key: "cgstrate",
        render: (_, text) => {
          return (
            <input
              className="tr001Input"
              // {amol-373 changed type to text}
              type={"text"}
              defaultValue={text.cgstrate}
              onFocus={(e) => e.target.select()}
              onChange={(e) => {
                text.cgstrate = e.target.value;
                handleChange(text, setRowData, igstappl, rowData);
              }}
            ></input>
          );
        },
      },

      // Serial No.
      {
        title: "Serial No.",
        width: 100,
        dataIndex: "sgstrate",
        key: "sgstrate",
        render: (_, text) => {
          return (
            <input
              className="tr001Input"
              type={"number"}
              defaultValue={text.sgstrate}
              onFocus={(e) => e.target.select()}
              // {amol-373 change in onChange event}
              onChange={(e) => {
                // {amol-373 regex for decimal}
                const regex = /^\d+(\.\d+)?$/;
                if (e.target.value === "" || regex.test(e.target.value)) {
                  text.sgstrate = e.target.value;
                  handleChange(text, setRowData, igstappl, rowData);
                } else {
                  alert("Invalid Input");
                  e.target.value = "";
                  text.sgstrate = e.target.value;
                  handleChange(text, setRowData, igstappl, rowData);
                }
              }}
              // onChange={(e) => {
              //   text.sgstrate = e.target.value;
              //   handleChange(text, setRowData, igstappl, rowData);
              // }}
            ></input>
          );
        },
      },

      // Nature of packing
      {
        title: "Nature of packing",
        width: 200,
        dataIndex: "igstrate",
        key: "igstrate",
        render: (_, text) => {
          return (
            <input
              className="tr001Input"
              // {amol-373 changed type to text}
              type={"text"}
              defaultValue={text.igstrate}
              onFocus={(e) => e.target.select()}
              onChange={(e) => {
                text.igstrate = e.target.value;
                handleChange(text, setRowData, igstappl, rowData);
              }}
            ></input>
          );
        },
      },

      // Remarks
      {
        title: "Remarks",
        width: 200,
        dataIndex: "amount",
        key: "amount",
        render: (_, text) => {
          return (
            <input
              className="tr001Input"
              // {amol-373 changed type to text}
              type={"text"}
              defaultValue={text.amount}
              onFocus={(e) => e.target.select()}
              onChange={(e) => {
                text.amount = e.target.value;
                handleChange(text, setRowData, igstappl, rowData);
              }}
            ></input>
          );
        },
      },
    ];
  }
};

export const handleAdd = (
  item,
  setRowData,
  igstappl,
  setSelectedItem,
  shortguid,
  packageNo,
  dimensions,
  area,
  quantity,
  volume,
  netWeight,
  grossWeight,
  testCertificateNo,
  serialNo,
  natureOfPacking,
  remarks
) => {
  var newShortguid = shortguid.getShortguid("POF" + item.itemrecno);
  console.log("Gnr1222", item);
  // No. of packages
  var packingextraqty = packageNo ? packageNo : 0;
  // Dimensions
  var packingqty = dimensions ? dimensions : 0;
  // Area
  var freepackingextraqty = area ? area : 0.0;
  // Quantity
  var qty = quantity ? quantity : 0;
  // Volume
  var freepackingqty = volume ? volume : 0.0;
  // Net Weight
  var free = netWeight ? netWeight : 0.0;
  // Gross Weight
  var purrate = grossWeight ? grossWeight : 0.0;
  // Test Certificate No.
  var cgstrate = testCertificateNo ? testCertificateNo : "";
  // Serial No.
  var sgstrate = serialNo ? serialNo : 0;
  // Nature of packing
  var igstrate = natureOfPacking ? natureOfPacking : "";
  // Remarks
  var amount = remarks ? remarks : "";

  var itemObject = {
    ...item,
    shortguid: newShortguid,
    itemrecno: item.itemrecno,
    itemdescn: item.itemdescn,
    packingextraqty: packingextraqty,
    packingqty: packingqty,
    freepackingextraqty: Number(freepackingextraqty).toFixed(2),
    qty: qty,
    freepackingqty: Number(freepackingqty).toFixed(2),
    free: Number(free).toFixed(2),
    purrate: Number(purrate).toFixed(2),
    cgstrate: cgstrate,
    sgstrate: sgstrate,
    igstrate: igstrate,
    amount: amount,
  };

  setRowData((p) => [...p, itemObject]);
  setSelectedItem(null);
};

export const handleChange = (
  item,
  setRowData,
  igstappl,
  rowData
  // setScandata,
) => {
  // No. of packages
  let packingextraqty = item?.packingextraqty ? item?.packingextraqty : 0;
  // Dimensions
  let packingqty = item?.packingqty ? item.packingqty : 0;
  // Area
  let freepackingextraqty = item?.freepackingextraqty
    ? item?.freepackingextraqty
    : 0.0;
  // Quantity
  let qty = item?.qty ? item.qty : 0;
  // Volume
  let freepackingqty = item?.freepackingqty ? item?.freepackingqty : 0.0;
  // Net Weight
  let free = item?.free ? item.free : 0.0;
  // Gross Weight
  let purrate = item?.purrate ? item.purrate : 0.0;
  // Test Certificate No.
  let cgstrate = item?.cgstrate ? item.cgstrate : "";
  // Serial No.
  let sgstrate = item?.sgstrate ? item.sgstrate : 0;
  // Nature of packing
  let igstrate = item?.igstrate ? item.igstrate : "";
  // Remarks
  let amount = item?.amount ? item.amount : "";

  var itemObject = {
    ...item,
    itemrecno: item.itemrecno,
    itemdescn: item.itemdescn,
    packingextraqty: packingextraqty,
    packingqty: packingqty,
    freepackingextraqty: Number(freepackingextraqty).toFixed(2),
    qty: qty,
    freepackingqty: Number(freepackingqty).toFixed(2),
    free: Number(free).toFixed(2),
    purrate: Number(purrate).toFixed(2),
    cgstrate: cgstrate,
    sgstrate: sgstrate,
    igstrate: igstrate,
    amount: amount,
  };
  var currentItem = rowData.map((itm) => {
    if (itm.shortguid == item.shortguid) {
      return { ...itemObject };
    } else {
      return { ...itm };
    }
  });

  setRowData(() => currentItem);
  // setScandata(item);
};

export const handleRemove = (item, setRowData, rowData) => {
  var removedArray = removeByAttribute(rowData, "shortguid", item.shortguid);
  console.log("Remove", item, setRowData, rowData, removedArray);
  setRowData(() => [...removedArray]);
};

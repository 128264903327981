import React, { forwardRef, useEffect, useMemo } from "react";
import PrintImage from "./PrintImage";
import PrintText from "./PrintText";
import PrintAmount from "./PrintAmount";
import PrintDate from "./PrintDate";
import { Table } from "antd";
import "./tableStyle.css";
import { tableColumns } from "./PrintTableComponent";
import PrintQRCode from "./PrintQRCode";
import PrintDecimal from "./PrintDecimal";
const PrintPage = forwardRef(
  (
    {
      openModal,
      printModal,
      PrintData,
      pageNumber,
      totalPageCount,
      rowitemsdata,
      pageheight,
      pagewidth,
      Messages,
      topHeight,
      firstPage,
      columnsinfo,
      lastPageIndex,
    },
    ref
  ) => {
    // const Pagenowisedata = PrintData.filter(
    //   (item) => item.pageno === pageNumber
    // );
    console.log("lastPageIndex", lastPageIndex);

    console.log("Messages", Messages, topHeight);

    const Pagenowisedata = PrintData;
    const totalrowheight = rowitemsdata.reduce((acc, curr) => {
      return acc + curr.rowheight;
    }, 0);
    console.log("filteredData==>", Pagenowisedata, PrintData.length);
    console.log("openmodal", openModal);
    console.log(
      "totalrowheight",
      totalrowheight,
      "rowitemsdata above",
      rowitemsdata
    );
    console.log("PrintData", PrintData, pageheight, pagewidth);

    console.log(
      "rowitemsdata111",
      PrintData,
      "totalPageCount",
      totalPageCount,
      "pageNumber",
      pageNumber
    );
    // let rowitemsdata= PrintData?.filter((item) => item?.headerfooter==2)
    //  const pageheight= "1000px"
    //  const pagewidth="827px"
    const pageborder = "1px solid black";
    useEffect(() => {
      printModal();
      //closeModal(!openModal)
    }, [openModal]);

    console.log("firstpage", firstPage);

    const headerdata = () => {
      return Pagenowisedata?.filter((item) => item?.headerfooter === 0).map(
        (item) => {
          // <div key={index}>
          if (item?.fieldtype === "img" || item?.fieldtype === "image") {
            return <PrintImage item={item} pageNumber={pageNumber} />;
          }
          if (item?.fieldtype === "qrcode") {
            if (item?.fieldvalue !== "") {
              return <PrintQRCode item={item} pageNumber={pageNumber} />;
            }
          }
          if (
            item?.fieldtype === "string" ||
            item?.fieldtype === "decimal" ||
            item?.fieldtype === "text"
          ) {
            return (
              <PrintText
                item={item}
                pageNumber={pageNumber}
                rowitemsdata={rowitemsdata}
              />
            );
          }
          if (item?.fieldtype === "amount" || item?.fieldtype === "number") {
            return <PrintAmount item={item} pageNumber={pageNumber} />;
          } else if (item?.fieldtype === "date") {
            return <PrintDate item={item} pageNumber={pageNumber} />;
          } else if (item?.fieldtype === "decimal") {
            return <PrintDecimal item={item} pageNumber={pageNumber} />;
          }
        }
      );
    };

    const bodyheader = () => {
      return Pagenowisedata?.filter((item) => item?.headerfooter === 1).map(
        (item) => {
          // <div key={index}>
          if (item?.fieldtype === "img" || item?.fieldtype === "image") {
            return <PrintImage item={item} pageNumber={pageNumber} />;
          }
          if (item?.fieldtype === "qrcode") {
            if (item?.fieldvalue !== "") {
              return <PrintQRCode item={item} pageNumber={pageNumber} />;
            }
          }
          if (
            item?.fieldtype === "string" ||
            item?.fieldtype === "decimal" ||
            item?.fieldtype === "text"
          ) {
            return (
              <PrintText
                item={item}
                pageNumber={pageNumber}
                rowitemsdata={rowitemsdata}
              />
            );
          }
          if (item?.fieldtype === "amount" || item?.fieldtype === "number") {
            return <PrintAmount item={item} pageNumber={pageNumber} />;
          } else if (item?.fieldtype === "date") {
            return <PrintDate item={item} pageNumber={pageNumber} />;
          } else if (item?.fieldtype === "decimal") {
            return <PrintDecimal item={item} pageNumber={pageNumber} />;
          }
        }
      );
    };

    const footerdata = () => {
      return Pagenowisedata?.filter((item) => item?.headerfooter === 3).map(
        (item) => {
          // <div key={index}>
          if (item?.fieldtype === "img" || item?.fieldtype === "image") {
            return <PrintImage item={item} pageNumber={pageNumber} />;
          }
          if (item?.fieldtype === "string" || item?.fieldtype === "text") {
            return (
              <PrintText
                item={item}
                pageNumber={pageNumber}
                rowitemsdata={rowitemsdata}
              />
            );
          }
          if (item?.fieldtype === "amount" || item?.fieldtype === "number") {
            return <PrintAmount item={item} pageNumber={pageNumber} />;
          } else if (item?.fieldtype === "date") {
            return <PrintDate item={item} pageNumber={pageNumber} />;
          } else if (item?.fieldtype === "decimal") {
            return <PrintDecimal item={item} pageNumber={pageNumber} />;
          }
        }
      );
    };

    const tabledata = () => {
      return (
        <div
          style={{
            position: "relative",
            maxWidth: "1099px",
            top: `${topHeight}px`,
          }}
        >
          {/* <div style={{ maxWidth: "1100px" }}>
          <Table
            dataSource={Messages}
            scroll={{ x: false }}
            
            tableLayout="fixed"
            overflowX="hidden"
            // columns={columnsinfo}
            columns={tableColumns(
              columnsinfo)}
            pagination={false}
            className="custom-table"
            style={{
              borderCollapse: "collapse",
              width: "1100px", // Set minimum width for the table
            }}
          />
          </div> */}

          {/* <div style={{ maxWidth: "1100px", overflowX: "auto" }}>
            <table
              style={{
                width: "1100px",
                tableLayout: "fixed",
                borderCollapse: "collapse",
              }}
              className="simple-table"
            >
              <thead>
                <tr>
                  {columnsinfo?.map((item) => (
                    <th style={{ width: `20px`,textAlign:item?.align }} key={item.dataIndex}>
                      {item.title}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {Messages?.map((item, index) => (
                  <tr key={index}>
                    {columnsinfo?.map((column) => (
                      <td
                        key={column.dataIndex}
                        style={{ width: "20px", wordWrap: "break-word" }}
                      >
                        {item[column.dataIndex]}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div> */}

          <table
            className="simple-table"
            style={{
              borderCollapse: "collapse",
              maxWidth: "100%",
              tableLayout: "fixed",
            }}
          >
            <thead>
              <tr>
                {columnsinfo?.map((item) => (
                  <th
                    style={{
                      fontStyle: item?.fontitalic ? "italic" : "",
                      fontWeight: item?.fontbold ? "bold" : "",
                      fontSize: `${item?.fontsize}px`,
                      fontFamily: `${item?.fontname}`,
                      width: `${item?.width}px`,
                      textAlign: "center",
                    }}
                    key={item?.dataIndex}
                  >
                    {item?.columndisplay}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {Messages?.map((item, index) => (
                <tr
                  className={`${
                    lastPageIndex === 2 && index === Messages.length - 1
                      ? "bold-last-row"
                      : ""
                  } ${item?.recno != null ? "last-row-border" : ""}`}
                  key={index}
                >
                  {columnsinfo?.map((column) => (
                    <td
                      key={column?.dataIndex}
                      style={{
                        fontStyle: column?.fontitalic ? "italic" : "",
                        fontWeight: column?.fontbold ? "bold" : "",
                        fontSize: `${column?.fontsize}px`,
                        width: `${column?.width}px`,
                        wordWrap: "break-word",
                        textAlign: column?.align,
                        padding: "3px",
                      }}
                    >
                      {lastPageIndex === 2 &&
                      index === Messages.length - 1 &&
                      ["0", "0.0", "0.00"].includes(item[column?.dataIndex]) ? (
                        ""
                      ) : column?.dataIndex === "itembatchno" ||
                        column?.dataIndex === "expdate" ||
                        column?.dataIndex === "testcertificateno" ||
                        column?.dataIndex === "serialno" ? (
                        <>
                          {item[column?.dataIndex]
                            ?.split(",")
                            .map((batchValue, index) => (
                              <div key={index}>{batchValue}</div>
                            ))}
                        </>
                      ) : lastPageIndex === 2 &&
                        index === Messages.length - 1 &&
                        !isNaN(item[column?.dataIndex]) ? (
                          column?.type === "number"
                          ? parseInt(item[column?.dataIndex])
                          : parseFloat(item[column?.dataIndex]).toFixed(2)
                      ) : (
                        item[column?.dataIndex]
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      );
    };

    return (
      <div
        style={{
          height: `${pageheight}px`,
          width: `${pagewidth}px`,
          border: pageborder,
          pageBreakBefore: "always",
          // backgroundColor:"red"
          // pageBreakAfter: "always"
        }}
        ref={ref}
      >
        {headerdata()}

        {firstPage ? bodyheader() : null}
        {footerdata()}
        {tabledata()}
        {/* <div style={{ position: "absolute" }}>
          <p
            style={{
              position: "absolute",
              left: `${pagewidth - 5}px`,
              top: `${pageheight + 60}px`,
            }}
          >
            {pageNumber}/{totalPageCount}
          </p>
        </div> */}
      </div>
    );
  }
);
export default PrintPage;

{
  /* <p
              style={{
                fontSize: `${item.fieldfontsize}px`,
                position: "absolute",
                border: item?.fieldbox === 1 ? "1px solid black" : null,
                left: `${item.fieldx}px`,
                top: item?.printonnextpage=== 1?`${(((1001)) + item.fieldy)}px`:`${(pageNumber - 1) * 1280 + item.fieldy}px`,
                fontFamily: item.fieldfontname,
                fontWeight: item.fontWeight,
                fontStyle: item.fontStyle,
                // fontWeight: item.fontbold==1?"bold":null,
                // fontStyle:item.fontitalic==1?"italic":null,
                width: item.fieldboxwidth == 0 ? "" : `${item.fieldboxwidth}px`,
                height:item.fieldboxheight == 0 ? "" : `${item.fieldboxheight}px`,
              }}
            >
              {item.fieldvalue}
            </p> */
}

// {Pagenowisedata?.filter((item) => item?.headerfooter !== 2).map(
//   (item, index) => {
//     // <div key={index}>
//     if (item?.fieldtype === "img" || item?.fieldtype === "image") {
//       return <PrintImage item={item} pageNumber={pageNumber} />;
//     }
//     if (
//       item?.fieldtype === "string" ||
//       item?.fieldtype === "decimal" ||
//       item?.fieldtype === "text"
//     ) {
//       return (
//         <PrintText
//           item={item}
//           pageNumber={pageNumber}
//           rowitemsdata={rowitemsdata}
//         />
//       );
//     }
//     if (item?.fieldtype === "amount" || item?.fieldtype === "number") {
//       return <PrintAmount item={item} pageNumber={pageNumber} />;
//     } else if (item?.fieldtype === "date") {
//       return <PrintDate item={item} pageNumber={pageNumber} />;
//     }
//   }
// )}
// <div style={{ position: "relative", top: "300px",bottom:"300px" }}>
//   <Table
//     dataSource={Message}
//     columns={columnsinfo}
//     pagination={false}
//     bordered
//   />
// </div>

{
  /* <div style={{ maxWidth: "1100px", overflowX: "auto" }}>
<table style={{ width: "1100px", tableLayout: "fixed", borderCollapse: "collapse" }}>
  <thead>
    <tr>
      {columnsinfo?.map((item) => (
        <th style={{ width: `20px` }} key={item.dataIndex}>
          {item.columndisplay}
        </th>
      ))}
    </tr>
  </thead>
  <tbody>
    {Messages?.map((item, index) => (
      <tr key={index}>
        {columnsinfo?.map((column) => (
          <td
            key={column.dataIndex}
            style={{ width: "20px", wordWrap: "break-word" }}
          >
            {item[column.dataIndex]}
          </td>
        ))}
      </tr>
    ))}
  </tbody>
</table>
</div> */
}

import { useContext, useEffect, useState } from "react";
import { getfastpathapi } from "../../../CommonControllers/ApiCalls";
import FilterComponents from "../../../CommonControllers/FiterComponent/FilterComponents"
import { TabStore } from "../../../TabsStructure/TabsStore";
import { AuthStore } from "../../../Auth/AuthStore";
import styles from "./IssuePoints.module.css";
export const IssuePoints=({fastPath})=>{
    const tabStore = useContext(TabStore);
    const auth = useContext(AuthStore);
    const [filterOptions, setFilterOptions] = useState([]);
    //------loading-----------------------
    const [showTable, setShowTable] = useState(true);
  
    const [pageIndex, setPageIndex] = useState();
  
    useEffect(() => {
      var tabsArray = tabStore.state.tabs;
      for (let i = 0; i < tabsArray?.length; i++) {
        if (tabsArray[i].name == fastPath) {
          setPageIndex(i);
          break;
        }
      }
    }, []);
    useEffect(() => {
        const payload = {
          tenantrecno: auth?.state?.userdata?.tenantrecno,
          domainrecno: auth?.state?.userdata?.domainrecno,
          userrolerecno: auth?.state?.userdata?.userrolerecno,
          domainuserrecno: auth?.state?.userdata?.recno,
          fastpath: tabStore.state.tabs[pageIndex]?.name,
          userrolerecno: auth.state.userdata.userrolerecno
        };
        // On component mount this api is called and filterOptions are set for that fast path
        getfastpathapi(payload).then((res) => {
          if (res?.Success) {
            console.log("on PO component mount", res);
            setFilterOptions(res?.Message?.filterOptions);
            tabStore.dispatch({
              type: "UPDATE_DATA",
              fieldType: "printUrl",
              text: res?.Message?.printUrl
    
            })
            tabStore.dispatch({
              type: "UPDATE_DATA",
              fieldType: "deleteUrl",
              text: res?.Message?.deleteapiurl
    
            })
          }
        });
      }, [pageIndex]);
return(<>
<di>
    <FilterComponents filterOption={filterOptions}/>
</di>
{/* <h1>Helllooooo</h1> */}
<div className={styles["tablesection"]}>
          <table>
            <thead>
              <tr>
                <th>Name</th>
                <th>Status</th>
                <th>Balance</th>
                <th>Add Points</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>2024-02-20</td>
                <td>10</td>
                <td>10</td>
                <td><input/></td>
                <td><button>Send</button></td>
              </tr>
              {/* Add more rows as needed */}
            </tbody>
          </table>
        </div>
        <h2>Creation </h2>
        <div className={styles["tablesection"]}>
          <table>
            <thead>
              <tr>
                <th>Date</th>
                <th>Cluster</th>
                <th>Domain</th>
                <th>Points</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>2024-02-20</td>
                <td>10</td>
                <td>10</td>
                <td>10</td>
                <td><button>Send</button></td>
              </tr>
              {/* Add more rows as needed */}
            </tbody>
          </table>
        </div>
</>)
}
import React, { useContext, useEffect, useState } from "react";
import { AuthStore } from "../../Auth/AuthStore";
import { TabStore } from "../../TabsStructure/TabsStore";
import DateComponent from "../Components/DateComponent";
import ExecuteDropdown from "../Components/ExecuteComponents/ExecuteDropdown";
import ExecuteTextInput from "../Components/ExecuteComponents/ExecuteTextInput";
import { getfastpathapi } from "../ApiCalls";
import ExecuteCheckbox from "../Components/ExecuteComponents/ExecuteCheckbox";
import ExecuteRadioBtn from "../Components/ExecuteComponents/ExecuteRadioBtn";
import { currentTabData } from "../Variable";
import { baseUrl } from "../Urls";
import ExecuteDateInput from "../Components/ExecuteComponents/ExecuteDateInput";
import ExecuteFileInput from "../Components/ExecuteComponents/ExecuteFileInput";
import { v4 as uuidv4 } from "uuid";
import { isArray } from "../AppFunction";

const FilterComponents = ({
  filterOptionsProps,
  setfilterdropdown,
  filterDictionary,
  setFilterDictionary,
  setDropdowndata,
  filterOption,
  returnValue,
  fastPath,pageIndex
}) => {
  const [filterOptions, setFilterOptions] = useState([]);
  const [selectedFilterOptions, setSelectedFilterOptions] = useState({});
  const auth = useContext(AuthStore);
  const tabStore = useContext(TabStore);
  const [alertShown, setAlertShown] = useState(false);

  useEffect(() => {
    setFilterOptions(filterOption);
  }, [filterOption]);

  useEffect(() => {
    setFilterOptions(filterOptionsProps);
  }, [filterOptionsProps]);
  useEffect(() => {
    // Reset alertShown when selectedFilterOptions changes
    setAlertShown(false);
  }, [selectedFilterOptions]);
  const storeData = (e, apikey, labeldropdowns) => {
    // Create a Temporary Add Store in the TabStore
    if (!e && !labeldropdowns) return;

    var newData = { ...selectedFilterOptions, [apikey]: e };
    setSelectedFilterOptions(newData);

    // Date validation started
    const fromdate = newData.fromdate
      ? newData.fromdate
      : getCurrentDateFormatted();
    const todate = newData.todate;

    const containsFromToDates =
      filterOptions.some((option) => option.apiKey === "fromdate") &&
      filterOptions.some((option) => option.apiKey === "todate");

    if (containsFromToDates && (apikey === "fromdate" || apikey === "todate")) {
      if (Number(todate) < Number(fromdate)) {
        if (!alertShown) {
          alert("To date cannot be less than from date");
          setAlertShown(true); // Set alert state to true

          const elements = document.getElementsByClassName("dateinput");
          for (let i = 0; i < elements.length; i++) {
            elements[i].style.color = "red";
          }
        }
        return;
      } else {
        setAlertShown(false);
        const elements = document.getElementsByClassName("dateinput");
        for (let i = 0; i < elements.length; i++) {
          elements[i].style.color = "black";
        }
      }
    }

    // Date validation end
    if (returnValue) {
      returnValue(newData);
    }

    if (setfilterdropdown && labeldropdowns) {
      setfilterdropdown(labeldropdowns); // smk 24-2-
    }
  };

  /// logic for getting todays date
  function getCurrentDateFormatted() {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");
    return `${year}${month}${day}`;
  }
  //* This function sets the values obtained from the filteroptions above
  const setterFunction = (event, element) => {
    let displayValue;
    //# May need to use logical OR operator
    typeof event === "object"
      ? (displayValue = event?.displayLabel ?? event.label)
      : (displayValue = event);

    //% Check here whether array contains the same id, if yes replace it with new object

    const targetReplacer = filterDictionary?.find(
      (obj) => obj.id === element.displayLabel
    );
    if (targetReplacer) {
      //~# alert(`Replaced previous filter: ${element?.displayLabel}`);
      Object.assign(targetReplacer, {
        id: element?.displayLabel,
        descn: displayValue,
      });
      return;
    }

    //% Setting id as displayLabel ==> id : Domain and key : value as ==> Domain : SAPL (example)
    setTimeout(() => {
      if (isArray(filterDictionary)) {
        let tempArray = [
          ...filterDictionary,
          { id: element?.displayLabel, descn: displayValue },
        ];

        setFilterDictionary(tempArray);
      }
    }, 100);
  };

  const enterPress = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      const form = e.target.form;
      const index = Array.prototype.indexOf.call(form, e.target);
      if (form.elements[index + 1]) {
        form.elements[index + 1].focus();
      }
    }
  };

  return (
    <div>
      <div className="searchBox">
        <form className="searchBox"  key={
            tabStore?.state?.tabs[pageIndex]?.refresh
              ? "refreshing"
              : "notRefreshing"
          }>
          {filterOptions?.map((elem) =>
            elem.type === "dropdown" ? (
              // file deepcode ignore ReactMissingArrayKeys: <please specify a reason of ignoring this>
              <div onKeyDown={enterPress} style={{ zIndex: 9999 }}>
                <ExecuteDropdown
                  displayLabel={elem?.displayLabel}
                  alignment="column"
                  placeholder={elem?.placeholder}
                  apiurl={elem?.apiEndPoint}
                  selectionLabel={elem?.selectionLabel}
                  selectionValue={elem?.selectionValue}
                  apikey={elem?.apiKey}
                  multi={elem?.isMulti}
                  records={20}
                  minInput={0}
                  returnValue={(e) => {
                    if (e) {
                      // Ensure e is an array before accessing [0]
                      const selectedItem = Array.isArray(e) ? e[0] : e;
                      setterFunction(selectedItem, elem);

                      // Check if selectedItem exists and has the desired property
                      if (selectedItem && selectedItem[elem?.selectionValue]) {
                        storeData(
                          selectedItem[elem?.selectionValue],
                          elem?.apiKey,
                          selectedItem[elem?.selectionLabel]
                        );
                      } else {
                        console.log(
                          "Selection Value not found in selectedItem"
                        );
                      }
                    }
                  }}
                  body={{
                    ...selectedFilterOptions,
                  }}
                  requiredBody={elem?.payloadKey}
                  width={elem?.width}
                  defaultSelected={
                    elem?.defaultValue
                      ? elem?.defaultValue
                      : selectedFilterOptions[elem.apiKey]
                  }
                  isrequired={elem?.req}
                />
              </div>
            ) : elem.type === "string" ? (
              <div onKeyDown={enterPress}>
                <ExecuteTextInput
                  alignment="column"
                  displayLabel={elem?.displayLabel}
                  placeholder={elem?.placeholder}
                  apikey={elem?.apiKey}
                  defaultValue={elem?.defaultValue}
                  change
                  returnValue={(e) => {
                    setterFunction(e, elem);
                    storeData(e, elem?.apiKey);
                  }}
                  isrequired={elem?.req}
                  width={`${elem?.width}px`}
                />
              </div>
            ) : elem.type === "boolean" ? (
              <div onKeyDown={enterPress}>
                <ExecuteCheckbox
                  alignment="column"
                  displayLabel={elem?.displayLabel}
                  placeholder={elem?.placeholder}
                  apikey={elem?.apiKey}
                  defaultValue={
                    fastPath === "RP117" || fastPath === "RP118"
                      ? true
                      : elem?.defaultValue
                  }
                  // defaultValue={elem?.defaultValue}
                  change
                  editable
                  returnValue={(e) => {
                    setterFunction(e, elem);
                    storeData(e, elem?.apiKey);
                  }}
                />
              </div>
            ) : elem.type === "date" ? (
              <div onKeyDown={enterPress}>
                <ExecuteDateInput
                  id={uuidv4()}
                  alignment="column"
                  defaultValue={elem?.defaultValue}
                  displayLabel={elem?.displayLabel}
                  placeholder={elem?.placeholder}
                  apiKey={elem?.apiKey}
                  className={"dateinput"}
                  change
                  editable
                  returnValue={(e) => {
                    setterFunction(Number(e), elem);
                    storeData(e, elem?.apiKey);
                  }}
                  isrequired={elem?.req}
                  width={`${elem?.width}px`}
                />
              </div>
            ) : elem.type === "file" ? (
              <div onKeyDown={enterPress}>
                <ExecuteFileInput
                  displayLabel={elem?.displayLabel}
                  placeholder={elem?.placeholder}
                  apiKey={elem?.apiKey}
                  returnValue={(e) => {
                    setterFunction(e, elem);
                    storeData(e, elem?.apiKey);
                  }}
                />
              </div>
            ) : elem.type === "radio" ? (
              <div onKeyDown={enterPress}>
                <ExecuteRadioBtn
                  apikey={"radio"}
                  displayLabel={"Select Radio"}
                  options={elem?.radioOptions}
                  defaultValue={elem?.defaultValue}
                  returnValue={(e) => {
                    setterFunction(e, elem);
                    storeData(e, elem?.apiKey);
                  }}
                />
              </div>
            ) : null
          )}
        </form>
      </div>
    </div>
  );
};

export default FilterComponents;

import React from 'react';
import CommonMaster1 from "./Images/CommonMaster1.png"
import CommonMaster2 from "./Images/CommonMaster2.png"
import CommonMaster3 from "./Images/CommonMaster3.png"
import Images from './Images/Images.css'
const CommonMasters = () => {
  return (
    <div>
          <div style={{position:'fixed',backgroundColor:'white',width:'80%',marginBottom:'1%'}}><h1>Common Masters</h1></div>
          <br/> 
          <br/> 
        <p>There are some places in the entire system whare there are drop downs are used to pickup desired selection value. Instead of making them hard coded , these values can be kept in common masters where in case any new values are to be inserted , can be done easily. This is called as metadata. </p>
        <p>Here this data can be categorized in two, one is common for all or other is specific to the domain. Common for all will be available to all the domain and domain specific will be visible only for specific domain.</p>
        <p>The UI for CRUD is available in the system by which user can create new record , update or even remove any value. This operation is restricted to the administration level and is not available for all the users. The common masters can be accessed only by the Tenant administrator.   </p>
        <img src={CommonMaster1} className="images" />
        <p>The above screen presents common masters for all where the domain is not required. </p>
        <p>Below is the screen where the selection of domain is mandatory.</p>
        <img src={CommonMaster2} className="images" />
         <img src={CommonMaster3} className="images" />
        <p>Once we attempt to alter the table the screen appears as above . Here the first button is for addition of new record and the button of pencil is editing the existing record. The record values can be edited and then can be saved row by row.  Once edited and saved the row it remains in red colour to indicate the row is edited and ready for save.</p>
        <p>User can edit multiple records and finally click save button in menu-bar to save all the changes done in the table.</p>
        <p>The total records have two values. First is the active record count and next is total records.</p>
        


        <h3>BU Type (MS001)</h3>
        <p>
                  During our understanding of the Distributor Chain, we have realised that there are various
        types of Business Units (BUs) involved, such as stockist, super distributor, distributor, and
        retailer. This master screen is used to store and manage information related to these
        different BU types.
        It is important to acknowledge that different clients of BCore may have varying types of
        Business Units (BUs). Consequently, for each BCore client, a distinct set of BU Types will be
        prepared to cater to their specific needs and requirements.
</p>
        <h3>Party Type (MS002)</h3>
        <p>
                  BCore is a crucial software solution designed to streamline the distribution structure of a
          company. Within this system, we utilise a single screen called Party (MS001) to create
          profiles for all entities involved, including customers, suppliers, consignees, salesmen,
          transporters, and more. This screen serves as a central hub for managing and identifying
          different party types, allowing for efficient classification and organisation of the entities within
          the distribution network.
        </p>
        <h3>Customer Category (MS003)</h3>
        <p>
                To cater to specific requirements, Business Units (BUs) have the ability to create customer
        categories. This particular screen facilitates the creation and maintenance of these
        categories, ensuring that all relevant information is effectively managed. By utilising this
        screen, BUs can efficiently define and organise customer categories to meet their specific
        needs and effectively streamline their operations.
        </p>
        <h3>Customer Class (MS004)</h3>
        <p>
                  For specific classification purposes, businesses can establish different customer classes.
          This feature enables companies to create and manage customer classes through a
          designated screen. By utilising this screen, businesses can effectively categorise customers.
          The customer class screen serves as a central tool for businesses to define and maintain
          distinct customer classes.
        </p>
        <h3>State (MS005)</h3>
        <p>
                  This screen presents a comprehensive list of all states and union territories of India. It
          includes the corresponding state numbers that are specifically designated for Goods and
          Services Tax (GST) purposes.
        </p>
        <h3>District (MS006), Taluka (MS007), City (MS008)</h3>
        <p>
                  Similarly, corresponding screens are available to maintain comprehensive lists of districts,
          talukas, and cities. These screens provide a systematic and organised collection of districts,
          talukas (subdivisions within districts), and cities throughout the designated area.
        </p>
        <h3>Region (MS009)</h3>
        <p>
                  To facilitate effective management of business operations, the company has the ability to
          divide its business area into different regions. This task is accomplished through a dedicated
          screen that allows for the creation and maintenance of the entire list of regions. By utilising
          this screen, the company can define and organise distinct geographical regions based on
          specific criteria.
        </p>
        <h3>Bank-Branch (MS010)</h3>
        <p>
                  The screen provides a convenient platform for maintaining detailed information about bank
          branches, including the bank and branch names, as well as important identifiers such as
          IFSC (Indian Financial System Code) and MICR (Magnetic Ink Character Recognition)
          numbers. Through this screen, users can efficiently manage and update the database of
          bank branch details
        </p>
        <h3>Certificate (MS011)</h3>
        <p>
                  This screen serves as a valuable tool for creating and managing various certificates and
          licences under which a business house is registered. It provides the necessary functionality
          to define and customise the names of required certificates and licences according to specific
          regulatory or compliance requirements.
        </p>
        <h3>Certificate Type (MS012)</h3>
        <p>
                This screen is specifically designed to create and manage different types of certificates, such
        as certificates and licences.
          </p>
        <h3>Area (MS014)</h3>
        <p>
                  To effectively handle sales and receivables across a large geographical area, it is often
          necessary for a BU to divide the region into more manageable areas. This task is
          accomplished through the utilisation of a dedicated screen that allows for the creation and
          management of a comprehensive list of details for each of these areas. By using this screen,
          BU can define and organise the various manageable areas within the larger geographical
          region.
        </p>
        <h3>Gender (MS015)</h3>
        <p>
                  In order to maintain the profile of an individual, it is necessary to capture their gender
          information. This particular screen provides a comprehensive list of all viable gender options
          available. By utilising this screen, users can conveniently select and assign the appropriate
          gender category to accurately reflect an individual's gender identity.
        </p>
        <h3>Location Type (MS016)</h3>
        <p>
                  This screen plays a pivotal role in managing the types of locations within a widely spread
          business. Both business units (BUs) and customers may have multiple locations that engage
          in diverse activities. The screen allows for the creation and organisation of different types of
          locations based on the specific activities carried out at each location. This functionality
          enables efficient categorization and management of locations, ensuring accurate
          documentation and streamlined operations.
        </p>
        <h3>Image Type (MS017)</h3>
        <p>
                  Here, we provide a definition of the types of images that can be stored for future utilisation.
        This includes documents, logos, licences, certificates, and more. Users have the capability
        to capture and store various types of images within the repository, specifically those
        mentioned in the defined categories.
        </p>
        <h3>Document Type (MS020)</h3>
        <p>
                Different documents are created to record various transactions. For example, a Sales
        Invoice is prepared to document a sale transaction, a delivery challan is generated to
        confirm the delivery of goods, a receipt is issued upon receiving payment, and so on. This
        screen serves as a repository for all types of documents.
        </p>
        <h3>Reason for Sales Return (MS021)</h3>
        <p>
                This screen manages the reasons for goods being returned by our customers. When
        recording a sales return transaction, the screen allows the selection of the appropriate
        reason, which is then stored along with the sales return transaction.
        </p>
        <h3>Reason for Purchase Return (MS022)</h3>
        <p>
                  This screen manages the reasons for goods being returned back to our suppliers. When
        recording a purchase return transaction, the screen allows the selection of the appropriate
        reason, which is then stored along with the purchase return transaction.
        </p>
        <h3>Reason for Stock Receipt (MS024)</h3>
        <p>
                This screen offers a facility to manage the reasons for item receipts. The reasons are
        presented in a list within the Item Issue screen.
        </p>
        <h3>
          Purchase Order Status (MS025)
          GRN Status (MS026)
          Purchase Invoice Status (MS027)
          Purchase Return Status (MS028)
          Sales Order Status (MS029)
          Delivery Challan Status (MS030)
          Sales Invoice Status (MS031)
          Sales Return Status (MS032)
          Counter Bill Status (MS033)
          </h3>
          <p>
                    These screens are dedicated to managing the status values associated with
          inventory-related transactions. Instead of numeric values, users are presented with captions
          that represent different status values. The application internally handles these status values
          based on predefined logic. The purpose of using status captions is to assist users in easily
          comprehending the current status of various transactions related to inventory. By providing
          descriptive captions, the screens aid users in quickly understanding the progress,
          completion, or any other relevant status information of inventory-related processes. This
          simplifies decision-making, improves communication, and enhances overall efficiency in
          managing inventory transactions within the application.
          </p>
          <h3>
        Accounting Narration (MS034)
        </h3>
        <p>
                  An accounting narration master is a dedicated component designed to store and manage
          accounting narrations within an accounting system or software. It serves as a central
          repository for storing descriptions or explanations related to financial transactions.The
          purpose of the accounting narration master is to provide a standardised and consistent way
          of documenting the context or details of each transaction. Users can create and maintain
          various narrations associated with specific accounting entries or events.
        </p>
          <h3>Packing (MS067)</h3>
          <p>
            The packing screen provides the functionality to create various packing containers and track
            the quantity of items packed within each container
        </p>
        <h3>Packing Material (MS068)</h3>
        <p>
          The screen offers the capability to create and manage different types of packing materials,
           such as glass bottles, boxes, plastic jars, and more.
        </p>
        <h3>Party Group 1 (MS085), Party Group 2 (MS086), Party Group 3 (MS087), Party Group 4
(MS088), Party Group 5 (MS089)</h3>
        <p>
                To enhance the criteria for classifying parties, additional groups are made available through
        this feature. Business units (BUs) can utilise these groups to classify and categorise the
        nature of their business transactions with different parties. By leveraging these additional
        groups, BUs can establish more specific and customised classifications that align with their
        unique business requirements. This facilitates improved organisation, analysis, and reporting
        of party-related data, enabling BUs to make informed decisions and develop targeted
        strategies tailored to each party group.
        </p>
        <h3></h3>
        <p>
          
        </p>
    </div>
  )
}

export default CommonMasters
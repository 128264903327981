import React from 'react';
import MenuBar from "./Images/MenuBar.png"

const GettingStarted = () => {
  return (
    <div>
         <div style={{position:'fixed',backgroundColor:'white',width:'80%'}}><h1>Getting Started</h1></div>
         <br/> 
         <br/> 
        <p>BCore application is browser based User interface providing all the functionalities expected by the user. The users of BCore are normally has multiple tasks to be done simultaneously for this the base APP provides us multi-tab operations . Each page opened by user is creating a new tab within one window of browser. All the internal pages remain within one single web page.</p>
        <p>Total number of pages (TABs ) to be opened at a time can be parameterized. This helps user to open new page in between the operations are incomplete, finish new page and then come back to the previous page. If user logs out for any reason then all the tabs will automatically gets closed and there is no pending webpage remained in open condition.</p>
        <img className='images' src={MenuBar} alt="Menu bar" />
        <p>Above menubar is always at the top of the page and all the important buttons are always be available at the top of the page.</p>
        <p>The first button is the main menu where all the pages are listed. It is a tree view which further expands and the menu items. Each of the menu item is one page . </p>
        <p>Next is fastpath where user can type the fastpath code are reach to the page directly as you click the search button. </p>
        <p>The edit button is for initiating the editing of the records. It is mainly for the masters or transactions listed in the grid. Edit button enables the row wise editing .</p>
    </div>
  )
}

export default GettingStarted
import React, { useState } from "react";
import { Button, Modal, Table } from "antd";

const PrinterModal = React.forwardRef(
  (
    { columns, rows, isModalOpen, setIsModalOpen, filterDictionary, setFilterDictionary, setStartPrinting, reportname,wtfIsThisData },
    ref
  ) => {
  
    let prevColumns = [...columns];
    //% If any error or bug encountered in future, it maybe due to key-names, check key names in network tab!
    const withItems = filterDictionary?.find((elem) => elem.id.toLowerCase() === "with items");

    let modalColumns = prevColumns?.filter((obj) => obj.columndisplay != "Item Name");

   

    function formatDate(data) {
      if (data !== "" && data !== 0) {
        let str = data.toString();
        return `${str.slice(-2)}/${str.slice(4, 6)}/${str.slice(0, 4)}`;
      }
    }

    return (
      <>
        <div>
          <Modal
            title={`${filterDictionary[0]?.descn}`}
            open={isModalOpen}
            width={"max-content"}
            style={{ top: 0 }}
            onCancel={() => setIsModalOpen((current) => !current)}
            footer={[
              <Button key="back" onClick={() => setIsModalOpen((current) => !current)}>
                Cancel
              </Button>,
              <Button key="submit" onClick={() => setStartPrinting(true)}>
                Print
              </Button>,
            ]}
          >
            <div ref={ref} style={{ ...printerWrapper }}>
              <table>
                {/* //> Head of the table where info of filters is displayed */}
                <thead className="printerDocHeader">
                  <tr>
                    <td>
                      <div className="reportHeading">
                        <h3>{reportname}</h3>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="headerDetails">
                        {filterDictionary?.map((element) => (
                          <div style={{ display: element?.id.toLowerCase() === "with items" && "none" }}>
                            <p style={{ display: element?.id.toLowerCase() === "domain" && "none" }}>{element?.id}</p>
                            <h4>{typeof element?.descn === "number" ? formatDate(element?.descn) : element?.descn?element?.descn:wtfIsThisData.data2}</h4>
                          </div>
                        ))}
                      </div>
                    </td>
                  </tr>
                </thead>
                {/* //% Body of the table where the table data is displayed */}
                <tbody>
                  <tr>
                    <td style={{ padding: 0, border: 0 }}>
                      <div className="dataTable">
                        <Table
                          size="middle"
                          pagination={false}
                          columns={withItems?.descn ? columns : modalColumns}
                          dataSource={rows}
                        />
                      </div>
                    </td>
                  </tr>
                </tbody>
                {/* //# Footer for future information reference or additional info */}
                <tfoot className="printerDocFooter"></tfoot>
              </table>
            </div>
          </Modal>
        </div>
      </>
    );
  }
);

export default PrinterModal;

const printerWrapper = {
  padding: 0,
};

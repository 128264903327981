import { Checkbox, Descriptions, Input, Table } from "antd";
import React, { useEffect, useState } from "react";
import { dateFormatter } from "../../CommonControllers/AppFunction";
import useFetchItem from "../../CommonControllers/ItemController/useFetchItems";



function ItemSelection({ item, forFlag ,setDens}) {

  const [itemDetails, setItemDetails] = useState();
  const [freeQty, setFreeQty] = useState(0);
  const [qty, setQty] = useState(0);
  const [isPick, setIsPick] = useState(false);
  const useFetchItems = useFetchItem()

  const [count, setCount] = useState(0);
  const [count1,setCount1] = useState(0)

  

  const covertDataForBatchDetails = (resp) => {

    if (resp?.Message[0]) {
      var batchColumns = [
        {
          dataIndex: "itembatchno",
          key: "itembatchno",
          title: "Batch No.",
        },
        {
          dataIndex: "expdate",
          key: "expdate",
          title: "Expiry",
          render: (_, record) => {
            return <p>{dateFormatter(record.expdate)}</p>;
          },
        },
        {
          dataIndex: "stock",
          key: "stock",
          title: "Stock",
        },
        // {
        //   dataIndex: "selectedQty",
        //   key: "selectedQty",
        //   width: "85px",
        //   title: "Quantity",
        // },

        {
          title: "Quantity",
          dataIndex: "selectedQty",
          key: "selectedQty",
          render: (_, text) => {
            return (
            <Input

                defaultValue={text.Quantity}
                // onFocus={(e) =>e.target.select()}
                id="Qty"
                onChange={(e) => {
                  console.log("Quantity::", text);

                  text.crqty= Number(e.target.value);
                  // text.itemrecno=i
                  // handleChange(text, setRowData, igstappl, rowData,i);
                  handleChange(text)
                  
                }}
              ></Input>
            );
          },
        }

        // {
        //   dataIndex: "selectedFreeQty",
        //   key: "selectedFreeQty",
        //   width: "80px",
        //   title: "Free Quantity",
        // },
      ];
      var batchColumnsEditable = [
        {
          dataIndex: "itembatchno",
          key: "itembatchno",
          title: "Batch No.",
        },
        {
          dataIndex: "expdate",
          key: "expdate",
          title: "Expiry",
          render: (_, record) => {
            return <p>{dateFormatter(record.expdate)}</p>;
          },
        },
        {
          dataIndex: "stock",
          key: "stock",
          title: "Stock",
        },
        {
          dataIndex: "qty",
          key: "qty",
          width: "80px",
          title: "Quantity",
          render: (_, record) => {
            return <Input placeholder="Enter Quantity"></Input>;
          },
        },

        {
          dataIndex: "freeqty",
          key: "freeqty",
          width: "80px",
          title: "Free Quantity",
          render: (_, record) => {
            return <Input placeholder="Enter Quantity"></Input>;
          },
        },
      ];



      resp.Message[0].batchColumnsEditable = batchColumnsEditable;
      resp.Message[0].batchColumns = batchColumns;
      console.log("Yeah Data Updated", "!!!!", resp);
      setItemDetails(resp);
 
    }
  };


  
  const getItemDetails = async (event) => {
    const resp = await useFetchItems.fetchItems(
      "itemrecno",
      event,
      forFlag,
      true
    );

    console.log("Response", resp.data)
    covertDataForBatchDetails(resp.data);
  };

  useEffect(() => {getItemDetails(item)}, [item])

  useEffect(() => {
    // Calculate the Quantiy of Batch Wise
    var adjustQty = qty;
    while (adjustQty > 0) {
      adjustQty = adjustQty -1
      console.log(adjustQty)
      let i;
      for (i=0;i < itemDetails.Message[0]?.batchdetails?.length; i++) {
        // itemDetails.Message[0]?.batchdetails[i]
        console.log("ItemDetails Batch", "Here we need to ")
      }
    }
    
    
  }, [qty, freeQty]);
  

    const handleChange =( item)=>{
     
        

        const ip1 = item?.stock;
    const ip2 = item?.crqty;
    setCount(ip1)
   
    setCount1(ip2)
        if(ip2>ip1){
          alert("Quenty is greated then stock")
          
          const temp = document.getElementById("Qty")
          console.log("temp", temp)
           temp.value = "";
          // temp.value="";
          // item.stock=0 
        }
      //   var p = item?.filter((el)=>{
      //     return el.length === 0
      //  })
      setDens(item) 
    
     
    }

  return (
    <div style={{width:"100%"}}>


    <div style={{width:"70%" ,margin:"auto",marginBottom:"50px"}}>
{!isPick ? (
          <Table
          scroll={{ y: 150 }}
            size="small"
            pagination={false}
            columns={itemDetails?.Message[0]?.batchColumns}
            dataSource={itemDetails?.Message[0]?.batchdetails}
          />
        ) : (
          <Table
          scroll={{ y: 150 }}
            size="small"
            pagination={false}
            columns={itemDetails?.Message[0]?.batchColumnsEditable}
            dataSource={itemDetails?.Message[0]?.batchdetails}
          />
        )}

</div>
    
    </div>
  );
}

export default ItemSelection;

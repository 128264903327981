import React from 'react'
// import QRCode from "qrcode.react";
const PrintQRCode = ({item}) => {
    console.log("qrcode",item)
    const  x = `${item?.fieldx}px`
    const  y = `${item?.fieldx}px`
  return (
    <div>
    {(item?.fieldyto && item?.fieldxto)!=0?(
      <div
        style={{
          // position: "relative", // Make sure the container has a position other than the default 'static'
          width: `${item?.fieldxto}px`,
          height: `${item?.fieldyto}px`,
          left: `${item?.fieldx}px`,
            // top: `${(pageNumber - 1)*1280 + item.fieldy}px`,
            top: `${ item?.fieldy}px`,
          position:"absolute"
        }}
      >
        {/* <QRCode value={item?.fieldvalue}  
          x= {x}  /// the issue with qrcode.react has been face on 11th july that it commented ou
            y={y} /> */}
      </div>
    ) : (
      <div
      style={{
      position:"absolute",
      left: `${item?.fieldx}px`,
      // top: `${(pageNumber - 1)*1280 + item.fieldy}px`,
      top: `${ item?.fieldy}px`,
      }}>
         {/* <QRCode value={item?.fieldvalue} /> */}
      </div>
    )}
  </div>
  )
}

export default PrintQRCode
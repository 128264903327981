import { useState, useEffect, useContext, useRef } from "react";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Button, Radio, Space, Divider } from "antd";

import { DeleteOutlined } from "@ant-design/icons";
import SaveDropDown from "../../../CommonControllers/SaveComponents/SaveDropDown";

import { getAccountlist } from "../../../CommonControllers/ApiCalls";
import { getAccountlistURL } from "../../../CommonControllers/Urls";

import { AuthStore } from "../../../Auth/AuthStore";
import { TabStore } from "../../../TabsStructure/TabsStore";
import {
  transformPendingInsutrments,
  generateAccountingEntryPayLoad,
  clearAutoAdjustements,
} from "../Transaction/TransactionHelper";
import SaveDropdown from "../../../CommonControllers/Components/SaveComponents/SaveDropdown";

function DebitNotePendingInsutrumentsTransaction(props) {
  {
    /**
     * 
    
    ** 01FEB2023:state variables used **

    displayvouchers: keep track of accounts which are selected by user for credit debit on main page
    pendinginstruments: pending instruments for selected account -> {selectedAccountToSettleDiscounts}
    gl_disocunt_items : store list of gl account selected in popup 


*/
  }

  const auth = useContext(AuthStore);
  const tabstore = useContext(TabStore);

  const [currentSettlmentAccount, setcurrentSettlmentAccount] = useState(null); // account where pending settlement button is clicked
  const [open, setOpen] = useState(false);
  const [
    currentInstrumentSelectedForDiscount,
    setcurrentInstrumentSelectedForDiscount,
  ] = useState(null); // selected insturment by user for disocunt / adjustments

  const [discountAmount, setDiscountAmount] = useState(null); // store amt for discount gl popup
  const [discountGLCode, setDiscountGLCode] = useState(null);
  const [discountGLName, setDiscountGLName] = useState(null);

  useEffect(() => {
    console.log("props.selectedaccount");
    console.log(JSON.stringify(props.selectedaccount));

    let changedrows =
      tabstore?.state?.tabs[tabstore?.state.tabIndex]?.changedRows;
    let _instruments = changedrows["pendinginstruments"];
    console.log("_instruments useEffect ");
    console.log(_instruments?.length);

    if (_instruments?.length == 0 || _instruments == undefined) {
      getpendinginstruments();
    } else {
      setcurrentSettlmentAccount(_instruments);
    }
  }, [props?.selectedaccount]);

  const handleClose = () => {
    setOpen(false);

    tabstore.dispatch({
      type: "UPDATE_CHANGED_DATA",
      fieldType: "pendinginstruments",
      text: [],
    });
  };

  const handleOpenGLDiscountPopup = (data) => {
    let gl_disocunt_items = []; // TO DO Handle In Case of Edit  here\
    console.log("handleOpenGLDiscountPopup");
    console.log(data);

    // reconcile childrows array stored in pendinginstruments

    /*
    let changedrows =
      tabstore?.state?.tabs[tabstore?.state.tabIndex]?.changedRows;
    let pending_instruments = changedrows["pendinginstruments"];
*/

    if (data.childrows?.length > 0) {
      gl_disocunt_items = data.childrows;
    }

    tabstore.dispatch({
      type: "UPDATE_CHANGED_DATA",
      fieldType: "gl_disocunt_items",
      text: gl_disocunt_items,
    });

    setcurrentInstrumentSelectedForDiscount(data);
    setOpen(true);
  };

  const handleSaveGLDiscountPopup = () => {
    //setOpen(false);
    /**
     * Save GL as child rows of pending instruments
     *  */

    let changedrows =
      tabstore?.state?.tabs[tabstore?.state.tabIndex]?.changedRows;

    console.log("handleSaveGLDiscountPopup");
    console.log(changedrows);

    console.log("currentInstrumentSelectedForDiscount");
    console.log(currentInstrumentSelectedForDiscount);

    let selectedAccountToSettleDiscounts =
      changedrows["selectedAccountToSettleDiscounts"];

    let pending_instruments = changedrows["pendinginstruments"];
    let gl_discount_items_added = changedrows["gl_disocunt_items"];

    if (pending_instruments?.length > 0) {
      // find instrument where to add discount gl rows
      //arr.find(o => o.name === 'string 1');

      let instrument_object = pending_instruments.find(
        (i) => i.recno == currentInstrumentSelectedForDiscount.recno
      );

      console.log("instrument_object");
      console.log(instrument_object);

      console.log("gl_discount_items_added");
      console.log(gl_discount_items_added);

      if (instrument_object) {
        instrument_object.childrows = gl_discount_items_added;
      }

      let index = pending_instruments?.findIndex(
        (i) => i.recno == currentInstrumentSelectedForDiscount.recno
      );

      pending_instruments[index] = instrument_object;

      tabstore.dispatch({
        type: "UPDATE_CHANGED_DATA",
        fieldType: "pendinginstruments",
        text: pending_instruments,
      });
    }

    console.log("selectedAccountToSettleDiscounts");
    console.log(selectedAccountToSettleDiscounts);

    setOpen(false);
  };

  function handleDiscountGL(e) {
    if (e) {
      console.log("handleDiscountGL");
      console.log(e);
      setDiscountGLCode(e.code);
      setDiscountGLName(e.descn);
    }
  }

  const handleDiscountAmtUpdate = (amt) => {
    if (amt > 0) {
      setDiscountAmount(amt);
    }
  };

  const AddDiscountGL = () => {
    if (discountAmount > 0 && discountGLCode.length > 0) {
      // add row to gl table
      console.log(discountGLCode);
      console.log("Amt =" + discountAmount);

      let discount_item = {
        code: discountGLCode,
        descn: discountGLName,
        amount: discountAmount,
      };

      let gl_disocunt_items = [];

      gl_disocunt_items =
        tabstore?.state?.tabs[tabstore?.state.tabIndex]?.changedRows[
          "gl_disocunt_items"
        ];

      if (gl_disocunt_items == undefined) {
        gl_disocunt_items = [];
      }

      gl_disocunt_items.push(discount_item);

      tabstore.dispatch({
        type: "UPDATE_CHANGED_DATA",
        fieldType: "gl_disocunt_items",
        text: gl_disocunt_items,
      });

      setDiscountGLCode("");
      setDiscountGLName("");
      setDiscountAmount(0);
    }
  };

  async function getpendinginstruments() {
    let temp_cdflag = 1;

    console.log("fetch getpendinginstruments");

    //
    // 04FEB2023 if we are entering debit amount for selected account then fetch credit balances
    // 04FEB2023 if we are entering debit amount for selected account then fetch credit balances
    /*
    if (props?.selectedaccount.debit > 0) {
      temp_cdflag = 2;
    } else if (props?.selectedaccount.credit > 0) {
      temp_cdflag = 1;
    }
    */

    // 27FEB2023
    // if Debit Note Fetch Credit Balances
    var payload = {
      domainrecno: auth.state.userdata.domainrecno,
      tenantrecno: auth.state.userdata.tenantrecno,
      code: props?.selectedaccount?.code,
      fortrn: 1,
      cdflag: 2, // Fetch Debit/// task id-244   rajendra cdflag should be 2 for debit 3note to get supplier 
    };

    getAccountlist(payload).then((data) => {
      if (data.Message[0]?.pendinginstruments) {
        console.log("getpendinginstruments");
        console.log(data.Message[0]?.pendinginstruments);
        const _instr = transformPendingInsutrments(
          data?.Message[0]?.pendinginstruments
        );

        // TODO Check if we alaready have pending instruments and discount is settled for those entries
        // 27JAN2023

        //let account_instruments_map = new Map();
        // No Need of Map here as this data will be temp stored
        // 30 Jan 2023

        //account_instruments_map.set(props?.selectedaccount?.code, _instr);

        tabstore.dispatch({
          type: "UPDATE_CHANGED_DATA",
          fieldType: "pendinginstruments",
          text: _instr,
        });

        setcurrentSettlmentAccount(_instr);
      }
    });
  }

  function removeDiscountGL(glcode) {
    let gl_disocunt_items = [];

    gl_disocunt_items =
      tabstore?.state?.tabs[tabstore?.state.tabIndex]?.changedRows[
        "gl_disocunt_items"
      ];

    const filterArrayGLDiscounts = gl_disocunt_items.filter(
      (item) => item.code != glcode
    );

    tabstore.dispatch({
      type: "UPDATE_CHANGED_DATA",
      fieldType: "gl_disocunt_items",
      text: filterArrayGLDiscounts,
    });
  }

  const TotalRow = () => {
    let temp_total_pending = 0;
    let temp_total_on_account = 0;
    let temp_total_gl_amount = 0;
    let temp_voucher_amount = 0;
    let temp_balance_on_account_amt = 0;

    temp_voucher_amount =
      tabstore?.state?.tabs[tabstore?.state.tabIndex]?.changedRows
        ?.VOUCHER_AMT_UPDATED;

    tabstore?.state?.tabs[
      tabstore?.state.tabIndex
    ]?.changedRows?.pendinginstruments?.forEach((item) => {
      temp_total_pending = Number(temp_total_pending) + Number(item.pendingamt);

      if (item.on_account_amount > 0) {
        temp_total_on_account =
          Number(temp_total_on_account) + Number(item.on_account_amount);
      }

      const other_gl_child = item.childrows;
      other_gl_child?.forEach((data) => {
        if (data.amount > 0) {
          temp_total_gl_amount =
            Number(temp_total_gl_amount) + Number(data.amount);
        }
      });
    });

    temp_balance_on_account_amt = Number(
      temp_voucher_amount - temp_total_on_account
    );

    return (
      <tr>
        <td
          colSpan={3}
          style={{ textAlign: "right", fontWeight: "bold", padding: 0 }}
        >
          Total{" "}
        </td>
        <td style={{ textAlign: "right", fontWeight: "bold", padding: 0 }}>
          {temp_total_pending}
        </td>

        <td style={{ textAlign: "right", fontWeight: "bold", padding: 1 }}>
          {temp_total_gl_amount}
        </td>
      </tr>
    );
  };

  function RenderTableRow(childrows) {
    return (
      <>
        {childrows.childrows.map((data) => (
          <tr key={data.code}>
            <td colSpan={4} style={{ textAlign: "right" }}>
              {data.descn}
            </td>
            <td style={{ textAlign: "right", padding: "0" }}>{data.amount}</td>
            <td style={{ textAlign: "right" }}>{}</td>
          </tr>
        ))}
      </>
    );
  }

  return (
    <div>
      <div>
        <table border="1" width={"100%"} className="tr023-pending-instrument">
          <tbody>
            <tr style={{ position: "sticky", top: "0", background: "#fff" }}>
              <td width="10%">Reference No.</td>
              <td width="5%">Credit Amount</td>
              <td width="5%">Debit Amount</td>
              <td width="5%">Balance Amount</td>
              <td width="5%">Adjusted Amount</td>
              <td width="5%">Action</td>
            </tr>

            {currentSettlmentAccount?.map((data) => (
              <>
                <tr key={data.recno}>
                  <td>{data.refno} </td>
                  <td style={{ textAlign: "right", padding: 0 }}>
                    {data.cramt}
                  </td>
                  <td style={{ textAlign: "right", padding: 0 }}>
                    {data.dramt}{" "}
                  </td>
                  <td style={{ textAlign: "right", padding: 0 }}>
                    {data.pendingamt}{" "}
                  </td>
                  <td style={{ textAlign: "right", padding: 0 }}>
                    <div style={{ textAlign: "right", padding: 0 }}> </div>
                  </td>
                  <td align="center">
                    <button
                      // type="button"
                      // value=""
                      style={{ padding: "4px", fontSize: "14px" }}
                      onClick={(e) => {
                        handleOpenGLDiscountPopup(data);
                      }}
                    >
                      Select Account
                    </button>
                  </td>
                </tr>
                {data.childrows.length > 0 ? (
                  <RenderTableRow childrows={data.childrows}></RenderTableRow>
                ) : (
                  <></>
                )}
              </>
            ))}
            <TotalRow />
          </tbody>
        </table>
      </div>

      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        PaperProps={{
          style: {
            minHeight: "90%",
            maxHeight: "90%",
            width: "70%",
            maxWidth: "90%",
          },
        }}
      >
        <DialogTitle>Account List</DialogTitle>
        <DialogContent>
          <div>
            <table className="tr023-account-list">
              <tbody>
                <tr>
                  <td>Ref No</td>
                  <td>Balance Amount</td>
                  <td>Adjusted Amount</td>
                  <td>Remaining Amount</td>
                </tr>
                <tr>
                  <td>{currentInstrumentSelectedForDiscount?.refno}</td>
                  <td style={{ textAlign: "right" }}>
                    {currentInstrumentSelectedForDiscount?.pendingamt}{" "}
                  </td>
                  <td style={{ textAlign: "right" }}>{0}</td>
                  <td style={{ textAlign: "right" }}>{0}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div
            style={{
              ...secondModalControlBox,
            }}
          >
            <SaveDropdown
              displayLabel={"Account"}
              alignment="column"
              id={"account1"}
              placeholder={"Search Account"}
              apiurl={getAccountlistURL}
              apikey={"discountgl"}
              multi={false}
              selectionLabel={"descn"}
              selectionValue={"code"}
              records={20}
              minInput={0}
              defaultValue={""}
              async
              returnValue={handleDiscountGL}
              width="30vw"
            />
            <div style={{ marginTop: "24px" }}>
              <input
                style={{
                  ...inputStyles,
                }}
                type="text"
                onChange={(e) => {
                  // HandleGLAmtUpdate(gl_data.code, e.target.value);
                  // handleAmtUpdate(e.target.value);
                  handleDiscountAmtUpdate(e.target.value);
                }}
              ></input>{" "}
              <Button type="primary" onClick={AddDiscountGL}>
                Add{" "}
              </Button>
            </div>
          </div>

          <table className="tr023-last-table">
            <tbody>
              <tr>
                <td>Code</td>
                <td width={"60%"}>Account Name</td>
                <td width={"10%"}>Amount</td>
                <td width={"10%"}>Delete</td>
              </tr>

              {tabstore?.state?.tabs[tabstore?.state.tabIndex]?.changedRows[
                "gl_disocunt_items"
              ]?.map((gl_data) => (
                <tr key={gl_data.code}>
                  <td>{gl_data.code}</td>
                  <td width={"60%"}>{gl_data.descn}</td>
                  <td align="right"> {gl_data.amount}</td>
                  <td align="center">
                    <Button
                      type="primary"
                      shape="circle"
                      onClick={() => {
                        removeDiscountGL(gl_data.code);
                      }}
                      icon={<DeleteOutlined />}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </DialogContent>
        <DialogActions>
          <Button type="primary" onClick={handleClose}>
            Cancel{" "}
          </Button>
          <Button type="primary" onClick={handleSaveGLDiscountPopup}>
            Save{" "}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default DebitNotePendingInsutrumentsTransaction;

const secondModalControlBox = {
  display: "flex",
  gap: "36px",
  margin: "24px 0",
  alignItems: "center",
  width: "fit-content",
};

const inputStyles = {
  textAlign: "right",
  width: "100px",
  height: "35px",
  border: "1px solid gray",
  borderRadius: "4px",
};

import { Modal, Input, Table, Button } from "antd";
import React, { useState, useContext, useEffect } from "react";
import "./Scan.css";
import { v4 as uuidv4 } from "uuid";
import { notification } from "antd";
import { RxCross2 } from "react-icons/rx";

import { TabStore } from "../../../TabsStructure/TabsStore";
import { AuthStore } from "../../../Auth/AuthStore";
import { datedisplay, dateFormatter } from "../../AppFunction";
import { getItemFromScannedBarcode } from "../../ApiCalls";
import SaveDropdown from "../SaveComponents/SaveDropdown";
import { getdomainlocationlistactive } from "../../Urls";
import useSaveChangedRows from "../../Hooks/useSaveChangedRows";

const ScanTable = ({
  data,
  openItem,
  setOpenItem,
  rowData,
  setRowData,
  renderer,
  setRenderer,
  setValidator,
  supplierrecno,
  forFlag,
  manuDate,
  pageDetails,
  saleNumber,
  forPage,
  changerow,
  pageIndex,
  trdate,
}) => {
  //~^ These values are calculated for the UI display only, no func can manipulate these values

  const [scanData, setScanData] = useState(data);
  const [serial, setSerialno] = useState(null);

  const [barcode, setBarcode] = useState("");
  const [barcodeData, setBarcodeData] = useState();
  const tabStore = useContext(TabStore);
 
  const useChangedRows = useSaveChangedRows();
  useEffect(() => {
    if (forFlag == "S") {
      setBarcodeData(scanData?.batcharray);
    } else if (
      (forFlag == "PR" && scanData.batcharray) ||
      (forFlag == "SR" && scanData.batcharray)
    ) {
      setBarcodeData(scanData?.batcharray);
    } else {
      setBarcodeData([]);
    }
  }, []);

  // Pradeep-362 to remove  tem data from scan barcode table
  // start

  // end

  const [acceptedBoxes, setAcceptedBoxes] = useState(
    scanData?.packingextraqtyaccepted ?? 0
  );

  const [acceptedPieces, setAcceptedPieces] = useState(
    scanData?.packingqtyaccepted ?? 0
  );
  const tabs = useContext(TabStore);
  const auth = useContext(AuthStore);

  useEffect(() => {
    setScanData(data);
  }, [data]);


  let carryOver = Math.floor(scanData?.packingqty / scanData?.conversionfactor);

  let boxes = Number(scanData?.packingextraqty) + Number(carryOver);



  let pieces = 0;

  let currentreturnqty = scanData?.currentreturnqtyNew
    ? scanData?.currentreturnqtyNew
    : scanData?.currentreturnqty;

  if (forFlag === "PR" || forFlag === "SR") {
    pieces = scanData?.returnfree
      ? Number(currentreturnqty) + Number(scanData?.returnfree)
      : Number(currentreturnqty);
  } else if (forFlag === "S") {
    pieces = scanData?.freeqty
      ? Number(scanData?.qty) + Number(scanData?.freeqty)
      : Number(scanData?.qty);
  } else {
    pieces = scanData?.free
      ? Number(scanData?.qty) + Number(scanData?.free)
      : Number(scanData?.qty);
  }

  // console.log("barcode:", barcode);
  let totalQty =
    Number(scanData?.packingextraqty) * Number(scanData?.conversionfactor) +
    Number(scanData?.packingqty) +
    scanData?.free
      ? Number(scanData?.free)
      : 0;

  useEffect(() => {
    if (scanData?.itembatcharray) {
      setBarcodeData(scanData?.itembatcharray);
    }
  }, [rowData]);

  const validateBoxesPieces = () => {
    let pieces;
    let carryOver;

    carryOver = Math.floor(barcodeData?.length / scanData?.conversionfactor);

    pieces =
      barcodeData?.length >= scanData?.conversionfactor
        ? Math.floor(barcodeData?.length % scanData?.conversionfactor)
        : barcodeData?.length;

    setAcceptedBoxes(carryOver);
    setAcceptedPieces(pieces);
  };

  //~% Calculating pieces addition func
  const calcQuantAdd = () => {
    //~! WARNING - conversion factor will always be whole positive int ( backend )
    if (acceptedPieces === scanData?.conversionfactor - 1) {
      setAcceptedPieces(0);
      setAcceptedBoxes((prev) => prev + 1);
    } else {
      setAcceptedPieces((prev) => prev + 1);
    }
  };

  //~% Calculating pieces deletion
  const calcQuantSub = () => {
    //~! Warning conversion factor will always be whole positive int ( backend )
    if (acceptedPieces === 0) {
      setAcceptedBoxes((prev) => prev - 1);
      setAcceptedPieces(scanData?.conversionfactor - 1);
    } else {
      setAcceptedPieces((prev) => prev - 1);
    }
  };

  const handleBarcodeInput = (event) => {
    const val = event.target.value;
    setBarcode(val);
  };

  /// scan table
  const handleBarcodeEnter = (event) => {
    if (barcode && event.keyCode === 13) {
      let checkDuplicate = barcodeData?.filter((e) => {
        const dateString = e.expdate;

        const month =
          dateString && dateString.length >= 6 ? dateString.substr(4, 2) : "";
        const yearSubstring =
          dateString && dateString.length >= 4 ? dateString.substr(0, 4) : "";
        const lastTwoDigitsOfYear = yearSubstring.slice(-2);

        if (dateString) {
          const bar = e.barcode + e.serialno + month + lastTwoDigitsOfYear;

          if (bar === barcode) {
            return e;
          }
        }
      });
      //let x=barcode.substr(10, 10)
      const serialNo = barcode.substr(10, 10);
      const isDuplicate = barcodeData?.some((e) => e.serialno == serialNo); /// for Date si page the barcode was not acceptinhg i added ? mark here check befor executing

      if (isDuplicate) {
        alert(`Serial number ${serialNo} already exists.`);
        setBarcode("");
        return;
      }

      if (checkDuplicate?.length > 0) {
        alert(`The barcode ${barcode} already exists/wrong barcode`);
        setBarcode("");
        // return true;
      } else if (
        barcode.length === scanData.barcodetotlength &&
        scanData.length == 0
      ) {
        let payload = {
          tenantrecno: auth.state.userdata.tenantrecno,
          domainrecno: auth.state.userdata.domainrecno,
          barcode: barcode,
          itemrecno: scanData?.itemrecno,
          forflag: forFlag,
        };

        if (forFlag == "P") {
          payload = { ...payload, supplierrecno: supplierrecno };
        }
        if (forFlag == "PR") {
          payload = {
            ...payload,
            supplierrecno: supplierrecno,
            purchasebillno: changerow?.refbillno,
          };
        } else if (forFlag == "S" || forFlag == "SR") {
          payload = {
            ...payload,
            customerrecno: supplierrecno,
            salebillno: saleNumber,
          };
        }

        if (forPage == "openingsi" || forPage == "openingpi") {
          payload = { ...payload, forflag: forPage };
        }

        getItemFromScannedBarcode(payload)
          .then((res) => {
            if (forFlag == "PR" || forFlag == "SR") {
              // Here we match the Serial Number with the Existing Array
            }

            if (forFlag == "SR" && res.Message[0].serialnofound) {
              alert("Item not found in Bill OR Item Already Returned");
              setBarcode("");
              return;
            }

            if (forFlag == "P" && res.Message[0]?.serialnofound) {
              alert("Item Already Exists in System");
              setBarcode("");
              return;
            }

            if (forFlag == "S" && !res.Message[0]?.serialnofound) {
              alert("Item Doesn't Exist in System");
              setBarcode("");
              return;
            }

            // if (
            //   forFlag == "S" &&
            //   res.Message[0]?.itembatcharrayfound[0]?.itembatchno !==
            //   scanData.itembatchno
            // ) {
            //   alert("Item Belongs to Different Batch");
            //   setBarcode("");
            //   return;
            // }
            if (
              forFlag == "S" &&
              res.Message[0]?.itembatcharrayfound[0]?.itembatchno !==
                scanData.itembatchno
            ) {
              alert("Stock is not Available"); // alert message change  date 19april 2023 by Rbn
              setBarcode("");
              return;
            }
            if (
              forFlag == "PR" &&
              res.Message[0]?.itembatcharrayfound[0]?.itembatchno !==
                scanData.itembatchno
            ) {
              alert("Stock is not Available"); // alert message change  date 19april 2023 by Rbn
              setBarcode("");
              return;
            }
            if (
              res?.Message[0].itemrecno !== scanData?.itemrecno &&
              res?.Success !== false
            ) {
              alert("Item Belongs to Different Batch");
            }

            if (
              res?.Message[0].itemrecno === scanData?.itemrecno &&
              res?.Success !== false
            ) {
              calcQuantAdd();
              setBarcode("");
              let expdate = res.Message[0]?.entryexpdate;
              if (expdate) {
                if (!pageDetails?.expdatemonthend)
                  expdate = datedisplay(expdate, true).dataDate;
                else expdate = datedisplay(expdate).dataDate;
                var exp = Experidate(dateFormatter(expdate));
              }

              setBarcodeData([
                {
                  barcode: res.Message[0]?.entrybarcode,
                  id: uuidv4(),
                  manudate: res.Message[0]?.entrymanudate
                    ? res.Message[0]?.entrymanudate
                    : 0,
                  serialno: res.Message[0]?.entryserialno,
                  expdate: exp,
                  currentreturnqty: data?.returnqty,
                },
                ...barcodeData,
              ]);
            } else if (res?.Success == false) {
              alert(res?.Message);
            }
          })
          .catch((e) => {
            alert(e?.response?.data?.Error);
            setBarcode("");
          });
      } else if (barcode.length === scanData.barcodetotlength) {
        let payload = {
          tenantrecno: auth.state.userdata.tenantrecno,
          domainrecno: auth.state.userdata.domainrecno,
          barcode: barcode,
          itemrecno: scanData?.itemrecno,
          forflag: forFlag,
        };
        if (forFlag == "P") {
          payload = { ...payload, supplierrecno: supplierrecno };
        }
        if (forFlag == "PR") {
          payload = {
            ...payload,
            supplierrecno: supplierrecno,
            purchasebillno: changerow?.refbillno,
          };
        } else if (forFlag == "S" || forFlag == "SR") {
          payload = {
            ...payload,
            customerrecno: supplierrecno,
            salebillno: saleNumber,
          };
        }

        getItemFromScannedBarcode(payload)
          .then((res) => {
            if (forFlag == "SR" && !res.Message[0]?.serialnofound) {
              alert("Item not found in Bill OR Item Already Returned"); // masssg change for allert purpose
              setBarcode("");
              return;
            }
            if (forFlag == "PR" && !res.Message[0]?.serialnofound) {
              alert("Item Already Returned or Not Available to Return");
              setBarcode("");
              return;
            }
            if (
              ((forFlag == "PR" && res.Message[0]?.serialnofound) ||
                (forFlag == "SR" && !res.Message[0]?.serialnofound)) &&
              res.Message[0] &&
              scanData?.batcharray?.length > 0
            ) {
              console.log("Scanned data", res);

              const bill = res.Message[0]?.itembatcharrayfound[0];
              const sidate = bill?.billdate;
              const dcdate = bill?.inwarddate;
              if (
                (sidate && sidate > Number(trdate)) ||
                (dcdate && dcdate > Number(trdate))
              ) {
                notification.error({
                  message: `Error`,
                  duration: 1,
                  description: `PR date less than PI/LatestInward date`,
                  style: { background: "#efa7a7" },
                });
                setBarcode("");
                return;
              }
              // const prdate = new Date()
              // Here we match the Serial Number with the Existing Array

              let filterArray = [];
              if (scanData?.billbatcharray) {
                filterArray = scanData?.billbatcharray?.filter(
                  (item) => item.serialno == res.Message[0].entryserialno
                );
              } else {
                filterArray = scanData?.batcharray?.filter(
                  (item) => item.serialno == res.Message[0].entryserialno
                );
              }

              if (filterArray?.length <= 0) {
                alert("Item Not Found In the Bill");
                setBarcode("");
                return;
              }
            }
            if (forFlag == "P" && res.Message[0]?.serialnofound) {
              alert("Item Already Exists in System");
              setBarcode("");
              return;
            }

            if (forFlag == "S" && !res.Message[0]?.serialnofound) {
              alert("Item Doesnt' Exist in System");
              setBarcode("");
              return;
            }

            if (
              forFlag == "S" &&
              res.Message[0]?.itembatcharrayfound[0]?.itembatchno !==
                scanData.itembatchno
            ) {
              alert("Stock is not Available"); // alert message change  date 19april 2023 by Rbn
              setBarcode("");
              return;
            }
            //// this
            if (
              forFlag == "PR" &&
              res.Message[0]?.itembatcharrayfound[0]?.itembatchno !==
                scanData.itembatchno
            ) {
              alert("Stock is not Available"); // alert message change  date 19april 2023 by Rbn
              setBarcode("");
              return;
            }

            if (
              res?.Message[0].itemrecno === scanData?.itemrecno &&
              res?.Success !== false
            ) {
              calcQuantAdd();
              setBarcode("");
              let expdate = res.Message[0]?.entryexpdate;
              if (expdate) {
                if (!pageDetails?.expdatemonthend)
                  expdate = datedisplay(expdate, true).dataDate;
                else expdate = datedisplay(expdate).dataDate;

                var exp = Experidate(dateFormatter(expdate));
              }

              if (barcodeData) {
                //// Rajendra Si here the barcode is not accepting start
                setBarcodeData([
                  {
                    barcode: res?.Message[0]?.entrybarcode,
                    id: uuidv4(),
                    manudate: res?.Message[0]?.entrymanudate,
                    serialno: res?.Message[0]?.entryserialno,
                    expdate: exp,
                    currentreturnqty: data?.returnqty,
                  },
                  ...barcodeData,
                ]);
              } else {
                setBarcodeData([
                  {
                    barcode: res?.Message[0]?.entrybarcode,
                    id: uuidv4(),
                    manudate: res?.Message[0]?.entrymanudate,
                    serialno: res?.Message[0]?.entryserialno,
                    expdate: exp,
                    currentreturnqty: data?.returnqty,
                  },
                ]);
              } //// Rajendra Si here the barcode is not accepting end here if and else condition maintain
            } else if (res?.Success == false) {
              console.log("success", res);
              alert(res?.Message);
            }
          })
          .catch((e) => {
            console.log("Error is==== ", e);
            alert(e?.response?.data?.Error);
            setBarcode("");
          });
      } else {
        setBarcode("");
        alert("Invalid Input");
      }
    }
  };
  const handleCloseModal = () => {
    // Assuming pageIndex is defined

    setOpenItem(false);
  
    RemoveLocation();
  
  };

  const RemoveLocation = () => {
    const { tabs } = tabStore.state;
    const tab = tabs[pageIndex]?.changedRows;
    const updatedTab = { ...tab };

    Object.keys(updatedTab).forEach((key) => {
      if (key.includes("locationrecno")) {
        delete updatedTab[key];
      }
    });

    // Dispatching the updated state to the reducer

    useChangedRows?.updateValue("", updatedTab);
    //tabStore.dispatch({ type: 'UPDATE_DATA', text: updatedTab });
  };
  console.log("updatedBarcodeData",tabStore)
  const handleOk = () => {

    const updatedBarcodeData = barcodeData?.map((item) => {
      if (item.expdate) {
        return {
          ...item,
          expdate: Experidate(dateFormatter(item.expdate)),
        };
      }
      return item;
    });

    const appendBarcodeData = rowData?.map((item) => {
      // item?.itemrecno === scanData?.itemrecno //Changed By Kaushik Koparkar 2/04/2023

      return item?.shortguid == scanData?.shortguid
        ? {
            ...item,
            itembatcharray: updatedBarcodeData,
            packingqtyaccepted: acceptedPieces,
            packingextraqtyaccepted: acceptedBoxes,
          }
        : item;
    });

    setRowData(appendBarcodeData);

    setOpenItem(false);
    setValidator(appendBarcodeData[0]?.itembatcharray?.length);
    setRenderer(() => uuidv4());

    RemoveLocation();
  };

  const handleBarcodeDelete = (code) => {
    //calcQuantSub();
    const filteredData = barcodeData?.filter(
      (barcode) => barcode.serialno !== code.serialno /// task id -367 rajendra delete problem
    );
    setBarcodeData(filteredData);

    // const appendBarcodeData = rowData?.map((item) =>
    //   item?.itemrecno === scanData?.itemrecno
    //     ? {
    //         ...item,
    //         barcodeData: filteredData,
    //         packingqtyaccepted: acceptedPieces,
    //         packingextraqtyaccepted: acceptedBoxes,
    //       }
    //     : item
    // );

    // console.log("TR003 Scan appendata ===>", appendBarcodeData);
    // setRowData(appendBarcodeData);
  };

  useEffect(() => {
    if (barcodeData?.length === 0) {
      setAcceptedBoxes(0);
      setAcceptedPieces(0);
    } else {
      validateBoxesPieces();
    }
  }, []);

  ///// here experidte function
  const Experidate = (dateString) => {
    var parts = dateString.split("/");
    var day = parts[0];
    var month = parts[1];
    var year = parts[2];

    // Pad day and month with leading zeros if necessary
    if (day.length === 1) {
      day = "0" + day;
    }
    if (month.length === 1) {
      month = "0" + month;
    }

    return year + month + day;
  };
  const handleLocationChange = (selectedLocation, _barcode) => {
    const updatedBarcodeData = barcodeData.map((barcode) => {
      // Check if serialno matches with the provided _barcode
      if (barcode.serialno === _barcode.serialno) {
        return {
          ...barcode,
          //location: selectedLocation,
          locationrecno: selectedLocation?.locationrecno,
        };
      }
      return barcode;
    });

    setBarcodeData(updatedBarcodeData);
  };

  return (
    <div>
      <Modal
        open={openItem}
        maskClosable={false}
        onCancel={handleCloseModal}
        onOk={handleOk}
        width={786}
        style={{ top: 0 }}
        title={scanData?.label ?? scanData?.itemdescn}
        closable={false}
      >
        <div className="itemsMainBox">
          <table className="singleItemData">
            <thead>
              <tr>
                <th>Item</th>
                {/* <th>Boxes</th> */}
                <th>Quantity</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{scanData?.label ?? scanData?.itemdescn}</td>
                {/* <td>{boxes}</td> */}
                <td>{pieces}</td>
              </tr>
              <tr>
                <td>
                  <div style={{ flex: 1 }}>
                    <Input
                      placeholder="Enter Barcode"
                      size="large"
                      value={barcode}
                      onChange={handleBarcodeInput}
                      onKeyUp={(event) => handleBarcodeEnter(event)}
                      disabled={Number(pieces) === barcodeData?.length}
                    />
                  </div>
                </td>
                {/* <td>
                  <div style={{ ...accAssest }}>AR40E00165AKw6Ill0100234
                    <p>Accepted Boxes</p>
                    <h2>{acceptedBoxes}</h2>
                  </div>
                </td> */}
                <td>
                  <div style={{ ...accAssest }}>
                    <p>Accepted Quantity</p>
                    <h2>{barcodeData?.length}</h2>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>

          <div className="barcodeTableWrapper">
            {barcodeData?.length > 0 && (
              <table className="barcodeTable">
                <thead>
                  <tr>
                    {pageDetails?.wantdomainlocation ? <th>Location</th> : null}
                    <th>Barcode</th>
                    <th>Serial No</th>
                    {/* <th>Mfg Date</th> */}
                    {/*Kaushik :  Commented for Task number 313, acctualy task was to hide the column */}
                    <th>Exp Date</th>
                    <th>Delete</th>
                  </tr>
                </thead>
                <tbody>
                  {barcodeData?.map((_barcode, i) => {
                    //accepting barcode from both itemscan and dc edit
                    const itembarcode = _barcode?.barcode
                      ? _barcode?.barcode
                      : scanData?.itembarcode;

                    return (
                      <tr>
                        {pageDetails?.wantdomainlocation ? (
                          <td key={i}>
                            <SaveDropdown
                              // defaultValue={location}
                              //{Pradeep task id-398 }/// added isrequired prop
                              defaultSelected={
                                barcode?.location?.locationrecno
                                  ? barcode?.location?.locationrecno
                                  : _barcode.locationrecno
                                // 131
                              }
                              zIndex={1000}
                              // disabled={rowData?.length > 0 || transactionEdit ? true : false}
                              apikey={"locationrecno" + i}
                              minInput={0}
                              inputValue={20}
                              displayLabel={"Locn"}
                              placeholder={"Locn"}
                              apiurl={getdomainlocationlistactive}
                              selectionLabel={"descn"}
                              selectionValue={"locationrecno"} //# previous key was recno, changed to locationrecno as per network response
                              returnValue={(selectedLocation) =>
                                handleLocationChange(selectedLocation, _barcode)
                              }
                              width={"170px"}
                              alignment="column"
                            />
                          </td>
                        ) : (
                          ""
                        )}
                        <td>{itembarcode}</td>
                        <td>{_barcode?.serialno}</td>
                        {/* <td>{dateFormatter(_barcode?.mfgdate?_barcode?.mfgdate:manuDate)}</td>   */}{" "}
                        {/*Kaushik :  Commented for Task number 313, acctualy task was to hide the column */}
                        <td>{dateFormatter(_barcode?.expdate)}</td>
                        <td>
                          <Button
                            size="medium"
                            danger
                            type="primary"
                            shape="circle"
                            onClick={() => handleBarcodeDelete(_barcode)}
                          >
                            <RxCross2 style={{ margin: "4px 0 0 1px" }} />
                          </Button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ScanTable;

const barcodeIp = {
  // width: "46%",
  margin: "18px 0",
  display: "flex",
  gap: "24px",
};

const accAssest = {
  display: "flex",
  alignItems: "center",
  boxSizing: "border-box",
  gap: "12px",
};

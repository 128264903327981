import { useState, useEffect, useContext } from "react";
import { AuthStore } from "../../Auth/AuthStore";
import { TabStore } from "../../TabsStructure/TabsStore";

const useSaveChangedRows = (url) => {
    const auth = useContext(AuthStore)
    const tabStore = useContext(TabStore)
    const getValue = (key, pageIndex) => {

        var currpageIndex = pageIndex>=0 ? pageIndex : tabStore.state.tabIndex
      
        if (key) {
            if (tabStore.state.tabs[currpageIndex]?.changedRows) {
                if (tabStore.state.tabs[currpageIndex]?.changedRows.hasOwnProperty(key)) {

                    return tabStore.state.tabs[currpageIndex]?.changedRows[key]
                } else {
                    return null
                }
            } else {
                return null
            }
        } else {
            return tabStore.state.tabs[currpageIndex]?.changedRows
        }
    }

    const updateValue = (key, value) => {

      
        // Need to take the key and store the value with that  key in the tab Store
        //  If the Tabs in Multi-level need to update the store using map function

        if (key) {
            
            tabStore.dispatch({type:"UPDATE_CHANGED_DATA",fieldType: key, text:value})
        } else {
            tabStore.dispatch({type:"UPDATE_DATA",fieldType: "changedRows", text:value})
        }
    }

    return {updateValue, getValue}
};

export default useSaveChangedRows;
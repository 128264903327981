import { Spin, Table } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { grnColumns } from "./ReturnableDCTableColumn";
import { TabStore } from "../../../TabsStructure/TabsStore";

const ReturnableDCItemsTable = ({ item, setRowData, rowData, pageDetails }) => {
  const [showTable, setShowTable] = useState(true);
  const tabStore = useContext(TabStore);

  useEffect(() => {
    console.log(`tr001 items table re-rendered ?? after deleting`);
    setShowTable(false);

    setTimeout(() => {
      setShowTable(true);
    }, 200);
  }, [
    rowData?.length,
    tabStore.state.tabs[tabStore.state.tabIndex]?.editIndex,
  ]);

  return (
    <>
      <div>
        {showTable ? (
          <Table
            scroll={{
              y: 500,
            }}
            size="large"
            className="tr001-purchaseOrder-itemsTable"
            bordered
            pagination={false}
            columns={grnColumns(item, setRowData, rowData, pageDetails)?.filter(
              (item) => !item.hidden
            )}
            dataSource={rowData}
            summary={(pageData) => {
              let quantity = 0;
              let itemAmount = 0;

              pageData.forEach((item) => {
                quantity = Number(quantity) + Number(item?.qty);
                itemAmount = Number(itemAmount) + Number(item?.amount);
              });

              return (
                <Table.Summary>
                  {
                    <Table.Summary.Row>
                      <Table.Summary.Cell></Table.Summary.Cell>
                      <Table.Summary.Cell>
                        <p style={{ ...pStyles, textAlign: "left" }}>Total :</p>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell>
                        <p style={{ ...pStyles }}>{quantity}</p>
                      </Table.Summary.Cell>
                      {pageDetails?.wantbatchno ? (
                        <Table.Summary.Cell></Table.Summary.Cell>
                      ) : null}
                      <Table.Summary.Cell>
                        <p style={{ ...pStyles, textAlign: "center" }}>
                          {Number(itemAmount).toFixed(2)}
                        </p>
                      </Table.Summary.Cell>
                    </Table.Summary.Row>
                  }
                </Table.Summary>
              );
            }}
          />
        ) : (
          <div style={{ ...loaderStyle }}>
            <Spin size="large" />
          </div>
        )}
      </div>
    </>
  );
};

export default ReturnableDCItemsTable;

const loaderStyle = {
  height: "max-content",
  width: "max-content",
  display: "flex",
  alignContent: "center",
  margin: "50px auto",
};

const pStyles = {
  textAlign: "right",
  fontWeight: "700",
  fontSize: "16px",
  color: "black",
};

import React, { useEffect, useState } from "react";
import ItemSearch from "../../CommonControllers/ItemController/ItemSearch";
import InputComponent from "../../CommonControllers/Components/InputComponent";
//import SupplierSaveDropDown from "../../CommonControllers/SaveComponents/HigherLevel/SupplierSaveDropDown";

import SupplierSaveDropDown from "../../CommonControllers/SaveComponents/HigherLevel/SupplierSaveDropDown";
import { Input, Button, Typography, Table } from "antd";
import { useContext } from "react";
import { TabStore } from "../../TabsStructure/TabsStore";
import { dateFormatter } from "../../CommonControllers/AppFunction";
import ItemSelectionModal from "../../CommonControllers/ItemController/ItemSelectionModal";
import ItemSelection from "./Itemissuecomponet";

const ItemIssue = ({ item }) => {
  const tabstore = useContext(TabStore);
  const [arr, setArr] = useState([]);
  const [getSelectedItem, setSelectedItem] = useState(null);
  const [supplierdata, setSupplierdata] = useState(null);
  const [count, setCount] = useState(0);
  const [docs, setDocs] = useState("");
  const [description, setDescription] = useState("");

  const [defcode, setDefcode] = useState(null);
  const [itemshortdescn, setItemshortdescn] = useState(null);
  const [refdoc, setrefdoc] = useState("");
  const [de, setDen] = useState([]);
  const [selectedItem, setSeleItem] = useState(null);
  const [batch, setBatch] = useState("");

  function setDatatoSupplierdata(e) {
    setSupplierdata(e);
  }
  console.log("dta", de);
  useEffect(() => {
    setDefcode(getSelectedItem);
  }, [getSelectedItem, defcode][(getSelectedItem, itemshortdescn)]);

  // let rows=tabstore.state.tabs[0].changedRows


  //  useEffect(() => {
  //   let x = tabstore?.state?.tabs[0]?.changedRows;
  //   setData(x);
  // }, [tabstore?.state?.tabs[0].changedRows]);
  useEffect(() => {
    console.log("getSelectedItem111", de);

    de.descn = description;
    de.refrecno = "122";
    de.itemrecno = getSelectedItem?.itemrecno;
    de.instrument = "Item Issue";
    // var p = arr.filter((el) => {
    //   return el.length !== 0;
    // });

    setArr([...arr, de]);
  }, [de]);


  const handleClear = () => {
    setCount("");
    setBatch("");
    setrefdoc("");
    setDescription("");

  };

  
  console.log("asytadr", arr);
  const handleSave = () => {
    console.log("re", arr);

    arr.shift();
 

    // arr.shift()
    tabstore.dispatch({
      type: "UPDATE_DATA",
      fieldType: "changedRows",
      text: arr,
    });

    handleClear()    
  };



  const handlechange = (e) => {
    setCount(e.target.value);
  };

  // function for getting ref docs
  const handleref = (e) => {
    setDocs(e.target.value);
  };

  const handledesn = (e) => {
    setDescription(e.target.value);
  };
  const handledocs = (e) => {
    setrefdoc(e.target.value);
  };

  const handlebatch = (e) => {
    setBatch(e.target.value);
  };

  console.log("handle", de);

  return (
    <div>
      <ItemSearch
        forFlag={"D"}
        returnValue={setSelectedItem}
        supplierdata={supplierdata?.supplierrecno}
        // selection={true}
        //batchdetails={true}
        dropdown
      />

      {getSelectedItem ? (
        <>
          <div
            style={{
              marginLeft: "40px",
              display: "flex",
              flexDirection: "row",
              gap: "40px",
              marginBottom: "10px",
            }}
          >
            <Typography style={{ display: "flex", flexDirection: "column" }}>
              {" "}
              Item Code
              <Input
                style={{
                  width: "150px",
                  flexDirection: "column",
                  marginTop: "5px",
                }}
                defaultValue={defcode?.itemcode}
                value={defcode?.itemcode}
              />
            </Typography>
            <Typography style={{ display: "flex", flexDirection: "column" }}>
              Item Description
              <Input
                style={{ width: "400px", marginTop: "5px" }}
                defaultValue={defcode?.itemdescn}
                value={defcode?.itemdescn}
              />
            </Typography>
            <Typography style={{ display: "flex", flexDirection: "column" }}>
              Item Short Description
              <Input
                style={{ width: "200px", marginTop: "5px" }}
                defaultValue={defcode?.itemshortdescn}
                value={defcode?.itemshortdescn}
              />
            </Typography>
          </div>

          <div
            style={{
              marginLeft: "40px",
              display: "flex",
              flexDirection: "row",
              gap: "40px",
              marginBottom: "80px",
              marginTop: "20px",
            }}
          >
            <br />
            <span>
              Reference Document Number
              <Input
                type="text"
                onChange={handledocs}
                value={refdoc}
                style={{
                  height: "30px",
                  width: "200px",
                  borderRadius: "5px",
                  marginLeft: "2%",
                  marginTop: "10px",
                }}
              />
            </span>
            <br />
            <span>
              Description
              <Input
                type="text"
                onChange={handledesn}
                value={description}
                style={{
                  height: "30px",
                  width: "400px",
                  borderRadius: "5px",
                  marginLeft: "3.5%",
                  marginTop: "10px",
                }}
              />
            </span>
          </div>

          <div>
            <div style={{ boder: "1px solid red" }}>
              <ItemSelection
                scroll={{ y: 150 }}
                setDens={setDen}
                //  item={selectedItem}
                // forFlag={forFlag}
              />
            </div>
          </div>

          {/* </div> */}
          <div style={{ marginBottom: "70px" }}>
            <Button
              onClick={handleSave}
              style={{ marginLeft: "8%", paddingBottom: "20px" }}
              type="primary"
            >
              Proceed
            </Button>
          </div>
        </>
      ) : null}
    </div>
  );
};

export default ItemIssue;

//~# Majid Sayyed - 02/03/2023
import { Button, Popconfirm } from "antd";
import { AiFillDelete } from "react-icons/ai";
import { removeByAttribute } from "../../CommonControllers/AppFunction";
import "../../CommonControllers/global.css";

export const poColumns = (igstappl, setRowData, rowData, pageDetails) => {
 
  if (igstappl) {
    return [
      {
        title: " ",
        dataIndex: "active",
        key: "active",
        width: "4%",
        render: (_, text) => {
          return (
            <Popconfirm
              title="Are you sure?"
              onConfirm={(e) => {
                handleRemove(text, setRowData, rowData);
              }}
            >
              <Button icon={<AiFillDelete />}></Button>
            </Popconfirm>
          );
        },
      },
      {
        title: "Item Description",  //{/* Pradeep task id -398  */} change title name from Item to  Item Description
        dataIndex: "itemdescn",
        key: "itemdescn",
        width: "max-content",
        align: "left", // nohit-388 set Align for column
      },
      {
        hidden: !pageDetails?.wantpackingextra ? true : false,
        title: "Boxes",
        dataIndex: "box",
        key: "box",
        width: "8%",
        align: "right",
        render: (_, text) => {
          return (
            <input
              className="tr001Input"
              defaultValue={text?.packingextraqty}
              onFocus={(e) => e.target.select()}
              //{Pradeep, task id -272}
              disabled={text.packingextrarecno > 0 ? false : true}
              onChange={(e) => {
                // if (e.target.value < 0) {
                //   e.target.value = null;
                //   alert("Invalid Input, please re-enter non-negative value");
                //   return false;
                // }
                const regex = /^[0-9\b]+$/;
                // if (e.target.value === "" || regex.test(e.target.value)) {
                //   text.packingextraqty = e.target.value;
                //   handleChange(text, setRowData, igstappl, rowData);
                // }

                //new validation of zero qty
                if (regex.test(e.target.value) && (Number(e.target.value) + Number(text.packingqty)) > 0) {
                  text.packingextraqty = e.target.value;
                  handleChange(text, setRowData, igstappl, rowData);
                } else {
                  e.target.value = text.packingextraqty
                  alert("Box and Pieces cannot be 0")
                }
              }}
            />
          );
        },
      },
      {
        title: "Pieces",
        dataIndex: "qty",
        key: "qty",
        width: "8%",
        render: (_, text) => {
          return (
            <input
              //{Pradeep, task id -272}
              //disabled={text.packingextrarecno==0? false:true} /// rajendra may29
              className="tr001Input"
              defaultValue={text.packingqty}
              onFocus={(e) => e.target.select()}
              onChange={(e) => {
                // if (e.target.value < 0) {
                //   e.target.value = null;
                //   alert("Invalid Input, please re-enter non-negative value");
                //   return false;
                // }
                const regex = /^[0-9\b]+$/;

                // if (e.target.value === "" || regex.test(e.target.value)) {
                //   text.packingqty = e.target.value;
                //   handleChange(text, setRowData, igstappl, rowData);
                // }

                if (regex.test(e.target.value) && (Number(e.target.value) + Number(text.packingextraqty)) > 0) {
                  text.packingqty = e.target.value;
                  handleChange(text, setRowData, igstappl, rowData);
                } else {
                  e.target.value = text.packingqty
                  alert("Box and Pieces cannot be 0")
                }


              }}
            ></input>
          );
        },
        align: "right",
      },
      {
        hidden: !pageDetails?.wantpackingextra ? true : false,
        title: "Box Rate",
        dataIndex: "boxpurrate",
        key: "boxpurrate",
        width: "8%",
        align: "right",
      },
      {
        title: "Rate",
        dataIndex: "salerate",
        key: "salerate",
        width: "8%",
        // render: (_, text) => {
        //   return (
        //     <input
        //       className="tr001Input"
        //       defaultValue={text.salerate}
        //       onFocus={(e) => e.target.select()}
        //       onChange={(e) => {
        //         if (e.target.value < 0) {
        //           e.target.value = null;
        //           alert("Invalid Input, please re-enter non-negative value");
        //           return false;
        //         }
        //         const regex = /^\d*(\.\d{0,2})?$/;
        //         if (e.target.value === "" || regex.test(e.target.value)) {
        //           text.salerate = e.target.value;
        //           handleChange(text, setRowData, igstappl, rowData);
        //         }
        //       }}
        //     ></input>
        //   );
        // },
        align: "right",
      },
      {
        title: "Taxable",
        dataIndex: "taxableamount",
        key: "taxableamount",
        align: "right",
        width: "9%",
      },
      {
        title: "IGST%",
        dataIndex: "igstrate",
        key: "igstrate",
        align: "right",
        width: "8%",
      },
      {
        title: "IGST",
        dataIndex: "igstamt",
        key: "igstamt",
        align: "right",
        width: "8%",
      },
      {
        title: "Amount",
        dataIndex: "amount",
        key: "amount",
        align: "right",
        width: "8%",
      },
    ];
  } else {
    return [
      {
        title: " ",
        dataIndex: "active",
        key: "active",
        width: "4%",
        render: (_, text) => {
          return (
            <Popconfirm title="Are you sure?" onConfirm={() => handleRemove(text, setRowData, rowData)}>
              <Button icon={<AiFillDelete />}></Button>
            </Popconfirm>
          );
        },
      },
      {
        title: "Item Description", // nohit-425 change title Item to Item Description
        dataIndex: "itemdescn",
        key: "itemdescn",
        width: "max-content",
      },
      {
        hidden: !pageDetails?.wantpackingextra ? true : false,
        title: "Boxes",
        dataIndex: "box",
        key: "box",
        width: "8%",
        align: "right",
        render: (_, text) => {
          return (
            <input
              className={`tr001Input tr001Box`}
              //{Pradeep, task id -272}
              disabled={text.packingextrarecno > 0 ? false : true}
              defaultValue={text?.packingextraqty}
              type="number"
              onFocus={(e) => e.target.select()}
              onChange={(e) => {
                if (e.target.value < 0) {
                  // document.querySelector(`.${text?.shortguid}`).value = null;
                  e.target.value = null;
                  // alert("Invalid Input, please re-enter non-negative value");
                  return false;
                }
                const regex = /^[0-9\b]+$/;
                if (e.target.value === "" || regex.test(e.target.value)) {
                  text.packingextraqty = e.target.value;
                  handleChange(text, setRowData, igstappl, rowData);
                }
              }}
            />
            // <p>{text.box}</p>
          );
        },
      },
      {
        title: "Pieces",
        dataIndex: "qty",
        key: "qty",
        width: "8%",
        render: (_, text) => {
          return (
            <input
              className={`tr001Input tr001Pieces`}
              //{Pradeep, task id -272}
              //disabled={text.packingextrarecno==0? false:true}/// rajendra may 29
              defaultValue={text.packingqty}
              onFocus={(e) => e.target.select()}
              type="number"
              onChange={(e) => {
                if (e.target.value < 0) {
                  e.target.value = null;
                  // alert("Invalid Input, please re-enter non-negative value");
                  return false;
                }
                const regex = /^[0-9\b]+$/;
                if (e.target.value === "" || regex.test(e.target.value)) {
                  text.packingqty = e.target.value;
                  handleChange(text, setRowData, igstappl, rowData);
                }
              }}
            />
            // <p>{text.qty}</p>
          );
        },
        align: "right",
      },
      {
        hidden: !pageDetails?.wantpackingextra ? true : false,
        title: "Box Rate",
        dataIndex: "boxpurrate",
        key: "boxpurrate",
        width: "8%",
        align: "right",
      },
      {
        title: "Rate",
        dataIndex: "salerate",
        align: "right",
        key: "salerate",
        width: "8%",
        // render: (_, text) => {
        //   return (
        //     <input
        //       className={`tr001Input`}
        //       defaultValue={text.salerate}
        //       onFocus={(e) => e.target.select()}
        //       type="number"
        //       onChange={(e) => {
        //         if (e.target.value < 0) {
        //           // document.querySelector(`.${text?.shortguid}`).value = null;
        //           e.target.value = null;
        //           // alert("Invalid Input, please re-enter non-negative value");
        //           return false;
        //         }
        //         text.salerate = e.target.value;
        //         handleChange(text, setRowData, igstappl, rowData);
        //       }}
        //     />
        //     // <p>{text.purrate}</p>
        //   );
        // },
      },
      {
        title: "Taxable",
        dataIndex: "taxableamount",
        key: "taxableamount",
        align: "right",
        width: "9%",
      },
      {
        title: "CGST%",
        dataIndex: "cgstrate",
        key: "csgtrate",
        align: "right",
        width: "6%",
      },
      {
        title: "CGST",
        dataIndex: "cgstamt",
        key: "cgstamt",
        align: "right",
        width: "6%",
      },
      {
        title: "SGST%",
        dataIndex: "sgstrate",
        key: "sgstrate",
        align: "right",
        width: "6%",
      },
      {
        title: "SGST",
        dataIndex: "sgstamt",
        key: "sgstamt",
        align: "right",
        width: "6%",
      },
      {
        title: "Amount",
        dataIndex: "amount",
        key: "amount",
        align: "right",
        width: "6%",
      },
    ];
  }
};

export const handleAdd = (item, setRowData, igstappl, setSelectedItem, shortguid, purchaseRate, purchaseQty, box) => {
  if (item?.packingextraqty < 0 || item?.packingqty < 0 || item?.salerate < 0) {
    alert("Cannot enter 0");
    return;
  }
  console.log("igstappl1133", igstappl)
  //~# Input boxes validation
  const boxValue = document.querySelector("tr001-input-box");
  const piecesValue = document.querySelector("tr001-input-pieces");
  const rateValue = document.querySelector("tr001-input-rate");

  console.log("tr012 handle add  ==>", item, boxValue, piecesValue, rateValue);

  var newShortguid = shortguid.getShortguid("POF" + item.itemrecno);
  console.log("tr012 Added Shortguid", newShortguid, box, purchaseQty);
  var salerate = purchaseRate ? purchaseRate : 0.0;
  var cgstrate = item?.cgstrate ? item.cgstrate : 0.0;
  var sgstrate = item?.sgstrate ? item.sgstrate : 0.0;
  var igstrate = item?.igstrate ? item.igstrate : 0.0;
  var boxpurrate = Number(salerate) * Number(item?.conversionfactor);
  var packingqty = purchaseQty ? purchaseQty : 0; //~# here qty of pieces changed to key-name packingqty, box will be freepackingqty
  var packingextraqty = box ? box : 0; //~# box value
  var totalQty = Number(packingextraqty) * Number(item?.conversionfactor) + Number(packingqty);

  var taxableamount = (Number(packingextraqty) * Number(item?.conversionfactor) + Number(packingqty)) * Number(salerate);
  var sgstamt = !igstappl ? taxableamount * (sgstrate / 100) : 0.0;
  var cgstamt = !igstappl ? taxableamount * (cgstrate / 100) : 0.0;
  var igstamt = igstappl ? taxableamount * (igstrate / 100) : 0.0;

  cgstamt = cgstamt?.toFixed(2);
  sgstamt = sgstamt?.toFixed(2);
  igstamt = igstamt?.toFixed(2);

  var amount = igstappl ? taxableamount + Number(igstamt) : taxableamount + (Number(cgstamt) + Number(sgstamt));

  var itemObject = {
    ...item,
    shortguid: newShortguid,
    itemrecno: item.itemrecno,
    itemdescn: item.itemdescn,
    packingextraqty: +box, //~# here box is the value coming from input Boxes
    packingqty: +packingqty, //~# here packingqty is the pieces value coming from Pieces input
    qty: totalQty,
    salerate: Number(salerate)?.toFixed(2),
    taxableamount: Number(taxableamount)?.toFixed(2),
    cgstrate: Number(cgstrate).toFixed(2),
    sgstrate: Number(sgstrate)?.toFixed(2),
    igstrate: Number(igstrate)?.toFixed(2),
    cgstamt: Number(cgstamt)?.toFixed(2),
    sgstamt: Number(sgstamt)?.toFixed(2),
    igstamt: Number(igstamt)?.toFixed(2),
    amount: Number(amount)?.toFixed(2),
    boxpurrate: Number(boxpurrate)?.toFixed(2)
  };

  if (itemObject?.packingextraqty === 0 && itemObject?.qty === 0) {
    alert("Enter boxes / pieces / Rate");
    const boxRef = document.querySelector("#tr001-input-box");
    boxRef.focus();
    return;
  }
  console.log("tr012 handleAdd final ==>", itemObject);

  setRowData((p) => [...p, itemObject]);
  setSelectedItem(null);
};

export const handleChange = (item, setRowData, igstappl, rowData) => {
  console.log("tr001 handle change", item, "conversion factor ??", rowData);


  console.log("igstappl11", igstappl)

  if (item?.packingextraqty == 0 && item?.qty == 0) {
    alert("Enter Boxes or Pieces");
  }

  if (item?.salerate == 0) {
    alert("Rate is compulsory");
  }

  var salerate = item?.salerate ? item.salerate : 0.0;
  var cgstrate = item?.cgstrate ? item.cgstrate : 0.0;
  var sgstrate = item?.sgstrate ? item.sgstrate : 0.0;
  var igstrate = item?.igstrate ? item.igstrate : 0.0;
  var packingextraqty = item?.packingextraqty ? item?.packingextraqty : 0;
  var packingqty = item?.packingqty ? item?.packingqty : 0;
  var totalQty = Number(packingextraqty) * Number(item?.conversionfactor) + Number(packingqty);
  var boxpurrate = item?.boxpurrate ? item.boxpurrate : 0.0;

  var taxableamount = (Number(packingextraqty) * Number(item?.conversionfactor) + Number(packingqty)) * Number(salerate);
  var sgstamt = !igstappl ? taxableamount * (sgstrate / 100) : 0.0;
  var cgstamt = !igstappl ? taxableamount * (cgstrate / 100) : 0.0;
  var igstamt = igstappl ? taxableamount * (igstrate / 100) : 0.0;

  cgstamt = cgstamt.toFixed(2);
  sgstamt = sgstamt.toFixed(2);

  console.log("tr001 gst ==>", cgstamt, sgstamt);

  var amount = igstappl ? taxableamount + igstamt : taxableamount + (Number(cgstamt) + Number(sgstamt));

  var itemObject = {
    ...item,
    itemrecno: item.itemrecno,
    itemdescn: item.itemdescn,
    packingextraqty: packingextraqty,
    packingqty: packingqty,
    qty: Number(totalQty),
    salerate: Number(salerate)?.toFixed(2),
    taxableamount: Number(taxableamount)?.toFixed(2),
    cgstrate: cgstrate,
    sgstrate: sgstrate,
    igstrate: igstrate,
    cgstamt: Number(cgstamt)?.toFixed(2),
    sgstamt: Number(sgstamt)?.toFixed(2),
    igstamt: Number(igstamt)?.toFixed(2),
    amount: Number(amount)?.toFixed(2),
    boxpurrate: Number(boxpurrate)?.toFixed(2)
  };

  var currentItem = rowData.map((itm) => {
    if (itm.itemrecno == item.itemrecno) {
      return { ...itemObject };
    } else {
      return { ...itm };
    }
  });

  console.log("tr001 ln 262 qty ??", currentItem);
  setRowData(currentItem);
};

export const handleRemove = (item, setRowData, rowData) => {
  console.log("Delete", item, setRowData, rowData);
  var removedArray = removeByAttribute(rowData, "itemrecno", item.itemrecno);
  console.log("Remove", item, setRowData, rowData, removedArray);
  setRowData(() => [...removedArray]);
};

//~^ GST AMOUNT getting added but not showing percentage values in table : Solved - 21/01/2023
//~! USING shortguid as classname for maipulating the DOM

import React, { useEffect, useState, useRef, useContext } from "react";
import { Input, Table, Button, Space, Pagination, Checkbox, Modal } from "antd";
import { EyeOutlined, FilterFilled, SearchOutlined } from "@ant-design/icons";
import { TabStore } from "../../../TabsStructure/TabsStore";
import Highlighter from "react-highlight-words";
import CallbackCheckbox from "../CallbackComponents/CallbackCheckbox";
import { dateFormatter } from "../../AppFunction";
import "./tablecomponent.css";
import "./Table.css";
import axios from "axios";
import { currentTabData } from "../../Variable";

function TableComponent({ pageIndex }) {
  const tabStore = useContext(TabStore);
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [searchParameters, setSearchParameters] = useState(false);
  const [openImage, setOpenImage] = useState(false);
  const [billformat, setBillformat] = useState("");
  //// images
  const [file, setFile] = React.useState("");
  const [displayFile, setDisplayFile] = React.useState(false);
  const tabstore = useContext(TabStore);
  const [imageLocation, setImageLocation] = useState("");
  const handleFileUpload = (e) => {
    console.log("EEEEE", e);
    setImageLocation(e);
    setOpenImage(true);
  };

  // const convert = (file) => {

  //   return new Promise((resolve, reject) => {
  //     const fileReader = new FileReader();
  //     fileReader.onload = () => {
  //       console.log("RRTDYTDYGHCTY", fileReader.result);
  //       // resolve(fileReader.result.split(',')[1]); // extract base64 data part
  //     };
  //     fileReader.onerror = (e) => {
  //       reject(e);
  //     };
  //     fileReader.readAsDataURL(file);
  //   });
  // };

  useEffect(() => {
    if (tabStore.state.tabs[pageIndex]?.pageNo) {
      setPage(tabStore.state.tabs[pageIndex].pageNo);
    }
    setSearchedColumn(tabStore.state.tabs[pageIndex]?.searchedColumn);
    setSearchParameters(tabStore.state.tabs[pageIndex]?.searchParameters);
  }, []);

  useEffect(() => {
    var searchParams = searchParameters;
    if (searchParams && rows.length > 0) {
      console.log("Search Parameters is ....", searchParams);
      handleSearch(
        searchParams.selectedKeys,
        searchParams.confirm,
        searchParams.dataIndex
      );
    }
  }, [columns]);

  useEffect(() => {
    // Need to Store the search Text to the server
    tabStore.dispatch({
      type: "UPDATE_DATA",
      fieldType: "searchParameters",
      text: searchParameters,
    });
  }, [searchParameters]);

  useEffect(() => {
    console.log("Page Data is ", page);
    tabStore.dispatch({
      fieldType: "UPDATE_DATA",
      text: page,
    });
  }, [page]);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters, confirm) => {
    clearFilters();
    setSearchText("");
    confirm();
  };
  const getColumnSearchProps = (dataIndex, title) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${title}`}
          value={selectedKeys[0]}
          onChange={(e) => {
            setSelectedKeys(e.target.value ? [e.target.value] : []);
            setSearchParameters({
              selectedKeys: e.target.value ? [e.target.value] : [],
              confirm: confirm,
              dataIndex: dataIndex,
            });
          }}
          onPressEnter={() => {
            handleSearch(selectedKeys, confirm, dataIndex);
          }}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() =>
              handleSearch(selectedKeys, confirm, dataIndex, title)
            }
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters, confirm)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    // filterIcon: (filtered) => (

    //   <SearchOutlined
    //     style={{
    //       color: filtered ? "#1890ff" : undefined,
    //     }}
    //   />
    // ),
    filterIcon: (filtered) => (
      <div>
        {filtered ? (
          <FilterFilled
            style={{
              backgroundColor: filtered ? "#2196F23" : undefined,
            }}
          />
        ) : null}
        <SearchOutlined
          style={{
            color: filtered ? "#1890ff" : undefined,
          }}
        />
      </div>
    ),
    onFilter: (value, record) =>
      record[dataIndex]?.toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const covertToRequiredFormat = () => {
    const totalData = { ...tabStore.state.tabs[pageIndex]?.apiResponse };

    console.log("Total Data is Columns", totalData);
    var columns = totalData?.columnsinfo?.map((item) => {
      if (item.filter) {
        return {
          ...item,
          width: Number(item?.width) > 0 ? item.width : 100,
          dataIndex: item.columnname,
          key: item.columnname,
          ...getColumnSearchProps(item.columnname, item.title),
          render: (_, tags) =>
            item.type == "boolean" ? (
              <CallbackCheckbox
                defaultValue={tags[item.columnname]}
                disabled
                label={[item.Title]}
              />
            ) : item.type == "date" ? (
              <p>{dateFormatter(tags[item.columnname])}</p>
            ) : item.type == "dropdown" ? (
              <p>{tags[item.columndisplay]}</p>
            ) : item.type == "image" ? (
              <Button
                onClick={() => {
                  handleFileUpload(tags[item?.columnname]);
                  console.log("RRDYTFUGYVFU1", tags, item);
                }}
              >
                <EyeOutlined />
              </Button>
            ) : (
              <p>{tags[item.columnname]}</p>
            ),
        };
      } else {
        return {
          ...item,
          width: Number(item?.width) > 0 ? item.width : 100,
          dataIndex: item.columnname,
          key: item.columnname,
          render: (_, tags) => {
            return item.type == "boolean" ? (
              <Checkbox checked={tags[item.columnname]} disabled />
            ) : item.type == "date" ? (
              <p>{dateFormatter(tags[item.columnname])}</p>
            ) : item.type == "dropdown" ? (
              <p>{tags[item.columndisplay]}</p>
            ) : item.type == "image" ? (
              <Button
                onClick={(e) => {
                  handleFileUpload(tags[item?.columnname]);
                  console.log("RRDYTFUGYVFU2", tags, item);
                }}
              >
                <EyeOutlined />
              </Button>
            ) : (
              <p>{tags[item.columnname]}</p> //<Button><EyeOutlined /></Button>
            );
          },
        };
      }
    });
    var newColumns = [];
    for (let i = 0; i < totalData.columnsinfo?.length; i++) {
      if (totalData.columnsinfo[i]?.isview) {
        console.log("IS Filtering", totalData.columnsinfo[i]);
        if (totalData.columnsinfo[i]?.filter) {
          var newData = {
            ...totalData.columnsinfo[i],
            width:
              totalData.columnsinfo[i]?.width == "fit-content"
                ? "fit-content"
                : Number(totalData.columnsinfo[i]?.width)
                ? Number(totalData.columnsinfo[i]?.width)
                : 100,
            dataIndex: totalData.columnsinfo[i].columnname,
            key: totalData.columnsinfo[i].columnname,
            ...getColumnSearchProps(
              totalData.columnsinfo[i].columnname,
              totalData.columnsinfo[i].title
            ),
            render: (_, tags) =>
              totalData.columnsinfo[i].type == "boolean" ? (
                <CallbackCheckbox
                  defaultValue={tags[totalData.columnsinfo[i].columnname]}
                  disabled
                  label={[totalData.columnsinfo[i].Title]}
                />
              ) : totalData.columnsinfo[i].type == "date" ? (
                <p>
                  {dateFormatter(tags[totalData.columnsinfo[i].columnname])}
                </p>
              ) : totalData.columnsinfo[i].type == "decimal" ? (
                <p>
                  {tags[totalData.columnsinfo[i].columnname] == "" ||
                  tags[totalData.columnsinfo[i].columnname] == null
                    ? 0
                    : tags[totalData.columnsinfo[i].columnname]}
                </p>
              ) : totalData.columnsinfo[i].type == "int" ? (
                <p>
                  {tags[totalData.columnsinfo[i].columnname] == "" ||
                  tags[totalData.columnsinfo[i].columnname] == null
                    ? 0
                    : tags[totalData.columnsinfo[i].columnname]}
                </p>
              ) : totalData.columnsinfo[i].type == "dropdown" ? (
                <p>{tags[totalData.columnsinfo[i].columndisplay]}</p>
              ) : totalData.columnsinfo[i].type == "image" ? (
                <Button
                  onClick={(e) => {
                    handleFileUpload(tags[totalData.columnsinfo[i].columnname]);
                    console.log("RRDYTFUGYVFU3", tags, totalData);
                  }}
                >
                  <EyeOutlined />
                </Button>
              ) : (
                <p>{tags[totalData.columnsinfo[i].columnname]}</p>
              ),
          };

          newColumns.push(newData);
        } else {
          newData = {
            ...totalData.columnsinfo[i],
            width:
              totalData.columnsinfo[i]?.width == "fit-content"
                ? "fit-content"
                : Number(totalData.columnsinfo[i]?.width)
                ? Number(totalData.columnsinfo[i]?.width)
                : 100,
            dataIndex: totalData.columnsinfo[i].columnname,
            key: totalData.columnsinfo[i].columnname,
            render: (_, tags) => {
              return totalData.columnsinfo[i].type == "boolean" ? (
                <Checkbox
                  checked={tags[totalData.columnsinfo[i].columnname]}
                  disabled
                />
              ) : totalData.columnsinfo[i].type == "date" ? (
                <p>
                  {dateFormatter(tags[totalData.columnsinfo[i].columnname])}
                </p>
              ) : totalData.columnsinfo[i].type == "decimal" ? (
                <p>
                  {tags[totalData.columnsinfo[i].columnname] == "" ||
                  tags[totalData.columnsinfo[i].columnname] == null
                    ? 0
                    : tags[totalData.columnsinfo[i].columnname]}
                </p>
              ) : totalData.columnsinfo[i].type == "int" ? (
                <p>
                  {tags[totalData.columnsinfo[i].columnname] == "" ||
                  tags[totalData.columnsinfo[i].columnname] == null
                    ? 0
                    : tags[totalData.columnsinfo[i].columnname]}
                </p>
              ) : totalData.columnsinfo[i].type == "dropdown" ? (
                <p>{tags[totalData.columnsinfo[i].columndisplay]}</p>
              ) : totalData.columnsinfo[i].type == "image" ? (
                <Button
                  onClick={(e) => {
                    handleFileUpload(tags[totalData.columnsinfo[i].columnname]);
                    console.log(
                      "RRDYTFUGYVFU4",
                      tags,
                      totalData.columnsinfo[i].columnname
                    );
                  }}
                >
                  <EyeOutlined />
                </Button>
              ) : (
                <p>{tags[totalData.columnsinfo[i].columnname]}</p>
              );
            },
          };
          newColumns.push(newData);
        }
      }
    }

    // var rows = totalData?.Message?.map((item) => {
    //   return { ...item };
    // });

    setColumns(newColumns);
    console.log(
      "billformatbillformat",
      newColumns,
      newColumns.filter((it) => it.dataIndex == "billformat")
    );
    setBillformat(() =>
      newColumns.filter((it) => it.dataIndex == "billformat")
    );
    setRows(totalData?.Message);
  };

  useEffect(() => {
    //let x=columns.filter((it)=>it.dataIndex==="billformat")

    covertToRequiredFormat();
  }, [tabStore.state.tabs[pageIndex]?.apiResponse]);

  return (
    <>
      <Modal
        open={openImage}
        //closable
        onCancel={() => setOpenImage(false)}
        onOk={() => setOpenImage(false)}
        closeIcon={false}
      >
        <div>
          <img
            src={`https://scientific.sutradhar.tech/backend/api/v1/getimages/${imageLocation}/`}
            width="200"
            height="200"
          ></img>
        </div>
      </Modal>
      <form>
        <Table
          columns={columns}
          dataSource={rows}
          pagination={{
            position: ["none"],
            pageSize: pageSize,
            defaultCurrent: page,
            current: page,
          }}
          tableLayout="fixed"
          scroll={{
            x: "max-content",

            y: "430px",
            scrollToFirstRowOnChange: true,
          }}
          title={() => {
            return (
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Space>
                  {tabStore.state.tabs[pageIndex]?.apiResponse?.selected ? (
                    <Button>
                      Total Records :{" "}
                      {tabStore.state.tabs[pageIndex]?.apiResponse?.selected} /{" "}
                      {rows?.length}{" "}
                    </Button>
                  ) : (
                    <Button>Total Records : {rows?.length} </Button>
                  )}

                  <Button>Current Page : {page} </Button>
                </Space>
                <Pagination
                  total={rows?.length}
                  showTotal={(total, range) =>
                    `${range[0]}-${range[1]} of ${total} items`
                  }
                  defaultCurrent={page}
                  defaultPageSize={10}
                  showSizeChanger
                  showQuickJumper
                  current={page}
                  onChange={(e, pageSize) => {
                    setPageSize(pageSize);
                    setPage(e);
                  }}
                />
              </div>
            );
          }}
          size={"small"}
          bordered
          rowClassName="editable-row"

          // sticky={true}
          // tableLayout="auto"
          // scroll={{
          //   scrollToFirstRowOnChange: true,
          //   x: columns?.reduce((acc, el, i, arr) => (acc += el?.width), 0),
          //   y: 350,
          // }}
        ></Table>
        <div style={{ padding: "5px", fontSize: "10px" }}>
          <p>
            {currentTabData(tabStore)?.name === "MS120" && billformat != "" ? (
              <>
                <span style={{ fontWeight: "bold" }}>Note:</span>
                {billformat[0]?.note}
              </>
            ) : null}
          </p>
        </div>
      </form>
    </>
  );
}

export default TableComponent;


export const tenantrecno = 3;
export const domainrecno = 9;
// export const domainrecno = 508

export const currentTabData = (tabStore) => {
  return tabStore?.state?.tabs[tabStore?.state?.tabIndex];
};



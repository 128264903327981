import React, { useState, useEffect, useContext, useRef } from "react";
import useSaveChangedRows from "../../CommonControllers/Hooks/useSaveChangedRows";
import ItemSearch from "../../CommonControllers/ItemController/ItemSearch";
import {
  handleAdd,
  piColumns,
  handleRemove,
  handleRemovePrev,
} from "./PurchaseInvoiceFunc";
import { Button, Collapse, Input, Modal, Space, Table } from "antd";
import {
  getToday,
  dateFormatter,
  convertToBoxAndPieces,
  enterPress,
  addMonthsAndDays,
} from "../../CommonControllers/AppFunction";
import SaveDatePicker from "../../CommonControllers/SaveComponents/SaveDatePicker";
import useShortguid from "../../CommonControllers/Hooks/useShortGuid";
import { TabStore } from "../../TabsStructure/TabsStore";
import SaveTextInput from "../../CommonControllers/Components/SaveComponents/SaveTextInput";
import { AuthStore } from "../../Auth/AuthStore";
import { IoAperture } from "react-icons/io5";
import CallbackDateInput from "../../CommonControllers/Components/CallbackComponents/CallbackDateInput";
import { apiCallwithBaseUrl } from "../../CommonControllers/ApiCalls";

import { currentTabData } from "../../CommonControllers/Variable";
import MultipleSelectionItem from "../GRN/MultipleSelectionItem";

import "../../CommonControllers/global.css";
import SearchFilterByBillNo from "../../CommonControllers/Components/SearchFilterBillNo/SearchFilterByBillNo";
import SaveCheckbox from "../../CommonControllers/Components/SaveComponents/SaveCheckbox";
import ItemsTable from "./ItemsTable";

import "../../CommonControllers/global.css";
import ScanTable from "../../CommonControllers/Components/ScanTable/ScanTable";
import SaveDropdown from "../../CommonControllers/Components/SaveComponents/SaveDropdown";
import useFetchItem from "../../CommonControllers/ItemController/useFetchItems";
import { getfastpathapi } from "../../CommonControllers/ApiCalls";
import AdditionalDetails from "../../CommonControllers/AdditionalDetails/AdditionalDetails";
import {
  getDomainSupplier,
  getdomainlocationlistactive,
  userlocationlist,
} from "../../CommonControllers/Urls";
import SaveRadioBtn from "../../CommonControllers/Components/SaveComponents/SaveRadioBtn";
import MultipleSelectionItemPendingDocs from "./MultipleSelectionItemPendingDocs";

function PurchaseInvoice() {
  const [getSelectedItem, setSelectedItem] = useState(null);
  const [rowData, setRowData] = useState([]);
  const [supplierdata, setSupplierdata] = useState(null);
  const [igstappl, setigstappl] = useState(false);
  const useChangedRows = useSaveChangedRows();
  const [displayMessages, setDisplayMessages] = useState(false);
  const [previousPOList, setPreviousPOList] = useState([]);
  const shortguid = useShortguid();
  const tabStore = useContext(TabStore);
  const auth = useContext(AuthStore);
  const { Panel } = Collapse;
  const fetchItems = useFetchItem();

  const [pageIndex, setPageIndex] = useState();

  const [supplierInvNo, setSupplierInvNo] = useState("");

  const boxRef = useRef(null);
  const piecesRef = useRef(null);
  const itemSearch = useRef(null);
  const btnRef = useRef(null);
  const itemSearchdiv = useRef(null);

  const [box, setBox] = useState("");
  const [purchaseRate, setPurchaseRate] = useState(getSelectedItem?.purrate);
  const [boxpurchaseRate, setBoxPurchaseRate] = useState(" "); 
  const [selectedQty, setSelectedQty] = useState("");
  const [acctualQty, setAcctualQty] = useState(); // Qty as in pieces in
  const [freeBox, setFreeBox] = useState("");
  const [freeQty, setFreeQty] = useState("");
  const [discount, setDiscount] = useState(0);
  const [discountPer, setDiscountPer] = useState(0);
  const [batchNo, setBatchNo] = useState(null);
  const [expDate, setExpDate] = useState("");
  const [manuDate, setManuDate] = useState("");
  const [status, setstatus] = useState(false);
  const [change, setChange] = useState(0);

  // ---------------------Edit Transtion States------------------
  // Create useState for Show POP Of Search Bill
  const [showFilterModal, setshowFilterModal] = useState(false); //Create by Nohit
  // render
  const [countRender, setcountRender] = useState(0);
  const [piNumber, setPiNumber] = useState();
  const [location, setlocation] = useState();
  // --------------------------------------------------------------

  //~# State variables for date validation
  const [purchaseInvDate, setPurchaseInvDate] = useState(null);
  const [supplierInvDate, setSupplierInvDate] = useState(null);

  /// dropdown clear state//////
  const [modalcount, setModalcount] = useState(0);
  const [modalcountpendin, setModalcountpending] = useState(0);

  // Required For Multi Select
  const [displayPrevPO, setDisplayPrevPO] = useState(false);
  const [returnData, setreturnData] = useState([]); //To Get the data from Selection Component of Miltiple Selection

  const [count, setCount] = useState(0);
  // scan table validation rules
  const [validator, setValidator] = useState(0);
  //scan table
  const [openItem, setOpenItem] = useState(false);
  const [scandata, setScandata] = useState([]);
  const [renderer, setRenderer] = useState(0);
  // KMK 06/04/2023 For Narration and Remarks
  const [narration, setNarration] = useState("");
  const [remarks, setRemarks] = useState("");

  ///batch
  const [opnenBatchcount, setopenBatchcount] = useState(0);
  const [itemForSelection, setItemForSelection] = useState(null);
  const [opnenBatch1, setopenBatch1] = useState(false);

  const [transactionEdit, setTransactionEdit] = useState(false);
  const [statusOptions, setStatusOptions] = useState([]);

  ///here logic fort pending documents
  const [pendingdocumentsopen, setPendingDocumentsOpen] = useState(false);
  const [pendingdocuments, setPendingdocs] = useState([]);
  ///here is the logic for parentdate from the pending documents
  const [parentdata, setParentData] = useState([]);
  const [parenthelper, setParentHelper] = useState([]);

  // Update Data from TabStore

  // useEffect(() => {
  //   if (tabStore.state.tabs[pageIndex]?.changedRows?.dataFromEdit) {
  //     setRowData(tabStore.state.tabs[pageIndex]?.changedRows?.items);
  //     return;
  //   }
  // }, [tabStore.state.tabs[pageIndex]?.changedRows?.dataFromEdit]);
 
  useEffect(() => {
    var tabsArray = tabStore.state.tabs;
    for (let i = 0; i < tabsArray?.length; i++) {
      if (tabsArray[i].name == "TR002") {
        setPageIndex(i);
        break;
      }
    }
  }, []);
  useEffect(() => {
    if (tabStore.state.tabs[pageIndex]?.changedRows?.dataFromEdit) {
      if (tabStore.state.tabs[pageIndex]?.changedRows?.items.length > 0) {
        // setRowData(tabStore.state.tabs[pageIndex]?.changedRows?.items);
        const newdata = tabStore.state.tabs[pageIndex]?.changedRows?.items.map((i) => {
          return { ...i, boxpurrate: Number(i?.purrate) * Number(i?.conversionfactor) };
        });
        setRowData(newdata);
      }
   
      setTransactionEdit(true);
      setSupplierdata(useChangedRows.getValue("supplierrecno"));
      setPiNumber(useChangedRows.getValue("billno"));
      setlocation(useChangedRows.getValue("locationrecno"));
      setSupplierInvDate(useChangedRows.getValue("refdate"));
      setStatusOptions([
        {
          label: "Save",
          value: tabStore.state.tabs[pageIndex]?.changedRows?.status,
        },
        {
          label: "Proceed",
          value: tabStore.state.tabs[pageIndex]?.changedRows?.nextStatus,
        },
      ]);
      setstatus(tabStore.state.tabs[pageIndex]?.changedRows?.status ==="9" ? true : false);
      var logData = tabStore.state.tabs[pageIndex]?.changedRows;

      logData.dataFromEdit = false;

      tabStore.dispatch({
        type: "UPDATE_TABS_ARRAY",
        changedRowsValue: logData,
        tabsFastPath: "TR002",
        keyName: "changedRows",
      });
    }
    
  }, [tabStore.state.tabs[pageIndex]?.changedRows?.dataFromEdit]);

  //this is to set supplier inv date from pi date

  useEffect(() => {
    useChangedRows.updateValue("dataFromEdit", false);
  }, [rowData]);

  const options = [
    {
      id: 1,
      optionName: "Purchase Order",
      apiurl: "filterpurchaseorder",
      supplierrecno: supplierdata?.supplierrecno,
      doctype: "PO",
    },
    {
      id: 2,
      optionName: "GRN",
      apiurl: "filtergrn",
      supplierrecno: supplierdata?.supplierrecno,
      doctype: "GRN",
    },
  ];

  const [pageDetails, setPageDetails] = useState();

  const payload = {
    tenantrecno: auth?.state?.userdata?.tenantrecno,
    domainrecno: auth?.state?.userdata?.domainrecno,
    userrolerecno: auth?.state?.userdata?.userrolerecno,
    domainuserrecno: auth.state.userdata.recno,
    fastpath: currentTabData(tabStore)?.name,
  };
  useEffect(() => {
    // Get Fast ath API
    getfastpathapi(payload).then((res) => {
      if (res?.Success) {
        setPageDetails(res?.Message);
        setStatusOptions([
          {
            label: "Save",
            value: res.Message?.defaultStatus,
          },
          {
            label: "Proceed",
            value: res.Message?.nextStatus,
          },
        ]);
      }
    });
  }, []);

  // KMK 06/04/2023 for automatically fetching the Narration and Remarks

  useEffect(() => {
    if (!narration) {
      if (useChangedRows.getValue("narration")) {
        setNarration(useChangedRows.getValue("narration"));
      }
    }

    if (!remarks) {
      if (useChangedRows.getValue("remarks")) {
        setRemarks(useChangedRows.getValue("remarks"));
      }
    }
  }, [
    useChangedRows.getValue("remarks"),
    useChangedRows.getValue("narration"),
  ]);

  useEffect(() => {
    if (getSelectedItem) {
      setPurchaseRate(Number(getSelectedItem?.purrate).toFixed(2));
      let boxRate = Number(getSelectedItem?.purrate) * Number(getSelectedItem?.conversionfactor)
      setBoxPurchaseRate(Number(boxRate).toFixed(2));
    }
    setManuDate(
      getSelectedItem?.wantbatchno == 0 &&
        getSelectedItem?.manudatecompulsory == 0
        ? auth?.state?.userdata?.workdate
        : manuDate
    );
    setExpDate(
      getSelectedItem?.wantbatchno == 0 &&
        getSelectedItem?.expdatecompulsory == 0
        ? "29991231"
        : expDate
    );
    getSelectedItem?.wantbatchno ? setBatchNo("") : setBatchNo(".");
  }, [getSelectedItem]);

  // useEffect(() => {
  //   setShowTable(false);

  //   setTimeout(() => {
  //     setShowTable(true);
  //     return
  //   }, 200);
  // }, [rowData?.length]);

  let grossAmt =
    (Number(box) * Number(getSelectedItem?.conversionfactor) +
      Number(selectedQty)) *
      Number(purchaseRate) || "00";

  let taxableAmt =
    (Number(box) * Number(getSelectedItem?.conversionfactor) +
      Number(selectedQty)) *
      Number(purchaseRate) -
      Number(discount) || "00";

  //~# Setting workdate
  let workdate = auth?.state?.userdata?.workdate;
  workdate = JSON.stringify(workdate);
  workdate = `${workdate?.slice(-2)}/${workdate?.slice(4, 6)}/${workdate?.slice(
    0,
    4
  )}`;

  useEffect(() => {
    if (useChangedRows.getValue("refdate",pageIndex)) {
      setSupplierInvDate(useChangedRows.getValue("refdate",pageIndex));
    } else {
      useChangedRows.updateValue("refdate", auth?.state?.workdate);
      setSupplierInvDate(auth?.state?.workdate);
    }

    if (useChangedRows.getValue("trdate",pageIndex)) {
      setPurchaseInvDate(useChangedRows.getValue("trdate",pageIndex));
    } else {
      useChangedRows.updateValue("trdate", auth?.state?.workdate);
      setPurchaseInvDate(auth?.state?.workdate);
    }
  }, [useChangedRows.getValue("trdate",pageIndex)]);
  useEffect(() => {
    if (purchaseInvDate < supplierInvDate) {
      setSupplierInvDate(purchaseInvDate);
      useChangedRows.updateValue("refdate", purchaseInvDate);
    }
  }, [purchaseInvDate]);

  useEffect(() => {
    setAcctualQty(
      Number(box) * Number(getSelectedItem?.conversionfactor) +
        Number(selectedQty)
    );
  }, [box, selectedQty]);

  function setDatatoSupplierdata(e) {
    setSupplierdata(e);
    // if (e && e.supplierrecno) {
    //   getPurchaseOrdersForSupplier(e.supplierrecno);
    // }
  }

  // const getPurchaseOrdersForSupplier = async (supplierrecno) => {
  //   try {
  //     if (supplierrecno) {
  //       const res = await apiCallwithBaseUrl("POST", "/filterpurchaseorder/", {
  //         supplierrecno,
  //         withitems: true,
  //         status: "P",
  //       });
  //       setPreviousPOList(res);
  //     }
  //   } catch (error) {
  //     console.error("Error fetching purchase orders for supplier:", error);
  //     // Handle or log the error as needed
  //   }
  // };

  const addItemstoTable = () => {
    // Validate input dates and data
    const formattedWorkdate = workdate.split("/").reverse().join("");
  
    if (
      getSelectedItem?.wantbatchno === 1 &&
      pageDetails?.wantexpdate === 1 &&
      Number(formattedWorkdate) > Number(expDate)
    ) {
      alert("Product is Expired");
      return;
    }
  
    if (!purchaseRate || Number(purchaseRate) === 0) {
      alert("Please Enter Purchase Rate");
      return;
    }
  
    if (
      Number(box) + Number(selectedQty) + Number(freeBox) + Number(freeQty) <= 0
    ) {
      alert("Box, Pieces, Free Box, and Free Qty all cannot be zero");
      return;
    }
  
    if (
      getSelectedItem?.wantbatchno === 1 &&
      (!batchNo || batchNo.trim() === "") &&
      pageDetails?.wantbatchno === 1
    ) {
      alert("Batch Number Cannot be Empty");
      return;
    }
  
    if (
      getSelectedItem?.wantbatchno === 1 &&
      pageDetails?.wantmanudate === 1 &&
      (!manuDate || manuDate.trim() === "")
    ) {
      alert("Manufacture Date Cannot be Empty");
      return;
    }
  
    if (
      getSelectedItem?.wantbatchno === 1 &&
      pageDetails?.wantexpdate === 1 &&
      (!expDate || expDate.trim() === "")
    ) {
      alert("Expiry Date Cannot be Empty");
      return;
    }
  
    if (getSelectedItem) {
      // Check if the item already exists in the table
      const existingItem = rowData.find(
        (item) => item.itemrecno === getSelectedItem.itemrecno && item.itembatchno === batchNo
      );
  
      if (!existingItem) {
        // Clear all input values after adding the item
        setBox("");
        setSelectedQty("");
        setPurchaseRate("");
        setFreeBox("");
        setFreeQty("");
        setDiscount("");
        setDiscountPer("");
        setBatchNo("");
        setExpDate("");
        setManuDate("");
        setBoxPurchaseRate("");

        // Add the new item to the table
        handleAdd(
          getSelectedItem,
          setRowData,
          igstappl,
          setSelectedItem,
          shortguid,
          purchaseRate,
          selectedQty,
          box,
          freeBox,
          freeQty,
          batchNo,
          expDate,
          discount,
          discountPer,
          manuDate,
          grossAmt,
          taxableAmt,
          workdate
        );
  
        // Refocus the item search input
        itemSearch.current.focus();
      } else {
        alert("Item Already Present");
        setSelectedItem(null);
        itemSearch.current.focus();
      }
    }
  
    // Reset supplier invoice number (if needed)
    setSupplierInvNo("");
  };
  

  useEffect(() => {
    if (supplierdata?.igstappl) {
      setigstappl(true);
    } else {
      setigstappl(false);
    }
  }, [supplierdata]);

  useEffect(() => {
    // When Row Data Changes we Need to store that data to the tabsStore with Header Data
    var existingObject = useChangedRows.getValue();

    // Calculate Other Numbers and Inputs and store in the tabStore

    var amount; // Calculate the total amount after tax
    var taxableamount; // Calculate the total taxable amount after tax
    var cgstamt; //Calculate the CGSTAMT
    var sgstamt; //Calculate teh SGST AMT
    var igstamt; //Calculat the IGSTAMT
    let grossAmountTotal;
    // var shortguid

    amount = rowData?.reduce((total, item) => total + Number(item?.amount), 0);
    taxableamount = rowData?.reduce(
      (total, item) => total + Number(item?.taxableamount),
      0
    );
    cgstamt = rowData?.reduce(
      (total, item) => total + Number(item?.cgstamt),
      0
    );
    sgstamt = rowData?.reduce(
      (total, item) => total + Number(item?.sgstamt),
      0
    );
    igstamt = rowData?.reduce(
      (total, item) => total + Number(item?.igstamt),
      0
    );
    grossAmountTotal = rowData?.reduce(
      (total, item) => total + Number(item?.grossamount),
      0
    );
    let grossDIsc = rowData?.reduce(
      (total, item) => total + Number(item?.discount),
      0
    );
    var short = existingObject?.shortguid
      ? existingObject?.shortguid
      : shortguid?.getShortguid("PI");
  

    existingObject = {
      ...existingObject,
      cgstamt: Number(cgstamt).toFixed(2),
      igstamt: Number(igstamt).toFixed(2),
      sgstamt: Number(sgstamt).toFixed(2),
      taxableamount: Number(taxableamount).toFixed(2),
      amount: Number(amount).toFixed(2),
      shortguid: short,
      items: rowData,
      status: status ? statusOptions[1]?.value : statusOptions[0]?.value,
      // podate: purchaseInvDate,

      grossAmountTotal: Number(grossAmountTotal).toFixed(2),
      discountamt: grossDIsc,
    };

    useChangedRows.updateValue(false, existingObject);
  }, [rowData, status]);

  useEffect(() => {
    let isButtonDisabled = false;

    for (let i = 0; i < rowData.length; i++) {
      const {
        packingqty,
        packingextraqty,
        freepackingqty,
        freepackingextraqty,
        manufacturedate,
        expdate,
        itembatchno,
        purrate,
        expdatecompulsory,
        itemasbatch,
        manudatecompulsory,
        wantbatchno,
        changesalerateinbill,
      } = rowData[i];

      if (
        (wantbatchno &&
          (!itembatchno ||
            itembatchno == "" ||
            itembatchno == null ||
            itembatchno == undefined)) ||
        (pageDetails?.wantexpdate &&
          wantbatchno &&
          (!rowData[i]?.expdate ||
            rowData[i]?.expdate == "undefined" ||
            Number(rowData[i]?.expdate) == 0)) ||
        (pageDetails?.wantmanudate &&
          wantbatchno &&
          (!rowData[i]?.manufacturedate ||
            rowData[i]?.manufacturedate == "undefined" ||
            Number(rowData[i]?.manufacturedate) == 0)) ||
        (rowData[i]?.itemasbatch &&
          Number(rowData[i]?.qty) +
            Number(rowData[i]?.free ? rowData[i]?.free : 0) !=
            rowData[i]?.itembatcharray?.length) ||
        Number(purrate) == 0
      ) {
        isButtonDisabled = true;

        break;
      } else if (
        (wantbatchno &&
          (!itembatchno ||
            itembatchno == "" ||
            itembatchno == null ||
            itembatchno == undefined)) ||
        Number(purrate) == 0
      ) {
        isButtonDisabled = true;
        break;
      } else if (
        (wantbatchno &&
          (!itembatchno ||
            itembatchno == "" ||
            itembatchno == null ||
            itembatchno == undefined)) ||
        (!rowData[i]?.itemasbatch &&
          Number(rowData[i]?.qty) +
            Number(rowData[i]?.free ? rowData[i]?.free : 0) ==
            0)
      ) {
        isButtonDisabled = true;
        break;
      } else if (!supplierdata) {
        isButtonDisabled = true;
        break;
      }
    }

    useChangedRows.updateValue(
      "validated",
      !isButtonDisabled ? "true" : "false"
    );
    if (rowData.length == 0) {
      useChangedRows.updateValue("validated", "false");
    }
  }, [rowData]);

  useEffect(() => {
    // When Row Data Changes we Need to store that data to the tabsStore with Header Data
    var currentData = useChangedRows.getValue();
    if (!currentData?.shortguid) {
      useChangedRows.updateValue("shortguid", shortguid.getShortguid("PI"));
    }

    if (rowData?.length == 0) {
      var items = useChangedRows.getValue("items");
      if (items) {
        setTransactionEdit(true);
        // setRowData(items);
        const newdata = items.map((i) => {
          return { ...i, boxpurrate: Number(i?.purrate) * Number(i?.conversionfactor) };
        });
        setRowData(newdata);
      }
    }
  }, []);

  useEffect(() => {
    // When Row Data Changes we Need to store that data to the tabsStore with Header Data
    var currentData = useChangedRows.getValue();
    if (!currentData?.shortguid) {
      useChangedRows.updateValue("shortguid", shortguid.getShortguid("PI"));
    }
    setSupplierdata(useChangedRows.getValue("supplierrecno"));
    setPiNumber(useChangedRows.getValue("billno"));
    setlocation(useChangedRows.getValue("locationrecno"));
    setSupplierInvDate(useChangedRows.getValue("refdate"));

    var items = useChangedRows.getValue("items");
    if (items) {
      // setRowData(items);
      const newdata = items.map((i) => {
        return { ...i, boxpurrate: Number(i?.purrate) * Number(i?.conversionfactor) };
      });
      setRowData(newdata);
    }
  }, [showFilterModal]);

  useEffect(() => {
    if (tabStore.state.tabs[pageIndex]?.pageStatus == "Success") {
      setRowData([]);
      setTransactionEdit(false);
      setStatusOptions([
        {
          label: "Save",
          value: pageDetails?.defaultStatus,
        },
        {
          label: "Proceed",
          value: pageDetails?.nextStatus,
        },
      ]);
      setstatus(false);
      setigstappl(false);
      setSelectedItem(null);
      setSelectedQty(" ");
      setPurchaseRate(" ");
      setDatatoSupplierdata(null);
      setSupplierInvNo(null);
      setlocation(null);
      setPurchaseInvDate(auth.state.workdate);
      setSupplierInvDate(0);
      setPiNumber("To Be Allotted");
      setFreeBox("");
      setFreeQty("");
      setBatchNo("");
      setBox("");
      // setManuDate(null)
      // setExpDate(null)
      useChangedRows.updateValue("shortguid", shortguid.getShortguid("PI"));
      useChangedRows.updateValue("refbillno", null);
      useChangedRows.updateValue("status", false);
      // document.getElementById("po-exp-delivery-date").value = "";

      // document.getElementById("po-date").value = auth?.state?.userdata?.workdate;
      setModalcount((p) => p + 1);
      setTimeout(
        tabStore.dispatch({
          type: "UPDATE_DATA",
          fieldType: "pageStatus",
          text: null,
        }),
        1000
      );
    }
  }, [
    tabStore.state.tabs[pageIndex]?.pageStatus,
    Object.keys(currentTabData(tabStore)?.changedRows).length,
  ]);

  useEffect(() => {
    if (opnenBatchcount > 0) {
      let req = {
        ...itemForSelection,
        packingqty: 0,
        itembatchno: "",
        manufacturedate: 0,
        expdate: 0,
        qty: 0,
        shortguid: generateUniqueId(),

        taxableamount: (0.0).toFixed(2),
        grossamount: (0.0).toFixed(2),

        cgstamt: (0.0).toFixed(2),
        sgstamt: (0.0).toFixed(2),
        igstamt: (0.0).toFixed(2),
        amount: (0.0).toFixed(2),
        discountrate: (0.0).toFixed(2),
        discountamt: (0.0).toFixed(2),
        grossamt: (0.0).toFixed(2),
        packingextraqty: 0,
        packingqty: 0,
        qty: 0,
        free: 0,

        freepackingextraqty: 0,
        freepackingqty: 0,
        // Add a unique identifier to the object
      };
      // Find the index of the clicked item in rowData
      const currentIndex = rowData?.findIndex(
        (item) => item === itemForSelection
      );
      // Calculate the index where you want to insert the new req object
      const insertionIndex = currentIndex + 1;
      // Create a new copy of the rowData array
      let newRowData = [...rowData];
      // Insert the req object at the specified index using splice
      newRowData.splice(insertionIndex, 0, req);
      // Update your state or variable with the modified newRowData
      setRowData(newRowData);
    }
  }, [opnenBatch1, opnenBatchcount]);

  // Function to generate a unique identifier
  function generateUniqueId() {
    return Date.now().toString(36) + Math.random().toString(36).substr(2);
  }

  useEffect(() => {
    if (Object.keys(parentdata).length > 0) {
      setSupplierInvNo(parentdata?.refbillno);
      // setPurchaseInvDate(parentdata?.trdate.toString())
      useChangedRows.updateValue("trdate", parentdata?.trdate?.toString());
      useChangedRows.updateValue("refdate", parentdata?.refdate?.toString());
      //useChangedRows.updateValue("dataFromEdit", true);
      useChangedRows.updateValue("shortguid", parentdata?.shortguid);
    }
 
  }, [parentdata]);

  console.log("RowData====>",rowData)
  return (
    <div style={{ ...mainFrame }}>
      <Modal
        open={displayMessages}
        onCancel={() => {
          setRemarks(useChangedRows.getValue("remarks"));
          setNarration(useChangedRows.getValue("narration"));
          setDisplayMessages(!displayMessages);
        }}
        onOk={() => {
          useChangedRows.updateValue("remarks", remarks);
          useChangedRows.updateValue("narration", narration);
          setDisplayMessages(!displayMessages);
        }}
      >
        <div style={{ display: "flex", flexDirection: "column" }}>
          <p>Message from Supl</p>
          <Input
            id={"remarks"}
            placeholder="Enter Message for Supl"
            value={remarks}
            onChange={(e) => {
              setRemarks(e.target.value);
            }}
          ></Input>
          <br /> <p>Message for Self</p>
          <Input
            id={"narration"}
            placeholder="Enter Message for Self"
            value={narration}
            onChange={(e) => {
              setNarration(e.target.value);
            }}
          ></Input>
        </div>
      </Modal>
      {/* ===========modal multi select================ */}
      <Modal
        key={count}
        width={620}
        open={displayPrevPO}
        onCancel={() => {
          setDisplayPrevPO(!displayPrevPO);
          setCount((count) => count + 1);
        }}
        onOk={() => {
         
          let newArray = returnData.map((item) =>  {
            let pcsBox = convertToBoxAndPieces(
              Number(item?.selectedQty),
              Number(item?.conversionfactor),
              pageDetails?.wantpackingextra
            );
            let forPrevDoc = true
            var existingItem = [];
            if (!rowData || !item) {
              alert("Invalid data");
            } else {
              existingItem = rowData.filter(
                (it) => it?.itemrecno === item?.itemrecno
              );
              if (existingItem.length ===0) {
               
                useChangedRows.updateValue("locationrecno", item.locationrecno);
                console.log("called 1===>",item,rowData,returnData)
                // Add item to the list
                handleAdd(
                  item,
                  setRowData,
                  // item?.igstrate > 0 ? true : false,  here automatic calculation is solve cgst amt
                  igstappl,
                  setSelectedItem,
                  shortguid,
                  item?.purrate ? item.purrate : item?.rate,
                  pcsBox[1],
                  pcsBox[0],
                  0,
                  item.freeQuantity,
                  null,
                  0,
                  item?.discountamount > 0 ? item.discountamount : 0,
                  item?.discountamt > 0 ? item.discountamt : 0,
                  0,
                  item?.amount > 0 ? item.amount : 0,
                  item?.taxableamount > 0 ? item.taxableamount : 0,
                  auth?.state?.userdata?.workdate,
                  forPrevDoc
                );

               setreturnData([]);
              } else {
                handleRemovePrev(item, setRowData, rowData);
                console.log("called 2",pcsBox[1],pcsBox[0])
                setTimeout(() => {
                  handleAdd(
                    item,
                    setRowData,
                    // item?.igstrate > 0 ? true : false, here automatic calculation is solve cgst amt
                    igstappl,
                    setSelectedItem,
                    shortguid,
                    item?.purrate ? item.purrate : item?.rate,
                    pcsBox[1],
                    pcsBox[0],
                    0,
                    item.freeQuantity,
                    null,
                    0,
                    item?.discountamount > 0 ? item.discountamount : 0,
                    item?.discountamt > 0 ? item.discountamt : 0,
                    0,
                    item?.amount > 0 ? item.amount : 0,
                    item?.taxableamount > 0 ? item.taxableamount : 0
                  );
                  setreturnData([]);
                }, 200);
                useChangedRows.updateValue("locationrecno", item.locationrecno);
              }
            }
          });
          setreturnData([]);
          setDisplayPrevPO(!displayPrevPO);
          setCount((count) => count + 1);
        }}
      >
        <MultipleSelectionItem
          options={options}
          selectedItemsArray={returnData}
          //selectOnlyOneDoc={1}
          returnValue={(e) => {
          console.log("Dtatat===",returnData)
            setreturnData(e);
          }}
          forFlag={"pi"}
        />
      </Modal>

      {/* here is pending Documents */}
      <Modal
        key={modalcountpendin}
        width={620}
        open={pendingdocumentsopen}
        onCancel={() => {
          setPendingDocumentsOpen(!pendingdocumentsopen);
          setModalcountpending((count) => count + 1);
        }}
        onOk={() => {
          setRowData([]);
      
          setParentData(parenthelper);
          useChangedRows.updateValue("refbillno", null);
          let newArray = returnData.map((item) => {
            let pcsBox = convertToBoxAndPieces(
              Number(item?.selectedQty),
              Number(item?.conversionfactor)
            );
         
            var existingItem = [];
            if (!rowData || !item) {
              alert("Invalid data");
            } else {
              existingItem = rowData.filter(
                (it) => it?.itemrecno === item?.itemrecno
              );
              if (existingItem.length == 0) {
                
                // Add item to the list
                useChangedRows.updateValue("locationrecno", item.locationrecno);
                handleAdd(
                  item,
                  setRowData,
                  // item?.igstrate > 0 ? true : false,  here automatic calculation is solve cgst amt
                  igstappl,
                  setSelectedItem,
                  shortguid,
                  item?.purrate ? item.purrate : item?.rate,
                  pcsBox[1],
                  pcsBox[0],
                  0,
                  item.freeQuantity,
                  null,
                  0,
                  item?.discountamount > 0 ? item.discountamount : 0,
                  item?.discountamt > 0 ? item.discountamt : 0,
                  0,
                  item?.amount > 0 ? item.amount : 0,
                  item?.taxableamount > 0 ? item.taxableamount : 0,
                  auth?.state?.userdata?.workdate
                );

                // setreturnData([]);
              } else {
                handleRemovePrev(item, setRowData, rowData);

                setTimeout(() => {
                  handleAdd(
                    item,
                    setRowData,
                    // item?.igstrate > 0 ? true : false, here automatic calculation is solve cgst amt
                    igstappl,
                    setSelectedItem,
                    shortguid,
                    item?.purrate ? item.purrate : item?.rate,
                    pcsBox[1],
                    pcsBox[0],
                    0,
                    item.freeQuantity,
                    null,
                    0,
                    item?.discountamount > 0 ? item.discountamount : 0,
                    item?.discountamt > 0 ? item.discountamt : 0,
                    0,
                    item?.amount > 0 ? item.amount : 0,
                    item?.taxableamount > 0 ? item.taxableamount : 0
                  );
                  // setreturnData([]);
                  useChangedRows.updateValue(
                    "locationrecno",
                    item.locationrecno
                  );
                }, 200);
              }
            }
          });
          setPendingdocs(returnData);
          setreturnData([]);
          setPendingDocumentsOpen(!pendingdocumentsopen);
          setModalcountpending((count) => count + 1);
        }}
      >
        <MultipleSelectionItemPendingDocs
          options={[
            {
              id: 1,
              optionName: "Pending Documents",
              apiurl: "filterpurchasebilllogs",
              supplierrecno: supplierdata?.supplierrecno,
              doctype: "PENDING",
            },
          ]}
          selectedItemsArray={returnData}
          selectOnlyOneDoc={1}
          returnValue={(e) => {
         
            setreturnData(e);
          }}
          forFlag={"pi"}
          parentData={(e) => {
           
            setParentHelper(e);
          }}
        />
      </Modal>
      {/* ===========modal multi select end================ */}
      {/* =============================//*Edit Transaction*\\=================================== */}
      <Modal
        title="Search Bill"
        open={showFilterModal}
        onOk={() => {
          setshowFilterModal(false);
          setcountRender((p) => p + 1);
        }}
        onCancel={() => {
          setshowFilterModal(false);
          setcountRender((p) => p + 1);
        }}
        width={"100%"}
        style={{ top: 20 }}
        key={countRender}
      >
        <SearchFilterByBillNo
          fastpath="RP102"
          setshowFilterModal={setshowFilterModal}
          showFilterModal={showFilterModal}
          pageDetails={pageDetails}
        />
      </Modal>
      <Collapse collapsible="header" defaultActiveKey={["1"]}>
        {/* amol-390 - Panel name changes from "selection frame" to "Header details" */}
        <Panel header="Header Details" key="1">
          <div>
            {/* amol-temp - replace space tag with form tag and added style to form tag */}
            <form
              style={{
                display: "flex",
                flexWrap: "wrap",
                columnGap: "40px", // Adjust the column gap as needed
                rowGap: "20px",
                marginTop: "5px",
              }}
            >
              {/* Select Supplier */}
              <div
                onKeyDown={enterPress}
                style={{
                  zIndex: 11,
                  position: "relative",
                  pointerEvents: rowData?.length > 0 && "none",
                  // marginTop: "",
                }}
              >
                <SaveDropdown
                  defaultValue={location}
                  //{Pradeep task id-398 }/// added isrequired prop

                  key={modalcount || location}
                  zIndex={1000}
                  // disabled={rowData?.length > 0 || transactionEdit ? true : false}
                  apikey={"locationrecno"}
                  minInput={0}
                  inputValue={20}
                  displayLabel={"Locn"}
                  placeholder={"Locn"}
                  apiurl={getdomainlocationlistactive}
                  selectionLabel={"descn"}
                  selectionValue={"locationrecno"} //# previous key was recno, changed to locationrecno as per network response
                  returnValue={() => setlocation()}
                  width={"250px"}
                  alignment="column"
                />
              </div>
              <div
                onKeyDown={enterPress}
                style={{
                  pointerEvents: rowData?.length > 0 ? "none" : "auto",
                  //marginTop: "10px",
                }}
              >
                <SaveDropdown
                  defaultSelected={supplierdata}
                  disabled={rowData && rowData.length > 0?1:0}
                  apikey={"supplierrecno"}
                  minInput={0}
                  inputValue={20}
                  displayLabel={"Supl"}
                  placeholder={"Supl"}
                  //{Pradeep task id-398 }/// added isrequired prop
                  isrequired
                  key={modalcount}
                  apiurl={getDomainSupplier}
                  selectionLabel={"supplierdescn"}
                  selectionValue={"supplierrecno"}
                  returnValue={setDatatoSupplierdata}
                  width="250px"
                  alignment="column"
                  zIndex={1000}
                  // disabled={
                  //   rowData?.length > 0 || transactionEdit ? true : false
                  // }
                />
              </div>

              <div onKeyDown={enterPress}>
                <div style={{ position: "relative" }}>
                  <label
                    // htmlFor={id}
                    className={`label ${"focused"}`}
                    style={{
                      zIndex: 1000,
                      whiteSpace: "nowrap",
                      wordWrap: "unset",
                      position: "absolute",
                      top: "-19px",
                      left: "12px",
                      //backgroundColor: 'white',
                      padding: "0 4px",
                      fontSize: "12px",
                      color: "black",
                      pointerEvents: "none",
                      transform: "translateY(0)",
                      transition:
                        "top 0.3s, transform 0.3s, font-size 0.3s, color 0.3s",
                    }}
                  >
                    PI No.
                  </label>
                  <input
                    style={{ ...piInputStyles, marginTop: "0px" }}
                    placeholder={piNumber ? piNumber : "To be allotted"}
                    disabled
                  />
                </div>
              </div>

              {/* <div
                onKeyDown={enterPress}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                 // marginTop: "10px",
                }}
              >
                <SaveDatePicker
                  defaultValue={purchaseInvDate}
                  key={purchaseInvDate}
                  //id="tr002-purchase-invoice"
                  id={"tr002-purchase-invoice"}
                  displayLabel="PI Date"
                  placeholder="PI Date"
                  apiKey="trdate"
                  returnValue={(e) => {
                    setPurchaseInvDate(e);
                  }}
                  dateLessthan={auth?.state?.userdata?.workdate} //! If this is false, postAlertMsg will trigger
                  postAlertMsg={`PI Date cannot be greater than Today's Date or less than Opening date`}
                  dateGreaterthan={auth?.state?.userdata?.openingbaldate?auth?.state?.userdata?.openingbaldate:auth?.state?.userdata?.workdate} // New Date Validation
                  
                  //key={renderdate}
                  disabled={transactionEdit ? true : false}
                />
              </div> */}

              <div onKeyDown={enterPress} >
                <SaveDatePicker
                  // disabled={fastPath=="TR013"?true:false}
                  disabled={rowData && rowData.length > 0?1:0}
                  defaultValue={purchaseInvDate}
                  placeholder={"PI Date"}
                  id="tr013-sale-invoice"
                  displayLabel="PI Date"
                  apiKey="trdate"
                  returnValue={(e) => {
                    setPurchaseInvDate(e);
                  }}
                  dateLessthan={auth?.state?.userdata?.workdate} //! If this is false, postAlertMsg will trigger
                  postAlertMsg={`PI Date cannot be greater than Today's Date or less than Opening date`}
                  dateGreaterthan={auth?.state?.userdata?.openingbaldate} // New Date Validation
                />
              </div>
              {/* nirmal-406  added in header details panel */}
              <div
                onKeyDown={enterPress}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  // marginTop: "10px",
                }}
              >
                <SaveTextInput
                pageIndex={pageIndex}
                  displayLabel={"Supl Invoice No."}
                  apikey={"refbillno"}
                  defaultValue={supplierInvNo}
                  key={supplierInvNo || modalcount || modalcountpendin}
                  placeholder={"Enter Supl Inv No."}
                  isrequired={1}
                />
              </div>
              {/* nirmal-406  added in header details panel */}
              <div
                onKeyDown={enterPress}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  //marginTop: "10px",
                }}
              >
                <SaveDatePicker
                  defaultValue={supplierInvDate}
                  id={"tr002-supplier-invoice-date"}
                  displayLabel={"Supl Inv Date"}
                  placeholder={"Supl Inv Date"}
                  pageIndex={pageIndex}
                  apiKey="refdate"
                  // dateLessthan={purchaseInvDate}
                  returnValue={(e) => {
                    setSupplierInvDate(e);
                  }}
                  dateLessthan={
                    purchaseInvDate
                      ? purchaseInvDate
                      : auth?.state?.userdata?.workdate
                  }
                  postAlertMsg={`Supplier Invoice Date cannot be greater than PI Date`}
                  dateGreaterthan={auth?.state?.userdata?.openingbaldate}
                  //key={modalcount}
                />
              </div>

              {/* Status */}
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                onKeyDown={enterPress}
              >
                <div style={{ position: "relative" }}>
                  {/* <label
                    className={`label ${"focused"}`}
                    style={{
                      // zIndex: 1000,
                      whiteSpace: "nowrap",
                      wordWrap: "unset",
                      position: "absolute",
                      top: "-12px",
                      left: "12px",
                      //backgroundColor: "white",
                      padding: "0 4px",
                      fontSize: "12px",
                      color: "black",
                      pointerEvents: "none",
                      transform: "translateY(0)",
                      transition:
                        "top 0.3s, transform 0.3s, font-size 0.3s, color 0.3s",
                    }}
                  >
                    Status
                  </label>
                  <div
                    onKeyDown={enterPress}
                    className="saveproceed"
                    style={{
                      padding: "8.7px",
                      //marginTop: "4px",
                    }}
                  >
                    <SaveRadioBtn
                      apikey={"status"}
                      key={modalcount}
                      // defaultValue={pageDetails?.defaultStatus}
                      options={statusOptions}
                    />
                  </div> */}
                  <SaveCheckbox
                    displayLabel={"Proceed"}
                    defaultValue={status}
                    key={status}
                    editable
                    apikey={"status"}
                    returnValue={(e) => {
                    
                      setstatus(e);
                    }}
                  />
                </div>
              </div>

              {/* Additional DEtauls are added here now */}

              {/* {
                  pageDetails?.additionalDetails ? <AdditionalDetails  filterOption={pageDetails?.additionalDetails}/> : null
                } */}
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  //marginTop: "10px",
                }}
                onKeyDown={enterPress}
              >
                <AdditionalDetails
                  filterOption={pageDetails?.additionalDetails}
                />
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  //marginTop: "8px",
                }}
                onKeyDown={enterPress}
              >
                <button
                  // amol-390 - added style
                  // style={{ ...button }}
                  className="btn"
                  // type="default"

                  onClick={(e) => {
                    e.preventDefault();
                    setshowFilterModal(true);
                    setcountRender((p) => p + 1);
                  }}
                >
                  Search Purchase Bill No.
                </button>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  //marginTop: "8px",
                }}
                onKeyDown={enterPress}
              >
                <button
                  className="btn"
                  // style={{ ...button }}
                  onClick={(e) => {
                    e.preventDefault();
                    setDisplayMessages(!displayMessages);
                  }}
                >
                  Add / View Messages
                </button>
              </div>

              <div
                style={{
                  ...previous,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  //marginTop: "8px",
                }}
                onKeyDown={enterPress}
              >
                <button
                  className="btn"
                  onClick={(e) => {
                    e.preventDefault();
                    if (supplierdata) {
                      setDisplayPrevPO(!displayPrevPO);
                      setCount((count) => count + 1);
                    } else {
                      alert("Supplier not selected");
                    }
                  }}
                >
                  Previous Documents
                </button>
              </div>
              {/* <div>
                <Button
                  style={{...button}} type="default"
                  onClick={() => {
                    setshowFilterModal(true);
                    setcountRender((p) => p + 1);
                  }}
                >
                  Search Billno
                </Button>
              </div> */}
              <div
                style={{
                  ...previous,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  //marginTop: "8px",
                }}
                onKeyDown={enterPress}
              >
                <button
                  className="btn"
                  onClick={(e) => {
                    e.preventDefault();
                    if (supplierdata) {
                      setPendingDocumentsOpen(!pendingdocumentsopen);
                      setModalcountpending((count) => count + 1);
                    } else {
                      alert("Supplier not selected");
                    }
                  }}
                >
                  Pending Documents
                </button>
              </div>

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  // marginTop: "10px",
                }}
                onKeyDown={(e) => {
                  if (e.keyCode == 13) {
                    e.preventDefault();
                    itemSearchdiv.current.focus();
                  }
                }}
              >
                <SaveCheckbox
                  apikey={"isPrint"}
                  displayLabel="Print PI"
                  defaultValue={false}
                  editable
                  key={modalcount}
                />
              </div>
            </form>
          </div>
        </Panel>

        <Panel header="Items Details" key="2">
          <div>
            <Space size="large" style={{ alignItems: "end" }}>
              {/* amol-390 - change width from 250px to 220px */}

              {/* amol-temp - Added * symbol to the mandatory field and added margin-left 5% to div style */}
              <div style={{ alignItems: "center" }}>
                <form style={{ ...callbackTextInputStyles, alignItems: "end" }}>
                  <div
                    style={{ zIndex: 2, position: "relative", width: "220px" }}
                    /// onKeyDown={enterPress}   enter functionality is removed
                  >
                    <ItemSearch
                      itemSearchRef={itemSearch} /// task id-256 rajendra auto focus issue  itemSearchRef-inputRef because in common components inputRef is used
                      forFlag={"P"}
                      returnValue={(e) => {
                        setSelectedItem(e);
                      }}
                      supplierdata={supplierdata?.supplierrecno}
                      dropdown
                      defaultSelectedItem={getSelectedItem}
                      forPage={"PO"}
                      selection={false}
                      trdate={purchaseInvDate}
                      barcodeReturnValue={(e) => {
                        //{ start Pradeep task id -dc start }

                        // Write a function to add to existing Items and check only if it has serial number

                        if (e?.itemasbatch == 1) {
                          fetchItems
                            .addBarcodeScannedItemToTable(
                              rowData,
                              e,
                              "PIF",
                              igstappl,
                              pageDetails,
                              "P"
                            )
                            .then((res) => {
                              // setRowData(res);
                              //  nirmal-purchaseinvoice ::: Solved the issue of deleting the entire item from the grid if the old barcode is entered.
                              if (res?.length == 0) {
                                setRowData(rowData);
                                // return
                              } else {
                                setRowData(res);
                                setChange(change + 1);
                              }
                            })
                            .catch((err) => {
                              alert(err);
                            });
                        } else {
                          setSelectedItem(e);
                        }
                      }}
                    />
                    {getSelectedItem ? (
                      <p>
                        {getSelectedItem?.itemdescn} <br />
                        Available Qty : {getSelectedItem?.availablestock}
                      </p>
                    ) : null}
                  </div>
                  {getSelectedItem?.packingextrarecno > 0 &&
                  pageDetails?.wantpackingextra ? (
                    <div onKeyDown={enterPress} style={{ marginLeft: "20px" }}>
                      <p>
                        Box <span style={{ color: "red" }}>*</span>
                        {getSelectedItem?.conversionfactor &&
                          +getSelectedItem?.conversionfactor}
                      </p>
                      <input
                        //  disabled={getSelectedItem?.packingextrarecno>0? false:true}
                        ref={boxRef}
                        style={{ ...inputStyles }}
                        value={box}
                        onChange={(e) => {
                          const regex = /^[0-9\b]+$/;
                          const value = e.target.value;
                          if (value === "" || regex.test(value)) {
                            setBox(value);
                          }
                        }}
                        type="number"
                      />
                    </div>
                  ) : null}
                  <div onKeyDown={enterPress} style={{ marginLeft: "20px" }}>
                    <p>
                      Pieces<span style={{ color: "red" }}>*</span>
                    </p>
                    <input
                      ref={piecesRef}
                      style={{ ...inputStyles }}
                      // disabled={getSelectedItem?.packingextrarecno==0? false:true}
                      value={selectedQty}
                      type="number"
                      onChange={(e) => {
                        const regex = /^[0-9\b]+$/;
                        const value = e.target.value;
                        if (value === "" || regex.test(value)) {
                          setSelectedQty(value);
                        }
                      }}
                    />
                  </div>

                  {pageDetails?.wantfreeqty ? (
                    <>
                      {getSelectedItem?.packingextrarecno > 0 ? (
                        <div onKeyDown={enterPress}>
                          <p>Free Boxes</p>
                          <input
                            ref={piecesRef}
                            style={{ ...inputStyles }}
                            // disabled={getSelectedItem?.packingextrarecno>0? false:true}
                            value={freeBox}
                            type="number"
                            onChange={(e) => {
                              const regex = /^[0-9\b]+$/;
                              const value = e.target.value;
                              if (value === "" || regex.test(value)) {
                                setFreeBox(value);
                              }
                            }}
                          />
                        </div>
                      ) : null}
                      <div onKeyDown={enterPress}>
                        <p>Free Pieces</p>
                        <input
                          ref={piecesRef}
                          style={{ ...inputStyles }}
                          // disabled={getSelectedItem?.packingextrarecno==0? false:true}
                          value={freeQty}
                          type="number"
                          onChange={(e) => {
                            const regex = /^[0-9\b]+$/;
                            const value = e.target.value;
                            if (value === "" || regex.test(value)) {
                              setFreeQty(value);
                            }
                          }}
                        />
                      </div>
                    </>
                  ) : null}

                  {pageDetails?.wantbatchno ? (
                    <>
                      {" "}
                      <div onKeyDown={enterPress}>
                        <p>
                          Batch No.<span style={{ color: "red" }}>*</span>
                        </p>
                        <input
                          ref={piecesRef}
                          style={{
                            ...inputStyles,
                            width: "160px",
                            textAlign: "left",
                          }}
                          value={batchNo}
                          onChange={(e) => setBatchNo(e.target.value)}
                          onBlur={(e) => {
                            /// batch validation if itemsbatch is true or false if batch is true it should not asked for batch again
                            if (getSelectedItem?.wantbatchno) {
                              if (!e.target.value) {
                                alert("Batch Cannot Be Empty");
                              }
                              return;
                            }
                          }}
                        />
                      </div>
                      {pageDetails?.wantmanudate > 0 ? (
                        <div onKeyDown={enterPress}>
                          <p>
                            Mfg. Date <span style={{ color: "red" }}>*</span>
                          </p>
                          <CallbackDateInput
                            defaultValue={
                              getSelectedItem?.wantbatchno == 0 &&
                              getSelectedItem?.manudatecompulsory == 0
                                ? auth?.state?.userdata?.workdate
                                : manuDate
                            }
                            id={"tr002-manufacture-date-callback"}
                            // displayLabel={"Expiry Date"}
                            apiKey={"manudate"}
                            returnValue={(e) => {
                              if (!manuDate && !e) {
                                {
                                  /*Kaushik : Added !e to check if response is coming */
                                }

                                setManuDate(auth?.state?.userdata?.workdate);
                              } else {
                                setManuDate(e);
                                if(getSelectedItem?.expmonths > 0 || getSelectedItem?.expdays > 0){
                                  let months = getSelectedItem?.expmonths > 0 ? Number(getSelectedItem?.expmonths) - 1 : 0;
                                  let days = getSelectedItem?.expdays > 0 ? Number(getSelectedItem?.expdays) - 1 : 0;
                                  let expDate = addMonthsAndDays(e, months, days)
                                  setExpDate(expDate);
                                }
                              }

                              document
                                .getElementById("tr002-exp-date-callback")
                                .focus();
                            }}
                            initial
                            dateLessthan={purchaseInvDate}
                            key={modalcount}
                          />
                        </div>
                      ) : null}
                      {pageDetails?.wantexpdate > 0 ? (
                        <div onKeyDown={enterPress}>
                          <p>
                            Expiry Date <span style={{ color: "red" }}>*</span>
                          </p>
                          <CallbackDateInput
                            defaultValue={
                              getSelectedItem?.wantbatchno == 0 &&
                              getSelectedItem?.expdatecompulsory == 0
                                ? "99991231"
                                : expDate
                            }
                            id={"tr002-exp-date-callback"}
                            // displayLabel={"Expiry Date"}
                            apiKey={"manudate"}
                            initial
                            dateGreaterthan={manuDate} //! If this is false, postAlertMsg will trigger
                            postAlertMsg="Expiry Date should not be lesser than Mfg. Date"
                            returnValue={(e) => {
                              if (e <= manuDate) {
                                document.getElementById(
                                  "po-manufacture-date-callback"
                                ).style.color = "red";
                                setExpDate(0);
                              } else {
                                setExpDate(e);
                              }
                            }}
                            key={modalcount}
                          />
                        </div>
                      ) : null}
                    </>
                  ) : null}

                  {getSelectedItem?.packingextrarecno > 0 &&
                    pageDetails?.wantpackingextra ? (
                        <div onKeyDown={enterPress}>
                          <p>
                            Box Rate 
                          </p>
                          <input
                            type="number"
                            style={{ ...inputStyles, width: "100px" }}
                            value={boxpurchaseRate}
                            disabled={true}
                          />
                 
                        </div>  
                  ) : null}

                  <div onKeyDown={enterPress}>
                    <p>
                      Rate <span style={{ color: "red" }}>*</span>
                    </p>
                    <input
                      type="number"
                      style={{ ...inputStyles, width: "100px" }}
                      value={purchaseRate}
                      disabled={pageDetails?.changepurrateinbill === 0}
                      onChange={(e) => {
                        if (getSelectedItem?.changepurrateinbill) {
                          const regex = /^\d*(\.\d{0,2})?$/;
                          const value = e.target.value;
                          if (value === "" || regex.test(value)) {
                            setPurchaseRate(value);
                          }
                        }
                      }}
                      onBlur={(e) => {
                        const value = e.target.value.trim(); // Trim whitespace

                        // Check for invalid rates
                        const regex = /^\d+(\.\d{1,2})?$/;
                        if (!regex.test(value)) {
                          alert("Invalid rate format");
                          return;
                        }

                        // Check for rates that cannot be zero or variants of zero
                        if (parseFloat(value) <= 0) {
                          
                          //alert("Rate cannot be 0 or 0.00");
                        return;
                        }

                        // Additional checks if needed can be added here

                        // If all validations pass, focus on the button reference
                        btnRef.current.focus();
                      }}
                    />
                 
                  </div>
                  <div onKeyDown={enterPress}>
                    <p>Gross Amount</p>
                    <input
                      disabled
                      value={Number(grossAmt).toFixed(2)}
                      style={{ ...grossAmtStyle, width: 120 }}
                    />
                  </div>

                  <div onKeyDown={enterPress}>
                    <p>Discount %</p>
                    <input
                      style={{ ...inputStyles, width: "75px" }}
                      value={discountPer}
                      type="number"
                      id="tr002-discount-perc"
                      onChange={(e) => {
                        const regex = /^\d*(\.\d{0,2})?$/;
                        const value = e.target.value;

                        if (
                          (value === "" && value < 99.99) ||
                          (regex.test(value) && value < 99.99)
                        ) {
                          setDiscountPer(value);

                          var dicsountAmount =
                            (Number(grossAmt) * Number(value)) / 100;
                          setDiscount(dicsountAmount.toFixed(2));
                        }
                      }}
                      onBlur={(e) => {
                        let temp = e.target.value;
                        temp != "" &&
                          setDiscountPer(Number(e.target.value)?.toFixed(2));
                        var dicsountAmount =
                          (Number(grossAmt) * Number(e.target.value)) / 100;
                        setDiscount(dicsountAmount.toFixed(2));
                      }}
                    />
                  </div>

                  <div onKeyDown={enterPress}>
                    <p>Discount</p>
                    <input
                      style={{ ...inputStyles, width: "110px" }}
                      value={discount}
                      type="number"
                      // disabled={discountPer !== 0}
                      disabled
                      onChange={(e) => setDiscount(e.target.value)}
                    />
                  </div>
                  <div onKeyDown={enterPress}>
                    <p>Taxable Amount</p>
                    <input
                      disabled
                      value={Number(taxableAmt).toFixed(2)}
                      style={{ ...grossAmtStyle, width: 120 }}
                    />
                  </div>
                  <div onKeyDown={enterPress}>
                    <Button
                      className="btn item-search-btn"
                      icon={<IoAperture size="1.2rem" />}
                      size="large"
                      onClick={() => {
                        addItemstoTable();
                      }}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          addItemstoTable();
                        }
                      }}
                    >
                      Add
                    </Button>
                  </div>
                  {/* <SaveCheckbox apikey={"addgrn"} displayLabel="Add GRN" editable /> */}
                </form>
              </div>
            </Space>
          </div>
          {/* Table for Selection  */}
          <div style={tableStyles}>
            {rowData?.length > 0 && (
              <ItemsTable
                rowData={rowData}
                setRowData={setRowData}
                igstappl={igstappl}
                scandata={scandata}
                setScandata={setScandata}
                setOpenItem={setOpenItem}
                purchaseInvDate={purchaseInvDate}
                useChangedRows={useChangedRows}
                change={change}
                pageDetails={pageDetails}
                setItemForSelection={setItemForSelection}
                setopenBatch={setopenBatch1}
                setopenBatchcount={setopenBatchcount}
              />
            )}
          </div>
        </Panel>
      </Collapse>

      {openItem && (
        <ScanTable
          data={scandata}
          openItem={openItem}
          setOpenItem={setOpenItem}
          rowData={rowData}
          setRowData={setRowData}
          renderer={renderer}
          setRenderer={setRenderer}
          setValidator={setValidator}
          supplierrecno={supplierdata?.supplierrecno}
          forFlag={"P"}
          pageDetails={pageDetails}
          pageIndex={pageIndex}
        />
      )}
    </div>
  );
}

export default PurchaseInvoice;

const itemTablePStyles = {
  textAlign: "right",
};
// amol-temp - mainFrame width change to 99vw from 95vw
const mainFrame = {
  width: "99vw",
  margin: "auto",
};

const topRowFilters = {
  gap: "24px",
  display: "flex",
  alignItems: "center",
};

const tableStyles = {
  position: "relative",
  zIndex: 0,
  marginTop: "24px",
};

// applying padding to align input rows inline
const callbackTextInputStyles = {
  display: "flex",
  flexWrap: "wrap",
  alignItems: "center",
  // amol-390 - change in CSS gap 10px to 24px
  gap: "24px",
};

// add to table btn styles

const inputStyles = {
  padding: "7px",
  border: "1px solid #dfdfdf",
  width: "90px",
  fontSize: "16px",
  marginTop: "2px",
  borderRadius: "8px",
  textAlign: "right",
  height: "36px",
};

const grossAmtStyle = {
  textAlign: "right",
  border: "1px solid #dfdfdf",
  padding: "2px",
  fontSize: "18px",
  width: "90px",
  borderRadius: "8px",
  marginTop: "2px",
  height: "36px",
  // border:"1px solid red"
};

const piInputStyles = {
  // amol-temp - margintop from 4px to 8 px
  marginTop: "8px",
  border: "1px solid #dfdfdf",
  borderRadius: "8px",
  width: "180px",
  padding: "9px",
  fontSize: "16px",
  height: "39px",
  // backgroundColor:"white"
  /* Transparent Depth */
};

const button = {
  marginTop: "7px",
  textAlign: "right",
  border: "1px solid #dfdfdfdf",
  height: "37px",
  boxShadow: "0 6px 8px rgba(0, 0, 0, 0.1)",
  borderRadius: "5px",
  backgroundColor: "#87CEEB",
};

const previous = {
  // justifyContent:"center",
  // border:"1px solid red",
  textAlign: "center",
};

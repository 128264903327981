import React, {
  useState,
  useEffect,
  useContext,
  useRef,
  useCallback,
} from "react";
import useSaveChangedRows from "../../CommonControllers/Hooks/useSaveChangedRows";
import ItemSearch from "../../CommonControllers/ItemController/ItemSearch";
import {
  handleAdd,
  piColumns,
  handleRemove,
  handleRemovePrev,
} from "./SalesInvoiceFunc";
import {
  Button,
  Collapse,
  Input,
  Modal,
  Popconfirm,
  Space,
  Table,
  message,
} from "antd";
import {
  getToday,
  dateFormatter,
  convertToBoxAndPieces,
  enterPress,
} from "../../CommonControllers/AppFunction";
import useShortguid from "../../CommonControllers/Hooks/useShortGuid";
import { TabStore } from "../../TabsStructure/TabsStore";
import { AuthStore } from "../../Auth/AuthStore";
import { IoAperture } from "react-icons/io5";
import { List } from "antd";
import { FcAddDatabase } from "react-icons/fc";
import SaveDropdown from "../../CommonControllers/Components/SaveComponents/SaveDropdown";
import InputComponent from "../../CommonControllers/Components/InputComponent";
import SearchFilterByBillNo from "../../CommonControllers/Components/SearchFilterBillNo/SearchFilterByBillNo";
import MultipleSelectionItem from "../GRN/MultipleSelectionItem";
import SaveCheckbox from "../../CommonControllers/Components/SaveComponents/SaveCheckbox";
import {
  getdomaincustomeritemlist,
  userlocationlist,
} from "../../CommonControllers/Urls";
import "../../CommonControllers/global.css";
import ScanTable from "../../CommonControllers/Components/ScanTable/ScanTable";
import ScannedItem from "./ScannenedItem";
import SaveDatePicker from "../../CommonControllers/SaveComponents/SaveDatePicker";
import SaveTextInput from "../../CommonControllers/Components/SaveComponents/SaveTextInput";
import ExecuteTextInput from "../../CommonControllers/Components/ExecuteComponents/ExecuteTextInput";
import useFetchItem from "../../CommonControllers/ItemController/useFetchItems";
import { setRef } from "@mui/material";
import SaveTextBox from "../../CommonControllers/NewComponents/SaveComponents/SaveTextBox";
import ExecuteTextBox from "../../CommonControllers/NewComponents/ExecuteComponents/ExecuteTextBox";
import { getfastpathapi } from "../../CommonControllers/ApiCalls";
import { currentTabData } from "../../CommonControllers/Variable";
import AdditionalDetails from "../../CommonControllers/AdditionalDetails/AdditionalDetails";
import ItemSelectionModal from "../../CommonControllers/ItemController/ItemSelectionModal";
import CallbackDateInput from "../../CommonControllers/Components/CallbackComponents/CallbackDateInput";
import axios from "axios";
import SaveRadioBtn from "../../CommonControllers/Components/SaveComponents/SaveRadioBtn";
import "../../CommonControllers/global.css";
import { notification } from "antd";
function SalesInvoice({ fastPath }) {
  const [getSelectedItem, setSelectedItem] = useState(null);
  const [displayValue, setdisplayValue] = useState(0);
  const [invoiceAmount, setinvoiceAmount] = useState(0);
  const [typeAmt, setTypeAmt] = useState("Rounding:");
  const [rowData, setRowData] = useState([]);
  const [igstappl, setigstappl] = useState(false);
  const useChangedRows = useSaveChangedRows();
  const [displayMessages, setDisplayMessages] = useState(false);
  const [roundDiff, setRoundDiff] = useState();
  const shortguid = useShortguid();
  const tabStore = useContext(TabStore);
  const auth = useContext(AuthStore);
  const { Panel } = Collapse;
  const [change, setChange] = useState(0);

  const boxRef = useRef(null);
  const piecesRef = useRef(null);
  const itemSearch = useRef(null);
  const btnRef = useRef(null);

  const [box, setBox] = useState("");
  const [collapse, setCollapse] = useState(false);
  const [expDeliveryDays, setExpDeliveryDays] = useState(null);
  const [salerate, setSaleRate] = useState(getSelectedItem?.purrate);
  const [boxSaleRate, setBoxSaleRate] = useState(" ");
  const [selectedQty, setSelectedQty] = useState(0);
  const [itemInformation, setItemInformation] = useState();
  const [acctualQty, setAcctualQty] = useState(); // Qty as in pieces in
  const [freeBox, setFreeBox] = useState(0);
  const [freeQty, setFreeQty] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [discountPer, setDiscountPer] = useState(0);
  const [batchNo, setBatchNo] = useState(null);
  const [expDate, setExpDate] = useState(0);
  const [manuDate, setManuDate] = useState(0);
  const [showTable, setShowTable] = useState(true);
  const [getInfoFromPO, setInfoFromPO] = useState([]);
  const [customerData, setCustomerData] = useState({}); //~# Setting customer data in this state
  const [drivername, setdrivername] = useState(null);
  const [vehiclenumber, setvehiclenumber] = useState(null);

  // -------------multiple selecton-----------------
  const [previousPOList, setPreviousPOList] = useState([]);
  const [displayPrevPO, setDisplayPrevPO] = useState(false);
  const [displayPrevSO, setDisplayPrevSO] = useState(false);

  const [displayPrevDC, setDisplayPrevDC] = useState(false);

  const [returnData, setreturnData] = useState([]); //To Get the data from Selection Component of Miltiple Selection
  const [count, setCount] = useState(0);
  const [itemForSelection, setItemForSelection] = useState(null);

  // scan table validation rules
  const [validator, setValidator] = useState(0);
  const [totalQty, setTotalQty] = useState(0);

  ////dropdown clear//////

  const [modalcount, setModalCount] = useState(0);

  ///// scannned items/////

  const [modal, setModal] = useState(false);
  const [scanner, setScanner] = useState([]);
  // ---------------------Edit Transtion States------------------
  // Create useState for Show POP Of Search Bill
  const [showFilterModal, setshowFilterModal] = useState(false);
  // render
  const [countRender, setcountRender] = useState(0);
  const [siNumber, setSiNumber] = useState();
  const [location, setlocation] = useState();

  const fetchItems = useFetchItem();
  const [opnenBatch, setopenBatch] = useState(false);

  // scan table
  const [openItem, setOpenItem] = useState(false);
  const [scandata, setScandata] = useState();
  const [renderer, setRenderer] = useState(0);

  //// here the batch after getting from the api cALL
  const [apidata, setApiData] = useState();
  ///// date validation
  const [purchaseInvDate, setPurchaseInvDate] = useState(
    auth?.state?.userdata?.workdate
  );

  const [pageDetails, setPageDetails] = useState();
  const [statusOptions, setStatusOptions] = useState([]);
  const [status, setstatus] = useState(true);
  // here is the logic for the maxcreditamt
  const [maxcredTrue, setmaxcredTrue] = useState(false);
  const [alertShown, setAlertShown] = useState(true);

  //// here to chek the length of rowData
  const previousRowDataLengthRef = useRef(rowData);

  /// here the pick from from opening dc
  const [isopening,setIsopening]=useState(false)

  const [pageIndex, setPageIndex] = useState();
  useEffect(() => {
    var tabsArray = tabStore.state.tabs;
    for (let i = 0; i < tabsArray?.length; i++) {
      if (tabsArray[i].name == "TR013") {
        setPageIndex(i);
        break;
      }
    }
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");
    const formattedDate = `${year}${month}${day}`;

    setPurchaseInvDate(formattedDate);
  }, []);

  useEffect(() => {
    const payload = {
      tenantrecno: auth?.state?.userdata?.tenantrecno,
      domainrecno: auth?.state?.userdata?.domainrecno,
      userrolerecno: auth?.state?.userdata?.userrolerecno,
      domainuserrecno: auth.state.userdata.recno,
      fastpath: currentTabData(tabStore)?.name,
    };
    // Get Fast ath API
    getfastpathapi(payload).then((res) => {
      if (res?.Success) {
        setPageDetails(res?.Message);
        setStatusOptions([
          {
            label: "Save",
            value: res.Message?.defaultStatus,
          },
          {
            label: "Proceed",
            value: res.Message?.nextStatus,
          },
        ]);
      }
    });
  }, []);

  useEffect(() => {
    setShowTable(false);

    setTimeout(() => {
      setShowTable(true);
    }, 100);
  }, [change]);

  /// ONE DC ONE INVoice

  useEffect(() => {
    if (getSelectedItem) {
      setSaleRate(Number(getSelectedItem?.salerate).toFixed(2));
      setBoxSaleRate(
        (
          Number(getSelectedItem?.salerate) *
          Number(getSelectedItem?.conversionfactor)
        ).toFixed(2)
      );
    }
  }, [getSelectedItem]);

  let grossAmt =
    (Number(box) * 10 + Number(selectedQty)) *
      (Number(salerate) - Number(discount)) || "00";

  let taxableAmt =
    (Number(box) * Number(getSelectedItem?.conversionfactor) +
      Number(selectedQty)) *
      Number(salerate) -
      Number(discount) || "00";

  //~# Setting workdate
  let workdate = auth?.state?.workdate;
  workdate = JSON.stringify(workdate);
  workdate = `${workdate?.slice(-2)}/${workdate?.slice(4, 6)}/${workdate?.slice(
    0,
    4
  )}`;

  // const enterPress = (e) => {
  //   if (e.keyCode === 13) {
  //     e.preventDefault();
  //     const form = e.target.form;
  //     const index = Array.prototype.indexOf.call(form, e.target);
  //     if (form.elements[index + 1]) {
  //       form.elements[index + 1].focus();
  //     }
  //   }
  // };

  useEffect(() => {
    // console.log("getSelectedItem in salesinvoice", getSelectedItem);
    setAcctualQty(
      Number(box) * Number(getSelectedItem?.conversionfactor) +
        Number(selectedQty)
    );
  }, [box, selectedQty]);
  //// may 31 rajendra
  useEffect(() => {
    if (getSelectedItem?.isservice) {
      addItemstoTable();
    }
  }, [getSelectedItem]);

  const addItemstoTable = () => {
    // if (!getSelectedItem.itembasbatch && !batchNo) {
    //   alert("Batch Cannot be Null");
    //   return;
    // }
    let x = workdate.split("/").reverse().join("");

    if (
      getSelectedItem?.wantbatchno == 1 &&
      pageDetails?.wantexpdate == 1 &&
      Number(x) > Number(expDate)
    ) {
      alert("Product is Expired");
    }

    if (getSelectedItem) {
      var existingItem = rowData.filter(
        (item) => item.itemrecno == getSelectedItem.itemrecno
      );

      if (existingItem?.length == 0) {
        // clearing all input values
        setBox("");

        setSelectedQty("");
        setSaleRate("");

        setFreeBox(0);
        setFreeQty(0);
        setDiscount(0);
        setDiscountPer(0);
        setBatchNo(0);
        setExpDate(null);
        setManuDate(null);

        handleAdd(
          getSelectedItem,
          setRowData,
          igstappl,
          setSelectedItem,
          shortguid,
          salerate,
          selectedQty,
          box,
          freeBox,
          freeQty,
          batchNo,
          expDate,
          discount,
          discountPer,
          manuDate
        );
        itemSearch?.current?.focus();
      } else {
        alert("Item Already Present");
        setSelectedItem(null);
        itemSearch?.current?.focus();
      }
    }
  };

  /*
  In order to add the Selected Item with Batch Details into the rows array
  1. Check if item is itemasbatch
  2. If itemasbatch : directly select the item and ask to input the quantity required
  3. If Not itemasbatch : using for loop add the selceted items to the rowsData
  4. From the ItemBatch Details and the items object select the selectedQty and selectedFreeQty form the selected Items
  */

  const addItemBatchWiseToTable = () => {
    // Get getSelectedItems

    // if (getSelectedItem?.itembasbatch) {
    //   //Added s at the end remove later
    //   // Here we need to add the from the Quantity,
    // } else {
    // Here we select form the selected Items

    let batchDetails = getSelectedItem?.batchdetails;
    for (let i = 0; i < batchDetails?.length; i++) {
      let itemrecno = getSelectedItem.itemrecno;
      let itemdescn = getSelectedItem.itemdescn;
      let igstrate = getSelectedItem.igstrate;
      let cgstrate = getSelectedItem.cgstrate;
      let sgstrate = getSelectedItem.sgstrate;
      let mrp = getSelectedItem.mrp;
      let conversionfactor = getSelectedItem.conversionfactor;

      let totalSelectedQuantity =
        batchDetails[i]?.selectedQty || batchDetails[i]?.selectedBoxQty
          ? Number(batchDetails[i].selectedQty) +
            Number(batchDetails[i].selectedBoxQty) *
              getSelectedItem.conversionfactor
          : 0;
      let totalFreeQuantity = batchDetails[i]?.selectedFreeQty
        ? Number(batchDetails[i]?.selectedFreeQty)
        : 0;

      let batchNo = batchDetails[i].itembatchno;
      let itemSerialNo = batchDetails[i].serialno;
      let salerate = batchDetails[i].salerate;
      let expdate = batchDetails[i].expdate;
      let manudate = batchDetails[i].manudate;

      if (totalSelectedQuantity > 0 || totalFreeQuantity > 0) {
        let convertedQty = convertToBoxAndPieces(
          totalSelectedQuantity,
          conversionfactor,
          getSelectedItem?.packingextrarecno
        );

        let convertedFreeQty = convertToBoxAndPieces(
          totalFreeQuantity,
          conversionfactor,
          getSelectedItem?.packingextrarecno
        );

        let selectedBoxQty = convertedQty[0];
        let selectedPcsQty = convertedQty[1];
        let selectedFreeBoxQty = convertedFreeQty[0];
        let selectedFreePcsQty = convertedFreeQty[1];

        handleAdd(
          getSelectedItem,
          setRowData,
          igstrate > 0 ? true : false,
          setSelectedItem,
          shortguid,
          salerate,
          selectedPcsQty,
          selectedBoxQty,
          selectedFreeBoxQty,
          selectedFreePcsQty,
          batchNo,
          expdate,
          0,
          0,
          manudate
        );
      }
      // }
    }
  };
  useEffect(() => {
    if (getSelectedItem && !getSelectedItem?.itemasbatch) {
      const checkIfItemExists = rowData?.filter(
        (item) => item.itemrecno === getSelectedItem.itemrecno
      );

      if (checkIfItemExists?.length > 0 && !apidata) {
        //alert("Item Already Exists");
        // Assuming handleRemoveBatch correctly removes the previous item
        // handleRemoveBatch(checkIfItemExists[0].itemrecno, setRowData, rowData);
      }

      addItemBatchWiseToTable();
    }
  }, [getSelectedItem, apidata]);

  useEffect(() => {
    // setModalCount(modalcount + 1)

    if (customerData?.igstappl) {
      setigstappl(true);
    } else {
      setigstappl(false);
    }
  }, [customerData]);

  useEffect(() => {
    if (tabStore.state.tabs[pageIndex]?.changedRows?.dataFromEdit) {
      if (tabStore.state.tabs[pageIndex]?.changedRows?.items.length > 0) {
        // setRowData(tabStore.state.tabs[pageIndex]?.changedRows?.items);
        const newdata = tabStore.state.tabs[pageIndex]?.changedRows?.items.map(
          (i) => {
            return {
              ...i,
              boxsalerate: Number(i?.salerate) * Number(i?.conversionfactor),
            };
          }
        );
        setRowData(newdata);
      }
      setStatusOptions([
        {
          label: "Save",
          value: tabStore.state.tabs[pageIndex]?.changedRows?.status,
        },
        {
          label: "Proceed",
          value: tabStore.state.tabs[pageIndex]?.changedRows?.nextStatus,
        },
      ]);
      var logData = tabStore.state.tabs[pageIndex]?.changedRows;

      logData.dataFromEdit = false;

      tabStore.dispatch({
        type: "UPDATE_TABS_ARRAY",
        changedRowsValue: logData,
        tabsFastPath: "TR013",
        keyName: "changedRows",
      });
    }
  }, [tabStore.state.tabs[pageIndex]?.changedRows?.dataFromEdit]);

  useEffect(() => {
    var existingObject = useChangedRows.getValue();
    const sumAmount = (key) =>
      rowData.reduce((total, item) => total + Number(item[key] || 0), 0);

    const amount = sumAmount("amount");
    const taxableAmount = sumAmount("taxableamount");
    const cgstamt = sumAmount("cgstamt");
    const sgstamt = sumAmount("sgstamt");
    const igstamt = sumAmount("igstamt");

    if (alertShown && amount > customerData?.maxcreditamt) {
      alert("Credit Limit Exceeded");
      setAlertShown(false);
    }

    const short = existingObject?.shortguid || shortguid?.getShortguid("SI");

    // Map and modify data, adding refrecno based on matching itembatchno
    const modifydata = rowData.map((item) => {
      const matchingBatch = item?.batchdetailswithserial?.find(
        (batch) => batch.itembatchno === item.itembatchno
      );

      const updatedItem = {
        ...item,
        availablestockforsale:
          (Number(item?.availablestockforsale) || 0) +
          (Number(item?.returnableqty) || 0),
        itembatcharray: item.itemasbatch === 0 ? [] : item.itembatcharray,
        refrecno: matchingBatch ? matchingBatch.refrecno : null,
      };

      // Remove unnecessary fields
      delete updatedItem.barcodetotlength;
      delete updatedItem.batchColumns;
      delete updatedItem.batchColumnsEditable;
      delete updatedItem.batchdetails;
      delete updatedItem.batchdetailswithserial;

      return updatedItem;
    });

    if (modifydata.length > 0) {
      const invoiceAmount = Number(amount).toFixed(2);
      const roundOff = roundOffAmount(invoiceAmount).toFixed(2);
      const roundOffDiff = (roundOff - invoiceAmount).toFixed(2);

      existingObject = {
        ...existingObject,
        cgstamt: Number(cgstamt).toFixed(2),
        igstamt: Number(igstamt).toFixed(2),
        sgstamt: Number(sgstamt).toFixed(2),
        taxableamount: Number(taxableAmount).toFixed(2),
        amount: invoiceAmount,
        shortguid: short,
        status: status ? statusOptions[1]?.value : statusOptions[0]?.value,
        items: modifydata,
        trdate: purchaseInvDate || auth?.state?.userdata?.workdate,
        podate: purchaseInvDate || auth?.state?.userdata?.workdate,
        roundamt: roundOffDiff,
      };

      // Update the changed rows in the store

      useChangedRows.updateValue(false, existingObject);
    }
  }, [rowData, status]);

  /// date 1 june 2023 validation start ///
  /// date 1 june 2023 validation start ///
  useEffect(() => {
    let isButtonDisabled = false;

    for (let i = 0; i < rowData.length; i++) {
      const {
        packingqty,
        packingextraqty,
        freepackingqty,
        freepackingextraqty,
        manufacturedate,
        expdate,
        itembatchno,
        purrate,
        expdatecompulsory,
        itemasbatch,
        manudatecompulsory,
        wantbatchno,
        changesalerateinbill,
        isservice,
        qty,
        freeqty,
      } = rowData[i];

      if (wantbatchno === 0 && itemasbatch == 0) {
        isButtonDisabled = false;

        break;
      } else {
        console.log(
          "isButtonDisabled 1",
          isButtonDisabled,
          wantbatchno,
          itemasbatch
        );
        if (isservice === 1 && qty + freeqty > 0) {
          isButtonDisabled = false;

          break;
        }

        if (
          (isservice === 0 &&
            wantbatchno &&
            (!rowData[i]?.itembatchno ||
              rowData[i]?.itembatchno == "" ||
              rowData[i]?.itembatchno == null ||
              rowData[i]?.itembatchno == undefined)) ||
          (expdatecompulsory &&
            (!expdate || expdate == "undefined" || Number(expdate) == 0)) ||
          (manudatecompulsory &&
            (!manufacturedate ||
              manufacturedate == "undefined" ||
              Number(manufacturedate) == 0)) ||
          (packingqty === "0" && packingextraqty === "0") ||
          (packingqty === "" && packingextraqty == "") ||
          (packingqty === 0 && packingextraqty === "0") ||
          (packingqty === "0" && packingextraqty === 0) ||
          (packingqty === 0 && packingextraqty === 0) ||
          (rowData[i]?.doctype !== "DC" &&
            rowData[i]?.itemasbatch &&
            Number(rowData[i]?.qty) +
              Number(rowData[i]?.free ? rowData[i]?.free : 0) !==
              rowData[i]?.itembatcharray?.length) ||
          Number(rowData[i].salerate) === 0
        ) {
          isButtonDisabled = true;

          break;
        } else if (
          (isservice == 0 &&
            wantbatchno &&
            (!rowData[i]?.itembatchno ||
              rowData[i]?.itembatchno == "" ||
              rowData[i]?.itembatchno == null ||
              rowData[i]?.itembatchno == undefined)) ||
          (packingqty === "0" && packingextraqty === "0") ||
          (packingqty == "" && packingextraqty == "") ||
          (packingqty === 0 && packingextraqty === "0") ||
          (packingqty === "0" && packingextraqty === 0) ||
          (packingqty === 0 && packingextraqty === 0) ||
          Number(rowData[i].salerate) == 0
        ) {
          isButtonDisabled = true;

          break;
        } else if (
          (isservice == 0 &&
            wantbatchno &&
            (!rowData[i]?.itembatchno ||
              rowData[i]?.itembatchno === "" ||
              rowData[i]?.itembatchno === null ||
              rowData[i]?.itembatchno === undefined)) ||
          (packingqty === "0" && packingextraqty === "0") ||
          (packingqty === "" && packingextraqty === "") ||
          (packingqty === 0 && packingextraqty === "0") ||
          (packingqty === "0" && packingextraqty === 0) ||
          (packingqty === 0 && packingextraqty === 0) ||
          Number(rowData[i].salerate) == 0
        ) {
          isButtonDisabled = true;

          break;
        } else if (!customerData) {
          isButtonDisabled = true;
          break;
        }

        // if (
        //   rowData[i]?.doctype === "DC" &&
        //   rowData[i]?.qty + rowData[i]?.freeqty !== rowData[i]?.dcqty
        // ) {

        //   isButtonDisabled = true;
        // }

        // if (
        //   rowData[i]?.doctype === "DC" &&
        //   rowData[i]?.qty  == rowData[i]?.itembatcharray?.length
        // ) {
        //   console.log("RDYGH",isButtonDisabled,rowData[i].qty)
        //   isButtonDisabled = false;
        //   return
        // }

        if (
          rowData[i]?.doctype === "DC" &&
          rowData[i]?.qty !== rowData[i]?.itembatcharray?.length &&
          wantbatchno === 0
        ) {
          console.log("RDYGH", isButtonDisabled, rowData[i]);
          isButtonDisabled = true;
          // Additional logic or return statement if needed
        }

        if (
          rowData[i]?.doctype === "SO" &&
          (!rowData[i]?.itembatchno ||
            rowData[i]?.itembatchno === "" ||
            rowData[i]?.itembatchno === null ||
            rowData[i]?.itembatchno === undefined)
        ) {
          isButtonDisabled = true;
        }
      }
    }

    useChangedRows.updateValue(
      "validated",
      !isButtonDisabled ? "true" : "false"
    );
    if (rowData.length == 0) {
      useChangedRows.updateValue("validated", "false");
    }
  }, [rowData]);

  /// date 1 june 2023 validation end///
  useEffect(() => {
    // When Row Data Changes we Need to store that data to the tabsStore with Header Data
    var currentData = useChangedRows.getValue();
    if (!currentData?.shortguid) {
      useChangedRows.updateValue("shortguid", shortguid.getShortguid("PO"));
    }

    if (rowData?.length === 0) {
      var items = useChangedRows.getValue("items");
      if (items) {
        // setRowData(items);
        const newdata = items.map((i) => {
          return {
            ...i,
            boxsalerate: Number(i?.salerate) * Number(i?.conversionfactor),
          };
        });
        setRowData(newdata);
      }
    }
  }, []);

  useEffect(() => {
    // When Row Data Changes we Need to store that data to the tabsStore with Header Data
    var currentData = useChangedRows.getValue();
    if (!currentData?.shortguid) {
      useChangedRows.updateValue("shortguid", shortguid.getShortguid("SI"));
    }
    setCustomerData(useChangedRows.getValue("customerrecno"));
    setSiNumber(useChangedRows.getValue("billno"));
    setlocation(useChangedRows.getValue("locationrecno"));

    var items = useChangedRows.getValue("items");
    if (items) {
      // setRowData(items);
      const newdata = items.map((i) => {
        return {
          ...i,
          boxsalerate: Number(i?.salerate) * Number(i?.conversionfactor),
        };
      });
      setRowData(newdata);
    }
  }, [showFilterModal]);

  useEffect(() => {
    if (useChangedRows.getValue("trdate",pageIndex)) {
      setPurchaseInvDate(useChangedRows.getValue("trdate",pageIndex));
    } else {
      useChangedRows.updateValue("trdate", auth?.state?.workdate);
      setPurchaseInvDate(auth?.state?.workdate);
    }
  }, [useChangedRows.getValue("trdate",pageIndex)]);

  useEffect(() => {
    if (tabStore.state.tabs[pageIndex]?.pageStatus == "Success") {
      setRowData([]);
      setigstappl(false);
      setSelectedItem(null);
      setSiNumber("To Be Allotted");
      setCustomerData({});
      setPurchaseInvDate(auth?.state?.userdata?.workdate);
      setIsopening(false)
      setStatusOptions([
        {
          label: "Save",
          value: pageDetails?.defaultStatus,
        },
        {
          label: "Proceed",
          value: pageDetails?.nextStatus,
        },
      ]);
      setstatus(true);
      setvehiclenumber("");
      setdrivername("");
      tabStore.dispatch({
        type: "UPDATE_DATA",
        fieldType: "pageStatus",
        text: null,
      });
      setModalCount((p) => p + 1);
      setCount((p) => p + 1);
      setTimeout(
        tabStore.dispatch({
          type: "UPDATE_DATA",
          fieldType: "pageStatus",
          text: null,
        }),
        1000
      );
    }
    let payload = {};
    FetchLoyality(payload);
  }, [tabStore.state.tabs[pageIndex]?.pageStatus]);

  /// logic for loyality

  const FetchLoyality = useCallback(
    async (payload) => {
      try {
        const response = await axios.post(
          "https://api.example.com/data",
          payload
        );
      } catch (error) {
        console.error("Error fetching data:", error);
        //setLoading(false);
      }
    },
    [tabStore.state.tabs[pageIndex]?.pageStatus]
  );

  //// rendering the table again/////////
  useEffect(() => {
    setShowTable(false);

    setTimeout(() => {
      setShowTable(true);
    }, 200);
  }, [count, rowData?.length, tabStore.state.tabs[pageIndex]?.editIndex]);

  //----------multiple selection item start------------------------------------------------

  const optionsforDC = [
    {
      id: 1,
      optionName: "Challan",
      apiurl: "filterdeliverychalan",
      customerrecno: customerData?.customerrecno,
      doctype: "DC",
    },
  ];

  const optionforSO = [
    {
      id: 2,
      optionName: "Sale Order",
      apiurl: "filtersaleorder",
      customerrecno: customerData?.customerrecno,
      doctype: "SO",
    },
  ];

  function roundOffAmount(amount) {
    const roundedAmount = Number(Math.round(amount * 100) / 100);
    if (roundedAmount < 0.5) {
      return Math.floor(roundedAmount);
    } else {
      return Math.round(roundedAmount);
    }
  }

  //// end///////
  /// logic for itembatchselection////
  const FetchingBtach = async () => {
    //console.log("tradyaT11", customerData?.customerrecno);
    var url = getdomaincustomeritemlist;
    var body = {
      tenantrecno: auth.state.userdata.tenantrecno,
      domainrecno: auth.state.userdata.domainrecno,
      domainuserrecno: auth.state.userdata.domainuserrecno,
      userrolerecno: auth.state.userdata.domainuserrecno,
      noofrecords: 20,
      forflag: "S",
      customerrecno: customerData?.customerrecno,
      batchdetails: true,
      details: true,
      itemrecno: itemForSelection?.itemrecno,
    };
    const resp = await axios
      .post(url, body)
      .then((resp) => {
        setApiData(
          resp?.data?.Message?.filter(
            (item) => item.itemrecno == itemForSelection?.itemrecno
          )[0]
        );
      })
      .catch((err) => {
        console.log(err);
      });

    // setItemForSelection(resp?.data);
    //let x=resp?.data?.Message?.filter((item)=>item.itemrecno==itemForSelection?.itemrecno)
  };

  // useEffect will call FetchingBtach only when opnenBatch changes
  useEffect(() => {
    FetchingBtach();
  }, [opnenBatch == true]);

  const confirm = (e) => {
    console.log(e);
    message.success("Click on Yes");
  };
  const cancel = (e) => {
    console.log(e);
    message.error("Click on No");
  };
  console.log("Rowdata==99>", rowData,auth);
  return (
    <div>
      {/* {maxcredTrue&& <Popconfirm
    title="Delete the task"
    description="Are you sure to delete this task?"
    onConfirm={confirm}
    onCancel={cancel}
    okText="Yes"
    cancelText="No"
    
  >
    ++++++++
  </Popconfirm>} */}
      <Modal
        onOk={() => {
          setopenBatch(false);
          setItemForSelection(null);
          // Here we need to send back the Selection of Batch Details to the parent
          // if (returnValue) {
          //   returnValue={setSelectedItem}
          // }
          //handleRemove(itemForSelection,setRowData,rowData)
        }}
        onCancel={() => {
          setItemForSelection(null);
          setopenBatch(false);
        }}
        open={opnenBatch}
        width={1200}
      >
        <ItemSelectionModal
          item={apidata}
          //forFlag={"S"}
          // selectedItemBatchDetails={getSelectedItemBatchDetails}
          // setterFunction={setSelectedItemBatchDetails}
          pageDetails={pageDetails}
          returnValue={setSelectedItem}
          forPage={"S"}
        />
      </Modal>

      <Modal
        open={displayMessages}
        onCancel={() => {
          setDisplayMessages(!displayMessages);
        }}
        onOk={() => {
          useChangedRows.updateValue(
            "remarks",
            document.getElementById("remarks").value
          );
          useChangedRows.updateValue(
            "narration",
            document.getElementById("narration").value
          );
          setDisplayMessages(!displayMessages);
        }}
      >
        <div style={{ display: "flex", flexDirection: "column" }}>
          <p>Message for Supl</p>
          <Input
            id={"remarks"}
            placeholder="Enter Message for Supl"
            defaultValue={useChangedRows.getValue("remarks")}
          ></Input>
          <br /> <p>Message for Self</p>
          <Input
            id={"narration"}
            placeholder="Enter Message for Self"
            defaultValue={useChangedRows.getValue("narration")}
          ></Input>
        </div>
      </Modal>

      <Modal
        open={displayPrevPO}
        onCancel={() => setDisplayPrevPO(!displayPrevPO)}
        onOk={() => setDisplayPrevPO(!displayPrevPO)}
      >
        <div>
          Select Items from Pending Sale Orders
          <Collapse
            collapsible="header"
            defaultActiveKey={["0"]}
            style={{ height: 300, overflowX: "scroll" }}
          >
            {previousPOList?.Message?.map((item, index) => {
              return (
                <Panel
                  header={`Purchase Order : ${item?.ponumber}`}
                  key={index}
                >
                  <List
                    size="small"
                    itemLayout="horizontal"
                    bordered
                    dataSource={item?.items}
                    renderItem={(it, ind) => {
                      it.selectedQty = it?.selectedQty
                        ? it.selectedQty
                        : it?.qty;
                      return (
                        <List.Item>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              width: "100%",
                              alignItems: "center",
                            }}
                          >
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              {it?.itemdescn} <br></br>Quantity : {it?.qty}
                            </div>
                            <input
                              className="tr001Input"
                              style={{ width: 80 }}
                              type={"number"}
                              defaultValue={it?.selectedQty}
                              onChange={(e) => {
                                it.selectedQty = e.target.value;
                              }}
                            ></input>
                            <Button
                              icon={<FcAddDatabase />}
                              onClick={() => {
                                setInfoFromPO((p) => [...p, it]);
                              }}
                            ></Button>
                          </div>
                        </List.Item>
                      );
                    }}
                  />
                </Panel>
              );
            })}
          </Collapse>
        </div>
      </Modal>

      {/* =============================//*Edit Transaction*\\=================================== */}
      <Modal
        title="Search Bill"
        open={showFilterModal}
        onOk={() => {
          setshowFilterModal(false);
          setcountRender((p) => p + 1);
        }}
        onCancel={() => {
          setshowFilterModal(false);
          setcountRender((p) => p + 1);
        }}
        width={"100%"}
        style={{ top: 20 }}
        key={countRender}
      >
        <SearchFilterByBillNo
          fastpath="RP106"
          setshowFilterModal={setshowFilterModal}
          showFilterModal={showFilterModal}
        />
      </Modal>
      <Modal
        key={displayPrevDC} /// modal count value changes
        width={620}
        open={displayPrevDC}
        onCancel={() => {
          setDisplayPrevDC(false);
          setModalCount((p) => p + 1);
          setreturnData([]);
          setIsopening(!setIsopening)
        }}
        onOk={() => {
          setreturnData(JSON.parse(JSON.stringify(returnData)));

          let newArray = returnData?.map((item) => {
            let pcsBox = convertToBoxAndPieces(
              Number(item?.selectedQty),
              Number(item?.conversionfactor),
              pageDetails?.wantpackingextra
            );

            if (!rowData || !item) {
              alert("Invalid data");
            } else {
              console.log("Set Prev DC444");
              var existingItem = rowData.filter(
                (it) => it && it.itemrecno === item.itemrecno
              );

              if (existingItem.length === 0) {
                console.log("Set Prev DC333");
                // Add item to the list

                console.log(
                  "Purchase Invoice : ",
                  item?.packingqty,
                  " ",
                  item?.packingextraqty
                );
                handleAdd(
                  item,
                  setRowData,
                  item?.igstrate > 0 ? true : false,
                  setSelectedItem,
                  shortguid,
                  item?.salerate ? item.salerate : item?.rate,
                  //Made Changes to Add Box Pieces from Previous Documents
                  pcsBox[1],
                  pcsBox[0], // Made Changes to Add Pieces From Pieces Documents
                  item.freeQuantity,
                  0,
                  item?.itembatchno,
                  0,
                  item?.discountamount > 0 ? item.discountamount : 0,
                  item?.discountamt > 0 ? item.discountamt : 0,
                  0,
                  // item?.amount > 0 ? item.amount : 0,
                  // item?.taxableamount > 0 ? item.taxableamount : 0,
                  // rowData,
                  auth?.state?.userdata?.onedconeinvoice
                );

                ///// commented this line
                // setreturnData([]);
                /////
              } else {
                handleRemovePrev(item, setRowData, rowData);

                setTimeout(() => {
                  handleAdd(
                    item,
                    setRowData,
                    item?.igstrate > 0 ? true : false,
                    setSelectedItem,
                    shortguid,
                    item?.salerate ? item.salerate : item?.rate,
                    //Made Changes to Add Box Pieces from Previous Documents
                    pcsBox[1],
                    pcsBox[0], // Made Changes to Add Pieces From Pieces Documents
                    0,
                    item.freeQuantity,
                    item?.itembatchno,
                    0,
                    item?.discountamount > 0 ? item.discountamount : 0,
                    item?.discountamt > 0 ? item.discountamt : 0,
                    0,
                    // item?.amount > 0 ? item.amount : 0,
                    // item?.taxableamount > 0 ? item.taxableamount : 0,
                    // rowData,
                    auth?.state?.userdata?.onedconeinvoice
                  );

                  setreturnData([]);
                }, 200);
              }
            }
          });

          setDisplayPrevDC(false);
          setModalCount((p) => p + 1);
        }}
      >
        <MultipleSelectionItem
          options={optionsforDC}
          selectedItemsArray={returnData}
          selectOnlyOneDoc={auth?.state?.userdata?.onedconeinvoice}
          returnValue={(e) => {
            // setreturnData([...e]);
            setreturnData(e);
          }}
          todate={purchaseInvDate}
          forFlag={"si"} //KMK:31/03/23 Dont Change the place of this Key Value
          pageDetails={pageDetails}
          isopening={isopening}
        />
      </Modal>

      <Modal
        key={modalcount}
        width={620}
        open={displayPrevSO}
        onCancel={() => {
          setDisplayPrevSO(!displayPrevSO);
          // setCount((p) => p + 1);
        }}
        onOk={() => {
          let newArray = returnData.map((item) => {
            let pcsBox = convertToBoxAndPieces(
              Number(item?.selectedQty),
              Number(item?.conversionfactor),
              pageDetails?.wantpackingextra
            );

            if (!rowData || !item) {
              alert("Invalid data");
            } else {
              var existingItem = rowData.filter(
                (it) => it && it.itemrecno === item.itemrecno
              );

              if (existingItem.length === 0) {
                // Add item to the list
                handleAdd(
                  item,
                  setRowData,
                  item?.igstrate > 0 ? true : false,
                  setSelectedItem,
                  shortguid,
                  item?.salerate ? item.salerate : item?.rate,
                  //Made Changes to Add Box Pieces from Previous Documents
                  pcsBox[1],
                  pcsBox[0], // Made Changes to Add Pieces From Pieces Documents
                  0,
                  item.freeQuantity,
                  item?.itembatchno,
                  0,
                  item?.discountamount > 0 ? item.discountamount : 0,
                  item?.discountamt > 0 ? item.discountamt : 0,
                  0,
                  item?.amount > 0 ? item.amount : 0,
                  item?.taxableamount > 0 ? item.taxableamount : 0,
                  rowData
                );

                setreturnData([]);
              } else {
                // var totalBoxQty = existingItem.reduce((acc, el) => {
                //   console.log("Item Existing Array is ", item, el)

                //   (acc += Number(el?.packingextraqty))
                // }, 0) +  Number(pcsBox[0])
                // var totalPcsQty = existingItem.reduce((acc, el) => (acc += Number(el?.packingqty)), 0) +  Number(pcsBox[1])

                handleRemovePrev(item, setRowData, rowData);

                setTimeout(() => {
                  handleAdd(
                    item,
                    setRowData,
                    item?.igstrate > 0 ? true : false,
                    setSelectedItem,
                    shortguid,
                    item?.salerate ? item.salerate : item?.rate,
                    //Made Changes to Add Box Pieces from Previous Documents
                    pcsBox[1],
                    pcsBox[0], // Made Changes to Add Pieces From Pieces Documents
                    0,
                    item.freeQuantity,
                    item?.itembatchno,
                    0,
                    item?.discountamount > 0 ? item.discountamount : 0,
                    item?.discountamt > 0 ? item.discountamt : 0,
                    0,
                    item?.amount > 0 ? item.amount : 0,
                    item?.taxableamount > 0 ? item.taxableamount : 0
                  );

                  setreturnData([]);
                }, 200);
              }
            }
          });
          setDisplayPrevSO(!displayPrevSO);
          setModalCount((count) => count + 1);
        }}
      >
        <MultipleSelectionItem
          options={optionforSO}
          selectedItemsArray={returnData}
          returnValue={(e) => {
            setreturnData(e);
          }}
          forFlag={"si"} //KMK:31/03/23 Dont Change the place of this Key Value
          todate={purchaseInvDate}
        />
      </Modal>

      <Collapse collapsible="header" defaultActiveKey={["1"]}>
        <Panel header="Header Details" key="1">
          <div>
            <Space
              style={{
                display: "flex",
                flexWrap: "wrap",
                columnGap: "40px", // Adjust the column gap as needed
                rowGap: "15px",
                marginTop: "5px",
              }}
            >
              {/* Select Supplier */}
              {/* //!Upper filter box red color */}
              <div
                style={{ display: "flex", gap: "24px", alignItems: "center" }}
              >
                <form
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    columnGap: "40px", // Adjust the column gap as needed
                    rowGap: "10px",
                    marginTop: "5px",
                  }}
                >
                  <div
                    onKeyDown={enterPress}
                    tabIndex={rowData && rowData.length > 0 ? -1 : 0} // Disables keyboard focus when rowData is present
                    style={{
                      zIndex: 11,
                      position: "relative",
                      pointerEvents:
                        rowData && rowData.length > 0 ? "none" : "auto",
                      opacity: rowData && rowData.length > 0 ? 0.5 : 1,
                      marginTop: "10px",
                    }}
                  >
                    <SaveDropdown
                      defaultSelected={customerData}
                      placeholder={"Select Cust"}
                      displayLabel="Cust"
                      alignment="column"
                      apikey="customerrecno"
                      apiurl="getdomaincustomerlist"
                      selectionLabel="customerdescn"
                      selectionValue="customerrecno"
                      disabled={rowData && rowData.length > 0 ? 1 : 0}
                      returnValue={(event) => {
                        {
                          /* // nirmal-389 adding values with bold*/
                        }
                        if (event?.hasOwnProperty("zincode")) {
                          // task rajendra id-240
                          if (event?.zincode) {
                            // task rajendra id-240
                            setCustomerData(event);
                          } else {
                            alert(
                              "Customer has no Zincode. Please Add Zincode to start billing"
                            );
                            // setModalCount(() => modalcount + 1)
                            if (customerData == 0) {
                              setCustomerData(null);
                            } else {
                              setCustomerData(0);
                            }
                          }
                        } else {
                          setCustomerData(event);
                        }
                      }}
                      key={count}
                      isrequired={1}
                    />
                    <p>{customerData ? customerData?.remarks : ""}</p>
                  </div>
                  <div onKeyDown={enterPress} style={{ marginTop: "10px" }}>
                    <SaveDropdown
                      defaultSelected={location}
                      displayLabel="Cust Locn"
                      placeholder={"Select Locn"}
                      alignment="column"
                      apiurl="domaincustomerlocationlist"
                      apikey="customerlocationrecno"
                      selectionLabel="descn"
                      selectionValue="customerlocationrecno"
                      returnValue={() => setlocation()}
                      body={{ customerrecno: customerData?.customerrecno }}
                      key={count}
                    />
                  </div>

                  {/* <div onKeyDown={enterPress} style={{  marginTop: "-25px" }}>
                    <p>SI Number</p>
                    <input
                      style={{ ...poNumberStyle }}
                      placeholder={siNumber ? siNumber : "TBA"}
                      disabled
                      key={modalcount}
                    />
                  </div> */}
                  <div onKeyDown={enterPress} style={{ marginTop: "10px" }}>
                    <div style={{ position: "relative" }}>
                      <label
                        // htmlFor={id}
                        className={`label ${"focused"}`}
                        style={{
                          zIndex: 1000,
                          whiteSpace: "nowrap",
                          wordWrap: "unset",
                          position: "absolute",
                          top: "-19px",
                          left: "12px",
                          //backgroundColor: 'white',
                          padding: "0 4px",
                          fontSize: "12px",
                          color: "black",
                          pointerEvents: "none",
                          transform: "translateY(0)",
                          transition:
                            "top 0.3s, transform 0.3s, font-size 0.3s, color 0.3s",
                        }}
                      >
                        SI Number
                      </label>
                      <input
                        style={{ ...poNumberStyle }}
                        placeholder={siNumber ? siNumber : "To be allotted"}
                        disabled
                        key={modalcount}
                      />
                    </div>
                  </div>
                  <div
                    onKeyDown={enterPress}
                    style={
                      rowData && rowData.length > 0
                        ? {
                            pointerEvents: "none",
                            opacity: 0.5,
                            marginTop: "10px",
                          }
                        : { marginTop: "10px" }
                    }
                  >
                    <SaveDatePicker
                      // disabled={fastPath=="TR013"?true:false}
                      disabled={rowData && rowData.length > 0 ? 1 : 0}
                      defaultValue={purchaseInvDate}
                      pageIndex={pageIndex}
                      placeholder={"Select SI Date"}
                      id="tr013-sale-invoice"
                      displayLabel="SI Date"
                      apiKey="trdate"
                      returnValue={(e) => {
                        setPurchaseInvDate(e);
                      }}
                      dateLessthan={auth?.state?.userdata?.workdate} //! If this is false, postAlertMsg will trigger
                      postAlertMsg={`SI Date cannot be greater than Today's Date or less than Opening date`}
                      dateGreaterthan={auth?.state?.userdata?.openingbaldate} // New Date Validation
                    />
                  </div>

                  {/* Status */}
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    onKeyDown={enterPress}
                  >
                    <div style={{ position: "relative" }}>
                      {/* <label
                        className={`label ${"focused"}`}
                        style={{
                          // zIndex: 1000,
                          whiteSpace: "nowrap",
                          wordWrap: "unset",
                          position: "absolute",
                          top: "-12px",
                          left: "12px",
                          // backgroundColor: "white",
                          padding: "0 4px",
                          fontSize: "12px",
                          color: "black",
                          pointerEvents: "none",
                          transform: "translateY(0)",
                          transition:
                            "top 0.3s, transform 0.3s, font-size 0.3s, color 0.3s",
                        }}
                      >
                        Status
                      </label>
                      <div
                        onKeyDown={enterPress}
                        className="saveproceed"
                        style={{
                          padding: "8.7px",
                          marginTop: "4px",
                        }}
                      >
                        <SaveRadioBtn
                          apikey={"status"}
                          key={count}
                          // defaultValue={pageDetails?.defaultStatus}
                          options={statusOptions}
                        />
                      </div> */}
                      <SaveCheckbox
                        displayLabel={"Proceed"}
                        defaultValue={status}
                        editable
                        apikey={"status"}
                        returnValue={(e) => setstatus(e)}
                      />
                    </div>
                  </div>

                  <div onKeyDown={enterPress} style={{ marginTop: "10px" }}>
                    <AdditionalDetails
                      customerrecno={customerData}
                      filterOption={pageDetails?.additionalDetails}
                      key={customerData}
                    />
                  </div>
                  <div
                    style={{ textAlign: "right", marginTop: "10px" }}
                    onKeyDown={enterPress}
                  >
                    <Button
                      // amol-390 - added style
                      style={{ height: "36px" }}
                      className="btn"
                      type="default"
                      onClick={() => {
                        setshowFilterModal(true);
                        setcountRender((p) => p + 1);
                      }}
                    >
                      Search Bill No.
                    </Button>
                  </div>

                  {/* amol-temp - changed in style */}
                  <div
                    onKeyDown={enterPress}
                    style={{ textAlign: "right", marginTop: "10px" }}
                  >
                    <Button
                      //style={{ ...buttonStyle }}
                      className="btn"
                      disabled={
                        auth?.state?.userdata?.onedconeinvoice &&
                        rowData.length > 0 &&
                        (rowData[0].doctype == "DC" ||
                          rowData[0].doctype == "SO" ||
                          !rowData[0]?.doctype)
                          ? true
                          : false
                      }
                      onClick={(e) => {
                        if (customerData) {
                          setDisplayPrevDC(true);
                          setreturnData([]);
                          setIsopening(false)
                        } else {
                          alert("Select Customer");
                        }
                      }}
                    >
                      Pick from DC
                    </Button>
                  </div>
                  {auth?.state?.userdata?.wantopeningtransaction && (
                    <div
                      onKeyDown={enterPress}
                      style={{ textAlign: "right", marginTop: "10px" }}
                    >
                      <Button
                        //style={{ ...buttonStyle }}
                        className="btn"
                        disabled={
                          auth?.state?.userdata?.onedconeinvoice &&
                          rowData.length > 0 &&
                          (rowData[0].doctype == "DC" ||
                            rowData[0].doctype == "SO" ||
                            !rowData[0]?.doctype)
                            ? true
                            : false
                        }
                        onClick={(e) => {
                          if (customerData) {
                            setDisplayPrevDC(true);
                            setIsopening(true)
                            setreturnData([]);
                          } else {
                            alert("Select Customer");
                          }
                        }}
                      >
                        Pick from Opening DC
                      </Button>
                    </div>
                  )}
                  <div
                    onKeyDown={enterPress}
                    style={{ textAlign: "right", marginTop: "10px" }}
                  >
                    <Button
                      //style={{ ...buttonStyle }}
                      className="btn"
                      disabled={
                        auth?.state?.userdata?.onedconeinvoice &&
                        rowData.length > 0 &&
                        rowData[0].doctype == "DC"
                          ? true
                          : false
                      }
                      onClick={() => {
                        if (customerData) {
                          setDisplayPrevSO(!displayPrevSO);
                        } else {
                          alert("Select Customer");
                        }
                      }}
                    >
                      Pick from SO
                    </Button>
                  </div>

                  <div
                    onKeyDown={enterPress}
                    style={{ textAlign: "right", marginTop: "10px" }}
                  >
                    <Button
                      className="btn"
                      // amol-temp - changed in style and removed type="dashed"
                      //style={{ ...buttonStyle }}
                      onClick={(e) => setDisplayMessages(!displayMessages)}
                    >
                      Add / View Messages
                    </Button>
                  </div>

                  <div onKeyDown={enterPress} style={{ marginTop: "15px" }}>
                    <SaveCheckbox
                      apikey={"isPrint"}
                      displayLabel="Print SI" ///here Lable Change Print -Print SI
                      editable
                      key={modalcount} /// clear after save butoon date 27jun 2023
                    />
                  </div>
                </form>
              </div>
            </Space>
          </div>

          {/* </div> */}
        </Panel>

        <Panel header="Items Details " key="3">
          <Space size="large">
            <div
              style={{
                zIndex: 4,
                position: "relative",
                pointerEvents:
                  rowData?.length > 0 &&
                  auth?.state?.userdata?.onedconeinvoice &&
                  rowData[0].doctype == "DC"
                    ? "none"
                    : null,
              }}
            >
              {/* <ItemSearch
                itemSearchRef={itemSearch}
                forFlag={"S"}
                returnValue={setSelectedItem}
                supplierdata={customerData?.customerrecno}
                dropdown
                defaultSelectedItem={getSelectedItem}
                selection
                /// task id-269 rajendra If item has isservicetrue = 1 then don't calculate stock.
                //selection /// rajendra  may 30 two selection key was there
                barcodeReturnValue={(e) => {
                  // Write a function to add to existing Items and check only if it has serial number
                  if (e?.itemasbatch == 1) {
                    fetchItems
                      .addBarcodeScannedItemToTable(
                        rowData,
                        e,
                        "SIF",
                        igstappl,
                        pageDetails,
                        "S"
                      )
                      .then((res) => {
                       
                        if (res?.length == 0) {
                          // Rajendra the same barcode grid getting blank date 9 jun 2023
                          setRowData(rowData);
                          return;
                        } else {
                          setRowData(res);
                          setChange(change + 1);
                        } // Rajendra the same barcode grid getting blank date 9 jun 2023
                      })
                      .catch((err) => alert(err));
                  } else {
                    setSelectedItem(e);
                  }
                }}
              /> */}

              <ItemSearch
                itemSearchRef={itemSearch}
                forFlag={"S"}
                returnValue={setSelectedItem}
                supplierdata={customerData?.customerrecno}
                dropdown
                defaultSelectedItem={getSelectedItem}
                selection
                pageDetails={pageDetails}
                // task id-269 rajendra If item has isservicetrue = 1 then don't calculate stock.
                //selection /// rajendra  may 30 two selection key was there
                barcodeReturnValue={(e) => {
                  // console.log("itemsearch e", e);

                  const bill = e?.itembatcharrayfound[0];
                  const sidate = bill?.inwarddate;
                  const dcdate = bill?.dcbilldate;
                  // const prdate = new Date().toISOString().slice(0, 10).replace(/-/g, ''); // Assuming prdate is in YYYYMMDD format
                  console.log("itemsearch e", e, sidate, purchaseInvDate);

                  // Check the conditions for serial number and stock availability
                  if (e?.serialnofound === false) {
                    notification.error({
                      message: `Error`,
                      duration: 1,
                      description: `Serial number not exist in system`,
                      style: { background: "#efa7a7" },
                    });
                    return;
                  } else if (
                    e?.serialnofound === true &&
                    e?.itembatcharrayfound?.length === 0
                  ) {
                    notification.error({
                      message: `Error`,
                      duration: 1,
                      description: `Stock not available`,
                      style: { background: "#efa7a7" },
                    });
                    return;
                  }

                  if (bill) {
                    if (sidate && sidate > Number(purchaseInvDate)) {
                      notification.error({
                        message: `Error`,
                        duration: 1,
                        description: `SI date less than PI date`,
                        style: { background: "#efa7a7" },
                      });
                    } else {
                      // Proceed with the existing logic
                      if (e?.itemasbatch == 1) {
                        fetchItems
                          .addBarcodeScannedItemToTable(
                            rowData,
                            e,
                            "SIF",
                            igstappl,
                            pageDetails,
                            "S"
                          )
                          .then((res) => {
                            console.log("New Rows Data", res);
                            if (res?.length == 0) {
                              // Rajendra the same barcode grid getting blank date 9 jun 2023
                              setRowData(rowData);
                              return;
                            } else {
                              setRowData(res);
                              setChange(change + 1);
                            } // Rajendra the same barcode grid getting blank date 9 jun 2023
                          })
                          .catch((err) =>
                            notification.error({
                              message: `Error`,
                              description: err,
                            })
                          );
                      } else {
                        setSelectedItem(e);
                      }
                    }
                  } else {
                    notification.error({
                      message: `Error`,
                      duration: 1,
                      description: `Stock not available`,
                      style: { background: "#efa7a7" },
                    });
                  }
                }}
              />
            </div>
            <div>
              {getSelectedItem?.wantbatchno == 0 &&
              getSelectedItem?.itemasbatch == 0 &&
              getSelectedItem?.isservice == 0 ? (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <p style={{ marginTop: "20px", padding: "5px" }}>
                    Available Stock:{getSelectedItem?.availablestock}
                  </p>
                  <div onKeyDown={enterPress}>
                    <p>
                      Pieces<span style={{ color: "red" }}>*</span>
                    </p>
                    <input
                      ref={piecesRef}
                      style={{ ...inputStyles }}
                      // disabled={getSelectedItem?.packingextrarecno==0? false:true}
                      value={selectedQty}
                      type="number"
                      onChange={(e) => {
                        const regex = /^[0-9\b]+$/;
                        const value = e.target.value;
                        if (value === "" || regex.test(value)) {
                          setSelectedQty(value);
                        }
                      }}
                    />
                  </div>
                  <div style={{ marginTop: "20px" }}>
                    <Button
                      style={{ ...btnStyles }}
                      className="btn item-search-btn"
                      icon={<IoAperture size="1.2rem" />}
                      size="large"
                      onClick={() => {
                        addItemstoTable();
                      }}
                    >
                      Add
                    </Button>
                  </div>
                </div>
              ) : null}
            </div>
            {getSelectedItem?.itemasbatch ? (
              <div style={{ ...callbackTextInputStyles, alignItems: "end" }}>
                {getSelectedItem?.packingextrarecno > 0 &&
                pageDetails?.wantpackingextra ? (
                  <div onKeyDown={enterPress}>
                    <p>
                      Box <span style={{ color: "red" }}>*</span>
                      {getSelectedItem?.conversionfactor &&
                        +getSelectedItem?.conversionfactor}
                    </p>
                    <input
                      //  disabled={getSelectedItem?.packingextrarecno>0? false:true}
                      ref={boxRef}
                      style={{ ...inputStyles }}
                      value={box}
                      onChange={(e) => {
                        const regex = /^[0-9\b]+$/;
                        const value = e.target.value;
                        if (value === "" || regex.test(value)) {
                          setBox(value);
                        }
                      }}
                      type="number"
                    />
                  </div>
                ) : null}
                <div onKeyDown={enterPress}>
                  <p>
                    Pieces<span style={{ color: "red" }}>*</span>
                  </p>
                  <input
                    ref={piecesRef}
                    style={{ ...inputStyles }}
                    // disabled={getSelectedItem?.packingextrarecno==0? false:true}
                    value={selectedQty}
                    type="number"
                    onChange={(e) => {
                      const regex = /^[0-9\b]+$/;
                      const value = e.target.value;
                      if (value === "" || regex.test(value)) {
                        setSelectedQty(value);
                      }
                    }}
                  />
                </div>

                {pageDetails?.wantfreeqty ? (
                  <>
                    {getSelectedItem?.packingextrarecno > 0 ? (
                      <div onKeyDown={enterPress}>
                        <p>Free Boxes</p>
                        <input
                          ref={piecesRef}
                          style={{ ...inputStyles }}
                          // disabled={getSelectedItem?.packingextrarecno>0? false:true}
                          value={freeBox}
                          type="number"
                          onChange={(e) => {
                            const regex = /^[0-9\b]+$/;
                            const value = e.target.value;
                            if (value === "" || regex.test(value)) {
                              setFreeBox(value);
                            }
                          }}
                        />
                      </div>
                    ) : null}
                    <div onKeyDown={enterPress}>
                      <p>Free Pieces</p>
                      <input
                        ref={piecesRef}
                        style={{ ...inputStyles }}
                        // disabled={getSelectedItem?.packingextrarecno==0? false:true}
                        value={freeQty}
                        type="number"
                        onChange={(e) => {
                          const regex = /^[0-9\b]+$/;
                          const value = e.target.value;
                          if (value === "" || regex.test(value)) {
                            setFreeQty(value);
                          }
                        }}
                      />
                    </div>
                  </>
                ) : null}

                {getSelectedItem?.wantbatchno ? (
                  <>
                    {" "}
                    <div onKeyDown={enterPress}>
                      <p>
                        Batch No.<span style={{ color: "red" }}>*</span>
                      </p>
                      <input
                        ref={piecesRef}
                        style={{
                          ...inputStyles,
                          width: "160px",
                          textAlign: "left",
                        }}
                        value={batchNo}
                        onChange={(e) => setBatchNo(e.target.value)}
                        onBlur={(e) => {
                          /// batch validation if itemsbatch is true or false if batch is true it should not asked for batch again
                          if (getSelectedItem?.wantbatchno) {
                            console.log("Batch No", getSelectedItem);
                            if (!e.target.value) {
                              alert("Batch Cannot Be Empty");
                            }
                            return;
                          }
                        }}
                      />
                    </div>
                    {pageDetails?.wantmanudate > 0 ? (
                      <div onKeyDown={enterPress}>
                        <p>
                          Mfg. Date <span style={{ color: "red" }}>*</span>
                        </p>
                        <CallbackDateInput
                          defaultValue={
                            getSelectedItem?.wantbatchno == 0 &&
                            getSelectedItem?.manudatecompulsory == 0
                              ? auth?.state?.userdata?.workdate
                              : manuDate
                          }
                          id={"tr002-manufacture-date-callback"}
                          // displayLabel={"Expiry Date"}
                          apiKey={"manudate"}
                          returnValue={(e) => {
                            console.log("!manuDate", manuDate, e);
                            if (!manuDate && !e) {
                              {
                                /*Kaushik : Added !e to check if response is coming */
                              }

                              setManuDate(auth?.state?.userdata?.workdate);
                            } else {
                              setManuDate(e);
                            }
                            console.log(
                              "Expiry Date should be greater than Mfg. Date",
                              e
                            );
                            document
                              .getElementById("tr002-exp-date-callback")
                              .focus();
                          }}
                          initial
                          // dateLessthan={}
                          key={modalcount}
                        />
                      </div>
                    ) : null}
                    {pageDetails?.wantexpdate > 0 ? (
                      <div onKeyDown={enterPress}>
                        <p>
                          Expiry Date <span style={{ color: "red" }}>*</span>
                        </p>
                        <CallbackDateInput
                          defaultValue={
                            getSelectedItem?.wantbatchno == 0 &&
                            getSelectedItem?.expdatecompulsory == 0
                              ? "99991231"
                              : expDate
                          }
                          id={"tr002-exp-date-callback"}
                          // displayLabel={"Expiry Date"}
                          apiKey={"manudate"}
                          initial
                          dateGreaterthan={manuDate} //! If this is false, postAlertMsg will trigger
                          postAlertMsg="Expiry Date should not be lesser than Mfg. Date"
                          returnValue={(e) => {
                            if (e <= manuDate) {
                              document.getElementById(
                                "po-manufacture-date-callback"
                              ).style.color = "red";
                              setExpDate(0);
                            } else {
                              setExpDate(e);
                            }
                          }}
                          key={modalcount}
                        />
                      </div>
                    ) : null}
                  </>
                ) : null}

                {getSelectedItem?.packingextrarecno > 0 &&
                pageDetails?.wantpackingextra ? (
                  <div onKeyDown={enterPress}>
                    <p>Box Rate</p>
                    <input
                      type="number"
                      style={{ ...inputStyles, width: "100px" }}
                      value={boxSaleRate}
                      disabled={true}
                    />
                  </div>
                ) : null}

                <div onKeyDown={enterPress}>
                  <p>
                    Rate <span style={{ color: "red" }}>*</span>
                  </p>
                  <input
                    type="number"
                    style={{ ...inputStyles, width: "100px" }}
                    value={salerate}
                    disabled={
                      pageDetails?.changepurrateinbill === 0 ? true : false
                    } //  17 Oct 2023
                    onChange={(e) => {
                      if (getSelectedItem?.changesalerateinbill) {
                        const regex = /^\d*(\.\d{0,2})?$/;
                        const value = e.target.value;
                        if (value === "" || regex.test(value)) {
                          setSaleRate(value);
                        }
                      }
                    }}
                    onBlur={(e) => {
                      const regex = /^[0-9\b]+$/;
                      const value = e.target.value;
                      console.log("TR002 rate value", value);
                      if (value == "0.00" || value == "0.0") {
                        alert("Cannot enter 0.00 as rate");
                        return false;
                      }

                      if (value === "" || regex.test(value)) {
                        if (value != 0 && value > 0) {
                          if (
                            box == 0 &&
                            selectedQty == 0 &&
                            freeQty == 0 &&
                            freeBox == 0
                          ) {
                            alert("Enter non-zero values in Box / Pieces");
                            boxRef.current.focus();
                          } else {
                            btnRef.current.focus();
                          }
                        } else {
                          alert("Cannot enter 0 as rate");
                          return false;
                        }
                      }
                    }}
                  />
                </div>
                <div onKeyDown={enterPress}>
                  <p>Gross Amount</p>
                  <input
                    disabled
                    value={Number(grossAmt).toFixed(2)}
                    style={{ ...grossAmtStyle, width: 120 }}
                  />
                </div>

                <div onKeyDown={enterPress}>
                  <p>Discount %</p>
                  <input
                    style={{ ...inputStyles, width: "75px" }}
                    value={discountPer}
                    type="number"
                    id="tr002-discount-perc"
                    onChange={(e) => {
                      const regex = /^\d*(\.\d{0,2})?$/;
                      const value = e.target.value;

                      if (
                        (value === "" && value < 99.99) ||
                        (regex.test(value) && value < 99.99)
                      ) {
                        setDiscountPer(value);
                        console.log("Gross Amt IN Discount", grossAmt);
                        var dicsountAmount =
                          (Number(grossAmt) * Number(value)) / 100;
                        setDiscount(dicsountAmount.toFixed(2));
                      }
                    }}
                    onBlur={(e) => {
                      let temp = e.target.value;
                      temp != "" &&
                        setDiscountPer(Number(e.target.value)?.toFixed(2));
                      var dicsountAmount =
                        (Number(grossAmt) * Number(e.target.value)) / 100;
                      setDiscount(dicsountAmount.toFixed(2));
                    }}
                  />
                </div>

                <div onKeyDown={enterPress}>
                  <p>Discount</p>
                  <input
                    style={{ ...inputStyles, width: "110px" }}
                    value={discount}
                    type="number"
                    disabled={discountPer !== 0}
                    onChange={(e) => setDiscount(e.target.value)}
                  />
                </div>
                <div onKeyDown={enterPress}>
                  <p>Taxable Amount</p>
                  <input
                    disabled
                    value={Number(taxableAmt).toFixed(2)}
                    style={{ ...grossAmtStyle, width: 120 }}
                  />
                </div>
                <div>
                  <Button
                    style={{ ...btnStyles }}
                    className="btn item-search-btn"
                    icon={<IoAperture size="1.2rem" />}
                    size="large"
                    onClick={() => {
                      addItemstoTable();
                    }}
                  >
                    Add
                  </Button>
                </div>
              </div>
            ) : null}
          </Space>
          <div>
            {showTable && rowData.length > 0 ? (
              <Table
                className="tr013-items-grid"
                scroll={{
                  y: "calc(80vh - 260px)",
                  x: 500,
                }}
                size="large"
                bordered
                pagination={false}
                columns={piColumns(
                  igstappl,
                  setRowData,
                  rowData,
                  scandata,
                  setScandata,
                  setOpenItem,
                  auth.state.userdata.workdate,
                  setModal,
                  scanner,
                  auth?.state?.userdata?.onedconeinvoice,
                  setScanner,
                  pageDetails,
                  setItemForSelection,
                  setopenBatch
                )?.filter((item) => !item.hidden)}
                dataSource={rowData}
                onChange={(e) => {}}
                summary={(pageData) => {
                  let qty = 0;
                  let free = 0;
                  let packingextraqty = 0;
                  let packingqty = 0;
                  let freepackingextraqty = 0;
                  let freepackingqty = 0;

                  var existingObject = useChangedRows.getValue();
                  console.log("Existing Object is", pageData);

                  const invoiceAmount = existingObject?.amount;
                  setinvoiceAmount(invoiceAmount);
                  const roundOff = roundOffAmount(invoiceAmount).toFixed(2);
                  const roundOffDiff = roundOff - invoiceAmount;
                  console.log("roundOffDiff", roundOffDiff);
                  let drCr;
                  if (roundOffDiff < 0) {
                    drCr = "Dr - Rounding";
                    setRoundDiff(roundOffDiff);
                    setTypeAmt(drCr);
                  } else if (roundOffDiff > 0) {
                    drCr = "Cr - Rounding";
                    setRoundDiff(roundOffDiff);
                    setTypeAmt(drCr);
                  }
                  // amol-382 - changes in condition as if there is no rounding message not display
                  else {
                    drCr = "";
                    setRoundDiff(roundOffDiff);
                    setTypeAmt(drCr);
                  }

                  const displayValue = Math.abs(roundOffDiff).toFixed(2);
                  setdisplayValue(displayValue);

                  pageData.forEach((item) => {
                    qty = Number(qty) + Number(item?.qty);
                    free = Number(free) + Number(item?.free);
                    packingextraqty =
                      Number(packingextraqty) + Number(item?.packingextraqty);
                    packingqty = Number(packingqty) + Number(item?.packingqty);
                    freepackingextraqty =
                      Number(freepackingextraqty) +
                      Number(item?.freepackingextraqty);
                    freepackingqty =
                      Number(freepackingqty) + Number(item?.freepackingqty);
                  });

                  return (
                    // amol-382 - removed fixed property and className="tr013-items-footer" property from Table.Summary
                    <Table.Summary style={{ position: "relative", zIndex: 0 }}>
                      {!igstappl ? (
                        <Table.Summary.Row>
                          <Table.Summary.Cell></Table.Summary.Cell>
                          {/* nohit-425 Change Position of Total Caption */}
                          <Table.Summary.Cell>
                            <p style={{ ...pStyles, textAlign: "left" }}>
                              Total :{" "}
                            </p>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell>{null}</Table.Summary.Cell>
                          <Table.Summary.Cell>{null}</Table.Summary.Cell>
                          {pageDetails?.wantpackingextra ? (
                            <Table.Summary.Cell>
                              <p style={{ ...pStyles }}>{packingextraqty}</p>
                            </Table.Summary.Cell>
                          ) : null}

                          <Table.Summary.Cell>
                            {" "}
                            <p style={{ ...pStyles }}>{packingqty}</p>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell>
                            {" "}
                            <p style={{ ...pStyles }}>{qty}</p>
                          </Table.Summary.Cell>

                          {pageDetails?.wantfreeqty &&
                          pageDetails?.wantpackingextra ? (
                            <Table.Summary.Cell>
                              <p style={{ ...pStyles }}>
                                {freepackingextraqty}
                              </p>
                            </Table.Summary.Cell>
                          ) : null}

                          {pageDetails?.wantfreeqty ? (
                            <Table.Summary.Cell>
                              <p style={{ ...pStyles }}>{freepackingqty}</p>
                            </Table.Summary.Cell>
                          ) : null}

                          {pageDetails?.wantfreeqty ? (
                            <Table.Summary.Cell>
                              <p style={{ ...pStyles }}>{free}</p>
                            </Table.Summary.Cell>
                          ) : null}

                          {pageDetails?.wantbatchno ? (
                            <Table.Summary.Cell></Table.Summary.Cell>
                          ) : null}

                          {pageDetails?.wantmanudate > 0 ? (
                            <Table.Summary.Cell></Table.Summary.Cell>
                          ) : null}
                          {pageDetails?.wantexpdate > 0 ? (
                            <Table.Summary.Cell></Table.Summary.Cell>
                          ) : null}
                          {pageDetails?.wantpackingextra ? (
                            <Table.Summary.Cell></Table.Summary.Cell>
                          ) : null}
                          <Table.Summary.Cell></Table.Summary.Cell>
                          <Table.Summary.Cell></Table.Summary.Cell>
                          <Table.Summary.Cell></Table.Summary.Cell>
                          {pageDetails?.saleitemnarration === 1 ? (
                            <Table.Summary.Cell></Table.Summary.Cell>
                          ) : null}
                          <Table.Summary.Cell>
                            <p style={{ ...pStyles }}>
                              {existingObject?.taxableamount}
                            </p>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell></Table.Summary.Cell>
                          <Table.Summary.Cell>
                            <p style={{ ...pStyles }}>
                              {existingObject?.sgstamt}
                            </p>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell></Table.Summary.Cell>
                          <Table.Summary.Cell>
                            <p style={{ ...pStyles }}>
                              {existingObject?.cgstamt}
                            </p>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell>
                            <p style={{ ...pStyles }}>
                              {existingObject?.amount}
                            </p>
                          </Table.Summary.Cell>
                        </Table.Summary.Row>
                      ) : (
                        <Table.Summary.Row>
                          <Table.Summary.Cell></Table.Summary.Cell>
                          {/* nohit-425 Change Position of Total Caption */}
                          <Table.Summary.Cell>
                            <p style={{ ...pStyles, textAlign: "left" }}>
                              Total :{" "}
                            </p>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell>{null}</Table.Summary.Cell>
                          <Table.Summary.Cell>{null}</Table.Summary.Cell>

                          {pageDetails?.wantpackingextra ? (
                            <Table.Summary.Cell>
                              <p style={{ ...pStyles }}>{packingextraqty}</p>
                            </Table.Summary.Cell>
                          ) : null}
                          {/* amol-temp - added p tag and applied style to p tag...
                          for packingqty and qty*/}
                          <Table.Summary.Cell>
                            <p style={{ ...pStyles }}>{packingqty}</p>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell>
                            <p style={{ ...pStyles }}>{qty}</p>
                          </Table.Summary.Cell>

                          {pageDetails?.wantfreeqty &&
                          pageDetails?.wantpackingextra ? (
                            <Table.Summary.Cell>
                              <p style={{ ...pStyles }}>
                                {freepackingextraqty}
                              </p>
                            </Table.Summary.Cell>
                          ) : null}

                          {pageDetails?.wantfreeqty ? (
                            <Table.Summary.Cell>
                              <p style={{ ...pStyles }}>{freepackingqty}</p>
                            </Table.Summary.Cell>
                          ) : null}

                          {pageDetails?.wantfreeqty ? (
                            <Table.Summary.Cell>
                              {" "}
                              <p style={{ ...pStyles }}>{free}</p>
                            </Table.Summary.Cell>
                          ) : null}

                          {pageDetails?.wantbatchno ? (
                            <Table.Summary.Cell></Table.Summary.Cell>
                          ) : null}

                          {pageDetails?.wantmanudate > 0 ? (
                            <Table.Summary.Cell></Table.Summary.Cell>
                          ) : null}
                          {pageDetails?.wantexpdate > 0 ? (
                            <Table.Summary.Cell></Table.Summary.Cell>
                          ) : null}
                          {pageDetails?.wantpackingextra ? (
                            <Table.Summary.Cell></Table.Summary.Cell>
                          ) : null}
                          {/* <Table.Summary.Cell></Table.Summary.Cell> */}
                          <Table.Summary.Cell></Table.Summary.Cell>
                          <Table.Summary.Cell></Table.Summary.Cell>
                          <Table.Summary.Cell></Table.Summary.Cell>
                          {pageDetails?.saleitemnarration === 1 ? (
                            <Table.Summary.Cell></Table.Summary.Cell>
                          ) : null}
                          <Table.Summary.Cell>
                            <p style={{ ...pStyles }}>
                              {existingObject?.taxableamount}
                            </p>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell></Table.Summary.Cell>
                          <Table.Summary.Cell>
                            <p style={{ ...pStyles }}>
                              {existingObject?.igstamt}
                            </p>
                          </Table.Summary.Cell>

                          <Table.Summary.Cell>
                            <p style={{ ...pStyles }}>
                              {existingObject?.amount}
                            </p>
                          </Table.Summary.Cell>
                        </Table.Summary.Row>
                      )}
                    </Table.Summary>
                  );
                }}
              />
            ) : null}
            {rowData.length > 0 ? (
              <div style={{ width: "25%" }}>
                <div
                  style={{
                    display: "flex",
                    gap: "10px",
                    marginLeft: "20px",
                    marginTop: "20px",
                    backgroundColor: "white",
                  }}
                >
                  <table
                    style={{
                      justifyContent: "center",
                      textAlign: "center",
                      margin: "auto",
                      backgroundColor: "white",
                    }}
                  >
                    <thead style={{ textAlign: "center" }}>
                      <tr style={{ border: "1px solid white" }}>
                        <th
                          style={{
                            fontWeight: "bold",
                            textAlign: "center",
                            margin: "auto",
                          }}
                        >
                          Invoice Amount
                        </th>
                        <th
                          style={{
                            fontWeight: "bold",
                            textAlign: "center",
                            margin: "auto",
                          }}
                        >
                      {typeAmt}
                        </th>
                        <th
                          style={{
                            fontWeight: "bold",
                            textAlign: "center",
                            margin: "auto",
                          }}
                        >
                        Total Amount
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <div onKeyDown={enterPress}>
                            <p></p>
                            <input
                              disabled
                              value={invoiceAmount}
                              style={{ ...grossAmtStyle, width: 120 }}
                            />
                          </div>
                        </td>
                        <td>
                          <div onKeyDown={enterPress}>
                            {/* <p>{typeAmt}</p> */}
                            <input
                              disabled
                              value={displayValue}
                              style={{ ...grossAmtStyle, width: 120 }}
                            />
                          </div>
                        </td>
                        <td>
                          <div onKeyDown={enterPress}>
                            <p></p>
                            <input
                              disabled
                              value={roundOffAmount(invoiceAmount).toFixed(2)}
                              style={{
                                ...grossAmtStyle,
                                width: 120,
                                fontWeight: "bold",
                              }}
                            />
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            ) : null}
          </div>
        </Panel>
      </Collapse>
      {/* Table for Selection  */}

      {openItem && (
        <ScanTable
          data={scandata}
          openItem={openItem}
          setOpenItem={setOpenItem}
          rowData={rowData}
          setRowData={setRowData}
          renderer={renderer}
          setRenderer={setRenderer}
          setValidator={setValidator}
          forFlag={"S"}
          supplierrecno={customerData?.customerrecno}
          pageDetails={pageDetails}
          pageIndex={pageIndex}
        />
      )}
      {modal && (
        <ScannedItem
          modal={modal}
          setModal={setModal}
          data={scanner}
          pageDetails={pageDetails}
          setRowData={setRowData}
          rowData={rowData}
        />
      )}
    </div>
  );
}

export default SalesInvoice;
// amol-temp - mainFrame width change to 99vw from 95vw
const mainFrame = {
  width: "99vw",
  margin: "auto",
};

const topRowFilters = {
  // amol-390 - changed in CSS - gap from 28px to 24px
  gap: "24px",
  display: "flex",
};

// applying padding to align input rows inline
const callbackTextInputStyles = {
  display: "flex",
  flexWrap: "wrap",
  alignItems: "center",
  gap: "10px",
  paddingTop: "10px",
};

// add to table btn styles
const btnStyles = {
  display: "flex",
  alignItems: "center",
  gap: "8px",
};

const inputStyles = {
  padding: "10px",
  border: "1px solid rgb(120,120,120)",
  width: "80px",
  fontSize: "16px",
  marginTop: "7px",
  borderRadius: "6px",
};

const grossAmtStyle = {
  textAlign: "right",
  border: "1px solid gray",
  padding: "4px 8px 4px 4px",
  fontSize: "18px",
  width: "80px",
  borderRadius: "8px",
  marginTop: "6px",
};
// amol-temp - change the values
const poNumberStyle = {
  // marginTop: "8px",
  border: "1px solid #dfdfdf",
  borderRadius: "8px",
  width: "180px",
  padding: "9px",
  fontSize: "16px",
  height: "39px",
};

const pStyles = {
  textAlign: "right",
  fontWeight: "700",
  fontSize: "16px",
  color: "black",
};

// amol-temp - added buttonStyle
const buttonStyle = {
  marginTop: "7px",
  textAlign: "right",
  border: "1px solid #dfdfdf",
  height: "36px",
};

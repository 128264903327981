import { Table, Button, Modal, Pagination, Space, Checkbox, Input } from "antd";
import { AiFillPrinter } from "react-icons/ai";
import React, { forwardRef, useContext, useRef, useState } from "react";
import { useEffect } from "react";
import { useReactToPrint } from "react-to-print";
import {
  dateFormatter,
  formatCurrency,
  formatCurrency3,
  isArray,
  removeFormatCurrency,
} from "../../CommonControllers/AppFunction";
import { TabStore } from "../../TabsStructure/TabsStore";
import "./sticky.css";
import TotalTable from "./TotalTable";
import { Spin } from "antd";
import PubSub from "pubsub-js";
import PrinterModal from "./PrinterModal";
import ReactPrint from "../../CommonControllers/Print/ReactPrint";
import { FilterFilled, SearchOutlined } from "@ant-design/icons";
import CallbackCheckbox from "../../CommonControllers/Components/CallbackComponents/CallbackCheckbox";
import Highlighter from "react-highlight-words";
import { currentTabData } from "../../CommonControllers/Variable";
import { useCallback } from "react";

const NewSalesInvoiceTable = React.memo(
  ({
    filterprinting,
    filterdropdownprint,
    filterDictionary,
    setFilterDictionary,
    fastPath,
  }) => {
    const tabstore = useContext(TabStore);
    const [checkStrictly, setCheckStrictly] = useState(false);
    const [col, setColumns] = useState([]);
    const [rows, setRow] = useState([]);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [open, setOpen] = useState(false);
    const [total, setTotal] = useState([]);
    const [startPrinting, setStartPrinting] = useState(false);

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [firstRender, setFirstRender] = useState(true);
    /// logic for search

    const [searchText, setSearchText] = useState("");
    const [searchedColumn, setSearchedColumn] = useState("");
    const searchInput = useRef(null);
    const [hasTotalRow, setHasTotalRow] = useState(true);

    const [searchParameters, setSearchParameters] = useState(false);

    const [pageIndex, setPageIndex] = useState();

    let response = tabstore?.state?.tabs[pageIndex]?.apiResponse;
    let itemresponse = tabstore?.state?.tabs[pageIndex]?.apiVariables.withitems; /// with item withserialno
    let titleprint = [tabstore?.state?.tabs[pageIndex]?.apiVariables];
    let fastpath = tabstore?.state?.tabs[pageIndex]?.name;
    let reportname = tabstore?.state?.tabs[pageIndex]?.headerName;

    /// with serioan number
    let withserialno =
      tabstore?.state?.tabs[pageIndex]?.apiVariables?.withserialno;
    const apiVariablesww = tabstore?.state?.tabs[pageIndex]?.apiVariables;

    useEffect(() => {
      var tabsArray = tabstore.state.tabs;
      for (let i = 0; i < tabsArray?.length; i++) {
        if (tabsArray[i].name == fastPath) {
          setPageIndex(i);
          break;
        }
      }
    }, []);
    useEffect(() => {
      PubSub.subscribe(fastpath + "_PRINT", commonFunction);
      let arr = [];
      setColumns(
        response?.columnsinfo?.filter(
          (it) => it.isview == 1 || it.isview == true
        )
      );
      let reqarr = [];
      let totalcolumn = -1;

      function formatColumnValue(value, type) {
        if (type === "date" && value !== "") {
          return dateFormatter(value);
        } else if (type === "decimal" && value !== "") {
          return formatCurrency(Number(removeFormatCurrency(value)));
        } else if (type === "decimal3" && value !== "") {
          return formatCurrency3(Number(removeFormatCurrency(value)));
        }
        return value;
      }

      const MergeArray = (arra1, arra2, type) => {
        let required = [];
        for (let m = 0; m < Math.max(arra1.length, arra2.length); m++) {
          required[m] = { ...(arra1[m] || {}), ...(arra2[m] || {}) };
        }
        return required;
      };
      let mapData =
        fastPath == "RP035"
          ? response?.Message[0]?.statement
          : response?.Message; /// here concept where data need to read from statement do contact before changing anythings rajendra
      console.log("mapData", mapData);
      for (let i = 0; i < mapData?.length; i++) {
        let responserow = mapData[i];

        for (let j = 0; j < response?.columnsinfo?.length; j++) {
          let responsecolumntype = response?.columnsinfo[j]["type"];
          let responsecolumnname = response?.columnsinfo[j]["dataIndex"];

          responserow[responsecolumnname] = formatColumnValue(
            responserow[responsecolumnname],
            responsecolumntype
          );
        }

        // Merge receiptitembatcharray and issueitembatcharray
        let mergeArray = [];
        if (
          responserow?.receiptitembatcharray &&
          responserow?.issueitembatcharray
        ) {
          mergeArray = MergeArray(
            responserow?.receiptitembatcharray,
            responserow?.issueitembatcharray
          );
        }

        // Combine main array with merged batch array
        let modify = {
          ...responserow,
          ...(mergeArray[0] || {}),
        };

        arr.push(modify);
        arr = arr.concat(mergeArray.slice(1));

        // Process items in the message
        if (itemresponse) {
          for (let j = 0; j < response?.Message[i]?.items?.length; j++) {
            let itemobj = responserow.items[j];

            for (let k = 0; k < response?.columnsinfo.length; k++) {
              let itemcolumntype = response?.columnsinfo[k]["type"];
              let itemcolumnname = response?.columnsinfo[k]["dataIndex"];

              itemobj[itemcolumnname] = formatColumnValue(
                itemobj[itemcolumnname],
                itemcolumntype
              );
            }

            arr.push(itemobj);

            if (withserialno && itemobj?.batcharray) {
              for (let l = 0; l < itemobj?.batcharray.length; l++) {
                let itembatcharray = itemobj?.batcharray[l];

                for (let m in itembatcharray) {
                  if (m == "expdate" || m == "manudate") {
                    itembatcharray[m] = dateFormatter(itembatcharray[m]);
                  } else {
                    itembatcharray[m] = itembatcharray[m];
                  }
                }
                arr.push(itembatcharray);
              }
              console.log("ARRR12", arr);
            }
          }
        }
      }

      // create total of the columns
      reqarr = [];
      totalcolumn = -1;
      var hasTotal = 0;
      for (let i = 0; i < response?.columnsinfo.length; i++) {
        let colfortotal = response?.columnsinfo[i]?.fortotal;
        if (colfortotal) {
          hasTotal = hasTotal + 1;
          let amt = 0;
          for (let j = 0; j < mapData?.length; j++) {
            amt = mapData?.reduce((x, y) => {
              return (
                Number(
                  removeFormatCurrency(y[response?.columnsinfo[i]?.dataIndex])
                ) + x
              );
            }, 0);
          }
          var columnname = response.columnsinfo[i][`dataIndex`];
          if (columnname) {
            var totalobj = {
              [columnname]: formatCurrency(amt),
            };
          }
        } else if (totalcolumn == -1) {
          totalcolumn = i;
        }
        reqarr.push(totalobj);
      }

      if (totalcolumn > -1 && hasTotal > 0) {
        var columnname = response.columnsinfo[totalcolumn][`dataIndex`];
        if (columnname) {
          var totalobj = {
            [columnname]: "Total",
          };
        }
        reqarr.push(totalobj);
      }
      // create total of the columns
      if (hasTotal > 0) {
        const mergedObject = reqarr.reduce(
          (acc, obj) => ({ ...acc, ...obj }),
          {}
        );

        arr.push(mergedObject);
        setRow(arr);
        setHasTotalRow(true);
      } else {
        setHasTotalRow(false);
        setRow(arr);
      }

      convertToRequiredFormat();
    }, [response, itemresponse, withserialno]);

    const setModal = () => {
      setOpen(true);
    };

    function handlePageChange(pageNumber) {
      // handle page change logic here
      setPage((p) => p + 1);
    }

    const commonFunction = () => {
      // handlePrintbody()
      // setOpen(true);
      setIsModalOpen((current) => !current);
      // setStartPrinting(true);
      //PubSub.unsubscribe(fastpath+"_PRINT")
    };

    useEffect(() => {
      if (startPrinting == true) {
        handlePrintbody();
      }
    }, [startPrinting]);

    const componentRef = useRef();
    let today = new Date();
    let date =
      today.getDate() +
      "-" +
      (today.getMonth() + 1) +
      "-" +
      today.getFullYear();

    const handlePrintbody = useReactToPrint({
      content: () => componentRef.current,
      onBeforeGetContent: () => (document.title = reportname),
      onAfterPrint: () => {
        setStartPrinting(false);
        setOpen(false);
      },
    });

    useEffect(() => {
      const apiVariablesww =
        tabstore?.state?.tabs[tabstore.state.tabIndex]?.apiVariables;
      const apiResponse =
        tabstore?.state?.tabs[tabstore.state.tabIndex]?.apiResponse;

      // This block will only be executed on the first render

      if (firstRender) {
        setFirstRender(false);
        return;
      }

      // This block will be executed when any of the dependencies change, but not on the first render
      // if (filterDictionary && apiResponse?.Success == true||apiResponse?.Count==0) {
      //   setRow([]);
      //   setColumns([]);
      // }
    }, [filterDictionary, apiVariablesww]);

    //// logic for search

    useEffect(() => {
      if (tabstore.state.tabs[pageIndex]?.pageNo) {
        setPage(tabstore.state.tabs[pageIndex].pageNo);
      }
      setSearchedColumn(tabstore.state.tabs[pageIndex]?.searchedColumn || "");
      setSearchParameters(tabstore.state.tabs[pageIndex]?.searchParameters);
    }, []);

    useEffect(() => {
      var searchParams = searchParameters;
      if (searchParams && rows.length > 0) {
        handleSearch(
          searchParams.selectedKeys,
          searchParams.confirm,
          searchParams.dataIndex
        );
      }
    }, [col]);

    useEffect(() => {
      // Need to Store the search Text to the server
      tabstore.dispatch({
        type: "UPDATE_DATA",
        fieldType: "searchParameters",
        text: searchParameters,
      });
    }, [searchParameters]);

    useEffect(() => {
      tabstore.dispatch({
        fieldType: "UPDATE_DATA",
        text: page,
      });
    }, [page]);

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
      confirm();
      setSearchText(selectedKeys[0]);
      setSearchedColumn(dataIndex);
    };
    const handleReset = (clearFilters, confirm) => {
      clearFilters();
      setSearchText("");
      confirm();
    };
    const getColumnSearchProps = (dataIndex) => ({
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
        close,
      }) => (
        <div
          style={{
            padding: 8,
          }}
          onKeyDown={(e) => e.stopPropagation()}
        >
          <Input
            ref={searchInput}
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              setSearchParameters({
                selectedKeys: e.target.value ? [e.target.value] : [],
                confirm: confirm,
                dataIndex: dataIndex,
              });
            }}
            onPressEnter={() => {
              handleSearch(selectedKeys, confirm, dataIndex);
            }}
            style={{
              marginBottom: 8,
              display: "block",
            }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
              icon={<SearchOutlined />}
              size="small"
              style={{
                width: 90,
              }}
            >
              Search
            </Button>
            <Button
              onClick={() => clearFilters && handleReset(clearFilters, confirm)}
              size="small"
              style={{
                width: 90,
              }}
            >
              Reset
            </Button>
            <Button
              type="link"
              size="small"
              onClick={() => {
                confirm({
                  closeDropdown: false,
                });
                setSearchText(selectedKeys[0]);
                setSearchedColumn(dataIndex);
              }}
            >
              Filter
            </Button>
            <Button
              type="link"
              size="small"
              onClick={() => {
                close();
              }}
            >
              close
            </Button>
          </Space>
        </div>
      ),
      // filterIcon: (filtered) => (

      //   <SearchOutlined
      //     style={{
      //       color: filtered ? "#1890ff" : undefined,
      //     }}
      //   />
      // ),
      filterIcon: (filtered) => (
        <div>
          {filtered ? (
            <FilterFilled
              style={{
                backgroundColor: filtered ? "#2196F23" : undefined,
              }}
            />
          ) : null}
          <SearchOutlined
            style={{
              color: filtered ? "#1890ff" : undefined,
              padding: "10px",
            }}
          />
        </div>
      ),
      onFilter: (value, record) =>
        record[dataIndex]
          ?.toString()
          .toLowerCase()
          .includes(value.toLowerCase()),
      onFilterDropdownOpenChange: (visible) => {
        if (visible) {
          setTimeout(() => searchInput.current?.select(), 100);
        }
      },
      render: (text) =>
        searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{
              backgroundColor: "#ffc069",
              padding: 0,
            }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text ? text.toString() : ""}
          />
        ) : (
          text
        ),
    });

    const convertToRequiredFormat = useCallback(() => {
      const totalData = tabstore.state.tabs[pageIndex]?.apiResponse;

      if (!totalData) return;

      const newColumns = totalData.columnsinfo
        .filter((item) => item.isview)
        .map((item) => {
          const width = Number(item.width) > 0 ? Number(item.width) : 100;
          const renderFunction = (_, tags) => {
            const value = tags[item.columnname];
            if (item.type === "boolean") {
              const defaultValue = item.filter ? value : null;
              return item.filter ? (
                <CallbackCheckbox
                  defaultValue={defaultValue}
                  disabled
                  label={[item.Title]}
                />
              ) : (
                <Checkbox checked={value} disabled />
              );
            } else if (item.type === "date") {
              return <p>{dateFormatter(value)}</p>;
            } else if (item.type === "dropdown") {
              return <p>{tags[item.columndisplay]}</p>;
            } else {
              return <p>{value}</p>;
            }
          };

          const columnProps = {
            ...item,
            width,
            dataIndex: item.columnname,
            key: item.columnname,
            render: renderFunction,
            ...(item.filter && getColumnSearchProps(item.columnname)),
          };

          return columnProps;
        });

      setColumns(() => newColumns);
    }, [pageIndex, tabstore.state.tabs]);

    useEffect(() => {
      convertToRequiredFormat();
    }, [tabstore.state.tabs[pageIndex]?.apiResponse]);

    const totalWidth =
      2 * col?.reduce((acc, el) => acc + Number(el?.width), 0) || "auto";
    const scrollStyles = {
      overflowX: "auto",
      overflowY: "hidden",
      scrollbarWidth: "none" /* Firefox */,
      msOverflowStyle: "none" /* Internet Explorer 10+ */,
    };

    const hideScrollBar = {
      "&::-webkit-scrollbar": {
        display: "none" /* Safari and Chrome */,
      },
    };
    console.log("RESDHF", rows);
    return (
      <div style={{ maxWidth: totalWidth, ...scrollStyles, ...hideScrollBar }}>
        {rows && col && Array.isArray(col) ? (
          <Table
            columns={col}
            dataSource={rows}
            key={rows}
            scroll={{
              scrollToFirstRowOnChange: true,
              x: col?.reduce((acc, el) => acc + Number(el?.width), 0),
              y: "calc(85vh - 200px)", // Adjust 150px according to your specific layout needs
            }}
            pagination={false}
            title={() => (
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  position: "sticky",
                  top: "0px",
                  zIndex: 1,
                  offset: 0,
                }}
              >
                {open ? (
                  <>
                    {filterprinting?.map((data) => (
                      <>
                        {titleprint?.map((titleprint) => {
                          if (data.type !== "boolean") {
                            return (
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <h2>{data.displayLabel}:</h2>
                                <h3>
                                  {data.type == "date"
                                    ? dateFormatter(titleprint[data.apiKey])
                                    : data.type == "dropdown"
                                    ? filterdropdownprint
                                    : titleprint[data.apiKey]}
                                </h3>
                              </div>
                            );
                          }
                        })}
                      </>
                    ))}
                  </>
                ) : (
                  <Space>
                    <Button
                      onClick={commonFunction}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "4px",
                        marginLeft: "16px",
                        padding: "4px 8px",
                        fontSize: "0.9rem",
                      }}
                    >
                      <AiFillPrinter style={{ fontSize: "0.9rem" }} /> Preview
                    </Button>
                    <Button style={{ fontSize: "0.9rem", padding: "4px 8px" }}>
                      Total Records:{" "}
                      {tabstore.state.tabs[tabstore.state.tabIndex]?.apiResponse
                        ?.selected
                        ? `${
                            tabstore.state.tabs[tabstore.state.tabIndex]
                              ?.apiResponse?.selected
                          } / ${rows?.length}`
                        : rows?.length}
                    </Button>
                    <Button style={{ fontSize: "0.9rem", padding: "4px 8px" }}>
                      Current Page: {page}
                    </Button>
                  </Space>
                )}
              </div>
            )}
            className={
              rows?.length - 1 && hasTotalRow
                ? "printtotalline"
                : open
                ? "printTable"
                : "print"
            }
            rowClassName={(record, index) => {
              if (hasTotalRow && record) {
                if (index === rows.length - 1) {
                  return "printtotalline";
                } else {
                  return "";
                }
              } else {
                return "";
              }
            }}
          />
        ) : (
          <div
            style={{
              position: "fixed",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            <Spin size="large" />
          </div>
        )}
        {col && Array.isArray(col) ? (
          <PrinterModal
            columns={col}
            rows={rows}
            isModalOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
            ref={componentRef}
            wtfIsThisData={{
              data1: filterprinting,
              data2: filterdropdownprint,
            }}
            filterDictionary={filterDictionary}
            setFilterDictionary={setFilterDictionary}
            setStartPrinting={setStartPrinting}
            reportname={reportname}
          />
        ) : null}
      </div>
    );
  }
);
export default NewSalesInvoiceTable;

const btnStyle = {
  display: "flex",
  alignItems: "center",
  gap: "4px",
  marginLeft: "16px",
};

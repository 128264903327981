import { Input } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import useExecute from "../../Hooks/useExecute";

function ExecuteTextInput({
  displayLabel,
  placeholder,
  change,
  className,
  style,
  type,
  required,
  defaultValue,
  apikey,
  id,
  returnValue,
  isrequired,
  width
}) {
  // Steps : onChange cahnge the value of the state variable in the component
  // OnBlur : in case the component needs to be onBlue save the changed value to the tabStore on blue event, else on change event
  // For Execute we save the values to the apiVariables store in tabStore

  const execute = useExecute();
  const [textValue, setTextValue] = useState(null);
 console.log("Value of Text Value is ", textValue)
  useEffect(() => {
    if (defaultValue) {
      if (execute.getValue(apikey)) {
        setTextValue(execute.getValue(apikey));
      } else if (execute.getValue(apikey) == "") {
        setTextValue(execute.getValue(apikey));
      } else {
        setTextValue(defaultValue);
        execute.updateValue(apikey, defaultValue);
      }
    } else {
        setTextValue(null)
      
    }
  }, [defaultValue]);

 

  const onChange = (e) => {
    setTextValue(e.target.value);
  };

  var className = "inputBox" + " " + className;

  // function updatedValue(e) {
  //   setTextValue(e.target.value);
  //   // Send the Data to the tabStore
  //   console.log("Key Values are", apikey, e.target.value)
  //   execute.updateValue(apikey, e.target.value);
  //   returnValue(e.target.value);
  // }

  const updatedValue = useCallback(
    (e) => {
      handleBlur();
      const newValue = e.target.value;
      setTextValue(newValue);
      execute.updateValue(apikey, newValue);
      returnValue(newValue);
    },
    [apikey, execute, returnValue]
  );

  const mainFrame = {
    display: "flex",
    // alignItems: "center",
    flexDirection: "column",
    gap: "row" && "8px",
    textAlign: "start",
  };
  const [isFocused, setIsFocused] = useState(false);
  const handleFocus = () => {
    // This function is called when the input element receives focus.
    setIsFocused(true)
  }

  const handleBlur = () => {
    // This function is called when the input element loses focus.
    setIsFocused(false)
  }

  return (
    <div style={{ ...mainFrame, width: width ? width : "250" }}>
        <div style={{ position: 'relative' }}>
        <label
              htmlFor={id}
              className={`label ${isFocused || textValue ? 'focused' : 'unfocused'}`}
              style={{
                position: 'absolute',
                top: isFocused || textValue ? '-20px' : '50%',
                left: '8px',
                //backgroundColor: isFocused || textValue ? 'white' : "transparent",
                padding: '0 4px',
                fontSize: isFocused || textValue ? '12px' : '16px',
                color: isFocused || textValue ? 'yellow' : 'red',
                pointerEvents: 'none',
                transform:
                  isFocused || textValue
                    ? 'translateY(0)'
                    : 'translateY(-50%)',
                transition:
                  'top 0.3s, transform 0.3s, font-size 0.3s, color 0.3s'
              }}
            >
               {isrequired ? (
                <span className={`label ${isFocused || textValue ? 'focused' : 'unfocused'}`}>
                  {isFocused || textValue? displayLabel : placeholder}
                  <span style={{ color: 'red' }}>*</span>
                </span>
              ) : (
                <span className={`label ${isFocused || textValue ? 'focused' : 'unfocused'}`}>
                  {isFocused || textValue? displayLabel : placeholder}
                 
                </span>
              )}
            </label>
      <div style={{ flexGrow: 1 }}>
        {change ? (
          <input
            id={id}
            className={className}
            style={{ ...style }}
            type={type}
            onBlur={handleBlur}
            onFocus={handleFocus}
            // placeholder={placeholder}
            onChange={updatedValue}
            value={textValue}
            required={required}
          />
        ) : (
          <input
            className={className}
            style={style}
            id={id}
            onFocus={handleFocus}
            type={type}
            // placeholder={placeholder}
            onBlur={updatedValue}
            onChange={onChange}
            value={textValue}
            required={required}
          />
        )}
      </div>
      </div>
    </div>
  );
}

export default ExecuteTextInput;

import { Button, Modal, Pagination, Popconfirm, Space, Table } from "antd";
import React, { useContext, useEffect, useState, useRef } from "react";
import { AiOutlineFileAdd } from "react-icons/ai";
import DynamicModalForm from "../../../Screens/CommonMasterwithModal/DynamicModalForm";
import { TabStore } from "../../../TabsStructure/TabsStore";
import { isArray, updateAddArray } from "../../AppFunction";
import useExecute from "../../Hooks/useExecute";
import useSaveChangedRows from "../../Hooks/useSaveChangedRows";
import CallbackCheckbox from "../CallbackComponents/CallbackCheckbox";
import {
  addNewRows,
  convertColumns,
  createRows,
  saveRows,
} from "./editableTableFunc";
import InlineEdit from "./InlineEdit";
import { v4 as uuidv4 } from "uuid";
import { LoadingOutlined } from "@ant-design/icons";
import { currentTabData } from "../../Variable";

function   EditableTable({
  filter,
  columnsArray,
  rowsArray,
  modal,
  selection,
  isAdd,
  isEdit,
  pageIndex,
}) {
  const [count, setCount] = useState(0);
  const [isOpen, setIsModalOpen] = useState(false);
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  const tabStore = useContext(TabStore);
  const useChangedRows = useSaveChangedRows();
  const [openModal, setOpenModal] = useState(false);
  const [editOpenType, setEditOpenType] = useState(false);
  const [selectedObject, setSelectedObject] = useState({});
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [searchedColumnDisplay, setSearchedColumnDisplay] = useState(null);
  const searchInput = useRef(null);
  const [page, setPage] = useState(1);
  const [columnsArrayforData, setColumnsArrayforData] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const [addRow, setAddRow] = useState(false);
  const [changesHaveBeenMade, setChangesHaveBeenMade] = useState(1);

  const [selectAllOptions, setSelectAllOptions] = useState([]);
  const [openImage, setOpenImage] = useState(false);
 

  const [isLoading, setIsLoading] = useState(false);
  ///note 
  const [billformat,setBillformat]=useState("")

  //// images
  const [file, setFile] = React.useState("");
  const [displayFile, setDisplayFile] = React.useState(false);
  const tabstore = useContext(TabStore);
  const [imageLocation, setImageLocation] = useState("");
  const handleFileUpload = async (e) => {
    setImageLocation(e);
    setOpenImage(true);
  };

  const convert = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (e) => {
        reject(e);
      };
    });
  };

  useEffect(() => {
    if (selection) {
      setSelectAllOptions(selection?.split(",").map((item) => item.trim()));
    }
  }, [selection]);



  useEffect(() => {
    if (tabStore.state.tabs[pageIndex]?.pageNo) {
      setPage(tabStore.state.tabs[pageIndex].pageNo);
    }
  }, []);

  const handleCancel = () => {
    tabStore.dispatch({
      type: "UPDATE_DATA",
      fieldType: "addObject",
      text: null,
    });
    setCount((p) => p + 1);
    setIsModalOpen(false);
  };

  
  useEffect(() => {
    tabStore.dispatch({
      fieldType: "UPDATE_DATA",
      text: page,
    });
  }, [page]);

  const setChanges = () => {
    setChangesHaveBeenMade((p) => p + 1);
  };

  useEffect(() => {
  
    if (changesHaveBeenMade > 1) {
      setOpenModal(true);
      // setEditOpenType(true);
      setCount((p) => p + 1);
    }
  }, [changesHaveBeenMade]);

  // Here now I need to convert the Colums to Desired format
  useEffect(() => {
    convertColumns(
      columnsArray,
      setColumns,
      rows,
      setRows,
      tabStore,
      useChangedRows,
      modal,
      setOpenModal,
      searchText,
      addSearchText,
      searchedColumn,
      setSearchedColumn,
      searchInput,
      setEditOpenType,
      setPage,
      setSearchedColumnDisplay,
      setChanges,
      setOpenImage,
      openImage,
      handleFileUpload,
      isEdit,
      setBillformat
    );
    setColumnsArrayforData(columnsArray);
  }, [columnsArray, rows]);

  const addSearchText = (e) => {
    setSearchText(e);
  };

  useEffect(() => {}, [searchInput, searchText, searchedColumn]);

  useEffect(() => {

    if (rowsArray) {
      // setRows(rowsArray);
      createRows(columnsArray, rowsArray, setRows);
    }
  }, [rowsArray]);

  const addNewRowsFunc = (e) => {
    var objNew = {
      isNew: true,
      isEditing: false,
      uuid: uuidv4(),
      changed: true,
    };

    var i;
    for (i = 0; i < columnsArrayforData?.length; i++) {
      // if (!objNew.hasOwnProperty(columns[i].dataIndex)) {
      objNew[columnsArrayforData[i].dataIndex] = columnsArrayforData[i]
        ?.defaultValue
        ? columnsArrayforData[i]?.defaultValue
        : null;
      // }
     
    }
    objNew = { ...objNew, ...e };
    var objRows = [objNew, ...rows];

    setRows(() => objRows);
    saveRows(objRows, "uuid", objNew, setRows, tabStore, setPage);
  };


  const convertAllRows = (bool, key) => {
    var existingArray = rows;
    var existingTabs = tabStore.state.tabs[pageIndex]?.changedRows
      ? tabStore.state.tabs[pageIndex]?.changedRows
      : [];
    var existingTabsArr = isArray(existingTabs);
    if (!existingTabsArr) {
      existingTabs = [];
    }

    for (let i = 0; i < existingArray?.length; i++) {
      if (existingArray[i][key] != bool) {
        existingArray[i][key] = bool;

        existingTabs = updateAddArray(existingTabs, existingArray[i], "uuid");
      }
    }
  
    tabStore.dispatch({
      type: "UPDATE_DATA",
      fieldType: "changedRows",
      text: existingTabs,
    });

  
    try {
      var apiResponse = tabStore.state.tabs[pageIndex]?.apiResponse;
      apiResponse.Message = existingArray;
      tabStore.dispatch({
        type: "UPDATE_DATA",
        fieldType: "apiResponse",
        text: apiResponse,
      });
    } catch (e) {
      console.log("Error is ", e);
    }
    setBillformat(()=>columns?.filter((it)=>it.dataIndex=="billformat"))
  };
  /// taryng resize the column
  //this function is for assingin width to the each column but it is hardcoded we can get width from backend
  // var columnsn=columns.map((item)=>{
  //   return {...item,width:200,float:"none"}
  // })

  return (
    <div>
      {isLoading ? (
        <div
          style={{
            height: "100%",
            width: "100%",
            background: "rgba(0,0,0,0.50)",
            position: "absolute",
            zIndex: 100,
          }}
        >
          Hello
        </div>
      ) : null}
      <Modal
      
        open={openModal}
        cancelButtonProps={{ style: { display: "none" } }}
        okButtonProps={{ style: { display: "none" } }}
        style={{ top: 5 }}
        width={1000}
        title={editOpenType ? "Edit Form" : "Add Form"}
        //closable
        closeIcon={null}
        onCancel={() => setOpenModal(false)}
      >
        {openModal ? (
          <DynamicModalForm
            filterData={filter}
            columnsArray={columnsArray}
            dataObject={selectedObject}
            handleClose={setOpenModal}
            isEdit={editOpenType}
            changes={changesHaveBeenMade}
          />
        ) : null}
      </Modal>

      {searchedColumnDisplay ? (
        <Space>
          Filters Applied On : <b>{searchedColumnDisplay}</b> applied filters :{" "}
          <b>{searchText}</b>
        </Space>
      ) : null}

      {/* <div>
       {selectAllOptions && isArray(selectAllOptions) && selectAllOptions.length > 0 ? (<div  style={{display:"flex", flexDirection:"row"}}>
       {
        selectAllOptions.map((it) => {
          return ( <CallbackCheckbox
            displayLabel={
              columns.filter(
                (item) => item.columnname == it
              )[0]?.columndisplay
            }
            editable
            returnValue={(e) => {
              
              convertAllRows(e, it);
              
            }}
            apikey={it}
          />)
        })
       }
       </div>) : null}
      </div> */}
      {/* {addRow ? ( */}
      <Modal
        key={count}
        open={isOpen}
        okButtonProps={{ style: { display: "none" } }}
        onCancel={handleCancel}
        style={{ marginTop: "-2%", padding: "2%" }}
        width={1000}
        title={editOpenType ? "Edit Form" : "Add Form"}
      >
        <InlineEdit
          columnsArray={columnsArrayforData}
          handleCancel={setIsModalOpen}
          // setAddRow={setAddRow}
          returnValue={(e) => addNewRowsFunc(e)}
          changes={changesHaveBeenMade}
          setCount={setCount}
        />
      </Modal>
      {/*   images */}
      <Modal
        open={openImage}
        //closable
        onCancel={() => setOpenImage(false)}
        onOk={() => setOpenImage(false)}
        closeIcon={false}
      >
        <div>
          <div>
            { (
              <img
                src={`https://scientific.sutradhar.tech/backend/api/v1/getimages/${imageLocation}/`}
                width="200"
                height="200"
              ></img>
            ) }
          </div>
        </div>
      </Modal>

      <form style={{ height: "300px", position: "relative" }}>
        <Table
          // columns={columns}
          columns={columns.map((column) => {
            if (column.type === 'boolean'&&isEdit) {
              return {
                ...column,
                title: (
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <span>{column.columndisplay}</span>
                  <CallbackCheckbox
                    editable
                    returnValue={(e) => convertAllRows(e, column.dataIndex)}
                    apikey={column.dataIndex}
                  />
                </div>
                ),
              };
            }
            return column;
          })}
          dataSource={rows}
          pagination={{
            position: ["none"],
            pageSize: pageSize,
            defaultCurrent: page,
            current: page,
          }}
          tableLayout="fixed"
          scroll={{
             x: "max-content",
            y: `430px`,
          }}
          title={() => {
            return (
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Space>
                  {/* <div>
                    {selectAllOptions &&
                    isArray(selectAllOptions) &&
                    selectAllOptions.length > 0 ? (
                      <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                        {selectAllOptions.map((it) => {
                          return (
                            <CallbackCheckbox
                              displayLabel={
                                columns.filter(
                                  (item) => item.columnname == it
                                )[0]?.columndisplay
                              }
                              editable
                              returnValue={(e) => {
                                convertAllRows(e, it);
                              }}
                              apikey={it}
                            />
                          );
                        })}
                      </div>
                    ) : null}
                  </div>
                  {selectAllOptions && !isArray(selectAllOptions) ? (
                    <CallbackCheckbox
                      displayLabel={
                        columns.filter(
                          (item) => item.columnname == selectAllOptions
                        )[0]?.columndisplay
                      }
                      editable
                      returnValue={(e) => {
                        convertAllRows(e, selectAllOptions);
                      }}
                      apikey={selectAllOptions}
                    />
                  ) : null} */}
                  {isAdd ? (
                    <Button
                      onClick={() => {
                        console.log("add button is clicked", modal);
                        if (modal) {
                          setCount(count + 1);
                          setOpenModal(true);
                          setChangesHaveBeenMade((p) => p + 1);
                          setEditOpenType(false);
                        } else {
                          setAddRow(true);
                          // addNewRows(rows, setRows, columnsArrayforData);
                          setPage(1);
                          setCount(count + 1);
                          setIsModalOpen(true);
                        }
                      }}
                    >
                      <AiOutlineFileAdd size="1.08rem" color="white" />
                    </Button>
                  ) : null}
                  {tabStore.state.tabs[pageIndex]?.apiResponse?.selected ? (
                    <Button>
                      Total Records :{" "}
                      {tabStore.state.tabs[pageIndex]?.apiResponse?.selected} /{" "}
                      {rows?.length}{" "}
                    </Button>
                  ) : (
                    <Button>Total Records : {rows?.length} </Button>
                  )}
                  <Button>Current Page : {page} </Button>
                </Space>
                <Pagination
                  total={rows?.length}
                  showTotal={(total, range) =>
                    `${range[0]}-${range[1]} of ${total} items`
                  }
                  defaultCurrent={page}
                  defaultPageSize={10}
                  showSizeChanger
                  showQuickJumper
                  current={page}
                  onChange={(e, pageSize) => {
                    setPageSize(pageSize);
                    setPage(e);
                  }}
                />
              </div>
            );
          }}
          size={"small"}
          // scroll={ { scrollToFirstRowOnChange: true,x:columns?.reduce((acc, el) => (acc += el?.width), 0) , y: 10000}}
          height={1000}
          bordered
          // tableLayout="fixed"
          // if used then scroll bar disappears but the filter dropdown is good style={{position:"fixed"}}

          // rowClassName="editable-row"
          // // sticky={true}
          // // tableLayout="auto"
          // scroll={ { scrollToFirstRowOnChange: true, x:columns?.reduce((acc, el, i, arr) => (acc += el?.width), 0) ,y: 350 }}
          //style={{position:"fixed"}}
        ></Table>
        <div style={{padding:"5px",fontSize:"10px"}}><p>{currentTabData(tabStore)?.name === "MS120"&&billformat!=""?<><span style={{fontWeight:"bold"}}>Note:</span>{billformat[0]?.note}</>:null}</p></div>
      </form>
    </div>
  );
}

export default EditableTable;

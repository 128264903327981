import React, { createContext, useContext } from "react";
import { TabStore } from "../TabsStructure/TabsStore";

export const AuthStore = createContext();


export const authStoreInitialState = {
  isLoggedIn: false,
  sessionValid: false,
  activityValid: false,
  sessionToken: null,
  userdata: null,
  tenantrecno: 0,
  workdate: null,
  surveytyperecno: 0
};

export const authStoreReducer = (prev, action) => {
  switch (action.type) {
    case "LOGIN": {
      return {
        ...prev,
        isLoggedIn: true,
        sessionValid: true,
        activityValid: true,
        sessionToken: action.sessionToken,
        userdata: action?.userdata,
        tenantrecno: action?.tenantrecno,
        workdate: action?.workdate,
      };
    }
    /////  ...prev,functionality  addded by rajendra date 3 april 2023logging out 
    case "LOGOUT": {
      
      return {
       
        isLoggedIn: false,
        sessionValid: false,
        activityValid:false,
        sessionToken: "",
        userdata: "",
        tenantrecno: "",
        workdate: "",
      };
    }

    case "CONTEXT_HOLDER":
      return { ...prev, contextHolder: action.contextHolder };
    default:
      return { ...prev };
  }
};

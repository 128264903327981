import { useContext } from "react"
import { AuthStore } from "../../Auth/AuthStore"
import { getCurrentTime, getToday } from "../AppFunction"


export default function useShortguid() {
    const auth = useContext(AuthStore)
   
    const getShortguid = (type) => {
        var uniqueNumber1 =  getCurrentTime() +getToday() 
        // WE create a Unique Number depending upon the Machine ID etc
       
          var  uniqueNumber = type + uniqueNumber1+"-" + auth.state.userdata.domainrecno + "-"+auth.state.userdata.recno 
            return uniqueNumber
    }
    return {getShortguid}
    
}
import { Button, Popconfirm } from "antd";
import { AiFillDelete } from "react-icons/ai";
import { removeByAttribute } from "../../CommonControllers/AppFunction";
import CallbackDateInput from "../../CommonControllers/Components/CallbackComponents/CallbackDateInput";
import { BiSelectMultiple } from "react-icons/bi";

export const piColumns = (
  igstappl,
  setRowData,
  rowData,
  scandata,
  setScandata,
  setOpenItem,
  sidate,
  setModal,
  scanner,
  onedconeinvoice,
  setScanner,
  pageDetails,
  setItemForSelection
) => {
  if (igstappl) {
    return [
      {
        title: "",
        dataIndex: "active",
        key: "active",
        width: 80,
        render: (_, text) => {
          return (
            <Popconfirm
              title="Do you want to Cancel the changes?"
              onConfirm={(e) => {
                handleRemove(text, setRowData, rowData);
              }}
            >
              <Button icon={<AiFillDelete />}></Button>
            </Popconfirm>
          );
        },
      },
      {
        width: 300,
        title: "Item Description", // nohit-425 change title Item to Item Description
        dataIndex: "itemdescn",
        key: "itemdescn",
        align: "left", // nohit-388 set Align for column
      },
      {
        width: 150,
        title: "Scanned Items",
        dataIndex: "scannedItems",
        align: "left", // nohit-388 set Align for column
        render: (_, data) => {
          console.log(
            "While Adding from DC item is",
            scanner,
            data?.itembatcharray
          );
          return (
            <p>
              {data?.doctype == "DC" && data?.wantbatchno == 0
                ? data?.batcharray?.length
                : data?.itembatcharray?.length}
            </p>
            // here this code is added to showned the scanned item length (data?.batcharray?.length) from DC
          );
        },
      },
      {
        title: "Scan Barcode",
        dataIndex: "barcode",
        align: "left", // nohit-388 set Align for column
        key: "barcode",
        width: 150,
        render: (_, text) => {
          console.log("Text is", text);
          //// task-id 345 rajendra  here if isservice is true we don't want to show scan option
          if (text.itemasbatch == 1 && text.doctype !== "DC") {
            return (
              <Button
                disabled={
                  onedconeinvoice && text?.doctype == "DC" ? true : false
                }
                onClick={() => {
                  setOpenItem(true);

                  handleChange(
                    text,
                    setRowData,
                    igstappl,
                    rowData,
                    scandata,
                    setScandata
                  );
                }}
                // disabled={text?.packingqtyaccepted !== 0 || text?.packingextraqtyaccepted !== 0}
              >
                Item Scan
              </Button>
            );
          } else if (text?.doctype == "DC" && text?.wantbatchno == 0) {
            return (
              <Button
                w=""
                onClick={() => {
                  setModal(true);
                  setScanner(text?.batcharray); // here this code is added to showned the scanned item from DC
                }}
              >
                Show Item
              </Button>
            );
          } else {
            return null;
          }
        },
      },
      {
        hidden: !pageDetails?.wantpackingextra ? true : false,
        title: "Box Qty",
        width: 100,
        dataIndex: "packingextraqty",
        key: "packingextraqty",
        align: "right", // nohit-388 set Align for column
        render: (_, text) => {
          console.log("text in SI", text);
          return (
            <input
              type={"number"}
              //{Pradeep task id -314 }
              disabled={
                text.packingextrarecno == 0 || text?.isservice == 1
                  ? true
                  : false
              }
              className="tr001Input"
              style={{ width: "50px" }}
              defaultValue={text.packingextraqty}
              //{start Pradeep, task id-238  }
              value={text.packingextraqty}
              // onFocus={(e) => e.target.select()}
              onChange={(e) => {
                const regex = /^[0-9\b]+$/;

                // console.log("e.target.value", e.target.value)
                // console.log("conversionfactor11", e.target.value, text.conversionfactor, text.itembatcharray.length)

                //old validation on box and piece
                if (e.target.value === "" || regex.test(e.target.value)) {
                  text.packingextraqty = e.target.value;
                  //{start Pradeep, task id-238 }
                  handleChange(
                    text,
                    setRowData,
                    igstappl,
                    rowData,
                    scandata,
                    setScandata
                  );
                }

                //{start Pradeep, task id-238 }
                //{Pradeep, task id-334 }
                else if (
                  e.target.value == "0" &&
                  (text.conversionfactor = 12)
                ) {
                  console.log("hiiiop");
                  text.packingextraqty =
                    text.itembatcharray.length / text.conversionfactor || 1;
                  handleChange(
                    text,
                    setRowData,
                    igstappl,
                    rowData,
                    scandata,
                    setScandata
                  );
                  //{END         END }
                } else {
                  // console.log("1111113")
                  alert("Invalid Input");
                  e.target.value = "";
                  text.packingextraqty = e.target.value;
                  //{start Pradeep, task id-238 }
                  handleChange(
                    text,
                    setRowData,
                    igstappl,
                    rowData,
                    scandata,
                    setScandata
                  );
                }

                //new validation of zero qty

                // if (regex.test(e.target.value) && (Number(e.target.value) + Number(text.packingqty)) > 0) {
                //   text.packingextraqty = e.target.value;
                //   handleChange(text, setRowData, igstappl, rowData);
                // } else {
                //   e.target.value = text.packingextraqty
                //   alert("Box and Pieces cannot be 0")
                // }
              }}
              onBlur={(e) => {
                //{start Pradeep, task id-238 start}
                //{Pradeep, task id-334 changes conversion factor to 12 }
                if (e.target.value == "0" && text.conversionfactor == 12) {
                  if (Number(text.free) == 0) {
                    let count =
                      Number(text.packingqty) +
                      Number(
                        text.freepackingextraqty * (text.conversionfactor || 1)
                      ) +
                      Number(text.freepackingqty);

                    console.log("count", count);
                    if (count >= text?.itembatcharray?.length) {
                      text.packingextraqty = 0;
                    } else {
                      alert(
                        "Quantity cannot be less than scanned Quantity,\n First Remove Items from Scanned List"
                      );
                      // console.log("1234",text.itembatcharray.length -count)
                      text.packingextraqty =
                        (text.itembatcharray.length - count) /
                        text.conversionfactor;
                      // console.log("12345", text.freepackingextraqty)
                    }
                    handleChange(
                      text,
                      setRowData,
                      igstappl,
                      rowData,
                      scandata,
                      setScandata
                    );
                  } else {
                    e.target.value =
                      text?.itembatcharray?.length -
                      (Number(text?.packingqty) + Number(text?.free));
                    console.log("e.target.value", e.target.value);

                    if (e.target.value > 0) {
                      text.packingextraqty = Number(e.target.value);
                    } else {
                      text.packingextraqty = 0;
                    }

                    console.log("text.packingextraqty", text.packingextraqty);
                    handleChange(
                      text,
                      setRowData,
                      igstappl,
                      rowData,
                      scandata,
                      setScandata
                    );
                  }
                }

                //{END         END }

                // Validate the Entered Quantity with the new Entered Quantity
                if (
                  //{start Pradeep, task id-238 start}
                  //{Pradeep, task id-334 changes conversion factor to 1 }
                  Number(text?.qty) + Number(text?.free) <
                    text?.itembatcharray?.length &&
                  text.conversionfactor == 1
                  //{END         END }
                ) {
                  e.target.value =
                    text?.itembatcharray?.length -
                    (Number(text?.packingqty) + Number(text?.free));
                  console.log("e.target.value", e.target.value);

                  alert(
                    "Quantity cannot be less than scanned Quantity,\n First Remove Items from Scanned List"
                  );
                  text.packingextraqty = Number(e.target.value);
                  console.log("text.packingextraqty", text.packingextraqty);
                  handleChange(
                    text,
                    setRowData,
                    igstappl,
                    rowData,
                    scandata,
                    setScandata
                  );
                }

                // Validate the Entered Quantity with the new Entered Quantity

                if (text?.wantbatchno === 1) {
                  console.log("wantbatchno?.qty", text?.qty, text?.free);
                  if (Number(text?.qty) + Number(text?.free) == 0) {
                    alert("Quantity cannot be less than or equal to zero.");
                  }
                }
              }}
            />
          );
        },
      },
      {
        title: "Pcs Qty",
        width: 80,
        dataIndex: "packingqty",
        key: "packingqty",
        align: "right", // nohit-388 set Align for column
        render: (_, text) => {
          return (
            <input
              className="tr001Input"
              // disabled={text?.packingextrarecno==0? false:true}
              type={"number"}
              style={{ width: "50px" }}
              defaultValue={text.packingqty}
              // disabled={onedconeinvoice&&text?.doctype=="DC"?true:false}
              onFocus={(e) => e.target.select()}
              onChange={(e) => {
                var scannedQty =
                  text?.doctype == "DC" && text?.wantbatchno == 0
                    ? scanner?.length
                    : text?.itembatcharray?.length;

                console.log("text++++", text?.qty, text?.free, scannedQty);
                if (e.target.value < 0) {
                  // document.querySelector(".tr001IgstBox").value = null;
                  e.target.value = null;
                  alert("Invalid Input, please re-enter non-negative value");
                  return false;
                }
                text.packingqty = e.target.value;
                handleChange(text, setRowData, igstappl, rowData);
                const regex = /^[0-9\b]+$/;

                if (text?.wantbatchno === 0) {
                  if (
                    regex.test(e.target.value) &&
                    Number(e.target.value) + Number(text.packingextraqty) > 0
                  ) {
                    text.packingqty = e.target.value;
                    handleChange(text, setRowData, igstappl, rowData);
                  } else {
                    e.target.value = text.packingqty;
                    alert("Box and Pieces cannot be 0");
                  }
                }
              }}
              onBlur={(e) => {
                // Validate the Entered Quantity with the new Entered Quantity
                // if (
                //   Number(text?.qty) + Number(text?.free) <
                //   text?.itembatcharray?.length
                // ) {
                //   e.target.value =
                //     text?.itembatcharray?.length -
                //     (Number(text?.qty) + Number(text?.free));

                //   text.freepackingqty = e.target.value;
                //   handleChange(text, setRowData, igstappl, rowData);
                // }

                if (
                  Number(text?.qty) + Number(text?.free) <
                  text?.itembatcharray?.length
                ) {
                  e.target.value =
                    text?.itembatcharray?.length -
                    (Number(text?.packingextraqty) + Number(text?.free));
                  alert(
                    "Quantity cannot be less than scanned Quantity,\n First Remove Items from Scanned List"
                  );
                  text.packingqty = e.target.value;
                  handleChange(text, setRowData, igstappl, rowData);
                }
              }}
            ></input>
          );
        },
      },
      {
        width: 80,
        title: "Qty",
        dataIndex: "qty",
        key: "qty",
        align: "right", // nohit-388 set Align for column
      },
      {
        hidden:
          pageDetails?.wantfreeqty && !pageDetails?.wantpackingextra
            ? false
            : true,
        width: 120,
        title: "Free Box Qty",
        dataIndex: "freepackingextraqty",
        key: "freepackingextraqty",
        align: "right", // nohit-388 set Align for column
        render: (_, text) => {
          return (
            <input
              type={"number"}
              //{Pradeep task id -314 }
              disabled={
                text.packingextrarecno == 0 || text?.isservice == 1
                  ? true
                  : false
              }
              className="tr001Input"
              defaultValue={text.freepackingextraqty}
              value={text.freepackingextraqty}
              onFocus={(e) => e.target.select()}
              onChange={(e) => {
                const regex = /^[0-9\b]+$/;
                if (e.target.value === "" || regex.test(e.target.value)) {
                  text.freepackingextraqty = e.target.value;
                  // { Pradeep, task id-238 }
                  handleChange(
                    text,
                    setRowData,
                    igstappl,
                    rowData,
                    scandata,
                    setScandata
                  );
                }
                //{Pradeep, task id-334 changes conversion factor to 12 }
                else if (
                  e.target.value === "0" &&
                  text.conversionfactor == 12
                ) {
                  console.log("hiiis");
                  text.freepackingextraqty =
                    text.itembatcharray.length / text.conversionfactor || 1;
                  handleChange(
                    text,
                    setRowData,
                    igstappl,
                    rowData,
                    scandata,
                    setScandata
                  );
                  //{END         END }
                } else {
                  alert("Invalid Input");
                  e.target.value = "";
                  text.freepackingextraqty = e.target.value;
                  // { Pradeep, task id-238 }
                  handleChange(
                    text,
                    setRowData,
                    igstappl,
                    rowData,
                    scandata,
                    setScandata
                  );
                }
              }}
              onBlur={(e) => {
                // {start  Pradeep, task id-238  start}
                if (e.target.value == "0" && text.conversionfactor == 12) {
                  if (Number(text.qty) == 0) {
                    let count =
                      Number(text.packingqty) +
                      Number(
                        text.packingextraqty * (text.conversionfactor || 1)
                      ) +
                      Number(text.freepackingqty);

                    console.log("count", count);
                    if (count >= text.itembatcharray.length) {
                      text.freepackingextraqty = 0;
                    } else {
                      alert(
                        "Quantity cannot be less than scanned Quantity,\n First Remove Items from Scanned List"
                      );
                      // console.log("1234",text.itembatcharray.length -count)
                      text.freepackingextraqty =
                        (text.itembatcharray.length - count) /
                        text.conversionfactor;
                      // console.log("12345", text.freepackingextraqty)
                    }
                    handleChange(
                      text,
                      setRowData,
                      igstappl,
                      rowData,
                      scandata,
                      setScandata
                    );
                  } else {
                    e.target.value =
                      text?.itembatcharray?.length -
                      (Number(text?.qty) + Number(text?.freepackingqty));
                    if (e.target.value > 0) {
                      text.freepackingextraqty = Number(e.target.value);
                    } else {
                      text.freepackingextraqty = 0;
                    }

                    // { Pradeep, task id-238 }
                    handleChange(
                      text,
                      setRowData,
                      igstappl,
                      rowData,
                      scandata,
                      setScandata
                    );
                  }
                }
                //{ end       end }

                // Validate the Entered Quantity with the new Entered Quantity
                if (
                  Number(text?.qty) + Number(text?.free) <
                    // { Pradeep, task id-238 }
                    //{Pradeep, task id-334 changes conversion factor to 1 }
                    text?.itembatcharray?.length &&
                  text.conversionfactor == 1
                ) {
                  e.target.value =
                    text?.itembatcharray?.length -
                    (Number(text?.qty) + Number(text?.freepackingqty));
                  alert(
                    "Quantity cannot be less than scanned Quantity,\n First Remove Items from Scanned List"
                  );
                  text.freepackingextraqty = e.target.value;
                  // { Pradeep, task id-238 }
                  handleChange(
                    text,
                    setRowData,
                    igstappl,
                    rowData,
                    scandata,
                    setScandata
                  );
                }

                if (text?.wantbatchno === 1) {
                  console.log("wantbatchno?.qty", text?.qty, text?.free);
                  if (Number(text?.qty) + Number(text?.free) == 0) {
                    alert("Quantity cannot be less than or equal to zero.");
                  }
                }
              }}
            ></input>
          );
        },
      },
      {
        hidden: pageDetails?.wantfreeqty ? false : true,
        width: 120,
        title: "Free Pcs Qty",
        dataIndex: "freepackingqty",
        key: "freepackingqty",
        align: "right", // nohit-388 set Align for column
        render: (_, text) => {
          return (
            <input
              className="tr001Input"
              type={"number"}
              disabled={text?.packingextrarecno == 0 ? false : true}
              // disabled={onedconeinvoice&&text?.doctype=="DC"?true:false}
              defaultValue={text.freepackingqty}
              onFocus={(e) => e.target.select()}
              onChange={(e) => {
                text.freepackingqty = e.target.value;
                handleChange(text, setRowData, igstappl, rowData);
              }}
              onBlur={(e) => {
                // Validate the Entered Quantity with the new Entered Quantity
                // if (
                //   Number(text?.qty) + Number(text?.free) <
                //   text?.itembatcharray?.length
                // ) {
                //   e.target.value =
                //     text?.itembatcharray?.length -
                //     (Number(text?.qty) + Number(text?.free));

                //   text.freepackingqty = e.target.value;
                //   handleChange(text, setRowData, igstappl, rowData);
                // }

                if (
                  Number(text?.qty) + Number(text?.free) <
                  text?.itembatcharray?.length
                ) {
                  e.target.value =
                    text?.itembatcharray?.length -
                    (Number(text?.qty) + Number(text?.freepackingextraqty));
                  alert(
                    "Quantity cannot be less than scanned Quantity,\n First Remove Items from Scanned List"
                  );
                  text.freepackingqty = e.target.value;
                  handleChange(text, setRowData, igstappl, rowData);
                }
              }}
            ></input>
          );
        },
      },
      {
        hidden: pageDetails?.wantfreeqty ? false : true,
        width: 100,
        title: "Free Qty",
        dataIndex: "freeqty",
        key: "freeqty",
        align: "right", // nohit-388 set Align for column
      },

      {
        hidden: pageDetails?.wantbatchno ? false : true,
        width: 200,
        title: "Batch No",
        dataIndex: "itembatchno",
        key: "itembatchno",
        render: (_, text) => {
          console.log("Text is888", onedconeinvoice && text?.doctype == "DC");
          return (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <p
                className="tr001Input"
                style={{ width: "70%" }}
                // rajendra-338 calendar disabled when isservice=true
                /// let isservice=item?.isservice //// task id -345 is service rajendra
                // disabled={
                //   onedconeinvoice &&
                //   (text?.doctype == "DC" || text?.isservice == 1)
                //     ? true
                //     : false
                // }
                // onFocus={(e) => e.target.select()}
                // defaultValue={text?.isservice == 1 ? null : text.itembatchno}
                // onChange={(e) => {
                //   console.log("TR013 igst =======>", text);
                //   text.itembatchno = e.target.value;
                //   handleChange(text, setRowData, igstappl, rowData);
                // }}
              >
                {text?.itembatchno}
              </p>
              <div>
                {text?.itembatchno ? null : (
                  <Button
                    icon={
                      <BiSelectMultiple
                        onClick={() => {
                          setItemForSelection(text);
                        }}
                      />
                    }
                  ></Button>
                )}
              </div>
            </div>
          );
        },
      },
      {
        hidden: pageDetails?.wantmanudate === 1 ? false : true,
        width: 300,
        title: "Manufacture Date",
        dataIndex: "manufacturedate",
        key: "manufacturedate",
        align: "left", // nohit-388 set Align for column
        render: (_, text) => {
          return (
            // <CallbackDateInput
            //   defaultValue={typeof text.manfacturedate=="number"?text.manfacturedate.toString():text.manfacturedate}
            //   id={"po-manufacturedate-" + text.shortguid}
            //   apiKey={"manufacturedate"}
            //   initial
            //   returnValue={(e) => {
            //     text.manufacturedate = e;
            //     handleChange(text, setRowData, igstappl, rowData);
            //   }}
            //   dateLessthan={sidate}
            //   postAlertMsg={""}

            // />
            <CallbackDateInput
              defaultValue={text.manufacturedate}
              id={"si-manufacturedate-" + text.shortguid}
              apiKey={"manufacturedate"}
              initial
              // rajendra-338 calendar disabled when isservice=true
              disabled={
                onedconeinvoice &&
                (text?.doctype == "DC" || text?.isservice == 1)
                  ? true
                  : false
              }
              returnValue={(e) => {
                text.manufacturedate = e;
                handleChange(text, setRowData, igstappl, rowData);
              }}
              dateLessthan={sidate}
              postAlertMsg={"Manufacture date must be less than SI date"}
            />
          );
        },
      },
      {
        hidden: pageDetails?.wantexpdate === 1 ? false : true,
        width: 300,
        title: "Expiry Date",
        dataIndex: "expdate",
        key: "expdate",
        align: "left", // nohit-388 set Align for column
        render: (_, text) => {
          console.log("SIDATE", text);
          return (
            <CallbackDateInput
              defaultValue={
                typeof text.expdate == "number"
                  ? text.expdate.toString()
                  : text.expdate
              }
              // rajendra-338 calendar disabled when isservice=true
              disabled={
                onedconeinvoice &&
                (text?.doctype == "DC" || text?.isservice == 1)
                  ? true
                  : false
              }
              id={"po-expdate-" + text.shortguid}
              apiKey={"expecteddeliverydate"}
              returnValue={(e) => {
                text.expdate = e;
                handleChange(text, setRowData, igstappl, rowData);
              }}
              dateGreaterthan={text.manufacturedate}
              // dateLessthan={text.manufacturedate}
            />
          );
        },
      },
      {
        width: 120,
        title: "Sale Rate",
        dataIndex: "salerate",
        key: "salerate",
        align: "right", // nohit-388 set Align for column
        render: (_, text) => {
          console.log("Sale rate of the Item is ", text);
          return (
            //{Pradeep task id -314 }
            text?.changesalerateinbill == 1 ? (
              <input
                className="tr001Input"
                disabled={
                  onedconeinvoice && text?.doctype == "DC" ? true : false
                }
                defaultValue={text.salerate}
                onFocus={(e) => e.target.select()}
                onChange={(e) => {
                  text.salerate = e.target.value;
                  handleChange(text, setRowData, igstappl, rowData);
                }}
              ></input>
            ) : (
              <p>{text.salerate}</p>
            )
          );
        },
      },
      {
        width: 110,
        title: "Discount%",
        dataIndex: "discount",
        key: "discount",
        align: "right", // nohit-388 set Align for column
        render: (_, text) => {
          console.log("text check:", text);
          return (
            <input
              className="tr001Input"
              /// task-id-253 rajendra discount amount input is unable
              // disabled={text.discountamt!=0||text.discountamt!="0.00"?true:false}

              value={
                text.discount != "0.00" || text.discount != 0
                  ? text.discount
                  : null
              }
              defaultValue={text?.discount}
              onFocus={(e) => e.target.select()}
              onChange={(e) => {
                text.discount = e.target.value;
                handleChange(text, setRowData, igstappl, rowData);
              }}
              type="number"
              max={100} /// task-id-253 rajendra discount amount input is unable
            ></input>
          );
        },
      },
      {
        width: 120,
        title: "Discount",
        dataIndex: "discountamt",
        key: "discountamt",
        align: "right", // nohit-388 set Align for column
        render: (_, text) => {
          return <p>{text?.discountamt}</p>; /// task id-253 the input box is remove here according to the percentage input discount change
        },
      },
      {
        width: 120,
        title: "Discount",
        dataIndex: "discountamt",
        key: "discountamt",
        align: "right", // nohit-388 set Align for column
        render: (_, text) => {
          return <p>{text?.discountamt}</p>; /// task id-253 the input box is remove here according to the percentage input discount change
        },
      },
      {
        width: 120,
        title: "Taxable Amount",
        dataIndex: "taxableamount",
        key: "taxableamount",
        align: "right", // nohit-388 set Align for column
      },
      {
        width: 120,
        title: "IGST%",
        dataIndex: "igstrate",
        key: "igstrate",
        align: "right", // nohit-388 set Align for column
      },
      {
        width: 120,
        title: "IGST",
        dataIndex: "igstamt",
        key: "igstamt",
        align: "right", // nohit-388 set Align for column
      },
      {
        width: 150,
        title: "Amount",
        dataIndex: "amount",
        key: "amount",
        align: "right", // nohit-388 set Align for column
      },
    ];
  } else {
    return [
      {
        title: "",
        dataIndex: "active",
        key: "active",
        width: 80,
        render: (_, text) => {
          return (
            <Popconfirm
              title="Do you want to Cancel the changes?"
              onConfirm={(e) => {
                handleRemove(text, setRowData, rowData);
              }}
            >
              <Button icon={<AiFillDelete />}></Button>
            </Popconfirm>
          );
        },
      },
      {
        width: 300,
        title: "Item Description", // nohit-425 change title Item to Item Description
        dataIndex: "itemdescn",
        key: "itemdescn",
        align: "left", // nohit-388 set Align for column
      },
      {
        width: 150,
        title: "Scanned Items",
        dataIndex: "scannedItems",
        align: "left", // nohit-388 set Align for column
        render: (_, data) => {
          console.log(
            "While Adding from DC item is",
            scanner,
            data?.itembatcharray
          );
          return (
            <p>
              {data?.doctype == "DC" && data?.wantbatchno == 0
                ? data?.batcharray?.length
                : data?.itembatcharray?.length}
            </p>
            // here this code is added to showned the scanned item length (data?.batcharray?.length) from DC
          );
        },
      },
      {
        title: "Scan Barcode",
        dataIndex: "barcode",
        align: "left",
        key: "barcode",
        width: 150,
        render: (_, text) => {
          console.log("Text is888", text);
          /// task-id 345 rajendra  here if isservice is true we don't want to show scan option
          if (text.itemasbatch == 1 && text.doctype !== "DC") {
            return (
              <Button
                disabled={
                  onedconeinvoice && text?.doctype == "DC" ? true : false
                }
                onClick={() => {
                  setOpenItem(true);
                  handleChange(
                    text,
                    setRowData,
                    igstappl,
                    rowData,
                    scandata,
                    setScandata
                  );
                }}
                // disabled={text?.packingqtyaccepted !== 0 || text?.packingextraqtyaccepted !== 0}
              >
                Item Scan
              </Button>
            );
          } else if (text?.doctype == "DC" && text?.wantbatchno == 0) {
            return (
              <Button
                w=""
                onClick={() => {
                  setModal(true);
                  setScanner(text?.batcharray); // here this code is added to showned the scanned item from DC
                }}
              >
                Show Item
              </Button>
            );
          } else {
            return null;
          }
        },
      },
      {
        hidden: !pageDetails?.wantpackingextra ? true : false,
        title: "Box Qty",
        width: 100,
        dataIndex: "packingextraqty",
        key: "packingextraqty",
        align: "right", // nohit-388 set Align for column
        render: (_, text) => {
          console.log("text check:", text);
          return (
            <input
              type={"number"}
              //{Pradeep task id -314 }   // rajendra-338 calendar disabled when isservice=true
              disabled={
                text.packingextrarecno == 0 || text?.isservice == 1
                  ? true
                  : false
              }
              className="tr001Input"
              style={{ width: "50px" }}
              defaultValue={text.packingextraqty}
              //{start Pradeep, task id-238  }
              value={text.packingextraqty}
              // onFocus={(e) => e.target.select()}
              onChange={(e) => {
                const regex = /^[0-9\b]+$/;

                // console.log("e.target.value", e.target.value)
                // console.log("conversionfactor11", e.target.value, text.conversionfactor, text.itembatcharray.length)

                //old validation on box and piece
                if (e.target.value === "" || regex.test(e.target.value)) {
                  text.packingextraqty = e.target.value;
                  //{start Pradeep, task id-238 }
                  handleChange(
                    text,
                    setRowData,
                    igstappl,
                    rowData,
                    scandata,
                    setScandata
                  );
                }

                //{start Pradeep, task id-238 }
                //{Pradeep, task id-334 changes conversion factor to 12 }
                else if (
                  e.target.value == "0" &&
                  text?.conversionfactor == 12
                ) {
                  console.log("hiiiop");
                  text.packingextraqty =
                    text.itembatcharray.length / text?.conversionfactor || 1;
                  handleChange(
                    text,
                    setRowData,
                    igstappl,
                    rowData,
                    scandata,
                    setScandata
                  );
                  //{END         END }
                } else {
                  // console.log("1111113")
                  alert("Invalid Input");
                  e.target.value = "";
                  text.packingextraqty = e.target.value;
                  //{start Pradeep, task id-238 }
                  handleChange(
                    text,
                    setRowData,
                    igstappl,
                    rowData,
                    scandata,
                    setScandata
                  );
                }

                //new validation of zero qty

                // if (regex.test(e.target.value) && (Number(e.target.value) + Number(text.packingqty)) > 0) {
                //   text.packingextraqty = e.target.value;
                //   handleChange(text, setRowData, igstappl, rowData);
                // } else {
                //   e.target.value = text.packingextraqty
                //   alert("Box and Pieces cannot be 0")
                // }
              }}
              onBlur={(e) => {
                //{start Pradeep, task id-238 start}

                if (e.target.value == "0" && text?.conversionfactor == 12) {
                  if (Number(text.free) == 0) {
                    let count =
                      Number(text.packingqty) +
                      Number(
                        text.freepackingextraqty * (text.conversionfactor || 1)
                      ) +
                      Number(text.freepackingqty);

                    console.log("count", count);
                    if (count >= text.itembatcharray.length) {
                      text.packingextraqty = 0;
                    } else {
                      alert(
                        "Quantity cannot be less than scanned Quantity,\n First Remove Items from Scanned List"
                      );
                      // console.log("1234",text.itembatcharray.length -count)
                      text.packingextraqty =
                        (text.itembatcharray.length - count) /
                        text.conversionfactor;
                      // console.log("12345", text.freepackingextraqty)
                    }
                    handleChange(
                      text,
                      setRowData,
                      igstappl,
                      rowData,
                      scandata,
                      setScandata
                    );
                  } else {
                    e.target.value =
                      text?.itembatcharray?.length -
                      (Number(text?.packingqty) + Number(text?.free));
                    console.log("e.target.value", e.target.value);

                    if (e.target.value > 0) {
                      text.packingextraqty = Number(e.target.value);
                    } else {
                      text.packingextraqty = 0;
                    }

                    console.log("text.packingextraqty", text.packingextraqty);
                    handleChange(
                      text,
                      setRowData,
                      igstappl,
                      rowData,
                      scandata,
                      setScandata
                    );
                  }
                }

                //{END         END }

                // Validate the Entered Quantity with the new Entered Quantity
                if (
                  //{start Pradeep, task id-238 start}
                  //{Pradeep, task id-334 changes conversion factor to 1 }
                  Number(text?.qty) + Number(text?.free) <
                    text?.itembatcharray?.length &&
                  text.conversionfactor == 1
                  //{END         END }
                ) {
                  e.target.value =
                    text?.itembatcharray?.length -
                    (Number(text?.packingqty) + Number(text?.free));
                  console.log("e.target.value", e.target.value);

                  alert(
                    "Quantity cannot be less than scanned Quantity,\n First Remove Items from Scanned List"
                  );
                  text.packingextraqty = Number(e.target.value);
                  console.log("text.packingextraqty", text.packingextraqty);
                  handleChange(
                    text,
                    setRowData,
                    igstappl,
                    rowData,
                    scandata,
                    setScandata
                  );
                }

                // Validate the Entered Quantity with the new Entered Quantity

                if (text?.wantbatchno === 1) {
                  console.log("wantbatchno?.qty", text?.qty, text?.free);
                  if (Number(text?.qty) + Number(text?.free) == 0) {
                    alert("Quantity cannot be less than or equal to zero.");
                  }
                }
              }}
            />
          );
        },
      },
      {
        title: "Pcs Qty",
        width: 80,
        dataIndex: "packingqty",
        key: "packingqty",
        align: "right", // nohit-388 set Align for column
        render: (_, text) => {
          return (
            <input
              type={"number"}
              //disabled={text?.packingextrarecno==0? false:true}
              // disabled={onedconeinvoice&&text?.doctype=="DC"?true:false}
              className="tr001Input"
              style={{ width: "50px" }}
              defaultValue={text.packingqty}
              onFocus={(e) => e.target.select()}
              onChange={(e) => {
                console.log("text++++", text);
                if (e.target.value < 0) {
                  // document.querySelector(".tr001IgstBox").value = null;
                  e.target.value = null;
                  alert("Invalid Input, please re-enter non-negative value");
                  return false;
                }
                text.packingqty = e.target.value;
                handleChange(text, setRowData, igstappl, rowData);
              }}
              onBlur={(e) => {
                // Validate the Entered Quantity with the new Entered Quantity
                if (
                  Number(text?.qty) + Number(text?.free) <
                  text?.itembatcharray?.length
                ) {
                  e.target.value =
                    text?.itembatcharray?.length -
                    (Number(text?.packingextraqty) + Number(text?.free));

                  text.freepackingqty = e.target.value;
                  handleChange(text, setRowData, igstappl, rowData);
                }
              }}
            ></input>
          );
        },
      },
      {
        width: 80,
        title: "Qty",
        dataIndex: "qty",
        key: "qty",
        align: "right", // nohit-388 set Align for column
      },
      {
        hidden:
          pageDetails?.wantfreeqty && !pageDetails?.wantpackingextra
            ? false
            : true,
        width: 120,
        title: "Free Box Qty",
        dataIndex: "freepackingextraqty",
        key: "freepackingextraqty",
        align: "right", // nohit-388 set Align for column
        render: (_, text) => {
          return (
            <input
              type={"number"}
              disabled={
                text.packingextrarecno > 0
                  ? false
                  : true || text?.isservice == 1
                  ? true
                  : false
              }
              className="tr001Input"
              defaultValue={text.freepackingextraqty}
              value={text.freepackingextraqty}
              onFocus={(e) => e.target.select()}
              onChange={(e) => {
                const regex = /^[0-9\b]+$/;
                if (e.target.value === "" || regex.test(e.target.value)) {
                  text.freepackingextraqty = e.target.value;
                  // { Pradeep, task id-238 }
                  handleChange(
                    text,
                    setRowData,
                    igstappl,
                    rowData,
                    scandata,
                    setScandata
                  );
                }
                //{Pradeep, task id-334 changes conversion factor to 12 }
                else if (
                  e.target.value === "0" &&
                  text?.conversionfactor == 12
                ) {
                  console.log("hiiis");
                  text.freepackingextraqty =
                    text.itembatcharray.length / text.conversionfactor || 1;
                  handleChange(
                    text,
                    setRowData,
                    igstappl,
                    rowData,
                    scandata,
                    setScandata
                  );
                  //{END         END }
                } else {
                  alert("Invalid Input");
                  e.target.value = "";
                  text.freepackingextraqty = e.target.value;
                  // { Pradeep, task id-238 }
                  handleChange(
                    text,
                    setRowData,
                    igstappl,
                    rowData,
                    scandata,
                    setScandata
                  );
                }
              }}
              onBlur={(e) => {
                // {start  Pradeep, task id-238  start}
                if (e.target.value == "0" && text?.conversionfactor == 12) {
                  if (Number(text.qty) == 0) {
                    let count =
                      Number(text.packingqty) +
                      Number(
                        text.packingextraqty * (text.conversionfactor || 1)
                      ) +
                      Number(text.freepackingqty);

                    console.log("count", count);
                    if (count >= text.itembatcharray.length) {
                      text.freepackingextraqty = 0;
                    } else {
                      alert(
                        "Quantity cannot be less than scanned Quantity,\n First Remove Items from Scanned List"
                      );
                      // console.log("1234",text.itembatcharray.length -count)
                      text.freepackingextraqty =
                        (text.itembatcharray.length - count) /
                        text.conversionfactor;
                      // console.log("12345", text.freepackingextraqty)
                    }
                    handleChange(
                      text,
                      setRowData,
                      igstappl,
                      rowData,
                      scandata,
                      setScandata
                    );
                  } else {
                    e.target.value =
                      text?.itembatcharray?.length -
                      (Number(text?.qty) + Number(text?.freepackingqty));
                    if (e.target.value > 0) {
                      text.freepackingextraqty = Number(e.target.value);
                    } else {
                      text.freepackingextraqty = 0;
                    }

                    // { Pradeep, task id-238 }
                    handleChange(
                      text,
                      setRowData,
                      igstappl,
                      rowData,
                      scandata,
                      setScandata
                    );
                  }
                }
                //{ end       end }

                // Validate the Entered Quantity with the new Entered Quantity
                if (
                  Number(text?.qty) + Number(text?.free) <
                    // { Pradeep, task id-238 }
                    //{Pradeep, task id-334 changes conversion factor to 1 }
                    text?.itembatcharray?.length &&
                  text?.conversionfactor == 1
                ) {
                  e.target.value =
                    text?.itembatcharray?.length -
                    (Number(text?.qty) + Number(text?.freepackingqty));
                  alert(
                    "Quantity cannot be less than scanned Quantity,\n First Remove Items from Scanned List"
                  );
                  text.freepackingextraqty = e.target.value;
                  // { Pradeep, task id-238 }
                  handleChange(
                    text,
                    setRowData,
                    igstappl,
                    rowData,
                    scandata,
                    setScandata
                  );
                }

                if (text?.wantbatchno === 1) {
                  console.log("wantbatchno?.qty", text?.qty, text?.free);
                  if (Number(text?.qty) + Number(text?.free) == 0) {
                    alert("Quantity cannot be less than or equal to zero.");
                  }
                }
              }}
            ></input>
          );
        },
      },
      {
        hidden: pageDetails?.wantfreeqty ? false : true,
        width: 120,
        title: "Free Pcs Qty",
        dataIndex: "freepackingqty",
        key: "freepackingqty",
        align: "right", // nohit-388 set Align for column
        render: (_, text) => {
          return (
            <input
              className="tr001Input"
              disabled={text?.packingextrarecno == 0 ? false : true}
              // disabled={onedconeinvoice&&text?.doctype=="DC"?true:false}
              type={"number"}
              defaultValue={text.freepackingqty}
              onFocus={(e) => e.target.select()}
              onChange={(e) => {
                text.freepackingqty = e.target.value;
                handleChange(text, setRowData, igstappl, rowData);
              }}
              onBlur={(e) => {
                // Validate the Entered Quantity with the new Entered Quantity
                if (
                  Number(text?.qty) + Number(text?.free) <
                  text?.itembatcharray?.length
                ) {
                  e.target.value =
                    text?.itembatcharray?.length -
                    (Number(text?.qty) + Number(text?.freepackingextraqty));

                  text.freepackingqty = e.target.value;
                  handleChange(text, setRowData, igstappl, rowData);
                }
              }}
            ></input>
          );
        },
      },
      {
        hidden: pageDetails?.wantfreeqty ? false : true,
        width: 100,
        title: "Free Qty",
        dataIndex: "freeqty",
        key: "freeqty",
        align: "right", // nohit-388 set Align for column
      },

      {
        hidden: pageDetails?.wantbatchno ? false : true,
        width: 200,
        title: "Batch No",
        dataIndex: "itembatchno",
        key: "itembatchno",
        align: "left", // nohit-388 set Align for column
        render: (_, text) => {
          return (
            <input
              className="tr001Input"
              // rajendra-338 calendar disabled when isservice=true
              ///let isservice=item?.isservice //// task id -345 is service rajendra
              disabled={
                onedconeinvoice &&
                (text?.doctype == "DC" || text?.isservice == 1)
                  ? true
                  : false
              }
              onFocus={(e) => e.target.select()}
              defaultValue={text?.isservice == 1 ? null : text.itembatchno}
              onChange={(e) => {
                text.itembatchno = e.target.value;
                handleChange(text, setRowData, igstappl, rowData);
              }}
            ></input>
          );
        },
      },
      {
        hidden: pageDetails?.wantmanudate === 1 ? false : true,
        width: 300,
        title: "Manufacture Date",
        dataIndex: "manufacturedate",
        key: "manufacturedate",
        align: "left", // nohit-388 set Align for column
        render: (_, text) => {
          return (
            <CallbackDateInput
              defaultValue={
                typeof text.manfacturedate == "number"
                  ? text.manfacturedate.toString()
                  : text.manfacturedate
              }
              // rajendra-338 calendar disabled when isservice=true
              disabled={
                onedconeinvoice &&
                (text?.doctype == "DC" || text?.isservice == 1)
                  ? true
                  : false
              }
              id={"po-manufacturedate-" + text.shortguid}
              apiKey={"manufacturedate"}
              initial
              returnValue={(e) => {
                text.manufacturedate = e;
                handleChange(text, setRowData, igstappl, rowData);
              }}
              dateLessthan={sidate}
            />
          );
        },
      },
      {
        hidden: pageDetails?.wantexpdate === 1 ? false : true,
        width: 300,
        title: "Expiry Date",
        dataIndex: "expdate",
        key: "expdate",
        align: "left", // nohit-388 set Align for column
        render: (_, text) => {
          console.log("Text is6848696", text);
          return (
            <CallbackDateInput
              defaultValue={
                typeof text.expdate == "number"
                  ? text.expdate.toString()
                  : text.expdate
              }
              // rajendra-338 calendar disabled when isservice=true
              disabled={
                onedconeinvoice &&
                (text?.doctype == "DC" || text?.isservice == 1)
                  ? true
                  : false
              }
              id={"po-expdate-" + text.shortguid}
              apiKey={"expecteddeliverydate"}
              returnValue={(e) => {
                text.expdate = e;
                handleChange(text, setRowData, igstappl, rowData);
              }}
              dateGreaterthan={text.manufacturedate}
            />
          );
        },
      },
      {
        width: 120,
        title: "Sale Rate",
        dataIndex: "salerate",
        key: "salerate",
        align: "right", // nohit-388 set Align for column
        render: (_, text) => {
          return (
            //{Pradeep task id -314 }
            text?.changesalerateinbill == 1 ? (
              <input
                className="tr001Input"
                defaultValue={text.salerate}
                disabled={
                  onedconeinvoice && text?.doctype == "DC" ? true : false
                }
                onFocus={(e) => e.target.select()}
                onChange={(e) => {
                  text.salerate = e.target.value;
                  handleChange(text, setRowData, igstappl, rowData);
                }}
              ></input>
            ) : (
              <p>{text.salerate}</p>
            )
          );
        },
      },
      {
        width: 110,
        title: "Discount%",
        dataIndex: "discount",
        key: "discount",
        align: "right", // nohit-388 set Align for column
        render: (_, text) => {
          console.log("Text=====", text);

          return (
            <input
              className="tr001Input"
              /// task-id-253 rajendra discount amount input is unable
              // disabled={text.discountamt!=0||text.discountamt!="0.00"?true:false}

              value={
                text.discount != "0.00" || text.discount != 0
                  ? text.discount
                  : null
              }
              defaultValue={text?.discount}
              onFocus={(e) => e.target.select()}
              onChange={(e) => {
                text.discount = e.target.value;
                handleChange(text, setRowData, igstappl, rowData);
              }}
              type="number"
              max={100} /// task-id-253 rajendra discount amount input is unable
            ></input>
          );
        },
      },
      {
        width: 120,
        title: "Discount",
        dataIndex: "discountamt",
        key: "discountamt",
        align: "right", // nohit-388 set Align for column
        render: (_, text) => {
          return <p>{text?.discountamt}</p>; /// task id-253 the input box is remove here according to the percentage input discount change
        },
      },
      {
        width: 120,
        title: "Discount",
        dataIndex: "discountamt",
        key: "discountamt",
        align: "right", // nohit-388 set Align for column
        render: (_, text) => {
          return <p>{text?.discountamt}</p>; /// task id-253 the input box is remove here according to the percentage input discount change
        },
      },
      {
        width: 120,
        title: "Taxable Amount",
        dataIndex: "taxableamount",
        key: "taxableamount",
        align: "right", // nohit-388 set Align for column
      },
      {
        width: 120,
        title: "CGST%",
        dataIndex: "cgstrate",
        key: "cgstrate",
        align: "right", // nohit-388 set Align for column
      },
      {
        width: 120,
        title: "CGST",
        dataIndex: "cgstamt",
        key: "cgst",
        align: "right", // nohit-388 set Align for column
      },
      {
        width: 120,
        title: "SGST%",
        dataIndex: "sgstrate",
        key: "sgstrate",
        align: "right", // nohit-388 set Align for column
      },
      {
        width: 120,
        title: "SGST",
        dataIndex: "sgstamt",
        key: "sgst",
        align: "right", // nohit-388 set Align for column
      },
      {
        width: 150,
        title: "Amount",
        dataIndex: "amount",
        key: "amount",
        align: "right", // nohit-388 set Align for column
      },
    ];
  }
};

export const handleAdd = (
  item,
  setRowData,
  igstappl,
  setSelectedItem,
  shortguid,
  salerate,
  purchaseQty,
  box,
  freeBox,
  freeQty,
  batchNo,
  expDate,
  discountAmt,
  discountPer,
  manufacturedate,
  onedconeinvoice
) => {
  var newShortguid = shortguid.getShortguid("SIF" + item.itemrecno + batchNo);
  console.log("item---", item);

  {
    /* nohit-325 - Sales Invoice Grid Salerate NaN issue solved */
  }
  var salerate = salerate ? salerate : 0.0;
  var cgstrate = item?.cgstrate ? item.cgstrate : 0.0;
  var sgstrate = item?.sgstrate ? item.sgstrate : 0.0;
  var igstrate = item?.igstrate ? item.igstrate : 0.0;
  let conversionfactor = item?.conversionfactor ? item.conversionfactor : 1;
  var packingqty = purchaseQty ? purchaseQty : 0;
  var packingextraqty = box ? box : 0;

  let isservice = item?.isservice; //// task id -345 is service rajendra
  var freepackingextraqty = freeBox ? freeBox : 0;
  var freepackingqty = freeQty ? freeQty : 0;
  var free =
    Number(freepackingqty) +
    Number(freepackingextraqty) * Number(conversionfactor);
  console.log("packingqty in Add Items", packingqty);
  console.log("packingextraqty", packingextraqty);
  console.log("conversionfactor", conversionfactor);

  var qty =
    Number(packingqty) + Number(packingextraqty) * Number(conversionfactor);
  console.log("Quantity", qty, conversionfactor);
  var discount = discountPer ? Number(discountPer) : 0.0;
  var discountamt = discountAmt ? Number(discountAmt) : 0.0;
  var expdate = expDate ? expDate : null;
  var manufacturedate = manufacturedate ? manufacturedate : null;
  var itembatchno = batchNo ? batchNo : null;

  var taxableamount = qty * (salerate - discountamt);
  var sgstamt = !igstappl ? taxableamount * (sgstrate / 100) : 0.0;
  var cgstamt = !igstappl ? taxableamount * (cgstrate / 100) : 0.0;
  var igstamt = igstappl ? taxableamount * (igstrate / 100) : 0.0;

  var amount = igstappl
    ? taxableamount + igstamt
    : taxableamount + (cgstamt + sgstamt);

  var itemObject = {
    ...item,
    shortguid: newShortguid,
    itemrecno: item.itemrecno,
    itemdescn: item.itemdescn,
    packingextraqty: packingextraqty,
    packingqty: packingqty,
    qty: Number(qty),
    free: Number(free),
    freeqty: Number(free),
    salerate: Number(salerate).toFixed(2),
    taxableamount: Number(taxableamount).toFixed(2),
    cgstrate: Number(cgstrate).toFixed(2),
    sgstrate: Number(sgstrate).toFixed(2),
    igstrate: Number(igstrate).toFixed(2),
    cgstamt: Number(cgstamt).toFixed(2),
    sgstamt: Number(sgstamt).toFixed(2),
    igstamt: Number(igstamt).toFixed(2),
    amount: Number(amount).toFixed(2),
    discount: Number(discount).toFixed(2),
    discountamt: Number(discountamt).toFixed(2),
    expdate: expdate ? expdate : item.expdate,
    //itembatchno: item?.wantbatchno === 0 ? "." : itembatchno,
    //// task-id-345 rajendra here isservice validation
    itembatchno:
      item?.wantbatchno === 0 && isservice == 0
        ? "."
        : isservice === 1 && item?.wantbatchno === 0
        ? "."
        : itembatchno,
    freepackingextraqty: Number(freepackingextraqty),
    freepackingqty: Number(freepackingqty),
    manufacturedate: manufacturedate ? manufacturedate : item?.manufacturedate,
    onedconeinvoice: onedconeinvoice,
  };
  if (item?.doctype == "DC") {
    itemObject.dcqty = Number(qty);
    itemObject.itembatcharray = itemObject?.batcharray;
  }

  //   let x=rowData?.findIndex(num => num.itemrecno==itemObject.itemrecno);

  //   if(x!==-1){
  // rowData[x]=itemObject
  // setRowData(rowData)
  //   }
  //   else{
  //     setRowData([...rowData, itemObject]);
  //   }

  console.log("While Adding from DC item is", itemObject);
  setRowData((p) => [...p, itemObject]);
  setSelectedItem(null);
};

export const handleChange = (
  item,
  setRowData,
  igstappl,
  rowData,
  scandata,
  setScandata,
  setOpenItem
) => {
  var salerate = item?.salerate ? item.salerate : 0.0;
  var cgstrate = item?.cgstrate ? item.cgstrate : 0.0;
  var sgstrate = item?.sgstrate ? item.sgstrate : 0.0;
  var igstrate = item?.igstrate ? item.igstrate : 0.0;
  let isservice = item?.isservice; //// task id -345 is service rajendra

  let packingextraqty = item?.packingextraqty ? item?.packingextraqty : 0.0;
  let packingqty = item?.packingqty ? item.packingqty : 0.0;
  let itembatchno = item?.itembatchno ? item?.itembatchno : null;
  let expdate = item?.expdate ? item?.expdate : null;
  let conversionfactor = item?.conversionfactor ? item.conversionfactor : 1;

  let freepackingextraqty = item?.freepackingextraqty
    ? item?.freepackingextraqty
    : 0.0;
  let freepackingqty = item?.freepackingqty ? item?.freepackingqty : 0.0;
  let free =
    Number(freepackingqty) +
    Number(freepackingextraqty) * Number(conversionfactor);
  let qty =
    Number(packingqty) + Number(packingextraqty) * Number(conversionfactor);
  let discountamt = item?.discountamt ? item.discountamt : 0;
  var discount = item?.discount ? item.discount : 0;

  ///===== task id-253 rajendra discount butoon unable  start=====  /////
  console.log("RAjendra==", discount, discountamt, isservice);
  if (Number(discount) >= 0 && Number(salerate) > 0) {
    discountamt = (Number(discount) * Number(salerate)) / 100;
  }
  //  if (Number(discountamt) > 0 && Number(salerate) > 0) {
  //   // Calculate discount based on discountamt and salerate
  //   discount = ((Number(discountamt) / Number(salerate)) )

  // }

  /// NAN problem in SI by rbn date 6th may 2023

  // Check if discount and salerate are valid numbers
  // if (Number(discount) > 0 && Number(salerate) > 0) {
  //   discountamt = (Number(discount) * Number(salerate)) / 100;
  // }

  // // Round the discountamt to 2 decimal places
  // discountamt = discountamt.toFixed(2);

  // discountamt = Number(discount/100 * purrate)
  console.log("RAjendra==4555", discount, discountamt);
  var taxableamount =
    Number(qty) * Number(Number(salerate) - Number(discountamt));

  var sgstamt = !igstappl ? taxableamount * (sgstrate / 100) : 0.0;
  var cgstamt = !igstappl ? taxableamount * (cgstrate / 100) : 0.0;
  var igstamt = igstappl ? taxableamount * (igstrate / 100) : 0.0;

  var amount = igstappl
    ? taxableamount + igstamt
    : taxableamount + (cgstamt + sgstamt);

  var itemObject = {
    ...item,
    itemrecno: item.itemrecno,
    itemdescn: item.itemdescn,
    box: +item?.box,
    salerate: Number(salerate).toFixed(2),
    taxableamount: Number(taxableamount).toFixed(2),
    ccgstrate: Number(cgstrate).toFixed(2),
    sgstrate: Number(sgstrate).toFixed(2),
    igstrate: Number(igstrate).toFixed(2),
    cgstamt: Number(cgstamt).toFixed(2),
    sgstamt: Number(sgstamt).toFixed(2),
    igstamt: Number(igstamt).toFixed(2),
    amount: Number(amount).toFixed(2),
    discount: Number(Number(discount).toFixed(2)), //// task id-253 rajendra converted to number
    discountamt: Number(Number(discountamt).toFixed(2)), //// task id-253 rajendra converted to number
    packingextraqty: Number(packingextraqty),
    packingqty: Number(packingqty),
    qty: Number(qty),
    free: Number(free),
    freeqty: Number(free),
    expdate: expdate ? expdate : item.expdate,
    // itembatchno: rowData?.wantbatchno==0?".":itembatchno,
    //// task-id-345 rajendra here isservice validation
    itembatchno:
      item?.wantbatchno === 0 && isservice == 0
        ? "."
        : isservice === 1 && item?.wantbatchno === 0
        ? "."
        : itembatchno,
    freepackingextraqty: Number(freepackingextraqty),
    freepackingqty: Number(freepackingqty),
  };
  console.log("ITEM=====123", discount, discountamt, salerate, itemObject);
  console.log("itemObject", itemObject, item);
  //{Pradeep, task id-334  }
  // if (item?.onedconeinvoice && item?.doctype == "DC" && (itemObject?.qty + itemObject?.freeqty < itemObject?.dcqty)) {
  //   alert("Total Added Quantity Should match with the DC Quantity for Item " + itemObject?.itemdescn)
  // }
  //{end                           end}

  var currentItem = rowData.map((itm) => {
    if (itm.shortguid == item.shortguid) {
      return { ...itemObject };
    } else {
      return { ...itm };
    }
  });

  setRowData(() => currentItem);
  setScandata(item);
};

export const handleRemove = (item, setRowData, rowData) => {
  var removedArray = removeByAttribute(rowData, "shortguid", item.shortguid);

  setRowData(() => [...removedArray]);
  console.log("Item Scan Items", item?.onedconeinvoice, item?.doctype);
  if (item?.onedconeinvoice && item?.doctype == "DC") {
    setRowData(() => []);
  }
};

export const handleRemovePrev = (item, setRowData, rowData) => {
  console.log("Remove", item, setRowData, rowData, removedArray);

  var removedArray = removeByAttribute(rowData, "itemrecno", item.itemrecno);
  setRowData(() => [...removedArray]);
  if (item?.onedconeinvoice && item?.doctype == "DC") {
    setRowData(() => []);
  }
};

import React, { useContext, useEffect, useState } from "react";
import { Button, Input, Modal } from 'antd';
import { TabStore } from "../../TabsStructure/TabsStore";
import { AuthStore } from "../../Auth/AuthStore";
import { dateFormatter } from "../../CommonControllers/AppFunction";
import { RxCross2 } from "react-icons/rx";

const ScannedItem=({  modal,setModal,data,
    openItem,
    setOpenItem,
    rowData,
    setRowData,
    renderer,
    setRenderer,
    setValidator,
    supplierrecno,
    forFlag})=>{
 
  //~^ These values are calculated for the UI display only, no func can manipulate these values
console.log("ROWDATA======>",data)
  const [scanData, setScanData] = useState(data);
  const [serial,setSerialno] = useState(null)


  const [barcode, setBarcode] = useState("");
  const [barcodeData, setBarcodeData] = useState(scanData?.barcodeData || []);

  const [acceptedBoxes, setAcceptedBoxes] = useState(
    scanData?.packingextraqtyaccepted ?? 0
  );
  const [acceptedPieces, setAcceptedPieces] = useState(
    scanData?.packingqtyaccepted ?? 0
  );
  const tabs = useContext(TabStore);
  const auth = useContext(AuthStore);

  useEffect(() => {
    setScanData(data);
  }, [data]);


  let carryOver = Math.floor(scanData?.packingqty / scanData?.conversionfactor);

  let boxes = Number(scanData?.packingextraqty) + Number(carryOver);
 
  // let pieces =
  //   scanData?.packingqty >= scanData?.conversionfactor
  //     ? Math.floor(scanData?.packingqty % scanData?.conversionfactor)
  //     : scanData?.packingqty;
 
  let pieces = scanData?.free ? scanData?.qty + scanData?.free : scanData?.qty;
  if(forFlag=="PR"||forFlag=="SR"){
   pieces=scanData?.returnfree ? scanData?.returnqty + scanData?.returnfree : scanData?.returnqty;
  }






 
const handleOk=()=>{
  setModal(false)
}

 


  return (
    <div>
      <Modal
        open={modal}
        maskClosable={false}
        onCancel={()=>setModal(!modal)}
        onOk={()=>setModal(!modal)}
        width={786}
        style={{ top: 0 }}
        title={scanData?.label ?? scanData?.itemdescn}
        closable={false}
      >
        <div className="itemsMainBox">
         

          <div className="barcodeTableWrapper">
            {data?.length > 0 && (
              <table className="barcodeTable">
                <thead>
                  <tr>
                    <th>Barcode</th>
                    <th>Serial No</th>
                    <th>Mfg Date</th>
                    <th>Exp Date</th>
                    {/* <th>Delete</th> */}
                  </tr>
                </thead>
                <tbody>
                  {data?.map((_barcode, i) => {
                  
                    return (
                      <tr>
                        <td>{_barcode?.itembarcode}</td>
                        <td>{_barcode?.serialno}</td>
                        <td>{dateFormatter(_barcode?.manudate)}</td>
                        <td>{dateFormatter(_barcode?.expdate)}</td>
                        {/* <td>
                          <Button
                            size="medium"
                            danger
                            type="primary"
                            shape="circle"
                            //</td>onClick={() => handleBarcodeDelete(_barcode)
                            // }
                          >
                            <RxCross2 style={{ margin: "4px 0 0 1px" }} />
                          </Button>
                        </td> */}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </Modal>
    </div>
  );
}
export default ScannedItem
const barcodeIp = {
    // width: "46%",
    margin: "18px 0",
    display: "flex",
    gap: "24px",
  };
  
  const accAssest = {
    display: "flex",
    alignItems: "center",
    boxSizing: "border-box",
    gap: "12px",
  };
  
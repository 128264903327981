

import { notification } from "antd";
import axios from "axios";
import { useState, useEffect, useContext } from "react";
import { AuthStore } from "../../Auth/AuthStore";
import { TabStore } from "../../TabsStructure/TabsStore";
import { baseUrl } from "../Urls";
import { currentTabData } from "../Variable";
import useExecuteApi from "./useExecuteApi";
import { datedisplay, isArray } from "../AppFunction";

export default function useDeleteApi() {
  const auth = useContext(AuthStore);
  const tabStore = useContext(TabStore);
  const executeApi = useExecuteApi();

  var changedRows =
    tabStore?.state?.tabs[tabStore.state.tabIndex]?.selectedDocuments;

  async function execute(body) {
    var body = tabStore?.state?.tabs[tabStore.state.tabIndex]?.deleteVoucherPayload

    if (body?.hasOwnProperty("validations")) {
      //  let errors_string = body?.validations.join("<br>");
      const _description = (
        <>
          {body?.validations?.map(function (item) {
            return item + "</br>";
          })}
        </>
      );
      if (body?.validations?.length > 0) {
        auth.state.contextHolder.open({
          message: `An Error has occured`,
          description: (
            <div style={{ display: "flex", flexDirection: "column" }}>
              {body?.validations.map((item) => (
                <p>{item}</p>
              ))}
            </div>
          ),
          duration: 5,
        });
        return;
      }
    }

    //console.log("datalength is = ",body)
   // if (Object?.keys(body)?.length == 0) return;
    // var newBody = body
    // if (isArray(body)) {

    //   var newBody = body?.map((item) => {
    //     console.log("Body is API SAVED", item)
    //     for (const property in item) {

    //       // Added Last includes ("/") because when date was comming in correct format it was getting converted to undefined
    //       if (property?.includes("date") &&  item[property] && item[property]?.includes("/")) {
    //         //// task id-369 rajendra MS108 the save button is not executing && item[property]?.includes("/") this one is removed
    //         console.log("Date is.........>>>>>>>>", property,item[property]);
    //         item[property] = datedisplay(item[property]).dataDate
    //         console.log("Date is.........>>>>>>>>", property,item[property]);
    //       }
    //     }
    //     console.log("Converted Item is", item)
    //     return item
    //   })

    // }
    body = {
      data: body,
      tenantrecno: auth.state.userdata.tenantrecno,
      domainrecno: auth.state.userdata.domainrecno,
      domainuserrecno: auth.state.userdata.recno,
      userrolerecno: auth.state.userdata.recno,
      fastpath: tabStore.state.tabs[tabStore.state.tabIndex].name,
    };

    var url =
      baseUrl + tabStore.state.tabs[tabStore.state.tabIndex]?.deleteUrl + "/";
    var type = tabStore?.state?.tabs[tabStore.state.tabIndex]?.saveApiType;

    let executeurl = tabStore?.state?.tabs[tabStore.state.tabIndex]?.apiUrl;

    var resp;
    try {
      if (type == "PATCH") {
        resp = await axios.patch(url, body);
      } else if (type == "GET") {
        resp = await axios.get(url, body);
      } else {
        resp = await axios.post(url, body);
      }

      // If the Function is of Execute Type
      // Update the TabStore to store the data as API-Response
    
      if (resp.data.Success) {
        auth.state.contextHolder.open({
          message: `Success`,
          description: resp?.data.Message,
          duration: 3,
          style: { background: "#a7efb6" },
        });
        executeApi.execute();
        tabStore.dispatch({
          type: "UPDATE_DATA",
          fieldType: "selectedDocuments",
          text: [],
        });

        //~# Checking tabstore for isPrint flag, if true run func

        if (currentTabData(tabStore)?.changedRows?.isPrintBarcode) {
          // print
          tabStore.dispatch({
            type: "UPDATE_PRINT_BARCODE",
            printInsBarcode: resp?.data?.forbarcode,
          });
        }

        tabStore.dispatch({
          type: "UPDATE_DATA",
          fieldType: "changedRows",
          text: {}, // reset to Initial Stage use Blank instead of null - Mandar
        });

        tabStore.dispatch({
          type: "UPDATE_DATA",
          fieldType: "pageStatus",
          text: "Success",
        });
        tabStore.dispatch({
          type: "UPDATE_DATA",
          fieldType: "selectedDocuments",
          text: [],
        });

        // func on every save =>

        console.log("executeurl");
        console.log(executeurl);
        if (executeurl != null) {
          executeApi.execute();
        }
      } else {
        auth.state.contextHolder.open({
          message: `An Error has occured`,
          description: resp?.data.Message,
          duration: 3,
          style: { background: "#efa7a7" },
        });

        var message = "";

        if (resp.data?.Error[0].Error.dcmsg.length > 0) {
          resp.data?.Error[0].Error.dcmsg.forEach((item) => {
            message = message + "\n" + item;
          });
        }

        if (resp.data?.Error[0].Error.simsg.length > 0) {
          if (message !== "") {
            message += "\n"; // Add a newline if dcmsg messages were already added
          }
          resp.data?.Error[0].Error.simsg.forEach((item) => {
            message = message + "\n" + item;
          });
        }
        auth?.state?.contextHolder?.open({
          message: `An Error has occured`,
          description: message,
          duration: 3,
          style: { background: "#efa7a7" },
        });
        tabStore.dispatch({
          type: "UPDATE_DATA",
          fieldType: "pageStatus",
          text: resp.data.Message,
        });

        tabStore.dispatch({
          type: "UPDATE_DATA",
          fieldType: "selectedDocuments",
          text: [],
        });

        executeApi.execute();
      }
    } catch (error) {
     
      auth?.state?.contextHolder?.info({
        message: `An Error has Occured`,
        description: String(error?.response?.data?.Error),
        duration: 3,
        style: { background: "#efa7a7" },
      });
    }
  }

  return { execute, changedRows };
}

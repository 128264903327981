import React, { useContext, useEffect, useState } from "react";
import { TabStore } from "../../../TabsStructure/TabsStore";
import { apiCall } from "../../ApiCalls";
import ReactPrint from "../../Print/ReactPrint";
import { getDistrict } from "../../Urls";
import EditableTable from "./EditableTable";

function TestTables() {

    // Api Call will happen from button click or either directly from the component
    const [columns, setColumns] = useState([])
    const [rows, setRows] = useState([])
    const tabSore = useContext(TabStore)
    useEffect(() => {
        getData()
    }, [])
    
    useEffect(() => {
        console.log("Tabs Store Data has been changed", tabSore.state.tabs[tabSore.state.tabIndex]?.apiResponse)
        setColumns(tabSore.state.tabs[tabSore.state.tabIndex]?.apiResponse?.columnsinfo)
        setRows(tabSore.state.tabs[tabSore.state.tabIndex].apiResponse?.Message)
    }, [tabSore.state.tabs[tabSore.state.tabIndex].apiResponse])

  

  async function getData() {
    const resp = await apiCall("POST", getDistrict, { tenantrecno: 3, domainrecno: 508 });
    console.log("Get DoTabsmain Supplier Api Response", resp);
    // tabSore.dispatch({ type: "UPDATE_DATA", fieldType: "apiResponse", text: resp });
  }
  return (
    <div>
      {/* <EditableTable columnsArray={columns} rowsArray={rows} /> */}
      {/* ReactPrint testing  */}
      <ReactPrint />
    </div>
  );
}

export default TestTables;

import { Spin, Table } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { TabStore } from "../../TabsStructure/TabsStore";
import { piColumns } from "./PurchaseInvoiceFunc";

const ItemsTable = ({
  rowData,
  setRowData,
  igstappl,
  scandata,
  setScandata,
  setOpenItem,
  purchaseInvDate,
  useChangedRows,
  change,
  pageDetails,
}) => {
  const [showTable, setShowTable] = useState(true);
  const tabStore = useContext(TabStore);

  useEffect(() => {
    console.log(
      `tr001 items table re-rendered ?? after deleting`,
      tabStore.state.tabs[tabStore.state.tabIndex]?.editIndex
    );
    setShowTable(false);

    setTimeout(() => {
      setShowTable(true);
    }, 100);
  }, [
    rowData?.length,
    tabStore.state.tabs[tabStore.state.tabIndex]?.editIndex,
    change,
  ]);

  return (
    <>
      <div>
        {showTable ? (
          <Table
            scroll={{
              x: 500,
              y: 500,
            }}
            size="large"
            className="tr001-purchaseOrder-itemsTable"
            bordered
            pagination={false}
            columns={piColumns(
              igstappl,
              setRowData,
              rowData,
              scandata,
              setScandata,
              setOpenItem,
              purchaseInvDate,
              pageDetails
            )?.filter((item) => !item.hidden)}
            dataSource={rowData}
            summary={(pageData) => {
              let qty = 0;
              let free = 0;
              let packingextraqty = 0;
              let packingqty = 0;
              let freepackingextraqty = 0;
              let freepackingqty = 0;
              let grossAmountTotal = 0;

              var existingObject = useChangedRows.getValue();
              console.log("Existing Object is", existingObject);
              pageData.forEach((item) => {
                qty = Number(qty) + Number(item?.qty);
                free = Number(free) + Number(item?.free);
                packingextraqty =
                  Number(packingextraqty) + Number(item?.packingextraqty);
                packingqty = Number(packingqty) + Number(item?.packingqty);
                freepackingextraqty =
                  Number(freepackingextraqty) +
                  Number(item?.freepackingextraqty);
                freepackingqty =
                  Number(freepackingqty) + Number(item?.freepackingqty);
                grossAmountTotal =
                  Number(grossAmountTotal) + Number(item?.grossamt);
              });

              return (
                <Table.Summary>
                  {!igstappl ? (
                    <Table.Summary.Row>
                      <Table.Summary.Cell index={1}>{null}</Table.Summary.Cell>
                      <Table.Summary.Cell>
                        <p style={{ ...pStyles }}>Total : </p>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell>
                        <p style={{ ...itemTablePStyles }}>{null}</p>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell></Table.Summary.Cell>

                      {pageDetails?.wantpackingextra ? (
                        <Table.Summary.Cell>
                          <p style={{ ...itemTablePStyles }}>
                            {packingextraqty}
                          </p>
                        </Table.Summary.Cell>
                      ) : null}

                      <Table.Summary.Cell>
                        <p style={{ ...itemTablePStyles }}>{packingqty}</p>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell>
                        <p style={{ ...itemTablePStyles }}>{qty}</p>
                      </Table.Summary.Cell>

                      {pageDetails?.wantpackingextra &&
                      pageDetails?.wantfreeqty ? (
                        <Table.Summary.Cell>
                          <p style={{ ...itemTablePStyles }}>
                            {freepackingextraqty}
                          </p>
                        </Table.Summary.Cell>
                      ) : null}

                      {pageDetails?.wantfreeqty ? (
                        <Table.Summary.Cell>
                          <p style={{ ...itemTablePStyles }}>
                            {freepackingqty}
                          </p>
                        </Table.Summary.Cell>
                      ) : null}
                      {pageDetails?.wantfreeqty ? (
                        <Table.Summary.Cell>
                          <p style={itemTablePStyles}>{free}</p>
                        </Table.Summary.Cell>
                      ) : null}
                      {pageDetails?.wantbatchno ? (
                        <Table.Summary.Cell></Table.Summary.Cell>
                      ) : null}

                      {pageDetails?.wantmanudate === 1 ? (
                        <Table.Summary.Cell></Table.Summary.Cell>
                      ) : null}
                      {pageDetails?.wantexpdate === 1 ? (
                        <Table.Summary.Cell></Table.Summary.Cell>
                      ) : null}
                      <Table.Summary.Cell></Table.Summary.Cell>
                      <Table.Summary.Cell>
                        <p style={{ ...itemTablePStyles }}>
                          {Number(grossAmountTotal).toFixed(2)}
                        </p>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell></Table.Summary.Cell>
                      <Table.Summary.Cell></Table.Summary.Cell>
                      <Table.Summary.Cell>
                        <p style={{ ...itemTablePStyles }}>
                          {existingObject?.taxableamount}
                        </p>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell></Table.Summary.Cell>
                      <Table.Summary.Cell>
                        <p style={{ ...itemTablePStyles }}>
                          {existingObject?.sgstamt}
                        </p>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell></Table.Summary.Cell>
                      <Table.Summary.Cell>
                        <p style={{ ...itemTablePStyles }}>
                          {existingObject?.cgstamt}
                        </p>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell>
                        <p style={{ ...itemTablePStyles }}>
                          {existingObject?.amount}
                        </p>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell>
                        <p style={{ ...itemTablePStyles }}>{null}</p>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell></Table.Summary.Cell>
                    </Table.Summary.Row>
                  ) : (
                    <Table.Summary.Row>
                      <Table.Summary.Cell index={1}>{null}</Table.Summary.Cell>
                      <Table.Summary.Cell>
                        <p style={{ ...pStyles }}>Total : </p>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell>
                        <p style={{ ...itemTablePStyles }}>{null}</p>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell></Table.Summary.Cell>
                      {pageDetails?.wantpackingextra ? (
                        <Table.Summary.Cell>
                          <p style={{ ...itemTablePStyles }}>
                            {packingextraqty}
                          </p>
                        </Table.Summary.Cell>
                      ) : null}
                      <Table.Summary.Cell>
                        <p style={itemTablePStyles}>{packingqty}</p>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell>
                        <p style={itemTablePStyles}>{qty}</p>
                      </Table.Summary.Cell>

                      {pageDetails?.wantpackingextra &&
                      pageDetails?.wantfreeqty ? (
                        <Table.Summary.Cell>
                          <p style={{ ...itemTablePStyles }}>
                            {freepackingextraqty}
                          </p>
                        </Table.Summary.Cell>
                      ) : null}

                      {pageDetails?.wantfreeqty ? (
                        <Table.Summary.Cell>
                          <p style={{ ...itemTablePStyles }}>
                            {freepackingqty}
                          </p>
                        </Table.Summary.Cell>
                      ) : null}
                      {pageDetails?.wantfreeqty ? (
                        <Table.Summary.Cell>
                          <p style={itemTablePStyles}>{free}</p>
                        </Table.Summary.Cell>
                      ) : null}
                      {pageDetails?.wantbatchno ? (
                        <Table.Summary.Cell></Table.Summary.Cell>
                      ) : null}

                      {pageDetails?.wantmanudate === 1 ? (
                        <Table.Summary.Cell></Table.Summary.Cell>
                      ) : null}
                      {pageDetails?.wantexpdate === 1 ? (
                        <Table.Summary.Cell></Table.Summary.Cell>
                      ) : null}
                      <Table.Summary.Cell></Table.Summary.Cell>
                      <Table.Summary.Cell>
                        <p style={itemTablePStyles}>
                          {Number(grossAmountTotal).toFixed(2)}
                        </p>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell></Table.Summary.Cell>
                      <Table.Summary.Cell></Table.Summary.Cell>
                      <Table.Summary.Cell>
                        <p style={itemTablePStyles}>
                          {existingObject?.taxableamount}
                        </p>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell></Table.Summary.Cell>
                      <Table.Summary.Cell>
                        <p style={itemTablePStyles}>
                          {existingObject?.igstamt}
                        </p>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell>
                        <p style={itemTablePStyles}>{existingObject?.amount}</p>
                      </Table.Summary.Cell>
                    </Table.Summary.Row>
                  )}
                </Table.Summary>
              );
            }}
          />
        ) : (
          <div style={{ ...loaderStyle }}>
            <Spin size="large" />
          </div>
        )}
      </div>
    </>
  );
};

export default ItemsTable;

const loaderStyle = {
  height: "max-content",
  width: "max-content",
  display: "flex",
  alignContent: "center",
  margin: "50px auto",
};

const itemTablePStyles = {
  textAlign: "right",
  fontWeight: "700",
  fontSize: "16px",
  color: "black",
};

const pStyles = {
  textAlign: "left",
  fontWeight: "700",
  fontSize: "16px",
  color: "black",
};

import React from "react";
import LineCharts from "./Graphs/LineChart";
import BarCharts from "./Graphs/BarChart";
import PieCharts from "./Graphs/PieCharts";
import AreaCharts from "./Graphs/AreaChart";
import DashboardTable from "./Graphs/DashboardTable";

function Dashboard() {
  // Here we will call an API checking which page is it and what kind of Dashboard to be displayed
  // Here we read data from the AuthStore and send to API to retieve the data

  // Sample Expected Response is as follows
  {
    /**
        This is the expected Sample Response from the API

        [
            {
                position : 1
                type : "lineargraph",
                api : "/urloftheapi/",
                size : 1
            },
            {
                position : "2"
                type : "bargraph",
                api : "/orloftheapi",
                size : 1
            },
            {
                position : 3,
                type : "table",
                api : "apioftheurl",
                size : 2
            }
        ]


    */
  }

  const sampleData = [
    {
      type: "lineargraph",
      api: "/urloftheapi/",
      size: 3,
    },
    {
      type: "bargraph",
      api: "/orloftheapi",
      size: 3,
    },
    {
      type: "piechart",
      api: "apioftheurl",
      size: 3,
    },
    {
      type: "table",
      api: "apioftheurl",
      size: 3,
    },
    // {

    //   type: "table",
    //   api: "apioftheurl",
    //   size: 1,
    // },
  ];

  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        display: "grid",
        gridTemplateColumns: "repeat(6, 1fr)",
        gridAutoFlow: "row",
        padding: "10px",
        gap: "10px",
        background: "#GGGGGG",
      }}
    >
      {sampleData?.map((dashItem) => {
        if (dashItem.type === "lineargraph") {
          return (
            <div
              style={{
                gridColumn: "span " + dashItem.size,
                background: "white",
                boxShadow: "2px 4px 8px 0 rgba(0,0,0,0.2)",
                transition: "0.3s",
                padding: "10px",
              }}
            >
              <LineCharts apiUrl={dashItem.apiUrl} />
            </div>
          );
        } else if (dashItem.type === "bargraph") {
          return (
            <div
              style={{
                gridColumn: "span " + dashItem.size,
                background: "white",
                boxShadow: "2px 4px 8px 0 rgba(0,0,0,0.2)",
                transition: "0.3s",
                padding: "10px",
              }}
            >
              <BarCharts apiUrl={dashItem.apiUrl} />
            </div>
          );
        } else if (dashItem.type === "piechart") {
          return (
            <div
              style={{
                gridColumn: "span " + dashItem.size,
                background: "white",
                boxShadow: "2px 4px 8px 0 rgba(0,0,0,0.2)",
                transition: "0.3s",
                padding: "10px",
              }}
            >
              <PieCharts apiUrl={dashItem.apiUrl} />
            </div>
          );
        } else if (dashItem.type === "areachart") {
          return (
            <div
              style={{
                background: "white",
                boxShadow: "2px 4px 8px 0 rgba(0,0,0,0.2)",
                transition: "0.3s",
                padding: "10px",
                gridColumn: "span " + dashItem.size,
              }}
            >
              <AreaCharts apiUrl={dashItem.apiUrl} />
            </div>
          );
        } else if (dashItem.type === "table") {
          return (
            <div
              style={{
                // background:"white",
                boxShadow: "2px 4px 8px 0 rgba(0,0,0,0.2)",
                transition: "0.3s",
                padding: "10px",
                gridColumn: "span " + dashItem.size,
              }}
            >
              <DashboardTable apiUrl={dashItem.apiUrl} />
            </div>
          );
        }
      })}
    </div>
  );
}

export default Dashboard;

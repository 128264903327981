import { Input } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { TabStore } from "../../../TabsStructure/TabsStore";
import useSaveChangedRows from "../../Hooks/useSaveChangedRows";

function SaveTextInput({
  displayLabel,
  placeholder,
  change,
  className,
  style,
  type,
  required,
  defaultValue,
  apikey,
  id,
  returnValue,
  isrequired, /// here task-id 400 rajendra mantodatary field
  nonEditable,
  width,
  pageIndex
}) {
  // Steps : onChange cahnge the value of the state variable in the component
  // OnBlur : in case the component needs to be onBlue save the changed value to the tabStore on blue event, else on change event
  // For Execute we save the values to the apiVariables store in tabStore

  // console.log("apikeyyyyy",apikey)

  const execute = useSaveChangedRows();
  const [textValue, setTextValue] = useState("");
  const [partyValue, setPartyValue] = useState(null);
  const [isFocused, setIsFocused] = useState(false);
  const tabStore = useContext(TabStore);
  // let drpWidth = width ? width : "150px";
  useEffect(() => {
    // console.log("MY RENDER: ",pageIndex, execute.getValue(apikey, pageIndex),defaultValue,apikey);
    if ((pageIndex>=0)) {
      // console.log("MY RENDER: ",pageIndex, execute.getValue(apikey, pageIndex),defaultValue,apikey);
      if (defaultValue) {
        // console.log("RENDERING", execute);
       
        if (execute.getValue(apikey, pageIndex)) {
          setTextValue(execute.getValue(apikey, pageIndex));
          setPartyValue(execute.getValue(apikey, pageIndex));
        } else if (execute.getValue(apikey, pageIndex) == "") {
          setTextValue(execute.getValue(apikey, pageIndex));
          setPartyValue(execute.getValue(apikey, pageIndex));
        } else {
          setTextValue(defaultValue);
          setPartyValue(defaultValue);
          execute.updateValue(apikey, defaultValue);
        }
      } else {
       
        if (execute.getValue(apikey, pageIndex)) {
          setTextValue(execute.getValue(apikey, pageIndex));
          setPartyValue(execute.getValue(apikey, pageIndex));
        }
      }
    }
   
    // tabStore.dispatch({ type: "UPDATE_TABS_ARRAY", changedRowsValue: "",  keyName: "" })
    if (tabStore.state.tabs[pageIndex]?.pageStatus == "Success") {
      tabStore.dispatch({
        type: "UPDATE_DATA",
        fieldType: "pageStatus",
        text: null,
      });
    }
  }, [defaultValue, tabStore,pageIndex]);

  useEffect(() => {
    if (pageIndex) {

      if (execute.getValue(apikey, pageIndex)) {
        if (!textValue) {
          setTextValue(execute.getValue(apikey, pageIndex));
        }
      }
    }
  }, [execute.getValue(apikey, pageIndex)]);

  useEffect(() => {
    if (pageIndex) {

      if (execute.getValue(apikey, pageIndex)) {
        if (!partyValue) {
          setPartyValue(execute.getValue(apikey, pageIndex));
        }
      }
    }
  }, [execute.getValue(apikey, pageIndex)]);

  useEffect(() => {
    setTextValue("");
    setPartyValue("");
  }, [tabStore.state.tabs[pageIndex]?.pageStatus == "Success"]);


  const onChange = (e) => {
    if (!nonEditable) {
      setTextValue(e.target.value);
      setPartyValue(e.target.value);
    }
  };

  const handleFocus = () => {
    // This function is called when the input element receives focus.
    setIsFocused(true)
  }

  const handleBlur = () => {
    // This function is called when the input element loses focus.
    setIsFocused(false)
  }

  var className = "inputBox" + " " + className;

  function updatedValue(e) {
    handleBlur();
    if (!nonEditable) {
      setTextValue(e.target.value);
      setPartyValue(e.target.value);
      // Send the Data to the tabStore
      console.log("tabStore in savetextinput", apikey, e.target.value);

      execute.updateValue(apikey, e.target.value);
      if (returnValue) {
        returnValue(e.target.value);
      }
    }
  }

  const mainFrame = {
    display: "flex",
    flexDirection: "column",

    gap: "row" && "8px",
    textAlign: "start",
  };
  return (
    <div style={{ ...mainFrame, width: width ? width : "150" }}>
      {/* <label>
        {isrequired ? (
          <span>
            {displayLabel}
            <span style={{ color: "red" }}>*</span>
          </span>
        ) : (
          `${displayLabel ? displayLabel : ""}`
        )}
      </label> */}
<div style={{ position: 'relative' }}>
<label
              htmlFor={id}
              className={`label ${isFocused || textValue ? 'focused' : 'unfocused'}`}
              style={{
                position: 'absolute',
                top: isFocused || textValue ? '-20px' : '50%',
                left: '8px',
                //backgroundColor: isFocused || textValue ? 'white' : "transparent",
                padding: '0 4px',
                fontSize: isFocused || textValue ? '12px' : '16px',
                color: isFocused || textValue ? 'yellow' : 'red',
                pointerEvents: 'none',
                transform:
                  isFocused || textValue
                    ? 'translateY(0)'
                    : 'translateY(-50%)',
                transition:
                  'top 0.3s, transform 0.3s, font-size 0.3s, color 0.3s'
              }}
            >
               {isrequired ? (
                <span className={`label ${isFocused || textValue ? 'focused' : 'unfocused'}`}>
                  {isFocused || textValue? displayLabel : placeholder}
                  <span style={{ color: 'red' }}>*</span>
                </span>
              ) : (
                <span className={`label ${isFocused || textValue ? 'focused' : 'unfocused'}`}>
                  {isFocused || textValue? displayLabel : placeholder}
                 
                </span>
              )}
            </label>
      {/*// here task-id 400 rajendra mantodatary field */}
      <div style={{ flexGrow: 1 }}>
        {change ? (
          <input
            id={id}
            className={className}
            style={{ ...style }}
            type={type}
            // placeholder={placeholder}
            onChange={updatedValue}
            value={defaultValue}
            required={required}
            onFocus={handleFocus}
              onBlur={handleBlur}
          />
        ) : (
          <input
            className={className}
            style={style}
            id={id}
            type={type}
            // placeholder={placeholder}
            onBlur={updatedValue}
            onChange={onChange}
            value={textValue}
            required={required}
            onFocus={handleFocus}
             
          />
        )}
      </div></div>
     
    </div>
  );
}

export default SaveTextInput;

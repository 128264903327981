import { Button, Checkbox, Col, Descriptions, Input, Row, Table } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { dateFormatter } from "../AppFunction";
import useFetchItem from "./useFetchItems";

function ItemSelectionModal({
  item,
  forFlag,
  returnValue,
  selectedItemBatchDetails,
  setterFunction,
  forPage,
  pageDetails,
}) {
  const [itemDetails, setItemDetails] = useState([]);
  const [freeQty, setFreeQty] = useState(0);
  const [count, setcount] = useState(0);
  const [qty, setQty] = useState(0);
  const [isPick, setIsPick] = useState(false);
  const useFetchItems = useFetchItem();
  const [showTable, setShowTable] = useState(false);
  const [effectiveQty, setEffectiveQty] = useState(0);
  const [showerrormesage, setshowerrormesage] = useState(false);
  const [box, setBox] = useState(0);

  const inputref1 = useRef(null);
  const inputref2 = useRef(null);

  //{Pradeep task id -337}

  useEffect(() => {
    if (isPick) {
      var totalQuantity = itemDetails?.batchdetails?.reduce(
        (total, item) =>
          total +
          Number(Number(item?.selectedQty) + Number(item?.selectedFreeQty)),
        0
      );
      setEffectiveQty(totalQuantity);
    } else {
      setEffectiveQty(Number(qty) + Number(freeQty));
    }
  }, [itemDetails, qty, freeQty, isPick, itemDetails, item]);

  useEffect(() => {
    setShowTable(false);

    setTimeout(() => {
      setShowTable(true);
    }, 200);
  }, []);

  useEffect(() => {
    covertDataForBatchDetails();
    Inizialisation();
  }, [item]);

  const handleCheckboxChange = (e) => {
    setIsPick(!isPick);
    resetterFunction();
  };

  function Inizialisation() {
    setQty(0);
    setFreeQty(0);
    setIsPick(false);
    setBox(0);
  }

  /*
  This function is to updated the selected Quantity is Not Above the suggested Quantity 
  above and is not greater than the quantity of the batch
  */
  const updateSelectionValue = (record, event, changedKey) => {
    // Get the Record
    //// this piece of code is added because the effective value was changing

    setcount((p) => p + 1);
    let changedRecord = record;
    // Check if Entered Quantity is greater than the available stock and the total of the
    // Quantity if not more than required quantity
    let totalSelectedFreeQtyOtherthanrecord = itemDetails?.batchdetails?.reduce(
      (total, item) => total + Number(item.selectedFreeQty),
      0
    );

    /////
    if (changedKey == "selectedQty") {
      if (changedRecord.stock >= changedRecord.selectedQty) {
        let totalSelectedQtyOtherthanrecord = itemDetails?.batchdetails?.reduce(
          (total, item) => {
            if (item.itembatchno != record.itembatchno) {
              return total + Number(item?.selectedQty ? item.selectedQty : 0);
            } else {
              return 0;
            }
          },
          0
        );

        if (
          qty <
          Number(totalSelectedQtyOtherthanrecord) + Number(record.selectedQty)
        ) {
          //  alert("Entered Quantity cannot exceed the Required Quantity" )
          event.target.text = "";
        }
      }
    }
    setEffectiveQty(
      itemDetails?.batchdetails?.reduce(
        (total, item) =>
          total + Number(item?.selectedQty) + Number(item?.selectedFreeQty),
        0
      )
    );
  };

  useEffect(() => {}, [itemDetails]);

  //   function Checkquantityvalue(e,record) {
  //     console.log("record stock",record.stock,"e",e.target.value)
  //    console.log("Checkquantityvalue call")
  //     if (Number(e.target.value)>(record.stock)) {

  //       console.log("hello")
  //       alert("Entered Quantity cannot exceed the Stock" +" "+record.stock);
  //       setshowerrormesage(true)
  //       // return e.target.value.slice(0, -1)
  //       record.selectedQty = e.target.value;
  //       updateSelectionValue(record, e, "selectedQty");
  //     }
  //     else{
  //       record.selectedQty = e.target.value;
  //       updateSelectionValue(record, e, "selectedQty")
  //     }
  //  }
  //     const handleKeyDown = (event) => {
  //     console.log("selectedQty1",selectedQty,"recordstock",recordstock)
  //   if ((event.keyCode === 9) && (selectedQty>recordstock)) {
  //     event.preventDefault();
  //   }
  //  };

  //console.log("TR013 => Item Details Changes", );
  console.log("data===>", item);
  const covertDataForBatchDetails = () => {
    if (item) {
      var batchColumns = [
        {
          dataIndex: "purrate",
          key: "purrate",
          title: "Purchase Rate.",
          render: (_, record) => {
            return item.ratecardappl ? (
              <p>{record?.purrate}</p>
            ) : (
              <p>{record?.batchpurrate}</p>
            );
          },
        },
        {
          dataIndex: "itembatchno",
          key: "itembatchno",
          title: "Batch No.",
        },
        {
          dataIndex: "expdate",
          key: "expdate",
          title: "Expiry",
          render: (_, record) => {
            return <p>{dateFormatter(record.expdate)}</p>;
          },
        },
        {
          hidden: !pageDetails?.wantpackingextra,
          dataIndex: "boxes",
          key: "boxes",
          title: "Boxes",
          render: (_, record) => {
            // Correct calculation of boxes
            const boxes = Math.floor(record.stock / item.conversionfactor);
            return <p>{boxes}</p>;
          },
        },
        {
          dataIndex: "pieces",
          key: "pieces",
          title: "Pieces",
          render: (_, record) => {
            // Correct calculation of pieces
            let pieces;
            if (pageDetails?.wantpackingextra) {
              pieces = record.stock % item.conversionfactor;
            } else {
              pieces = record.stock;
            }
            return <p>{pieces}</p>;
          },
        },
        {
          dataIndex: "selectedBoxQty",
          key: "selectedBoxQty",
          title: "Box Quantity",
          render: (_, record) => {
            return <p>{record?.selectedBoxQty}</p>;
          },
        },
        {
          dataIndex: "selectedQty",
          key: "selectedQty",
          width: "80px",
          title: "Quantity",
        },
        {
          hidden: !pageDetails?.wantfreeqty,
          dataIndex: "selectedFreeQty",
          key: "selectedFreeQty",
          width: "80px",
          title: "Free Quantity",
        },
      ];

      var batchColumnsEditable = [
        {
          dataIndex: "purrate",
          key: "purrate",
          title: "Purchase Rate.",
          render: (_, record) => {
            return item.ratecardappl ? (
              <p>{record?.purrate}</p>
            ) : (
              <p>{record?.batchpurrate}</p>
            );
          },
        },
        {
          dataIndex: "itembatchno",
          key: "itembatchno",
          title: "Batch No.",
        },
        {
          dataIndex: "expdate",
          key: "expdate",
          title: "Expiry",
          render: (_, record) => {
            return <p>{dateFormatter(record.expdate)}</p>;
          },
        },
        {
          hidden: !pageDetails?.wantpackingextra,
          dataIndex: "boxes",
          key: "boxes",
          title: "Boxes",
          render: (_, record) => {
            // Correct calculation of boxes
            const boxes = Math.floor(record.stock / item.conversionfactor);
            return <p>{boxes}</p>;
          },
        },
        {
          dataIndex: "pieces",
          key: "pieces",
          title: "Pieces",
          render: (_, record) => {
            // Correct calculation of pieces
            let pieces;
            if (pageDetails?.wantpackingextra) {
              pieces = record.stock % item.conversionfactor;
            } else {
              pieces = record.stock;
            }

            return <p>{pieces}</p>;
          },
        },
        {
          dataIndex: "selectedBoxQty",
          key: "selectedBoxQty",
          title: "Box Quantity",
          render: (_, record) => {
            return (
              <input
                placeholder="Enter Box Qty"
                defaultValue={record?.selectedBoxQty}
                style={{
                  color:
                    Number(record.selectedBoxQty) * item.conversionfactor >
                    record.stock -
                      (Number(record.selectedQty) +
                        Number(record.selectedFreeQty))
                      ? "red"
                      : "black",
                }}
                onChange={(e) => {
                  let convertedBoxQty =
                    Number(e.target.value) * item.conversionfactor;
                  if (
                    convertedBoxQty +
                      Number(record.selectedQty) +
                      Number(record.selectedFreeQty) >
                    record.stock
                  ) {
                    alert(
                      "Entered Box Quantity exceeds available stock: " +
                        record.stock
                    );
                    setshowerrormesage(true);
                    record.selectedBoxQty = e.target.value;
                  } else {
                    record.selectedBoxQty = e.target.value;
                    updateSelectionValue(record, e, "selectedBoxQty");
                  }
                }}
              />
            );
          },
        },
        {
          dataIndex: "qty",
          key: "qty",
          width: "80px",
          title: "Quantity",
          render: (_, record) => {
            return (
              <input
                placeholder="Enter Qty"
                defaultValue={record?.selectedQty}
                style={{
                  color:
                    Number(record.selectedQty) +
                      Number(record.selectedFreeQty) >
                    record.stock
                      ? "red"
                      : "black",
                }}
                onChange={(e) => {
                  if (
                    Number(e.target.value) + Number(record.selectedFreeQty) >
                    record.stock
                  ) {
                    alert(
                      "Entered Quantity cannot exceed the Stock: " +
                        record.stock
                    );
                    setshowerrormesage(true);
                  } else {
                    record.selectedQty = e.target.value;
                    updateSelectionValue(record, e, "selectedQty");
                  }
                }}
              />
            );
          },
        },
        {
          hidden: !pageDetails?.wantfreeqty,
          dataIndex: "freeqty",
          key: "freeqty",
          width: "80px",
          title: "Free Quantity",
          render: (_, record) => {
            return (
              <input
                placeholder="Enter Free Qty"
                defaultValue={record?.selectedFreeQty}
                style={{
                  color:
                    Number(record.selectedQty) +
                      Number(record.selectedFreeQty) >
                    record.stock
                      ? "red"
                      : "black",
                }}
                onChange={(e) => {
                  if (
                    Number(e.target.value) + Number(record.selectedQty) >
                    record.stock
                  ) {
                    alert(
                      "Entered Quantity cannot exceed the Stock: " +
                        record.stock
                    );
                    setshowerrormesage(true);
                  } else {
                    record.selectedFreeQty = e.target.value;
                    updateSelectionValue(record, e, "selectedFreeQty");
                  }
                }}
              />
            );
          },
        },
      ];

      item.batchColumnsEditable = batchColumnsEditable;
      item.batchColumns = batchColumns;
      setItemDetails(item);
    }
  };

  const resetterFunction = () => {
    if (!isPick) {
      // reset pick manullly reset remove
      // setFreeQty(0);
      // setQty(0);

      const tempArray = itemDetails?.batchdetails?.map((item) => {
        return {
          ...item,
          selectedQty: 0,
          selectedFreeQty: 0,
          selectedBoxQty: 0,
        };
      });

      setItemDetails((p) => {
        return { ...p, batchdetails: tempArray };
      });
    }
  };

  useEffect(() => {
    if (returnValue) {
      returnValue(itemDetails);
    }
  }, [itemDetails]);

  function selectStock(
    stockArray,
    quantity,
    freeQuantity,
    boxQty,
    conversionFactor
  ) {
    let remainingQty = quantity; // 20 normal quantity
    let remainingFreeQty = freeQuantity; // 5 free quantity
    let remainingBoxQty = boxQty * conversionFactor; // 100 * 24 = 2400, convert box quantity to normal pieces
    let remainingTotalQty = remainingQty + remainingFreeQty + remainingBoxQty; // Total quantity to allocate

    // Calculate the total available stock in all batches
    let totalStock = stockArray?.batchdetails?.reduce(
      (acc, el) => acc + el?.stock,
      0
    );

    // Reset previous selected quantities for each batch
    stockArray.batchdetails.forEach((batch) => {
      batch.selectedQty = 0;
      batch.selectedFreeQty = 0;
      batch.selectedBoxQty = 0;
    });

    // Check if total stock is sufficient
    if (totalStock < remainingTotalQty) {
      alert("Stock Not Available");
      return;
    }

    for (
      let i = 0;
      i < stockArray?.batchdetails.length && remainingTotalQty > 0;
      i++
    ) {
      let stock = stockArray?.batchdetails[i].stock;
      let remainingStock = Number(stock);

      let selectedQty = 0;
      let selectedFreeQty = 0;
      let selectedBoxQty = 0;

      // Handle full box allocation
      let stockInBoxes = Math.floor(remainingStock / conversionFactor); // Full boxes available in current batch
      let requestedBoxes = Math.floor(remainingBoxQty / conversionFactor); // Full boxes requested

      if (stockInBoxes > 0 && requestedBoxes > 0) {
        let boxesAllocated = Math.min(stockInBoxes, requestedBoxes); // Allocate as many full boxes as possible
        selectedBoxQty = boxesAllocated * conversionFactor; // Convert back to quantity in pieces

        remainingStock -= selectedBoxQty; // Reduce the batch's stock by the allocated box quantity
        remainingBoxQty -= selectedBoxQty; // Reduce the remaining box quantity
      }

      // Handle individual normal quantity allocation after boxes
      if (remainingStock > 0 && remainingQty > 0) {
        if (remainingStock >= remainingQty) {
          selectedQty = remainingQty;
          remainingStock -= remainingQty;
          remainingQty = 0;
        } else {
          selectedQty = remainingStock;
          remainingQty -= remainingStock;
          remainingStock = 0;
        }
      }

      // Handle free quantity allocation
      if (remainingStock > 0 && remainingFreeQty > 0) {
        if (remainingStock >= remainingFreeQty) {
          selectedFreeQty = remainingFreeQty;
          remainingStock -= remainingFreeQty;
          remainingFreeQty = 0;
        } else {
          selectedFreeQty = remainingStock;
          remainingFreeQty -= remainingStock;
          remainingStock = 0;
        }
      }

      // Update batch stock details
      stockArray.batchdetails[i].remainingStock = remainingStock;
      stockArray.batchdetails[i].selectedQty = selectedQty;
      stockArray.batchdetails[i].selectedFreeQty = selectedFreeQty;
      stockArray.batchdetails[i].selectedBoxQty = Math.ceil(
        selectedBoxQty / conversionFactor
      ); // Convert back to boxQty for consistency

      // Recalculate remaining total quantities
      remainingTotalQty = remainingQty + remainingFreeQty + remainingBoxQty;
    }

    // Update state and call callback function
    setItemDetails((prev) => {
      return { ...stockArray };
    });

    if (returnValue) {
      returnValue(stockArray);
    }

    setterFunction(stockArray);
  }

  /// trying to make effective qty///// by rbn 06 may 2023
  useEffect(() => {
    if (isPick) {
      const totalSelectedQty = itemDetails.batchdetails?.reduce(
        (total, item) => total + Number(item.selectedQty),
        0
      );
      const totalSelectedFreeQty = itemDetails.batchdetails?.reduce(
        (total, item) => total + Number(item.selectedFreeQty),
        0
      );
      setEffectiveQty(totalSelectedQty + totalSelectedFreeQty);
    }
  }, [isPick, itemDetails.batchdetails, qty, freeQty, count]);

  return (
    <Descriptions
      bordered
      title={"Item Details"}
      column={{
        xxl: 3,
        xl: 3,
        lg: 3,
        md: 3,
        sm: 2,
        xs: 1,
      }}
    >
      <Descriptions.Item label="Product" span={3}>
        {item?.itemdescn}
      </Descriptions.Item>
      <Descriptions.Item label="MRP" span={1}>
        {Number(item?.mrp).toFixed(2)}
      </Descriptions.Item>
      <Descriptions.Item label="Sale Rate" span={1}>
        {Number(item?.salerate).toFixed(2)}
      </Descriptions.Item>
      <Descriptions.Item label="Available Stock" span={1}>
        {itemDetails?.batchdetails?.reduce(
          (acc, el, i, arr) => (acc += el?.stock),
          0
        )}
      </Descriptions.Item>

      {/* Wrap the inputs in a Row/Col to keep them aligned */}
      <Descriptions.Item label="Selection Details" span={3}>
        <Row gutter={[16, 16]}>
          <Col span={6}>
            <label>Selected Box:</label>
            <Input
              type="number"
              placeholder="Enter Qty"
              onFocus={(e) => e.target.select()}
              value={box}
              disabled={isPick}
              onChange={(e) => setBox(e.target.value)}
            />
          </Col>
          <Col span={6}>
            <label>Selected Quantity:</label>
            <Input
              type="number"
              placeholder="Enter Qty"
              onFocus={(e) => e.target.select()}
              value={qty}
              disabled={isPick}
              onChange={(e) => setQty(e.target.value)}
            />
          </Col>
          {forPage !== "DC" && pageDetails?.wantfreeqty  && (
            <Col span={6}>
              <label>Selected Free Quantity:</label>
              <Input
                type="number"
                placeholder="Enter Free Qty"
                value={freeQty}
                disabled={isPick}
                onFocus={(e) => e.target.select()}
                onChange={(e) => setFreeQty(e.target.value)}
              />
            </Col>
          )}

          <Col span={6} style={{ display: "flex", flexDirection: "column" }}>
            <label>Effective Box Qty: {box} </label>
            <label>Effective Qty: {effectiveQty} </label>
            <Button
              disabled={isPick}
              onClick={() =>
                selectStock(
                  itemDetails,
                  Number(qty),
                  Number(freeQty),
                  Number(box),
                  Number(item?.conversionfactor)
                )
              }
            >
              Add
            </Button>
          </Col>
          {/* <Col span={6}>
        <Button
          disabled={isPick}
          onClick={() => selectStock(itemDetails, Number(qty), Number(freeQty))}
        >
          Add
        </Button>
      </Col> */}
        </Row>
      </Descriptions.Item>

      <Descriptions.Item label="Batch Details" span={5}>
        <Checkbox checked={isPick} onChange={handleCheckboxChange}>
          Pick Manually
        </Checkbox>
        {/* 
        {
          !isPick?null:<div style={{display:"flex", flexDirection:'row', justifyContent:"space-evenly"}}>
            <p>Selected Qty : {itemDetails?.batchdetails.reduce(
      (total, item) => {
        if (item.selectedQty) {
          
          return total + Number(item.selectedQty)
        }
      },
      0
    )}</p>
            <p>Selected Free Qty : {itemDetails?.batchdetails.reduce(
      (total, item) =>{
        if (item.selectedQty) {
          
          return total + Number(item.selectedFreeQty)
        }
      },
      0
    )}</p>
            <p>Remaining Qty : {Number(qty) - itemDetails?.batchdetails.reduce(
      (total, item) => {
        if (item.selectedQty) {
          
          return total + Number(item.selectedQty)
        }
      },
      0
    )}</p>
            <p>Remaining Free Qty: {Number(freeQty) - itemDetails?.batchdetails.reduce(
      (total, item) => {
        if (item.selectedQty) {
          
          return total + Number(item.selectedFreeQty)
        }
      },
      0
    )}</p>
          </div>
        } */}

        {/* {!isPick ? (
          <Table
            size="small"
            pagination={false}
            columns={itemDetails?.batchColumns}
            dataSource={uniqueBatches}
          />
        ) : (
          <Table
            size="small"
            pagination={false}
            columns={itemDetails?.batchColumnsEditable}
            dataSource={uniqueBatches}
          />
        )} */}
        <div style={{ height: 300, overflowX: "scroll" }}>
          {!isPick ? (
            <div style={{ height: "350px", overflow: "scroll" }}>
              {" "}
              <Table
                size="small"
                pagination={false}
                columns={itemDetails?.batchColumns}
                dataSource={itemDetails?.batchdetails}
              />
            </div>
          ) : (
            <div style={{ height: "350px", overflow: "scroll" }}>
              {" "}
              <Table
                size="small"
                pagination={false}
                columns={itemDetails?.batchColumnsEditable}
                dataSource={itemDetails?.batchdetails}
              />
            </div>
          )}
        </div>
      </Descriptions.Item>
    </Descriptions>
  );
}

export default ItemSelectionModal;

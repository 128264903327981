import { Button, Popconfirm } from "antd";
import { AiFillDelete } from "react-icons/ai";
import {
  dateFormatter,
  removeByAttribute,
} from "../../CommonControllers/AppFunction";
import CallbackDateInput from "../../CommonControllers/Components/CallbackComponents/CallbackDateInput";
import "../../CommonControllers/global.css";
import { v4 as uuidv4 } from "uuid";
export const prColumns = (igstappl, setRowData, rowData, scandata, setScandata, setOpenItem,pageDetails) => {

 
  if (igstappl) {
    return [
      {
        title: " ",
        dataIndex: "active",
        key: "active",
        width: 60,
        render: (_, text) => {
          return (
            <Popconfirm
              title="Are you sure?"
              onConfirm={(e) => {
                handleRemove(text, setRowData, rowData);
              }}
            >
              <Button icon={<AiFillDelete />}></Button>
            </Popconfirm>
          );
        },
      },
      {
        title: "Item Description", // nohit-425 change title Item to Item Description
        dataIndex: "itemdescn",
        key: "itemdescn",
        width: 350,
        align: "left", // nohit-388 set Align for column
        ellipsis: true, // Add ellipsis to truncate text
    },
      {
        title: "Batch No",
        dataIndex: "itembatchno",
        key: "itembatchno",
        width: 100,
        align: "left", // nohit-388 set Align for column
      },
      {
        title: "Mfg Date",
        dataIndex: "manufacturedate",
        key: "manufacturedate",
        width: 150,
        align: "left", // nohit-388 set Align for column
        render: (_, text) => {
          return <p>{dateFormatter(text?.manufacturedate)}</p>;
        },
      },
      {
        title: "Exp Date",
        dataIndex: "expdate",
        key: "expdate",
        width: 150,
        align: "left", // nohit-388 set Align for column
        render: (_, text) => {
          return (

            <p>{dateFormatter(text?.expdate)}</p>
          );
        },
      },

      {
        hidden: !pageDetails?.wantpackingextra ? true : false,
        title: "Boxes",
        dataIndex: "packingextraqty",
        key: "packingextraqty",
        width:80,
        align: "right",

      },
      {
       
        title: "Pieces",
        dataIndex: "packingqty",
        key: "packingqty",
        width: 80,

        align: "right",
      },

      {
        hidden: pageDetails?.wantpackingextra && pageDetails?.wantfreeqty ? false : true,
        title: "Free Boxes",
        dataIndex: "freepackingextraqty",
        key: "freepackingextraqty",
        align: "right",
        width: 80,
      },
      {
        hidden: !pageDetails?.wantfreeqty ? true : false,
        title: "Free Pieces",
        dataIndex: "freepackingqty",
        key: "freepackingqty",
        align: "right",
        width: 80,
      },
      {
        hidden: !pageDetails?.wantpackingextra ? true : false,
        title: "Returnable box Qty",
        dataIndex: "returnablepackingextraqty",
        key: "returnablepackingextraqty",
        align: "right",
        width: 130,
      },
      {
        hidden: !pageDetails?.wantpackingextra ? true : false,
        title: "Returnable Piece Qty",
        dataIndex: "returnablepackingqty",
        key: "returnablepackingqty",
        align: "right",
        width: 130,
      },
       {
       
        title: "Returnable Qty",
        dataIndex: "returnableqty",
        key: "returnableqty",
        align: "right",
        width: 130,
      },
      {
        hidden: !pageDetails?.wantfreeqty ? true : false,
        title: "Returnable freeQty",
        dataIndex: "returnablefree",
        key: "returnablefree",
        align: "right",
        width:130,

      },

      {
        title: "Amount",
        dataIndex: "amount",
        key: "amount",
        align: "right",
        width: 150,
      },
      {
        hidden: !pageDetails?.wantpackingextra ? true : false,
        title: "Returned Boxes",
        dataIndex: "currentreturnpackingextraqty",
        key: "currentreturnpackingextraqty",
        align: "right",
        width: 130,
        render: (_, text) => {
          const maxValue = Math.floor((Number(text.returnableqty) + Number(text.returnablefree)) / Number(text.conversionfactor));
          return (
            <input
              className={`tr001Input`}
              defaultValue={text?.currentreturnpackingextraqty}
              //onFocus={(e) => e.target.select()}
              type="number"
              min={0}
              max={maxValue}
              onChange={(e) => {
                const inputValue = e.target.value;
                if (inputValue < 0 || inputValue > maxValue) {
                  e.target.value = null;
                  return false;
                }
                text.currentreturnpackingextraqty = Number(inputValue);
                handleChange(text, setRowData, igstappl, rowData);
              }}
            />
            // <p>{text.purrate}</p>
          );
        },
      },
      {
      
        title: "Returned Pieces",
        dataIndex: "currentreturnpackingqty",
        key: "currentreturnpackingqty",
        align: "right",
        width: 130,
        render: (_, text) => {
          //(Number(text.returnableqty)+Number(text.returnablefree))
          const maxValue = (Number(text.returnableqty) + Number(text.returnablefree)) - (Number(text.returnpackingextraqty ? text.returnpackingextraqty : 0) * (Number(text.conversionfactor)))
          return (
            <input
              className={`tr001Input`}
              defaultValue={text?.currentreturnpackingqty}
              // onFocus={(e) => e.target.select()}
              type="number"
              min={0}
              max={maxValue}
              onChange={(e) => {
                const inputValue = e.target.value;
                if (inputValue > maxValue) {
                  alert(`Cannot return more than available quantity  ${maxValue}`)
                }
                else if (inputValue > text.stock) {
                  alert(`Cannot return more than available quantity  ${maxValue}`)
                }
                if (inputValue < 0 || inputValue > maxValue) {
                  e.target.value = null;
                  return false;
                }
                text.currentreturnpackingqty = Number(inputValue);
                handleChange(text, setRowData, igstappl, rowData);
              }}
            />
            // <p>{text.purrate}</p>
          );
        },
      },
      {
      
        title: "Qty",
        dataIndex: "currentreturnqty",
        key: "currentreturnqty",
        align: "right",
        width: 80,
      },
      {
        hidden: !pageDetails?.wantfreeqty ? true : false,
        title: "Free Qty",
        dataIndex: "returnfree",
        key: "returnfree",
        align: "right",
        width: 80,
      },
      {
        hidden: !pageDetails?.wantpackingextra ? true : false,
        title: "Box Rate",
        dataIndex: "boxsalerate",
        key: "boxsalerate",
        width: 120,
        align: "right",
      },
      {
        title: "Rate",
        dataIndex: "salerate",
        key: "salerate",
        align: "right",
        width: 130,
      },
      {
        title: "Discount%",
        dataIndex: "returndiscountrate",
        key: "returndiscountrate",
        align: "right",
        width: 90,
        render: (_, text) => {
          //const maxValue =Number(text.packingextraqty) *Number(text.conversionfactor) +Number(text.packingqty);
          return (
            <input
              className={`tr001Input`}
              //defaultValue={text?.packingqty}
              onFocus={(e) => e.target.select()}
              type="number"
              min={0}
              // max={maxValue}
              disabled={text.returndiscountamt}
              onChange={(e) => {
                const inputValue = e.target.value;
                // if (inputValue < 0 || inputValue > maxValue) {
                //   e.target.value = null;
                //   return false;
                // }
                text.returndiscountrate = Number(inputValue);
                handleChange(text, setRowData, igstappl, rowData);
              }}
            />
            // <p>{text.purrate}</p>
          );
        },
      },
      {
        title: "Discount",
        dataIndex: "returndiscountamt",
        key: "returndiscountamt",
        align: "right",
        width: 130,
        render: (_, text) => {
          //const maxValue =Number(text.packingextraqty) *Number(text.conversionfactor) +Number(text.packingqty);
          return (
            <input
              className={`tr001Input`}
              //defaultValue={text?.packingqty}
              onFocus={(e) => e.target.select()}
              type="number"
              min={0}
              disabled={text.returndiscountrate}
              // max={maxValue}
              onChange={(e) => {
                const inputValue = e.target.value;
                // if (inputValue < 0 || inputValue > maxValue) {
                //   e.target.value = null;
                //   return false;
                // }
                text.returndiscountamt = Number(inputValue);
                handleChange(text, setRowData, igstappl, rowData);
              }}
            />
            // <p>{text.purrate}</p>
          );
        },
      },
      {
        title: "Reason",
        dataIndex: "narration",
        key: "narration",
        align: "left", // nohit-388 set Align for column
        width:150,
        render: (_, text) => {
          return (
            <input
              className={`tr001Input`}
              //defaultValue={text?.packingqty}
             // onFocus={(e) => e.target.select()}
              type="text"
              min={0}
              //disabled={text.returndiscountrate}
              // max={maxValue}
              onChange={(e) => {
                // const inputValue = e.target.value;
                // if (inputValue < 0 || inputValue > maxValue) {
                //   e.target.value = null;
                //   return false;
                // }
                text.narration = e.target.value;
                handleChange(text, setRowData, igstappl, rowData);
              }}
            />
          )
        }
      },
      {
        title: "Taxable Amount",
        dataIndex: "currentreturntaxableamount",
        key: "currentreturntaxableamount",
        align: "right",
        width: 150,
      },

      {
        title: "IGST%",
        dataIndex: "igstrate",
        key: "igstrate",
        align: "right",
        width:100,
      },
      {
        title: "IGST",
        dataIndex: "currentreturnigstamt",
        key: "currentreturnigstamt",
        align: "right",
        width: 150,
      },

      {
        title: "Returned Amount",
        dataIndex: "currentreturnamount",
        key: "currentreturnamount",
        align: "right",
        width: 150,
      },

      {
        width: 150,
        title: "Scanned Items",
        dataIndex: "scannedItems",
        align: "left", // nohit-388 set Align for column 
        render: (_, text) => {

          return <p>{text?.itembatcharray?.length}</p>
        }
      },
      {
        title: "Scan Barcode",
        dataIndex: "barcode",
        align: "left", // nohit-388 set Align for column
        key: "barcode",
        width: 150,
        render: (_, text) => {
          console.log("Text is748788", text);
          if (text.itemasbatch && text.doctype != "DC") {
            return (
              <Button
                onClick={() => {
                  setOpenItem(true);
                  handleChange(text, setRowData, igstappl, rowData, scandata, setScandata, setOpenItem);
                  if (text?.currentreturnpackingextraqty ? text?.currentreturnpackingextraqty : 0 >= 0 && text?.currentreturnpackingqty > 0) {
                    setOpenItem(true)
                  }
                  setScandata(text);
                }}
              // disabled={text?.packingqtyaccepted !== 0 || text?.packingextraqtyaccepted !== 0}
              >
                Item Scan
              </Button>
            );
          } else return null;
        },
      },
    ];
  }

  else {
    return [
      {
        title: " ",
        dataIndex: "active",
        key: "active",
        width: 80,
        render: (_, text) => {
          return (
            <Popconfirm
              title="Are you sure?"
              onConfirm={(e) => {
                handleRemove(text, setRowData, rowData);
              }}
            >
              <Button icon={<AiFillDelete />}></Button>
            </Popconfirm>
          );
        },
      },
      {
        title: "Item Description", // nohit-425 change title Item to Item Description
        dataIndex: "itemdescn",
        key: "itemdescn",
        width: 350,
        align: "left", // nohit-388 set Align for column
        ellipsis: true, // Add ellipsis to truncate text
    },
      {
        title: "Batch No",
        dataIndex: "itembatchno",
        key: "itembatchno",
        width: 100,
        align: "left", // nohit-388 set Align for column
      },
      {
        title: "Mfg Date",
        dataIndex: "manufacturedate",
        key: "manufacturedate",
        width: 150,
        align: "left", // nohit-388 set Align for column
        render: (_, text) => {
          return <p>{dateFormatter(text?.manufacturedate)}</p>;
        },
      },
      {
        title: "Exp Date",
        dataIndex: "expdate",
        key: "expdate",
        width: 150,
        align: "left", // nohit-388 set Align for column
        render: (_, text) => {
          return (

            <p>{dateFormatter(text?.expdate)}</p>
          );
        },
      },

      {
        hidden: !pageDetails?.wantpackingextra ? true : false,
        title: "Boxes",
        dataIndex: "packingextraqty",
        key: "packingextraqty",
        width: 80,
        align: "right",

      },
      {
       
        title: "Pieces",
        dataIndex: "packingqty",
        key: "packingqty",
        width:80,

        align: "right",
      },
      {
        hidden: pageDetails?.wantpackingextra && pageDetails?.wantfreeqty ? false : true,
        title: "Free Boxes",
        dataIndex: "freepackingextraqty",
        key: "freepackingextraqty",
        align: "right",
        width: 80,
      },
      {
        hidden: !pageDetails?.wantfreeqty ? true : false,
        title: "Free Pieces",
        dataIndex: "freepackingqty",
        key: "freepackingqty",
        align: "right",
        width: 80,
      },
      {
        hidden: !pageDetails?.wantpackingextra ? true : false,
        title: "Returnable box Qty",
        dataIndex: "returnablepackingextraqty",
        key: "returnablepackingextraqty",
        align: "right",
        width: 130,
      },
      
      {
        hidden: !pageDetails?.wantpackingextra ? true : false,
        title: "Returnable Piece Qty",
        dataIndex: "returnablepackingqty",
        key: "returnablepackingqty",
        align: "right",
        width: 130,
      },

      {
      
        title: "Returnable Qty",
        dataIndex: "returnableqty",
        key: "returnableqty",
        align: "right",
        width: 130,
      },
      {
        hidden: !pageDetails?.wantfreeqty ? true : false,
        title: "Returnable freeQty",
        dataIndex: "returnablefree",
        key: "returnablefree",
        align: "right",
        width: 130,

      },
      {
        title: "Amount",
        dataIndex: "amount",
        key: "amount",
        align: "right",
        width: 150,
      },
      {
        hidden: !pageDetails?.wantpackingextra ? true : false,
        title: "Returned Boxes",
        dataIndex: "currentreturnpackingextraqty",
        key: "currentreturnpackingextraqty",
        align: "right",
        width:150,
        render: (_, text) => {
          const maxValue = Math.floor((Number(text.returnableqty) + Number(text.returnablefree)) / Number(text.conversionfactor));
          return (
            <input
              className={`tr001Input`}
              defaultValue={text?.currentreturnpackingextraqty}
              //onFocus={(e) => e.target.select()}
              type="number"
              min={0}
              max={maxValue}
              onChange={(e) => {
                const inputValue = e.target.value;
                if (inputValue < 0 || inputValue > maxValue) {
                  e.target.value = null;
                  return false;
                }
                text.currentreturnpackingextraqty = Number(inputValue);
                handleChange(text, setRowData, igstappl, rowData);
              }}
            />
            // <p>{text.purrate}</p>
          );
        },
      },
      {
        title: "Returned Pieces",
        dataIndex: "currentreturnpackingqty",
        key: "currentreturnpackingqty",
        align: "right",
        width:150,
        render: (_, text) => {
          //(Number(text.returnableqty)+Number(text.returnablefree))
          const maxValue = (Number(text.returnableqty) + Number(text.returnablefree)) - (Number(text.returnpackingextraqty ? text.returnpackingextraqty : 0) * (Number(text.conversionfactor)))
          return (
            <input
              className={`tr001Input`}
              defaultValue={text?.currentreturnpackingqty}
              onFocus={(e) => e.target.select()}
              type="number"
              min={0}
              max={maxValue}
              onChange={(e) => {
                const inputValue = e.target.value;
                if (inputValue > maxValue) {
                  alert(`Cannot return more than available quantity  ${maxValue}`)
                }
                else if (inputValue > text.stock) {
                  alert(`Cannot return more than available quantity  ${maxValue}`)
                }
                if (inputValue < 0 || inputValue > maxValue) {
                  e.target.value = null;
                  return false;
                }
                text.currentreturnpackingqty = Number(inputValue);
                handleChange(text, setRowData, igstappl, rowData);
              }}
            />
            // <p>{text.purrate}</p>
          );
        },
      },
      {
        title: "Qty",
        dataIndex: "currentreturnqty",
        key: "currentreturnqty",
        align: "right",
        width: 100,
      },
      {
        hidden: !pageDetails?.wantfreeqty ? true : false,
        title: "Free Qty",
        dataIndex: "returnfree",
        key: "returnfree",
        align: "right",
        width: 100,
      },
      {
        hidden: !pageDetails?.wantpackingextra ? true : false,
        title: "Box Rate",
        dataIndex: "boxsalerate",
        key: "boxsalerate",
        width: 120,
        align: "right",
      },
      {
        title: "Rate",
        dataIndex: "salerate",
        key: "salerate",
        align: "right",
        width: 150,
      },
      {
        title: "Discount%",
        dataIndex: "returndiscountrate",
        key: "returndiscountrate",
        align: "right",
        width:100,
        render: (_, text) => {
          //const maxValue =Number(text.packingextraqty) *Number(text.conversionfactor) +Number(text.packingqty);
          return (
            <input
              className={`tr001Input`}
              //defaultValue={text?.packingqty}
              onFocus={(e) => e.target.select()}
              type="number"
              min={0}
              // max={maxValue}
              disabled={text.returndiscountamt}
              onChange={(e) => {
                const inputValue = e.target.value;
                // if (inputValue < 0 || inputValue > maxValue) {
                //   e.target.value = null;
                //   return false;
                // }
                text.returndiscountrate = Number(inputValue);
                handleChange(text, setRowData, igstappl, rowData);
              }}
            />
            // <p>{text.purrate}</p>
          );
        },
      },
      
      {
        title: "Discount",
        dataIndex: "returndiscountamt",
        key: "returndiscountamt",
        align: "right",
        width:150,
        render: (_, text) => {
          //const maxValue =Number(text.packingextraqty) *Number(text.conversionfactor) +Number(text.packingqty);
          return (
            <input
              className={`tr001Input`}
              //defaultValue={text?.packingqty}
              onFocus={(e) => e.target.select()}
              type="number"
              min={0}
              disabled={text.returndiscountrate}
              // max={maxValue}
              onChange={(e) => {
                const inputValue = e.target.value;
                // if (inputValue < 0 || inputValue > maxValue) {
                //   e.target.value = null;
                //   return false;
                // }
                text.returndiscountamt = Number(inputValue);
                handleChange(text, setRowData, igstappl, rowData);
              }}
            />
            // <p>{text.purrate}</p>
          );
        },
      },
      {
        title: "Reason",
        dataIndex: "narration",
        key: "narration",
        align: "left", // nohit-388 set Align for column
        width:150,
        render: (_, text) => {
          return (
            <input
              className={`tr001Input`}
              //defaultValue={text?.packingqty}
              onFocus={(e) => e.target.select()}
              type="text"
              min={0}
              //disabled={text.returndiscountrate}
              // max={maxValue}
              onChange={(e) => {
                // const inputValue = e.target.value;
                // if (inputValue < 0 || inputValue > maxValue) {
                //   e.target.value = null;
                //   return false;
                // }
                text.narration = e.target.value;
                handleChange(text, setRowData, igstappl, rowData);
              }}
            />
          )
        }
      },
      {
        title: "Taxable Amount",
        dataIndex: "currentreturntaxableamount",
        key: "currentreturntaxableamount",
        align: "right",
        width: 150,
      },
      {
        title: "CGST%",
        dataIndex: "cgstrate",
        key: "cgstrate",
        align: "right",
        width:100,
      },
      {
        title: "CGST",
        dataIndex: "currentreturncgstamt",
        key: "currentreturncgstamt",
        align: "right",
        width:150,
      },
      {
        title: "SGST%",
        dataIndex: "sgstrate",
        key: "sgstrate",
        align: "right",
        width:100,
      },
      {
        title: "SGST",
        dataIndex: "currentreturnsgstamt",
        key: "currentreturnsgstamt",
        align: "right",
        width:150,
      },
      // {
      //   title: "IGST%",
      //   dataIndex: "retunigstrate",
      //   key: "returnigstamt",
      //   align: "right",
      //   width:150,
      // },
      // {
      //   title: "IGST",
      //   dataIndex: "returnigstamt",
      //   key: "returnigstamt",
      //   align: "right",
      //   width:150,
      // },

      {
        title: "Returned Amount",
        dataIndex: "currentreturnamount",
        key: "currentreturnamount",
        align: "right",
        width:150,
      },
      {
        width: 150,
        title: "Scanned Items",
        dataIndex: "scannedItems",
        align: "left", // nohit-388 set Align for column
        render: (_, text) => {

          return <p>{text?.itembatcharray?.length}</p>
        }
      },
      {
        title: "Scan Barcode",
        dataIndex: "barcode",
        align: "left", // nohit-388 set Align for column
        key: "barcode",
        width: 150,
        render: (_, text) => {
          console.log("Text is748788", text);
          if (text.itemasbatch && text.doctype != "DC") {
            return (
              <Button
                onClick={() => {
                  setOpenItem(true);
                  handleChange(text, setRowData, igstappl, rowData, scandata, setScandata, setOpenItem);
                  if (text?.currentreturnpackingextraqty ? text?.currentreturnpackingextraqty : 0 >= 0 && text?.currentreturnpackingqty > 0) {
                    setOpenItem(true)
                  }
                  setScandata(text);
                }}
              // disabled={text?.packingqtyaccepted !== 0 || text?.packingextraqtyaccepted !== 0}
              >
                Item Scan
              </Button>
            );
          } else return null;
        },
      },
    ];
  }
};
// };

export const handleAdd = (
  item,
  setRowData,
  igstappl,
  setSelectedItem,
  shortguid,
  saleRate,
  saleQty,
  box,
  batch,
  expDate
) => {


  //~# Input boxes validation
  const batchValue = document.querySelector("tr004-input-batch");
  const boxValue = document.querySelector("tr004-input-box");
  const piecesValue = document.querySelector("tr004-input-pieces");
  const rateValue = document.querySelector("tr004-input-rate");

  var newShortguid = shortguid.getShortguid("POF" + item.itemrecno);

  var salerate = saleRate ? saleRate : 0.0;
  var cgstrate = item?.cgstrate ? item.cgstrate : 0.0;
  var sgstrate = item?.sgstrate ? item.sgstrate : 0.0;
  var igstrate = item?.igstrate ? item.igstrate : 0.0;
  var boxsalerate = Number(salerate) * Number(item?.conversionfactor);
  var packingqty = saleQty ? saleQty : 0; //~# here qty of pieces changed to key-name packingqty, box will be freepackingqty
  var packingextraqty = box ? box : 0; //~# box value
  var totalQty =
    Number(packingextraqty) * Number(item?.conversionfactor) +
    Number(packingqty);
  var batch = batch ? batch : 0;
  var expDate = expDate ? expDate : 0;
  var taxableamount =
    (Number(packingextraqty) * Number(item?.conversionfactor) +
      Number(packingqty)) *
    Number(salerate);
  var sgstamt = !igstappl ? taxableamount * (sgstrate / 100) : 0.0;
  var cgstamt = !igstappl ? taxableamount * (cgstrate / 100) : 0.0;
  var igstamt = igstappl ? taxableamount * (igstrate / 100) : 0.0;

  var amount = Number(igstappl)
    ? Number(taxableamount) + Number(igstamt)
    : Number(taxableamount) + Number(cgstamt) + Number(sgstamt);

  var itemObject = {
    ...item,
    saleshortguid: uuidv4(),//// date 24/04/023 the shortguid was neweded unique
    shortguid: newShortguid,
    itemrecno: item.itemrecno,
    itemdescn: item.itemdescn,

    packingextraqty:  item?.packingextraqty || 0,// +box, //~# here box is the value coming from input Boxes
    packingqty: item?.packingqty || 0, // +packingqty, //~# here packingqty is the pieces value coming from Pieces input
    batch: batch,
    qty: Number(totalQty) ? Number(totalQty) : 0,
    purrate: Number(item.purrate)?.toFixed(2),
    taxableamount: Number(taxableamount)?.toFixed(2),
    returncgstrate: cgstrate ? cgstrate : 0,
    returnsgstrate: sgstrate ? sgstrate : 0,
    returnigstrate: igstrate ? igstrate : 0,
    expDate: expDate,
    returncgstamt: cgstamt ? Number(cgstamt)?.toFixed(2) : 0,
    retunsgstamt: sgstamt ? Number(sgstamt)?.toFixed(2) : 0,
    returnigstamt: igstamt ? Number(igstamt)?.toFixed(2) : 0,
    returnamount: amount ? Number(amount)?.toFixed(2) : 0,
    salerate: item?.rate,
    /// 
    //date 24/04/23 rbn
    returntostock: true,
    boxsalerate: Number(boxsalerate).toFixed(2),
  };

  if (itemObject?.packingextraqty === 0 && itemObject?.qty === 0) {
    // alert("Enter boxes / pieces / Rate");
    const boxRef = document.querySelector("#tr001-input-box");
    boxRef.focus();
    return;
  }
 

  setRowData((p) => [...p, itemObject]);
  setSelectedItem(null);
};

/// Fuctipon for  handle changes

export const handleChange = (item, setRowData, igstappl, rowData) => {
  console.log("handleChange", item);

  // Define default values for potential undefined values
  const cgstrate = Number(item?.cgstrate) || 0;
  const sgstrate = Number(item?.sgstrate) || 0;
  const igstrate = Number(item?.igstrate) || 0;
  const salerate = Number(item?.salerate) || 0;
  const conversionFactor = Number(item?.conversionfactor) || 1;
  const returnpackingextraqty = Number(item?.currentreturnpackingextraqty) || 0;
  const returnpackingqty = Number(item?.currentreturnpackingqty) || 0;
  const returnableqty = Number(item?.returnableqty) || 0;
  const returndiscountrate = Number(item?.returndiscountrate) || 0;
  const returndiscountamt = Number(item?.returndiscountamt) || 0;
  const boxsalerate = item?.boxsalerate || 0.0;
  // Calculate total quantities and taxable amounts
  const totalQty = returnpackingextraqty * conversionFactor + returnpackingqty;
  const taxableamount = totalQty * salerate;

  // Calculate tax amounts based on IGST applicability
  const sgstamt = !igstappl ? (taxableamount * sgstrate) / 100 : 0.0;
  const cgstamt = !igstappl ? (taxableamount * cgstrate) / 100 : 0.0;
  const igstamt = igstappl ? (taxableamount * igstrate) / 100 : 0.0;

  // Total amount calculation
  const amount = igstappl
    ? taxableamount + igstamt
    : taxableamount + sgstamt + cgstamt;

  console.log("totalQty", totalQty, taxableamount, igstappl, sgstamt, cgstamt, igstamt);

  // Returnable quantity and free quantity calculation
  let totalreturnqty = returnpackingextraqty * conversionFactor + returnpackingqty;
  let returnfreeqty = 0;

  if (totalreturnqty >= returnableqty) {
    totalreturnqty = returnableqty;
    returnfreeqty = returnpackingextraqty * conversionFactor + returnpackingqty - returnableqty;
  } else if (totalreturnqty < returnableqty) {
    returnfreeqty = 0;
  } else {
    alert("Wrong input");
    return; // Exit if invalid input
  }

  // Calculate return taxable amount
  const percentrate = (returndiscountrate / 100) * salerate;
  const effectiverate = salerate - percentrate;
  let returntaxableamount = totalreturnqty * salerate;

  if (returndiscountamt && !returndiscountrate) {
    returntaxableamount -= returndiscountamt;
  } else if (!returndiscountamt && returndiscountrate) {
    returntaxableamount = totalreturnqty * effectiverate;
  }

  // Calculate return tax amounts
  const returnsgstamt = !igstappl ? (returntaxableamount * sgstrate) / 100 : 0.0;
  const returncgstamt = !igstappl ? (returntaxableamount * cgstrate) / 100 : 0.0;
  const returnigstamt = igstappl ? (returntaxableamount * igstrate) / 100 : 0.0;

  // Total return amount calculation
  const returnamount = igstappl
    ? returntaxableamount + returnigstamt
    : returntaxableamount + returnsgstamt + returncgstamt;
    let selectedQty=(Number(item?.currentreturnpackingextraqty)*conversionFactor)+Number(item.currentreturnpackingqty)

    const updatedRefDocuments =
      item?.refDocuments?.map((doc) => {
        // if(item.refDocuments=="undefined"){
        var count = item?.itembatcharray?.filter(
          (batch) => batch.docno === doc.refdocrecno
        ).length;
        // }
  
        return {
          ...doc,
          selectedQty: selectedQty,
          selectedpackingQty: Number(item.currentreturnpackingqty),
          selectedpackingextraQty: Number(item.currentreturnpackingextraqty),
        };
      }) || [];
  // Create the item object with updated values
  const itemObject = {
    ...item,
    returnpackingextraqty,
    returnpackingqty,
    returnqty: totalreturnqty,
    qty: Number(item.qty) || 0,
    salerate,
    returnfreeqty,
    currentreturnpackingextraqty:returnpackingextraqty,
    currentreturntaxableamount: returntaxableamount.toFixed(2),
    currentreturnsgstamt: returnsgstamt.toFixed(2),
    currentreturncgstamt: returncgstamt.toFixed(2),
    currentreturnigstamt: returnigstamt.toFixed(2),
    currentreturnamount: returnamount.toFixed(2),
    itembatchno: item?.wantbatchno ? item.itembatchno : ".",
    currentreturnqty: totalreturnqty,
    currentreturnfree: returnfreeqty,
    returnfree: returnfreeqty,
    returntostock: true,
    boxsalerate: Number(boxsalerate).toFixed(2),
    refDocuments:updatedRefDocuments
  };

  // Update row data with the new item object
  const updatedRowData = rowData.map((itm) =>
    itm.recno === itemObject.recno
      ? { ...itemObject }
      : { ...itm, shortguid: uuidv4() }
  );

  setRowData(updatedRowData);
};


export const handleRemove = (item, setRowData, rowData) => {
  console.log("Delete", item, setRowData, rowData);
  var removedArray = removeByAttribute(rowData, "shortguid", item.shortguid);
  console.log("Remove", item, setRowData, rowData, removedArray);
  setRowData(() => [...removedArray]);
};

//~^ GST AMOUNT getting added but not showing percentage values in table : Solved - 21/01/2023
//~! USING shortguid as classname for maipulating the DOM

import React from "react";
import ItemSearch from "../../CommonControllers/ItemController/ItemSearch";

function CreateCounterBill() {
  return (
    <div>
      <div style={{ maxHeight: "100px", height: "100px" }}>
        <ItemSearch forFlag={"sale"} selection />
      </div>
    </div>
  );
}

export default CreateCounterBill;

import React, { useState, useEffect, useContext } from "react";
import { Button, Popconfirm, Spin } from "antd";
import { AiFillDelete } from "react-icons/ai";
import { updateValue } from "../../../TabsStructure/tabFunctions";
import { TabStore } from "../../../TabsStructure/TabsStore";
import SaveDropDown from "../../../CommonControllers/SaveComponents/SaveDropDown";
import { getAccountlistURL } from "../../../CommonControllers/Urls";
import { v4 as uuidv4 } from "uuid";

const RcTable = ({
  accountingVoucher,
  setAccountingVoucher,
  renderer,
  setRenderer,
  setDdRenderer,
  validateCreditDebit,
}) => {
  const tabstore = useContext(TabStore);
  const [showTable, setShowTable] = useState(true);
  const [accNameData, setAccNameData] = useState({});

  //~# This function handle the deletion of the table record!
  const udaDoSaaleKo = (data) => {
    console.log("TR121 ln 16 ===> Udo do saale ko", data, "from", accountingVoucher);
    const filteredData = accountingVoucher?.filter((item) => item?.recno !== data?.recno);
    console.log("TR121 18 ===> Uda diya saale ko 8)", filteredData);

    //~* Dispatching the filtered data to tabstore
    tabstore.dispatch({
      type: "UPDATE_CHANGED_DATA",
      fieldType: "accounting_vouchers_2",
      text: filteredData,
    });

    //# Set the filtered data to accounting-voucher state data!
    setAccountingVoucher(filteredData);
    setRenderer(() => uuidv4());
  };

  useEffect(() => {
    setShowTable(false);

    setTimeout(() => {
      setShowTable(true);
    }, 100);
  }, [accountingVoucher?.length, renderer]);

  console.log("TR121 RC table rendered ??", renderer);

  return (
    <>
      {showTable ? (
        <div>
          <table border="1" width={"70%"}>
            <tbody>
              <tr>
                <td width="5%" align="center">
                  <p>Delete</p>
                </td>
                <td width="5%">Sr.No</td>
                <td width="10%">Account code</td>
                <td width="70%">Account Name</td>
                <td width="5%" align="right">
                  Debit
                </td>
                <td width="5%" align="right">
                  Credit
                </td>
              </tr>
              {
                <>
                  {accountingVoucher?.map((data, index) => (
                    <tr>
                      <td width="5%">
                        <Popconfirm
                          title={`Are you sure you want to delete? ${data?.descn}`}
                          onConfirm={() => udaDoSaaleKo(data)}
                        >
                          <Button size="medium">
                            <AiFillDelete />
                          </Button>
                        </Popconfirm>
                      </td>
                      <td width="5%">{index + 1}</td>
                      <td width="10%">
                        <p>{data.code}</p>
                      </td>
                      <td width="70%">
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "34px",
                            position: "relative",
                            zIndex: `${9 - index + 4}`,
                          }}
                        >
                          {(data?.descn ?? data?.gldescn) && (
                            <SaveDropDown
                              width="300px"
                              apiUrl={getAccountlistURL}
                              selectionLabel="descn"
                              selectionValue="code"
                              minInput={0}
                              async
                              placeholder="Select new account name"
                              apikey={`Account${index + 1}`}
                              setDataToParent={(e) => {
                                setAccNameData(e);

                                console.log("TR121 dropdown value ====>", e, "TR121 data", data);
                                //~* Setting values from dropdown to data object
                                data.descn = e?.descn;
                                data.code = e?.code;

                                setDdRenderer((prev) => prev + 1);
                              }}
                            />
                          )}
                          <div>
                            <h4>{data.descn ?? data?.gldescn}</h4>
                          </div>
                        </div>
                      </td>
                      <td width="5%" align="right">
                        {/* cdflag 1 is for credit, cdflag 2 for debit! */}
                        {data?.cdflag === 2 && (
                          <input
                            style={inputTableStyles}
                            defaultValue={data?.dramt}
                            onChange={(e) => {
                              const regex = /^\d*(\.\d{0,2})?$/;
                              const value = e.target.value;
                              if (value === "" || regex.test(value)) {
                                data.dramt = Number(e.target.value).toFixed(2);
                                updateValue(
                                  tabstore,
                                  "accounting_vouchers_2",
                                  accountingVoucher,
                                  "UPDATE_CHANGED_DATA"
                                );
                                console.log("TR121 DEBIT ===>", e.target.value, data, accountingVoucher);
                                validateCreditDebit();
                              } else {
                                data.dramt = 0;
                                updateValue(tabstore, "accounting_voucher_2", accountingVoucher, "UPDATE_CHANGED_DATA");
                                validateCreditDebit();
                              }
                            }}
                            onBlur={() => {
                              setRenderer(() => uuidv4());
                            }}
                          />
                        )}
                      </td>
                      <td width="5%" align="right">
                        {data?.cdflag === 1 && (
                          <input
                            style={inputTableStyles}
                            defaultValue={data?.cramt}
                            onChange={(e) => {
                              const regex = /^\d*(\.\d{0,2})?$/;
                              const value = e.target.value;
                              if (value === "" || regex.test(value)) {
                                data.cramt = Number(e.target.value).toFixed(2);
                                updateValue(
                                  tabstore,
                                  "accounting_vouchers_2",
                                  accountingVoucher,
                                  "UPDATE_CHANGED_DATA"
                                );
                                console.log("TR121 CREDIT ===>", e.target.value, accountingVoucher);
                                validateCreditDebit();
                              } else {
                                data.cramt = 0;
                                updateValue(
                                  tabstore,
                                  "accounting_vouchers_2",
                                  accountingVoucher,
                                  "UPDATE_CHANGED_DATA"
                                );
                                validateCreditDebit();
                              }
                            }}
                            onBlur={() => {
                              setRenderer(() => uuidv4());
                            }}
                          />
                        )}
                      </td>
                    </tr>
                  ))}
                </>
              }
            </tbody>
          </table>
        </div>
      ) : (
        <div style={{ ...loaderStyle }}>
          <Spin size="large" />
        </div>
      )}
    </>
  );
};

export default RcTable;

const inputTableStyles = {
  padding: "4px",
  border: "1px solid gray",
  textAlign: "right",
  borderRadius: "6px",
  fontSize: "15px",
};

const loaderStyle = {
  height: "max-content",
  width: "max-content",
  display: "flex",
  alignContent: "center",
  margin: "50px auto",
};

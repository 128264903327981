import React, { useState } from 'react';
import Back from "./Images/NavigationButtons/Back.jpeg";
import Copy from "./Images/NavigationButtons/Copy.jpeg";
import Delete from "./Images/NavigationButtons/Delete.jpeg";
import Download from "./Images/NavigationButtons/Download.jpeg";
import Edit from "./Images/NavigationButtons/Edit.jpeg";
import Execute from "./Images/NavigationButtons/Execute.jpeg";
import FastPath from "./Images/NavigationButtons/FastPath.jpeg";
import MainWindow from "./Images/NavigationButtons/MainWindow.jpeg";
import MenuButton from "./Images/NavigationButtons/MenuButton.jpeg";
import Refresh from "./Images/NavigationButtons/Refresh.jpeg";
import Save from "./Images/NavigationButtons/Save.jpeg";
import SearchButton from "./Images/NavigationButtons/SearchButton.jpeg";
import images from './Images/Images.css';
import "./../Help.css"
import { AiFillPlayCircle, AiOutlineClose } from 'react-icons/ai';


const Navigation = () => {
    const [isPlaying, setIsPlaying] = useState(false);
    const [videoId, setVideoId] = useState("");
    const handlePlayClick = () => {
        setIsPlaying(!isPlaying);
        setVideoId(videoId);
      };    
      const handleCloseClick = () => {
        setIsPlaying(false);
      };

  return (
    <div>
          <div style={{position:'fixed',backgroundColor:'white',width:'80%'}}><h1>Navigation</h1></div>
          <br/> 
          <br/> 
        <div style={{ marginLeft:'20%',width: "10%" }}>
                <div onClick={() => handlePlayClick("VIDEO_ID")} title="Play Menu and Taskbar tutorial">
                <AiFillPlayCircle size={54} />
                </div>
                {isPlaying && (
                <div className="popup">
                    <div className="popup-content">
                    <div className="close-button" onClick={handleCloseClick}>
                         <AiOutlineClose size={24} />
                    </div>
                    <iframe
                        title="YouTube Video"
                        width="100%"
                        height="100%"
                        src={`https://www.youtube.com/embed/Gv8F4R2M6rI/${videoId}?autoplay=1`}
                        frameBorder="0"
                        allow="autoplay; encrypted-media"
                        allowFullScreen
                    />
                    </div>
                </div>
                )}
            </div>
        <div >
            <h2>Main Window</h2>
            <img src={MainWindow} className="images"/>
        </div>
        <div style={{...flexDiv}}>
           <div style={{...imgDiv}}> <img src={MenuButton}  /></div>
           <span style={{...spanDiv}}>Menu Button :</span> 
            <div style={{...pDiv}}> <p >After clicking on button, a menu will typically appear, displaying a list of Fast Path that you can choose from.</p>
        </div>
        </div>
        <div style={{...flexDiv}}>
            <div ><img style={{width:"50px",height:"35px"}} src={FastPath} /></div>
            <span style={{...spanDiv}}>Fast Path :</span>
            <div style={{...pDiv}}><p>If user want to go directly to specific fast path without searching in menu, then just enter Fast Path number here.</p></div>
        </div>
        <div style={{...flexDiv}}>
            <div style={{...imgDiv}}><img src={SearchButton} /></div>
            <span style={{...spanDiv}}>Search Button :</span>
            <div style={{...pDiv}}><p>After entering Fast Path number user can click search button for execute the Fast Path or simply press Enter key.</p></div>
        </div>
        <div style={{...flexDiv}}>
            <div style={{...imgDiv}}><img src={Edit} /></div>
            <span style={{...spanDiv}}>Edit :</span>
            <div style={{...pDiv}}><p>This button used for add or edit data.</p></div>
        </div>
        <div style={{...flexDiv}}>
            <div style={{...imgDiv}}><img src={Download} /></div>
            <span style={{...spanDiv}}>Download :</span>
            <div style={{...pDiv}}><p>For download data.</p></div>
        </div>
        <div style={{...flexDiv}}>   
            <div style={{...imgDiv}}><img src={Save} /></div>
            <span style={{...spanDiv}}>Save :</span>
            <div style={{...pDiv}}><p>After add or edit data click on save button for preventing data lost.</p></div>    
        </div>
        <div style={{...flexDiv}}>
            <div style={{...imgDiv}}><img src={Execute} /></div>
            <span style={{...spanDiv}}>Execute :</span>
            <div style={{...pDiv}}><p>To load the selected data.</p></div>
        </div>
        <div style={{...flexDiv}}>
            <div style={{...imgDiv}}> <img src={Delete} /></div>
            <span style={{...spanDiv}}>Delete :</span>
            <div style={{...pDiv}}><p>The "delete" button is used to delete or remove data.</p></div>
        </div>
        <div style={{...flexDiv}}>
            <div style={{...imgDiv}}> <img src={Refresh} /></div>
            <span style={{...spanDiv}}>Refresh :</span>
            <div style={{...pDiv}}><p>The program will re-read the data from its source and
update the display to reflect any changes that have been made.</p></div>
        </div>
        <div style={{...flexDiv}}>
        <div style={{...imgDiv}}><img src={Copy} /></div>
        <span style={{...spanDiv}}>Copy :</span>
            <div style={{...pDiv}}><p>When you click on the copy button, the selected data is stored in a
temporary location called the clipboard, from where it can be pasted
into another location.</p></div>
        </div>
        <div style={{...flexDiv}}>
        <div style={{...imgDiv}}><img src={Back} /></div>
        <span style={{...spanDiv}}>Back :</span>
            <div style={{...pDiv}}><p></p></div>
        </div>
    </div>
  )
}

export default Navigation;


    const flexDiv ={
    display:"flex",
    gap:"1%",
    justifyContent:"space-evenly",
    marginBottom:"4%",
    // border:'1px solid red',
}
const imgDiv={
    width:"10%",
    // border:'1px solid green',
    height:"35px"
}

const spanDiv ={
    fontWeight:"bold",
    // border:'1px solid blue',
    width:"20%",
    justifyContent:"center"
}
const pDiv={
    textAlign:"justify",
    // border:'1px solid red',
    width:'60%',
}
import React, { useContext, useEffect, useState } from "react";
import { piColumns } from "./SalesInvoiceFunc";
import { Table, Spin } from "antd";
import { TabStore } from "../../../TabsStructure/TabsStore";
import ScanTable from "../../../CommonControllers/Components/ScanTable/ScanTable";
import noData from "../../../Assets/noData.png";
const ItemsTable = ({ rowData, setRowData, igstappl ,pageDetails,changerow, }) => {
  const [showTable, setShowTable] = useState(true);
  const tabStore = useContext(TabStore)

   console.log("setRowData in itemtables", rowData)
 // scan table
 const [openItem, setOpenItem] = useState(false);
 const [scandata, setScandata] = useState();
 const [renderer, setRenderer] = useState(0);
 const [customerData, setCustomerData] = useState({});
 const [validator, setValidator] = useState(0);
 const [modifiedRowData, setModifiedRowData] = useState([])

  useEffect(() => {
   
    setShowTable(false);

    setTimeout(() => {
      setShowTable(true);
     
    }, 200);
  
    // setModifiedRowData(modified)
   
  }, [rowData?.length, tabStore.state.tabs[tabStore.state.tabIndex]?.editIndex]);
 // let modified=rowData.filter((row)=>row.stockavailableforreturn!==false)
  console.log("Modified",scandata)
  return (
    <>
      <div>
        {showTable&&rowData?.length>0 ? (
          <Table
            style={{
              thead: { background: '#f5f5f5', color: '#333' },
              tfoot: { background: '#f5f5f5', color: '#333' }
            }}
            scroll={{
              // y: 305,
              x: "auto"
            }}
            size="small"
            bordered
            className="tr001-purchaseOrder-itemsTable"
            pagination={false}
            columns={piColumns(igstappl, setRowData, rowData,scandata, setScandata, setOpenItem,pageDetails)?.filter((item) => !item.hidden)}
            dataSource={rowData}
            onChange={(e) => {
             
            }}
            summary={(pageData) => {
              let quantity = 0;
              let taxableAmount = 0;
              let cgstAmount = 0;
              let sgstAmount = 0;
              let igstAmount = 0;
              let totalAmount = 0;
              let boxTotal = 0;
              let freeTotalBoxes = 0;
              let freeTotalpieces = 0;
              let batchTotal = 0;
              let rateTotal = 0;
              let currentboxTotal = 0;
              let currentpieceTotal = 0;
              let currentTotalqty = 0;
              let returnTaxableAmount = 0;
              let returncgstAmount = 0;
              let retutnsgstAmount = 0;
              let returnigstAmount = 0;
              let returnAmount = 0;
              let currentreturnpackingextraqty1=0
              let currentreturnpackingqty1=0
              let currentreturnqty=0
              let freeQty=0
              pageData.forEach(
                ({ packingqty, taxableamount, cgstamt, sgstamt, igstamt, amount, packingextraqty, purrate, batch, currentpackingextraqty, currentpackingqty, currentqty, currentreturntaxableamount, currentretutnsgstamt, currentreturncgstamt, currentreturnigstamt, currentreturnamount, freepackingextraqty, freepackingqty,returnpackingqty,returnqty,returnpackingextraqty,currentreturnpackingextraqty,currentreturnpackingqty,currentreturnqty ,returnfree,qty}) => {
                  quantity += Number(qty?qty:0);
                  console.log("quantity",pageData)
                  taxableAmount += Number(taxableamount?taxableamount:0);
                  cgstAmount += Number(cgstamt?cgstamt:0);
                  sgstAmount += Number(sgstamt?sgstamt:0);
                  igstAmount += Number(igstamt?igstamt:0);
                  totalAmount += Number(amount?amount:0);
                  boxTotal += Number(packingextraqty?packingextraqty:0);
                  rateTotal += Number(purrate);
                  batchTotal += Number(batch);
                  currentboxTotal += Number(returnpackingextraqty) ? Number(returnpackingextraqty) : 0;
                  currentpieceTotal += Number(returnpackingqty) ? Number(returnpackingqty) : 0;

                  currentTotalqty +=Number(currentreturnqty) ?Number(currentreturnqty):0

                   console.log("currentTotalqty",currentreturnpackingqty)
                  returnTaxableAmount += Number(currentreturntaxableamount) ? Number(currentreturntaxableamount) : 0;
                  returncgstAmount += Number(currentreturncgstamt) ? Number(currentreturncgstamt) : 0;
                  retutnsgstAmount += Number(currentretutnsgstamt) ? Number(currentretutnsgstamt) : 0;
                  returnigstAmount += Number(currentreturnigstamt) ? Number(currentreturnigstamt) : 0;
                  returnAmount += Number(currentreturnamount) ? Number(currentreturnamount) : 0;
                  freeTotalBoxes += Number(freepackingextraqty) ? Number(freepackingextraqty) : 0;
                  freeTotalpieces += Number(freepackingqty) ? Number(freepackingqty) : 0;
                  currentreturnpackingextraqty1+=Number(currentreturnpackingextraqty)?Number(currentreturnpackingextraqty):0
                  currentreturnpackingqty1+=Number(currentreturnpackingqty)?Number(currentreturnpackingqty):0
                  freeQty+=returnfree?Number(returnfree):0
                }
              );
              return (
                <Table.Summary fixed>
                  {!igstappl ? (
                    <Table.Summary.Row>
                      <Table.Summary.Cell index={0}>{null}</Table.Summary.Cell>
                      {/* nohit-425 Change Position of Total Caption */}
                      <Table.Summary.Cell index={1}>
                        <p style={{ ...pStyles, textAlign: "left" }}>Total</p>
                      </Table.Summary.Cell>
              
                      <Table.Summary.Cell index={4}>
                        <p style={{ ...pStyles }}>{Number(quantity).toFixed(2)}</p>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={0}>{null}</Table.Summary.Cell>
                      <Table.Summary.Cell index={0}>{null}</Table.Summary.Cell>
                      <Table.Summary.Cell index={0}>{null}</Table.Summary.Cell>
                      <Table.Summary.Cell index={0}><p style={{ ...pStyles }}>{Number(taxableAmount).toFixed(2)}</p></Table.Summary.Cell>
                      <Table.Summary.Cell index={0}>{null}</Table.Summary.Cell>
              
                    
                      {/* taskid-261 rajendra here added infotter to make it proper alignment */}
                      <Table.Summary.Cell index={15}>
                        <p style={{ ...pStyles }}>{Number(cgstAmount).toFixed(2)}</p>
                      </Table.Summary.Cell>
                
                      <Table.Summary.Cell index={17}>
                        <p style={{ ...pStyles }}></p>
                      </Table.Summary.Cell>
                     
                      <Table.Summary.Cell index={19}>
                        <p style={{ ...pStyles }}>{Number(sgstAmount).toFixed(2)}</p>
                      </Table.Summary.Cell>
                     
                      <Table.Summary.Cell index={20}>
                        <p style={{ ...pStyles }}>{Number(totalAmount).toFixed(2)}</p>
                      </Table.Summary.Cell>
                 

                    </Table.Summary.Row>
                  ) : (
                    <Table.Summary.Row>
                      <Table.Summary.Cell index={0}>{null}</Table.Summary.Cell>
                      {/* nohit-425 Change Position of Total Caption */}
                      <Table.Summary.Cell index={1}>
                        <p style={{ ...pStyles, textAlign: "left" }}>Total</p>
                      </Table.Summary.Cell>
                      {/* <Table.Summary.Cell index={0}>{null}</Table.Summary.Cell>
                      <Table.Summary.Cell index={0}>{null}</Table.Summary.Cell>
                      <Table.Summary.Cell index={0}>{null}</Table.Summary.Cell> */}
                      <Table.Summary.Cell index={2}><p style={{ ...pStyles }}>{Number(quantity).toFixed(2)}</p></Table.Summary.Cell>
                      <Table.Summary.Cell index={0}>{null}</Table.Summary.Cell>
                     
                  
                      <Table.Summary.Cell index={3}>
                        <p style={{ ...pStyles }}>{}</p>
                      </Table.Summary.Cell>
                 
                      <Table.Summary.Cell index={7}>
                        <p style={{ ...pStyles }}>{}</p>
                      </Table.Summary.Cell>
                 
                   
                      <Table.Summary.Cell index={10}>
                        <p style={{ ...pStyles }}>{Number(taxableAmount).toFixed(2)}</p>
                      </Table.Summary.Cell>
                    
                     
                      {/* taskid-261 rajendra here added infotter to make it proper alignment */}
                     
                      <Table.Summary.Cell index={15}>
                        <p style={{ ...pStyles }}>{}</p>
                      </Table.Summary.Cell>
                     
                      <Table.Summary.Cell index={17}>
                        <p style={{ ...pStyles }}>{Number(igstAmount).toFixed(2)}</p>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={18}>
                        <p style={{ ...pStyles }}>{Number(totalAmount).toFixed(2)}</p>
                      </Table.Summary.Cell>
                     
                  
                    </Table.Summary.Row>
                  )}
                </Table.Summary>
              );
            }}
          />
        ) : rowData?.length>0?(
          <div style={{ ...loaderStyle }}>
            <Spin size="large" />
          </div>): (
          <div className="noDataFound">
            {/* <h1>Whoops! No Returnable Records Found</h1>
            <img src={noData} /> */}
          </div>
        )
        }
      </div>
      <div>
      {openItem && (
        <ScanTable
          data={scandata}
          openItem={openItem}
          setOpenItem={setOpenItem}
          rowData={rowData}
          setRowData={setRowData}
          renderer={renderer}
          setRenderer={setRenderer}
          setValidator={setValidator}
          forFlag={"PR"}
          supplierrecno={customerData?.supplierrecno}
          // forFlag="PR"
          changerow={changerow}

        />
      )}
      
      </div>
    </>
  );
};

export default ItemsTable;

const loaderStyle = {
  height: "max-content",
  width: "max-content",
  display: "flex",
  alignContent: "center",
  margin: "50px auto",
};

const pStyles = {
  textAlign: "right",
  fontWeight: "700",
  fontSize: "16px",
  color: "black",
};


import { Checkbox } from "antd";
import React, { useEffect, useState } from "react";
import { apiCallwithBaseUrl } from "../../ApiCalls";

function CellCheckBox({ handleChange, defaultValue , label, editable}) {
    const [checked, setChecked] = useState(defaultValue);
    const [disabled, setDisabled] = useState(editable?false:true);

    useEffect(()=> {setChecked(defaultValue)}, [defaultValue])// kmk 7-1-2023
  return (
    <Checkbox checked={checked} disabled={disabled} onChange={(e) => {setChecked(!checked)
    handleChange(e)
    }}>
        {label}
    </Checkbox>
  );
}

export default CellCheckBox;

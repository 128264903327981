import { useState, useEffect, useContext } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { Button, Input } from "antd";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import { AuthStore } from "../../../Auth/AuthStore";
import { TabStore } from "../../../TabsStructure/TabsStore";
import SaveDropDown from "../../../CommonControllers/SaveComponents/SaveDropDown";
import SaveDatePicker from "../../../CommonControllers/SaveComponents/SaveDatePicker";
import SaveTextInput from "../../../CommonControllers/Components/SaveComponents/SaveTextInput";
import PendingInsutrumentsTransaction from "./RcPendingInstrumentsTransaction";
import { VOUCHER_RECEIPT, VOUCHER_PAYMENT } from "./RcTransactionHelper";
import { getScroll } from "../../../CommonControllers/ApiCalls";
import { v4 as uuidv4 } from "uuid";

import {
  transformPendingInsutrments,
  generateAccountingEntryPayLoad_TabStore,
  clearAutoAdjustements,
} from "./RcTransactionHelper";

import { VOUCHER_TYPE_RECEIPT, VOUCHER_TYPE_PAYMENT } from "./RcTransactionHelper";

import { getAccountlist } from "../../../CommonControllers/ApiCalls";

import { getAccountlistURL } from "../../../CommonControllers/Urls";

import "./style.css";

import { Voucher_Account } from "./RcTransactionHelper";
import CallbackTextInput from "../../../CommonControllers/Components/CallbackComponents/CallbackTextInput";
import TableModal from "./RcTable";
import { updateValue } from "../../../TabsStructure/tabFunctions";
import { currentTabData } from "../../../CommonControllers/Variable";
import CallbackDropdown from "../../../CommonControllers/Components/CallbackComponents/CallbackDropdown";
import { AiFillDelete } from "react-icons/ai";
import RcTable from "./RcTable";
import ReactPrint from "../../../CommonControllers/Print/ReactPrint";

function TransactionScreen(props) {
  const [glclear, setglclear] = useState(false);
  const [referenceclear, setreferenceclear] = useState(false);
  const tabstore = useContext(TabStore);
  const auth = useContext(AuthStore);
  const [valueTab, setValueTab] = useState(0);
  const [voucherInProgress, setVoucherInProgress] = useState(false);

  const [account1label, setAccount1label] = useState(null);
  const [account2label, setAccount2label] = useState(null);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [onAccountAmount, setonAccountAmount] = useState(0);
  const [localstate, setlocalState] = useState(0);
  const [voucherNo, setVoucherNo] = useState(currentTabData(tabstore)?.changedRows?.vouno ?? "");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [singleTableRecord, setSingleTableRecord] = useState();
  const [accountingVoucher, setAccountingVoucher] = useState(
    currentTabData(tabstore).changedRows?.accounting_vouchers_2 || []
  );
  const [renderer, setRenderer] = useState(0);
  const [ddRenderer, setDdRenderer] = useState(0);

  //* Variables to add in the table:
  const [addAccName, setAddAccName] = useState({});
  const [addDebitAmt, setAddDebitAmt] = useState("");
  const [addCreditAmt, setAddCreditAmt] = useState("");
  const [addSelectedAccount, setAddSelectedAccount] = useState({});

  //~! allow these trnType to be displayed
  const trnDisplay = [0, 1, 2, 3, 6, 7, 8, 9, "NA"]; //! Excluding 4(paymnent) and 5(Sales)

  console.log("<=============== TR121 TransactionScreen Render ===============>");

  const gllistbody = {
    gldetailtype: "'B','A'",
  };
  const [pageIndex, setPageIndex] = useState();

  useEffect(() => {
    var tabsArray = tabstore.state.tabs;
    for (let i = 0; i < tabsArray?.length; i++) {
      if (tabsArray[i].name == props?.fastPath) {
        setPageIndex(i);
        break;
      }
    }
  }, []);
  useEffect(() => {
    // if (tabstore?.state?.tabs[tabstore?.state.tabIndex]?.pageStatus == "Success") {
    console.log("Page Status Changed");

    tabstore.dispatch({
      type: "UPDATE_CHANGED_DATA",
      fieldType: "ourreference",
      text: "",
    });

    tabstore.dispatch({
      type: "UPDATE_CHANGED_DATA",
      fieldType: "narration",
      text: "",
    });

    tabstore.dispatch({
      type: "UPDATE_CHANGED_DATA",
      fieldType: "partyreference",
      text: "",
    });

    tabstore.dispatch({
      type: "UPDATE_CHANGED_DATA",
      fieldType: "VOUCHER_AMT_UPDATED",
      text: "",
    });

    //

    // generateAPIData();
    console.log("set local state page change");
    setlocalState(localstate + 1);
    // }
  }, [tabstore?.state?.tabs[tabstore?.state.tabIndex]?.pageStatus]);

  useEffect(() => {
    calculateOnAccountAmount();
    generateAccountingEntryNew();
    handleVoucherSearch();
    setAccountingVoucher(tabstore?.state?.tabs[tabstore?.state?.tabIndex]?.changedRows?.accounting_vouchers_2);
    console.log("useEffect - changedRows");
  }, [localstate]);

  useEffect(() => {
    // setlocalState(localstate + 1);
  }, [tabstore?.state?.tabs[tabstore?.state.tabIndex]?.changedRows]);

  useEffect(() => {
    if (props.vouchertype == "RECEIPT") {
      setAccount1label("Receipt From");
      setAccount2label("Received In");

      tabstore.dispatch({
        type: "UPDATE_CHANGED_DATA",
        fieldType: "voucher_type",
        text: VOUCHER_TYPE_RECEIPT,
      });
    } else if (props.vouchertype == "PAYMENT") {
      tabstore.dispatch({
        type: "UPDATE_CHANGED_DATA",
        fieldType: "voucher_type",
        text: VOUCHER_TYPE_PAYMENT,
      });

      setAccount1label("Payment To");
      setAccount2label("Payment From");
    }
  }, [props.vouchertype]);

  /*
  function generateAPIData() {
    const changedrows = tabstore?.state?.tabs[tabstore?.state.tabIndex]?.changedRows;

    console.log("changedrows");
    console.log(changedrows);
    let validations = [];

    // validate data before sending api data to tab store

    if (!changedrows.hasOwnProperty("gl_account_1")) {
      validations.push("Payment to Party not selected.");
    }

    if (!changedrows.hasOwnProperty("gl_account_2")) {
      validations.push("Payment from not selected.");
    }

    if (!changedrows.hasOwnProperty("voucherdate")) {
      validations.push("Voucher Date not selected.");
    }

    if (changedrows.hasOwnProperty("VOUCHER_AMT_UPDATED") === false) {
      validations.push("Amount not entered.");
    }

    // console.log("validations");
    //console.log(validations);

    tabstore.dispatch({
      type: "UPDATE_CHANGED_DATA",
      fieldType: "validations",
      text: validations,
    });

    //

    let _trntype = 0; // tran table

    if (props.VOUCHER_TYPE_RECEIPT) {
      _trntype = VOUCHER_RECEIPT;
    } else {
      _trntype = VOUCHER_PAYMENT;
    }

    const postdata = generateAccountingEntryPayLoad_TabStore(
      changedrows,
      auth.state.userdata.domainrecno,
      auth.state.userdata.tenantrecno,
      _trntype
    );
    console.log("generateAPIData" + JSON.stringify(postdata));

    tabstore.dispatch({
      type: "UPDATE_CHANGED_DATA",
      fieldType: "data",
      text: postdata,
    });
  }
  */

  const handleChangeTab = (event, newValue) => {
    setValueTab(newValue);
    console.log("handleChangeTab: " + newValue);

    tabstore.dispatch({
      type: "UPDATE_CHANGED_DATA",
      fieldType: "ourreference",
      text: "",
    });

    tabstore.dispatch({
      type: "UPDATE_CHANGED_DATA",
      fieldType: "narration",
      text: "",
    });

    tabstore.dispatch({
      type: "UPDATE_CHANGED_DATA",
      fieldType: "partyreference",
      text: "",
    });

    if (newValue == 1) {
      generateAccountingEntryNew();
    }
  };

  const updateReturnValue = (e) => {
    console.log("updateReturnValue");
    setlocalState(localstate + 1);
    e && setRenderer(() => uuidv4());
  };

  function handleGlUpdateNew(event) {
    console.log("handleGlUpdateNew event");
    console.log(event);

    if (event != undefined) {
      let account_1 = new Voucher_Account(event.code, event.glcode, event.gldescn, event.accountcode, event.descn);

      tabstore.dispatch({
        type: "UPDATE_CHANGED_DATA",
        fieldType: "gl_account_1",
        text: account_1,
      });
      setSelectedAccount(event.code);
      getpendinginstruments(event.code);
      setlocalState(localstate + 1);
    }
  }

  function handleGlUpdate(event) {
    console.log("handleGlUpdate event");

    if (event != undefined) {
      // console.log(event);

      let account_2 = new Voucher_Account(event.code, event.glcode, event.gldescn, event.accountcode, event.descn);

      tabstore.dispatch({
        type: "UPDATE_CHANGED_DATA",
        fieldType: "gl_account_2",
        text: account_2,
      });
    }
  }

  function handleClearAdjustment(event) {
    let array = [];

    //array = clearAutoAdjustements(state.pending_instruments);

    array = clearAutoAdjustements(tabstore?.state?.tabs[tabstore?.state.tabIndex]?.changedRows?.pending_instruments);
    console.log("handleClearAdjustment");
    console.log(array);

    tabstore.dispatch({
      type: "UPDATE_CHANGED_DATA",
      fieldType: "pending_instruments",
      text: array,
    });

    tabstore.dispatch({
      type: "UPDATE_CHANGED_DATA",
      fieldType: "trigger_distribute",
      text: "NO",
    });

    setlocalState(localstate + 1);
  }

  /**
   * getpendinginstruments for GL / Account 1
   * if GL / Account 1 is selected for Receipt then send cdflag = 1
   * if GL / Account 2 is selected for Payment then send cdflag = 2
   * 16-JAN-2023
   **/

  async function getpendinginstruments(_code) {
    let temp_cdflag = 1;

    console.log("fetch getpendinginstruments");

    if (tabstore?.state?.tabs[tabstore?.state.tabIndex]?.changedRows?.voucher_type == VOUCHER_TYPE_PAYMENT) {
      temp_cdflag = 2;
    } else if (tabstore?.state?.tabs[tabstore?.state.tabIndex]?.changedRows?.voucher_type == VOUCHER_TYPE_RECEIPT) {
      temp_cdflag = 1;
    }

    var payload = {
      domainrecno: auth.state.userdata.domainrecno, //508, // Changed for EDIT Testing -- Mandar
      tenantrecno: auth.state.userdata.tenantrecno,
      code: _code,
      fortrn: 1,
      cdflag: temp_cdflag,
    };

    getAccountlist(payload).then((data) => {
      // console.log("getpendinginstruments");
      //  console.log(data);
      if (data.Message[0]?.pendinginstruments) {
        const _instr = transformPendingInsutrments(data?.Message[0]?.pendinginstruments);

        tabstore.dispatch({
          type: "UPDATE_CHANGED_DATA",
          fieldType: "pending_instruments",
          text: _instr,
        });
      } else {
        let _instr = [];
        tabstore.dispatch({
          type: "UPDATE_CHANGED_DATA",
          fieldType: "pending_instruments",
          text: _instr,
        });
      }
    });
  }

  function calculateOnAccountAmount() {
    /** copy of PendingInstruments.js TotalRow */
    console.log("called calculateOnAccountAmount");

    let temp_total_pending = 0;
    let temp_total_on_account = 0;
    let temp_total_gl_amount = 0;
    let temp_voucher_amount = 0;
    let temp_balance_on_account_amt = 0;

    temp_voucher_amount = tabstore?.state?.tabs[tabstore?.state.tabIndex]?.changedRows?.VOUCHER_AMT_UPDATED;

    tabstore?.state?.tabs[tabstore?.state.tabIndex]?.changedRows?.pending_instruments?.forEach((item) => {
      temp_total_pending = Number(temp_total_pending) + Number(item.pendingamt);
      if (item.on_account_amount > 0) {
        temp_total_on_account = Number(temp_total_on_account) + Number(item.on_account_amount);
      }

      const other_gl_child = item.childrows;
      other_gl_child.forEach((data) => {
        if (data.amount > 0) {
          temp_total_gl_amount = Number(temp_total_gl_amount) + Number(data.amount);
        }
      });
    });
    temp_balance_on_account_amt = Number(temp_voucher_amount - temp_total_on_account);

    if (isNaN(temp_balance_on_account_amt) == true) {
      setonAccountAmount(0);
    } else {
      setonAccountAmount(temp_balance_on_account_amt);
    }
  }

  const onInstrumentsSettlementChanged = (data) => {
    console.log("onInstrumentsSettlementChanged Parent", data);
    setlocalState(localstate + 1);
  };

  const returnValueChanged = (e) => {
    setlocalState(localstate + 1);
    console.log("TR121 387 =================================>", e);
    e && setRenderer(() => uuidv4());
  };

  function generateAccountingEntryNew() {
    // setVoucherInProgress(true);
    let temp_array = [];

    let Entry1 = {
      code: "",
      GLcode: "0",
      GLName: "",
      Accountcode: "",
      Accountname: "",
      Debit: 0,
      Credit: 0,
      CDFlag: 0,
    };

    let Entry2 = {
      code: "",
      GLcode: "0",
      GLName: "",
      Accountcode: "",
      Accountname: "",
      Debit: 0,
      Credit: 0,
      CDFlag: 0,
    };

    if (tabstore?.state?.tabs[tabstore?.state.tabIndex]?.changedRows?.voucher_type == VOUCHER_TYPE_RECEIPT) {
      // gl account code 1 is  credit
      // gl account  code 2 is debit

      let _temp_account_2 = tabstore?.state?.tabs[tabstore?.state.tabIndex]?.changedRows?.gl_account_2;

      let _temp_account_1 = tabstore?.state?.tabs[tabstore?.state.tabIndex]?.changedRows?.gl_account_1;

      Entry2.code = _temp_account_2?.code;
      Entry2.GLcode = _temp_account_2?.glcodeglcode;
      Entry2.GLName = _temp_account_2?.glname;
      Entry2.Accountcode = _temp_account_2?.accountcode;
      Entry2.Accountname = _temp_account_2?.accountname;
      Entry2.Debit = tabstore?.state?.tabs[tabstore?.state.tabIndex]?.changedRows?.VOUCHER_AMT_UPDATED;
      Entry2.CDFlag = 2;

      Entry1.code = _temp_account_1?.code;
      Entry1.GLcode = _temp_account_1?.glcodeglcode;
      Entry1.GLName = _temp_account_1?.glname;
      Entry1.Accountcode = _temp_account_1?.accountcode;
      Entry1.Accountname = _temp_account_1?.accountname;
      Entry1.Credit = tabstore?.state?.tabs[tabstore?.state.tabIndex]?.changedRows?.VOUCHER_AMT_UPDATED;
      Entry1.CDFlag = 1;

      temp_array.push(Entry2);
      temp_array.push(Entry1);
    } else if (tabstore?.state?.tabs[tabstore?.state.tabIndex]?.changedRows?.voucher_type == VOUCHER_TYPE_PAYMENT) {
      // gl account  code 1 is  debit
      // gl account  code 2 is credit

      let _temp_account_2 = tabstore?.state?.tabs[tabstore?.state.tabIndex]?.changedRows?.gl_account_2;

      let _temp_account_1 = tabstore?.state?.tabs[tabstore?.state.tabIndex]?.changedRows?.gl_account_1;

      Entry1.code = _temp_account_1?.code;
      Entry1.GLcode = _temp_account_1?.glcodeglcode;
      Entry1.GLName = _temp_account_1?.glname;
      Entry1.Accountcode = _temp_account_1?.accountcode;
      Entry1.Accountname = _temp_account_1?.accountname;
      Entry1.Debit = tabstore?.state?.tabs[tabstore?.state.tabIndex]?.changedRows?.VOUCHER_AMT_UPDATED;
      Entry1.CDFlag = 2;

      Entry2.code = _temp_account_2?.code;
      Entry2.GLcode = _temp_account_2?.glcodeglcode;
      Entry2.GLName = _temp_account_2?.glname;
      Entry2.Accountcode = _temp_account_2?.accountcode;
      Entry2.Accountname = _temp_account_2?.accountname;
      Entry2.Credit = tabstore?.state?.tabs[tabstore?.state.tabIndex]?.changedRows?.VOUCHER_AMT_UPDATED;
      Entry2.CDFlag = 1;

      temp_array.push(Entry1);
      temp_array.push(Entry2);
    }

    // check pending instruments array with child rows

    // loop thru all arrays and generate entries

    let disocunt_entries = new Map();
    let other_gl_amount = 0;

    tabstore?.state?.tabs[tabstore?.state.tabIndex]?.changedRows?.pending_instruments?.forEach((element) => {
      element?.childrows?.forEach((discountgl) => {
        let temp_entry = disocunt_entries.get(discountgl.code);

        console.log("temp_entry found" + discountgl.code);
        console.log(temp_entry);

        if (temp_entry == undefined) {
          // entry does not exists

          let Entry_temp_discount = {
            code: discountgl.code,
            GLcode: "0",
            GLName: discountgl.descn,
            Accountcode: "",
            Accountname: "",
            Debit: 0,
            Credit: 0,
            CDFlag: 0,
          };

          if (tabstore?.state?.tabs[tabstore?.state.tabIndex]?.changedRows?.voucher_type == VOUCHER_TYPE_PAYMENT) {
            Entry_temp_discount.Credit = Number(discountgl.amount);
            Entry_temp_discount.Debit = 0;
            Entry_temp_discount.CDFlag = 1;
            other_gl_amount = Number(other_gl_amount) + Number(discountgl.amount);
          } else if (
            tabstore?.state?.tabs[tabstore?.state.tabIndex]?.changedRows?.voucher_type == VOUCHER_TYPE_PAYMENT
          ) {
            Entry_temp_discount.Debit = Number(discountgl.amount);
            Entry_temp_discount.Credit = 0;
            Entry_temp_discount.CDFlag = 2;
            other_gl_amount = Number(other_gl_amount) + Number(discountgl.amount);
          }

          disocunt_entries.set(discountgl.code, Entry_temp_discount);
        } else {
          if (tabstore?.state?.tabs[tabstore?.state.tabIndex]?.changedRows?.voucher_type == VOUCHER_TYPE_PAYMENT) {
            temp_entry.Credit = Number(temp_entry.Credit) + Number(discountgl.amount);
            other_gl_amount = Number(other_gl_amount) + Number(discountgl.amount);
          } else if (
            tabstore?.state?.tabs[tabstore?.state.tabIndex]?.changedRows?.voucher_type.voucher_type ==
            VOUCHER_TYPE_PAYMENT
          ) {
            temp_entry.Debit = Number(temp_entry.Debit) + Number(discountgl.amount);
            other_gl_amount = Number(other_gl_amount) + Number(discountgl.amount);
          }
        }
      });
    });

    //console.log(disocunt_entries);

    for (const [key, value] of disocunt_entries.entries()) {
      //console.log(key, value);
      temp_array.push(value);
    }

    if (other_gl_amount > 0) {
      let code1_entry = temp_array.filter(
        (x) => x.code == tabstore?.state?.tabs[tabstore?.state.tabIndex]?.changedRows?.gl_account_1.code
      );
      //console.log("code1_entry");

      console.log(code1_entry);
      if (code1_entry.length > 0) {
        let main_entry = code1_entry[0];

        if (tabstore?.state?.tabs[tabstore?.state.tabIndex]?.changedRows?.voucher_type == VOUCHER_TYPE_PAYMENT) {
          main_entry.Debit = Number(main_entry.Debit) + Number(other_gl_amount);
        } else if (tabstore?.state?.tabs[tabstore?.state.tabIndex]?.changedRows?.voucher_type == VOUCHER_TYPE_RECEIPT) {
          main_entry.Credit = Number(main_entry.Credit) + Number(other_gl_amount);
        }

        let arrayindex = temp_array.indexOf(main_entry);
        if (arrayindex !== -1) {
          temp_array[arrayindex] = main_entry;
        }
      }
    }

    let debitTotal = 0;
    let creditTotal = 0;

    temp_array.forEach((item) => {
      debitTotal = Number(debitTotal) + Number(item.Debit);
      creditTotal = Number(creditTotal) + Number(item.Credit);
    });

    if (isNaN(debitTotal) == true) {
      debitTotal = 0;
    }
    if (isNaN(creditTotal) == true) {
      creditTotal = 0;
    }

    let totalEntry = {
      code: "TOTAL",
      GLcode: "",
      GLName: "",
      Accountcode: "",
      Accountname: "TOTAL",
      Debit: debitTotal,
      Credit: creditTotal,
      CDFlag: 0,
    };

    temp_array.push(totalEntry);

    // tabstore.dispatch({
    //   type: "UPDATE_CHANGED_DATA",
    //   fieldType: "accounting_vouchers",
    //   text: temp_array,
    // });

    // generateAPIData();
  }

  const handleVoucherSearch = () => {
    const payload = {
      tenantrecno: auth?.state?.userdata?.tenantrecno,
      domainrecno: auth?.state?.userdata?.domainrecno,
      vouno: voucherNo,
    };

    voucherNo &&
      getScroll(payload)?.then((data) => {
        console.log("TR121 get scroll data onclick of button ==>", data?.Message);
        tabstore.dispatch({
          type: "UPDATE_CHANGED_DATA",
          fieldType: "accounting_vouchers_2",
          text: data?.Message,
        });
        tabstore.dispatch({
          type: "UPDATE_CHANGED_DATA",
          fieldType: "vouno",
          text: voucherNo,
        });
        tabstore.dispatch({
          type: "UPDATE_CHANGED_DATA",
          fieldType: "trdate",
          text: data?.Message[0]?.trdate ?? auth?.state?.userdata?.workdate,
        });
        setAccountingVoucher(data?.Message);
        setRenderer(() => uuidv4());
      });
  };

  //~*This func handles Debit and credit amount validations:
  const validateCreditDebit = () => {
    let creditTotal;
    let debitTotal;

    creditTotal = accountingVoucher?.reduce((acc, el) => {
      let value = el?.cramt;
      return Number(value) + acc;
    }, 0);

    debitTotal = accountingVoucher?.reduce((acc, el) => {
      let value = el?.dramt;
      return Number(value) + acc;
    }, 0);

    console.log("TR121 validation", "Credit ===>", creditTotal, "Debit ===>", debitTotal);
    console.log(
      `TR121 validation: IS Credit ${creditTotal} and debit ${debitTotal} balanced ?? ${debitTotal === creditTotal}`
    );
    console.log(`TR121 validation balance deficit is: ${debitTotal - creditTotal}`);

    return creditTotal === debitTotal;
  };

  useEffect(() => {
    console.log("TR121 data setter func triggered", accountingVoucher);
    if (currentTabData(tabstore).changedRows?.vouno) {
      const filteredAccVoucherData = accountingVoucher?.map((item) => {
        return {
          ...item,
          trdate: currentTabData(tabstore)?.changedRows?.trdate || item?.trdate,
          ctflag: item?.ctflag || 2, //~! Hardcoded for now
          amount: item?.dramt || item?.cramt,
          particular: currentTabData(tabstore)?.changedRows?.narration || item?.particular,
          refno: currentTabData(tabstore)?.changedRows?.ourreference || item?.refno,
          theirreference: currentTabData(tabstore)?.changedRows?.partyreference || item?.theirreference,
          recno: item?.recno ?? 0, //~# if recno is undefined, keep it 0
        };
      });

      const generateApiPayload = {
        domainrecno: auth?.state?.userdata?.domainrecno,
        tenantrecno: auth?.state?.userdata?.tenantrecno,
        vouno: voucherNo,
        items: filteredAccVoucherData,
        trdate: currentTabData(tabstore)?.changedRows?.trdate,
        chequedate: currentTabData(tabstore)?.changedRows?.chequedate,
        chequeno: currentTabData(tabstore)?.changedRows?.chequeno ?? "",
        isCreditAndDebitBalanced: validateCreditDebit(),
      };

      tabstore.dispatch({
        type: "UPDATE_CHANGED_DATA",
        fieldType: "data",
        text: generateApiPayload,
      });
    }
  }, [renderer, ddRenderer]);

  return (
    <>
      <div style={{ margin: "15px" }}>
        <div className="voucherInput">
          <SaveTextInput
            displayLabel={`Voucher No.`}
            pageIndex={pageIndex}
            placeholder={`Voucher No.`}
            alignment="column"
            id="tr121-voucher-number"
            apikey="vouno"
            change
            returnValue={(e) => setVoucherNo(e)}
          />
          <div>
            <p style={{ marginBottom: "8px" }}>Transaction Type:</p>
            <Input
              style={{ ...trnTypeStyle }}
              size="medium"
              disabled
              value={accountingVoucher?.length && (accountingVoucher[0]?.trntypedescn ?? "NA")}
            />
          </div>
          <div>
            <Button onClick={handleVoucherSearch}>Search Voucher</Button>
          </div>
          <div>
            <Button
              onClick={() => {
                tabstore.dispatch({
                  type: "UPDATE_DATA",
                  fieldType: "changedRows",
                  text: {},
                });
                tabstore.dispatch({
                  type: "UPDATE_CHANGED_DATA",
                  fieldType: "vouno",
                  text: "",
                });
                setAccountingVoucher([]);
              }}
            >
              Reset
            </Button>
          </div>
        </div>

        {trnDisplay.includes(accountingVoucher?.length && accountingVoucher[0]?.trntype) ? (
          <div
            style={{
              ...inputBoxes,
              display: accountingVoucher?.length ? "inline-block" : "none",
            }}
          >
            <div style={{ ...editorInputs }}>
              <div style={{ zIndex: 1000 }}>
                <SaveDatePicker
                  defaultValue={""}
                  id="voucherdate"
                  displayLabel="Voucher Date *"
                  apiKey="trdate"
                  required
                  returnValue={(e) => returnValueChanged(e)}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "12px",
                  marginBottom: "25px",
                }}
              >
                <div>
                  <SaveDatePicker
                    id={"chequedate"}
                    displayLabel={"Cheque Date"}
                    apiKey={"chequedate"}
                    defaultValue=""
                    returnValue={() => setRenderer(() => uuidv4())}
                  />
                </div>
                <div>
                  <SaveTextInput
                    type={"text"}
                    pageIndex={pageIndex}
                    key={"chequeno"}
                    alignment="column"
                    placeholder="Cheque No"
                    displayLabel={"Cheque No."}
                    apikey={"chequeno"}
                    width="700px"
                    defaultValue=""
                    returnValue={() => setRenderer(() => uuidv4())}
                  />
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "12px",
                  flexWrap: "wrap",
                }}
              >
                <div style={{ marginLeft: "5px", marginRight: "5px", width: "320px" }}>
                  <SaveTextInput
                    defaultValue={""}
                    pageIndex={pageIndex}
                    key={"narration"}
                    placeholder="Enter Narration"
                    displayLabel={"Narration / Particular *"}
                    apikey={"narration"}
                    type="text"
                    returnValue={updateReturnValue}
                  />
                </div>
                <div style={{ marginLeft: "5px", marginRight: "5px", width: "320px" }}>
                  <SaveTextInput
                  pageIndex={pageIndex}
                    defaultValue=""
                    key={"ourreference"}
                    placeholder="Enter Reference"
                    displayLabel={"Our Reference"}
                    apikey={"ourreference"}
                    type="text"
                    returnValue={() => setRenderer(() => uuidv4())}
                  />
                </div>
                <div style={{ marginLeft: "5px", marginRight: "5px", width: "320px" }}>
                  <SaveTextInput
                    key={"partyreference"}
                    pageIndex={pageIndex}
                    placeholder="Enter Reference"
                    displayLabel={"Party  Reference"}
                    apikey={"partyreference"}
                    type="text"
                    defaultValue=""
                    returnValue={() => setRenderer(() => uuidv4())}
                  />
                </div>
              </div>
            </div>
            <div>
              <h3 style={{ margin: "16px 0 0 25px" }}>Use below inputs to add a record to the table</h3>
              <div style={{ ...rowAdditionStyle }}>
                <div style={{ width: "fit-content", position: "relative", zIndex: 20 }}>
                  <CallbackDropdown
                    displayLabel="Account Name"
                    alignment="column"
                    width="300px"
                    apiurl={getAccountlistURL}
                    selectionLabel="descn"
                    selectionValue="code"
                    placeholder="Select Account Name"
                    apikey="selectedAccName"
                    returnValue={(e) => {
                      setAddSelectedAccount(e);
                      console.log("TR121 add account name ===>", addSelectedAccount, e);
                    }}
                  />
                </div>
                <div>
                  <p style={{ paddingBottom: "8px" }}>Debit</p>
                  <Input
                    size="large"
                    value={addDebitAmt}
                    disabled={addCreditAmt !== ""}
                    onChange={(e) => {
                      const regex = /^\d*(\.\d{0,2})?$/;
                      const value = e.target.value;
                      if (value === "" || regex.test(value)) {
                        setAddDebitAmt(value);
                      }
                    }}
                  />
                </div>
                <div>
                  <p style={{ paddingBottom: "8px" }}>Credit</p>
                  <Input
                    size="large"
                    value={addCreditAmt}
                    disabled={addDebitAmt !== ""}
                    onChange={(e) => {
                      const regex = /^\d*(\.\d{0,2})?$/;
                      const value = e.target.value;
                      if (value === "" || regex.test(value)) {
                        setAddCreditAmt(value);
                      }
                    }}
                  />
                </div>
                <Button
                  onClick={() => {
                    const payload = {
                      dramt: addDebitAmt ?? 0,
                      cramt: addCreditAmt ?? 0,
                      amount: addDebitAmt ?? addCreditAmt,
                      code: addSelectedAccount?.code,
                      descn: addSelectedAccount?.descn,
                      cdflag: addDebitAmt ? 2 : addCreditAmt ? 1 : null,
                      ctflag: 2, //! hardcoded for now
                      trdate: currentTabData(tabstore)?.changedRows?.voucherdate ?? auth?.state?.userdata?.workdate,
                      recno: 0, //# Initially when adding a record the recno is set to 0
                    };

                    const data = [...accountingVoucher, payload];

                    tabstore.dispatch({
                      type: "UPDATE_CHANGED_DATA",
                      fieldType: "accounting_vouchers_2",
                      text: data,
                    });
                    setAccountingVoucher(data);
                    setAddDebitAmt("");
                    setRenderer(() => uuidv4());

                    console.log("TR121 Add this object to array ====>", payload, accountingVoucher);
                  }}
                >
                  Add Record
                </Button>
              </div>
            </div>
            {/* <div
            style={{
              display: "flex",
              flexDirection: "row",
              margin: "3px",
              marginLeft: "25px",
              alignContent: "center",
              justifyContent: "flex-start",
            }}
          >
            <div style={{ marginLeft: "5px", marginRight: "5px" }}>
              <SaveTextInput
                type={"number"}
                key={"VOUCHER_AMT_UPDATED"}
                alignment="column"
                placeholder="Amount"
                displayLabel={"Amount"}
                apikey={"VOUCHER_AMT_UPDATED"}
                pageIndex={pageIndex}
                width="700px"
              />
            </div>
            <div style={{ marginLeft: "5px", marginRight: "5px" }}>
              <Button
                type="primary"
                onClick={() => {
                  tabstore.dispatch({
                    type: "UPDATE_CHANGED_DATA",
                    fieldType: "trigger_distribute",
                    text: "YES",
                  });

                  setlocalState(localstate + 1);
                }}
              >
                Auto Adjust Amount
              </Button>
            </div>
            <div style={{ marginLeft: "5px", marginRight: "5px" }}>
              <Button
                type="primary"
                onClick={() => {
                  handleClearAdjustment();
                }}
              >
                Clear Adjusted Amount
              </Button>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginLeft: "5px",
                marginRight: "5px",
              }}
            >
              On Account Amt:{onAccountAmount}
            </div>
          </div> */}
          </div>
        ) : (
          <h1 style={{ marginLeft: "50px", display: accountingVoucher?.length ? "inline-block" : "none" }}>
            Cannot edit{" "}
            <span style={{ color: "red" }}>{accountingVoucher?.length && accountingVoucher[0]?.trntypedescn}</span>{" "}
            entries / records!
          </h1>
        )}

        <Box style={{ maxHeight: "100vh", marginTop: "24px" }}>
          <Grid container rowSpacing={1} sx={{ mx: 0, my: 0, p: 0 }}>
            <Grid item xs={12}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                {/* <Tabs value={valueTab} onChange={handleChangeTab} aria-label="basic tabs example">
                  <Tab label="Adjustments" />
                  <Tab label="Vouchers" />
                </Tabs> */}
              </Box>
              {/**    Key={state.selected_gl} */}
              {/* <TabPanel value={valueTab} index={0}>
                {tabstore?.state?.tabs[tabstore?.state.tabIndex]?.changedRows?.["pending_instruments"] ? (
                  <PendingInsutrumentsTransaction
                    Key={tabstore?.state?.tabs[tabstore?.state.tabIndex]?.changedRows?.["party1"]}
                    distribute={tabstore?.state?.tabs[tabstore?.state.tabIndex]?.changedRows?.["VOUCHER_AMT_UPDATED"]}
                    onInstrumentsSettlementChanged={onInstrumentsSettlementChanged}
                    vouchertype={props.vouchertype}
                  />
                ) : (
                  <></>
                )}
              </TabPanel> */}
              {/* <TabPanel value={valueTab} index={1}> */}
              <div>
                {/* <table border="1" width={"70%"}>
                  <tbody>
                    <tr>
                      <td width="5%" align="center">
                        <p>Delete</p>
                      </td>
                      <td width="5%">Sr.No</td>
                      <td width="10%">Account code</td>
                      <td width="70%">Account Name</td>
                      <td width="5%" align="right">
                        Debit
                      </td>
                      <td width="5%" align="right">
                        Credit
                      </td>
                    </tr>
                    {
                      <>
                        {accountingVoucher?.map((data, index) => (
                          <tr>
                            <td width="5%">
                              <Button
                                size="medium"
                                onClick={(e) => {
                                  console.log("TR121 823 ===>", e);
                                }}
                              >
                                <AiFillDelete />
                              </Button>
                            </td>
                            <td width="5%">{index + 1}</td>
                            <td width="10%">
                              <p>{data.code}</p>
                            </td>
                            <td width="70%">
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  gap: "34px",
                                  position: "relative",
                                  zIndex: `${999 - index + 4}`,
                                }}
                              >
                                {data?.descn && (
                                  <SaveDropDown
                                    width="300px"
                                    apiUrl={getAccountlistURL}
                                    selectionLabel="descn"
                                    selectionValue="code"
                                    minInput={0}
                                    async
                                    placeholder="Select new account name"
                                    apikey={`Account${index + 1}`}
                                    setDataToParent={(e) => {
                                      console.log("TR121 dropdown value ====>", e, "TR121 data", data);

                                      //~* Setting values from dropdown to data object
                                      data.descn = e.descn;
                                      data.code = e.code;
                                    }}
                                  />
                                )}
                                <div>
                                  <h4>{data.descn}</h4>
                                </div>
                              </div>
                            </td>
                            <td width="5%" align="right">
                              {data?.cdflag === 2 && (
                                <input
                                  style={inputTableStyles}
                                  defaultValue={data?.dramt}
                                  onChange={(e) => {
                                    data.dramt = Number(e.target.value);
                                    updateValue(
                                      tabstore,
                                      "accounting_vouchers_2",
                                      accountingVoucher,
                                      "UPDATE_CHANGED_DATA"
                                    );
                                    console.log("TR121 DEBIT ===>", e.target.value, data, accountingVoucher);
                                  }}
                                />
                              )}
                            </td>
                            <td width="5%" align="right">
                              {data?.cdflag === 1 && (
                                <input
                                  style={inputTableStyles}
                                  defaultValue={data?.cramt}
                                  onChange={(e) => {
                                    data.cramt = Number(e.target.value);
                                    updateValue(
                                      tabstore,
                                      "accounting_vouchers_2",
                                      accountingVoucher,
                                      "UPDATE_CHANGED_DATA"
                                    );
                                    console.log("TR121 CREDIT ===>", e.target.value, accountingVoucher);
                                  }}
                                />
                              )}
                            </td>
                          </tr>
                        ))}
                      </>
                    }
                  </tbody>
                </table> */}
              </div>
              {trnDisplay.includes(accountingVoucher?.length && accountingVoucher[0]?.trntype) && (
                <div>
                  <RcTable
                    accountingVoucher={accountingVoucher}
                    setAccountingVoucher={setAccountingVoucher}
                    renderer={renderer}
                    setRenderer={setRenderer}
                    ddRenderer={ddRenderer}
                    setDdRenderer={setDdRenderer}
                    validateCreditDebit={validateCreditDebit}
                  />
                </div>
              )}
              {/* </TabPanel> */}
            </Grid>
          </Grid>
        </Box>
      </div>
    </>
  );
}

// function TabPanel(props) {
//   const { children, value, index, ...other } = props;

//   return (
//     <div
//       role="tabpanel"
//       hidden={value !== index}
//       id={`simple-tabpanel-${index}`}
//       aria-labelledby={`simple-tab-${index}`}
//       {...other}
//     >
//       {value === index && (
//         <Box sx={{ p: 3 }}>
//           <Typography>{children}</Typography>
//         </Box>
//       )}
//     </div>
//   );
// }

// TabPanel.propTypes = {
//   children: PropTypes.node,
//   index: PropTypes.number.isRequired,
//   value: PropTypes.number.isRequired,
// };

export default TransactionScreen;

const inputBoxes = {
  display: "flex",
  alignItems: "start",
  flexDirection: "column",
  gap: "15px",
  width: "fit-content",
  marginLeft: "25px",
  flexWrap: "wrap",
};

const bottomControls = {
  display: "flex",
  justifyContent: "space-between",
  margin: "48px 0 0",
  flexWrap: "wrap",
};

const inputTableStyles = {
  padding: "4px",
  border: "1px solid gray",
  textAlign: "right",
  borderRadius: "6px",
  fontSize: "15px",
};

const editorInputs = {
  display: "flex",
  margin: "0 0 0 25px",
  flexDirection: "row",
  gap: "12px",
};

const rowAdditionStyle = {
  border: "1px solid purple",
  margin: "16px 0 0 25px",
  padding: "8px",
  borderRadius: "10px",
  display: "flex",
  alignItems: "center",
  gap: "24px",
};

const trnTypeStyle = {
  border: "1px solid #dcdcdc",
  padding: "6px 6px 6px 8px",
  backgroundColor: "white",
  color: "black",
  borderRadius: "6px",
};

import React from 'react'
import LineCharts from './LineChart'
import AreaCharts from './AreaChart';
import BarCharts from './BarChart';
import PieCharts from './PieCharts';

function Chart() {
 
return (
 <div>
  <LineCharts/>
  <AreaCharts/>
  <BarCharts/>
  <PieCharts/>
 </div>
);
}

export default Chart
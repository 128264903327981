// start date : 09 May 2023 and code written by Amol Chavhan
import React, { useContext, useEffect, useRef, useState } from "react";
import "../../CommonControllers/global.css";
import { IoAperture } from "react-icons/io5";
import SaveDropDown from "../../CommonControllers/SaveComponents/SaveDropDown";
import SaveTextInput from "../../CommonControllers/Components/SaveComponents/SaveTextInput";
import { Button, Collapse, Space, Modal, Divider } from "antd";
import SaveDatePicker from "../../CommonControllers/SaveComponents/SaveDatePicker";
import ItemSearch from "../../CommonControllers/ItemController/ItemSearch";
import useSaveChangedRows from "../../CommonControllers/Hooks/useSaveChangedRows";
import useShortguid from "../../CommonControllers/Hooks/useShortGuid";
import { TabStore } from "../../TabsStructure/TabsStore";
import { AuthStore } from "../../Auth/AuthStore";
import {
  getDomaincustomerlist,
  getDomainCustomerConsigneeList,
} from "../../CommonControllers/Urls";
import SaveDropdown from "../../CommonControllers/Components/SaveComponents/SaveDropdown";
import axios from "axios";
import { handleAdd } from "./GdnFunction";
import { currentTabData } from "../../CommonControllers/Variable";
import GdnItemsTable from "./GdnItemstable";

const WMS4 = ({fastPath}) => {
  const { Panel } = Collapse;
  const itemSearch = useRef(null);
  const boxRef = useRef(null);
  const piecesRef = useRef(null);
  const [rowData, setRowData] = useState([]);
  const [igstappl, setigstappl] = useState(false);
  const [getSelectedItem, setSelectedItem] = useState("");
  const useChangedRows = useSaveChangedRows();
  const shortguid = useShortguid();
  const tabStore = useContext(TabStore);
  // console.log("tab---", tabStore)
  const auth = useContext(AuthStore);
  const [showTable, setShowTable] = useState(true);

  // Create state for items details inputs
  const [packageNo, setPackageNo] = useState("");
  const [dimensions, setDimensions] = useState("");
  const [area, setArea] = useState("");
  const [quantity, setQuantity] = useState("");
  const [volume, setVolume] = useState("");
  const [netWeight, setNetWeight] = useState("");
  const [grossWeight, setGrossWeight] = useState("");
  const [testCertificateNo, setTestCertificateNo] = useState("");
  const [serialNo, setSerialNo] = useState("");
  const [natureOfPacking, setNatureOfPacking] = useState("");
  const [remarks, setRemarks] = useState("");

  // Setting customer data in this state
  const [customerData, setCustomerData] = useState();

  // Setting Consignee data in this state
  const [consigneeData, setConsigneeData] = useState();

  // Create useState for Show POP Of Document details and Transportation Details
  const [documentModal, setDocumentModel] = useState(false);
  const [transportationModal, setTransportationModal] = useState(false);

  // Create useState for Show POP Of Test Certificate and Serial No.
  // const [tcSrModal, setTcSrModel] = useState(false);

  // Render
  const [gdnNumber, setGdnNumber] = useState();
  const [trdat, settrdat] = useState();

  const [showFilterModal, setshowFilterModal] = useState(false);
  ////dropdown clear//////
  const [modalcount, setModalCount] = useState(0);
  const [pageIndex, setPageIndex] = useState();
  useEffect(() => {
    var tabsArray = tabStore.state.tabs;
    for (let i = 0; i < tabsArray?.length; i++) {
      if (tabsArray[i].name == fastPath) {
        setPageIndex(i);
        break;
      }
    }
  }, []);

  useEffect(() => {
    setShowTable(false);

    setTimeout(() => {
      setShowTable(true);
    }, 200);
  }, [rowData?.length]);

  //~# Setting workdate
  let workdate = auth?.state?.userdata?.workdate;
  workdate = JSON.stringify(workdate);
  workdate = `${workdate?.slice(-2)}/${workdate?.slice(4, 6)}/${workdate?.slice(
    0,
    4
  )}`;

  const enterPress = (e) => {
    console.log("Enter Key is Preseed in Table");

    if (e.keyCode === 13) {
      e.preventDefault();
      const form = e.target.form;
      const index = Array.prototype.indexOf.call(form, e.target);
      if (form.elements[index + 1]) {
        form.elements[index + 1].focus();
      }
    }
  };

  // Add button of Items Details Table function
  const addItemstoTable = () => {
    console.log("Get Selected Item on Button Click", getSelectedItem);
    if (getSelectedItem) {
      var existingItem = rowData.filter(
        (item) => item.itemrecno == getSelectedItem.itemrecno
      );

      console.log("existingItem?.length", existingItem?.length);
      if (existingItem?.length == 0) {
        setPackageNo("");
        setDimensions("");
        setArea("");
        setQuantity("");
        setVolume("");
        setNetWeight("");
        setGrossWeight("");
        setTestCertificateNo("");
        setSerialNo("");
        setNatureOfPacking("");
        setRemarks("");

        handleAdd(
          getSelectedItem,
          setRowData,
          igstappl,
          setSelectedItem,
          shortguid,
          packageNo,
          dimensions,
          area,
          quantity,
          volume,
          netWeight,
          grossWeight,
          testCertificateNo,
          serialNo,
          natureOfPacking,
          remarks
        );
        itemSearch.current.focus();
      } else {
        alert("Item Already Present");
        setSelectedItem(null);
        itemSearch.current.focus();
      }
    }
  };

  useEffect(() => {
    if (customerData?.item) {
      setigstappl(true);
    } else {
      setigstappl(false);
    }
  }, [customerData]);
  // console.log("rowData", rowData);

  useEffect(() => {
    // When Row Data Changes we Need to store that data to the tabsStore with Header Data
    var currentData = useChangedRows.getValue();

    if (!currentData?.shortguid) {
      useChangedRows.updateValue("shortguid", shortguid.getShortguid("PI"));
    }

    if (rowData?.length == 0) {
      var items = useChangedRows.getValue("items");
      if (items) {
        setRowData(items);
      }
    }
  }, []);

  useEffect(() => {
    // When Row Data Changes we Need to store that data to the tabsStore with Header Data
    var existingObject = useChangedRows.getValue();

    existingObject = {
      ...existingObject,
      items: rowData,
    };

    useChangedRows.updateValue(false, existingObject);
  }, [rowData]);

  useEffect(() => {
    // When Row Data Changes we Need to store that data to the tabsStore with Header Data
    var currentData = useChangedRows.getValue();

    if (!currentData?.shortguid) {
      useChangedRows.updateValue("shortguid", shortguid.getShortguid("SO"));
    }
    setCustomerData(useChangedRows.getValue("customerrecno"));
    setConsigneeData(useChangedRows.getValue("consigneerecno"));
    setGdnNumber(useChangedRows.getValue("gdnnumber"));

    var items = useChangedRows.getValue("items");
    if (items) {
      setRowData(items);
    }
  }, [showFilterModal]);

  useEffect(() => {
    if (tabStore.state.tabs[tabStore.state.tabIndex]?.pageStatus == "Success") {
      setRowData([]);
      setigstappl(false);
      setSelectedItem(null);
      setGdnNumber("TBA");
      setPackageNo("");
      setDimensions("");
      setArea("");
      setQuantity("");
      setVolume("");
      setNetWeight("");
      setGrossWeight("");
      setTestCertificateNo("");
      setSerialNo("");
      setNatureOfPacking("");
      setRemarks("");

      useChangedRows.updateValue("shortguid", shortguid.getShortguid("GDN"));
      setModalCount((p) => p + 1);
      setTimeout(
        tabStore.dispatch({
          type: "UPDATE_DATA",
          fieldType: "pageStatus",
          text: null,
        }),
        1000
      );
    }
  }, [
    tabStore.state.tabs[tabStore.state.tabIndex]?.pageStatus,
    Object.keys(currentTabData(tabStore)?.changedRows).length,
  ]);
  console.log("tabStore888", rowData, getSelectedItem);

  const getConsigneeData = async () => {
    const payload = {
      tenantrecno: auth.state.userdata.tenantrecno,
      domainrecno: auth.state.userdata.domainrecno,
      customerrecno: auth.state.userdata.customerrecno,
    };
    const resp = await axios.post(getDomainCustomerConsigneeList, payload);
    console.log("resp", resp);
  };
  useEffect(() => {
    getConsigneeData();
  }, []);

  return (
    <div>
      <Collapse collapsible="header" defaultActiveKey={["1"]}>
        <Panel header="Selection Frame" key="1">
          <div>
            <Space
              style={{
                alignItems: "end",
                display: "flex",
                flexWrap: "wrap",
                gap: "50px",
              }}
            >
              {/* Header Details */}
              <div style={{ display: "flex", gap: "33px" }}>
                <div
                  style={{
                    zIndex: 10,
                    pointerEvents: rowData.length > 0 && "none",
                  }}
                >
                  <SaveDropdown
                    width={"300px"}
                    defaultSelected={customerData}
                    displayLabel="Cust: "
                    alignment="column"
                    apikey="customerrecno"
                    apiurl={getDomaincustomerlist}
                    selectionLabel="customerdescn"
                    selectionValue="customerrecno"
                    returnValue={(event) => {
                      console.log(
                        "Customer Data is in Retrun Value",
                        customerData
                      );

                      console.log("event---", event);

                      setCustomerData(event);
                    }}
                    key={modalcount}
                  />
                </div>

                <div>
                  <SaveDropdown
                    width={"300px"}
                    defaultSelected={consigneeData}
                    displayLabel="Consignee: "
                    alignment="column"
                    apiurl={getDomainCustomerConsigneeList}
                    apikey={"consigneerecno"}
                    selectionLabel="consigneedescn"
                    selectionValue={"consigneerecno"}
                    returnValue={() => setConsigneeData()}
                    // body={{ customerrecno: customerData?.customerrecno }}
                    key={modalcount}
                  />
                </div>

                <div>
                  <p>GDN No.: </p>
                  <input
                    style={{ ...poNumberStyle }}
                    placeholder={gdnNumber ? gdnNumber : "TBA"}
                    disabled
                  />
                </div>

                <div>
                  <SaveDatePicker
                    defaultValue={trdat}
                    apiKey="trdate"
                    displayLabel="GDN Date: "
                    id="wms-gdn-trdate"
                    dateLessthan={auth?.state?.userdata?.workdate}
                    returnValue={(e) => {
                      if (e > auth?.state?.userdata?.workdate) {
                        settrdat(0);
                      } else {
                        settrdat(e);
                      }
                    }}
                    postAlertMsg={`GDN Date cannot be greater than Today's Date`}
                    key={modalcount}
                  />
                </div>

                <div>
                  <Button
                    style={{
                      marginTop: "30px",
                      height: "36px",
                    }}
                    onClick={() => setDocumentModel(true)}
                  >
                    Document Details
                  </Button>
                  {/* Document Details Modal */}
                  <Modal
                    width={"800px"}
                    centered
                    open={documentModal}
                    onOk={() => setDocumentModel(false)}
                    onCancel={() => setDocumentModel(false)}
                  >
                    <div
                      style={{
                        display: "flex",
                        width: "520px",
                        margin: "auto",
                        backgroundColor: "#a3cdef",
                      }}
                    >
                      <div style={{ margin: "auto" }}>
                        <h2>Document Details</h2>
                      </div>
                    </div>
                    <Divider />

                    <div
                      style={{
                        display: "flex",
                        gap: "70px",
                        width: "600px",
                        margin: "auto",
                      }}
                    >
                      <div style={{ width: "270px", marginTop: "5%" }}>
                        <SaveTextInput displayLabel={"Bill of Entry No.: "} pageIndex={pageIndex}/>
                      </div>

                      <div style={{ marginTop: "5%" }}>
                        <SaveDatePicker displayLabel={"Bill of Entry Date: "} />
                      </div>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        gap: "70px",
                        width: "600px",
                        margin: "auto",
                      }}
                    >
                      <div style={{ width: "270px", marginTop: "5%" }}>
                        <SaveTextInput displayLabel={"Tax Inv No.: "} />
                      </div>

                      <div style={{ marginTop: "5%" }}>
                        <SaveDatePicker displayLabel={"Tax Inv Date: "} pageIndex={pageIndex}/>
                      </div>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        gap: "70px",
                        width: "600px",
                        margin: "auto",
                      }}
                    >
                      <div style={{ width: "350px", marginTop: "5%" }}>
                        <SaveTextInput displayLabel={"Pur Order No.: "} pageIndex={pageIndex}/>
                      </div>
                      <div style={{ width: "350px", marginTop: "5%" }}>
                        <SaveTextInput displayLabel={"OML DC: "} pageIndex={pageIndex}/>
                      </div>
                    </div>

                    <div style={{ width: "600px", margin: "auto" }}>
                      <div style={{ marginTop: "5%", marginBottom: "5%" }}>
                        <SaveTextInput displayLabel={"Payment Terms: "} pageIndex={pageIndex}/>
                      </div>
                    </div>
                  </Modal>
                </div>

                <div>
                  <Button
                    style={{
                      marginTop: "30px",
                      height: "36px",
                    }}
                    onClick={() => setTransportationModal(true)}
                  >
                    Transportation Details
                  </Button>
                  {/* Transportation Details Modal */}
                  <Modal
                    width={"800px"}
                    centered
                    open={transportationModal}
                    onOk={() => setTransportationModal(false)}
                    onCancel={() => setTransportationModal(false)}
                  >
                    <div
                      style={{
                        display: "flex",
                        width: "520px",
                        margin: "auto",
                        backgroundColor: "#a3cdef",
                      }}
                    >
                      <div style={{ margin: "auto" }}>
                        <h2>Transportation Details</h2>
                      </div>
                    </div>
                    <Divider />

                    <div
                      style={{
                        display: "flex",
                        gap: "70px",
                        width: "520px",
                        margin: "auto",
                      }}
                    >
                      <div style={{ marginTop: "1%" }}>
                        <div style={{ marginTop: "5%" }}>
                          <SaveTextInput displayLabel={"Destination: "}pageIndex={pageIndex} />
                        </div>

                        <div style={{ marginTop: "5%" }}>
                          <SaveDropDown
                            width={"520px"}
                            displayLabel={"Transporter Name: "}
                            alignment="column"
                            selectionLabel="descn"
                          />
                        </div>

                        <div
                          style={{
                            display: "flex",
                            gap: "70px",
                            width: "520px",
                            margin: "auto",
                          }}
                        >
                          <div style={{ width: "290px", marginTop: "5%" }}>
                            <SaveTextInput displayLabel={"L.R. No.: "} pageIndex={pageIndex}/>
                          </div>

                          <div style={{ marginTop: "5%" }}>
                            <SaveDatePicker displayLabel={"L.R. Date: "} />
                          </div>
                        </div>

                        <div
                          style={{
                            display: "flex",
                            gap: "21px",
                            width: "520px",
                            margin: "auto",
                          }}
                        >
                          <div style={{ marginTop: "5%" }}>
                            <SaveTextInput displayLabel={"Vehicle No.: "} pageIndex={pageIndex}/>
                          </div>

                          <div style={{ marginTop: "5%" }}>
                            <SaveDatePicker
                              displayLabel={"Vehicle placed on: "}
                            />
                          </div>

                          <div
                            style={{
                              marginTop: "5%",
                            }}
                          >
                            <SaveDatePicker
                              displayLabel={"Vehicle released on: "}
                            />
                          </div>
                        </div>

                        <div style={{ marginTop: "5%" }}>
                          <SaveTextInput displayLabel={"Driver Name.: "} pageIndex={pageIndex}/>
                        </div>

                        <div style={{ marginTop: "5%", marginBottom: "5%" }}>
                          <SaveTextInput
                          pageIndex={pageIndex}
                            displayLabel={"Driver Contact No.: "}
                          />
                        </div>
                      </div>
                    </div>
                  </Modal>
                </div>
              </div>
            </Space>

            <Space size="large" style={{ marginTop: "2%"}}>
              {/* amol-wms - changes in style attribute */}
              <div style={{ zIndex: 2, position: "relative", width: "220px" }}>
                <ItemSearch
                  itemSearchRef={itemSearch}
                  forFlag={"S"}
                  returnValue={setSelectedItem}
                  supplierdata={customerData?.customerrecno}
                  dropdown
                  defaultSelectedItem={getSelectedItem}
                  forPage={"GDN"}
                />
              </div>

              {/* Items Details */}
              <div style={{ alignItems: "end" }}>
                <form style={{ ...callbackTextInputStyles, alignItems: "end" }}>
                  {/*No. of packages*/}
                  <div onKeyDown={enterPress}>
                    <p>No. of packages</p>
                    <input
                      ref={boxRef}
                      style={{ ...inputStyles, width: "130px" }}
                      value={packageNo}
                      onChange={(e) => {
                        const regex = /^[0-9\b]+$/;
                        const value = e.target.value;
                        if (value === "" || regex.test(value)) {
                          setPackageNo(value);
                        }
                      }}
                      type="number"
                    />
                  </div>
                  {/* Dimensions */}
                  <div onKeyDown={enterPress}>
                    <p>Dimensions</p>
                    <input
                      ref={piecesRef}
                      style={{ ...inputStyles, width: "130px" }}
                      value={dimensions}
                      type="number"
                      onChange={(e) => {
                        const regex = /^[0-9\b]+$/;
                        const value = e.target.value;
                        if (value === "" || regex.test(value)) {
                          setDimensions(value);
                        }
                      }}
                    />
                  </div>
                  {/* Area */}
                  <div onKeyDown={enterPress}>
                    <p>Area</p>
                    <input
                      ref={piecesRef}
                      style={{ ...inputStyles, width: "110px" }}
                      value={area}
                      type="number"
                      onChange={(e) => {
                        // const regex = /^[0-9\b]+$/;
                        // {amol-373 regex for decimal}
                        const regex = /^\d+(\.\d+)?$/;
                        const value = e.target.value;
                        if (value === "" || regex.test(value)) {
                          setArea(value);
                        }
                      }}
                    />
                  </div>
                  {/* Quantity */}
                  <div onKeyDown={enterPress}>
                    <p>Quantity</p>
                    <input
                      ref={piecesRef}
                      style={{ ...inputStyles, width: "90px" }}
                      value={quantity}
                      type="number"
                      onChange={(e) => {
                        const regex = /^[0-9\b]+$/;
                        const value = e.target.value;
                        if (value === "" || regex.test(value)) {
                          setQuantity(value);
                        }
                      }}
                    />
                  </div>
                  {/* Volume */}
                  <div onKeyDown={enterPress}>
                    <p>Volume</p>
                    <input
                      style={{ ...inputStyles, width: "110px" }}
                      ref={piecesRef}
                      type="number"
                      value={volume}
                      onChange={(e) => {
                        // const regex = /^[0-9\b]+$/;
                        // {amol-373 regex for decimal}
                        const regex = /^\d+(\.\d+)?$/;
                        const value = e.target.value;
                        if (value === "" || regex.test(value)) {
                          setVolume(value);
                        }
                      }}
                    />
                  </div>
                  {/* Net Weight */}
                  <div onKeyDown={enterPress}>
                    <p>Net Weight</p>
                    <input
                      style={{ ...inputStyles, width: "110px" }}
                      ref={piecesRef}
                      type="number"
                      value={netWeight}
                      onChange={(e) => {
                        // const regex = /^[0-9\b]+$/;
                        // {amol-373 regex for decimal}
                        const regex = /^\d+(\.\d+)?$/;
                        const value = e.target.value;
                        if (value === "" || regex.test(value)) {
                          setNetWeight(value);
                        }
                      }}
                    />
                  </div>
                  {/* Gross Weight */}
                  <div onKeyDown={enterPress}>
                    <p>Gross Weight</p>
                    <input
                      style={{ ...inputStyles, width: "110px" }}
                      ref={piecesRef}
                      type="number"
                      value={grossWeight}
                      onChange={(e) => {
                        // const regex = /^[0-9\b]+$/;
                        // {amol-373 regex for decimal}
                        const regex = /^\d+(\.\d+)?$/;
                        const value = e.target.value;
                        if (value === "" || regex.test(value)) {
                          setGrossWeight(value);
                        }
                      }}
                    />
                  </div>
                  {/* Test Certificate No. */}
                  <div onKeyDown={enterPress}>
                    <p>Test Certificate No.</p>
                    <input
                      style={{ ...inputStyles, width: "150px" }}
                      ref={piecesRef}
                      // {amol-373 changed type to text}
                      type="text"
                      value={testCertificateNo}
                      // {amol-373 change in onChange event}
                      onChange={(e) => setTestCertificateNo(e.target.value)}
                      // onChange={(e) => {
                      //   const regex = /^[0-9\b]+$/;
                      //   const value = e.target.value;
                      //   if (value === "" || regex.test(value)) {
                      //     setTestCertificateNo(value);
                      //   }
                      // }}
                    />
                  </div>
                  {/* Serial No. */}
                  <div onKeyDown={enterPress}>
                    <p>Serial No.</p>
                    <input
                      style={{ ...inputStyles, width: "90px" }}
                      ref={piecesRef}
                      type="number"
                      value={serialNo}
                      onChange={(e) => {
                        const regex = /^[0-9\b]+$/;
                        const value = e.target.value;
                        if (value === "" || regex.test(value)) {
                          setSerialNo(value);
                        }
                      }}
                    />
                  </div>

                  {/* <div>
                    <div>
                      <Button
                        style={{
                          marginTop: "30px",
                          height: "36px",
                        }}
                        onClick={() => setTcSrModel(true)}
                      >
                        TC No. and Serial No.
                      </Button>
                      Test Certificate No. and Serial No. Modal
                      <Modal
                        width={"1000px"}
                        centered
                        open={tcSrModal}
                        onOk={() => setTcSrModel(false)}
                        onCancel={() => setTcSrModel(false)}
                      >
                        <div
                          style={{
                            display: "flex",
                            width: "520px",
                            margin: "auto",
                            backgroundColor: "#a3cdef",
                          }}
                        >
                          <div style={{ margin: "auto" }}>
                            <h2>Test Certificate No. and Serial No.</h2>
                          </div>
                        </div>
                        <Divider />

                        <div
                          style={{
                            display: "flex",
                            gap: "70px",
                            width: "800px",
                            margin: "auto",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              gap: "20px",
                              marginTop: "2%",
                            }}
                          >
                            <SaveTextInput
                            pageIndex={pageIndex}
                              displayLabel={"Bill of Entry No.: "}
                            />
                            <SaveTextInput
                            pageIndex={pageIndex}
                              displayLabel={"Test Certificate No.: "}
                            />
                            <SaveTextInput displayLabel={"Serial No.: "} pageIndex={pageIndex}/>

                            <div style={{ marginTop: "3%" }}>
                              <Button
                                style={{ ...btnStyles }}
                                icon={<IoAperture size="1.2rem" />}
                                size="large"
                                onClick={() => {
                                  addItemstoTable();
                                }}
                              >
                                Add
                              </Button>
                            </div>
                          </div>
                        </div>
                        <div style={{ marginTop: "5%" }}>
                          <Table
                            dataSource={data}
                            bordered
                            style={{ textAlign: "center" }}
                          >
                            <Column
                              align="center"
                              title="Bill of Entry"
                              dataIndex="billofentry"
                              key="billofentry"
                            />
                            <Column
                              align="center"
                              title="Test Certificate No."
                              dataIndex="tcno"
                              key="tcno"
                            />
                            <Column
                              align="center"
                              title="Serial No."
                              dataIndex="serialno"
                              key="serialno"
                            />
                            <Column align="center" title="Action" />
                          </Table>
                        </div>
                      </Modal>
                    </div>
                  </div> */}
                  {/* Nature of packing */}
                  <div>
                    <p>Nature of packing</p>
                    <input
                      style={{ ...inputStyles, width: "515px" }}
                      ref={piecesRef}
                      // {amol-373 changed type to text}
                      type="text"
                      value={natureOfPacking}
                      onChange={(e) => setNatureOfPacking(e.target.value)}
                    />
                  </div>
                  {/* Remarks */}
                  <div>
                    <p>Remarks</p>
                    <input
                      style={{ ...inputStyles, width: "535px" }}
                      ref={piecesRef}
                      // {amol-373 changed type to text}
                      type="text"
                      value={remarks}
                      onChange={(e) => setRemarks(e.target.value)}
                    />
                  </div>
                  {/* Add Button */}
                  <div>
                    <Button
                      style={{ ...btnStyles }}
                      icon={<IoAperture size="1.2rem" />}
                      size="large"
                      onClick={() => {
                        addItemstoTable();
                      }}
                    >
                      Add
                    </Button>
                  </div>

                  {/* <SaveCheckbox apikey={"isPrint"} displayLabel="Print PO" editable /> */}
                  {/* <SaveCheckbox apikey={"addgrn"} displayLabel="Add GRN" editable /> */}
                </form>
              </div>
            </Space>
          </div>
        </Panel>
      </Collapse>
      {/* Table for Selection  */}
      <div style={tableStyles}>
        {rowData?.length > 0 && (
          <GdnItemsTable
            rowData={rowData}
            setRowData={setRowData}
            igstappl={igstappl}
            useChangedRows={useChangedRows}
          />
        )}
      </div>
    </div>
  );
};

export default WMS4;

// add to table btn styles
const btnStyles = {
  display: "flex",
  alignItems: "center",
  gap: "8px",
  // padding:"3px",
  width: "90px",
  borderRadius: "8px",
  padding: "6px",
};

const inputStyles = {
  padding: "7px",
  border: "1px solid #dfdfdf",
  width: "90px",
  fontSize: "16px",
  marginTop: "2px",
  borderRadius: "8px",
  textAlign: "right",
};

const poNumberStyle = {
  marginTop: "7px",
  border: "1px solid gray",
  borderRadius: "8px",
  width: "230px",
  padding: "8.7px",
  fontSize: "16px",
};

const tableStyles = {
  position: "relative",
  zIndex: 0,
  marginTop: "24px",
};

// applying padding to align input rows inline
const callbackTextInputStyles = {
  display: "flex",
  flexWrap: "wrap",
  alignItems: "center",
  gap: "18px",
  paddingTop: "10px",
};

import axios from "axios";
import { useState, useEffect, useContext } from "react";
import { AuthStore } from "../../Auth/AuthStore";
import { TabStore } from "../../TabsStructure/TabsStore";
import {
  baseUrl,
  getdomaincustomeritemlist,
  getdomainsupplieritemlist,
  getDomainItemListURL,
} from "../Urls";
import useShortguid from "../Hooks/useShortGuid";
import { dateFormatter, datedisplay, isArray } from "../AppFunction";
import { currentTabData } from "../Variable";
import { notification } from "antd";
export default function useFetchItem() {
  const auth = useContext(AuthStore);
  const tabStore = useContext(TabStore);
  const shortguid = useShortguid();

  var apiVariables = tabStore.state.tabs[tabStore.state.tabIndex]?.apiVariables;

  async function fetchItems(
    searchBy,
    searchText,
    forFlag,
    forDetails,
    supplierdata,
    trdate,
    forPage
  ) {
    var body = {
      tenantrecno: auth.state.userdata.tenantrecno,
      domainrecno: auth.state.userdata.domainrecno,
      domainuserrecno: auth.state.userdata.domainuserrecno,
      userrolerecno: auth.state.userdata.domainuserrecno,
      noofrecords: 20,
      forflag: forFlag,
      trdate: trdate,
      // supplierrecno: supplierdata,
      //{task-id-271, Pradeep}
      wantnamewithcode: searchBy == "itemshortdescn" ? 0 : 1, /// date 30 may prefix and suffix code and name
    };
    if (trdate?.toString().includes("/")) {
      body = { ...body, trdate: datedisplay(trdate).dataDate };
    }

    body[searchBy] = searchText;
    if (forDetails) {
      body.details = true;
      body.batchdetails = true;
    }

    // var url;
    // if (forFlag == "P" &&currentTabData(tabStore)?.name!=="TR029")
    //   url = getdomainsupplieritemlist; //baseUrl + "getdomainsupplieritemlist/";
    // else if (forFlag == "S") url = getdomaincustomeritemlist; // baseUrl + "getdomaincustomeritemlist/"

    var url;
    //  if(forFlag=="P"&&currentTabData(tabStore)?.name=="TR029"){
    //   url = getDomainItemListURL;
    //  // body = { ...body, forflag: null, isservice: 0 };
    // }
    if (
      forFlag == "P" ||
      (currentTabData(tabStore)?.name !== "TR034" && forFlag == "SR")
    ) {
      if (forPage == "GRN") {
        url = getdomainsupplieritemlist;
        body = { ...body, supplierrecno: supplierdata, isservice: 0 };
      } else {
        url = getdomainsupplieritemlist;
        body = { ...body, supplierrecno: supplierdata };
      }
    } //baseUrl + "getdomainsupplieritemlist/";
    else if (
      forFlag == "S" ||
      (currentTabData(tabStore)?.name == "TR034" && forFlag == "SR")
    ) {
      if (
        (forPage == "GRN" || forPage == "GDN" || forPage == "DC") &&
        currentTabData(tabStore)?.name !== "TR014"
      ) {
        url = getdomainsupplieritemlist;
        body = { ...body, customerrecno: supplierdata, isservice: 0 };
      } else {
        /// stk forflag is adde for tr034
        url = getdomaincustomeritemlist;
        body = { ...body, customerrecno: supplierdata };
      }
    } else if (forFlag == "D") {
      url = getDomainItemListURL;
      body = { ...body, forflag: forFlag, isservice: 0 };
    }

    const resp = await axios.post(url, body);

    // console.log("REsponse", resp);

    // If the Function is of Execute Type
    // Update the TabStore to store the data as API-Response
    return resp;
  }

  async function addBarcodeScannedItemToTable(
    rowData,
    currentSelectedItem,
    trnType,
    igstappl,
    pageDetails,
    forFlag,
    customerrecno
  ) {
    var existingItem = rowData?.filter(
      (item) => item?.itemrecno == currentSelectedItem?.itemrecno
    );
    console.log("DATA===>",currentSelectedItem)
    // console.log("RYTUFU",currentSelectedItem)
    // Here if Existing Item is 0, that means that the item was not earlier present in the system.||(forFlag=="openingsi"||forFlag=="openingpi"||forFlag=="openingdc")
    if (existingItem?.length > 0) {
      // We filter through the existing list of Items
      console.log("checkings", customerrecno);
      var newRowData = rowData?.filter((item) => {
        //  Here we check where we find the item

        if (item?.itemrecno == currentSelectedItem?.itemrecno) {
          // If For Purchase check if Item not already not present in System
          // If For Sale check if Already Present in the system

          // forflag aded for validation
          if (
            (trnType == "PIF" &&
              currentSelectedItem?.serialnofound == 0 &&
              forFlag !== "MTIS") ||
            (trnType == "PIF" &&
              currentSelectedItem?.serialnofound == 0 &&
              forFlag == "P") ||
            (trnType == "SIF" &&
              currentSelectedItem?.serialnofound == 1 &&
              currentSelectedItem?.itembatcharrayfound[0]?.serialno) || //// condition added on 13 dec 2023
            (trnType == "DCF" &&
              currentSelectedItem?.serialnofound == 1 &&
              currentSelectedItem?.itembatcharrayfound[0]?.serialno) ||
            (trnType === "PIF" &&
              forFlag == "MTIS" &&
              currentSelectedItem?.serialnofound &&
              (!Array.isArray(currentSelectedItem?.itembatcharrayfound) ||
                (Array.isArray(currentSelectedItem?.itembatcharrayfound) &&
                  currentSelectedItem?.itembatcharrayfound.length > 0 &&
                  Object.keys(currentSelectedItem?.itembatcharrayfound[0])
                    .length > 0)))
          ) {
            var isArrays = isArray(item.itembatcharray);
            if (isArrays) {
              var sameItem = item?.itembatcharray?.filter(
                (item) => item.serialno == currentSelectedItem?.entryserialno
              );

              if (sameItem?.length > 0) {
                //alert("Scanned Item Already Present in the System");
                notification.error({
                  message: `
                  Error `,
                  description: `Scanned Item Already Present in the System`,
                  duration: 1,
                  style: { background: "#efa7a7" },
                });
                return { ...item };
              } else if (
                Number(item.qty) + Number(item?.freeQty ? item.freeQty : 0) >
                item?.itembatcharray?.length
              ) {
              } else if (
                Number(item.qty) + Number(item?.freeQty ? item.freeQty : 0) <=
                item?.itembatcharray?.length
              ) {
                item.packingqty = Number(item?.packingqty) + 1;
                item.qty = Number(item?.qty) + 1;
              }
            }

            var taxableamount;
            if (trnType == "PIF" || trnType == "GRN") {
              taxableamount = Number(item.qty) * currentSelectedItem?.purrate;
            } else {
              taxableamount = Number(item.qty) * currentSelectedItem?.salerate;
            }

            var sgstamt = !igstappl
              ? taxableamount * (currentSelectedItem?.sgstrate / 100)
              : 0.0;
            var cgstamt = !igstappl
              ? taxableamount * (currentSelectedItem?.cgstrate / 100)
              : 0.0;
            var igstamt = igstappl
              ? taxableamount * (currentSelectedItem?.igstrate / 100)
              : 0.0;

            item.sgstamt = Number(sgstamt).toFixed(2);
            item.cgstamt = Number(cgstamt).toFixed(2);
            item.igstamt = Number(igstamt).toFixed(2);
            item.discountrate = item?.discountrate ? item.discountrate : 0;
            item.discountamt = item?.discountamt ? item.discountamt : 0;
            item.taxableamount = Number(taxableamount).toFixed(2);
            item.grossamt = (
              Number(taxableamount) - Number(item.discountamt)
            ).toFixed(2);
            var amount = igstappl
              ? taxableamount + igstamt
              : taxableamount + (cgstamt + sgstamt);
            item.amount = Number(amount).toFixed(2);
            if (item?.itembatcharray?.length > 0) {
              item.itembatcharray?.push({
                serialno: currentSelectedItem?.entryserialno,
                manudate: currentSelectedItem?.entrymanudate,
                expdate: datedisplay(
                  currentSelectedItem?.entryexpdate,
                  pageDetails?.expdatemonthend ? false : true
                ).dataDate,
                barcode: currentSelectedItem?.entrybarcode,
              });
            } else {
              item.itembatcharray = [
                {
                  serialno: currentSelectedItem?.entryserialno,
                  manudate: currentSelectedItem?.entrymanudate,
                  expdate: datedisplay(
                    currentSelectedItem?.entryexpdate,
                    pageDetails?.expdatemonthend ? false : true
                  ).dataDate,
                  barcode: currentSelectedItem?.entrybarcode,
                },
              ];
            }
            notification.success({
              message: "Message",
              description: `Item Added Successfully`,
              duration: 1,
              style: { background: "#a7efa7" },
              placement: "top",
            });
            return item;
          }
          ////// opening transation/////
          if (
            (trnType == "PIF" &&
              (forFlag == "openingsi" ||
                forFlag == "openingpi" ||
                forFlag == "openingdc")) ||
            (trnType == "SIF" &&
              (forFlag == "openingsi" || //// condition added on 13 dec 2023
                forFlag == "openingpi" ||
                forFlag == "openingdc")) ||
            (trnType == "DCF" &&
              (forFlag == "openingsi" ||
                forFlag == "openingpi" ||
                forFlag == "openingdc"))
          ) {
            var isArrays = isArray(item.itembatcharray);
            if (isArrays) {
              var sameItem = item?.itembatcharray?.filter(
                (item) => item.serialno == currentSelectedItem?.entryserialno
              );

              if (sameItem?.length > 0) {
                // alert("Scanned Item Already Present in the System");
                notification.error({
                  message: `
                  Error `,
                  description: `Scanned Item Already Present in the System `,
                  duration: 1,
                  style: { background: "#efa7a7" },
                });

                return { ...item };
              } else if (
                Number(item.qty) + Number(item?.freeQty ? item.freeQty : 0) >
                item?.itembatcharray?.length
              ) {
              } else if (
                Number(item.qty) + Number(item?.freeQty ? item.freeQty : 0) <=
                item?.itembatcharray?.length
              ) {
                item.packingqty = Number(item?.packingqty) + 1;
                item.qty = Number(item?.qty) + 1;
              }
            }

            var taxableamount;
            if (trnType == "PIF" || trnType == "GRN") {
              taxableamount = Number(item.qty) * currentSelectedItem?.purrate;
            } else {
              taxableamount = Number(item.qty) * currentSelectedItem?.salerate;
            }

            var sgstamt = !igstappl
              ? taxableamount * (currentSelectedItem?.sgstrate / 100)
              : 0.0;
            var cgstamt = !igstappl
              ? taxableamount * (currentSelectedItem?.cgstrate / 100)
              : 0.0;
            var igstamt = igstappl
              ? taxableamount * (currentSelectedItem?.igstrate / 100)
              : 0.0;

            item.sgstamt = Number(sgstamt).toFixed(2);
            item.cgstamt = Number(cgstamt).toFixed(2);
            item.igstamt = Number(igstamt).toFixed(2);
            item.discountrate = item?.discountrate ? item.discountrate : 0;
            item.discountamt = item?.discountamt ? item.discountamt : 0;
            item.taxableamount = Number(taxableamount).toFixed(2);
            item.grossamt = (
              Number(taxableamount) - Number(item.discountamt)
            ).toFixed(2);
            var amount = igstappl
              ? taxableamount + igstamt
              : taxableamount + (cgstamt + sgstamt);
            item.amount = Number(amount).toFixed(2);
            if (item?.itembatcharray?.length > 0) {
              item.itembatcharray?.push({
                serialno: currentSelectedItem?.entryserialno,
                manudate: currentSelectedItem?.entrymanudate,
                expdate: datedisplay(
                  currentSelectedItem?.entryexpdate,
                  pageDetails?.expdatemonthend ? false : true
                ).dataDate,
                barcode: currentSelectedItem?.entrybarcode,
              });
            } else {
              item.itembatcharray = [
                {
                  serialno: currentSelectedItem?.entryserialno,
                  manudate: currentSelectedItem?.entrymanudate,
                  expdate: datedisplay(
                    currentSelectedItem?.entryexpdate,
                    pageDetails?.expdatemonthend ? false : true
                  ).dataDate,
                  barcode: currentSelectedItem?.entrybarcode,
                },
              ];
            }
            notification.success({
              message: "Message",
              description: `Item Added Successfully`,
              duration: 1,
              style: { background: "#a7efa7" },
              placement: "top",
            });
          }

          ////// opening transation/////
          else {
            if (forFlag == "P") {
              // alert(
              //   "Serial / Batch Canoot be added. Item Already Present. Scan using Scan Table"
              // );
              notification.error({
                message: `
                Error`,
                description: ` Serial / Batch Canoot be added.\n Item Already Present.\n Scan using Scan Table `,
                duration: 1,
                style: { background: "#efa7a7" },
              });
              return { ...item };
            }
            //if(forFlag==="S"){
            notification.error({
              message: `Error `,
              duration: 1,
              description: "Stock Not Available",
              style: { background: "#efa7a7" },
            });
            // }

            // Added {}...item here to make the item stay in the table
            return { ...item };
          }

          return { ...item };
        } else {
          return { ...item };
        }
      });

      rowData = newRowData;
    } else {
      /// here shortguid  code
      // console.log("Mesaage array1")
   
      if (
        customerrecno &&
        currentSelectedItem?.serialnofound === true &&
        currentSelectedItem?.itembatcharrayfound?.length === 1 &&
        Object.keys(currentSelectedItem.itembatcharrayfound[0]).length === 0
      ) {
        // If the condition is met (serialnofound is true and itembatcharrayfound has exactly one empty object),
        // show the notification for an empty item batch array.
       
        notification.error({
          message: `Error`,
          duration: 1,
          description: `Serial No. Already Present in System .`,
          style: { background: "#efa7a7" },
        });
        return;
      }
      var newShortguid = shortguid.getShortguid(
        trnType +
          currentSelectedItem?.itemrecno +
          (currentSelectedItem?.batchNo
            ? currentSelectedItem?.batchNo
            : currentSelectedItem?.entrybarcode)
      );

      var taxableamount = 0;
      if (trnType == "PIF" || trnType == "GRN") {
        taxableamount = 1 * currentSelectedItem?.purrate;
      } else {
        taxableamount = 1 * currentSelectedItem?.salerate;
      }
      var sgstamt = !igstappl
        ? taxableamount * (currentSelectedItem?.sgstrate / 100)
        : 0.0;
      var cgstamt = !igstappl
        ? taxableamount * (currentSelectedItem?.cgstrate / 100)
        : 0.0;
      var igstamt = igstappl
        ? taxableamount * (currentSelectedItem?.igstrate / 100)
        : 0.0;

      var amount = igstappl
        ? taxableamount + igstamt
        : taxableamount + (cgstamt + sgstamt);
      var itemBatchArray = [];
      if (
        trnType === "PIF" && // condition for tr029 added
        forFlag !== "MTIS" && forFlag==="ITRCPT"
        // &&
        // (
          // (
          //   currentSelectedItem?.serialnofound === true &&
          //   forFlag !== "openingpi" 
            //&& (
            //   !Array.isArray(currentSelectedItem?.itembatcharrayfound) ||
            //   (Array.isArray(currentSelectedItem?.itembatcharrayfound) &&
            //     currentSelectedItem?.itembatcharrayfound.length > 0 &&
            //     Object.keys(currentSelectedItem?.itembatcharrayfound[0]).length === 0) ||
            //   currentSelectedItem?.itembatcharrayfound.length === 0
            // )
          // ) ||
          // currentSelectedItem?.serialnofound === false
        // )
      ) {
        //console.log("TRNTYPE1", trnType, currentSelectedItem.serialnofound, forFlag);
        itemBatchArray = [
          {
            serialno: currentSelectedItem?.entryserialno,
            manudate: currentSelectedItem?.entrymanudate,
            expdate: datedisplay(
              currentSelectedItem?.entryexpdate,
              pageDetails?.expdatemonthend ? false : true
            ).dataDate,
            barcode: currentSelectedItem?.entrybarcode,
          },
        ];
        var newObject = {
          ...currentSelectedItem,
          shortguid: newShortguid,
          packingextraqty: 0,
          packingqty: 1,
          qty: 1,
          itembatchno: currentSelectedItem?.wantbatchno === 0 ? "." : currentSelectedItem?.itembatchno,
          freepackingextraqty: 0,
          freepackingqty: 0,
          free: 0,
          freeQty: 0,
          salerate: Number(currentSelectedItem?.salerate).toFixed(2),
          taxableamount: Number(taxableamount).toFixed(2),
          cgstrate: Number(currentSelectedItem?.cgstrate).toFixed(2),
          sgstrate: Number(currentSelectedItem?.sgstrate).toFixed(2),
          igstrate: Number(currentSelectedItem?.igstrate).toFixed(2),
          cgstamt: Number(cgstamt).toFixed(2),
          sgstamt: Number(sgstamt).toFixed(2),
          igstamt: Number(igstamt).toFixed(2),
          amount: Number(amount).toFixed(2),
          discountrate: 0.0,
          discountamt: 0.0,
          grossamt: Number(taxableamount).toFixed(2),
          itembatcharray: itemBatchArray,
        };
      
        rowData = [newObject, ...rowData];
        return rowData
      }
      if (
        trnType === "PIF" && // condition for tr030 added
        forFlag == "MTIS" && forFlag!=="ITRCPT"&&
        (
          (
            currentSelectedItem?.serialnofound === true &&
            forFlag !== "openingpi" &&
            (
              !Array.isArray(currentSelectedItem?.itembatcharrayfound) ||
              (Array.isArray(currentSelectedItem?.itembatcharrayfound) &&
                currentSelectedItem?.itembatcharrayfound.length > 0 ) 
            )
          ) 
        )
      ) {
        //console.log("TRNTYPE1", trnType, currentSelectedItem.serialnofound, forFlag);
        itemBatchArray = [
          {
            serialno: currentSelectedItem?.entryserialno,
            manudate: currentSelectedItem?.entrymanudate,
            expdate: datedisplay(
              currentSelectedItem?.entryexpdate,
              pageDetails?.expdatemonthend ? false : true
            ).dataDate,
            barcode: currentSelectedItem?.entrybarcode,
          },
        ];
        var newObject = {
          ...currentSelectedItem,
          shortguid: newShortguid,
          packingextraqty: 0,
          packingqty: 1,
          qty: 1,
          itembatchno: currentSelectedItem?.wantbatchno === 0 ? "." : currentSelectedItem?.itembatchno,
          freepackingextraqty: 0,
          freepackingqty: 0,
          free: 0,
          freeQty: 0,
          salerate: Number(currentSelectedItem?.salerate).toFixed(2),
          taxableamount: Number(taxableamount).toFixed(2),
          cgstrate: Number(currentSelectedItem?.cgstrate).toFixed(2),
          sgstrate: Number(currentSelectedItem?.sgstrate).toFixed(2),
          igstrate: Number(currentSelectedItem?.igstrate).toFixed(2),
          cgstamt: Number(cgstamt).toFixed(2),
          sgstamt: Number(sgstamt).toFixed(2),
          igstamt: Number(igstamt).toFixed(2),
          amount: Number(amount).toFixed(2),
          discountrate: 0.0,
          discountamt: 0.0,
          grossamt: Number(taxableamount).toFixed(2),
          itembatcharray: itemBatchArray,
        };
      
        rowData = [newObject, ...rowData];
        return rowData
      }
      ///here material issue scanned barcode start==== ask befor changing anything
      if (
        trnType === "PIF" && //// condtion for tr029 added
        forFlag !== "MTIS" && forFlag !== "ITRCPT" &&
        ((currentSelectedItem?.serialnofound === true &&
          forFlag !== "openingpi" &&
          (!Array.isArray(currentSelectedItem?.itembatcharrayfound) ||
            (Array.isArray(currentSelectedItem?.itembatcharrayfound) &&
              currentSelectedItem?.itembatcharrayfound.length > 0 &&
              Object.keys(currentSelectedItem?.itembatcharrayfound[0])
                .length === 0))) ||
          currentSelectedItem?.serialnofound === false)
      ) {
        //console.log("TRNTYPE1", trnType, currentSelectedItem.serialnofound,forFlag);
        itemBatchArray = [
          {
            serialno: currentSelectedItem?.entryserialno,
            manudate: currentSelectedItem?.entrymanudate,
            expdate: datedisplay(
              currentSelectedItem?.entryexpdate,
              pageDetails?.expdatemonthend ? false : true
            ).dataDate,
            barcode: currentSelectedItem?.entrybarcode,
          },
        ];
        var newObject = {
          ...currentSelectedItem,
          shortguid: newShortguid,

          packingextraqty: 0,
          packingqty: 1,
          qty: 1,
          itembatchno:
            currentSelectedItem?.wantbatchno === 0
              ? "."
              : currentSelectedItem?.itembatchno,
          freepackingextraqty: 0,
          freepackingqty: 0,
          free: 0,
          freeQty: 0,
          salerate: Number(currentSelectedItem?.salerate).toFixed(2),
          taxableamount: Number(taxableamount).toFixed(2),
          cgstrate: Number(currentSelectedItem?.cgstrate).toFixed(2),
          sgstrate: Number(currentSelectedItem?.sgstrate).toFixed(2),
          igstrate: Number(currentSelectedItem?.igstrate).toFixed(2),
          cgstamt: Number(cgstamt).toFixed(2),
          sgstamt: Number(sgstamt).toFixed(2),
          igstamt: Number(igstamt).toFixed(2),
          amount: Number(amount).toFixed(2),
          discountrate: 0.0,
          discountamt: 0.0,
          grossamt: Number(taxableamount).toFixed(2),
          itembatcharray: itemBatchArray,
        };

        rowData = [newObject, ...rowData];
      return rowData
      }
      ///here material issue scanned barcode end ===
      else if (
        (trnType == "SIF" &&
          currentSelectedItem?.serialnofound == 1 &&
          currentSelectedItem?.itembatcharrayfound[0]?.serialno) ||
        (trnType == "DCF" &&
          currentSelectedItem?.serialnofound == 1 &&
          currentSelectedItem?.itembatcharrayfound[0]?.serialno)
      ) {
        itemBatchArray = [
          {
            serialno: currentSelectedItem?.entryserialno,
            manudate: currentSelectedItem?.entrymanudate,
            expdate: datedisplay(
              currentSelectedItem?.entryexpdate,
              pageDetails?.expdatemonthend ? false : true
            ).dataDate,
            barcode: currentSelectedItem?.entrybarcode,
          },
        ];
        var newObject = {
          ...currentSelectedItem,
          shortguid: newShortguid,

          packingextraqty: 0,
          packingqty: 1,
          qty: 1,
          itembatchno:
            currentSelectedItem?.wantbatchno === 0
              ? "."
              : currentSelectedItem?.itembatchno,
          freepackingextraqty: 0,
          freepackingqty: 0,
          free: 0,
          freeQty: 0,
          salerate: Number(currentSelectedItem?.salerate).toFixed(2),
          taxableamount: Number(taxableamount).toFixed(2),
          cgstrate: Number(currentSelectedItem?.cgstrate).toFixed(2),
          sgstrate: Number(currentSelectedItem?.sgstrate).toFixed(2),
          igstrate: Number(currentSelectedItem?.igstrate).toFixed(2),
          cgstamt: Number(cgstamt).toFixed(2),
          sgstamt: Number(sgstamt).toFixed(2),
          igstamt: Number(igstamt).toFixed(2),
          amount: Number(amount).toFixed(2),
          discountrate: 0.0,
          discountamt: 0.0,
          grossamt: Number(taxableamount).toFixed(2),
          itembatcharray: itemBatchArray,
        };

        rowData = [newObject, ...rowData];
      }

      //// opening transaction start      to remove validation////
      else if (
        (trnType == "SIF" || trnType == "DCF") &&
        (forFlag == "openingsi" || forFlag == "openingdc")
      ) {
        itemBatchArray = [
          {
            serialno: currentSelectedItem?.entryserialno,
            manudate: currentSelectedItem?.entrymanudate,
            expdate: datedisplay(
              currentSelectedItem?.entryexpdate,
              pageDetails?.expdatemonthend ? false : true
            ).dataDate,
            barcode: currentSelectedItem?.entrybarcode,
          },
        ];
        var newObject = {
          ...currentSelectedItem,
          shortguid: newShortguid,

          packingextraqty: 0,
          packingqty: 1,
          qty: 1,
          itembatchno:
            currentSelectedItem?.wantbatchno === 0
              ? "."
              : currentSelectedItem?.itembatchno,
          freepackingextraqty: 0,
          freepackingqty: 0,
          free: 0,
          freeQty: 0,
          salerate: Number(currentSelectedItem?.salerate).toFixed(2),
          taxableamount: Number(taxableamount).toFixed(2),
          cgstrate: Number(currentSelectedItem?.cgstrate).toFixed(2),
          sgstrate: Number(currentSelectedItem?.sgstrate).toFixed(2),
          igstrate: Number(currentSelectedItem?.igstrate).toFixed(2),
          cgstamt: Number(cgstamt).toFixed(2),
          sgstamt: Number(sgstamt).toFixed(2),
          igstamt: Number(igstamt).toFixed(2),
          amount: Number(amount).toFixed(2),
          discountrate: 0.0,
          discountamt: 0.0,
          grossamt: Number(taxableamount).toFixed(2),
          itembatcharray: itemBatchArray,
        };

        rowData = [newObject, ...rowData];
      } else if (
        trnType == "PIF" &&
        forFlag == "openingpi" &&
        currentSelectedItem?.serialnofound
      ) {
        itemBatchArray = [
          {
            serialno: currentSelectedItem?.entryserialno,
            manudate: currentSelectedItem?.entrymanudate,
            expdate: datedisplay(
              currentSelectedItem?.entryexpdate,
              pageDetails?.expdatemonthend ? false : true
            ).dataDate,
            barcode: currentSelectedItem?.entrybarcode,
          },
        ];
        var newObject = {
          ...currentSelectedItem,
          shortguid: newShortguid,

          packingextraqty: 0,
          packingqty: 1,
          qty: 1,
          itembatchno:
            currentSelectedItem?.wantbatchno === 0
              ? "."
              : currentSelectedItem?.itembatchno,
          freepackingextraqty: 0,
          freepackingqty: 0,
          free: 0,
          freeQty: 0,
          salerate: Number(currentSelectedItem?.salerate).toFixed(2),
          taxableamount: Number(taxableamount).toFixed(2),
          cgstrate: Number(currentSelectedItem?.cgstrate).toFixed(2),
          sgstrate: Number(currentSelectedItem?.sgstrate).toFixed(2),
          igstrate: Number(currentSelectedItem?.igstrate).toFixed(2),
          cgstamt: Number(cgstamt).toFixed(2),
          sgstamt: Number(sgstamt).toFixed(2),
          igstamt: Number(igstamt).toFixed(2),
          amount: Number(amount).toFixed(2),
          discountrate: 0.0,
          discountamt: 0.0,
          grossamt: Number(taxableamount).toFixed(2),
          itembatcharray: itemBatchArray,
        };

        rowData = [newObject, ...rowData];
      }
      //// opening transaction start////
      else {
        if (
          trnType == "PIF" &&
          (forFlag !== "openingsi" ||
            forFlag !== "openingpi" ||
            forFlag !== "openingdc")
        ) {
          // alert("Item Already Present in System");
          if (forFlag == "MTIS" && currentSelectedItem.serialnofound) {
            notification.error({
              message: `Error`,
              duration: 1,
              description: `Stock Not Present`,
              style: { background: "#efa7a7" },
            });
          } else if (
            forFlag == "MTIS" &&
            currentSelectedItem.serialnofound == false &&
            Array.isArray(currentSelectedItem?.itembatcharrayfound) &&
            Object.keys(currentSelectedItem?.itembatcharrayfound[0]).length == 0
          ) {
            notification.error({
              message: `Error`,
              duration: 1,
              description: `Item Not Present In System`,
              style: { background: "#efa7a7" },
            });
          } else {
            notification.error({
              message: `Error`,
              duration: 1,
              description: `Item Already Present in System`,
              style: { background: "#efa7a7" },
            });
          }
        } else {
          if (forFlag == "MTIS") {
            notification.error({
              message: `Error`,
              duration: 1,
              description: `Item Not Present In System`,
              style: { background: "#efa7a7" },
            });
          } else {
            notification.error({
              message: `Error `,
              duration: 1,
              description: `Stock Not Available`,
              style: { background: "#efa7a7" },
            });
          }
        }
      }
    }

    return rowData;
  }

  return { fetchItems, apiVariables, addBarcodeScannedItemToTable };
}

import React, { useState, useEffect } from "react";
import { Modal, Table, Card } from "antd";

const ItemCard = ({ isModalOpen, setIsModalOpen, itemData, itemDescn }) => {
  const itemRows = [];

  for (let i = 0; i < itemData.length; i++) {
    let strDate = itemData[i]?.trdate.toString();

    const payload = {
      key: i + 1,
      method: itemData[i]?.method,
      instrumentno: itemData[i].instrumentno,
      date: `${strDate?.slice(-2)} / ${strDate?.slice(4, 6)} / ${strDate?.slice(
        0,
        4
      )}`,
      qty: itemData[i]?.qty,
      taxableamount: itemData[i].taxableamount,
      amount: itemData[i]?.amount,
    };
    itemRows.push(payload);
  }

  const itemColumns = [
    // {
    //   title: "Method",
    //   width: "fit-content",
    //   dataIndex: "method",
    // },
    {
      title: "PO Number",
      width: "fit-content",
      dataIndex: "instrumentno",
    },
    {
      title: "Date",
      width: "fit-content",
      dataIndex: "date",
    },
    {
      title: "Qty",
      width: "fit-content",
      dataIndex: "qty",
    },
    {
      title: "Taxable Amount",
      width: "fit-content",
      dataIndex: "taxableamount",
      align: "center",
    },
    {
      title: "Amount",
      width: "fit-content",
      dataIndex: "amount",
      align: "center",
    },
  ];

  // console.log(itemData, "Item Card Data");
  // console.log("rows data", itemRows);

  return (
    <>
      <Modal
        centered
        footer={null}
        open={isModalOpen}
        onCancel={() => setIsModalOpen((prev) => !prev)}
        width="fit-content"
      >
        <Card
          title={itemDescn}
          style={{ margin: "48px 0", width: "100%", padding: 0 }}
        >
          <Table
            columns={itemColumns}
            bordered
            dataSource={itemRows}
            pagination={{ position: ["bottomCenter"], pageSize: 7 }}
          />
        </Card>
      </Modal>
    </>
  );
};

export default ItemCard;

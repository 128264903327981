import * as XLSX from "xlsx";
import { useContext, useEffect, useRef, useState } from "react";
import axios from "axios";
// import "./ETLtool.css";
import AppConstants from "./AppConstant";
import { Button, Card, Modal, Select, Table } from "antd";
import CustomButton from "./CustomButton";
import { json, useNavigate } from "react-router-dom";
import { type } from "@testing-library/user-event/dist/type";
import { TableBody, TableCell, TableFooter, TableRow } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { ExcelRenderer, OutTable } from "react-excel-renderer";
import dayjs from 'dayjs';
import ToolETL from "./ToolETL";
import { ValidData } from "./ValidData";
import { AuthStore } from "../../Auth/AuthStore";
import { baseUrl } from "../../CommonControllers/Urls";

export const MultipletableValidate = (props) => {
  const [fieldsArr, setfieldsArr] = useState([]);
  const [getFileds, setgetFileds] = useState([]);
  const [filename, setFilename] = useState(null);
  const [types, settypes] = useState([]);
  const [filedata, setFiledata] = useState([]);
  const [payloadData, setPayloadData] = useState([]);
  const [isValid, setIsValid] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editedRowData, setEditedRowData] = useState({});
  const [update, setupdate] = useState();
  const [editedCell, setEditedCell] = useState(null);
  const [data, setData] = useState();
  const [editMode, setEditMode] = useState(false);
  const [etlFormat, setEtlFormat] = useState();
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [etlcode, setetlcode] = useState();
  const [etlDescn, setetlDescn] = useState();
  const [newfieldsArr, setnewfieldsArr] = useState([]);
  const [domainOptions, setDomainOptions] = useState({ Message: [] });
  const [selectedDomain, setSelectedDomain] = useState();
  const [fieldsArrByFormat, setfieldsArrByFormat] = useState([]);
  const [excelDynamicData, setexcelDynamicData] = useState([]);
  const [missingFields, setmissingFields] = useState([]);
  const [domainDescn, setDomainDescn] = useState("");
  const [totamount, settotamount] = useState(0);
  const [showSingleTable, setShowSingleTable] = useState(false);
  const [tableType, settableType] = useState();
  const [errorMsg, setErrorMsg] = useState();
  const [etltypeshortdescn, setetltypeshortdescn] = useState();

  console.log("errorMsg", errorMsg);
  console.log("filedata", filedata);
  console.log("missingFields", missingFields);
  console.log("etlDescn", etlDescn);
  console.log("etlFormat", etlFormat);
  console.log("newfieldsArr", newfieldsArr);
  console.log("fieldsArr", fieldsArr);
  console.log("fieldsArrByFormat", fieldsArrByFormat);

  const auth = useContext(AuthStore);

  useEffect(() => {
    const newFiledArr = fieldsArr.filter((f) => f.descn === etlDescn);
    setnewfieldsArr(newFiledArr);
  }, [etlDescn]);

  useEffect(() => {
    setIsButtonDisabled(true);
  }, [filedata]);

  const handleEditClick = () => {
    setEditMode(true);
  };

  const handleSaveClick = () => {
    setEditMode(false);
  };

  // async function fieldsforETL(etltypecode) {
  //   console.log("f.etltypecode", etltypecode);
  //   setetlcode(etltypecode);

  //   const selectedField = fieldsArr.find((f) => f.etltypecode === etltypecode);
  //   console.log("selectedField", selectedField);

  //   let etltypecodedescn = ""; // Declare the tablename variable outside the if condition

  //   if (selectedField) {
  //     console.log(
  //       "selectedField.etltypecodedescn",
  //       selectedField.etltypecodedescn
  //     );
  //     etltypecodedescn = selectedField.etltypecodedescn; // Assign the value to tablename
  //     console.log("tablename", etltypecodedescn);
  //     setEtlFormat(etltypecodedescn);
  //     setetlDescn(selectedField.descn);
  //   }

  //   try {
  //     let payload = {
  //       tenantrecno: 3,
  //       domainrecno: 9,
  //       etltypecode: etltypecode,
  //     };
  //     //old
  //     // let { data: res } = await axios.post(
  //     //   AppConstants.APIProduction + "/getetlmasterlist/",
  //     //   payload
  //     // );

  //     let { data: res } = await axios.post(
  //       // "http://bizzcontrol.in/backend/api/v1/getetlmasterlist/",
  //       "https://scientific.sutradhar.tech/backend/api/v1/getetlmasterlist/",
  //       payload
  //     );

  //     console.log("res", res.Message);
  //     if (res.Success) {
  //       setfieldsArr(res?.Message);
  //       settypes(etltypecodedescn);
  //     }
  //   } catch (e) {
  //     alert(e);
  //     console.log("e :", e);
  //   }
  // }

  async function fieldsforETLFormat(etltypecode) {

    const selectedField = fieldsArr.find((f) => f.etltypecode === etltypecode);
    console.log("selectedField", selectedField);

    let etltypecodedescn = ""; // Declare the tablename variable outside the if condition

    if (selectedField) {
      console.log(
        "selectedField.etltypecodedescn",
        selectedField.etltypecodedescn
      );
      etltypecodedescn = selectedField.etltypecodedescn; // Assign the value to tablename
      console.log("tablename", etltypecodedescn);
      setEtlFormat(etltypecodedescn);
      setetltypeshortdescn(selectedField?.etltypeshortdescn)

    }

    setetlcode(etltypecode)
    try {
      let payload = {
        tenantrecno: auth?.state?.userdata?.tenantrecno,
        domainrecno: selectedDomain,
        etltypecode: etltypecode,
      };

      let { data: res } = await axios.post(
        baseUrl + "getsubmittedformatlist/",
        // "https://scientific.sutradhar.tech/backend/api/v1/getsubmittedformatlist/",
        payload
      );

      console.log("res--", res);
      if (res.Success) {
        setfieldsArrByFormat(res);
      }
    } catch (error) {
      console.log("e :", error);
    }
  }

  async function fieldsforETL(descn) {
    console.log("descn", descn);
    // setetlcode(etltypecode)

    const selectedField = fieldsArr.find((f) => f.descn === descn);
    console.log("selectedField", selectedField);

    let etltypecodedescn = ""; // Declare the tablename variable outside the if condition

    if (selectedField) {
      console.log(
        "selectedField.etltypecodedescn",
        selectedField.etltypecodedescn
      );
      etltypecodedescn = selectedField.etltypecodedescn; // Assign the value to tablename
      console.log("tablename", etltypecodedescn);
      setEtlFormat(etltypecodedescn);

    }

    try {
      let payload = {
        tenantrecno: auth?.state?.userdata?.tenantrecno,
        domainrecno: selectedDomain,
        etltypecode: selectedField?.etltypecode
          ? selectedField.etltypecode
          : null,
      };
      //old
      // let { data: res } = await axios.post(
      //   AppConstants.APIProduction + "/getetlmasterlist/",
      //   payload
      // );

      let { data: res } = await axios.post(
        baseUrl + "getetlmasterlist/",
        // "http://bizzcontrol.in/backend/api/v1/getetlmasterlist/",
        // "https://scientific.sutradhar.tech/backend/api/v1/getetlmasterlist/",
        payload
      );

      console.log("res", res.Message);
      if (res.Success) {
        setfieldsArr(res?.Message);
        settypes(etltypecodedescn);
      }
    } catch (e) {
      alert(e);
      console.log("e :", e);
    }
  }


  async function funcFields(recno) {
    console.log("recno", recno);
    console.log("fieldsArr", fieldsArr);
    const selectedField = fieldsArr.find((f) => f.recno === recno);
    console.log("selectedField", selectedField);
    setetlDescn(selectedField?.descn);

    try {
      let payload = {
        tenantrecno: auth?.state?.userdata?.tenantrecno,
        domainrecno: selectedDomain,
        etltypecode: selectedField?.etltypecode
          ? selectedField.etltypecode
          : null,
        recno: recno
      };

      let { data: res } = await axios.post(
        baseUrl + "getetlmasterlist/",
        // "https://scientific.sutradhar.tech/backend/api/v1/getetlmasterlist/",
        payload
      );

      console.log("res", res.Message);
      if (res.Success) {
        setgetFileds(res?.Message);
      }
    } catch (e) {
      alert(e);
      console.log("e :", e);
    }
  }
  console.log("getFileds", getFileds);

  console.log("etlDescn", etlDescn, fieldsArr);
  //item.descn changes to item.etltypecodedescn
  let fields = fieldsArr?.filter((item) => item.descn == etlDescn)?.[0]
    ?.fields;
  let compulsoryfields = fieldsArr?.filter(
    (item) => item.descn == etlDescn
  )?.[0]?.compulsoryfields;

  console.log("fields--", fields, compulsoryfields);

  const expectedHeadings = fields?.concat(compulsoryfields);

  function checkformatmatch(firstRow, datarr) {
    console.log("firstRow--", firstRow);
    for (let i = 0; i < datarr.length; i++) {
      const indexandname = datarr[i];
      console.log("indexString", indexandname[0].split(","));
      const indexString = indexandname[0].split(",")[1];
      const index = parseInt(indexString);

      // Check if index is a valid number
      if (isNaN(index)) {
        console.log(`Invalid index: ${indexString}`);
        return false;
      }

      console.log("index", index);

      const nameatindex = firstRow[index];
      console.log("nameatindex", nameatindex);
      console.log("indexandname[1]", indexandname[1]);

      if (indexandname[1] !== nameatindex) {
        return false;
      }
    }

    return true;
  }

  const [showIndicator, setshowIndicator] = useState(false);
  const [footerInitiation, setfooterInitiation] = useState();
  const [rowHeader, setrowHeader] = useState([]);
  const [col, setcol] = useState([]);
  console.log("rowHeader", rowHeader);

  function rowandcolumnSetter(rows, cols) {
    // setrow(rows);
    setcol(cols);
    let rowData = rows.map((r, ind) => {
      cols.map((c, index) => {
        if (r[c.key] != undefined) {
          if (index != footerInitiation) {
            let sliced_header = rows.slice(r, footerInitiation);
            setrowHeader(sliced_header);
          }
          console.log("colsss", rows);
          console.log("rowsss", cols);
          console.log("rowheadsss", setrowHeader);
        }
      });
    });
  }

  const uploadFile = (e) => {
    const file = e.target.files[0];
    console.log("file", file);

    if (file) {

      ExcelRenderer(file, (err, resp) => {
        if (err) {
          console.log("err ::", err);
        } else {
          console.log("Read Excel", resp);
          const { cols, rows } = resp;
          console.log("cols", cols);
          setshowIndicator(true);
          const data = rows.map((row, rowIndex) => {
            const rowData = {};
            row.forEach((cellValue, columnIndex) => {
              const columnName = cols[columnIndex];
              const cellLabel = `${String.fromCharCode(65 + columnIndex)}${rowIndex + 1}`;
              rowData[cellLabel] = cellValue;
            });
            return rowData;
          });
          console.log("exceldata1", data);
          setexcelDynamicData(data)

          rowandcolumnSetter(data, cols);
          setshowIndicator(false);
        }
      });

      console.log("excelDynamicData", excelDynamicData);

      const reader = new FileReader();
      reader.onload = (event) => {
        const workbook = XLSX.read(event.target.result, { type: "binary" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const data = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

        console.log("exceldata2", data);
        const firstRow = data[0];
        let datarr = [];

        console.log("expectedHeadings", expectedHeadings);
        {
          expectedHeadings?.map((item) => {
            datarr.push([item.cellno, item.keyname]);
          });
        }

        // for (let i = 0; i < data[0].length; i++) {
        //   if (data[0][i] === datarr[i][1] && data[0][i] === datarr[i][0]) {
        //     alert("hello");
        //     return;
        //   }
        // }

        // Blank value validation
        const maxColumns = Math.max(...data.map(row => row.length)); // Find the maximum number of columns
        console.log("maxColumns", maxColumns);


        const fieldsForCompare = getFileds[0].fields.filter((field) => /^[A-Z]+$/.test(field.cellno));
        console.log("fieldsForCompare", fieldsForCompare.length);

        const blankValidationData = data.map((row) => {
          const newRow = [...row];
          for (let i = row.length; i < maxColumns; i++) {
            newRow.push(''); // Fill the remaining cells with empty strings
          }
          return newRow;
        });

        console.log("blankValidationData", blankValidationData);

        // Exclude the last blank row
        const filteredData = blankValidationData.filter(row => row.some(cell => cell !== ''));

        setFiledata(filteredData);

        // if (maxColumns === fieldsForCompare.length) {
        //   setFiledata(filteredData);
        // } else {
        //   // Perform actions or show an error message if the condition is not met
        //   alert('Column count mismatch');
        // }

        const isMatching = checkformatmatch(firstRow, datarr);
        console.log("isMatching", isMatching);
        setIsValid(isMatching);
      };


      reader.readAsBinaryString(file);
    }
  };

  const [bgColor, setbgColor] = useState();
  const [validationResult, setValidationResult] = useState([]);
  const [validationResultHeader, setvalidationResultHeader] = useState([]);
  console.log("validationResult", validationResult);
  console.log("validationResultHeader", validationResultHeader);

  let sumOfAmounts = 0;
  const validate = () => {
    console.log("fieldsArr", fieldsArr);
    console.log("compulsoryfields", fieldsArr?.compulsoryfields);
    console.log("fields---", fieldsArr?.fields);
    const fields = getFileds[0].fields.filter((field) => /^[A-Z]+$/.test(field.cellno));
    const fieldsHeader = getFileds[0].fields;
    console.log("fields", fields);
    const excelData = filedata;
    let hasMissingFields = false;

    newfieldsArr.forEach((record) => {
      console.log("compulsoryfields", record.compulsoryfields);
      const compulsoryFieldNames = record?.compulsoryfields.map((field) => field.fieldname);
      console.log("compulsoryFieldNames", compulsoryFieldNames);
      console.log("record", record);

      const missingFieldNames = compulsoryFieldNames.filter(
        (fieldName) => !record.fields.find((field) => field.keyname === fieldName)
      );

      if (missingFieldNames.length > 0) {
        alert("Missing fields: " + missingFieldNames.join(", "));
        hasMissingFields = true;
        setmissingFields(missingFieldNames)
      }
    });

    console.log("excelData", excelData);

    const startRow = getFileds[0].startrow - 1; // Subtract 1 to convert to 0-based index
    console.log("startRow", startRow);


    //validation for footer
    const validationResults = excelData.slice(startRow).map((row) =>
      fields.map((field, j) => {
        console.log("field in excel", field);
        console.log("cell", row[j]); // Access cell value from the excelData row
        const columnDataType =
          field.type === "int" || field.type === "decimal"
            ? "number"
            : field.type === "varchar" || field.type === "string"
              ? "string"
              : field.type;

        const cellValue = columnDataType === "string" ? String(row[j]) : row[j];

        let isTypeMatch = true;
        let isDateFormatValid = true;
        let isRegexMatch = true;
        let isWidthValid = true;

        if (columnDataType === "number") {
          isTypeMatch = !isNaN(Number(cellValue));
        } else if (columnDataType === "string") {
          isTypeMatch = typeof cellValue === "string" || isNaN(Number(cellValue));
        }

        console.log("field.type", field.type, field.columnheading);

        console.log(
          "cellValue",
          cellValue,
          typeof cellValue,
          columnDataType,
          field.type,
          String(cellValue).length
        );



        if (field.columnname === "amount") {
          const amount = Number(cellValue);
          sumOfAmounts += amount;
        }

        console.log("sumOfAmounts", sumOfAmounts);
        settotamount(sumOfAmounts)

        return isTypeMatch;

      })
    );
    console.log("totamount", totamount);



    console.log("validationResults", validationResults);

    setValidationResult(validationResults);
    console.log("hasMissingFields", hasMissingFields);

    const hasInvalidResult = validationResults.some((row) =>
      row.includes(false)
    );
    // setIsButtonDisabled(hasInvalidResult || hasMissingFields)
    setIsButtonDisabled(hasInvalidResult);

    // validateHeader()
  };




  const validateHeader = () => {
    console.log("getFileds[", getFileds)
    const fields = getFileds[0].fields.filter((field) => /^[A-Z]+\d+$/.test(field.cellno));


    const excelData = filedata.slice(1, getFileds[0]?.startrow - 2);
    console.log("filedata[", filedata)
    console.log("excelData[", excelData)
    console.log("fieldsHeader", fields)
    console.log("excelDynamicData", excelDynamicData)


    fields.forEach((field) => {
      const regex = new RegExp(field.regex);
      const cellno = field.cellno;

      excelDynamicData.forEach((record) => {
        const cellValue = record[cellno];

        console.log("regex", regex, cellno, cellValue)


        if (!cellValue) {
          // Cell value is not found for the given cellno
          console.log(`Cell value not found for cellno: ${cellno}`);
          // Perform further action or validation
          return;
        }

        if (!regex.test(cellValue)) {
          // Regex doesn't match the cell value
          console.log(
            `Regex match failed for field ${field.fieldname}. Cell value: ${cellValue}`
          );
          // Perform further action or validation
        } else {
          // Regex matches the cell value
          console.log(`Regex match successful for field ${field.fieldname}`);
          // Perform further action or validation
        }
      });
    });




    const validationResultsHeader = excelData.map((row) =>
      fields.map((field, j) => {
        const columnDataType =
          field.type === "int" || field.type === "decimal"
            ? "number"
            : field.type === "varchar" || field.type === "string"
              ? "string"
              : field.type;

        const cellValue = columnDataType === "string" ? String(row[j]) : row[j];

        let isTypeMatch = true;
        let isDateFormatValid = true;
        let isRegexMatch = true;

        if (columnDataType === "number") {
          isTypeMatch = !isNaN(Number(cellValue));
        } else if (columnDataType === "string") {
          isTypeMatch = typeof cellValue === "string" || isNaN(Number(cellValue));
        }

        console.log(
          "cellValueHeader",
          cellValue,
          typeof cellValue,
          columnDataType,
          field.type,
          String(cellValue).length
        );
        console.log("field.typeH", field.type, field.columnheading);

        // Validate date format
        if (field.type === "date" && field.columnheading === "ddmmyyyy") {
          const day = parseInt(cellValue.toString().substring(0, 2));
          const month = parseInt(cellValue.toString().substring(2, 4));
          const year = parseInt(cellValue.toString().substring(4));

          console.log("day", day, month, year);

          isDateFormatValid =
            day >= 1 &&
            day <= 31 &&
            month >= 1 &&
            month <= 12 &&
            year >= 1000 &&
            year <= 9999;
        }
        console.log("result", isTypeMatch, isDateFormatValid)

        console.log("field?.keyname", field);
        // Validate PAN, Mobile Number, GST based on regex
        if (field?.columnname) {
          if (field?.columnname === "pan") {
            const panRegex = /^[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}$/;
            isRegexMatch = panRegex.test(cellValue);
          } else if (field?.columnname === "mobile") {
            const mobileRegex = /^[6-9]\d{9}$/;
            isRegexMatch = mobileRegex.test(cellValue);
          } else if (field?.columnname === "gstn") {
            const gstRegex = /^\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1}$/;
            isRegexMatch = gstRegex.test(cellValue);
          }
        }

        return isTypeMatch && isDateFormatValid && isRegexMatch;
        // isDateFormatValid,
        // Other relevant information you want to log


      })

    );

    console.log("validationResultsHeader", validationResultsHeader)

    setvalidationResultHeader(validationResultsHeader);
  };


  // const validate = () => {
  //   console.log("fieldsArr", fieldsArr);
  //   console.log("compulsoryfields", fieldsArr?.compulsoryfields);
  //   console.log("fields---", fieldsArr?.fields);
  //   const fields = fieldsArr[0].fields;
  //   console.log("fields", fields);
  //   const excelData = filedata;

  //   let isTypeMatch = true;
  //   let isDateFormatValid = true;
  //   let isRegexMatch = true;
  //   let isWidthValid = true;
  //   let hasMissingFields = false;

  //   newfieldsArr.forEach((record) => {
  //     console.log("compulsoryfields", record.compulsoryfields);
  //     const compulsoryFieldNames = record?.compulsoryfields.map(
  //       (field) => field.fieldname
  //     );

  //     console.log("compulsoryFieldNames", compulsoryFieldNames);
  //     console.log("record", record);

  //     const missingFieldNames = compulsoryFieldNames.filter(
  //       (fieldName) =>
  //         !record.fields.find((field) => field.keyname === fieldName)
  //     );

  //     if (missingFieldNames.length > 0) {
  //       alert("Missing fields: " + missingFieldNames.join(", "));
  //       hasMissingFields = true;
  //     }
  //   });

  //   // Validate data types and date format
  //   const validationResults = excelData.slice(1).map((row) =>
  //     row.map((cell, j) => {
  //       const field = fields[j];
  //       const columnDataType = field.type === "int" ? "number" : field.type;
  //       const cellValue = columnDataType === "string" ? String(cell) : cell;

  //       // Validate data type
  //       if (columnDataType === "number") {
  //         isTypeMatch = !isNaN(Number(cellValue));
  //       } else if (columnDataType === "string") {
  //         isTypeMatch = typeof cellValue === "string";
  //       }

  //       console.log("field.type", field.type, field.columnheading);

  //       // Validate date format
  //       if (field.type === "date" && field.columnheading === "ddmmyyyy") {
  //         const day = parseInt(cellValue.toString().substring(0, 2));
  //         const month = parseInt(cellValue.toString().substring(2, 4));
  //         const year = parseInt(cellValue.toString().substring(4));

  //         console.log("day", day, month, year);

  //         isDateFormatValid =
  //           day >= 1 &&
  //           day <= 31 &&
  //           month >= 1 &&
  //           month <= 12 &&
  //           year >= 1000 &&
  //           year <= 9999;
  //       }

  //       console.log("field?.keyname", field?.keyname);
  //       // Validate PAN, Mobile Number, GST based on regex
  //       if (field?.keyname) {
  //         if (field?.keyname === "pan") {
  //           const panRegex = /^[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}$/;
  //           isRegexMatch = panRegex.test(cellValue);
  //         } else if (field?.keyname === "mobile") {
  //           const mobileRegex = /^[6-9]\d{9}$/;
  //           isRegexMatch = mobileRegex.test(cellValue);
  //         } else if (field?.keyname === "gstn") {
  //           console.log("in else", cellValue);
  //           const gstRegex =
  //             /^\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1}$/;
  //           isRegexMatch = gstRegex.test(cellValue);
  //           console.log("isRegexMatch", isRegexMatch);
  //         }
  //       }

  //       console.log(
  //         "cellValue",
  //         cellValue,
  //         typeof cellValue,
  //         String(cellValue).length
  //       );

  //       isWidthValid =
  //         !field?.columnlength ||
  //         String(cellValue).length <= field?.columnlength;

  //       console.log(
  //         "isTypeMatch",
  //         isTypeMatch,
  //         isDateFormatValid,
  //         isRegexMatch,
  //         isWidthValid
  //       );

  //       return isTypeMatch && isDateFormatValid && isRegexMatch && isWidthValid;
  //     })
  //   );

  //   setValidationResult(validationResults);
  //   console.log("hasMissingFields", hasMissingFields);

  //   const hasInvalidResult = validationResults.some((row) =>
  //     row.includes(false)
  //   );
  //   // setIsButtonDisabled(hasInvalidResult || hasMissingFields)
  //   setIsButtonDisabled(hasInvalidResult);
  // };

  const fileInputref = useRef(null)

  const refreshFunc = () => {
    setFiledata([])
    setfieldsArr([])
    setgetFileds([])
    setEtlFormat(null)
    setetlDescn(null)
    setEtlFormat(null)
    // setSelectedDomain(null)
    fieldsforETL()
     if(fileInputref.current){
      fileInputref.current.value = ""
     } 
     setErrorMsg("")
  }

  async function uploadData(i) {

    let tabletype = getFileds[0]?.tabletype

    console.log("tabletype", getFileds[0]);

    //multipletable upload function
    if (tabletype > 0) {

      console.log("multipletable upload")

      const getColumnIndexFromCellLetter = (cellLetter) => {
        const letters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
        let index = 0;

        // Check if cellLetter is a multi-letter column name like AA, AB, AC, etc.
        if (cellLetter?.length > 1 && /^[A-Z]+$/.test(cellLetter)) {
          let columnLetters = cellLetter.split('').reverse(); // Reverse the letters for easier calculation

          // Calculate the index for multi-letter columns (AA, AB, AC, etc.)
          for (let i = 0; i < columnLetters?.length; i++) {
            index += Math.pow(letters.length, i) * (letters.indexOf(columnLetters[i]) + 1);
          }

          return index - 1; // Subtract 1 since the index starts from 0
        }

        // If cellLetter is a single letter like A, B, C, etc.
        if (cellLetter?.length === 1 && letters.includes(cellLetter)) {
          return letters.indexOf(cellLetter);
        }

        // If cellLetter does not match the above patterns
        return -1;
      };


      console.log("fieldsUp", fields);

      let index = 0; // Initialize index variable

      const slcide = filedata
        .slice(getFileds[0]?.startrow - 1)

      console.log("slcide", slcide);

      //set items object
      const items = filedata
        .slice(getFileds[0]?.startrow - 1)
        .map((row) => {

          const itemShortguid = `items${Date.now()}-${++index}`;
          // const filteredFields = fields.filter((field) => {
          //   const cellno = field.cellno;
          //   const letters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";

          //   // Exclude keyname-value pair if cellno is in the format "A1", "B1", etc.
          //   if (cellno.length === 2 && letters.includes(cellno[0]) && !isNaN(parseInt(cellno[1]))) {
          //     return false;
          //   }

          //   return true;
          // });

          const filteredFields = fields.filter((field) => {
            return field?.payloadtype === "items"
          });

          let discountAmt = 0;

          // Find the discount amount column index
          const discountField = filteredFields.find((field) => field.keyname === 'discountamt');
          console.log("discountField", discountField);
          if (discountField) {
            const discountIndex = getColumnIndexFromCellLetter(discountField.cellno);
            const discountValue = row[discountIndex];
            if (!isNaN(parseFloat(discountValue))) {
              discountAmt = parseFloat(discountValue);
            }
          }


          console.log("filteredFields", filteredFields);
          return filteredFields.reduce((obj, field) => {
            const keyname = field.keyname;
            let value = '';
            console.log("keyname", keyname);


            console.log("row in excel", row);
            // Check if cellno is a single letter like A, B, C, etc.
            const letters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";

            if (/^[A-Z]+$/.test(field.cellno)) {
              console.log("cellno in excel", field.cellno);
              const columnindex = getColumnIndexFromCellLetter(field.cellno);
              console.log("columnindex in excel", columnindex);
              value = row[columnindex];
            }

            console.log("value in excel", value);
            console.log("field type", field);

            // Check if value is a date string and needs formatting
            if (field.masking === 'yyyymmdd') {

              if (typeof value !== 'string') {
                if (typeof value !== 'undefined' && value !== null) {
                  value = value?.toString(); // Convert value to string
                } else {
                  value = ''; // Assign an empty string as the default value
                }
              }

              let day, month, year;

              console.log("includes", value.length)

              if (value.length === 6) {
                // Format: 020323
                day = value.substring(0, 2);
                month = value.substring(2, 4);
                year = value.substring(4);

                // Adjust the year to include the century
                year = parseInt(year);
                const currentYear = new Date().getFullYear() % 100;
                const currentCentury = Math.floor(new Date().getFullYear() / 100) * 100;
                year = (year > currentYear) ? (currentCentury - 100 + year) : (currentCentury + year);
              } else if (value.length === 8) {

                if (value.includes('/')) {
                  // Format: 02/03/23 or 02/03/2023
                  const dateParts = value.split('/');
                  console.log("dateParts", value);
                  day = dateParts[0];
                  month = dateParts[1];
                  year = dateParts[2].length === 2 ? '20' + dateParts[2] : dateParts[2];

                  console.log("dateParts", dateParts);

                } else if (value.includes('-')) {
                  // Format: 02-03-23 or 02-03-2023
                  const dateParts = value.split('-');
                  day = dateParts[0];
                  month = dateParts[1];
                  year = dateParts[2].length === 2 ? '20' + dateParts[2] : dateParts[2];
                }
                else {
                  // Format: 02032023
                  day = value.substring(0, 2);
                  month = value.substring(2, 4);
                  year = value.substring(4);
                }
              }
              else if (value.length === 10) {

                if (value.includes('/')) {
                  // Format: 02/03/23 or 02/03/2023
                  const dateParts = value.split('/');
                  console.log("dateParts2", value);
                  day = dateParts[0];
                  month = dateParts[1];
                  year = dateParts[2].length === 2 ? '20' + dateParts[2] : dateParts[2];

                  console.log("dateParts", dateParts);

                } else if (value.includes('-')) {
                  // Format: 02-03-23 or 02-03-2023
                  const dateParts = value.split('-');
                  day = dateParts[0];
                  month = dateParts[1];
                  year = dateParts[2].length === 2 ? '20' + dateParts[2] : dateParts[2];
                }
                else {
                  // Format: 02032023
                  day = value.substring(0, 2);
                  month = value.substring(2, 4);
                  year = value.substring(4);
                }
              }
              else if (value.length === 5) {
                // Format: 45170
                const excelDate = parseInt(value);
                const jsDate = new Date((excelDate - 25569) * 86400 * 1000);
                day = jsDate.getDate().toString().padStart(2, '0');
                month = (jsDate.getMonth() + 1).toString().padStart(2, '0');
                year = jsDate.getFullYear().toString();
              }

              // Format the date as "YYYYMMDD"
              value = year + month + day;
              console.log("value", value);
            }

            if (keyname === 'qty' && typeof value === 'string' && value.includes('+')) {
              const values = value.split('+');
              value = parseFloat(values[0].trim()); // Assign the first part before the '+'

              // Generate a new key for the second part after the '+'
              const newFreeKeyname = 'freepackingqty';
              const newKeyname = 'free';
              obj[newKeyname] = parseFloat(values[1].trim()); // Assign the second part to the new key
              obj[newFreeKeyname] = parseFloat(values[1].trim());
            }

            if (keyname !== 'qty' && field.type === "decimal" && typeof value === 'string') {
              value = parseFloat(value.replace(/[^a-zA-Z0-9.]/g, ""));// Remove comma from the value
            }

            if (keyname === 'qty') {
              const newKeyname = 'packingqty';
              obj[newKeyname] = value; // Assign the second part to the new key
            }

            // const qty = field.qty ? parseFloat(row[getColumnIndexFromCellLetter(field.qty)]) : 0;
            // const purrate = field.purrate ? parseFloat(row[getColumnIndexFromCellLetter(field.purrate)]) :0;
            // const discount = field.discount ? parseFloat(row[getColumnIndexFromCellLetter(field.discount)]) : 0;
            // console.log("purrate", qty,purrate,discount);

            if (field?.cellno === "" && field?.defaultvalue !== null && field?.payloadtype === "items") {

              value = field.defaultvalue;

              if (field.type === "decimal" && typeof value === 'string') {
                value = parseFloat(value)
              }

              if (field.masking === 'yyyymmdd') {

                if (typeof value !== 'string') {
                  if (typeof value !== 'undefined' && value !== null) {
                    value = value?.toString(); // Convert value to string
                  } else {
                    value = ''; // Assign an empty string as the default value
                  }
                }

                let day, month, year;

                console.log("includes", value.length)

                if (value.length === 6) {
                  // Format: 020323
                  day = value.substring(0, 2);
                  month = value.substring(2, 4);
                  year = value.substring(4);

                  // Adjust the year to include the century
                  year = parseInt(year);
                  const currentYear = new Date().getFullYear() % 100;
                  const currentCentury = Math.floor(new Date().getFullYear() / 100) * 100;
                  year = (year > currentYear) ? (currentCentury - 100 + year) : (currentCentury + year);
                } else if (value.length === 8) {

                  if (value.includes('/')) {
                    // Format: 02/03/23 or 02/03/2023
                    const dateParts = value.split('/');
                    console.log("dateParts", value);
                    day = dateParts[0];
                    month = dateParts[1];
                    year = dateParts[2].length === 2 ? '20' + dateParts[2] : dateParts[2];

                    console.log("dateParts", dateParts);

                  } else if (value.includes('-')) {
                    // Format: 02-03-23 or 02-03-2023
                    const dateParts = value.split('-');
                    day = dateParts[0];
                    month = dateParts[1];
                    year = dateParts[2].length === 2 ? '20' + dateParts[2] : dateParts[2];
                  }
                  else {
                    // Format: 02032023
                    day = value.substring(0, 2);
                    month = value.substring(2, 4);
                    year = value.substring(4);
                  }
                }
                else if (value.length === 10) {

                  if (value.includes('/')) {
                    // Format: 02/03/23 or 02/03/2023
                    const dateParts = value.split('/');
                    console.log("dateParts2", value);
                    day = dateParts[0];
                    month = dateParts[1];
                    year = dateParts[2].length === 2 ? '20' + dateParts[2] : dateParts[2];

                    console.log("dateParts", dateParts);

                  } else if (value.includes('-')) {
                    // Format: 02-03-23 or 02-03-2023
                    const dateParts = value.split('-');
                    day = dateParts[0];
                    month = dateParts[1];
                    year = dateParts[2].length === 2 ? '20' + dateParts[2] : dateParts[2];
                  }
                  else {
                    // Format: 02032023
                    day = value.substring(0, 2);
                    month = value.substring(2, 4);
                    year = value.substring(4);
                  }
                }
                else if (value.length === 5) {
                  // Format: 45170
                  const excelDate = parseInt(value);
                  const jsDate = new Date((excelDate - 25569) * 86400 * 1000);
                  day = jsDate.getDate().toString().padStart(2, '0');
                  month = (jsDate.getMonth() + 1).toString().padStart(2, '0');
                  year = jsDate.getFullYear().toString();
                }

                // Format the date as "YYYYMMDD"
                value = year + month + day;
                console.log("value", value);
              }

            }

            let netamount = 0;
            let taxableAmount = 0;

            if (keyname === 'amount') {
              console.log("amount is present")
              // Get the amount value
              netamount = parseFloat(value);
              console.log("netamount", netamount, discountAmt);
               taxableAmount = netamount - discountAmt;
              console.log("taxableAmount", taxableAmount);
              const newKeyname = 'taxableamount';
              obj[newKeyname] = isNaN(taxableAmount) ? 0 : taxableAmount.toFixed(2); // Assign the taxable amount to the new key
            }
            else if (!filteredFields.some(field => field.keyname === 'amount') &&
              (keyname === 'purrate' || keyname === 'salerate')) {

                console.log("amount is not present")

              const qtyField = filteredFields.find((field) => field.keyname === 'qty');
              const purrateField = filteredFields.find((field) => field.keyname === 'purrate');
              const salerateField = filteredFields.find((field) => field.keyname === 'salerate');

              if (qtyField && (purrateField || salerateField)) {
                
                const qty = parseFloat(row[getColumnIndexFromCellLetter(qtyField.cellno)]);
                const rate = purrateField ? parseFloat(row[getColumnIndexFromCellLetter(purrateField.cellno)]) : parseFloat(row[getColumnIndexFromCellLetter(salerateField.cellno)]);
                netamount = parseFloat(qty * rate);
                console.log("netamount=>",netamount)
                const amountKeyname = 'amount'
                obj[amountKeyname] = isNaN(netamount) ? 0 : netamount;

                 taxableAmount = netamount - discountAmt;
                console.log("taxableAmount", taxableAmount);
                const newKeyname = 'taxableamount';
                obj[newKeyname] = isNaN(taxableAmount) ? 0 : taxableAmount.toFixed(2); // Assign the taxable amount to the new key
              }
            }

            

            console.log("outertaxableAmount", taxableAmount);

            if (field.type === 'string') {
              value = value?.toString();
            }


            return { ...obj, [keyname]: value };
          }, { shortguid: itemShortguid });
        });


      console.log("items", items);

      //const filterFields = getFileds[0].fields.filter((field) => /^[A-Z]+\d+$/.test(field.cellno));

      const filterFields = getFileds[0].fields.filter((field) => {
        return field.payloadtype === "header"
      });

      console.log("filterFields", filterFields);

      const data = {};

      filterFields.forEach((field) => {
        console.log("field in array", field)
        const cellno = field.cellno;

        if (cellno) {

          excelDynamicData.forEach((record) => {
            if (record.hasOwnProperty(cellno)) {
              let value = record[cellno];

              if (field.masking === 'yyyymmdd') {

                if (typeof value !== 'string') {
                  value = value?.toString(); // Convert value to string
                }

                let day, month, year;

                console.log("includes", value.trim().length)

                let date = value.trim()

                if (date.length === 6) {
                  // Format: 020323
                  day = date.substring(0, 2);
                  month = date.substring(2, 4);
                  year = date.substring(4);

                  // Adjust the year to include the century
                  year = parseInt(year);
                  const currentYear = new Date().getFullYear() % 100;
                  const currentCentury = Math.floor(new Date().getFullYear() / 100) * 100;
                  year = (year > currentYear) ? (currentCentury - 100 + year) : (currentCentury + year);
                } else if (date.length === 8) {

                  if (date.includes('/')) {
                    // Format: 02/03/23 or 02/03/2023
                    const dateParts = date.split('/');
                    console.log("dateParts", date);
                    day = dateParts[0];
                    month = dateParts[1];
                    year = dateParts[2].length === 2 ? '20' + dateParts[2] : dateParts[2];

                    console.log("dateParts", dateParts);

                  } else if (date.includes('-')) {
                    // Format: 02-03-23 or 02-03-2023
                    const dateParts = date.split('-');
                    day = dateParts[0];
                    month = dateParts[1];
                    year = dateParts[2].length === 2 ? '20' + dateParts[2] : dateParts[2];
                  }
                  else {
                    // Format: 02032023
                    day = date.substring(0, 2);
                    month = date.substring(2, 4);
                    year = date.substring(4);
                  }
                }
                else if (date.length === 10) {

                  if (date.includes('/')) {
                    // Format: 02/03/23 or 02/03/2023
                    const dateParts = date.split('/');
                    console.log("dateParts2", date);
                    day = dateParts[0];
                    month = dateParts[1];
                    year = dateParts[2].length === 2 ? '20' + dateParts[2] : dateParts[2];

                    console.log("dateParts", dateParts);

                  } else if (date.includes('-')) {
                    // Format: 02-03-23 or 02-03-2023
                    const dateParts = date.split('-');
                    day = dateParts[0];
                    month = dateParts[1];
                    year = dateParts[2].length === 2 ? '20' + dateParts[2] : dateParts[2];
                  }
                  else {
                    // Format: 02032023
                    day = date.substring(0, 2);
                    month = date.substring(2, 4);
                    year = date.substring(4);
                  }
                }
                else if (date.length === 5) {
                  // Format: 45170
                  const excelDate = parseInt(date);
                  const jsDate = new Date((excelDate - 25569) * 86400 * 1000);
                  day = jsDate.getDate().toString().padStart(2, '0');
                  month = (jsDate.getMonth() + 1).toString().padStart(2, '0');
                  year = jsDate.getFullYear().toString();
                }

                // Format the date as "YYYYMMDD"
                value = year + month + day;
                console.log("value", value);
              }
              // data[cellno] = value;
              data[field.keyname] = value;
              console.log("regex", cellno, value, field.keyname, field.masking);
            }
          });
        }
        else if (field?.defaultvalue !== null) {

          let value = field.defaultvalue;

          if (field.masking === 'yyyymmdd') {

            if (typeof value !== 'string') {
              if (typeof value !== 'undefined' && value !== null) {
                value = value?.toString(); // Convert value to string
              } else {
                value = ''; // Assign an empty string as the default value
              }
            }

            let day, month, year;

            console.log("includes", value.length)

            if (value.length === 6) {
              // Format: 020323
              day = value.substring(0, 2);
              month = value.substring(2, 4);
              year = value.substring(4);

              // Adjust the year to include the century
              year = parseInt(year);
              const currentYear = new Date().getFullYear() % 100;
              const currentCentury = Math.floor(new Date().getFullYear() / 100) * 100;
              year = (year > currentYear) ? (currentCentury - 100 + year) : (currentCentury + year);
            } else if (value.length === 8) {

              if (value.includes('/')) {
                // Format: 02/03/23 or 02/03/2023
                const dateParts = value.split('/');
                console.log("dateParts", value);
                day = dateParts[0];
                month = dateParts[1];
                year = dateParts[2].length === 2 ? '20' + dateParts[2] : dateParts[2];

                console.log("dateParts", dateParts);

              } else if (value.includes('-')) {
                // Format: 02-03-23 or 02-03-2023
                const dateParts = value.split('-');
                day = dateParts[0];
                month = dateParts[1];
                year = dateParts[2].length === 2 ? '20' + dateParts[2] : dateParts[2];
              }
              else {
                // Format: 02032023
                day = value.substring(0, 2);
                month = value.substring(2, 4);
                year = value.substring(4);
              }
            }
            else if (value.length === 10) {

              if (value.includes('/')) {
                // Format: 02/03/23 or 02/03/2023
                const dateParts = value.split('/');
                console.log("dateParts2", value);
                day = dateParts[0];
                month = dateParts[1];
                year = dateParts[2].length === 2 ? '20' + dateParts[2] : dateParts[2];

                console.log("dateParts", dateParts);

              } else if (value.includes('-')) {
                // Format: 02-03-23 or 02-03-2023
                const dateParts = value.split('-');
                day = dateParts[0];
                month = dateParts[1];
                year = dateParts[2].length === 2 ? '20' + dateParts[2] : dateParts[2];
              }
              else {
                // Format: 02032023
                day = value.substring(0, 2);
                month = value.substring(2, 4);
                year = value.substring(4);
              }
            }
            else if (value.length === 5) {
              // Format: 45170
              const excelDate = parseInt(value);
              const jsDate = new Date((excelDate - 25569) * 86400 * 1000);
              day = jsDate.getDate().toString().padStart(2, '0');
              month = (jsDate.getMonth() + 1).toString().padStart(2, '0');
              year = jsDate.getFullYear().toString();
            }

            // Format the date as "YYYYMMDD"
            value = year + month + day;
            console.log("value", value);
          }
          data[field.keyname] = value;
          console.log("regex", value, field.keyname, field.masking);
        }


      });

      console.log("Pdata", data);

      console.log("dataA", data);

      const outerShortguid = etltypeshortdescn + Date.now();
      console.log("outerShortguid", outerShortguid);

      const newfields = getFileds[0].fields.filter((field) => /^[A-Z]+$/.test(field.cellno));
      console.log("newfields", newfields);
      const newexcelData = filedata;

      console.log("newexcelData", newexcelData);

      const newstartRow = getFileds[0].startrow - 1; // Subtract 1 to convert to 0-based index
      console.log("newstartRow", newstartRow);


      //sum of amounts


      // const sumOfSgst = newexcelData
      //   .slice(newstartRow)
      //   .map((row) => {
      //     const amountColumnIndex = newfields.findIndex((field) => field.columnname === "sgstamt");
      //     if (amountColumnIndex !== -1) {
      //       const amountValue = Number(row[amountColumnIndex]);
      //       return isNaN(amountValue) ? 0 : amountValue;
      //     }
      //     return 0;
      //   })
      //   .reduce((sum, amount) => sum + amount, 0);

      // console.log("sumOfSgst:", sumOfSgst);

      const findColumnIndexByCellNo = (cellNo) => {
        const letters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
        let index = 0;

        // Check if cellNo is a multi-letter column name like AA, AB, AC, etc.
        if (cellNo?.length > 1 && /^[A-Z]+$/.test(cellNo)) {
          let columnLetters = cellNo.split('').reverse(); // Reverse the letters for easier calculation

          // Calculate the index for multi-letter columns (AA, AB, AC, etc.)
          for (let i = 0; i < columnLetters.length; i++) {
            index += Math.pow(letters.length, i) * (letters.indexOf(columnLetters[i]) + 1);
          }

          return index - 1; // Subtract 1 since the index starts from 0
        }

        // If cellNo is a single letter like A, B, C, etc.
        if (cellNo?.length === 1 && letters.includes(cellNo)) {
          return letters.indexOf(cellNo);
        }

        // If cellNo does not match the above patterns
        return -1;
      };

      const columnCellNoCgst = newfields.find((field) => field.columnname === "cgstamt")?.cellno; // Get the cell number for "cgstamt"
      const columnCellNoSgst = newfields.find((field) => field.columnname === "sgstamt")?.cellno; // Get the cell number for "sgstamt"
      const columnCellNoIgst = newfields.find((field) => field.columnname === "igstamt")?.cellno; // Get the cell number for "igstamt"
      const columnCellNoAMt = newfields.find((field) => field.columnname === "amount")?.cellno; // Get the cell number for "igstamt"

      const columnCellIndexCgst = findColumnIndexByCellNo(columnCellNoCgst); // Find the column index for "cgstamt"
      const columnCellIndexSgst = findColumnIndexByCellNo(columnCellNoSgst); // Find the column index for "sgstamt"
      const columnCellIndexIgst = findColumnIndexByCellNo(columnCellNoIgst); // Find the column index for "igstamt"
      const columnCellIndexAmt = findColumnIndexByCellNo(columnCellNoAMt); // Find the column index for "igstamt"

      const sumOfAmounts = newexcelData
        .slice(newstartRow)
        .map((row) => {
          if (columnCellIndexAmt !== -1) {
            const amountValue = Number(row[columnCellIndexAmt]);
            return isNaN(amountValue) ? 0 : amountValue;
          }
          return 0;
        })
        .reduce((sum, amount) => sum + amount, 0);

      console.log("Sum of Amounts:", sumOfAmounts);

      const sumOfCgst = newexcelData
        .slice(newstartRow)
        .map((row) => {
          if (columnCellIndexCgst !== -1) {
            const amountValue = Number(row[columnCellIndexCgst]);
            return isNaN(amountValue) ? 0 : amountValue;
          }
          return 0;
        })
        .reduce((sum, amount) => sum + amount, 0);

      const sumOfSgst = newexcelData
        .slice(newstartRow)
        .map((row) => {
          if (columnCellIndexSgst !== -1) {
            const amountValue = Number(row[columnCellIndexSgst]);
            return isNaN(amountValue) ? 0 : amountValue;
          }
          return 0;
        })
        .reduce((sum, amount) => sum + amount, 0);

      const sumOfIgst = newexcelData
        .slice(newstartRow)
        .map((row) => {
          if (columnCellIndexIgst !== -1) {
            const amountValue = Number(row[columnCellIndexIgst]);
            return isNaN(amountValue) ? 0 : amountValue;
          }
          return 0;
        })
        .reduce((sum, amount) => sum + amount, 0);

      console.log("Sum of Amounts:", sumOfAmounts);
      console.log("Sum of Cgst:", sumOfCgst);
      console.log("Sum of Sgst:", sumOfSgst);
      console.log("Sum of Igst:", sumOfIgst);


      const totTaxableAmount = Number(sumOfAmounts) + Number(sumOfSgst) + Number(sumOfIgst) + Number(sumOfCgst)
      console.log("selectedDomain", selectedDomain);

      try {
        let payload = {
          tenantrecno: auth?.state?.userdata?.tenantrecno,
          domaindescn: domainDescn,
          domainrecno: selectedDomain,
          domainuserrecno: auth.state.userdata.domainuserrecno,
          data: {
            ...data, // Assuming data contains a single object
            items: items,
            shortguid: outerShortguid,
            status: "C",
            totalamount: sumOfAmounts.toFixed(2),
            totalsgstamt: sumOfSgst.toFixed(2),
            totaligstamt: sumOfIgst.toFixed(2),
            totalcgstamt: sumOfCgst.toFixed(2),
            totaltaxableamount: sumOfAmounts.toFixed(2),
            amount: sumOfAmounts.toFixed(2),
          },
        };

        console.log("payload", payload);

        console.log("etlFormat", etlFormat);

        let apiurl = "";

        console.log("etlcode", etlcode)

        const matchingField = getFileds.find((field) => field.etltypecode === etlcode);
        console.log("matchingField", matchingField)
        if (matchingField) {
          apiurl = baseUrl + matchingField.apiurl + "/";
        }


        console.log("apiurl", apiurl)

        let { data: res } = await axios.post(apiurl, payload);

        console.log("res", res.Success)

        if (res.Success) {
          setPayloadData(res.Message);
          alert("Submitted !!!")
        }

      } catch (e) {
        // alert(e);
        console.log("Error:", e.response.data.Error);

        // const errorMessages = Object.entries(e.response.data.Error); // Convert the object values to an array

        // alert(errorMessages)

        setErrorMsg(e.response.data.Error);
      }
    }
    //singletable upload function
    else {
      console.log("singletable upload")
      const getColumnIndexFromCellLetter = (cellLetter) => {
        const letters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
        let index = 0;

        // Check if cellLetter is a multi-letter column name like AA, AB, AC, etc.
        if (cellLetter?.length > 1 && /^[A-Z]+$/.test(cellLetter)) {
          let columnLetters = cellLetter.split('').reverse(); // Reverse the letters for easier calculation

          // Calculate the index for multi-letter columns (AA, AB, AC, etc.)
          for (let i = 0; i < columnLetters?.length; i++) {
            index += Math.pow(letters.length, i) * (letters.indexOf(columnLetters[i]) + 1);
          }

          return index - 1; // Subtract 1 since the index starts from 0
        }

        // If cellLetter is a single letter like A, B, C, etc.
        if (cellLetter?.length === 1 && letters.includes(cellLetter)) {
          return letters.indexOf(cellLetter);
        }

        // If cellLetter does not match the above patterns
        return -1;
      };

      const payloadData = filedata.slice(1).map((row) => {
        return fields.reduce((obj, field) => {
          const keyname = field.keyname;
          const columnindex = getColumnIndexFromCellLetter(field.cellno);
          const value = row[columnindex];

          return { ...obj, [keyname]: value };
        }, {});
      });
      console.log("filedata", filedata);
      console.log("startrow", getFileds[0]);

      const items = filedata
        .slice(getFileds[0]?.startrow - 1)
        .map((row) => {

          const filteredFields = fields.filter((field) => {
            const cellno = field.cellno;
            const letters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";

            // Exclude keyname-value pair if cellno is in the format "A1", "B1", etc.
            if (cellno.length === 2 && letters.includes(cellno[0]) && !isNaN(parseInt(cellno[1]))) {
              return false;
            }

            return true;
          });



          console.log("filteredFields", filteredFields);
          return filteredFields.reduce((obj, field) => {
            const keyname = field.keyname;
            let value = '';
            console.log("keyname", keyname);


            console.log("row in excel", row);
            // Check if cellno is a single letter like A, B, C, etc.
            const letters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";

            if (/^[A-Z]+$/.test(field.cellno)) {
              console.log("cellno in excel", field.cellno);
              const columnindex = getColumnIndexFromCellLetter(field.cellno);
              console.log("columnindex in excel", columnindex);
              value = row[columnindex];
            }

            console.log("value in excel", value);

            // Check if value is a date string and needs formatting
            if (field.masking === 'yyyymmdd') {

              if (typeof value !== 'string') {
                value = value?.toString(); // Convert value to string
              }

              let day, month, year;

              console.log("includes", value.length)

              if (value.length === 6) {
                // Format: 020323
                day = value.substring(0, 2);
                month = value.substring(2, 4);
                year = value.substring(4);

                // Adjust the year to include the century
                year = parseInt(year);
                const currentYear = new Date().getFullYear() % 100;
                const currentCentury = Math.floor(new Date().getFullYear() / 100) * 100;
                year = (year > currentYear) ? (currentCentury - 100 + year) : (currentCentury + year);
              } else if (value.length === 8) {

                if (value.includes('/')) {
                  // Format: 02/03/23 or 02/03/2023
                  const dateParts = value.split('/');
                  console.log("dateParts", value);
                  day = dateParts[0];
                  month = dateParts[1];
                  year = dateParts[2].length === 2 ? '20' + dateParts[2] : dateParts[2];

                  console.log("dateParts", dateParts);

                } else if (value.includes('-')) {
                  // Format: 02-03-23 or 02-03-2023
                  const dateParts = value.split('-');
                  day = dateParts[0];
                  month = dateParts[1];
                  year = dateParts[2].length === 2 ? '20' + dateParts[2] : dateParts[2];
                }
                else {
                  // Format: 02032023
                  day = value.substring(0, 2);
                  month = value.substring(2, 4);
                  year = value.substring(4);
                }
              }
              else if (value.length === 10) {

                if (value.includes('/')) {
                  // Format: 02/03/23 or 02/03/2023
                  const dateParts = value.split('/');
                  console.log("dateParts2", value);
                  day = dateParts[0];
                  month = dateParts[1];
                  year = dateParts[2].length === 2 ? '20' + dateParts[2] : dateParts[2];

                  console.log("dateParts", dateParts);

                } else if (value.includes('-')) {
                  // Format: 02-03-23 or 02-03-2023
                  const dateParts = value.split('-');
                  day = dateParts[0];
                  month = dateParts[1];
                  year = dateParts[2].length === 2 ? '20' + dateParts[2] : dateParts[2];
                }
                else {
                  // Format: 02032023
                  day = value.substring(0, 2);
                  month = value.substring(2, 4);
                  year = value.substring(4);
                }
              }
              else if (value.length === 5) {
                // Format: 45170
                const excelDate = parseInt(value);
                const jsDate = new Date((excelDate - 25569) * 86400 * 1000);
                day = jsDate.getDate().toString().padStart(2, '0');
                month = (jsDate.getMonth() + 1).toString().padStart(2, '0');
                year = jsDate.getFullYear().toString();
              }

              // Format the date as "YYYYMMDD"
              value = year + month + day;
              console.log("value", value);
            }

            if (keyname === 'qty' && typeof value === 'string' && value.includes('+')) {
              const values = value.split('+');
              value = values[0].trim(); // Assign the first part before the '+'

              // Generate a new key for the second part after the '+'
              const newKeyname = 'free';
              obj[newKeyname] = values[1].trim(); // Assign the second part to the new key
            }

            if (keyname === 'qty') {
              const newKeyname = 'packingqty';
              obj[newKeyname] = value; // Assign the second part to the new key
            }




            // const qty = field.qty ? parseFloat(row[getColumnIndexFromCellLetter(field.qty)]) : 0;
            // const purrate = field.purrate ? parseFloat(row[getColumnIndexFromCellLetter(field.purrate)]) :0;
            // const discount = field.discount ? parseFloat(row[getColumnIndexFromCellLetter(field.discount)]) : 0;
            // console.log("purrate", qty,purrate,discount);

            if (field?.cellno === "" && field?.defaultvalue !== null) {

              value = field.defaultvalue;

              if (field.masking === 'yyyymmdd') {

                if (typeof value !== 'string') {
                  if (typeof value !== 'undefined' && value !== null) {
                    value = value?.toString(); // Convert value to string
                  } else {
                    value = ''; // Assign an empty string as the default value
                  }
                }

                let day, month, year;

                console.log("includes", value.length)

                if (value.length === 6) {
                  // Format: 020323
                  day = value.substring(0, 2);
                  month = value.substring(2, 4);
                  year = value.substring(4);

                  // Adjust the year to include the century
                  year = parseInt(year);
                  const currentYear = new Date().getFullYear() % 100;
                  const currentCentury = Math.floor(new Date().getFullYear() / 100) * 100;
                  year = (year > currentYear) ? (currentCentury - 100 + year) : (currentCentury + year);
                } else if (value.length === 8) {

                  if (value.includes('/')) {
                    // Format: 02/03/23 or 02/03/2023
                    const dateParts = value.split('/');
                    console.log("dateParts", value);
                    day = dateParts[0];
                    month = dateParts[1];
                    year = dateParts[2].length === 2 ? '20' + dateParts[2] : dateParts[2];

                    console.log("dateParts", dateParts);

                  } else if (value.includes('-')) {
                    // Format: 02-03-23 or 02-03-2023
                    const dateParts = value.split('-');
                    day = dateParts[0];
                    month = dateParts[1];
                    year = dateParts[2].length === 2 ? '20' + dateParts[2] : dateParts[2];
                  }
                  else {
                    // Format: 02032023
                    day = value.substring(0, 2);
                    month = value.substring(2, 4);
                    year = value.substring(4);
                  }
                }
                else if (value.length === 10) {

                  if (value.includes('/')) {
                    // Format: 02/03/23 or 02/03/2023
                    const dateParts = value.split('/');
                    console.log("dateParts2", value);
                    day = dateParts[0];
                    month = dateParts[1];
                    year = dateParts[2].length === 2 ? '20' + dateParts[2] : dateParts[2];

                    console.log("dateParts", dateParts);

                  } else if (value.includes('-')) {
                    // Format: 02-03-23 or 02-03-2023
                    const dateParts = value.split('-');
                    day = dateParts[0];
                    month = dateParts[1];
                    year = dateParts[2].length === 2 ? '20' + dateParts[2] : dateParts[2];
                  }
                  else {
                    // Format: 02032023
                    day = value.substring(0, 2);
                    month = value.substring(2, 4);
                    year = value.substring(4);
                  }
                }
                else if (value.length === 5) {
                  // Format: 45170
                  const excelDate = parseInt(value);
                  const jsDate = new Date((excelDate - 25569) * 86400 * 1000);
                  day = jsDate.getDate().toString().padStart(2, '0');
                  month = (jsDate.getMonth() + 1).toString().padStart(2, '0');
                  year = jsDate.getFullYear().toString();
                }

                // Format the date as "YYYYMMDD"
                value = year + month + day;
                console.log("value", value);
              }

            }


            if (keyname == 'amount') {
              const taxableAmount = parseFloat(row[getColumnIndexFromCellLetter(field.cellno)])
              console.log("taxableAmount", taxableAmount);
              const newKeyname = 'taxableamount';
              obj[newKeyname] = taxableAmount.toFixed(2); // Assign the taxable amount to the new key
            }





            return { ...obj, [keyname]: value };
          }, {});
        });


      console.log("payloadData", items);
      console.log("getFileds", getFileds);



      try {
        let payload = {
          tenantrecno: auth?.state?.userdata?.tenantrecno,
          domainrecno: selectedDomain,

          data: items,
        };

        console.log("payload", payload);

        console.log("etlFormat", etlFormat);

        let apiurl = "";

        console.log("etlcode", etlcode)

        // if (etlcode === 7) {
        //   apiurl = "http://bizzcontrol.in/backend/api/v1/adddomainsfrometl/";
        // } else if (etlcode === 1) {
        //   apiurl = "http://bizzcontrol.in/backend/api/v1/addtenantitemsfrometl/";
        // }

        console.log("getFileds", getFileds)

        const matchingField = getFileds.find((field) => field.etltypecode === etlcode);
        console.log("matchingField", matchingField)
        if (matchingField) {
          apiurl = baseUrl + matchingField.apiurl + "/";
        }

        console.log("apiurl", apiurl)


        console.log("apiurl", apiurl)

        let { data: res } = await axios.post(apiurl, payload);

        console.log("ETL Master Res:", res);

        if (res.Success) {
          setPayloadData(res.Message);
          alert("Submitted !!!")
        }
      } catch (e) {
        alert(e);
        console.log("Error:", e);
      }

    }

  }

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  // const handleEditClick = (index, cell) => {
  //   setEditedRowData(filedata[index + 1]);
  //   setEditedCell(cell);
  //   showModal();
  // };

  const handleInputChange = (id, field, value, e, rowId, key) => {
    const updatedData1 = filedata.map((row) =>
      row.id === id ? { ...row, [field]: value } : row
    );
    setupdate(updatedData1);
    console.log("datafile11", updatedData1);

    const updatedData = filedata.map((row) => {
      if (row.id === rowId) {
        return { ...row, [key]: e.target.value };
      }
      return row;
    });
    setFiledata(updatedData);
  };

  const handleEditCell = (cell) => {
    setEditedCell(cell);
  };

  const handleCellChange = (e, rowId, key) => {
    const updatedData = filedata.map((row) => {
      if (row.id === rowId) {
        return { ...row, [key]: e.target.value };
      }
      return row;
    });
    setFiledata(updatedData);
  };

  const handleSaveCell = () => {
    setEditedCell(null);
  };

  useEffect(() => {
    fieldsforETL();
  }, []);



  const handleBackClick = () => {
    console.log("clicked");
    // setshowAddFormat(true)
  };

  //fetch domain list
  useEffect(() => {
    let body = {
      tenantrecno: auth?.state?.userdata?.tenantrecno,

    };


    let apiurl = baseUrl + "getdomainlist/";

    const fetchDomainList = async () => {
      try {
        const response = await axios.post(apiurl, body);
        console.log("response", response.data);
        setDomainOptions(response.data);
      } catch (error) {
        console.log("error", error);
      }
    };

    fetchDomainList();
  }, []);

  useEffect(() => {
    setSelectedDomain(domainOptions?.Message[0]?.recno);

    const selectedOption = domainOptions.Message.find((item) => item.recno === domainOptions?.Message[0]?.recno)
    console.log("selectedOption", selectedOption);
    const selectedDescn = selectedOption ? selectedOption.descn : ''
    console.log("selectedDescn", selectedDescn);
    setDomainDescn(selectedDescn)
  }, [domainOptions]);

  const setDomain = (value) => {
    setSelectedDomain(value);
    const selectedOption = domainOptions.Message.find((item) => item.recno === value)
    console.log("selectedOption", selectedOption);
    const selectedDescn = selectedOption ? selectedOption.descn : ''
    console.log("selectedDescn", selectedDescn);
    setDomainDescn(selectedDescn)

  };

  console.log("domainOptions", domainOptions);
  console.log("selectedDomain", selectedDomain);

  const setTableTypeFunc = (value) => {
    settableType(value)
    if (value === 'single') {
      setShowSingleTable(true)
    } else {
      setShowSingleTable(false)
    }
  }
  const closeError = () => {
    setErrorMsg("")
  }

  return (
    <div >

      {/* <div
        style={{
          marginTop: "2%", marginLeft: "2%"
        }}
      >
        <label style={{ fontWeight: "bold" }}>Select Table Type</label>
        <Select
          style={{ width: "10%", height: 40, marginLeft: "2%" }}
          placeholder="Select For Format"
          value={tableType}
          onChange={setTableTypeFunc}
        >
          <option key={1} value={"single"}>{"Single Table"}</option>
          <option key={2} value={"mutiple"}>{"Mutiple Table"}</option>
        </Select>
      </div> */}

      <div>

        <div style={{ margin: "1%" }}>
          {/* <div className="etltool_back">
          <CustomButton type="back" text="back" clickHandler={handleBackClick} />
        </div>*/}
          {/* <h3 style={{ textAlign: "center" }}>Validate format & upload data</h3> */}
          <div
              style={{
                marginTop: "1%",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between", // Updated justifyContent property
              }}
            >
            <div
              style={{ flex: 0.2 }}
            >
              <label style={{ fontWeight: "bold" }}>Business Unit</label>
              <Select
                style={{ width: "100%", height: 40,marginTop:"4%" }}
                placeholder="Domain"
                value={domainOptions?.Message[0]?.recno}
                onChange={setDomain}
              >
                {domainOptions.Message.map((item) => {
                  return (
                    <option key={item.recno} value={item.recno}>
                      {item.descn}
                    </option>
                  );
                })}
              </Select>
            </div>
            <div style={{ flex: 0.2 }}>
              <label style={{ fontWeight: "bold" }}>ETL Type*</label>
              <Select
                style={{ width: "100%", height: 40,marginTop:"4%" }}
                placeholder="ETL Type"
                onChange={fieldsforETLFormat}
                value={etlFormat}
              >
                {fieldsArr
                  .reduce((uniqueOptions, f) => {
                    // Check if the current etltypecodedescn value already exists in the uniqueOptions array
                    const isDuplicate = uniqueOptions.some(
                      (option) =>
                        option.value === f.etltypecode &&
                        option.label === f.etltypecodedescn
                    );
                    if (!isDuplicate) {
                      // If it's not a duplicate, add it to the uniqueOptions array
                      uniqueOptions.push({
                        value: f.etltypecode,
                        label: f.etltypecodedescn,
                      });
                    }
                    return uniqueOptions;
                  }, [])
                  .map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
              </Select>
            </div>

            <div
          style={{ flex: 0.2 }}
            >
              <label style={{ fontWeight: "bold" }}>ETL Format*</label>
              <Select
                style={{ width: "100%", height: 40,marginTop:"4%" }}
                placeholder="ETL Format"
                value={etlDescn}
                onChange={funcFields}
              >
                {fieldsArrByFormat &&
                  fieldsArrByFormat.Message &&
                  fieldsArrByFormat.Message.map((item) => (
                    <option key={item.recno} value={item.recno}>
                      {item.descn}
                    </option>
                  ))}
              </Select>
            </div>

            <div  style={{ flex: 0.2 }}>
              {filename && (
                <p>
                  FileName :<span>{filename}</span>
                </p>
              )}
              <input
                type="file"
                accept=".csv,.xlsx,.xls"
                className="file"
                onChange={uploadFile}
                ref={fileInputref}
                style={{ display: "inline-block" }}
              />
            </div>
            <Button type="primary" onClick={refreshFunc}>
              Refresh
            </Button>
            <Button type="primary" onClick={uploadData}>
              Upload Data
            </Button>

            {/* <Button type="primary" onClick={validate}>
              validate
            </Button> */}
          </div>
          {errorMsg && (
            <Card

              title="Error"
              extra={<a onClick={closeError}>Close</a>}
              style={{
                width: "100%",
                height: "148px", // Set the desired height for the Card component
                overflow: "auto", float: "right", border: "1px solid red"
              }}
            >
              <p>{errorMsg}</p>
            </Card>
          )}


          {getFileds
            // .filter((item) => item.descn == etlDescn)
            .map((fr) => {
              return (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    flexWrap: "wrap",
                    flexDirection: "row",
                    alignItems: "center",
                    alignContent: "center",
                    padding: "1vw",
                    width: "100vw",
                  }}
                >
                  <h3>Compulsory Fields:</h3>

                  {fr?.compulsoryfields.map((f) => {
                    return (
                      <div
                        style={{
                          borderStyle: "dashed",
                          borderWidth: 0.6,
                          display: "flex",
                          flex: 1,
                          marginLeft: "1%",
                          justifyContent: "center",
                          elevation: "above",
                          margin: "4px",
                          flexWrap: "wrap",
                          flexDirection: "row",
                          paddingLeft: "1%",
                          paddingRight: "1%",
                          alignItems: "center",
                          alignContent: "center",
                          padding: "8px",
                        }}
                      >
                        <table
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            flexWrap: "wrap",
                            flexDirection: "column",
                            alignItems: "center",
                            alignContent: "center",
                            backgroundColor: "lightsalmon",
                            color: "white",
                          }}
                        >
                          <tr key={f.fieldname}>
                            <thead>{f.fieldname}</thead>
                          </tr>
                        </table>
                      </div>
                    );
                  })}
                  {fr?.fields
                  .filter(field => field.cellno !== "")
                  .map((f) => {
                    return (
                      <div
                        style={{
                          borderStyle: "dashed",
                          borderWidth: 0.6,
                          display: "flex",
                          flex: 1,
                          marginLeft: "1%",
                          justifyContent: "center",
                          elevation: "above",
                          margin: "4px",
                          flexWrap: "wrap",
                          flexDirection: "row",
                          paddingLeft: "1%",
                          paddingRight: "1%",
                          alignItems: "center",
                          alignContent: "center",
                          padding: "5px",
                        }}
                      >
                        <table
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            flexWrap: "wrap",
                            flexDirection: "column",
                            alignItems: "center",
                            alignContent: "center",
                          }}
                        >
                          <tr key={f.keyname}>
                            <thead>
                              {f.keyname}
                              <p>{f.cellno}</p>
                            </thead>
                          </tr>
                        </table>
                      </div>
                    );
                  })}
                </div>
              );
            })}
          {/* {filedata.map((row) => (
        <tr key={row.id}>
          <td>
            {editedCell === `${row.id}-name` ? (
              <input type='text' value={row.name} onChange={(e) => handleCellChange(e, row.id, 'name')} />
            ) : (
              row.name
            )}
          </td>
          <td>
            {editedCell === `${row.id}-age` ? (
              <input type='text' value={row.age} onChange={(e) => handleCellChange(e, row.id, 'age')} />
            ) : (
              row.age
            )}
          </td>
          <td>
            {editedCell === `${row.id}-name` || editedCell === `${row.id}-age` ? (
              <button onClick={handleSaveCell}>Save</button>
            ) : (
              <button onClick={() => handleEditCell(`${row.id}-name`)}>Edit</button>
            )}
          </td>
        </tr>
      ))} */}

          {filedata[0] &&
            <table className="" border={1} style={{ width: "100%" }}>
              {/* <thead>
          <tr>
            {filedata[0] &&
              filedata[0]?.map((cell, i) => (
                <TableCell
                  style={{ backgroundColor: "lightgray", textAlign: "center" }}
                  key={i}
                >
                  {cell}
                </TableCell>
              ))}
          </tr>
        </thead> */}

              <tbody>
                {filedata?.slice(0, getFileds[0]?.startrow - 2).map((row, i) => {
                  return (
                    <tr key={i}>
                      {row?.map((cell, j) => {
                        const field = fields[j]; // Assuming fields array corresponds to the columns in the Excel data

                        const bgColor =
                          validationResultHeader[i] && !validationResultHeader[i][j]
                            ? "yellow"
                            : "";
                        return (
                          <TableCell
                            style={{ textAlign: "center", background: bgColor }}
                            key={j}
                          >
                            {editMode ? (
                              <input
                                type="text"
                                value={data}
                                onChange={(e) => setData(e.target.value)}
                              />
                            ) : (
                              <div>{cell}</div>
                            )}
                          </TableCell>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          }
          {filedata[0] && <div style={{ overflow: "auto", width: "100%", height: "370px" }}>
            <table className="" border={1} style={{ width: "100%" }}>
              {/* <thead>
    <tr>
      {filedata?.slice(getFileds[0]?.startrow - 2, getFileds[0]?.startrow - 1).map((cell, i) => (
        <th
          style={{ backgroundColor: "lightgray", textAlign: "center" }}
          key={i}
        >
          {cell}
        </th>
      ))}
    </tr>
  </thead> */}

              <thead style={{ position: "sticky", top: 0 }}>
                {filedata?.slice(getFileds[0]?.startrow - 2, getFileds[0]?.startrow - 1).map((row, i) => {
                  return (
                    <tr key={i}>
                      {row?.map((cell, j) => {

                        return (
                          <th
                            style={{ textAlign: "center", background: bgColor }}
                            key={j}
                          >
                            {cell}
                          </th>
                        );
                      })}
                    </tr>
                  );
                })}
              </thead>

              <tbody>
                {filedata?.slice(getFileds[0]?.startrow - 1).map((row, i) => {
                  return (
                    <tr key={i}>
                      {row?.map((cell, j) => {
                        const field = fields[j]; // Assuming fields array corresponds to the columns in the Excel data

                        const bgColor =
                          validationResult[i] && !validationResult[i][j]
                            ? "yellow"
                            : "";
                        return (
                          <td
                            style={{ textAlign: "center", background: bgColor }}
                            key={j}
                          >
                            <div>{cell}</div>

                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          }




        </div>

        {/* {showSingleTable ? (
            <ValidData />
          ) : null
          } */}

      </div>


    </div>
  );
};

import React, { useContext, useEffect, useState } from "react";
import { TabStore } from "../../TabsStructure/TabsStore";
import { getDomainLocationUrl } from "../../CommonControllers/Urls";
import { AuthStore } from "../../Auth/AuthStore";
import ExecuteDropdown from "../../CommonControllers/Components/ExecuteComponents/ExecuteDropdown";
import { currentTabData } from "../../CommonControllers/Variable";
import {
  getLocationItemListApi,
  getfastpathapi,
} from "../../CommonControllers/ApiCalls";
import FilterComponents from "../../CommonControllers/FiterComponent/FilterComponents";
import { Tooltip, List, Avatar } from "antd";

import { Stage, Layer, Rect, Circle, Group, Text } from "react-konva";
import { Rnd } from "react-rnd";
import { dateFormatter } from "../../CommonControllers/AppFunction";
import Konva from "konva";

function Visualization() {
  const colors = [
    // "#FF5733", // Living Room
    // "#3498DB", // Bedroom
    // "#27AE60", // Kitchen
    // "#9B59B6", // Bathroom
    // "#E74C3C", // Dining Room
    // "#1ABC9C", // Study Room
    // "#F39C12", // Guest Room
    // "#2C3E50", // Balcony
    // "#95A5A6", // Laundry Room
    // "#D35400", // Nursery
    "#D98880", // Living Room
    "#85C1E9", // Bedroom
    "#73C6B6", // Kitchen
    "#D7BDE2", // Bathroom
    "#F0B27A", // Dining Room
    "#82E0AA", // Study Room
    "#FAD7A0", // Guest Room
    "#AAB7B8", // Balcony
    "#BFC9CA", // Laundry Room
    "#F5B041", // Nursery
  ];

  const [scale, setScale] = useState(2);
  const [locationInfo, setLocatinInfo] = useState([]);
  const tabStore = useContext(TabStore);
  const auth = useContext(AuthStore);
  const [subLocationData, setSubLocationData] = useState([]);

  const [pageIndex, setPageIndex] = useState();

  useEffect(() => {
    var tabsArray = tabStore.state.tabs;
    for (let i = 0; i < tabsArray?.length; i++) {
      if (tabsArray[i].name == "WMS1") {
        setPageIndex(i);
        break;
      }
    }
  }, []);

  useEffect(() => {
    setLocatinInfo(tabStore?.state?.tabs[pageIndex]?.apiResponse);
  }, [tabStore?.state?.tabs[pageIndex]?.apiResponse, pageIndex]);

  const getDomainLocationItemList = async (location) => {
    // API Call to get the Response
    // Payload Required domainrecno, domainlocationrecno, locationgroupcode

    const resp = await getLocationItemListApi({
      locationgroupcode: location.code,
      // domainlocationgroupcode: location.code,
      // domainrecno: 106251,
      domainrecno: location.domainrecno,
      domainlocationcode: location.domainlocationcode,
      tenantrecno: auth.state.userdata.tenantrecno,
    });

    setSubLocationData(resp);
  };
  return (
    <div>
      <FilterComponents />
      <div style={{ display: "flex" }}>
        <div
          style={{
            display: "flex",
            flex: 0.7,
            height: "70vh",
            overflowX: "scroll",
            overflowY: "scroll",
          }}
        >
          <Stage
            // width={(window.innerWidth * 70) / 100}
            height={(window.innerHeight * 70) / 100}
            width={window.innerWidth}
            // height={(window.innerHeight)}
            // scaleX={1}
            // scaleY={1}
          >
            <Layer>
              <Rect
                // width={(window.innerWidth * 70) / 100}
                // height={(window.innerHeight * 70) / 100}
                x={0}
                y={0}
                stroke="black"
              ></Rect>
              {locationInfo?.Message?.map((item, index) => {
                return (
                  <Group>
                    <Rect
                      fill={
                        colors.length >= index
                          ? colors[index]
                          : colors[index - colors.length]
                      }
                      shadowBlur={5}
                      x={item?.xcord}
                      y={item?.ycord}
                      height={item?.length}
                      width={item?.width}
                      stroke={"black"}
                      onClick={() => {
                        getDomainLocationItemList(item);
                      }}
                    ></Rect>
                    <Text
                      align="center"
                      verticalAlign="middle"
                      text={item?.descn}
                      x={item?.xcord}
                      wrap="word"
                      onClick={() => {
                        getDomainLocationItemList(item);
                      }}
                      y={item?.ycord}
                      height={item?.length}
                      width={item?.width}
                    />
                  </Group>
                );
              })}
            </Layer>
          </Stage>
        </div>
        {/* {locationInfo?.Message?.map((item, index) => {
          return (
            <Rnd
              // index used to uniquely identify each box

              key={index}
              position={{ x: item?.xcord, y: item?.ycord }}
              // called when user stops dragging the box
              size={{
                height: item?.length,
                width: item?.width,
              }}
            >
              

              <div className="rectangle-box">
                <h3>{item?.title}</h3>

                <div>ID: {item.id}</div>
              </div>
            </Rnd>
          );
        })} */}
        <div style={{ flex: 0.3, padding: "10px" }}>
          <List
            itemLayout="horizontal"
            dataSource={subLocationData?.Message}
            renderItem={(item, index) => (
              <List.Item>
                <List.Item.Meta
                  avatar={
                    <Avatar
                      src={`https://xsgames.co/randomusers/avatar.php?g=pixel&key=${index}`}
                    />
                  }
                  title={
                    <a>
                      {item?.itemdescn} &nbsp; &nbsp; | &nbsp; &nbsp;
                      Sub-Location : {item?.domainsublocationcodedescn}{" "}
                    </a>
                  }
                  description={
                    <p>
                      Quantity : {item?.qty} &nbsp; | &nbsp; Batch :{" "}
                      {item?.itembatchno} <br /> Exp Date :{" "}
                      {dateFormatter(item?.expdate)} &nbsp;|&nbsp; Manu Date :{" "}
                      {dateFormatter(item?.manudate)}
                    </p>
                  }
                />
              </List.Item>
            )}
          />
        </div>
      </div>
    </div>
  );
}

export default Visualization;

{
  /* <Stage
width={(window.innerWidth * 70) / 100}
height={(window.innerHeight * 70) / 100}
>
<Layer>
  <Rect
    width={(window.innerWidth * 70) / 100}
    height={(window.innerHeight * 70) / 100}
    x={0}
    y={0}
    stroke="black"
  ></Rect>
  {locationInfo?.Message?.map((item) => {
    return (
    <Group>

      <Rect
        x={item?.xcord}
        y={item?.ycord}
        height={item?.length * scale}
        width={item?.width * scale}
        stroke={"black"}
        onClick={()=>alert("Helo")}
      >
      </Rect>
        <Text text={item?.descn}  x={item?.xcord}
        y={item?.ycord}/>
    </Group>

    
    );
  })}
</Layer>
</Stage> */
}

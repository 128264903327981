import { authStoreInitialState } from "../../../Auth/AuthStore";

/**
 * Transfer Data received from API Call to the State Variables
 * @param {
 * } pendinginstruments_array
 *
 *
 */
export const transformPendingInsutrments = (pendinginstruments_array) => {
  const _instr = pendinginstruments_array.map((item) => ({
    accountcode: item.accountcode,
    recno: item.recno,
    refno: item.refno,
    cramt: item.cramt,
    dramt: item.dramt,
    pendingamt: item.pendingamt,
    othergl: 0,
    othergl_total: 0,
    on_account_amount: 0,
    childrows: [],
    refno_balance: 0,
    refdate: item.refdate,
  }));
  return _instr;
};

/** Clear Auto Adjusted Amount , set amount to zero
 *  20-JAN-2023
 *  @param - pending insturment array
 */
export const clearAutoAdjustements = (pendinginstruments_array) => {
  let temp_pending_array = [];

  if (pendinginstruments_array.length > 0) {
    pendinginstruments_array.forEach((item) => {
      item.on_account_amount = 0;

      temp_pending_array.push(item);
    });
  }
  return temp_pending_array;
};

export const generateAccountingEntryPayLoad_TabStore = (
  changedRows,
  domainrecno,
  tenantrecno,
  trntype
) => {
  // console.log("generateAccountingEntryPayLoad");
  //console.log(state);
  //console.log(auth);

  let payload = {
    domainrecno: domainrecno,
    tenantrecno: tenantrecno,
    trdate: changedRows?.voucherdate, //state.selected_date,
    items: [],
    particular: changedRows?.narration,
  };

  if (changedRows?.accounting_vouchers?.length > 0) {
    changedRows?.accounting_vouchers?.forEach((element) => {
      if (element.code != "TOTAL") {
        let amount = 0;

        if (element.CDFlag == CREDIT) {
          amount = element.Credit;
        } else {
          amount = element.Debit;
        }

        let item = {
          trdate: changedRows?.voucherdate,
          code: element.code,
          cdflag: element.CDFlag,
          amount: amount,
          ctflag: 2, // ALL Entries Transfer
          trntype: trntype,
          particular: changedRows?.narration,
          billingdetails: [],
        };

        if (item.code == changedRows?.gl_account_1?.code) {
          const billingdetails = generateBillingdetails(
            changedRows?.pending_instruments,
            changedRows?.gl_account_1?.code
          );
          item.billingdetails = billingdetails;
        }

        payload.items.push(item);
      }
    });
  }
  //console.log(payload);
  return payload;
};

export const generateAccountingEntryPayLoad = (state) => {
  // console.log("generateAccountingEntryPayLoad");
  //console.log(state);
  //console.log(auth);

  let payload = {
    domainrecno: 0,
    tenantrecno: 0,
    trdate: state.selected_date,
    items: [],
  };

  if (state.accounting_vouchers.length > 0) {
    state.accounting_vouchers.forEach((element) => {
      if (element.code != "TOTAL") {
        let amount = 0;

        if (element.CDFlag == CREDIT) {
          amount = element.Credit;
        } else {
          amount = element.Debit;
        }

        let item = {
          trdate: state.selected_date,
          code: element.code,
          cdflag: element.CDFlag,
          amount: amount,
          ctflag: 2, // ALL Entries Transfer
          trntype: 0,
          particular: state.particulars,
          billingdetails: [],
        };

        if (item.code == state.gl_account_1.code) {
          const billingdetails = generateBillingdetails(
            state.pending_instruments,
            state.gl_account_1.code
          );
          item.billingdetails = billingdetails;
        }

        payload.items.push(item);
      }
    });
  }
  //console.log(payload);
  return payload;
};

function generateBillingdetails(pending_instruments, account_code) {
  let billingdetails = [];
  if (pending_instruments?.length > 0) {
    pending_instruments?.forEach((bill) => {
      // console.log("bill");
      // console.log(bill);

      if (bill.on_account_amount > 0) {
        let billitem = {
          recno: bill.recno,
          refno: bill.refno,
          code: account_code,
          amount: bill.on_account_amount,
          discountdetails: [],
        };

        bill.childrows.forEach((discount) => {
          let discountitem = {
            code: discount.code,
            amount: discount.amount,
          };
          billitem.discountdetails.push(discountitem);
        });

        billingdetails.push(billitem);
      }
    });
  }
  return billingdetails;
}

// const used in Receipt and Payment screens TR021 and TR022

export const VOUCHER_TYPE_RECEIPT = 1;
export const VOUCHER_TYPE_PAYMENT = 2;
export const CREDIT = 1;
export const DEBIT = 2;

// transaction types
// commonmaster where method  = 'ACTRNTYPE';

export const VOUCHER_RECEIPT = 1;
export const VOUCHER_PAYMENT = 2;
export const VOUCHER_JV = 3;

export const VOUCHER_CREDITNOTE = 6;
export const VOUCHER_DEBITNOTE = 7;

export class Voucher_Account {
  constructor(code, glcode, glname, accountcode, accountname, gldetailtype) {
    this.code = code;
    this.glcode = glcode;
    this.glname = glname;
    this.accountcode = accountcode;
    this.accountname = accountname;
    this.gldetailtype = gldetailtype;
  }
}

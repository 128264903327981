import React, { useContext, useEffect, useState } from "react";
import { Button, Tooltip } from "antd";
import { UnorderedListOutlined } from "@ant-design/icons";
import { TabStore } from "../../../../TabsStructure/TabsStore";
import { currentTabData } from "../../../../CommonControllers/Variable";
import WMSLog from "./WMSLog";
import { isArray } from "../../../AppFunction";
import noData from "../../../../Assets/noData.png";

const logComponentFrame = {
  width: "fit-content",
  margin: "auto",
};

const WMSLogComponent = ({
  data,
  columns,
  itemsColumn,
  attributeColumn,
  setshowFilterModal,
  showFilterModal,
  statusC,
  setstatusC,
  forFlag,
  dontEdit,
  pageDetails,
}) => {
  const [showAllItems, setShowAllItems] = useState(false);
  const tabs = useContext(TabStore);
  const [data1, setData1] = useState([]);

  const [modified, setModified] = useState([]);

  const handleShowAllItems = () => {
    setShowAllItems((prev) => !prev);
  };
  useEffect(() => {
    setModified(columns.filter((it) => it.isview));
  }, []);

  return (
    <>
      {isArray(data) ? (
        <div style={{ ...logComponentFrame }}>
          <div>
            <Tooltip
              placement="top"
              color="#1677ff"
              title={showAllItems ? "Collapse All" : "Expand All"}
            >
              <Button
                type="primary"
                size="large"
                shape="circle"
                onClick={handleShowAllItems}
                icon={<UnorderedListOutlined />}
              />
            </Tooltip>
            <Button style={{ marginLeft: "5px" }}>
              Total Records : {data?.length}{" "}
            </Button>
          </div>
          <div>
            {data?.map((elem, i) => (
              <WMSLog
                dontEdit={dontEdit}
                key={i}
                logData={elem}
                showAllItems={showAllItems}
                columns={modified}
                itemsColumn={itemsColumn}
                attributeColumn={attributeColumn}
                setshowFilterModal={setshowFilterModal}
                showFilterModal={showFilterModal}
                statusC={statusC}
                setstatusC={setstatusC}
                forFlag={forFlag}
                // setRowData={setRowData}
                pageDetails={pageDetails}
              />
            ))}
          </div>
        </div>
      ) : (
        <div className="noDataFound">
          <h1>Oops! No Records Found</h1>
          <img src={noData} />
        </div>
      )}
    </>
  );
};

export default WMSLogComponent;

import { Checkbox } from "antd";
import React, { useEffect, useState } from "react";
import useExecute from "../../Hooks/useExecute";

function CallbackCheckbox({
  editable,
  returnValue,
  displayLabel,
  apikey,
  defaultValue,
}) {
  const [checked, setChecked] = useState(defaultValue);

  useEffect(() => {
    if (defaultValue) {
      setChecked(true);
    } else {
      setChecked(false);
    }
  }, [defaultValue]);

  const onChange = () => {
    const updatedChecked = !checked;
    setChecked(updatedChecked);
    if (returnValue) {
      returnValue(updatedChecked);
    }
  };

 // console.log("Callback checkbox", checked, defaultValue);

  return (
    <div>
      {editable ? (
        <Checkbox onChange={onChange} checked={checked}>
          {displayLabel}
        </Checkbox>
      ) : (
        <Checkbox checked={checked}>{displayLabel}</Checkbox>
      )}
    </div>
  );
}

export default CallbackCheckbox;


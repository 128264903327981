import React, { useState } from "react";

function VerifyJsonResponses() {
  function validate() {
    var jsonObj1 = document.getElementById("1").value;
    var jsonObj2 = document.getElementById("2").value;
    var dif = compareJSONObjects(jsonObj1, jsonObj2);
    setDiffe(dif);
  }

  const [diffe, setDiffe] = useState([]);

  // Function to validate and verify two json objects which might contain array, If array is found loop through the array to verify and validate the objects within the array. and return with the defferences found in the both

  // A Function to verify tweo json objects, in the json object check for arrays, if array is found loop through the array to verify and validate the objects within the array. and return with the defferences found in the both
  function compareJSONObjects(jsonObject1, jsonObject2) {
    let differences = [];

    // here we firstly convert string to json object

    const jsonObject = JSON.parse(jsonObject1);
    // Check for properties in jsonObject1 not present in jsonObject2
    Object.keys(jsonObject).forEach((key1) => {
      console.log("key1", key1, typeof jsonObject);
      if (!jsonObject2.hasOwnProperty(key1)) {
        differences.push({ type: "Missing in Second Object", key: key1 });
      } else if (jsonObject[key1] !== jsonObject2[key1]) {
        differences.push({
          type: "Different value",
          key: key1,
          value1: jsonObject[key1],
          value2: jsonObject2[key1],
        });
      }
    });

    // Check for properties in jsonObject2 not present in jsonObject1
    Object.keys(jsonObject2).forEach((key2) => {
      if (!jsonObject1.hasOwnProperty(key2)) {
        differences.push({ type: "Missing in First Object", key: key2 });
      }
    });
    console.log("DIffferences", jsonObject1, jsonObject2, differences);
    return differences;
  }

  return (
    <div>
      <div style={{ display: "flex" }}>
        <textarea id="1"></textarea>

        <textarea id="2"></textarea>
      </div>

      <button
        onClick={(e) => {
          validate();
        }}
      >
        {" "}
        Validate
      </button>

      <div>
        {diffe?.map((item) => {
          return <div>{item}</div>;
        })}
      </div>
    </div>
  );
}

export default VerifyJsonResponses;

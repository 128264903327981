import axios from "axios";
import React, { useState, useEffect, useContext } from "react";
import { AuthStore } from "../../Auth/AuthStore";
import { getDomainPara } from "../../CommonControllers/Urls";
import style from "./domainpara.module.css";
import CheckBoxFun from "../../CommonControllers/Components/CustomCheckBox";
import InputComponent from "../../CommonControllers/Components/InputComponent";
import { TabStore } from "../../TabsStructure/TabsStore";
import { updateAddArray } from "../../CommonControllers/AppFunction";
import SaveTextInput from "../../CommonControllers/Components/SaveComponents/SaveTextInput";
import SaveCheckbox from "../../CommonControllers/Components/SaveComponents/SaveCheckbox";
import useExecuteApi from "../../CommonControllers/Hooks/useExecuteApi";
import SaveDatePicker from "../../CommonControllers/SaveComponents/SaveDatePicker";
import { v4 as uuidv4 } from "uuid";
import SaveDropdown from "../../CommonControllers/Components/SaveComponents/SaveDropdown";
import { Spin } from "antd";
import { currentTabData } from "../../CommonControllers/Variable";

const DomainPara = ({ fastPath }) => {
  // State for data
  const [data, setData] = useState([]);
  const [val, setVal] = useState([]);
  const [pageIndex, setPageIndex] = useState();
  const execute = useExecuteApi();
  const tabstore = useContext(TabStore);
  const auth = useContext(AuthStore);

  // Determine the page index based on fastPath
  useEffect(() => {
    const tabsArray = tabstore.state.tabs;
    for (let i = 0; i < tabsArray?.length; i++) {
      if (tabsArray[i].name === fastPath) {
        setPageIndex(i);
        break;
      }
    }
  }, [fastPath, tabstore.state.tabs]);

  // Fetch data if not already present
  useEffect(() => {
    if (!tabstore.state.tabs[fastPath]?.apiResponse &&
      tabstore.state.tabs[fastPath]?.callapipageload) {
      getCommonMasterList();
    }
  }, [pageIndex, tabstore.state.tabs, fastPath]);

  // Fetch common master list (API call)
  async function getCommonMasterList() {
    await execute.execute();
  }

  // Update state with API response
  useEffect(() => {
    const resp = tabstore.state.tabs[pageIndex]?.apiResponse;
    if (resp) {
      setData(resp?.columnsinfo);
      setVal(resp?.Message[0]);
    }
  }, [tabstore.state.tabs[pageIndex]?.apiResponse, pageIndex]);

  // Call reseFun after data and tabstore updates
  useEffect(() => {
    if (tabstore.state.tabs[pageIndex]?.apiResponse) {
      reseFun();
    }
  }, [tabstore.state.tabs[pageIndex]?.apiResponse, pageIndex]);

  // Function to reset state and dispatch updates
  const reseFun = () => {
    let x = tabstore.state.tabs[pageIndex];
    if (currentTabData(tabstore)?.name === "RP117") {
      tabstore.dispatch({
        type: "UPDATE_DATA",
        fieldType: "excelUpdate",
        text: true,
      });
    }
    tabstore.dispatch({ type: "UPDATE_DATA", fieldType: "apiVariables", text: {} });
    tabstore.dispatch({ type: "UPDATE_DATA", fieldType: "changedRows", text: {} });
    tabstore.dispatch({
      type: "UPDATE_DATA",
      fieldType: "pageStatus",
      text: "Success",
    });
  };

  // Render component
  return (
    <div style={{ marginLeft: "15%" }}>
      <table className={style.customers}>
        <thead style={{ borderRadius: "10px" }}>
          <tr>
            <th>Title</th>
            <th>Type</th>
          </tr>
        </thead>
        {
          tabstore.state.tabs[tabstore.state.tabDataIndex]?.executeLoading === true ||
          tabstore.state.tabs[tabstore.state.tabDataIndex]?.saveLoading === true ? (
            <div style={{ ...loaderStyle }}>
              <Spin size="large" />
            </div>
          ) : (
            <tbody>
              {data?.map((i, ind) => {
                return (
                  <tr className={style.divTr} key={ind}>
                    <td>{i?.title}</td>
                    <td>
                      {i?.columnname === "domainrecno" ? (
                        <SaveTextInput
                          pageIndex={pageIndex}
                          type="number"
                          id={`idnumber${ind}`}
                          apikey={i?.apiKey}
                          change
                          defaultValue={val[i?.dataIndex]}
                        />
                      ) : i.type === "boolean" ? (
                        <SaveCheckbox
                          apikey={i.apiKey}
                          defaultValue={val[i?.dataIndex]}
                          editable
                        />
                      ) : i.type === "int" ? (
                        <SaveTextInput
                          pageIndex={pageIndex}
                          type="number"
                          id={`idnumber${ind}`}
                          apikey={i?.apiKey}
                          change
                          defaultValue={val[i?.dataIndex]}
                        />
                      ) : i.type === "date" ? (
                        <SaveDatePicker
                          alignment="column"
                          defaultValue={val[i?.dataIndex]}
                          apiKey={i?.apiKey}
                          returnValue={(e) => {
                            console.log("FC", e);
                          }}
                          id={uuidv4()}
                        />
                      ) : i?.type === "dropdown" ? (
                        <SaveDropdown
                          alignment="column"
                          displayLabel={""}
                          defaultSelected={val[i?.dataIndex]}
                          apiurl={i.apiurl}
                          selectionLabel={i?.selectionlabel}
                          selectionValue={i?.selectionvalue}
                          apikey={i?.apiKey}
                          returnValue={(e) => {
                            console.log("FC", e);
                          }}
                          id={uuidv4()}
                        />
                      ) : (
                        <SaveTextInput
                          pageIndex={pageIndex}
                          id={`idtext${ind}`}
                          type="text"
                          apikey={i?.apiKey}
                          change
                          defaultValue={val[i?.dataIndex]}
                        />
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          )
        }
      </table>
    </div>
  );
};

const loaderStyle = {
  height: "max-content",
  width: "max-content",
  display: "flex",
  alignContent: "center",
  margin: "50px auto",
};

export default DomainPara;

import React, { useContext } from "react";
import { AuthStore } from "../Auth/AuthStore";
import { apiCall, apiCallwithBaseUrl } from "../CommonControllers/ApiCalls";

export function changeTabs(
  method,
  tabStore,
  headerName,
  apiUrl,
  apiType,
  menuData
) {
  var tabIndex = null;
  var availableTab = tabStore.state.tabs.filter((item) => item.name == method);

  tabStore.state.tabs.map((item, index) => {
    if (item.name == method) {
      tabIndex = index;
    }
  });
  if (tabIndex != null) {
    tabStore.dispatch({
      type: "OPEN_TAB",
      index: tabIndex,
      headerName: headerName,
    });
  } else {
    tabStore.dispatch({
      type: "NEW_TAB",
      name: method,
      headerName: headerName,
      apiUrl: apiUrl,
      apiType: apiType,
      menuData: menuData,
    });
  }
}

export function getInputValue(tabStore, apiKey, defaultValue) {
  let retValue;
  if (tabStore == undefined) return;
  if (tabStore.state.tabs[tabStore.state.tabIndex].apiVariables[apiKey]) {
    retValue =
      tabStore.state.tabs[tabStore.state.tabIndex].apiVariables[apiKey];
  } else {
    retValue = defaultValue;
  }
  return retValue;
}

export function updateValue(tabStore, apiKey, value, type) {
  if (type == null || type == undefined) {
    type = "API_DATA";
  }

  
  tabStore?.dispatch({
    type: type,
    fieldType: apiKey,
    text: value,
  });
}

export async function executeAPI(tabStore) {
  var url = tabStore.state.tabs[tabStore.state.tabIndex].apiUrl;
  var body = tabStore.state.tabs[tabStore.state.tabIndex].apiVariables;
  var apiType = tabStore.state.tabs[tabStore.state.tabIndex].apiType
    ? tabStore.state.tabs[tabStore.state.tabIndex].apiType
    : "POST";

  var response = await apiCallwithBaseUrl(apiType, url, body);

  updateValue(tabStore, "response", response);

  
}

export function checkFastPath(method, tabStore, menuArray) {
  if (menuArray === undefined) {
    menuArray = tabStore.state.menuArray;
  }

  let x = false;
  let verifyFastPath = false;

  for (let i = 0; i < menuArray.length; i++) {
    if (menuArray[i]?.fastpath?.toUpperCase() === "MS101") {
      console.log("Menu Array MS101", menuArray[i].pageName, menuArray[i].apiurl, menuArray[i]?.apitype);
    }

    if (menuArray[i].childArr && menuArray[i].childArr?.length > 0) {
      const { verifyFastPath: childVerify, x: childX } = checkFastPath(method, tabStore, menuArray[i].childArr);
      if (childVerify) {
        verifyFastPath = true;
        x = childX;
        break; // Break loop if a match is found in children
      }
    } else if (menuArray[i]?.fastpath?.toUpperCase() === method.toUpperCase()) {
      verifyFastPath = true;
      x = true;
      changeTabs(
        method.toUpperCase(),
        tabStore,
        menuArray[i].pageName,
        menuArray[i].apiurl,
        menuArray[i]?.apitype ? menuArray[i]?.apitype : "POST",
        menuArray[i]
      );
      break; // Break loop if a match is found
    }
  }

  return { verifyFastPath, x };
}


export async function saveUpdatedTable(tabStore) {
  var functionType = tabStore.state.tabs[tabStore.state.tabIndex];
  if (functionType.name.includes("MS")) {
    var saveApiUrl = tabStore.state.tabs[tabStore.state.tabIndex]?.saveApiUrl;
    var saveApiType = tabStore.state.tabs[tabStore.state.tabIndex]?.saveApiType
      ? tabStore.state.tabs[tabStore.state.tabIndex].saveApiType.toUpperCase()
      : "POST";
    var body = tabStore.state.tabs[tabStore.state.tabIndex]?.changedRows
      ? tabStore.state.tabs[tabStore.state.tabIndex].changedRows
      : [];
    var body = { data: body };
    var response = await apiCallwithBaseUrl(saveApiType, saveApiUrl, body);

    if (response.Success) {
      executeAPI(tabStore);
    }
  }
}

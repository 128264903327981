import React, { useState, useEffect, useContext } from "react";
import { currentTabData } from "../../CommonControllers/Variable";
import { AuthStore } from "../../Auth/AuthStore";
import { TabStore } from "../../TabsStructure/TabsStore";
import { filtergrn, getfastpathapi } from "../../CommonControllers/ApiCalls";
import SaveRadioBtn from "../../CommonControllers/Components/SaveComponents/SaveRadioBtn";
import { Collapse, Space, Table } from "antd";
import ExecuteDropdown from "../../CommonControllers/Components/ExecuteComponents/ExecuteDropdown";
import SaveDropdown from "../../CommonControllers/Components/SaveComponents/SaveDropdown";
import { List } from "antd";
import CallbackDropdown from "../../CommonControllers/Components/CallbackComponents/CallbackDropdown";

function SkuStocking({ fastPath }) {
  const [pageIndex, setPageIndex] = useState();
  const auth = useContext(AuthStore);
  const tabStore = useContext(TabStore);
  const [selectedOption, setSelectedOption] = useState("grn");
  const [selectedDocument, setSelectedDocument] = useState();
  const [selectedDocumentDetails, setSelectedDocumentDetails] = useState();
  const [locn, setLocn] = useState();
  const [pageDetails, setPageDetails] = useState();
  console.log("Selected Option in ", locn);
  const columns = [
    {
      title: "Item Description",
      dataIndex: "itemdescn",
    },

    {
      title: "Location",
      dataIndex: "address",
      render: (_, it) => {
        console.log("RRRR", it);
        return (
          <>
            {" "}
            <CallbackDropdown
              apiurl={"getdomainlocationlistactive"}
              selectionLabel={"descn"}
              selectionValue={"domainlocationcode"}
              returnValue={(e) => {
                setLocn(e?.domainlocationcode);
              }}
            />
          </>
        );
      },
    },
    {
      title: "Sub Location",
      dataIndex: "address",
      render: () => {
        return (
          <CallbackDropdown
            apiurl={"getdomainsublocationlist"}
            body={{ domainlocationcode: locn }}
            selectionLabel={"descn"}
            selectionValue={"locationcode"}
            returnValue={(e) => {
              console.log("sub-location",e.descn);
            }}
          />
        );
      },
    },
  ];
  useEffect(() => {
    if (selectedDocument) {
      getGRNDetails();
    }
  }, [selectedDocument]);

  const getGRNDetails = async () => {
    const res = await filtergrn({
      billno: selectedDocument?.billno,
      tenantrecno: auth.state.userdata.tenantrecno,
      domainrecno: auth.state.userdata.domainrecno,
      userrolerecno: auth.state.userdata.userrolerecno,
      //   status:"C"
    });
    if (res.Success) {
      setSelectedDocumentDetails(res.Message[0].items);
    }
  };

  const optionForStacking = [
    { label: "GRN", value: "grn" },
    { label: "Un-Stacked Items", value: "all-unstacked" },
    { label: "Stock Transfer", value: "stocktrnasfer" },
  ];

  //   Functions for Getting Page Details and Page's Index
  useEffect(() => {
    var tabsArray = tabStore.state.tabs;
    for (let i = 0; i < tabsArray?.length; i++) {
      if (tabsArray[i].name == fastPath) {
        setPageIndex(i);
        break;
      }
    }
  }, []);

  useEffect(() => {
    const payload = {
      tenantrecno: auth?.state?.userdata?.tenantrecno,
      domainrecno: auth?.state?.userdata?.domainrecno,
      userrolerecno: auth?.state?.userdata?.userrolerecno,
      domainuserrecno: auth.state.userdata.recno,
      fastpath: fastPath,
    };
    // Get Fast ath API
    getfastpathapi(payload).then((res) => {
      console.log("rr", res);
      if (res?.Success) {
        setPageDetails(res?.Message);
        console.log("Resposnse of Fast Path API", res.Message);
      }
    });
  }, []);
  console.log("tyfuyfcu", selectedDocumentDetails);
  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "5px",
          gap: "15px",
        }}
      >
        <SaveRadioBtn
          options={optionForStacking}
          returnValue={setSelectedOption}
          defaultValue={selectedOption}
        />
        <SaveDropdown
          apiKey={"billno"}
          apiurl={"getgrnlist"}
          alignment={true}
          displayLabel={"Select GRN"}
          placeholder={"Select GRN"}
          async
          minInput={0}
          bodyKey={"billno"}
          selectionValue={"billno"}
          selectionLabel={"billno"}
          returnValue={(e) => {
            setSelectedDocument(e);
          }}
        />
      </div>
      <div style={{ marginTop: "30px" }}>
        {/* {selectedDocumentDetails?.map((doc, index) => {
          return (
            <Collapse>
              <Collapse.Panel header={doc?.billno} key={index}>
                <List size="small"
                      itemLayout="horizontal"
                      bordered
                      dataSource={doc?.items}
                      renderItem={(it, ind) => {

                        return (
                            <List.Item   key={it?.recno}>

                            </List.Item>
                        )
                      }}>
                </List>
              </Collapse.Panel>
            </Collapse>
          );
        })} */}
        <Table
          columns={columns}
          dataSource={selectedDocumentDetails}
          pagination={false}
          scroll={{ y: 480 }}
        />
      </div>
    </div>
  );
}

export default SkuStocking;

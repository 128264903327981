import React, { useContext, useEffect, useState } from "react";
import { TabStore } from "../../TabsStructure/TabsStore";
import { getfastpathapi } from "../../CommonControllers/ApiCalls";

import useExecute from "../../CommonControllers/Hooks/useExecute";
import { AuthStore } from "../../Auth/AuthStore";
import axios from "axios";
import ExcelDropdown from "./ExcelDropdown";
import { Button } from "antd";
import { baseUrl } from "../../CommonControllers/Urls";

const DownloadExcel = ({ fastPath }) => {
  const tabStore = useContext(TabStore);
  const auth = useContext(AuthStore);
  const [filterOptions, setFilterOptions] = useState([]);
  const [ReportName, setReportName] = useState([]);
  const [open, setOpen] = useState(false);
  const [filterprinting, setFilterprinting] = useState([]);
  const [filterdropdown, setFilterdropdown] = useState("");
  const execute = useExecute();

  //------loading-----------------------
  const [showTable, setShowTable] = useState(true);
  const [filterDictionary, setFilterDictionary] = useState([]);
  const [file, setFile] = useState("");
  const [pageIndex, setPageIndex] = useState();
  //   console.log("fastPath",fastPath,tabStore.state.tabs[pageIndex]?.name)
  useEffect(() => {
    var tabsArray = tabStore.state.tabs;
    for (let i = 0; i < tabsArray?.length; i++) {
      if (tabsArray[i].name == fastPath) {
        setPageIndex(i);
        break;
      }
    }
  }, []);

  useEffect(() => {
    const payload = {
      tenantrecno: auth?.state?.userdata?.tenantrecno,
      domainrecno: auth?.state?.userdata?.domainrecno,
      userrolerecno: auth?.state?.userdata?.userrolerecno,
      fastpath: tabStore.state.tabs[pageIndex]?.name
        ? tabStore.state.tabs[pageIndex]?.name
        : fastPath,
    };

    execute.updateValue("forreport", "Y");

    // On component mount this api is called and filterOptions are set for that fast path
    //if(pageIndex){
    getfastpathapi(payload)
      .then((res) => {
        if (res?.Success) {
          setFilterOptions(res?.Message?.filterOptions);
          //setReportName(res?.Message?.descn);
        }
      })
      .catch((err) => console.log(err));
  }, []);

  const handleDropdownChange = (value) => {
    console.log("file hgjbk", file);
    setFile(value);
  };

  const handleDownload = async () => {
    try {
      // Data to be sent in the request body
      const requestData = {
        filename: file,
      };

      
      // Make a POST request to the API endpoint to download the Excel file
      const response = await axios.post(
      `${baseUrl}downloadexcel/`,
        requestData,
        {
          responseType: "blob", // Set response type to blob to handle binary data
        }
      );

      // Create a blob object from the response data
      const blob = new Blob([response.data], {
        type: response.headers["content-type"],
      });

      // Create a URL for the blob object
      const url = window.URL.createObjectURL(blob);

      // Create a link element to trigger the download
      const link = document.createElement("a");
      link.href = url;

      link.setAttribute("download", `${file}`); // Set the filename for the downloaded file
      document.body.appendChild(link);

      // Trigger the download
      link.click();

      // Remove the link element from the DOM
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading Excel file:", error);
    }
  };

  return (
    <div
      style={{
      
        alignItems: "center",
        justifyContent: "center",
      }}
    >
    
      <div className="searchBox">
        <ExcelDropdown returnValue={(e) => handleDropdownChange(e)} />
        <Button onClick={handleDownload} style={{marginBottom:"15px"}}>Download</Button>
      </div>
 
       
    
    </div>
  );
};

export default DownloadExcel;

// Nohit Patel 20/02/2023
import { Button, Collapse, Input, Modal, Space, Table, Typography } from "antd";
import React, { useContext, useEffect, useState } from "react";
import {
  getToday,
  dateFormatter,
  enterPress,
  convertToBoxAndPieces,
} from "../../CommonControllers/AppFunction";
import BillingTable from "../../CommonControllers/Components/BillingTableComponents/BillingTable";
import ItemSearch from "../../CommonControllers/ItemController/ItemSearch";
import SupplierSaveDropDown from "../../CommonControllers/SaveComponents/HigherLevel/SupplierSaveDropDown";
import SaveDatePicker from "../../CommonControllers/SaveComponents/SaveDatePicker";
import { handleAdd, prColumns } from "./SaleReturnBillingTableColumns";
import useSaveChangedRows from "../../CommonControllers/Hooks/useSaveChangedRows";
import useShortguid from "../../CommonControllers/Hooks/useShortGuid";
import { TabStore } from "../../TabsStructure/TabsStore";
import { AuthStore } from "../../Auth/AuthStore";
import SaveDropDown from "../../CommonControllers/SaveComponents/SaveDropDown";
import { v4 as uuidv4 } from "uuid";
import {
  domainLocationURL,
  getCustomer,
  getcustomerlistURL,
  getDomaincustomerlist,
  getdomainlocationlistactive,
} from "../../CommonControllers/Urls";
import "../../CommonControllers/global.css";
import CallbackTextInput from "../../CommonControllers/Components/CallbackComponents/CallbackTextInput";
import { IoAperture } from "react-icons/io5";
import UserLocationList from "../../CommonControllers/SaveComponents/HigherLevel/UserLocationList";
import ItemsTable from "./ItemsTable";
import { useRef } from "react";
import CallbackDateInput from "../../CommonControllers/Components/CallbackComponents/CallbackDateInput";
import SaveDropdown from "../../CommonControllers/Components/SaveComponents/SaveDropdown";
import SaveTextInput from "../../CommonControllers/Components/SaveComponents/SaveTextInput";

import {
  getSalebill,
  getSalebillOPening,
  getfastpathapi,
} from "../../CommonControllers/ApiCalls";
import SearchFilterByBillNo from "../../CommonControllers/Components/SearchFilterBillNo/SearchFilterByBillNo";
import { borderRadius } from "@mui/system";
import Searchsalebillnumber from "./Searchsalebillnumber";
import { CleaningServices } from "@mui/icons-material";
import { currentTabData } from "../../CommonControllers/Variable";
import CustomerDrop from "../../CommonControllers/Components/CommonDropDowns/CustomerDrop";
import SaveRadioBtn from "../../CommonControllers/Components/SaveComponents/SaveRadioBtn";
import SaveCheckbox from "../../CommonControllers/Components/SaveComponents/SaveCheckbox";
import MultipleSelectionItem from "../GRN/MultipleSelectionItem";
import { handleRemovePrev } from "../SalesInvoice/SalesInvoiceFunc";
import MultipleSelectionItemPendingDocs from "../PurchaseInvoice/MultipleSelectionItemPendingDocs";
function SaleReturn({ fastPath }) {
  const { Panel } = Collapse;
  const auth = useContext(AuthStore);
  const shortguid = useShortguid();
  const tabStore = useContext(TabStore);
  const [getSelectedItem, setSelectedItem] = useState(null);
  const [rowData, setRowData] = useState([]);
  const [customerdata, setCustomerdata] = useState(null);
  const [igstappl, setigstappl] = useState(false);
  const useChangedRows = useSaveChangedRows();
  const [displayMessages, setDisplayMessages] = useState(false);
  const [showFilters, setShowFilters] = useState(true);
  const [saleRate, setSaleRate] = useState(" "); // smk 7-3-2023 purchaserate -salerate
  const [selectedQty, setSelectedQty] = useState(" "); // Qty as in pieces in UI, total = pieces * rate
  const [box, setBox] = useState("");
  const [batch, setbatch] = useState(" ");
  const [expDate, setexpDate] = useState(null);
  const [collapse, setCollapse] = useState(false);
  const [expDeliveryDays, setExpDeliveryDays] = useState(null);
  const [datapayload, setDatapayload] = useState([]);
  const [barcodeValue, setbarcodeValue] = useState();
  const itemSearch = useRef(null);
  const [statusOptions, setStatusOptions] = useState([]);
  const [status, setstatus] = useState(true);
  // saleshortuid
  const [saleshortguid, setsaleShortguid] = useState("");
  const [pageIndex, setPageIndex] = useState();

  useEffect(() => {
    var tabsArray = tabStore.state.tabs;
    for (let i = 0; i < tabsArray?.length; i++) {
      if (tabsArray[i].name == "TR015") {
        setPageIndex(i);
        break;
      }
    }
  }, []);

  // ---------------------Edit Transtion States------------------
  // Create useState for Show POP Of Search Bill
  const [showFilterModal, setshowFilterModal] = useState(false);
  const [customerdata1, setCustomerdata1] = useState(null);
  const [location1, setlocation1] = useState();
  // render
  const [count, setCount] = useState(0);

  const [srNumber, setSrNumber] = useState();
  const [location, setlocation] = useState();
  // --------------------------------------------------------------

  ///  new model state for search invoice
  const [model, setModel] = useState(false);

  const [modelOpening, setModelOpening] = useState(false);
  const [counter, Setcounter] = useState(0);
  const [statusC, setstatusC] = useState(false);
  // pick from si
  const [supplierdata, setSupplierdata] = useState(null);
  const [displayPrevDC, setDisplayPrevDC] = useState(false);
  const [modalcount, setModalcount] = useState(0);
  const [returnData, setreturnData] = useState([]);
  const [prdate, setPrdate] = useState(auth?.state?.userdata?.workdate);

  const changerow = tabStore?.state?.tabs[tabStore.state.tabIndex]?.changedRows;
  ///-----------------------------------------PI--------------------------------


  const [pi, setPino] = useState(null);
  const [srDate, setSrDate] = useState(auth?.state?.userdata?.workdate);

  const [countRender, setcountRender] = useState(0);



  /// page details is added
  const [pageDetails, setPageDetails] = useState();
  const payld = {
    tenantrecno: auth?.state?.userdata?.tenantrecno,
    domainrecno: auth?.state?.userdata?.domainrecno,
    userrolerecno: auth?.state?.userdata?.userrolerecno,
    domainuserrecno: auth.state.userdata.recno,
    fastpath: currentTabData(tabStore)?.name,
  };

  //// here Date 29 jun rajendra
  useEffect(() => {
    // Get Fast ath API
    getfastpathapi(payld).then((res) => {
      console.log("rr", res);
      if (res?.Success) {
        setPageDetails(res?.Message);
        console.log("Resposnse of Fast Path API", res.Message);
        setStatusOptions([
          {
            label: "Save",
            value: res.Message?.defaultStatus,
          },
          {
            label: "Proceed",
            value: res.Message?.nextStatus,
          },
        ]);
      }
    });
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");
    const formattedDate = `${year}${month}${day}`;

    setPrdate(formattedDate);
  }, []);

  useEffect(() => {
    if (tabStore.state.tabs[pageIndex]?.changedRows?.dataFromEdit) {
      // setRowData(tabStore.state.tabs[pageIndex]?.changedRows?.items);
      const newdata = tabStore.state.tabs[pageIndex]?.changedRows?.items.map((i) => {
        return { ...i, boxsalerate: Number(i?.salerate) * Number(i?.conversionfactor) };
      });
      setRowData(newdata);
      setStatusOptions([
        {
          label: "Save",
          value: tabStore.state.tabs[pageIndex]?.changedRows?.status,
        },
        {
          label: "Proceed",
          value: tabStore.state.tabs[pageIndex]?.changedRows?.nextStatus,
        },
      ]);
    }
  }, [tabStore.state.tabs[pageIndex]?.changedRows?.dataFromEdit]);

  useEffect(() => {
    getSelectedItem?.salerate !== undefined
      ? setSaleRate(getSelectedItem?.salerate)
      : setSaleRate("");
  }, [getSelectedItem?.salerate]);

  useEffect(() => {
    let _temp = changerow?.billno;
    console.log("kkkk", _temp);
    if (_temp?.length > 0) {
      handleAdditem();
    }
  }, [model]);

  useEffect(() => {
    let _temp = changerow?.billno;
    console.log("kkkk", _temp);
    if (_temp?.length > 0) {
      handleAdditemOPeningTransaction();
    }
  }, [modelOpening]);

  //~# Setting workdate
  let workdateyymmdd = auth?.state?.workdate;
  let workdate = auth?.state?.workdate;
  workdate = JSON.stringify(workdate);
  workdate = `${workdate?.slice(-2)}/${workdate?.slice(4, 6)}/${workdate?.slice(
    0,
    4
  )}`;

  function setDatatoCustomerdata(e) {
    setCustomerdata1(e);
    setlocation1(e);
  }
  useEffect(() => {
    //customerdata1 have igstappl field not customerdata

    if (customerdata1?.igstappl) {
      setigstappl(true);
      // dateSetter();
    } else {
      setigstappl(false);
      //dateSetter();
    }
  }, [customerdata1]);

  useEffect(() => {
    // When Row Data Changes we Need to store that data to the tabsStore with Header Data
    var existingObject = useChangedRows.getValue();

    // Calculate Other Numbers and Inputs and store in the tabStore
    // var status = "P";
    var approvalStatus = "P";
    var remark = "Need to add a field for that";
    var narration = "Need to ad a text field for this as well";
    var amount; // Calculate the total amount after tax
    var taxableamount; // Calculate the total taxable amount after tax
    var cgstamt; //Calculate the CGSTAMT
    var sgstamt; //Calculate teh SGST AMT
    var igstamt; //Calculat the IGSTAMT
    var returnamount; //Calculate the Return amount
    // var shortguid;

    //console.log("ROW DATA", rowData);
    var reqpayload = [];
    for (let i = 0; i < rowData.length; i++) {
      //console.log("ROW DATA", rowData);
      let item = rowData[i];

      if (item["returnqty"] !== undefined) {
        //console.log("CHH",item["returnqty"])
        reqpayload.push(item);
      }
    }

    amount = reqpayload.reduce((total, item) => total + Number(item.amount), 0);
    taxableamount = datapayload.reduce(
      (total, item) => total + Number(item.taxableamount),
      0
    );
    cgstamt = reqpayload.reduce(
      (total, item) => total + Number(item?.cgstamt),
      0
    );
    sgstamt = reqpayload.reduce(
      (total, item) => total + Number(item?.sgstamt),
      0
    );
    igstamt = reqpayload.reduce(
      (total, item) => total + Number(item?.igstamt),
      0
    );
    // shortguid =  existingObject.shortguid ? (existingObject.shortguid):( shortguid.getShortguid("SR") );
    // console.log("new/old shortguid is",shortguid)
    //// return amount calculation
    returnamount = reqpayload.reduce(
      (total, item) =>
        total + Number(item?.returnamount ? item?.returnamount : 0),
      0
    );
    let returncgstamt = reqpayload.reduce(
      (total, item) =>
        total + Number(item?.returncgstamt ? item?.returncgstamt : 0),
      0
    );
    let returnigstamt = reqpayload.reduce(
      (total, item) =>
        total + Number(item?.returnigstamt ? item?.returnigstamt : 0),
      0
    );

    let returntaxableamount = reqpayload.reduce(
      (total, item) =>
        total +
        Number(item?.returntaxableamount ? item?.returntaxableamount : 0),
      0
    );
    let returnsgstamt = reqpayload.reduce(
      (total, item) =>
        total + Number(item?.returnsgstamt ? item?.returnsgstamt : 0),
      0
    );
    existingObject = {
      ...existingObject,
      cgstamt: cgstamt ? Number(cgstamt.toFixed(2)) : 0,
      igstamt: igstamt ? Number(igstamt.toFixed(2)) : 0,
      sgstamt: sgstamt ? Number(sgstamt.toFixed(2)) : 0,
      returnamount: returnamount ? Number(returnamount.toFixed(2)) : 0,
      taxableamount: taxableamount ? Number(taxableamount.toFixed(2)) : 0,
      amount: amount ? Number(amount.toFixed(2)) : 0,
      trdate: srDate,
      shortguid: uuidv4(),
      items: rowData.map((it) => {
        if (it.itemasbatch === 0) {
          return { ...it, itembatcharray: [] };
        } else {
          return it;
        }
      }),
      status: status ? statusOptions[1]?.value : statusOptions[0]?.value,
      saleshortguid: saleshortguid?saleshortguid:rowData[0]?.refshortguid,
      returncgstamt: returncgstamt ? Number(returncgstamt.toFixed(2)) : 0,
      returnigstamt: returnigstamt ? Number(returnigstamt.toFixed(2)) : 0,
      returntaxableamount: returntaxableamount
        ? Number(returntaxableamount.toFixed(2))
        : 0,
      returnsgstamt: returnsgstamt ? Number(returnsgstamt.toFixed(2)) : 0,
    };

    useChangedRows.updateValue(false, existingObject);

  }, [rowData, status]);

  useEffect(() => {
    // When Row Data Changes we Need to store that data to the tabsStore with Header Data
    var currentData = useChangedRows.getValue();
    if (!currentData?.shortguid) {
      useChangedRows.updateValue("shortguid", shortguid.getShortguid("PR"));
    }

    if (rowData?.length == 0) {
      var items = useChangedRows.getValue("items");
      console.log("item", items);
      if (items) {
        // setRowData(items);
        const newdata = items.map((i) => {
          return { ...i, boxsalerate: Number(i?.salerate) * Number(i?.conversionfactor) };
        });
        setRowData(newdata);
      }
    }
  }, []);

  useEffect(() => {
    // When Row Data Changes we Need to store that data to the tabsStore with Header Data
    var currentData = useChangedRows.getValue();
    if (!currentData?.shortguid) {
      useChangedRows.updateValue("shortguid", shortguid.getShortguid("PR"));
    }
    setCustomerdata(useChangedRows.getValue("customerrecno"));
    setSrNumber(useChangedRows.getValue("billno"));
    setlocation(useChangedRows.getValue("locationrecno"));
    setlocation1(useChangedRows.getValue("locationrecno"));

    if (rowData?.length == 0) {
      var items = useChangedRows.getValue("items");
      if (items) {
        // setRowData(items);
        const newdata = items.map((i) => {
          return { ...i, boxsalerate: Number(i?.salerate) * Number(i?.conversionfactor) };
        });
        setRowData(newdata);
      }
    }
  }, [showFilterModal]);

  useEffect(() => {
    // When Row Data Changes we Need to store that data to the tabsStore with Header Data
    var currentData = useChangedRows.getValue();

    if (!currentData?.shortguid) {
      useChangedRows.updateValue("shortguid", shortguid.getShortguid("PR"));
    }
    setCustomerdata1(useChangedRows.getValue("customerrecno"));
    setSrNumber(useChangedRows.getValue("billno"));
    setlocation1(useChangedRows.getValue("locationrecno"));


    if (rowData?.length == 0) {
      var items = useChangedRows.getValue("items");
      if (items) {
        // setRowData(items);
        const newdata = items.map((i) => {
          return { ...i, boxsalerate: Number(i?.salerate) * Number(i?.conversionfactor) };
        });
        setRowData(newdata);
      }
    }
  }, [model, modelOpening]);

  /// the pi date  validation
  const setPi = (e) => {
   
    setPino(e);
  };

  useEffect(() => {
    setPi();
  }, [pi]);

  useEffect(() => {

    if (useChangedRows.getValue("trdate",pageIndex)) {
      setSrDate(useChangedRows.getValue("trdate",pageIndex));
    } else {
      useChangedRows.updateValue("trdate", auth?.state?.workdate);
      setSrDate(auth?.state?.workdate);
    }
  }, [useChangedRows.getValue("trdate",pageIndex)]);

  useEffect(() => {
    if (tabStore.state.tabs[pageIndex]?.pageStatus == "Success") {
      setRowData([]);
      setigstappl(false);
      setSelectedItem(null);
      setSelectedQty(" ");
      setSaleRate(" ");
      setCount((p) => p + 1);
      setSrDate(auth?.state?.userdata?.workdate);
      setCustomerdata(null)
      setStatusOptions([
        {
          label: "Save",
          value: pageDetails?.defaultStatus,
        },
        {
          label: "Proceed",
          value: pageDetails?.nextStatus,
        },
      ]);
      setstatus(true);
      setSrNumber("To Be Allotted");
      tabStore.dispatch({
        type: "UPDATE_DATA",
        fieldType: "pageStatus",
        text: "",
      });
    }
  }, [
    tabStore.state.tabs[pageIndex]?.pageStatus,
    Object.keys(currentTabData(tabStore)?.changedRows)?.length,
  ]);

  /// function for api call

  const payload = {
    ...changerow,
    tenantrecno: auth?.state?.userdata?.tenantrecno,
    domainrecno: auth?.state?.userdata?.domainrecno,
    userrolerecno: auth?.state?.userdata?.userrolerecno,
  };

  async function handleAdditem() {
    if (pi !== null && pi !== "" && pi !== 0) {
      try {
        let res = await getSalebill(payload);

        if (res.Success && res?.Message[0]?.items.length > 0) {
          setsaleShortguid(res?.Message[0]?.shortguid);
          const newdata = res?.Message[0]?.items.map((i) => {
            return { ...i, itembatcharray: [], shortguid: uuidv4(), boxsalerate: Number(i?.salerate) * Number(i?.conversionfactor) };
          });
          setRowData(newdata);

          setigstappl(res?.Message[0]?.items[0]?.igstrate);
        } else {
          auth.state.contextHolder.info({
            message: `An Error has Occured`,
            description: "Bill not present",
            duration: 3,
            style: { background: "#efa7a7" },
          });
        }
      } catch (err) {
        auth.state.contextHolder.info({
          message: `An Error has Occured`,
          description: String(err),
          duration: 3,
          style: { background: "#efa7a7" },
        });
      }
    }
  }

  async function handleAdditemOPeningTransaction() {
    if (pi !== null && pi !== "" && pi !== 0) {
      try {
        let res = await getSalebillOPening(payload);

        if (res.Success && res?.Message[0]?.items.length > 0) {
          setsaleShortguid(res?.Message[0]?.shortguid);
          const newdata = res?.Message[0]?.items.map((i) => {
            return { ...i, itembatcharray: [], shortguid: uuidv4(), boxsalerate: Number(i?.salerate) * Number(i?.conversionfactor) };
          });
          setRowData(newdata);

          setigstappl(res?.Message[0]?.items[0]?.igstrate);
        } else {
          auth.state.contextHolder.info({
            message: `An Error has Occured`,
            description: "Bill not present",
            duration: 3,
            style: { background: "#efa7a7" },
          });
        }
      } catch (err) {
        auth.state.contextHolder.info({
          message: `An Error has Occured`,
          description: String(err),
          duration: 3,
          style: { background: "#efa7a7" },
        });
      }
    }
  }

  /////// validation  for save //////
  ////// validation for save button changes /////
  useEffect(() => {
    let isButtonDisabled = false;

    for (let i = 0; i < rowData.length; i++) {
      const {
        packingqty,
        packingextraqty,
        freepackingqty,
        freepackingextraqty,
        manufacturedate,
        expdate,
        itembatchno,
        purrate,
        expdatecompulsory,
        itemasbatch,
        manudatecompulsory,
        wantbatchno,
        changesalerateinbill,
        currentreturnqty,
        currentreturnpackingqty,
      } = rowData[i];
      console.log("ROWDATA", rowData[i]);
      let x = rowData?.reduce((acc, item) => acc + item?.currentreturnqty, 0);
      if (
        rowData[i]?.itemasbatch &&
        Number(rowData[i]?.currentreturnqty) +
          Number(rowData[i]?.returnfree ? rowData[i]?.returnfree : 0) !==
          rowData[i]?.itembatcharray?.length &&
        currentreturnpackingqty != 0
      ) {
        isButtonDisabled = true;
        console.log("isButtonDisabled:122", isButtonDisabled);
        break;
      }
      // else if ((wantbatchno && (!itembatchno || itembatchno == "" || itembatchno == null || itembatchno == undefined))||  (packingqty === "0" && packingextraqty === "0") || (packingqty == "" && packingextraqty == "") || (packingqty === 0 && packingextraqty === "0") || (packingqty === "0" && packingextraqty === 0) || (packingqty === 0 && packingextraqty === 0)|| Number(purrate) == 0) {
      //   console.log("Custermer891",Number(rowData[i]?.qty) +
      //   Number(rowData[i]?.free ? rowData[i]?.free : 0) ,
      //   rowData[i]?.itembatcharray?.length)
      //   isButtonDisabled = true;
      //   console.log("isButtonDisabled:122", isButtonDisabled);
      //   break;
      // }
      if (x == 0) {
        isButtonDisabled = true;
      }
    }

    useChangedRows.updateValue(
      "validated",
      !isButtonDisabled ? "true" : "false"
    );
  }, [rowData]);

  useEffect(() => {
    if (barcodeValue) {
      getBillFromBarcode();
    }
  }, [barcodeValue]);

  //// status changes with dropdown
  const getBillFromBarcode = async () => {
    let payload = {
      tenantrecno: auth?.state?.userdata?.tenantrecno,
      domainrecno: auth?.state?.userdata?.domainrecno,
      // domainuserrecno: auth?.state?.userdata?.recno,
      userrolerecno: auth?.state?.userdata?.userrolerecno,
      billno: barcodeValue?.itembatcharrayfound[0]?.billno,
    };

    let response = await getSalebill(payload);

    if (response.Success && response.Message?.length > 0) {
  
      // setRowData(...response?.Message[0]?.items,"items"=[])

      const itembatcharray = [];
      // const newdata = [...response?.Message[0]?.items,itembatcharray]
      // console.log("newdatanohit",newdata)
      const newdata = response?.Message[0]?.items.map((i) => {
        return { ...i, itembatcharray: [], boxsalerate: Number(i?.salerate) * Number(i?.conversionfactor) };
      });
     
      setRowData(newdata);
      // setpurchaseshortguid(response?.Message[0]?.shortguid)
      setsaleShortguid(response?.Message[0]?.shortguid);
      setigstappl(response?.Message[0]?.items[0]?.igstrate);
    } else {
      let res = await getSalebillOPening(payload);
   

      const itembatcharray = [];
      // const newdata = [...response?.Message[0]?.items,itembatcharray]
      // console.log("newdatanohit",newdata)
      const newdata = res?.Message[0]?.items.map((i) => {
        if (i.itemrecno == barcodeValue.itemrecno) {
          return {
            ...i,
            itembatcharray: [
              {
                serialno: barcodeValue.entryserialno,
                expdate: barcodeValue.entryexpdate,
                id: uuidv4(),
                manudate: barcodeValue.entrymanudate,
              },
            ],
          };
        }
        return { ...i, itembatcharray: [], boxsalerate: Number(i?.salerate) * Number(i?.conversionfactor) };
      });

      setRowData(newdata);
      // setpurchaseshortguid(response?.Message[0]?.shortguid)
      setsaleShortguid(res?.Message[0]?.shortguid);
      setigstappl(res?.Message[0]?.items[0]?.igstrate);
    }
  };
  const optionsforDC = [
    {
      id: 1,
      optionName: "SI",
      apiurl: "filtersalebilllog",
      customerrecno: customerdata?.customerrecno,
      doctype: "DC",
    },
  ];
  return (
    <div style={{ ...mainFrame }}>
      {/* pick from si */}
      <Modal
        key={displayPrevDC} /// modal count value changes
        width={620}
        open={displayPrevDC}
        onCancel={() => {
          setDisplayPrevDC(false);
          setModalcount((p) => p + 1);
          setreturnData([]);
        }}
        onOk={() => {
          setreturnData(JSON.parse(JSON.stringify(returnData)));

          let newArray = returnData?.map((item) => {
            let pcsBox = convertToBoxAndPieces(
              Number(item?.selectedQty),
              Number(item?.conversionfactor),
              pageDetails?.wantpackingextra
            );

            if (!rowData || !item) {
              alert("Invalid data");
            } else {
              var existingItem = rowData.filter(
                (it) => it && it.itemrecno === item.itemrecno
              );

              if (existingItem.length === 0) {
                // Add item to the list

                handleAdd(
                  item,
                  setRowData,
                  item?.igstrate > 0 ? true : false,
                  setSelectedItem,
                  shortguid,
                  item?.salerate ? item.salerate : item?.rate,
                  //Made Changes to Add Box Pieces from Previous Documents
                  pcsBox[1],
                  pcsBox[0], // Made Changes to Add Pieces From Pieces Documents
                  item.freeQuantity,
                  0,
                  item?.itembatchno,
                  0,
                  item?.discountamount > 0 ? item.discountamount : 0,
                  item?.discountamt > 0 ? item.discountamt : 0,
                  0,
                  // item?.amount > 0 ? item.amount : 0,
                  // item?.taxableamount > 0 ? item.taxableamount : 0,
                  // rowData,
                  auth?.state?.userdata?.onedconeinvoice
                );

                ///// commented this line
                // setreturnData([]);
                /////
              } else {
                handleRemovePrev(item, setRowData, rowData);

                setTimeout(() => {
                  handleAdd(
                    item,
                    setRowData,
                    item?.igstrate > 0 ? true : false,
                    setSelectedItem,
                    shortguid,
                    item?.salerate ? item.salerate : item?.rate,
                    //Made Changes to Add Box Pieces from Previous Documents
                    pcsBox[1],
                    pcsBox[0], // Made Changes to Add Pieces From Pieces Documents
                    0,
                    item.freeQuantity,
                    item?.itembatchno,
                    0,
                    item?.discountamount > 0 ? item.discountamount : 0,
                    item?.discountamt > 0 ? item.discountamt : 0,
                    0,
                    // item?.amount > 0 ? item.amount : 0,
                    // item?.taxableamount > 0 ? item.taxableamount : 0,
                    // rowData,
                    auth?.state?.userdata?.onedconeinvoice
                  );

                  setreturnData([]);
                }, 200);
              }
            }
          });

          setDisplayPrevDC(false);
          setModalcount((p) => p + 1);
        }}
      >
        <MultipleSelectionItemPendingDocs
          options={optionsforDC}
          selectedItemsArray={returnData}
          selectOnlyOneDoc={1}
          returnValue={(e) => {
            console.log("seleted DC chalan eee", e);
            // setreturnData([...e]);
            setreturnData(e);
          }}
          forFlag={"sr"} //KMK:31/03/23 Dont Change the place of this Key Value
          todate={srDate}
        />
      </Modal>
      <Modal
        open={displayMessages}
        onCancel={() => {
          setDisplayMessages(!displayMessages);
        }}
        onOk={() => {
          useChangedRows.updateValue(
            "message",
            document.getElementById("message").value
          );
          useChangedRows.updateValue(
            "narration",
            document.getElementById("narration").value
          );
          setDisplayMessages(!displayMessages);
        }}
      >
        <div style={{ display: "flex", flexDirection: "column" }}>
          <p>Message from Cust</p>
          <Input
            id={"message"}
            placeholder="Enter Message for Cust"
            defaultValue={useChangedRows.getValue("message")}
          ></Input>
          <br /> <p>Message for Self</p>
          <Input
            id={"narration"}
            placeholder="Enter Message for Self"
            defaultValue={useChangedRows.getValue("narration")}
          ></Input>
        </div>
      </Modal>

      {/* =============================//*Edit Transaction*\\=================================== */}

      {/* ============here======= */}
      {/* commentou asper asked by client date 20 april 2023 rbn */}
      {/* <Modal
        title="Search Bill"
        open={showFilterModal}
        onOk={() => {
          setshowFilterModal(false);
          setCount((p) => p + 1);
        }}
        onCancel={() => {
          setshowFilterModal(false);
          setCount((p) => p + 1);
        }}
        width={"100%"}
        style={{ top: 20 }}
        key={count}
      >
        <SearchFilterByBillNo
          fastpath="RP108"
          setshowFilterModal={setshowFilterModal}
          showFilterModal={showFilterModal}

        />
      </Modal> */}
      {/* =============here======== */}
      {/*            model new  search invoice number   */}
      <Modal
        title="Search Invoice"
        open={model}
        onOk={() => {
          setModel(!model);
          Setcounter((p) => p + 1);
        }}
        onCancel={() => {
          setModel(!model);
          Setcounter((p) => p + 1);
        }}
        width={"100%"}
        style={{ top: 20 }}
        key={model}
      >
        <Searchsalebillnumber
          fastpath="RP106"
          setshowFilterModal={setModel}
          showFilterModal={model}
          statusC={statusC}
          setstatusC={setstatusC}
          forFlag={"SR"}
          forreturn={true} /// this key is added so that it was required for payload
        />
      </Modal>

      <Modal
        title="Search Opening Invoice"
        open={modelOpening}
        onOk={() => {
          setModelOpening(false);
          Setcounter((p) => p + 1);
        }}
        onCancel={() => {
          setModelOpening(false);
          Setcounter((p) => p + 1);
        }}
        width={"100%"}
        style={{ top: 20 }}
        key={counter}
      >
        <Searchsalebillnumber
          fastpath="RP115"
          setshowFilterModal={setModelOpening}
          showFilterModal={modelOpening}
          statusC={statusC}
          setstatusC={setstatusC}
          forFlag={"SR"}
          forreturn={true} /// this key is added so that it was required for payload
        />
      </Modal>

      <Collapse collapsible="header" defaultActiveKey={["1"]}>
        <Panel header="Selection Frame" key="1">
          <div>
            <form
              style={{
                display: "flex",
                flexWrap: "wrap",
                columnGap: "40px", // Adjust the column gap as needed
                rowGap: "15px",
                marginTop: "5px",
              }}
            >
                            <div>
                <div style={{ display: "flex" }}>
                  <div
                    style={{
                      zIndex: 11,
                      position: "relative",
                      pointerEvents: rowData?.length > 0 && "none",
                      marginTop: "8px",
                    }}
                  >
                    <SaveDropdown
                      defaultSelected={customerdata1}
                      key={count}
                      alignment="column"
                      displayLabel="Customer"
                      placeholder={"Select Customer"}
                      apikey="customerrecno"
                      apiurl="getdomaincustomerlist"
                      selectionLabel="customerdescn"
                      selectionValue="customerrecno"
                      returnValue={(event) => {
                        {
                        }
                        if (event?.hasOwnProperty("zincode")) {
                          // task rajendra id-240
                          if (event?.zincode) {
                            // task rajendra id-240
                            setCustomerdata(event);
                            setDatatoCustomerdata(event);
                          } else {
                            alert(
                              "Customer has no Zincode. Please Add Zincode to start billing"
                            );

                            if (customerdata == 0) {
                              setCustomerdata(null);
                              setDatatoCustomerdata(event);
                            } else {
                              setCustomerdata(0);
                              setDatatoCustomerdata(0);
                            }
                          }
                        } else {
                          setCustomerdata(event);
                          setDatatoCustomerdata(event);
                        }
                      }}
                      isrequired={1}
                    />

                    <p>{customerdata ? customerdata?.remarks : ""}</p>
                  </div>

                  {/* <ItemSearch
                  itemSearchRef={itemSearch}
                  forFlag={"SR"}
                  returnValue={setSelectedItem}
                  supplierdata={customerdata1?.customerrecno}
                  dropdown
                  selection
                  barcodeReturnValue={(e) => {
                    console.log("itemsearch e", e);
                    setbarcodeValue(e);
                  }}
                /> */}
                </div>

              </div>
              <div onKeyDown={enterPress} style={{ textAlign: "right",marginTop:"10px" }}>
                  <Button
                    //style={{ ...buttonStyle }}
                    className="btn"
                    disabled={
                      auth?.state?.userdata?.onedconeinvoice &&
                      rowData.length > 0 &&
                      (rowData[0].doctype == "DC" ||
                        rowData[0].doctype == "SO" ||
                        !rowData[0]?.doctype)
                        ? true
                        : false
                    }
                    onClick={(e) => {
                      if (customerdata) {
                        setDisplayPrevDC(true);
                        setreturnData([]);
                      } else {
                        alert("Select Customer");
                      }
                    }}
                  >
                    Pick from SI
                  </Button>
                </div>
              {/* =============================//*Edit Transaction*\\=================================== */}

              <div onKeyDown={enterPress} style={{ marginTop: "10px" }}>
                <Button
                  //style={{ ...buttons }}
                  className="btn"
                  type="default"
                  onClick={() => {
                    setModel(true);

                    Setcounter((p) => p + 1);

                    setstatusC(true); ///////////////////////// Added for deleting data from Tabstore for tab TR015

                    tabStore.dispatch({
                      type: "UPDATE_TABS_ARRAY",

                      changedRowsValue: {},

                      tabsFastPath: "TR015",

                      keyName: "changedRows",
                    }); //////////////////////////////////
                  }}
                >
                  Search Sales Invoice
                </Button>
              </div>
              <div onKeyDown={enterPress} style={{ marginTop: "10px" }}>
                <Button
                  //style={{ ...buttons }}
                  className="btn"
                  type="default"
                  onClick={() => {
                    setModelOpening(true);

                    Setcounter((p) => p + 1);

                    setstatusC(true); ///////////////////////// Added for deleting data from Tabstore for tab TR015

                    tabStore.dispatch({
                      type: "UPDATE_TABS_ARRAY",

                      changedRowsValue: {},

                      tabsFastPath: "TR015",

                      keyName: "changedRows",
                    }); //////////////////////////////////
                  }}
                >
                  Search Opening Sales Invoice
                </Button>
              </div>

              {/* Select Customer */}
              {/* <div style={{ pointerEvents: "none" }}>   */}
              <div
                onKeyDown={enterPress}
                style={{ marginTop: "10px", zIndex: 9 }}
              >
                {/* <UserLocationList
                    setSupplier={setDatatoCustomerdata}
                    setlocation={setlocation1}
                    defaultSelection={location1}
                    apikey={"locationrecno"}
                  /> */}

                <SaveDropdown
                  defaultValue={location1}
                  apikey={"locationrecno"}
                  minInput={0}
                  inputValue={20}
                  displayLabel={"Location"}
                  placeholder={"Select Location"}
                  //className={style.drop}
                  apiurl={getdomainlocationlistactive}
                  selectionLabel={"descn"}
                  selectionValue={"locationrecno1"}
                  setDataToParent={setlocation1}
                  width={"250px"}
                  alignment="column"
                />
              </div>

              <div onKeyDown={enterPress} style={{ marginTop: "10px" }}>
                <div style={{ position: "relative" }}>
                  <label
                    // htmlFor={id}
                    className={`label ${"focused"}`}
                    style={{
                      // zIndex: 1000,
                      whiteSpace: "nowrap",
                      wordWrap: "unset",
                      position: "absolute",
                      top: "-19px",
                      left: "12px",
                      //backgroundColor: "white",
                      padding: "0 4px",
                      fontSize: "12px",
                      color: "black",
                      pointerEvents: "none",
                      transform: "translateY(0)",
                      transition:
                        "top 0.3s, transform 0.3s, font-size 0.3s, color 0.3s",
                    }}
                  >
                    SR No.
                  </label>
                  <input
                    style={{ ...poNumberStyle, marginTop: "0px" }}
                    placeholder={srNumber ? srNumber : "To Be Allotted"}
                    disabled
                    defaultValue={"To Be Allotted"}
                  />
                </div>
              </div>
              {/* <div>
                <p>SR Date</p>
                <input style={{ ...poNumberStyle }} value={workdate} disabled />
              </div> */}
              {/* <Button type="dashed">PO Date : {workdate}</Button> */}
              <div onKeyDown={enterPress} style={{ marginTop: "10px" }}>
                {/* <SaveDatePicker
                defaultValue={auth?.state?.workdate}
                id={"sr-date"}
                displayLabel={"SR Date"}
                apiKey={"trdate"}
                dateLessthan={workdate}
                
              /> */}
                <SaveDatePicker
                  displayLabel={"SR Date"}
                  placeholder={"SR Date"}
                  disabled={rowData && rowData.length > 0 ? 1 : 0}
                  // dateLessthan={workdate}
                  // disabled={fastPath=="TR015"?true:false}
                  apiKey={"trdate"}
                  pageIndex={pageIndex}
                  defaultValue={srDate}
                  id={"sr-date"}
                  dateLessthan={auth?.state?.userdata?.workdate} //! If this is false, postAlertMsg will trigger
                  postAlertMsg={`SR Date cannot be greater than Today's Date or less than Opening date`}
                  dateGreaterthan={auth?.state?.userdata?.openingbaldate} // New Date Validation
                  returnValue={(e) => {
                    setSrDate(e);
                    return true;
                  }}
                />
              </div>

              {/* Status */}
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                onKeyDown={enterPress}
              >
                <div style={{ position: "relative" }}>
                  {/* <label
                    className={`label ${"focused"}`}
                    style={{
                      // zIndex: 1000,
                      whiteSpace: "nowrap",
                      wordWrap: "unset",
                      position: "absolute",
                      top: "-12px",
                      left: "12px",
                      //backgroundColor: "white",
                      padding: "0 4px",
                      fontSize: "12px",
                      color: "black",
                      pointerEvents: "none",
                      transform: "translateY(0)",
                      transition:
                        "top 0.3s, transform 0.3s, font-size 0.3s, color 0.3s",
                    }}
                  >
                    Status
                  </label>
                  <div
                    onKeyDown={enterPress}
                    className="saveproceed"
                    style={{
                      padding: "8.7px",
                      marginTop: "4px",
                    }}
                  >
                    <SaveRadioBtn
                      apikey={"status"}
                      key={count}
                      // defaultValue={pageDetails?.defaultStatus}
                      options={statusOptions}
                    />
                  </div> */}
                  <SaveCheckbox
                    displayLabel={"Proceed"}
                    defaultValue={status}
                    editable
                    apikey={"status"}
                    returnValue={(e) => setstatus(e)}
                  />
                </div>
              </div>

              {/* <div>
                <SaveTextInput
                pageIndex={pageIndex}
                  id="customer-bill-no"
                  defaultValue={changerow.billno}
                  displayLabel={"Invoice No"}
                  type="text"
                  change
                  style={{ width: "150px" }}
                  apikey={"billno"}
                  alignment="column"
                  returnValue={setPi}
                  
                />

              </div> */}

              {/* <div
              style={{
                border: "1px dashed #dddd",
                padding: "5px",
                borderRadius: "5px",
              }}
            >
              <SaveDatePicker
                defaultValue={customerdata?.supplierrecno && expDeliveryDays}
                id={"po-exp-devlivery-date"}
                displayLabel={"Expected Delivery Date"}
                apiKey={"expecteddeliverydate"}
              />
            </div> */}

              {/* <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                <Button onClick={handleAdditem} >
                  Submit
                </Button></div> */}

              {/* <Button
                style={{ ...buttons }}
                type="default"
                onClick={() => {
                  setshowFilterModal(true);
                  setCount((p) => p + 1);
                }}
              >
                Search Sales Return
              </Button> */}
              <div onKeyDown={enterPress} style={{ marginTop: "8px" }}>
                <Button
                  //style={{ ...buttons }}
                  className="btn"
                  type="default"
                  onClick={(e) => setDisplayMessages(!displayMessages)}
                >
                  Add / View Messages
                </Button>
              </div>

              {/* <Space size="large"> */}

              {/* <div style={{ ...callbackTextInputStyles }}> */}
              {/* <CallbackTextInput
                displayLabel="Box"
                id="tr001-box"
                defaultValue={box}
                returnValue={(e) => setBox(e)}
                alignment="column"
                width="100px"
              />
              <CallbackTextInput
                displayLabel="Pieces"
                id="tr001-pieces"
                returnValue={(e) => setSelectedQty(e)}
                defaultValue={selectedQty}
                alignment="column"
                width="100px"
              />
              <CallbackTextInput
                displayLabel="Rate"
                id="tr001-rate"
                returnValue={(e) => setPurchaseRate(e)}
                defaultValue={purchaseRate}
                alignment="column"
                width="100px"
              /> */}
              {/* <div>
                  <p>Batch No</p>
                  <input
                    style={{ ...inputStyles,textAlign:"left" }}
                    value={batch}
                    id="tr004-input-batch"
                    onChange={handleBatchChange}
                    type="text"
                  />
                </div>
                <div>
                  <CallbackDateInput
                    id={"exp-date"}
                    displayLabel={"Expiry Date"}
                    apiKey={"expirydate"}
                    returnValue={setexpDate}
                   
                  
                  />
                </div>
                <div>
                  <p>Boxes</p>
                  <input
                    ref={boxRef}
                    style={{ ...inputStyles }}
                    value={box}
                    id="tr004-input-box"
                    onChange={handleBoxChange}
                    type="number"
                    onKeyDown={(event) => {
                      const { key } = event;
                      if (key === "Enter") {
                        event.target.value.length > 0 &&
                          piecesRef.current.focus();
                      }
                    }}
                  />
                </div>
                <div>
                  <p>Pieces</p>
                  <input
                    ref={piecesRef}
                    style={{ ...inputStyles }}
                    value={selectedQty}
                    id="tr004-input-pieces"
                    type="number"
                    onChange={handlePiecesChange}
                    onKeyDown={(event) => {
                      const { key } = event;
                      if (key === "Enter") {
                        event.target.value.length > 0 &&
                          rateRef.current.focus();
                      }
                    }}
                  />
                </div>
                <div>
                  <p>Rate</p>
                  <input
                    ref={rateRef}
                    type="number"
                    style={{ ...inputStyles }}
                    value={purchaseRate}
                    id="tr004-input-rate"
                    onChange={handleRateChange}
                    onKeyDown={(event) => {
                      const { key } = event;
                      if (key === "Enter") {
                        if (
                          event.target.value != 0 &&
                          event.target.value.length > 0
                        ) {
                          if (box == 0 && selectedQty == 0) {
                            alert("Enter non-zero values in Box / Pieces");
                            boxRef.current.focus();
                          } else {
                            btnRef.current.focus();
                          }
                        } else {
                          alert("Cannot enter 0 as rate");
                        }
                      }
                    }}
                  />
                </div>
                <div>
                  <p>Gross Amount</p>
                  <div style={{ ...grossAmtStyle }}>
                    {Number(grossAmt).toFixed(2)}
                  </div>
                </div> */}
              {/* <Button
                  ref={btnRef}
                  style={{ ...btnStyles }}
                  icon={<IoAperture size="1.2rem" />}
                  size="large"
                  onClick={() => {
                    if (purchaseRate == 0 || purchaseRate == undefined) {
                      alert("Enter Rate");
                      rateRef.current.focus();
                      return;
                    }
                    addItemstoTable();
                  }}
                >
                  Add
                </Button> */}
              {/* </div> */}

              {/* </Space> */}
            </form>
          </div>
        </Panel>
        <Panel header="Items Details" key="2">
          {/* Table for Selection  */}
          <div
            style={{ zIndex: 1, position: "relative", margin: "24px auto 0" }}
          >
            {rowData?.length > 0 && (
              <ItemsTable
                rowData={rowData.filter((item) => item?.returnableqty !== 0)}
                setRowData={setRowData}
                // setDatapayload={setDatapayload}  igstappl and igstrate validation
                igstappl={
                  typeof igstappl === "number" ||
                  (typeof igstappl === "string" && parseFloat(igstappl) > 0)
                    ? true
                    : typeof igstappl === "boolean"
                    ? igstappl
                    : false
                } /// here the igstapp getting string vale its converted to Number
                datapayload={datapayload}
                pageDetails={pageDetails}
                saleNumber={srNumber}
              />
            )}
          </div>
        </Panel>
      </Collapse>
    </div>
  );
}

export default SaleReturn;
// amol-temp - mainFrame width change to 99vw from 95vw
const mainFrame = {
  width: "99vw",
  margin: "auto",
};

const topRowFilters = {
  gap: "24px",
  display: "flex",
  flexWrap: "wrap",
  alignItems: "center",
};

// applying padding to align input rows inline
const callbackTextInputStyles = {
  display: "flex",
  flexWrap: "wrap",
  alignItems: "center",
  gap: "36px",
  paddingTop: "38px",
};

// add to table btn styles
const btnStyles = {
  display: "flex",
  alignItems: "center",
  gap: "8px",
  marginTop: "26px",
};

const inputStyles = {
  padding: "10px",
  border: "1px solid rgb(120,120,120)",
  width: "100px",
  fontSize: "16px",
  marginTop: "7px",
  borderRadius: "6px",
};

const grossAmtStyle = {
  textAlign: "right",
  border: "1px solid gray",
  padding: "4px 8px 4px 4px",
  fontSize: "18px",
  width: "120px",
  borderRadius: "8px",
  marginTop: "6px",
};

// amol-temp - change the values
const poNumberStyle = {
  // marginTop: "8px",
  border: "1px solid #dfdfdf",
  borderRadius: "8px",
  width: "180px",
  padding: "9px",
  fontSize: "16px",
  height: "39px",
};

const buttons = {
  // width: "200px",
  height: "40px",
  borderRadius: "8px",
  marginTop: "23px",
};

//~# getdomainsupplieritemlist api endpoint for Item name => pass payload as { supplierrecno : 78123 } for item name
//~# conversionfactor = boxes * conversionfactor + pieces =

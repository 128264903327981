import { Button, Popconfirm } from "antd";
import { AiFillDelete } from "react-icons/ai";
import { dateFormatter, removeByAttribute } from "../../CommonControllers/AppFunction";
import CallbackDateInput from "../../CommonControllers/Components/CallbackComponents/CallbackDateInput";
import SaveDatePicker from "../../CommonControllers/SaveComponents/SaveDatePicker";

export const piColumns = (
  igstappl,
  setRowData,
  rowData,
  scandata,
  setScandata,
  setOpenItem,
  purchaseInvDate,
  pageDetails
) => {
  console.log("rowData1", rowData);
  
  if (igstappl) {
    return [
      {
        title: " ",
        dataIndex: "active",
        key: "active",
        width: 80,
        render: (_, text) => {
          return (
            <Popconfirm
              title="Do you want to Cancel the changes?"
              onConfirm={(e) => {
                handleRemove(text, setRowData, rowData);
              }}
            >
              <Button icon={<AiFillDelete />}></Button>
            </Popconfirm>
          );
        },
      },
      {
        width: 350,
        title: "Item Description",
        dataIndex: "itemdescn",
        key: "itemdescn",
      },
      {
        width: 150,
        title: "Scanned Items",
        dataIndex: "scannedItems",
        align: "left", // nohit-388 set Align for column
        render: (_, data) => <p>{data?.itembatcharray?.length}</p>,
      },
      {
        title: "Scan Barcode",
        dataIndex: "barcode",
        align: "left", // nohit-388 set Align for column
        key: "barcode",
        width: "7%",
        width: 150,
        render: (_, text) => {
          console.log("Text is", text.itemasbatch);
          if (text.itemasbatch) {
            return (
              <Button
                onClick={() => {
                  setOpenItem(true);
                  handleChange(
                    text,
                    setRowData,
                    igstappl,
                    rowData,
                    scandata,
                    setScandata
                  );
                }}
                // disabled={text?.packingqtyaccepted !== 0 || text?.packingextraqtyaccepted !== 0}
              >
                Item Scan
              </Button>
            );
          } else return null;
        },
      },
      {
        hidden: !pageDetails?.wantpackingextra ? true : false,
        title: "Boxes",
        width: 80,
        dataIndex: "packingextraqty",
        key: "packingextraqty",
        render: (_, text) => {
          console.log("text of Boxes", _, text);
          return (
            <input
              type={"number"}
              className="tr001Input"
              style={{ width: "50px" }}
              //{Pradeep, task id -272}
              disabled={text.packingextrarecno > 0 ? false : true}
              defaultValue={text?.packingextraqty}
              //{start Pradeep, task id-238  }
              value={text?.packingextraqty}
              // onFocus={(e) => e.target.select()}
              onChange={(e) => {
                const regex = /^[0-9\b]+$/;

                // console.log("e.target.value", e.target.value)
                // console.log("conversionfactor11", e.target.value, text.conversionfactor, text.itembatcharray.length)

                //old validation on box and piece
                if (e.target.value === "" || regex.test(e.target.value)) {
                  text.packingextraqty = e.target.value;
                  //{start Pradeep, task id-238 }
                  handleChange(
                    text,
                    setRowData,
                    igstappl,
                    rowData,
                    scandata,
                    setScandata
                  );
                }

                //{start Pradeep, task id-238 }
                else if (e.target.value == "0" && text.conversionfactor != 0) {
                  console.log("hiiiop");
                  text.packingextraqty =
                    text.itembatcharray.length / text.conversionfactor || 1;
                  handleChange(
                    text,
                    setRowData,
                    igstappl,
                    rowData,
                    scandata,
                    setScandata
                  );
                  //{END         END }
                } else {
                  // console.log("1111113")
                  alert("Invalid Input");
                  e.target.value = "";
                  text.packingextraqty = e.target.value;
                  //{start Pradeep, task id-238 }
                  handleChange(
                    text,
                    setRowData,
                    igstappl,
                    rowData,
                    scandata,
                    setScandata
                  );
                }

                //new validation of zero qty

                // if (regex.test(e.target.value) && (Number(e.target.value) + Number(text.packingqty)) > 0) {
                //   text.packingextraqty = e.target.value;
                //   handleChange(text, setRowData, igstappl, rowData);
                // } else {
                //   e.target.value = text.packingextraqty
                //   alert("Box and Pieces cannot be 0")
                // }
              }}
              onBlur={(e) => {
                //{start Pradeep, task id-238 start}
                // let count = text.packingqty + (text.freepackingextraqty * (text.conversionfactor || 1)) + text.freepackingqty

                // if (count+Number(e.target.value) < text.itembatcharray.length) {
                //   alert(
                //     "Quantity cannot be less than scanned Quantity,\n First Remove Items from Scanned List"
                //   );

                //   if (text.conversionfactor != 0) {
                //     var x = 0

                //     if (count > text.itembatcharray.length) {
                //       x = 0
                //     } else {

                //       x = count - text.itembatcharray.length
                //     }
                //     text.packingextraqty = (x) / text.conversionfactor;
                //   } else {
                //     text.packingextraqty = 0;
                //   }
                //   handleChange(text, setRowData, igstappl, rowData, scandata, setScandata);
                // }

                if (e.target.value == "0" && text.conversionfactor != 0) {
                  if (Number(text.free) == 0) {
                    let count =
                      Number(text.packingqty) +
                      Number(
                        text.freepackingextraqty * (text.conversionfactor || 1)
                      ) +
                      Number(text.freepackingqty);

                    console.log("count", count);
                    if (count >= text.itembatcharray.length) {
                      text.packingextraqty = 0;
                    } else {
                      alert(
                        "Quantity cannot be less than scanned Quantity,\n First Remove Items from Scanned List"
                      );
                      // console.log("1234",text.itembatcharray.length -count)
                      text.packingextraqty =
                        (text.itembatcharray.length - count) /
                        text.conversionfactor;
                      // console.log("12345", text.freepackingextraqty)
                    }
                    handleChange(
                      text,
                      setRowData,
                      igstappl,
                      rowData,
                      scandata,
                      setScandata
                    );
                  } else {
                    e.target.value =
                      text?.itembatcharray?.length -
                      (Number(text?.packingqty) + Number(text?.free));
                    console.log("e.target.value", e.target.value);

                    if (e.target.value > 0) {
                      text.packingextraqty = Number(e.target.value);
                    } else {
                      text.packingextraqty = 0;
                    }

                    console.log("text.packingextraqty", text.packingextraqty);
                    handleChange(
                      text,
                      setRowData,
                      igstappl,
                      rowData,
                      scandata,
                      setScandata
                    );
                  }
                }

                //{END         END }

                // Validate the Entered Quantity with the new Entered Quantity
                if (
                  //{start Pradeep, task id-238 start}
                  Number(text?.qty) + Number(text?.free) <
                    text?.itembatcharray?.length &&
                  text.conversionfactor == 0
                  //{END         END }
                ) {
                  e.target.value =
                    text?.itembatcharray?.length -
                    (Number(text?.packingqty) + Number(text?.free));
                  console.log("e.target.value", e.target.value);

                  alert(
                    "Quantity cannot be less than scanned Quantity,\n First Remove Items from Scanned List"
                  );
                  text.packingextraqty = Number(e.target.value);
                  console.log("text.packingextraqty", text.packingextraqty);
                  handleChange(
                    text,
                    setRowData,
                    igstappl,
                    rowData,
                    scandata,
                    setScandata
                  );
                }

                // Validate the Entered Quantity with the new Entered Quantity

                if (text?.wantbatchno === 1) {
                  console.log("wantbatchno?.qty", text?.qty, text?.free);
                  if (Number(text?.qty) + Number(text?.free) == 0) {
                    alert("Quantity cannot be less than or equal to zero.");
                  }
                }
              }}
            />
          );
        },
        align: "right",
      },
      {
        title: "Pieces",
        width: 80,
        dataIndex: "packingqty",
        key: "packingqty",
        render: (_, text) => {
          console.log("text of Pieces", text);
          return (
            <input
              className="tr001Input"
              type={"number"}
              //{Pradeep, task id -272}
              //disabled={text.packingextrarecno==0? false:true}
              style={{ width: "50px" }}
              defaultValue={text.packingqty}
              onFocus={(e) => e.target.select()}
              onChange={(e) => {
                const regex = /^[0-9\b]+$/;

                console.log("text--", text);

                if (e.target.value === "" || regex.test(e.target.value)) {
                  text.packingqty = e.target.value;
                  //{Pradeep, task id-238 }
                  handleChange(
                    text,
                    setRowData,
                    igstappl,
                    rowData,
                    scandata,
                    setScandata
                  );
                } else {
                  alert("Invalid Input");
                  e.target.value = "";
                  text.packingqty = e.target.value;
                  // Pradeep, task id-238 }
                  handleChange(
                    text,
                    setRowData,
                    igstappl,
                    rowData,
                    scandata,
                    setScandata
                  );
                }

                // if (regex.test(e.target.value) && (Number(e.target.value) + Number(text.packingextraqty)) > 0) {
                //   text.packingqty = e.target.value;
                //   handleChange(text, setRowData, igstappl, rowData);
                // } else {
                //   e.target.value = text.packingqty
                //   alert("Box and Pieces cannot be 0")
                // }
              }}
              onBlur={(e) => {
                console.log(
                  "text?.qty",
                  text?.qty,
                  text?.free,
                  text?.itembatcharray?.length
                );
                // Validate the Entered Quantity with the new Entered Quantity
                if (
                  Number(text?.qty) + Number(text?.free) <
                  text?.itembatcharray?.length
                ) {
                  e.target.value =
                    text?.itembatcharray?.length -
                    (Number(text?.packingextraqty) + Number(text?.free));

                  console.log("e.target.value", e.target.value);
                  alert(
                    "Quantity cannot be less than scanned Quantity,\n First Remove Items from Scanned List"
                  );
                  console.log("text.packingqty pieces", text.packingqty);
                  text.packingqty = Number(e.target.value);
                  // { Pradeep, task id-238 }
                  handleChange(
                    text,
                    setRowData,
                    igstappl,
                    rowData,
                    scandata,
                    setScandata
                  );
                  // console.log("E",handleChange(text, setRowData, igstappl, rowData))
                }

                if (text?.wantbatchno === 1) {
                  console.log("wantbatchno?.qty", text?.qty, text?.free);
                  if (Number(text?.qty) + Number(text?.free) == 0) {
                    alert("Quantity cannot be less zero");
                  }
                }
              }}
            ></input>
          );
        },
        align: "right",
      },
      {
        width: 80,
        title: "Qty",
        dataIndex: "qty",
        key: "qty",
        align: "right",
      },

      {
        width: 120,
        title: "Free Boxes",
        dataIndex: "freepackingextraqty",
        key: "freepackingextraqty",
        render: (_, text) => {
          return (
            <input
              type={"number"}
              //{Pradeep, task id -272}
              disabled={text.packingextrarecno > 0 ? false : true}
              className="tr001Input"
              defaultValue={text.freepackingextraqty}
              value={text.freepackingextraqty}
              onFocus={(e) => e.target.select()}
              onChange={(e) => {
                const regex = /^[0-9\b]+$/;
                if (e.target.value === "" || regex.test(e.target.value)) {
                  text.freepackingextraqty = e.target.value;
                  // { Pradeep, task id-238 }
                  handleChange(
                    text,
                    setRowData,
                    igstappl,
                    rowData,
                    scandata,
                    setScandata
                  );
                } else if (
                  e.target.value === "0" &&
                  text.conversionfactor != 0
                ) {
                  console.log("hiiis");
                  text.freepackingextraqty =
                    text.itembatcharray.length / text.conversionfactor || 1;
                  handleChange(
                    text,
                    setRowData,
                    igstappl,
                    rowData,
                    scandata,
                    setScandata
                  );
                  //{END         END }
                } else {
                  alert("Invalid Input");
                  e.target.value = "";
                  text.freepackingextraqty = e.target.value;
                  // { Pradeep, task id-238 }
                  handleChange(
                    text,
                    setRowData,
                    igstappl,
                    rowData,
                    scandata,
                    setScandata
                  );
                }
              }}
              onBlur={(e) => {
                // {start  Pradeep, task id-238  start}
                if (e.target.value == "0" && text.conversionfactor != 0) {
                  if (Number(text.qty) == 0) {
                    let count =
                      Number(text.packingqty) +
                      Number(
                        text.packingextraqty * (text.conversionfactor || 1)
                      ) +
                      Number(text.freepackingqty);

                    console.log("count", count);
                    if (count >= text.itembatcharray.length) {
                      text.freepackingextraqty = 0;
                    } else {
                      alert(
                        "Quantity cannot be less than scanned Quantity,\n First Remove Items from Scanned List"
                      );
                      // console.log("1234",text.itembatcharray.length -count)
                      text.freepackingextraqty =
                        (text.itembatcharray.length - count) /
                        text.conversionfactor;
                      // console.log("12345", text.freepackingextraqty)
                    }
                    handleChange(
                      text,
                      setRowData,
                      igstappl,
                      rowData,
                      scandata,
                      setScandata
                    );
                  } else {
                    e.target.value =
                      text?.itembatcharray?.length -
                      (Number(text?.qty) + Number(text?.freepackingqty));
                    if (e.target.value > 0) {
                      text.freepackingextraqty = Number(e.target.value);
                    } else {
                      text.freepackingextraqty = 0;
                    }

                    // { Pradeep, task id-238 }
                    handleChange(
                      text,
                      setRowData,
                      igstappl,
                      rowData,
                      scandata,
                      setScandata
                    );
                  }
                }
                //{ end       end }

                // Validate the Entered Quantity with the new Entered Quantity
                if (
                  Number(text?.qty) + Number(text?.free) <
                    // { Pradeep, task id-238 }
                    text?.itembatcharray?.length &&
                  text.conversionfactor == 0
                ) {
                  e.target.value =
                    text?.itembatcharray?.length -
                    (Number(text?.qty) + Number(text?.freepackingqty));
                  alert(
                    "Quantity cannot be less than scanned Quantity,\n First Remove Items from Scanned List"
                  );
                  text.freepackingextraqty = e.target.value;
                  // { Pradeep, task id-238 }
                  handleChange(
                    text,
                    setRowData,
                    igstappl,
                    rowData,
                    scandata,
                    setScandata
                  );
                }

                if (text?.wantbatchno === 1) {
                  console.log("wantbatchno?.qty", text?.qty, text?.free);
                  if (Number(text?.qty) + Number(text?.free) == 0) {
                    alert("Quantity cannot be less than or equal to zero.");
                  }
                }
              }}
            ></input>
          );
        },
        align: "right",
        hidden:
          pageDetails?.wantfreeqty && !pageDetails?.wantpackingextra
            ? false
            : true,
      },
      {
        width: 120,
        hidden: pageDetails?.wantfreeqty ? false : true,
        title: "Free Pieces",
        dataIndex: "freepackingqty",
        key: "freepackingqty",
        align: "right", // nohit-388 set Align for column
        render: (_, text) => {
          return (
            <input
              className="tr001Input"
              //{Pradeep, task id -272}
              disabled={text.packingextrarecno == 0 ? false : true}
              type={"number"}
              defaultValue={text.freepackingqty}
              onFocus={(e) => e.target.select()}
              onChange={(e) => {
                const regex = /^[0-9\b]+$/;
                if (e.target.value === "" || regex.test(e.target.value)) {
                  text.freepackingqty = e.target.value;
                  // { Pradeep, task id-238 }
                  handleChange(
                    text,
                    setRowData,
                    igstappl,
                    rowData,
                    scandata,
                    setScandata
                  );
                } else {
                  alert("Invalid Input");
                  e.target.value = "";
                  text.freepackingqty = e.target.value;
                  // { Pradeep, task id-238 }
                  handleChange(
                    text,
                    setRowData,
                    igstappl,
                    rowData,
                    scandata,
                    setScandata
                  );
                }
              }}
              onBlur={(e) => {
                // Validate the Entered Quantity with the new Entered Quantity
                if (
                  Number(text?.qty) + Number(text?.free) <
                  text?.itembatcharray?.length
                ) {
                  e.target.value =
                    text?.itembatcharray?.length -
                    (Number(text?.qty) + Number(text?.freepackingextraqty));
                  alert(
                    "Quantity cannot be less than scanned Quantitexisting Objecy,\n First Remove Items from Scanned List"
                  );
                  text.freepackingqty = e.target.value;
                  // { Pradeep, task id-238 }
                  handleChange(
                    text,
                    setRowData,
                    igstappl,
                    rowData,
                    scandata,
                    setScandata
                  );
                }
                if (text?.wantbatchno === 1) {
                  console.log("wantbatchno?.qty", text?.qty, text?.free);
                  if (Number(text?.qty) + Number(text?.free) == 0) {
                    alert("Quantity cannot be less than or equal to zero.");
                  }
                }
              }}
            ></input>
          );
        },
      },
      {
        hidden: pageDetails?.wantfreeqty ? false : true,
        width: 100,
        title: "Free Qty",
        dataIndex: "free",
        key: "free",
        align: "right",
      },

      {
        hidden: pageDetails?.wantbatchno ? false : true,
        width: 180,
        title: "Batch No",
        dataIndex: "itembatchno",
        key: "itembatchno",
        render: (_, text) => {
          if (text?.wantbatchno) {
            return (
              <input
                className="tr001Input"
                onFocus={(e) => e.target.select()}
                style={{ textAlign: "left" }}
                defaultValue={text.itembatchno}
                onChange={(e) => {
                  text.itembatchno = e.target.value;
                }}
                onBlur={(e) => {
                  let existingItem = rowData.filter((item) => {
                    if (
                      item.itemrecno == text.itemrecno &&
                      item.itembatchno == text.itembatchno &&
                      item.shortguid != text.shortguid
                    ) {
                      return item;
                    }
                  });
                  if (existingItem?.length > 0) {
                    alert("Duplicate Batch Number for Same Item");
                    text.itembatchno = "";
                    e.target.value = "";
                  } else {
                    // { Pradeep, task id-238 }
                    handleChange(
                      text,
                      setRowData,
                      igstappl,
                      rowData,
                      scandata,
                      setScandata
                    );
                  }
                }}
              ></input>
            );
          } else {
            return <p>.</p>
          }
        },
      },
      {
        hidden: pageDetails?.wantmanudate === 1 ? false : true,
        width: 300,
        title: "Mfg Date",
        dataIndex: "manufacturedate",
        key: "manufacturedate",
        render: (_, text) => {
          if (text?.wantbatchno) {

            return (
              <CallbackDateInput
                defaultValue={
                  typeof text.manufacturedate == "number"
                    ? text.manufacturedate.toString()
                    : text.manufacturedate
                }
                id={"po-manufacturedate-" + text.shortguid}
                apiKey={"manufacturedate"}
                
                returnValue={(e) => {
                  text.manufacturedate = e;
                  // { Pradeep, task id-238 }
                  handleChange(
                    text,
                    setRowData,
                    igstappl,
                    rowData,
                    scandata,
                    setScandata
                  );
                }}
                dateLessthan={purchaseInvDate}
                initial={pageDetails?.manudatemonthend ? false : true}
                postAlertMsg={"Manufacture date must be less than PI date"}
              />
            );
          } else {
            return (<p>{dateFormatter(text?.manufacturedate)}</p>)
          }
        },
        align: "left", // nohit-388 set Align for column
      },
      {
        hidden: pageDetails?.wantexpdate === 1 ? false : true,
        width: 300,
        title: "Exp Date",
        dataIndex: "expdate",
        key: "expdate",
        render: (_, text) => {
          if (text?.wantbatchno) {
          return (
            <CallbackDateInput
              defaultValue={
                typeof text.expdate == "number"
                  ? text.expdate.toString()
                  : text.expdate
              }
              id={"po-expdate-" + text.shortguid}
              apiKey={"expecteddeliverydate"}
              returnValue={(e) => {
                text.expdate = e;
                // { Pradeep, task id-238 }
                handleChange(
                  text,
                  setRowData,
                  igstappl,
                  rowData,
                  scandata,
                  setScandata
                );
              }}
              initial={pageDetails?.expdatemonthend ? false : true}
              dateGreaterthan={text.manufacturedate}
            />
          );} else  {
            return (<p>{dateFormatter(text?.expdate)}</p>)
          }
        },
        align: "left", // nohit-388 set Align for column
      },
      {
        width: 140,
        title: "Rate",
        dataIndex: "purrate",
        key: "purrate",
        // render: (_, text) => {
        //   return (
        //     <input
        //       className="tr001Input"
        //       defaultValue={text.purrate}
        //       onFocus={(e) => e.target.select()}
        //       onChange={(e) => {
        //         const regex = /^\d*(\.\d{0,2})?$/;
        //         if (e.target.value === "" || regex.test(e.target.value)) {
        //           text.purrate = e.target.value;
        //           handleChange(text, setRowData, igstappl, rowData);
        //         } else {
        //           alert("Invalid Input / Only 2 decimal places allowed");
        //           e.target.value = "";
        //           text.purrate = e.target.value;
        //           handleChange(text, setRowData, igstappl, rowData);
        //         }
        //       }}
        //     ></input>
        //   );
        // },
        align: "right",
      },
      {
        width: 140,
        title: "Gross Amount",
        key: "grossamt",
        dataIndex: "grossamt",
        align: "right",
      },
      {
        width: 120,
        title: "Discount %",
        dataIndex: "discountrate",
        key: "discountrate",
        // render: (_, text) => {
        //   return (
        //     <input
        //       className="tr001Input"
        //       defaultValue={text?.discount}
        //       onFocus={(e) => e.target.select()}
        //       onChange={(e) => {
        //         text.discount = e.target.value;
        //         handleChange(text, setRowData, false, rowData);
        //       }}
        //       value={text.discount}
        //     ></input>
        //   );
        // },
        align: "right",
      },
      {
        width: 120,
        title: "Discount",
        dataIndex: "discountamt",
        key: "discountamt",
        render: (_, text) => {
          return (
            <input
              className="tr001Input"
              defaultValue={text.discountamt}
              onFocus={(e) => e.target.select()}
              onChange={(e) => {
                text.discountamt = e.target.value;
                // { Pradeep, task id-238 }
                handleChange(text, setRowData, igstappl, rowData, scandata);
              }}
            ></input>
          );
        },
        align: "right",
      },
      {
        width: 160,
        title: "Taxable Amount",
        dataIndex: "taxableamount",
        key: "taxableamount",
        align: "right",
      },
      {
        width: 120,
        title: "IGST%",
        dataIndex: "igstrate",
        key: "igstrate",
        align: "right",
      },
      {
        width: 120,
        title: "IGST",
        dataIndex: "igstamt",
        key: "igstamt",
        align: "right",
      },
      {
        width: 150,
        title: "Amount",
        dataIndex: "amount",
        key: "amount",
        align: "right",
      },
    
    ];
  } else {
    return [
      {
        title: " ",
        dataIndex: "active",
        key: "active",
        width: 80,
        render: (_, text) => {
          return (
            <Popconfirm
              title="Do you want to Cancel the changes?"
              onConfirm={(e) => {
                handleRemove(text, setRowData, rowData);
              }}
            >
              <Button icon={<AiFillDelete />}></Button>
            </Popconfirm>
          );
        },
      },
      {
        width: 350,
        title: "Item Description",
        dataIndex: "itemdescn",
        key: "itemdescn",
      },
      {
        width: 150,
        title: "Scanned Items",
        dataIndex: "scannedItems",
        align: "right",
        render: (_, data) => <p>{data?.itembatcharray?.length}</p>,
      },
      {
        title: "Scan Barcode",
        dataIndex: "barcode",
        align: "right",
        key: "barcode",
        width: 150,
        render: (_, text) => {
          console.log("Text is", text);
          if (text.itemasbatch) {
            return (
              <Button
                onClick={() => {
                  setOpenItem(true);
                  handleChange(
                    text,
                    setRowData,
                    igstappl,
                    rowData,
                    scandata,
                    setScandata
                  );
                }}
                // disabled={text?.packingqtyaccepted !== 0 || text?.packingextraqtyaccepted !== 0}
              >
                Item Scan
              </Button>
            );
          } else return null;
        },
      },
      {
        hidden: !pageDetails?.wantpackingextra ? true : false,
        title: "Boxes",
        width: 80,
        dataIndex: "packingextraqty",
        key: "packingextraqty",
        render: (_, text) => {
          return (
            <input
              type={"number"}
              className="tr001Input"
              style={{ width: "50px" }}
              //{Pradeep, task id -272}
              disabled={text.packingextrarecno > 0 ? false : true}
              // { Pradeep, task id-238 }
              value={text.packingextraqty}
              defaultValue={text.packingextraqty}
              onFocus={(e) => e.target.select()}
              onChange={(e) => {
                const regex = /^[0-9\b]+$/;
                if (e.target.value === "" || regex.test(e.target.value)) {
                  text.packingextraqty = e.target.value;
                  handleChange(text, setRowData, igstappl, rowData);
                }
                // {start  Pradeep, task id-238 start}
                else if (e.target.value == "0" && text.conversionfactor != 0) {
                  text.packingextraqty =
                    text.itembatcharray.length / text.conversionfactor || 1;
                  handleChange(
                    text,
                    setRowData,
                    igstappl,
                    rowData,
                    scandata,
                    setScandata
                  );
                }
                // { end     end  }
                else {
                  alert(`Invalid Input`);
                  e.target.value = "";
                  text.packingextraqty = e.target.value;
                  handleChange(text, setRowData, igstappl, rowData);
                }
              }}
              onBlur={(e) => {
                //{start Pradeep, task id-238 start}
                if (e.target.value == "0" && text.conversionfactor != 0) {
                  if (Number(text.free) == 0) {
                    let count =
                      Number(text.packingqty) +
                      Number(
                        text.freepackingextraqty * (text.conversionfactor || 1)
                      ) +
                      Number(text.freepackingqty);

                    console.log("count", count);
                    if (count >= text.itembatcharray.length) {
                      text.packingextraqty = 0;
                    } else {
                      alert(
                        "Quantity cannot be less than scanned Quantity,\n First Remove Items from Scanned List"
                      );
                      // console.log("1234",text.itembatcharray.length -count)
                      text.packingextraqty =
                        (text.itembatcharray.length - count) /
                        text.conversionfactor;
                      // console.log("12345", text.freepackingextraqty)
                    }
                    handleChange(
                      text,
                      setRowData,
                      igstappl,
                      rowData,
                      scandata,
                      setScandata
                    );
                  } else {
                    e.target.value =
                      text?.itembatcharray?.length -
                      (Number(text?.packingqty) + Number(text?.free));
                    console.log("e.target.value", e.target.value);

                    if (e.target.value > 0) {
                      text.packingextraqty = Number(e.target.value);
                    } else {
                      text.packingextraqty = 0;
                    }

                    console.log("text.packingextraqty", text.packingextraqty);
                    handleChange(
                      text,
                      setRowData,
                      igstappl,
                      rowData,
                      scandata,
                      setScandata
                    );
                  }
                }

                //{END         END }

                // Validate the Entered Quantity with the new Entered Quantity
                if (
                  //{start Pradeep, task id-238 start}
                  Number(text?.qty) + Number(text?.free) <
                    text?.itembatcharray?.length &&
                  text.conversionfactor == 0
                  //{END         END }
                ) {
                  e.target.value =
                    text?.itembatcharray?.length -
                    (Number(text?.packingqty) + Number(text?.free));
                  console.log("e.target.value", e.target.value);

                  alert(
                    "Quantity cannot be less than scanned Quantity,\n First Remove Items from Scanned List"
                  );
                  text.packingextraqty = Number(e.target.value);
                  console.log("text.packingextraqty", text.packingextraqty);
                  handleChange(
                    text,
                    setRowData,
                    igstappl,
                    rowData,
                    scandata,
                    setScandata
                  );
                }

                // Validate the Entered Quantity with the new Entered Quantity

                if (text?.wantbatchno === 1) {
                  console.log("wantbatchno?.qty", text?.qty, text?.free);
                  if (Number(text?.qty) + Number(text?.free) == 0) {
                    alert("Quantity cannot be less than or equal to zero.");
                  }
                }
              }}
            />
          );
        },
        align: "right",
      },
      {
        title: "Pieces",
        width: 80,
        dataIndex: "packingqty",
        key: "packingqty",
        render: (_, text) => {
          return (
            <input
              type={"number"}
              //{Pradeep, task id -272}
              //disabled={text.packingextrarecno==0? false:true}
              className="tr001Input"
              style={{ width: "50px" }}
              defaultValue={text.packingqty}
              onFocus={(e) => e.target.select()}
              onChange={(e) => {
                const regex = /^[0-9\b]+$/;
                if (e.target.value === "" || regex.test(e.target.value)) {
                  text.packingqty = e.target.value;
                  handleChange(text, setRowData, igstappl, rowData);
                } else {
                  alert("Invalid Input");
                  e.target.value = "";
                  text.packingqty = e.target.value;
                  handleChange(text, setRowData, igstappl, rowData);
                }
              }}
              onBlur={(e) => {
                // Validate the Entered Quantity with the new Entered Quantity

                // if (
                //   text?.itemasbatch &&
                //   Number(text?.qty) + Number(text?.free) <
                //     text?.itembatcharray?.length
                // )

                if (
                  Number(text?.qty) + Number(text?.free) <
                  text?.itembatcharray?.length
                ) {
                  e.target.focus();

                  e.target.value =
                    text?.itembatcharray?.length -
                    (Number(text?.packingextraqty) + Number(text?.free));

                  // let itemBatchArr =
                  //   text?.itembatcharray?.length -
                  //   (Number(text?.qty) + Number(text?.free));
                  // console.log("text.packingqty1",text.packingqty)
                  //  e.target.value =
                  //   Number(text.packingqty) + Number(itemBatchArr);
                  alert(
                    "Quantity cannot be less than scanned Quantity,\n First Remove Items from Scanned List"
                  );
                  // text.packingqty =
                  //   Number(text.packingqty) + Number(itemBatchArr);
                  //     console.log("text.packingqty2",text.packingqty)
                  text.packingqty = e.target.value;
                  handleChange(text, setRowData, igstappl, rowData);
                }
              }}
            ></input>
          );
        },
        align: "right",
      },
      {
        width: 80,
        title: "Qty",
        dataIndex: "qty",
        key: "qty",
        align: "right",
      },
      {
        hidden:
          pageDetails?.wantfreeqty && !pageDetails?.wantpackingextra
            ? false
            : true,
        width: 120,
        title: "Free Boxes",
        dataIndex: "freepackingextraqty",
        key: "freepackingextraqty",
        render: (_, text) => {
          return (
            <input
              //{Pradeep, task id -272}
              disabled={text.packingextrarecno > 0 ? false : true}
              type={"number"}
              className="tr001Input"
              defaultValue={text.freepackingextraqty}
              value={text.freepackingextraqty}
              onFocus={(e) => e.target.select()}
              onChange={(e) => {
                const regex = /^[0-9\b]+$/;
                if (e.target.value === "" || regex.test(e.target.value)) {
                  text.freepackingextraqty = e.target.value;
                  // { Pradeep, task id-238 }
                  handleChange(
                    text,
                    setRowData,
                    igstappl,
                    rowData,
                    scandata,
                    setScandata
                  );
                } else if (
                  e.target.value === "0" &&
                  text.conversionfactor != 0
                ) {
                  console.log("hiiis");
                  text.freepackingextraqty =
                    text.itembatcharray.length / text.conversionfactor || 1;
                  handleChange(
                    text,
                    setRowData,
                    igstappl,
                    rowData,
                    scandata,
                    setScandata
                  );
                  //{END         END }
                } else {
                  alert("Invalid Input");
                  e.target.value = "";
                  text.freepackingextraqty = e.target.value;
                  // { Pradeep, task id-238 }
                  handleChange(
                    text,
                    setRowData,
                    igstappl,
                    rowData,
                    scandata,
                    setScandata
                  );
                }
              }}
              onBlur={(e) => {
                // {start  Pradeep, task id-238  start}
                if (e.target.value == "0" && text.conversionfactor != 0) {
                  if (Number(text.qty) == 0) {
                    let count =
                      Number(text.packingqty) +
                      Number(
                        text.packingextraqty * (text.conversionfactor || 1)
                      ) +
                      Number(text.freepackingqty);

                    console.log("count", count);
                    if (count >= text.itembatcharray.length) {
                      text.freepackingextraqty = 0;
                    } else {
                      alert(
                        "Quantity cannot be less than scanned Quantity,\n First Remove Items from Scanned List"
                      );
                      // console.log("1234",text.itembatcharray.length -count)
                      text.freepackingextraqty =
                        (text.itembatcharray.length - count) /
                        text.conversionfactor;
                      // console.log("12345", text.freepackingextraqty)
                    }
                    handleChange(
                      text,
                      setRowData,
                      igstappl,
                      rowData,
                      scandata,
                      setScandata
                    );
                  } else {
                    e.target.value =
                      text?.itembatcharray?.length -
                      (Number(text?.qty) + Number(text?.freepackingqty));
                    if (e.target.value > 0) {
                      text.freepackingextraqty = Number(e.target.value);
                    } else {
                      text.freepackingextraqty = 0;
                    }

                    // { Pradeep, task id-238 }
                    handleChange(
                      text,
                      setRowData,
                      igstappl,
                      rowData,
                      scandata,
                      setScandata
                    );
                  }
                }
                //{ end       end }

                // Validate the Entered Quantity with the new Entered Quantity
                if (
                  Number(text?.qty) + Number(text?.free) <
                    // { Pradeep, task id-238 }
                    text?.itembatcharray?.length &&
                  text.conversionfactor == 0
                ) {
                  e.target.value =
                    text?.itembatcharray?.length -
                    (Number(text?.qty) + Number(text?.freepackingqty));
                  alert(
                    "Quantity cannot be less than scanned Quantity,\n First Remove Items from Scanned List"
                  );
                  text.freepackingextraqty = e.target.value;
                  // { Pradeep, task id-238 }
                  handleChange(
                    text,
                    setRowData,
                    igstappl,
                    rowData,
                    scandata,
                    setScandata
                  );
                }

                if (text?.wantbatchno === 1) {
                  console.log("wantbatchno?.qty", text?.qty, text?.free);
                  if (Number(text?.qty) + Number(text?.free) == 0) {
                    alert("Quantity cannot be less than or equal to zero.");
                  }
                }
              }}
            ></input>
          );
        },
        align: "right",
      },
      {
        hidden: pageDetails?.wantfreeqty ? false : true,
        width: 120,
        title: "Free Pieces",
        dataIndex: "freepackingqty",
        key: "freepackingqty",
        render: (_, text) => {
          return (
            <input
              className="tr001Input"
              //{Pradeep, task id -272}
              disabled={text.packingextrarecno == 0 ? false : true}
              type={"number"}
              defaultValue={text.freepackingqty}
              onFocus={(e) => e.target.select()}
              onChange={(e) => {
                const regex = /^[0-9\b]+$/;
                if (e.target.value === "" || regex.test(e.target.value)) {
                  text.freepackingqty = e.target.value;
                  handleChange(text, setRowData, false, rowData);
                } else {
                  alert("Invalid Input");
                  e.target.value = "";
                  text.freepackingqty = e.target.value;
                  handleChange(text, setRowData, false, rowData);
                }
              }}
              onBlur={(e) => {
                // Validate the Entered Quantity with the new Entered Quantity
                if (
                  Number(text?.qty) + Number(text?.free) <
                  text?.itembatcharray?.length
                ) {
                  e.target.value =
                    text?.itembatcharray?.length -
                    (Number(text?.qty) + Number(text?.freepackingextraqty));
                  alert(
                    "Quantity cannot be less than scanned Quantity,\n First Remove Items from Scanned List"
                  );
                  text.freepackingqty = e.target.value;
                  handleChange(text, setRowData, igstappl, rowData);
                }
              }}
            ></input>
          );
        },
        align: "right",
      },
      {
        hidden: pageDetails?.wantfreeqty ? false : true,
        width: 100,
        title: "Free Qty",
        dataIndex: "free",
        key: "free",
        align: "right",
      },

      {
        hidden: pageDetails?.wantbatchno ? false : true,
        width: 180,
        title: "Batch No",
        dataIndex: "itembatchno",
        key: "itembatchno",
        render: (_, text) => {
          if (text?.wantbatchno) {
            return (
              <input
                className="tr001Input"
                onFocus={(e) => e.target.select()}
                style={{ textAlign: "left" }}
                defaultValue={text.itembatchno}
                onChange={(e) => {
                  text.itembatchno = e.target.value;
                }}
                onBlur={(e) => {
                  let existingItem = rowData.filter((item) => {
                    if (
                      item.itemrecno == text.itemrecno &&
                      item.itembatchno == text.itembatchno &&
                      item.shortguid != text.shortguid
                    ) {
                      return item;
                    }
                  });
                  if (existingItem?.length > 0) {
                    alert("Duplicate Batch Number for Same Item");
                    text.itembatchno = "";
                    e.target.value = "";
                  } else {
                    // { Pradeep, task id-238 }
                    handleChange(
                      text,
                      setRowData,
                      igstappl,
                      rowData,
                      scandata,
                      setScandata
                    );
                  }
                }}
              ></input>
            );
          } else {
            return <p>.</p>
          }
        },
      },
      {
        hidden: pageDetails?.wantmanudate === 1 ? false : true,
        width: 300,
        title: "Mfg. Date",
        dataIndex: "manufacturedate",
        key: "manufacturedate",
        render: (_, text) => {
          if (text?.wantbatchno) {

            return (
              <CallbackDateInput
                defaultValue={
                  typeof text.manufacturedate == "number"
                    ? text.manufacturedate.toString()
                    : text.manufacturedate
                }
                id={"po-manufacturedate-" + text.shortguid}
                apiKey={"manufacturedate"}
                
                returnValue={(e) => {
                  text.manufacturedate = e;
                  // { Pradeep, task id-238 }
                  handleChange(
                    text,
                    setRowData,
                    igstappl,
                    rowData,
                    scandata,
                    setScandata
                  );
                }}
                dateLessthan={purchaseInvDate}
                initial={pageDetails?.manudatemonthend ? false : true}
                postAlertMsg={"Manufacture date must be less than PI date"}
              />
            );
          } else {
            return (<p>{dateFormatter(text?.manufacturedate)}</p>)
          }
        },
        align: "left", // nohit-388 set Align for column
      },
      {
        hidden: pageDetails?.wantexpdate === 1 ? false : true,
        width: 300,
        title: "Exp. Date",
        dataIndex: "expdate",
        key: "expdate",
        render: (_, text) => {
          if (text?.wantbatchno) {
          return (
            <CallbackDateInput
              defaultValue={
                typeof text.expdate == "number"
                  ? text.expdate.toString()
                  : text.expdate
              }
              id={"po-expdate-" + text.shortguid}
              apiKey={"expecteddeliverydate"}
              returnValue={(e) => {
                text.expdate = e;
                // { Pradeep, task id-238 }
                handleChange(
                  text,
                  setRowData,
                  igstappl,
                  rowData,
                  scandata,
                  setScandata
                );
              }}
              initial={pageDetails?.expdatemonthend ? false : true}
              dateGreaterthan={text.manufacturedate}
            />
          );} else  {
            return (<p>{dateFormatter(text?.expdate)}</p>)
          }
        },
        align: "left", // nohit-388 set Align for column
      },
      {
        width: 120,
        title: "Rate",
        dataIndex: "purrate",
        key: "purrate",
        // render: (_, text) => {
        //   return (
        //     <input
        //       className="tr001Input"
        //       type={"number"}
        //       defaultValue={text.purrate}
        //       onFocus={(e) => e.target.select()}
        //       onChange={(e) => {
        //         const regex = /^\d*(\.\d{0,2})?$/;
        //         if (e.target.value === "" || regex.test(e.target.value)) {
        //           text.purrate = e.target.value;
        //           handleChange(text, setRowData, false, rowData);
        //         } else {
        //           alert("Invalid Input / Only 2 decimal place allowed");
        //           e.target.value = "";
        //           text.purrate = e.target.value;
        //           handleChange(text, setRowData, false, rowData);
        //         }
        //       }}
        //     />
        //   );
        // },
        align: "right",
      },
      {
        width: 140,
        title: "Gross Amount",
        key: "grossamt",
        dataIndex: "grossamt",
        align: "right",
      },
      {
        width: 120,
        title: "Discount %",
        dataIndex: "discountrate",
        key: "discountrate",
        // render: (_, text) => {
        //   return (
        //     <input
        //       type={"number"}
        //       className="tr001Input"
        //       defaultValue={text?.discount ? text.discount : ""}
        //       onFocus={(e) => e.target.select()}
        //       onChange={(e) => {
        //         const regex = /^\d*(\.\d{0,2})?$/;
        //         if (e.target.value === "" || regex.test(e.target.value)) {
        //           text.discount = e.target.value;
        //           handleChange(text, setRowData, false, rowData);
        //         } else {
        //           alert("Invalid input / Only 2 decimal places allowed");
        //           e.target.value = "";
        //           text.discount = e.target.value;
        //           handleChange(text, setRowData, false, rowData);
        //         }
        //       }}
        //     />
        //   );
        // },
        align: "right",
      },
      {
        width: 120,
        title: "Discount",
        dataIndex: "discountamt",
        key: "discountamt",
        render: (_, text) => {
          return (
            <input
              className="tr001Input"
              type={"number"}
              defaultValue={text.discountamt ? text?.discountamt : ""}
              onFocus={(e) => e.target.select()}
              onChange={(e) => {
                const regex = /^\d*(\.\d{0,2})?$/;
                if (e.target.value === "" || regex.test(e.target.value)) {
                  text.discountamt = e.target.value;
                  handleChange(text, setRowData, false, rowData);
                } else {
                  alert("Invalid Input / Only 2 decimal place allowed");
                  e.target.value = "";
                  text.discountamt = e.target.value;
                  handleChange(text, setRowData, false, rowData);
                }
              }}
            ></input>
          );
        },
        align: "right",
      },
      {
        width: 150,
        title: "Taxable Amount",
        dataIndex: "taxableamount",
        key: "taxableamount",
        align: "right",
      },
      {
        width: 120,
        title: "CGST %",
        dataIndex: "cgstrate",
        key: "cgstrate",
        align: "right",
      },
      {
        width: 120,
        title: "CGST",
        dataIndex: "cgstamt",
        key: "cgst",
        align: "right",
      },
      {
        width: 120,
        title: "SGST%",
        dataIndex: "sgstrate",
        key: "sgstrate",
        align: "right",
      },
      {
        width: 120,
        title: "SGST",
        dataIndex: "sgstamt",
        key: "sgst",
        align: "right",
      },
      {
        width: 150,
        title: "Amount",
        dataIndex: "amount",
        key: "amount",
        align: "right",
      },
     
    ];
  }
};

export const handleAdd = (
  item,
  setRowData,
  igstappl,
  setSelectedItem,
  shortguid,
  purchaseRate,
  purchaseQty,
  box,
  freeBox,
  freeQty,
  batchNo,
  expDate,
  discountAmt,
  discountPer,
  manuDate,
  grossAmt,
  taxableAmt,
  workdate
) => {
  var newShortguid = shortguid.getShortguid("POF" + item.itemrecno);
  console.log("Gnr1222", item);
  var purrate = purchaseRate ? purchaseRate : 0.0;
  var cgstrate = item?.cgstrate ? item.cgstrate : 0.0;
  var sgstrate = item?.sgstrate ? item.sgstrate : 0.0;
  var igstrate = item?.igstrate ? item.igstrate : 0.0;
  let conversionfactor = item?.conversionfactor ? item.conversionfactor : 1;
  var freepackingextraqty = freeBox ?  Number(freeBox ) : 0;
  var freepackingqty = freeQty ? Number(freeQty) : 0;
  var free =
    Number(freepackingqty) +
    Number(freepackingextraqty) * Number(conversionfactor);
  var discount = discountPer ? Number(discountPer) : 0.0;
  var discountamt = discountAmt ? Number(discountAmt) : 0.0;

  // taxable amout new formula-------------------------------
  // var taxableamount = Number(taxableAmt).toFixed(2); ----------------------old taxable formula---------
  var packingqty = purchaseQty ? Number(purchaseQty) : 0; //~# here qty of pieces changed to key-name packingqty, box will be freepackingqty
  var packingextraqty = box ? Number(box ): 0; //~# box value
  var taxableamount =
    Number(packingextraqty) * Number(item?.conversionfactor) +
      Number(packingqty) * Number(purrate) -
      Number(discountamt) || item?.taxableamount;
  console.log("taxableamount::", taxableamount);
  var grossamount =
    Number(item?.packingextraqty) * Number(item?.conversionfactor) +
      Number(item?.packingqty) * Number(item?.purrate) ||
    Number(packingextraqty) * Number(conversionfactor) +
      Number(packingqty) * Number(purrate);
  console.log("grossamount:", grossamount);
  //taxable amoutnt end-------------------------------------------------------

  var qty =
    Number(packingqty) + Number(packingextraqty) * Number(conversionfactor);
  console.log("Quantity", manuDate);

  var expdate = expDate ? expDate : null;
  var manudate = item?.wantbatchno === 0 && workdate ? workdate : manuDate;
  var itembatchno = batchNo ? batchNo : null;

  var sgstamt = !igstappl ? Number(taxableamount) * (sgstrate / 100) : 0.0;
  var cgstamt = !igstappl ? Number(taxableamount) * (cgstrate / 100) : 0.0;
  var igstamt = igstappl ? Number(taxableamount) * (igstrate / 100) : 0.0;

  var amount = igstappl
    ? Number(taxableamount) + igstamt
    : Number(taxableamount) + (Number(cgstamt) + Number(sgstamt));
  console.log("amount::", amount, item?.taxableamount,taxableamount, cgstamt, sgstamt);
  var itemObject = {
    ...item,
    shortguid: newShortguid,
    itemrecno: item.itemrecno,
    itemdescn: item.itemdescn,
    packingextraqty: Number(packingextraqty),
    packingqty: Number(packingqty) || Number(item?.packingqty), //nirmal-purchaseinvoice "adding from previous document button showing in qty in grid table"
    qty: Number(qty) || Number(item?.qty), //nirmal-purchaseinvoice "adding from previous document button showing in qty in grid table"
    free: Number(free),
    purrate: Number(purrate).toFixed(2),
    taxableamount: Number(taxableamount).toFixed(2),
    cgstrate: Number(cgstrate).toFixed(2),
    sgstrate: Number(sgstrate).toFixed(2),
    igstrate: Number(igstrate).toFixed(2),
    cgstamt: Number(cgstamt).toFixed(2),
    sgstamt: Number(sgstamt).toFixed(2),
    igstamt: Number(igstamt).toFixed(2),
    amount: Number(amount).toFixed(2) || Number(item?.amount).toFixed(2),
    discountrate: discount ? Number(discount).toFixed(2) : 0,
    discountamt: Number(discountamt).toFixed(2),
    expdate: item?.wantbatchno === 0 ? "99991231" : expdate,
    itembatchno: itembatchno,
    freepackingextraqty: Number(freepackingextraqty),
    freepackingqty: Number(freepackingqty),
    manufacturedate: manudate,
    grossamount: Number(grossamount).toFixed(2),
    grossamt: Number(grossamount).toFixed(2),
  };

  console.log("Tiem in Add is ", itemObject);

  setRowData((p) => [...p, itemObject]);
  setSelectedItem(null);
};


export const handleChange = (
  item,
  setRowData,
  igstappl,
  rowData,
  scandata,
  setScandata,
  setOpenItem
) => {
  //{ Pradeep, task id-238 }
  console.log("item in handlechange", item);
  var purrate = item?.purrate ? item.purrate : 0.0;
  var cgstrate = item?.cgstrate ? item.cgstrate : 0.0;
  var sgstrate = item?.sgstrate ? item.sgstrate : 0.0;
  var igstrate = item?.igstrate ? item.igstrate : 0.0;
  // {start  Pradeep, task id-238 start}
  // if (item?.packingextraqty == '') {
  //   item.packingextraqty = 0
  // }
  //{end       end }
  let packingextraqty = item?.packingextraqty ? item?.packingextraqty : 0.0;
  let packingqty = item?.packingqty ? item.packingqty : 0.0;
  let itembatchno = item?.itembatchno ? item?.itembatchno : null;
  let expdate = item?.expdate ? item?.expdate : null;
  let conversionfactor = item?.conversionfactor ? item.conversionfactor : 1;

  let freepackingextraqty = item?.freepackingextraqty
    ? item?.freepackingextraqty
    : 0.0;
  let freepackingqty = item?.freepackingqty ? item?.freepackingqty : 0.0;
  let free =
    Number(freepackingqty) +
    Number(freepackingextraqty) * Number(conversionfactor);
  let qty =
    Number(packingqty) + Number(packingextraqty) * Number(conversionfactor);

  var grossamt = item?.grossamt ? item?.grossamt : 0.0;

  var discountrate = item?.discountrate ? Number(item.discountrate) : 0.0;
  var discountamt = item?.discountamt ? item.discountamt : 0.0;
  discountrate =
    discountamt && Number(item?.grossamount) !== 0
      ? (discountamt / Number(item?.grossamount)) * 100
      : 0; //~! Found the culprit, UDA DO SAALE KO -> purrate
  // discountamt = Number(discount/100 * purrate)
  console.log("TR002 handle Change ===>", discountrate, item);
  var grossamount = Number(qty) * Number(purrate);

  var taxableamount = Number(grossamount) - Number(discountamt); //~! Found the culprit, UDA DO SAALE KO -> Qty, purrate

  console.log("TR002 handle changen 454646", grossamount);

  var sgstamt = !igstappl ? taxableamount * (sgstrate / 100) : 0.0;
  var cgstamt = !igstappl ? taxableamount * (cgstrate / 100) : 0.0;
  var igstamt = igstappl ? taxableamount * (igstrate / 100) : 0.0;

  var amount = igstappl
    ? taxableamount + igstamt
    : taxableamount + (cgstamt + sgstamt);

  var itemObject = {
    ...item,
    itemrecno: item.itemrecno,
    itemdescn: item.itemdescn,
    box: +item?.box,
    purrate: Number(purrate).toFixed(2),
    taxableamount: Number(taxableamount).toFixed(2),
    grossamount: Number(grossamount).toFixed(2),
    ccgstrate: Number(cgstrate).toFixed(2),
    sgstrate: Number(sgstrate).toFixed(2),
    igstrate: Number(igstrate).toFixed(2),
    cgstamt: Number(cgstamt).toFixed(2),
    sgstamt: Number(sgstamt).toFixed(2),
    igstamt: Number(igstamt).toFixed(2),
    amount: Number(amount).toFixed(2),
    discountrate: Number(discountrate).toFixed(2),
    discountamt: Number(discountamt).toFixed(2),
    grossamt: Number(grossamount).toFixed(2),
    packingextraqty: packingextraqty,
    packingqty: Number(packingqty),
    qty: Number(qty),
    free:Number( free),
    expdate: expdate,

    freepackingextraqty: Number(freepackingextraqty),
    freepackingqty: Number(freepackingqty) ,
    itembatchno: item?.wantbatchno ? item.itembatchno : ".",
  };
  var currentItem = rowData.map((itm) => {
    if (itm.shortguid == item.shortguid) {
      return { ...itemObject };
    } else {
      return { ...itm };
    }
  });

  setRowData(() => currentItem);
  setScandata(item);
};

export const handleRemove = (item, setRowData, rowData) => {
  var removedArray = removeByAttribute(rowData, "shortguid", item.shortguid);
  console.log("Remove", item, setRowData, rowData, removedArray);
  setRowData(() => [...removedArray]);
};

export const handleRemovePrev = (item, setRowData, rowData) => {
  console.log("Remove", item, rowData[0]);
  // var removedArray = removeByAttribute(rowData, "itemrecno", item.itemrecno);
  setRowData((p) => removeByAttribute(p, "itemrecno", item.itemrecno));
};

import React, { useState, useEffect, useContext, useRef } from "react";

import "../../CommonControllers/global.css";
import { Typography, Button, Input } from "antd";

import axios from "axios";
import { CheckCircleTwoTone } from "@ant-design/icons";

import { changesPasswordsUrl, confirmURL } from "../../CommonControllers/Urls";
import InputComponent from "../../CommonControllers/Components/InputComponent";
import { AuthStore } from "../../Auth/AuthStore";

const { Title } = Typography;

const PasswordChanges = () => {
  const auth = useContext(AuthStore);
  const [loading, setLoading] = useState(false);
  const [token, setToken] = useState("");
  const [tenantName, setTenantName] = useState("");
  const [tenantrecno, setTenantrecno] = useState("");
  const [forgot, setForgot] = useState(false);
  const [changepasswor, setChangesPasswords] = useState(false);
  const [oldpass, setOld] = useState("");
  const [newpwd, setNewpwd] = useState("");
  let old=useRef(null)
  let newpass=useRef(null)
  let btn =useRef(null)

  const Greetings = () => (
    <div>{token ? `Welcome ${tenantName} !!!` : { tenantName }}</div>
  );

  // This func handle the user loginid and password,
  const updatePassword = () => {
    console.log("uwvlquwyu", auth.state.userdata.descn);
    setLoading((prev) => !prev);
    const oldpassword = document.querySelector("#oldpassword").value;
    const newpassword = document.querySelector("#newpassword").value;

    if (oldpassword === "" || newpassword === "") {
      alert("Please Input Required Field");

      return;
    }

    const payload = {
      loginid: auth.state.userdata.loginid,
      oldpwd: oldpassword,
      newpwd: newpassword,
    };

    payload &&
      axios
        .post(changesPasswordsUrl, payload)
        .then((res) => {
          setTimeout(() => {
            if (res.data.Success) {
              alert("Passwords Updated Successfully");
              setChangesPasswords(true);
              setLoading((prev) => !prev);
              setToken(res?.data?.Message?.firebasetokenweb);
              setOld("")
              setNewpwd("")
             
            } else {
              setLoading((prev) => !prev);
              alert("Invalid LoginId or Password");
              document.querySelector("#oldpassword").value = "";
              document.querySelector("#newpassword").value = "";
            }
          }, 1000);
        })
        .catch((error) => {
          alert(error?.message);
          setLoading((prev) => !prev);
        });
  };

  return (
    <>
      {
        <div>
          <div>
            <div>
              <div>
                <div></div>
              </div>
            </div>
          </div>

      

          <div className="loginBox" style={{ marginLeft: "0%",paddingBottom:"20px" }}>
            <div>
              <Title level={3}>
                {!tenantName ? (
                  <>{auth?.state?.userdata.descn}</>
                ) : (
                  <Greetings />
                )}
              </Title>
            </div>

            <div
            //   onKeyDown={(e) => {
            //     if (e.keyCode == 13 && e.target.value) {
            //       updatePassword(e);
            //     }
            //   }}
            >
              <p  style={{height:"40px",width:"80%",marginLeft:"10%"}}>Enter Old Password</p>
              <Input
                id="oldpassword"
                type="password"
                required
                style={{height:"40px",width:"80%",marginLeft:"10%"}}
                //displayLabel="Enter Password"
                value={oldpass}
                onChange={(e)=>setOld(e.target.value)}
                ref={old}
                onKeyDown={(e)=>{
                    if(e.keyCode == 13 && oldpass){
                        newpass.current.focus()
                    }
                }}
                
              />
            </div>
            <div
            //   onKeyDown={(e) => {
            //     if (e.keyCode == 13 && e.target.value) {
            //       updatePassword(e);
            //     }
            //   }}
            >
              <p  style={{height:"40px",width:"80%",marginLeft:"10%"}}>Enter New Password</p>
              <Input
                id="newpassword"
                type="password"
                required
                style={{height:"40px",width:"80%",marginLeft:"10%"}}
                //displayLabel="Enter Password"
                ref={newpass}
                value={newpwd}
                onChange={(e)=>setNewpwd(e.target.value)}
                onKeyDown={(e)=>{
                    if(e.keyCode == 13 && newpwd){
                        btn.current.focus()
                    }
                }}
              />
            </div>

            <div>
              <Button
                type="primary"
                size="large"
                style={{height:"40px",width:"80%",marginLeft:"10%"}}
                onClick={() => {
                  updatePassword();
                }}
                loading={loading}
                icon={token && <CheckCircleTwoTone twoToneColor="green" />}
                ref={btn}
              >
                {!token ? "Submit" : "Logged In"}
              </Button>
            </div>
          </div>
        </div>
      }
    </>
  );
};

export default PasswordChanges;

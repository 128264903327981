import React, { useEffect, useState, useContext } from "react";
import { grnColumns } from "./GRNTableColumns";
import { Table, Spin } from "antd";

import { TabStore } from "../../TabsStructure/TabsStore";
import { currentTabData } from "../../CommonControllers/Variable";
import { AuthStore } from "../../Auth/AuthStore";
import ScanTable from "../../CommonControllers/Components/ScanTable/ScanTable";
import useSaveChangedRows from "../../CommonControllers/Hooks/useSaveChangedRows";

const GrnTable = ({
  rowData,
  setRowData,
  igstappl,
  scandata,
  setScandata,
  setOpenItem,
  useChangedRows,
  pageDetails
}) => {
  const [showTable, setShowTable] = useState(true);

  const [renderer, setRenderer] = useState(0);
  const [validator, setValidator] = useState(0);
  const tabs = useContext(TabStore);
  const auth = useContext(AuthStore);

  let workdate = auth?.state?.userdata?.workdate;

  useEffect(() => {
    console.log(`tr001 items table re-rendered ?? after deleting`, scandata);
    setShowTable(false);

    setTimeout(() => {
      setShowTable(true);
    }, 200);
  }, [rowData?.length, renderer]);
  console.log("111", scandata);

  let accPieces = rowData.reduce((acc, item) => {
    return Number(item.acceptedpieces) + acc;
  }, 0);
  let accBoxes = rowData.reduce((acc, item) => {
    return Number(item.acceptedboxes) + acc;
  }, 0);
  let pieces = rowData.reduce((acc, item) => {
    return Number(item.pieces) + acc;
  }, 0);
  let box = rowData.reduce((acc, item) => {
    return Number(item.box) + acc;
  }, 0);
  useEffect(() => {
    let validateData = "true";

    for (let i = 0; i < rowData?.length; i++) {
      console.log("RowData", rowData[i]);
      if (
        rowData[i]?.itemasbatch &&
        Number(rowData[i]?.qty) +
          Number(rowData[i]?.free ? rowData[i]?.free : 0) !=
          rowData[i]?.itembatcharray?.length
      ) {
        // console.log("VLAIDATIONS APPLICATION", rowData[i]?.itemasbatch,
        // Number(rowData[i]?.qty) +
        //   Number(rowData[i]?.free ? rowData[i]?.free : 0),
        //   rowData[i]?.itembatcharray?.length)
        console.log("Validations are True");
        validateData = "false";
      }
    }
    console.log("Validated IS", validateData);
    useChangedRows.updateValue("validated", validateData);
  }, [rowData]);
  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        {showTable ? (
          <Table
            scroll={{
              y: 305,
            }}
            size="small"
            bordered
            className="tr013-items-grid"
            // className={open ? "scantable" : "not-scantable"}
            pagination={false}
            columns={grnColumns(
              igstappl,
              setRowData,
              rowData,
              setOpenItem,
              scandata,
              setScandata,
              workdate,
              setRenderer,pageDetails
            )?.filter((item) => !item.hidden)}
            dataSource={rowData}
            onChange={(e) => {
              console.log("ONCHANGE HANDLE", e);
            }}
            summary={(pageData) => {
              console.log("tr003 page data ??", pageData);
              let boxesTotal = 0;
              let piecesTotal = 0;
              let accBoxesTotal = 0;
              let accPiecesTotal = 0;

              pageData.forEach(
                ({
                  packingextraqty,
                  packingqty,
                  // box,
                  // pieces,
                  packingextraqtyaccepted,
                  packingqtyaccepted,
                }) => {
                  //{start Pradeep, task id-238  changes pieces to packingqty key and box to packingextraqty }
                  boxesTotal += Number(packingextraqty);
                  piecesTotal += Number(packingqty);
                  //{{end            end }}
                  accBoxesTotal += Number(packingextraqtyaccepted);
                  accPiecesTotal += Number(packingqtyaccepted);
                }
              );

              return (
                <>
                  {
                    <Table.Summary.Row>
                      <Table.Summary.Cell>
                       
                      </Table.Summary.Cell>
                      <Table.Summary.Cell> <p style={{fontWeight:"bold"}}>Total:</p></Table.Summary.Cell>
                     {
                      pageDetails?.wantpackingextra? <Table.Summary.Cell>
                      <p style={{ ...pStyles }}>{boxesTotal}</p>
                    </Table.Summary.Cell>:null
                     }

                      <Table.Summary.Cell>
                        <p style={{ ...pStyles }}>{piecesTotal}</p>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell></Table.Summary.Cell>
                      {
                        pageDetails?.wantbatchno > 0 ? <Table.Summary.Cell></Table.Summary.Cell> : null
                      }
                      {
                        pageDetails?.wantmanudate > 0 ? <Table.Summary.Cell></Table.Summary.Cell> : null
                      }
                      {
                        pageDetails?.wantexpdate > 0 ? <Table.Summary.Cell></Table.Summary.Cell> : null
                      }                     
                      {/* <Table.Summary.Cell>
                        <p style={{ ...pStyles }}>{accBoxesTotal}</p>
                      </Table.Summary.Cell> */}
                      <Table.Summary.Cell>
                        {/* <p style={{ ...pStyles }}>{accPiecesTotal}</p> */}
                      </Table.Summary.Cell>
                      <Table.Summary.Cell></Table.Summary.Cell>
                     
                    </Table.Summary.Row>
                  }
                </>
              );
            }}
          />
        ) : (
          <div style={{ ...loaderStyle }}>
            <Spin size="large" />
          </div>
        )}
      </div>
    </>
  );
};

export default GrnTable;

const loaderStyle = {
  height: "max-content",
  width: "max-content",
  display: "flex",
  alignContent: "center",
  margin: "50px auto",
};

const pStyles = {
  textAlign: "right",
  fontWeight: "700",
  fontSize: "16px",
  color: "black",
};

const scantable = {
  width: "40%",
};

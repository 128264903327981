import React, { useState, useEffect, useContext } from "react";
import "../../CommonControllers/global.css";
import ItemSearch from "../../CommonControllers/ItemController/ItemSearch";
import {
  getDomainItemListColumns,
  getDomainItemListData,
  getfastpathapi,
} from "../../CommonControllers/ApiCalls";
import { AuthStore } from "../../Auth/AuthStore";
import EditableTable from "../../CommonControllers/Components/Tables/EditableTable";
import { TabStore } from "../../TabsStructure/TabsStore";
import { currentTabData } from "../../CommonControllers/Variable";
import useFetchItem from "../../CommonControllers/ItemController/useFetchItems";

const StockVerificationMainScreen = ({ fastPath }) => {
  const [itemRecord, setItemRecord] = useState(null);
  const auth = useContext(AuthStore);
  const tabs = useContext(TabStore);

  const batchData = currentTabData(tabs)?.apiResponse?.Message;
  const batchColumns = currentTabData(tabs)?.apiResponse?.columnsinfo;
  const fetchItems = useFetchItem();
  console.log(
    "tr031",
    itemRecord,
    "batch data",
    batchData,
    "batchColumns",
    batchColumns
  );
  const [fastPathOptions, setFastPathOptions] = useState();
  const [pageDetails, setPageDetails] = useState();
  const [rowData, setRowData] = useState([]);
  const [igstappl, setigstappl] = useState(false);
  // const tab=useContext(TabStore)
  const [pageIndex, setPageIndex] = useState();

  useEffect(() => {
    setRowData(batchData);
  }, [batchData]);

  useEffect(() => {
    var tabsArray = tabs.state.tabs;
    for (let i = 0; i < tabsArray?.length; i++) {
      if (tabsArray[i].name == fastPath) {
        setPageIndex(i);
        break;
      }
    }
  }, []);
  useEffect(() => {
    if (pageIndex >= 0) {
      const payload = {
        tenantrecno: auth?.state?.userdata?.tenantrecno,
        domainrecno: auth?.state?.userdata?.domainrecno,
        userrolerecno: auth?.state?.userdata?.userrolerecno,
        domainuserrecno: auth.state.userdata.recno,
        fastpath: tabs.state.tabs[pageIndex]?.name,
      };
      // On component mount this api is called and filterOptions are set for that fast path
      getfastpathapi(payload).then((res) => {
        if (res?.Success) {
          setFastPathOptions(res?.Message);
          setPageDetails(res?.Message);
        }
      });
    }
  }, [pageIndex]);

  useEffect(() => {
    console.log("tr031", "filter tenant item api called");
    if (itemRecord?.itemcode) {
      const payload = {
        tenantrecno: auth?.state?.userdata?.tenantrecno,
        domainrecno: auth?.state?.userdata?.domainrecno,
        batchdetails: true,
        itemcode: itemRecord?.itemcode,
      };
      var Message;
      var columns;
      var newResp;
      getDomainItemListColumns(payload).then((res) => {
        columns = res?.columnsinfo;
        newResp = { ...newResp, columnsinfo: columns };
        getDomainItemListData(payload).then((res) => {
          //Message = res?.Message[0]?.batchdetails;
          let data=[]
          let filterData=res.Message.filter((it)=>it?.itemcode==itemRecord?.itemcode)
         if(filterData.length>0){
          filterData.forEach((Message)=>{
            var newMessage = Message.batchdetails.forEach((item) => {
              let obj= {
                ...item,
                remark: "",
                actual: "",
                status: "",
                difference: "",
                itemrecno: itemRecord?.itemrecno,
                systemstock: item.stock,
              };
              console.log("API REsponse Update Data1", obj,item);
              data.push(obj)
            });
            
          })
         }
          newResp = { ...newResp, Message: data };
          console.log("API REsponse Update Data2", data);
          tabs.dispatch({
            type: "UPDATE_DATA",
            fieldType: "apiResponse",
            text: newResp,
          });
        });
      });
    }
  }, [itemRecord?.itemcode]);

  console.log("TabsStore Data Of The Data is The......", tabs.state.tabs);

  return (
    <>
      <div className="tr031MainFrame">
        {/* Item search box */}
        <div style={{ zIndex: 10, position: "relative" }}>
          {/* <ItemSearch
            returnValue={(e) => setItemRecord(e)}
            forFlag={"S"}
            dropdown
            selection
            barcodeReturnValue={(e) => {
              // Write a function to add to existing Items and check only if it has serial number
              console.log("RDUJGVY", e);
              if (e?.itemasbatch == 1) {
                fetchItems
                  .addBarcodeScannedItemToTable(
                    rowData,
                    e,
                    "SIF",
                    igstappl,
                    pageDetails,
                    "S"
                  )
                  .then((res) => {
                    console.log("New ROws Data", res);
                    if (res?.length == 0) {
                      // Rajendra the same barcode grid getting blank date 9 jun 2023
                      setRowData(rowData);
                      return;
                    } else {
                      setRowData(res);
                      // setChange(change + 1);
                    } // Rajendra the same barcode grid getting blank date 9 jun 2023
                  })
                  .catch((err) => alert(err));
              } else {
                setItemRecord(e);
              }
            }}
          /> */}

          <ItemSearch
            //  itemSearchRef={itemSearch}
            forFlag={"D"}
            returnValue={(e) => setItemRecord(e)}
            //supplierdata={1234}
            dropdown
            defaultSelectedItem={itemRecord}
            //selection
            /// task id-269 rajendra If item has isservicetrue = 1 then don't calculate stock.
            //selection /// rajendra  may 30 two selection key was there
            barcodeReturnValue={(e) => {
              // Write a function to add to existing Items and check only if it has serial number
              if (e?.itemasbatch == 1) {
                fetchItems
                  .addBarcodeScannedItemToTable(
                    rowData,
                    e,
                    "SIF",
                    igstappl,
                    pageDetails,
                    "S"
                  )
                  .then((res) => {
                    console.log("New ROws Data", res);
                    if (res?.length == 0) {
                      // Rajendra the same barcode grid getting blank date 9 jun 2023
                      setRowData(rowData);
                      return;
                    } else {
                      setRowData(res);
                      //setChange(change + 1);
                    } // Rajendra the same barcode grid getting blank date 9 jun 2023
                  })
                  .catch((err) => alert(err));
              } else {
                setItemRecord(e);
              }
            }}
          />
        </div>
        {/* display label box */}
        <div className="tr001DisplayRecord">
          <div style={{ ...itemData }}>
            <p>Item Code</p>
            <p>{itemRecord?.itemcode}</p>
          </div>
          <div style={{ ...itemData }}>
            <p>Item Description</p>
            <p>{itemRecord?.itemdescn}</p>
          </div>
          <div style={{ ...itemData }}>
            <p>Item Short Description</p>
            <p>{itemRecord?.itemshortdescn}</p>
          </div>
        </div>
        {/* single item batch info box */}
        <div>
          <EditableTable
            columnsArray={batchColumns}
            rowsArray={rowData}
            isEdit={fastPathOptions?.isedit}
          />
        </div>
      </div>
    </>
  );
};

export default StockVerificationMainScreen;

// CSS
const itemData = {
  display: "flex",
  gap: "8px",
};

import React, { useContext } from "react";
import CustomDropDown from "../../CommonControllers/Components/DropdownSelect";
import { userPermissions } from "../../CommonControllers/Urls";
import style from "./userPermission.module.css";
const UserDropDown = () => {
  return (
    <div>
      <div>
        <CustomDropDown
          apikey={"userrolerecno"}
          minInput={0}
          inputValue={20}
          displayLabel={" User :"}
          className={style.drop}
          apiUrl={userPermissions}
          selectionLabel="descn"
          selectionValue={"recno"}
          width={300}
        />
      </div>
    </div>
  );
};

export default UserDropDown;

import axios from "axios";
import { useState, useEffect, useContext } from "react";
import { AuthStore } from "../../Auth/AuthStore";
import { TabStore } from "../../TabsStructure/TabsStore";
import { baseUrl } from "../Urls";
import { currentTabData } from "../Variable";
import { CheckResigister, downloadExcel } from "../AppFunction";
import { notification } from "antd";

export default function useExecuteApi() {
  const auth = useContext(AuthStore);
  const tabStore = useContext(TabStore);

  var apiVariables = tabStore.state.tabs[tabStore.state.tabIndex]?.apiVariables;

  async function execute(pageIndex) {
    var tabIndex = 0;

    tabStore.dispatch({
      type: "UPDATE_DATA_WITH_TABINDEX",
      fieldType: "executeLoading",
      text: true,
      tabDataIndex: pageIndex ? pageIndex : tabStore.state.tabIndex,
    });

    if (!pageIndex) {
      tabIndex = tabStore.state.tabIndex;
    } else {
      tabIndex = pageIndex;
    }
    var body = tabStore.state.tabs[tabIndex]?.apiVariables;
    body = {
      tenantrecno: auth.state.userdata.tenantrecno,
      domainrecno: auth.state.userdata.domainrecno,
      domainuserrecno: auth.state.userdata.recno,
      userrolerecno: auth.state.userdata.userrolerecno,
      fastpath: tabStore.state.tabs[tabIndex]?.name,
      ...body,
    };
    var url = baseUrl + tabStore.state.tabs[tabIndex]?.apiUrl + "/";
    var type = tabStore.state.tabs[tabIndex]?.apiType;

    let resp;
    try {
      if (type == "PATCH") {
        resp = await axios.patch(url, body);
      } else if (type == "GET") {
        resp = await axios.get(url, body);
      } else {
        if (CheckResigister(currentTabData(tabStore).name)) {
          body.wantexcel = 1;
          downloadExcel(url, body);
        }
        resp = await axios.post(url, body);
      }

      // If the Function is of Execute Type
      // Update the TabStore to store the data as API-Response

      if (resp.data.Success) {
        tabStore.dispatch({
          type: "UPDATE_DATA_WITH_TABINDEX",
          fieldType: "executeLoading",
          text: false,
          tabDataIndex: pageIndex ? pageIndex : tabStore.state.tabIndex,
        });
        tabStore.dispatch({
          type: "UPDATE_DATA_WITH_TABINDEX",
          fieldType: "pageStatus",
          text: "Success",
          tabDataIndex: tabIndex,
        });
        tabStore.dispatch({
          type: "UPDATE_DATA_WITH_TABINDEX",
          fieldType: "apiResponse",
          text: resp.data,
          tabDataIndex: tabIndex,
        });
      } else {
        tabStore.dispatch({
          type: "UPDATE_DATA_WITH_TABINDEX",
          fieldType: "pageStatus",
          text: resp.data.Message,
          tabDataIndex: tabIndex,
        });
        
     notification.error({
        message: `An Error has Occured`,
        description: String(resp?.data?.Message),
        duration: 3,
        style: { background: "#efa7a7" },
      });
      }
    } catch (error) {
      tabStore.dispatch({
        type: "UPDATE_DATA_WITH_TABINDEX",
        fieldType: "executeLoading",
        text: false,
        tabDataIndex: tabIndex,
      });

      auth.state.contextHolder.info({
        message: `An Error has Occured`,
        description: String(error.response.data.Error),
        duration: 3,
        style: { background: "#efa7a7" },
      });
    } finally {
      tabStore.dispatch({
        type: "UPDATE_DATA_WITH_TABINDEX",
        fieldType: "executeLoading",
        text: false,
        tabDataIndex: pageIndex ? pageIndex : tabStore.state.tabIndex,
      });
    }
  }

  return { execute, apiVariables };
}

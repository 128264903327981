import { useState, useEffect, useContext, useRef } from "react";
import { RadioChangeEvent } from "antd";
import { DeleteOutlined } from "@ant-design/icons";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import SaveDropDown from "../../../CommonControllers/SaveComponents/SaveDropDown";
import SaveDatePicker from "../../../CommonControllers/SaveComponents/SaveDatePicker";
import SaveTextInput from "../../../CommonControllers/Components/SaveComponents/SaveTextInput";

import { Button, Radio, Space, Divider } from "antd";
import { getAccountlistURL } from "../../../CommonControllers/Urls";

//import JVPendingInsutrumentsTransaction from "./JVPendingInstrumentsTransaction";
import DebitNotePendingInsutrumentsTransaction from "./DebitNotePendingInsutrumentsTransaction";

import { Voucher_Account } from "../Transaction/TransactionHelper";

import { AuthStore } from "../../../Auth/AuthStore";
import { TabStore } from "../../../TabsStructure/TabsStore";
import useSaveChangedRows from "../../../CommonControllers/Hooks/useSaveChangedRows";
import SaveDropdown from "../../../CommonControllers/Components/SaveComponents/SaveDropdown";

function DebitNoteScreen(props) {
  const auth = useContext(AuthStore);
  const [valueDrCr, setValueDrCr] = useState(1);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [amount, setAmount] = useState(null);
  const [open, setOpen] = useState(false);
  const [localstate, setlocalState] = useState(0);
 
  const[vdate,setVdate]=useState()
  //const [selectedSettlementAcct, setselectedSettlementAcct] = useState(false); not used 24feb2022
  const [mode, setmode] = useState("ADD");
  const tabstore = useContext(TabStore);
  const [pageIndex, setPageIndex] = useState();

  useEffect(() => {
    var tabsArray = tabstore.state.tabs;
    for (let i = 0; i < tabsArray?.length; i++) {
      if (tabsArray[i].name == props?.fastPath) {
        setPageIndex(i);
        break;
      }
    }
  }, []);
  let changedrows =
      tabstore?.state?.tabs[tabstore?.state.tabIndex]?.changedRows;

      const useChangedRows = useSaveChangedRows();

  useEffect(() => {
    if (
      tabstore?.state?.tabs[tabstore?.state.tabIndex]?.pageStatus == "Success"
    ) {
      console.log("Page Status Changed");

      setAmount(); // voucher amount

      tabstore.dispatch({
        type: "UPDATE_CHANGED_DATA",
        fieldType: "ourreference",
        text: "",
      });

      tabstore.dispatch({
        type: "UPDATE_CHANGED_DATA",
        fieldType: "narration",
        text: "",
      });

      tabstore.dispatch({
        type: "UPDATE_CHANGED_DATA",
        fieldType: "partyreference",
        text: "",
      });

      tabstore.dispatch({
        type: "UPDATE_CHANGED_DATA",
        fieldType: "voucher_amount",
        text: "",
      });

      //

      // clear voucher date

      document.getElementById("voucherdate").value = "";
      //document.getElementById("account1").value = "";
      setSelectedAccount(null);

      generateAPIData();
    }
  }, [tabstore?.state?.tabs[tabstore?.state.tabIndex]?.pageStatus]);

  useEffect(() => {
    generateAPIData();
  }, [localstate,changedrows?.narration,changedrows?.ourreference,changedrows?.partyreference]);

  const onChangeDrCr = (e) => {
    //   console.log("radio checked", e.target.value);
    setValueDrCr(e.target.value);
  };

  function getDisplayVouchersFromStore() {
    let changedrows =
      tabstore?.state?.tabs[tabstore?.state.tabIndex]?.changedRows;

    let displayvouchers = [];

    if (_changedRows?.hasOwnProperty("displayvouchers")) {
      displayvouchers =
        tabstore?.state?.tabs[tabstore?.state.tabIndex]?.changedRows?.[
          "displayvouchers"
        ];
    }
    return displayvouchers;
  }

  function handleAddAccount() {
    // let selectedAccount = null;
    console.log(selectedAccount);
    console.log(valueDrCr);
    console.log(amount);
    if (amount == 0) {
      alert("Please enter amount.");
      return;
    }

    let changedRows = {
      ...tabstore?.state?.tabs[tabstore?.state.tabIndex]?.changedRows,
    };

    let vouchers = [];
    vouchers =
      tabstore?.state?.tabs[tabstore?.state.tabIndex]?.changedRows[
        "displayvouchers"
      ];

    console.log("handleAddAccount");
    console.log(vouchers);

    if (vouchers == undefined) {
      let tempArray = [];

      let voucher = {
        code: selectedAccount.code,
        name: selectedAccount.accountname,
        debit: 0,
        credit: 0,
        instruments: [],
        isDiscountGL: false,
        gldetailtype: selectedAccount.gldetailtype,
      };

      /**decide DR or CR Amount  */
      if (valueDrCr == 1) {
        voucher.debit = amount;
        voucher.credit = 0;
      } else if (valueDrCr == 2) {
        voucher.credit = amount;
        voucher.debit = 0;
      }

      tempArray.push(voucher);

      changedRows["displayvouchers"] = tempArray;
      tabstore.dispatch({
        type: "UPDATE_CHANGED_DATA",
        fieldType: "displayvouchers",
        text: tempArray,
      });
    } else {
      let voucher = {
        code: selectedAccount.code,
        name: selectedAccount.accountname,
        debit: 0,
        credit: 0,
        instruments: [],
        isDiscountGL: false,
        gldetailtype: selectedAccount.gldetailtype,
      };
      /**decide DR or CR Amount  */

      if (valueDrCr == 1) {
        voucher.debit = amount;
        voucher.credit = 0;
      } else if (valueDrCr == 2) {
        voucher.credit = amount;
        voucher.debit = 0;
      }

      vouchers.push(voucher);

      tabstore.dispatch({
        type: "UPDATE_CHANGED_DATA",
        fieldType: "displayvouchers",
        text: vouchers,
      });
    }

    generateAPIData();

    // clear drop down
    let account_2 = new Voucher_Account("", "", "", "", "");
    setSelectedAccount(account_2);
    setAmount(0);

    // setSelectedAccount(null);
  }

  function handleDeleteAccount(data) {
    console.log("handleDeleteAccount");
    console.log(data);

    let account_entry_for_delete_code = data.code;

    let vouchers = [];
    vouchers =
      tabstore?.state?.tabs[tabstore?.state.tabIndex]?.changedRows[
        "displayvouchers"
      ];

    if (vouchers != undefined) {
      const filteredvouchers = vouchers.filter((x) => x.code != data.code);

      const _tempVouchers = filteredvouchers.filter(
        (item) => item.parentcode != account_entry_for_delete_code
      );

      tabstore.dispatch({
        type: "UPDATE_CHANGED_DATA",
        fieldType: "displayvouchers",
        text: _tempVouchers,
      });
    }
  }

  function handleAmtUpdate(value) {
    if (value > 0) {
      setAmount(value);

      tabstore.dispatch({
        type: "UPDATE_CHANGED_DATA",
        fieldType: "voucher_amount",
        text: Number(value),
      });
    } else{
      setAmount(null)
    }
  }

  function handleGlUpdate(event) {
    console.log("handleGlUpdate event");

    if (event != undefined) {
      console.log(event);

      let account_2 = new Voucher_Account(
        event.code,
        event.glcode,
        event.gldescn,
        event.accountcode,
        event.descn,
        event.gldetailtype
      );
      setSelectedAccount(account_2);
      setlocalState((localstate)=>localstate + 1);
    }
  }

  function generateAPIData() {
    let changedrows =
      tabstore?.state?.tabs[tabstore?.state.tabIndex]?.changedRows;

    let displayvouchers = getDisplayVouchersFromStore();

    let validations = [];

    // validate data before sending api data to tab store
    if(changedrows.narration!=""||changedrows.ourreference!=""||changedrows.partyreference!=""){
      validations=[]
    }
    //narration validation added
    if (!changedrows.hasOwnProperty("narration")||changedrows["narration"]=="") {
      validations.push("Narrationn is not Entered.");
    }

    if (!changedrows.hasOwnProperty("voucherdate")) {
      validations.push("Voucher Date is not selected.");
    }

    if (!changedrows.hasOwnProperty("discountgl")) {
      validations.push("Account Not Selcted.");
    }

    if (!changedrows.hasOwnProperty("voucher_amount")) {
      validations.push("Voucher Amount is not entered.");
    }

    if (!changedrows.hasOwnProperty("displayvouchers")) {
      validations.push("Accounts not selected.");
    } else {
      let TotalDebit = 0;
      let TotalCredit = 0;
      let contramatch = false;

      displayvouchers?.forEach((element) => {
        TotalDebit = Number(TotalDebit) + Number(element.debit);
        TotalCredit = Number(TotalCredit) + Number(element.credit);
      });

      if (TotalDebit == 0 && TotalCredit == 0) {
        validations.push("Accounts not selected.");
      }

      if (TotalDebit == TotalCredit) {
        contramatch = true;
      } else {
        validations.push("Debit/ Credit Amount is not matching.");
      }
    }
    if( tabstore?.state?.tabs[tabstore?.state.tabIndex].name=="TR028"){
      // tabstore.dispatch({
      //   type: "UPDATE_CHANGED_DATA",
      //   fieldType: "validations",
      //   text: validations,
      // });
      useChangedRows.updateValue("validations", validations);
    }

    let postdata = {
      domainrecno: auth.state.userdata.domainrecno,
      tenantrecno: auth.state.userdata.tenantrecno,
      trdate: auth.state.workdate, //20221202,
      items: [],
    };

    displayvouchers?.forEach((element) => {
      //

      let _cdflg = 0;
      let _amount = 0;

      if (element.debit > 0) {
        _cdflg = 2;
        _amount = element.debit;
      }
      if (element.credit > 0) {
        _cdflg = 1;
        _amount = element.credit;
      }

      let item_acct = {
        trdate: auth.state.workdate,
        code: element?.code,
        cdflag: _cdflg,
        amount: _amount,
        ctflag: 2,
        particular: "",
      };

      let billingdetails = [];

      let filtered_instruments = element.instruments.filter(
        (x) => x.childrows.length > 0
      );

      if (filtered_instruments?.length > 0) {
        filtered_instruments?.forEach((instrument) => {
          //
          console.log(instrument);

          let billing_detail = {
            recno: instrument.recno,
            refno: instrument.refno,
            code: instrument.code,
            amount: 0,
          };

          let _discountdetails = [];

          instrument?.childrows?.forEach((gldiscount) => {
            let _discount = {
              code: gldiscount.code,
              amount: gldiscount.amount,
            };

            _discountdetails.push(_discount);
          });

          billing_detail.discountdetails = _discountdetails;
          billingdetails.push(billing_detail);
        });
      }
      item_acct.billingdetails = billingdetails;
      item_acct.particular = changedrows?.narration;
      item_acct.refno = changedrows?.ourreference;
      item_acct.theirrefno = changedrows?.partyreference;

      postdata.items.push(item_acct);
    });

    console.log("postdata for API====",postdata,"ROW",changedrows);
    

    if (displayvouchers.length > 0) {
     
     // console.log("displayvouchers.length",displayvouchers.length)
      tabstore.dispatch({
        type: "UPDATE_CHANGED_DATA",
        fieldType: "data",
        text: postdata,
      });
    }
  }

  const TotalRow = () => {
    let displayvouchers = getDisplayVouchersFromStore();

    let TotalDebit = 0;
    let TotalCredit = 0;
    let contramatch = false;

    displayvouchers?.forEach((element) => {
      TotalDebit = Number(TotalDebit) + Number(element.debit);
      TotalCredit = Number(TotalCredit) + Number(element.credit);
    });
    if (TotalDebit == TotalCredit) {
      contramatch = true;
    }

    const styles = {
      exampleStyle1: {
        backgroundColor: "red",
      },
    };

    return (
      <tr>
        <td width="10%"></td>
        <td width="20%" style={{ display: "none" }}></td>
        <td width="40%">Total</td>
        <td width="20%" align="right">
          {TotalDebit}
        </td>
        <td width="20%" align="right">
          {TotalCredit}
        </td>
        <td width="10%" align="center"></td>
      </tr>
    );
  };

  const handleCloseInstrumentsPopUp = () => {
    setOpen(false);

    tabstore.dispatch({
      type: "UPDATE_CHANGED_DATA",
      fieldType: "selectedAccountToSettleDiscounts",
      text: "",
    });
  };

  const handleSaveInstrumentsPopup_New = () => {
    let changedrows =
      tabstore?.state?.tabs[tabstore?.state.tabIndex]?.changedRows;

    let _srno = 1;
    let pending_instruments = changedrows["pendinginstruments"];

    if (pending_instruments?.length >= 0 && selectedAccount) {
      // generate voucher here   24 feb 2023

      let displayvouchers = [];
      let voucher_amt = amount;

      let voucher_acct = {
        code: selectedAccount.code,
        name: selectedAccount.accountname,
        debit: voucher_amt,
        credit: 0,
        instruments: [],
        isDiscountGL: false,
        parentcode: selectedAccount.code,
      };

      displayvouchers.push(voucher_acct);

      let discount_entries = new Map();

      pending_instruments.forEach((instrument) => {
        let childrows = instrument.childrows;
        childrows.forEach((discountgl) => {
          let _temp_gl = discount_entries.get(discountgl.code);

          if (_temp_gl == undefined) {
            console.log("_temp_gl not found");
            console.log(_temp_gl);

            let voucher_acct = {
              code: discountgl.code,
              name: discountgl.descn,
              debit: 0,
              credit: discountgl.amount,
              instruments: pending_instruments,
              isDiscountGL: true,
              parentcode: selectedAccount.code,
            };

            discount_entries.set(discountgl.code, voucher_acct);
            //
          } else {
            console.log(" else _temp_gl  found");
            console.log(_temp_gl);

            _temp_gl.credit =
              Number(discountgl.amount) + Number(_temp_gl.credit);

            discount_entries.set(discountgl.code, _temp_gl);
          }
        });
      });

      for (const [key, value] of discount_entries.entries()) {
        displayvouchers.push(value);
      }

      tabstore.dispatch({
        type: "UPDATE_CHANGED_DATA",
        fieldType: "displayvouchers",
        text: displayvouchers,
      });
    }

    generateAPIData();
    setOpen(false);
    setlocalState((p)=>p+1)
  };

  const handleSaveInstrumentsPopup = () => {
    let changedrows =
      tabstore?.state?.tabs[tabstore?.state.tabIndex]?.changedRows;
    let _srno = 1;

    let pending_instruments = changedrows["pendinginstruments"];
    let selected_account = changedrows["selectedAccountToSettleDiscounts"];

    let displayvouchers = changedrows["displayvouchers"];

    if (pending_instruments?.length > 0 && selected_account) {
      let voucher_object = displayvouchers?.find(
        (item) => item.code == selected_account.code
      );

      let item_index = displayvouchers?.findIndex(
        (item) => item.code == selected_account.code
      );

      if (voucher_object != undefined) {
        voucher_object.instruments = pending_instruments;
      }

      displayvouchers[item_index] = voucher_object;

      /**
       * Generate Accounting Entries for Discount GL
       * 01FEB2023
       */

      let vouchers = [];
      let discount_entries = new Map();

      if (displayvouchers?.length > 0) {
        displayvouchers.forEach((element) => {
          if (element.isDiscountGL == false) {
            // entry added by user

            vouchers.push(element);

            let _instruments = element.instruments;

            _instruments.forEach((instrument) => {
              let childrows = instrument.childrows;
              childrows.forEach((discountgl) => {
                let _temp_gl = discount_entries.get(discountgl.code);

                if (_temp_gl == undefined) {
                  console.log("_temp_gl not found");
                  console.log(_temp_gl);

                  let voucher_acct = {
                    code: discountgl.code,
                    name: discountgl.descn,
                    debit: 0,
                    credit: 0,
                    instruments: [],
                    isDiscountGL: true,
                    parentcode: element.code,
                  };

                  if (element.credit > 0) {
                    voucher_acct.debit = discountgl.amount;
                  } else {
                    voucher_acct.credit = discountgl.amount;
                  }

                  discount_entries.set(discountgl.code, voucher_acct);
                  //
                } else {
                  console.log(" else _temp_gl  found");
                  console.log(_temp_gl);

                  if (element.credit > 0) {
                    _temp_gl.debit =
                      Number(discountgl.amount) + Number(_temp_gl.debit);
                  } else {
                    _temp_gl.credit =
                      Number(discountgl.amount) + Number(_temp_gl.credit);
                  }
                  discount_entries.set(discountgl.code, _temp_gl);
                }
              });
            });
          }
        });
      }

      for (const [key, value] of discount_entries.entries()) {
        vouchers.push(value);
      }

      tabstore.dispatch({
        type: "UPDATE_CHANGED_DATA",
        fieldType: "displayvouchers",
        text: vouchers,
      });

      // clear temp array
      tabstore.dispatch({
        type: "UPDATE_CHANGED_DATA",
        fieldType: "pendinginstruments",
        text: [],
      });

      tabstore.dispatch({
        type: "UPDATE_CHANGED_DATA",
        fieldType: "selectedAccountToSettleDiscounts",
        text: {},
      });
    }

    setOpen(false);

    //generateAPIData();
  };

  const handleOpenPendingInstrumentPopUp = (data) => {
    console.log("handleOpen ");
    console.log(data);
    //setselectedSettlementAcct(data);

    //handleAddAccount();

    let changedrows =
      tabstore?.state?.tabs[tabstore?.state.tabIndex]?.changedRows;

    let displayvouchers = changedrows["displayvouchers"];

    if (displayvouchers?.length > 0) {
      let voucher_object = displayvouchers.find(
        (item) => item.code == data.code
      );

      let pending_instruments = voucher_object?.instruments;

      //let disocunt_entries = new Map();
      if (pending_instruments?.length > 0) {
        tabstore.dispatch({
          type: "UPDATE_CHANGED_DATA",
          fieldType: "pendinginstruments",
          text: pending_instruments,
        });
      }
    }

    tabstore.dispatch({
      type: "UPDATE_CHANGED_DATA",
      fieldType: "selectedAccountToSettleDiscounts",
      text: data,
    });
    /// start////
    /// task id-249 rajendra  here the data in the diolog box was not updating in futute before changing ask me
    tabstore.dispatch({
      type: "UPDATE_CHANGED_DATA",
      fieldType: "pendinginstruments",
      text: [],
    });
    //// end task id-249 rajendra ////
    setOpen(true);
  };
  //

  let _vouchers = [];
  let _changedRows =
    tabstore?.state?.tabs[tabstore?.state.tabIndex]?.changedRows;

  if (_changedRows?.hasOwnProperty("displayvouchers")) {
    _vouchers =
      tabstore?.state?.tabs[tabstore?.state.tabIndex]?.changedRows?.[
        "displayvouchers"
      ];
  }
  useEffect(() => {
    console.log("Vdate",vdate)
    let isButtonDisabled = false;
    if(vdate === "" || vdate === null){
      //alert("Voucher Date is Compulsory")
      isButtonDisabled = true;
    }  
    useChangedRows.updateValue(
      "validated",
      !isButtonDisabled ? "true" : "false"
    );
  }, [localstate])
  return (
    <>
      <div style={{ margin: "36px auto" }}>
      <div style={{display:"flex" ,marginLeft:"22% ",marginBottom:"2%"}}>
      <div style={{ zIndex: 50 }}>
      <SaveTextInput
        type={"text"}
        key={"edit_voucherno"}
        alignment="column"
        placeholder="Voucher No."
        displayLabel={"Voucher No."}
        apikey={"edit_voucherno"}
        width="700px"
        pageIndex={pageIndex}
      />
    </div>
    <div
      style={{
        alignSelf: "center",
        marginLeft: "25px",
        marginTop: "20px",
      }}
    >
      <Button
        type="primary"
        onClick={() => {
          //fetchVoucher();
        }}
      >
        Show Voucher
      </Button>
      {"   "}Page Mode {mode}
    </div></div>
        <div
          style={{
            ...inputBoxes,
          }}
        >
     
          <div style={{ width: "fit-content" }}>
            <SaveDatePicker
              defaultValue={auth?.state?.workdate}
              id={"voucherdate"}
              displayLabel={"Voucher Date"}
              apiKey={"voucherdate"}
              key={tabstore?.state?.tabs[tabstore?.state.tabIndex]?.pageStatus}
              returnValue={(e)=>{
              
                setVdate(e)
                setlocalState((localstate)=>localstate + 1);
              }}
            />
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "36px",
              width: "fit-content",
              flexWrap: "wrap",
            }}
          >
            <SaveDropdown
              displayLabel={"Debit Account"}
              alignment="column"
              id={"account1"}
              placeholder={"Search"}
              apiurl={getAccountlistURL}
              apikey={"discountgl"}
              multi={false}
              selectionLabel={"descn"}
              selectionValue={"code"}
              records={20}
              minInput={0}
              key={tabstore?.state?.tabs[tabstore?.state.tabIndex]?.pageStatus}
              // start task-249 rajendra

              // defaultValue={selectedAccount?.code}

              /// rajendra nali task id -249 the default value was remove so that the custome dropdown will not render afteron change

              // end task-249 rajendra
              value={selectedAccount?.code}
              async
              returnValue={handleGlUpdate}
              width="30vw"
            />

            {/*
            <Radio.Group
              style={{ marginLeft: "10px" }}
              onChange={onChangeDrCr}
              value={valueDrCr}
            >
              <Radio value={1}>Debit</Radio>
              <Radio value={2}>Credit</Radio>
            </Radio.Group>
             */}
            <div>
              <input
                style={{
                  textAlign: "right",
                  width: "100px",
                  height: "35px",
                }}
                key={tabstore?.state?.tabs[tabstore?.state.tabIndex]?.pageStatus}
                type="text"
                value={amount}
                onChange={(e) => {
                  handleAmtUpdate(e.target.value);
                  setlocalState(localstate + 1);
                }}
              ></input>{" "}
              {/*
                                  <Button
                type="primary"
                onClick={() => {
                  handleAddAccount();
                }}
              >
                Add{" "}
              </Button>

                    */}
              <Button
                type="primary"
                onClick={(e) => {
                  if (selectedAccount?.code.length > 0) {
                    handleOpenPendingInstrumentPopUp(selectedAccount);
                  }
                }}
              >
                Pending Instruments
              </Button>
            </div>
          </div>
        </div>

        <div
          style={{
            margin: "40px auto",
            width: "1200px",
            minHeight: "250px",
            // border: "1px solid black",
          }}
        >
          <div>
            <table className="tr023-table">
              <tbody>
                <tr>
                  <td width="10%">Sr.No</td>

                  <td width="20%" style={{ display: "none" }}>
                    Account code
                  </td>
                  <td width="60%">Account Name</td>
                  <td width="20%" align="right">
                    Debit
                  </td>
                  <td width="20%" align="right">
                    Credit
                  </td>

                  <td width="10%" align="center">
                    Delete
                  </td>
                </tr>
                {_vouchers?.map((data, index) => (
                  <>
                    <tr>
                      <td>{index + 1}</td>
                      <td style={{ display: "none" }}>{data.code}</td>
                      <td>{data.name}</td>
                      <td align="right">{data.debit}</td>
                      <td align="right">{data.credit}</td>

                      <td align="center">
                        {data?.isDiscountGL == false ? (
                          <Button
                            type="primary"
                            shape="circle"
                            icon={<DeleteOutlined />}
                            onClick={(e) => {
                              handleDeleteAccount(data);
                            }}
                          />
                        ) : (
                          <></>
                        )}
                      </td>
                    </tr>
                  </>
                ))}
                <TotalRow></TotalRow>
              </tbody>
            </table>
          </div>

          <div style={{ ...bottomControls }}>
            <div style={{ width: "fit-content" }}>
              <SaveTextInput
                key={"narration"}
                alignment="column"
                placeholder="Enter Narration"
                displayLabel={"Narration"}
                apikey={"narration"}
                type="text"
                width="700px"
                isrequired
                pageIndex={pageIndex}
              />
            </div>
            <div>
              <SaveTextInput
                key={"ourreference"}
                placeholder="Enter Reference"
                displayLabel={"Our Reference"}
                apikey={"ourreference"}
                type="text"
                alignment="column"
                defaultValue={
                  tabstore?.state?.tabs[tabstore?.state.tabIndex]?.changedRows
                    ?.ourreference
                }
                pageIndex={pageIndex}
              />
            </div>
            <div>
              <SaveTextInput
                key={"partyreference"}
                placeholder="Enter Reference"
                displayLabel={"Party  Reference"}
                apikey={"partyreference"}
                type="text"
                alignment="column"
                pageIndex={pageIndex}
              />
            </div>
          </div>
        </div>
      </div>
      <Dialog
        open={open}
        onClose={handleCloseInstrumentsPopUp}
        fullWidth
        PaperProps={{
          style: {
            minHeight: "90%",
            maxHeight: "90%",
            width: "70%",
            maxWidth: "90%",
          },
        }}
      >
        <DialogTitle>Instrument Sellement</DialogTitle>
        <DialogContent>
          <div>
            <b> Code:</b> {selectedAccount?.code} - <b>Account Name: </b>{" "}
            {selectedAccount?.name}
            {
              <DebitNotePendingInsutrumentsTransaction
                selectedaccount={selectedAccount}
              />
            }
          </div>
        </DialogContent>
        <DialogActions>
          <Button type="primary" onClick={handleCloseInstrumentsPopUp}>
            Cancel{" "}
          </Button>
          <Button type="primary" onClick={handleSaveInstrumentsPopup_New}>
            Save{" "}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default DebitNoteScreen;

const inputBoxes = {
  // border: "1px solid black",
  display: "flex",
  alignItems: "center",
  gap: "36px",
  width: "fit-content",
  margin: "auto",
  flexWrap: "wrap",
};

const bottomControls = {
  // border: "1px solid red",
  display: "flex",
  justifyContent: "space-between",
  margin: "48px 0 0",
  flexWrap: "wrap",
};

import React, { useState, useRef, useEffect, useContext } from "react";

import { ExcelRenderer, OutTable } from "react-excel-renderer";
import * as XLSX from "xlsx";
import "./ItemRecieptwithDC.css";
import CommonMaster from "../CommonMaster";
import TableComponent from "../../CommonControllers/Components/TableComponent/TableComponent";
import { Table } from "antd";
import { TabStore } from "../../TabsStructure/TabsStore";
import {
  dateFormatter,
  datedisplay,
} from "../../CommonControllers/AppFunction";
import SaveCheckbox from "../../CommonControllers/Components/SaveComponents/SaveCheckbox";
import CallbackCheckbox from "../../CommonControllers/Components/CallbackComponents/CallbackCheckbox";

function LinkingPage() {
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  const [message, setMessage] = useState("");
  const tabStore = useContext(TabStore);

  const [itemlink,setItemLink]=useState(false)
  console.log("ROWS ARE...", rows);

  const [pageIndex, setPageIndex] = useState();
  const[render,setRender]=useState(0)
  //// here to chek the length of rowData
  useEffect(() => {
    var tabsArray = tabStore.state.tabs;
    for (let i = 0; i < tabsArray?.length; i++) {
      if (tabsArray[i].name == "TR059") {
        setPageIndex(i);
        break;
      }
    }
  }, []);

 
  useEffect(() => {
    let obj={
      etldata:rows,linkitems:itemlink
    }
    if (rows) {
      tabStore.dispatch({
        type: "UPDATE_TABS_ARRAY",
        changedRowsValue: obj,
        tabsFastPath: "TR059",
        keyName: "changedRows",
      });
    }
  }, [rows,itemlink]);
  useEffect(() => {
    if (tabStore.state.tabs[pageIndex]?.pageStatus == "Success") {
      setRows([]);
      setColumns([]);
      setTimeout(
        tabStore.dispatch({
          type: "UPDATE_DATA",
          fieldType: "pageStatus",
          text: null,
        }),
        1000
      );
    }
  }, [tabStore.state.tabs[pageIndex]?.pageStatus]);

  const convertExcelDateToYYYYMMDD = (excelDate) => {
    console.log("Excel Date", excelDate);
    const serialDate = new Date(1900, 0, excelDate - 1); // 1900 because Excel incorrectly considers 1900 as a leap year
    const year = serialDate.getFullYear().toString();
    const month = (serialDate.getMonth() + 1).toString().padStart(2, "0");
    const day = serialDate.getDate().toString().padStart(2, "0");

    if (year?.toString().length > 4) {
      return null;
    }
    return `${year}${month}${day}`;
  };

  const uploadFile = (e) => {
    console.log("E is ", e);
    const file = e;

    if (file) {
      ExcelRenderer(file, (err, resp) => {
        if (err) {
          alert("An Error Occured while reading the Excel");
        } else {
          const { cols, rows } = resp;
          console.log("cols......", cols, rows);
          let mergedData = [];
          var requiredColumns = [
            "category",
            "descn",
            "descn1",
            "gstn",
            "pan",
            "address",
            "city",
            "area",
            "district",
            "state",
            "country",
            "pincode",
            "zincode",
            "mobile",
            "mailid",
            "code",
            "partygp1",
            "partygp2",
            "partygp3",
            "partygp4",
            "stno",
            "sezappl",
          ];

          for (let i = 0; i < rows.length; i++) {
            let rowData = {};
            for (let j = 0; j < requiredColumns.length; j++) {
              if (
                i != 0 &&
                (requiredColumns[j] == "dcdate" ||
                  requiredColumns[j] == "expirydate" ||
                  requiredColumns[j] == "trdate")
              ) {
                var convertedDate = convertExcelDateToYYYYMMDD(rows[i][j]);
                if (!convertedDate) {
                  alert(
                    "An Error Occured while Adding the Excel, Update Date in Date Format"
                  );
                  return;
                }
                rowData[requiredColumns[j]] = convertedDate;
              } else if (requiredColumns[j] == "manudate") {
                rowData[requiredColumns[j]] = 0;
              } else if (requiredColumns[j] == "expdate") {
                rowData[requiredColumns[j]] = datedisplay(
                  rowData?.serialno?.substr(-4),
                  true
                ).dataDate;
              } else {
                rowData[requiredColumns[j]] = rows[i][j];
              }

              if (rowData[requiredColumns[j]] == undefined) {
                rowData[requiredColumns[j]] = "";
              }
            }
            mergedData.push(rowData);
          }

          console.log("Merge Data is", mergedData[0]);

          const columns = Object.keys(mergedData[0]).map((key) => {
            if (key == "manudate") {
              return {
                title: "Manufacturing Date",
                dataIndex: key,
                key,
                render: (_, text) => {
                  console.log("TEXT IS", text, key, text?.key);
                  if (
                    key == "dcdate" ||
                    key == "expirydate" ||
                    key == "trdate" ||
                    key == "expdate"
                  ) {
                    return <p>{dateFormatter(text[key])}</p>;
                  } else {
                    return <p>{text[key]}</p>;
                  }
                },
              };
            } else if (key == "expdate") {
              return {
                title: "Expiry Date",
                dataIndex: key,
                key,
                render: (_, text) => {
                  console.log("TEXT IS", text, key, text?.key);
                  if (
                    key == "dcdate" ||
                    key == "expirydate" ||
                    key == "trdate" ||
                    key == "expdate"
                  ) {
                    return <p>{dateFormatter(text[key])}</p>;
                  } else {
                    return <p>{text[key]}</p>;
                  }
                },
              };
            } else {
              return {
                title: mergedData[0][key],
                dataIndex: key,
                key,
                render: (_, text) => {
                  console.log("TEXT IS", text, key, text?.key);
                  if (
                    key == "dcdate" ||
                    key == "expirydate" ||
                    key == "trdate" ||
                    key == "expdate"
                  ) {
                    return <p>{dateFormatter(text[key])}</p>;
                  } else {
                    return <p>{text[key]}</p>;
                  }
                },
              };
            }
          });
          setColumns(columns);
          setRows(mergedData.slice(1));
        }
      });
    }
  };

  const Experidate = (dateString) => {
    console.log("datestring:", dateString);
    var parts = dateString.split("/");
    var day = parts[0];
    var month = parts[1];
    var year = parts[2];

    // Pad day and month with leading zeros if necessary
    if (day.length === 1) {
      day = "0" + day;
    }
    if (month.length === 1) {
      month = "0" + month;
    }
    console.log("mydate:", dateString, year + month + day);
    return year + month + day;
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const files = e.dataTransfer.files;
    if (files.length > 0) {
      // Assuming you have a function called handleFileUpload to handle the file
      uploadFile(files[0]);
      showMessage("File added successfully!");
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      // Assuming you have a function called handleFileUpload to handle the file
      uploadFile(file);
      showMessage("File added successfully!");
    }
  };
  const showMessage = (message) => {
    setMessage(message);
    setTimeout(() => {
      setMessage("");
    }, 3000); // Clear message after 3 seconds
  };

  console.log("Columns Are",  tabStore);
 /// here code rendring the excel upload
 useEffect(()=>{
  setRender((p)=>p+1)
    },[tabStore?.state?.tabs[pageIndex]?.changedRows?.length])
  return (
    <div className="container" key={render}>
      <div style={{ display: "flex", gap: "10px", marginLeft: "40%" }}>
        <div
          className={`drop-area ${message ? "active" : ""}`}
          onDragOver={handleDragOver}
          onDrop={handleDrop}
        >
          <p className="message">{message || "Drag & Drop files here"}</p>
          <div className="or">or</div>
          <label htmlFor="fileInput" className="file-label">
            Select File
            <input
              type="file"
              accept=".csv,.xlsx,.xls"
              id="fileInput"
              className="file-input"
              onChange={handleFileChange}
            />
          </label>
          <div className="file-info" id="fileInfo"></div>
        </div>
        <div style={{ flex: 1 }}>
          <CallbackCheckbox
          editable={true}
            apikey={"linkitems"}
            displayLabel={"Link Customer Item"}
            defaultValue={itemlink}
            returnValue={(e) => {
              console.log("RRRDTDYTD", e);
              setItemLink(e)
            }}
          />
        </div>
      </div>

      <div>
        <Table dataSource={rows} columns={columns} />
      </div>
    </div>
  );
}

export default LinkingPage;

import { Table, Typography} from 'antd'
import React, { useContext, useEffect, useState } from 'react'
import { TabStore } from '../../../TabsStructure/TabsStore'
import useSaveChangedRows from '../../Hooks/useSaveChangedRows'
const { Text } = Typography;

function BillingTable({columns}) {
  const useChangedRows = useSaveChangedRows()
  const [rowData, setRowData] = useState([])
  const tabStore = useContext(TabStore)
  useEffect(()=> {
    var changedColumnRowData = useChangedRows.getValue("footer")?useChangedRows.getValue("footer"):[]
    setRowData(changedColumnRowData)
  }, [tabStore.state.tabs[tabStore.state.tabIndex].changedRows?.footer])

  return (
    <div>
        <Table
        bordered
        pagination={false}
        columns={columns}
        dataSource={rowData}
        
        />
    </div>
  )
}

export default BillingTable
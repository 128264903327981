import React, { useState, useEffect, useContext } from "react";
import InputComponent from "../CommonControllers/Components/InputComponent";
import "../CommonControllers/global.css";
import { Typography, Button } from "antd";
import { AuthStore } from "./AuthStore";
import { forgotURL, loginURL } from "../CommonControllers/Urls";
import axios from "axios";
import { CheckCircleTwoTone } from "@ant-design/icons";
import johnson from "../Assets/johnson.jpeg";
import sapldemo from "../Assets/sapldemo.png";
// import bizzcontrol from '../Assets/MicrosoftTeams-image.png'

import sutradhar from "../Assets/sutradhar.png";
import ForgotPassword from "./ForgotPasswor";
import { Avatar } from "@material-ui/core";

const { Title } = Typography;

const Login = () => {
  const auth = useContext(AuthStore);
  const [loading, setLoading] = useState(false);
  const [token, setToken] = useState("");
  const [tenantName, setTenantName] = useState("");
  const [tenantrecno, setTenantrecno] = useState("");
  const [forgot, setForgot] = useState(false);
  const [changepasswor, setChangesPasswords] = useState(false);
  const [change, setChange] = useState(false);

  const Greetings = () => (
    <div>{token ? `Welcome ${tenantName} !!!` : { tenantName }}</div>
  );
  const url = new URL(window.location.href);
  const tenant = url.searchParams.get("tenant"); // Extract tenant name from URL path

  // This func handle the user loginid and password,
  const handleClick = () => {
    setLoading((prev) => !prev);
    const loginId = document.querySelector("#userLoginId").value;
    const password = document.querySelector("#userPassword").value;
 
    if (loginId === "" || password === "") {
      alert("Please Input Login Id and Password");
      setLoading((prev) => !prev);
      return;
    }

    const payload = {
      loginid: loginId,
      pwd: password,
    };

    payload &&
      axios
        .post(loginURL, payload)
        .then((res) => {
          setTimeout(() => {
            if (res.data.Success) {
              setLoading((prev) => !prev);
              setToken(res?.data?.Message?.firebasetokenweb);
              setTenantName(res?.data.Message?.tenantname);
              setTenantrecno(res?.data?.Message?.tenantrecno);
              auth.dispatch({
                type: "LOGIN",
                sessionToken: "",
                userdata: res?.data.Message,
                tenantrecno: res?.data?.Message?.tenantrecno,
                workdate: res?.data?.Message?.workdate,
              });
            } else {
              setLoading((prev) => !prev);
              alert("Invalid LoginId or Password");
              document.querySelector("#userLoginId").value = "";
              document.querySelector("#userPassword").value = "";
            }
          }, 1000);
        })
        .catch((error) => {
          alert(error?.response?.data?.Error);
          console.log("Error is", error?.response)
          setLoading((prev) => !prev);
        });
  };

  useEffect(() => {
    setTimeout(() => {
      token &&
        auth.dispatch({
          type: "LOGIN",
          sessionToken: token,
          tenantrecno: tenantrecno,
        });
    }, 1000);
  }, [token]);
  /// forgotpasswords logic here
  const forgotpasswords = async () => {
    const loginId = document.querySelector("#userLoginId").value;
    const payload = {
      loginid: loginId,
    };
    let res = await axios
      .post(forgotURL, payload)
      .then((res) => {
       
      alert("OTP has been sent to your registered mail id.");
        if (res.data.Success) {
          setChangesPasswords(true);
        }
      })
      .catch((err) => {
        console.log("TDIO",err)
       alert(err?.response?.data?.Error)
      });
  };

  return (
    <>
      {changepasswor == false ? (
        <div className="maindiv">
          <div className="main-content">
            <div className="imformation">
              <div>
                <div>
                  <div>
                    {url?.hostname == "bizzcontrol.in" ? (
                      <img src={johnson} />
                    ) : url?.hostname == "bizzcontrol.in/demo/"?<><img src={sapldemo} /></>:(
                      <>
                        {" "}
                        <img src={sutradhar} />
                        <div>
                          <h2 style={{ color: "black",fontWeight:"bold" }}>
                            {" "}
                            Sutradhar Project Consultancy Pvt. Ltd.
                          </h2>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="loginBox">
              <div>
                <Title level={3} style={{ color: "gray", fontWeight: "bold" }}>
                  {!tenantName ? (
                    <Avatar
                      style={{
                        fontSize: "20px",
                        width: "100px", // Set the desired width
                        height: "100px", // Set the desired height
                        backgroundColor: "#f0f0f0", // Light gray background color
                        margin: "0 auto 20px", // Center horizontally and add 20px margin at the bottom
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        transition: 'transform 0.2s'
                      }}
                      className="avatar"
                    >
                      {/* Your avatar content */}
                    </Avatar>
                  ) : (
                    <Greetings />
                  )}
                </Title>
              </div>
              <div
                onKeyDown={(e) => {
                  if (e.keyCode == 13 && e.target.value) {
                    document.getElementById("userPassword").focus();
                  } else if (e.keyCode == 13 && !e.target.value) {
                    alert("Please Enter Valid User ID");
                  }
                }}
              >
                <p>Enter Login ID</p>
                <InputComponent
                  id="userLoginId"
                  required
                  //displayLabel="Enter Login Id"
                />
              </div>
              <div
                onKeyDown={(e) => {
                  if (e.keyCode == 13 && e.target.value) {
                    handleClick(e);
                  }
                }}
              >
                {forgot == false ? (
                  <>
                    {" "}
                    <p>Enter Password</p>
                    <InputComponent
                      id="userPassword"
                      type="password"
                      required
                      //displayLabel="Enter Password"
                    />
                  </>
                ) : null}
              </div>
              {forgot == false ? (
                <p
                  onClick={(e) => {
                    setForgot(true);
                  }}
                  style={{ color: "blue", cursor: "pointer" }}
                >
                  Forgot Password ?
                </p>
              ) : null}

              <div>
                <Button
                  type="primary"
                  size="large"
                  onClick={() => {
                    if (forgot == false) {
                      handleClick();
                    } else if (forgot == true) {
                      forgotpasswords();
                    }
                  }}
                  loading={loading}
                  style={{ color: "white" }}
                  icon={token && <CheckCircleTwoTone twoToneColor="green" />}
                >
                  {!token ? "Submit" : "Logged In"}
                </Button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <ForgotPassword />
      )}
    </>
  );
};

export default Login;

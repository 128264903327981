import React, { useContext, useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import SaveDropdown from "../Components/SaveComponents/SaveDropdown";
import SaveTextInput from "../Components/SaveComponents/SaveTextInput";
import SaveCheckbox from "../Components/SaveComponents/SaveCheckbox";
import SaveDatePicker from "../SaveComponents/SaveDatePicker";
import SaveFileInput from "../Components/SaveComponents/SaveFileInput";
import SaveRadioBtn from "../Components/SaveComponents/SaveRadioBtn";
import { TabStore } from "../../TabsStructure/TabsStore";

const WMSAdditionalDetails = ({
  customerrecno,
  supplierrecno,
  filterOption,
  fastPath
}) => {
  const [filterOptions, setFilterOptions] = useState([]);
  const [filterDictionary, setFilterDictionary] = useState([]);
  const [selectedFilterOptions, setSelectedFilterOptions] = useState({});
  const tabStore = useContext(TabStore);
  const [pageIndex, setPageIndex] = useState();

  useEffect(() => {
    var tabsArray = tabStore.state.tabs;
    for (let i = 0; i < tabsArray?.length; i++) {
      if (tabsArray[i].name == fastPath) {
        setPageIndex(i);
        break;
      }
    }
  }, []);

  useEffect(() => {
    setFilterOptions(filterOption);
  }, [filterOption]);

  useEffect(() => {
    if (customerrecno) {
      setSelectedFilterOptions((p) => {
        return { ...p, customerrecno: customerrecno.customerrecno };
      });
    } else if (supplierrecno) {
      setSelectedFilterOptions((p) => {
        return { ...p, supplierrecno: supplierrecno.supplierrecno };
      });
    }
  }, [customerrecno, supplierrecno]);

  //* This function sets the values obtained from the filteroptions above
  const setterFunction = (event, element) => {
    let displayValue;
    //# May need to use logical OR operator
    typeof event === "object"
      ? (displayValue = event?.displayLabel ?? event.label)
      : (displayValue = event);

    console.log(
      "FC ln 64 value ========>",
      element["displayLabel"],
      displayValue,
      "filter dict",
      filterDictionary
    );

    //% Check here whether array contains the same id, if yes replace it with new object

    const targetReplacer = filterDictionary?.find(
      (obj) => obj.id === element.displayLabel
    );
    if (targetReplacer) {
      //~# alert(`Replaced previous filter: ${element?.displayLabel}`);
      Object.assign(targetReplacer, {
        id: element?.displayLabel,
        descn: displayValue,
      });
      return;
    }

    console.log("FC target found ??", targetReplacer);

    //% Setting id as displayLabel ==> id : Domain and key : value as ==> Domain : SAPL (example)
    setTimeout(() => {
      let tempArray = [
        ...filterDictionary,
        { id: element?.displayLabel, descn: displayValue },
      ];

      console.log("FC", tempArray);

      setFilterDictionary(tempArray);
    }, 100);
  };

  const storeData = (e, apikey, labeldropdowns) => {
    // Create a Temporary Add Store in the TabStore
    var newData = selectedFilterOptions;
    newData[apikey] = e;
    setSelectedFilterOptions(newData);

    // if (setfilterdropdown) {
    //   setfilterdropdown(labeldropdowns); // smk 24-2-
    // }

    console.log("SD", e, apikey, labeldropdowns);
  };

  // Switch to the next field as we click the Enter button
  const enterPress = (e) => {
    // when the button is disable also we have make to move the next elements
    if (e.keyCode === 13) {
      e.preventDefault();
      const form = e.target.form;
      const index = Array.prototype.indexOf.call(form, e.target);

      // Find the next focusable element that is not disabled
      let nextIndex = index + 1;
      while (form.elements[nextIndex] && form.elements[nextIndex].disabled) {
        nextIndex++;
      }

      // Focus on the next focusable and non-disabled element
      if (form.elements[nextIndex]) {
        form.elements[nextIndex].focus();
      }
    }
  };
  console.log("filterOptions2", filterOptions);

  return (
    <form className="additionalDetails">
      {filterOptions?.map((elem, i) =>
        elem.type === "dropdown" ? (
          <div
            onKeyDown={enterPress}
            style={{ zIndex: 2000 - i, display: "flex", flexWrap: "wrap" }}
          >
            <SaveDropdown
              displayLabel={elem?.title}
              alignment="column"
              placeholder={elem?.placeholder}
              apiurl={elem?.apiurl}
              selectionLabel={elem?.selectionlabel}
              selectionValue={elem?.selectionvalue}
              apikey={elem?.apiKey}
              multi={elem?.isMulti}
              records={20}
              minInput={0}
              returnValue={(e) => {
                if (e) {
                  console.log(
                    "FC:",
                    e[elem?.selectionvalue],
                    elem?.apiKey,
                    e[elem?.selectionlabel]
                  );
                  setterFunction(e, elem);
                  storeData(
                    e[elem?.selectionvalue],
                    elem?.apiKey,
                    e[elem?.selectionlabel]
                  );
                }
              }}
              body={{
                ...selectedFilterOptions,
                customerrecno: customerrecno,
              }}
              requiredBody={elem?.payloadKey}
              width={`${elem?.width}px`}
              defaultSelected={elem?.defaultValue}
              isrequired={elem?.req}
            />
          </div>
        ) : elem.type === "boolean" ? (
          <div onKeyDown={enterPress}>
            <SaveCheckbox
              alignment="column"
              displayLabel={elem?.title}
              placeholder={elem?.placeholder}
              apikey={elem?.apiKey}
              defaultValue={elem?.defaultValue}
              change
              editable
              returnValue={(e) => {
                setterFunction(e, elem);
                storeData(e, elem?.apiKey);
              }}
            />
          </div>
        ) : elem.type === "int" ? (
          <div onKeyDown={enterPress}>
            <SaveTextInput
              alignment="column"
              type={"number"}
              displayLabel={elem?.title}
              placeholder={elem?.placeholder}
              apikey={elem?.apiKey}
              defaultValue={elem?.defaultValue}
              change
              returnValue={(e) => {
                setterFunction(e, elem);
                storeData(e, elem?.apiKey);
              }}
              isrequired={elem?.req}
              // width={elem?.width}
              width={`${elem?.width}px`}
              pageIndex={pageIndex}
            />
          </div>
        ) : elem.type === "decimal" ? (
          <div onKeyDown={enterPress}>
            <SaveTextInput
              alignment="column"
              type={"number"}
              displayLabel={elem?.title}
              placeholder={elem?.placeholder}
              apikey={elem?.apiKey}
              defaultValue={elem?.defaultValue}
              change
              returnValue={(e) => {
                console.log("FC", e);
                setterFunction(e, elem);
                storeData(e, elem?.apiKey);
              }}
              isrequired={elem?.req}
              // width={elem?.width}
              width={`${elem?.width}px`}
              pageIndex={pageIndex}
            />
          </div>
        ) : elem.type === "string" ? (
          <div onKeyDown={enterPress}>
            <SaveTextInput
              alignment="column"
              displayLabel={elem?.title}
              placeholder={elem?.placeholder}
              apikey={elem?.apiKey}
              defaultValue={elem?.defaultValue}
              change
              returnValue={(e) => {
                console.log("FC", e);
                setterFunction(e, elem);
                storeData(e, elem?.apiKey);
              }}
              isrequired={elem?.req}
              // width={elem?.width}
              width={`${elem?.width}px`}
              pageIndex={pageIndex}
            />
          </div>
        ) : elem.type === "date" ? (
          <div onKeyDown={enterPress} style={{ zIndex: 1800 - i }}>
            <SaveDatePicker
              id={uuidv4()}
              alignment="column"
              defaultValue={elem?.defaultValue ? elem.defaultValue : ""}
              displayLabel={elem?.title}
              placeholder={elem?.placeholder}
              apiKey={elem?.apiKey}
              change
              editable
              returnValue={(e) => {
                console.log("FC", e);
                setterFunction(Number(e), elem);
                storeData(e, elem?.apiKey);
              }}
              width={`${elem?.width}px`}
            />
          </div>
        ) : elem.type === "file" ? (
          <div onKeyDown={enterPress}>
            <SaveFileInput
              displayLabel={elem?.title}
              placeholder={elem?.placeholder}
              apiKey={elem?.apiKey}
              returnValue={(e) => {
                setterFunction(e, elem);
                storeData(e, elem?.apiKey);
              }}
            />
          </div>
        ) : elem.type === "radio" ? (
          <div onKeyDown={enterPress}>
            <SaveRadioBtn
              apikey={"radio"}
              displayLabel={"Select Radio"}
              options={elem?.radioOptions}
              defaultValue={elem?.defaultValue}
              returnValue={(e) => {
                setterFunction(e, elem);
                storeData(e, elem?.apiKey);
              }}
            />
          </div>
        ) : null
      )}
    </form>
  );
};

export default WMSAdditionalDetails;

import React from "react";

const PrintDecimal = ({ item }) => {
  const stringValue = `${item?.fieldvalue?.toString()}.00`;

  console.log("stringValue", stringValue);

  return (
    <div
      style={{
        position: "absolute",
      }}
    >
      <p
        style={{
          padding: "3px",
          fontSize: `${item?.fontsize}px`,
          position: "absolute",
          textAlign: "right",
          border: item?.printbox === 1 ? "1px solid black" : null,
          left: `${item?.fieldx}px`,
          top: `${item?.fieldy}px`,
          fontFamily: item?.fontname,
          fontWeight: item?.fontbold === 1 ? "bold" : null,
          fontStyle: item?.fontitalic === 1 ? "italic" : null,
          width: `${item?.fieldxto}px`,
          height: `${item?.fieldyto}px`,
          wordWrap: "break-word",
        }}
      >
        {stringValue}
      </p>
    </div>
  );
};

export default PrintDecimal;

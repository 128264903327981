import axios from "axios";

import React, { useContext, useEffect, useState } from "react";

import Select from "react-select";

import AsyncSelect from "react-select/async";

import { updateValue } from "../../TabsStructure/tabFunctions";

import { TabStore } from "../../TabsStructure/TabsStore";

import { AuthStore } from "../../Auth/AuthStore";
import useSaveChangedRows from "../Hooks/useSaveChangedRows";
import { PropaneSharp } from "@mui/icons-material";
import { baseUrl } from "../Urls";

const SaveDropDown = ({
  async,
  inputValue,
  apiUrl,
  selectionLabel,
  selectionValue,
  minInput,
  placeholder,
  displayLabel,
  className,
  apikey,
  id,
  multi,
  width,
  body,
  setDataToParent,
  defaultValue,
  zIndex = 10,
  isrequired, ///Pradeep task id -398
  alignment,
  disabled
}) => {
  const tabStore = useContext(TabStore);
  const auth = useContext(AuthStore);
  const bgcolor = auth?.state?.userdata?.theme[0]?.inputbgcolor
  const btncolor = auth?.state?.userdata?.theme[0]?.btncolor
  let drpWidth = width ? width : "250px";
  const [options, setOptions] = useState([]);
  const useChangedRows = useSaveChangedRows();
  const [selectedValue, setSellectedValue] = useState(null);
  const [apiBody,setApibody]=useState(body)

 
  useEffect(() => {
   
    if(apiBody){
      getDropDownList();
    }
  
  }, [apiBody]);
  async function getDropDownList(inputValue) {
    body = {
      tenantrecno: auth.state.userdata.tenantrecno,
      domainrecno: auth.state.userdata.domainrecno,
      // domainlocationrecno: 81,
      descn: inputValue,
      ...body,
    };
   
    
    if (apiUrl.includes("http")) {
      apiUrl = apiUrl
    } else {
      apiUrl = baseUrl + apiUrl + "/"
    }


    const resp = await axios.post(apiUrl, body); 
   console.log("resp12",resp)
    const formattedArray = resp.data.Message.map((item) => {
      return {
        ...item,
        value: item[selectionValue],
        label: item[selectionLabel],
      };
    });
    console.log("yscaxhysavcyxshga",selectionLabel,selectionValue,formattedArray)
    setOptions(formattedArray);

    if (formattedArray.length === 1) {
      setSellectedValue(formattedArray[0]);
      useChangedRows.updateValue(apikey, formattedArray[0][apikey]);
      if (setDataToParent) {
        setDataToParent(formattedArray[0]);
        useChangedRows.updateValue(apikey, formattedArray[0][apikey]);
      }
    }
    return formattedArray;
  }

  const loadOptions = async (inputValue, callback) => {
    if (inputValue.length >= minInput) {
      const customers = await getDropDownList(inputValue);
      callback(customers);
    }
  };

  useEffect(() => {
    //if (!async) {
      getDropDownList();
    //}
  }, []);

  // useEffect(() => {
  //   console.log("Default Value of DropDown is", defaultValue, selectedValue)
  //   // if (selectedValue == null) {
  //     var selectedSupplier = tabStore?.state?.tabs[tabStore.state.tabIndex]?.changedRows
  //       ? tabStore?.state?.tabs[tabStore.state.tabIndex]?.changedRows[apikey]
  //       : defaultValue;

  //     const selectedObject = options?.filter((item) => item[apikey] == selectedSupplier);

  //     console.log("Selected Object", selectedObject)
  //     if (selectedObject.length > 0) {
  //       setSellectedValue(selectedObject[0]);
  //       if (setDataToParent) {
  //         setDataToParent(selectedObject[0]);
  //       }
  //     } else {
  //       setSellectedValue(null)
  //     }
  //   // }

  // }, [options, defaultValue]);

  /// here the logic for the default select for the dropdown where one item id there in the dropdown
  

  useEffect(() => {
    // Selecting the value if it's not selected and a default value is provided
    if (defaultValue && selectedValue === null) {
      const selectedObject = options.find(
        (item) => item[apikey] === defaultValue
      );

      if (selectedObject) {
        setSellectedValue(selectedObject);
        if (setDataToParent) {
          setDataToParent(selectedObject);
        }
      }
    }

    // Synchronizing with selected supplier based on changes in options
    if (selectedValue === null) {
      var selectedSupplier = tabStore?.state?.tabs[tabStore.state.tabIndex]
        ?.changedRows
        ? tabStore?.state?.tabs[tabStore.state.tabIndex]?.changedRows[apikey]
        : defaultValue;

      const selectedObject = options.find(
        (item) => item[apikey] === selectedSupplier
      );

      if (selectedObject) {
        setSellectedValue(selectedObject);
        if (setDataToParent) {
          setDataToParent(selectedObject);
        }
      } else {
        setSellectedValue(null);
      }
    }
  }, [async, defaultValue, selectedValue, options]);

  const updateMultiSelection = (e) => {
    if (e?.length > 0) {
      var updateValues = "";
      e.map((item) => {
        updateValues = updateValues + item[selectionValue] + ",";
      });
      updateValues = updateValues.substring(0, updateValues.length - 1);
      useChangedRows.updateValue(apikey, updateValues);
      setSellectedValue(e);
      if (setDataToParent) {
        setDataToParent(e);
        useChangedRows.updateValue(apikey, e[selectionValue]);
      }
    } else {
      setSellectedValue(e);

      useChangedRows.updateValue(apikey, e[selectionValue]);
      if (setDataToParent) {
        setDataToParent(e);
      }
    }
  };

  console.log("RTYTRFCIUYTFVYu",options)
  const [isFocused, setIsFocused] = useState(false);
  const handleFocus = () => {
    // This function is called when the input element receives focus.
    setIsFocused(true)
  }

  const handleBlur = () => {
    // This function is called when the input element loses focus.
    setIsFocused(false)
  }
  // return (
  //   <div
  //     style={{
  //       display: "flex",
  //       alignItems: "center",
  //       flexDirection: alignment,
  //       gap: alignment && "8px",
  //       zIndex: zIndex,
  //     }}
  //   >
  //     {!alignment ? (
  //       <p style={{ paddingRight: "15px", color: "black" }}>{displayLabel} </p>
  //     ) : (
  //       //{Pradeep task id -398 added * mark for mandatory field}
  //       <p style={{ width: "100%" }}>
  //         {isrequired ? (
  //           <span>
  //             {displayLabel}
  //             <span style={{ color: "red" }}>*</span>
  //           </span>
  //         ) : (
  //           `${displayLabel}`
  //         )}
  //       </p>
  //     )}
  //     <div style={{ width:drpWidth }}>
  //       {async ? (
  //         <AsyncSelect
            
  //           styles={{
  //             control: (baseStyle, state) => ({
  //               ...baseStyle,
  //               ...dropDownStyles,
  //               menu: (provided) => ({ ...provided, zIndex: 9999 }),
  //             }),
  //           }}
  //         />
  //       ) : (
  //         <Select
            
  //           styles={{
  //             control: (baseStyle, state) => ({
  //               ...baseStyle,
  //               ...dropDownStyles,
  //               menu: (provided) => ({ ...provided, zIndex: 9999 }),
  //             }),
  //           }}
  //         />
  //       )}
  //     </div>
  //   </div>
  // );
  return (
    <div
    
      style={{
        display: "flex",
        alignItems: "center",
        flexDirection: alignment,
        textAlign: "start",
        gap: "8px",
        position: "relative"
      }}
    >
      {/* {!alignment ? (
        <p>{displayLabel}</p>
      ) : (
        <p
          style={{
            width: "100%",
          }}
        >
          {isrequired ? (
            <span>
              {displayLabel}
              <span style={{ color: "red" }}>*</span>
            </span>
          ) : (
            `${displayLabel}`
          )}
          
        </p>
      )} */}
     

      <div style={{ width: drpWidth }}>
        {async ? (
          <AsyncSelect
          onFocus={handleFocus}
            onBlur={handleBlur}
            id={id}
            isDisabled={disabled}
            name={apikey}
            defaultValue={selectedValue}
            value={selectedValue}
            placeholder={placeholder}
            cacheOptions
            loadOptions={loadOptions}
            onChange={(e) => {
              updateMultiSelection(e);
            }}
            isClearable
            isMulti={multi}
            styles={{
              control: (baseStyle, state) => ({
                ...baseStyle,
                ...dropDownStyles,
                menu: (provided) => ({ ...provided, zIndex: 9999 }),
                backgroundColor:bgcolor || "#EBEDF0"
              }),
              option: (baseStyle, { isFocused, isSelected }) => ({
                ...baseStyle,
                backgroundColor: isSelected
                  ? btncolor || "#EBEDF0"
                  : isFocused
                  ? bgcolor || "#EBEDF0"
                  : 'white',
                  color:isSelected
                  ? "black" : "black"
              }),
            }}
          />
        ) : (
          <Select
          onFocus={handleFocus}
            onBlur={handleBlur}
            id={id}
            isDisabled={disabled}
            defaultValue={selectedValue}
            onChange={(e) => {
              updateMultiSelection(e);
            }}
            value={selectedValue}
            isMulti={multi}
            options={options}
            className="basic-multi-select"
            classNamePrefix="select"
            name={apikey}
            theme={(theme) => ({
              ...theme,
              borderRadius: "4px",
              backgroundColor: "ghostwhite",
              colors: { ...theme.colors, text: "orangered" },
            })}
            styles={{
              control: (baseStyle, state) => ({
                ...baseStyle,
                ...dropDownStyles,
                menu: (provided) => ({ ...provided, zIndex: 9999 }),
                backgroundColor:bgcolor || "#EBEDF0"
              }),
              option: (baseStyle, { isFocused, isSelected }) => ({
                ...baseStyle,
                backgroundColor: isSelected
                  ? btncolor || "#EBEDF0"
                  : isFocused
                  ? bgcolor || "#EBEDF0"
                  : 'white',
                  color:isSelected
                  ? "black" : "black"
              }),
            }}
            //// added for rendering  every changes
          />
        )}
      </div>
      <label
              htmlFor={id}
              className={`label ${isFocused || selectedValue ? 'focused' : 'unfocused'}`}
              style={{
               
                whiteSpace: "nowrap",
                wordWrap:"unset",
                position: 'absolute',
                top: isFocused || selectedValue ? '-19px' : '50%',
                left: '8px',
                //backgroundColor: isFocused || selectedValue ? 'white' : "transparent",
                padding: '0 4px',
                fontSize: isFocused || selectedValue ? '12px' : '16px',
                color: isFocused ? 'black' : '',
                pointerEvents: 'none',
                transform:
                  isFocused || selectedValue
                    ? 'translateY(0)'
                    : 'translateY(-50%)',
                transition:
                  'top 0.3s, transform 0.3s, font-size 0.3s, color 0.3s'
              }}
            >
              {isrequired ? (
               <span className={`label ${isFocused || selectedValue ? 'focused' : 'unfocused'}`}>
                  {isFocused || selectedValue? displayLabel : placeholder}
                  <span style={{ color: 'red' }}>*</span>
                </span>
              ) : (
                <span className={`label ${isFocused || selectedValue ? 'focused' : 'unfocused'}`}>
                  {isFocused || selectedValue? displayLabel :placeholder}
                 
                </span>
              )}
            </label>
    </div>
  );
};

export default SaveDropDown;

//CSS

const dropDownStyles = {
  border: "1px solid #cccccc",
  borderRadius: "8px",
  height: "16px",
};

import React, { useContext, useState, useEffect } from "react";
import { apiCallwithBaseUrl } from "../../ApiCalls";
import { AuthStore } from "../../../Auth/AuthStore";
import axios from "axios";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import useExecute from "../../Hooks/useExecute";

function CallbackDropdown({
  displayLabel,
  apikey,
  apiurl,
  optionsList,
  defaultSelected,
  returnValue,
  returnLabel,
  async,
  selectionValue,
  selectionLabel,
  placeholder,
  style,
  className,
  multi,
  body,
  alignment,
  width,
  minInput,
  surveytyperecno,
  isrequired,
  id,disabled
}) {
  // console.log("Body",option)
  let drpWidth = width ? width : "100%";
  const auth = useContext(AuthStore);
  const bgcolor = auth?.state?.userdata?.theme[0]?.inputbgcolor
  const btncolor = auth?.state?.userdata?.theme[0]?.btncolor
  const [options, setOptions] = useState([]);
  const [selectedValue, setSelectedValue] = useState(null);
  const execute = useExecute();
  const [apiBody, setApiBody] = useState({});

  
  if (!selectedValue || (Array.isArray(selectedValue) && selectedValue.length === 0)) {
    drpWidth = "250px";
  }
  
  useEffect(() => {
    // Check if body is defined and its values are not undefined
    if (body && Object.values(body).every(value => value !== undefined) && apiBody) {
      const bodyValues = Object.values(body);
      const apiBodyValues = Object.values(apiBody);
  
      if (bodyValues.some((value, index) => value !== apiBodyValues[index])) {
      
        setApiBody(body);
      }
    }
   
  }, [body, apiBody]);
  
  // Use a separate useEffect for calling the API when apiBody changes
  useEffect(() => {
   
      if (body) {
        console.log("Customer API Called", apiBody,body);
        // Call the API function (getdropdownoptions) here
        if( Object.values(body).every(value => value !== undefined)){ 
         
          getdropdownoptions();}
       
       }
  }, [apiBody]); 

  // // Default Values
  // useEffect(() => {
  //   if (options?.length > 0) {
  //     if (defaultSelected) {
  //       if (execute.getValue(apikey)) {
  //         if (typeof execute.getValue(apikey) == "string") {
  //           if (execute.getValue(apikey).includes(",")) {
  //             let selectionArr = execute.getValue(apikey).split(",");
  //             let selectedOptions = [];
  //             selectionArr.map((i) => {
  //               const selectedObject = options?.filter(
  //                 (item) => item[selectionValue] == i
  //               );
  //               selectedOptions.push(selectedObject);
  //             });
  //             setSelectedValue(selectedOptions);
  //           } else {
  //             const selectedObject = options?.filter(
  //               (item) => item[selectionValue] == execute.getValue(apikey)
  //             );
  //             let selectedOptions = selectedObject[0];
  //             setSelectedValue(selectedOptions);
  //           }
  //         }
  //          else {
  //           const selectedObject = options?.filter(
  //             (item) => item[selectionValue] == execute.getValue(apikey)
  //           );
  //           let selectedOptions = selectedObject[0];
  //           setSelectedValue(selectedOptions);
  //         }

  //         // } else if (execute.getValue(apikey) == "") {
  //         //   setSelectedValue(execute.getValue(apikey));
  //       } else {
  //         const selectedObject = options?.filter(
  //           (item) => item[selectionValue] == defaultSelected
  //         );
  //         setSelectedValue(selectedObject);
  //         execute.updateValue(apikey, defaultSelected);
  //       }
  //     } else {
  //       if (execute.getValue(apikey)) {
  //         if (typeof execute.getValue(apikey) == "string") {
  //           if (execute.getValue(apikey).includes(",")) {
  //             let selectionArr = execute.getValue(apikey).split(",");
  //             let selectedOptions = [];
  //             selectionArr.map((i) => {
  //               const selectedObject = options?.map((item) => {
  //                 if (item[selectionValue] == i) {
  //                   console.log("Selected Array Matched", item, i);
  //                   selectedOptions.push(item);
  //                 }
  //               });
  //             });

  //             setSelectedValue(selectedOptions);
  //           } else {
  //             const selectedObject = options?.filter(
  //               (item) => item[selectionValue] == execute.getValue(apikey)
  //             );
  //             let selectedOptions = selectedObject[0];
  //             setSelectedValue(selectedOptions);
  //           }
  //         }
  //          else {
  //           const selectedObject = options?.filter(
  //             (item) => item[selectionValue] == execute.getValue(apikey)
  //           );
  //           let selectedOptions = selectedObject[0];
  //           setSelectedValue(selectedOptions);
  //         }
  //       }
  //     }
  //   }
  // }, [options]);
  // Default Values
  useEffect(() => {
    if (options?.length > 0) {
  
      if (defaultSelected && (typeof defaultSelected == "string" || typeof defaultSelected == "number")) {
        defaultSelected = defaultSelected.toString();
        if (defaultSelected.includes(",")) {
          let selectionArr = defaultSelected.split(",");
          let selectedOptions = [];


          selectionArr.map((i) => {
            const selectedObject = options?.map((item) => {
              if (item[selectionValue] == i) {
                selectedOptions.push(item);
              }
            });
          });
          setSelectedValue(selectedOptions);
        } else {
          const selectedObject1 = options?.filter(
            (item) => console.log("item[selectionValue] == defaultSelected",defaultSelected,item[selectionValue])
          );


          const selectedObject = options?.filter((item) => item[selectionValue] == defaultSelected)
          
         
            if (defaultSelected == "21069099" ) {
              options?.map((item) => {console.log("MAP Function of the Object is..", item[selectionValue], defaultSelected, item, selectionValue)})
            }

            console.log("MAP Function of the Object is", selectedObject1, selectedObject)
          setSelectedValue(selectedObject);
        }
      }
    }
  }, [options, defaultSelected]);

  async function getdropdownoptions(inputValue) {
    var newBody = {
      tenantrecno: auth.state.userdata.tenantrecno,
      domainrecno: auth.state.userdata.domainrecno,
      descn: inputValue,
      surveytyperecno: auth.state.usedata?.surveytyperecno,
      ...apiBody,
    };
    console.log("Body is DD", newBody, apiurl, apiBody);
    var resp;
    if (apiurl.includes("http")) {
      resp = await axios.post(apiurl, newBody);
      resp = resp.data;
    } else {
      resp = await apiCallwithBaseUrl("POST", apiurl, newBody);
    }
    console.log("resp:", apikey, resp);
    const formattedArray = resp.Message.map((item) => {
      return {
        ...item,
        value: item[selectionValue],
        label: item[selectionLabel],
      };
    });
    console.log("formattedArray:", apikey, formattedArray);
    setOptions(formattedArray);
    if (formattedArray.length === 1) {
      setSelectedValue(formattedArray[0]);
      execute.updateValue(apikey, formattedArray[0][apikey]);
      if (returnValue) {
        returnValue(formattedArray[0]);
        execute.updateValue(apikey, formattedArray[0][apikey]);
      }
    }
    return formattedArray;
  }

  // For Updating the Values to the TabStore and reverting to Parent
  const updateMultiSelection = (e) => {
    if(e===null){
      console.log("DropDown Selected Values", e);
      return 
    }
    
    if (Array.isArray(e) && e.length > 0) {
      var updateValues = "";
      e.map((item) => {
        updateValues = updateValues + item[selectionValue] + ",";
      });
      updateValues = updateValues.substring(0, updateValues.length - 1);
      //execute.updateValue(apikey, updateValues);
      setSelectedValue(e);
      if (returnValue) {
        returnValue(e);

        // console.log("CX",x)
        return selectedValue;
      }
    } else {
      setSelectedValue(e);
      //execute.updateValue(apikey, e[selectionValue]);
      if (returnValue) {
        returnValue(e);
        if (returnLabel) {
          returnLabel(e);
        }
        return selectedValue;
      }
    }
  };

  const loadOptions = async (inputValue, callback) => {
    if (inputValue.length >= minInput) {
      const dropdownoptions = await getdropdownoptions(inputValue);
      callback(dropdownoptions);
    }
  };

  useEffect(() => {
    getdropdownoptions();
  }, []);

  const [isFocused, setIsFocused] = useState(false);
  const handleFocus = () => {
    // This function is called when the input element receives focus.
    setIsFocused(true)
  }

  const handleBlur = () => {
    // This function is called when the input element loses focus.
    setIsFocused(false)
  }
console.log("disableddisabled",disabled)
  return (
    <div
      style={{
        display: "flex",
        alignItems: "start",
        flexDirection: alignment,
        textAlign: "start",
        gap: "8px",
        position: "relative"
      }}
    >
      {/* {!alignment ? (
        <p>{displayLabel}</p>
      ) : (
        <p
          style={{
            width: "100%",
          }}
        >
          {displayLabel}
        </p>
      )} */}
       

      <div style={{ width: drpWidth }} >
        {async ? (
          <AsyncSelect
          onFocus={handleFocus}
            onBlur={handleBlur}
            placeholder={""}
            cacheOptions
            loadOptions={loadOptions}
            onChange={(e) => {
              updateMultiSelection(e);
            }}
            value={selectedValue}
            isDisabled={disabled==0?true:false}
            // isClearable
            isMulti={multi}
            styles={{
              control: (baseStyle, state) => ({
                ...baseStyle,
                ...dropDownStyles,
                menu: (provided) => ({ ...provided, zIndex: 9999 }),
                backgroundColor:bgcolor || "#EBEDF0"
              }),
              option: (baseStyle, { isFocused, isSelected }) => ({
                ...baseStyle,
                backgroundColor: isSelected
                  ? btncolor || "#EBEDF0"
                  : isFocused
                  ? bgcolor || "#EBEDF0"
                  : 'white',
                  color:isSelected
                  ? "black" : "black"
              }),
            }}
          />
        ) : (
          <Select
          onFocus={handleFocus}
            onBlur={handleBlur}
            onChange={(e) => {
              updateMultiSelection(e);
            }}
            isMulti={multi}
            value={selectedValue}
            defaultValue={defaultSelected}
            options={options}
            placeholder={""}
            className="basic-multi-select"
            classNamePrefix="select"
            //onChange={handleupdate}
            isDisabled={disabled==0?true:false}
            isClearable
            theme={(theme) => ({
              ...theme,
              borderRadius: "4px",
              backgroundColor: "ghostwhite",
              colors: { ...theme.colors, text: "orangered" },
            })}
            styles={{
              control: (baseStyle, state) => ({
                ...baseStyle,
                ...dropDownStyles,
                menu: (provided) => ({ ...provided, zIndex: 9999 }),
                backgroundColor:bgcolor || "#EBEDF0"
              }),
              option: (baseStyle, { isFocused, isSelected }) => ({
                ...baseStyle,
                backgroundColor: isSelected
                  ? btncolor || "#EBEDF0"
                  : isFocused
                  ? bgcolor || "#EBEDF0"
                  : 'white',
                  color:isSelected
                  ? "black" : "black"
              }),
            }}
          />
        )}
      </div>
      <label
              htmlFor={id}
              className={`label ${isFocused || selectedValue ? 'focused' : 'unfocused'}`}
              style={{
               
                whiteSpace: "nowrap",
                wordWrap:"unset",
                position: 'absolute',
                top: isFocused || selectedValue ? '-19px' : '50%',
                left: '8px',
                //backgroundColor: isFocused || selectedValue ? 'white' : "transparent",
                padding: '0 4px',
                fontSize: isFocused || selectedValue ? '12px' : '16px',
                color: isFocused ? 'black' : '',
                pointerEvents: 'none',
                transform:
                  isFocused || selectedValue
                    ? 'translateY(0)'
                    : 'translateY(-50%)',
                transition:
                  'top 0.3s, transform 0.3s, font-size 0.3s, color 0.3s'
              }}
            >
              {isrequired ? (
               <span className={`label ${isFocused || selectedValue ? 'focused' : 'unfocused'}`}>
                  {isFocused || selectedValue? displayLabel : placeholder}
                  <span style={{ color: 'red' }}>*</span>
                </span>
              ) : (
                <span className={`label ${isFocused || selectedValue ? 'focused' : 'unfocused'}`}>
                  {isFocused || selectedValue? displayLabel :placeholder}
                 
                </span>
              )}
            </label>
    </div>
  );
}

//CSS

export default CallbackDropdown;

const dropDownStyles = {
  border: "1px solid #cccccc",

  borderRadius: "8px",
};

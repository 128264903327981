import { Button, Collapse, Modal, Space, Table } from "antd";
import React, { useContext, useEffect, useRef, useState } from "react";
import { notification } from "antd";
import SaveDropDown from "../../CommonControllers/SaveComponents/SaveDropDown";
import SaveDropdown from "../../CommonControllers/Components/SaveComponents/SaveDropdown";
import SaveTextInput from "../../CommonControllers/Components/SaveComponents/SaveTextInput";
import SaveRadioBtn from "../../CommonControllers/Components/SaveComponents/SaveRadioBtn";
import ItemSearch from "../../CommonControllers/ItemController/ItemSearch";
import useFetchItem from "../../CommonControllers/ItemController/useFetchItems";
import { TabStore } from "../../TabsStructure/TabsStore";
import { AuthStore } from "../../Auth/AuthStore";
import { currentTabData } from "../../CommonControllers/Variable";
import {
  apiCallwithBaseUrl,
  getfastpathapi,
} from "../../CommonControllers/ApiCalls";
import { handleAdd, tableColumns } from "./ItemExchangeFunc";
import useSaveChangedRows from "../../CommonControllers/Hooks/useSaveChangedRows";
import CallbackRadioBtn from "../../CommonControllers/Components/CallbackComponents/CallbackRadioBtn";
import {
  convertToBoxAndPieces,
  enterPress,
  getToday,
} from "../../CommonControllers/AppFunction";
import useShortguid from "../../CommonControllers/Hooks/useShortGuid";
import { IoAperture } from "react-icons/io5";
import ScanTable from "../../CommonControllers/Components/ScanTable/ScanTable";
import ScannedItem from "../SalesInvoice/ScannenedItem";
import { List } from "antd";
import CallbackTextInput from "../../CommonControllers/Components/CallbackComponents/CallbackTextInput";
import SaveDatePicker from "../../CommonControllers/SaveComponents/SaveDatePicker";
// import SaveDatePicker from "../../CommonControllers/NewComponents/SaveComponents/NewSaveDatePicker";

function ItemExchange({ fastPath }) {
  const { Panel } = Collapse;

  const [customerData, setCustomerData] = useState();
  const [showTable, setShowTable] = useState(true);
  const [validator, setValidator] = useState(0);

  const [rowData, setRowData] = useState([]);
  const [modalcount, setModalCount] = useState(0);
  const [location, setlocation] = useState();
  const [docNumber, setDocNumber] = useState();
  const itemSearch = useRef(null);
  const [getSelectedItem, setSelectedItem] = useState(null);
  const fetchItems = useFetchItem();

  const [pageIndex, setPageIndex] = useState();
  const tabStore = useContext(TabStore);
  const auth = useContext(AuthStore);
  const [change, setChange] = useState(0);
  const [openItem, setOpenItem] = useState(false);
  const [scandata, setScandata] = useState();
  const [renderer, setRenderer] = useState(0);

  const useChangedRows = useSaveChangedRows();
  const [box, setBox] = useState(0);
  const [pcs, setPcs] = useState(0);

  ///// scannned items/////

  const [modal, setModal] = useState(false);
  const [scanner, setScanner] = useState([]);

  const [docType, setDocType] = useState("stockIn");

  const [openLinkModal, setOpenLinkModal] = useState(false);

  const shortguid = useShortguid();

  const [itemForLink, setItemForLink] = useState();
  const [linkDetails, setLinkDetails] = useState([]);

  const [transactiondate, settransactiondate] = useState();

  useEffect(() => {
    if (tabStore.state.tabs[tabStore.state.tabIndex].pageStatus == "Success") {
      setRowData([]);
      setCustomerData();
      setlocation();
      settransactiondate(getToday());
      tabStore?.dispatch({
        type: "UPDATE_DATA",
        fieldType: "pageStatus",
        text: "",
      });
    }
    setModalCount((p) => p + 1);
  }, [tabStore.state.tabs[tabStore.state.tabIndex].pageStatus]);

  useEffect(() => {
    if (itemForLink) {
      fetchLinkDetails(itemForLink);
    }
  }, [itemForLink]);

  async function fetchLinkDetails(itemForLink) {
    const bodyofAPI = {
      tenantrecno: auth?.state?.userdata?.tenantrecno,
      domainrecno: auth?.state?.userdata?.domainrecno,
      userrolerecno: auth?.state?.userdata?.userrolerecno,
      domainuserrecno: auth.state.userdata.recno,
      fastpath: currentTabData(tabStore)?.name,
      itemrecno: itemForLink?.itemrecno,
      customerrecno: customerData?.customerrecno,
    };
    var url =
      docType == "stockIn"
        ? "getitemissuelistforstockexchange"
        : "getitemreceiptlistforstockexchange";
    const res = await apiCallwithBaseUrl("POST", url, bodyofAPI);

    if (res.Success) {
      setLinkDetails(res.Message);
    }
  }

  useEffect(() => {
    var tabsArray = tabStore.state.tabs;
    for (let i = 0; i < tabsArray?.length; i++) {
      if (tabsArray[i].name == "TR013") {
        setPageIndex(i);
        break;
      }
    }
  }, []);

  const [pageDetails, setPageDetails] = useState();
  useEffect(() => {
    const payload = {
      tenantrecno: auth?.state?.userdata?.tenantrecno,
      domainrecno: auth?.state?.userdata?.domainrecno,
      userrolerecno: auth?.state?.userdata?.userrolerecno,
      domainuserrecno: auth.state.userdata.recno,
      fastpath: currentTabData(tabStore)?.name,
    };
    // Get Fast ath API
    getfastpathapi(payload).then((res) => {
      if (res?.Success) {
        setPageDetails(res?.Message);
        console.log("Resposnse of Fast Path API", res.Message);
      }
    });
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");
    const formattedDate = `${year}${month}${day}`;

    settransactiondate(formattedDate);
  }, []);

  // When-ever the Item is Selected,
  useEffect(() => {
    if (getSelectedItem?.isservice) {
      addItemBatchWiseToTable();
    }
  }, [getSelectedItem]);
  useEffect(() => {
    if (getSelectedItem) {
      var checkIfItemExists = rowData?.filter(
        (item) => item.itemrecno == getSelectedItem.itemrecno
      );
      console.log("Get Selected Items", getSelectedItem);

      if (checkIfItemExists?.length > 0) {
        alert("Item Already Exists");
      } else {
        addItemBatchWiseToTable();
      }
    }
  }, [getSelectedItem]);

  const addItemstoTable = () => {
    // if (!getSelectedItem.itembasbatch && !batchNo) {
    //   alert("Batch Cannot be Null");
    //   return;
    // }
    if (getSelectedItem) {
      var existingItem = rowData.filter(
        (item) => item.itemrecno == getSelectedItem.itemrecno
      );

      if (existingItem?.length == 0) {
        // clearing all input values
        setBox("");

        handleAdd(
          getSelectedItem,
          setRowData,
          false,
          setSelectedItem,
          shortguid,
          0,
          pcs,
          box,
          0,
          0,
          0,
          0,
          0,
          0,
          0
        );
        itemSearch?.current?.focus();
      } else {
        alert("Item Already Present");
        setSelectedItem(null);
        itemSearch?.current?.focus();
      }
    }
  };
  const addItemBatchWiseToTable = () => {
    // Get getSelectedItems
    console.log(
      "Get Selected Items in Sales Invoice",
      getSelectedItem,
      getSelectedItem?.itembasbatch
    );
    // if (getSelectedItem?.itembasbatch) {
    //   //Added s at the end remove later
    //   // Here we need to add the from the Quantity,
    // } else {
    // Here we select form the selected Items
    let batchDetails = getSelectedItem?.batchdetails;
    for (let i = 0; i < batchDetails?.length; i++) {
      let itemrecno = getSelectedItem.itemrecno;
      let itemdescn = getSelectedItem.itemdescn;
      let igstrate = getSelectedItem.igstrate;
      let cgstrate = getSelectedItem.cgstrate;
      let sgstrate = getSelectedItem.sgstrate;
      let mrp = getSelectedItem.mrp;
      let conversionfactor = getSelectedItem.conversionfactor;
      let totalSelectedQuantity = batchDetails[i]?.selectedQty
        ? batchDetails[i].selectedQty
        : 0;
      let totalFreeQuantity = batchDetails[i]?.selectedFreeQty
        ? batchDetails[i]?.selectedFreeQty
        : 0;

      let batchNo = batchDetails[i].itembatchno;
      let itemSerialNo = batchDetails[i].serialno;
      let salerate = batchDetails[i].salerate;
      let expdate = batchDetails[i].expdate;
      let manudate = batchDetails[i].manudate;

      if (totalSelectedQuantity > 0 || totalFreeQuantity > 0) {
        let convertedQty = convertToBoxAndPieces(
          totalSelectedQuantity,
          conversionfactor,
          getSelectedItem?.packingextrarecno
        );

        let convertedFreeQty = convertToBoxAndPieces(
          totalFreeQuantity,
          conversionfactor,
          getSelectedItem?.packingextrarecno
        );

        console.log(
          "COnverted Qty",
          convertedQty,
          getSelectedItem?.packingextrarecno,
          conversionfactor,
          totalSelectedQuantity,
          totalFreeQuantity
        );
        let selectedBoxQty = convertedQty[0];
        let selectedPcsQty = convertedQty[1];
        let selectedFreeBoxQty = convertedFreeQty[0];
        let selectedFreePcsQty = convertedFreeQty[1];

        handleAdd(
          getSelectedItem,
          setRowData,
          igstrate > 0 ? true : false,
          setSelectedItem,
          shortguid,
          salerate,
          selectedPcsQty,
          selectedBoxQty,
          selectedFreeBoxQty,
          selectedFreePcsQty,
          batchNo,
          expdate,
          0,
          0,
          manudate
        );
      }
      // }
    }
  };

  useEffect(() => {
    // When Row Data Changes we Need to store that data to the tabsStore with Header Data
    var existingObject = useChangedRows.getValue();

    // Calculate Other Numbers and Inputs and store in the tabStore
    //var status = "P";
    var trdate = "workdate";
    var approvalStatus = "P";
    var remark = "Need to add a field for that";
    var narration = "Need to ad a text field for this as well";
    var amount; // Calculate the total amount after tax
    var taxableamount; // Calculate the total taxable amount after tax
    var cgstamt; //Calculate the CGSTAMT
    var sgstamt; //Calculate teh SGST AMT
    var igstamt; //Calculat the IGSTAMT
    var shortguid;

    amount = rowData.reduce((total, item) => total + Number(item.amount), 0);
    console.log("Amount is  : ", existingObject);
    taxableamount = rowData.reduce(
      (total, item) => total + Number(item.taxableamount),
      0
    );
    cgstamt = rowData.reduce((total, item) => total + Number(item?.cgstamt), 0);
    sgstamt = rowData.reduce((total, item) => total + Number(item?.sgstamt), 0);
    igstamt = rowData.reduce((total, item) => total + Number(item?.igstamt), 0);
    // shortguid = existingObject.shortguid ? (existingObject.shortguid) : (shortguid.getShortguid("SI"));
    // console.log("new/old shortguid is", shortguid)

    existingObject = {
      ...existingObject,
      cgstamt: Number(cgstamt).toFixed(2),
      igstamt: Number(igstamt).toFixed(2),
      sgstamt: Number(sgstamt).toFixed(2),
      taxableamount: Number(taxableamount).toFixed(2),
      amount: Number(amount).toFixed(2),
      shortguid: new Date(), /// shortguid was going null after second time saving

      items: rowData,
      trdate: transactiondate ? transactiondate : auth.state.userdata.workdate,
      // trdate: getToday(),
      status: "C",
      forflag: docType,
      refrecno: 0,
      // roundamt: Number(roundDiff).toFixed(2),
    };
    const invoiceAmount = existingObject?.amount;
    // const roundOff = roundOffAmount(invoiceAmount).toFixed(2);
    // const roundOffDiff = roundOff - invoiceAmount;

    // existingObject.roundamt = Number(roundOffDiff).toFixed(2);
    useChangedRows.updateValue(false, existingObject);
    console.log("Update", existingObject);
  }, [rowData]);

  const validateLinkItems = () => {
    var validatedItems = linkDetails?.reduce((acc, ini) => {
      return Number(acc) + Number(ini?.selectedQty);
    }, 0);
    console.log("ValidatedItems", validatedItems);
    if (validatedItems > itemForLink?.qty) {
      alert("Total Quantity and Selected Quantity Doesnot match");
    } else {
      var linkedArray = linkDetails?.filter((item) => item?.selectedQty > 0);
      var newRowData = rowData?.map((item) => {
        if (item.itemrecno == itemForLink.itemrecno) {
          item.linkedList = linkedArray;
        }
        return item;
      });
      setRowData(newRowData);
      setOpenLinkModal(false);
    }
  };
  const handleQuantityChangeInLink = (ind, e) => {
    linkDetails[ind].selectedQty = e.target.value;
    console.log("Linked Qty", linkDetails[ind]);
    setLinkDetails((p) => [...linkDetails]);
  };

  useEffect(() => {
    console.log("Packing======", rowData);
    let isButtonDisabled = false;

    for (let i = 0; i < rowData.length; i++) {
      const {
        packingqty,
        packingextraqty,
        freepackingqty,
        freepackingextraqty,
        manufacturedate,
        expdate,
        itembatchno,
        purrate,
        expdatecompulsory,
        itemasbatch,
        manudatecompulsory,
        wantbatchno,
        changesalerateinbill,
        isservice,
        qty,
        freeqty,
      } = rowData[i];
      console.log("Packing======", rowData[i]);
      if (isservice === 1 && qty + freeqty == 0) {
        isButtonDisabled = true;

        break;
      }
      if (isservice === 1 && qty + freeqty > 0) {
        isButtonDisabled = false;
        console.log("Chhhhhhh==123", rowData[i], packingqty, packingextraqty);
        break;
      }

      if (
        (isservice === 0 &&
          wantbatchno &&
          (!itembatchno ||
            itembatchno == "" ||
            itembatchno == null ||
            itembatchno == undefined)) ||
        (expdatecompulsory &&
          (!expdate || expdate == "undefined" || Number(expdate) == 0)) ||
        (manudatecompulsory &&
          (!manufacturedate ||
            manufacturedate == "undefined" ||
            Number(manufacturedate) == 0)) ||
        (packingqty === "0" && packingextraqty === "0") ||
        (packingqty === "" && packingextraqty == "") ||
        (packingqty === 0 && packingextraqty === "0") ||
        (packingqty === "0" && packingextraqty === 0) ||
        (packingqty === 0 && packingextraqty === 0) ||
        (rowData[i]?.doctype !== "DC" &&
          rowData[i]?.itemasbatch &&
          Number(rowData[i]?.qty) +
            Number(rowData[i]?.free ? rowData[i]?.free : 0) !==
            rowData[i]?.itembatcharray?.length)
      ) {
        isButtonDisabled = true;
        console.log("Chhhhhhh==1", rowData[i]);
        break;
      } else if (
        (isservice == 0 &&
          wantbatchno &&
          (!itembatchno ||
            itembatchno == "" ||
            itembatchno == null ||
            itembatchno == undefined)) ||
        (packingqty === "0" && packingextraqty === "0") ||
        (packingqty == "" && packingextraqty == "") ||
        (packingqty === 0 && packingextraqty === "0") ||
        (packingqty === "0" && packingextraqty === 0) ||
        (packingqty === 0 && packingextraqty === 0)
      ) {
        isButtonDisabled = true;
        console.log("Chhhhhhh==2");
        break;
      } else if (
        (isservice == 0 &&
          wantbatchno &&
          (!itembatchno ||
            itembatchno === "" ||
            itembatchno === null ||
            itembatchno === undefined)) ||
        (packingqty === "0" && packingextraqty === "0") ||
        (packingqty === "" && packingextraqty === "") ||
        (packingqty === 0 && packingextraqty === "0") ||
        (packingqty === "0" && packingextraqty === 0) ||
        (packingqty === 0 && packingextraqty === 0)
      ) {
        isButtonDisabled = true;
        console.log("Chhhhhhh==3");
        break;
      }

      if (
        rowData[i]?.doctype === "DC" &&
        rowData[i]?.qty + rowData[i]?.freeqty !== rowData[i]?.dcqty
      ) {
        isButtonDisabled = true;
        console.log("Chhhhhhh==4");
      }
    }
    console.log("Chhhhhhh==5");
    useChangedRows.updateValue(
      "validated",
      !isButtonDisabled ? "true" : "false"
    );
  }, [rowData]);

  return (
    <div>
      <Modal
        width={500}
        title="Link Items"
        open={openLinkModal}
        onOk={(e) => {
          // Validate the Amount
          validateLinkItems();
          // console.log("Validated Qty ", e)
        }}
        onCancel={() => {
          setOpenLinkModal(false);
        }}
        // okButtonProps={{ style: { display: "none" } }}
        // cancelButtonProps={{ style: { display: "none", zIndex: 9999 } }}
      >
        <div>
          <List
            size="small"
            itemLayout="horizontal"
            bordered
            dataSource={linkDetails}
            renderItem={(it, ind) => {
              return (
                <List.Item key={ind}>
                  <p>{it?.descn}</p>
                  <p>{it?.remainingqty}</p>
                  <input
                    className="searchBox"
                    type={"number"}
                    value={it?.selectedQty}
                    onChange={(e) => {
                      console.log("Return Valueis", e);
                      if (e.target.value <= it?.remainingqty) {
                        it.selectedQty = e.target.value;
                        handleQuantityChangeInLink(ind, e);
                      } else {
                        alert("Invalid Quantity");
                        it.selectedQty = 0;
                        e.target.value = 0;
                      }
                    }}
                  />
                </List.Item>
              );
            }}
          ></List>
        </div>
      </Modal>

      <Collapse collapsible="header" defaultActiveKey={["1"]}>
        <Panel header="Document Details" key="1">
          <form
            style={{
              display: "flex",
              gridTemplateColumns: "repeat(6, 1fr)",
              columnGap: "24px",
            }}
          >
            <div
              onKeyDown={enterPress}
              style={{
                zIndex: 11,
                position: "relative",
                pointerEvents: rowData?.length > 0 && "none",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <SaveDropdown
                defaultSelected={customerData?.customerrecno}
                disabled={rowData && rowData.length > 0?1:0}
                displayLabel="Cust"
                alignment="column"
                apikey="customerrecno"
                isrequired
                placeholder={"Select Cust"}
                apiurl="getdomaincustomerlist"
                selectionLabel="customerdescn"
                selectionValue="customerrecno"
                returnValue={(event) => {
                  console.log("CUSTOMER RECNO", event);
                  {
                    /* // nirmal-389 adding values with bold*/
                  }
                  if (event?.hasOwnProperty("zincode")) {
                    // task rajendra id-240
                    if (event?.zincode) {
                      // task rajendra id-240
                      setCustomerData(event);
                    } else {
                      alert(
                        "Customer has no Zincode. Please Add Zincode to start billing"
                      );
                      // setModalCount(() => modalcount + 1)
                      if (customerData == 0) {
                        setCustomerData(null);
                      } else {
                        setCustomerData(0);
                      }
                    }
                  } else {
                    setCustomerData(event);
                  }
                }}
                key={modalcount}
              />
            </div>
            <div
              style={{
                zIndex: 11,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              onKeyDown={enterPress}
            >
              <SaveDropdown
                defaultSelected={location}
                displayLabel="Cust Locn"
                alignment="column"
                apiurl="domaincustomerlocationlist"
                apikey="customerlocationrecno"
                selectionLabel="descn"
                placeholder={"Select Locn"}
                selectionValue="customerlocationrecno"
                returnValue={() => setlocation()}
                body={{ customerrecno: customerData?.customerrecno }}
                key={modalcount}
              />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              onKeyDown={enterPress}
            >
              <SaveDatePicker
                id={transactiondate}
                disabled={rowData && rowData.length > 0?1:0}
                alignment="column"
                defaultValue={transactiondate}
                displayLabel={"TR Date"}
                placeholder={"TR Date"}
                apiKey={"trdate"}
                change
                editable
                returnValue={(e) => {
                  settransactiondate(e);
                }}
                key={modalcount}
              />
            </div>
            <div
              onKeyDown={enterPress}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                pointerEvents: rowData?.length > 0 && "none",
              }}
            >
              <SaveRadioBtn
                options={[
                  { label: "Stock In", value: "stockIn" },
                  { label: "Stock Out", value: "stockOut" },
                ]}
                returnValue={(e) => {
                  setDocType(e);
                }}
                defaultValue={docType}
                apikey={"forflag"}
              />
            </div>
            <div onKeyDown={enterPress}>
              <div style={{ position: "relative" }}>
                <label
                  // htmlFor={id}
                  className={`label ${"focused"}`}
                  style={{
                    zIndex: 1000,
                    whiteSpace: "nowrap",
                    wordWrap: "unset",
                    position: "absolute",
                    top: "-19px",
                    left: "12px",
                    //backgroundColor: 'white',
                    padding: "0 4px",
                    fontSize: "12px",
                    color: "black",
                    pointerEvents: "none",
                    transform: "translateY(0)",
                    transition:
                      "top 0.3s, transform 0.3s, font-size 0.3s, color 0.3s",
                  }}
                >
                  Document no
                </label>
                <input
                  style={{ ...poNumberStyle }}
                  placeholder={docNumber ? docNumber : "TBA"}
                  disabled
                  key={modalcount}
                />
              </div>
            </div>
          </form>
        </Panel>

        <Panel header="Item Details" key="2">
          <Space size="large">
            <div style={{ alignItems: "center" }}>
              <form style={{ ...callbackTextInputStyles, alignItems: "end" }}>
                <div
                  style={{
                    zIndex: 2,
                    position: "relative",
                  }}
                >
                  <ItemSearch
                    itemSearchRef={itemSearch}
                    forFlag={docType == "stockIn" ? "SR" : "S"} // for further referance ternatory is added
                    returnValue={setSelectedItem}
                    supplierdata={customerData?.customerrecno}
                    dropdown
                    defaultSelectedItem={getSelectedItem}
                    selection={
                      docType == "stockOut" && !pageDetails?.wantbatchno
                        ? true
                        : false
                    }
                    barcodeReturnValue={(e) => {
                      const bill = e?.itembatcharrayfound[0];
                      const inwardDate = bill?.inwarddate;
                      const dcdate = bill?.dcbilldate;
                      const sidate = bill?.billdate;
                      // Assuming trdate is available in the scope

                      // Check the inward date condition only for stock out

                      console.log("DTCYFDYF", inwardDate, transactiondate);
                      if (
                        docType == "stockOut" &&
                        inwardDate &&
                        inwardDate > Number(transactiondate)
                      ) {
                        notification.error({
                          message: "Error",
                          duration: 1,
                          description:
                            " transaction date is less than the Inward date ",
                          style: { background: "#efa7a7" },
                        });
                        return;
                      }
                      console.log("DTCYFDYF", inwardDate, transactiondate);
                      if (
                        docType == "stockIn" &&
                        (dcdate > Number(transactiondate)||inwardDate > Number(transactiondate))
                      ) {
                        notification.error({
                          message: "Error",
                          duration: 1,
                          description:
                            " transaction date is less than the Inward date ",
                          style: { background: "#efa7a7" },
                        });
                        return;
                      }
                      if (
                        docType == "stockIn" &&
                        e.itembatcharrayfound[0]?.billcustomerrecno!==customerData?.customerrecno
                      ) {
                        notification.error({
                          message: "Error",
                          duration: 1,
                          description:
                            "Scanned Item does not belong to selected Customer.",
                          style: { background: "#efa7a7" },
                        });
                        return;
                      }
                      if (customerData?.customerrecno) {
                        if (e?.itemasbatch == 1) {
                          fetchItems
                            .addBarcodeScannedItemToTable(
                              rowData,
                              e,
                              "SIF",
                              false,
                              pageDetails,
                              docType == "stockIn" ? "SR" : "S",
                              customerData?.customerrecno,
                              transactiondate // Include transaction date in the payload
                            )
                            .then((res) => {
                              if (
                                customerData?.customerrecno &&
                                res &&
                                res.length > 0 &&
                                res[0]?.itembatcharrayfound &&
                                res[0]?.itembatcharrayfound.length > 0
                              ) {
                                if (
                                  (res[0]?.itembatcharrayfound[0]
                                    ?.billcustomerrecno &&
                                    customerData?.customerrecno ===
                                      res[0].itembatcharrayfound[0]
                                        .billcustomerrecno &&
                                    docType == "stockIn") ||
                                  res[0].itembatcharrayfound[0]?.dcbilldate ||
                                  res[0].itembatcharrayfound[0]?.sibilldate
                                ) {
                                  setRowData(res.length === 0 ? rowData : res);
                                  setChange(change + 1);
                                } else if (docType !== "stockIn") {
                                  setRowData(res.length === 0 ? rowData : res);
                                  setChange(change + 1);
                                } else if (
                                  (res[0]?.itembatcharrayfound[0]
                                    ?.billcustomerrecno &&
                                    customerData?.customerrecno !==
                                      res[0].itembatcharrayfound[0]
                                        .billcustomerrecno) ||
                                  (res[0].itembatcharrayfound[0]?.dcbilldate ==
                                    null &&
                                    res[0].itembatcharrayfound[0]?.sibilldate ==
                                      null)
                                ) {
                                  notification.error({
                                    message: "Notification",
                                    description:
                                      "Scanned Item does not belong to selected Customer.",
                                    duration: 3,
                                    style: { background: "#efa7a7" },
                                  });
                                }
                              }
                            })
                            .catch((err) => alert(err));
                        } else {
                          setSelectedItem(e);
                        }
                      }else{
                        alert("Customer Not selected")
                      }
                    }}
                  />
                </div>
                {getSelectedItem?.packingextrarecno > 0 &&
                pageDetails?.wantpackingextra ? (
                  <div onKeyDown={enterPress}>
                    <p>Box</p>
                    <input
                      value={box}
                      onChange={(e) => setBox(e.target.value)}
                      style={{ ...inputStyles }}
                      id="tr001-input-box"
                      type="number"
                    />
                  </div>
                ) : null}

                <div onKeyDown={enterPress}>
                  <p>
                    Pcs<span style={{ color: "red" }}>*</span>
                  </p>
                  <input
                    value={pcs}
                    onChange={(e) => setPcs(e.target.value)}
                    style={{ ...inputStyles }}
                    id="tr001-input-box"
                    type="number"
                  />
                </div>

                <div onKeyDown={enterPress}>
                  <Button
                    // style={{ ...btnStyles }}
                    className="btn item-search-btn"
                    icon={<IoAperture size="1.2rem" />}
                    size="large"
                    onClick={() => {
                      // console.log("RRRRRRRRRRRRRR",batchNo,purchaseRate,mfgDate,expDate)
                      // if (batchNo  && mfgDate && expDate) {
                      //   addItemstoTable();
                      // } else {
                      //   alert("Please Enter Required Fields")
                      // }                }}
                      if (!getSelectedItem.itemasbatch) {
                        // if (purchaseRate && mfgDate && expDate) {
                        addItemstoTable();
                        setPcs(0);
                        // } else {
                        //   alert("Please Enter Required Fields");
                        // }
                      } else if (getSelectedItem.itemasbatch) {
                        // if (batchNo && purchaseRate && mfgDate && expDate) {
                        addItemstoTable();
                        setPcs(0);
                        // } else {

                        //   alert("Please Enter Required Fields");
                        // }
                      }

                      //setSupplierInvNo("");
                    }}
                  >
                    Add
                  </Button>
                  {/* <SaveCheckbox apikey={"isPrint"} displayLabel="Print PO" editable /> */}
                </div>
              </form>
            </div>
          </Space>
          <div>
            {showTable && rowData.length > 0 ? (
              <Table
                className="tr013-items-grid"
                scroll={{
                  y: 500,
                  x: 500,
                }}
                size="large"
                bordered
                pagination={false}
                columns={tableColumns(
                  false,
                  setRowData,
                  rowData,
                  scandata,
                  setScandata,
                  setOpenItem,
                  auth.state.userdata.workdate,
                  setModal,
                  scanner,
                  auth?.state?.userdata?.onedconeinvoice,
                  setScanner,
                  pageDetails,
                  setOpenLinkModal,
                  setItemForLink
                )?.filter((item) => !item.hidden)}
                dataSource={rowData}
                onChange={(e) => {}}
              />
            ) : null}
            {openItem && (
              <ScanTable
                data={scandata}
                openItem={openItem}
                setOpenItem={setOpenItem}
                rowData={rowData}
                setRowData={setRowData}
                renderer={renderer}
                setRenderer={setRenderer}
                setValidator={setValidator}
                forFlag={docType == "stockIn" ? "SR" : "S"}
                supplierrecno={customerData?.customerrecno}
                pageDetails={pageDetails}
                pageIndex={pageIndex}
              />
            )}
            {modal && (
              <ScannedItem
                modal={modal}
                setModal={setModal}
                data={scanner}
                pageDetails={pageDetails}
              />
            )}
          </div>
        </Panel>
      </Collapse>
    </div>
  );
}
const poNumberStyle = {
  marginTop: "4px",
  border: "1px solid gray",
  borderRadius: "8px",
  width: "200px",
  padding: "8px",
  fontSize: "16px",
};
const btnStyles = {
  display: "flex",
  alignItems: "center",
  gap: "8px",
  marginTop: "26px",
};
const inputStyles = {
  padding: "10px",
  border: "1px solid rgb(120,120,120)",
  width: "100px",
  fontSize: "16px",
  marginTop: "7px",
  borderRadius: "6px",
};

const callbackTextInputStyles = {
  display: "flex",
  flexWrap: "wrap",
  alignItems: "center",
  // amol-390 - change in CSS gap 10px to 24px
  gap: "24px",
};

export default ItemExchange;


// CHanged by Kaushik while merging change the URL
//export const baseUrl = process.env.REACT_APP_BACKEND_LINK
 //export const baseUrl = "https://scientific.sutradhar.tech/backend/api/v1/";
export const baseUrl = process.env.REACT_APP_BACKEND_LINK
// export const baseUrl = "http://192.168.1.27:8050/api/v1/"
// export const baseUrl = "https://bcore.sutradhar.tech/dev/backend/api/v1/";

//  export const baseUrl = "https://scientific.sutradhar.tech/backend/api/v1/";
// export const baseUrl = "http://bizzcontrol.in/prod/api/v1/";
//export const baseUrl = "http://192.168.1.78:8060/api/v1/"

export const getMenuUrl = baseUrl + "getmenu/";
export const filtercommonmaster = baseUrl + "filtercommonmaster/";
export const commonmaster = baseUrl + "commonmaster/";

//master url rajendra
export const getMasterUrl = baseUrl + "filtercommonmaster/";

export const loginURL = `${baseUrl}loginadmin/`;
//// forgot passwords
export const forgotURL = `${baseUrl}forgotpassword/`; // forget password
export const confirmURL = `${baseUrl}verifyotp/`; /// verfy after otp has been sent
export const changesPasswordsUrl = `${baseUrl}changepwd/`;

// Purchase Order URLs
export const getPurchaseOrderURL = `${baseUrl}filterpurchaseorder/`;
export const getPartyNameURL = `${baseUrl}getdomainsupplierlist/`;
export const getPurchaseInfoForItemURL = `${baseUrl}getpurchaseinfoforitem/`;
export const getfastpathurl = baseUrl + "getfastpath/";

// Common master
export const getCustomerTypeListURL = `${baseUrl}getcustomertypelist/`;
export const getcustomerlistURL = `${baseUrl}getcustomerlist/`;
export const getcustomerlistwithoutdomain = `${baseUrl}getcustomerlistwithoutdomain/`;
export const getAreaListURL = `${baseUrl}getarealist/`;
export const addCustomersURL = `${baseUrl}addcustomers/`;
export const getpostatuslistactive = `${baseUrl}getpurchasereturnstatuslistactive/`
// domian  supplier
export const getDomainSupplier = `${baseUrl}getdomainsupplierlist/`;
export const getDomainSupplierAll = `${baseUrl}domainsupplierlist/`;

export const getLanguageListURL = `${baseUrl}getlanguagelist/`;

export const getDomainSalesman = `${baseUrl}getdomainsalesmanlist/`;
export const getCustomer = `${baseUrl}getcustomerlist/`;
export const getDomain = `${baseUrl}getdomainlist/`;
export const getDomainType = `${baseUrl}getdomaintypelist/`;
export const addDomainsURL = `${baseUrl}adddomains/`;

export const getCustomerType = `${baseUrl}getcustomertypelist/`;
export const getCustomerCategory = `${baseUrl}getcustomercategorylist/`;
export const getCustomerClass = `${baseUrl}getcustomerclasslist/`;
export const getStateMaster = `${baseUrl}getstatemasterlist/`;
export const getDistrict = `${baseUrl}getdistrictlist/`;
export const getTaluka = `${baseUrl}gettalukalist/`;
export const getCity = `${baseUrl}getcitylist/`;
export const getRegion = `${baseUrl}getregionlist/`;
export const getBank = `${baseUrl}getbankbranchmasterlist/`;
export const getArea = `${baseUrl}getarealist/`;

export const getPackType = `${baseUrl}getpacktypelist/`;
export const getUOM = `${baseUrl}getuomlist/`;
export const getHSN = `${baseUrl}gethsnlist/`;
export const getItemDept = `${baseUrl}getdeptlist/`;
export const getItemSection = `${baseUrl}getsectionlist/`;
export const getItemCategory = `${baseUrl}getitemcategorylist/`;
export const getItemSubCategory = `${baseUrl}getsubcategorylist/`;
export const getAccountsCategory = `${baseUrl}getaccountingcategorylist/`;
export const getBillingCategory = `${baseUrl}getbillingcategorylist/`;
export const getItemGroup1 = `${baseUrl}getitemgroup1list/`;
export const getItemGroup2 = `${baseUrl}getitemgroup2list/`;
export const getItemGroup3 = `${baseUrl}getitemgroup3list/`;
export const getItemGroup4 = `${baseUrl}getitemgroup4list/`;
export const getItemBrand = `${baseUrl}getitembrandlist/`;
export const getRateCard = `${baseUrl}getratecardlist/`;
export const getLocationType = `${baseUrl}getlocationtypelist/`; // smk 10-1-2023

export const getPert = `${baseUrl}getpertlist/`;

export const getSurveyType = `${baseUrl}getsurveytypelist/`;
export const getSurveyorListURL = `${baseUrl}getsurveyerlist/`;
export const getSurveyQuestionType = `${baseUrl}getsurveyquestionstypelist/`;
export const getSurveyQuestionsListUrl = `${baseUrl}surveyquestionlist/`;
export const getForAnswer = `${baseUrl}getanswerdropdownforsurveyquestion/`;
export const getQuestionBySurveyType = `${baseUrl}questionbysurveytype/`
export const getCustomerinSurvey = `${baseUrl}getsurveyanswerlist/`;
export const bulkupdatesurveyquestion = `${baseUrl}addsurveyquestion/`;
export const getSurveyQuestionListUrl = `${baseUrl}surveyquestionlist/`;
//export const bulkupdatesurveyquestion = `${baseUrl}bulkupdatesurveyquestion/`;

export const getFormulation = `${baseUrl}getformulationlist/`;
export const getThep = `${baseUrl}gettheurapeuticmasterlist/`;
export const getMktDiv = `${baseUrl}getMarketeerdivisionforpharmalist/`;
export const getScheduleForPharma = `${baseUrl}getschedulemasterforpharmalist/`;
export const getComposition = `${baseUrl}getcompositionlist/`;
export const getCompositionGroup = `${baseUrl}getcompositiongrouplist/`;

export const getReasonStockVerification = `${baseUrl}getreasonmasterforstockverificationlist/`;
export const getReasonGRN = `${baseUrl}getreasonmasterforgrnlist/`;
export const getReasonPurchaseReturn = `${baseUrl}getreasonmasterforpurchasereturnlist/`;
export const getReasonSalesReturn = `${baseUrl}gerreasonmasterforsalereturnlist/`;
export const getStorageCondition = `${baseUrl}getstorageconditionlist/`;

export const getCertificateMaster = `${baseUrl}getcertificatemasterlist/`;
export const getCertificateType = `${baseUrl}getcertificatelist/`;

export const getCrateType = `${baseUrl}getcratetypelist/`;
export const getVehicleType = `${baseUrl}getvehicletypelist/`;
export const getVehicleMode = `${baseUrl}getvehiclemodellist/`;

// save executes rajendra

export const saveData = `${baseUrl}adddomainsuppliers/`;

// user permissions  need to get the api yet rajendra

export const userPermissions = `${baseUrl}getuserrolelist/`;

// accounts related API
export const getGllistURL = `${baseUrl}getgllist/`;
export const getScrollURL = `${baseUrl}getscroll/`;
export const getAccountlistURL = `${baseUrl}getaccountlist/`;

export const addTransactionsURL = `${baseUrl}addtransactions/`;
//Rajendra  Domain Para page get api

export const getDomainPara = `${baseUrl}getdomainparamslist/`;

//rajendra  domail location get list api

export const getDomainList = `${baseUrl}getdomainlist/`;
 export const getManu=`${baseUrl}getmfglist/`
 export const getmanulist=`${baseUrl}getmfglicenselist/`

//rajendra domain loaction get list api
export const getDomainlocationtable = `${baseUrl}getdomainlocationlist/`;
export const domainLocationURL = `${baseUrl}getdomainlocationlist/`;

//rajendra Glliast get list api

export const getGlliast = `${baseUrl}getgllist/`;

//rajendra account code  api for drop down
export const getAccountCode = `${baseUrl}getaccountlist/`;

//rajendra purchase glcode api for drop down

export const getPurchaseGlcode = `${baseUrl}purchaseglcode/`;

export const getTenantItemListURL = `${baseUrl}gettenantitemlist/`;

export const getGenderList = `${baseUrl}getgenderlist/`;

// rajendra  adddomainitemaccountcategoryinfo api
export const getItemDomain = `${baseUrl}getdomainitemaccountcategoryinfo/`;

// rajendra  adddomainitemaccountcategoryinfo api

//rajendra getlocation type list api
export const getLocationTypeList = `${baseUrl}getlocationtypelist/`;

// rajendra get licencense
export const getLicencenseList = `${baseUrl}getdomainlicenselist/`;

export const getCustomeListForSurveyURL = `${baseUrl}getcustomerlistforsurvey/`;

export const filterTenantItemListURL = `${baseUrl}getfiltertenantitemlist/`;

// PDC List
export const getPDCList = `${baseUrl}getpdclist/`

export const getDomainItemListURL = `${baseUrl}getdomainitemlist/`;
export const DomainItemListURL = `${baseUrl}domainitemlist/`;

export const getDomainItemColumnsURL = `${baseUrl}getdomainitemverificationlist/`;
//rajendra surveyview 
export const getsurveyanswerfooterlist=`${baseUrl}getsurveyanswerfooterlist/`
export const getprintformatfooterlist=`${baseUrl}getprintformatfooterlist/`
export const addprintformatall=`${baseUrl}addprintformatall/`
export const removeprintformatfooter=`${baseUrl}removeprintformatfooter/`
export const copyprintfromats=`${baseUrl}copyprintfromats/`

// rajendra ohk button api call for domain license  getprintformatfooterlist

export const getAdddomailicense=`${baseUrl}adddomainlicenses/`

//rajendra ohk button sv003
export const getbulkaddsurveyanswerfooter=`${baseUrl}bulkaddanswerfooter/`
export const addprintformatfooters=`${baseUrl}addprintformatfooters/`

// rajendra ohk button ms298
export const addmfglicenses=`${baseUrl}addmfglicenses/`

// export const userlocationlist=`${baseUrl}getdomainlocationlist/`
export const userlocationlist=`${baseUrl}getdomainlocationlistactive/`

///getdomainlocationlistactive url is added 2nd dec
export const getdomainlocationlistactive=`${baseUrl}getdomainlocationlistactive/`
// smk 20-2-2023
export const getdomaincustomeritemlist = `${baseUrl}getdomaincustomeritemlist/`
export const getdomainsupplieritemlist = `${baseUrl}getdomainsupplieritemlist/`
export const getdomainsalesmancustomeritemlist = `${baseUrl}getdomainsalesmancustomeritemlist/`


export const getDomaincustomerlist= `${baseUrl}getdomaincustomerlist/`;
export const domaincustomerlocationlist = `${baseUrl}domaincustomerlocationlist/`;


//rajendra tr015
export const getFilterSalebill=`${baseUrl}filtersalebill/`

export const getFilterSalebillOPening=`${baseUrl}filteropeningsalebill/`

//

export const getDomainLocationUrl=`${baseUrl}getdomainlocationlistactive/`
export const getpurchasebillURL = `${baseUrl}filterpurchasebill/`

export const getItemFromScannedBarcodeURL = `${baseUrl}getitemfromscannedbarcode/`

export const getDomainCustomerSalesman = `${baseUrl}getdomaincustomersalesman/`;
export const getCustomerListWithoutDomain = `${baseUrl}getcustomerlistwithoutdomain/`;
export const getDomainTransporterList = `${baseUrl}getdomaintransporterlist/`;

export const getDomainCustomerConsigneeList = `${baseUrl}getdomaincustomerconsigneelist/`;


export const gethelpdoc = `${baseUrl}gethelpdocbyfastpath/`;


export const getfastpathlist = `${baseUrl}getfastpathlist/`;
/// PR//

export const filterpurchasereturnlog=`${baseUrl}filterpurchasereturnlog/`

export const filterpurchasebilllogs=`${baseUrl}filterpurchasebilllogs/`
export const getGRNDetailsUrl = `${baseUrl}filtergrn/`
export const getFilterPurchasebillOPening=`${baseUrl}filteropeningpurchasebill/`
// SR //

export const filtersalebilllogs = `${baseUrl}filtersalebilllog/`
// WMS
export const getWMSGRNDetailsUrl = `${baseUrl}addgrn/`
export const getWMSGDNDetailsUrl = `${baseUrl}adddeliverychalan/`
export const getLocationItemList = `${baseUrl}getdomainitemlocationlistactive/`
export const deletetransactions = `${baseUrl}deletetransactions/`


///images preview 
export const getitemcategorylistactive = `${baseUrl}getitemcategorylistactive/`
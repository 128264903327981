import React, { useState, useRef, useEffect, useContext } from "react";

import { ExcelRenderer, OutTable } from "react-excel-renderer";
import * as XLSX from "xlsx";
import "./ItemRecieptwithDC.css";
import CommonMaster from "../CommonMaster";
import TableComponent from "../../CommonControllers/Components/TableComponent/TableComponent";
import { Table } from "antd";
import { TabStore } from "../../TabsStructure/TabsStore";
import {
  dateFormatter,
  datedisplay,
} from "../../CommonControllers/AppFunction";
import { AuthStore } from "../../Auth/AuthStore";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
function PurchaseInvoiceETL() {
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  const [message, setMessage] = useState("");
  const tabStore = useContext(TabStore);
  const auth = useContext(AuthStore);

  const [render, setRender] = useState(0);

  const [pageIndex, setPageIndex] = useState();
  //// here to chek the length of rowData

  useEffect(() => {
    var tabsArray = tabStore.state.tabs;
    for (let i = 0; i < tabsArray?.length; i++) {
      if (tabsArray[i].name == "TR058") {
        setPageIndex(i);
        break;
      }
    }
  }, []);

  useEffect(() => {
    if (rows) {
      tabStore.dispatch({
        type: "UPDATE_TABS_ARRAY",
        changedRowsValue: rows,
        tabsFastPath: "TR058",
        keyName: "changedRows",
      });
    }
  }, [rows]);
  useEffect(() => {
    console.log("TABSTORE", tabStore)
    if (tabStore.state.tabs[pageIndex]?.pageStatus == "Success") {
      setRows([]);
      setColumns([]);
      setTimeout(
        tabStore.dispatch({
          type: "UPDATE_DATA",
          fieldType: "pageStatus",
          text: null,
        }),
        1000
      );
    }

  }, [tabStore.state.tabs[pageIndex]?.pageStatus]);
  useEffect(() => {
    if (tabStore?.state?.tabs[pageIndex]?.saveLoading === false) {
      setRows([]);
      setColumns([]);
      tabStore.dispatch({
        type: "UPDATE_TABS_ARRAY",
        changedRowsValue: [],
        tabsFastPath: "TR058",
        keyName: "changedRows",
      });
    }
  }, [tabStore?.state?.tabs[pageIndex]?.saveLoading]);

  const convertExcelDateToYYYYMMDD = (excelDate) => {

    const serialDate = new Date(1900, 0, excelDate - 1); // 1900 because Excel incorrectly considers 1900 as a leap year
    const year = serialDate.getFullYear().toString();
    const month = (serialDate.getMonth() + 1).toString().padStart(2, "0");
    const day = serialDate.getDate().toString().padStart(2, "0");

    if (year?.toString().length > 4) {
      return null;
    }
    return `${year}${month}${day}`;
  };

  const convertExcelDateToFormat = (excelDate, outputFormat) => {
    console.log("Input date:", excelDate);

    if (!excelDate) {
      return null;
    }

    // Check if the input is in the numeric Excel date format
    if (typeof excelDate === "number" && !/^\d{8}$/.test(excelDate.toString())) {
      const date = new Date(Math.floor((excelDate - 25569) * 86400 * 1000));
      const utcDate = new Date(date.getTime() + date.getTimezoneOffset() * 60000);
      const year = utcDate.getUTCFullYear();
      const month = (utcDate.getUTCMonth() + 1).toString().padStart(2, "0");
      const day = utcDate.getUTCDate().toString().padStart(2, "0");

      console.log("Converted date:", utcDate, "Year:", year, "Month:", month, "Day:", day);

      if (outputFormat === "YYYYMMDD") {
        return `${year}${month}${day}`;
      } else if (outputFormat === "DD/MM/YYYY") {
        return `${day}/${month}/${year}`;
      } else if (outputFormat === "MMYY") {
        return `${month}${year.toString().slice(-2)}`;
      } else {
        return null;
      }
    }

    // Check if the input is in the "YYYYMMDD" format (as a number)
    if (typeof excelDate === "number" && /^\d{8}$/.test(excelDate.toString())) {
      const year = excelDate.toString().substring(0, 4);
      const month = excelDate.toString().substring(4, 6);
      const day = excelDate.toString().substring(6, 8);

      console.log("Parsed YYYYMMDD numeric format:", { year, month, day });

      if (outputFormat === "YYYYMMDD") {
        return `${year}${month}${day}`;
      } else if (outputFormat === "DD/MM/YYYY") {
        return `${day}/${month}/${year}`;
      } else if (outputFormat === "MMYY") {
        return `${month}${year.slice(-2)}`;
      } else {
        return null;
      }
    }

    // Check if the input is in the "DD.MM.YYYY" format
    const matchDotFormat = excelDate.match(/^(\d{2})\.(\d{2})\.(\d{4})$/);
    if (matchDotFormat) {
      const [, day, month, year] = matchDotFormat;
      console.log("Parsed dot format:", { day, month, year });

      if (outputFormat === "YYYYMMDD") {
        return `${year}${month}${day}`;
      } else if (outputFormat === "DD/MM/YYYY") {
        return `${day}/${month}/${year}`;
      } else if (outputFormat === "MMYY") {
        return `${month}${year.slice(-2)}`;
      } else {
        return null;
      }
    }

    // Check if the input is in the "DD/MM/YYYY" format
    const matchSlashFormat = excelDate.match(/^(\d{2})\/(\d{2})\/(\d{4})$/);
    if (matchSlashFormat) {
      const [, day, month, year] = matchSlashFormat;
      console.log("Parsed slash format:", { day, month, year });

      if (outputFormat === "YYYYMMDD") {
        return `${year}${month}${day}`;
      } else if (outputFormat === "DD/MM/YYYY") {
        return `${day}/${month}/${year}`;
      } else if (outputFormat === "MMYY") {
        return `${month}${year.slice(-2)}`;
      } else {
        return null;
      }
    }

    // Check if the input is in the "DD-MM-YYYY" format
    const matchHyphanFormat = excelDate.match(/^(\d{2})\-(\d{2})\-(\d{4})$/);
    if (matchHyphanFormat) {
      const [, day, month, year] = matchHyphanFormat;
      console.log("Parsed hyphan format:", { day, month, year });

      if (outputFormat === "YYYYMMDD") {
        return `${year}${month}${day}`;
      } else if (outputFormat === "DD/MM/YYYY") {
        return `${day}/${month}/${year}`;
      } else if (outputFormat === "MMYY") {
        return `${month}${year.slice(-2)}`;
      } else {
        return null;
      }
    }

    // Check if the input is in the "YYYYMMDD" format (as a string)
    const matchYMDFormat = excelDate.match(/^(\d{4})(\d{2})(\d{2})$/);
    if (matchYMDFormat) {
      const [, year, month, day] = matchYMDFormat;
      console.log("Parsed YMD string format:", { year, month, day });

      if (outputFormat === "YYYYMMDD") {
        return `${year}${month}${day}`;
      } else if (outputFormat === "DD/MM/YYYY") {
        return `${day}/${month}/${year}`;
      } else if (outputFormat === "MMYY") {
        return `${month}${year.slice(-2)}`;
      } else {
        return null;
      }
    }

    // If the input format is not recognized
    console.log("Unrecognized date format:", excelDate);
    return null;
  };

  // const uploadFile = (e) => {
  //   console.log("E is ", e);
  //   const file = e;

  //   if (file) {
  //     ExcelRenderer(file, (err, resp) => {
  //       if (err) {
  //         alert("An Error Occured while reading the Excel");
  //       } else {
  //         const { cols, rows } = resp;
  //         console.log("cols......", cols, rows);
  //         let mergedData = [];
  //         var requiredColumns = [
  //           "trntype",
  //           "domaincode",
  //           "domaindescn",
  //           "suppliercode",
  //           "supplierdescn",
  //           "refbillno",
  //           "trdate",
  //           "itemcode",
  //           "itemdescn",
  //           "serialno",
  //           "qty",
  //           "purrate",
  //           "discountamt",
  //           "tabableamt",
  //           "cgstrate",
  //           "sgstrate",
  //           "igstrate",
  //           "cgstamt",
  //           "sgstamt",
  //           "igstamt",
  //           "totalamt",
  //           "entrydate",
  //           "manudate",
  //           "expdate",
  //         ];

  //         for (let i = 0; i < rows.length; i++) {
  //           let rowData = {};
  //           for (let j = 0; j < requiredColumns.length; j++) {
  //             if (
  //               i != 0 &&
  //               (requiredColumns[j] == "dcdate" ||
  //                 requiredColumns[j] == "expirydate" ||
  //                 requiredColumns[j] == "trdate")
  //             ) {
  //               var convertedDate = convertExcelDateToYYYYMMDD(rows[i][j]);
  //               if (!convertedDate) {
  //                 alert(
  //                   "An Error Occured while Adding the Excel, Update Date in Date Format"
  //                 );
  //                 return;
  //               }
  //               rowData[requiredColumns[j]] = convertedDate;
  //             } else if (requiredColumns[j] == "manudate") {

  //               rowData[requiredColumns[j]] = 0;
  //             }else if (requiredColumns[j] == "expdate") {
  //               rowData[requiredColumns[j]] = datedisplay(rowData?.serialno?.substr(-4),true).dataDate
  //             } else {
  //               rowData[requiredColumns[j]] = rows[i][j];
  //             }

  //             if (rowData[requiredColumns[j]] == undefined) {
  //               rowData[requiredColumns[j]] = "";
  //             }
  //           }
  //           mergedData.push(rowData);
  //         }

  //         console.log("Merge Data is", mergedData[0]);

  //         const columns = Object.keys(mergedData[0]).map((key) => {
  //           if (key=="manudate") {
  //             return {
  //               title: "Manufacturing Date",
  //               dataIndex: key,
  //               key,
  //               render: (_, text) => {
  //                 console.log("TEXT IS", text, key, text?.key);
  //                 if (key == "dcdate" || key == "expirydate" || key == "trdate"  || key == "expdate") {
  //                   return <p>{dateFormatter(text[key])}</p>;
  //                 } else {
  //                   return <p>{text[key]}</p>;
  //                 }
  //               },
  //             }
  //           } else if (key=="expdate") {
  //             return {
  //               title: "Expiry Date",
  //               dataIndex: key,
  //               key,
  //               render: (_, text) => {
  //                 console.log("TEXT IS", text, key, text?.key);
  //                 if (key == "dcdate" || key == "expirydate" || key == "trdate"  || key == "expdate") {
  //                   return <p>{dateFormatter(text[key])}</p>;
  //                 } else {
  //                   return <p>{text[key]}</p>;
  //                 }
  //               },
  //             }
  //           } else {

  //             return {
  //             title: mergedData[0][key],
  //             dataIndex: key,
  //             key,
  //             render: (_, text) => {
  //               console.log("TEXT IS", text, key, text?.key);
  //               if (key == "dcdate" || key == "expirydate" || key == "trdate"  || key == "expdate") {
  //                 return <p>{dateFormatter(text[key])}</p>;
  //               } else {
  //                 return <p>{text[key]}</p>;
  //               }
  //             },
  //           }
  //           }
  //       });
  //         setColumns(columns);
  //         setRows(mergedData.slice(1));
  //       }
  //     });
  //   }
  // };

  const uploadFile = (e) => {

    const file = e;

    if (file) {
      ExcelRenderer(file, (err, resp) => {
        if (err) {
          alert("An Error Occurred while reading the Excel");
        } else {
          const { rows } = resp;

          let mergedData = [];
          var requiredColumnsMapping = {};

          // Check if columns like "TranFlag" are present
          if (rows[0][0].replace(/^\s+|\s+$/g, "").includes("TranFlag")) {
            requiredColumnsMapping = {
              TranFlag: "trnflag",
              "Distributor ERPCode": "domaincode",
              "Distributor Name": "domaindescn",
              "Supplier ERP Code": "suppliercode",
              "Supplier Name": "supplierdescn",
              "Supplier Invoice No": "refbillno",
              "Supplier Inv. Date": "trdate",
              "Item Code": "itemcode",
              "Item Name": "itemdescn",
              "Serial No": "serialno",
              Qty: "qty",
              "Purchase rate": "purrate",
              "Discount Amt": "discountamt",
              "Taxable Amt": "tabableamt",
              "CGST %": "cgstrate",
              "SGST %": "sgstrate",
              "IGST %": "igstrate",
              "CGST Amt": "cgstamt",
              "SGST Amt": "sgstamt",
              "IGST Amt": "igstamt",
              "Total Amt": "totalamt",
              expdate: "expdate", // assuming "expdate" is in the correct format in the Excel file
              trdate: "trdate", // assuming "trdate" is in the correct format in the Excel file
              Insertdatetime: "entrydate",
            };
            tabStore.dispatch({
              type: "UPDATE_DATA_WITH_TABINDEX",
              fieldType: "saveLoading",
              text: true,
              tabDataIndex: pageIndex ? pageIndex : tabStore.state.tabIndex,
            });
            for (let i = 0; i < rows.length; i++) {
              let rowData = {};
              for (let j = 0; j < rows[i].length; j++) {
                const currentColumnName = rows[0][j].replace(/^\s+|\s+$/g, "");

                if (
                  i !== 0 &&
                  (currentColumnName === "dcdate" ||
                    currentColumnName === "expirydate" ||
                    currentColumnName === "trdate")
                ) {
                  var convertedDate = convertExcelDateToYYYYMMDD(rows[i][j]);
                  if (!convertedDate) {
                    alert(
                      "An Error Occurred while Adding the Excel, Update Date in Date Format"
                    );
                    return;
                  }
                  rowData[currentColumnName] = convertedDate;
                } else if (currentColumnName === "manudate") {
                  rowData[currentColumnName] = 0;
                } else if (currentColumnName === "expdate") {
                  // Format "expdate" as YYYYMMDD
                  rowData[currentColumnName] = convertExcelDateToYYYYMMDD(
                    rows[i][j]
                  );
                } else if (currentColumnName === "Serial No") {
                  // Format "Serial No" as a string
                  rowData[currentColumnName] = String(rows[i][j]);
                } else {
                  rowData[currentColumnName] = rows[i][j];
                }

                if (rowData[currentColumnName] === undefined) {
                  rowData[currentColumnName] = "";
                }
              }

              // Additional check for "Serial No" being empty
              if (rowData["Serial No"] !== "") {
                mergedData.push(rowData);
              }
            }

            // Additional column logic for "TranFlag"
            const columns = Object.keys(mergedData[0]).map((key) => {
              if (key === "manudate") {
                return {
                  title: "Manufacturing Date",
                  dataIndex: key,
                  key,
                  render: (_, text) => {
                    if (
                      key === "dcdate" ||
                      key === "expirydate" ||
                      key === "trdate" ||
                      key === "expdate"
                    ) {
                      return <p>{dateFormatter(text[key])}</p>;
                    } else {
                      return <p>{text[key]}</p>;
                    }
                  },
                };
              } else if (key === "expdate" || key === "trdate") {
                return {
                  title: key === "expdate" ? "Expiry Date" : "Transaction Date",
                  dataIndex: key,
                  key,
                  render: (_, text) => (
                    <p>{dateFormatter(text[key], "YYYYMMDD")}</p>
                  ),
                };
              } else if (key === "Serial No") {
                return {
                  title: "Serial No",
                  dataIndex: key,
                  key,
                  render: (_, text) => <p>{String(text[key])}</p>,
                };
              } else {
                return {
                  title: mergedData[0][key],
                  dataIndex: key,
                  key,
                  render: (_, text) => {
                    if (
                      key === "dcdate" ||
                      key === "expirydate" ||
                      key === "trdate" ||
                      key === "expdate"
                    ) {
                      return <p>{dateFormatter(text[key])}</p>;
                    } else {
                      return <p>{text[key]}</p>;
                    }
                  },
                };
              }
            });

            setColumns(columns);
            setRows(mergedData.slice(1));
            tabStore.dispatch({
              type: "UPDATE_DATA_WITH_TABINDEX",
              fieldType: "saveLoading",
              text: false,
              tabDataIndex: pageIndex ? pageIndex : tabStore.state.tabIndex,
            });
          }
          // Check if columns like "Sold-To" are present
          else if (rows[0][0].replace(/^\s+|\s+$/g, "").includes("Sold-To")) {
            requiredColumnsMapping = {
              "Sold-To": "domaincode",
              "Sold To Name": "domaindescn",
              "PO No": "pono",
              "Invoice Number": "refbillno",
              "Invoice Date": "trdate",
              "Material No": "itemcode",
              "Material Description": "itemdescn",
              "Deliv Qty": "qty",
              "Net Price": "purrate",
              "Total Amt W.VAT": "totalamt",
              Tax: "tax",
              "Batch No": "itembatchno",
              "Serial No": "serialno",
              "Exp Date": "expdate",
            };
            tabStore.dispatch({
              type: "UPDATE_DATA_WITH_TABINDEX",
              fieldType: "saveLoading",
              text: true,
              tabDataIndex: pageIndex ? pageIndex : tabStore.state.tabIndex,
            });
            for (let i = 0; i < rows.length; i++) {
              let rowData = {};

              for (let j = 0; j < rows[i].length; j++) {
                const currentColumnName = rows[0][j].replace(/^\s+|\s+$/g, "");
                if (requiredColumnsMapping[currentColumnName]) {
                  const fieldName = requiredColumnsMapping[currentColumnName];

                  if (fieldName === "trdate") {
                    // Format date columns as needed
                    console.log("TRDATE", rows[i][j])
                    rowData[fieldName] = Number(
                      convertExcelDateToFormat(rows[i][j], "YYYYMMDD")
                    );
                  } else if (fieldName === "expdate") {
                    rowData[fieldName] = Number(
                      convertExcelDateToFormat(rows[i][j], "YYYYMMDD")
                    );
                  } else if (fieldName === "serialno") {
                    // Format "Serial No" as a string
                    rowData[fieldName] = (
                      rows[i][j] !== undefined ? String(rows[i][j]) : ""
                    ).trim();

                    if (rowData[fieldName] === "") {
                      break; // No need to continue processing the row
                    }
                  } else if (fieldName === "itembatchno") {
                    rowData[fieldName] = rows[i][j] === "" ? "." : rows[i][j];
                  } else if (fieldName === "itemcode") {
                    // Take only the first 10 characters of the Material No
                    rowData[fieldName] = String(rows[i][j]).substring(0, 10);
                  } else {
                    rowData[fieldName] = rows[i][j];
                  }
                } else {
                  // If the column name is not in the mapping, use the actual column name
                  rowData[currentColumnName] = rows[i][j];
                }
              }

              // Check if tenantrecno is 3 and add supplierreco=1571
              if (auth.state.userdata.tenantrecno === 3) {
                rowData.supplierrecno = 1571;
              }
              // Only push the row if "Serial No" is not empty
              if (rowData["serialno"] !== "") {
                const itemcode = rowData["itemcode"] || "";
                const serialno = (
                  rowData["serialno"] !== undefined
                    ? String(rowData["serialno"])
                    : ""
                ).trim();
                const expdate = convertExcelDateToFormat(
                  rowData["expdate"],
                  "MMYY"
                );

                if (serialno !== "") {
                  rowData["serialno"] = `${itemcode}${serialno}${expdate}`;
                } else {
                  rowData["serialno"] = serialno;
                }
                if (rowData["serialno"]) {
                  mergedData.push(rowData);
                }
              }

            }

            const columns = Object.keys(mergedData[0]).map((key) => {
              return {
                title:
                  key === "trdate"
                    ? "Invoice Date"
                    : key === "expdate"
                      ? "Exp Date"
                      : key === "supplierrecno"
                        ? "Supplier Code"
                        : key === "serialno"
                          ? "Serial No"
                          : mergedData[0][key],
                dataIndex: key,
                key,
                render: (_, text) => {
                  if (key === "trdate" || key === "expdate") {
                    return <p>{dateFormatter(text[key])}</p>;
                  } else {
                    return <p>{text[key]}</p>;
                  }
                },
              };
            });

            setColumns(columns);
            setRows(mergedData.slice(1));
            tabStore.dispatch({
              type: "UPDATE_DATA_WITH_TABINDEX",
              fieldType: "saveLoading",
              text: false,
              tabDataIndex: pageIndex ? pageIndex : tabStore.state.tabIndex,
            });
          }
        }
      });
    }
  };

  const Experidate = (dateString) => {

    var parts = dateString.split("/");
    var day = parts[0];
    var month = parts[1];
    var year = parts[2];

    // Pad day and month with leading zeros if necessary
    if (day.length === 1) {
      day = "0" + day;
    }
    if (month.length === 1) {
      month = "0" + month;
    }

    return year + month + day;
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const files = e.dataTransfer.files;
    if (files.length > 0) {
      // Assuming you have a function called handleFileUpload to handle the file
      uploadFile(files[0]);
      showMessage("File added successfully!");
    }
  };

  const handleFileChange = (e) => {
    tabStore.dispatch({
      type: "UPDATE_DATA_WITH_TABINDEX",
      fieldType: "saveLoading",
      text: true,
      tabDataIndex: pageIndex ? pageIndex : tabStore.state.tabIndex
    });
    const file = e.target.files[0];
    if (file) {
      // Assuming you have a function called handleFileUpload to handle the file
      uploadFile(file);
      showMessage("File added successfully!");
      tabStore.dispatch({
        type: "UPDATE_DATA_WITH_TABINDEX",
        fieldType: "saveLoading",
        text: false,
        tabDataIndex: pageIndex ? pageIndex : tabStore?.state.tabIndex
      });
    }
  };
  const showMessage = (message) => {
    setMessage(message);
    setTimeout(() => {
      setMessage("");
    }, 3000); // Clear message after 3 seconds
  };
  useEffect(() => {
    setRender((p) => p + 1);
  }, [tabStore?.state?.tabs[pageIndex]?.changedRows?.length]);
  const gradientColor = {
    backgroundImage: "linear-gradient(to right, #ff9966, #ff5e62)",
    WebkitBackgroundClip: "text",
    color: "transparent",
  };

  return (
    <div className="container" key={render}>
      {tabStore?.state?.tabs[pageIndex]?.saveLoading && (
        <div
          style={{
            position: "fixed",
            top: "0",
            left: "0",
            width: "100%",
            height: "100%",
            zIndex: 999,
            backgroundColor: "rgba(255, 255, 255, 0.8)", // Adjust alpha to make it slightly darker
            padding: "20px",
            borderRadius: "8px",
          }}
        >
          {/* Inside your component */}
          <Spin spinning={tabStore?.state?.tabs[pageIndex]?.saveLoading} indicator={<LoadingOutlined style={{ ...gradientColor, fontSize: 50 }} spin />} />
          <p style={{ marginTop: "30px", textAlign: "center", fontSize: "20px", opacity: tabStore?.state?.tabs[pageIndex]?.saveLoading ? 1 : 0, transition: 'opacity 0.5s ease' }}>
            Your Request is Processing in background. Please Wait or Check after a while..
          </p>
        </div>
      )}

      <div
        className={`drop-area ${message ? "active" : ""}`}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
      >
        <p className="message">{message || "Drag & Drop files here"}</p>
        <div className="or">or</div>
        <label htmlFor="fileInput" className="file-label">
          Select File
          <input
            type="file"
            accept=".csv,.xlsx,.xls"
            id="fileInput"
            className="file-input"
            onChange={handleFileChange}
          />
        </label>
        <div className="file-info" id="fileInfo"></div>
      </div>

      <div>
        {/* Render your table here */}
        <Table dataSource={rows} columns={columns} />
      </div>
    </div>
  );
}

export default PurchaseInvoiceETL;

import React from "react";
import PurposeofHelp from "./Images/PurposeofHelp.png"
import images from './Images/Images.css';
import Buttons from './Images/Buttons.png'

const PurposeOfHelp = () => {
  return (
    <div>
        <div style={{position:'fixed',backgroundColor:'white',width:'80%'}}>
          <h1>Purpose of Help</h1>
        </div>
        <br/> 
        <br/> 
      <h2>BCore help v1.0</h2>
      <p>The facility of online help is provided to the product BCore developed by Sutradhar. The users of the product should have handy tutor which will elaborate the process of using the application software.</p>
      <p>The help will provide overview of the application and will further details of every fastpath and suggest you “How to ? “</p>
      <p>In some places there will be a video tutor to explain the workflow </p>
      <p>The images of the screen to use will also be available.</p>
      <p>If anyone is interested to have deep dive then he can also read the theorical part of the process.</p>
      <img className="images" src={PurposeofHelp} alt="Purpose of Help"/>
      <p>On the top there are some buttons </p>
      <img src={Buttons}  className="images" alt="Buttons"/>
      <p>The first button is for going to the previous screen till we reach to the home screen. </p>
      <p>The second button will take the pages forward where we had already visited. </p>
      <p>Third button is for coming to home screen directly.</p>
      <p>For the button is to have printout of the page which is displayed to you.</p>
      <p>There is index listed at the home page by which you can directly jump the topic you have clicked.</p>
      <p>Cancel button will exit from the help screen.</p>
    </div>
  );
};

export default PurposeOfHelp;
